import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

export const ListContabilidad = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    const [open7, setOpen7] = useState(false);

    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'contabilidad']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <AutoStoriesIcon />
                    </ListItemIcon>
                    <ListItemText primary="Contabilidad" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`contabilidad/asientos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Asientos" />
                        </ListItemButton>

                        {/* <ListItemButton component={RouterLink} to={`contabilidad/plan-de-cuentas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Plan de Cuentas" />
                        </ListItemButton> */}

                        <ListItemButton component={RouterLink} to={`contabilidad/plan-cuentas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Plan De Cuentas" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`contabilidad/ejercicios-contables/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ejercicios Contables" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`contabilidad/centro-de-costos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Centro de Costos" />
                        </ListItemButton>

                        <ListItemButton onClick={() => setOpen7(!open7)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes Financieros" />
                            {open7 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open7} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Estado de Resultados'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/contabilidad/reportes-financieros/estado-de-resultados/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Balance General'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/contabilidad/reportes-financieros/balance-general/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Balance de Comprobación'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/contabilidad/reportes-financieros/Balance-de-Comprobacion/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Flujo de Caja'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/contabilidad/reportes-financieros/flujo-de-caja/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}