import {
  IconButton, Typography, Box, Paper, Chip, Button
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from "moment";
import * as XLSX from 'xlsx/xlsx.mjs'
import { auth, db, firebase } from '../../firebase'
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ViewPdfClient } from '../clientes/ViewPdfClient';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';
import { ViewPhotosInstalaciones } from './ViewPhotosInstalaciones';
function Estado({ estado }) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado == 0) {
    name = 'Esperando..'
    color = 'warning'
  } else if (estado === 1) {
    name = 'No factible'
    color = 'info'
  } else if (estado === 2) {
    name = 'FACTIBILIDAD'
    color = 'success'
  }
  return (
    <Chip label={name} color={color} />
  )
}
export const MaterialesUtilizados = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState()
  //
  const current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  useEffect(() => {
    LoadData()

  }, [startDate])
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth

  // const data2 = () => {
  //   db.collection("tecnicos").where("estado", "==", 2).get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         const data = doc.data()
  //         if (data) {
  //           db.collection("materiales3").doc(data.numContrato).set(data)
  //             .then((doc) => {
  //               if (doc.exists) {
  //                 console.log("Si existe data:", doc.data());
  //               } else {
  //                 // doc.data() will be undefined in this case
  //                 console.log("No such document!");
  //               }
  //             }).catch((error) => {
  //               console.log("Error getting document:", error);
  //             });
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  // }

  // const list = () => {
  //   console.log('Prueba')
  //   db.collection("tecnicos")
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         //console.log(doc.id);
  //         //
  //         var washingtonRef = db.collection("registro_orden_trabajo").doc(`${doc.id}`);

  //         // Set the "capital" field of the city 'DC'
  //         return washingtonRef.update({
  //           estado_mat: 1
  //         })
  //           .then(() => {
  //             console.log("Document successfully updated!");
  //           })
  //           .catch((error) => {
  //             // The document probably doesn't exist.
  //             console.error("Error updating document: ", error);
  //           });

  //       });
  //     })
  //     .catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  // }
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("tecnicos").where("estado", "==", 2)
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fechaHora', '>=', startfulldate)
      ref = ref.where('fechaHora', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            
            return {
              ...doc.data(),
              id: doc.id,
            }

          }
          )
        ];
        setData(data)
      })
    }
    //}
  }
  const handleExport = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let date1;
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`;
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`;
    }
    const array = []
    data.forEach(i => {
      if (i.cantidadSoportes != undefined) {
        array.push(i);
      }
    });
    
    const reData = [
      ...array.map(doc => {
        return {
          NUM_CONTRATO: doc.numContrato,
          FLOTA: doc.numFlota,
          ONU: doc.cantidadOnus,
          BOBINA_COD: doc.bobinaCod,
          AMARRAS_P: doc.cantidadAmarrasP,
          AMARRAS_G: doc.cantidadAmarrasG,
          MECANICOS: doc.cantidasConectoresMecanicos,
          TORNILLOS: doc.cantidadTornillos,
          TACOS: doc.cantidadTacos,
          GRAPAS: doc.cantidadGrapas,
          FIBRAS: doc.cantidadFibra,
          CABLE_INICIO: doc.cableInicio,
          CABLE_FINALIZA: doc.cableFinaliza,
          CABLE_UTILIZADO: Math.abs(doc.cableUtilizado),

          METRAJE_EXTRA: Math.abs(doc.metrajeExtraCableUtilizado),

          PERSONA_AUTORIZA_METRAJE: doc.personaAutorizaMetraje === false ? '' : doc.personaAutorizaMetraje,

          VALOR_PLAN: doc.valorCobrado === undefined ? '' : doc.valorCobrado,

          NUMERO_CUOTAS: doc.noCuotas,

          VALOR_EXTRA: Math.abs(doc.valorExtra) === undefined ? '' : Math.abs(doc.valorExtra),

          VALOR_TOTAL: parseFloat(doc.valorCobrado) + parseFloat(doc.valorExtra),

          MATERIALES_EXTRA: doc.materialesExtra === undefined ? '' : doc.materialesExtra,

          VALOR_MATERIALES_EXTRA: doc.valorMaterialesExtra === undefined ? '' : doc.valorMaterialesExtra,

          TECNICO_RESPONSABLE: doc.nombreLider,

          DSERIE: doc.DSerie,

          CLIENTE: doc.nombres,

          FECHA_DE_INSTALACION: doc.fechaDeInstalacion === undefined ? '' : doc.fechaDeInstalacion.toDate(),

        }
      })
    ]


    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);

    ws['!cols'] = [];
    ws['!cols'][1] = { hidden: true };
    ws['!cols'][2] = { hidden: true };
    ws['!cols'][3] = { hidden: true };
    ws['!cols'][4] = { hidden: true };
    ws['!cols'][5] = { hidden: true };
    XLSX.utils.book_append_sheet(wb, ws, "MaterialesUtilizados");

    XLSX.writeFile(wb, `Materiales_Utilizados_${date1}.xlsx`)
  }
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //LoadData()
  }
  const columns = [
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fechaDeInstalacion', headerName: 'FECHA DE INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: '', headerName: 'MOTIVO', width: 175,
      renderCell: (params) => {
        return params.row.cambio_domicilio === true ? <Chip label="CAMBIO DOMICILIO" size="small" color="info" /> : <Chip label="INSTALACION" size="small" color="success" />  
      }
    },
    { field: 'numFlota', headerName: 'FLOTA', width: 110 },
    { field: 'numContrato', headerName: 'CONTRATO', width: 250 },
    {
      field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 280
    },
    { field: 'DSerie', headerName: 'DSerie', width: 200 },
    { field: 'cantidadOnus', headerName: 'ONU', width: 50 },
    { field: 'bobinaCod', headerName: 'BOBINA COD', width: 120 },
    { field: 'cantidadSoportes', headerName: 'SOPORTES', width: 120 },
    { field: 'cantidadAmarrasG', headerName: 'AMARRAS GRANDES', width: 120 },
    { field: 'cantidadAmarrasP', headerName: 'AMARRAS PEQUEÑAS', width: 120 },
    { field: 'cantidasConectoresMecanicos', headerName: 'MECANICOS', width: 120 },
    { field: 'cantidadTornillos', headerName: 'TORNILLOS', width: 120 },
    { field: 'cantidadTacos', headerName: 'TACOS', width: 120 },
    { field: 'cantidadGrapas', headerName: 'GRAPAS', width: 120 },
    { field: 'cableInicio', headerName: 'CABLE INICIO', width: 120 },
    { field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 160 },
    { field: 'cableUtilizado', headerName: 'CABLE UTILIZADO', width: 160 },
    { field: 'canton', headerName: 'CANTON', width: 120 },
    
    {
      field: 'nombreLider', headerName: 'NOMBRE LIDER', width: 320,
      renderCell: (params) => {
        return params.row.nombreLider === undefined ? '' : params.row.nombreLider
      }
    },
    { field: 'valorCobrado', headerName: 'VALOR DEL PLAN', width: 160 },
    {
      field: 'valor_extra', headerName: 'VALOR EXTRA', width: 130,
      renderCell: (params) => {
        return params.row.valorExtra === undefined ? '' : params.row.valorExtra
      }
    },
    {
      field: 'button', headerName: 'VER', width: 90,
      renderCell: (params) => {
        return <ViewPdfClient props={params.row} />
      }
    },
    {
      field: 'button1', headerName: 'IMAGENES', width: 100,
      renderCell: (params) => {
        return <ViewPhotosInstalaciones props={params.row} />
      }
    },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  const onChange2 = () => {
    LoadData()
  }

  return (
    <>

      <Box pt={1} pl={1}>
        <Typography >
          INVENTARIO/<strong>REGISTRO DE MATERIALES</strong>  
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems='center' py={2}>
            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density="compact"
            columns={columns}
            components={{
              Toolbar: CustomToolbar,

            }}
            
          />
        </Box>
      </Paper>
      <Box pt={2}></Box>
      <Button disableElevation variant='outlined' onClick={() => handleExport()}>REPORTE MARIELA</Button>
    </>
  )
}