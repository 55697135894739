import {
    Table, TableContainer, TableRow, TableCell, TableBody,
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse, Hidden
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';
import { UpdateFecha } from "./UpdateFecha";

export const ActualizarFechaScreen = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 86400000))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    //
    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
        mostrarTotal()

    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }
    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("reporte_movimientos").where("estado", "==", 0)

        //Si tiene algun doc anterior se agrega acontinuacion
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0);

            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }
    };


    const mostrarTotal = () => {

    }
    function CustomFooterTotalComponent(props) {

        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    function EstadoPagado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado_pagado === 0 || !d.estado_pagado) {
            name = 'NO PAGADO'
            color = 'info'
        }
        if (d.estado_pagado === 1) {
            name = 'NO PAGADO'
            color = 'info'
        }
        if (d.estado_pagado === 2) {
            name = 'PAGADO'
            color = 'secondary'
        }
        if (d.promo_santa_elena === true || d.debito == 1) {
            name = 'PAGO DEBITO'
            color = 'warning'
        }
        return (
            <Chip label={name} size="small" color={color} />
        )

    }
    const columns = [
        {
            field: 'created', headerName: 'FECHA', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        { field: 'uidSolicitud', headerName: 'NUM MOVIMIENTO', width: 200 },
        { field: 'motivo', headerName: 'MOTIVO', width: 200 },
        { field: 'bodega', headerName: 'BODEGA', width: 200 },
        { field: 'ubicacion', headerName: 'UBICACION', width: 200 },
        { field: 'flota', headerName: 'FLOTA', width: 200 },
        { field: 'placa_flota', headerName: 'PLACA FLOTA', width: 200 },
        { field: 'observacion_adicional', headerName: 'OBSERVACION', width: 200 },
        { field: 'user', headerName: 'USUARIO QUE REALIZO EL MOVIMIENTO', width: 200 },
        {
            field: '1', headerName: 'AGREGAR PAGO', width: 140,
            renderCell: (params) => {
                return <UpdateFecha props={params.row} />
            }
        },
        /* {
            field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
        }, */
    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
            {/* <CustomExportButton /> */}
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {

        console.log(data.length)
    }

    return (
        <>
            <Box pt={1}>
                <Typography variant="button" >
                    <Typography >
                        <strong>INSTALACIONES EN ESPERA</strong>
                    </Typography >
                </Typography >
            </Box>

            <Box pt={1}>
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                    </Box>
                </Box>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    <Button
                        disableElevation
                        variant="contained"
                        onClick={() => LoadData()}
                    >
                        Aplicar busqueda
                    </Button>
                </Collapse>
            </Box>

            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        density="compact"
                        pagination={true}
                        // disableColumnSelector
                        // disableDensitySelector
                        //hideFooterPagination
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};