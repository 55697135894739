import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import { DataGrid, esES } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

export function cambioTiempo(tiempoTotal) {
    tiempoTotal = parseInt(tiempoTotal / 1000)
    let horas = parseInt(tiempoTotal / 3600)
    tiempoTotal -= horas * 3600
    let minutos = parseInt(tiempoTotal / 60)
    tiempoTotal -= minutos * 60
    let segundos = tiempoTotal
    if (horas < 0) {
        horas *= -1
    }
    if (minutos < 0) {
        minutos *= -1
    }
    if (segundos < 0) {
        segundos *= -1
    }
    return horas + ' horas, ' + minutos + ' minutos, ' + segundos + ' segundos'

}

export const LogTicket = (props) => {

    const [ticket, setTicket] = useState(props.ticket)

    useEffect(() => {
        setTicket(props.ticket)
    }, [props])

    const columns = [
        {
            field: 'fecha', headerClassName: 'super-app-theme--header', headerName: 'Fecha', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.tiempo && params.row.tiempo !== '--' && params.row.fecha &&
                        moment(params.row.fecha.toDate().toISOString()).format('DD/MM/YYYY  HH:mm:ss')
                    }
                    {params.row.tiempo === '--' &&
                        <>
                            {params.row.fecha}
                        </>
                    }

                </>
            }
        },
        {
            field: 'tiempo', headerClassName: 'super-app-theme--header', headerName: 'Tiempo', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.tiempo && params.row.tiempo === '--' &&
                        <>
                            {params.row.tiempo}
                        </>
                    }
                    {params.row.tiempo && params.row.tiempo !== '--' &&
                        cambioTiempo(params.row.tiempo)
                    }

                </>
            }
        },
        { field: 'usuario', headerClassName: 'super-app-theme--header', headerName: 'USUARIO', width: 200 },
        { field: 'accion', headerClassName: 'super-app-theme--header', headerName: 'ACCION', width: 180 },
        { field: 'comentario', headerClassName: 'super-app-theme--header', headerName: 'COMENTARIO', width: 200, },
    ]
    return (
        <>
            <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ fontSize: '16px' }}><strong>HISTORIAL:</strong><br />
                </Typography>
                <Box sx={{
                    height: 200, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                    '& .super-app-theme--header': {
                        backgroundColor: 'LightSlateGray',
                        color: 'white',
                        fontSize: '16px'
                    },
                    backgroundColor: '#f0f2f5'
                }}>
                    <DataGrid
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        loading={ticket.comentarios ? false : true}
                        hideFooterSelectedRowCount={true}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={props.columns ? props.columns : columns}
                        density='compact'
                        rows={ticket.comentarios ? ticket.comentarios : []}
                        getRowHeight={() => 'auto'}
                        getRowId={(row) => row.id}
                        getEstimatedRowHeight={() => 100}
                        hideFooterPagination={true}
                        hideFooter={true}
                    />
                </Box>
            </Grid>
        </>
    )
}
