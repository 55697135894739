import {
    TextField, Typography, Box, Grid, Button, AlertTitle, Alert,
    FormControl, MenuItem, Select, InputLabel, Container
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputValidation from "../../../components/utils/InputValidation";
import Swal from "sweetalert2";
import SelectGeografico from '../../../components/utils/SelectGeograficos';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from "@mui/lab";
import { auth, firebase, functions, db, storage } from '../../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { GeolocationGoogle } from '../../../components/utils/GeolocationGoogle'
import { listsPlanes } from "../../../redux/actions/parametersActions";
import { Divider } from "@mui/material";
import { SubirDocumento } from "./SubirDocumento";
import Compressor from 'compressorjs';
export const NuevoCambioPropietario = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const { planes } = useSelector(state => state.planesList)
    //Estado de crear nueva solicitud
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [statusClient, setStatusClient] = useState();
    const [contractArray, setContractArray] = useState([]);
    const [linea, setLinea] = useState('')
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [cedula2, setCedula2] = useState('');
    const [cedula3, setCedula3] = useState({ campo: "", valido: true })
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [fechaNacimiento, setFechaNacimiento] = useState({ campo: "", valido: true });
    const [direccion, setDireccion] = useState({ campo: "", valido: true });
    const [telefono, setTelefono] = useState({ campo: "", valido: true });
    const [celular, setCelular] = useState({ campo: "", valido: true });
    const [correo, setCorreo] = useState({ campo: "", valido: true });
    const [nacionalidad, setNacionalidad] = useState({ campo: "", valido: true });
    const [plan, setPlan] = useState([]);
    const [planValor, setPlanValor] = useState();
    const [geografico, setGeografico] = useState('');
    const [position, setPosition] = useState({
        lat: latitude,
        lng: longitude,
    })
    const [planId, setPlanId] = useState();
    const [planName, setPlanName] = useState('')

    const [camara, setCamara] = useState('NO')
    const [numCam, setNumCam] = useState('')
    const [num_contrato, setNumContrato] = useState('')
    const [contrato, setContrato] = useState('')
    //
    const [cedulaFron, setCedulaFron] = useState('')
    const [cesion, setCesion] = useState('')
    const [cedulaTra, setCedulaTra] = useState('')
    const [aceptacion, setAceptacion] = useState('')
    const [tipoContrato, setTipoContrato] = useState('')
    const [list_planes, setListPlanes] = useState([])
    useEffect(() => {
        geolocationPosition();
        dispatch(listsPlanes())
        geo()

    }, [dispatch])
    const geo = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        function success(pos) {
            var crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        };
        function error(err) {
            console.warn('ERROR(' + err.code + '): ' + err.message);
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
    }
    const apiCivil = (cedula2) => {
        setStatusClient('')
        setContractArray('')
        setNombre({ campo: "", valido: true })
        setDireccion({ campo: "", valido: true })
        setNacionalidad({ campo: "", valido: true })
        setFechaNacimiento({ campo: "", valido: true })
        setCedula2(cedula2)
        if (cedula2 && cedula2.length >= 10) {
            const mikrowisp = functions.httpsCallable('mikrowisp')
            mikrowisp(cedula2)
                .then(res => {

                    if (res) {
                        if (res.mensaje == "No existe el cliente con el filtro indicado.") {
                            setStatusClient("Este cliente es nuevo en Yiga5");
                        } else {
                            const contracts = res.data;
                            const data = [
                                ...contracts.map((doc) => {
                                    return {
                                        ...doc,
                                        NUM_CONTRATO: doc.NUM_CONTRATO,
                                        estado: doc.estado,
                                        REFERENCIA: doc.REFERENCIA,
                                    };
                                }),
                            ];
                            setContractArray(data);
                            setStatusClient("");
                            if (res.data.find((element) => element.estado.includes('SUSPENDIDO'))) {

                                Swal.fire({ icon: "warning", text: "Cliente con contrato suspendido" })
                            }
                        }
                    }
                })
                .catch(error => console.log('Function: ', error))
        }

    }
    const submitHandler = () => {
        setLoading(true)
        const dataForm = {
            ...contrato,
            uid: auth.currentUser.uid,
            cedula: cedula2,
            nombres: nombre.campo,
            direccion: direccion.campo,
            nacionalidad: nacionalidad.campo,
            telefono: telefono.campo,
            celular: celular.campo,
            correo: correo.campo,
            coordenadas: new firebase.firestore.GeoPoint(
                position.lat,
                position.lng
            ),
            centro_costo: {
                codigo_vendedor: '40',
                lider_venta: userInfo.vendedor.lider_venta,
                categoria: userInfo.vendedor.categoria,
                centro_costo: userInfo.vendedor.centro_costo,
                canal: userInfo.vendedor.canal
            },

            fechaNacimiento: fechaNacimiento.campo,
            observacion: 'No hay observaciones',
            estado: 2,
            camara: camara,
            numCam: numCam,
            planId: planId,
            planNombre: planName,
            planValor: planValor,
            provincia: geografico.provincia,
            canton: geografico.canton,
            parroquia: geografico.parroquia,
            sector: geografico.sector,
            vendedor: userInfo.displayName,
            linea: linea,

        }
        //Generamos el nuevo codigo de contrato
        db.collection("pre_ventas").orderBy("created", "desc").limit(1).get()
            .then((preventasDb) => {
                if (preventasDb.size > 0) {
                    const preventas = preventasDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    const preventa = preventas[0]
                    const numeroSolicitud = preventa.numeroContrato + 1
                    const uidContrato = geografico.canton + `-` + linea + `-` + numeroSolicitud + '-' + 'HOG'

                    var info = {
                        id_agenda: uidContrato,
                        ...dataForm,
                        numeroContrato: numeroSolicitud,
                        numContrato: uidContrato,
                        cedula_nueva: cedula3.campo
                    }
                    console.log(info, ' info')
                    db.collection("pre_ventas").doc(uidContrato).set({
                        ...info,
                        tipo_venta: 'CAMBIO PROPIETARIO',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    db.collection("cambio_propietario").doc(uidContrato).set({
                        ...info,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }).then(() => {
                        //
                        const array = [
                            {
                                nameFile: 'foto_carta_cesion',
                                file: cesion
                            },
                            {
                                nameFile: 'foto_cliente_letrero',
                                file: aceptacion
                            },
                            {
                                nameFile: 'foto_cedula_trasera',
                                file: cedulaTra
                            },
                            {
                                nameFile: 'foto_cedula_frontal',
                                file: cedulaFron
                            }
                        ]
                        array.map((item) => {
                            new Compressor(item.file, {
                                quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
                                success: (compressedResult) => {
                                    const pictureRef = storage.ref().child('cambios_propietario').child(`${linea} ${nombre.campo}`).child(item.nameFile)
                                    const letreroUpload = pictureRef.put(compressedResult)
                                    letreroUpload.on("state_changed",
                                        (snapshot) => {
                                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                            console.log('Upload is ' + progress + '% done');
                                            switch (snapshot.state) {
                                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                                    console.log('Upload is paused');
                                                    break;
                                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                                    console.log('Upload is running');
                                                    break;
                                            }
                                        },
                                        (error) => {
                                            // Handle unsuccessful uploads
                                            Swal.fire({ icon: 'error', text: error, })
                                        },
                                        () => {
                                            letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                                console.log('File available at', downloadURL)

                                                if (item.nameFile === 'foto_carta_cesion') {
                                                    db.collection('cambio_propietario').doc(uidContrato).update({
                                                        foto_carta_cesion: downloadURL
                                                    })
                                                } else if (item.nameFile === 'foto_cliente_letrero') {
                                                    db.collection('cambio_propietario').doc(uidContrato).update({
                                                        foto_cliente_letrero: downloadURL
                                                    })
                                                } else if (item.nameFile === 'foto_cedula_trasera') {
                                                    db.collection('cambio_propietario').doc(uidContrato).update({
                                                        foto_cedula_trasera: downloadURL
                                                    })
                                                } else if (item.nameFile === 'foto_cedula_frontal') {
                                                    db.collection('cambio_propietario').doc(uidContrato).update({
                                                        foto_cedula_frontal: downloadURL
                                                    })
                                                }
                                                setLoading(false)
                                                navigate(`/gestion_post_ventas/cambio_propietario/1`)
                                            });
                                        }
                                    )
                                },
                                error: (error) => {
                                    Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                                }
                            })
                        })


                    })
                    var linea_ = linea[1]
                    const form = {
                        user_id_microwisp: contrato.id,
                        nombre: nombre.campo,
                        direccion: direccion.campo,
                        telefono: telefono.campo,
                        celular: celular.campo,
                        correo: correo.campo,
                        linea: linea_,
                        cedula: cedula3.campo,
                    }
                    const updateMikroClient = functions.httpsCallable('updateMikroClient');
                    updateMikroClient(form)
                        .then(res => {
                            Swal.fire({ icon: "success", text: "Usuario actualizado" })
                        })
                        .catch(error => {
                            setLoading(false)
                            console.log(error)
                        })

                    const enviarCorreo = functions.httpsCallable('enviarContrato')
                    enviarCorreo(info)
                        .then(res => {
                            console.log('Se envio correo')
                        })
                        .catch(error => console.log(error))

                }
            }).catch((error) => {
                console.log(error)
                setLoading(false)
                alert("No se puedo crear el id codigo de la solicitud: ", error)
            })
    }
    const geolocationPosition = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        function success(pos) {
            var crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        }

        function error(err) {
            console.warn("ERROR(" + err.code + "): " + err.message);
        }
        navigator.geolocation.getCurrentPosition(success, error, options);
    }
    const handlePlan = (e) => {
        const p = planes.filter((item) => item.id === e)
        const plan = p[0]
        setPlan(e)
        setPlanName(plan.plan)
        setPlanId(plan.id)
        setPlanValor(parseFloat(plan.costo))
    }
    const handleContrato = (e) => {
        setContrato('')
        setNumContrato(e)
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        const data = p[0]
        setContrato(data)
    }



    const handleTipoContrato = (e) => {
        setTipoContrato(e)
        if (e == 'HOGAR') {
            const hogar = planes.filter(row => (row.plan.includes('DEFENDER PLUS_')
                || row.plan.includes('UNLIMITED')))
            setListPlanes(hogar)
        } else {
            const corpor = planes.filter(row => !row.plan.includes('DEFENDER'))
            setListPlanes(corpor)
        }
    }
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Grid container spacing={5}>
                <Grid item md={4} xs={12}>
                    <Box py={2}>
                        <TextField
                            value={cedula2}
                            onChange={(e) => apiCivil(e.target.value)}
                            label="CÉDULA | RUC"
                        />
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box py={2}>
                        {
                            contractArray && cedula2.length >= 10 &&
                            <FormControl fullWidth disabled={!contractArray}>
                                <InputLabel >LINEA DE CONTRATO</InputLabel>
                                <Select
                                    value={num_contrato}
                                    label="LINEA DE CONTRATO"
                                    onChange={(e) => { handleContrato(e.target.value) }}
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {contractArray && contractArray.map((item, index) => (
                                        <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </Box>
                </Grid>
            </Grid>
            {statusClient && <Alert severity="info">{statusClient}</Alert>}
            {contrato && (

                <Alert severity="info">
                    <AlertTitle>
                        Información del contrato {contrato.NUM_CONTRATO}
                    </AlertTitle>

                    <Grid container spacing={5}>
                        <Grid item lg={6} md={6} xs={12}>
                            <Typography >
                                <strong>Nombres: </strong>{contrato.nombre}
                            </Typography>
                            <Typography >
                                <strong>Correo: </strong>{contrato.correo}
                            </Typography>
                            <Typography >
                                <strong>Direccion1: </strong>{contrato.REFERENCIA}
                            </Typography>
                            <Typography >
                                <strong>Direccion2: </strong>{contrato.direccion_principal}
                            </Typography>
                            <Typography >
                                <strong>Telefonos: </strong>{contrato.telefono}
                            </Typography>
                            <Typography >
                                <strong>Celular: </strong>{contrato.movil}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Typography >
                                <strong>PLANES: </strong>
                            </Typography>
                            {contrato && contrato.servicios.map((item, index) => (
                                <Typography key={index} value={item.NUM_CONTRATO}>{item.perfil} - <strong>{item.estado}</strong></Typography>
                            ))}
                        </Grid>
                    </Grid>
                </Alert>
            )}
            <Box pb={2}><Divider /></Box>
            {
                contrato && <>
                    <Grid container spacing={5}>
                        <Grid item md={4} xs={12}>
                            <Box py={3}>
                                <FormControl fullWidth>
                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <Select
                                        value={linea}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { setLinea(e.target.value) }}
                                    >
                                        <MenuItem value="">-</MenuItem>
                                        <MenuItem value="L1">L1</MenuItem>
                                        <MenuItem value="L2">L2</MenuItem>
                                        <MenuItem value="L3">L3</MenuItem>
                                        <MenuItem value="L4">L4</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <InputValidation
                                estado={cedula3}
                                cambiarEstado={setCedula3}
                                label="NUEVA CEDULA"
                                type="number"
                                placeholder=""
                                name="name"
                                helperText="Iniciar con 09 y minimo 10 digitos"
                                patterns={/^\d{10}$/}
                            />
                            <InputValidation
                                estado={nombre}
                                cambiarEstado={setNombre}
                                label="NOMBRES"
                                name="name"
                                helperText="Minimo 8 caracteres"
                            />
                            <InputValidation
                                estado={celular}
                                cambiarEstado={setCelular}
                                label="CELULAR"
                                type="number"
                                placeholder=""
                                name="name"
                                helperText="Iniciar con 09 y minimo 10 digitos"
                                patterns={/^\d{10}$/}
                            />
                            <SelectGeografico cambiarEstado={setGeografico} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <InputValidation
                                estado={direccion}
                                cambiarEstado={setDireccion}
                                label="DIRECCIÓN"
                                name="name"
                                helperText="Minimo 8 caracteres"
                            />
                            <InputValidation
                                estado={telefono}
                                type="number"
                                cambiarEstado={setTelefono}
                                label="TELEFONO"
                                name="name"
                                helperText="Minimo 10 caracteres"
                                patterns={/^.{0,10}$/}

                            />
                            <Box py={3}>
                                <TextField
                                    placeholder="LATITUD"
                                    type="number"
                                    onChange={(e) => setPosition({ ...position, lat: parseFloat(e.target.value) })}
                                    value={position.lat}
                                />
                            </Box>
                            <TextField
                                placeholder="LONGITUD"
                                type="number"
                                onChange={(e) => setPosition({ ...position, lng: parseFloat(e.target.value) })}
                                value={position.lng}
                            />

                            <Box pt={3}>
                                <FormControl fullWidth>
                                    <InputLabel>TIPO DE CONTRATO</InputLabel>
                                    <Select
                                        label="TIPO DE CONTRATO"
                                        value={tipoContrato}
                                        onChange={(e) => handleTipoContrato(e.target.value)}
                                    >
                                        <MenuItem value="HOGAR">HOGAR</MenuItem>
                                        <MenuItem value="CORPORATIVO">CORPORATIVO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box py={3}>
                                <FormControl fullWidth>
                                    <InputLabel>PLAN</InputLabel>
                                    <Select
                                        value={plan}
                                        label="PLAN"
                                        onChange={(e) => handlePlan(e.target.value)}
                                    >
                                        <MenuItem value="">--</MenuItem>
                                        {list_planes?.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.plan}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>


                            {
                                camara == 'SI' &&
                                <Box pb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>NUM CAMARA</InputLabel>
                                        <Select
                                            value={numCam}
                                            label="CAMARA INTELIGENTE"
                                            onChange={(e) => setNumCam(e.target.value)}
                                        >
                                            <MenuItem value="1">1</MenuItem>
                                            <MenuItem value="2">2</MenuItem>
                                            <MenuItem value="3">3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <InputValidation
                                estado={nacionalidad}
                                cambiarEstado={setNacionalidad}
                                label="NACIONALIDAD"
                                name="name"
                                helperText="Minimo 8 caracteres"
                            //patterns={/^.{8,30}$/}
                            />
                            <InputValidation
                                estado={correo}
                                type="email"
                                cambiarEstado={setCorreo}
                                label="CORREO"
                                name="name"
                                helperText="Minimo 8 caracteres"
                            //patterns={/^.{8,30}$/}

                            />
                            <span style={{ textAlign: "center", color: "olive" }}>¿Desea seleccionar manualmente la ubicacion?</span>
                            {
                                position &&
                                <GeolocationGoogle position={position} setPosition={setPosition} />
                            }
                        </Grid>
                    </Grid>


                    <Grid container>
                        <Grid item md={6}>
                            <Box py={2}>
                                <Typography>DOCUMENTO CEDULA FRONTAL</Typography>
                            </Box>

                            <SubirDocumento
                                solicitud={cedulaFron}
                                cambiarEstado={setCedulaFron}
                                estado={cedulaFron}
                                nameFile="cedulaFron"
                            />
                            <Box py={2}>
                                <Typography>DOCUMENTO CEDULA TRASERA</Typography>
                            </Box>

                            <SubirDocumento
                                solicitud={cedulaTra}
                                cambiarEstado={setCedulaTra}
                                estado={cedulaTra}
                                nameFile="cedulaTra"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Box py={2}>
                                <Typography>CARTA DE CESION</Typography>
                            </Box>

                            <SubirDocumento
                                solicitud={cesion}
                                cambiarEstado={setCesion}
                                estado={cesion}
                                nameFile="cesion"
                            />
                            <Box py={2}>
                                <Typography>FOTO CLIENTE DE ACCEPTACION</Typography>
                            </Box>

                            <SubirDocumento
                                solicitud={aceptacion}
                                cambiarEstado={setAceptacion}
                                estado={aceptacion}
                                nameFile="aceptacion"
                            />
                        </Grid>
                    </Grid>


                    <Box display="flex" flexDirection="row-reverse" pt={4}>
                        {
                            !loading ?
                                <Button
                                    type="submit"
                                    disableElevation
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => submitHandler()}
                                    disabled={loading || !cedula2 || !celular.campo ||
                                        !position.lat || !position.lng ||
                                        !telefono.campo || !correo.campo || !geografico.sector
                                        || celular.valido === false || !linea || !aceptacion || !cesion || !cedulaTra || !cedulaFron
                                    }
                                > <strong>Enviar solicitud</strong>
                                </Button>
                                :
                                <LoadingButton
                                    variant="contained"
                                    loading
                                >
                                    Enviar solicitud
                                </LoadingButton>
                        }
                    </Box>
                </>
            }

        </Container>
    )
}