import {
    Container,
    Table,
    Button,
    Typography,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Paper,
    Chip,
    Box,
    Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, storage, firebase, auth } from "../../../firebase";
import moment from 'moment'
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchInput from '../../../components/utils/SearchInput';
import { UpdateItemsEstadoCuenta } from "./IconsButtonItemsEstadoCuenta/UpdateItemsEstadoCuenta";
import { DeleteItemsEstadoCuenta } from "./IconsButtonItemsEstadoCuenta/DeleteItemsEstadoCuenta";

export const ListItemEstadoFinanciero = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth

    function Estado({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 0) {
            name = 'Por agendar'
            color = 'warning'
        } else if (estado === 1) {
            name = 'Rechazada'
            color = 'warning'
        }
        return (
            <Chip label={name} color={color} />
        )
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [nombreZona, setNombreZona] = useState();

    const [codigoZona, setCodigoZona] = useState("");

    const [data, setData] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    const [search, setSearch] = useState('')
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        LoadData()
        console.log(data, 'data')
    }, []);

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("item_estado_financiero");


        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });

    };

    return (
        <>
            <br />
            <Box pt={1} pl={1}>
                <Typography >
                    TOTAL <strong>({data && data.length})</strong> 
                </Typography >
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Box display="flex" alignItems='center' >
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
                <SearchInput setSearch={setSearch} search={search} />
            </Box>

            <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table size="small" stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>DESCRIPCION DE ITEM DE ESTADO FINANCIERO</TableCell>
                                <TableCell>USUARIO CREADOR DE ITEM</TableCell>
                                <TableCell>FECHA CREACION DE ITEM</TableCell>
                                <TableCell>EDITAR</TableCell>
                                <TableCell>ELIMINAR</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.filter((val) => {
                                if (search === '') {
                                    return val
                                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.numFlota.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }
                            }).map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        {row.nombre_item_estado_financiero}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.usuario_creador_item_estado_financiero}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        {row.fecha_creacion_item_estado_financiero && typeof row.fecha_creacion_item_estado_financiero === 'number' &&
                                            moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                        {row.fecha_creacion_item_estado_financiero && typeof row.fecha_creacion_item_estado_financiero === 'object' &&
                                            moment(row.fecha_creacion_item_estado_financiero.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <UpdateItemsEstadoCuenta props={row} />
                                    </TableCell>

                                    <TableCell component="th" scope="row">
                                        <DeleteItemsEstadoCuenta props={row} />
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

