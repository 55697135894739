import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    FormHelperText,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { db, firebase } from '../../../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { ConvertRoles } from '../../../../components/config/ConvertRoles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { Grade } from '@mui/icons-material';

import * as XLSX from "xlsx/xlsx.mjs";
import UploadIcon from "@mui/icons-material/Upload";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import {
    listsBodegas,
    listsUbicaciones,
    listsMotivoIngresoProductos,
} from "../../../../redux/actions/parametersActions";

import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const TransferirMercaderia = () => {

    const [loadingButtonSolicitudTransferencia, setLoadingButtonSolicitudTransferencia] = useState(false);

    const [openConfirmSendSolicitud, setOpenConfirmSendSolicitud] = useState(false);

    const CargaSeriesIngresoProductos = ({
        props,
        bodega,
        codigo,
        numPedido,
    }) => {

        /* esxcel upload */

        const [openUploadExcelSeries, setOpenUploadExcelSeries] = useState(false)

        /* const handleOpenDialogUploadExcel = () => {
            setOpenUploadExcelSeries(true)
            setOpenDialogSelectsOptionUploadSeries(false)
        }
    
        const handleCloseDialogUploadExcel = () => {
            setOpenDialogSelectsOptionUploadSeries(true)
            setOpenUploadExcelSeries(false)
        } */

        const [openDialogExcelSuccess, setOpenDialogExcelSuccess] = useState(false)

        /*  */

        const userAuth = useSelector((state) => state.userAuth);
        const { loadin, userInfo } = userAuth;

        const [open, setOpen] = useState(null);
        //
        const onData = () => {
            setOpen(true);
        };

        const [openIngresoManual, setOpenIngresoManual] = useState(null);

        const onDataIngresoManual = () => {
            setOpenIngresoManual(true);
            setOpen(false);
        };

        const onCloseIngresoManual = () => {
            setOpen(true);
            setOpenIngresoManual(false);
        };

        const [openIngresoSeriesAutomaticas, setOpenIngresoSeriesAutomaticas] =
            useState(null);

        const onDataIngresoSeriesAutomaticas = () => {
            setOpenIngresoSeriesAutomaticas(true);
            setOpen(false);
        };

        const onCloseIngresoSeriesAutomaticas = () => {
            setOpen(true);
            setOpenIngresoSeriesAutomaticas(false);
        };

        const [openIngresoSeriesExcel, setOpenIngresoSeriesExcel] = useState(null);

        const onDataIngresoSeriesExcel = () => {
            setOpenIngresoSeriesExcel(true);
            setOpen(false);
        };

        const onCloseIngresoSeriesExcel = () => {
            setOpen(true);
            setOpenIngresoSeriesExcel(false);
        };

        const [dataExcel, setDataExcel] = useState([]);

        const [file, setFile] = useState(null);
        const [fileName, setFileName] = useState(null);

        const handleUploadFileLocal = async (e) => {
            const file = e.target.files[0];
            setFileName(file.name);
            setFile(file);
        };

        const fileRef = useRef();

        const handleRemove = () => {
            setFileName(null);
            setFile(null);
            fileRef.current.value = "";
        };

        const [openCaja, setOpenCaja] = React.useState(false);

        const [openIngresoErrorCargaSeries, setOpenIngresoErrorCargaSeries] =
            useState(null);

        const onDataIngresoErrorCargaSeries = () => {
            setOpenIngresoErrorCargaSeries(true);
        };

        const onCloseIngresoErrorCargaSeries = () => {
            setOpenIngresoErrorCargaSeries(false);
        };

        /* CARGA EXCEL PARA MOSTARR EN EL GRID */

        const handleUploadFile = async () => {
            try {
                if (file) {
                    const data = await file.arrayBuffer();
                    const workbook = XLSX.read(data);
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);
                    setDataExcel(jsonData);
                    setData(jsonData)
                    setOpenCaja(false)
                } else {
                    console.log("No se cargado ningun archivo");
                }
            } catch (error) {
                console.log("error", error);
            }
        };

        /* CARGA SERIES MANUALES */

        const cargarSeriesManuales = async (data) => {
            //console.log(data, 'data')
            const onSeries = async () => {
                console.log(data, "data");
                console.log(props.series, "data");
                /* const agendaDB2 = await db
                    .collection("reporte_stock")
                    .where("codigo_producto", "==", props.codigo)
                    .where("bodega", "==", bodega)
                    .get();

                const data2 = await agendaDB2.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })); */
                props.series.forEach(async (obj) => {
                    const uidSolicitud = `SO-TRAN-` + numPedido;

                    console.log(uidSolicitud)
                    console.log(obj)
                    console.log(obj.serie)
                    console.log(props.codigo)

                    db
                        .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(codigo).collection('series').doc(obj.serie)
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                        })

                    db
                        .collection('transferencias').doc(uidSolicitud).collection('items')
                        .doc(codigo).collection('series').doc(obj.serie)
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                        })

                    /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 1
                        }) */

                    Swal.fire({
                        icon: "success",
                        title: "Series cargadas con exito",
                    });
                    setOpenIngresoManual(false);
                });
            };
            return (
                <>
                    {props.series.map((row, index) => (
                        <Box pt={1} pb={1}>
                            <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                                <strong>SERIE: {row.id}</strong>
                            </p>
                            <TextField
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { Wtop: 0 },
                                }}
                                size="small"
                                fullWidth
                                name={index}
                                //value={[i]}
                                onChange={(e) => handleSeries(index, e, data)}
                            />
                        </Box>
                    ))}
                    <Button onClick={() => onCloseIngresoManual()}>
                        <strong>Cancelar</strong>
                    </Button>
                    <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={() => onSeries()}
                    >
                        CREAR SERIES
                    </Button>
                </>
            );
        };

        var automaticSeries = [];

        /* CARGA SERIES AUTOMATICAS */

        const [loadingButtonSeriesAutomaticas, setLoadingButtonSeriesAutomaticas] = useState(false);

        const cargarSeriesManualesAutomaticas = () => {

            const uidSolicitud = `SO-TRAN-` + numPedido;
            /* console.log(resultado) */
            var fieldsArray = [];

            for (var i = 1; i <= props.cantidad_pedida; i++) {
                const resultado = Math.floor(
                    Math.pow(10, 20 - 1) +
                    Math.random() * (Math.pow(10, 20) - Math.pow(10, 5 - 1) - 1)
                );

                automaticSeries.push({ serie: resultado })

                function createData(serie) {
                    return { serie };
                }

                var rows = [createData(resultado)];

                /* setAddSeriesAutomaticasArray(createData(resultado)) */

                fieldsArray.push(
                    <>
                        <Card>
                            <CardContent
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                }}
                            >
                                <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table size="small" stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>SERIE</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {i}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.serie}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </>
                );
            }

            /* FUNCION PARA CARGAR LAS SERIES AUTOMATICAS */

            const uploadSeries = () => {
                setLoadingButtonSeriesAutomaticas(true)
                /* const uidSolicitud = `ING-` + numPedido;
                console.log(codigo)
                console.log(props.codigo) */
                automaticSeries.forEach(async (obj) => {
                    console.log(obj)
                    db
                        .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 0
                        })

                    db
                        .collection(`ingreso_productos/${uidSolicitud}/items`)
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 0
                        })

                    /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 1
                        }) */

                    Swal.fire({
                        icon: "success",
                        title: "Series cargadas con exito",
                    });
                    setOpenIngresoSeriesAutomaticas(false);

                })
            }

            return (
                <>
                    {fieldsArray}
                    <Button onClick={() => onCloseIngresoSeriesAutomaticas()}>
                        <strong>Cancelar</strong>
                    </Button>
                    <LoadingButton
                        color="success"
                        size="small"
                        onClick={() => uploadSeries()}
                        loading={loadingButtonSeriesAutomaticas}
                        endIcon={<SendIcon />}
                        variant="contained"
                        disabled={loadingButtonSeriesAutomaticas === true}
                        loadingPosition="end"
                    >
                        {" "}
                        CREAR SERIES
                    </LoadingButton>
                </>
            );
        };

        /* FUNCION QUE SUBE LAS SERIES A FIREBASE */

        const SubirExcelFirebase = async () => {
            console.log(bodega);
            console.log(codigo);
            console.log(dataExcel.length);
            console.log(props.cantidad_pedida);
            /* cantidad_pedida */

            const agendaDB2 = await db
                .collection("reporte_stock")
                .where("codigo_producto", "==", codigo)
                .where("bodega", "==", bodega)
                .get();

            const data2 = await agendaDB2.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            /* console.log(data2[0].id) */

            {
                parseFloat(data.length) != parseFloat(props.cantidad_pedida)
                    ? onDataIngresoErrorCargaSeries()
                    : data.forEach((obj) => {
                        const uidSolicitud = `SO-TRAN-` + numPedido;

                        db
                            .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                            .doc(codigo).collection('series').doc(obj.SERIE)
                            .set({
                                serie:
                                    obj.SERIE === "" ||
                                        obj.SERIE === null ||
                                        obj.SERIE === undefined
                                        ? ""
                                        : obj.SERIE,
                                created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                usuario_que_cargo_serie: userInfo.displayName,
                            })

                        /* db.collection('reporte_movimientos').doc(uidSolicitud).collection('series').doc(obj.codigo).set({
                          serie: obj.SERIE === "" ||
                            obj.SERIE === null ||
                            obj.SERIE === undefined
                            ? ""
                            : obj.SERIE,
                          created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                          usuario_que_cargo_serie: userInfo.displayName,
                        }) */

                        db
                            .collection(`ingreso_productos/${uidSolicitud}/items`)
                            .doc(codigo).collection('series').doc(obj.SERIE)
                            .set({
                                serie:
                                    obj.SERIE === "" ||
                                        obj.SERIE === null ||
                                        obj.SERIE === undefined
                                        ? ""
                                        : obj.SERIE,
                                created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                usuario_que_cargo_serie: userInfo.displayName,
                            })

                        /* db.collection('ingreso_productos').doc(uidSolicitud).collection('series').doc(obj.codigo).set({
                          serie: obj.SERIE === "" ||
                            obj.SERIE === null ||
                            obj.SERIE === undefined
                            ? ""
                            : obj.SERIE,
                          created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                          usuario_que_cargo_serie: userInfo.displayName,
                        }) */



                        Swal.fire({
                            icon: "success",
                            title: "Series cargadas con exito",
                        });
                        setOpenIngresoSeriesExcel(false);
                    });
            }
        };

        const [data, setData] = useState([]);

        const [total, setTotal] = useState(0);

        function CustomFooterTotalComponent(props) {
            console.log(props)
            return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
        }

        const columns = [
            { field: 'SERIE', headerName: 'SERIE', width: 180 },
        ];

        const CustomToolbar = (props) => (
            <GridToolbarContainer {...props}>
                <GridToolbarFilterButton />
                {/* <GridToolbarQuickFilter /> */}

            </GridToolbarContainer>
        );


        return (
            <>
                {dataExcel === "" || dataExcel === undefined || dataExcel === null ? (
                    <Tooltip title="INGRESAR SERIES">
                        <Button onClick={() => onData()}>
                            <QrCodeScannerIcon />
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title="INGRESAR SERIES">
                        <Button onClick={() => onData()}>
                            <QrCodeScannerIcon color="success" />
                        </Button>
                    </Tooltip>
                )}

                {/* LISTA DE OPCIONES */}

                <Dialog
                    open={open}
                    fullWidth
                    onClose={() => setOpen(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>SERIES PERTENECIENTES A: {props.codigo}</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Box pt={1}>
                            <FormControl fullWidth>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            /* onClick={() => onDataIngresoSeriesExcel()} */
                                            /* onClick={() => window.open('/#/inventario/carga-series/', '_blank')} */
                                            onClick={() => onDataIngresoSeriesExcel()}
                                            /* component={RouterLink}
                                            to={`/inventario/carga-series/`} */
                                            size="small"
                                            color="success"
                                            variant="outlined"
                                            startIcon={<FileUploadIcon />}
                                        >
                                            CARGAR EXCEL
                                        </Button>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                        <Button
                          fullWidth
                          size="small"
                          color="info"
                          variant="outlined"
                          startIcon={<FormatAlignCenterIcon />}
                        >
                          CARGAR TXT
                        </Button>
                      </Grid> */}

                                    <Grid item xs={6}>
                                        {/* <CargaManualSeries props={props} /> */}
                                        <Button
                                            onClick={() => onDataIngresoManual()}
                                            fullWidth
                                            size="small"
                                            color="secondary"
                                            variant="outlined"
                                            startIcon={<FolderSharedIcon />}
                                        >
                                            CARGA MANUAL
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            onClick={() => onDataIngresoSeriesAutomaticas()}
                                            fullWidth
                                            size="small"
                                            color="warning"
                                            variant="outlined"
                                            startIcon={<AllInclusiveIcon />}
                                        >
                                            CARGA SERIES AUTOMATICAS
                                        </Button>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => setOpen(false)}
                        >
                            <strong>Cancelar</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* DIALOG INGRESO MANUAL */}

                <Dialog
                    open={openIngresoManual}
                    fullWidth
                    onClose={() => setOpenIngresoManual(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES MANUAL {props.codigo}</strong>
                    </DialogTitle>
                    <DialogContent dividers>{cargarSeriesManuales(props)}</DialogContent>
                </Dialog>

                {/* DIALOG INGRESO SERIES AUTOMATICAS */}

                <Dialog
                    open={openIngresoSeriesAutomaticas}
                    fullWidth
                    onClose={() => setOpenIngresoSeriesAutomaticas(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES AUTOMATICAS</strong>
                    </DialogTitle>
                    <DialogContent>{cargarSeriesManualesAutomaticas()}</DialogContent>
                </Dialog>

                {/* DIALOG INGRESO SERIES MEDIANTE EXCEL */}

                <Dialog
                    open={openIngresoSeriesExcel}
                    fullWidth
                    onClose={() => setOpenIngresoSeriesExcel(false)}
                    maxWidth="md"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES MEDIANTE EXCEL</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Card>
                            <CardContent>
                                <Button
                                    color="success"
                                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                                    size="small"
                      /* onClick={() => handleUploadFile()} */ onClick={() =>
                                        setOpenCaja(true)
                                    }
                                    variant="outlined"
                                    startIcon={<UploadIcon />}
                                >
                                    SUBIR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                                        <DataGrid
                                            density="compact"
                                            rows={data ? data : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            // disableColumnFilter
                                            // disableColumnSelector
                                            // disableDensitySelector
                                            //hideFooterPagination
                                            columns={columns}
                                            getRowId={(row) => row.SERIE}
                                            components={{
                                                Toolbar: CustomToolbar,
                                                Footer: CustomFooterTotalComponent,

                                            }}
                                            onStateChange={(state) => {
                                                console.log(state.pagination.rowCount);
                                                setTotal(state.pagination.rowCount)
                                            }}
                                        />
                                    </Box>
                                </Paper>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => onCloseIngresoSeriesExcel()}
                        >
                            <strong>Cancelar</strong>
                        </Button>
                        <Button
                            /* disabled={dataExcel === ""} */
                            disabled={data.length === 0}
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => SubirExcelFirebase()}
                        >
                            <strong>SUBIR EXCEL</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* SUBIR EXCEL */}
                <Dialog
                    open={openCaja}
                    onClose={() => setOpenCaja(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Subir Excel</DialogTitle>
                    <DialogContent>
                        <Box pb={2}>
                            {fileName
                                ? `Nombre el archivo cargado:  ${fileName}`
                                : "Por favor, cargar el archivo..."}
                        </Box>
                        {!file ? (
                            <>
                                <input
                                    style={{ display: "none" }}
                                    onChange={(e) => handleUploadFileLocal(e)}
                                    multiple
                                    id="buttonFile"
                                    type="file"
                                    ref={fileRef}
                                />
                                <label htmlFor="buttonFile">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        component="span"
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </>
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box pr={5}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={(e) => handleRemove()}
                                        >
                                            Borrar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={() => handleUploadFile()}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Subir archivo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                </Dialog>

                {/* ERROR INGRESO SERIES */}

                <Dialog
                    open={openIngresoErrorCargaSeries}
                    fullWidth
                    onClose={() => setOpenIngresoErrorCargaSeries(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>
                            LA CANTIDAD DE SERIES QUE INTENTA SUBIR NO ES IGUAL A LA CANTIDAD
                            SOLICITADA
                        </strong>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => onCloseIngresoErrorCargaSeries()}
                        >
                            <strong>VOLVER</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* DIALOG UPLOAD EXCEL */}

                <Dialog
                    open={openUploadExcelSeries}
                    fullWidth
                    onClose={() => setOpenUploadExcelSeries(false)}
                    maxWidth="md"
                >
                    <DialogTitle>
                        <strong>SUBA SU SERIES MEDIANTE EXCEL</strong>
                    </DialogTitle>
                    <DialogContent>

                        <Box >
                            {fileName
                                ? `Nombre el archivo cargado:  ${fileName}`
                                : "Por favor, cargar el archivo..."}
                        </Box>

                        {!file ? (
                            <>
                                <input
                                    style={{ display: "none" }}
                                    onChange={(e) => handleUploadFileLocal(e)}
                                    multiple
                                    id="buttonFile"
                                    type="file"
                                    ref={fileRef}
                                />
                                <label htmlFor="buttonFile">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        component="span"
                                        size='small'
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </>
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box pr={2}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={(e) => handleRemove()}
                                        >
                                            Borrar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={handleUploadFile}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Subir archivo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        {/* <Button variant='outlined' size='small' color='success'>
                            Cargar series
                        </Button> */}

                        <Paper>
                            <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    getRowId={(row) => row.SERIE}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,

                                    }}
                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => setOpenUploadExcelSeries(false)}
                        >
                            <strong>Regresar</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    };

    const [fechaDocumento, setFechaDocumento] = useState('');

    const [observacionAdicional, setObservacionAdicional] = useState("");

    const bodegasList = useSelector((state) => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList;
    const [bodega, setBodegas] = useState("");
    const [bodegaDestino, setBodegaDestino] = useState("");



    let navigate = useNavigate()
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [fecha, setFecha] = useState({ campo: new Date, valido: true })
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState({ campo: '', valido: true })
    const [tipo_material, setTipoMaterial] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [numEgreso, setNumEgreso] = useState(false)
    const [productos, setProductos] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    //
    const [modal, setModal] = useState(false)
    //
    //
    const [inputFields, setInputFields] = useState([
    ]);

    //OPCIONES DE CARGA DE SERIES

    const [openDialogSelectsOptionUploadSeries, setOpenDialogSelectsOptionUploadSeries] = useState(false)

    /* MODAL CARGA SERIES MEDIANTE EXCEL */

    const [openUploadExcelSeries, setOpenUploadExcelSeries] = useState(false)

    const handleOpenDialogUploadExcel = () => {
        setOpenUploadExcelSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadExcel = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadExcelSeries(false)
    }

    const [openDialogExcelSuccess, setOpenDialogExcelSuccess] = useState(false)

    /* MODAL CARGA SERIES MANUALES */

    const [openUploadManualSeries, setOpenUploadManualSeries] = useState(false)

    const handleOpenDialogUploadManual = () => {
        setOpenUploadManualSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadManual = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadManualSeries(false)
    }

    /* MODAL CARGA SERIES AUTOMATICAS */

    const [openUploadAutomaticasSeries, setOpenUploadAutomaticasSeries] = useState(false)

    const handleOpenDialogUploadAutomaticas = () => {
        setOpenUploadAutomaticasSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadAutomaticas = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadAutomaticasSeries(false)
    }

    //
    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }
    const addInput = async (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else if (parseFloat(row.stock_actual) <= 0) {
            Swal.fire({ icon: "info", text: 'No existe stock disponible', });
        } else {
            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", row.codigo_producto).get();

            const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 0,
                descripcion: row.descripcion,
                codigo: row.codigo_producto,
                categoria_producto: row.categoria,
                subcategoria_producto: data4[0].subcategoria_producto,
                marca_producto: data4[0].marca_producto,
                modelo_producto: data4[0].modelo_producto,
                presentacion_producto: data4[0].presentacion_producto,
                unidad_Medida_Producto: data4[0].unidad_Medida_Producto,
                stock_actual: row.stock_actual,
                series: [{ serie: 0 }],
            }])
        }
        setItem('')
        setModal(false)
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = async (index, evnt) => {
        console.log(inputFields)
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                //
                setInputFields(list);
                //Cantidad
                //console.log(evnt.target.value, 'cantidad')
                const num = evnt.target.value;
                let array = [];
                for (var i = 0; i < num; i++) {
                    //console.log('Hola')
                    let n;
                    n += i;
                    //console.log('for', n)
                    array.push({
                        index_serie: index,
                        id: i,
                        serie: 0,
                    });
                }
                list[index]["series"] = array;
                //
                console.log(list, "--");
                //console.log(inputFields, 'array')
            } else {
                const list = [...inputFields];
                list[index]["fullName"] = evnt;
                setInputFields(list);
            }
        }
    };

    const getProductos = (e) => {
        let ref = db.collection("reporte_stock").where('bodega', '==', e)

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setProductos(data)
        })
        /* var docRef = db.collection("reporte_stock").where('bodega', '==', bodega)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
    }

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        dispatch(listsBodegas());
    }, []);

    const handleSeries = async (index, evnt, row) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                let index_serie = row.series[0].index_serie;
                let array = {
                    id: index,
                    index_serie: index_serie,
                    serie: value.toUpperCase(),
                };
                list[index_serie]["series"][index] = array;
            } else {
                const list = [...inputFields];
                list[index]["fullName"] = evnt;
                setInputFields(list);
            }
        }
        //console.log('input files', inputFields)
    };

    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            var docRef = db.collection("productos").where("codigoProducto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcionProducto
                    console.log(data.codigo)
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
            console.log(inputFields, 'da')
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>

                <TableCell align="center">
                    {data.fullName}
                </TableCell>

                <TableCell align="center">{data.descripcion}</TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.categoria_producto}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.subcategoria_producto}
                </TableCell>

                <TableCell align="center">
                    <TextField
                        name={"cantidad_pedida"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>

                <TableCell align="center">
                    {/* <CargaSeriesIngresoProductos props={data} bodega={bodega} codigo={data.codigo} /> */}
                    {/* {bodega === "" ? (
                        <Tooltip title="SELECCIONE UNA BODEGA">
                            <Button disabled>
                                <QrCodeScannerIcon
                                    onClick={() => {
                                        console.log(data.cdogio);
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <CargaSeriesIngresoProductos
                            props={data}
                            bodega={bodega}
                            codigo={data.codigo}
                            numPedido={numPedido}
                        />
                    )} */}

                    <UploadSeriesFunction props={data} codigo={data.codigo} />

                </TableCell>

                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    //
    useEffect(() => {
        /* getProductos() */
        console.log(productos, 'productos')
    }, []);
    //
    const loadNumPedido = () => {
        const ref = db.collection("transferencias").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `SO-TRAN-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const loadNunEgreso = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `SO-TRAN-` + numeroSolicitud;
                setNumEgreso(numeroSolicitud)
            }
        })
    }

    const handleSubmit = () => {

        //
        const uidSolicitud = `SO-TRAN-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            /* rol: rol, */
            created: firebase.firestore.FieldValue.serverTimestamp(),
            fecha_documento: fechaDocumento
        }
        console.log(data, 'DATA')
        console.log(inputFields, 'INPUT FIELDS')

        console.log(bodegaDestino, 'BODEGA DESTINO')
        //

        inputFields.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", bodegaDestino).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", bodega).get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            db.collection('transferencias').doc(uidSolicitud).set({
                subcategoria_producto: obj.subcategoria_producto,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega_origen: bodega,
                bodega_destino: bodegaDestino,
                numeroSolicitud: numPedido,
                uidSolicitud: uidSolicitud,
                fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_transfiere: userInfo.displayName,
                estado: 0,
                motivo: 'TRANSFERENCIA',
                observacion: observacionAdicional === '' ? 'SIN OBSERVACIONES' : observacionAdicional,
                fecha_documento: fechaDocumento
            })

            db.collection('transferencias').doc(uidSolicitud).collection('items').doc(obj.codigo).set({
                subcategoria_producto: obj.subcategoria_producto,
                codigo: obj.codigo,
                bodega_origen: bodega,
                bodega_destino: bodegaDestino,
                numeroSolicitud: numPedido,
                uidSolicitud: uidSolicitud,
                fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_transfiere: userInfo.displayName,
                descripcion: obj.descripcion,
                cantidad_transferencia: parseInt(obj.cantidad_pedida),
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                fecha_documento: fechaDocumento
            })

            if (inputFields.length >= 0 && inputFields.length <= 8) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 50000);
            } else if (inputFields.length >= 9 && inputFields.length <= 18) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 66000);
            } else if (inputFields.length >= 19 && inputFields.length <= 30) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 99000);
            } else if (inputFields.length >= 31 && inputFields.length <= 40) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 115000);
            } else if (inputFields.length >= 41 && inputFields.length <= 50) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 140000);
            } else if (inputFields.length >= 51) {
                Swal.fire({
                    title: 'Se estan cargando todos los datos',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                setTimeout(() => {

                    Swal.fire({
                        title: 'Estamos cargando sus datos de solicitud',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                    setTimeout(() => {
                        window.location.reload();
                    }, 8000);

                }, 260000);
            }

            /* Swal.fire({
                title: 'Se esta creando su solicitud de transferencia',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos de solicitud',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/productos/transferencias/aprobar-transferencias`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 20000); */

        });
    }

    useEffect(() => {
        loadNumPedido()
        loadNunEgreso()
    }, [inputFields])

    /* UPLOAD EXCEL */

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [data, setData] = useState();

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData)

                /* FOR EACH DE LA DATA DEL EXCEL DE CLIENTES */

                const uidSolicitud = `SO-TRAN-` + numPedido;

                jsonData.forEach((obj) => {

                    console.log(obj)

                    db.collection('transferencias').doc(uidSolicitud).collection('series').doc(obj.SERIE).set({
                        serie: obj.SERIE,
                        usuario_que_ingresa_serie: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                });

                let timerInterval
                if (jsonData.length <= 200) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 8000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 400) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 16000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 600) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 24000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 1500) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 40000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                }
                setOpenUploadExcelSeries(false);
                /* Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false); */
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            /* setMessage(error.toString()); */
        }
    };

    /* GRID TABLE UI */

    const [total, setTotal] = useState(0);

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'SERIE', headerName: 'SERIE', width: 180 },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );


    /* FUNCION PARA SELECCIONAR QUE TIPO DE INGRESO DE SERIES SE QUIERE REALIZAR */

    const UploadSeriesFunction = ({ props, codigo }) => {

        const [inputFieldsSeries, setInputFieldsSeries] = useState([]);
        const [seriesList, setSeriesList] = useState([]);
        const [series, setSeries] = useState([]);
        const [seriesAutomaticas, setSeriesAutomaticas] = useState([]);

        const [modalSelectedSeries, setModalSelectedSeries] = useState(false)

        const [openListSeriesAEgresar, setOpenListSeriesAEgresar] = useState(false)

        const [openSelectMetodoAEgresar, setOpenSelectMetodoAEgresar] = useState(null)

        const [openListSeriesAEgresarAutomaticas, setOpenListSeriesAEgresarAutomaticas] = useState(false)

        const uploadSeriesManuales = () => {

            const uidSolicitud = `SO-TRAN-` + numPedido;

            const uidSolicitudEgreso = `EGR-` + numEgreso;

            inputFieldsSeries.forEach(async function (obj, index) {

                console.log('inputFieldsSeries', obj)

                console.log('inputFieldsSeries', obj.serie)

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                db.collection(`egreso_productos/${uidSolicitudEgreso}/items`)
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                db
                    .collection('transferencias').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie.toString(),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                    .update({
                        estado: 1
                    }) */

            })

            setOpenListSeriesAEgresarAutomaticas(false)
            setOpenListSeriesAEgresar(false)

            Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            });

        }

        const [itemSerie, setItemSerie] = useState('')

        const uploadSeriesAutomaticas = () => {

            const uidSolicitud = `SO-TRAN-` + numPedido;

            const uidSolicitudEgreso = `EGR-` + numEgreso;

            seriesAutomaticas.forEach(async function (obj, index) {

                console.log('seriesAutomaticas', obj)

                console.log('seriesAutomaticas', obj.serie)

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                db.collection(`egreso_productos/${uidSolicitudEgreso}/items`)
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                db.collection('transferencias').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie.toString(),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie)
                    .update({
                        estado: 1
                    }) */

            })



            Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            });

            setOpenListSeriesAEgresarAutomaticas(false)



        }

        /* ADD SERIES */

        const addInputSeries = (row) => {
            console.log(row, 'row')
            if (inputFieldsSeries.find(x => x.serie === row.serie)) {
                Swal.fire({ icon: "info", text: 'Ya se agrego esta serie a la lista', });
            } else {
                setInputFieldsSeries([...inputFieldsSeries, {
                    serie: row.serie
                }])
            }
            setModalSelectedSeries(false)
            /* setItem('')
            setModal(false) */
        }

        const removeInputFieldsSeries = (index) => {
            const rows = [...inputFieldsSeries];
            rows.splice(index, 1);
            setInputFieldsSeries(rows);
        }

        function detalleMateSeries(data, index) {
            return (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell align="center">
                        {data.serie}
                    </TableCell>

                    <TableCell align="center">
                        <IconButton onClick={() => removeInputFieldsSeries(index)} color='error'>
                            <DeleteForeverIcon />
                        </IconButton>
                    </TableCell>

                </TableRow>
            )
        }

        const getSeries = async (row) => {
            console.log('ROW SERIES', row)
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.fullName).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)
            docRef.get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage)
                setSeries(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const getSeriesAutomaticas = async (row) => {
            console.log('ROW SERIES AUTOMATICAS', row)
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.fullName).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)
            docRef.limit(parseFloat(row.cantidad_pedida)).get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage)
                setSeriesAutomaticas(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const onData = () => {
            getSeries(props)
            getSeriesAutomaticas(props)
            setOpenSelectMetodoAEgresar(true)
        };

        return (
            <>
                {/* SELECCIONAR METODO DE EGRESAR SERIES */}

                <Tooltip title="INGRESAR SERIES">
                    <Button onClick={() => onData()}>
                        <QrCodeScannerIcon />
                    </Button>
                </Tooltip>


                <Dialog
                    open={openSelectMetodoAEgresar}
                    maxWidth='xs'
                    fullWidth
                    onClose={() => setOpenSelectMetodoAEgresar(false)}
                >
                    <DialogTitle>
                        METODO A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Grid container rowSpacing={2} columnSpacing={2}>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresar(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='primary'>Seleccion manual</Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresarAutomaticas(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='secondary'>Seleccion automatica</Button>
                            </Grid>

                        </Grid>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='primary' onClick={() => setOpenSelectMetodoAEgresar(false)}>
                            REGRESAR
                        </Button>
                    </DialogActions>

                </Dialog>


                {/* LISTA DE SERIES A EGRESAR */}

                <Dialog
                    open={openListSeriesAEgresar}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Box pb={1}>
                            <Button disabled={props.cantidad_pedida <= inputFieldsSeries.length} onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        inputFieldsSeries.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresar(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={inputFieldsSeries.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesManuales()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>

                {/* LISTA PARA SELECCIONAR Y BUSCAR LAS SERIES A EGRESAR */}

                <Dialog
                    open={modalSelectedSeries}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        AGREGAR ITEMS
                    </DialogTitle>
                    <DialogContent dividers >
                        <Box pb={3}>
                            <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={series}
                                clearOnBlur={false}
                                autoComplete={false}
                                disableClearable
                                getOptionLabel={(option) => option.serie}
                                sx={{ width: 300 }}
                                onInputChange={(e, newInputValue) => {
                                    console.log(itemSerie)
                                    setItemSerie(newInputValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="BUSCAR SERIE" />}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SERIES</TableCell>
                                        <TableCell>ACCIONES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {series && series
                                        .filter((val) => {
                                            if (itemSerie === "") {
                                            } else if (val.serie.toLowerCase().includes(itemSerie.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow
                                                key={row.serie}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.serie}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => /* blockButtonAddItem */addInputSeries(row)}
                                                    >Agregar</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions dividers>
                        <Button size='small' variant='contained' onClick={() => setModalSelectedSeries(false)}>
                            Cerrar Ventana
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* LISTA DE SERIES A EGRESAR AUTOMATICAS */}

                <Dialog
                    open={openListSeriesAEgresarAutomaticas}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        {/* <Box pb={1}>
                            <Button onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box> */}

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        seriesAutomaticas.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresarAutomaticas(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={seriesAutomaticas.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesAutomaticas()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>
            </>
        )

    }

    const verificacionDeStaock = () => {
        const cantidadesValidas = inputFields.some(item => item.cantidad_pedida > item.stock_actual);
        console.log('cantidadesValidas', cantidadesValidas)
        if (cantidadesValidas) {
            console.log('Al menos una cantidad ingresada es mayor que la cantidad disponible para un item.');
            Swal.fire({ icon: "warning", text: `Se ha detectado que algunas cantidades que desea egresar son mayores al stock actual` })
        } else {
            handleButtonClick()
        }
    }

    const handleButtonClick = async () => {

        setLoadingButtonSolicitudTransferencia(true)

        console.log('inputFields', inputFields)
        try {
            const solicitudTransferenciaProductos = firebase.functions().httpsCallable("solicitudTransferenciaProductos");

            const data = {
                numPedido: numPedido,
                userInfo: userInfo,
                inputFields: inputFields,
                bodega: bodega,
                bodegaDestino: bodegaDestino,
                observacionAdicional: observacionAdicional,
                fechaDocumento: fechaDocumento/* firebase.firestore.Timestamp.fromDate(fechaDocumento) */
            };

            const response = await solicitudTransferenciaProductos(data);

            setOpenConfirmSendSolicitud(false)

            Swal.fire({ icon: "success", text: "Se realizo la solicitud de transferencia correctamente", });

            setTimeout(() => {
                navigate(`/inventario/productos/transferencias/aprobar-transferencias`)
            }, 3000);

            console.log(response.data.message); // Mensaje de éxito de la Cloud Function
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpenConfirmSendSolicitud(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    };

    return (
        <>
            <Box py={1} pl={2}>
                <Typography>
                    NUEVA SOLICITUD
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <Typography gutterBottom><strong>NUMERO DE REFERENCIA</strong></Typography>
                                <Typography>SO-TRAN-{numPedido}</Typography>
                                <Typography gutterBottom><strong>FECHA DE CREACION</strong></Typography>
                                <Typography>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                                <Divider />
                            </Grid>
                            <Grid item md={6}>
                                <Typography gutterBottom><strong>USUARIO</strong></Typography>
                                <Typography>{userInfo.displayName}</Typography>
                                <Typography gutterBottom><strong>ROL</strong></Typography>
                                <ConvertRoles id={userInfo.rol} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <Box py={2}>
                                    <FormControl fullWidth>
                                        <Typography gutterBottom style={{ fontSize: "14px" }}>
                                            <strong>BODEGA ORIGEN</strong>
                                        </Typography>
                                        <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            size="small"
                                            value={bodega}
                                            label="MOTIVO"
                                            onChange={(e) => {
                                                setBodegas(e.target.value)
                                                getProductos(e.target.value)
                                            }}
                                        >
                                            {bodegas &&
                                                bodegas.map((item) => (
                                                    <MenuItem
                                                        key={item.nombre_bodega}
                                                        value={item.nombre_bodega}
                                                    >
                                                        {item.nombre_bodega}
                                                    </MenuItem>
                                                ))}
                                        </Select>

                                    </FormControl>
                                </Box>

                                <FormControl fullWidth>
                                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                                        <strong>FECHA DE DOCUMENTO</strong>
                                    </Typography>

                                    <Box pb={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                                renderInput={(props) => <TextField sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }} fullWidth {...props} size='small' />}
                                                inputFormat="dd/MM/yyyy"
                                                value={fechaDocumento}
                                                onChange={(newValue) => {
                                                    setFechaDocumento(newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </FormControl>

                                {/* <FormControl fullWidth>
                                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                                        <strong>OBSERVACION ADICIONAL</strong>
                                    </Typography>
                                    <TextField
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        value={observacionAdicional}
                                        onChange={(e) => setObservacionAdicional(e.target.value)}
                                        size="small"
                                        fullWidth
                                    />
                                </FormControl> */}




                            </Grid>

                            <Grid item md={6}>
                                <Box py={2}>
                                    <FormControl fullWidth>
                                        <Typography gutterBottom style={{ fontSize: "14px" }}>
                                            <strong>BODEGA DESTINO</strong>
                                        </Typography>
                                        <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            size="small"
                                            value={bodegaDestino}
                                            label="MOTIVO"
                                            onChange={(e) => setBodegaDestino(e.target.value)}
                                        >
                                            {bodegas &&
                                                bodegas.map((item) => (
                                                    <MenuItem
                                                        key={item.nombre_bodega}
                                                        value={item.nombre_bodega}
                                                    >
                                                        {item.nombre_bodega}
                                                    </MenuItem>
                                                ))}
                                        </Select>

                                    </FormControl>
                                </Box>

                                <FormControl fullWidth>
                                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                                        <strong>OBSERVACION ADICIONAL</strong>
                                    </Typography>
                                    <TextField
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        value={observacionAdicional}
                                        onChange={(e) => setObservacionAdicional(e.target.value)}
                                        size="small"
                                        fullWidth
                                    />
                                </FormControl>

                                {/* <Box pb={2}>
                                    <FormControl fullWidth>
                                        <Typography gutterBottom style={{ fontSize: "14px" }}>
                                            <strong>MOTIVO</strong>
                                        </Typography>
                                        <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            size="small"
                                            value={motivoIngresoProducto}
                                            label="MOTIVO"
                                            onChange={(e) => setMotivoIngresoProducto(e.target.value)}
                                        >
                                            {motivoIngresoProductos &&
                                                motivoIngresoProductos.map((item) => (
                                                    <MenuItem
                                                        key={item.nombre_motivo_ingreso_producto}
                                                        value={item.nombre_motivo_ingreso_producto}
                                                    >
                                                        {item.nombre_motivo_ingreso_producto}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {loadingMotivoIngresoProductos && <LinearProgress />}
                                    </FormControl>
                                </Box> */}
                            </Grid>
                        </Grid>

                        <Box display="flex" flexDirection="row-reverse" py={1}>
                            <Button
                                variant='outlined'
                                disabled={bodega === '' || bodegaDestino === '' || bodega === bodegaDestino}
                                onClick={() => setModal(true)}>
                                Agregar Items
                            </Button>
                        </Box>

                        <Dialog
                            open={modal}
                            maxWidth='md'
                            fullWidth
                        >
                            <DialogTitle>
                                AGREGAR ITEMS
                            </DialogTitle>
                            <DialogContent dividers >
                                <Box pb={3}>
                                    <Autocomplete
                                        disablePortal
                                        size="small"
                                        id="combo-box-demo"
                                        options={productos}
                                        clearOnBlur={false}
                                        autoComplete={false}
                                        disableClearable
                                        getOptionLabel={(option) => option.descripcion}
                                        sx={{ width: 300 }}
                                        onInputChange={(e, newInputValue) => {
                                            console.log(item)
                                            setItem(newInputValue)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                                    />
                                </Box>
                                <TableContainer component={Paper}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>CODIGO</TableCell>
                                                <TableCell>DESCRIPCION</TableCell>
                                                <TableCell>CANTIDAD</TableCell>
                                                <TableCell>ACCIONES</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productos && productos
                                                .filter((val) => {
                                                    if (item === "") {
                                                        return val;
                                                    } else if (val.descripcion.toLowerCase().includes(item.toLowerCase())) {
                                                        return val;
                                                    }
                                                }).map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.codigo_producto}</TableCell>
                                                        <TableCell>{row.descripcion}</TableCell>
                                                        <TableCell>{row.stock_actual}</TableCell>
                                                        <TableCell><Button
                                                            variant='contained'
                                                            color='success'
                                                            onClick={() => addInput(row)}
                                                        >Agregar</Button></TableCell>
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box py={5} ></Box>
                            </DialogContent>
                            <DialogActions dividers>
                                <Button onClick={() => setModal(false)}>
                                    Cerrar ventana
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Typography gutterBottom>DETALLE DE MATERIALES {item}</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center">CODIGO</TableCell>
                                        <TableCell align="center">DESCRIPCION</TableCell>
                                        <TableCell align="center">CATEGORIA</TableCell>
                                        <TableCell align="center">SUBCATEGORIA</TableCell>
                                        <TableCell align="center">CANT PEDIDA</TableCell>
                                        <TableCell align="center">SERIES</TableCell>
                                        <TableCell align="center">ACCIONES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        inputFields.map((data, index) => {
                                            const { cantidad_pedida, codigo, descripcion } = data;
                                            return (
                                                detalleMate(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider />
                        <Box display="flex" flexDirection="row-reverse" pt={1}>
                            <LoadingButton
                                loading={loadingButtonSolicitudTransferencia}
                                endIcon={loadingButtonSolicitudTransferencia === true ? <SendIcon /> : ''}
                                loadingPosition="end"
                                /* component={RouterLink} */
                                variant='contained'
                                disabled={inputFields.length <= 0 || fechaDocumento === ''}
                                /* type="submit" */
                                onClick={() => setOpenConfirmSendSolicitud(true)/* handleSubmit() */}
                            /* to={`/inventario/productos/transferencias/aprobar-transferencias`} */
                            >Enviar solicitud</LoadingButton>
                        </Box>
                    </form>
                </CardContent>
            </Card>

            {/* SELECCIONAR LA OPCION DE CARGA DE SERIES */}

            <Dialog
                open={openDialogSelectsOptionUploadSeries}
                fullWidth
                onClose={() => setOpenDialogSelectsOptionUploadSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SELECCIONE UNA OPCION DE CARGA {/* PERTENECIENTES A: {props.codigo} */}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box pt={1}>
                        <FormControl fullWidth>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={() => handleOpenDialogUploadExcel()}
                                        size="small"
                                        color="success"
                                        variant="outlined"
                                        startIcon={<FileUploadIcon />}
                                    >
                                        CARGAR EXCEL
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size="small"
                                        color="info"
                                        variant="outlined"
                                        startIcon={<FormatAlignCenterIcon />}
                                    >
                                        CARGAR TXT
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        onClick={() => handleOpenDialogUploadManual()}
                                        fullWidth
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        startIcon={<FolderSharedIcon />}
                                    >
                                        CARGA MANUAL
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        onClick={() => handleOpenDialogUploadAutomaticas()}
                                        fullWidth
                                        size="small"
                                        color="warning"
                                        variant="outlined"
                                        startIcon={<AllInclusiveIcon />}
                                    >
                                        CARGA SERIES AUTOMATICAS
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpenDialogSelectsOptionUploadSeries(false)}
                    >
                        <strong>Cancelar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES EXCEL */}

            <Dialog
                open={openUploadExcelSeries}
                fullWidth
                onClose={() => setOpenUploadExcelSeries(false)}
                maxWidth="md"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES MEDIANTE EXCEL</strong>
                </DialogTitle>
                <DialogContent>

                    <Box >
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>

                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                    size='small'
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={2}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {/* <Button variant='outlined' size='small' color='success'>
                        Cargar series
                    </Button> */}

                    <Paper>
                        <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                // disableColumnFilter
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                getRowId={(row) => row.SERIE}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,

                                }}
                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadExcel()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES MANUALES */}

            <Dialog
                open={openUploadManualSeries}
                fullWidth
                onClose={() => setOpenUploadManualSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES MANUALES</strong>
                </DialogTitle>
                <DialogContent>

                    <>
                        {/* {props.series.map((row, index) => (
                            <Box pt={1} pb={1}>
                                <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                                    <strong>SERIE: {row.id}</strong>
                                </p>
                                <TextField
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { Wtop: 0 },
                                    }}
                                    size="small"
                                    fullWidth
                                    name={index}
                                    //value={[i]}
                                    onChange={(e) => handleSeries(index, e, data)}
                                />
                            </Box>
                        ))}
                        <Button onClick={() => onCloseIngresoManual()}>
                            <strong>Cancelar</strong>
                        </Button>
                        <Button
                            size="small"
                            color="success"
                            variant="contained"
                            onClick={() => onSeries()}
                        >
                            CREAR SERIES
                        </Button> */}
                    </>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadManual()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES AUTOMATICAS */}

            <Dialog
                open={openUploadAutomaticasSeries}
                fullWidth
                onClose={() => setOpenUploadAutomaticasSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES AUTOMATICAS</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadAutomaticas()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL EXCELS CARGADOS CON EXITO */}

            <Dialog
                open={openDialogExcelSuccess}
                fullWidth
                onClose={() => setOpenDialogExcelSuccess(false)}
                maxWidth="xs"
            >
                <DialogTitle>
                    <strong>SERIES SUBIDAS CON EXITO</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>

                    <Button
                        size="small"
                        variant="contained"
                        color='success'
                        onClick={() => setOpenDialogExcelSuccess(false)}
                    >
                        <strong>OK</strong>
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth

                open={openConfirmSendSolicitud}
            >
                <DialogTitle dividers>
                    ¿ESTA SEGURO DE REALIZAR LA SOLICITUD DE TRANSFERENCIA?
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions dividers>
                    <LoadingButton loading={loadingButtonSolicitudTransferencia}
                        endIcon={loadingButtonSolicitudTransferencia === true ? <SendIcon /> : ''} loadingPosition="end" color="warning" variant="outlined" size="small" onClick={() => setOpenConfirmSendSolicitud(false)}>REGRESAR</LoadingButton>
                    <LoadingButton loading={loadingButtonSolicitudTransferencia}
                        endIcon={loadingButtonSolicitudTransferencia === true ? <SendIcon /> : ''} loadingPosition="end" color="success" variant="contained" size="small" onClick={() => verificacionDeStaock()}>SI, REALIZAR</LoadingButton>
                </DialogActions>
            </Dialog>

        </>
    )
}