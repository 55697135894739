import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import {
    Box, Button, Card, CardContent, Grid,
    LinearProgress, Paper, TextField, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Select, MenuItem
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2';
import { db } from '../../firebase';

export function fillWithZeros(inputString, desiredLength) {
    if (inputString.length >= desiredLength) {
      return inputString;
    }
  
    const numberOfZerosToAdd = desiredLength - inputString.length;
    const zeros = '0'.repeat(numberOfZerosToAdd);
  
    return zeros + inputString;
  }


export const Secuenciales = () => {

    const tabla = 'cuentas_pagar/parametros/secuencial'
    const [nombre, setNombre] = useState('')
    const [valor, setValor] = useState('')
    const ventana = 'Secuenciales'

    const [id, setId] = useState('')
    const [establecimiento, setEstablecimiento] = useState('')
    const [emision, setEmision] = useState('')

    const [categorias, setCategorias] = useState(null)
    const [documentos, setDocumentos] = useState([])
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    useEffect(() => {
        cargarCategorias()
        getDocumentos()
    }, [])


    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )

    

    const columns = [
        { field: 'documento', headerClassName: 'super-app-theme--header', headerName: 'Documento', flex: 1 },

        { field: 'correo', headerClassName: 'super-app-theme--header', headerName: 'Correo Usuario', flex: 1 },
        {
            field: 'valor', headerClassName: 'super-app-theme--header', headerName: 'Valor', flex: 1,
            renderCell: (params) => {
                return <a>
                    {params.row.establecimiento + '-' + params.row.emision + '-' + fillWithZeros(params.row.valor+"",9)}
                </a>
            }
        },

        {
            field: 'EDITAR', headerClassName: 'super-app-theme--header', headerName: 'EDITAR', flex: 0.5,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}
                >
                    <EditIcon color='info' />
                </IconButton>
            }
        },
        {
            field: 'BORRAR', headerClassName: 'super-app-theme--header', headerName: 'BORRAR', flex: 0.5,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen2(params.row)}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];

    function getDocumentos() {
        db.collection('cuentas_pagar/parametros/tipo_comprobantes')
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setDocumentos(arrayMessage)
            })
    }

    function cargarCategorias() {
        db.collection(tabla)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setCategorias(arrayMessage)
            })
    }


    function crearSubCategoria() {
        db.collection(tabla).doc().set({
            documento: nombre,
            valor: valor,
            establecimiento: "001",
            correo: establecimiento,
            emision: emision,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        if (id !== '') {
            db.collection(tabla).doc(id).update({
                documento: nombre,
                valor: valor,
                establecimiento: "001",
                correo: establecimiento,
                emision: emision,
            }).then(() => {
                limpiar()
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha actualizado", });
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
        } else {
            crearSubCategoria()
        }
    }

    function limpiar() {
        setId('')
        setNombre('')
        setEstablecimiento('')
        setEmision('')
        setValor('')
    }

    function handleOpen(item) {
        if (item) {
            setId(item.id)
            setValor(item.valor)
            setNombre(item.documento)
            setEstablecimiento(item.correo)
            setEmision(item.emision)
        }
        setOpen(true)
    }

    function handleOpen2(item) {
        if (item) {
            setId(item.id)
            setValor(item.valor)
            setNombre(item.documento)
        }
        setOpen2(true)
    }

    function deleteProducto() {
        db.collection(tabla).doc(id).delete()
            .then(() => {
                Swal.fire({ icon: "success", text: "Se ha eliminado" })
                setOpen2(false)
                limpiar()
            })
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };


    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>{ventana}</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleOpen()}
                                variant="contained" color='success'>
                                AGREGAR
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px', overflowX: 'scroll' }}>
                                <Box sx={{
                                    minWidth: 500,
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={categorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={categorias ? categorias : []}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 100}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} {ventana} </strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Documento:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={nombre}
                                            disabled={id !== ''}
                                            onChange={(e) => setNombre(e.target.value)}
                                        >
                                            {documentos ? documentos.map((item) => (
                                                <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                            )) : ''}

                                        </Select>
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Correo usuario SOEI:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={establecimiento}
                                            // disabled={id !== ''}
                                            onChange={(e) => setEstablecimiento(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Punto de Emisión:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={emision}
                                            MenuProps={MenuProps}
                                            disabled={id !== ''}
                                            onChange={(e) => setEmision(e.target.value)}
                                        >
                                            <MenuItem key={'001'} value={'001'}>{'001'}</MenuItem>
                                            <MenuItem key={'002'} value={'002'}>{'002'}</MenuItem>
                                            <MenuItem key={'003'} value={'003'}>{'003'}</MenuItem>
                                            <MenuItem key={'004'} value={'004'}>{'004'}</MenuItem>
                                            <MenuItem key={'005'} value={'005'}>{'005'}</MenuItem>
                                            <MenuItem key={'012'} value={'012'}>{'012'}</MenuItem>
                                            <MenuItem key={'013'} value={'013'}>{'013'}</MenuItem>
                                            <MenuItem key={'014'} value={'014'}>{'014'}</MenuItem>
                                            <MenuItem key={'015'} value={'015'}>{'015'}</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Secuencial:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={valor}
                                            onChange={(e) => setValor(e.target.value)}
                                            size="small"
                                            type='number'
                                            fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                valor == '' ||
                                                establecimiento == '' ||
                                                emision == '' ||
                                                nombre === ''
                                            }
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>

            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: {nombre}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
