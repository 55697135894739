import {
  IconButton, Typography, Box, Paper, Chip, Button, Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from "moment";
import * as XLSX from 'xlsx/xlsx.mjs'
import { auth, db, firebase } from '../../firebase'
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import "react-datepicker/dist/react-datepicker.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';
import { PdfHojaTrabajo } from './PdfHojaTrabajo';
import { ViewPhotosInstalaciones } from '../../components/inventario/ViewPhotosInstalaciones';


export const MaterialesUtilizados2 = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState()
  const [isLastPage, setIsLastPage] = useState(0)
  const [queryDoc, setQueryDocqueryDoc] = useState([])
  const [reData, setreData] = useState([])
  //
  const [total, setTotal] = useState(0);
  //
  const [search, setSearch] = useState('')
  //var current = new Date();
  let current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const [value, setValue] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    LoadData()

  }, [startDate])
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth


  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("tecnicos").where("estado", "==", 2)
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created_instalacion_foto', '>=', startfulldate)
      ref = ref.where('created_instalacion_foto', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const totalCount = snapshot.size
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {

            return {
              ...doc.data(),
              id: doc.id,
            }

          }
          )
        ];
        setData(data)
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
        setIsLastPage(totalCount < 1)
      })
    }
    //}
  }
  const handleExport = () => {
    function instalacion(fechaHora) {
      if (fechaHora && fechaHora.seconds) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }
    }
    const reData = [
      ...data.map(doc => {
        return {
          FECHA_PARA_INSTALACION: instalacion(doc.fechaHora),
          FECHA_DE_INSTALACION: instalacion(doc.fechaDeInstalacion),
          MOTIVO: doc.cambio_domicilio === true ? "CAMBIO DOMICILIO" : "INSTALACION",
          FLOTA: doc.numFlota,
          CONTRATO: doc.numContrato,
          SERIE_UTILIZADA: doc.serie_utilizada,
          NOMBRE_CLIENTE: doc.nombres,
          CEDULA_CLIENTE: doc.cedula,
          NOMBRE_PLAN: doc.planNombre,
          CANTON: doc.canton,
          CENTRO_COSTO: doc.centro_costo.centro_costo,
          NOMBRE_LIDER: doc.nombreLider,
          VENDEDOR: doc.vendedor,
          VALOR_COBRADO: doc.valorCobrado,
          CUOTAS: doc.noCuotas,
          CABLE_INICIO: doc.cableInicio === undefined || doc.cableInicio === null || doc.cableInicio === '' ? 0 : doc.cableInicio,
          CABLE_FINALIZA: doc.cableFinaliza === undefined || doc.cableFinaliza === null || doc.cableFinaliza === '' ? 0 : doc.cableFinaliza,
          METRAJE_EXTRA: doc.metraje_cable_extra,
          CUOTAS_METRAJE_EXTRA: doc.numero_cuotas_metraje_cable_extra,
          VALOR_MENSUAL_METRAJE_EXTRA: doc.valor_mensual_a_pagar_metraje_cable_extra,
          VALOR_MENSUAL_METRAJE_EXTRA_DESCUENTO: doc.valor_mensual_a_pagar_metraje_cable_extra_descuento,
          OBSERVACIONES_EXTRA: doc.observaciones_extra,
        }
      })
    ]


    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(reData);

    let inicio = moment(startDate.toString()).format('DD/MM/YYYY')
    let fin = moment(endDate.toString()).format('DD/MM/YYYY')
    // ws['!cols'] = [];
    // ws['!cols'][1] = { hidden: true };
    // ws['!cols'][2] = { hidden: true };
    // ws['!cols'][3] = { hidden: true };
    // ws['!cols'][4] = { hidden: true };
    // ws['!cols'][5] = { hidden: true };
    XLSX.utils.book_append_sheet(wb, ws, "OrdenTrabajo");

    XLSX.writeFile(wb, `OrdenTrabajo${inicio + '-' + fin}.xlsx`)
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //LoadData()
  }
  const columns = [
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fechaDeInstalacion', headerName: 'FECHA DE INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: '', headerName: 'MOTIVO', width: 175,
      renderCell: (params) => {
        return params.row.cambio_domicilio === true ? <Chip label="CAMBIO DOMICILIO" size="small" color="info" /> : <Chip label="INSTALACION" size="small" color="success" />
      }
    },
    { field: 'numFlota', headerName: 'FLOTA', width: 110 },
    { field: 'numContrato', headerName: 'CONTRATO', width: 250 },
    { field: 'serie_utilizada', headerName: 'SERIE UTILIZADA', width: 180 },
    {
      field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 280
    },
    {
      field: 'cedula', headerName: 'CEDULA', width: 120
    },
    {
      field: 'planNombre', headerName: 'NOMBRE DEL PLAN', width: 280
    },
    // { field: 'DSerie', headerName: 'DSerie', width: 200 },
    // { field: 'cantidadOnus', headerName: 'ONU', width: 50 },
    // { field: 'bobinaCod', headerName: 'BOBINA COD', width: 120 },
    // { field: 'cantidadSoportes', headerName: 'SOPORTES', width: 120 },
    // { field: 'cantidadAmarrasG', headerName: 'AMARRAS GRANDES', width: 120 },
    // { field: 'cantidadAmarrasP', headerName: 'AMARRAS PEQUEÑAS', width: 120 },
    // { field: 'cantidasConectoresMecanicos', headerName: 'MECANICOS', width: 120 },
    // { field: 'cantidadTornillos', headerName: 'TORNILLOS', width: 120 },
    // { field: 'cantidadTacos', headerName: 'TACOS', width: 120 },
    // { field: 'cantidadGrapas', headerName: 'GRAPAS', width: 120 },
    // { field: 'cableInicio', headerName: 'CABLE INICIO', width: 120 },
    // { field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 160 },
    // { field: 'cableUtilizado', headerName: 'CABLE UTILIZADO', width: 160 },
    { field: 'canton', headerName: 'CANTON', width: 120 },

    {
      field: 'nombreLider', headerName: 'NOMBRE LIDER', width: 320,
      renderCell: (params) => {
        return params.row.nombreLider === undefined ? '' : params.row.nombreLider
      }
    },
    {
      field: 'centro', headerName: 'CENTRO DE COSTO', width: 320,
      renderCell: (params) => {
        return params.row.centro_costo === undefined ? '' : params.row.centro_costo.centro_costo
      }
    },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
    { field: 'valorCobrado', headerName: 'VALOR COBRADO', width: 160 },
    { field: 'noCuotas', headerName: 'CUOTAS', width: 160 },
    { field: 'cableInicio', headerName: 'CABLE INICIO', width: 160 },
    { field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 160 },
    // { field: 'metraje', headerName: 'CABLE EXTRA', width: 160 },
    { field: 'metraje_cable_extra', headerName: 'METRAJE EXTRA', width: 160 },
    { field: 'numero_cuotas_metraje_cable_extra', headerName: 'CUOTAS METRAJE EXTRA', width: 210 },
    { field: 'valor_mensual_a_pagar_metraje_cable_extra', headerName: 'VALOR MENSUAL A PAGAR METRAJE EXTRA', width: 330 },
    { field: 'valor_mensual_a_pagar_metraje_cable_extra_descuento', headerName: 'VALOR MENSUAL A PAGAR METRAJE EXTRA DESCUENTO', width: 420 },
    {
      field: 'button', headerName: 'VER', width: 90,
      renderCell: (params) => {
        return <PdfHojaTrabajo info={params.row} />
      }
    },
    {
      field: 'button1', headerName: 'IMAGENES', width: 100,
      renderCell: (params) => {
        return <ViewPhotosInstalaciones props={params.row} />
      }
    },
    { field: 'observaciones_extra', headerName: 'OBSERVACION EXTRA', width: 330 },
  ];

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      {/* <GridToolbarQuickFilter /> */}
    </GridToolbarContainer>
  );
  const onChange2 = () => {
    LoadData()
  }
  return (
    <>

      <Box pt={1} pl={1}>
        <Typography >
          <strong>REGISTRO DE ORDEN DE TRABAJO</strong>
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >

        <Box>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
          <Stack direction="row" spacing={2}>

            <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
            >
              {" "}
              DESCARGAR EXCEL
            </Button>
          </Stack >
        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density="compact"
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>
      {/* <Box pt={2}></Box>
        <Button disableElevation variant='outlined' onClick={() => handleExport()}>REPORTE MARIELA</Button> */}
    </>
  )
}