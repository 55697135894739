import React, { useEffect, useState } from "react"
import { db, firebase, auth } from "../../../firebase"
import Swal from "sweetalert2"
import { Grid, Button, Box, CardContent, TextField, InputLabel } from "@mui/material"
import InputValidation from "../../../components/utils/InputValidation"
import { useSelector } from 'react-redux'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES,
    GridToolbarExport,
    GridCsvExportMenuItem,
    GridToolbarExportContainer
} from '@mui/x-data-grid'

export const CrearCodigoVendedor = () => {
    const userAuth = useSelector((state) => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    //
    const [list_codigo, setListCodigo] = useState('')
    useEffect(() => {
        onLoad()
    }, [])
    const onsubmit = () => {
        //setLoading(true)
        db.collection('system/centro_de_costos/codigo_vendedor').orderBy("created", "desc").get()
            .then((snapshot) => {
                const arrayMessage = snapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                const cod = arrayMessage[0].codigo + 1
                //
                db.collection('system/centro_de_costos/codigo_vendedor').doc(`${cod}`).set({
                    id: cod,
                    email: userInfo.email,
                    nombre: nombre.campo,
                    codigo: cod,
                    estado: 1,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    setLoading(false)
                    setNombre({ campo: '', valido: true })
                    Swal.fire({ icon: "success", text: 'Se guardo su codigo vendedor' })
                }).catch((e) => {
                    alert(e)
                    setLoading(false)
                })
            })
    }
    const onLoad = () => {
        let ref = db.collection('system/centro_de_costos/codigo_vendedor').orderBy("created", "desc")
        if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
            ref = ref.where("email", "==", auth.currentUser.email)
            
        ref.onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListCodigo(arrayMessage)
            })
    }
    const CambiarEstado = ({ props }) => {
        const update = () => {
            console.log(props.codigo)
            db.collection('system/centro_de_costos/codigo_vendedor').doc(`${props.codigo}`).update({
                estado: props.estado == 1 ? 0 : 1
            })
        }
        return <Button
            size='small'
            onClick={() => update()}
        >{props.estado == 1 ? 'SUSPENDER' : 'ACTIVAR'}
        </Button>
    }
    //
    const columns = [
        { field: 'id', headerName: 'CODIGO', width: 75, align:'center', },
        { field: 'nombre', headerName: 'NOMBRE VENDEDOR', width: 200, align:'center', }, 

        {
            field: 'estado', headerName: 'ESTADO', width: 125, align:'center',
            renderCell: (params) => {
                return params.row.estado == 1 ? 'ACTIVO' : 'SUSPENDIDO'
            }
        },
        {
            field: 'acciones', headerName: 'ACCIONES', width: 150, align:'center',
            renderCell: (params) => {
                return <CambiarEstado props={params.row} />
            }
        }
    ]
    function CustomFooterTotalComponent(props) {
        return ''
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <>
            <CardContent>
                <Grid container>
                    <Grid item xs={10} md={6}>
                        <InputValidation
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="NOMBRE DEL VENDEDOR"
                        />
                        <Button
                            disabled={
                                !nombre.campo || loading
                            }
                            onClick={() => onsubmit()}
                            variant="contained"
                            color="secondary"
                        >Crear codigo vendedor
                        </Button>
                    </Grid>
                    <Grid item xs={10} md={6}>
                    </Grid>
                </Grid>
                <Box pt={4} sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                        rows={list_codigo ? list_codigo : []}

                        density="compact"
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                    />
                </Box>
            </CardContent>
        </>
    )
}
