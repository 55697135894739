import {
    Box,
    Typography,
    Button,
    Paper,
    Chip,
    Collapse,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES,
    GridToolbarExport
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../firebase";
import { useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
export const FamilyCardPage = () => {
    const [open, setOpen] = useState(null);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth;
    const [search, setSearch] = useState("");
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 518400000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //
    useEffect(() => {
        LoadData();
        LoadData2();
    }, []);
    //
    function Estado({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === true) {
            name = 'SI TIENE'
            color = 'warning'
        }
        if (estado === false) {
            name = 'NO TIENE'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const LoadData = () => {
        let ref = db.collection("users_familycard")
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where("created", ">=", startfulldate);
            ref = ref.where("created", "<=", endfulldate);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                            familycard: doc.data().FAMILY_CARD ? 
                            'SI TIENE' : 'NO TIENE'
                        };
                    }),
                ];
                setData(data);
            });
        }
    }
    const LoadData2 = () => {
        const startDate = new Date()
        const endDate = new Date()
        let ref = db.collection("users_familycard")
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("vigencia_hasta", ">=", startfulldate);
        ref = ref.where("vigencia_hasta", "<=", endfulldate);
        ref.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var data = doc.data()
                console.log(data.NUMERO_IDENTIFICACION);
                var users_familycard = db.collection("users_familycard").doc(data.NUMERO_IDENTIFICACION);
                return users_familycard.update({
                    FAMILY_CARD: false
                })
            });
        });
        // ref.onSnapshot((snapshot) => {
        //     const totalCount = snapshot.size;
        //     const data = [
        //         ...snapshot.docs.map((doc) => {
        //             return {
        //                 ...doc.data(),
        //                 id: doc.id,
        //             };
        //         }),
        //     ];
        //     console.log(data, 'data')
        // });

    }
    // 
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    //
    const columns = [
        { field: 'FECHA_VIGENCIA_HASTA', headerName: 'FECHA_VIGENCIA_HASTA', width: 200 },
        { field: 'FECHA_VIGENCIA_DESDE', headerName: 'FECHA_VIGENCIA_DESDE', width: 200 },
        { field: 'id', headerName: 'NUMERO_IDENTIFICACION', width: 200 },
        { field: 'NOMBRE_ASEGURADO', headerName: 'NOMBRE_ASEGURADO', width: 300 },
        {
            field: 'familycard', headerName: 'FAMILY_CARD', width: 175,
            renderCell: (params) => {
                return <Estado estado={params.row.FAMILY_CARD} />
            }
        },
        { field: 'TELEFONO', headerName: 'TELEFONO', width: 200 },
        { field: 'CORREO_ELECTRONICO', headerName: 'CORREO_ELECTRONICO', width: 200 },
        { field: 'NUMERO_POLIZA', headerName: 'NUMERO_POLIZA', width: 200 },
        { field: 'ACCESS_LEVEL', headerName: 'ACCESS_LEVEL', width: 200 },
        { field: 'SEXO', headerName: 'SEXO', width: 200 },
        { field: 'FECHA_NACIMIENTO', headerName: 'FECHA_NACIMIENTO', width: 200 },
    ]
    return (
        <>
            <Box pt={1}>
                <Typography variant="button" >
                    <Typography>
                        <strong>LIST FAMILY CARD</strong>
                    </Typography>
                </Typography >
            </Box>
            <Box pt={1}>
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                    </Box>
                </Box>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    <Button
                        disableElevation
                        variant="contained"
                        onClick={() => LoadData()}
                    >
                        Aplicar busqueda
                    </Button>
                </Collapse>
            </Box>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    density='compact'
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </Box>
        </>
    )
}
