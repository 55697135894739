import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  InputLabel,
  Tooltip,
  Grid,
  Paper,
  Divider,
  Card,
  CardContent,
  Typography,
  Alert,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  Autocomplete,
  IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../../firebase";
import Swal from "sweetalert2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "moment/locale/es";
import moment from "moment";
import { listsMateriales } from "../../../../redux/actions/parametersActions";
import { UpdateFileUploadInstaladores } from "../../../../components/utils/UpdateFileUploadInstaladores";
import { detallesInstalacionTickets } from "../../../../redux/actions/instalacionesAction";
import { UpdateFileUploadTicketsInstaladores } from "./UpdateFileUploadTicketsInstaladores";

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const ViewTicketEnEsperaInstaladorNew = ({ props }) => {

  const [productos, setProductos] = useState([])

  const [item, setItem] = useState('')

  const [modalProducts, setModalProducts] = useState(false);

  const [inputFields, setInputFields] = useState([]);

  const [updateCoordenadas, setUpdateCoordenadas] = useState(false)

  const [dialogCoordenadasActualizadoConExito, setdialogCoordenadasActualizadoConExito] = useState(false)

  const [guardarSerie, setGuardarSerie] = useState('')

  //OBTENER LOS MATERIALES OTORGADOS PARA LA INSTALACION

  const [contactarCliente, setContactarCliente] = useState('');

  const [dataMateriales, setDataMateriales] = useState();

  const [numEgreso, setNumEgreso] = useState('')

  const [coordenadas, setCoordenadas] = useState(props.coordenadas)

  const loadNumEgreso = () => {
    const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
    ref.onSnapshot(snapshot => {
      if (snapshot.size > 0) {
        const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
        const solicitud = solicitudes[0]
        const numeroSolicitud = solicitud.numero_solicitud + 1;
        const uidSolicitud = `EGR-` + numeroSolicitud;
        setNumEgreso(numeroSolicitud)
      }
    })
  }

  const getMaterialesUtilizados = () => {
    let ref = db
      .collection("flotas")
      .doc(props.correo_flota)
      .collection("materiales");
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setDataMateriales(data);
    });
  };


  /* INSTALACION */

  const userAuth = useSelector((state) => state.userAuth);
  const { loadin, userInfo } = userAuth;

  const materialesList = useSelector((state) => state.materialesList);
  const { materiales, loading } = materialesList;
  const [material, setMaterial] = useState("");

  const [caja, setCaja] = useState(props.caja);
  const [open, setOpen] = useState(false);
  const [onuActualACambiar, setOnuActualACambiar] = useState("");
  const [openCaja, setOpenCaja] = useState(false);
  const [nombrePlan, setNombrePlan] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPuerto, setOpenPuerto] = useState(false);
  const [openConfirmInstalacion, setOpenConfirmInstalacion] = useState(false);
  const [openInstalacionRechazada, setOpenInstalacionRechazada] =
    useState(false);
  const [openSendInstalacionRechazada, setOpenSendInstalacionRechazada] =
    useState(false);
  //Servicios contratados
  const [potencia, setPotencia] = useState("");
  const [puerto, setPuerto] = useState("");

  const [nombresConfirmacionCable, setNombresConfirmacionCable] = useState("");

  const [observacionesExtra, setObservacionesExtra] = useState("");

  const [loadingConfirmInstalacion, setLoadingConfirmInstalacion] = useState(false);

  //Equipos instalados
  const [equipo, setEquipo] = useState("");
  const [DSerie, setDSerie] = useState("");
  const [NMac, setNMac] = useState({ campo: "", valido: true });
  const [NSerie, setNSerie] = useState({ campo: "", valido: true });
  //Accesorios
  const [materialesExtra, setMaterialesExtra] = useState("");

  const [valorCuotas, setValorCuotas] = useState({ campo: "", valido: true });
  //Observaciones
  //Detalle cable
  const [bobinaCod, setBobinaCod] = useState("");
  const [cableInicio, setCableInicio] = useState("");
  const [cableFinaliza, setCableFinaliza] = useState("");
  const [cableUtilizado, setCableUtilizado] = useState({
    campo: "",
    valido: true,
  });
  //Detalle instalacion y extras (accesorios) (detalles pago instalacion)
  const [valorMaterialesExtra, setValorMaterialesExtra] = useState("");
  const [valorCobrado, setValorCobrado] = useState({ campo: "", valido: true });
  const [valorExtra, setValorExtra] = useState("");
  const [noCuotas, setNoCuotas] = useState(0);

  //Novedades de instalacion (Materiales utilizados)
  const [onus, setOnu] = useState("");
  const [tornillos, setTornillos] = useState("");
  //
  const [grapas, setGrapas] = useState("");

  //
  const [number, setNumber] = useState({ one: 0, two: 0 });
  const [suma, setSuma] = useState();
  const [cableTotal, setCableTotal] = useState(0);
  const [fibra, setFibra] = useState({ campo: "", valido: true });
  const [transicionesDuplex, setTransicionesDuplex] = useState("");
  const [soportes, setSoportes] = useState("");
  const [amarrasP, setAmarrasP] = useState("");
  const [amarrasG, setAmarrasG] = useState("");
  const [mecanicos, setMecanicos] = useState("");
  const [tacos, setTacos] = useState("");
  const [pernos, setPernos] = useState("");
  const [observacionInstalacionRealizada, setObservacionInstalacionRealizada] =
    useState("");
  const [observacionInstalacionRechazada, setObservacionInstalacionRechazada] =
    useState({ campo: "", valido: true });
  const [data, setData] = useState("");
  const [tiposRechazosTickets, setTiposRechazosTickets] = useState([]);
  const [tipoRechazoTicket, setTipoRechazoTicket] = useState("");

  const alertaHojaDeInstalacion = () => {
    console.log("Alerta hoja de instalacion");

    console.log(props.created_pago);

    console.log(moment().format("h:mm:ss a"));

    console.log(
      moment(props.created_pago.toDate().toString()).format("h:mm:ss a")
    );

    /* if (props.created_pago ){
    
        } */
  };

  const [valorACobrar, setValorACobrar] = useState(props.valor_a_cobrar);

  const getMotivosRechazoTicket = async () => {
    let ref = await db
      .collection("parameters")
      .doc("tipos_rechazos_tickets_operaciones")
      .collection("tipos_rechazos");

    ref.onSnapshot(async (snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];

      setTiposRechazosTickets(data);
    });
  };

  const [numSolicitudFlota, setNumSolicitudFlota] = useState("");

  const loadNumSolicitudFlota = () => {
    const ref = db
      .collection("reporte_movimientos_flotas")
      .orderBy("created", "desc")
      .limit(1);
    ref.onSnapshot((snapshot) => {
      if (snapshot.size > 0) {
        const solicitudes = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const solicitud = solicitudes[0];
        const numeroSolicitud = solicitud.numero_solicitud + 1;
        const uidSolicitud = `MOV-` + numeroSolicitud;
        setNumSolicitudFlota(numeroSolicitud);
      }
    });
  };

  const [materialesInstalacion, setMaterialesInstalacion] = useState([]);

  const [materialesInstalacionAsignados, setMaterialesInstalacionAsignados] =
    useState([]);

  const handleSubmitEgreso = async () => {
    let rol = "";
    if (userInfo.rol === 0) {
      rol = "ADMIN";
    }
    if (userInfo.rol === 1) {
      rol = "COMERCIAL";
    }
    if (userInfo?.roles?.find((role) => ["operador"]?.includes(role))) {
      rol = "OPERACIONES";
    }
    if (userInfo.rol === 3) {
      rol = "PLANEACION";
    }
    if (userInfo.rol === 4) {
      rol = "FLOTAS";
    }
    if (userInfo.rol === 5) {
      rol = "REPORTES";
    }
    if (userInfo.rol === 6) {
      rol = "RECURSOS HUMANOS";
    }

    const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo_flota).get()

    const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    db.collection(`reporte_movimientos`).doc(`EGR-${numEgreso}`).set({
      uidSolicitud: `EGR-${numEgreso}`,
      estado: 0,
      numero_solicitud: numEgreso,
      uid_user: userInfo.uid,
      user: userInfo.displayName,
      departamento: ''/* props.departamento_seleccionado */,
      rol: '',
      created: firebase.firestore.FieldValue.serverTimestamp(),
      bodega: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA'/* props.bodega */,
      ubicacion: '',
      motivo: 'ORDEN TRABAJO'/* props.motivo.toUpperCase() */,
      motivo_movimiento: 'EGRESO',
      observacion_adicional: ''/* observacionExtra === '' ? 'NINGUNA' : observacionExtra */,
      flota: flotas1[0].numeroFlota === undefined ? '' : flotas1[0].numeroFlota,
      lider_flota: flotas1[0].nombreLider === undefined ? '' : flotas1[0].nombreLider,
      auxiliar_flota: flotas1[0].nombreAuxiliar === undefined ? '' : flotas1[0].nombreAuxiliar,
      placa_flota: flotas1[0].placa === undefined ? '' : flotas1[0].placa,
      marca_flota: flotas1[0].marca === undefined ? '' : flotas1[0].marca,
      modelo_flota: flotas1[0].modelo === undefined ? '' : flotas1[0].modelo,
      tipo_movimiento: 'ORDEN TRABAJO',
      empleado: flotas1[0].nombreLider === undefined ? '' : flotas1[0].nombreLider,
      fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
      relacion: props.numContrato
    })

    inputFields.forEach(async function (obj, index) {

      if (obj.cantidad_pedida != 0 && obj.cantidad_pedida != undefined && obj.cantidad_pedida != null) {

        const agendaDB1 = await db.collection("flotas").where('correo', '==', props.correo_flota).get()

        const data1 = await agendaDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        const agendaDB3 = await db.collection("flotas").doc(data1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo_producto).get()

        const data3 = await agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        const costoPromedio = data3[0].costo_promedio

        const cantidad_total = parseFloat(data3[0].stock_actual) - parseFloat(obj.cant_entrega)

        const saldo_valorizado = parseFloat(data3[0].costo_promedio).toFixed(2) * parseFloat(obj.cant_entrega).toFixed(2)

        db.collection(`reporte_movimientos`).doc(`EGR-${numEgreso}`).collection('items').doc(obj.codigo).set({
          /* ...obj, */
          fullName: obj.codigo,
          descripcion: obj.descripcion,
          uid_solicitud_pedido: `EGR-${numEgreso}`,
          cantidad_solicitada: parseInt(obj.cantidad_pedida) <= 0 ? 0 : parseInt(obj.cantidad_pedida),
          cantidad_total_antes: data3[0].stock_actual,
          cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
          uid_user: userInfo.uid,
          user: userInfo.displayName,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          saldo_valorizado: saldo_valorizado === 0 || saldo_valorizado === undefined || Number.isNaN(Number(saldo_valorizado)) ? 0 : saldo_valorizado,//totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2,
          costo_promedio: costoPromedio <= 0 ? 0 : costoPromedio,
          tipo_movimiento: 'egreso',
          precio_compra: data3[0].precio_compra,
          observacion_adicional: '', //observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
          fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else {
        return
      }

    })
  };

  const getMaterialesAsignados = async () => {

    console.log("props.id", props.id);

    const agendaDB2 = await db
      .collection("flotas")
      .where("correo", "==", props.correo_flota)
      .get();

    const data2 = await agendaDB2.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    let ref = await db
      .collection("flotas")
      .doc(data2[0].id)
      .collection("materiales");

    ref.onSnapshot(async (snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];

      console.log("dataMaterialesAsignados", data);

      setMaterialesInstalacionAsignados(data);

      data.forEach(async (material, index) => {
        console.log("material", material);
        console.log("codigo producto", material.codigo_producto);

        let ref = await db
          .collection("flotas")
          .doc(data2[0].id)
          .collection("materiales")
          .doc(material.codigo_producto)
          .collection("series")
          .where('estado', '==', 0);

        ref.onSnapshot((snapshot) => {
          const dataSerie = [
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];

          dataSerie.forEach((serie, indexSerie) => {
            console.log("serie", serie);
            console.log("numero serie", serie.serie);
            console.log("index serie", indexSerie);
            console.log("index material", index);

            const num = indexSerie;
            let array = [];
            for (var i = 0; i <= num; i++) {
              //console.log('Hola')
              let n;
              n += i;
              //console.log('for', n)
              array.push({
                index_serie: i,
                id: i,
                serie: dataSerie[i]["serie"],
                descripcion: dataSerie[i]["descripcion"],
              });
              data[index]["series"] = array;
            }
          });
        });
      });
    });
  };

  const rechazarInstalacion = () => {
    db.collection("tickets_tecnicos").doc(props.id).update({
      estado: 3,
      fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
      motivo_rechazo: tipoRechazoTicket,
    });

    db.collection("tickets").doc(props.id).update({
      estado: 3,
      fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
      motivo_rechazo: tipoRechazoTicket,
    });

    setOpenSendInstalacionRechazada(false);
    Swal.fire({
      icon: "success",
      ext: "El ticket rechazado se reagendara",
    });
  };

  /* FUNCTION SEND SERIE */

  const sendSerie = () => {

    console.log('materialesInstalacionAsignados', materialesInstalacionAsignados)

    materialesInstalacionAsignados.forEach(async function (obj, index) {
      console.log("props.correo_flota", props.correo_flota);

      const agendaDB2 = await db
        .collection("flotas")
        .where("correo", "==", props.correo_flota)
        .get();

      const data2 = await agendaDB2.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("flota", data2[0]);

      console.log("id flota", data2[0].id);

      if (obj.serie_entrega === undefined) {
        return;
      } else {
        const agendaDB3 = await db
          .collection("flotas")
          .doc(data2[0].id)
          .collection("materiales")
          .where("codigo_producto", "==", obj.codigo_producto)
          .get();

        const data3 = await agendaDB3.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("data3[0].id", data3[0].id)

        const agendaDB4 = await db
          .collection("flotas")
          .doc(data2[0].id)
          .collection("materiales")
          .doc(data3[0].id)
          .collection("series")
          .where('serie', '==', obj.serie_entrega.toString())
          .get()

        const data4 = await agendaDB4.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("data4[0].id", data4[0].id);

        await db
          .collection("flotas")
          .doc(data2[0].id)
          .collection("materiales")
          .doc(data3[0].id)
          .collection("series")
          .doc(data4[0].id)
          .update({
            estado: 1,
          });

        if (props.correo_flota.includes('u')) {
          await db
            .collection("bodegas_flotas")
            .doc('flotas_internas')
            .collection("materiales")
            .doc(data3[0].id)
            .collection("series")
            .doc(data4[0].id)
            .update({
              estado: 1,
            });
        } else {
          await db
            .collection("bodegas_flotas")
            .doc('flotas_externas')
            .collection("materiales")
            .doc(data3[0].id)
            .collection("series")
            .doc(data4[0].id)
            .update({
              estado: 1,
            });
        }

      }

    })
  }

  /* FUNCION DE ENVIO */

  const InstalacionRealizada = async (numeroContrato, numFlota) => {

    setLoadingConfirmInstalacion(true)

    db.collection("tickets").doc(props.id).update({
      visita_realizada: 1,
      numero_cuotas: noCuotas,
      valor_a_pagar_mensual: numeroCuotasAPagarFiltroNaN,
      observaciones_extra: observacionesExtra,
      fechaTicketRealizado: firebase.firestore.FieldValue.serverTimestamp(),
      contactar_con_cliente: contactarCliente === '' || contactarCliente === undefined ? 'NO' : contactarCliente
    });

    db.collection("tickets_tecnicos")
      .doc(props.id)
      .update({
        created: firebase.firestore.FieldValue.serverTimestamp(),
        estado: 1,
        noCuotas: Number.isNaN(Number(noCuotas)) ? 0 : Math.abs(noCuotas),
        valor_mensual_a_pagar: Number.isNaN(Number(numeroCuotasAPagar)) || numeroCuotasAPagar === Infinity
          ? 0
          : Math.abs(numeroCuotasAPagar),
        observaciones_extra:
          observacionesExtra.toUpperCase() === ""
            ? "NINGUNA OBSERVACION EXTRA"
            : observacionesExtra.toUpperCase(),
        fechaTicketRealizado: firebase.firestore.FieldValue.serverTimestamp(),
        visita_realizada: 1,
        numero_cuotas: noCuotas,
        valor_a_pagar_mensual: numeroCuotasAPagarFiltroNaN,
        contactar_con_cliente: contactarCliente === '' || contactarCliente === undefined ? 'NO' : contactarCliente
      });

    /* sendSerie() */

    /* handleSubmitEgreso() */

    inputFields.forEach(async function (obj, index) {

      if (obj.cantidad_pedida != 0 && obj.cantidad_pedida != undefined && obj.cantidad_pedida != null) {
        db.collection('tickets_tecnicos').doc(props.id).collection('materiales_utilizados').doc(obj.codigo).set({
          bodega: obj.bodega === undefined || obj.bodega === null || obj.bodega === '' ? '' : obj.bodega,
          categoria: obj.categoria === undefined || obj.categoria === null || obj.categoria === '' ? '' : obj.categoria,
          codigo_producto: obj.codigo === undefined || obj.codigo === null || obj.codigo === '' ? '' : obj.codigo,
          costo_promedio: obj.costo_promedio === undefined || obj.costo_promedio === null || obj.costo_promedio || Number.isNaN(Number(obj.costo_promedio)) === '' ? 0 : obj.costo_promedio,
          motivo: obj.motivo === undefined || obj.motivo === null || obj.motivo === '' ? '' : obj.motivo,
          precio_compra: obj.precio_compra === undefined || obj.precio_compra === null || obj.precio_compra === '' ? 0 : obj.precio_compra,
          descripcion: obj.descripcion === undefined || obj.descripcion === null || obj.descripcion === '' ? '' : obj.descripcion,
          cantidad_entregada_para_instalacion: obj.stock_actual === undefined || obj.stock_actual === null || obj.stock_actual || Number.isNaN(Number(obj.stock_actual)) === '' ? 0 : obj.stock_actual,
          unidad_medida: obj.unidad_medida === undefined || obj.unidad_medida === null || obj.unidad_medida === '' ? '' : obj.unidad_medida,
          cantidad_utilizada: obj.cantidad_pedida === undefined || obj.cantidad_pedida === null || obj.cantidad_pedida === '' || Number.isNaN(Number(obj.cantidad_pedida)) ? 0 : obj.cantidad_pedida,
          serie_utilizada: obj.codigo === 'INV-00113' ? guardarSerie : 'NINGUNA',
          valor_metraje_extra: obj.valor_metraje_extra === undefined || obj.valor_metraje_extra === null || obj.valor_metraje_extra === '' || Number.isNaN(Number(obj.valor_metraje_extra)) ? 0 : obj.valor_metraje_extra,
          numero_cuotas_metraje_extra: obj.numero_cuotas_metraje_extra === undefined || obj.numero_cuotas_metraje_extra === null || obj.numero_cuotas_metraje_extra === '' || Number.isNaN(Number(obj.numero_cuotas_metraje_extra)) ? 0 : obj.numero_cuotas_metraje_extra,
          valor_mensual_a_pagar_metraje_extra: obj.valor_mensual_a_pagar_metraje_extra === undefined || obj.valor_mensual_a_pagar_metraje_extra === null || obj.valor_mensual_a_pagar_metraje_extra === '' || Number.isNaN(Number(obj.valor_mensual_a_pagar_metraje_extra)) ? 0 : obj.valor_mensual_a_pagar_metraje_extra,
          valor_mensual_a_pagar_metraje_extra_con_descuento: obj.valor_mensual_a_pagar_metraje_extra_con_descuento === undefined || obj.valor_mensual_a_pagar_metraje_extra_con_descuento === null || obj.valor_mensual_a_pagar_metraje_extra_con_descuento === '' || Number.isNaN(Number(obj.valor_mensual_a_pagar_metraje_extra_con_descuento)) ? 0 : obj.valor_mensual_a_pagar_metraje_extra_con_descuento,
          persona_que_aprueba_metraje_extra: obj.persona_que_aprueba_metraje_extra === undefined || obj.persona_que_aprueba_metraje_extra === null || obj.persona_que_aprueba_metraje_extra === '' ? '' : obj.persona_que_aprueba_metraje_extra,
          contactar_con_cliente: contactarCliente === '' || contactarCliente === undefined ? 'NO' : contactarCliente
        })

      }


    });

    /* handleSubmitEgreso() */

    setOpenPuerto(false);
    setOpenConfirmInstalacion(false);

    Swal.fire({
      icon: "success",
      text: "Gestion enviada con exito",
    });
  };

  const onData = () => {
    setOpen(true);
    setData(props);
  };

  //TOTAL PAGO

  const conversionPlanValor = parseFloat(valorACobrar);

  const convertValorExtra = parseFloat(valorExtra);

  const ConversorCuotas = parseFloat(noCuotas);

  const valorExtraValorCobrado = conversionPlanValor + convertValorExtra;

  const valorIntsalacionYNumeroDeCuotas =
    valorExtraValorCobrado / ConversorCuotas;

  const numeroCuotasAPagar = parseFloat(valorACobrar) / parseFloat(noCuotas);

  const numeroCuotasAPagarFiltroNaN = Number.isNaN(Number(numeroCuotasAPagar)) || numeroCuotasAPagar === Infinity ? 0 : numeroCuotasAPagar

  //TOTAL CABLE

  const convertCableInicio = parseFloat(cableInicio);

  const convertCableFin = parseFloat(cableFinaliza);

  const sumaCableInicioYCableFinal = convertCableInicio - convertCableFin;

  const metrajeExtra = sumaCableInicioYCableFinal - 150;

  const setUpdateCaja = async (numFlota) => {
    await db
      .collection("tickets_tecnicos")
      .doc(numFlota)
      .update({
        caja: caja,
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          text: "Se ha actualizado la caja con exito",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

    setOpenCaja(false);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const [
    seriesMaterialesInstalacionAsignados,
    setSeriesMaterialesInstalacionAsignados,
  ] = useState([]);

  const handleChange = async (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...materialesInstalacionAsignados];
    const cantidad_pedida = list[index]["stock_actual"];
    const cantidad_entregada = parseInt(evnt.target.value);
    console.log(cantidad_pedida, "--", cantidad_entregada);
    if (cantidad_entregada > cantidad_pedida) {
      console.log("Si entra");
    } else if (Math.sign(evnt.target.value) === -1) {
      console.log("Numero negativo no pasa");
    } else {
      if (evnt.target) {
        const { name, value } = evnt.target;
        const list = [...materialesInstalacionAsignados];
        list[index][name] = value;

        const cantidad_recibida = list[index]["cant_entrega"];
        const diferencia_recepcion =
          list[index]["stock_actual"] - parseInt(cantidad_recibida);
        list[index]["cant_entrega"] = parseInt(cantidad_entregada);

        //
        console.log(list, "--");

        setMaterialesInstalacionAsignados(list);
        /* contador(); */
        console.log(materialesInstalacionAsignados, "input");

        const respJson = materialesInstalacionAsignados.some(
          (items) => items.cant_entrega
        );

        setIsDisabled(respJson);
      } else {
        const list = [...materialesInstalacionAsignados];
        list[index]["codigo_producto"] = evnt;
        setMaterialesInstalacionAsignados(list);

        const respJson = materialesInstalacionAsignados.some(
          (items) => items.cant_entrega
        );

        respJson(respJson);
      }
    }
  };

  /* FUNCION PARA LAS SERIES ENTREGADAS */

  const handleChangeSerieEntrega = async (index, evnt) => {
    console.log("evnt.target.value", evnt.target.value);
    console.log("index", index);

    const list = [...materialesInstalacionAsignados];
    const serie_entrega = evnt.target.value;
    console.log("evnt.target.value", evnt.target.value);
    list[index]["serie_entrega"] = serie_entrega;
    if (serie_entrega != undefined || serie_entrega != '') {
      setGuardarSerie(serie_entrega)
    }
  };

  // ONU ACTUAL A REEMPLAZAR

  const handleChangeOnuAReemplazar = async (index, evnt) => {
    const list = [...materialesInstalacionAsignados];
    const serie_entrega = evnt.target.value;
    console.log("evnt.target.value", evnt.target.value);
    list[index]["nueva_onu_a_reemplazar"] = serie_entrega;
    console.log(
      "materialesInstalacionAsignados",
      materialesInstalacionAsignados
    );
  };

  const closeModalUpdateCaja = () => {
    setOpenPuerto(true);
    setOpenCaja(false);
  };

  /* FOTOS */

  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [file6, setFile6] = useState(null);
  const [file8, setFile8] = useState(null);
  const [file9, setFile9] = useState(null);
  const [file10, setFile10] = useState(null);

  const [dataPhotos, setDataPhotos] = useState([]);

  const instalacionTicketsDetalle = useSelector(
    (state) => state.instalacionTicketsDetalle
  );
  const { instalacionTickets } = instalacionTicketsDetalle;

  const [uidROT, setUidROT] = useState('');

  const getNumRegistroOrdenTrabajo = () => {
    const ref = db.collection("registro_orden_trabajo").orderBy("created", "desc").limit(1)
    ref.onSnapshot(snapshot => {
      if (snapshot.size > 0) {
        const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
        const registro = solicitudes[0]
        const id = registro.uid + 1;
        setUidROT(id)
      }
    })
  }

  const dispatch = useDispatch();

  const imprimirId = () => {
    console.log("props.uid_ticket", props.uid_ticket);
    console.log("props.id", props.id);
  };

  const getPhotosCollectionFirebase = () => {
    const ref = db
      .collection("tickets_tecnicos")
      .where("uid_ticket", "==", props.uid_ticket);
    /* if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email) */

    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setDataPhotos(data);
    });
  };

  const getProductos = (e) => {
    let docRef = db.collection("productos")
    docRef.get().then((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      console.log(arrayMessage)
      setProductos(arrayMessage)
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  useEffect(() => {

    getProductos()

    loadNumEgreso()

    getNumRegistroOrdenTrabajo()

    getPhotosCollectionFirebase();

    /* dispatch(detallesInstalacionTickets(props.uid_ticket)) */

    /* setFile1(dataPhotos[0].foto_antes)

        setFile2(dataPhotos[0].foto_opcional1)

        setFile3(dataPhotos[0].foto_opcional2)

        setFile4(dataPhotos[0].foto_despues) */

    getMotivosRechazoTicket();

    getMaterialesUtilizados();

    loadNumSolicitudFlota();

    getMaterialesAsignados();
    /* setFile1(instalacion.foto_potencia_casa)
        setFile2(instalacion.foto_potencia_caja)
        setFile3(instalacion.foto_router_casa)
        setFile4(instalacion.foto_etiqueta)

        setFile6(instalacion.foto_caja) */

    /* getMateriales() */

    setNombrePlan(props.planNombre);
    const { one, two } = number;
    setSuma(Number(one) + Number(two));
    setValorCobrado(valorACobrar);
    //mensajeHidden()
    dispatch(listsMateriales());
  }, [dispatch, number]);

  const updateCoordenadasFunction = () => {
    db.collection('tickets').doc(props.id).update({
      coordenadas: coordenadas
    })

    db.collection('tickets_tecnicos').doc(props.id).update({
      coordenadas: coordenadas
    })

    setUpdateCoordenadas(false)

    setdialogCoordenadasActualizadoConExito(true)

  }

  function detalleMate(data, index) {
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell style={{ fontSize: "12px" }} component="th" scope="row">
          {index + 1}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.codigo}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.descripcion}
        </TableCell>
        <TableCell align="center">
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            name={"cantidad_pedida"}
            onChange={(e) => {
              handleChangeProducto(index, e)
            }}
            value={data.cantidad_pedida}
            size="small"
            type="number"
          />
        </TableCell>

        <TableCell align="center">
          <IconButton
            onClick={() => removeInputFields(data.codigo)}
            color="error"
          >
            <DeleteForeverIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  const addInput = (row) => {
    console.log(row, "row AddInput");
    if (inputFields.find((x) => x.fullName === row.codigo_producto)) {
      /* Swal.fire({
          icon: "info", 
          text: "Ya se agrego este producto a la lista",
      }); */
      setItem("");
      setModalProducts(false);
    } else {
      setInputFields([
        ...inputFields,
        {
          fullName: row.codigo_producto,
          cantidad_pedida: 0,
          descripcion: row.descripcion_producto,
          codigo: row.codigo_producto,
          categoria_producto: row.categoria_producto,
          subcategoria_producto: row.subcategoria_producto,
          marca_producto: row.marca_producto,
          modelo_producto: row.modelo_producto,
          presentacion_producto: row.presentacion_producto,
          series: [{ serie: 0 }],
          unidad_Medida_Producto: row.unidad_Medida_Producto,
          usuario_que_creo_el_producto: row.usuario_que_creo_el_producto,
          tipo_movimiento: "ingreso",
          precio_compra_producto: row.precio_compra_producto,
          usa_serie: row.usa_serie,
          costo_promedio: row.costo_promedio,
          id_producto: row.id
        },
      ]);
    }
    setItem("");
    setModalProducts(false);
  };

  const blockButtonAddItem = async (row) => {

    addInput(row)

  }

  const removeInputFields = (codigo) => {

    const rows = [...inputFields];

    const indexOfObject = rows.findIndex((object) => {
      return object.codigo === codigo;
    });

    rows.splice(indexOfObject, 1);

    setInputFields(rows);
  };

  const handleChangeProducto = async (index, evnt) => {
    if (Math.sign(evnt.target.value) === -1) {
      console.log("Numero negativo no pasa");
    } else if (evnt.target) {
      const { name, value } = evnt.target;
      const list = [...inputFields];
      list[index][name] = value;
      //
      setInputFields(list);
      //Cantidad
      //console.log(evnt.target.value, 'cantidad')
      const num = evnt.target.value;
      let array = [];
      for (let i = 0; i < num; i++) {
        //console.log('Hola')
        let n;
        n += i;
        //console.log('for', n)
        array.push({
          index_serie: index,
          id: i,
          serie: 0,
        });
      }
      list[index]["series"] = array;
      //
      console.log(list, "--");
      //console.log(inputFields, 'array')
    } else {
      const list = [...inputFields];
      list[index]["fullName"] = evnt;
      setInputFields(list);
    }
  };

  return (
    <>
      <Tooltip title="Gestionar">
        <Button onClick={() => setOpenPuerto(true) /* onData() */}>
          <EditIcon />
        </Button>
      </Tooltip>

      {/* ---------------------------------------------------------- */}
      {/* ACTUALIZAR CAJA */}
      <Dialog open={openCaja} onClose={() => setOpenCaja(false)} maxWidth="xs">
        <DialogTitle>
          Por favor asegurece de ingresar correctamente los datos.
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box py={1}>
              <FormControl fullWidth>
                <Box py={1}>
                  <TextField
                    size="small"
                    fullWidth
                    label="CAJA"
                    value={caja}
                    onChange={(e) => setCaja(e.target.value)}
                  />
                </Box>
              </FormControl>
            </Box>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeModalUpdateCaja()}>
            <strong>Cancelar</strong>
          </Button>
          <Button
            onClick={() => setUpdateCaja(props.numContrato)}
            size="small"
            variant="contained"
            color="secondary"
          >
            {" "}
            ACTUALIZAR CAJA
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------------------------------------------- */}
      {/* RECHAZO INSTALACION */}
      <Dialog
        open={openInstalacionRechazada}
        onClose={() => setOpenInstalacionRechazada(false)}
        maxWidth="xs"
      >
        <DialogTitle>
          Seleccione el motivo por el cual rechaza la visita.
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box py={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  value={tipoRechazoTicket}
                  onChange={(e) => setTipoRechazoTicket(e.target.value)}
                >
                  {tiposRechazosTickets &&
                    tiposRechazosTickets.map((row, key) => (
                      <MenuItem value={row.tipo}>{row.tipo}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            size="small"
          >
            <strong>Cancelar</strong>
          </Button>
          <Button
            disabled={tipoRechazoTicket === ""}
            onClick={() => rechazarInstalacion()}
            variant="contained"
            color="error"
            size="small"
          >
            {" "}
            Rechazar visita
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------------------------------------------- */}
      {/* CONFIRMAR RECHAZO INSTALACION */}
      <Dialog
        open={openSendInstalacionRechazada}
        onClose={() => setOpenSendInstalacionRechazada(false)}
        maxWidth="xs"
      >
        <DialogTitle>
          <strong>¿Esta seguro de rechazar esta instalacion?</strong>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
          ></LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            <strong>Cancelar</strong>
          </Button>
          <Button onClick={() => rechazarInstalacion()} variant="contained">
            {" "}
            Rechazar Instalacion
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------------------------------------- */}
      {/* COLOCACION DEL PUERTO */}
      <Dialog
        fullScreen
        open={openPuerto}
        fullWidth
        onClose={() => setOpenPuerto(false)}
        maxWidth="md"
      >
        {/* <DialogTitle><strong>ORDEN DE TRABAJO</strong></DialogTitle> */}
        <DialogContent dividers>
          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                <Box py={1} px={1}>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    VISITA
                  </p>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={2} px={2}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          ASUNTO TICKET
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.asunto_del_ticket}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            #TICKET
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.numero_ticket}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          #CONTRATO
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.numContrato}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            NOMBRE DEL CLIENTE
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.nombre_cliente}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          CEDULA
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.cedula}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            DIRECCION
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.direccion}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          COORDENADAS
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.coordenadas}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            CAJA
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.caja}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          ZONA
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.zona}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box pt={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            TELEFONO
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.telefono}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          TECNICO RESPONSABLE
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.lider_flota}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            FLOTA DE INSTALACION
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={props.flota}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          SERVICIO CONTRATADO
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={props.planNombre}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box py={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            VALOR COBRADO
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              type="number"
                              size="small"
                              fullWidth
                              value={valorACobrar}
                              onChange={(e) => setValorACobrar(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={6}>
                      <Grid item xs={2}>
                        <p
                          style={{
                            fontSize: "10px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          N CUOTAS
                        </p>
                      </Grid>
                      <Grid item xs={10}>
                        <FormControl fullWidth>
                          <TextField
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            type="number"
                            size="small"
                            fullWidth
                            value={noCuotas}
                            onChange={(e) => setNoCuotas(e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box pt={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            VALOR MENSUAL A PAGAR
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={parseFloat(
                                numeroCuotasAPagarFiltroNaN
                              ).toFixed(2)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box pt={2}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p
                            style={{
                              fontSize: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            CONTACTAR CON CLIENTE
                          </p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <Select
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                              }}
                              size="small"
                              value={contactarCliente}
                              onChange={(e) => setContactarCliente(e.target.value)}
                            >
                              <MenuItem value="SI">SI</MenuItem>
                              <MenuItem value="NO">NO</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box pt={2}>
                      <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                        <strong>FOTO VIVIENDA CLIENTE:</strong>
                      </p>
                    </Box>

                    <Box py={2}>
                      <img width={400} src={props.imagen} />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Box pt={2}>
                      <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                        <strong>FOTO OPCIONAL:</strong>
                      </p>
                    </Box>

                    <Box py={2}>
                      <img width={400} src={props.imagen_opcional} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                <Box py={1} px={1}>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    MATERIALES
                  </p>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={2} px={2}>
                <Box pb={1}>
                  <Button
                    variant='outlined'
                    color="success"
                    size="small"
                    onClick={() => setModalProducts(true)}>
                    Agregar material utilizado
                  </Button>
                </Box>

                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>NO.</TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <strong>CODIGO</strong>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <strong>DESCRIPCION</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ width: "160px", fontSize: "12px" }}
                        >
                          <strong>CANTIDAD</strong>
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "12px" }}>
                          <strong>ELIMINAR</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {inputFields.map((data, index) => {
                        return detalleMate(data, index);
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>


                <Box py={2} px={2}>

                  {/* MATERIALES ASIGNADOS */}

                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <Grid container spacing={6}>
                        <Grid item xs={2}>
                          <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SERIAL ONU</p>
                        </Grid>
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                              }}
                              size="small"
                              fullWidth
                              value={guardarSerie}
                              onChange={(e) => setGuardarSerie(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* MATERIALES UTILIZADOS */}

                    <Grid item xs={6}>
                    </Grid>

                  </Grid>

                </Box>
              </Box>


            </Paper>
          </Box>

          {/* <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>OBSERVACIONES EXTRA PYL</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>OBSERVACION SOBRE LA VISITA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.observacion_extra_pyl}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Paper>
                    </Box> */}

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                <Box py={1} px={1}>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    INFORMACION SOBRE LA VISITA
                  </p>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={2} px={2}>
                {/* MATERIALES ASIGNADOS */}

                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Box py={1} px={1}>
                      <Box pb={1}>
                        <Paper elevation={3}>
                          <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                            <Box py={1} px={1}>
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                  fontWeight: "bold",
                                }}
                              >
                                SOLUCION DE VISITA
                              </p>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>

                      {/* <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>MATERIALES ASIGNADOS</p> */}
                      <Paper elevation={3}>
                        <Box py={2} px={2}>
                          <Box pb={2}>
                            <Grid container spacing={6}>
                              <Grid item xs={2}>
                                <p
                                  style={{
                                    fontSize: "10px",
                                    marginBottom: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  ESCRIBA QUE SOLUCION TUVO LA VISITA
                                </p>
                              </Grid>
                              <Grid item xs={10}>
                                <FormControl fullWidth>
                                  <TextField
                                    sx={{
                                      "& legend": { display: "none" },
                                      "& fieldset": { top: 0 },
                                    }}
                                    size="small"
                                    fullWidth
                                    value={observacionesExtra}
                                    onChange={(e) =>
                                      setObservacionesExtra(e.target.value)
                                    }
                                    multiline
                                    rows={3}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>

                  {/* MATERIALES UTILIZADOS */}

                  <Grid item xs={6}>
                    <Box pb={1} px={1}>
                      <Box py={1}>
                        <Paper elevation={3}>
                          <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                            <Box py={1} px={1}>
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "0px",
                                  fontWeight: "bold",
                                }}
                              >
                                OBSERVACIONES EXTRA PYL
                              </p>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>

                      {/* <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>MATERIALES UTILIZADOS</p> */}
                      <Paper elevation={3}>
                        <Box py={2} px={2}>
                          <Box pb={2}>
                            <Grid container spacing={6}>
                              <Grid item xs={2}>
                                <p
                                  style={{
                                    fontSize: "10px",
                                    marginBottom: "10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OBSERVACION SOBRE LA VISITA
                                </p>
                              </Grid>
                              <Grid item xs={10}>
                                <FormControl fullWidth>
                                  <TextField
                                    sx={{
                                      "& legend": { display: "none" },
                                      "& fieldset": { top: 0 },
                                    }}
                                    size="small"
                                    fullWidth
                                    value={props.observacion_extra_pyl}
                                    multiline
                                    rows={3}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>

                {/* MOTIVO REASIGNAMIENTO TICKET */}

                {
                  props.reagendado === false || props.reagendado === undefined || props.reagendado === null ? '' : <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <Box py={1} px={1}>
                        <Box pb={1}>
                          <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                              <Box py={1} px={1}>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    marginBottom: "0px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  OBSERVACION DE REAGENDAMIENTO
                                </p>
                              </Box>
                            </Box>
                          </Paper>
                        </Box>

                        {/* <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>MATERIALES ASIGNADOS</p> */}
                        <Paper elevation={3}>
                          <Box py={2} px={2}>
                            <Box pb={2}>
                              <Grid container spacing={6}>
                                <Grid item xs={2}>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      marginBottom: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    DETALLE DEL REAGENDAMIENTO
                                  </p>
                                </Grid>
                                <Grid item xs={10}>
                                  <FormControl fullWidth>
                                    <TextField
                                      sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                      }}
                                      size="small"
                                      fullWidth
                                      value={props.observacion_reagendamiento}
                                      multiline
                                      rows={3}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                }

              </Box>
            </Paper>
          </Box>

          <Box py={1} px={1}>
            <Paper elevation={3}>
              <Box py={1} px={1} sx={{ bgcolor: "#ececec" }}>
                <Box py={1} px={1}>
                  <p
                    style={{
                      fontSize: "14px",
                      marginBottom: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    FOTOS
                  </p>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box px={1} py={1}>
            <Paper elevation={3}>
              <Box py={2} px={2}>
                {/* <Alert severity="info">Recuerde, debe subir fotos claras al sistema de la instalacion</Alert> */}

                <Grid container>
                  <Grid md={6}>
                    <Box pb={5}>
                      <Typography>Foto de la ONU antes de Solución</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_antes ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_antes} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile1}
                        estado={file1}
                        nameFile="foto_antes"
                      />
                    )}

                    <Box py={5}>
                      <Typography>Potencia casa del cliente antes de solución</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_opcional1 ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_opcional1} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile2}
                        estado={file2}
                        nameFile="foto_opcional1"
                      />
                    )}
                  </Grid>
                  <Grid md={6}>
                    <Box pb={5}>
                      <Typography>Foto de la ONU despues de la solución</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_despues ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_despues} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile4}
                        estado={file4}
                        nameFile="foto_despues"
                      />
                    )}

                    <Box py={5}>
                      <Typography>foto del daño encontrado</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_opcional2 ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_opcional2} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile3}
                        estado={file3}
                        nameFile="foto_opcional2"
                      />
                    )}
{
  //jesus
}
<Box py={5}>
                      <Typography>Test de velocidad</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_test ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_test} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile8}
                        estado={file8}
                        nameFile="foto_test"
                      />
                    )}
{
  //jesus
}

<Box py={5}>
                      <Typography>Foto de potencia en casa cliente despues de la solución</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_potencia ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_potencia} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile9}
                        estado={file9}
                        nameFile="foto_potencia"
                      />
                    )}

<Box py={5}>
                      <Typography>Foto de solución dada</Typography>
                      <Divider />
                    </Box>
                    {dataPhotos[0] && dataPhotos[0].foto_solucion ? (
                      <>
                        <img width={100} src={dataPhotos[0].foto_solucion} />
                        <Alert>Archivo subido</Alert>
                      </>
                    ) : (
                      <UpdateFileUploadTicketsInstaladores
                        instalacion={props}
                        cambiarEstado={setFile10}
                        estado={file10}
                        nameFile="foto_solucion"
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          {/* <Button
            color="error"
            variant="outlined"
            size="small"
            onClick={() => {
              console.log("instalacionTickets", instalacionTickets);
              console.log("props", props);
              console.log("props.id", props.id);
              console.log("dataPhotos", dataPhotos);
              console.log("dataPhotos.foto_antes", dataPhotos[0].foto_antes);
            }}
          >
            Get Data Pictures
          </Button> */}
          <Button
            color="warning"
            variant="outlined"
            size="small"
            onClick={() => setOpenPuerto(false)}
          >
            <strong>REGRESAR</strong>
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            size="small"
            onClick={() => setUpdateCoordenadas(true)}>
            <strong>ACTUALIZAR COORDENADAS</strong>
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={() => {
              setOpenInstalacionRechazada(true);
            }}
          >
            RECHAZAR VISITA
          </Button>
          <Button
            disabled={
              /* noCuotas === ''
                            || */ /* isDisabled == false
|| */
              !observacionesExtra ||
              !dataPhotos[0].foto_antes ||
              !dataPhotos[0].foto_despues ||
              !dataPhotos[0].foto_test ||
              !dataPhotos[0].foto_potencia
              /* || !instalacion.foto_potencia_casa
                            || !instalacion.foto_potencia_caja
                            || !instalacion.foto_router_casa
                            || !instalacion.foto_etiqueta
                            || !instalacion.foto_caja */
            }
            size="small"
            color="secondary"
            disableElevation
            onClick={
              /* handleSubmitEgreso() */ () => setOpenConfirmInstalacion(true)
            }
            variant="contained"
          >
            CONFIRMAR VISITA
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------------------------------------- */}
      {/* CONFIRMACION DE LA INSTALACION */}
      <Dialog
        open={openConfirmInstalacion}
        onClose={() => setOpenConfirmInstalacion(false)}
        maxWidth="xs"
      >
        <DialogContent>
          Por favor, asegúrese que ha llenado todos los campos necesarios.
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={loadingConfirmInstalacion}
            endIcon={loadingConfirmInstalacion === true ? <SendIcon /> : ''}
            loadingPosition="end"
            onClick={() => setOpenConfirmInstalacion(false)}
            size="small"
            color="primary"
            variant="outlined"
          >
            <strong>Cancelar</strong>
          </LoadingButton>
          <LoadingButton
            loading={loadingConfirmInstalacion}
            endIcon={loadingConfirmInstalacion === true ? <SendIcon /> : ''}
            loadingPosition="end"
            /* component={RouterLink} */
            size="small"
            color="secondary"
            onClick={() => InstalacionRealizada(props.id, props.numFlota)}
            variant="contained"
          /* to={`/Instaladores/subir-foto/${props.id}`} */
          >
            {" "}
            Confirmar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* ACTUALIZAR COORDENADAS */}
      <Dialog open={updateCoordenadas} onClose={() => setUpdateCoordenadas(false)} maxWidth="xs">
        <DialogContent>
          Por favor, asegúrese de que las coordenadas esten correctas.
          <Box py={2}>
            <Grid container spacing={6}>
              <Grid item xs={2}>
                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>Coordenadas</p>
              </Grid>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    fullWidth
                    value={coordenadas}
                    onChange={(e) => setCoordenadas(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setUpdateCoordenadas(false)} size="small" color="primary" variant="outlined" ><strong>Regresar</strong></Button>
          <Button
            size="small"
            color='secondary'
            onClick={() => updateCoordenadasFunction()}
            variant="contained">
            {" "}
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>

      {/* --------------------------------------------------------------- */}
      {/* DIALOG VALOR COBRADO ACTUALIZADO CON EXITO */}
      <Dialog open={dialogCoordenadasActualizadoConExito} onClose={() => setdialogCoordenadasActualizadoConExito(false)} maxWidth="xs">
        <DialogContent>Se han actualizado las coordenadas correctamente.</DialogContent>

        <DialogActions>
          <Button onClick={() => setdialogCoordenadasActualizadoConExito(false)} size="small" color="primary" variant="outlined" ><strong>Ok</strong></Button>
        </DialogActions>
      </Dialog>

      {/* MODAL PRODUCTOS INVENTARIO */}

      <Dialog
        open={modalProducts}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          AGREGAR ITEMS
        </DialogTitle>
        <DialogContent dividers >
          <Box pb={3}>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              options={productos}
              clearOnBlur={false}
              autoComplete={false}
              disableClearable
              getOptionLabel={(option) => option.descripcion_producto}
              sx={{ width: 300 }}
              onInputChange={(e, newInputValue) => {
                console.log(item)
                setItem(newInputValue)
              }}
              renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>CODIGO</TableCell>
                  <TableCell>DESCRIPCION</TableCell>
                  <TableCell>ACCIONES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productos && productos
                  .filter((val) => {
                    if (item === "") {
                      return val;
                    } else if (val.descripcion_producto.toLowerCase().includes(item.toLowerCase())) {
                      return val;
                    }
                  }).slice(0, 10).map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row.codigo_producto}</TableCell>
                      <TableCell>{row.descripcion_producto}</TableCell>
                      <TableCell><Button
                        size='small'
                        variant='contained'
                        color='success'
                        onClick={() => blockButtonAddItem(row)
                                                            /* onClick={() => addInput(row) */}
                      >Agregar</Button></TableCell>
                    </TableRow>
                  ))}

              </TableBody>
            </Table>
          </TableContainer>
          <Box py={5} ></Box>
        </DialogContent>
        <DialogActions dividers>
          <Button onClick={() => setModalProducts(false)}>
            Cerrar ventana
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};
