import {
    Typography,
    Card,
    CardContent,
    Grid,
    Button,
    Select,
    MenuItem,
    Divider,
    Box,
    TextField,
    FormControl,
    CircularProgress,
    Stack,
    DialogTitle,
    Dialog,
} from '@mui/material'
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { db, firebase, storage } from '../../firebase';
import Swal from "sweetalert2";
import { listsDepartamentos } from '../../redux/actions/parametersActions';
export const RequerimientosMarketing = () => {
    const useStorage = storage;
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [numPedido, setNumPedido] = useState(false)
    //
    const [loading, setLoading] = useState(false)
    //
    const departamentosList = useSelector((state) => state.departamentosList);
    const { departamentos } = departamentosList;
    const [departamento, setDepartamento] = useState("");

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listsDepartamentos())
    }, [dispatch]);

    useEffect(() => {
        loadNumRequerimiento()
    }, []);
    const [tarea, setTarea] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const loadNumRequerimiento = () => {
        const ref = db.collection("requerimientos_marketing").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                console.log(solicitud.numero_requerimiento)
                setNumPedido(solicitud.numero_requerimiento + 1)
            } else {
                setNumPedido(0)
            }
        })
    }
    const [file, setFile] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [Imagen, setImagen] = useState('');
    const [viewImagen, setViewImagen] = useState(null)
    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
    }

    const changeArchivo = async (e) => {
        setArchivo(e.target.files[0]);
        setFile(e.target.files[0].name)
    }
    const enviarRequerimientoo = async () => {
        setLoading(true)
        let imagenUrl = '';
        let archivoUrl = '';
        const uidSolicitud = `REQ-MARKETING-` + numPedido;
        if (viewImagen) {
            const newRef = useStorage.ref('requerimientos_marketing').child(uidSolicitud).child('IMAGEN_REQUERIMIENTO')
            await newRef.put(Imagen);
            const urlImagen = await newRef.getDownloadURL()
            imagenUrl = urlImagen;
            console.log('la url de la imagen es' + urlImagen);
        }
        if (archivo) {
            const newRef2 = useStorage.ref('requerimientos_marketing').child(uidSolicitud).child('SOLICITUD_'+file)
            await newRef2.put(archivo);
            const urlArchivo = await newRef2.getDownloadURL()
            archivoUrl = urlArchivo;
            console.log('la url del archivo es' + urlArchivo);
        }
        await db.collection('requerimientos_marketing').doc(uidSolicitud).set({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            numero_requerimiento: numPedido,
            departamento: departamento,
            asignado: '',
            uid_requerimiento: uidSolicitud,
            requerimiento: tarea.toUpperCase(),
            descripcion: descripcion.toUpperCase(),
            estado: 0,
            observacion: '',
            imagen: imagenUrl,
            archivo: archivoUrl,
            nombreArchivo: 'SOLICITUD_'+file,
            user: userInfo.nombres,
        }).then(() => {
            setFile('')
            Swal.fire({ icon: "success", text: "Se ha enviado su solicitud de requerimiento con exito", });
            setLoading(false)
            setDescripcion('')
            setTarea('')
            setArchivo(null)
            setViewImagen(null)
            setImagen('')
            setDepartamento('')
        })
            .catch((error) => {
                setLoading(false)
                setDepartamento('')
                setFile('')
                setImagen('')
                setArchivo(null)
                setViewImagen(null)
                Swal.fire({ icon: "error", text: "Error al enviar la solicitud.", });
                console.error("Error adding document: ", error);
            });
    }
    return (
        <>
            <Card>
                <CardContent>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <Typography gutterBottom >NUMERO DE REQUERIMIENTO</Typography>
                            <Typography >REQ-MARKETING-{numPedido}</Typography>
                            <Box pt={1} pb={1}>
                                <Typography gutterBottom >FECHA DE CREACION</Typography>
                                <Typography >{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                            </Box>
                            <Divider />
                        </Grid>
                        <Grid item md={6}>
                            <Typography gutterBottom >USUARIO QUE SOLICITA EL REQUERIMIENTO</Typography>
                            <Typography >{userInfo.displayName}</Typography>
                        </Grid>
                    </Grid>
                    <Box py={1}></Box>
                    <Grid container spacing={1}>
                        <Grid item md={6}>
                            <Box pb={2}>
                                <FormControl fullWidth>
                                    <Typography gutterBottom >REQUERIMIENTO</Typography>
                                    <TextField
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        size="small"
                                        fullWidth
                                        value={tarea}
                                        onChange={(e) => setTarea(e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Typography gutterBottom >DEPARTAMENTO</Typography>
                            <FormControl fullWidth>
                                <Select sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    size="small"
                                    value={departamento}
                                    label="MODELO"
                                    onChange={(e) => setDepartamento(e.target.value)}>
                                    {departamentos ?
                                        departamentos.map(item => (
                                            <MenuItem key={item.departamento} value={item.departamento} >
                                                {item.departamento}
                                            </MenuItem>
                                        )) : <></>}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Box pb={2}>
                        <FormControl fullWidth>
                            <Typography gutterBottom >DESCRIPCION</Typography>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                rows={5}
                                multiline
                                fullWidth
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box display="flex" flexDirection="row-reverse" pb={2}>
                        <FormControl >
                            {
                                viewImagen === null ? <Button
                                    size="small"
                                    onChange={changeImagen}
                                    variant='outlined'
                                    component="label"
                                >SUBIR IMAGEN
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button> : <Button
                                    size="small"
                                    onChange={changeImagen}
                                    variant='outlined'
                                    component="label"
                                >CAMBIAR IMAGEN
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button>
                            }
                        </FormControl>
                    </Box>
                    <Box display="flex" flexDirection="row-reverse" py={1}>
                        <img style={{ width: "10%" }} src={viewImagen} />
                    </Box>

                    <Box display="flex" flexDirection="row-reverse" pb={2}>
                        <FormControl >
                            {
                                archivo === null ? <Button
                                    size="small"
                                    onChange={changeArchivo}
                                    variant='outlined'
                                    component="label"
                                >SUBIR ARCHIVO
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button> : <Button
                                    size="small"
                                    onChange={changeArchivo}
                                    variant='outlined'
                                    component="label"
                                >CAMBIAR ARCHIVO
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button>
                            }
                        </FormControl>
                    </Box>

                    <Box display="flex" flexDirection="row-reverse">
                        <Typography sx={{ fontSize: '14px' }}> <strong>Archivo: </strong> {file}
                        </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row-reverse" pt={1}>
                        <Button
                            disabled={
                                tarea === ''
                                || descripcion === '' || departamento === '' ||
                                loading
                            }

                            size="small"
                            onClick={() => enviarRequerimientoo()}
                            variant="contained"
                            component="label"
                        >ENVIAR REQUERIMIENTO
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>ENVIANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    )
}