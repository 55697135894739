import {
    Box,
    Button,
    Container,
    Grid
} from '@mui/material'
import React from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack } from '@mui/system';
import logo1 from '../../../assets/img/contrato/image005.jpg'


export const Anexo3 = () => {
    const container = React.useRef(null);

    const Plantilla1 = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                </div>
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                </p>
            </div >
        )
    }

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: Plantilla1,
            paperSize: "A4",
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.6,
            fileName: `ANEXO 3`,
        });

    };


    return (
        <>
            <Container>
                <div className="example-config">
                    <Button
                        onClick={exportPDFWithMethod}
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        color="error" size='small' variant="outlined"
                        startIcon={<PictureAsPdfIcon />}>
                        <strong>PDF</strong>
                    </Button>
                </div>
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth="md" >
                            <Grid container spacing={1} sx={{ fontSize: "12px", marginTop: "5px" }}>
                                <Grid item xs={12} sx={{ marginBottom: "10px", fontSize: "16px" }}>
                                    <p align="center"><strong>ANEXO 3 <br />
                                        AUTORIZACIÓN DE USO DE INFORMACION PERSONAL </strong></p>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                    <p align="justify">“Autorizo(amos) expresa e irrevocablemente a
                                        INTERCOMMERCE S.A., o a quien sea el futuro cesionario, beneficiario
                                        o acreedor del crédito solicitado o del documento o título cambiario
                                        que lo respalde para que obtenga cuantas veces sean necesarias, de
                                        cualquier fuente de información, incluidos los burós de crédito, mi
                                        información de riesgos crediticios, de igual forma INTERCOMMERCE S.A.,
                                        o quien sea el futuro cesionario, beneficiario o acreedor del crédito
                                        solicitado o del documento o título cambiario que lo respalde queda
                                        expresamente autorizado para que pueda transferir o entregar dicha
                                        información a los burós de crédito y/o a la Central de Riesgos si
                                        fuere pertinente”. </p>
                                </Grid>

                                <Grid item xs={2} sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                    <p><strong>ACEPTACIÓN:</strong></p>
                                </Grid>
                                <Grid item xs={1} sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                    <p>SI ({'    '}) </p>
                                </Grid>
                                <Grid item xs={1} sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                    <p>NO ({'    '}) </p>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '12px' }}>
                                <Grid item xs={5}>
                                    <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                        <p align="center" ><strong>
                                            PRESTADOR DEL SERVICIO <br />
                                            ING. JUAN CARLOS GONZALEZ ORTEGA <br />
                                            REPRESENTANTE DE INTERCOMMERCE S:A. <br />
                                            RUC: 0992988061001 <br />
                                        </strong></p>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                        <p align="center" ><strong>
                                            ABONADO/SUSCRIPTOR <br />
                                            NOMBRE:  <br />
                                            CRI/RUC: <br /><br />
                                        </strong></p>
                                    </Box>
                                </Grid>
                            </Grid >
                        </Container>
                    </div>
                </PDFExport>
            </Container>

        </>
    )
}
