import { 
    Card, 
    CardContent,
    Typography,
    Box,
    Grid,
    FormControl,
    TextField,
    Select,
    MenuItem,
    Paper,
    Button,
    Stack} from "@mui/material"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DataGrid, esES } from "@mui/x-data-grid";
import { useState } from "react";
import moment from 'moment'

export const ConciliacionBanco = () => {
    const [fecha, setFecha] = useState(new Date())
    const [codigo_entidad, setCodigo_entidad] = useState('')
    const [cargado, setCargado] = useState([])
    const [count, setCount] = useState(0)
    function getDatos(){
        let cod = codigo_entidad.split('-')[0]
        let date = moment(fecha.toString()).format('YYYYMMDD')
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiArchivoConciliacion/?fechacontable=${date}&codbancario=${cod}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let carga = []
                const re = JSON.parse(result)
                re.forEach((data)=>{
                    let key = Object.keys(data)[0]
                    let dato = {
                        data: data[key]
                    }
                    carga.push(dato)
                })
                setCargado(carga)
                setCount(carga.length-1)
            }).catch(error => console.log('error', error));
        
    }
    let columns = [
        { field: 'data', headerClassName: 'super-app-theme--header', headerName: 'Codigos de Pagos', flex: 1 },
    ]
    function sendData(){
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let cod = codigo_entidad.split('-')[1]
        let date = moment(fecha.toString()).format('YYYYMMDD')
        let text = '';
        cargado.forEach((data)=>{
            text += data.data + "\n"
        })
        text.replace(/\n$/, '');
        let raw = JSON.stringify({
          "datos": text,
          "nombre_doc": 'INTER'+cod+date+'.CON'
        })
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw
        }
        fetch("http://192.168.90.161:3000/funcion/conciliacionBancos/", requestOptions)
        .then(response => response.text())
        .then(result => {
            const data = JSON.parse(result)
            console.log(data)
        })
    }

    const createAndDownloadTextFile = () => {
        let cod = codigo_entidad.split('-')[1]
        let date = moment(fecha.toString()).format('DDMMYY')
        let text = '';
        cargado.forEach((data)=>{
            text += data.data + "\n"
        })
        text.replace(/\n$/, '');
        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'INTER'+cod+date+'.CON';
        a.click();
        URL.revokeObjectURL(url);
      };
    return (
        <Card>
                <CardContent>
                    <Typography variant="h5"><strong>GENERACION DE ARCHIVO DE CONCILIACION</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={3} lg={3} xs={6}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: '14px' }}><strong>Fecha Contable: *</strong></Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl fullWidth>
                                        <DateTimePicker
                                            renderInput={(props) => <TextField {...props} sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }} size="small" />}
                                            value={fecha}
                                            onChange={(newValue) => {
                                                setFecha(newValue);
                                            }}
                                            inputFormat="yyyy/MM/dd"
                                        />
                                    </FormControl>
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3} xs={6}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}>
                                    <strong>Código Entidad: *</strong>
                                </Typography>
                                <Select
                                    fullWidth
                                    size="small"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                        fontSize: '14px'
                                    }}
                                    value={codigo_entidad}
                                    onChange={(e) => {
                                        setCargado([])
                                        setCodigo_entidad(e.target.value)
                                    }}
                                >
                                    <MenuItem key={0} value="008-GYE">008-GUAYAQUIL</MenuItem>
                                    <MenuItem key={1} value="004-PRO">004-PRODUBANCO</MenuItem>
                                    <MenuItem key={2} value="003-WUN">003-WESTERN</MenuItem>
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item md={3} lg={3} xs={6}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}>
                                    <strong>Número de transacciones</strong>
                                </Typography>
                                <Typography sx={{ fontSize: "25px" }}>
                                    <strong>{count}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                disabled={
                                    codigo_entidad === ''
                                }
                                onClick={() => {
                                    getDatos()
                                }}
                                variant="contained" color='success'>
                                Realizar Consulta
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{
                                    height: 'auto', 
                                    '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        autoHeight
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        getRowId={(row) => cargado.indexOf(row)}
                                        rows={cargado}
                                        pageSize={15}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} >
                            <Stack sx={{alignItems:'end', alignSelf:'end'}}>
                                <Button
                                    disabled={cargado.length === 0}
                                    onClick={() => {
                                        sendData()
                                        createAndDownloadTextFile()
                                    }}
                                    variant="contained" color='primary'>
                                    Descargar Consulta
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid> 
                </CardContent>
            </Card>
    )
}