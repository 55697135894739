import React, { useState, useEffect } from "react";
import {
    Grid, Box, Button, Select, FormControl,
    MenuItem, Typography, InputLabel, CardContent
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import InputValidation from "../../../components/utils/InputValidation";
import { db, functions } from '../../../firebase'

export const CrearVendedor = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [correo, setCorreo] = useState({ campo: '', valido: true })
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: true })
    //
    const [lider, setLider] = useState([])
    const [centro, setCentro] = useState([])
    const [categoria, setCategoria] = useState([])
    const [canal, setCanal] = useState([])
    //
    const [list_lider, setListLider] = useState([])
    const [list_centro, setListCentro] = useState([])
    const [list_categoria, setListCategoria] = useState([])
    const [list_canal, setListCanal] = useState([])
    //
    const [lider_, setLider_] = useState('')
    const [centro_, setCentro_] = useState('')
    const [categoria_, setCategoria_] = useState('')
    const [canal_, setCanal_] = useState('')
    //
    const dispatch = useDispatch()
    //
    useEffect(() => {
        loadRoles()
    }, [dispatch])
    const loadRoles = () => {
        db.collection('system/centro_de_costos/lider_de_ventas').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListLider(arrayMessage)
        })
        db.collection('system/centro_de_costos/centro_de_costos').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCentro(arrayMessage)
        })
        db.collection('system/centro_de_costos/categoria').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCategoria(arrayMessage)
        })
        db.collection('system/centro_de_costos/canal').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCanal(arrayMessage)
        })
    }
    const onSubmit = () => {
        setLoading(true)
        const data = {
            nombres: nombre.campo,
            cedula: '',
            direccion: '',
            sangre: '',
            fechaNacimiento: '',
            departamento: 'VENTAS',
            email: correo.campo,
            password: password.campo,
            roles: [
                'ventas',
            ],
            vendedor: {
                codigos_vendedor: [
                    {
                        id: '001',
                        codigo: '001',
                        nombre: `VENDEDOR ${nombre.campo}`
                    },
                ],
                lider_venta: lider,
                centro_costo: centro,
                categoria: categoria,
                canal: canal
            },
            creado_por: userInfo.uid,
        }
        console.log(data, 'data2')
        const createUser = functions.httpsCallable('createUser')
        createUser(data).then(res => {
            setLoading(false)
            console.log(res, 'se creo')
            Swal.fire({ text: "Se creo nuevo usuario" })
            setNombre({ campo: '', valido: true })
            setPassword({ campo: '', valido: true })
            setCorreo({ campo: '', valido: true })
        }).catch(e => {
            alert(e)
            setLoading(false)
        })
    }
    const onUpdate = () => {
        const data = {
            vendedor: {
                lider_venta: lider_,
                centro_costo: centro_,
                categoria: categoria_,
                canal: canal_
            }
        }
        db.collection('usuarios').doc(userInfo.uid).update(data).then(() => {
            Swal.fire({ icon: 'success', text: "Se actualizo su centro de costo en su usuario, por favor refrescar la pagina." })
            dispatch({
                type: 'AUTH_ADD_ITEMS',
                payload: data
            })
        })
    }
    return (
        <>
            <CardContent>
                <Grid container spacing={7}>
                    <Grid item xs={12} md={6}>
                        <InputValidation
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="NOMBRES Y APELLIDOS"
                            name="NOMBRE"
                        />
                        <Box py={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >LIDER DE VENTA</InputLabel>
                                <Select
                                    label="LIDER DE VENTA"
                                    value={lider}
                                    onChange={(e) => setLider(e.target.value)}>
                                    {list_lider.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box pt={3} pb={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CENTRO DE COSTO</InputLabel>
                                <Select
                                    label="CENTRO DE COSTO"
                                    value={centro}
                                    onChange={(e) => setCentro(e.target.value)}>
                                    {list_centro.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>

                        <Box pt={3} pb={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CATEGORIA</InputLabel>
                                <Select
                                    label="CATEGORIA"
                                    value={categoria}
                                    onChange={(e) => setCategoria(e.target.value)}>
                                    {list_categoria.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box py={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CANAL</InputLabel>
                                <Select
                                    label="CANAL"
                                    value={canal}
                                    onChange={(e) => setCanal(e.target.value)}>
                                    {list_canal.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                    </Grid>
                </Grid>
                <Box>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <InputValidation
                                estado={correo}
                                cambiarEstado={setCorreo}
                                label="CORREO ELECTRÓNICO"
                                name="correo"
                                patterns={/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/}
                                helperText="Solo se aceptan correos electronicos"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputValidation
                                estado={password}
                                cambiarEstado={setPassword}
                                label="CONTRASEÑA"
                                name="password"
                                helperText="Minimo 8 caracteres"
                                patterns={/^.{8,30}$/}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" flexDirection="row-reverse" >
                    <Button
                        disabled={loading || password.valido == false || correo.valido == false}
                        onClick={onSubmit}
                        variant="contained"
                    >Crear usuario
                    </Button>
                </Box>
                <Typography>ACTUALIZAR CENTRO DE COSTO</Typography>
                <Grid container spacing={7}>
                    <Grid item xs={12} md={6}>
                        <Box py={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >LIDER DE VENTA</InputLabel>
                                <Select
                                    label="LIDER DE VENTA"
                                    value={lider_}
                                    onChange={(e) => setLider_(e.target.value)}>
                                    {list_lider.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box pt={3} pb={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CENTRO DE COSTO</InputLabel>
                                <Select
                                    label="CENTRO DE COSTO"
                                    value={centro_}
                                    onChange={(e) => setCentro_(e.target.value)}>
                                    {list_centro.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box pt={3} pb={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CATEGORIA</InputLabel>
                                <Select
                                    label="CATEGORIA"
                                    value={categoria_}
                                    onChange={(e) => setCategoria_(e.target.value)}>
                                    {list_categoria.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box py={2}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel >CANAL</InputLabel>
                                <Select
                                    label="CANAL"
                                    value={canal_}
                                    onChange={(e) => setCanal_(e.target.value)}>
                                    {list_canal.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" >
                    <Button
                        disabled={loading || password.valido == false || correo.valido == false}
                        onClick={onUpdate}
                        variant="contained"
                    >Actualizar usuario
                    </Button>
                </Box>
            </CardContent>
        </>
    );
};
