import { Table, Box, Button, Collapse, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from 'moment'
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx/xlsx.mjs'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import IconButton from '@mui/material/IconButton';
import { UpdateCambioDomicilio } from "./cambio_domicilio/UpdateCambioDomicilio";
export const CambioDeDomicilio = () => {
  function Estado({ estado }) {
    let name;
    let color;

    //Controlamos el estado de la factiblidad
    if (estado.estado === 0) {
      name = 'Por agendar'
      color = 'warning'
    } else if (estado.estado === 5) {
      name = 'Realizada'
      color = 'success'
    } else if (estado.cambio_domicilio === true) {
      name = 'Cambio Domicilio'
      color = 'warning'
    }
    return (
      <Chip label={name} color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  //
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  //
  useEffect(() => {
    LoadData();
  }, []);
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  const LoadData = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("agenda").where("estado", "==", 1).where("cambio_domicilio", "==", true)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ]
        setData(data)
      })
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    const reData = [
      ...data.map(doc => {
        return {
          CEDULA: doc.cedula,
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,
          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,
          ASESOR: doc.vendedor,
          created: doc.created.toDate(),
        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }
  return (
    <>
      <Typography>Agenda/Cambio Domicilio</Typography>
      <Box pl={2} pt={2} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />onClick={() => LoadData()}
            <Button disableElevation variant='contained' onClick={LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <Box>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' style={{ textTransform: 'uppercase' }} py={2} pl={2}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>
      <TableContainer sx={{ maxHeight: 540 }}>
        <Table size="small" stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>FECHA CREACION DE SOLICITUD</TableCell>
              <TableCell>#CONTRATO</TableCell>
              <TableCell>ESTADO</TableCell>
              <TableCell>CEDULA</TableCell>
              <TableCell>NOMBRE DEL CLIENTE</TableCell>
              <TableCell>TELEFONO</TableCell>
              <TableCell>CELULAR</TableCell>
              <TableCell>WHATSAPP</TableCell>
              <TableCell>#REFERENCIA</TableCell>
              <TableCell>FECHA NACIMIENTO</TableCell>
              <TableCell>DIRECCION</TableCell>
              <TableCell>SECTOR</TableCell>
              <TableCell>CANTON</TableCell>
              <TableCell>COORDENADAS</TableCell>
              <TableCell>TERCERA EDAD</TableCell>
              <TableCell>DISCAPACIDAD</TableCell>
              <TableCell>GRADO DISCAPACIDAD</TableCell>
              <TableCell>DESCUENTO</TableCell>
              <TableCell>VALOR DESCUENTO</TableCell>
              <TableCell>PLAN CONTRATADO</TableCell>
              <TableCell>TIPO CONTRATO</TableCell>
              <TableCell>NUMERO FLOTA</TableCell>
              <TableCell>NOMBRE LIDER</TableCell>
              <TableCell>NOMBRE AUXILIAR</TableCell>
              <TableCell>VALOR PLAN</TableCell>
              <TableCell>FECHA INSTALACION</TableCell>
              <TableCell>NOMBRE DEL VENDEDOR</TableCell>
              <TableCell>CODIGO DEL VENDEDOR</TableCell>
              <TableCell>CENTRO DE COSTO</TableCell>
              <TableCell>CANAL DE VENTAS</TableCell>
              <TableCell>LIDER DE VENTA</TableCell>
              <TableCell>CATEGORIA</TableCell>
              <TableCell>USUARIO QUE GENERA CAMBIO DE DOMICILIO</TableCell>
              <TableCell>ACCIONES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, key) => (
              <TableRow key={key}>
                {/* fecha de creaacion */}
                <TableCell component="th" scope="row">
                  {row.created && typeof row.created === 'number' &&
                    moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                  {row.created && typeof row.created === 'object' &&
                    moment(row.created.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.numContrato}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Estado estado={row} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.cedula}
                </TableCell>
                {/* Nombres */}
                <TableCell component="th" scope="row">
                  {row.nombres}
                </TableCell>
                {/* Telefono */}
                <TableCell component="th" scope="row">
                  {row.telefono}
                </TableCell>
                {/* celular */}
                <TableCell component="th" scope="row">
                  {row.celular}
                </TableCell>
                {/* Whatsapp */}
                <TableCell component="th" scope="row">
                  {row.whatsapp}
                </TableCell>
                {/* numReferencia */}
                <TableCell component="th" scope="row">
                  {row.numbRef}
                </TableCell>
                {/* Fecha Nacimiento */}
                <TableCell component="th" scope="row">
                  {row.fechaNacimiento}
                </TableCell>
                {/* Direccion */}
                <TableCell component="th" scope="row">
                  {row.direccion}
                </TableCell>
                {/* Sector */}
                <TableCell component="th" scope="row">
                  {row.sector}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.canton}
                </TableCell>
                {/* Coordenadas */}
                <TableCell component="th" scope="row">
                  {row.coordenadas._lat},{row.coordenadas._long}
                </TableCell>

                {/* Tercera Edad */}
                <TableCell component="th" scope="row">
                  {row.terceraEdad === 1 ? 'SI' : 'NO'}
                </TableCell>
                {/* Nombres */}
                <TableCell component="th" scope="row">
                  {row.discapacitado === 1 ? 'SI' : 'NO'}
                </TableCell>
                {/* Nombres */}
                <TableCell component="th" scope="row">
                  {row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.discapacidad}
                </TableCell>
                {/* Descuento */}
                <TableCell component="th" scope="row">
                  {
                    row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.descuento
                  }
                </TableCell>
                {/* Valor */}
                <TableCell component="th" scope="row">
                  {
                    row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.valor
                  }
                </TableCell>

                {/* planContratado */}
                <TableCell component="th" scope="row">
                  {row.planNombre}
                </TableCell>
                {/* tipoContrato */}
                <TableCell component="th" scope="row">
                  {row.contrato}
                </TableCell>
                {/* Nombre del lider */}
                <TableCell component="th" scope="row">
                  {row.numFlota}
                </TableCell>
                {/* Nombre del lider */}
                <TableCell component="th" scope="row">
                  {row.nombreLider}
                </TableCell>
                {/* Nombre Auxiliar */}
                <TableCell component="th" scope="row">
                  {row.nombreAuxiliar}
                </TableCell>

                {/* Valor Plan */}
                <TableCell component="th" scope="row">
                  {row.planValor}
                </TableCell>

                {/* fecha instalacion */}
                <TableCell component="th" scope="row">
                  {row.fechaHora && typeof row.fechaHora === 'number' &&
                    moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                  {row.fechaHora && typeof row.fechaHora === 'object' &&
                    moment(row.fechaHora.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                </TableCell>
                {/* Vendedor */}
                <TableCell component="th" scope="row">
                  {row.vendedor}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.centro_costo && row.centro_costo.codigo_vendedor}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.centro_costo && row.centro_costo.centro_costo}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.centro_costo && row.centro_costo.canal}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.centro_costo && row.centro_costo.lider_venta}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.centro_costo && row.centro_costo.categoria}
                </TableCell>
                {/* CAMBIO DE USUARIO QUE GENERA SOLICITUD DE CAMBIO DE DOMICILIO*/}
                <TableCell component="th" scope="row">
                  {row.vendedor}
                </TableCell>

                {/* Acciones */}
                <TableCell component="th" scope="row">
                  <UpdateCambioDomicilio props={row} />

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};