import React, { useState, useEffect } from 'react';
import {
    Grid as Grid2,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
    Button,
    Container,
    Grid,
} from '@mui/material'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Formato1 } from '../../inventario/reporte_de_movimientos/PlantillasPDF/Headers';


export const KardexGPDF = (props) => {
    const container = React.useRef(null);
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [datos, setDatos] = useState([])


    useEffect(() => {
        if (props.contenido && props.contenido.length > 0) {
            let datos = []
            props.contenido.map((item) => {
                datos.push({
                    created: item.created === undefined ?
                        "No asignado" : moment(item.created.toDate().toISOString())
                            .format("MMMM Do YYYY, h:mm:ss a"),
                    uid_solicitud_pedido: item.uid_solicitud_pedido
                        === undefined ? "No asignado" : item.uid_solicitud_pedido,
                    bodega: item.bodega === undefined ?
                        "No asignado" : item.bodega,
                    motivo: item.motivo === undefined ?
                        "No asignado" : item.motivo,
                    observacion_adicional: item.observacion_adicional
                        === undefined ? "SIN OBSERVACION" : item.observacion_adicional,
                    codigo: item.codigo === undefined ?
                        "No asignado" : item.codigo,
                    flota: item.flota === undefined ? "No asignado" : item.flota,
                    descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,

                    cantidad_solicitada: (item.tipo_movimiento ===
                        'ingreso' && item.cantidad_solicitada !== undefined) ?
                        Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                    costo_promedio: (item.tipo_movimiento ===
                        'ingreso' && item.costo_promedio !== undefined) ?
                        Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                    saldo_valorizado: (item.tipo_movimiento ===
                        'ingreso' && item.saldo_valorizado !== undefined) ?
                        Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                    cantidad_solicitada2: (item.tipo_movimiento ===
                        'egreso' && item.cantidad_solicitada !== undefined) ?
                        Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                    costo_promedio2: (item.tipo_movimiento ===
                        'egreso' && item.costo_promedio !== undefined) ?
                        Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                    saldo_valorizado2: (item.tipo_movimiento ===
                        'egreso' && item.saldo_valorizado !== undefined) ?
                        Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                    cantidad_total_despues: item.cantidad_total_despues
                        === undefined ? 0 : Number((parseFloat(item.cantidad_total_despues)).toFixed(0)),
                    costo_promedio3: item.costo_promedio ===
                        undefined ? 0.00 : Number((parseFloat(item.costo_promedio)).toFixed(2)),
                    total: item.total === undefined ? 0.00 :
                        Number((parseFloat(item.total)).toFixed(2))
                })
            })
            setDatos(datos)
        }
    }, [props.contenido])

    const headerColumn = (props) => {
        return (
            <a>
                <span ><strong>{props.title}</strong></span>
                {props.children}
            </a>
        );
    };


    const plantilla = (props) => {
        return (
            <div>
                {props.pageNum == 1 &&
                    <Formato1 usuario={userInfo.displayName} />
                }
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                    Pag {props.pageNum} de {props.totalPages}
                </p>
            </div >
        )
    };


    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: plantilla,
            paperSize: "A4",
            landscape: true,
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.4,
            fileName: `REPORTE KARDEX`,
        });
    };



    return (
        <Container>
            <div className="example-config">
                <Button
                    onClick={exportPDFWithMethod}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </div>
            <div className="border rounded p-2">
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth='xl'>
                            <Grid container spacing={1}>
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>INTERCOMMERCE S A</strong>
                                </Grid>
                                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                    <strong>REPORTE KARDEX</strong>
                                </Grid>
                                <Grid item xs={4} align="center" />
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>0992988061001</strong>
                                </Grid>
                                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                    <strong>{moment((new Date()).toString()).format("DD/MM/YYYY")}</strong>
                                </Grid>
                                <Grid item xs={4} align="center" />
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>AV. NARCISA DE JESUS 6421 Y PUENTE DE LA JOYA</strong>
                                </Grid>
                            </Grid>
                        </Container>
                        <Grid2 data={datos} >
                            <Column field="created" title="FECHA DE CREACION" headerCell={headerColumn} />
                            <Column field="uid_solicitud_pedido"
                                title="NUMERO DE REFERENCIA" headerCell={headerColumn} />
                            <Column field="bodega" title="BODEGA" headerCell={headerColumn} />
                            <Column field="motivo" title="CONCEPTO" headerCell={headerColumn} />
                            <Column field="observacion_adicional" title="OBSERVACION" headerCell={headerColumn} />
                            <Column field="codigo" title="CODIGO" headerCell={headerColumn} />
                            <Column field="flota" title="FLOTA" headerCell={headerColumn} />
                            <Column field="descripcion" title="DESCRIPCION" headerCell={headerColumn} />
                            <Column field="cantidad_solicitada" title="CANTIDAD" headerCell={headerColumn} />
                            <Column field="costo_promedio" title="COSTO UNITARIO" headerCell={headerColumn} />
                            <Column field="saldo_valorizado" title="TOTAL VALORIZADO" headerCell={headerColumn} />
                            <Column field="cantidad_solicitada2" title="CANTIDAD" headerCell={headerColumn} />
                            <Column field="costo_promedio2" title="COSTO UNITARIO" headerCell={headerColumn} />
                            <Column field="saldo_valorizado2" title="TOTAL VALORIZADO" headerCell={headerColumn} />
                            <Column field="cantidad_total_despues" title="STOCK ACTUAL" headerCell={headerColumn} />
                            <Column field="costo_promedio3" title="COSTO PROMEDIO" headerCell={headerColumn} />
                            <Column field="total" title="SALDO VALORIZADO" headerCell={headerColumn} />
                        </Grid2>
                    </div>
                </PDFExport>
            </div>
        </Container>
    );
}
