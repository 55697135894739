import {
    Box, Typography, Button, Card, Paper, Chip, Menu, CardHeader, MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

export const LogsPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl)

    var totalInstalaciones;
    const [data, setData] = useState();
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    //
    const dispatch = useDispatch()
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("logs")
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(12, 0, 0, 0)
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                data.reverse()
                setData(data)
                totalInstalaciones = data
            })
        }
    };
    function CustomFooterTotalComponent(props) {
        return ''
    }
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        if (d.estado === 0) {
            name = 'INFORMATIVO'
            color = 'info'
        }
        if (d.estado === 1) {
            name = 'WARNING'
            color = 'warning'
        }
        if (d.estado === 2) {
            name = 'APIS'
            color = 'warning'
        }
        if (d.estado === 3) {
            name = 'WARNING'
            color = 'error'
        }
        if (d.estado === 4) {
            name = 'SEGURIDAD'
            color = 'secondary'
        }
        return (
            <Chip label={name} size="small" variant='outlined' color={color} />
        )
    }

    const columns = [
        { field: 'id', headerName: 'id', width: 100 },
        {
            field: 'created', headerName: 'FECHA', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss A'),
        },
        {
            field: 'estado', headerName: '', width: 75,
            renderCell: (params) => {
                return <Estado estado={params.row} />
            }
        },
        { field: 'function', headerName: 'FUNCION', width: 100 },
        { field: 'detalle', headerName: 'DETALLE', width: 500 },
        { field: 'usuario', headerName: 'USUARIO', width: 200 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 150 },
    ];
    const printOptions = { hideFooter: true, hideToolbar: true }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    return (
        <>
            <Card>
                <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader={<strong>LOGS DEL SISTEMA</strong>} />
                <Box pt={1} pl={1}>
                    <Box display='flex' style={{ textTransform: 'uppercase' }} >
                        <Typography variant="button" >
                            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                        </Typography >
                        <Typography variant="button" style={{ paddingLeft: 10 }}>
                            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >Buscar por fecha
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Box px={2}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Box>
                </Menu>
                <Paper>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            noRowsLabel='a'
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </Box>
                </Paper>
            </Card>
        </>
    );
};