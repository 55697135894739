import {Box, Button, Collapse, Typography,Chip, Paper} from "@mui/material"
import React, { useEffect, useState } from "react";
import { db, auth, firebase } from "../../../firebase";
import { useSelector } from 'react-redux';

import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES,
  GridToolbarExport
} from '@mui/x-data-grid';
import { CambioPropietarioCargarDocumento } from "./CambioPropietarioCargarDocumento";
//
export const CambioPropietarios = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    } else {
      name = 'INSTALACION'
      color = 'success'
    }
    return (
      <Chip size='small' variant='outlined' label={name} color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const [total, setTotal] = useState();
  const [data, setData] = useState();
  const [search, setSearch] = useState('')

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  useEffect(() => {
    LoadData()
  }, [])

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("cambio_propietario")

    //if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id_mikro: doc.data().id,
              id: doc.id,
            };
          }),
        ];
        setData(data);
      });
    }
    //}
  }
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start)
    setEndDate(end)
  }
  //
  function CustomFooterTotalComponent(props) {
    return ''
  }
  const columns = [
    {
      field: 'created', headerName: 'FECHA SOLICITUD', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'nombres', headerName: 'NOMBRE CLIENTE', width: 300 },
    { field: 'id_mikro', headerName: 'MIKRO', width: 75 },
    { field: 'NUM_CONTRATO', headerName: 'CONTRATO ANTIGUO', width: 200 },

    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    {
      field: 'fotos', headerName: 'SUBIR FOTOS', width: 100,
      renderCell: (params) => {
        return <CambioPropietarioCargarDocumento props={params.row} />
      }
    },
    { field: 'direccion', headerName: 'DIRECCION', width: 300 },
    { field: 'canton', headerName: 'CANTON', width: 150 },
    {
      field: 'fechaHora', headerName: 'FECHA INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    { field: 'planNombre', headerName: 'PLAN NOMBRE', width: 300 },
    { field: 'contrato', headerName: 'TIPO', width: 100 },
    { field: 'observacion2', headerName: 'OBSERVACION DEL METRAJE	', width: 400 },

    {
      valueGetter: ({ value }) => value == 1 ? 'SI' : 'NO'
    },
    {
      field: 'anexo_', headerName: 'ANEXO DEBITO', width: 125,
      renderCell: (params) => {
        return params.row.debito === 1 ? <>
          <a target="_blank" href={params.row.foto_debito} >VER FOTO</a>
        </> : ''
      }
    },
    { field: 'banco', headerName: 'BANCO', width: 200 },
    { field: 'tipoCuenta', headerName: 'TIPO BANCO', width: 100 },
    { field: 'numBanco', headerName: 'NUM BANCO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
      valueGetter: ({ value }) => value == 1 ? 'SI' : 'NO'
    },
    

    { field: 'planValor', headerName: 'VALOR PLAN', width: 100 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      valueGetter: (params) => { 
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido_', headerName: 'REFERIDO', width: 125,
      valueGetter: (params) => {
        return params.row.referido === undefined ? 'NO' : params.row.referido
      }
    },

  ]
  const csvOptions = { delimiter: ';' };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>GESTION POST VENTA</strong>/CAMBIOS PROPIETARIO
        </Typography >
      </Box>
      <Box display='flex' style={{ textTransform: 'uppercase' }} pl={1}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>
      <Box display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
    
      </Box>
      <Paper>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};