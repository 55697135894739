import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
export const ListSolicitudes = () => {
    const location = useLocation();
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        userAuth?.roles?.find(role => ['admin', 'solicitudes_pedidos']?.includes(role)) ? 
            <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <ProductionQuantityLimitsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Solicitudes" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton
                            selected={location.pathname === 'solicitud/compra/0'}
                            component={RouterLink}
                            to={`solicitud/compra/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Pedido" />
                        </ListItemButton>
                    </List>
                    
                    {
                     userAuth?.roles?.find(role => ['admin', 'aprobar_solicitud_pedido']?.includes(role))
                    ?
                        <List component="div" disablePadding dense>
                            <ListItemButton
                                selected={location.pathname === 'solicitud/aprobar/0'}
                                component={RouterLink}
                                to={`solicitud/aprobar/0`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Aprobar Solicitud de Pedido" />
                            </ListItemButton>
                        </List>
                        : ''}
                </Collapse>
            </>
            : ''
    )
}