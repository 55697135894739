import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NewPresentation = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [nombrePresentacionProducto, setNombrePresentacionProducto] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    }

    const createPresentacionProducto = async (NombrepresentacionProducto) => {
        await db.collection('presentaciones_productos').doc().set({
            nombre_presentacion_producto: NombrepresentacionProducto,
            fecha_creacion_presentacion_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva presentacion creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={nombrePresentacionProducto}
                cambiarEstado={setNombrePresentacionProducto}
                label="NOMBRE PRESENTACION PRODUCTO"
                name="nombrePresentacionProducto"
            />

            <br />
            <Button
                onClick={() => createPresentacionProducto(nombrePresentacionProducto.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !nombrePresentacionProducto.campo}
            >
                {" "}
                CREAR NUEVA PRESENTACION PRODUCTO{" "}
            </Button>
        </>
    );
};
