import React, { useEffect, useState } from "react"
import { db, firebase } from "../../../firebase"
import {
    Grid, Box, TextField, Button, Select,
    Checkbox,
    FormControlLabel,
    Typography,
    Autocomplete, FormControl, MenuItem, CardContent
} from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { listsPlanes, listsZonas } from '../../../redux/actions/parametersActions'
import Swal from 'sweetalert2'
import moment from "moment";


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 200
        },
    },
};
export const NuevaPromocion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    // const [inicioPromocion, setInicioPromocion] = useState(null)
    // const [finPromocion, setFinPromocion] = useState(null)

    // const [list_planes, setListPlanes] = useState([])
    // const [descuentosCuotasNro, setDescuentosCuotasNro] = useState(0)
    // const [recurrencia, setRecurrencia] = useState(1)
    // const [inputFields, setInputFields] = useState([])
    // const [item, setItem] = useState('')
    // const [productos, setProductos] = useState(null)
    // const [selectedOption, setSelectedOption] = useState(null)
    const dispatch = useDispatch()

    const [nombre, setNombre] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [descuento, setDescuento] = useState('')

    const { planes } = useSelector(state => state.planesList)

    const zonasList = useSelector(state => state.zonasList)
    const { zonas } = zonasList
    const [contrato, setContrato] = useState([])
    const [plan, setPlan] = useState([])
    const [zona, setZona] = useState([])
    const [nodo, setNodo] = useState([])
    const [sector, setSector] = useState([])
    const [sectores, setSectores] = useState([])
    const [nodos, setNodos] = useState([])

    const [cuotas, setCuotas] = useState([])
    const [listaCuotas, setListaCuotas] = useState([])

    useEffect(() => {
        dispatch(listsZonas())
        dispatch(listsPlanes())
        cargarNodos()
        cargarSectores()
        cargarCuotas()
        
    }, [dispatch])

    function cargarCuotas() {
        let lista = []
        let inicio = 1
        let fin = 18
        while (inicio <= fin) {
            lista.push(inicio)
            inicio += 1
        }
        setListaCuotas(lista)
    }

    function cargarNodos() {
        let docRef = db.collection("nodo")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setNodos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }



    function cargarSectores() {
        db.collection("parameters/cantones/provincias").get().then((querySnapshot) => {
            const arrayMessage1 = querySnapshot.docs.map(item => { return item.id })
            return arrayMessage1
        }).then(async (arrayMessage) => {
            let listaCantones = [];
            let listaSectores = []
            for (let provincia in arrayMessage) {
                await db.collection(`parameters/cantones/provincias/${arrayMessage[provincia]}/canton`).get().then(async (querySnapshot) => {
                    const arrayMessage2 = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id, provincia: arrayMessage[provincia] } })
                    arrayMessage2.map(val => {
                        listaCantones.push(val)
                    })

                })
            }
            for (const canton in listaCantones) {
                await db.collection(`parameters/cantones/provincias/${listaCantones[canton].provincia}/canton/${listaCantones[canton].id}/parroquia/TODOS/sector`).get().then((querySnapshot2) => {
                    const arrayMessage3 = querySnapshot2.docs.map(item => { return item.id })
                    arrayMessage3.map(val => {
                        listaSectores.push(val)
                    })
                })
            }
            const conjunto = Array.from(new Set(listaSectores));
            setSectores([...conjunto.map(val=>{
                return {
                    id:val,
                }
            })])
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const handleSubmit = () => {
        let body = {
            nombre: nombre,
            descuento: descuento,
            inicio: startDate,
            fin: endDate,
            zonas: zona.includes('TODOS') ? [...zonas.filter(val => {
                if (val.id.includes('ZONA')) return val
            }).map(ite => { return ite.id })] : zona,
            nodos: nodo.includes('TODOS') ? [...nodos.map(ite => { return ite.alias })] : nodo,
            planes: plan.includes('TODOS') ? [...planes.filter((val) => {
                if (contrato.includes('HOGAR')) {
                    if (val.plan.includes('DEFENDER')) return val
                } else if (contrato.includes('COORPORATIVO')) {
                    if (!val.plan.includes('DEFENDER')) return val
                } else {
                    return val
                }
            }).map(ite => { return ite.plan })] : plan,
            sectores: sector.includes('TODOS') ? [...sectores.map(ite => { return ite.id })] : sector,
            tipoContrato: contrato.includes('TODOS') ? ['HOGAR', 'COORPORATIVO'] : contrato,
            cuotas: cuotas.includes('TODOS') ? listaCuotas : cuotas,
        }
        console.log(body)


        db.collection('promociones').doc().set({
            ...body,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_creador_promocion: userInfo.displayName,
        }).then(() => {
            limpiar()
            Swal.fire({ icon: "success", title: "Promoción creada con exito" })
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error })
        })
    }

    function limpiar() {
        setNombre('')
        setDescuento('')
        setZona([])
        setSector([])
        setNodo([])
        setContrato([])
        setPlan([])
        setStartDate('')
        setEndDate('')
        setCuotas([])

    }

    const onChange = (dates) => {
        const [start, end] = dates
        if (start) start.setHours(0, 0, 0, 0);
        if (end) end.setHours(23, 59, 0, 0);
        setStartDate(start)
        setEndDate(end)
    }


    return (
        <CardContent>
            <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Nombre de la promoción:</strong></p>
                        <TextField
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                            size="small"
                            fullWidth
                        />
                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>% Descuento:</strong></p>
                        <TextField
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            value={descuento}
                            onChange={(e) => setDescuento(e.target.value)}
                            size="small"
                            type='number'
                            fullWidth
                        />
                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Zonas:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    size="small"
                                    value={zona}
                                    multiple
                                    disabled={zona.includes('TODOS')}
                                    onChange={(e) => setZona(e.target.value)}
                                    fullWidth
                                    MenuProps={MenuProps}
                                >

                                    {zonas ? zonas.filter(val => {
                                        if (val.id.includes('ZONA')) return val
                                    }).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                                    )) : ''}


                                </Select>

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={zona.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setZona(['TODOS'])
                                                } else {
                                                    setZona([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Nodo:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}

                                    size="small"
                                    disabled={nodo.includes('TODOS')}
                                    value={nodo}
                                    multiple
                                    onChange={(e) => setNodo(e.target.value)}
                                    fullWidth
                                    MenuProps={MenuProps}
                                >
                                    {nodos ? nodos.map((item) => (
                                        <MenuItem key={item.id} value={item.alias}>{item.alias}</MenuItem>
                                    )) : ''}
                                </Select>

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={nodo.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setNodo(['TODOS'])
                                                } else {
                                                    setNodo([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>

                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Tipo Contrato:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}

                                    size="small"
                                    disabled={contrato.includes('TODOS')}
                                    value={contrato}
                                    onChange={(e) => setContrato(e.target.value)}
                                    multiple
                                    fullWidth
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"HOGAR"}>HOGAR</MenuItem>
                                    <MenuItem value={"CORPORATIVO"}>CORPORATIVO</MenuItem>
                                </Select>

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={contrato.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setContrato(['TODOS'])
                                                } else {
                                                    setContrato([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>


                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Planes:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}

                                    size="small"
                                    value={plan}
                                    disabled={plan.includes('TODOS')}
                                    multiple
                                    onChange={(e) => setPlan(e.target.value)}
                                    fullWidth
                                    MenuProps={MenuProps}
                                >
                                    {planes ? planes.filter((val) => {
                                        if (contrato.includes('HOGAR')) {
                                            if (val.plan.includes('DEFENDER')) return val
                                        } else if (contrato.includes('COORPORATIVO')) {
                                            if (!val.plan.includes('DEFENDER')) return val
                                        } else {
                                            return val
                                        }
                                    }).map((item) => (
                                        <MenuItem key={item.id} value={item.plan}>{item.plan}</MenuItem>
                                    )) : ''}
                                </Select>


                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={plan.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setPlan(['TODOS'])
                                                } else {
                                                    setPlan([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>


                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Sector:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    multiple
                                    disabled={sector.includes('TODOS')}
                                    id="combo-box-demo"
                                    options={sectores ? sectores : []}
                                    fullWidth
                                    getOptionLabel={(option) => option.id}
                                    onInputChange={(e, newInputValue) => {
                                        setSector(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={sector.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSector(['TODOS'])
                                                } else {
                                                    setSector([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>


                    </Box>

                </Grid>
                <Grid item xs={12} md={6}>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    <Box py={1}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Fecha Inicio:</strong></p>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={startDate ? moment(startDate.toString()).format('DD/MM/YYYY HH:mm') : ''}
                                disabled={true}
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                    <Box py={1}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Fecha Fin:</strong></p>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={endDate ? moment(endDate.toString()).format('DD/MM/YYYY HH:mm') : ''}
                                disabled={true}

                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                    <Box py={1}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>Cuotas:</strong></p>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={10}>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    size="small"
                                    value={cuotas}
                                    multiple
                                    disabled={cuotas.includes('TODOS')}
                                    onChange={(e) => setCuotas(e.target.value)}
                                    fullWidth
                                    MenuProps={MenuProps}
                                >

                                    {listaCuotas ? listaCuotas.map(val => (
                                        <MenuItem key={val} value={val}>{val}</MenuItem>
                                    )) : ''}


                                </Select>

                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel
                                    label={
                                        <Typography sx={{ fontSize: 12 }}>
                                            TODOS
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={cuotas.includes('TODOS')}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setCuotas(['TODOS'])
                                                } else {
                                                    setCuotas([])
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
            <br />
            <Button
                disabled={
                    nombre === '' ||
                    descuento === '' ||
                    startDate === '' ||
                    endDate === '' ||
                    startDate === null ||
                    endDate === null ||
                    zona.length === 0 ||
                    nodo.length === 0 ||
                    contrato.length === 0 ||
                    plan.length === 0 ||
                    cuotas.length === 0 ||
                    sectores.length === 0
                }
                onClick={() => { handleSubmit() }}
                fullWidth
                color='success'
                variant="contained"
            >
                CREAR PROMOCION
            </Button>
            <br />
            {/* 
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>NO.</TableCell>
                            <TableCell align="center">CODIGO 5</TableCell>
                            <TableCell align="center">DESCRIPCION</TableCell>
                            <TableCell align="center">CANT PEDIDA</TableCell>
                            <TableCell align="center">ACCIONES</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inputFields.map((data, index) => {
                            return detalleMate(data, index)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogActions>
                <Button
                    onClick={() => handleSubmit()}
                    disabled={nombre === ''
                        || inicioPromocion === ''
                        || finPromocion === ''
                        || zona === ''
                        || descuentosCuotasNro === ''
                    }
                    variant="contained"
                    color="secondary"
                >
                    CREAR PROMOCION
                </Button>
            </DialogActions> */}
        </CardContent >
    )
}
