import {
    Box, Button, Container, Dialog,
    DialogActions, DialogContent, DialogTitle,
    LinearProgress
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DataGrid, esES } from '@mui/x-data-grid';


export const Logs = (props) => {


    const [id, setId] = useState('')
    const [nombre, setNombre] = useState('')
    const [plan, setPlan] = useState('')
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setId(props.id)
        setPlan(props.plan)
        setNombre(props.nombre)
    }, [props]);

    const handleOpen = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiInfoMikrowisp/?logs=${id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                res.reverse()
                setData([...res.map((item) => {
                    let str2 = (item.log ? item.log.replaceAll('{{cliente}}', nombre) : '').replace(/<[^>]*>?/gm, '')
                    str2 = str2.replaceAll('{plan}', plan)

                    return {
                        ...item,
                        log: str2,
                    }
                })
                ])
                setOpen(true)
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const columns = [
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        {
            field: 'log', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 500,
            renderCell: (params) => {
                return (
                    <>
                        <br />
                        {params.row.log}
                        <br /><br />
                    </>
                )
            }
        },
        { field: 'fechalog', headerClassName: 'super-app-theme--header', headerName: 'Fecha', width: 200 },

    ]
    return (
        <>

            <Button
                fullWidth variant="contained" onClick={() => {
                    handleOpen()
                }} color='error' >
                LOGS
            </Button>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle sx={{ bgcolor: '#1A6EB9', color: "white" }}><strong>LOGS DEL CLIENTE ID: {id}</strong>
                </DialogTitle>
                <DialogContent>
                    <br />
                    <Container>
                        <Box sx={{
                            height: 400, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                            '& .super-app-theme--header': {
                                backgroundColor: 'LightSlateGray',
                                color: 'white',
                                fontSize: '16px'
                            },
                        }}>
                            <DataGrid
                                slots={{
                                    loadingOverlay: LinearProgress,
                                }}
                                hideFooterSelectedRowCount={true}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                density='compact'
                                rows={data ?? []}
                                getRowHeight={() => 'auto'}
                                getEstimatedRowHeight={() => 200}
                            />
                        </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
