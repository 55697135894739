import React, { useState } from "react";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import InputValidation from '../../../../components/utils/InputValidation';
import { db, firebase } from '../../../../firebase';
export const CrearNueva = ({tabla}) => {
    const [nombres, setNombres] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
  
    const createProduct = async () => {
        setLoading(true)
        db.collection(tabla).orderBy('created', 'desc').limit(1).get()
            .then((rubrosDb) => {
                let id = 1
                if (rubrosDb.size > 0) {
                    const servicio = rubrosDb.docs[0].data()
                    id = servicio.id + 1
                }
                db.collection(tabla).doc(id.toString()).set({
                    id: id,
                    nombre: nombres.campo,
                    created: firebase.firestore.FieldValue.serverTimestamp()
                }).then(() => {
                    setLoading(false)
                    setNombres({ campo: '', valido: true })
                    Swal.fire({ icon: 'success', text: 'Se creo con exito' })
                })
            })
    }
    return (
        <>
            <InputValidation
                estado={nombres}
                cambiarEstado={setNombres}
                label="DESCUENTOS POR PAGAR"
                name="nombres"
            />
            <Button
                onClick={() => createProduct()}
                variant='contained'
                disableElevation
                disabled={!nombres.campo || loading}
            >
                CREAR
            </Button>
        </>
    )
}
