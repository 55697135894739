import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Paper, Tab, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AdPais } from './AdPais'
import { AdProvincia } from './AdProvincia'
import { AdRegion } from './AdRegion'
import { AdCanton } from './AdCanton'


export const ParametrosGeograficos = () => {
    const [tab, setTab] = useState('0');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <>
            <Box px={2}>
                <TabContext value={tab}>
                    <Box sx={{ borderColor: 'divider' }}>
                        <Paper elevation={2}>
                        <TabList onChange={handleChange} variant="scrollable" >
                            <Tab label="PAIS" value={'0'} />
                            <Tab label="REGION" value={'1'} />
                            <Tab label="PROVINCIA" value={'2'} />
                            <Tab label="CANTON" value={'3'} />
                        </TabList>
                        </Paper>
                    </Box>
                    <TabPanel value={'0'}>
                        <AdPais />
                    </TabPanel>
                    <TabPanel value={'1'}>
                        <AdRegion />
                    </TabPanel>
                    <TabPanel value={'2'}>
                        <AdProvincia />
                    </TabPanel>
                    <TabPanel value={'3'}>
                        <AdCanton />
                    </TabPanel>
                </TabContext>
            </Box>

        </>
    )
}
