import React, { useEffect, useState, } from 'react'
import { useParams } from 'react-router-dom';
import {
    Grid, Box, Card, Button, Divider, Typography, Breadcrumbs, Alert, CardContent
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from 'react-router-dom';
import axios, { Axios } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UpdateFileUpload } from '../../../../components/utils/UpdateFileUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Swal from 'sweetalert2'
import { firebase, storage, db } from '../../../../firebase'
import { detallesSolicitud } from '../../../../redux/actions/ventasActions';
import Loader from '../../../../components/utils/Loader'
export const UpdateCargarDocumentos = ({ items }) => {
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameClient, setNameClient] = useState('');
    const [name, setName] = useState('');
    async function postName(e) {
        e.preventDefault()
        try {
            await axios.post("http://192.168.90.19:7000/post_name", {
                name
            })
        } catch (error) {
            console.log(error)
        }
    }
    const params = useParams()
    const dispatch = useDispatch()
    const solicitudDetalle = useSelector(state => state.solicitudDetalle)
    const { solicitud } = solicitudDetalle
    useEffect(() => {
        dispatch(detallesSolicitud(params.id))
        setFile1(solicitud.foto_cedula_frontal)
        setFile2(solicitud.foto_cedula_trasera)
        setFile3(solicitud.foto_cliente_letrero)
        setFile4(solicitud.foto_cliente_planilla)
        setFile5(solicitud.foto_cliente_vivienda)
        console.log(solicitud.nombres, 'solicitud')
    }, [dispatch])

    const onFormSubmit2 = () => {

        dispatch({ type: 'CARGA_DOCUMENTO_REQUEST' })
        //Nombre de la carpeta
        const cedulaFrontalRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child('cedulaFrontal')
        const frontalUpload = cedulaFrontalRef.put(file1)
        //console.log(frontalUpload, 'upload')
        frontalUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                frontalUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)
                    const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                        foto_cedula_frontal: downloadURL
                    }).then(() => {
                        console.info("Finished uploading CEDULA FRONTAL");

                    }).catch((error) => {
                        Swal.fire({ icon: 'error', text: error, })
                    });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }

        )
        // const cedulaFrontalURL = cedulaFrontalRef.getDownloadURL()
        // //
        const cedulaPosteriorRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child('cedulaPosterior')
        const posteriorUpload = cedulaPosteriorRef.put(file2)
        posteriorUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                posteriorUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)
                    const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                        foto_cedula_trasera: downloadURL
                    }).then(() => {
                        console.info("Finished uploading CEDULA FRONTAL");

                    }).catch((error) => {
                        Swal.fire({ icon: 'error', text: error, })
                    });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }

        )
        // //
        const fotoLetreroRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child('fotoLetrero')
        const letreroUpload = fotoLetreroRef.put(file3)
        letreroUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)
                    const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                        foto_cliente_letrero: downloadURL
                    }).then(() => {
                        console.info("Finished uploading CEDULA FRONTAL");

                    }).catch((error) => {
                        Swal.fire({ icon: 'error', text: error, })
                    });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }
        )
        // const fotoLetreroURL = fotoLetreroRef.getDownloadURL()
        // //
        const fotoViviendaRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child('fotoVivienda')
        const viviendaUpload = fotoViviendaRef.put(file4)
        viviendaUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                viviendaUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)
                    const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                        foto_cliente_vivienda: downloadURL
                    }).then(() => {
                        console.info("Finished uploading CEDULA FRONTAL");

                    }).catch((error) => {
                        Swal.fire({ icon: 'error', text: error, })
                    });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }

        )
        // const fotoViviendaURL = fotoViviendaRef.getDownloadURL()
        // //
        const fotoPlanillaRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child('fotoPlanilla')
        const planillaUpload = fotoPlanillaRef.put(file5)
        planillaUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                planillaUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)
                    const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                        foto_cliente_planilla: downloadURL
                    }).then(() => {
                        console.info("Finished uploading CEDULA FRONTAL");
                        setOpen(true)
                        setLoading(false)

                    }).catch((error) => {
                        Swal.fire({ icon: 'error', text: error, })
                    });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }

        )
        //
        //Swal.fire({ icon: "success", title: "Archivos subido", timer: 1500 });

        //  db.collection('users').doc(params.id).update({
        //     picture: pictureURL
        // })
        // var data = JSON.stringify({
        //     "name": solicitud.nombres
        // });
        // var configName = {
        //     method: 'post',
        //     url: 'http://192.168.90.19:7000/user/nombreUser',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: data
        // };
        // axios(configName)
        //     .then(function (response) {
        //         setLoading(true)
        //         console.log(JSON.stringify(response.data));
        //         //Archivos
        //         const cedulaFrontal = new FormData();
        //         cedulaFrontal.append('photo', file1);

        //         const cedulaPosterior = new FormData();
        //         cedulaPosterior.append('photo', file2);

        //         const fotoLetrero = new FormData();
        //         fotoLetrero.append('photo', file3);

        //         const fotoVivienda = new FormData();
        //         fotoVivienda.append('photo', file4);

        //         const fotoPlanilla = new FormData();
        //         fotoPlanilla.append('photo', file5);
        //         const config = {
        //             headers: {
        //                 'content-type': 'multipart/form-data'
        //             }
        //         }
        //         //Imagen
        //         const url_cedulaFrontal = 'http://192.168.90.19:7000/user/cedulaFrontal';
        //         axios.post(url_cedulaFrontal, cedulaFrontal, config).then((response) => {
        //             console.log('Imagen subida, cedulaFrontal')
        //         }).catch((err) => {
        //             console.log('cedulaFrontal err', err);
        //         })
        //         const url_cedulaPosterior = 'http://192.168.90.19:7000/user/cedulaPosterior';
        //         axios.post(url_cedulaPosterior, cedulaPosterior, config).then((response) => {
        //             console.log('Imagen subida, cedulaPosterior')
        //         }).catch((err) => {
        //             console.log('cedulaPosterior err', err);
        //         })
        //         const url_fotoLetrero = 'http://192.168.90.19:7000/user/fotoLetrero';
        //         axios.post(url_fotoLetrero, fotoLetrero, config).then((response) => {
        //             console.log('Imagen subida, fotoLetrero')
        //         }).catch((err) => {
        //             console.log('fotoLetrero err', err);
        //         })
        //         const url_fotoVivienda = 'http://192.168.90.19:7000/user/fotoVivienda';
        //         axios.post(url_fotoVivienda, fotoVivienda, config).then((response) => {
        //             console.log('Imagen subida, fotoVivienda')
        //         }).catch((err) => {
        //             console.log('fotoVivienda err', err);
        //         })
        //         const url_fotoPlanilla = 'http://192.168.90.19:7000/user/fotoPlanilla';
        //         axios.post(url_fotoPlanilla, fotoPlanilla, config).then((response) => {
        //             console.log('Imagen subida, fotoPlanilla')
        //             Swal.fire({ icon: "success", title: "Archivos subido", timer: 1500 });
        //             setOpen(true)
        //             setLoading(false)
        //         }).catch((err) => {
        //             console.log('fotoPlanilla err', err);
        //         })


        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('photo', file1);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        //const url = 'http://192.168.90.19:8000/user/nombreUser';
        /* const urlNombre = 'http://192.168.90.19:8000/user/nombreUser'; */
        // axios.post(url, 'sebastian', config).then((response) => {
        //     console.log('nombre sebastian', response)  
        // }).catch((err) => {
        //     console.log('err', err);
        // })
        //Nombre
        var data = JSON.stringify({
            "name": "henry"
        });

        var config2 = {
            method: 'post',
            url: 'http://192.168.90.19:7000/user/nombreUser',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config2)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });



        /* const formDataNombre = new FormData();
        formDataNombre.append('nombre', nameClient);
        const configNombre = {
            headers: {
              'content-type': 'multipart/form-data'
            }
          } */
        //Imagen
        const url2 = 'http://192.168.90.19:7000/user/cedulaFrontal';
        /*const urlNombre = 'http://192.168.90.19:8000/user/nombreUser'; */
        axios.post(url2, formData, config).then((response) => {
            alert('imagen subida');
        }).catch((err) => {
            console.log('err', err);
        })
        /* axios.post(urlNombre, formDataNombre, configNombre).then((response) => {
          alert('Nombre enviado');
          console.log(formDataNombre)
        }).catch((err) => {
          console.log('err', err);
        }) */
    }
    const onInputChange = (e) => {
        setFile(e.target.files[0]);
    }

    const ActualizarFoto = async () => {

        const agendaDB = await db.collection("agenda").where("uid", "==", params.id).get()
        const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log('a', data1)
        const data = data1[0]

        console.log(data.id)
        console.log('Hola 2')

        db.collection("agenda").doc(data.id).update({
            estado: 2
        }).then(() => {
            setOpen(false)


            Swal.fire({ icon: "success", text: "Se ha reagendado la pre venta con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });


    }

    return (
        <>
            <Box py={2} pl={2}>

            </Box>
            <Card variant='outlined'>
                <CardContent>
                    <Alert severity="info">Recuerde, al actualizar debe subir fotos claras al sistema, en caso de alguna anomalia su pre venta sera rechazada</Alert>
                    {
                        solicitud ?
                            <Grid container>
                                <Grid md={6}>
                                    <Box pb={5}>
                                        <Typography>Documento cedula frontal</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cedula_frontal ?
                                        <>
                                            <img width={100} src={solicitud.foto_cedula_frontal} />
                                            <Alert>Archivo Actualizado</Alert>

                                            <UpdateFileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile1}
                                                estado={file1}
                                                nameFile="foto_cedula_frontal"
                                            />
                                            
                                        </>
                                        :
                                        <UpdateFileUpload
                                            solicitud={solicitud}
                                            cambiarEstado={setFile1}
                                            estado={file1}
                                            nameFile="foto_cedula_frontal"
                                        />}
                                    <Box py={5}>
                                        <Typography>Documento cedula trasera</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cedula_trasera ?
                                        <>
                                            <img width={100} src={solicitud.foto_cedula_trasera} />
                                            <Alert>Archivo Actualizado</Alert>
                                            <UpdateFileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile2}
                                                estado={file2}
                                                nameFile="foto_cedula_trasera"
                                            />
                                        </>
                                        :
                                        <UpdateFileUpload
                                            solicitud={solicitud}
                                            cambiarEstado={setFile2}
                                            estado={file2}
                                            nameFile="foto_cedula_trasera"
                                        />}
                                    <Box py={5}>
                                        <Typography>Documento planilla domicilio</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cliente_planilla ?
                                        <>
                                            <img width={100} src={solicitud.foto_cliente_planilla} />
                                            <Alert>Archivo Actualizado</Alert>
                                            <UpdateFileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile3}
                                                estado={file3}
                                                nameFile="foto_cliente_planilla"
                                            />
                                        </>
                                        :
                                        <UpdateFileUpload
                                            solicitud={solicitud}
                                            cambiarEstado={setFile3}
                                            estado={file3}
                                            nameFile="foto_cliente_planilla"
                                        />}
                                </Grid>
                                <Grid md={6}>
                                    <Box pb={5}>
                                        <Typography>Foto vivienda</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cliente_vivienda ?
                                        <>
                                            <img width={100} src={solicitud.foto_cliente_vivienda} />
                                            <Alert>Archivo Actualizado</Alert>
                                            <UpdateFileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile4}
                                                estado={file4}
                                                nameFile="foto_cliente_vivienda"
                                            />
                                        </>
                                        :
                                        <UpdateFileUpload
                                            solicitud={solicitud}
                                            cambiarEstado={setFile4}
                                            estado={file4}
                                            nameFile="foto_cliente_vivienda"
                                        />}
                                    <Box py={5}>
                                        <Typography>Foto cliente con letrero</Typography>
                                        <Divider />
                                    </Box>
                                    {
                                        solicitud && solicitud.foto_cliente_letrero ?
                                            <>
                                                <img width={100} src={solicitud.foto_cliente_letrero} />
                                                <Alert>Archivo Actualizado</Alert>
                                                <UpdateFileUpload
                                                    solicitud={solicitud}
                                                    cambiarEstado={setFile5}
                                                    estado={file5}
                                                    nameFile="foto_cliente_letrero"
                                                />
                                            </>
                                            :
                                            <UpdateFileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile5}
                                                estado={file5}
                                                nameFile="foto_cliente_letrero"
                                            />
                                    }
                                </Grid>
                            </Grid>
                            :
                            <Loader />
                    }

                    <div id="sign-in-button"></div>
                    <Box pt={2} display="flex" flexDirection="row-reverse">
                        {solicitud &&
                            <Button component={RouterLink} disabled={
                                !solicitud.foto_cedula_frontal
                                || !solicitud.foto_cedula_trasera
                                || !solicitud.foto_cliente_planilla
                                || !solicitud.foto_cliente_vivienda
                                || !solicitud.foto_cliente_letrero} variant='contained'
                                disableElevation to={`/ventas/ventas-por-reagendar/`} onClick={() => ActualizarFoto()}>
                                {" "}
                                DOCUMENTOS ACTUALIZADOS
                            </Button>}

                    </Box>
                </CardContent>
            </Card>
        </>
    )
}