import React, { useState, useEffect } from "react";
import { functions } from "../../firebase";
import Swal from "sweetalert2";
import {
    Grid, ListItemText, Button, Select, Checkbox,
    MenuItem, InputLabel, CardContent, CardActions
} from "@mui/material";
import { useSelector } from 'react-redux';
import InputValidation2 from "../../components/utils/InputValidation2";
import { db } from '../../firebase';
import { CentroCosto } from "./CentroCosto";
export const CrearUsuario = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [correo, setCorreo] = useState({ campo: '', valido: true })
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: true })
    const [rol, setRol] = useState([])
    const [roles, setRoles] = useState([])
    const [loagin, setLoading] = useState(false)
    useEffect(() => {
        loadRoles()
    }, [])
    const loadRoles = () => {
        db.collection('system/settings/roles').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setRoles(arrayMessage)
        })
    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setRol(
            typeof value === 'string' ? value.split(',') : value,
        );

        console.log(rol, ' rol')
    };
    //
    const onSubmit = async () => {
        setLoading(true)
        const data = {
            nombres: nombre.campo,
            cedula: cedula.campo,
            direccion: "",
            sangre: "",
            fechaNacimiento: "",
            departamento: "",
            email: correo.campo,
            password: password.campo,
            roles: rol,
            creado_por: userInfo.uid,
        }
        const createUser = functions.httpsCallable('createUser')
        createUser(data).then((res) => {
            console.log(res.data.code, ' res')
            if (res.data.code == 500) {
                Swal.fire({ icon: 'error', text: res.data.message})
                setLoading(false)
            }
            if (res.data.code == 200) {
                Swal.fire({ icon: 'success', text: res.data.message  })
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
        })
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };
    return (
        <>
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        <InputValidation2
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="Nombres y Apellidos"
                            name="NOMBRE"
                        />
                        <InputValidation2
                            estado={correo}
                            cambiarEstado={setCorreo}
                            label="Correo Electronico"
                            name="correo"
                        />
                        <InputValidation2
                            estado={password}
                            cambiarEstado={setPassword}
                            label="Contraseña"
                            name="password"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputValidation2
                            estado={cedula}
                            cambiarEstado={setCedula}
                            label="Cedula | Ruc"
                            name="CEDULA"
                        />
                        <InputLabel>Roles</InputLabel>
                        <Select
                            multiple
                            size='small'
                            value={rol}
                            fullWidth
                            onChange={handleChange}

                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {roles.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={rol.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                        {
                            rol?.find(role => role?.includes("ventas")) &&
                            <CentroCosto />
                        }
                        
                    </Grid>
                    <Grid item xs={12} md={4}>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    disabled={loagin}
                    onClick={onSubmit}
                    variant='outlined'
                    color='success'
                >Crear Nuevo usuario
                </Button>
            </CardActions>
        </>
    );
};
