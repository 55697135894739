import React, { useState, useEffect } from "react";
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    InputLabel,
    Tooltip,
    Grid,
    Paper,
    Divider,
    Typography,
    Alert,
    Autocomplete,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    IconButton
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../firebase";
import Swal from "sweetalert2";
import { Link as RouterLink } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'moment/locale/es'
import { listsMateriales } from '../../redux/actions/parametersActions'
import { UpdateFileUploadInstaladores } from "../../components/utils/UpdateFileUploadInstaladores";
import { detallesInstalacion } from '../../redux/actions/instalacionesAction';

import { useNavigate } from "react-router-dom";

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export const ViewMigracionesNew = ({ props }) => {

    const [modalProducts, setModalProducts] = useState(false);

    const [productos, setProductos] = useState([])

    const [item, setItem] = useState('')

    const [inputFields, setInputFields] = useState([]);

    const [dataPhotos, setDataPhotos] = useState([]);

    const getProductos = (e) => {
        let docRef = db.collection("productos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getPhotosCollectionFirebase = () => {
        const ref = db
            .collection('tecnicos')
            .where("numContrato", "==", props.id);

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setDataPhotos(data);
        });
    };

    const [loadingConfirmInstalacion, setLoadingConfirmInstalacion] = useState(false);

    let navigate = useNavigate()

    //OBTENER LOS MATERIALES OTORGADOS PARA LA INSTALACION

    const [dataMateriales, setDataMateriales] = useState();

    const getMaterialesUtilizados = () => {
        let ref = db.collection("tecnicos").doc(props.id).collection('items')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setDataMateriales(data)
        })
    }

    /* INSTALACION */

    const [guardarSerie, setGuardarSerie] = useState('')

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const materialesList = useSelector(state => state.materialesList)
    const { materiales, loading } = materialesList
    const [material, setMaterial] = useState('')

    const [caja, setCaja] = useState(props.caja);
    const [open, setOpen] = useState(false);

    const [valorMetrajeExtra, setValorMetrajeExtra] = useState(0);

    const [openCaja, setOpenCaja] = useState(false);
    const [nombrePlan, setNombrePlan] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPuerto, setOpenPuerto] = useState(false);
    const [openConfirmInstalacion, setOpenConfirmInstalacion] = useState(false);
    const [openInstalacionRechazada, setOpenInstalacionRechazada] = useState(false);
    const [openSendInstalacionRechazada, setOpenSendInstalacionRechazada] = useState(false);
    //Servicios contratados
    const [potencia, setPotencia] = useState('');

    const [observacionesExtra, setObservacionesExtra] = useState('');

    const [valorCuotas, setValorCuotas] = useState({ campo: "", valido: true });
    //Detalle instalacion y extras (accesorios) (detalles pago instalacion)
    const [valorCobrado, setValorCobrado] = useState({ campo: "", valido: true });
    const [noCuotas, setNoCuotas] = useState('');

    //
    const [number, setNumber] = useState({ one: 0, two: 0 })
    const [suma, setSuma] = useState()
    const [observacionInstalacionRechazada, setObservacionInstalacionRechazada] = useState({ campo: "", valido: true });
    const [data, setData] = useState('');

    const [numSolicitudFlota, setNumSolicitudFlota] = useState(false)

    const [numEgreso, setNumEgreso] = useState('')

    const loadNumSolicitudFlota = () => {
        const ref = db.collection("reporte_movimientos_flotas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `MOV-` + numeroSolicitud;
                setNumSolicitudFlota(numeroSolicitud)
            }
        })
    }

    const loadNumEgreso = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumEgreso(numeroSolicitud)
            }
        })
    }

    const [materialesInstalacion, setMaterialesInstalacion] = useState([]);

    const [materialesInstalacionAsignados, setMaterialesInstalacionAsignados] = useState([]);

    const getMaterialesAsignados = async () => {

        const agendaDB2 = await db.collection("flotas").where("correo", "==", props.correo_flota).get()

        const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        let ref = await db.collection("flotas").doc(data2[0].id).collection("materiales")

        ref.onSnapshot(async (snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setMaterialesInstalacionAsignados(data);

            data.forEach(async (material, index) => {

                let ref = await db.collection("flotas").doc(data2[0].id).collection('materiales').doc(material.codigo_producto).collection('series').where('estado', '==', 0)

                ref.onSnapshot((snapshot) => {
                    const dataSerie = [
                        ...snapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                id: doc.id,
                            };
                        }),
                    ];

                    dataSerie.forEach((serie, indexSerie) => {

                        const num = indexSerie;
                        let array = [];
                        for (var i = 0; i <= num; i++) {
                            //console.log('Hola')
                            let n;
                            n += i;
                            //console.log('for', n)
                            array.push({
                                index_serie: i,
                                id: i,
                                serie: dataSerie[i]['serie'],
                                descripcion: dataSerie[i]['descripcion']
                            });
                            data[index]["series"] = array;
                        }
                    })


                });
            })

        });
    }

    const rechazarInstalacion = (id) => {

        db.collection("tecnicos").doc(id).update({
            estado: 1,
            observacionInstalacionRechazada: observacionInstalacionRechazada,
            echaRechazoInstalacion: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_rechazo_instalacion: userInfo.displayName,
            fecha_rechazo_instalacion: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then(() => {
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha rechazado la solicitud de instalacion y se procedera a revisar el caso", });
            })
            .catch((error) => { console.error("Error adding document: ", error); });

        /* db.collection("agenda").doc(id).update({
          estado: 2,
          observacionInstalacionRechazada: observacionInstalacionRechazada,
          fechaRechazoInstalacion: firebase.firestore.FieldValue.serverTimestamp(),
        }) */

        /* ESTADO 3 === PENDIENTE DE REVISION DEL MOTIVO DE RECHAZO */

        db.collection("agenda").doc(id).update({
            estado: 2,
            observacionInstalacionRechazada: observacionInstalacionRechazada,
            fechaRechazoInstalacion: firebase.firestore.FieldValue.serverTimestamp(),
        })

    }

    /* FUNCTION SEND SERIE */

    const sendSerie = () => {

        materialesInstalacionAsignados.forEach(async function (obj, index) {

            const agendaDB2 = await db
                .collection("flotas")
                .where("correo", "==", props.correo_flota)
                .get();

            const data2 = await agendaDB2.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (obj.serie_entrega === undefined) {
                return;
            } else {
                const agendaDB3 = await db
                    .collection("flotas")
                    .doc(data2[0].id)
                    .collection("materiales")
                    .where("codigo_producto", "==", obj.codigo_producto)
                    .get();

                const data3 = await agendaDB3.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const agendaDB4 = await db
                    .collection("flotas")
                    .doc(data2[0].id)
                    .collection("materiales")
                    .doc(data3[0].id)
                    .collection("series")
                    .where('serie', '==', obj.serie_entrega.toString())
                    .get()

                const data4 = await agendaDB4.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                await db
                    .collection("flotas")
                    .doc(data2[0].id)
                    .collection("materiales")
                    .doc(data3[0].id)
                    .collection("series")
                    .doc(data4[0].id)
                    .update({
                        estado: 1,
                    });

                if (props.correo_flota.includes('u')) {
                    await db
                        .collection("bodegas_flotas")
                        .doc('flotas_internas')
                        .collection("materiales")
                        .doc(data3[0].id)
                        .collection("series")
                        .doc(data4[0].id)
                        .update({
                            estado: 1,
                        });
                } else {
                    await db
                        .collection("bodegas_flotas")
                        .doc('flotas_externas')
                        .collection("materiales")
                        .doc(data3[0].id)
                        .collection("series")
                        .doc(data4[0].id)
                        .update({
                            estado: 1,
                        });
                }

            }

        })
    }

    /* FUNCION DE ENVIO */

    const InstalacionRealizada = async (numeroContrato, numFlota) => {

        setLoadingConfirmInstalacion(true)

        if (data.cambio_domicilio === undefined) {
            var hoja_tipo = 'HOJA INSTALACION'
            var _numeroContrato = numeroContrato
        } else {
            var hoja_tipo = 'CAMBIO DOMICILIO'

            var _numeroContrato = data.NUM_CONTRATO
            //console.log(_numeroContrato, 'Numero de contrato')
        }

        const dataForm = {
            tipo_orden_trabajo: 'MIGRACION',
            numero_orden_trabajp: props.numContrato,
            uid_hoja_instalacion: `HI-${uidROT}`,
            numeroContrato_micro: _numeroContrato,
            uid: uidROT,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            ...data,
            numFlota: props.numFlota,
            numContrato: numeroContrato,
            hoja_tipo: 'MIGRACION',
            estado: 0,
            nombres: props.nombres,
            potencia: potencia,
            valorCuotas: valorCuotas.campo,
            valorCobrado: props.planValor,
            noCuotas: Number.isNaN(Number(noCuotas)) ? 0 : noCuotas,
            valor_mensual_a_pagar: numeroCuotasAPagarFiltroNaN,
            observacionInstalacionRealizada: observacionesExtra.toUpperCase() === '' ? 'NINGUNA OBSERVACION EXTRA' : observacionesExtra.toUpperCase(),
            observaciones_extra: observacionesExtra.toUpperCase() === '' ? 'NINGUNA OBSERVACION EXTRA' : observacionesExtra.toUpperCase(),
            valorIntsalacionYNumeroDeCuotas: numeroCuotasAPagarFiltroNaN,
            fechaDeInstalacion: firebase.firestore.FieldValue.serverTimestamp(),
            canton: props.canton,
            material: material,
            foto_potencia_casa: instalacion.foto_potencia_casa === undefined ? '' : instalacion.foto_potencia_casa,
            foto_potencia_caja: instalacion.foto_potencia_caja === undefined ? '' : instalacion.foto_potencia_caja,
            foto_etiqueta: instalacion.foto_etiqueta === undefined ? '' : instalacion.foto_etiqueta,
            foto_router_casa: instalacion.foto_router_casa === undefined ? '' : instalacion.foto_router_casa,
            foto_caja: instalacion.foto_caja === undefined ? '' : instalacion.foto_caja,
            created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp()
        }
        //
        db.collection("agenda").doc(props.id).update({
            fechaInstalacionMigracion: firebase.firestore.FieldValue.serverTimestamp(),
            estado: 5,
        })
        db.collection("hoja_de_trabajo").doc(props.id).set({
            ...dataForm,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })



        db.collection("registro_orden_trabajo").doc(props.id).set({
            ...dataForm,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            numFlota: props.numFlota,
            canton: props.canton,
            nombres: props.nombres,
        })

        db.collection("tecnicos").doc(props.id).update({
            ...dataForm,
            fechaInstalacionMigracion: firebase.firestore.FieldValue.serverTimestamp(),
            estado: 2,
            created_instalacion: firebase.firestore.FieldValue.serverTimestamp(),
        })

        sendSerie()

        handleSubmitEgreso()

        materialesInstalacionAsignados.forEach(async function (obj, index) {

            if (obj.cant_entrega != 0 && obj.cant_entrega != undefined && obj.cant_entrega != null) {
                db.collection('tecnicos').doc(props.id).collection('materiales_utilizados').doc(obj.codigo_producto).set({
                    bodega: obj.bodega === undefined || obj.bodega === null || obj.bodega === '' ? '' : obj.bodega,
                    categoria: obj.categoria === undefined || obj.categoria === null || obj.categoria === '' ? '' : obj.categoria,
                    codigo_producto: obj.codigo_producto === undefined || obj.codigo_producto === null || obj.codigo_producto === '' ? '' : obj.codigo_producto,
                    costo_promedio: obj.costo_promedio === undefined || obj.costo_promedio === null || obj.costo_promedio || Number.isNaN(Number(obj.costo_promedio)) === '' ? 0 : obj.costo_promedio,
                    motivo: obj.motivo === undefined || obj.motivo === null || obj.motivo === '' ? '' : obj.motivo,
                    precio_compra: obj.precio_compra === undefined || obj.precio_compra === null || obj.precio_compra === '' ? 0 : obj.precio_compra,
                    descripcion: obj.descripcion === undefined || obj.descripcion === null || obj.descripcion === '' ? '' : obj.descripcion,
                    cantidad_entregada_para_instalacion: obj.stock_actual === undefined || obj.stock_actual === null || obj.stock_actual || Number.isNaN(Number(obj.stock_actual)) === '' ? 0 : obj.stock_actual,
                    unidad_medida: obj.unidad_medida === undefined || obj.unidad_medida === null || obj.unidad_medida === '' ? '' : obj.unidad_medida,
                    cantidad_utilizada: obj.cant_entrega === undefined || obj.cant_entrega === null || obj.cant_entrega === '' || Number.isNaN(Number(obj.cant_entrega)) ? 0 : obj.cant_entrega,
                    serie_utilizada: obj.codigo_producto === 'INV-00113' ? guardarSerie : 'NINGUNA',
                    valor_metraje_extra: obj.valor_metraje_extra === undefined || obj.valor_metraje_extra === null || obj.valor_metraje_extra === '' || Number.isNaN(Number(obj.valor_metraje_extra)) ? 0 : obj.valor_metraje_extra,
                    numero_cuotas_metraje_extra: obj.numero_cuotas_metraje_extra === undefined || obj.numero_cuotas_metraje_extra === null || obj.numero_cuotas_metraje_extra === '' || Number.isNaN(Number(obj.numero_cuotas_metraje_extra)) ? 0 : obj.numero_cuotas_metraje_extra,
                    valor_mensual_a_pagar_metraje_extra: obj.valor_mensual_a_pagar_metraje_extra === undefined || obj.valor_mensual_a_pagar_metraje_extra === null || obj.valor_mensual_a_pagar_metraje_extra === '' || Number.isNaN(Number(obj.valor_mensual_a_pagar_metraje_extra)) ? 0 : obj.valor_mensual_a_pagar_metraje_extra,
                    valor_mensual_a_pagar_metraje_extra_con_descuento: obj.valor_mensual_a_pagar_metraje_extra_con_descuento === undefined || obj.valor_mensual_a_pagar_metraje_extra_con_descuento === null || obj.valor_mensual_a_pagar_metraje_extra_con_descuento === '' || Number.isNaN(Number(obj.valor_mensual_a_pagar_metraje_extra_con_descuento)) ? 0 : obj.valor_mensual_a_pagar_metraje_extra_con_descuento,
                })

                const agendaDB2 = await db
                    .collection("flotas")
                    .where("correo", "==", props.correo_flota)
                    .get();

                const data2 = await agendaDB2.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                if (String(obj.descripcion).includes("ONU")) {

                    const agendaDB3 = await db
                        .collection("flotas")
                        .doc(data2[0].id)
                        .collection("materiales")
                        .where("codigo_producto", "==", obj.codigo_producto)
                        .get();

                    const data3 = await agendaDB3.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    const resultStock =
                        parseFloat(data3[0].stock_actual).toFixed(2) -
                        parseInt(obj.cant_entrega).toFixed(0);

                    await db
                        .collection("flotas")
                        .doc(data2[0].id)
                        .collection("materiales")
                        .doc(data3[0].id)
                        .update({
                            stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                        });

                    if (props.correo_flota.includes('u')) {
                        await db
                            .collection("bodegas_flotas")
                            .doc('flotas_internas')
                            .collection("materiales")
                            .doc(data3[0].id)
                            .update({
                                stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                            });
                    } else {
                        await db
                            .collection("bodegas_flotas")
                            .doc('flotas_externas')
                            .collection("materiales")
                            .doc(data3[0].id)
                            .update({
                                stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                            });
                    }

                } else {

                    const agendaDB3 = await db
                        .collection("flotas")
                        .doc(data2[0].id)
                        .collection("materiales")
                        .where("codigo_producto", "==", obj.codigo_producto)
                        .get();

                    const data3 = await agendaDB3.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));

                    const resultStock =
                        parseFloat(data3[0].stock_actual).toFixed(2) -
                        parseInt(obj.cant_entrega).toFixed(0);

                    await db
                        .collection("flotas")
                        .doc(data2[0].id)
                        .collection("materiales")
                        .doc(data3[0].id)
                        .update({
                            stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                        });

                    if (props.correo_flota.includes('u')) {
                        await db
                            .collection("bodegas_flotas")
                            .doc('flotas_internas')
                            .collection("materiales")
                            .doc(data3[0].id)
                            .update({
                                stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                            });
                    } else {
                        await db
                            .collection("bodegas_flotas")
                            .doc('flotas_externas')
                            .collection("materiales")
                            .doc(data3[0].id)
                            .update({
                                stock_actual: Number.isNaN(Number(resultStock)) ? 0 : resultStock,
                            });
                    }
                }
            } else {
                return
            }

        });

        setOpenPuerto(false)
        setOpenConfirmInstalacion(false)

        Swal.fire({
            title: 'Se esta realizando su gestion',
            text: 'Por favor espere',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        setTimeout(() => {

            Swal.fire({
                icon: "success",
                text: 'Orden de trabajo creada con exito'
            });

            navigate(`/Instaladores/registro-orden-de-trabajo/`)

        }, 25000)

        /* Swal.fire({
            icon: "success",
            text: 'Orden de trabajo creada con exito'
        }); */
    }

    //HANDLE SUBMIT EGRESO

    const handleSubmitEgreso = () => {
        let rol = ''
        if (userInfo.rol === 0) {
            rol = 'ADMIN'
        }
        if (userInfo.rol === 1) {
            rol = 'COMERCIAL'
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = 'OPERACIONES'
        }
        if (userInfo.rol === 3) {
            rol = 'PLANEACION'
        }
        if (userInfo.rol === 4) {
            rol = 'FLOTAS'
        }
        if (userInfo.rol === 5) {
            rol = 'REPORTES'
        }
        if (userInfo.rol === 6) {
            rol = 'RECURSOS HUMANOS'
        }
        //
        const uidSolicitud = `MOV-` + numSolicitudFlota;

        //SE INGRESAN LOS PRODUCTOS A 'EGRESO DE PRODUCTOS'

        db.collection('reporte_movimientos_flotas').doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numSolicitudFlota,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: ''/* rol */,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: ''/* props.bodega_origen */,
            ubicacion: '',
            motivo: 'ORDEN DE TRABAJO',
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionesExtra === '' ? 'NINGUNA' : observacionesExtra.toUpperCase(),
            flota: props.numFlota,
            lider_flota: props.nombreLider,
            auxiliar_flota: props.nombreAuxiliar,
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso',
            id_orden_trabajo: props.id,
            bodega_general: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
            tipo_flota: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
        })

        db.collection('egreso_productos').doc(`EGR-${numEgreso}`).set({
            uidSolicitud: `EGR-${numEgreso}`,
            estado: 0,
            numero_solicitud: numEgreso,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: ''/* props.departamento_seleccionado */,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA'/* props.bodega */,
            motivo: ''/* props.motivo.toUpperCase() */,
            motivo_movimiento: 'EGRESO',
            observacion_adicional: ''/* observacionExtra === '' ? 'NINGUNA' : observacionExtra */,
            tipo_movimiento: 'ORDEN TRABAJO',
            fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
            relacion: props.numContrato
        })

        db.collection(`reporte_movimientos`).doc(`EGR-${numEgreso}`).set({
            uidSolicitud: `EGR-${numEgreso}`,
            estado: 0,
            numero_solicitud: numEgreso,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: ''/* props.departamento_seleccionado */,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA'/* props.bodega */,
            ubicacion: '',
            motivo: 'ORDEN TRABAJO'/* props.motivo.toUpperCase() */,
            motivo_movimiento: 'EGRESO',
            observacion_adicional: ''/* observacionExtra === '' ? 'NINGUNA' : observacionExtra */,
            flota: props.numFlota,
            lider_flota: props.nombreLider,
            auxiliar_flota: props.nombreAuxiliar,
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'ORDEN TRABAJO',
            empleado: props.nombreLider,
            fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
            relacion: props.numContrato
        })

        materialesInstalacionAsignados.forEach(async function (obj, index) {

            if (obj.cant_entrega != 0 && obj.cant_entrega != undefined && obj.cant_entrega != null) {
                const agendaDB1 = await db.collection("flotas").where('correo', '==', props.correo_flota).get()

                const data1 = await agendaDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const agendaDB2 = await db.collection("flotas").doc(data1[0].id).collection('materiales').get()

                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const agendaDB3 = await db.collection("flotas").doc(data1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo_producto).get()

                const data3 = await agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const costoPromedio = data3[0].costo_promedio

                const cantidad_total = parseFloat(data3[0].stock_actual) - parseFloat(obj.cant_entrega)

                const saldo_valorizado = parseFloat(data3[0].costo_promedio).toFixed(2) * parseFloat(obj.cant_entrega).toFixed(2)

                const dataRef = {
                    cantidad_solicitada: Number.isNaN(Number(parseInt(obj.cant_entrega))) ? 0 : parseInt(obj.cant_entrega),
                    cantidad_total_antes: Number.isNaN(Number(parseFloat(data3[0].stock_actual))) ? 0 : parseFloat(data3[0].stock_actual),
                    cantidad_total_despues: Number.isNaN(Number(parseFloat(cantidad_total).toFixed(2))) ? 0 : parseFloat(cantidad_total).toFixed(2),
                    ...obj,
                    uid_solicitud_pedido: uidSolicitud,
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    categoria_producto: obj.categoria,
                    subcategoria_producto: '',
                    marca_producto: '',
                    modelo_producto: '',
                    presentacion_producto: '',
                    unidad_Medida_Producto: '',
                    usuario_que_creo_el_producto: '',
                    costo_promedio: costoPromedio,
                    saldo_valorizado: saldo_valorizado === 0 || saldo_valorizado === undefined || Number.isNaN(Number(saldo_valorizado)) ? 0 : saldo_valorizado,
                    total: '',
                    precio_compra: data3[0].precio_compra,
                    observacion_adicional: observacionesExtra === '' ? 'NINGUNA' : observacionesExtra.toUpperCase(),
                    flota: props.numFlota,
                    lider_flota: props.nombreLider,
                    auxiliar_flota: props.nombreAuxiliar,
                    placa_flota: '',
                    marca_flota: '',
                    modelo_flota: '',
                    bodega: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    ubicacion: '',
                    motivo: 'ORDEN DE TRABAJO',
                    tipo_movimiento: 'egreso',
                    total_bodegas: Number.isNaN(Number(parseFloat(data3[0].stock_actual) - parseFloat(data3[0].cant_entrega))) ? 0 : parseFloat(data3[0].stock_actual) - parseFloat(data3[0].cant_entrega),
                    suma_valores_total_blodegas: '',
                    costo_promedio_general: costoPromedio,
                    id_orden_trabajo: props.id,
                    codigo: obj.codigo_producto,
                    bodega_general: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    tipo_flota: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    fecha_documento: firebase.firestore.FieldValue.serverTimestamp()
                }

                const dataRefBodegaFlota = {
                    cantidad_solicitada: Number.isNaN(Number(parseInt(obj.cant_entrega))) ? 0 : parseInt(obj.cant_entrega),
                    cantidad_total_antes: Number.isNaN(Number(parseFloat(data3[0].stock_actual))) ? 0 : parseFloat(data3[0].stock_actual),
                    cantidad_total_despues: Number.isNaN(Number(parseFloat(cantidad_total).toFixed(2))) ? 0 : parseFloat(cantidad_total).toFixed(2),
                    ...obj,
                    uid_solicitud_pedido: uidSolicitud,
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    categoria_producto: obj.categoria,
                    subcategoria_producto: '',
                    marca_producto: '',
                    modelo_producto: '',
                    presentacion_producto: '',
                    unidad_Medida_Producto: '',
                    usuario_que_creo_el_producto: '',
                    costo_promedio: costoPromedio,
                    saldo_valorizado: saldo_valorizado === 0 || saldo_valorizado === undefined || Number.isNaN(Number(saldo_valorizado)) ? 0 : saldo_valorizado,
                    total: '',
                    precio_compra: data3[0].precio_compra,
                    observacion_adicional: observacionesExtra === '' ? 'NINGUNA' : observacionesExtra.toUpperCase(),
                    flota: props.numFlota,
                    lider_flota: props.nombreLider,
                    auxiliar_flota: props.nombreAuxiliar,
                    placa_flota: '',
                    marca_flota: '',
                    modelo_flota: '',
                    bodega: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    ubicacion: '',
                    motivo: 'ORDEN DE TRABAJO',
                    tipo_movimiento: 'egreso',
                    total_bodegas: Number.isNaN(Number(parseFloat(data3[0].stock_actual) - parseFloat(obj.cant_entrega))) ? 0 : parseFloat(data3[0].stock_actual) - parseFloat(obj.cant_entrega),
                    suma_valores_total_blodegas: '',
                    costo_promedio_general: costoPromedio,
                    id_orden_trabajo: props.id,
                    codigo: obj.codigo_producto,
                    bodega_general: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    tipo_flota: props.correo_flota.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    fecha_documento: firebase.firestore.FieldValue.serverTimestamp()
                }

                db.collection(`total_movimientos`).doc().set(dataRef)

                db.collection(`total_movimientos_flotas`).doc().set(dataRefBodegaFlota)

                db.collection(`reporte_movimientos_flotas/${uidSolicitud}/items`).doc(obj.codigo_producto).set(dataRef)

                db.collection(`reporte_movimientos`).doc(`EGR-${numEgreso}`).collection('items').doc(obj.codigo_producto).set({
                    /* ...obj, */
                    fullName: obj.codigo_producto,
                    descripcion: obj.descripcion,
                    uid_solicitud_pedido: `EGR-${numEgreso}`,
                    cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                    cantidad_total_antes: data3[0].stock_actual,
                    cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    saldo_valorizado: saldo_valorizado === 0 || saldo_valorizado === undefined || Number.isNaN(Number(saldo_valorizado)) ? 0 : saldo_valorizado,//totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2,
                    costo_promedio: costoPromedio <= 0 ? 0 : costoPromedio,
                    tipo_movimiento: 'egreso',
                    precio_compra: data3[0].precio_compra,
                    observacion_adicional: '', //observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                    fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                })

            } else {
                return
            }

        })

    }

    //TOTAL PAGO

    const numeroCuotasAPagar = parseFloat(props.planValor) / parseFloat(noCuotas);

    const numeroCuotasAPagarFiltroNaN = Number.isNaN(Number(numeroCuotasAPagar)) || numeroCuotasAPagar === Infinity ? 0 : numeroCuotasAPagar

    const setUpdateCaja = async (numFlota) => {
        await db.collection('tecnicos').doc(numFlota).update({
            caja: caja
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado la caja con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });

        setOpenCaja(false)
    }

    const [isDisabled, setIsDisabled] = useState(false);

    const [seriesMaterialesInstalacionAsignados, setSeriesMaterialesInstalacionAsignados] = useState([]);

    const handleChange = async (index, evnt, codigoId) => {
        const { name, value } = evnt.target;
        const list = [...materialesInstalacionAsignados];
        const cantidad_pedida = list[index]["stock_actual"]
        const cantidad_entregada = parseInt(evnt.target.value)
        if (cantidad_entregada > cantidad_pedida) {
            console.log('Si entra')
        } else if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {

                const { name, value } = evnt.target;
                const list = [...materialesInstalacionAsignados];
                list[index][name] = value;

                const cantidad_recibida = list[index]["cant_entrega"]
                const diferencia_recepcion = list[index]["stock_actual"] - parseInt(cantidad_recibida)
                list[index]["cant_entrega"] = parseInt(cantidad_entregada)

                setMaterialesInstalacionAsignados(list);
                /* contador(); */

                const respJson = materialesInstalacionAsignados.some((items) => items.cant_entrega)

                setIsDisabled(respJson)

                /* CONDICIONALES POR SI SE EXCEDEN CON EL METRAJE */

                if (codigoId.toString().includes('SUM-00490')) {
                    console.log('incluye metraje seleccionado')

                    if (cantidad_entregada > 200) {
                        console.log('Metraje excede los 200')
                        const ultimoDigito = cantidad_entregada % 100;

                        if (ultimoDigito > 0) {
                            const multiplicacion = ultimoDigito * 0.5;

                            const valor_metraje_extra = list[index]["valor_metraje_extra"]
                            list[index]["valor_metraje_extra"] = multiplicacion

                        } else {
                        }
                    } else {
                    }
                } else if (codigoId.toString().includes('SUM-00491')) {
                    console.log('incluye metraje seleccionado')

                    if (cantidad_entregada > 200) {
                        console.log('Metraje excede los 200')
                        const ultimoDigito = cantidad_entregada % 100;

                        if (ultimoDigito > 0) {
                            const multiplicacion = ultimoDigito * 0.5;

                            const valor_metraje_extra = list[index]["valor_metraje_extra"]
                            list[index]["valor_metraje_extra"] = multiplicacion

                        } else {
                        }
                    } else {
                    }
                } else if (codigoId.toString().includes('SUM-00492')) {
                    console.log('incluye metraje seleccionado')

                    if (cantidad_entregada > 200) {
                        console.log('Metraje excede los 200')
                        const ultimoDigito = cantidad_entregada % 100;

                        if (ultimoDigito > 0) {
                            const multiplicacion = ultimoDigito * 0.5;

                            const valor_metraje_extra = list[index]["valor_metraje_extra"]
                            list[index]["valor_metraje_extra"] = multiplicacion

                        } else {
                        }
                    } else {
                    }
                } else if (codigoId.toString().includes('INV-00067')) {
                    console.log('incluye metraje seleccionado')

                    if (cantidad_entregada > 200) {
                        console.log('Metraje excede los 200')
                        const ultimoDigito = cantidad_entregada % 100;

                        if (ultimoDigito > 0) {
                            const multiplicacion = ultimoDigito * 0.5;

                            const valor_metraje_extra = list[index]["valor_metraje_extra"]
                            list[index]["valor_metraje_extra"] = multiplicacion

                        } else {
                        }
                    } else {
                    }
                }



            } else {
                const list = [...materialesInstalacionAsignados];
                list[index]['codigo_producto'] = evnt;
                setMaterialesInstalacionAsignados(list);

                const respJson = materialesInstalacionAsignados.some((items) => items.cant_entrega)

                respJson(respJson)

            }
        }
    }

    const handleChangeMetrajeExtra = async (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...materialesInstalacionAsignados];
        const cantidad_entregada = parseInt(evnt.target.value)
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {

                const { name, value } = evnt.target;
                const list = [...materialesInstalacionAsignados];
                list[index][name] = value;

                list[index]["numero_cuotas_metraje_extra"] = parseInt(cantidad_entregada)

                const valorMensualAPagarMetrajeExtra = list[index]["valor_metraje_extra"] / cantidad_entregada

                list[index]["valor_mensual_a_pagar_metraje_extra"] = valorMensualAPagarMetrajeExtra

                list[index]["valor_mensual_a_pagar_metraje_extra_con_descuento"] = valorMensualAPagarMetrajeExtra / 2

                setMaterialesInstalacionAsignados(list);

                const respJson = materialesInstalacionAsignados.some((items) => items.cant_entrega)

                setIsDisabled(respJson)



            } else {
                const list = [...materialesInstalacionAsignados];
                list[index]['codigo_producto'] = evnt;
                setMaterialesInstalacionAsignados(list);

                const respJson = materialesInstalacionAsignados.some((items) => items.cant_entrega)

                respJson(respJson)

            }
        }
    }

    /* FUNCION PARA LAS SERIES ENTREGADAS */

    const handleChangeSerieEntrega = async (index, evnt) => {

        const list = [...materialesInstalacionAsignados];
        const serie_entrega = evnt.target.value
        list[index]["serie_entrega"] = serie_entrega
        if (serie_entrega != undefined || serie_entrega != '') {
            setGuardarSerie(serie_entrega)
        }
    }

    const openModalUpdateCaja = () => {
        setOpenCaja(true)
        setOpenPuerto(false)
    }

    const closeModalUpdateCaja = () => {
        setOpenPuerto(true)
        setOpenCaja(false)
    }

    /* FOTOS */

    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file6, setFile6] = useState(null);

    const instalacionDetalle = useSelector(state => state.instalacionDetalle)
    const { instalacion } = instalacionDetalle

    const [uidROT, setUidROT] = useState('');

    const getNumRegistroOrdenTrabajo = () => {
        const ref = db.collection("registro_orden_trabajo").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const registro = solicitudes[0]
                const id = registro.uid + 1;
                setUidROT(id)
            }
        })
    }

    const dispatch = useDispatch()

    useEffect(() => {

        getProductos()

        loadNumEgreso()

        getNumRegistroOrdenTrabajo()

        getPhotosCollectionFirebase()

        getMaterialesUtilizados()

        loadNumSolicitudFlota()

        getMaterialesAsignados()

        dispatch(detallesInstalacion(props.id))
        setFile1(instalacion.foto_potencia_casa)
        setFile2(instalacion.foto_potencia_caja)
        setFile3(instalacion.foto_router_casa)
        setFile4(instalacion.foto_etiqueta)

        setFile6(instalacion.foto_caja)

        /* getMateriales() */

        setNombrePlan(props.planNombre);
        const { one, two } = number
        setSuma(Number(one) + Number(two))
        setValorCobrado(props.planValor);
        //mensajeHidden()
        dispatch(listsMateriales())


    }, [dispatch, number])

    const sendDataCloudFunction = async () => {
        setLoadingConfirmInstalacion(true)
        try {
            const sendMigracionServicioNew = firebase.functions().httpsCallable("sendMigracionServicioNew");

            const data = {
                numeroContrato: props.id,
                props: props,
                uidROT: Number.isNaN(Number(uidROT)) ? 0 : uidROT,
                instalacion: instalacion,
                guardarSerie: guardarSerie,
                materialesInstalacionAsignados: inputFields,
                potencia: potencia,
                noCuotas: Number.isNaN(Number(noCuotas)) ? 0 : noCuotas,
                numeroCuotasAPagarFiltroNaN: Number.isNaN(Number(numeroCuotasAPagarFiltroNaN)) ? 0 : numeroCuotasAPagarFiltroNaN,
                observacionesExtra: observacionesExtra,
                numSolicitudFlota: numSolicitudFlota,
                numEgreso: numEgreso,
                userInfo: userInfo
            }

            const response = await sendMigracionServicioNew(data);

            setOpenPuerto(false)
            setOpenConfirmInstalacion(false)

            Swal.fire({ icon: "success", text: "Se realizo correctamente su instalacion", });

            console.log(response.data.message); // Mensaje de éxito de la Cloud Function
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpenPuerto(false)
            setOpenConfirmInstalacion(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    };

    const addInput = (row) => {
        console.log(row, "row AddInput");
        if (inputFields.find((x) => x.fullName === row.codigo_producto)) {
            /* Swal.fire({
                icon: "info", 
                text: "Ya se agrego este producto a la lista",
            }); */
            setItem("");
            setModalProducts(false);
        } else {
            setInputFields([
                ...inputFields,
                {
                    fullName: row.codigo_producto,
                    cantidad_pedida: 0,
                    descripcion: row.descripcion_producto,
                    codigo: row.codigo_producto,
                    categoria_producto: row.categoria_producto,
                    subcategoria_producto: row.subcategoria_producto,
                    marca_producto: row.marca_producto,
                    modelo_producto: row.modelo_producto,
                    presentacion_producto: row.presentacion_producto,
                    series: [{ serie: 0 }],
                    unidad_Medida_Producto: row.unidad_Medida_Producto,
                    usuario_que_creo_el_producto: row.usuario_que_creo_el_producto,
                    tipo_movimiento: "ingreso",
                    precio_compra_producto: row.precio_compra_producto,
                    usa_serie: row.usa_serie,
                    costo_promedio: row.costo_promedio,
                    id_producto: row.id
                },
            ]);
        }
        setItem("");
        setModalProducts(false);
    };

    const blockButtonAddItem = async (row) => {

        addInput(row)

    }

    const removeInputFields = (codigo) => {

        const rows = [...inputFields];

        const indexOfObject = rows.findIndex((object) => {
            return object.codigo === codigo;
        });

        rows.splice(indexOfObject, 1);

        setInputFields(rows);
    };

    const handleChangeProducto = async (index, evnt) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else if (evnt.target) {
            const { name, value } = evnt.target;
            const list = [...inputFields];
            list[index][name] = value;
            //
            setInputFields(list);
            //Cantidad
            //console.log(evnt.target.value, 'cantidad')
            const num = evnt.target.value;
            let array = [];
            for (let i = 0; i < num; i++) {
                //console.log('Hola')
                let n;
                n += i;
                //console.log('for', n)
                array.push({
                    index_serie: index,
                    id: i,
                    serie: 0,
                });
            }
            list[index]["series"] = array;
            //
            console.log(list, "--");
            //console.log(inputFields, 'array')
        } else {
            const list = [...inputFields];
            list[index]["fullName"] = evnt;
            setInputFields(list);
        }
    };

    function detalleMate(data, index) {
        return (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell style={{ fontSize: "12px" }} component="th" scope="row">
                    {index + 1}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.codigo}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.descripcion}
                </TableCell>
                <TableCell align="center">
                    <TextField
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        name={"cantidad_pedida"}
                        onChange={(e) => {
                            handleChangeProducto(index, e)
                        }}
                        value={data.cantidad_pedida}
                        size="small"
                        type="number"
                    />
                </TableCell>

                <TableCell align="center">
                    <IconButton
                        onClick={() => removeInputFields(data.codigo)}
                        color="error"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    return (
        <>
            <Tooltip title="Migracion">
                <Button onClick={() => setOpenPuerto(true)/* onData() */}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* ---------------------------------------------------------- */}
            {/* ACTUALIZAR CAJA */}
            <Dialog open={openCaja} onClose={() => setOpenCaja(false)} maxWidth="xs">
                <DialogTitle>Por favor asegurece de ingresar correctamente los datos.</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Box py={1}>
                            <FormControl fullWidth>
                                <Box py={1}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="CAJA"
                                        value={caja}
                                        onChange={(e) => setCaja(e.target.value)}
                                    />
                                </Box>
                            </FormControl>
                        </Box>


                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeModalUpdateCaja()}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setUpdateCaja(props.numContrato)} size="small" variant="contained" color="secondary">
                        {" "}
                        ACTUALIZAR CAJA
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ---------------------------------------------------------- */}
            {/* RECHAZO INSTALACION */}
            <Dialog open={openInstalacionRechazada} onClose={() => setOpenInstalacionRechazada(false)} maxWidth="xs">
                <DialogTitle>Por favor deje una observacion del por que rechaza la instalacion.</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Box py={2}>
                            <FormControl fullWidth>
                                <InputLabel>Observaciones</InputLabel>
                                <Select
                                    label="Observaciones"

                                    value={observacionInstalacionRechazada}
                                    onChange={(e) => setObservacionInstalacionRechazada(e.target.value)}
                                >
                                    <MenuItem value="No hay caña o posteria">No hay caña o posteria</MenuItem>
                                    <MenuItem value="No hay disponibilidad">No hay disponibilidad</MenuItem>
                                    <MenuItem value="No hay dinero">No hay dinero</MenuItem>
                                    <MenuItem value="Sector inseguro">Sector inseguro</MenuItem>
                                    <MenuItem value="Caja llena">Caja llena</MenuItem>
                                    <MenuItem value="Coordenadas erroneas del vendedor">Coordenadas erroneas del vendedor</MenuItem>
                                    <MenuItem value="Cliente no disponible">Cliente no disponible</MenuItem>
                                    <MenuItem value="No existe unidad">No existe unidad</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>


                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenSendInstalacionRechazada(true)} variant="contained">
                        {" "}
                        Rechazar Instalacion
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ---------------------------------------------------------- */}
            {/* CONFIRMAR RECHAZO INSTALACION */}
            <Dialog open={openSendInstalacionRechazada} onClose={() => setOpenSendInstalacionRechazada(false)} maxWidth="xs">
                <DialogTitle><strong>¿Esta seguro de rechazar esta instalacion?</strong></DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSendInstalacionRechazada(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => rechazarInstalacion(props.id)} variant="contained">
                        {" "}
                        Rechazar Instalacion
                    </Button>
                </DialogActions>
            </Dialog>
            {/* --------------------------------------------------------------- */}
            {/* COLOCACION DEL PUERTO */}
            <Dialog fullScreen open={openPuerto} fullWidth onClose={() => setOpenPuerto(false)} maxWidth="md">
                {/* <DialogTitle><strong>ORDEN DE TRABAJO</strong></DialogTitle> */}
                <DialogContent dividers>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>ORDEN DE MIGRACION DE SERVICIO</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>



                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>TIPO DE ORDEN DE TRABAJO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value='MIGRACION'
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>#ORDEN DE TRABAJO</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.numeroContrato}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>#CONTRATO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.numContrato}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>NOMBRE DEL CLIENTE</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.nombres}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>CEDULA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.cedula}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>DIRECCION</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.direccion}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>TELEFONO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.telefono}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SERVICIO CONTRATADO</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.planNombre}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR COBRADO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.planValor}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>N CUOTAS</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            type="number"
                                                            size="small"
                                                            fullWidth
                                                            value={noCuotas}
                                                            onChange={(e) => setNoCuotas(e.target.value)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {
                                            noCuotas === '' ? '' :

                                                <Box pt={2}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={2}>
                                                            <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR MENSUAL A PAGAR</p>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    sx={{
                                                                        '& legend': { display: 'none' },
                                                                        '& fieldset': { top: 0 },
                                                                    }}
                                                                    size="small"
                                                                    fullWidth
                                                                    value={parseFloat(numeroCuotasAPagarFiltroNaN).toFixed(2)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                        }

                                    </Grid>

                                    <Grid item xs={6}>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>TECNICO RESPONSABLE</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.nombreLider}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>FLOTA DE INSTALACION</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.numFlota}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box py={2}>
                                            <p><strong>VIVIENDA CLIENTE</strong></p>
                                            <img src={props.foto_cliente_vivienda} width={500} />
                                        </Box>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>MATERIALES</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Box pb={1}>
                                    <Button
                                        variant='outlined'
                                        color="success"
                                        size="small"
                                        onClick={() => setModalProducts(true)}>
                                        Agregar material utilizado
                                    </Button>
                                </Box>

                                <TableContainer component={Paper}>
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        size="small"
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NO.</TableCell>
                                                <TableCell align="center" style={{ fontSize: "12px" }}>
                                                    <strong>CODIGO</strong>
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "12px" }}>
                                                    <strong>DESCRIPCION</strong>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    style={{ width: "160px", fontSize: "12px" }}
                                                >
                                                    <strong>CANTIDAD</strong>
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "12px" }}>
                                                    <strong>ELIMINAR</strong>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {inputFields.map((data, index) => {
                                                return detalleMate(data, index);
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                                <Box py={2} px={2}>

                                    {/* MATERIALES ASIGNADOS */}

                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SERIAL ONU</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={guardarSerie}
                                                            onChange={(e) => {
                                                                if (e.target.value.length <= 16) {
                                                                    setGuardarSerie(e.target.value);
                                                                }
                                                            }}
                                                            inputProps={{
                                                                maxLength: 16
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* MATERIALES UTILIZADOS */}

                                        <Grid item xs={6}>
                                        </Grid>

                                    </Grid>

                                </Box>
                            </Box>


                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>OBSERVACIONES GENERALES</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>POTENCIA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        type="number"
                                                        size="small"
                                                        fullWidth
                                                        value={potencia}
                                                        onChange={(e) => setPotencia(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>OBSERVACIONES EXTRA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={observacionesExtra}
                                                        onChange={(e) => setObservacionesExtra(e.target.value)}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>FOTOS</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box px={1} py={1}>
                        <Paper elevation={3}>

                            <Box py={2} px={2}>

                                <Grid container>
                                    <Grid md={6}>
                                        <Box pb={5}>
                                            <Typography>Documento potencia casa</Typography>
                                            <Divider />
                                        </Box>
                                        {dataPhotos[0] && dataPhotos[0].foto_potencia_casa ?
                                            <>
                                                <img width={100} src={dataPhotos[0].foto_potencia_casa} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <UpdateFileUploadInstaladores
                                                instalacion={props}
                                                cambiarEstado={setFile1}
                                                estado={file1}
                                                nameFile="foto_potencia_casa"
                                            />}

                                        <Box py={5}>
                                            <Typography>Documento potencia caja</Typography>
                                            <Divider />
                                        </Box>
                                        {dataPhotos[0] && dataPhotos[0].foto_potencia_caja ?
                                            <>
                                                <img width={100} src={dataPhotos[0].foto_potencia_caja} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <UpdateFileUploadInstaladores
                                                instalacion={props}
                                                cambiarEstado={setFile2}
                                                estado={file2}
                                                nameFile="foto_potencia_caja"
                                            />}
                                        <Box py={5}>
                                            <Typography>Documento router casa</Typography>
                                            <Divider />
                                        </Box>
                                        {dataPhotos[0] && dataPhotos[0].foto_router_casa ?
                                            <>
                                                <img width={100} src={dataPhotos[0].foto_router_casa} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <UpdateFileUploadInstaladores
                                                instalacion={props}
                                                cambiarEstado={setFile3}
                                                estado={file3}
                                                nameFile="foto_router_casa"
                                            />}
                                    </Grid>
                                    <Grid md={6}>
                                        <Box pb={5}>
                                            <Typography>Documento foto etiqueta</Typography>
                                            <Divider />
                                        </Box>
                                        {
                                            dataPhotos[0] && dataPhotos[0].foto_etiqueta ?
                                                <>
                                                    <img width={100} src={dataPhotos[0].foto_etiqueta} />
                                                    <Alert>Archivo subido</Alert>
                                                </>
                                                :
                                                <UpdateFileUploadInstaladores
                                                    instalacion={props}
                                                    cambiarEstado={setFile4}
                                                    estado={file4}
                                                    nameFile="foto_etiqueta"
                                                />}

                                        <Box py={5}>
                                            <Typography>Documento foto caja</Typography>
                                            <Divider />
                                        </Box>

                                        {
                                            dataPhotos[0] && dataPhotos[0].foto_caja ?
                                                <>
                                                    <img width={100} src={dataPhotos[0].foto_caja} />
                                                    <Alert>Archivo subido</Alert>
                                                </>
                                                :
                                                <UpdateFileUploadInstaladores
                                                    instalacion={props}
                                                    cambiarEstado={setFile6}
                                                    estado={file6}
                                                    nameFile="foto_caja"
                                                />
                                        }

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>


                </DialogContent>
                <DialogActions>
                    <Button color="warning" variant="outlined" size="small" onClick={() => setOpenPuerto(false)}><strong>REGRESAR</strong></Button>
                    <Button size="small" color="error" variant="outlined" onClick={() => {
                        setOpenSendInstalacionRechazada(true)
                    }}>RECHAZAR MIGRACION</Button>
                    <Button color="primary" variant="outlined" size="small" onClick={() => openModalUpdateCaja()}><strong>ACTUALIZAR CAJA</strong></Button>
                    <Button
                        disabled={
                            numeroCuotasAPagarFiltroNaN === ''
                            || potencia === ''
                            || !dataPhotos[0].foto_potencia_casa
                            || !dataPhotos[0].foto_potencia_caja
                            || !dataPhotos[0].foto_router_casa
                            || !dataPhotos[0].foto_etiqueta
                            || !dataPhotos[0].foto_caja
                        }
                        size="small"
                        color='secondary'
                        disableElevation
                        onClick={() => setOpenConfirmInstalacion(true)}
                        variant="contained"
                    >

                        CONFIRMAR MIGRACION
                    </Button>
                </DialogActions>
            </Dialog>
            {/* --------------------------------------------------------------- */}
            {/* CONFIRMACION DE LA INSTALACION */}
            <Dialog open={openConfirmInstalacion} onClose={() => setOpenConfirmInstalacion(false)} maxWidth="xs">
                <DialogContent>Por favor, asegúrese que ha llenado todos los campos necesarios.</DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loadingConfirmInstalacion}
                        endIcon={loadingConfirmInstalacion === true ? <SendIcon /> : ''}
                        loadingPosition="end"
                        onClick={() => setOpenConfirmInstalacion(false)} size="small" color="primary" variant="outlined" ><strong>Cancelar</strong></LoadingButton>
                    <LoadingButton
                        loading={loadingConfirmInstalacion}
                        endIcon={loadingConfirmInstalacion === true ? <SendIcon /> : ''}
                        loadingPosition="end"
                        /* component={RouterLink} */
                        size="small"
                        color='secondary'
                        onClick={() => sendDataCloudFunction()/* InstalacionRealizada(props.id, props.numFlota) */}
                        variant="contained"
                        /* to={`/Instaladores/registro-orden-trabajo/`} */>
                        {" "}
                        Confirmar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* MODAL PRODUCTOS INVENTARIO */}

            <Dialog
                open={modalProducts}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    AGREGAR ITEMS
                </DialogTitle>
                <DialogContent dividers >
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={productos}
                            clearOnBlur={false}
                            autoComplete={false}
                            disableClearable
                            getOptionLabel={(option) => option.descripcion_producto}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item)
                                setItem(newInputValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CODIGO</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productos && productos
                                    .filter((val) => {
                                        if (item === "") {
                                            return val;
                                        } else if (val.descripcion_producto.toLowerCase().includes(item.toLowerCase())) {
                                            return val;
                                        }
                                    }).slice(0, 10).map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.codigo_producto}</TableCell>
                                            <TableCell>{row.descripcion_producto}</TableCell>
                                            <TableCell><Button
                                                size='small'
                                                variant='contained'
                                                color='success'
                                                onClick={() => blockButtonAddItem(row)
                                                            /* onClick={() => addInput(row) */}
                                            >Agregar</Button></TableCell>
                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box py={5} ></Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setModalProducts(false)}>
                        Cerrar ventana
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}