import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Card, CardContent, CardActions, Container } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";

export const CreateItemEstadoFinanciero = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [nombreTipoCuenta, setNombreTipoCuenta] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createNewBodega = async (NombreCuenta) => {
        await db.collection('item_estado_financiero').doc().set({
            nombre_item_estado_financiero: NombreCuenta,
            fecha_creacion_item_estado_financiero: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_creador_item_estado_financiero: userInfo.displayName
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nuevo item de estado financiero creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />
            <Card>

                <Container maxWidth="lg">
                <CardContent>
                    <InputValidation

                        fullWidth
                        estado={nombreTipoCuenta}
                        cambiarEstado={setNombreTipoCuenta}
                        label="ITEM ESTADO FINANCIERO"
                        name="tipoDeCuenta"
                    />
                </CardContent>

                <CardActions>
                    
                    <Button
                        onClick={() => createNewBodega(nombreTipoCuenta.campo.toUpperCase())}
                        fullWidth
                        variant="contained"
                        disableElevation
                        disabled={
                            !nombreTipoCuenta.campo
                        }
                    >
                        {" "}
                        CREAR{" "}
                    </Button>
                </CardActions>
                </Container>
            </Card>
        </>
    );
};
