import {
    Box,
    Paper,
    Button,
    Grid,
    TextField,
    Select,
    FormControl,
    MenuItem,
    Alert,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent
} from "@mui/material"

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

import { auth, firebase, functions, db, storage } from '../../../firebase';

import {
    listsTicketsDepartamentos,
    listsTicketsAsuntos,
    listsTicketsOficinas,
    listsTicketsTurnos
} from "../../../redux/actions/parametersActions";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import { useParams, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'

export const NuevoTicket = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [data, setData] = useState([])

    const [numTicket, setNumTicket] = useState('')

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false)

    const [dataDatos, setDataDatos] = useState([])

    const [linea, setLinea] = useState('')

    const [numContrato, setNumContrato] = useState('')

    const [planNombre, setPlanNombre] = useState('')

    const [cedula, setCedula] = useState('')

    const [correo, setCorreo] = useState('')

    const [telefono, setTelefono] = useState('')

    const [movil, setMovil] = useState('')

    const [direccion, setDireccion] = useState('')

    const [coordenadas, setCoordenadas] = useState('')

    const [caja, setCaja] = useState('')

    const [fotoClienteVivienda, setFotoClienteVivienda] = useState('')

    const [zona, setZona] = useState('')

    const [valorACobrar, setValorACobrar] = useState('')

    const [nombreSolicitante, setNombreSolicitante] = useState('')

    const ticketsDepartamentosList = useSelector((state) => state.ticketsDepartamentosList);
    const { ticketsDepartamentos, loadingTicketsDepartamentos } = ticketsDepartamentosList;
    const [ticketsDepartamento, setTicketsDepartamento] = useState("");

    const ticketsAsuntosList = useSelector((state) => state.ticketsAsuntosList);
    const { ticketsAsuntos, loadingTicketsAsuntos } = ticketsAsuntosList;
    const [ticketsAsunto, setTicketsAsunto] = useState("");

    const ticketsOficinasList = useSelector((state) => state.ticketsOficinasList);
    const { ticketsOficinas, loadingTicketsOficinas } = ticketsOficinasList;
    const [ticketsOficina, setTicketsOficinas] = useState("VIA TELEFONICA");

    const ticketsTurnosList = useSelector((state) => state.ticketsTurnosList);
    const { ticketsTurnos, loadingTicketsTurnos } = ticketsTurnosList;
    const [ticketsTurno, setTicketsTurnos] = useState("");

    const getValorAsunto = (e) => {
        const p = ticketsAsuntos.filter((item) => item.asunto === e);
        console.log(p)
        const data = p[0]

        console.log(data)
        setValorACobrar(data.valor_a_cobrar)
    }

    const consultCedula = (e) => {

        const mikrowisp = functions.httpsCallable('mikrowisp')
        mikrowisp(e)
            .then(res => {
                if (res.mensaje == "No existe el cliente con el filtro indicado.") {
                    //console.log("No existe cliente");
                    Swal.fire({
                        icon: 'info',
                        text: 'El numero de cedula ingresado no pertenece a ningun cliente.'
                    })
                } else {
                    //console.log("Si existe cSliente ");
                    const contracts = res.data;
                    const data = [
                        ...contracts.map((doc) => {
                            return {
                                ...doc,
                                NUM_CONTRATO: doc.NUM_CONTRATO,
                                estado: doc.estado,
                                REFERENCIA: doc.REFERENCIA,
                            };
                        }),
                    ];
                    setDataDatos(data);
                    //console.log(contractArray, "array");
                }
            })

        /* var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "cedula": `${e}`
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetClientsDetails", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                const data = JSON.parse(result)
                setData(data)
                if (data.mensaje == 'No existe el cliente con el filtro indicado.') {
                    Swal.fire({
                        icon: 'info',
                        text: 'El numero de cedula ingresado no pertenece a ningun cliente.'
                    })
                } else {
                    setNombreSolicitante(data['datos'][0].nombre)
                }

            })
            .catch(error => console.log('error', error)); */
    }

    const consultImageHouseClient = async (e) => {
        const agendaDB2 = await db.collection('tecnicos').where('cedula', '==', e).get()
        const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log(data2[0].id)
        setFotoClienteVivienda(data2[0].foto_cliente_vivienda)
        setZona(data2[0].zona)
    }

    const getNumTicket = () => {
        const ref = db.collection("tickets").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_ticket + 1;
                setNumTicket(numeroSolicitud)
            }
        })
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listsTicketsDepartamentos())
        dispatch(listsTicketsAsuntos())
        dispatch(listsTicketsOficinas())
        dispatch(listsTicketsTurnos())
        getNumTicket()
        /* leerCodigo() */
    }, [dispatch]);

    const [detallesTicket, setDetallesTicket] = useState('')

    const [fechaVisita, setFechaVisita] = useState('')

    var imagenUrl;

    var imagenUrlOpcional;

    const [Imagen, setImagen] = useState(null);

    const [ImagenOpcional, setImagenOpcional] = useState(null);

    const [viewImagen, setViewImagen] = useState('')

    const [viewImagenOpcional1, setViewImagenOpcional1] = useState('')

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }

    const changeImagenOpcional = async (e) => {
        setImagenOpcional(e.target.files[0]);
        setViewImagenOpcional1(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenOpcional);
    }

    const [contrato, setContrato] = useState('')

    const consultLinea = (e) => {
        setContrato('')
        setLinea(e)
        const p = dataDatos.filter((item) => item.NUM_CONTRATO === e);
        const data = p[0]
        setContrato(data)
        setNombreSolicitante(data.nombre)
        setCorreo(data.correo)
        setTelefono(data.telefono)
        setMovil(data.movil)
        setDireccion(data.direccion_principal)
        setNumContrato(data.NUM_CONTRATO)
        setPlanNombre(data.servicios[0].perfil)
        setCoordenadas(data.servicios[0].coordenadas)
        setCaja(data.DATOS_GPON)
        console.log(data.servicios[0].perfil)
    }

    const [ref, setRef] = useState(null);
    const [refOpcional, setRefOpcional] = useState(null);
    const useStorage = storage;

    const navigate = useNavigate()

    const sendTicket = async () => {

        const newRef = useStorage.ref('tickets').child(`TICKET-${numTicket}`).child(`TICKET-${numTicket}`); // nombre del archivo
        setRef(newRef);
        await newRef.put(Imagen);
        const urlImagen = await newRef.getDownloadURL()
        imagenUrl = urlImagen;
        console.log('la url de la imagen es' + urlImagen);

        const newRef2 = useStorage.ref('tickets').child(`TICKET-${numTicket}`).child(`TICKET-${numTicket}-OPCIONAL`); // nombre del archivo
        setRefOpcional(newRef2);
        await newRef2.put(ImagenOpcional);
        const urlImagenOpcional = await newRef2.getDownloadURL()
        imagenUrlOpcional = urlImagenOpcional;
        console.log('la url de la imagen opcional es' + imagenUrlOpcional);

        db.collection('tickets').doc(`TICKET-${numTicket}`).set({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            numero_ticket: numTicket,
            uid_ticket: `TICKET-${numTicket}`,
            nombre_cliente: nombreSolicitante,
            linea_contrato: linea,
            numContrato: numContrato,
            agendado_desde: ticketsOficina,
            departamento: ticketsDepartamento,
            asunto_del_ticket: ticketsAsunto,
            fecha_sugerida_visita: fechaVisita,
            turno: ticketsTurno,
            detalles_del_ticket: detallesTicket.toUpperCase(),
            imagen: viewImagen === '' || viewImagen === undefined 
            || viewImagen === null ? fotoClienteVivienda : imagenUrl,
            imagen_opcional: viewImagenOpcional1 === '' ? '' : imagenUrlOpcional,
            estado: 0,
            cedula: cedula,
            valor_a_cobrar: valorACobrar,
            usuario_generador_ticket: userInfo.displayName,
            telefono: telefono === '' || telefono === undefined || telefono === null ? '' : telefono,
            celular: movil === '' || movil === undefined || movil === null ? '' : movil,
            correo: correo === '' || correo === undefined || correo === null ? '' : correo,
            direccion: direccion === '' || direccion === undefined || direccion === null ? '' : direccion,
            planNombre: planNombre === '' || planNombre === undefined || planNombre === null ? '' : planNombre,
            caja: caja,
            coordenadas: coordenadas,
            zona: zona
        })

        setCedula('')
        setNombreSolicitante('')
        setLinea('')
        setTicketsOficinas('')
        setTicketsDepartamento('')
        setTicketsOficinas('')
        setTicketsAsunto('')
        setFechaVisita('')
        setTicketsTurnos('')
        setDetallesTicket('')
        setImagen('')

        navigate(`/tickets/lista-tickets/`)

        Swal.fire({ icon: "success", text: 'Se ha generado un nuevo ticket con exito' });

    }

    const HanldeSendTicket = () => {
        sendTicket()
        setOpenDialogConfirm(false)
    }

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>

                    <Paper elevation={3}>
                        <Box py={1} px={2} sx={{ bgcolor: '#1A6EB9' }}>
                            <Box py={1}>
                                <p style={{ fontSize: "16px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>NUEVO TICKET-{numTicket}</p>
                            </Box>
                        </Box>
                    </Paper>

                    <Box py={2} px={2}>

                        {/* <Button variant="contained" color="secondary" size="small" onClick={() => consultCedula()}>
                            Consultar
                        </Button> */}

                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>

                                        <Box pb={3}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CEDULA:</strong></p>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={cedula}
                                                onChange={(e) => {
                                                    setCedula(e.target.value)
                                                    if (e.target.value.length >= 10) {
                                                        consultCedula(e.target.value)
                                                        consultImageHouseClient(e.target.value)
                                                    } else {

                                                    }
                                                }
                                                }
                                                name="cedula"
                                                size="small"
                                                fullWidth
                                            />

                                        </Box>

                                        {
                                            dataDatos.length === 0 ? '' : <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>LINEA:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={linea}
                                                        label="LINEA"
                                                        onChange={(e) => consultLinea(e.target.value)/* setLinea(e.target.value) */}>
                                                        {dataDatos &&
                                                            dataDatos.map(item => (
                                                                <MenuItem value={item.NUM_CONTRATO} >
                                                                    {item.NUM_CONTRATO}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        }

                                        {
                                            dataDatos.length === 0 ? <>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE SOLICITANTE:</strong></p>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={nombreSolicitante}
                                                    name="nombreSolicitante"
                                                    size="small"
                                                    fullWidth
                                                />

                                                <Box pt={3}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>COORDENADAS CLIENTE:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={coordenadas}
                                                        name="coordenadas"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>

                                                <Box pt={3}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CAJA CLIENTE:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={caja}
                                                        name="caja"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>

                                                {
                                                    zona === '' || zona === undefined || zona === null ? <Box pt={3}>
                                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA CLIENTE:</strong></p>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={zona}
                                                            onChange={(e) => setZona(e.target.value)}
                                                            name="zona"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Box> : <Box pt={3}>
                                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA CLIENTE:</strong></p>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={zona}
                                                            name="zona"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Box>
                                                }

                                            </> :
                                                <>
                                                    <Box py={3}>
                                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE SOLICITANTE:</strong></p>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={nombreSolicitante}
                                                            name="nombreSolicitante"
                                                            size="small"
                                                            fullWidth
                                                        />

                                                        <Box pt={3}>
                                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>COORDENADAS CLIENTE:</strong></p>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={coordenadas}
                                                                name="coordenadas"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Box>

                                                        <Box pt={3}>
                                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CAJA CLIENTE:</strong></p>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={caja}
                                                                name="caja"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Box>

                                                        {
                                                            zona === '' || zona === undefined || zona === null ? <Box pt={3}>
                                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA CLIENTE:</strong></p>
                                                                <TextField sx={{
                                                                    '& legend': { display: 'none' },
                                                                    '& fieldset': { top: 0 },
                                                                }}
                                                                    value={zona}
                                                                    onChange={(e) => setZona(e.target.value)}
                                                                    name="zona"
                                                                    size="small"
                                                                    fullWidth
                                                                />
                                                            </Box> : <Box pt={3}>
                                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA CLIENTE:</strong></p>
                                                                <TextField sx={{
                                                                    '& legend': { display: 'none' },
                                                                    '& fieldset': { top: 0 },
                                                                }}
                                                                    value={zona}
                                                                    name="zona"
                                                                    size="small"
                                                                    fullWidth
                                                                    onChange={(e) => setZona(e.target.value)}
                                                                />
                                                            </Box>
                                                        }

                                                        <Box pt={3}>
                                                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>TELEFONO:</strong></p>
                                                            <FormControl fullWidth>
                                                                <TextField sx={{
                                                                    '& legend': { display: 'none' },
                                                                    '& fieldset': { top: 0 },
                                                                }}
                                                                    value={telefono}
                                                                    type="number"
                                                                    onInput={(e) => {
                                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                                    }}
                                                                    name="telefono"
                                                                    size="small"
                                                                    fullWidth
                                                                    onChange={(e) => setTelefono(e.target.value)}
                                                                />
                                                            </FormControl>
                                                        </Box>

                                                    </Box>
                                                </>
                                        }

                                        {/* {
                                            dataDatos.length === 0 ? <>
                                                <Box py={3}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>AGENDADO DESDE:</strong></p>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={ticketsOficina}
                                                            label="DEPARTAMENTO"
                                                            onChange={(e) => setTicketsOficinas(e.target.value)}>
                                                            {ticketsOficinas &&
                                                                ticketsOficinas.map(item => (
                                                                    <MenuItem key={item.oficina} value={item.oficina} >
                                                                        {item.oficina}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </> : <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>AGENDADO DESDE:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={ticketsOficina}
                                                        label="DEPARTAMENTO"
                                                        onChange={(e) => setTicketsOficinas(e.target.value)}>
                                                        {ticketsOficinas &&
                                                            ticketsOficinas.map(item => (
                                                                <MenuItem key={item.oficina} value={item.oficina} >
                                                                    {item.oficina}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        } */}

                                    </Grid>

                                    <Grid item xs={6}>

                                        <Box pb={3}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DEPARTAMENTO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={ticketsDepartamento}
                                                    label="DEPARTAMENTO"
                                                    onChange={(e) => setTicketsDepartamento(e.target.value)}>
                                                    {ticketsDepartamentos &&
                                                        ticketsDepartamentos.map(item => (
                                                            <MenuItem key={item.departamento} value={item.departamento} >
                                                                {item.departamento}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ASUNTO DEL TICKET:</strong></p>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={ticketsAsunto}
                                                label="DEPARTAMENTO"
                                                onChange={(e) => {
                                                    setTicketsAsunto(e.target.value)
                                                    getValorAsunto(e.target.value)
                                                }
                                                }>
                                                {ticketsAsuntos &&
                                                    ticketsAsuntos.map(item => (
                                                        <MenuItem key={item.asunto} value={item.asunto} >
                                                            {item.asunto}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>

                                        {
                                            ticketsAsunto === '' ? '' :
                                                <>
                                                    <Box pt={3}>
                                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VALOR A COBRAR:</strong></p>
                                                        <FormControl fullWidth>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={valorACobrar}
                                                                /* value={nombreSolicitante} */
                                                                /* onChange={(e) => setNombreSolicitante(e.target.value)} */
                                                                name="nombreSolicitante"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </>
                                        }

                                        <Box pt={3}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FECHA SUGERIDA DE VISITA</strong></p>
                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        renderInput={(props) => <TextField {...props} size="small" />}
                                                        value={fechaVisita}
                                                        onChange={(newValue) => {
                                                            setFechaVisita(newValue);
                                                        }}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Box>

                                        <Box py={3}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TURNO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={ticketsTurno}
                                                    label="DEPARTAMENTO"
                                                    onChange={(e) => setTicketsTurnos(e.target.value)}>
                                                    {ticketsTurnos &&
                                                        ticketsTurnos.map(item => (
                                                            <MenuItem key={item.turno} value={item.turno} >
                                                                {item.turno}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        {
                                            dataDatos.length === 0 ? <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>AGENDADO DESDE:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={ticketsOficina}
                                                        label="DEPARTAMENTO"
                                                        onChange={(e) => setTicketsOficinas(e.target.value)}>
                                                        {ticketsOficinas &&
                                                            ticketsOficinas.map(item => (
                                                                <MenuItem key={item.oficina} value={item.oficina} >
                                                                    {item.oficina}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </> : <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>AGENDADO DESDE:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={ticketsOficina}
                                                        label="DEPARTAMENTO"
                                                        onChange={(e) => setTicketsOficinas(e.target.value)}>
                                                        {ticketsOficinas &&
                                                            ticketsOficinas.map(item => (
                                                                <MenuItem key={item.oficina} value={item.oficina} >
                                                                    {item.oficina}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        }

                                        <Box pt={3}>
                                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CELULAR:</strong></p>
                                            <FormControl fullWidth>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={movil}
                                                    type="number"
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }}
                                                    name="celular"
                                                    size="small"
                                                    fullWidth
                                                    onChange={(e) => setMovil(e.target.value)}
                                                />
                                            </FormControl>
                                        </Box>

                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>

                                    <Grid item xs={6}>

                                        <Box pt={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO VIVIENDA CLIENTE:</strong></p>
                                        </Box>

                                        {/* <Box pt={2}>
                                    <Button component="label" onChange={changeImagen} variant="outlined" color="secondary" size="small">
                                        Adjuntar archivo
                                        <input
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                                </Box> */}

                                        {
                                            fotoClienteVivienda === '' || fotoClienteVivienda === undefined || fotoClienteVivienda === null ? <Box pt={1}>
                                                <Button component="label" onChange={changeImagen} variant="outlined" color="secondary" size="small">
                                                    Adjuntar archivo
                                                    <input
                                                        type="file"
                                                        hidden
                                                    />
                                                </Button>
                                            </Box> : ''
                                        }

                                        {
                                            fotoClienteVivienda === '' || fotoClienteVivienda === undefined || fotoClienteVivienda === null ? '' : <>
                                                <Box pt={1}>
                                                    <img width={400} src={fotoClienteVivienda} />
                                                </Box></>
                                        }

                                        {
                                            fotoClienteVivienda === '' || fotoClienteVivienda === undefined || fotoClienteVivienda === null ? <>
                                                <Box pt={1}>
                                                    <img width={400} src={viewImagen} />
                                                </Box></> : ''
                                        }

                                    </Grid>

                                    {/* {
                                    viewImagen === '' ? '' : <>
                                        <Box pt={2}>
                                            <img width={400} src={viewImagen} />
                                        </Box></>
                                } */}

                                    <Grid item xs={6}>

                                        <Box pt={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO OPCIONAL:</strong></p>
                                        </Box>

                                        {/* <Box pt={2}>
                                    <Button component="label" onChange={changeImagen} variant="outlined" color="secondary" size="small">
                                        Adjuntar archivo
                                        <input
                                            type="file"
                                            hidden
                                        />
                                    </Button>
                                </Box> */}

                                        <Box pt={1}>
                                            <Button component="label" onChange={changeImagenOpcional} variant="outlined" color="secondary" size="small">
                                                Adjuntar archivo
                                                <input
                                                    type="file"
                                                    hidden
                                                />
                                            </Button>
                                        </Box>

                                        <Box pt={1}>
                                            <img width={400} src={viewImagenOpcional1} />
                                        </Box>
                                    </Grid>

                                </Grid>

                                <Box py={2}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>INGRESE DETALLES DEL TICKET</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={detallesTicket}
                                        onChange={(e) => setDetallesTicket(e.target.value)}
                                        name="detallesTicket"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={10}
                                    />
                                </Box>

                                <Grid container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <Button
                                        disabled={
                                            cedula === ''
                                            || ticketsOficina === ''
                                            || ticketsDepartamento === ''
                                            || ticketsAsunto === ''
                                            || ticketsTurno === ''
                                            || detallesTicket === ''
                                            || fechaVisita === ''
                                            || movil === ''
                                            || telefono === ''
                                        }
                                        onClick={() => { setOpenDialogConfirm(true) }} variant="contained" color="primary" size="small">
                                        Abrir ticket
                                    </Button>

                                    {/* <Button onClick={() => { console.log(data) }} variant="contained" color="primary" size="small">
                                        data
                                    </Button>

                                    <Button onClick={() => { console.log(dataDatos) }} variant="contained" color="primary" size="small">
                                        data Datos
                                    </Button> */}
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Paper>
            </Box >

            {/* DIALOG CONFIRM*/}
            < Dialog open={openDialogConfirm} onClose={() => setOpenDialogConfirm(false)} maxWidth="xs" >
                <DialogTitle><strong>ESTA SEGURO DE GENERAR UN NUEVO TICKET?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogConfirm(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => HanldeSendTicket()} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog >

        </>
    )
}