import {
    Box,
    Typography,
    Paper,
    Select,
    Dialog,
    DialogTitle,
    Stack,
    MenuItem,
    CircularProgress,
    Button,
    FormControl
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { useDispatch } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';

import { SeriesMaterialesAsignados } from "../MaterialesAsignados/SeriesMaterialesAsignados";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

export const MaterialesTecnicos = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [idFlota, setIdFlota] = useState('');

    const [flotas, setFlotas] = useState([]);

    const [flota, setFlota] = useState([]);

    const [flotaEmail, setFlotaEmail] = useState('');

    const getFlotas = async () => {

        const agendaDB2 = await db.collection("flotas")

        agendaDB2.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setFlotas(data)
            console.log('flotas', data)
        })

    }

    const LoadData = async (e) => {

        console.log('e', e)

        const agendaDB2 = await db.collection("flotas").where("numeroFlota", "==", e).limit(1).get()

        const data2 = agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setIdFlota(data2[0].id)

        setFlotaEmail(data2[0].correo)

        console.log('data2[0].id', data2[0].id)

        console.log('data2[0].correo', data2[0].correo)

        const docRef = await db.collection("flotas").doc(data2[0].id).collection('materiales')

        docRef.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            console.log(data)
        })

    };

    const handleSeriesDataUpdate = (seriesData) => {
        setData(seriesData);
    };

    const dispatch = useDispatch()

    useEffect(() => {
        getFlotas()
    }, [dispatch])

    const [total, setTotal] = useState(0);

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total: {total}</Box>
        </div>
    }

    const columns = [
        { field: 'codigo_producto', headerName: 'CODIGO', width: 140 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 700 },
        { field: 'stock_actual', headerName: 'STOCK ACTUAL', width: 140 },
        {
            field: '1', headerName: 'MOSTRAR SERIES', width: 150,
            renderCell: (params) => {
                return <SeriesMaterialesAsignados props={params.row} email={flotaEmail} id={idFlota} handleSeriesDataUpdate={handleSeriesDataUpdate} flota={flota} />
            }
        },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    const exporter = React.createRef(null);
    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };


    return (
        <>

            <Box pb={1} pl={2}>

                <FormControl>
                    <p style={{ fontSize: "12px", marginBottom: '0px' }}>SELECCIONE UNA FLOTA</p>
                    <Select sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        size="small"
                        value={flota}
                        /* MenuProps={MenuProps} */
                        onChange={(e) => {
                            setFlota(e.target.value)
                            LoadData(e.target.value)
                        }}>
                        {/* {flotas ?
                            flotas.map(item => (
                                <MenuItem key={item.numeroFlota} value={item.numeroFlota} >
                                    {item.numeroFlota}
                                </MenuItem>
                                
                            )) : ''} */}
                        {flotas.map(item => (
                            <MenuItem key={item.id} value={item.numeroFlota}>
                                {item.numeroFlota}
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl>
            </Box>

            <Box pt={0} pl={2}>
                <Typography >
                    <strong>STOCK ACTUAL DE MATERIALES TECNICOS</strong>
                </Typography >
                <Button onClick={() => excelExport()}
                    size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                >
                    {" "}
                    DESCARGAR EXCEL
                </Button>
                <br />
            </Box>


            <Box px={2}>
                <Paper>
                    <Box sx={{ height: 690, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ?? []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}

                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>

            <ExcelExport onExportComplete={handleExport2} data={data} fileName={"MATERIALES de " +
                flota + " .xlsx"}
                ref={exporter} filterable={true} >
                <ExcelExportColumn field="codigo_producto"
                    title="CODIGO PRODUCTO" headerCellOptions={{ background: "#672780" }} width={200} />
                <ExcelExportColumn field="descripcion"
                    title="DESCRIPCION" headerCellOptions={{ background: "#672780" }} width={400} />
                <ExcelExportColumn field="stock_actual"
                    title="STOCK ACTUAL"
                    headerCellOptions={{ background: "#672780" }} width={200} />
            </ExcelExport>
        </>
    );
};