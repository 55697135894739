import {
    Box,
    Button,
    Container,
    Grid,
    Card
} from '@mui/material'
import React from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack } from '@mui/system';
import logo1 from '../../../assets/img/contrato/image005.jpg'
export const Anexo1 = () => {
    const container = React.useRef(null)
    const Plantilla1 = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                </div>
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                </p>
            </div >
        )
    }
    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: Plantilla1,
            paperSize: "A4",
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `ANEXO 1`
        })
    }
    return (
        <>
            <div className="example-config">
                <Button
                    onClick={exportPDFWithMethod}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </div>
            <Container>
                {/* <div style={{
                    position: "absolute",
                    left: "-50000px",
                    top: 0,
                }}> */}
                    <PDFExport>
                        <div ref={container}>
                                <Container  maxWidth="md" >
                                    <Grid container spacing={1} sx={{ fontSize: "12px", marginTop: "5px" }}>
                                        <Grid item xs={12} sx={{ border: 1, marginBottom: "10px", fontSize: "16px" }}>
                                            <p align="center"><strong>ANEXO 1. ACUERDO DE PRESTACION DEL SERVICIO DE ACCESO A INTERNET</strong></p>
                                        </Grid>
                                        <Grid item xs={3} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                            <p align="center"><strong>FECHA</strong></p>
                                        </Grid>
                                        <Grid item xs={3} sx={{ border: 1, marginBottom: "10px" }}>  </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                            <p align="center"><strong>NOMBRE DEL PLAN </strong></p>
                                        </Grid>
                                        <Grid item xs={3} sx={{ border: 1, marginBottom: "10px" }}> </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={3} sx={{ backgroundColor: '#a6a4a4', border: 1, }}>
                                            <p align="center"><strong>TÉRMINOS DEL CONTATO</strong></p>
                                        </Grid>
                                        <Grid item xs={9} />
                                        <Grid item xs={3} sx={{ border: 1 }}>
                                            <p align="center"><strong>FECHAS DE PAGO</strong></p>
                                        </Grid>
                                        <Grid item xs={4} sx={{ border: 1 }}> </Grid>
                                        <Grid item xs={5} />
                                        <Grid item xs={3} sx={{ border: 1, marginBottom: "10px" }}>
                                            <p align="center"><strong>PERIODO DE FACTURACION</strong></p>
                                        </Grid>
                                        <Grid item xs={4} sx={{ border: 1, marginBottom: "10px" }}>  </Grid>
                                        <Grid item xs={5} />
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ marginBottom: "10px" }}> <strong>RED DE ACCESO:</strong> </Grid>
                                                <Grid item xs={4} > PAR DE COBRE </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={4} > FIBRA OPTICA </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={2} />
                                                <Grid item xs={4} > COAXIAL </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={4} > INALAMBRICO </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={2} />
                                                <Grid item xs={4} sx={{ marginBottom: "10px" }}> OTROS </Grid>
                                                <Grid item xs={1} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{ marginBottom: "10px" }}> <strong>TIPO DE CUENTA:</strong> </Grid>
                                                <Grid item xs={4} > RESIDENCIAL </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={4} > CORPORATIVO </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={2} />
                                                <Grid item xs={4} > CIBERCAFE </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={4} > OTROS TIPOS </Grid>
                                                <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                                <Grid item xs={2} />
                                                <Grid item xs={9} sx={{ marginBottom: "10px" }} >VELOCIDAD EFECTIVA MINIMA HACIA CLIENTE:  </Grid>
                                                <Grid item xs={3} sx={{ border: 1, marginBottom: "10px" }} > </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ marginBottom: "10px" }}> <strong>VELOCIDAD CONTRATADA:</strong> </Grid>
                                        <Grid item xs={3} > COMERCIAL DE BAJADA </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={3} > COMERCIAL DE SUBIDA </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={3} sx={{ marginBottom: "10px" }}> MINIMA EFECTIVA DE BAJADA </Grid>
                                        <Grid item xs={2} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={3} sx={{ marginBottom: "10px" }}> MINIMA EFECTIVA DE SUBIDA </Grid>
                                        <Grid item xs={2} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={1} />
                                        <Grid item xs={4} sx={{ marginBottom: "10px" }}> <strong>NIVEL DE COMPARTICION:</strong> </Grid>
                                        <Grid item xs={2} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={6} />
                                        <Grid item xs={4}> <strong>PERMANENCIA MINIMA:</strong> </Grid>
                                        <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={2} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={4} sx={{ marginBottom: "10px" }} />
                                        <Grid item xs={1} > <p align="center">SI</p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={1}  > <p align="center">NO</p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={2} >  <p align="center">TIEMPO</p> </Grid>
                                        <Grid item xs={4} sx={{ marginBottom: "10px" }} > BENEFICIOS DE PERMANENCIA</Grid>
                                        <Grid item xs={8} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>

                                        <Grid item xs={12}> <strong>SERVICIOS ADICIONALES:</strong> </Grid>
                                        <Grid item xs={4} />
                                        <Grid item xs={1} sx={{ border: 1 }} > <p align="center">SI</p></Grid>
                                        <Grid item xs={1} sx={{ border: 1 }} > <p align="center">NO</p></Grid>
                                        <Grid item xs={6} sx={{ border: 1 }} > <p align="center">DESCRIPCION</p></Grid>

                                        <Grid item xs={4} sx={{ border: 1 }} > CUENTAS DE CORREO ELECTRONICO </Grid>
                                        <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={1} sx={{ border: 1 }} >  </Grid>
                                        <Grid item xs={6} sx={{ border: 1 }} >  </Grid>

                                        <Grid item xs={4} sx={{ border: 1, marginBottom: "10px" }} > OTROS SERVICIOS </Grid>
                                        <Grid item xs={1} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={1} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={6} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>

                                        <Grid item xs={12} > <strong>TARIFAS: </strong> </Grid>
                                        <Grid item xs={12} > VALORES A PAGAR POR UNA SOLA VEZ: </Grid>
                                        <Grid item xs={2} sx={{ border: 1, marginBottom: "10px" }} > VALOR INSTALACION </Grid>
                                        <Grid item xs={2} sx={{ border: 1, marginBottom: "10px" }} >  </Grid>
                                        <Grid item xs={2} sx={{ marginBottom: "10px" }} > USD </Grid>
                                        <Grid item xs={7} sx={{ marginBottom: "10px" }} />

                                        <Grid item xs={12} sx={{ border: 1 }} > <p align="center"><strong>VALORES PAGO MENSUAL</strong></p> </Grid>
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>ITEM</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>VALOR</strong></p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>ITEM</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>VALOR</strong></p> </Grid>

                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>PRECIO MENSUAL</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>YIGA DEFENDER</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>

                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>PRECIO INSTALACION</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>YIGA CAM</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>

                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>OTROS</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>OTROS</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>

                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>VALOR TOTAL</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={3} sx={{ border: 1, backgroundColor: "#a6a4a4" }} > <p align="center"><strong>VALOR OTROS VALORES</strong></p> </Grid>
                                        <Grid item xs={2} sx={{ border: 1 }} > <p align="center"></p> </Grid>

                                        <Grid item xs={5}>  <p align="right">Sitio web para consultas: <br />
                                            Sitio web para consultas calidad del servicio: <br />
                                            NOTA:  LAS TARIFAS NO INLCUYEN IMPUESTOS DE LEY
                                        </p> </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={5}>  <p align="left"> www.yiga5.com/planes <br />
                                            www.yiga5.com/calidad
                                        </p> </Grid>
                                    </Grid>

                                    <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '12px' }}>
                                        <Grid item xs={5}>
                                            <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                                <p align="center" ><strong>
                                                    ING. JUAN CARLOS GONZALEZ ORTEGA <br />
                                                    REPRESENTANTE DE INTERCOMMERCE S:A. <br />
                                                    PERMISIONARIO <br />
                                                    RUC: 0992988061001 <br />
                                                </strong></p>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                                <p align="center" ><strong>
                                                    ABONADO/SUSCRIPTOR <br />
                                                    NOMBRE:  <br />
                                                    CRI/RUC: <br /><br />
                                                </strong></p>
                                            </Box>
                                        </Grid>
                                    </Grid >
                                </Container>
                        </div>
                    </PDFExport>
                {/* </div> */}
            </Container>

        </>
    )
}
