import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NewMarcaProducto = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [marca, setMarca] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createMarca = async (marca) => {
        await db.collection('marcas_productos').doc().set({
            nombre_marca_producto: marca,
            fecha_creacion_marca_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva marca creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={marca}
                cambiarEstado={setMarca}
                label="MARCA"
                name="marca"
            />

            <br />
            <Button
                onClick={() => createMarca(marca.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !marca.campo}
            >
                {" "}
                CREAR NUEVA MARCA DE PRODUCTO{" "}
            </Button>
        </>
    );
};
