import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, Typography, Container, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { CentroDeCostos } from './CentroDeCostos';
import { Proyecto } from './proyectos/Proyecto';
import { NuevoCentroDeCosto } from './NuevoCentroDeCosto';
import { NuevoGrupoCentroDeCosto } from './NuevoGrupoCentroDeCosto';
import { NuevoProyecto } from './proyectos/NuevoProyecto';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CentrosDeCostosPage = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    useEffect(() => {
        if (params.id) {
            console.log(typeof params.id)
            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
            setTab(parseInt(params.id));
        }
    }, [params.id]);
    return (
        <>
            <Container maxWidth="xl">
                <Tabs value={tab} onChange={handleChange} aria-label="solicitud de servicio">
                    <Tab label="Centro de Costos" {...tabs(0)} style={{ fontSize: "12px" }} />
                    <Tab label="Proyecto" {...tabs(1)} style={{ fontSize: "12px" }} />
                    <Tab label="Nuevo Centro De Costo" {...tabs(2)} style={{ fontSize: "12px" }} />
                    <Tab label="Nuevo Grupo Centro Costo" {...tabs(3)} style={{ fontSize: "12px" }} />
                    <Tab label="Nuevo Proyecto" {...tabs(4)} style={{ fontSize: "12px" }} />
                </Tabs>

                <TabPanel value={tab} index={0}>
                    <CentroDeCostos />
                </TabPanel>

                <TabPanel value={tab} index={1}>
                    <Proyecto />
                </TabPanel>

                <TabPanel value={tab} index={2}>
                    <NuevoCentroDeCosto />
                </TabPanel>

                <TabPanel value={tab} index={3}>
                    <NuevoGrupoCentroDeCosto />
                </TabPanel>

                <TabPanel value={tab} index={4}>
                    <NuevoProyecto />
                </TabPanel>

            </Container>
        </>
    )
}