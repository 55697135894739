import React, { useEffect, useState } from "react";
import {
    Grid, Box, InputLabel, Button, Select, FormControl,
    MenuItem, CircularProgress, Card, CardContent, Typography
} from '@mui/material';
import { db } from '../../../firebase';
import InputValidation from "../../utils/InputValidation";
import { TextField } from "@mui/material";
export const DebitoAutomatico = ({ cDebito, cBanco, cNumBanco, cTipoCuenta }) => {

    const [debito, setDebito] = useState('')
    //
    const [numBanco, setNumBanco] = useState('')
    const [banco, setBanco] = useState('')
    //List
    const [bancos, setBancos] = useState('')
    const [tipo, setTipo] = useState('')

    useEffect(() => {
        listBanco()
    }, [])

    const listBanco = () => {
        db.collection(`parameters/config/bancos`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setBancos(arrayMessage)
            })
    }
    //
    const handleDebito = (e) => {
        setDebito(e)
        cDebito(e)
    }
    //
    const handleBanco = (e) => {
        setBanco(e)
        cBanco(e)
    }
    //
    const handleNumBanco = (e) => {
        setNumBanco(e)
        cNumBanco(e)
    }
    //
    const handleTipo = (e) => {
        setTipo(e)
        cTipoCuenta(e)
    }

    return (
        <>
            <Box pb={2}>
                <FormControl fullWidth >
                    <InputLabel>¿DÉBITO AUTOMÁTICO CUENTA DE AHORRO O CORRÍENTE?</InputLabel>
                    <Select
                        label="¿DÉBITO AUTOMÁTICO CUENTA DE AHORRO O CORRÍENTE?"
                        value={debito}
                        onChange={(e) => handleDebito(e.target.value)}>
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Si</MenuItem>
                        <MenuItem value={2}>No</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {
                debito && debito === 1 &&
                <>
                    <Box pt={1}>
                        <FormControl fullWidth >
                            <InputLabel>BANCO</InputLabel>
                            <Select
                                label="BANCO"
                                value={banco}
                                onChange={(e) => handleBanco(e.target.value)}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {bancos &&
                                    bancos.map(item => (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.id}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box py={3}>
                        <FormControl fullWidth >
                            <InputLabel>TIPO DE CUENTA</InputLabel>
                            <Select
                                label="TIPO DE CUENTA"
                                value={tipo}
                                onChange={(e) => handleTipo(e.target.value)}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="AHORRO" >
                                    AHORRO
                                </MenuItem>
                                <MenuItem value="CORRIENTE" >
                                    CORRIENTE
                                </MenuItem>
                                <MenuItem value="TARJETA CREDITO" >
                                    TARJETA CREDITO
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pb={3}>
                        <TextField
                            fullWidth
                            label='NUMERO CUENTA'
                            value={numBanco}
                            onChange={(e) => handleNumBanco(e.target.value)}
                        />
                    </Box>
                </>
            }

        </>
    );
};