import React, { useEffect, useState } from "react";
import { db, storage, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Divider,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
    Paper
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

export const NuevaZona = () => {

    const [codigo, setCodigo] = useState('');

    const [zona, setZona] = useState('');

    const getLastCodigoZona = () => {
        const ref = db.collection("zonas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const zonas = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const zona = zonas[0]
                const numeroZona = zona.codigo + 1;
                setCodigo(numeroZona)
                setZona(`ZONA ${numeroZona}`)
            }
        })
    }

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        getLastCodigoZona()
    }, []);

    const [nombre, setNombre] = useState('')
    const [ubicacion, setUbicacion] = useState('');

    const onsubmit = async () => {

        await db
            .collection("zonas").doc(`${zona} - ${nombre} - ${ubicacion}`).set({
                zona: zona.toUpperCase(),
                nombre: nombre.toUpperCase(),
                ubicacion: ubicacion.toUpperCase(),
                codigo: codigo,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                Swal.fire({ icon: "success", title: "Zona creada con exito" });
                setNombre('')
                setUbicacion('')
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    };
    
    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>
                        <Grid container spacing={7}>
                            <Grid item xs={10} md={6}>

                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO:</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={codigo}
                                    /* onChange={(e) => setCodigo(e.target.value)} */
                                    name="codigo"
                                    size="small"
                                    fullWidth
                                />

                                <Box py={4}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        name="nombre"
                                        size="small"
                                        fullWidth
                                    />
                                </Box>
                            </Grid>


                            {/* *********************************** */}
                            <Grid item xs={10} md={6}>


                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA:</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={zona}
                                    /* onChange={(e) => setZona(e.target.value)} */
                                    name="zona"
                                    size="small"
                                    fullWidth
                                />

                                <Box py={4}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>UBICACION:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={ubicacion}
                                        onChange={(e) => setUbicacion(e.target.value)}
                                        name="ubicacion"
                                        size="small"
                                        fullWidth
                                    />
                                </Box>

                            </Grid>

                        </Grid>

                        <Button
                            onClick={() => onsubmit()}
                            disabled={codigo === ''
                                || nombre === ''
                                || ubicacion === ''
                                || zona === ''
                            }
                            fullWidth
                            variant="contained"
                            color="secondary"
                        >
                            {" "}
                            CREAR{" "}
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </>
    );
};
