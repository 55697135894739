import React from 'react'
import { db } from '../../../../firebase'
export default function UpdatePago({ props }) {
    //  
    const updatePago = () => {
        console.log(props, 'props')

                    if (props.estado_pagado == 0) {
                        console.log('No pago')
                        db.collection("nms/clients/clients").doc(props.id).update({
                            estado_pagado: 1,
                            ipv4: props.assigned_ipv4,
                            ipv6: props.assigned_ipv6,
                        })
                    } else {
                  
                        db.collection("nms/clients/clients").doc(props.id).update({
                            estado_pagado: 0,
                            ipv4: props.ipv4_no_navigation,
                            ipv6: props.ipv6_no_navigation,
                        })
                    }

    }
    return (
        <>

            <button onClick={() => updatePago()}>x</button>
        </>
    )
}
