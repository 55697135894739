import React, { useState } from "react";
import {
    Box, Card, Tabs, Tab, tabsClasses, AppBar
} from "@mui/material";
import { ConfiguracionTab } from "./tabs/ConfiguracionTab";
import { TransaccionesTab } from "./tabs/TransaccionesTab";
import { SaldosTab } from "./tabs/SaldosTab";
import { FacturacionTab } from "./facturas/FacturacionTab";
export const TabsFacturacion = () => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={1}>
                        {children}
                    </Box>
                )}
            </div>
        )
    }
    const [tab, setTab] = useState(0)
    return (
        <Card variant='outlined'>
            <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                <AppBar elevation={0} color='default' position="static">
                    <Tabs
                        value={tab}
                        variant="scrollable"
                        indicatorColor="primary"
                        allowScrollButtonsMobile
                        onChange={(event, newValue) => setTab(newValue)}
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 }
                            }
                        }}
                    >
                        <Tab label="FACTURAS" />
                        <Tab label="CONFIGURACION" />
                        <Tab label="TRANSACCIONES" />
                        <Tab label="SALDOS" />
                    </Tabs>
                </AppBar>
            </Box>
            <TabPanel value={tab} index={0}>
                <FacturacionTab />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ConfiguracionTab />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <TransaccionesTab />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <SaldosTab />
            </TabPanel>
        </Card >
    )
}