import { Radio, Tabs, Typography, Box, Tab, RadioGroup, Button, FormLabel, Divider, Select, FormControl, FormControlLabel, CardContent } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { TeleventasAprobada } from './TeleventasAprobada';
import { TeleventasEspera } from './TeleventasEspera';
import { TeleventasRechazada } from './TeleventasRechazada';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const GestionTeleventasPage = () => {
  const params = useParams()
  const [value, setValue] = useState(0)
  useEffect(() => {
    if (params.id) {
      setValue(parseInt(params.id))
    }
  }, [params.id])
  return (
    <>
      <Box>
        <Typography variant='h5' component='h1'> <strong>GESTION/TELEVENTAS</strong> </Typography>
      </Box>
      <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
        <Tab label="Solicitudes en Espera"  />
        <Tab label="Solicitudes Aprobadas"  />
        <Tab label="Solicitudes Rechazadas"  />
      </Tabs>
      <TabPanel value={value} index={0}>
       <TeleventasEspera />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TeleventasAprobada />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TeleventasRechazada />
      </TabPanel>
    </>
  )
}