import {
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse, Hidden, Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { GridFilterPanel } from "@mui/x-data-grid";
/* import { UpdateProduct } from "./IconsButtons/UpdateProduct";
import { InactivateProduct } from "./IconsButtons/InactivateProduct";
import { DeleteProduct } from "./IconsButtons/DeleteProduct"; */

export const ProductosActivosFijoPage = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //
    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()

    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    /* const LoadData = (startAfterDoc, persistMessages = []) => {
  
      let ref = db.collection("productos").where("estado_producto", "==", 0);
  
      //Si tiene algun doc anterior se agrega acontinuacion
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
  
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("fecha_creacion_producto", '>=', startfulldate)
        ref = ref.where("fecha_creacion_producto", '<=', endfulldate)
  
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
          setData(data)
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
          setIsLastPage(totalCount < 1)
        })
      }
    }; */

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("productos_activos_fijos")

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'codigo_producto', headerName: 'CODIGO', width: 140 },
        { field: 'descripcion_producto', headerName: 'DESCRIPCION', width: 400 },
        { field: 'marca_producto', headerName: 'MARCA', width: 120 },
        { field: 'categoria_producto', headerName: 'CATEGORIA', width: 140 },
        { field: 'subcategoria_producto', headerName: 'SUBCATEGORIA', width: 150 },
        /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
        { field: 'unidad_Medida_Producto', headerName: 'UNIDAD MEDIDA', width: 150 },
        { field: 'bodega', headerName: 'BODEGA', width: 150 },

        { field: 'tipo', headerName: 'TIPO', width: 150 },
        { field: 'vidaUtilActivo', headerName: 'VIDA UTIL ACTIVO', width: 150 },
        { field: 'porcentaje_depreciacion', headerName: 'PORCENTAJE DEPRECIACION', width: 230 },
        { field: 'clases_activos', headerName: 'CLASES ACTIVOS', width: 150 },

        { field: 'usuario_que_creo_el_producto', headerName: 'USUARIO QUE CREO EL PRODUCTO', width: 270 },
        {
            field: 'fecha_creacion_producto', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 220,
            valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
        },
        /* {
          field: '', headerName: 'VER', width: 90,
          renderCell: (params) => {
            return <UpdateProduct props={params.row} />
          }
        },
        {
          field: '1', headerName: 'INACTIVAR', width: 90,
          renderCell: (params) => {
            return <InactivateProduct props={params.row} />
          }
        },
        {
          field: '2', headerName: 'ELIMINAR', width: 90,
          renderCell: (params) => {
            return <DeleteProduct props={params.row} />
          }
        }, */
    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {

                    CODIGO_PRODUCTO: doc.codigo_producto,

                    DESCRIPCION: doc.descripcion_producto,

                    CATEGORIA: doc.categoria_producto,

                    SUBCATEGORIA: doc.subcategoria_producto,

                    PRESENTACION: doc.presentacion_producto,

                    MARCA: doc.marca_producto,

                    MODELO: doc.modelo_producto,

                    UNIDAD_MEDIDA: doc.unidad_Medida_Producto,

                    COSTO_ESTANDAR: doc.costo_estandar_producto,

                    PRECIO_COMPRA: doc.precio_compra_producto,

                    PRECIO_VENTA: doc.precio_venta_producto,

                    CANTIDAD: doc.cantidad_producto,

                    ESTADO_PRODUCTO: doc.estado_producto === 0 ? 'ACTIVO' : 'INACTIVO',

                    STOCK_MINIMO: doc.stock_minimo_producto,

                    STOCK_SEGURIDAD: doc.stock_seguridad_producto,

                    STOCK_MAXIMO: doc.stock_maximo_producto,

                    BODEGA: doc.bodega,

                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "productos");
        XLSX.writeFile(wb, `PRODUCTOS_${date1}.xlsx`)
    }

    const onChange2 = () => {
        LoadData();
    };

    return (
        <>
            <Box px={2}>
                <Box pt={1} pl={1}>
                    <Typography variant="button" >

                        <Typography >
                            <strong>ACTIVOS FIJOS ({data && data.length})</strong>
                        </Typography >
                    </Typography >
                </Box>
                {/* <Box pt={1} pl={1}>
          <Box display='flex' style={{ textTransform: 'uppercase' }} >
            <Typography variant="button" >
              <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography >
            <Typography variant="button" style={{ paddingLeft: 10 }}>
              <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Box>
        </Box> */}
                <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                    <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                        </Box>
                        <Stack direction="row" spacing={2}>

                            {/* <Box display="flex" alignItems='center' >
                <Button size="small" variant="outlined" onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
              </Box> */}

                            <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                            >
                                {" "}
                                DESCARGAR EXCEL
                            </Button>
                        </Stack >

                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box pt={1}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={onChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                />
                            </Box>
                            <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                        </Collapse>
                    </Box>
                </Box>

                <Paper>
                    <Box sx={{ height: 800, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            // disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                            //hideFooterPagination
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}

                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

        </>
    );
};