import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    FormHelperText,
    TableCell,
    IconButton,
    Autocomplete
    , TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
    InputAdornment
} from '@mui/material'
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { db, firebase } from '../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import { ConvertRoles } from '../../components/config/ConvertRoles';
import InputValidation from '../../components/utils/InputValidation'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { Grade } from '@mui/icons-material';

import {
    listsDepartamentosInventario
} from "../../redux/actions/parametersActions";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { SolicitudPedido } from '../solicitud/SolicitudPedidoPage';

export const NuevaCompra = () => {

    const departamentosInventarioList = useSelector((state) => state.departamentosInventarioList);
    const { departamentosInventario, loadingDepartamentosInventario } = departamentosInventarioList;
    const [departamentoInventario, setDepartamentoInventario] = useState("");

    let navigate = useNavigate()
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [fecha, setFecha] = useState({ campo: new Date, valido: true })
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState({ campo: '', valido: true })
    const [tipo_material, setTipoMaterial] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [productos, setProductos] = useState('')
    const [productosActivosFijos, setProductosActivosFijos] = useState('')
    const [motivo, setMotivo] = useState('')
    const [observacion, setObservacion] = useState('')

    const [total, setTotal] = useState(0);

    const [solicitudesDePedido, setSolicitudesDePedido] = useState('')

    const [solicitudDePedido, setSolicitudDePedido] = useState('')

    const [detalleProducto, setDetalleProducto] = useState('')

    const [itemUsuarios, setItemUsuarios] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    const [itemActivoFijo, setItemActivoFijo] = useState('')
    //
    const [modal, setModal] = useState(false)

    const [modalActivosFijos, setModalActivosFijos] = useState(false)
    //
    //
    const [inputFields, setInputFields] = useState([]);
    //
    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }
    const addInput = (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else {
            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 0,
                descripcion: row.descripcion_producto,
                codigo: row.codigo_producto,
                precio_compra_producto: row.precio_compra_producto,
                usa_serie: row.usa_serie
            }])
        }
        setItem('')
        setModal(false)
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        //console.log(typeof evnt.target.value, 'items')
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                setInputFields(list);
            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }
    const getProductos = () => {
        var docRef = db.collection("productos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getProductosActivosFijos = () => {
        var docRef = db.collection("activos_fijos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setProductosActivosFijos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            var docRef = db.collection("productos").where("codigoProducto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcion_producto
                    console.log(data.codigo)
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
            console.log(inputFields, 'da')
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">
                    {data.codigo}
                </TableCell>
                <TableCell align="center">{data.descripcion}</TableCell>
                <TableCell align="center">
                    <TextField
                        name={"cantidad_pedida"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    //

    const getSolicitudesDePedido = () => {
        var docRef = db.collection("solicitud_pedido").where('estado', '==', 4)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setSolicitudesDePedido(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const dispatch = useDispatch()
    useEffect(() => {
        getProductos()
        getProductosActivosFijos()
        getSolicitudesDePedido()
        dispatch(listsDepartamentosInventario())
    }, [dispatch]);
    //
    const loadNumPedido = () => {
        const ref = db.collection("solicitud_compra").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `SO-COM-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        let rol = ''
        if (userInfo.rol === 0) {
            rol = 'ADMIN'
        }
        if (userInfo.rol === 1) {
            rol = 'COMERCIAL'
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = 'OPERACIONES'
        }
        if (userInfo.rol === 3) {
            rol = 'PLANEACION'
        }
        if (userInfo.rol === 4) {
            rol = 'FLOTAS'
        }
        if (userInfo.rol === 5) {
            rol = 'REPORTES'
        }
        if (userInfo.rol === 6) {
            rol = 'RECURSOS HUMANOS'
        }
        //
        const uidSolicitud = `SO-COM-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            /* departamento: userInfo.departamentoText, */
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            motivo: motivo.toUpperCase(),
            observacion: observacion.toUpperCase(),
            departamento: departamentoInventario,
            detalle_de_producto: detalleProducto.toUpperCase(),
            ref_solicitud_pedido: solicitudDePedido
        }
        //console.log(data, 'enviar')
        //console.log(inputFields, 'enviar 2')
        //
        db.collection('solicitud_compra').doc(uidSolicitud).set(data)
            .then((docRef) => {
                setLoading(false)
                console.log('Solicitud id: ', docRef)
                /* navigate("/inventario/compras/1") */
                navigate("/inventario/solicitudes/solicitud-inventario")
                Swal.fire({ icon: "success", text: 'Se creo su solicitud de compra' });
                //
                inputFields.forEach(function (obj, index) {
                    const dataRef = {
                        cantidad: parseInt(obj.cantidad_pedida),
                        cantidad_pedida: parseInt(obj.cantidad_pedida),
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }
                    db.collection(`solicitud_compra/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)
                        .then((docRef) => {
                            console.log('items id: ', docRef)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                });

                db.collection('solicitud_pedido').doc()
                //
            })
            .catch((error) => {
                setLoading(false)
                Swal.fire({ icon: "info", text: error, });
                console.error("Error adding document: ", error);
            });

        /* db.collection('solicitud_pedido').doc(SolicitudPedido).update({}) */

    }
    useEffect(() => {
        loadNumPedido()
    }, [inputFields])

    const addCountUsers = (row) => {
        console.log('row', row)
        console.log('row.uidSolicitud', row.uidSolicitud)
        setSolicitudDePedido(row.uidSolicitud)
        setOpenDialogUsuarios(false)

        /* let ref = db.collection("solicitud_pedido").doc(row.uidSolicitud).collection('items').where('cant_diferencia', '>', 0)

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('data', data)
            setInputFields(data)
            data.forEach(function (obj, index) {
                setInputFields([...inputFields, {
                    fullName: obj.codigo,
                    cantidad_pedida: obj.cant_diferencia,
                    descripcion: obj.descripcion,
                    codigo: obj.codigo,
                    precio_compra_producto: ''
                }])
            });
        }); */

        let ref = db.collection("solicitud_pedido").doc(row.uidSolicitud).collection('items').where('cant_diferencia', '>', 0);

        ref.onSnapshot((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));

            const newInputFields = data.map((obj) => ({
                fullName: obj.codigo,
                cantidad_pedida: obj.cantidad_pendiente,
                descripcion: obj.descripcion,
                codigo: obj.codigo,
                precio_compra_producto: '',
                usa_serie: obj.usa_serie === undefined ? false : obj.usa_serie
            }));

            setInputFields([...inputFields, ...newInputFields]);
        });

    }

    const [openDialogUsuarios, setOpenDialogUsuarios] = useState(false);

    const columns = [
        { field: 'uidSolicitud', headerName: 'NUMERO SOLICITUD', width: 250 },
        {
            field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 190,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        {
            field: '', headerName: '', width: 140,
            renderCell: (params) => {
                return <Button
                    size='small'
                    variant='contained'
                    color='success' style={{ fontSize: '11px' }}
                    onClick={() => {
                        
                        console.log('Producto anadido')
                        addCountUsers(params.row)
                    }}>Seleccionar</Button>
            }
        },

    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    return (
        <>
            {/* <Box py={1} pl={2}>
                <Typography>
                    NUEVA SOLICITUD DE COMPRA
                </Typography>
            </Box> */}
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>

                        <Box py={1}>
                            <p style={{ fontSize: "22px", marginBottom: "10px", fontWeight: "bold" }}>NUEVA SOLICITUD DE COMPRA</p>
                        </Box>

                        <form onSubmit={handleSubmit}>

                            <Grid container spacing={5}>
                                <Grid item md={6}>
                                    <Typography gutterBottom><strong>NUMERO DE REFERENCIA</strong></Typography>
                                    <Typography>SO-COM-{numPedido}</Typography>
                                    <Box pt={1}>
                                        <Typography gutterBottom><strong>FECHA DE CREACION</strong></Typography>
                                        <Typography>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                                    </Box>
                                    <Divider />

                                    <Box pt={4}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}>MOTIVO:</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size='small'
                                                    fullWidth
                                                    variant='outlined'
                                                    value={motivo}
                                                    onChange={(e) => setMotivo(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box py={2}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}>OBSERVACION:</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={observacion}
                                                    onChange={(e) => setObservacion(e.target.value)}
                                                    name="observacion"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>REF SOLICITUD PEDIDO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={solicitudDePedido}
                                                /* onChange={(e) => setSolicitudDePedido(e.target.value)} */
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenDialogUsuarios(true)}>
                                                            <SearchIcon color='primary' />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item md={6}>
                                    <Typography gutterBottom><strong>USUARIO</strong></Typography>
                                    <Typography>{userInfo.displayName}</Typography>
                                    {/* <Box pt={1}>
                                        <Typography gutterBottom><strong>ROL</strong></Typography>
                                        <ConvertRoles id={userInfo.rol} />
                                    </Box> */}
                                    <Box pt={12}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}>DEPARTAMENTO:</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={departamentoInventario}
                                                        label="MODELO"
                                                        onChange={(e) => setDepartamentoInventario(e.target.value)}>
                                                        {departamentosInventario &&
                                                            departamentosInventario.map(item => (
                                                                <MenuItem key={item.departamento} value={item.departamento} >
                                                                    {item.departamento}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box pt={2}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}>DETALLE DE PRODUCTO:</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        value={detalleProducto}
                                                        onChange={(e) => setDetalleProducto(e.target.value)}
                                                    >
                                                        <MenuItem value="ACTIVO FIJO">ACTIVO FIJO</MenuItem>
                                                        <MenuItem value="INVENTARIO">INVENTARIO</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Box display="flex" flexDirection="row-reverse" py={1}>
                                {
                                    detalleProducto === 'INVENTARIO' ? <Button
                                        variant='outlined'
                                        onClick={() => setModal(true)}>
                                        Agregar Items
                                    </Button> : <Button
                                        variant='outlined'
                                        onClick={() => setModalActivosFijos(true)}>
                                        Agregar Items
                                    </Button>
                                }
                            </Box>

                            {/* PRODUCTOS INVENTARIO */}

                            <Dialog
                                open={modal}
                                maxWidth='md'
                                fullWidth
                            >

                                <DialogTitle>
                                    AGREGAR ITEMS
                                </DialogTitle>
                                <DialogContent dividers >
                                    <Box pb={3}>
                                        <Autocomplete
                                            disablePortal
                                            clearOnBlur={false}
                                            autoComplete={false}
                                            size="small"
                                            id="combo-box-demo"
                                            options={productos}
                                            disableClearable
                                            getOptionLabel={(option) => option.descripcion_producto}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setItem(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                                        />
                                    </Box>

                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>CODIGO</TableCell>
                                                    <TableCell>DESCRIPCION</TableCell>
                                                    <TableCell>STOCK</TableCell>
                                                    <TableCell>ACCIONES</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productos && productos
                                                    .filter((val) => {
                                                        if (item === "") {
                                                            return val;
                                                        } else if (val.descripcion_producto.toLowerCase().includes(item.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{row.codigo_producto}</TableCell>
                                                            <TableCell>{row.descripcion_producto}</TableCell>
                                                            <TableCell>{row.cantidad_producto}</TableCell>
                                                            <TableCell><Button
                                                                variant='contained'
                                                                color='success'
                                                                onClick={() => addInput(row)}
                                                            >Agregar</Button></TableCell>
                                                        </TableRow>
                                                    ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box py={5} ></Box>
                                </DialogContent>
                                <DialogActions dividers>
                                    <Button onClick={() => setModal(false)}>
                                        Cerrar ventana
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* PRODUCTOS ACTIVOS FIJOS */}

                            <Dialog
                                open={modalActivosFijos}
                                maxWidth='md'
                                fullWidth
                            >

                                <DialogTitle>
                                    AGREGAR ITEMS
                                </DialogTitle>
                                <DialogContent dividers >
                                    <Box pb={3}>
                                        <Autocomplete
                                            disablePortal
                                            clearOnBlur={false}
                                            autoComplete={false}
                                            size="small"
                                            id="combo-box-demo"
                                            options={productos}
                                            disableClearable
                                            getOptionLabel={(option) => option.codigo_producto}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setItemActivoFijo(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                                        />
                                    </Box>

                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>CODIGO</TableCell>
                                                    <TableCell>DESCRIPCION</TableCell>
                                                    <TableCell>STOCK</TableCell>
                                                    <TableCell>ACCIONES</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productosActivosFijos && productosActivosFijos
                                                    .filter((val) => {
                                                        if (itemActivoFijo === "") {
                                                            return val;
                                                        } else if (val.codigo_producto.toLowerCase().includes(itemActivoFijo.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{row.codigo_producto}</TableCell>
                                                            <TableCell>{row.descripcion_producto}</TableCell>
                                                            <TableCell>{row.cantidad_producto}</TableCell>
                                                            <TableCell><Button
                                                                variant='contained'
                                                                color='success'
                                                                onClick={() => addInput(row)}
                                                            >Agregar</Button></TableCell>
                                                        </TableRow>
                                                    ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box py={5} ></Box>
                                </DialogContent>
                                <DialogActions dividers>
                                    <Button onClick={() => setModalActivosFijos(false)}>
                                        Cerrar ventana
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Typography gutterBottom>DETALLE DE MATERIALES {item}</Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NO.</TableCell>
                                            <TableCell align="center">CODIGO</TableCell>
                                            <TableCell align="center">DESCRIPCION</TableCell>
                                            <TableCell align="center">CANT PEDIDA</TableCell>
                                            <TableCell align="center">ACCIONES</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            inputFields.map((data, index) => {
                                                const { cantidad_pedida, codigo, descripcion } = data;
                                                return (
                                                    detalleMate(data, index)
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Divider />
                            <Box display="flex" flexDirection="row-reverse" pt={1}>
                                <Button
                                    variant='contained'
                                    disabled={inputFields.length <= 0}
                                    type="submit"
                                >Enviar solicitud</Button>
                            </Box>
                        </form>
                    </Box>
                </Paper>
            </Box>

            {/* DIALOG SOLICITUDES DE PEDIDO */}
            <Dialog open={openDialogUsuarios} onClose={() => setOpenDialogUsuarios(false)} maxWidth="md" fullWidth>
                <DialogTitle><strong>SELECCIONAR LA SOLICITUD DE PEDIDO A ENLAZAR</strong></DialogTitle>
                <DialogContent>
                    <Box py={2} px={2}>
                        <Paper>
                            <Box py={2} px={2} sx={{ height: 600, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={solicitudesDePedido ? solicitudesDePedido : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,

                                    }}

                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                    {/* <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={solicitudesDePedido}
                                    getOptionLabel={(option) => option.uidSolicitud}
                                    onInputChange={(e, newInputValue) => {
                                        setItemUsuarios(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>NUMERO SOLICITUD</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>FECHA CREACION</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {solicitudesDePedido && solicitudesDePedido
                                        .filter((val) => {
                                            if (itemUsuarios === "") {
                                                return val;
                                            } else if (val.uidSolicitud.toLowerCase().includes(itemUsuarios.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.uidSolicitud}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.uidSolicitud}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.created}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCountUsers(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl> */}
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogUsuarios(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogUsuarios(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    )
}