import {
    Tabs,
    Box,
    Tab,
    Paper
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { TicketsEnEsperaPage } from './TicketsEnEspera/TicketsEnEsperaPage';
import { TicketsAprobados } from './TicketsAprobados/TicketsAprobados';
import { TicketsPorReasignar } from './TicketsPorReasignar/TicketsPorReasignar';
import { TicketsCerrados } from './TicketsRechazados/TicketsCerrados';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const TicketsPage = (props) => {
    console.log(props)
    const params = useParams()
    const [value, setValue] = useState((props.valor ? props.valor : 0));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (params.id) {
            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
            setValue(parseInt(params.id));
        }
    }, [params.id]);
    return (
        <>
            {/* <Box px={2}>
                <Typography variant='h5' component='h1'> <strong>TICKETS</strong> </Typography>
            </Box> */}
            <Box px={2}>
                <Box pl={2} pt={2}>
                    <p style={{ fontSize: "18px", marginBottom: "0px" }}><strong>TICKETS VISITA TECNICA</strong></p>
                </Box>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Tickets en espera" {...a11yProps(0)} />
                            <Tab label="Tickets Gestionados" {...a11yProps(1)} />
                            <Tab label="Tickets por reasignar" {...a11yProps(2)} />
                            <Tab label="Tickets Cerrados" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                </Paper>
            </Box>
            <TabPanel value={value} index={0}>
                <TicketsEnEsperaPage />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TicketsAprobados />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TicketsPorReasignar />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <TicketsCerrados />
            </TabPanel>
        </>
    )
}