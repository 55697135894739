import { Button, Tooltip, Paper, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import { ComprobanteEgreso } from "./../inventario/reporte_de_movimientos/PlantillasPDF/ComprobanteEgreso"


export const DescargarPdfDespacho = ({ data, id }) => {
    const [open, setOpen] = useState(false);
    const [dataItems, setDataItems] = useState([]);

    const onData = async () => {
        /* let agendaDB2 = db.collection('reporte_movimientos').where('relacion_despacho', '==', id).where('motivo_movimiento', '==', 'EGRESO').limit(1);
        const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() })) */
        let ref = db.collection("reporte_movimientos").doc(id).collection('items');
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setDataItems(data)
            setOpen(true)
        });

    }

    return (
        <>
            <Tooltip title="descargar pdf">
                <Button color="success" variant="contained" onClick={() => onData()}>
                    VER / DESCARGAR PDF
                    {/* <PictureAsPdfIcon color="error" /> */}
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                <DialogTitle>
                    <strong>Descargar: {id}</strong>
                </DialogTitle>
                <DialogContent>

                    <ComprobanteEgreso encabezado={data} contenido={dataItems} />
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};