import React, { useEffect, useState } from "react";
import {
    Grid, Stack, TextField, Select, MenuItem, Card, FormControl,
    InputLabel, CardContent, Button, Box, Typography, Dialog, DialogContent,
    Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listsTipoNombreCuenta, listsNaturalezaCuenta, listsTiposCuentas, listsItemsEstadoFinanciero } from "../../../redux/actions/parametersActions";
import { fontSize } from "@mui/system";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase, auth } from '../../../firebase';

import Swal from "sweetalert2";

export const EditChildCount = ({ props }) => {

    const [allData, setAllData] = useState();

    const [data, setData] = useState();

    const [isLastPage, setIsLastPage] = useState(0);

    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const dispatch = useDispatch()
    useEffect(() => {
    }, []);

    const [openCuentaMenor, setOpenCuentaMenor] = useState(null);



    const [codigoCuenta, setCodigoCuenta] = useState('')

    const handleCodigoNombreCuenta = (e) => {
        setCodigoCuenta(e)
    }

    const [nombreCuenta, setNombreCueta] = useState(props.nombre)

    const handleNombreCuenta = (e) => {
        setNombreCueta(e)
    }

    const [codigoNombreCuenta, setCodigoNombreCuenta] = useState(props.cuenta)

    const changeTipoNombreCuenta = (e) => {
        setTipoNombreCuenta(e)
    }

    const tipoNombreCuentasList = useSelector(state => state.tipoNombreCuentasList);
    const { tipoNombreCuentas, loading } = tipoNombreCuentasList
    const [tipoNombreCuenta, setTipoNombreCuenta] = useState(props.tipo_de_saldo)

    const handleDCodigoCuenta = (e) => {
        setCodigoCuenta(e)
    }

    const naturalezaCuentaList = useSelector(state => state.naturalezaCuentaList);
    const { naturalezaCuentas, loadingNaturalezaCuentas } = naturalezaCuentaList
    const [naturalezaCuenta, setNaturalezaCuenta] = useState(props.naturaleza)

    const changeNaturalezaCuenta = (e) => {
        setNaturalezaCuenta(e)
    }

    const tiposCuentasList = useSelector(state => state.tiposCuentasList);
    const { tipoCuentas, loadingTipoCuentas } = tiposCuentasList
    const [tipoCuenta, setTipoCuenta] = useState(props.tipo_de_cuenta)

    const handleTipoCuenta = (e) => {
        setTipoCuenta(e)
    }

    const itemsEtsadoFinancierosList = useSelector(state => state.itemsEtsadoFinancierosList);
    const { itemsEstadoFinancieros, loadingItemsEstadoFinanciero } = itemsEtsadoFinancierosList
    const [itemsEstadoFinanciero, setItemsEstadoFinanciero] = useState(props.item_estado_financiero)

    const handleItemsEstadoFinanciero = (e) => {
        setItemsEstadoFinanciero(e)
    }

    const [catalogoDeCostoObligatorio, setCatalogoDeCostoObligatorio] = useState(props.catalogo_de_costo_obligatorio);
    const [cuentaUtilidad, setCuentaUtilidad] = useState(false);
    const [cuentaGanancia, setCuentaGanancia] = useState(props.cuenta_de_ganancia);
    const [cuentaPerdida, setCuentaPerdida] = useState(props.cuenta_de_perdida);
    const [agrupador101, setAgrupador101] = useState();

    const handleAgrupador101 = (e) => {
        setAgrupador101(e)
    }

    const [estado, setEstado] = useState(props.estado);

    const [nivelDeCuenta, setNivelDeCuenta] = useState(props.nivel);

    const onData = () => {
        setOpenCuentaMenor(true)
    }

    const handleNivelDeCuenta = (e) => {
        setNivelDeCuenta(e)
    }

    const pruebaDatos = () => {
        console.log(props.naturaleza)
        console.log(props.cuenta)
    }

    const getDataChildCount = async () => {
        console.log(props)

        await db.collection('plan_cuentas').doc(props.cuenta_mayor).collection("items").doc(props.id).update({
            nombre: nombreCuenta,
            cuenta: codigoNombreCuenta,
            nivel: nivelDeCuenta
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado correctamente", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpenCuentaMenor(false)

    }

    return (
        <>
            <Tooltip title="EDITAR CUENTA">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={openCuentaMenor} fullWidth onClose={() => setOpenCuentaMenor(false)} maxWidth="lg">
                <DialogContent>
                    <Card sx={{ minWidth: 100 }}>
                        <CardContent>
                            <Box paddingBottom={2}>
                                <Typography >
                                    <strong>CUENTA HIJA</strong>
                                </Typography >
                            </Box>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CODIGO NOMBRE DE CUENTA</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={codigoNombreCuenta}
                                            onChange={(e) => handleCodigoNombreCuenta(e.target.value)}
                                            name="codigonombrecuenta"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NIVEL DE CUENTA</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={nivelDeCuenta}
                                            onChange={(e) => handleNivelDeCuenta(e.target.value)}
                                            name="agrupador101"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>TIPO DE NOMBRE DE CUENTA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={tipoNombreCuenta}
                                            label="TIPO NOMBRE DE CUENTA"
                                            onChange={(e) => changeTipoNombreCuenta(e.target.value)}>
                                            {tipoNombreCuentas &&
                                                tipoNombreCuentas.map(item => (
                                                    <MenuItem key={item.tipo_nombre_cuenta} value={item.tipo_nombre_cuenta} >
                                                        {item.tipo_nombre_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>

                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NOMBRE DE CUENTA</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            value={nombreCuenta}
                                            onChange={(e) => handleNombreCuenta(e.target.value)}
                                            name="NOMBRE CUENTA"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NATURALEZA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={naturalezaCuenta}
                                            onChange={(e) => changeNaturalezaCuenta(e.target.value)}>
                                            {naturalezaCuentas &&
                                                naturalezaCuentas.map(item => (
                                                    <MenuItem key={item.naturaleza_cuenta} value={item.naturaleza_cuenta} >
                                                        {item.naturaleza_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>TIPO CUENTA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={tipoCuenta}
                                            onChange={(e) => handleTipoCuenta(e.target.value)}>
                                            {tipoCuentas &&
                                                tipoCuentas.map(item => (
                                                    <MenuItem key={item.tipo_cuenta} value={item.tipo_cuenta} >
                                                        {item.tipo_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>

                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>ITEM DE ESTADO FINANCIERO</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={itemsEstadoFinanciero}
                                            onChange={(e) => handleItemsEstadoFinanciero(e.target.value)}>
                                            {itemsEstadoFinancieros &&
                                                itemsEstadoFinancieros.map(item => (
                                                    <MenuItem key={item.nombre_item_estado_financiero} value={item.nombre_item_estado_financiero} >
                                                        {item.nombre_item_estado_financiero}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CATALOGO DE COSTO OBLIGATORIO</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={catalogoDeCostoObligatorio}
                                            onChange={(e) => setCatalogoDeCostoObligatorio(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CUENTA GANANCIA</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={cuentaGanancia}
                                            onChange={(e) => setCuentaGanancia(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CUENTA PERDIDA</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={cuentaPerdida}
                                            onChange={(e) => setCuentaPerdida(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>AGRUPADOR 101</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={agrupador101}
                                            onChange={(e) => handleAgrupador101(e.target.value)}
                                            name="agrupador101"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>ESTADO</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}
                                        >
                                            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button onClick={() => setOpenCuentaMenor(false)} color="error" size='small' style={{ margin: "10px" }} variant="contained" endIcon={<KeyboardArrowRightIcon />}>
                            <strong>SALIR</strong>
                        </Button>
                        <Button onClick={() => getDataChildCount()} size='small' style={{ margin: "10px" }} variant="contained" endIcon={<AddCircleIcon />}>
                            <strong>CONFIRMAR</strong>
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>

        </>
    )
}