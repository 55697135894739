import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const ListBancos = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    return (
        <>

            {userAuth?.roles?.find(role => ['admin', 'bancos']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <AccountBalanceIcon  />
                    </ListItemIcon>
                    <ListItemText primary="Bancos" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`bancos/registro-banco/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Registro Banco" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/registro-cuenta-bancaria/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Registro Cuenta Bancaria" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/registro-de-movimiento/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Registro de Movimiento" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/conciliacion/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Conciliacion" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/carga-de-movimientos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga de Movimientos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/paarametro-de-tipo-de-movimiento/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametro de Tipo de Movimiento" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`bancos/carga-de-pagos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga de Pagos" />
                        </ListItemButton>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}