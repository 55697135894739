import {
  Box, Typography, Button, Paper, Chip,  Collapse
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';
export const PagoDebitoClientes = () => {
  function Estado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 0) {
      name = 'PAGADO'
      color = 'secondary'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }
    return (
      <Chip label={name} size="small" color={color} />
    )
  }
  function EstadoPagado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado_pagado === 0 || !d.estado_pagado) {
      name = 'NO PAGADO'
      color = 'info'
    }
    if (d.estado_pagado === 1) {
      name = 'NO PAGADO'
      color = 'info'
    }
    if (d.estado_pagado === 2) {
      name = 'PAGADO'
      color = 'warning'
    }
    return (
      <Chip label={name} size="small" color={color} />
    )
  }
  const [open, setOpen] = useState(false)
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  //
  const current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  //
  useEffect(() => {
    LoadData()
  }, [])
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("tecnicos").where("debito", "==", 1).where("provincia", "==", "SANTA ELENA")
    //Si tiene algun doc anterior se agrega acontinuacion
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(12, 0, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fechaHora', '>=', startfulldate)
      ref = ref.where('fechaHora', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];
        setData(data)
      })
    }
  }
  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }
  const columns = [
    {
      field: 'fechaHora', headerName: 'FECHA INSTALACION', type: 'dateTime', width: 175,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },

    {
      field: 'estado2', headerName: 'ESTADO', width: 175,
      renderCell: (params) => {
        return <EstadoPagado estado={params.row} />
      }
    },
    { field: 'user_id_microwisp', headerName: 'ID CLIENTE', width: 100 },
    { field: 'id_factura', headerName: 'ID FACTURA', width: 100 },
    { field: 'id_tranferencia', headerName: 'ID TRANSACCION', width: 150 },
    { field: 'cobrado', headerName: 'PAGADO', width: 100 },
    { field: 'forma_pago', headerName: 'FORMA PAGO', width: 150 },
    { field: 'fecha_pago', headerName: 'FECHA PAGO', width: 150 },
    { field: 'fecha_emitido', headerName: 'FECHA EMITIDO', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 300 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'nombres', headerName: 'CLIENTE', width: 400 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado', headerName: 'DISCAPACIDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'DISCAPACIDAD DESCUENTO', width: 300,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : `${value.descuento} - ${value.discapacidad} - $${value.valor}`
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 100 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },

    { field: 'vendedor', headerName: 'VENDEDOR', width: 200 },
    {
      field: 'referido', headerName: 'REFERIDO', width: 150,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },
    {
      field: 'flotaReferida', headerName: 'FLOTA REFERIDA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },

    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    {
      field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    { field: 'estado_pagado', headerName: 'ORDENAR', width: 100 },
  ]
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  const onload = () => {
    db.collection("tecnicos").where("estado_pagado", "==", 2)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          const data = doc.data()
          let requestOptions = {
              method: 'GET',
              redirect: 'follow'
          };
          fetch(`https://serverbd.intercommerce.com.ec/apioperacionestab/?nfactura=${data.id_factura}`, requestOptions)
            .then(response => response.text())
            .then(result => {
              const re = JSON.parse(result)
              const dataF = re[0]
            
              let tecnicosRef = db.collection("tecnicos").doc(`${data.numContrato}`);
              tecnicosRef.update({
                user_id_microwisp: dataF.idcliente,
                cobrado: dataF.cobrado,
                forma_pago: dataF.forma_pago,
                id_tranferencia: dataF.transaccion,
                fecha_emitido: dataF.descripcion,
                fecha_pago: dataF.fecha_pago,
                //idcliente: dataF.idcliente,
              })
            }).catch(error => console.log('error', error));
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  return (
    <>
      <Box p={2}>
        <Typography variant="h5" >CLIENTES/<strong>DEBITO PAGADA</strong> </Typography>
      </Box>
      <Box pt={1} pl={1}>
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            density='compact'
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
        <Button onClick={() => onload()}>Hola</Button>

      </Paper>
    </>
  )

}

