import { Box, LinearProgress } from "@mui/material"
import { DataGrid, esES } from "@mui/x-data-grid"

export const tabla = (columnas, lista ,loading) => {
    return (
        <Box sx={{
            height: 400, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
            '& .super-app-theme--header': {
                backgroundColor: 'LightSlateGray',
                color: 'white',
                fontSize: '16px'
            },
        }}>
            <DataGrid
                slots={{
                    loadingOverlay: LinearProgress,
                }}
                loading={loading}
                hideFooterSelectedRowCount={true}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={columnas}
                density='compact'
                rows={lista ?? []}
                getRowHeight={() => 'auto'}
                getRowId={(row) => row.id ? row.id : row.codigo}
                getEstimatedRowHeight={() => 100}
            />
        </Box>
    )
}

