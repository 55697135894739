import {
    IconButton, Table, Typography, TableContainer, TableRow,
    TableCell, TableBody, TableHead, Box, Paper, Chip, Button
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'
import { auth, db, firebase } from '../../firebase'
import { useSelector } from 'react-redux';
import { ExportarCSV } from '../../components/utils/ExportarCSV';
import DatePicker from "react-datepicker";
import Collapse from '@mui/material/Collapse';
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from '../../components/utils/SearchInput';
import FilterListIcon from '@mui/icons-material/FilterList';

function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado == 0) {
        name = 'Esperando..'
        color = 'warning'
    } else if (estado === 1) {
        name = 'No factible'
        color = 'info'
    } else if (estado === 2) {
        name = 'FACTIBILIDAD'
        color = 'success'
    }
    return (
        <Chip label={name} color={color} />
    )
}
export const CodigosOtpPage = () => {
    const [open, setOpen] = useState(null);
    const [data, setData] = useState()
    const [isLastPage, setIsLastPage] = useState(0)
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [reData, setreData] = useState([])
    //
    const [search, setSearch] = useState('')
    //var current = new Date();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const [value, setValue] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        LoadData()
    }, [startDate])
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const LoadData = (startAfterDoc, persistMessages = []) => {
        console.log(startDate, '..', endDate)
        let ref = db.collection("codigoOTP").orderBy('created', 'desc').limit(20)
        //if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(0, 0, 0, 0)
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }
    const onChange2 = () => {
        LoadData()
    }
    return (
        <>
            <Box pt={1} pl={1}>
                <Typography >
                    <strong>({data && data.length})</strong> CODIGOS OTP
                </Typography >
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                </Box>
                <Box>
                    <Box display="flex" alignItems='center' py={2}>
                        <SearchInput setSearch={setSearch} search={search} />
                        <IconButton id="basic-button" onClick={() => setOpen(!open)}>
                            <FilterListIcon />
                        </IconButton>
                        <ExportarCSV data={data} />
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table size="small" stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>CREACIÓN</TableCell>
                                <TableCell>CODIGO</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.filter((val) => {
                                if (search === '') {
                                    return val
                                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.direccion.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.caja.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }
                            }).map((row, key) => (
                                <TableRow hover key={key}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.created && typeof row.created === 'number' &&
                                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                                        {row.created && typeof row.created === 'object' &&
                                            moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.codigoOTP}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}