import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    Checkbox,
    Select,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    MenuItem
} from '@mui/material'
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from '../../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import InputValidation from '../../../components/utils/InputValidation'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { Grade } from '@mui/icons-material';
import {
    listsBodegas,
    listsUbicaciones,
    listsMotivoEgresoProductos
} from "../../../redux/actions/parametersActions";
import { ConvertRoles } from '../../../components/config/ConvertRoles';
export const GenerarOrdenCompra = ({ items }) => {
    const [open, setOpen] = useState(false);
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    //
    const [subTotal, setSubTotal] = useState(0.00)
    const [descuento, setDescuento] = useState(0.00)
    const [flete, setFlete] = useState(0.00)
    const [fleteTotal, setFleteTotal] = useState(0.00)
    const [descuentoTotal, setDescuentoTotal] = useState(0.00)
    const [totalCompra, setTotalCompra] = useState('')
    const [valorIce, setValorICE] = useState(0)
    const [checkIVA, setCheckIVA] = useState(true)
    const [checkICE, setCheckICE] = useState(false)
    //
    const [valorIva, setValorIVA] = useState(0)
    //
    const [data, setData] = useState('')
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [productos, setProductos] = useState('')
    //
    const [irbpn, setIRBPN] = useState(0)
    const [totalIRBPN, setTotalIRBPN] = useState('')
    //
    const [provedores, setProvedores] = useState('')
    //
    const ubicacionesList = useSelector(state => state.ubicacionesList);
    const { ubicaciones, loadingUbicaciones } = ubicacionesList
    const [ubicacion, setUbicacion] = useState('')
    //
    const bodegasList = useSelector(state => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList
    const [bodega, setBodegas] = useState('')
    //
    const motivoEgresoProductosList = useSelector(state => state.motivoEgresoProductosList);
    const { motivoEgresoProductos, loadingMotivoIngresoProductos } = motivoEgresoProductosList
    const [motivoIngresoProducto, setMotivoIngresoProducto] = useState('')
    //
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    //
    const [modal, setModal] = useState(false)
    //
    const [formaPago, setFormaPago] = useState('')

    const [inputFields, setInputFields] = useState([]);
    //
    const [orden, setOrden] = useState("");
    //PRODUCTOS AGREGADOS A LA TABLA
    const addInput = (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            alert("Ya se agrego este producto a la lista")
            //Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else {
            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 1,
                descripcion: row.descripcion_producto,
                codigo: row.codigo_producto,
                categoria_producto: row.categoria_producto,
                subcategoria_producto: row.subcategoria_producto,
                marca_producto: row.marca_producto,
                costo_estandar_producto: parseFloat(row.costo_estandar_producto),
                modelo_producto: row.modelo_producto,
                presentacion_producto: row.presentacion_producto,
                unidad_Medida_Producto: row.unidad_Medida_Producto,
                usuario_que_creo_el_producto: row.usuario_que_creo_el_producto,

            }])
            //console.log(inputFields, 'input fields')
        }
        //
        // 15
        // var sum = 0
        // for(let i = 0; i < inputFields.length; i++){
        //     const item = inputFields[i]
        //     console.log(item.costo_estandar_producto)    
        //     // if(item.costo_estandar_producto){
        //     //     console.log(item.costo_estandar_producto, 'Cantidad producto')
        //     sum +=  parseFloat(item.costo_estandar_producto)
        //     // }
        // }
        //console.log(sum)
        setItem('')
        setModal(false)
    }
    //PRODUCTOS REMOVIDOS DE LA TABLA
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    //SE DETECTAN LOS CAMBIOS EN LA DIGITACION
    //DE LA CANTIDAD
    const handleChange = async (index, evnt) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                contador()
                // handleIVA()
                setInputFields(list);

            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }
    const calcularTotal = () => {
        let total = descuentoTotal + valorIva
        let total2 = total + parseFloat(irbpn)
        // console.log(irbpn, 'irbpn')
        // console.log(total, 'total')
        // console.log(total2, 'SUBTOTAL')
        //console.log(total2, 'total2')
        //
        //console.log(total, 'total2', irbpn)
        //total = irbpn + total
        setTotalCompra(total2)
    }
    const calcularFlete = () => {
        let fleteTotal = subTotal + parseFloat(flete)
        //console.log(fleteTotal, 'flete total 3')
        setFleteTotal(fleteTotal)
    }
    const calcularDescuento = () => {
        let descuentoTotal = fleteTotal - descuento
        //console.log(descuentoTotal, 'descuento total')
        setDescuentoTotal(descuentoTotal)
    }
    //
    const calcularIRBPN = () => {
        let t = descuentoTotal + irbpn
        //console.log(t, 'irbpn', irbpn)
        setTotalIRBPN(t)
    }
    //SE DETECTAN CAMBIOS DE DESCUENTOS
    const handleDescuento = async (evnt) => {
        calcularDescuento()
        var descuento = evnt.target.value
        if (Math.sign(evnt.target.value) === -1) {
        } else {
            //if(flete > descuento){
            setDescuento(descuento)
            //}
        }
    }
    const handleIRBPN = async (evnt) => {
        calcularIRBPN()
        var descuento = evnt.target.value
        if (Math.sign(evnt.target.value) === -1) {
        } else {
            setIRBPN(descuento)
        }
    }
    //SE DETECTAN CAMBIOS DE FLETES
    const handleFlete = async (evnt) => {
        calcularFlete()
        var descuento = evnt.target.value
        if (Math.sign(evnt.target.value) === -1) {
        } else {
            //if(subTotal > descuento){
            setFlete(descuento)
            //}
        }
    }
    const handleCheckIVA = (e) => {
        setCheckIVA(e.target.checked)
        setValorIVA(0)
    }

    const cambioIva = (e, index) => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let iva = 0
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.costo_estandar_producto)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.subtotal.toFixed(2));
                }
                let total = contenido[i]["costo_estandar_producto"] * contenido[i]["cantidad_pedida"]
                total -= contenido[i]["descuento"]
                list[i]["subtotal"] = total ?? 0
                if (index === i) {
                    list[i]["valorIva"] = e.target.checked ? parseFloat((list[i]["subtotal"] * 0.12).toFixed(2)) : 0
                } else {
                    list[i]["valorIva"] = list[i]["valorIva"] ?? 0
                }
                iva += list[i]["valorIva"]
                list[i]["precio_total"] = parseFloat((list[i]["subtotal"] + list[i]["valorIva"]).toFixed(2));
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                let docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        //console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
            }
            setValorIVA(iva)
            return suma;
        }
        const list = inputFields
        const subTotal = sumarContenidoArreglo(inputFields)
        setSubTotal(subTotal)
    }


    const handleCheckICE = (e) => {
        if (checkICE == true) {
            setCheckICE(e.target.checked)
            setValorICE(0)
        }
    }
    //IVA
    const handleIVA = async () => {
        if (checkIVA == true) {
            const iva = (descuentoTotal * 12) / 100
            setValorIVA(iva)
        }
    }

    const calcularICE = async () => {
        if (checkICE == true) {
            const iva = (descuentoTotal * 12) / 100
            setValorICE(iva)
        }
    }
    const getProductos = () => {
        var docRef = db.collection("productos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const productoPrecioHistorico = (codigo) => {
        let sum = 0;
        var docRef = db.collection("productos").doc(codigo);
        docRef.get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                sum = data.precio_compra_producto
                return sum
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            var docRef = db.collection("productos").where("codigoProducto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcionProducto
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">
                    {data.codigo}
                </TableCell>
                <TableCell align="center">{data.descripcion}</TableCell>
                <TableCell style={{ fontSize: '12px' }} align="center">
                    {data.precio_compra_producto}
                </TableCell>
                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        name={"costo_estandar_producto"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.costo_estandar_producto}
                        size='small'
                        type='number'
                    />
                </TableCell>

                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        name={"cantidad_pedida"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        name={"descuento"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.descuento}
                        size='small'
                        type='number'
                    />
                </TableCell>
                <TableCell style={{ fontSize: '12px' }} align="center">
                    {data.subtotal ? data.subtotal.toFixed(2) : 0}
                </TableCell>
                <TableCell style={{ fontSize: '12px' }} align="center">
                    <Checkbox size='smalls' checked={data.checkIva} onChange={(event) => {
                        cambioIva(event, index)
                    }} />
                </TableCell>
                <TableCell style={{ fontSize: '12px' }} align="center">
                    {data.valorIva ? data.valorIva.toFixed(2) : 0}
                </TableCell>
                {/* <TableCell style={{ fontSize: '12px' }} align="center">
                    {data.precio_total ? data.precio_total.toFixed(2) : 0}
                </TableCell> */}
                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)} color='error'>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    const getProvedores = () => {
        var docRef = db.collection("proveedores")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProvedores(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const getFormaPago = () => {
        var docRef = db.collection("forma_pago")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setFormaPago(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    //
    const loadNumOrden = () => {
        const ref = db.collection("orden_compra").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                //console.log(solicitudes, 'solicitudes')
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                var uidSolicitud = `ORD-COM-` + numeroSolicitud;
                setOrden(numeroSolicitud)
            }
        })
    }
    const dispatch = useDispatch()
    useEffect(() => {
        calcularICE()
        getProvedores()
        getProductos()
        dispatch(listsBodegas())
        dispatch(listsUbicaciones())
        dispatch(listsMotivoEgresoProductos())
        loadNumPedido()
        contador()
        // handleIVA()
        calcularTotal()
        calcularDescuento()
        calcularFlete()
        getFormaPago()
        loadNumOrden()
    }, [inputFields, totalCompra, subTotal, fleteTotal, flete,
        totalIRBPN, irbpn,
        descuentoTotal, valorIva, descuento, checkIVA, checkICE]);
    //
    const contador = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let iva = 0
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.costo_estandar_producto) && elemento.subtotal) {
                    suma += parseFloat(elemento.subtotal.toFixed(2));
                }
                /* if (esNumerico(elemento.costo_estandar_producto)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.subtotal.toFixed(2));
                } */
                let total = contenido[i]["costo_estandar_producto"] * contenido[i]["cantidad_pedida"]
                total -= contenido[i]["descuento"] ?? 0
                list[i]["subtotal"] = total ?? 0
                list[i]["valorIva"] = list[i]["valorIva"] ?? 0
                iva += list[i]["valorIva"]
                list[i]["precio_total"] = parseFloat((list[i]["subtotal"] + list[i]["valorIva"]).toFixed(2));
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                let docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        //console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });

            }
            setValorIVA(iva)
            return suma;
        }

        const list = inputFields
        const subTotal = sumarContenidoArreglo(inputFields)
        setSubTotal(subTotal)

    }
    const loadNumPedido = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }
    const loadItems = () => {
        var docRef = db.collection("solicitud_compra").doc(items.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setInputFields([...data])
        });
    }
    const handleSubmit = () => {
        var opcion = window.confirm("¿Esta seguro en generar la orden de compra?");
        if (opcion == true) {
            let rol = ''
            if (userInfo.rol === 0) {
                rol = 'ADMIN'
            }
            if (userInfo.rol === 1) {
                rol = 'COMERCIAL'
            }
            if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
                rol = 'OPERACIONES'
            }
            if (userInfo.rol === 3) {
                rol = 'PLANEACION'
            }
            if (userInfo.rol === 4) {
                rol = 'FLOTAS'
            }
            if (userInfo.rol === 5) {
                rol = 'REPORTES'
            }
            if (userInfo.rol === 6) {
                rol = 'RECURSOS HUMANOS'
            }
            //
            const uidSolicitud = `ORD-COM-` + numPedido;
            const data = {
                uidSolicitud: `ORD-COM-${orden}`,
                estado: 0,
                numeroSolicitud: orden,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: userInfo.departamentoText,
                rol: rol,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: bodega.toUpperCase(),
                ubicacion: ubicacion.toUpperCase(),
                motivo: motivoIngresoProducto.toUpperCase(),
                subTotal: subTotal,
                descuento: parseInt(descuento),
                descuentoTotal: descuentoTotal,
                valorIva: valorIva,
                totalCompra: totalCompra,
                flete: flete,
                cargoxcompra: irbpn,
                fleteTotal: fleteTotal,
                relacion: items.ref_solicitud_pedido
            }
            //console.log(data, 'enviar')
            db.collection('orden_compra').doc(`ORD-COM-${orden}`).set({
                ...data,
                estado: 1,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            }).then((docRef) => {
                Swal.fire({ icon: "success", text: 'Se actualizo su solicitud de pedido' });
                setLoading(false)
                setOpen(false)
                db.collection("solicitud_compra").doc(items.uidSolicitud).update({
                    estado: 3
                })
                inputFields.forEach(function (obj, index) {
                    const dataRef = {
                        ...obj,
                        uid_orden_compra: `ORD-COM-` + orden,
                        cantidad: parseInt(obj.cantidad_pedida),
                        cantidad_pedida: parseInt(obj.cantidad_pedida),
                        cantidad_pendiente: parseInt(obj.cantidad_pedida),
                        estado: 1,
                        // costo_estandar_producto: parseFloat(obj.costo_estandar_producto).toFixed(4),
                        costo_estandar_producto: parseFloat(obj.costo_estandar_producto),
                        descuento: parseFloat(obj.descuento),
                        valorIva: parseFloat(obj.valorIva),
                        subtotal: parseFloat(obj.subtotal),
                        precio_total: parseFloat(obj.precio_total),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }
                    const doc = db.collection(`orden_compra/ORD-COM-${orden}/items`).doc(dataRef.codigo).set(dataRef)
                        .then((docRef) => {
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                    //
                    db.collection("productos").doc(dataRef.codigo).update({
                        precio_compra_producto: dataRef.costo_estandar_producto,
                        precio_compra: dataRef.costo_estandar_producto
                    })
                    db.collection("reporte_stock").where("codigo_producto", "==", dataRef.codigo).get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                const data = doc.data()
                                db.collection("reporte_stock").doc(doc.id).update({
                                    precio_compra: dataRef.costo_estandar_producto
                                })
                            });
                        })
                });
            })
        } else {
            //mensaje = "Has clickado Cancelar";
        }
    }
    const handleRecha = () => {
        var opcion = window.confirm("¿Esta seguro en rechazar la orden de compra?");
        if (opcion == true) {
            let rol = ''
            if (userInfo.rol === 0) {
                rol = 'ADMIN'
            }
            if (userInfo.rol === 1) {
                rol = 'COMERCIAL'
            }
            if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
                rol = 'OPERACIONES'
            }
            if (userInfo.rol === 3) {
                rol = 'PLANEACION'
            }
            if (userInfo.rol === 4) {
                rol = 'FLOTAS'
            }
            if (userInfo.rol === 5) {
                rol = 'REPORTES'
            }
            if (userInfo.rol === 6) {
                rol = 'RECURSOS HUMANOS'
            }
            //
            const uidSolicitud = `ORD-COM-` + numPedido;
            const data = {
                uidSolicitud: `ORD-COM-${orden}`,
                estado: 0,
                numeroSolicitud: orden,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: userInfo.departamentoText,
                rol: rol,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: bodega.toUpperCase(),
                ubicacion: ubicacion.toUpperCase(),
                motivo: motivoIngresoProducto.toUpperCase(),
            }
            //console.log(data, 'enviar')
            db.collection('orden_compra').doc(`ORD-COM-${orden}`).set({
                ...data,
                estado: 2,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            }).then((docRef) => {
                Swal.fire({ icon: "success", text: 'Se rechazo su orden de compra' });
                setLoading(false)
                setOpen(false)
                db.collection("solicitud_compra").doc(items.uidSolicitud).update({
                    estado: 2
                })
                inputFields.forEach(function (obj, index) {
                    const dataRef = {
                        ...obj,
                        uid_orden_compra: `ORD-COM-` + orden,
                        cantidad: parseInt(obj.cantidad_pedida),
                        cantidad_pedida: parseInt(obj.cantidad_pedida),
                        estado: 1,
                        costo_estandar_producto: parseFloat(obj.costo_estandar_producto),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }
                    const doc = db.collection(`orden_compra/ORD-COM-${orden}/items`).doc(dataRef.codigo).set(dataRef)
                        .then((docRef) => {
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                });
                //console.log(inputFields, 'input')
            })
        } else {
            //mensaje = "Has clickado Cancelar";
        }
    }
    const onData = () => {
        setOpen(true)
        setData(items)
        loadItems()
        setInputFields([...inputFields,])
    }
    //
    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
            >

                <DialogTitle >
                    ORDEN COMPRA: {items.uidSolicitud}
                </DialogTitle>
                <DialogContent dividers>
                    <Card>
                        <CardContent>
                            <form>
                                <Grid container spacing={5}>
                                    <Grid item md={6}>
                                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>ORDEN DE COMPRA</strong></Typography>
                                        <Typography style={{ fontSize: '14px' }}>ORD-{orden}</Typography>
                                        <Box pt={1} pb={1}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>FECHA DE CREACION</strong></Typography>
                                            <Typography style={{ fontSize: '14px' }}>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                                        </Box>
                                        <Divider />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>USUARIO</strong></Typography>
                                        <Typography style={{ fontSize: '14px' }}>{userInfo.displayName}</Typography>
                                        <Box pt={1} pb={1}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>DEPARTAMENTO</strong></Typography>
                                            <ConvertRoles id={userInfo.rol} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box py={1}></Box>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Box pb={2}>
                                            <FormControl fullWidth>
                                                <Typography gutterBottom style={{ fontSize: '14px' }}><strong>PROVEDORES</strong></Typography>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={bodega}
                                                    label="MOTIVO"
                                                    onChange={(e) => setBodegas(e.target.value)}>
                                                    {provedores ?
                                                        provedores.map(item => (
                                                            <MenuItem key={item.nombre_comercial} value={item.nombre_comercial} >
                                                                {item.nombre_comercial}
                                                            </MenuItem>
                                                        )) : <></>}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormControl fullWidth>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>FORMA DE PAGO</strong></Typography>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={motivoIngresoProducto}
                                                label="FORMA DE PAGO"
                                                onChange={(e) => setMotivoIngresoProducto(e.target.value)}>
                                                {formaPago &&
                                                    formaPago.map(item => (
                                                        <MenuItem key={item.id} value={item.id} >
                                                            {item.id}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Box display="flex" flexDirection="row-reverse" py={1}>
                                    <Button
                                        size='small'
                                        variant='outlined'
                                        onClick={() => setModal(true)}>
                                        Agregar Items
                                    </Button>
                                </Box>
                                <Dialog
                                    open={modal}
                                    maxWidth='md'
                                    fullWidth
                                >
                                    <DialogTitle>
                                        AGREGAR ITEMS
                                    </DialogTitle>
                                    <DialogContent dividers >
                                        <Box pb={3}>
                                            <Autocomplete
                                                disablePortal
                                                size="small"
                                                id="combo-box-demo"
                                                clearOnBlur={false}
                                                autoComplete={false}
                                                options={productos}
                                                disableClearable
                                                getOptionLabel={(option) => option.codigo_producto}
                                                sx={{ width: 300 }}
                                                onInputChange={(e, newInputValue) => {
                                                    setItem(newInputValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                                            />
                                        </Box>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>CODIGO</TableCell>
                                                        <TableCell>DESCRIPCION</TableCell>
                                                        <TableCell>CANTIDAD</TableCell>
                                                        <TableCell>ACCIONES</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {productos && productos
                                                        .filter((val) => {
                                                            if (item === "") {
                                                                return val;
                                                            } else if (val.codigo_producto.toLowerCase().includes(item.toLowerCase())) {
                                                                return val;
                                                            }
                                                        }).map((row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>{row.codigo_producto}</TableCell>
                                                                <TableCell>{row.descripcion_producto}</TableCell>

                                                                <TableCell>{row.cantidad_producto}</TableCell>
                                                                <TableCell><Button
                                                                    size='small'
                                                                    variant='contained'
                                                                    color='success'
                                                                    onClick={() => addInput(row)}
                                                                >Agregar</Button></TableCell>
                                                            </TableRow>
                                                        ))}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </DialogContent>
                                    <DialogActions dividers>
                                        <Button size='small' variant='contained' onClick={() => setModal(false)}>
                                            Cerrar Ventana
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NO.</TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>CODIGO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>DESCRIPCION</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>PRECIO HISTORICO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>PRECIO ACTUAL</strong></TableCell>
                                                <TableCell align="center" style={{ width: '120px', fontSize: '12px' }}><strong>CANTIDAD</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>DESCUENTO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>SUBTOTAL</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>IVA</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>TOTAL IVA</strong></TableCell>
                                                {/* <TableCell align="center" style={{ fontSize: '12px' }}><strong>PRECIO TOTAL</strong></TableCell> */}
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>ACCIONES</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                inputFields.map((data, index) => {
                                                    const {
                                                        cantidad_pedida,
                                                        categoria_producto,
                                                        subcategoria_producto,
                                                        marca_producto,
                                                        modelo_producto,
                                                        presentacion_producto,
                                                        unidad_Medida_Producto,
                                                        usuario_que_creo_el_producto,
                                                        descripcion_producto,
                                                        codigo_producto,
                                                    } = data;
                                                    return (
                                                        detalleMate(data, index)
                                                    )
                                                })
                                            }
                                        </TableBody>
                                        <TableRow>
                                            <TableCell colSpan={11}></TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>DESC. TOTAL</Typography></TableCell>
                                            <TableCell align="center">
                                                {descuentoTotal.toFixed(2)}
                                            </TableCell>
                                        </TableRow> */}
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>SUBTOTAL</Typography></TableCell>
                                            <TableCell align="center">
                                                {subTotal && subTotal.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>TOTAL IVA</Typography></TableCell>
                                            <TableCell align="center">
                                                {valorIva && valorIva.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>FLETE</Typography></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    disabled={subTotal == 0}
                                                    type="number"
                                                    variant="outlined"
                                                    onChange={(e) => handleFlete(e)}
                                                    value={flete}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell colSpan={5}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>SUM. FLETE</Typography></TableCell>
                                            <TableCell align="center">
                                                {fleteTotal.toFixed(2)}
                                            </TableCell>
                                        </TableRow> */}
                                        {/* <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>DESCUENTO</Typography></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    disabled={subTotal == 0}
                                                    type="number"
                                                    variant="outlined"
                                                    onChange={(e) => handleDescuento(e)}
                                                    value={descuento}
                                                />
                                            </TableCell>
                                        </TableRow> */}

                                        {/* <TableRow>
                                            <TableCell colSpan={8}>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Checkbox size='smalls' checked={checkIVA} onChange={handleCheckIVA} defaultChecked />
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>IVA (12%)</Typography></TableCell>
                                            <TableCell align="center">
                                                {valorIva.toFixed(2)}
                                            </TableCell>
                                        </TableRow> */}
                                        {/* <TableRow>
                                            <TableCell colSpan={4}>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <Checkbox size='smalls' checked={checkICE} onChange={handleCheckICE} defaultChecked />
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>ICE (15%)</Typography></TableCell>
                                            <TableCell align="center">
                                                {valorIce.toFixed(2)}
                                            </TableCell>
                                        </TableRow> */}

                                        <TableRow>
                                            <TableCell colSpan={9}>
                                            </TableCell>
                                            <TableCell align="center" ><Typography variant='caption'>CARGOxCOMPRA</Typography></TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    disabled={subTotal == 0}
                                                    type="number"
                                                    variant="outlined"
                                                    onChange={(e) => handleIRBPN(e)}
                                                    value={irbpn}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={9}></TableCell>
                                            <TableCell align="center" ><Typography variant='h6'><strong>TOTAL</strong> </Typography> </TableCell>
                                            <TableCell align="center"><Typography variant='h6'><strong>{totalCompra && totalCompra.toFixed(2)}</strong> </Typography></TableCell>
                                            <TableCell colSpan={2}></TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <Divider />
                            </form>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <Button color='error' variant='contained' onClick={() => handleRecha()}>RECHAZAR ORDEN</Button>
                    <Button
                        size='small'
                        variant='contained'
                        color='success'
                        disabled={
                            inputFields.length <= 0
                            || bodega === ''
                            || motivoIngresoProducto === ''
                            || isNaN(subTotal)
                            //|| ubicacion === ''
                        }
                        onClick={() => handleSubmit()}
                    >GENERAR ORDEN DE COMPRA</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}