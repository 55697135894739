import {
  Paper,
  Chip,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ViewEsperaSale } from "../../../components/ventas/viewPages/ViewEsperaSale";
import { auth, db, firebase } from "../../../firebase";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
function Estado(estado) {

  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado.props == 0) {
    name = 'ESPERANDO'
    color = 'warning'
  } else if (estado.props === 1) {
    name = 'NO FACTIBLE'
    color = 'error'
  } else if (estado.props === 2) {
    name = 'FACTIBILIDAD'
    color = 'success'
  }
  return (
    <Chip size='small' label={name} color={color} />
  )
}
export const SolicitudServicioEspera = () => {
  const [open, setOpen] = useState(null);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const [data, setData] = useState();
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 172800000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData();
  }, [startDate]);
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("solicitudes").orderBy("created", "desc").where("estado", "==", 0);
    if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
      ref = ref.where("user_uid", "==", auth.currentUser.uid)
    if (userInfo?.roles?.find(role => ['corporativo']?.includes(role)))
      ref = ref.where("contrato", "==", "CORPORATIVO")
      if (userInfo?.roles?.find(role => ['lideres_ventas']?.includes(role)))
      ref = ref.where("centro_costo.lider_venta", "==", userInfo.nombres)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //ref = ref.where("created", ">=", startfulldate);
      //ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      });
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const onChange2 = () => {
    LoadData();
  };
  const [total, setTotal] = useState(0);
  const columns = [
    {
      field: 'created', headerName: 'FECHA DE CREACION DE SOLICITUD', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'ac', headerName: 'ESTADO', width: 125,
      renderCell: (params) => {
        return <Estado props={params.row.estado} />
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 125 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
    { field: 'canton', headerName: 'CANTON', width: 100 },
    { field: 'direccion', headerName: 'DIRECCION', width: 400 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR	', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido', headerName: 'ES REFERIDO', width: 125,
      renderCell: (params) => {
        return params.row.referido === "" ? "NO" : params.row.referido
      }
    },
    {
      field: 'referido_flota', headerName: 'VENTA REFERIDA POR FLOTA', width: 225,
      renderCell: (params) => {
        return params.row.referido === "SI"
          ? params.row.flotaReferida.numeroFlota
          : "NINGUNA"
      }
    },
    {
      field: 'ver', headerName: 'VER', width: 100,
      renderCell: (params) => {
        return <ViewEsperaSale props={params.row} />
      }
    },
  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `SOLICITUD ESPERA ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  function CustomFooterTotalComponent(props) {
    return ''
  }
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          <strong>({data && data.length})</strong> SOLICITUDES EN ESPERA
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Box>



      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
