/* import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { db, firebase } from "../../firebase";
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import InputValidation from "../utils/InputValidation";
import {
  listsModelos,
  listsNivel,
  listsNodos,
  listsParameters,
  listsSplitter,
} from "../../redux/actions/parametersActions";
import Loader from "../utils/Loader";
import SelectGeografico from "../utils/SelectGeograficos";

export const UploadBox = () => {
  const dispatch = useDispatch();
  const parametersList = useSelector((state) => state.parametersList);
  const { parameters, loading } = parametersList;

  const splitterList = useSelector((state) => state.splitterList);
  const { splitter: splitter_List, loading: splitterLoading } = splitterList;

  const nivelList = useSelector((state) => state.nivelList);
  const { nivel: nivel_list, loading: nivelLoading } = nivelList;

  const nodoList = useSelector((state) => state.nodoList);
  const { nodos: nodo_List, loading: nodoLoading } = nodoList;

  const modeloList = useSelector((state) => state.modeloList);
  const { modelos: modelo_List, loading: modeloLoading } = modeloList;

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [caja, setCaja] = useState({ campo: "", valido: true });
  const [splitter, setSplitter] = useState("");
  const [nodo, setNodo] = useState({ campo: "", valido: true });
  const [nivel, setNivel] = useState("");
  const [modelo, setModelo] = useState("");
  const [longitude, setLongitude] = useState({ campo: "", valido: true });
  const [latitude, setLatitude] = useState({ campo: "", valido: true });
  const [canton, setCanton] = useState({ campo: "", valido: true });
  const [sector, setSector] = useState({ campo: "", valido: true });
  const [provincia, setProvincia] = useState({ campo: "", valido: true });
  const [message, setMessage] = useState();
  const fileRef = useRef();
  //
  const [geografico, setGeografico] = useState("");
  //Paramets
  const listsParametersRef = useRef(parameters);
  const handleUploadFileLocal = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  };
  const handleRemove = () => {
    setFileName(null);
    setFile(null);
    fileRef.current.value = "";
  };
  const handleUploadFile = async () => {
    try {
      if (file) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        jsonData.forEach((obj) => {
          db.collection("cajas")
            .doc(obj.CAJA)
            .set({
              ...obj,
              CREATED: firebase.firestore.FieldValue.serverTimestamp(),
              caja: obj.CAJA,
              splitter: obj.SPLITTER,
              nodo: obj.NODO,
              nivel: obj.NIVEL,
              modelo: obj.MODELO,
              longitude: obj.LONGITUD,
              latitude: obj.LATITUD,
              canton: obj.CANTON,
              sector: obj.SECTOR,
              provincia: obj.PROVINCIA,
              created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((docRef) => {
              console.log("CAJA: ", docRef);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
        Swal.fire({
          icon: "success",
          title: "Subiendo cajas, esto tardara un poco...",
        });
        setOpen(false);
      } else {
        console.log("No se cargado ningun archivo");
      }
    } catch (error) {
      console.log("error", error);
      setMessage(error.toString());
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log({
      caja: caja.campo,
      splitter: splitter,
      nodo: nodo,
      nivel: nivel,
      modelo: modelo,
      longitude: parseFloat(longitude.campo),
      latitude: parseFloat(latitude.campo),
      canton: geografico.canton,
      sector: geografico.sector,
      provincia: geografico.provincia,
    });
    try {
      const doc = db
        .collection("cajas")
        .doc(caja.campo)
        .set({
          CAJA: caja.campo,
          SPLITTER: splitter,
          NODO: nodo,
          NIVEL: nivel,
          MODELO: modelo,
          LONGITUDE: parseFloat(longitude.campo),
          LATITUDE: parseFloat(latitude.campo),
          CANTON: geografico.canton,
          SECTOR: geografico.sector,
          PROVINCIA: geografico.provincia,
          CREATED: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          Swal.fire({
            icon: "info",
            title: "Se creo nueva caja con exito!",
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "warning",
            title: "Se produjo un error al crear la caja.",
          });
          console.error("Error writing document: ", error);
        });
    } catch (error) {
      try {

            const doc = db.collection("cajas").doc(caja.campo).set({
                CAJA: caja.campo,
                SPLITTER: splitter,
                NODO: nodo,
                NIVEL: nivel,
                MODELO: modelo,
                LONGITUDE: parseFloat(longitude.campo),
                LATITUDE: parseFloat(latitude.campo),
                CANTON: geografico.canton,
                SECTOR: geografico.sector,
                PROVINCIA: geografico.provincia,
                CREATED: firebase.firestore.FieldValue.serverTimestamp(),
            })
                .then(() => {
                    Swal.fire({
                        icon: 'info',
                        title: 'Se creo nueva caja con exito!',
                    })
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Se produjo un error al crear la caja.',
                    })
                    console.error("Error writing document: ", error);
                });


        } 
    }
  };
  useEffect(() => {
    dispatch(listsParameters());
    dispatch(listsSplitter());
    dispatch(listsModelos());
    dispatch(listsNivel());
    dispatch(listsNodos());
    if (!loading) {
      console.log(parameters, "cargo");
    }
  }, [dispatch]);
  return (
    <>
      <Box pb={5}>
        <Typography>Subir cajas</Typography>
        <Divider />
      </Box>
      <Button onClick={() => setOpen(true)}>Cargar excel</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Subir cajas</DialogTitle>
        <DialogContent>
          <Box pb={2}>
            {fileName
              ? `Nombre el archivo cargado:  ${fileName}`
              : "Por favor, cargar el archivo..."}
          </Box>
          {!file ? (
            <>
              <input
                style={{ display: "none" }}
                onChange={(e) => handleUploadFileLocal(e)}
                multiple
                id="buttonFile"
                type="file"
                ref={fileRef}
              />
              <label htmlFor="buttonFile">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component="span"
                >
                  Cargar archivo
                </Button>
              </label>
            </>
          ) : (
            <Box>
              <Box display="flex">
                <Box pr={5}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleRemove()}
                  >
                    Borrar archivo
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={handleUploadFile}
                    variant="contained"
                    color="primary"
                  >
                    Subir archivo
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Box py={5}>
        <Typography> Nueva caja</Typography>
        <Divider />
      </Box>
      {!loading ? (
        <form onSubmit={submitHandler}>
          <Grid container>
            <Grid item xs={4}>
              <InputValidation
                estado={caja}
                cambiarEstado={setCaja}
                label="CAJA"
                name="name"
                helperText="Minimo 8 caracteres"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>SPLITTER</InputLabel>
                  <Select
                    label="SPLITTER"
                    value={splitter}
                    onChange={(e) => setSplitter(e.target.value)}
                  >
                    {splitter_List &&
                      splitter_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>NIVEL</InputLabel>
                  <Select
                    label="NIVEL"
                    value={nivel}
                    onChange={(e) => setNivel(e.target.value)}
                  >
                    {nivel_list &&
                      nivel_list.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>MODELO</InputLabel>
                  <Select
                    label="MODELO"
                    value={modelo}
                    onChange={(e) => setModelo(e.target.value)}
                  >
                    {modelo_List &&
                      modelo_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>NODO</InputLabel>
                  <Select
                    label="NODO"
                    value={nodo}
                    onChange={(e) => setNodo(e.target.value)}
                  >
                    {nodo_List &&
                      nodo_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <InputValidation
                estado={longitude}
                cambiarEstado={setLongitude}
                label="LONGITUD"
                name="name"
                helperText="Minimo 8 caracteres"
              />
              <InputValidation
                estado={latitude}
                cambiarEstado={setLatitude}
                label="LATITUD"
                name="name"
                helperText="Minimo 8 caracteres"
              />
            </Grid>
            <Grid item xs={4}>
              <SelectGeografico cambiarEstado={setGeografico} />
            </Grid>
          </Grid>
          <Box display="flex">
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </Box>
        </form>
      ) : (
        <Loader />
      )}
    </>
  );
};
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { db, firebase } from "../../firebase";
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import InputValidation from "../utils/InputValidation";
import {
  listsModelos,
  listsNivel,
  listsNodos,
  listsParameters,
  listsSplitter,
} from "../../redux/actions/parametersActions";
import Loader from "../utils/Loader";
import SelectGeografico from "../utils/SelectGeograficos";

export const UploadBox = () => {
  const dispatch = useDispatch();
  const parametersList = useSelector((state) => state.parametersList);
  const { parameters, loading } = parametersList;

  const splitterList = useSelector((state) => state.splitterList);
  const { splitter: splitter_List } = splitterList;

  const nivelList = useSelector((state) => state.nivelList);
  const { nivel: nivel_list } = nivelList;

  const nodoList = useSelector((state) => state.nodoList);
  const { nodos: nodo_List } = nodoList;

  const modeloList = useSelector((state) => state.modeloList);
  const { modelos: modelo_List } = modeloList;

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [caja, setCaja] = useState({ campo: "", valido: true });
  const [splitter, setSplitter] = useState("");
  const [nodo, setNodo] = useState({ campo: "", valido: true });
  const [nivel, setNivel] = useState("");
  const [modelo, setModelo] = useState("");
  const [potencia, setPotencia] = useState("");
  const [longitude, setLongitude] = useState({ campo: "", valido: true });
  const [latitude, setLatitude] = useState({ campo: "", valido: true });
  const fileRef = useRef();

  //
  const [geografico, setGeografico] = useState("");
  //Paramets
  const handleUploadFileLocal = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  };
  const handleRemove = () => {
    setFileName(null);
    setFile(null);
    fileRef.current.value = "";
  };

  const UpdateCajas = () => {
    /* db.collection('cajas').onSnapshot(snapshot => {
      const data = snapshot.docs.map(doc => {
        const { latitude, longitude } = doc.data();
      });
    }); */
    let ref = db.collection('cajas').limit(2)
    ref.onSnapshot(snapshot => {
      const data = [
        ...snapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      ];
      data.forEach((obj) => {
        console.log(obj.longitude)
        let longitude = parseFloat(obj.longitude);
        let formattedLongitude = longitude.toFixed(6).replace('.', '').replace(/(\d{3})/g, '$1.').replace(/(\.0+)$/, '').replace(/\.$/, '');
        console.log('formacion longitude', formattedLongitude)
        /* db.collection('cajas').doc(obj.caja).update({
          latitude: Number(obj.latitude).toFixed(2) + '.'
        }) */
      })
    })
  }

  const handleUploadFile = async () => {
    try {
      if (file) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        jsonData.forEach((obj) => {
          db.collection("cajas")
            .doc(obj.CAJA)
            .set({
              /* ...obj, */
              created: firebase.firestore.FieldValue.serverTimestamp(),
              caja: obj.CAJA === '' || obj.CAJA === undefined ? '' : obj.CAJA,
              splitter: obj.SPLITTER === '' || obj.SPLITTER === undefined ? '' : obj.SPLITTER.toString(),
              nodo: obj.NODO === '' || obj.NODO === undefined ? '' : obj.NODO,
              nivel: obj.NIVEL === '' || obj.NIVEL === undefined ? '' : obj.NIVEL,
              modelo: obj.MODELO === '' || obj.MODELO === undefined ? '' : obj.MODELO,
              longitude: obj.LONGITUD === '' || obj.LONGITUD === undefined ? '' : obj.LONGITUD,
              latitude: obj.LATITUD === '' || obj.LATITUD === undefined ? '' : obj.LATITUD,
              canton: obj.CANTON === '' || obj.CANTON === undefined ? '' : obj.CANTON,
              sector: obj.SECTOR === '' || obj.SECTOR === undefined ? '' : obj.SECTOR,
              provincia: obj.PROVINCIA === '' || obj.PROVINCIA === undefined ? '' : obj.PROVINCIA,
              potencia: obj.POTENCIA === '' || obj.POTENCIA === undefined ? '' : obj.POTENCIA,
            })
            .then((docRef) => {
              console.log("CAJA: ", docRef);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
        Swal.fire({
          icon: "success",
          title: "Subiendo cajas, esto tardara un poco...",
        });
        setOpen(false);
      } else {
        console.log("No se cargado ningun archivo");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log({
      caja: caja.campo,
      splitter: splitter,
      nodo: nodo,
      nivel: nivel,
      modelo: modelo,
      longitude: parseFloat(longitude.campo),
      latitude: parseFloat(latitude.campo),
      canton: geografico.canton,
      sector: geografico.sector,
      provincia: geografico.provincia,
      potencia: potencia
    });
    try {
      db.collection("cajas")
        .doc(caja.campo)
        .set({
          caja: caja.campo,
          splitter: splitter.toString(),
          nodo: nodo,
          nivel: nivel,
          modelo: modelo,
          longitude: parseFloat(longitude.campo),
          latitude: parseFloat(latitude.campo),
          canton: geografico.canton,
          sector: geografico.sector,
          provincia: geografico.provincia,
          potencia: potencia,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          Swal.fire({
            icon: "info",
            title: "Se creo nueva caja con exito!",
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "warning",
            title: "Se produjo un error al crear la caja.",
          });
          console.error("Error writing document: ", error);
        });
    } catch (error) {
      /* try {

            const doc = db.collection("cajas").doc(caja.campo).set({
                CAJA: caja.campo,
                SPLITTER: splitter,
                NODO: nodo,
                NIVEL: nivel,
                MODELO: modelo,
                LONGITUDE: parseFloat(longitude.campo),
                LATITUDE: parseFloat(latitude.campo),
                CANTON: geografico.canton,
                SECTOR: geografico.sector,
                PROVINCIA: geografico.provincia,
                CREATED: firebase.firestore.FieldValue.serverTimestamp(),
            })
                .then(() => {
                    Swal.fire({
                        icon: 'info',
                        title: 'Se creo nueva caja con exito!',
                    })
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Se produjo un error al crear la caja.',
                    })
                    console.error("Error writing document: ", error);
                });


        } */ console.log(error);
    }
  };

  useEffect(() => {
    dispatch(listsParameters());
    dispatch(listsSplitter());
    dispatch(listsModelos());
    dispatch(listsNivel());
    dispatch(listsNodos());
    if (!loading) {
      console.log(parameters, "cargo");
    }
  }, [dispatch]);

  return (
    <>
      <Box pb={2}>
        <Typography>Subir cajas</Typography>
        <Divider />
      </Box>
      <Button color="primary" variant="contained" size="small" onClick={() => setOpen(true)}>Cargar excel</Button>
      {/* <Button onClick={() => UpdateCajas()}>Actualizar cajas</Button> */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Subir cajas</DialogTitle>
        <DialogContent>
          <Box pb={2}>
            {fileName
              ? `Nombre el archivo cargado:  ${fileName}`
              : "Por favor, cargar el archivo..."}
          </Box>
          {!file ? (
            <>
              <input
                style={{ display: "none" }}
                onChange={(e) => handleUploadFileLocal(e)}
                multiple
                id="buttonFile"
                type="file"
                ref={fileRef}
              />
              <label htmlFor="buttonFile">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component="span"
                >
                  Cargar archivo
                </Button>
              </label>
            </>
          ) : (
            <Box>
              <Box display="flex">
                <Box pr={5}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleRemove()}
                  >
                    Borrar archivo
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={handleUploadFile}
                    variant="contained"
                    color="primary"
                  >
                    Subir archivo
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <Box py={5}>
        <Typography> Nueva caja</Typography>
        <Divider />
      </Box>
      {!loading ? (
        <form onSubmit={submitHandler}>
          <Grid container>
            <Grid item xs={4}>
              <InputValidation
                estado={caja}
                cambiarEstado={setCaja}
                label="CAJA"
                name="name"
                helperText="Minimo 8 caracteres"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>SPLITTER</InputLabel>
                  <Select
                    label="SPLITTER"
                    value={splitter}
                    onChange={(e) => setSplitter(e.target.value)}
                  >
                    {splitter_List ?
                      splitter_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      )) : <></>}
                  </Select>
                </FormControl>
              </Box>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>NIVEL</InputLabel>
                  <Select
                    label="NIVEL"
                    value={nivel}
                    onChange={(e) => setNivel(e.target.value)}
                  >
                    {nivel_list ?
                      nivel_list.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      )) : <></>}
                  </Select>
                </FormControl>
              </Box>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>MODELO</InputLabel>
                  <Select
                    label="MODELO"
                    value={modelo}
                    onChange={(e) => setModelo(e.target.value)}
                  >
                    {modelo_List ?
                      modelo_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      )) : <></>}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box pb={3}>
                <FormControl fullWidth>
                  <InputLabel>NODO</InputLabel>
                  <Select
                    label="NODO"
                    value={nodo}
                    onChange={(e) => setNodo(e.target.value)}
                  >
                    {nodo_List ?
                      nodo_List.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.id}
                        </MenuItem>
                      )) : <></>}
                  </Select>
                </FormControl>
              </Box>
              <InputValidation
                estado={longitude}
                cambiarEstado={setLongitude}
                label="LONGITUD"
                name="name"
                helperText="Minimo 8 caracteres"
              />
              <InputValidation
                estado={latitude}
                cambiarEstado={setLatitude}
                label="LATITUD"
                name="name"
                helperText="Minimo 8 caracteres"
              />
              <TextField
                label='POTENCIA'
                value={potencia}
                onChange={(e) => setPotencia(e.target.value)}
                name="potencia"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <SelectGeografico cambiarEstado={setGeografico} />
            </Grid>
          </Grid>
          <Box display="flex">
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </Box>
        </form>
      ) : (
        <Loader />
      )}
    </>
  );
};
