import {
    Box,
    Paper,
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableHead,
    TableContainer,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Autocomplete,
    IconButton,
    Select,
    MenuItem,
    Tooltip
} from "@mui/material"

import Swal from "sweetalert2";

import { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../firebase";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import moment from 'moment'

import {
    listsBodegas
} from "../../../redux/actions/parametersActions";

import EditIcon from '@mui/icons-material/Edit';

export const EditItemsKitInventario = ({ props }) => {

    const [data, setData] = useState([]);

    const [openModal, setOpenModal] = useState(false);

    const userAuth = useSelector((state) => state.userAuth);
    const { loadin, userInfo } = userAuth;

    const bodegasList = useSelector((state) => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList;
    const [bodega, setBodegas] = useState(props.bodega);

    const [nombre, setNombre] = useState(props.nombre);

    const [modalConfirmKit, setModalConfirmKit] = useState(false);

    const [observacion, setObservacion] = useState(props.observacion);

    const [numKit, setNumKit] = useState(0);

    const getNumberKit = () => {
        const ref = db.collection("kits_inventario").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_kit + 1;
                const uidSolicitud = `KIT-` + numeroSolicitud;
                setNumKit(numeroSolicitud)
            }
        })
    }

    const getProductos = () => {
        var docRef = db.collection("productos");
        docRef
            .get()
            .then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map((item) => {
                    return { ...item.data(), id: item.id };
                });
                setProductos(arrayMessage);
            })
            .catch((error) => {
                console.log("Error getting document:", error);
            });
    };

    const openModalConfirmUpdate = () => {
        setModalConfirmKit(true)
        setOpenModal(false)
    }

    const closeModalConfirmUpdate = () => {
        setOpenModal(true)
        setModalConfirmKit(false)
    }

    const dispatch = useDispatch()

    const getItems = () => {
        let ref = db.collection('kits_inventario').doc(props.uid_kit).collection('list')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setInputFields([...data])
        })
    }

    useEffect(() => {
        getNumberKit()
        getProductos()
        getItems()
        dispatch(listsBodegas())
    }, [dispatch]);

    const [productos, setProductos] = useState("");

    const [item, setItem] = useState("");

    const [modal, setModal] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);

    const [inputFields, setInputFields] = useState([]);

    const addInput = (row) => {
        console.log(row, "Item agregado");
        if (inputFields.find((x) => x.codigo_producto === row.codigo_producto)) {
            Swal.fire({
                icon: "info",
                text: "Ya se agrego este producto a la lista",
            });
        } else {
            setInputFields([
                ...inputFields,
                {
                    codigo_producto: row.codigo_producto,
                    descripcion: row.descripcion_producto,
                    modelo_producto: row.modelo_producto,
                    categoria_producto: row.categoria_producto,
                    subcategoria_producto: row.subcategoria_producto,
                    marca_producto: row.marca_producto,
                    modelo_producto: row.modelo_producto,
                    unidad_medida_producto: row.unidad_Medida_Producto,
                },
            ]);
        }
        setItem("");
        setModal(false);
    };

    const removeInputFields = (codigo_producto) => {
        /* const rows = [...inputFields];
            rows.splice(index, 1);
            setInputFields(rows); */

        /* const rows = [...inputFields];
            rows.splice(codigo, 1);
            setInputFields(rows); */

        console.log(codigo_producto);

        const rows = [...inputFields];

        const indexOfObject = rows.findIndex((object) => {
            return object.codigo_producto === codigo_producto;
        });

        console.log(indexOfObject);

        rows.splice(indexOfObject, 1);

        setInputFields(rows);

        console.log(rows);
    };

    const handleChange = async (index, evnt) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                //
                setInputFields(list);
                //console.log(inputFields, 'array')
            } else {
                const list = [...inputFields];
                list[index]["codigo_producto"] = evnt;
                setInputFields(list);
            }
        }
    };

    function detalleMate(data, index) {
        return (
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell style={{ fontSize: "12px" }} component="th" scope="row">
                    {index + 1}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.codigo_producto}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.categoria_producto}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.subcategoria_producto}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.descripcion}
                </TableCell>
                <TableCell align="center">
                    <TextField
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        name={"cantidad"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad}
                        size="small"
                        type="number"
                    />
                </TableCell>

                <TableCell align="center">
                    <IconButton
                        onClick={() => removeInputFields(data.codigo_producto)}
                        color="error"
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    const updateKit = () => {
        db.collection('kits_inventario').doc(props.uid_kit).update({
            nombre: nombre.toUpperCase(),
            bodega: bodega,
            observacion: observacion.toUpperCase(),
            actualizado_por: userInfo.displayName,
            fecha_actualizacion: firebase.firestore.FieldValue.serverTimestamp(),
        })

        console.log('INPUT FIELDS', inputFields)

        inputFields.forEach(async function (obj, index) {
            db.collection('kits_inventario').doc(props.uid_kit).collection('list').doc(obj.codigo_producto).set({
                codigo_producto: obj.codigo_producto,
                descripcion: obj.descripcion,
                modelo_producto: obj.modelo_producto,
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: obj.subcategoria_producto,
                marca_producto: obj.marca_producto,
                modelo_producto: obj.modelo_producto,
                unidad_medida_producto: obj.unidad_medida_producto,
                cantidad: obj.cantidad
            })
        });
    }

    const handleSubmit = () => {

        updateKit()
        setModalConfirmKit(false)
        Swal.fire({
            icon: "success",
            text: "El kit se ha actualizado con exito",
        });

        /* setNombre('')
        setObservacion('')
        setBodegas('')
        setInputFields([]) */

    }

    return (
        <>
            <Tooltip title="EDITAR">
                <Button onClick={() => setOpenModal(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* DIALOG CUENTAS*/}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                <DialogTitle>EDICION <strong>{props.uid_kit}</strong></DialogTitle>

                <DialogContent>

                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>


                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                        name="nombre"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>OBSERVACION:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={observacion}
                                            onChange={(e) => setObservacion(e.target.value)}
                                            name="observacion"
                                            size="small"
                                            fullWidth
                                        /* multiline
                                        rows={2} */
                                        />
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>BODEGA:</strong></p>
                                    <Select
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                        fullWidth
                                        size="small"
                                        value={bodega}
                                        label="MOTIVO"
                                        onChange={(e) => setBodegas(e.target.value)}
                                    >
                                        {bodegas &&
                                            bodegas.map((item) => (
                                                <MenuItem
                                                    key={item.nombre_bodega}
                                                    value={item.nombre_bodega}
                                                >
                                                    {item.nombre_bodega}
                                                </MenuItem>
                                            ))}
                                    </Select>

                                    {/* <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>USUARIO:</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value)}
                                            name="nombre"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box> */}

                                </Grid>

                            </Grid>

                            <Box pb={2}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        disabled={
                                            nombre === ''
                                            || bodega === ''
                                        }
                                        onClick={() => setModal(true)}
                                        variant="outlined"
                                        size="small"
                                        color="secondary">
                                        Agregar items
                                    </Button>
                                </Grid>
                            </Box>


                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NO.</TableCell>
                                            <TableCell align="center" style={{ fontSize: "12px" }}>
                                                <strong>CODIGO</strong>
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "12px" }}>
                                                <strong>CATEGORIA</strong>
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "12px" }}>
                                                <strong>SUBCATEGORIA</strong>
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "12px" }}>
                                                <strong>DESCRIPCION</strong>
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                style={{ width: "160px", fontSize: "12px" }}
                                            >
                                                <strong>CANTIDAD</strong>
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "12px" }}>
                                                <strong>ELIMINAR</strong>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {inputFields.map((data, index) => {
                                            return detalleMate(data, index);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/* <Box pt={2}>
                                <Button
                                    disabled={
                                        nombre === ''
                                        || inputFields.length < 2
                                    }
                                    onClick={() => setModalConfirmKit(true)}
                                    onClick={() => console.log(inputFields)}
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    fullWidth>
                                    CREAR KIT
                                </Button>
                            </Box> */}

                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>

                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    <Button
                        disabled={
                            nombre === ''
                            || inputFields.length < 2
                        }
                        onClick={() => openModalConfirmUpdate()/* setModalConfirmKit(true) */}
                        /* onClick={() => console.log(inputFields)} */
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        ACTUALIZAR KIT
                    </Button>

                </DialogActions>

            </Dialog>

            {/* DIALOG SELECT PRODUCTS */}

            <Dialog open={modal} maxWidth="md" fullWidth>
                <DialogTitle>AGREGAR ITEMS</DialogTitle>
                <DialogContent dividers>
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={productos}
                            disableClearable
                            clearOnBlur={false}
                            autoComplete={false}
                            getOptionLabel={(option) => option.codigo_producto}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item);
                                setItem(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="BUSCAR PRODUCTO" />
                            )}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CODIGO</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productos &&
                                    productos
                                        .filter((val) => {
                                            if (item === "") {
                                                return val;
                                            } else if (
                                                val.codigo_producto
                                                    .toLowerCase()
                                                    .includes(item.toLowerCase())
                                            ) {
                                                return val;
                                            }
                                        })
                                        .map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell>{row.codigo_producto}</TableCell>
                                                <TableCell>{row.descripcion_producto/* descripcion */}</TableCell>

                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => addInput(row)}
                                                    >
                                                        Agregar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions dividers>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setModal(false)}
                    >
                        Cerrar Ventana
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG CONFIRM KIT */}

            <Dialog open={modalConfirmKit} maxWidth="sm" fullWidth>
                <DialogTitle>¿ESTA SEGURO DE ACTUALIZAR EL <strong>{props.uid_kit}</strong>?</DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions dividers>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => closeModalConfirmUpdate()/* setModalConfirmKit(false) */}
                    >
                        REGRESAR
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSubmit()/* setModalConfirmKit(false) */}
                    >
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
