import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    FormHelperText,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
    InputAdornment
} from '@mui/material'
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { db, firebase } from '../../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { ConvertRoles } from '../../../components/config/ConvertRoles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { Grade, SettingsAccessibilityOutlined } from '@mui/icons-material';

import {
    listsBodegas,
    listsUbicaciones,
    listsMotivoIngresoProductos
} from "../../../redux/actions/parametersActions";

export const TransferirActivos = () => {

    const [categorias, setCategorias] = useState('')

    const [categoriaActivoFijo, setCategoriaActivoFijo] = useState('')

    const [itemCategorias, setItemcategorias] = useState('')

    const [categoria, setCategoria] = useState('')

    const [activosFijosCategorias, setActivosFijosCategorias] = useState('')

    const [activosFijos, setActivosFijos] = useState('')

    const [activosFijo, setActivosFijo] = useState('')

    const [origenBodegas, setOrigenBodegas] = useState('')

    const [origenResponsables, setOrigenResponsables] = useState('')

    const [destinoBodegas, setDestinoBodegas] = useState('')

    const [destinoResponsables, setDestinoResponsables] = useState('')

    const [observaciones, setObservaciones] = useState('')

    const [motivo, setMotivo] = useState('')

    const [usuarios, setUsuarios] = useState('')

    const [departamentos, setDepartamentos] = useState('')

    const [users, setUsers] = useState('')

    const [itemUsuarios, setItemUsuarios] = useState('')

    const [itemDepartamentos, setItemDepartamentos] = useState('')

    const [itemActivosFijos, setItemActivosFijos] = useState('')

    const [openDialogUsuariosOrigen, setOpenDialogUsuariosOrigen] = useState(false);

    const [openDialogUsuariosDestino, setOpenDialogUsuariosDestino] = useState(false);

    const [openDialogDepartamentos, setOpenDialogDepartamentos] = useState(false);

    const [openDialogActivoFijo, setOpenDialogActivoFijo] = useState(false);

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

    const getCategorias = () => {
        var docRef = db.collection("activos_fijos_parametros").doc("categoria_activos").collection("list")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setCategorias(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getUsers = () => {
        var docRef = db.collection("usuarios")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setUsuarios(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getDepartamentos = () => {
        var docRef = db.collection("activos_fijos_parametros").doc('departamento').collection('list')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setDepartamentos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getActivosFijos = () => {
        var docRef = db.collection("activos_fijos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setActivosFijos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getActivosFijosCategories = () => {
        var docRef = db.collection("activos_fijos").where('categoria', '==', categoria)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setActivosFijosCategorias(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getCategoriaActivoFijo = (e) => {
        let ref = db.collection('activos_fijos').where('categoria', '==', e)
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setCategoriaActivoFijo(data)
        })
    }

    const addCountUsersOrigen = (row) => {
        console.log(row.nombres)
        setOrigenResponsables(row.nombres)
        setOpenDialogUsuariosOrigen(false)
    }

    const addCountUsersDestino = (row) => {
        console.log(row.nombres)
        setDestinoResponsables(row.nombres)
        setOpenDialogUsuariosDestino(false)
    }

    const addDepartamento = (row) => {
        console.log(row.departamento)
        setDestinoBodegas(row.departamento)
        setOpenDialogDepartamentos(false)
    }

    const addActivoFijoCategorias = (row) => {
        console.log(row.categoria_activos)
        setCategoria(row.categoria_activos)
        setOpenDialogActivoFijo(false)
    }

    const [observacionAdicional, setObservacionAdicional] = useState("");

    const bodegasList = useSelector((state) => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList;
    const [bodega, setBodegas] = useState("");
    const [bodegaDestino, setBodegaDestino] = useState("");



    let navigate = useNavigate()
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [numPedido, setNumPedido] = useState(false)
    const [productos, setProductos] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    //
    const [modal, setModal] = useState(false)
    //
    //
    const [inputFields, setInputFields] = useState([]);
    //
    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }
    const addInput = (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else {
            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 0,
                descripcion_producto: row.descripcion_producto,
                codigo: row.codigo_producto,
                categoria: row.categoria,
                sub_categoria: row.sub_categoria,
                serie: 0
            }])
        }
        setItem('')
        setModal(false)
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    const handleChange = (index, evnt) => {
        //console.log(typeof evnt.target.value, 'items')
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                setInputFields(list);
            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }

    const getProductos = (e) => {
        let ref = db.collection("reporte_stock").where('bodega', '==', e)

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setProductos(data)
        })
        /* var docRef = db.collection("reporte_stock").where('bodega', '==', bodega)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
    }

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        getUsers()
        getDepartamentos()
        getActivosFijos()
        getCategorias()
        getActivosFijosCategories()
        dispatch(listsBodegas());
    }, []);

    const handleChangeSerie = (index, evnt) => {
        //console.log(typeof evnt.target.value, 'items')
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                setInputFields(list);
            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }

    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            var docRef = db.collection("activos_fijos").where("codigo_producto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcionProducto
                    console.log(data.codigo)
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
            console.log(inputFields, 'da')
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">
                    {data.fullName}
                </TableCell>
                <TableCell align="center">{data.categoria}</TableCell>
                <TableCell align="center">{data.sub_categoria}</TableCell>
                <TableCell align="center">{data.descripcion_producto}</TableCell>
                <TableCell align="center">
                    <TextField
                        name={"cantidad_pedida"}
                        onChange={(e) => {
                            handleChange(index, e)
                            console.log(inputFields)
                        }}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center">
                    <TextField
                        name={"serie"}
                        onChange={(e) => {
                            handleChangeSerie(index, e)
                            console.log(inputFields)
                        }}
                        value={data.serie}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    //
    const loadNumPedido = () => {
        const ref = db.collection("transferencia_activos_fijos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `TRAN-ACT` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }
    const handleSubmit = () => {

        console.log('ORIGEN BODEGAS', origenBodegas)
        console.log('DESTINO BODEGAS', destinoBodegas)
        console.log('ORIGEN RESPONSABLE', origenResponsables)
        console.log('DESTINO RESPONSABLE', destinoResponsables)
        console.log('OBSERVACIONES', observaciones)
        console.log('MOTIVO', motivo)
        console.log('ACTIVO FIJO', activosFijo)
        console.log('NUM PEDIDO', numPedido)
        //
        /* const uidSolicitud = `SO-TRAN-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }
        console.log(data, 'DATA')
        console.log(inputFields, 'INPUT FIELDS')

        console.log(bodegaDestino, 'BODEGA DESTINO') */
        //

        inputFields.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            db.collection('transferencia_activos_fijos').doc(`TRAN-ACT-${numPedido}`).set({
                created: firebase.firestore.FieldValue.serverTimestamp(),
                numeroSolicitud: numPedido,
                uidSolicitud: `TRAN-ACT-${numPedido}`,
                origenBodegas: origenBodegas,
                destinoBodegas: destinoBodegas,
                origenResponsables: origenResponsables,
                destinoResponsables: destinoResponsables,
                observaciones: observaciones.toUpperCase(),
                motivo: motivo.toUpperCase(),
                activosFijo: activosFijo,
                numPedido: numPedido,
                estado: 0
            })

            db.collection('transferencia_activos_fijos').doc(`TRAN-ACT-${numPedido}`).collection('items').doc(obj.codigo).set({
                cantidad_pedida: obj.cantidad_pedida,
                categoria: obj.categoria,
                codigo: obj.codigo,
                descripcion_producto: obj.descripcion_producto,
                serie: obj.serie,
                sub_categoria: obj.sub_categoria
            })

        });

        setOpenDialogConfirm(false)

        Swal.fire({ icon: "success", text: 'Se ha generado una solicitud de transferencia con exito' });

        setTimeout(() => {
            setOrigenBodegas('')
            setDestinoBodegas('')
            setOrigenResponsables('')
            setDestinoResponsables('')
            setObservaciones('')
            setMotivo('')
            setActivosFijo('')
            setInputFields([])
            setCategoria('')
        }, 2000)

    }

    useEffect(() => {
        loadNumPedido()
    }, [inputFields])



    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>

                        {/* <Grid container spacing={5}>

                            <Grid item xs={6}>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "0px" }}>NO.</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <p style={{ fontSize: "10px", marginBottom: "0px" }}>TRAN-ACT{numPedido}</p>
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={6}>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "0px" }}>FECHA</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <p style={{ fontSize: "10px", marginBottom: "0px" }}>{moment().format('YYYY-MM-D h:mm:ss A')}</p>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid> */}

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                <Grid item xs={6}>

                                    <Grid container spacing={6}>
                                        <Grid item xs={1}>
                                            <p style={{ fontSize: "10px", marginBottom: "0px" }}>NO.</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <p style={{ fontSize: "10px", marginBottom: "0px" }}><strong>TRAN-ACT-{numPedido}</strong></p>
                                        </Grid>
                                    </Grid>


                                </Grid>

                                <Box py={3}>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}><strong>DEPARTAMENTOS / BODEGAS</strong></p>
                                </Box>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>ORIGEN:</p>
                                    </Grid>
                                    <Grid item xs={7}>

                                        <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            fullWidth
                                            size="small"
                                            value={origenBodegas}
                                            label="MOTIVO"
                                            onChange={(e) => setOrigenBodegas(e.target.value)}
                                        >
                                            {bodegas &&
                                                bodegas.map((item) => (
                                                    <MenuItem
                                                        key={item.nombre_bodega}
                                                        value={item.nombre_bodega}
                                                    >
                                                        {item.nombre_bodega}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Grid>
                                </Grid>

                                <Box py={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>DESTINO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                name={"cuenta"}
                                                value={destinoBodegas}
                                                onChange={(e) => setDestinoBodegas(e.target.value)}
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenDialogDepartamentos(true)}>
                                                            <SearchIcon color='primary' />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>

                            <Grid item xs={6}>

                                <Grid item xs={6}>

                                    <Grid container spacing={6}>
                                        <Grid item xs={1}>
                                            <p style={{ fontSize: "10px", marginBottom: "0px" }}>FECHA</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <p style={{ fontSize: "10px", marginBottom: "0px" }}><strong>{moment().format('YYYY-MM-D h:mm:ss A')}</strong></p>
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Box py={3}>
                                    <p style={{ fontSize: "12px", marginBottom: "0px" }}><strong>ASIGNADOS / RESPONSABLES</strong></p>
                                </Box>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>ORIGEN:</p>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            name={"cuenta"}
                                            value={origenResponsables}
                                            onChange={(e) => setOrigenResponsables(e.target.value)}
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setOpenDialogUsuariosOrigen(true)}>
                                                        <SearchIcon color='primary' />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box py={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>DESTINO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                name={"cuenta"}
                                                value={destinoResponsables}
                                                onChange={(e) => setDestinoResponsables(e.target.value)}
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenDialogUsuariosDestino(true)}>
                                                            <SearchIcon color='primary' />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>


                            </Grid>

                        </Grid>



                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={12}>
                                    <Grid item xs={1}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>OBSERVACIONES:</p>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={observaciones}
                                            onChange={(e) => setObservaciones(e.target.value)}
                                            name="observaciones"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <Box py={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>MOTIVO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={motivo}
                                                onChange={(e) => setMotivo(e.target.value)}
                                                name="motivo"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>ACTIVO FIJO:</p>
                                    </Grid>
                                    <Grid item xs={5}>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            name={"categoria"}
                                            value={categoria}
                                            onChange={(e) => setCategoria(e.target.value)}
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setOpenDialogActivoFijo(true)}>
                                                        <SearchIcon color='primary' />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                        {/* <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={motivo}
                                            onChange={(e) => setMotivo(e.target.value)}
                                            name="motivo"
                                            size="small"
                                            fullWidth
                                        /> */}
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={6}>

                                <Button disabled={
                                    origenBodegas === ''
                                    || origenResponsables === ''
                                    || destinoBodegas === ''
                                    || destinoResponsables === ''
                                    || observaciones === ''
                                    || motivo === ''
                                    || categoria === ''
                                }
                                    onClick={() => setModal(true)}
                                    variant='contained'
                                    color='secondary'
                                    size='small'>Agregar Item</Button>

                            </Grid>

                        </Grid>


                        <Box pt={2}>
                            <Paper elevation={3}>
                                <Box py={1} px={1}>
                                    <TableContainer>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            size="small"
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>NO.</TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>CODIGO</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>CATEGORIA</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>SUBCATEGORIA</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>DESCRIPCION</strong>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        style={{ width: "160px", fontSize: "12px" }}
                                                    >
                                                        <strong>CANTIDAD SOLICITADA</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>SERIES</strong>
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "12px" }}>
                                                        <strong>ELIMINAR</strong>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {inputFields.map((data, index) => {
                                                    return detalleMate(data, index);
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Paper>
                        </Box>

                        <Box pt={2}>
                            <Button
                                disabled={
                                    origenBodegas === ''
                                    || origenResponsables === ''
                                    || destinoBodegas === ''
                                    || destinoResponsables === ''
                                    || observaciones === ''
                                    || motivo === ''
                                    || categoria === ''
                                    || inputFields === []
                                }
                                fullWidth
                                variant='contained'
                                color='secondary'
                                size='small'
                                onClick={() => setOpenDialogConfirm(true)}>
                                Enviar
                            </Button>
                        </Box>

                    </Box>
                </Paper>
            </Box>



            {/* DIALOG USUARIOS ORIGEN*/}
            <Dialog open={openDialogUsuariosOrigen} onClose={() => setOpenDialogUsuariosOrigen(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UN RESPONSABLE</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={usuarios}
                                    getOptionLabel={(option) => option.nombres}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        setItemUsuarios(newInputValue)
                                        setUsuarios(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR USUARIO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {usuarios && usuarios
                                        .filter((val) => {
                                            if (itemUsuarios === "") {
                                                return val;
                                            } else if (val.nombres.toLowerCase().includes(itemUsuarios.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.nombres}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.nombres}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCountUsersOrigen(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogUsuariosOrigen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogUsuariosOrigen(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG USUARIOS DESTINO*/}
            <Dialog open={openDialogUsuariosDestino} onClose={() => setOpenDialogUsuariosDestino(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UN RESPONSABLE</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={usuarios}
                                    getOptionLabel={(option) => option.nombres}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        setItemUsuarios(newInputValue)
                                        setUsuarios(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR USUARIO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {usuarios && usuarios
                                        .filter((val) => {
                                            if (itemUsuarios === "") {
                                                return val;
                                            } else if (val.nombres.toLowerCase().includes(itemUsuarios.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.nombres}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.nombres}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCountUsersDestino(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogUsuariosDestino(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogUsuariosDestino(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG DEPARTAMENTOS */}
            <Dialog open={openDialogDepartamentos} onClose={() => setOpenDialogDepartamentos(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UN DEPARTAMENTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={departamentos}
                                    getOptionLabel={(option) => option.departamento}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        setItemDepartamentos(newInputValue)
                                        setDestinoBodegas(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR USUARIO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>DEPARTAMENTO</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {departamentos && departamentos
                                        .filter((val) => {
                                            if (itemDepartamentos === "") {
                                                return val;
                                            } else if (val.departamento.toLowerCase().includes(itemDepartamentos.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.departamento}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.departamento}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addDepartamento(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogDepartamentos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogDepartamentos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG ACTIVO FIJO CATEGORIA */}
            <Dialog open={openDialogActivoFijo} onClose={() => setOpenDialogActivoFijo(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UN ACTIVO FIJO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={categorias}
                                    getOptionLabel={(option) => option.categoria_activos}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        console.log(newInputValue)
                                        setItemcategorias(newInputValue)
                                        setCategoria(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CATEGORIA</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {categorias && categorias
                                        .filter((val) => {
                                            if (itemCategorias === "") {
                                                return val;
                                            } else if (val.categoria_activos.toLowerCase().includes(itemCategorias.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.categoria_activos}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.categoria_activos}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => {
                                                        addActivoFijoCategorias(row)
                                                        getCategoriaActivoFijo(row.categoria_activos)
                                                    }}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogActivoFijo(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogActivoFijo(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL AGREGAR ITEMS */}

            <Dialog open={modal} maxWidth="md" fullWidth>
                <DialogTitle>AGREGAR ITEMS</DialogTitle>
                <DialogContent dividers>
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={categoriaActivoFijo}
                            disableClearable
                            clearOnBlur={false}
                            autoComplete={false}
                            getOptionLabel={(option) => option.codigo_producto}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item);
                                setItemActivosFijos(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="BUSCAR ACTIVO" />
                            )}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CODIGO</TableCell>
                                    <TableCell>CATEGORIA</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categoriaActivoFijo &&
                                    categoriaActivoFijo
                                        .filter((val) => {
                                            if (itemActivosFijos === "") {
                                                return val;
                                            } else if (
                                                val.codigo_producto
                                                    .toLowerCase()
                                                    .includes(itemActivosFijos.toLowerCase())
                                            ) {
                                                return val;
                                            }
                                        })
                                        .map((row) => (
                                            <TableRow
                                                key={row.codigo_producto}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell>{row.codigo_producto}</TableCell>
                                                <TableCell>{row.categoria}</TableCell>
                                                <TableCell>{row.descripcion_producto/* descripcion */}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => addInput(row)}
                                                    >
                                                        Agregar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions dividers>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setModal(false)}
                    >
                        Cerrar Ventana
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL CONFIRMACION DE TRANSFERENCIA */}

            <Dialog open={openDialogConfirm} onClose={() => setOpenDialogConfirm(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>¿ESTA SEGURO DE REALIZAR LA TRANSFERENCIA?</strong></DialogTitle>

                <DialogActions dividers>
                    <Button
                        size="small"
                        variant="outlined"
                        color='error'
                        onClick={() => setOpenDialogConfirm(false)}
                    >
                        Regresar
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color='secondary'
                        onClick={handleSubmit}
                    >
                        CONFIRMAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}