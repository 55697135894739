import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
export const ListCodigosOtp = () => {
    const userAuth = useSelector(state => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'codigo_otp']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <QrCodeScannerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Codigos OTP" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <ListItemButton dense component={RouterLink} to={`codigos/codigosOtp/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion de Codigos" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}