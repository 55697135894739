import {
     Stack, Card
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ServiciosInternet } from "./ServiciosInternet";
import { ServiciosRecurrentes } from "./ServiciosRecurrentes";
export const ClientesServicios = () => {
    return (
        <Card>
            <Stack spacing={2}>
                <ServiciosInternet />
                {/* <Box py={2} px={1}>
                <Paper elevation={1}>
                    <Box py={1} px={2}>
                        <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>EQUIPOS ASIGNADOS</p>
                    </Box>
                    <Box sx={{ height: 300, width: '100%' }} pb={2} px={2}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            getRowId={(row) => row.idservicio}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>
            <Box py={2} px={1}>
                <Paper elevation={1}>
                    <Box py={1} px={2}>
                        <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>SERVICIOS VOIP</p>
                    </Box>
                    <Box sx={{ height: 300, width: '100%' }} pb={2} px={2}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            getRowId={(row) => row.idservicio}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box> */}
                <ServiciosRecurrentes />
                {/* <Box py={2} px={1}>
                <Paper elevation={1}>
                    <Box py={1} px={2}>
                        <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>PRODUCTOS Y OTROS SERVICIOS</p>
                    </Box>
                    <Box sx={{ height: 300, width: '100%' }} pb={2} px={2}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            getRowId={(row) => row.idservicio}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {

                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box> */}
            </Stack>
        </Card>
    )
}