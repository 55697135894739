import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import EditIcon from '@mui/icons-material/Edit';

export const ObservacionExtraPyl = ({ props }) => {
    const [open, setOpen] = useState(null);

    const [observacionExtraPyl, setObservacionExtraPyl] = useState(props.observacion_extra_pyl);

    const onData = () => {
        setOpen(true)
    }

    const setObservacionExtraPylFirebase = async () => {

        await db.collection('tickets').doc(props.id).update({
            observacion_extra_pyl: observacionExtraPyl.toUpperCase()
        })

        /* await db.collection('parameters').doc('forma_pago_rubro').collection('formas_pago').doc(props.id).delete().then(() => {

            Swal.fire({ icon: "success", text: "Se ha eliminado el metodo de pago con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); }); */
        setOpen(false)
        Swal.fire({ icon: "success", text: "Observacion enviada con exito", });
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <EditIcon color="secondary" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>AGREGUE UNA OBSERVACION EXTRA</strong>
                </DialogTitle>
                <DialogContent>
                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>ESTA OBSERVACION LA PODRA VISUALIZAR EL TECNICO QUE REALIZARA LA VISITA:</strong></p>
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        multiline
                        rows={3}
                        value={observacionExtraPyl}
                        onChange={(e) => setObservacionExtraPyl(e.target.value)}
                        name="observacionExtraPyl"
                        size="small"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button disabled={observacionExtraPyl === ''} onClick={() => {
                        setObservacionExtraPylFirebase()
                    }} variant="contained"
                        size="small"
                        color="secondary"
                    >
                        AGREGAR OBSERVACION
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};