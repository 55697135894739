import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Paper,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Tooltip
} from "@mui/material";
import { DataGrid, esES, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import SettingsIcon from '@mui/icons-material/Settings';


export const NuevoPlan = () => {

    const [nombrePlan, setNombrePlan] = useState('')
    const [codigoPlan, setCodigoPlan] = useState('');
    const [velocidadSubida, setVelocidadSubida] = useState('');

    const [velocidadBajada, setVelocidadBajada] = useState('');

    const [valor, setValor] = useState('');

    const [comparticion, setComparticion] = useState('');
    const [estado, setEstado] = useState('');
    const [tipo, setTipo] = useState('');

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const [data2, setData2] = useState(null);


    useEffect(() => {
        LoadData();
    }, []);

    const onsubmit = async () => {
        db.collection(`perfiles`).doc(data2.id).update(
            {
                configurado: 1,
                comparticion: comparticion,
                valor: parseFloat(valor).toFixed(2),
                estado: estado,
                tipo: tipo,
            }
        ).then(() => {
            Swal.fire({ icon: "success", title: "Plan creado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });

        setVelocidadBajada('')
        setVelocidadSubida('')
        setNombrePlan('')
        setComparticion('')
        setEstado('')
        setTipo('')
        setCodigoPlan('')
        setValor('')
        setOpen(false)

        // await db
        //     .collection("planes")
        //     .add({
        //         nombrePlanes: nombrePlan,
        //         codigoPlan: codigoPlan,
        //         velocidadSubida: velocidadSubida,
        //         velocidadBajada: velocidadBajada,
        //         valor: valor,
        //         comparticion: comparticion,
        //         tipo: tipo,
        //         estado: estado,
        //         created: firebase.firestore.FieldValue.serverTimestamp(),
        //     })
        //     .then(() => {
        //         Swal.fire({ icon: "success", title: "Plan creado con exito" });
        //         setVelocidadBajada('')
        //         setVelocidadSubida('')
        //         setNombrePlan('')
        //         setComparticion('')
        //         setEstado('')
        //         setTipo('')
        //         setCodigoPlan('')
        //         setValor('')
        //     })
        //     .catch((error) => {
        //         Swal.fire({ icon: "error", title: error });
        //     });
    }
    const handleConfig = (row) => {
        setVelocidadBajada(row.velocidadBajada)
        setVelocidadSubida(row.velocidadSubida)
        setNombrePlan(row.plan)
        setCodigoPlan(row.id)
        setData2(row)
        setOpen(true)
    }
    const LoadData = () => {
        let ref = db.collection(`perfiles`)
        ref.onSnapshot((snapshot) => {
            const datos = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        velocidadSubida: doc.data().velocidadSubida,
                        velocidadBajada: doc.data().velocidadBajada
                    };
                }),
            ];
            setData(datos)
        })
    }
    
    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'plan', headerName: 'NOMBRE', width: 400 },
        { field: 'velocidadSubida', headerName: 'VELOCIDAD DE SUBIDA', width: 220 },
        { field: 'velocidadBajada', headerName: 'VELOCIDAD DE BAJADA', width: 220 },
        {
            field: '1', headerName: 'CONFIGURAR', width: 150,
            renderCell: (params) => {
                return <Tooltip title="Editar">
                    <Button onClick={() => handleConfig(params.row)}>
                        <SettingsIcon />
                    </Button>
                </Tooltip>
            }
        },
    ];
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    return (
        <>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={data ? data.filter((val) => {
                        if (!val.configurado) return val
                    }) : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}

                />
            </Box>


            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>CONFIGURAR PLAN</DialogTitle>
                <DialogContent>
                    <Box py={2} px={2}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={7}>
                                    <Grid item xs={10} md={6}>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE DEL PLAN:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombrePlan}
                                            disabled={true}
                                            name="NombreDelPlan"
                                            size="small"
                                            fullWidth
                                        />

                                        <Box py={4}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE SUBIDA:</strong></p>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={velocidadSubida}
                                                disabled={true}
                                                name="VelocidadDeSubida"
                                                size="small"
                                                fullWidth
                                            />
                                        </Box>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VALOR DEL PLAN:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={valor}
                                            onChange={(e) => setValor(e.target.value)}
                                            name="valorDelPlan"
                                            size="small"
                                            fullWidth
                                        />

                                        <Box py={4}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TIPO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select size="small" value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                                    <MenuItem value="COORPORATIVO">COORPORATIVO</MenuItem>
                                                    <MenuItem value="PYMES">PYMES</MenuItem>
                                                    <MenuItem value="HOME">HOME</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>


                                    {/* *********************************** */}
                                    <Grid item xs={10} md={6}>


                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO DEL PLAN:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={codigoPlan}
                                            disabled={true}
                                            name="codigoPlan"
                                            size="small"
                                            fullWidth
                                        />

                                        <Box py={4}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE BAJADA:</strong></p>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={velocidadBajada}
                                                disabled={true}
                                                name="velocidadDeBajada"
                                                size="small"
                                                fullWidth
                                            />
                                        </Box>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>COMPARTICION:</strong></p>
                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                value={comparticion}
                                                onChange={(e) => setComparticion(e.target.value)}
                                            >
                                                <MenuItem value="1:1">1:1</MenuItem>
                                                <MenuItem value="2:1">2:1</MenuItem>
                                            </Select>

                                        </FormControl>

                                        <Box py={4}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select size="small" value={estado} onChange={(e) => setEstado(e.target.value)}>
                                                    <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                                                    <MenuItem value="VIGENTE">VIGENTE</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </Grid>

                                </Grid>

                                <Button
                                    onClick={() => onsubmit()}
                                    disabled={nombrePlan === ''
                                        || codigoPlan === ''
                                        || velocidadBajada === ''
                                        || velocidadSubida === ''
                                        || valor === ''
                                        || comparticion === ''
                                        || estado === ''
                                        || tipo === ''}
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                >
                                    {" "}
                                    CREAR{" "}
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="warning" size="small" onClick={() => setOpen(false)}>Regresar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};









// import React, { useEffect, useState } from "react";
// import { db, storage, firebase } from "../../../firebase";
// import Swal from "sweetalert2";
// import {
//     Grid,
//     Box,
//     InputLabel,
//     Button,
//     Divider,
//     Select,
//     FormControl,
//     MenuItem,
//     CircularProgress,
//     Breadcrumbs,
//     TextField,
//     Paper
// } from "@mui/material";
// import InputValidation from "../../utils/InputValidation";
// export const NuevoPlan = () => {

//     const [nombrePlan, setNombrePlan] = useState('')
//     const [codigoPlan, setCodigoPlan] = useState('');
//     const [velocidadSubida, setVelocidadSubida] = useState('');

//     const [velocidadBajada, setVelocidadBajada] = useState('');

//     const [valor, setValor] = useState('');

//     const [comparticion, setComparticion] = useState('');
//     const [estado, setEstado] = useState('');
//     const [tipo, setTipo] = useState('');

//     const onsubmit = async () => {

//         await db
//             .collection("planes")
//             .add({
//                 nombrePlanes: nombrePlan,
//                 codigoPlan: codigoPlan,
//                 velocidadSubida: velocidadSubida,
//                 velocidadBajada: velocidadBajada,
//                 valor: valor,
//                 comparticion: comparticion,
//                 tipo: tipo,
//                 estado: estado,
//                 created: firebase.firestore.FieldValue.serverTimestamp(),
//             })
//             .then(() => {
//                 Swal.fire({ icon: "success", title: "Plan creado con exito" });
//             })
//             .catch((error) => {
//                 Swal.fire({ icon: "error", title: error });
//             });
//     };

//     return (
//         <>
//             <Box py={2} px={2}>
//                 <Paper elevation={3}>
//                     <Box py={2} px={2}>
//                         <Grid container spacing={7}>
//                             <Grid item xs={10} md={6}>

//                                 <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE DEL PLAN:</strong></p>
//                                 <TextField sx={{
//                                     '& legend': { display: 'none' },
//                                     '& fieldset': { top: 0 },
//                                 }}
//                                     value={nombrePlan}
//                                     onChange={(e) => setNombrePlan(e.target.value)}
//                                     name="NombreDelPlan"
//                                     size="small"
//                                     fullWidth
//                                 />

//                                 <Box py={4}>
//                                     <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE SUBIDA:</strong></p>
//                                     <TextField sx={{
//                                         '& legend': { display: 'none' },
//                                         '& fieldset': { top: 0 },
//                                     }}
//                                         value={velocidadSubida}
//                                         onChange={(e) => setVelocidadSubida(e.target.value)}
//                                         name="VelocidadDeSubida"
//                                         size="small"
//                                         fullWidth
//                                     />
//                                 </Box>

//                                 <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VALOR DEL PLAN:</strong></p>
//                                 <TextField sx={{
//                                     '& legend': { display: 'none' },
//                                     '& fieldset': { top: 0 },
//                                 }}
//                                     value={valor}
//                                     onChange={(e) => setValor(e.target.value)}
//                                     name="valorDelPlan"
//                                     size="small"
//                                     fullWidth
//                                 />

//                                 <Box py={4}>
//                                     <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TIPO:</strong></p>
//                                     <FormControl fullWidth>
//                                         <Select size="small" value={tipo} onChange={(e) => setTipo(e.target.value)}>
//                                             <MenuItem value="COORPORATIVO">COORPORATIVO</MenuItem>
//                                             <MenuItem value="PYMES">PYMES</MenuItem>
//                                             <MenuItem value="HOME">HOME</MenuItem>
//                                         </Select>
//                                     </FormControl>
//                                 </Box>
//                             </Grid>


//                             {/* *********************************** */}
//                             <Grid item xs={10} md={6}>


//                                 <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO DEL PLAN:</strong></p>
//                                 <TextField sx={{
//                                     '& legend': { display: 'none' },
//                                     '& fieldset': { top: 0 },
//                                 }}
//                                     value={codigoPlan}
//                                     onChange={(e) => setCodigoPlan(e.target.value)}
//                                     name="codigoPlan"
//                                     size="small"
//                                     fullWidth
//                                 />

//                                 <Box py={4}>
//                                     <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE BAJADA:</strong></p>
//                                     <TextField sx={{
//                                         '& legend': { display: 'none' },
//                                         '& fieldset': { top: 0 },
//                                     }}
//                                         value={velocidadBajada}
//                                         onChange={(e) => setVelocidadBajada(e.target.value)}
//                                         name="velocidadDeBajada"
//                                         size="small"
//                                         fullWidth
//                                     />
//                                 </Box>

//                                 <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>COMPARTICION:</strong></p>
//                                 <FormControl fullWidth>
//                                     <Select
//                                         size="small"
//                                         value={comparticion}
//                                         onChange={(e) => setComparticion(e.target.value)}
//                                     >
//                                         <MenuItem value="1:1">1:1</MenuItem>
//                                         <MenuItem value="2:1">2:1</MenuItem>
//                                     </Select>

//                                 </FormControl>

//                                 <Box py={4}>
//                                     <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
//                                     <FormControl fullWidth>
//                                         <Select size="small" value={estado} onChange={(e) => setEstado(e.target.value)}>
//                                             <MenuItem value="INACTIVO">INACTIVO</MenuItem>
//                                             <MenuItem value="VIGENTE">VIGENTE</MenuItem>
//                                         </Select>
//                                     </FormControl>
//                                 </Box>

//                             </Grid>

//                         </Grid>

//                         <Button
//                             onClick={() => onsubmit()}
//                             disabled={nombrePlan === ''
//                                 || codigoPlan === ''
//                                 || velocidadBajada === ''
//                                 || velocidadSubida === ''
//                                 || valor === ''
//                                 || comparticion === ''
//                                 || estado === ''
//                                 || tipo === ''}
//                             fullWidth
//                             variant="contained"
//                             color="secondary"
//                         >
//                             {" "}
//                             CREAR{" "}
//                         </Button>
//                     </Box>
//                 </Paper>
//             </Box>
//         </>
//     );
// };
