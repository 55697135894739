import { Table, Box, Button, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from 'moment'
import { useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx/xlsx.mjs'
import { ViewPdfClient } from "./ViewPdfClient";

export const ContratosCliente = () => {
  function Estado(estado) {
    const d = estado.estado
    console.log(d.cambio_domicilio)
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 1) {
      name = 'INSTALADO'
      color = 'success'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }

    return (
      <Chip label={name} color={color} />
    )
  }
  const [data, setData] = useState();
  const [search] = useState('')
  //
  const [startDate] = useState(new Date('2022-06-06'))
  const [endDate] = useState(new Date('2022-07-02'))
  //
  useEffect(() => {
    LoadData();
  }, []);
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("agenda").where("estado", "==", 1)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      // let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      })
    }
  };

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    let date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }

    //
    function instalacion(fechaHora) {

      if (fechaHora ? fechaHora.seconds : false) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }

    }
    const reData = [
      ...data.map(doc => {
        instalacion(doc.fechaHora)
        return {
          CLIENTE: doc.nombres,
          CEDULA: doc.cedula,
          CONTRATO: doc.numContrato,
          PLAN: doc.planNombre,
          VENDEDOR: doc.vendedor,
          CREACION: doc.created.toDate(),
          FECHA_INSTALACION: instalacion(doc.fechaHora),
          GRUPO_VENTA: doc.grupo_venta

        }
      })

    ]
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }
  return (
    <>
      <Typography pt={3} pl={3}>
        <strong>CONTRATOS CLIENTES</strong>
      </Typography>
      {/* <Box pl={2} pt={2} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <Box>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' style={{ textTransform: 'uppercase' }} py={2} pl={2}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>  */}
      <Box pl={2} pt={2} display="flex" alignItems='center' >
        <Box>
          <Button onClick={() => handleExport()}>
            EXPORTAR EXCEL
          </Button>
        </Box>
      </Box>


      <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 740 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ESTADO</TableCell>
                <TableCell>ACCIONES</TableCell>
                <TableCell>#CONTRATO</TableCell>

                <TableCell>TIPO CONTRATO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>CLIENTE</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DESCUENTO</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>#REFERENCIA</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                {/* <TableCell>FECHA Y HORA INSTALACION</TableCell> */}
                <TableCell>NUMERO FLOTA</TableCell>
                <TableCell>NOMBRE LIDER</TableCell>
                <TableCell>NOMBRE AUXILIAR</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>FECHA CREACION</TableCell>
                <TableCell>FECHA INSTALACION</TableCell>
                <TableCell>VENDEDOR</TableCell>
                <TableCell>REFERIDO</TableCell>
                <TableCell>FLOTA REFERIDA</TableCell>
                <TableCell>CARGO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? data.filter((val) => {
                if (search === '' || val.nombres.toLowerCase().includes(search.toLowerCase())
                  || val.cedula.toLowerCase().includes(search.toLowerCase())) {
                  return val
                }
              }).map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    <Estado estado={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <ViewPdfClient props={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {row.numContrato && row.cambio_domicilio === true ? row.NUM_CONTRATO : row.numContrato}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.contrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.terceraEdad === 1 ? 'SI' : 'NO'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.discapacitado === 1 ? 'SI' : 'NO'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.discapacidad}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.descuento
                    }
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.valor
                    }
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>


                  <TableCell component="th" scope="row">
                    {row.sector}
                  </TableCell>


                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.whatsapp}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.numbRef}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.planNombre}
                  </TableCell>



                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.planValor}
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {row.created && typeof row.created === 'number' &&
                      moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                    {row.created && typeof row.created === 'object' &&
                      moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {row.created && typeof row.created === 'number' &&
                      moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                    {row.created && typeof row.created === 'object' &&
                      moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>


                  <TableCell component="th" scope="row">
                    {row.referido === undefined ? 'NO' : row.referido}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {userInfo.cargo}
                  </TableCell>

                </TableRow>
              )) : <></>}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

    </>
  );
};