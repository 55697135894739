import { Button, Tooltip, Paper, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../firebase";
import { ComprobanteIngreso } from "./PlantillasPDF/ComprobanteIngreso"
import { ComprobanteEgreso } from "./PlantillasPDF/ComprobanteEgreso"
import { TransferenciaActivos } from "./PlantillasPDF/TransferenciaActivos"


export const DescargarPdf = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [dataItems, setDataItems] = useState([]);

    const onData = async () => {
        const agendaDB = await db.collection("reporte_movimientos").where("uidSolicitud", "==", props.id).get()
        let ref = db.collection("reporte_movimientos").doc(props.id).collection('items');
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setDataItems(data)
            setOpen(true)
        });

    }

    return (
        <>
            <Tooltip title="descargar pdf">
                <Button onClick={() => onData()}>
                    <PictureAsPdfIcon color="error" />
                </Button>
            </Tooltip>
            {props.motivo_movimiento == 'INGRESO' &&
                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                    <DialogTitle>
                        <strong>Descargar: {props.id}</strong>
                    </DialogTitle>
                    <DialogContent>
                        <ComprobanteIngreso encabezado={props} contenido={dataItems} />
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                    </DialogActions>
                </Dialog>
            }
            {props.motivo_movimiento == 'EGRESO' &&
                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                    <DialogTitle>
                        <strong>Descargar: {props.id}</strong>
                    </DialogTitle>
                    <DialogContent>

                        <ComprobanteEgreso encabezado={props} contenido={dataItems} />
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                    </DialogActions>
                </Dialog>
            }
            {props.motivo_movimiento == 'TRANSFERENCIA' &&
                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                    <DialogTitle>
                        <strong>Descargar: {props.id}</strong>
                    </DialogTitle>
                    <DialogContent>
                        <TransferenciaActivos encabezado={props} contenido={dataItems} />
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};