import React, { useState } from 'react'
import { Box, Tab, Card, Container, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { VentasPreAprobadas } from './VentasPreAprobadas';
import { VentasRechazadas } from './VentasRechazadas';
import { VentasRechazadasActualizadas } from './VentasRechazadasActualizadas';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  )
}
export const VentasPreAprobadasPage = () => {
  const [value, setValue] = useState(0)
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1"><strong>VENTAS PRE APROBADAS</strong></Typography>
        </Box>
        <Card variant="outlined">
          <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>
            <Tabs
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
              value={value}
              onChange={(e, newValue) => setValue(newValue)}
            >
              <Tab label="Ventas Pre Aprobadas" />
              <Tab label="Ventas Pre Rechazadas" />
              <Tab label="Ventas rechazadas actualizadas" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <VentasPreAprobadas />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <VentasRechazadas />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <VentasRechazadasActualizadas />
          </TabPanel>
        </Card>
      </Container>
    </>
  )
}