import React, { useEffect, useState, useRef } from "react";
import {
    Grid, Stack, TextField, Select, MenuItem, Card, FormControl,
    InputLabel, CardContent, Button, Box, Typography, Dialog, DialogContent, Paper, DialogTitle
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listsTipoNombreCuenta, listsNaturalezaCuenta, listsTiposCuentas, listsItemsEstadoFinanciero } from "../../../redux/actions/parametersActions";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { db, firebase, auth } from "../../../firebase";

import Swal from "sweetalert2";
import FileUploadIcon from '@mui/icons-material/FileUpload';

import * as XLSX from "xlsx/xlsx.mjs";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

export const UploadExcel = () => {

    const [idCuentaContable, setIdCuentaContable] = useState('');

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsTipoNombreCuenta())
        dispatch(listsNaturalezaCuenta())
        dispatch(listsTiposCuentas())
        dispatch(listsItemsEstadoFinanciero())
        loadNumCuenta()
        getCuentas()
    }, [dispatch]);

    const [nombreCuenta, setNombreCuenta] = useState('')

    const changeNombreCuenta = (e) => {
        setNombreCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [codigoCuenta, setCodigoCuenta] = useState('')

    const changeTipoNombreCuenta = (e) => {
        setTipoNombreCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [codigoNombreDeCuenta, setCodigoNombreDeCuenta] = useState('')

    const changeCodigoNombreDeCuenta = (e) => {
        setCodigoNombreDeCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const tipoNombreCuentasList = useSelector(state => state.tipoNombreCuentasList);
    const { tipoNombreCuentas, loading } = tipoNombreCuentasList
    const [tipoNombreCuenta, setTipoNombreCuenta] = useState('')

    const handleDCodigoCuenta = (e) => {
        setCodigoCuenta(e)
    }

    const naturalezaCuentaList = useSelector(state => state.naturalezaCuentaList);
    const { naturalezaCuentas, loadingNaturalezaCuentas } = naturalezaCuentaList
    const [naturalezaCuenta, setNaturalezaCuenta] = useState('')

    const handleNaturalezaCuenta = (e) => {
        setNaturalezaCuenta(e)
    }

    const tiposCuentasList = useSelector(state => state.tiposCuentasList);
    const { tipoCuentas, loadingTipoCuentas } = tiposCuentasList
    const [tipoCuenta, setTipoCuenta] = useState('')

    const handleTipoCuenta = (e) => {
        setTipoCuenta(e)
    }

    const itemsEtsadoFinancierosList = useSelector(state => state.itemsEtsadoFinancierosList);
    const { itemsEstadoFinancieros, loadingItemsEstadoFinanciero } = itemsEtsadoFinancierosList
    const [itemsEstadoFinanciero, setItemsEstadoFinanciero] = useState('')

    const handleItemsEstadoFinanciero = (e) => {
        setItemsEstadoFinanciero(e)
    }

    const [catalogoDeCostoObligatorio, setCatalogoDeCostoObligatorio] = useState(false);
    const [cuentaUtilidad, setCuentaUtilidad] = useState(false);
    const [cuentaGanancia, setCuentaGanancia] = useState(false);
    const [cuentaPerdida, setCuentaPerdida] = useState(false);
    const [agrupador101, setAgrupador101] = useState();

    const handleAgrupador101 = (e) => {
        setAgrupador101(e)
    }

    const [estado, setEstado] = useState(false);

    const [nivelDeCuenta, setNivelDeCuenta] = useState();

    const [numCuenta, setNumCuenta] = useState();

    const handleNivelDeCuenta = (e) => {
        setNivelDeCuenta(e)
    }

    const [data, setData] = useState();

    const getCuentas = () => {
        let ref = db.collection("plan_cuentas")

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
        })

    }

    const loadNumCuenta = () => {
        const ref = db.collection("plan_cuentas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_plan_cuenta + 1;
                setNumCuenta(numeroSolicitud)
            }
        })
    }

    const [openUploadExcel, setOpenUploadExcel] = useState(false);
    const [open, setOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const [dataExcel, setDataExcel] = useState(false);

    const seleccionarBoton = ({ props }) => {
        console.log(props)
        return (
            <>
                <Button color="success" variant="contained" size="small">
                    SELECCIONAR
                </Button>
            </>
        )
    }

    const prueba = async (props) => {
        console.log(props)
        console.log(props.codigo_contable)
        setIdCuentaContable(props.codigo_contable)

        const agendaDB3 = await db.collection("plan_cuentas").where("codigo_contable", "==", props.codigo_contable).get()

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        {
            data3[0] === '' ||
                data3[0] === null ||
                data3[0] === undefined ?
                /* console.log('No existe id') */
                console.log('No existe id') :
                OpenModalUploadExcel()
        }
    }

    const OpenModalUploadExcel = () => {
        console.log('Existe')
        setOpen(false)
        setOpenUploadExcel(true)
    }

    const columns = [
        { field: 'numero_plan_cuenta', headerName: 'CODIGO CONTABLE', width: 200 },
        { field: 'nombre_cuenta_contable', headerName: 'NOMBRE CUENTA CONTABLE', width: 400 },
        { field: 'nivel', headerName: 'NIVEL', width: 160 },
        {
            field: '', headerName: 'SELECCIONAR', width: 140,
            renderCell: (params) => {
                return <Button color="success" variant="contained" size="small" onClick={() => prueba(params.row)}>
                    SELECCIONAR
                </Button>
            }
        },
        /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );

    function CustomFooterTotalComponent(props) {

        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setDataExcel(jsonData)
                console.log(jsonData)
                console.log(idCuentaContable)

                jsonData.forEach((obj) => {

                    db.collection("plan_cuentas")
                        .doc(idCuentaContable)
                        .collection("items")
                        .doc(obj.CUENTA)
                        .set({
                            cuenta: obj.CUENTA,
                            nombre: obj.NOMBRE,
                            nivel: obj.NIVEL,
                            naturaleza: obj.NATURALEZA,
                            tipo_de_saldo: obj.TIPO_DE_SALDO,
                            tipo_de_cuenta: obj.TIPO_DE_CUENTA,
                            item_estado_financiero: obj.ITEM_ESTADO_FINANCIERO,
                            catalogo_de_costo_obligatorio: obj.CATALOGO_DE_COSTO_OBLIGATORIO,
                            cuenta_de_ganancia: obj.CUENTA_DE_GANANCIA,
                            /* cuenta_de_perdida: obj.CUENTA_DE_PERDIDA, */
                            estado: obj.ESTADO,
                            fecha_carga_plan_cuentas: firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_excel: userInfo.displayName,
                            cuenta_mayor: idCuentaContable
                        });
                });

                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenUploadExcel(false)
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    return (
        <>

            <Button color="success" size='small' onClick={() => setOpen(true)} variant="outlined" startIcon={<FileUploadIcon />}>
                SUBIR EXCEL
            </Button>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                <DialogTitle>
                    Seleccione la cuenta en la que desea cargar los datos
                </DialogTitle>
                <DialogContent>
                    <Paper>
                        <Box sx={{ height: 500, width: '100%' }}>
                            <DataGrid
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                density="compact"
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,
                                }}
                                onStateChange={(state) => {
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>
                </DialogContent>
            </Dialog>

            <Dialog open={openUploadExcel} fullWidth onClose={() => setOpenUploadExcel(false)} maxWidth="sm">
                <DialogTitle>
                    Subir Excel
                </DialogTitle>
                <DialogContent>
                    <Box pb={2}>
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>
                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={5}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                        to
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                </DialogContent>
            </Dialog>


        </>
    )
}