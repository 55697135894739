import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, Box, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../../../firebase";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import BorderColorIcon from '@mui/icons-material/BorderColor';

export const UpdateFecha = ({ props }) => {

    const params = useParams()

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const fechaFirestoreCreacion = props.created;
    const fechaDateCreacion = fechaFirestoreCreacion.toDate(); // Convierte el timestamp de Firestore a un objeto Date

    const [fechaCreacion, setFechaCreacion] = useState(fechaDateCreacion)

    const updateFecha = async () => {
        await db.collection('reporte_movimientos').doc(props.id).update({
            created: fechaCreacion
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado la fecha con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Editar fecha">
                <Button onClick={() => onData()}>
                    <BorderColorIcon color="secondary" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>EDITAR FECHA DEL MOVIMIENTO: {props.id}</strong>
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box py={2}>
                            <DatePicker
                                renderInput={(props) => <TextField fullWidth {...props} size='small' />}
                                label="FECHA VENCIMIENTO"
                                inputFormat="dd/MM/yyyy"
                                value={fechaCreacion}
                                onChange={(newValue) => {
                                    setFechaCreacion(newValue);
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button size="small" color="secondary" variant="outlined" onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateFecha()
                    }}
                        size='small'
                        color="secondary"
                        variant="contained"
                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};