import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NewUnidadMedida = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [unidadMedida, setUnidadMedida] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createUnidadMedida = async (unidadMedida) => {
        await db.collection('unidades_medidas_productos').doc().set({
            nombre_unidad_medida_producto: unidadMedida,
            fecha_creacion_unidad_medida_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva unidad de medida creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={unidadMedida}
                cambiarEstado={setUnidadMedida}
                label="UNIDAD MEDIDA"
                name="unidadMedida"
            />

            <br />
            <Button
                onClick={() => createUnidadMedida(unidadMedida.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !unidadMedida.campo}
            >
                {" "}
                CREAR NUEVA UNIDAD DE MEDIDA{" "}
            </Button>
        </>
    );
};
