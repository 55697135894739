import { Chip } from '@mui/material'
import React from 'react'

export const ChipTickets = (props) => {
    
  return (
    <>
    {props.estado === '0' &&
        <Chip label={'ABIERTO'} size='small' color='success' variant="outlined" />
      }
      {props.estado === '1' &&
        <Chip label={'EN PROCESO'} size='small' color='warning' variant="outlined" />
      }
      {props.estado === '2' &&
        <Chip label={'FINALIZADO'} size='small' color='error' variant="outlined" />
      }
      {props.estado === '3' &&
        <Chip label={'MIGRADO'} size='small' color='info' variant="outlined" />
      }
      {props.estado === '4' &&
        <Chip label={'ANULADO'} size='small' color='error' variant="filled" />
      }
      {props.estado === '7' &&
        <Chip label={'PAUSADO'} size='small' color='info' variant="filled" />
      }
      {props.estado === '8' &&
        <Chip label={'ESCALADO'} size='small' color='warning' variant="filled" />
      }
      </>
  )
}


export const ChipTickets2 = (props) => {
    
  return (
    <>
    {props.estado === 0 &&
        <Chip label={'EN ESPERA'} size='small' color='warning' variant="outlined"/>
      }
      {props.estado === 1 &&
        <Chip label={'RESPONDIDO'} size='small' color='success' variant="outlined"/>
      }
      {props.estado === 3 &&
        <Chip label={'POR REASIGNAR'} size='small' color='warning'  />
      }
      {props.estado === 2 &&
        <Chip label={'CERRADO'} size='small' color='error' variant="outlined"/>
      }
      </>
  )
}