import React, { useEffect, useState, } from 'react'
import { useParams } from 'react-router-dom';
import {
    Grid, Box, Card, Button, Divider, Typography, Breadcrumbs, Alert, CardContent
} from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from 'react-router-dom';
import axios, { Axios } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileUpload } from '../../../../components/utils/FileUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Swal from 'sweetalert2'
import { firebase, storage, db } from '../../../../firebase'
import { detallesSolicitud } from '../../../../redux/actions/ventasActions';
import Loader from '../../../../components/utils/Loader'
import { submitDomicilio } from '../../../../redux/actions/ventas/solicitudesActions';
import { Container } from '@mui/system';

export const CargarDocumento = ({ items }) => {
    let navigate = useNavigate()
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);
    const [file6, setFile6] = useState(null);
    const [file7, setFile7] = useState(null);
    const [file9, setFile9] = useState(null);//jesus
    const [file8, setFile8] = useState(null)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameClient, setNameClient] = useState('Lian');
    const [name, setName] = useState('Lian');

    const params = useParams()
    const dispatch = useDispatch()
    const solicitudDetalle = useSelector(state => state.solicitudDetalle)
    const { solicitud } = solicitudDetalle
    useEffect(() => {
        dispatch(detallesSolicitud(params.id))
        setFile1(solicitud.foto_cedula_frontal)
        setFile2(solicitud.foto_cedula_trasera)
        setFile3(solicitud.foto_cliente_letrero)
        setFile4(solicitud.foto_cliente_planilla)
        setFile5(solicitud.foto_cliente_vivienda)
        setFile5(solicitud.foto_cliente_vivienda)
        setFile7(solicitud.foto_debito)
        setFile8(solicitud.aceptacion_cuenta)
        setFile9(solicitud.foto_terceraEdad) //jesus 
        console.log(solicitud, 'solicitud')
    }, [dispatch])


    const onSubmitAgenda = () => {
        dispatch(submitDomicilio(solicitud, navigate))
    }
    return (
        <>
            <Box py={2} pl={2}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <RouterLink to='/ventas/solicitud-de-servicio/1'>
                    Solicitud de servicio
                    </RouterLink>
                    <Button component={RouterLink} to={`/ventas/solicitud-de-servicio/1`}>
                        Solicitud de servicio
                    </Button>
                    <Button component={RouterLink} to={`/ventas/nueva-venta/${params.id}`}>
                        Nueva venta
                    </Button>
                    <Button>
                        Subir documento
                    </Button>
                </Breadcrumbs>
            </Box>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                <Card>
                    <CardContent>
                        <Box pb={2}>
                        <Alert severity="info">Recuerde, debe subir fotos claras al sistema, en caso de alguna anomalia sera sancionado.</Alert>
                        </Box>
                     
                        {
                            solicitud ?
                                <Grid container spacing={5}>
                                    <Grid item md={6}>
                                        <Box pb={5}>
                                            <Typography>Documento cedula frontal</Typography>
                                            <Divider />
                                        </Box>
                                        {solicitud && solicitud.foto_cedula_frontal ?
                                            <>
                                                <img width={100} src={solicitud.foto_cedula_frontal} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <FileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile1}
                                                estado={file1}
                                                nameFile="foto_cedula_frontal"
                                            />}
                                        <Box py={5}>
                                            <Typography>Documento cedula trasera</Typography>
                                            <Divider />
                                        </Box>
                                        {solicitud && solicitud.foto_cedula_trasera ?
                                            <>
                                                <img width={100} src={solicitud.foto_cedula_trasera} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <FileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile2}
                                                estado={file2}
                                                nameFile="foto_cedula_trasera"
                                            />}
                                        <Box py={5}>
                                            <Typography>Documento planilla domicilio</Typography>
                                            <Divider />
                                        </Box>
                                        {solicitud && solicitud.foto_cliente_planilla ?
                                            <>
                                                <img width={100} src={solicitud.foto_cliente_planilla} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <FileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile3}
                                                estado={file3}
                                                nameFile="foto_cliente_planilla"
                                            />}
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box pb={5}>
                                            <Typography>Foto vivienda</Typography>
                                            <Divider />
                                        </Box>
                                        {solicitud && solicitud.foto_cliente_vivienda ?
                                            <>
                                                <img width={100} src={solicitud.foto_cliente_vivienda} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <FileUpload
                                                solicitud={solicitud}
                                                cambiarEstado={setFile4}
                                                estado={file4}
                                                nameFile="foto_cliente_vivienda"
                                            />}
                                        {
                                            solicitud && solicitud.tipo_venta !== 'MIGRACION' &&
                                            <>
                                                <Box py={5}>
                                                    <Typography>Foto cliente con letrero</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.foto_cliente_letrero ?
                                                        <>
                                                            <img width={100} src={solicitud.foto_cliente_letrero} />
                                                            <Alert>Archivo subido</Alert>
                                                        </>
                                                        :
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile5}
                                                            estado={file5}
                                                            nameFile="foto_cliente_letrero"
                                                        />
                                                }
                                            </>

                                        }

                                        {solicitud && solicitud.discapacitado === 1 &&
                                            <>
                                                <Box py={5}>
                                                    <Typography>Carnet de discapacidad</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.foto_carnet_discapacidad ?
                                                        <>
                                                            <img width={100} src={solicitud.foto_carnet_discapacidad} />
                                                            <Alert>Archivo subido</Alert>
                                                        </>
                                                        :
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile6}
                                                            estado={file6}
                                                            nameFile="foto_carnet_discapacidad"
                                                        />
                                                }
                                            </>
                                        }
                               

                               {solicitud && solicitud.terceraEdad === 1 &&
                                            <>
                                                  <Box py={5}>
                                                    <Typography>Subir Anexo Tercera Edad</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.foto_terceraEdad ?
                                                        <>
                                                            <img width={100} src={solicitud.foto_terceraEdad} />
                                                            <Alert> Archivo Subido </Alert>
                                                        </>
                                                        ://jesus
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile9}
                                                            estado={file9}
                                                            nameFile="foto_terceraEdad"
                                                        />
                                                }
                                            </>
                                        }


                                        {solicitud && solicitud.debito === 1 &&
                                            <>
                                                <Box py={5}>
                                                    <Typography>Subir debito bancario</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.foto_debito ?
                                                        <>
                                                            <img width={100} src={solicitud.foto_debito} />
                                                            {/* <Button variant='outlined'>
                                                                <a style={{ textDecoration: 'none' }} target="_blank" href={solicitud.foto_debito}>VER PDF</a>
                                                            </Button> */}
                                                          <Alert>Archivo subido</Alert>Subir foto de aceptacion


                                                        </>
                                                        :
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile7}
                                                            estado={file7}
                                                            nameFile="foto_debito"
                                                        />
                                                }
                                            </>
                                        }
                                        {solicitud && solicitud.debito === 1 &&
                                            <>
                                                <Box py={5}>
                                                    <Typography>Subir foto de aceptacion de cuenta</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.aceptacion_cuenta ?
                                                        <>
                                                            <img width={100} src={solicitud.aceptacion_cuenta} />
                                                       
                                                          <Alert>Archivo subido</Alert>Subir foto de aceptacion de cuenta


                                                        </>
                                                        :
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile8}
                                                            estado={file8}
                                                            nameFile="aceptacion_cuenta"
                                                        />
                                                }
                                            </>
                                        }

                                        {solicitud && solicitud.tipo_venta === 'MIGRACION' &&
                                            <>
                                                <Box py={5}>
                                                    <Typography>Subir foto de aceptacion</Typography>
                                                    <Divider />
                                                </Box>
                                                {
                                                    solicitud && solicitud.foto_carta_cesion ?
                                                        <>
                                                            <img width={100} src={solicitud.foto_carta_cesion} />
                                                            {/* <Button variant='outlined'>
                                                                <a style={{ textDecoration: 'none' }} target="_blank" href={solicitud.foto_debito}>VER PDF</a>
                                                            </Button> */}
                                                          <Alert>Archivo subido</Alert>Subir foto de aceptacion
                                                        </>
                                                        :
                                                        <FileUpload
                                                            solicitud={solicitud}
                                                            cambiarEstado={setFile7}
                                                            estado={file7}
                                                            nameFile="foto_carta_cesion"
                                                        />
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                                :
                                <Loader />
                        }

                        <div id="sign-in-button"></div>
                        <Box pt={2} display="flex" flexDirection="row-reverse">

                            {solicitud && solicitud.cambio_domicilio === true &&
                                <Button
                                    variant='contained'
                                    disableElevation
                                    disabled={
                                        !solicitud.foto_cedula_frontal
                                        || !solicitud.foto_cedula_trasera
                                        || !solicitud.foto_cliente_planilla
                                        || !solicitud.foto_cliente_vivienda
                                        || !solicitud.foto_cliente_letrero}
                                    onClick={() => onSubmitAgenda()}

                                >AGENDAR CAMBIO DOMICILIO</Button>
                            }
                            {solicitud && solicitud.tipo_venta === 'MIGRACION' &&
                                <Button
                                    variant='contained'
                                    disableElevation
                                    disabled={
                                        !solicitud.foto_cedula_frontal
                                        || !solicitud.foto_cedula_trasera
                                        || !solicitud.foto_cliente_planilla
                                        || !solicitud.foto_cliente_vivienda
                                        || !solicitud.foto_carta_cesion}
                                    onClick={() => onSubmitAgenda()}

                                >AGENDAR MIGRACION</Button>
                            }


                            {solicitud && solicitud.cambio_domicilio === undefined &&
                                <Button
                                    disabled={
                                        !solicitud.foto_cedula_frontal
                                        || !solicitud.foto_cedula_trasera
                                        || !solicitud.foto_cliente_planilla
                                        || !solicitud.foto_cliente_vivienda
                                        || !solicitud.foto_cliente_letrero
                                    || (solicitud && solicitud.debito === 1 && !solicitud.aceptacion_cuenta)}
                                    variant='contained'
                                    disableElevation
                                    style={{ color: 'white' }}
                                    to={`/ventas/codigo-verificacion/${params.id}`}
                                    component={RouterLink}
                                >SIGUIENTE</Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Container>

        </>
    )
}