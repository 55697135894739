import React, { useEffect, useState } from 'react'
import { Card, Avatar, Badge, ListItemText, ListItem, ListItemAvatar, CardHeader, List, Box } from '@mui/material';
import { db } from '../../../firebase'
import moment from 'moment'
import { styled } from '@mui/material/styles';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
export const ListUsersOnline = () => {
    const [list, setList] = useState([])
    useEffect(() => {
        list_users()
    }, [])
    const list_users = () => {
        let ref = db.collection("usuarios").orderBy("last_changed", "desc").where("state", "==", "online")
        ref.onSnapshot((snapshot) => {
        
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                })
            ]
            setList(data)
        })
    }
    return (
        <>
            <Card>
                <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader={<strong>Usuarios en Linea {list.length}</strong>} />
                <Box height="400px" display="flex" flexDirection="column">
                    <Box flex={1} overflow="auto">
                        <List dense>
                            {
                                list?.map((item, index) => (
                                    <ListItem key={item.id}>
                                        <ListItemAvatar>
                                            <StyledBadge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant="dot"
                                            >
                                                <Avatar alt={item.nombres} />
                                            </StyledBadge>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.nombres} secondary={
                                          item?.last_changed?.seconds ?  moment(new Date(item?.last_changed?.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a') : ''}  />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </Box>
                </Box>
            </Card>
        </>
    )
}
