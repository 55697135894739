import React, { useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

export const  Financiero =()=> {
  const chartRef = useRef();

  useEffect(() => {
    const chart = chartRef.current.chartInstance;
    chart.data.labels = ['Jan', 'Feb', 'Mar'];
    chart.data.datasets[0].data = [1, 2, 3];
    chart.update();
  }, []);

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Sales',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [],
      },
    ],
  };

  return (
    <div>
      <Bar ref={chartRef} data={data} />
    </div>
  );
}

