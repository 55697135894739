import React, { useState } from 'react'
import { Tabs, Box, Tab, Container } from '@mui/material';
import { KardexList } from './KardexList';
import { KardexGeneral } from './KardexGeneral';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const KardexPage = () => {
    const [tab, setTab] = useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
   

    return (
        <>
            <Container maxWidth="xl">
                <Tabs value={tab} onChange={handleChange} aria-label="">
                    <Tab style={{ fontSize: "13px" }} label="Kardex Por Articulo" {...tabs(0)} />
                    <Tab style={{ fontSize: "13px" }} label="Kardex General" {...tabs(0)} />
                </Tabs>

                <TabPanel value={tab} index={0}>
                    <KardexList />
                </TabPanel>

                <TabPanel value={tab} index={1}>
                    <KardexGeneral />
                </TabPanel>

            </Container>
        </>
    )
}