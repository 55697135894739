import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import { useSelector } from 'react-redux';
import { ExpandLess } from '@mui/icons-material';
export const ListCallCenter = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [open, setOpen] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'call_center']?.includes(role)) ?
                <>
                    {userAuth?.roles?.find(role => ['externo']?.includes(role)) ?
                        <>
                            <ListItemButton onClick={() => setOpen(!open)}>
                                <ListItemIcon>
                                    <RingVolumeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Call Center" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center/consulta_servicios`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Consultar Servicios" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center/consulta_usuarios`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Usuarios" />
                                    </ListItemButton>
                                </List>
                                
                            </Collapse>
                        </>
                        :
                        <>
                            <ListItemButton onClick={() => setOpen(!open)}>
                                <ListItemIcon>
                                    <RingVolumeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Call Center" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`encuestas`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Encuestas" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Solicitudes" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`encuestas`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Luktv" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center/consulta_usuarios`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Usuarios" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center/consulta_servicios`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Consultar Servicios" />
                                    </ListItemButton>
                                </List>
                                <List component="div" disablePadding dense>
                                    <ListItemButton component={RouterLink} to={`call_center/soporte1`}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Soporte N1" />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </>}
                </> : ''}
        </>
    )
}