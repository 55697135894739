import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, tabsClasses, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { OnusList } from './OnusList';
import { NewUsers } from './radius/NewUsers';
import { PoolIpv4 } from './PoolIpv4';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const NetworkingPage = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1">NETWORKING/<strong>CONFIGURED</strong></Typography>
      </Box>
      <Box sx={{ maxWidth: { xs: 300, sm: '100%' }, bgcolor: 'background.paper' }}>
        <Tabs
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
          value={tab}
          onChange={handleChange}
          aria-label="solicitud de servicio"
        >
          <Tab label="ONUS" {...tabs(0)} />
          <Tab label="PoolIpv4" {...tabs(1)} />  {/* 
          <Tab label="Rechazadas" {...tabs(2)} />
          <Tab label="Del Dia" {...tabs(3)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <OnusList />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PoolIpv4 />
      </TabPanel>
      <TabPanel value={tab} index={2}>
      </TabPanel>
      <TabPanel value={tab} index={3}>
      </TabPanel>
    </>
  )
}