import React, { useEffect, useState } from "react";
import {
    Grid,
    Stack,
    TextField,
    Select,
    MenuItem,
    Card,
    FormControl,
    InputLabel,
    CardContent,
    Button,
    Box,
    Typography,
    Dialog,
    DialogContent,
    Tooltip,
    DialogActions,
    DialogTitle,
    CardActions,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { db, firebase } from "../../../../firebase";
import moment from 'moment'

export const EditPeriodoContable = ({ props }) => {

    const [valueFechaInicio, setValueFechaInicio] = useState(moment(new Date(props.fecha_inicio.seconds * 1000)));
    const [valueFechaFin, setValueFechaFin] = useState(props.fecha_fin.seconds * 1000);

    const [stateCierreMensual, setStateCierreMensual] = React.useState(props.estado_cierre_mensual);

    const handleEstado = (event) => {
        setStateCierreMensual(event.target.checked);
    };

    const [openEditPeriodoContable, setOpenEditPeriodoContable] = useState(null);

    const [openCrearNuevoAsiento, setOpenEditAsiento] = useState(false);

    const editPeriodoContable = async () => {
        console.log(props.fecha_inicio)
        console.log(valueFechaInicio)

        console.log(valueFechaFin)

        await db.collection('periodos_contables').doc(props.id).update({
            estado_cierre_mensual: stateCierreMensual,
        })

        /* await db.collection('periodos_contables').doc(props.id).update({
            fecha_inicio: valueFechaInicio,
            fecha_fin: valueFechaFin,
        }) */

        /* if (valueFechaInicio === props.fecha_inicio) {
            console.log('es igual')
        } else {
            console.log('no es igual')
        } */

        /* if (valueFechaInicio === props.fecha_inicio) {
            await db.collection('periodos_contables').doc(props.id).update({
                fecha_fin: valueFechaFin,
            })
        } else if (valueFechaFin === props.fecha_fin) {
            await db.collection('periodos_contables').doc(props.id).update({
                fecha_inicio: valueFechaInicio,
            })
        } else if(!valueFechaFin === props.fecha_fin && !valueFechaInicio === props.fecha_inicio){
            await db.collection('periodos_contables').doc(props.id).update({
                fecha_inicio: valueFechaInicio,
                fecha_fin: valueFechaFin,
            })
        }

        Swal.fire({ icon: "success", text: 'Se han actualizado los datos con exito' });

        setOpenEditPeriodoContable(false) */

        /* await db.collection('periodos_contables').doc(props.id).update({
            fecha_inicio: valueFechaInicio,
            fecha_fin: valueFechaFin,
            estado_cierre_mensual: stateCierreMensual,
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        }); */

        Swal.fire({ icon: "success", text: 'Se han actualizado los datos con exito' });
        setOpenEditPeriodoContable(false)

    }

    const consult = () => {
        console.log(props.fecha_inicio)
        console.log(props.fecha_fin)
    }

    return (
        <>
            <Tooltip title="MODIFICAR">
                <Button onClick={() => setOpenEditPeriodoContable(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* Editar periodo contable */}

            <Dialog
                open={openEditPeriodoContable}
                onClose={() => setOpenEditPeriodoContable(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>EDITAR PERIODO CONTABLE</DialogTitle>

                <DialogContent>
                    <Card>
                        <CardContent>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box py={2}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>FECHA INICIO</p>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label=""
                                            value={valueFechaInicio}
                                            onChange={async (fechaInicio) => {
                                                setValueFechaInicio(fechaInicio);
                                                await db.collection('periodos_contables').doc(props.id).update({
                                                    fecha_inicio: fechaInicio,
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box py={2}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>FECHA FIN</p>
                                    <FormControl fullWidth>

                                        <DatePicker
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label=""
                                            value={valueFechaFin}
                                            onChange={async (fechaFin) => {
                                                setValueFechaFin(fechaFin);
                                                await db.collection('periodos_contables').doc(props.id).update({
                                                    fecha_fin: fechaFin,
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </Box>

                                <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                                    <Box pb={3} style={{ paddingBottom: "0px" }}>
                                        <FormControlLabel
                                            label={
                                                <Typography sx={{ fontSize: 11 }}>
                                                    CIERRE MENSUAL
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={stateCierreMensual}
                                                    onChange={handleEstado}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                        />
                                    </Box>
                                </Grid>

                            </LocalizationProvider>

                        </CardContent>
                        <CardActions>
                            <Box pb={2} pl={1}>
                                <Button disabled={
                                    valueFechaInicio === '' ||
                                    valueFechaFin === '' ||
                                    valueFechaInicio === valueFechaFin
                                } variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => editPeriodoContable()}>
                                    Guardar
                                </Button>
                            </Box>
                            <Box pb={2} onClick={() => setOpenEditAsiento(false)}>
                                <Button variant="outlined" size="small">
                                    CANCELAR
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>

                </DialogContent>
            </Dialog>


        </>
    )
}