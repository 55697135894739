import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import { Box, Button, IconButton, Card, CardContent, Grid, LinearProgress, MenuItem, Paper, Select, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2';
import { db } from '../../../firebase';
import * as XLSX from "xlsx/xlsx.mjs";



export const CatalogoRetenciones = () => {

    const [porcentaje, setPorcentaje] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [tipoRetencion, setTipoRetencion] = useState('')
    const [cuenta, setCuenta] = useState('')
    const [tipoContribuyente, setTipoContribuyente] = useState('')
    const [formaPago, setFormaPago] = useState('')
    const [tipoProducto, setTipoProducto] = useState('')
    const [estado, setEstado] = useState('')
    const [codigo, setCodigo] = useState('')

    const [formas, setFormas] = useState([])
    const [tipos, setTipos] = useState([])

    const [id, setId] = useState('')
    const [categorias, setCategorias] = useState(null)
    const [retenciones, setRetenciones] = useState([])
    const [retenciones2, setRetenciones2] = useState([])
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [file, setFile] = useState(null)

    useEffect(() => {
        cargarCategorias()
        getPersonas()
        getTipoContribuyente()
        getRetenciones()
        getRetenciones2()
    }, [])


    const getRetenciones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_fuentes")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRetenciones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getRetenciones2 = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_iva")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRetenciones2(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }




    const getTipoContribuyente = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_contribuyente")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setTipos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const getPersonas = () => {
        let docRef = db.collection("cuentas_pagar/parametros/formas_pago")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setFormas(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )

    const columns = [
        { field: 'codigo', headerClassName: 'super-app-theme--header', headerName: 'Código SRI', width: 150 },
        { field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción Retención', width: 200 },
        { field: 'tipoRetencion', headerClassName: 'super-app-theme--header', headerName: 'Tipo Retención', width: 200 },
        { field: 'cuenta', headerClassName: 'super-app-theme--header', headerName: 'Cuenta Contable', width: 200 },
        { field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 200 },
        { field: 'porcentaje', headerClassName: 'super-app-theme--header', headerName: 'Porcentaje Retención', width: 300 },
        { field: 'tipoProducto', headerClassName: 'super-app-theme--header', headerName: 'Tipo Producto', width: 200 },
        { field: 'tipoContribuyente', headerClassName: 'super-app-theme--header', headerName: 'Tipo Contribuyente', width: 200 },
        { field: 'formaPago', headerClassName: 'super-app-theme--header', headerName: 'Forma de Pago', width: 200 },
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'EDITAR', width: 100,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}>
                    <EditIcon color='info' />
                </IconButton>
            }
        },
        {
            field: 'BORRAR', headerClassName: 'super-app-theme--header', headerName: 'BORRAR', width: 100,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen2(params.row)}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];

    function cargarCategorias() {
        db.collection(`cuentas_pagar/colecciones/catalogo_retenciones`)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setCategorias(arrayMessage)
            })
    }



    function crearSubCategoria() {
        db.collection("cuentas_pagar/colecciones/catalogo_retenciones").doc().set({
            codigo: porcentaje.split('-')[0],
            porcentajeCod: porcentaje,
            porcentaje: porcentaje.split('-')[1].replace('(', '').replace(')', '').replace('%', ''),
            descripcion: descripcion,
            tipoRetencion: tipoRetencion,
            tipoProducto: tipoProducto,
            cuenta: cuenta,
            estado: estado,
            tipoContribuyente: tipoContribuyente,
            formaPago: formaPago,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        if (id !== '') {
            db.collection("cuentas_pagar/colecciones/catalogo_retenciones").doc(id).update({
                porcentajeCod: porcentaje,
                porcentaje: porcentaje.split('-')[1].replace('(', '').replace(')', '').replace('%', ''),
                codigo: porcentaje.split('-')[0],
                descripcion: descripcion,
                tipoRetencion: tipoRetencion,
                tipoProducto: tipoProducto,
                cuenta: cuenta,
                estado: estado,
                tipoContribuyente: tipoContribuyente,
                formaPago: formaPago,
            }).then(() => {
                limpiar()
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha actualizado", });
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
        } else {
            crearSubCategoria()
        }
    }

    function limpiar() {
        setId('')
        setCodigo('')
        setPorcentaje('')
        setDescripcion('')
        setTipoContribuyente('')
        setTipoProducto('')
        setTipoRetencion('')
        setCuenta('')
        setFormaPago('')
        setEstado('')

    }

    function handleOpen(item) {
        if (item) {
            setId(item.id)
            setCodigo(item.codigo)
            setPorcentaje(item.porcentajeCod)
            setDescripcion(item.descripcion)
            setTipoContribuyente(item.tipoContribuyente)
            setTipoProducto(item.tipoProducto)
            setTipoRetencion(item.tipoRetencion)
            setCuenta(item.cuenta)
            setFormaPago(item.formaPago)
            setEstado(item.estado)
        }
        setOpen(true)
    }

    function handleOpen2(item) {
        if (item) {
            setId(item.id)
        }
        setOpen2(true)
    }

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                jsonData.forEach((obj) => {
                    if (obj.MOTIVO && obj.MOTIVO === "INSTALACION" &&
                        obj.CONTRATO && obj.CONTRATO.length > 0) {
                        // datos.push(obj.CONTRATO)
                        // new Date(item.fechaInstalacionVenta.seconds *1000).getMonth() === 5

                    }

                });
                console.log(jsonData.length)
                console.log("YA ESTA")

            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    function aleatorio(lista) {
        if (lista.length === 0) {
            return null
        }
        return lista[(Math.floor(Math.random() * lista.length))]
    }

    async function crearAleatorio() {
        let a = 0
        while (a < 100) {
            let cont = aleatorio(tipos)
            if (cont) cont = cont.nombre
            let tipo = aleatorio(['BIEN', 'SERVICIO'])
            let forma = aleatorio(['DEBITO DE CUENTA', 
            'TARJETA DE CREDITO', 'OTROS CON UTILIZACION DEL SISTEMA FINANCIERO'])
            let tipoRet = aleatorio(['IVA', 'RENTA'])

            let lista = tipoRet === 'IVA' ? retenciones2 : retenciones


            let cuentaaa = aleatorio(lista)
            if(cuentaaa.cuenta_contable !== ''){
                await db.collection("cuentas_pagar/colecciones/catalogo_retenciones").doc().set({
                    codigo: cuentaaa.codigo,
                    porcentajeCod: `${cuentaaa.codigo}-(${cuentaaa.porcentaje}%)`,
                    porcentaje: cuentaaa.porcentaje,
                    descripcion: 'ALEATORIO',
                    tipoRetencion: tipoRet,
                    tipoProducto: tipo,
                    cuenta: cuentaaa.cuenta_contable,
                    estado: 'ACTIVO',
                    tipoContribuyente: cont,
                    formaPago: forma,
                }).then(() => {
                    a+=1
                    console.log('CREADO')
                    // Swal.fire({ icon: "success", text: "Se ha creado", });
                }).catch((error) => {
                    // Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                    console.error("Error adding document: ", error)
                });
            }
            
        }


    }

    function deleteProducto() {
        db.collection('cuentas_pagar/colecciones/catalogo_retenciones').doc(id).delete()
            .then(() => {
                Swal.fire({ icon: "success", text: "Se ha eliminado" })
                setOpen2(false)
                limpiar()
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
    }


    return (
        <>
            <Box px={2}>
                <Typography variant="h5"><strong>Catálogo de Retenciones</strong> </Typography>
                <Card>
                    {/* <Button
                        onClick={() => crearAleatorio()}
                        variant="contained" color='success'>
                        AGREGAR ALEATORIO
                    </Button> */}
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => handleOpen()}
                                    variant="contained" color='success'>
                                    AGREGAR
                                </Button>

                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={2} sx={{ padding: '10px' }}>
                                    <Box sx={{
                                        height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                        '& .super-app-theme--header': {
                                            backgroundColor: '#672780',
                                            color: 'white',
                                            fontSize: '16px'
                                        },
                                    }}>
                                        <DataGrid
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                            }}
                                            loading={categorias === null}
                                            hideFooterSelectedRowCount={true}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            density='compact'
                                            rows={categorias ? categorias : []}

                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Dialog open={open} fullWidth onClose={() => {
                            limpiar()
                            setOpen(false)
                        }} maxWidth="md">
                            <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} PARAMETRO RETENCION </strong></DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2}>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Tipo Contribuyente Proveedor:  *</strong></Typography>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={tipoContribuyente}
                                                onChange={(e) => setTipoContribuyente(e.target.value)}
                                                fullWidth
                                                MenuProps={MenuProps}
                                            >
                                                {tipos ? tipos.map((item, index) => (
                                                    <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                                )) : <></>}

                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Forma Pago Proveedor:  *</strong></Typography>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={formaPago}
                                                onChange={(e) => setFormaPago(e.target.value)}
                                                fullWidth
                                                MenuProps={MenuProps}
                                            >
                                                {formas ? formas.map((item, index) => (
                                                    <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                                )) : <></>}

                                            </Select>
                                        </Box>
                                    </Grid>

                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Bien/Servicio:  *</strong></Typography>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={tipoProducto}
                                                onChange={(e) => setTipoProducto(e.target.value)}
                                                fullWidth
                                                MenuProps={MenuProps}>
                                                <MenuItem key={0} value={'BIEN'}>{'BIEN'}</MenuItem>
                                                <MenuItem key={1} value={'SERVICIO'}>{'SERVICIO'}</MenuItem>

                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: "14px" }}><strong>Tipo de Retención:  *</strong></Typography>

                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={tipoRetencion}
                                                disabled={id !== ''}
                                                onChange={(e) => {
                                                    setTipoRetencion(e.target.value)
                                                }}
                                                fullWidth
                                                MenuProps={MenuProps}
                                            >

                                                <MenuItem key={0} value={'IVA'}>{'IVA'}</MenuItem>
                                                <MenuItem key={1} value={'RENTA'}>{'RENTA'}</MenuItem>

                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: "14px" }}><strong>Porcentaje Retención:  *</strong></Typography>
                                            {/* {id !== '' ?
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={porcentaje}
                                                    disabled={true}
                                                    size="small"
                                                    fullWidth />
                                                : */}
                                            <Autocomplete
                                                size='small'
                                                fullWidth
                                                disablePortal
                                                id="combo-box-demo"
                                                options={(tipoRetencion === 'IVA' ? retenciones2 :
                                                    (tipoRetencion === 'RENTA' ? retenciones : []))}
                                                getOptionLabel={(option) => `${option.codigo}-(${option.porcentaje}%)`}
                                                onInputChange={(e, newInputValue) => {
                                                    setPorcentaje(newInputValue)
                                                    setCodigo(newInputValue.split('-')[0])
                                                    if (tipoRetencion === 'IVA') {
                                                        let cuen = retenciones2.filter((item) =>
                                                            item.codigo.toString() === newInputValue.split('-')[0])[0]
                                                        setCuenta(cuen.cuenta_contable)

                                                    } else if (tipoRetencion === 'RENTA') {
                                                        let cuen = retenciones.filter((item) =>
                                                            item.codigo.toString() === newInputValue.split('-')[0])[0]
                                                        setCuenta(cuen.cuenta_contable)
                                                    }
                                                }}
                                                renderInput={(params) => <TextField  {...params} />}
                                            />
                                            {/* } */}
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Cuenta Contable:  *</strong></Typography>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={cuenta}
                                                disabled={true}
                                                size="small"
                                                fullWidth />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: "14px" }}><strong>Código SRI:  *</strong></Typography>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={codigo}
                                                disabled={true}
                                                // onChange={(e) => setCodigo(e.target.value)}
                                                size="small"
                                                fullWidth />
                                        </Box>
                                    </Grid>



                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: "14px" }}><strong>Estado:  *</strong></Typography>

                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={estado}
                                                onChange={(e) => setEstado(e.target.value)}
                                                fullWidth
                                                MenuProps={MenuProps}
                                            >

                                                <MenuItem key={0} value={'ACTIVO'}>{'ACTIVO'}</MenuItem>
                                                <MenuItem key={1} value={'INACTIVO'}>{'INACTIVO'}</MenuItem>

                                            </Select>
                                        </Box>
                                    </Grid>

                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Typography sx={{ fontSize: "14px" }}><strong>Descripción de Retención:  *</strong></Typography>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={descripcion}
                                                onChange={(e) => setDescripcion(e.target.value)}
                                                size="small"
                                                fullWidth />
                                        </Box>
                                    </Grid>


                                    <Grid item md={6} lg={6} xs={12}>
                                        <Box py={1}>
                                            <Button
                                                sx={{ marginTop: '20px' }}
                                                disabled={
                                                    codigo === '' ||
                                                    porcentaje === '' ||
                                                    descripcion === '' ||
                                                    tipoRetencion === '' ||
                                                    tipoProducto === '' ||
                                                    estado === '' ||
                                                    tipoContribuyente === '' ||
                                                    formaPago === ''}
                                                onClick={() => actualizarSubCategoria()}
                                                fullWidth variant="contained" color='success'>
                                                {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                            </Button>
                                        </Box>
                                    </Grid>

                                </Grid>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    limpiar()
                                    setOpen(false)
                                }} variant="contained"><strong>Regresar</strong></Button>
                            </DialogActions>
                        </Dialog>

                    </CardContent>
                </Card>
            </Box>


            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: { }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
