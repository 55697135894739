import {
    Table, TableContainer, TableRow, TableCell, TableBody,
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse, Hidden
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from 'xlsx/xlsx.mjs'

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { GridFilterPanel } from "@mui/x-data-grid";
import { InfoFiscalizacion } from "./IconButtonsFiscalizacion/InfoFiscalizacion";

export const FiscalizacionesRechazadas = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 1) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date("2022-07-3"))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    //
    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
        mostrarTotal()

    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }
    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("instalaciones_fiscalizadas").where('estado', '==', 1)

        //Si tiene algun doc anterior se agrega acontinuacion
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)

            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('fecha_rechazo_fiscalizacion', '>=', startfulldate)
            ref = ref.where('fecha_rechazo_fiscalizacion', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                data.reverse()

                if (userInfo.email === "luis.vinan@intercommerce.com.ec" ||
                    userInfo.email === "fernando.malave@intercommerce.com.ec") {
                    setData(data.filter((item) => item.numContrato.includes('GUAYAQUIL')));

                }
                else if (userInfo.email === "diego.macias@intercommerce.com.ec") {
                    setData(data.filter((item) => item.numContrato.includes('SANTA-ELENA')));
                }
                else {
                    setData(data);
                }
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }
    };


    const mostrarTotal = () => {
        console.log('DATOS 1')
    }
    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    function Estado() {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (1 === 1) {
            name = 'RECHAZADA'
            color = 'error'
        }
        return (
            <Chip label={name} size="small" variant="filled" color={color} />
        )
    }

    const columns = [
        { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
        {
            field: 'estado', headerName: 'ESTADO', width: 150,
            renderCell: (params) => {
                return <Estado />
            }
        },
        { field: 'observacion_rechazo_fiscalizacion', headerName: 'OBSERVACION RECHAZO FISCALIZACION', width: 300 },
        { field: 'cedula', headerName: 'CEDULA', width: 110 },
        { field: 'nombres', headerName: 'CLIENTE', width: 300 },
        { field: 'direccion', headerName: 'DIRECCION', width: 350 },
        { field: 'celular', headerName: 'CELULAR', width: 110 },
        { field: 'correo', headerName: 'CORREO CLIENTE', width: 220 },
        { field: 'nacionalidad', headerName: 'NACIONALIDAD', width: 150 },
        { field: 'provincia', headerName: 'PROVINCIA', width: 150 },
        { field: 'canton', headerName: 'CANTON', width: 150 },
        { field: 'sector', headerName: 'SECTOR', width: 200 },
        { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 150 },
        { field: 'nombreLider', headerName: 'NOMBRE LIDER', width: 280 },
        { field: 'nombreAuxiliar', headerName: 'NOMBRE AUXILIAR', width: 260 },
        {
            field: 'coordenadas', headerName: 'COORDENADAS', width: 180,
            valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
        },
        {
            field: 'fecha_rechazo_fiscalizacion', headerName: 'FECHA DE FISCALIZACION', type: 'dateTime', width: 220,
            valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
        },
    ];

    const esVerdadero = 1;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {
                    NUM_CONTRATO: doc.numContrato,
                    OBSERVACION_RECHAZO_FISCALIZACION: doc.observacion_rechazo_fiscalizacion,
                    CEDULA: doc.cedula,
                    NOMBRES: doc.nombres,
                    DIRECCION: doc.direccion,
                    CELULAR: doc.celular,
                    CORREO: doc.correo,
                    NACIONALIDAD: doc.nacionalidad,
                    PROVINCIA: doc.provincia,
                    CANTON: doc.canton,
                    SECTOR: doc.sector,
                    NUM_FLOTA: doc.numFlota,
                    NOMBRE_LIDER: doc.nombreLider,
                    NOMBRE_AUXILIAR: doc.nombreAuxiliar,
                    COORDENADAS: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
                    USUARIO_QUE_FISCALIZO: doc.usuario_que_rechazo_fiscalizacion,
                    FECHA_FISCALIZACION: doc.fecha_rechazo_fiscalizacion.toDate(),
                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Fiscalizaciones_rechazadas");
        XLSX.writeFile(wb, `REPORTE_FISCALIZACIONES_RECHAZADAS_${date1}.xlsx`)
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    return (
        <>
            <Box pt={1} pl={1}>
                <Typography variant="button" >
                    <Typography >
                        <strong>FISCALIZACIONES RECHAZADAS</strong>
                    </Typography >
                </Typography >
            </Box>
            <Box pt={1} pl={1}>
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Box display="flex" alignItems='center' >
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box>

            <IconButton color="primary" onClick={handleExport}>
                <SimCardDownloadIcon />
            </IconButton>

            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        density='compact'
                        columns={columns}
                        components={{
                            // Footer: CustomFooterTotalComponent,
                            Toolbar: CustomToolbar,
                        }}

                        onStateChange={(state) => {
                            console.log(state.pagination.rowCount);
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>

        </>
    );
};