import React, { useState, useEffect } from "react";
import {
  TextField, FormControl, Select, MenuItem, Button, Box,
  DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { firebase, db } from "../../../firebase";
import Swal from "sweetalert2";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { listFlotas } from "../../../redux/actions/flotas/flotasActions";
import { listLiders } from "../../../redux/actions/flotas/flotasLiders";
import { listAuxiliares } from "../../../redux/actions/flotas/flotasAuxiliares";

import Grid from '@mui/material/Grid';
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";
import { createAgenda } from "../../../redux/actions/agenda/agendaActions";
export const ViewVentasReAgendadas = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [data, setData] = useState('')
  //
  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loading } = flotasList
  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList
  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList
  const [open, setOpen] = useState(false);
  const [openFoto, setOpenFoto] = useState(false);
  const [openRechazo, setOpenRechazo] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [value, setValue] = useState(null);
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')
  const [correo, setCorreo] = useState('')
  const [observacion2, setObservacion2] = useState(false);
  const [openChangeColor, setOpenChangeColor] = useState(false);
  const [noSeAgendCliente, setNoSeAgendCliente] = useState(false);

  useEffect(() => {
    dispatch(listFlotas())
    dispatch(listLiders())
    dispatch(listAuxiliares())
  }, [dispatch])

  const handleFlota = (e) => {
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0]
    setNumFlota(data.numeroFlota)
    setLider(data.nombreLider)
    setAuxiliar(data.nombreAuxiliar)
    setCorreo(data.correo)
  }

  const handleLider = (e) => {
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e);
    const data = p[0]
    setLider(data.nombreLider)
  }
  const handleAuxiliar = (e) => {
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e);
    const data = p[0]
    setAuxiliar(data.nombreAuxiliar)
  }


  const handleSubmit = (id) => {
    const formData = {
      ...data,
      estado_pagado: 2,
      fechaHora: value,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      correo_flota: correo,
      numFlota: numFlota,
      estado: 0,
      fechaDeReAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueReAgendo: userInfo.displayName,
    }
    const agendaData = {
      estado_pagado: 2,
      fechaHora: value,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      numFlota: numFlota,
      estado: 3,
      fechaDeReAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueReAgendo: userInfo.displayName
    }
    dispatch(createAgenda(id, formData, agendaData, navigate))
    db.collection("agenda").doc(id).update({
      fechaDeReAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueReAgendo: userInfo.displayName
    })
  }

  const rechazarVenta = (id) => {

    db.collection("pre_ventas").doc(id).update({
      estado: 1,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName
    })

    db.collection("agenda").doc(id).update({
      estado: 4,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha rechazado la preventa", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });

  }

  const rechazarVentaSinEnvioVendedor = (id) => {

    db.collection("agenda").doc(id).update({
      estado: 4,
      rechazoVenta: observacion2
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha rechazado la preventa", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });

  }

  //
  const onData = () => {
    setOpenFoto(true)
    setData(props)
  }

  const respuesta = () => {

  }

  const clienteNoRespondeLlamada = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 3
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }

  const clienteNoDisponeDinero = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 4
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }

  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      {/* ----------------------------------------------------------------------- */}
      {/* Verificar fotos */}
      <Dialog open={openFoto} fullWidth onClose={() => setOpenFoto(false)} maxWidth="md">
        <DialogTitle><strong>VERIFICAMIENTO FOTOS DE: {data.nombres}</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <p>CEDULA FRONTAL</p>
                <img src={props.foto_cedula_frontal} style={{ width: "360px" }} />
              </Grid>
              <Grid item xs={6}>
                <p>CEDULA POSTERIOR</p>
                <img src={props.foto_cedula_trasera} style={{ width: "360px" }} />
              </Grid>
              <Grid item xs={6}>
                <p>CEDULA PLANILLA/SENSO</p>
                <img src={props.foto_cliente_planilla} style={{ width: '100%' }} />
              </Grid>
              <Grid item xs={6}>
                <p>Cliente con letrero</p>
                <img src={props.foto_cliente_letrero} style={{ width: "360px" }} />
              </Grid>
              <Grid item xs={6}>
                <p>Vivienda cliente</p>
                <img src={props.foto_cliente_vivienda} style={{ width: "360px" }} />
              </Grid>
              {
                props.foto_carnet_discapacidad &&
                <Grid item xs={6}>
                  <p>Foto carnet discapacidad</p>
                  <img src={props.foto_carnet_discapacidad} style={{ width: "360px" }} />
                </Grid>
              }

            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => setOpenFoto(false)}><strong>Cancelar</strong></Button>
          <Button color="error" disableElevation onClick={() => setOpenChangeColor(true)/* cambioColorEstado(data.id) */} variant="contained">
            {" "}
            Motivo no se agenda cliente
          </Button>
          <Button onClick={() => setOpenRechazo(true)} variant="contained">
            {" "}
            Rechazar venta
          </Button>
          <Button onClick={() => setOpen(true)} variant="contained">
            {" "}
            Reagendar
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------------------------------------------- */}
      {/* Formulario agendamiento */}
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle><strong>VERIFICAR AGENDAMIENTO DE 2: {data.nombres}</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box py={2}>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Seleccione"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </FormControl>
            </Box>

            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Flota</InputLabel>
                <Select
                  label="Flota"
                  value={flota}
                  onChange={(e) => handleFlota(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {flotas && flotas.map((row, key) => (
                    <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>
            {/* --------------------------------------------------------------- */}
            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Lider Flota</InputLabel>
                <Select
                  disabled
                  label="LiderFlota"
                  value={lider}
                  onChange={(e) => handleLider(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {liders && liders.map((row, key) => (
                    <MenuItem value={row.nombreLider}>{row.nombreLider}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>
            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Auxiliar</InputLabel>
                <Select
                  disabled
                  label="Auxiliar"
                  value={auxiliar}
                  onChange={(e) => handleAuxiliar(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {auxiliars && auxiliars.map((row, key) => (
                    <MenuItem value={row.nombreAuxiliar}>{row.nombreAuxiliar}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>



          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => setOpenConfirm(true)} variant="contained">
            {" "}
            Reagendar
          </Button>

        </DialogActions>
      </Dialog>
      {/* ---------------------------------------------------------- */}
      {/* Aceptacion agendamiento */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xs">
        <DialogTitle><strong>¿Esta seguro de Reagendar?</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => handleSubmit(data.id)} variant="contained">
            {" "}
            Reagendar
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------------------------------- */}
      {/* Rechazar venta */}
      <Dialog open={openRechazo} onClose={() => setOpenRechazo(false)} maxWidth="xs">
        <DialogTitle><strong>Seleccione el motivo por el cual rechaza esta venta</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Motivo del rechazo</InputLabel>
                <Select
                  label="Motivo del rechazo"

                  value={observacion2}
                  onChange={(e) => setObservacion2(e.target.value)}
                >
                  <MenuItem value="Datos incorrectos">Datos incorrectos</MenuItem>
                  <MenuItem value="Coordenadas no concuerdan con direccion">Coordenadas no concuerdan con direccion</MenuItem>
                  <MenuItem value="Cambio por baja de plan (Diferente al contrato)">Cambio por baja de plan (Diferente al contrato)</MenuItem>
                  <MenuItem value="Cliente con linea suspendida">Cliente con linea suspendida</MenuItem>
                  <MenuItem value="Imagenes subidas erroneamente">Imagenes subidas erroneamente</MenuItem>
                  <MenuItem value="Imagenes faltantes por el vendedor">Imagenes faltantes por el vendedor</MenuItem>
                  <MenuItem value="Cliente no contesta">Cliente no contesta</MenuItem>
                  <MenuItem value="Riesgo electrico">Riesgo electrico</MenuItem>
                  <MenuItem value="Cliente desiste del servicio">Cliente desiste del servicio</MenuItem>
                  <MenuItem value="Cliente no desea cancelar metraje adicional">Cliente no desea cancelar metraje adicional</MenuItem>
                  <MenuItem value="Zona sin posteria">Zona sin posteria</MenuItem>
                  <MenuItem value="Coordenadas erroneas">Coordenadas erroneas</MenuItem>
                  <MenuItem value="Sector sin propiedad legal / invasion">Sector sin propiedad legal / invasion</MenuItem>
                  <MenuItem value="Zona peligrosa">Zona peligrosa</MenuItem>
                  <MenuItem value="Caja llena sin disponibilidad de puertos">Caja llena sin disponibilidad de puertos</MenuItem>
                  <MenuItem value="Ventas duplicadas">Ventas duplicadas</MenuItem>
                  <MenuItem value="No tiene dinero">No tiene dinero</MenuItem>
                  <MenuItem value="Dificil Acceso">Dificil Acceso</MenuItem>

                </Select>
              </FormControl>
            </Box>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRechazo(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => rechazarVenta(data.id)} variant="contained">
            {" "}
            Rechazar
          </Button>

        </DialogActions>
      </Dialog>

      {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}

      <Dialog open={openChangeColor} onClose={() => setOpenChangeColor(false)} maxWidth="md">
        <DialogTitle>Seleccione el motivo por el cual no se agendo el cliente.</DialogTitle>
        <DialogContent>

          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
              <Select
                label="MOTIVO DEL RECHAZO"

                value={noSeAgendCliente}
                onChange={(e) => setNoSeAgendCliente(e.target.value)}
              >
                <MenuItem value="NO RESPONDEN LLAMADAS">NO RESPONDEN LLAMADAS</MenuItem>
                <MenuItem value="NO DISPONE DE DINERO">NO DISPONE DE DINERO</MenuItem>
              </Select>
            </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangeColor(false)}><strong>Cancelar</strong></Button>
          {
            noSeAgendCliente === 'NO RESPONDEN LLAMADAS' ? <Button onClick={() => clienteNoRespondeLlamada(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

          {
            noSeAgendCliente === 'NO DISPONE DE DINERO' ? <Button onClick={() => clienteNoDisponeDinero(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

        </DialogActions>
      </Dialog>

    </>
  );
};