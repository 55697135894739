import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, tabsClasses, Typography, Card } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { NewUsers } from '../NewUsers';
import { ListClientsPage } from '../ListClientsPage';
import { Container } from '@mui/system';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const NewUserPage = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1">RADIUS/<strong>NEW USER</strong></Typography>
        </Box>
        <Card>
          <Box sx={{ maxWidth: { xs: 300, sm: '100%' }, bgcolor: 'background.paper' }}>
            <Tabs
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                }
              }}
              value={tab}
              onChange={handleChange}
              aria-label="solicitud de servicio"
            >
              <Tab label="ACCOUNT INFO" {...tabs(0)} />
              <Tab label="LIST USER" {...tabs(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <NewUsers />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ListClientsPage />
          </TabPanel>
        </Card>
      </Container>
    </>
  )
}