import {
  Table, Typography, TableContainer, TableRow, TableCell,
  TableBody, TableHead, Box, Paper, Chip, Button, Collapse
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { db, firebase } from '../../../firebase'
import DatePicker from "react-datepicker";

import IconButton from '@mui/material/IconButton';
import * as XLSX from "xlsx/xlsx.mjs";
import { UpdateFeasibilityRechazado }
  from '../../../pages/operaciones/factibilidad/components/UpdateFeasibilityRechazado'
import FilterListIcon from '@mui/icons-material/FilterList';
function Estado({ estado }) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado == 0) {
    name = 'Esperando..'
    color = 'warning'
  } else if (estado === 1) {
    name = 'No factible'
    color = 'info'
  } else if (estado === 2) {
    name = 'FACTIBILIDAD'
    color = 'success'
  }
  return (
    <Chip label={name} color={color} />
  )
}

function FACTIBILIDAD({ factibilidad }) {
  let name;
  //Controlamos el estado de la factiblidad
  if (factibilidad == 0) {
    name = ''
  } else if (factibilidad === 1) {
    name = ''
  } else if (factibilidad === 2) {
    name = 'CONFIRMAR VENTA'
  }
  return (
    <Chip label={name} />
  )
}


const center = {
  lat: -3.745,
  lng: -38.523
};



export const SolicitudesRechazadas = () => {
  //Google APIS

  const [open, setOpen] = useState(false);
  const [data, setData] = useState()
  let current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const onChange2 = () => {
    LoadData()
  }

  useEffect(() => {


    LoadData()
  }, [])

  const LoadData = () => {

    let ref = db.collection("solicitudes").orderBy('created', 'desc').where('estado', '==', 1)

    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)

      ref.onSnapshot(snapshot => {
        const data = [
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];
        setData(data)

      })
    }
  }

  const handleExport = () => {
    const reData = [
      ...data.map(doc => {
        return {
          FECHA_SOLICITUD: doc.created ? moment(doc.created.toDate().toString()).format(
            "DD/MM/YYYY, h:mm:ss a") : '',
          ESTADO: doc.estado == 0 ? 'Esperando..' :
            (doc.estado == 1 ? 'No factible' : 'FACTIBILIDAD'),
          MOTIVO_RECHAZO: doc.observacion,
          CEDULA: doc.cedula,
          NOMBRE_CLIENTE: doc.nombres,
          FECHA_NACIMIENTO: doc.fechaNacimiento,
          DIRECCION: doc.direccion,
          CANTON: doc.canton,
          COORDENADAS: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          HORA_RECHAZO: doc.fechaRechazoFactibilidad ? moment(doc.fechaRechazoFactibilidad.toDate().toString()).format(
            "DD/MM/YYYY, h:mm:ss a") : '',
          USUARIO_RECHAZO: doc.usuario_que_rechazo_factibilidad,
          NOMBRE_VENDEDOR: doc.vendedor,
          ES_REFERIDO: doc.referido === '' ? 'NO' : doc.referido,
          VENTA_REFERIDA_FLOTA: doc.referido === 'SI' ? doc.flotaReferida.numeroFlota : 'NINGUNA',
        }
      })
    ]
    console.log(data, ' reData')
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Solicitudes")
    XLSX.writeFile(wb, `SOLICITUDES RECHAZADAS.xlsx`)
  }

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>({data ? data.length : 0})</strong> SOLICITUDES RECHAZADAS
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems='center' py={2}>

            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>

            <Button onClick={() => handleExport()}>EXPORT</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
        </Box>
      </Box>


      <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>FECHA DE CREACION DE SOLICITUD</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>MOTIVO DEL RECHAZO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>FECHA NACIMIENTO</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>CANTON</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>HORA DE RECHAZO DE SOLICITUD</TableCell>
                <TableCell>USUARIO QUE RECHAZO FACTIBILIDAD</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR</TableCell>
                <TableCell>ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA</TableCell>
                {/* <TableCell>USUARIO QUE RECHAZA</TableCell> */}
                <TableCell>ACCIONES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? data
                // .filter((val) => {
                //   if (search === '' || val.nombres.toLowerCase().includes(search.toLowerCase())
                //     || val.cedula.toLowerCase().includes(search.toLowerCase())) {
                //     return val
                //   }
                // })
                .map((row, key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {row.created &&
                        typeof row.created === "number" &&
                        moment(new Date().toString()).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      {row.created &&
                        typeof row.created === "object" &&
                        moment(row.created.toDate().toString()).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Estado estado={row.estado} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.observacion}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.cedula}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.nombres}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fechaNacimiento}
                    </TableCell>
                    <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>
                    <TableCell component="th" scope="row">
                      {row.direccion}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.canton}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.coordenadas._lat},{row.coordenadas._long}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.fechaRechazoFactibilidad &&
                        typeof row.fechaRechazoFactibilidad === "number" &&
                        moment(new Date().toString()).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      {row.fechaRechazoFactibilidad &&
                        typeof row.fechaRechazoFactibilidad === "object" &&
                        moment(row.fechaRechazoFactibilidad.toDate().toString()).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {
                        row.usuario_que_rechazo_factibilidad === undefined
                          || row.usuario_que_rechazo_factibilidad === null
                          || row.usuario_que_rechazo_factibilidad === ''
                          ? ''
                          : row.usuario_que_rechazo_factibilidad
                      }
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.vendedor}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.referido === '' ? 'NO' : row.referido}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                    </TableCell>
                    {/* CAMBIAR NOMBRE DE QUIEN RECHAZO*/}
                    {/* <TableCell component="th" scope="row">
                      {row.vendedor}
                    </TableCell> */}
                    <TableCell component="th" scope="row">


                      <UpdateFeasibilityRechazado props={row} />
                    </TableCell>
                  </TableRow>
                )) : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}