import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Box, Button, DialogActions, DialogTitle, TextField, Typography } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../firebase'
import { SaveItem } from "./SaveItem";
const VerBodega = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [motivoRechazo, setMotivoRechazo] = useState('');
    const [items, setItems] = useState([]);
    //
    const [orden, setOrden] = useState('');
    useEffect(() => {
        console.log(items, 'items...')
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
        loadNumOrden()
    }
    const loadItems = () => {
        var docRef = db.collection("solicitud_compra").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('a', props.uidSolicitud)
            setItems(data);
        });

    }
    const loadNumOrden = () => {
        const ref = db.collection("orden_compra").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                var uidSolicitud = `ORD-COM-` + numeroSolicitud;
                setOrden(numeroSolicitud)
            }
        })
    }
    const onSubmit = () => {
        setLoading(true)
        db.collection('solicitud_compra').doc(props.uidSolicitud).update({
            estado: 1,
            egreso: firebase.firestore.FieldValue.increment(1),
            fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
        })

    }
    //
    const onRechazar = () => {
        setLoading(true)
        db.collection('solicitud_compra').doc(props.uidSolicitud).update({
            estado: 2,
            motivo_rechazo: motivoRechazo,
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            Swal.fire({ icon: "info", text: 'Se rechazo su solicitud de pedido' });
            setLoading(false)
        })
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 100 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 400 },
        { field: 'cantidad', headerName: 'CANTIDAD SOLICITADA', width: 200 },

    ];

    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle>
                    SOLICITUD DE COMPRA: {props.id}
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                        
                    </Box>
                    <Box pt={2}>
                            <TextField
                                fullWidth
                                label='MOTIVO'
                                value={props.motivo}
                                disabled
                            />
                        </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        setOpen1(true)
                    }} color='error' variant="contained" disabled={loading} >RECHAZAR COMPRA</Button>
                    <Button onClick={onSubmit} color='success' variant="contained" disabled={loading} >CONFIRMAR COMPRA</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth

                open={open1}
            >
                <DialogTitle>
                    ESCRIBIR EL RECHAZO DE: {props.id}
                </DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <TextField
                            label='RECHAZO DE LA ENTREGA'
                            fullWidth
                            multiline
                            onChange={(e) => setMotivoRechazo(e.target.value)}
                            rows={4}
                        />
                    </Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setOpen1(false)}>CERRAR VENTANA</Button>
                    <Button onClick={onRechazar} color='error' variant="contained" disabled={loading || !motivoRechazo} >RECHAZAR ENTREGA</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerBodega