import React, { useState, useEffect } from 'react'
import { Select, Box, DialogTitle, Dialog, Grid, DialogActions, Button, DialogContent,
 FormControl, MenuItem, InputLabel } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingButton } from "@mui/lab";
import InputValidation from '../../../components/utils/InputValidation';
export const EditUser = ({ props }) => {
    const params = useParams()
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [data, setData] = useState('');
    const [linea, setLinea] = useState('');
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [password, setPassword] = useState({ campo: "", valido: true });
    const [loading, setLoading] = useState(false)
    useEffect(() => {
    }, []);
    const onData = () => {

        setModal(true)
        setData(props)
    }
    return (
        <>
            <Button size='small' variant="outlined" onClick={() => onData()}>EDIT USER</Button>
            <Dialog
                maxWidth="md"
                fullWidth
                open={modal}
                
            >
                
                <DialogTitle><strong>ACTUALIZAR USUARIO: {data.username}</strong></DialogTitle>
                <DialogContent dividers>
                    <form >
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Box pb={1}>
                                    <InputValidation
                                        estado={nombre}
                                        cambiarEstado={setNombre}
                                        label="USERNAME"
                                        name="USERNAME"
                                    />
                                </Box>
                                <InputValidation
                                    estado={password}
                                    cambiarEstado={setPassword}
                                    label="PASSWORD"
                                    name="password"
                                />
                                <Box pb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>PASSWORD TYPE</InputLabel>
                                        <Select
                                            value={linea}
                                            label="LINEA DE CONTRATO"
                                            onChange={(e) => { setLinea(e.target.value) }}
                                        >
                                            <MenuItem value="">-</MenuItem>
                                            <MenuItem value="L1">L1</MenuItem>
                                            <MenuItem value="L2">L2</MenuItem>
                                            <MenuItem value="L3">L3</MenuItem>
                                            <MenuItem value="L4">L4</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <FormControl fullWidth>
                                    <InputLabel>GROUP</InputLabel>
                                    <Select
                                        value={linea}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { setLinea(e.target.value) }}
                                    >
                                        <MenuItem value="">-</MenuItem>
                                        <MenuItem value="L1">L1</MenuItem>
                                        <MenuItem value="L2">L2</MenuItem>
                                        <MenuItem value="L3">L3</MenuItem>
                                        <MenuItem value="L4">L4</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pb={1}>

                                </Box>

                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button color='success' variant='contained'>ACTUALIZAR USUARIO</Button>
                    <Button onClick={() => setModal(false)}>CERRAR VENTANA</Button>
                </DialogActions>

            </Dialog>

        </>
    )
}