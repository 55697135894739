import React, { useState, useEffect } from 'react'
import { DataGrid, esES, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
    Box,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Paper,
    Button,
    Typography,
    Chip,
    Dialog,
    DialogTitle,
    Stack,
    CircularProgress
} from '@mui/material';
import { db, firebase } from '../../firebase';

export const CobranzaPichincha = () => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    useEffect(() => {
        cargarDatos()
    }, [])

    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const handleFilterModelChange = (model) => {
        console.log(model);
        setFilterModel(model);
    };

    function cargarDatos() {
        db.collection(`sftpcollectionPichincha`).orderBy("create", "desc")
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setData(arrayMessage)
            })
    }
    const columns = [
        { field: 'Id_Sobre', headerClassName: 'super-app-theme--header', headerName: 'Id Sobre', width: 100 },
        { field: 'Id_Item', headerClassName: 'super-app-theme--header', headerName: 'Id Item', width: 100 },
        {
            field: 'ReferenciaSobre',
            headerClassName: 'super-app-theme--header',
            headerName: 'Referencia Sobre', minWidth: 180
        },
        { field: 'Pais', headerClassName: 'super-app-theme--header', headerName: 'Pais', width: 50 },
        { field: 'Banco', headerClassName: 'super-app-theme--header', headerName: 'Banco', width: 80 },
        { field: 'Formapago', headerClassName: 'super-app-theme--header', headerName: 'Forma de Pago', width: 130 },
        { field: 'PaisBancoCuenta', headerClassName: 'super-app-theme--header', headerName: 'Pais Banco Cuenta', width: 200 },
        { field: 'Contrapartida', headerClassName: 'super-app-theme--header', headerName: 'Contrapartida', width: 120 },
        { field: 'Referencia', headerClassName: 'super-app-theme--header', headerName: 'Cliente', width: 120 },
        { field: 'ValorProcc', headerClassName: 'super-app-theme--header', headerName: 'Valor Procesado', width: 140 },
        { field: 'Valor', headerClassName: 'super-app-theme--header', headerName: 'Valor', width: 60 },
        { field: 'Moneda', headerClassName: 'super-app-theme--header', headerName: 'Moneda', width: 90 },
        { field: 'FechaProceso', headerClassName: 'super-app-theme--header', headerName: 'Fecha', width: 100 },
        { field: 'HoraProceso', headerClassName: 'super-app-theme--header', headerName: 'Hora', width: 100 },
        { field: 'Mensaje', headerClassName: 'super-app-theme--header', headerName: 'Mensaje', width: 100 },
        { field: 'ReferenciaAdicional', headerClassName: 'super-app-theme--header', headerName: 'Referencia', width: 120 },
        { field: 'NumeroDocumento', headerClassName: 'super-app-theme--header', headerName: 'No. Documento', width: 135 },
        { field: 'TipoPago', headerClassName: 'super-app-theme--header', headerName: 'TipoS Pago', width: 110 },
        { field: 'NumeroCuenta', headerClassName: 'super-app-theme--header', headerName: 'No. Cuenta', width: 110 },
        { field: 'No_Documento', headerClassName: 'super-app-theme--header', headerName: 'No. Documento', width: 135 },
        { field: 'Estado_Impresion', headerClassName: 'super-app-theme--header', headerName: 'Estado de Impresion', width: 180 },
        { field: 'Secuencial_Cobro', headerClassName: 'super-app-theme--header', headerName: 'Secuencial de Cobro', width: 180 },
        { field: 'Numero_Comprobante', headerClassName: 'super-app-theme--header', headerName: 'No. Comprobante', width: 150 },
        {
            field: 'Estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 160,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.estado === 1 ? <Chip label="PROCESADO" color='success' size="sm" /> : <Chip color='warning' label="NO PROCESADO" size="sm" />}
                    </>
                )
            }
        },

        { field: 'bitmap40', headerClassName: 'super-app-theme--header', headerName: 'Sms. Proceso', width: 150 },
        {
            field: 'btn?', headerClassName: 'super-app-theme--header', headerName: 'Cambiar Estado', width: 160,
            renderCell: (params) => {
                return (
                    <Button variant='contained' onClick={() => activar_pichincha(params.row)}>Procesar</Button>
                )
            }
        },
    ];

    function obtenerLinea(nombre) {
        const regex = /L(\d+)/;
        const match = nombre.match(regex);
        if (match) {
            const numero = parseInt(match[1], 10);
            return numero;
        } else {
            return 1;
        }
    }

    function getFechaContable(fecha_transaccion) {
        var fecha_itrans = fecha_transaccion;

        var day = fecha_transaccion.getDay();
        var hours = fecha_transaccion.getHours();

        if (day === 6 || day === 0) {
            fecha_itrans.setHours(8);
            fecha_itrans.setMinutes(0);
            fecha_itrans.setSeconds(0);

            if (day === 6) {
                fecha_itrans.setDate(fecha_itrans.getDate() + 2);
            }
            if (day === 0) {
                fecha_itrans.setDate(fecha_itrans.getDate() + 1);
            }
            return fecha_itrans;
        }

        if (day === 5 && hours > 16) {
            fecha_itrans.setHours(8);
            fecha_itrans.setMinutes(0);
            fecha_itrans.setSeconds(0);
            fecha_itrans.setDate(fecha_itrans.getDate() + 3)
            return fecha_itrans;
        }

        if (hours < 7) {
            fecha_itrans.setHours(8);
        } else if ((hours > 16)) {
            console.log("FECHA MAS UN DIA, NO ESTA EN EL RANGO");
            fecha_itrans.setHours(8);
            fecha_itrans.setMinutes(0);
            fecha_itrans.setSeconds(0);
            fecha_itrans.setDate(fecha_itrans.getDate() + 1);
        }

        return fecha_itrans;
    }
    const activar_pichincha = (datos) => {
        setOpen(true)
        //console.log('activar_pichincha', datos)
        if (datos.estado === 1) {
            setOpen(false)
            return

        };
        let linea = obtenerLinea(datos.Referencia);


        // const fechaParseada = moment(datos.FechaProceso, "DD/MM/YY").toDate();
        // const horaParseada = new Date(`1970-01-01T${datos.HoraProceso}Z`);
        // const fechaFormateada = `${datos.FechaProceso} ${datos.HoraProceso}`;
        // const fechaMoment = moment(fechaFormateada, 'DD/MM/YYYY HH:mm:ss');
        // const fechaDate = fechaMoment.toDate();
        // // Formatear la fecha y la hora según el formato deseado
        // const fecha = fechaParseada.toISOString().slice(0, 10).replace(/-/g, "");
        // const hora = horaParseada.toISOString().slice(11, 19).replace(/:/g, "");

        // let fecha_contable = getFechaContable(fechaDate)
        // fecha_contable = moment(fecha_contable, "DD/MM/YY").toDate();
        // const fechacontable = fecha_contable.toISOString().slice(0, 10).replace(/-/g, "");

        // let cedula = datos.Contrapartida.padStart(13, "0");
        // let contrapartida = "01" + cedula + "0" + linea;

        // let numeroConCeros = datos.ValorProcc.replace(".", "");
        // numeroConCeros = numeroConCeros.padStart(12, "0");
        // console.log("VALOR PAGADO:", numeroConCeros)


        // let raw = JSON.stringify({
        //     tipoMensaje: "0200",
        //     bitmapPrimario: "F01A040108008000",
        //     bitmap1: "0000004000000000",
        //     bitmap2: contrapartida,
        //     bitmap3: "000101",
        //     bitmap4: numeroConCeros,
        //     bitmap12: hora,
        //     bitmap13: fecha,
        //     bitmap15: fecha,
        //     bitmap22: "WEB",
        //     bitmap32: "03006",
        //     bitmap37: "000001",
        //     bitmap49: "840",
        //     bitmap90: numeroConCeros,
        //   });

        let raw = JSON.stringify({
            cedula: datos.Contrapartida,
            linea: linea,
            fecha: datos.FechaProceso,
            hora: datos.HoraProceso,
            deuda: datos.ValorProcc,
            id_base: datos.id

        });
        console.log(datos.id);
        
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "manual",
        };
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/pago_itrans_sftp", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                console.log(data)
                setOpen(false)
            }).catch((error) => {
                setOpen(false)
                db.collection("logs").add({
                    message: `API ITRANS TRANSACCIONAR ERROR ${data.Referencia} ${data.Contrapartida}`,
                    error: error,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    function: "CRON PICHINCHA",
                    usuario: "SOEI",
                });
                console.log("error itrans trasaccionar", error);
            });

        // fetch("http://45.236.141.201:8085/iTransWService/g2c/rest/yiga5/transaccionar", requestOptions)
        // .then((response) => response.text())
        // .then((result) => {
        //     const data = JSON.parse(result);

        //     if (data.bitmap39 === "00") {

        //     db.collection("sftpcollectionPichincha").doc(datos.id).update({
        //         estado: 1,
        //         bitmap39: data.bitmap39,
        //         bitmap40: data.bitmap40,
        //     });
        //     } else {
        //     db.collection("sftpcollectionPichincha").doc(datos.id).update({
        //         estado: 2,
        //         bitmap39: data.bitmap39,
        //         bitmap40: data.bitmap40,
        //     });
        //     }
        // }).catch((error) => {
        //     db.collection("logs").add({
        //     message: `API ITRANS TRANSACCIONAR ERROR ${data.Referencia} ${data.Contrapartida}`,
        //     error: error,
        //     created: db.firestore.FieldValue.serverTimestamp(),
        //     function: "CRON PICHINCHA",
        //     usuario: "SOEI",
        //     });
        //     console.log("error itrans trasaccionar", error);
        // });

        // fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/deuda", requestOptions)
        //     .then(response => response.text())
        //     .then(result => console.log(result))
        //     .catch(error => console.log('error', error));
    }


    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong>  <GridToolbarQuickFilter />
        </GridToolbarContainer>
    )



    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>Cobranza Archivo BANCO PICHINCA</strong> </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Box sx={{
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={data.length === 0}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={data}
                                        filterModel={filterModel}
                                        onFilterModelChange={handleFilterModelChange}
                                        getRowId={(row) => data.indexOf(row)}
                                        getRowHeight={() => 'auto'}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>


                </CardContent>
            </Card>

            <Dialog open={open} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    )
}