export const flotasAuxiliarReducers = (state = { auxiliars: [] }, action) => {
    switch (action.type) {
        case 'AUXILIAR_LIST_REQUEST':
            return { loading2: true }
        case 'AUXILIAR_LIST_SUCCESS':
            return { loading2: false, auxiliars: action.payload }
        case 'AUXILIAR_LIST_FAIL':
            return { loading2: false, error: action.payload }
        default:
            return state
    }
}