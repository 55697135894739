import { Radio, Tabs, Typography, Box, Tab, RadioGroup, Button, FormLabel, Divider, Select, FormControl, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { SolicitudAprobadas } from '../solicitud/SolicitudAprobadas';
import { AprobarSolicitudAprobadas } from './AprobarSolicitudAprobadas';
import { AprobarSolicitudesEspera } from './AprobarSolicitudesEspera';
import { AprobarSolicitudRechazado } from './AprobarSolicitudRechazado';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const AprobarSolicitudPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box >
        <Typography variant='h5' component='h1'><strong>APROBAR SOLICITUDES DE PEDIDOS</strong></Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label=" Solicitudes en Espera" {...a11yProps(0)} />
        <Tab label=" Solicitudes  Aprobadas" {...a11yProps(1)} />
        <Tab label=" Solicitudes  Rechazadas" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AprobarSolicitudesEspera />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AprobarSolicitudAprobadas />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AprobarSolicitudRechazado />
      </TabPanel>
    </>
  )
}