import React, { useState, useEffect } from "react";
import {
  Button,
  DialogActions, DialogContent, DialogTitle, Dialog, IconButton, Tooltip, Grid
} from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { db, functions } from '../../../firebase'
import Swal from "sweetalert2"
import EditIcon from "@mui/icons-material/Edit"
import CloseIcon from '@mui/icons-material/Close'
import InputValidation from "../../../components/utils/InputValidation"
export const UpdateMikroClient = ({ props }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState('')
  const [open, setOpen] = useState(false)
  const [nombres, setNombres] = useState({ campo: '', valido: true })
  const [direccion, setDireccion] = useState({ campo: '', valido: true })
  const [telefono, setTelefono] = useState({ campo: '', valido: true })
  const [celular, setCelular] = useState({ campo: '', valido: true })
  const [correo, setCorreo] = useState({ campo: '', valido: true })
  const [whatsapp, setWhatsapp] = useState({ campo: '', valido: true })
  const [numRef, setNumRef] = useState({ campo: '', valido: true })
  const onData = () => {
    setData(props)
    console.log(props, ' props')
    setOpen(true)
  }
  useEffect(() => {
    if (data) {
      setNombres({ campo: data.nombres, valido: true })
      setDireccion({ campo: data.direccion, valido: true })
      setTelefono({ campo: data.telefono, valido: true })
      setCelular({ campo: data.celular, valido: true })
      setCorreo({ campo: data.correo, valido: true })
      setWhatsapp({ campo: data.whatsapp, valido: true })
      setNumRef({ campo: data.numbRef, valido: true })
    }
  }, [data])
  const onSubmit = () => {

    if (data.user_id_microwisp) {
      setLoading(true)
      var linea_ = data.linea[1]
      console.log(linea_, ' linea')
      const form = {
        user_id_microwisp: data.user_id_microwisp,
        nombre: nombres.campo,
        nombres: nombres.campo,
        direccion: direccion.campo,
        telefono: telefono.campo,
        celular: celular.campo,
        correo: correo.campo,
        cedula: data.cedula,
        linea: linea_,
        numbRef: numRef.campo,
        whatsapp: whatsapp.campo,
      }
      console.log(form, ' form')
      const form2 = {
        ...form,
        linea: data.linea,
      }
      db.collection('agenda').doc(data.numContrato).update(form2)
      db.collection('tecnicos').doc(data.numContrato).update(form2)
      const updateMikroClient = functions.httpsCallable('updateMikroClient')
      updateMikroClient(form)
        .then(res => {
          setOpen(false)
          Swal.fire({ icon: "success", text: "Usuario actualizado" })
          setLoading(false)
        })
        .catch(error => {
          setOpen(false)
          setLoading(false)
          alert(error)
        })

    } else {
      alert('no tiene id microwips')
    }
  }
  const regresar = () => {
    setLoading(true)
    db.collection("agenda").doc(`${data.numContrato}`).get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data()
          if (data.estado !== 0) {
            console.log('Se regreso a pre agenda')
            db.collection("agenda").doc(`${data.numContrato}`).update({
              estado: 0,
            })
            setLoading(false)
            alert('Se regreso contrato a pre agenda')

          } else {
            setLoading(false)
            alert('Este contrato ya esta en pre agenda')
          }
        } else {
          setLoading(false)
          alert('Este contrato no existe')
        }
      })
  }
  const verpago = () => {
    db.collection("agenda").doc(`${data.numContrato}`).get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data()
          console.log(data, ' data 0')
          if (data.id_factura) {
            let requestOptions = {
              method: "GET"
            }
            fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?id=${data.id_factura}`, requestOptions)
              .then((response) => response.text())
              .then((result) => {
                const re = JSON.parse(result);

                if (re.length > 0) {
                  const dataF = re[0];

                  if (dataF.estado === "pagado") {
                    db.collection("agenda").doc(`${data.numContrato}`).update({
                      estado_pagado: 2
                    })
                    db.collection("tecnicos").doc(`${data.numContrato}`).update({
                      estado_pagado: 2
                    })
                    setLoading(false)
                    alert('Contrato pagado')

                  }
                  if (dataF.estado === "No pagado") {
                    alert('Este contrato no esta pagado')
                  }
                }
              })
          } else {
            setLoading(false)
            alert('No se le ha generado factura a este contrato')
          }
        } else {
          setLoading(false)
          alert('Este contrato no existe')
        }
      })
  }
  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogTitle>{data.nombres} idmikro: {data.user_id_microwisp}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <InputValidation
            estado={nombres}
            cambiarEstado={setNombres}
            label="NOMBRES"
            name="nombre"
          />
          <InputValidation
            estado={direccion}
            cambiarEstado={setDireccion}
            label="DIRECCION"
            name="direccion"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputValidation
                estado={telefono}
                cambiarEstado={setTelefono}
                label="TELEFONO"
                name="direccion"
                helperText="Minimo 10 caracteres"
                patterns={/^\d{10}$/}
              />

              <InputValidation
                estado={celular}
                cambiarEstado={setCelular}
                label="CELULAR"
                name="direccion"
                helperText="Iniciar con 09 y minimo 10 digitos"
                patterns={/^\d{10}$/}
              />
            </Grid>
            <Grid item xs={6}>
              <InputValidation
                estado={numRef}
                cambiarEstado={setNumRef}
                label="NUM REFERENCIA"
                name="direccion"
                helperText="Minimo 10 caracteres"
                patterns={/^\d{10}$/}
              />
              <InputValidation
                estado={whatsapp}
                cambiarEstado={setWhatsapp}
                label="Whatsapp"
                name="direccion"
                helperText="Minimo 10 caracteres"
                patterns={/^\d{10}$/}
              />
            </Grid>
          </Grid>


          <InputValidation
            estado={correo}
            cambiarEstado={setCorreo}
            patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
            helperText='Correo electronico no valido'
            label="CORREO ELECTRONICO"
            name="e-mail"
          />

        </DialogContent>
        <DialogActions>
          <Button
           
            onClick={() => regresar()}
            color='warning'
            variant='contained'>
            Regresar PreAgendamiento
          </Button>
          <Button
            onClick={() => verpago()}
            variant='contained'
            color='success'
          >
            Consultar Pago
          </Button>
          <Button
            variant='outlined'
            disabled={loading || correo.valido === false || !correo.campo || celular.valido === false
              || !celular.campo || telefono.valido === false || !telefono.campo || !direccion.campo
            }
            color='success'
            onClick={() => onSubmit()}
          >
            Actualizar cliente
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};