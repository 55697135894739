import EditIcon from "@mui/icons-material/Edit";
import {
    Grid,
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    Table,
    DialogActions,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    Paper,
    Tooltip
} from "@mui/material";

import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState } from "react";

import {
    listsTiposActivosFijos,
    listsSubCategoriasFirebase,
    listsClaseDeProducto,
    listsCategoriasFirebase,
    listsUnidadMedidaFirebase,
    listsVidaUtil,
    listsMetodoDeDepreciacion,
    listsPorcentajeDeDepreciacion,
    listsFrecuenciaDeDepreciacion,
    listsPresentacionProducto,
    listsMarcaFirebase,
    listsModeloFirebase,
    listsCondicionDeActivo,
    listsLocalidadProducto,
    listsDepartamento,
    listsTipoDeProducto,
    listsTipoTransaccion,
    listsSubcategoriaActivos,
    listsResidual,
    listsCategoriaActivos,
    listsUnidadMedidaActivos,
    listsMarcaActivos,
    listsModeloActivos
} from "../../redux/actions/parametersActions";

import SearchIcon from '@mui/icons-material/Search';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import { db } from "../../firebase";

import Swal from "sweetalert2";

export const UpdateActivoFijo = ({ props }) => {

    const getCuentas = () => {
        var docRef = db.collection("plan_cuentas_test")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const [item, setItem] = useState('')

    const [productos, setProductos] = useState('')

    /* GENERAL */

    const tipoDeProductList = useSelector(state => state.tipoDeProductList);
    const { tipoDeProductos, loadingtipoDeProductos } = tipoDeProductList
    const [tipoDeProducto, setTipoDeProducto] = useState(props.tipo_de_producto)

    const [codigoProducto, setCodigoProducto] = useState(props.codigo_producto)

    const [cuenta, setCuenta] = useState(props.cuenta_contable_mayor)

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const [descripcionProducto, setDescripcionProducto] = useState(props.descripcion_producto)

    const categoriaActivosList = useSelector((state) => state.categoriaActivosList);
    const { categoriaActivos, loadingCategoriaActivos } = categoriaActivosList;
    const [categoriaActivosOnly, setCategoriaActivos] = useState(props.categoria);

    const subcategoriaActivosList = useSelector((state) => state.subcategoriaActivosList);
    const { subcategoriaActivos, loadingSubcategoriaActivos } = subcategoriaActivosList;
    const [subcategoriaActivosOnly, setSubcategoriaActivos] = useState(props.sub_categoria);

    const claseDeProductoList = useSelector(state => state.claseDeProductoList);
    const { claseDeProductos, loadingclaseDeProductos } = claseDeProductoList
    const [claseDeProducto, setClaseDeProducto] = useState(props.clase_de_producto)

    const [cantidad, setCantidad] = useState(props.cantidad)

    const unidadMedidaActivosList = useSelector(state => state.unidadMedidaActivosList);
    const { unidadMedidaActivos, loadingUnidadMedidaActivos } = unidadMedidaActivosList
    const [unidadMedidaProducto, setUnidadMedidaProducto] = useState(props.unidad_medida)

    const [costo, setCosto] = useState(props.costo)

    const [fecha, setFecha] = useState(props.fecha)

    const addCount = (row) => {
        console.log(row.nombre)
        setCuenta(row.nombre)
        setOpenDialogCuentas(false)
    }

    const dispatch = useDispatch()

    /* INFORMACION TECNICA */

    const marcaActivosList = useSelector(state => state.marcaActivosList);
    const { marcaActivos, loadingMarcaActivos } = marcaActivosList
    const [marcaproducto, setMarcaProducto] = useState(props.marca_producto)

    const modeloActivosList = useSelector(state => state.modeloActivosList);
    const { modeloActivos, loadingModeloActivos } = modeloActivosList
    const [modeloproducto, setModeloProducto] = useState(props.modelo_producto)

    const [anioModelo, setAnioModelo] = useState(props.anio_Modelo)

    const [placa, setPlaca] = useState(props.placa)

    const [numeroSerie, setNumeroSerie] = useState(props.numero_serie)

    const [ultimoMantenimiento, setUltimoMantenimiento] = useState(props.ultimo_mantenimiento)

    const [seguimientoMantenimiento, setSeguimientoMantenimiento] = useState(props.seguimiento_mantenimiento)

    const [informacion1, setInformacion1] = useState(props.informacion1)

    const [informacion2, setInformacion2] = useState(props.informacion2)

    const [informacionTecnica, setInformacionTecnica] = useState(props.informacion_tecnica)

    const [informacionTecnica2, setInformacionTecnica2] = useState(props.informacion_tecnica2)

    const [fechaGarantia, setFechaGarantia] = useState(props.fecha_garantia)

    const condicionDeActivoList = useSelector((state) => state.condicionDeActivoList);
    const { condicionDeActivo, loadingCondicionDeActivo } = condicionDeActivoList;
    const [condicionDeActivoOnly, setCondicionDeActivoOnly] = useState(props.condicion_de_activo);





    /* DEPRECIACION */





    const vidaUtilList = useSelector((state) => state.vidaUtilList);
    const { vidaUtil, loadingVidaUtil } = vidaUtilList;
    const [vidaUtilOnly, setVidaUtilOnly] = useState(props.vida_util);

    const porcentajeDeDepreciacionList = useSelector((state) => state.porcentajeDeDepreciacionList);
    const { porcentajeDeDepreciacion, loadingPorcentajeDeDepreciacion } = porcentajeDeDepreciacionList;
    const [porcentajeDeDepreciacionOnly, setPorcentajeDeDepreciacionOnly] = useState(props.porcentaje_de_depreciacion);

    const metodoDeDepreciacionList = useSelector((state) => state.metodoDeDepreciacionList);
    const { metodoDeDepreciacion, loadingMetodoDeDepreciacion } = metodoDeDepreciacionList;
    const [metodoDeDepreciacionOnly, setMetodoDeDepreciacionOnly] = useState(props.metodo_de_depreciacion);

    const frecuenciaDeDepreciacionList = useSelector((state) => state.frecuenciaDeDepreciacionList);
    const { frecuenciaDeDepreciacion, loadingFrecuenciaDeDepreciacion } = frecuenciaDeDepreciacionList;
    const [frecuenciaDeDepreciacionOnly, setFrecuenciaDeDepreciacionOnly] = useState(props.frecuencia_de_depreciacion);


    const [fechaCompra, setFechaCompra] = useState(props.fecha_compra)

    const [fechaTermino, setFechaTermino] = useState(props.fecha_termino)



    /* SEGUROS */



    const [polizaNo, setPolizaNo] = useState(props.poliza_no)
    const [vigenciaDocumento, setVigenciaDocumento] = useState(props.vigencia_documento)
    const [caducidadDocumento, setCaducidadDocumento] = useState(props.caducidad_documento)
    const [sumaAsegurada, setSumaAsegurada] = useState(props.suma_asegurada)
    const [duracionSeguro, setDuracionSeguro] = useState(props.duracion_seguro)
    const [nombreAsegurado, setNombreAsegurado] = useState(props.nombre_asegurado)
    const [observaciones, setObservaciones] = useState(props.observaciones)
    const [proveedor, setProveedor] = useState(props.proveedor)

    /* LOCALIDAD */

    const localidadProductoList = useSelector((state) => state.localidadProductoList);
    const { localidadDeProductos, loadingLocalidadDeProductos } = localidadProductoList;
    const [localidadDeProducto, setLocalidadDeProducto] = useState(props.localidad_de_producto);

    const [memoriaDeLocalidad, setMemoriaDeLocalidad] = useState(props.memoria_de_localidad)

    const [archivadorPercha, setArchivadorPercha] = useState(props.archivador_percha)

    const departamentoList = useSelector((state) => state.departamentoList);
    const { departamentos, loadingDepartamentos } = departamentoList;
    const [departamento, setDepartamento] = useState(props.departamento);

    const [usuarios, setUsuarios] = useState('')
    const [users, setUsers] = useState(props.responsable)

    const [openDialogUsuarios, setOpenDialogUsuarios] = useState(false);

    const [itemUsuarios, setItemUsuarios] = useState('')

    const getUsers = () => {
        var docRef = db.collection("usuarios")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setUsuarios(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const addCountUsers = (row) => {
        console.log(row.nombre)
        setUsers(row.nombre)
        setOpenDialogUsuarios(false)
    }

    const [codigoDeBarra, setCodigoDeBarra] = useState(props.codigo_de_barra)

    const [notaDeConcesion, setNotaDeConcesion] = useState(props.nota_de_concesion)

    const [propietarioDelTitulo, setPropietarioDelTitulo] = useState(props.propietario_del_titulo)

    const [numeroDeReferenciaGis, setNumeroDeReferenciaGis] = useState(props.numero_de_referencia_gis)

    /* REFERENCIAS Y NOTAS */

    const [referencias, setReferencias] = useState(props.referencias)

    const [comentarios, setComentarios] = useState(props.comentarios)

    const [localidadDelDocumento, setLocalidadDelDocumento] = useState(props.localidad_del_documento)

    const [reestriccionesDeCancelacion, setReestriccionesDeCancelacion] = useState(props.reestricciones_de_cancelacion)

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

    /*  */

    useEffect(() => {
        dispatch(listsTiposActivosFijos())
        dispatch(listsTipoDeProducto())
        dispatch(listsSubCategoriasFirebase())
        dispatch(listsClaseDeProducto())
        dispatch(listsPresentacionProducto())
        dispatch(listsCategoriasFirebase())
        dispatch(listsUnidadMedidaFirebase())
        dispatch(listsVidaUtil())
        dispatch(listsMetodoDeDepreciacion())
        dispatch(listsPorcentajeDeDepreciacion())
        dispatch(listsFrecuenciaDeDepreciacion())
        dispatch(listsMarcaFirebase())
        dispatch(listsModeloFirebase())
        dispatch(listsCondicionDeActivo())
        dispatch(listsLocalidadProducto())
        dispatch(listsDepartamento())
        dispatch(listsTipoTransaccion())
        dispatch(listsSubcategoriaActivos())
        dispatch(listsResidual())
        dispatch(listsCategoriaActivos())
        dispatch(listsUnidadMedidaActivos())
        dispatch(listsMarcaActivos())
        dispatch(listsModeloActivos())
        getCuentas()
        getUsers()
    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const updateProducto = () => {
        console.log(props.id)
        db.collection('activos_fijos').doc(props.id).update({
            tipo_de_producto: tipoDeProducto,
            codigo_producto: codigoProducto,
            descripcion_producto: descripcionProducto.toUpperCase(),
            sub_categoria: subcategoriaActivosOnly,
            clase_de_producto: claseDeProducto,
            categoria: categoriaActivosOnly,
            cantidad: cantidad,
            unidad_medida: unidadMedidaProducto,
            costo: costo,
            fecha: fecha,
            vida_util: vidaUtilOnly,
            porcentaje_de_depreciacion: porcentajeDeDepreciacionOnly,
            metodo_de_depreciacion: metodoDeDepreciacionOnly,
            frecuencia_de_depreciacion: frecuenciaDeDepreciacionOnly,
            marca_producto: marcaproducto,
            modelo_producto: modeloproducto,
            anio_Modelo: anioModelo,
            placa: placa,
            numero_serie: numeroSerie,
            ultimo_mantenimiento: ultimoMantenimiento,
            seguimiento_mantenimiento: seguimientoMantenimiento,
            informacion1: informacion1,
            fecha_garantia: fechaGarantia,
            informacion_tecnica: informacionTecnica,
            informacion_tecnica2: informacionTecnica2,
            informacion2: informacion2,
            condicion_de_activo: condicionDeActivoOnly,
            localidad_de_producto: localidadDeProducto,
            memoria_de_localidad: memoriaDeLocalidad,
            archivador_percha: archivadorPercha,
            departamento: departamento,
            responsable: users,
            codigo_de_barra: codigoDeBarra,
            nota_de_concesion: notaDeConcesion,
            propietario_del_titulo: propietarioDelTitulo,
            numero_de_referencia_gis: numeroDeReferenciaGis,
            referencias: referencias,
            comentarios: comentarios,
            localidad_del_documento: localidadDelDocumento,
            reestricciones_de_cancelacion: reestriccionesDeCancelacion
        })

        setOpen(false)

        Swal.fire({ icon: "success", text: "Se ha actualizado el activo fijo con exito", });
    }

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xl">
                <DialogTitle>
                    <strong>EDICION DE PRODUCTO: {props.descripcion_producto}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box px={2} py={4}>


                        <Box pb={2}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>IDENTIFICACION</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* IDENTIFICACION */}


                                <Box pb={2}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>IDENTIFICACION</strong></p>
                                </Box>

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>TIPOS DE ACTIVOS FIJOS:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={tipoDeProducto}
                                                label="TIPO DE ACTIVO FIJO"
                                                onChange={(e) => setTipoDeProducto(e.target.value)}>
                                                {tipoDeProductos &&
                                                    tipoDeProductos.map(item => (
                                                        <MenuItem key={item.activo_fijo} value={item.activo_fijo} >
                                                            {item.activo_fijo}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* CODIGO */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>CODIGO DE ACTIVO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={codigoProducto}
                                                onChange={(e) => setCodigoProducto(e.target.value)}
                                                name="codigoProducto"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* CTA CONTABLE */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>CUENTA CONTABLE MAYOR:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cuenta}
                                            /* onChange={(e) => setCuenta(e.target.value)} */
                                            name="codigoProducto"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => setOpenDialogCuentas(true)}>
                                                        <SearchIcon color='primary' />
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box py={4}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>DESCRIPCION</strong></p>
                                </Box>

                                {/* Descripcion */}
                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>NOMBRE:</p>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={descripcionProducto}
                                            onChange={(e) => setDescripcionProducto(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <Box py={4}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>TIPO</strong></p>
                                </Box>


                                {/* SUBCATEGORIA */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>SUBCATEGORIA:</p>
                                    </Grid>

                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={subcategoriaActivosOnly}
                                                label="CATEGORIA"
                                                onChange={(e) => setSubcategoriaActivos(e.target.value)}>
                                                {subcategoriaActivos &&
                                                    subcategoriaActivos.map(item => (
                                                        <MenuItem key={item.subcategoria_activos} value={item.subcategoria_activos} >
                                                            {item.subcategoria_activos}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* CLASE DE PRODUCTO */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>CLASE DE ACTIVO:</p>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={claseDeProducto}
                                                    label="SUBCATEGORIA"
                                                    onChange={(e) => setClaseDeProducto(e.target.value)}>
                                                    {claseDeProductos &&
                                                        claseDeProductos.map(item => (
                                                            <MenuItem key={item.clase_de_producto} value={item.clase_de_producto} >
                                                                {item.clase_de_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* CATEGORIA */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>CATEGORIA:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={categoriaActivosOnly}
                                                label="CATEGORIA"
                                                onChange={(e) => setCategoriaActivos(e.target.value)}>
                                                {categoriaActivos &&
                                                    categoriaActivos.map(item => (
                                                        <MenuItem key={item.categoria_activos} value={item.categoria_activos} >
                                                            {item.categoria_activos}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={6}>

                                {/* CANTIDAD */}

                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>CANTIDAD</strong></p>


                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>CANTIDAD:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cantidad}
                                            onChange={(e) => setCantidad(e.target.value)}
                                            name="cantidadProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* UNIDAD DE MEDIDA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>UNIDAD MEDIDA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={unidadMedidaProducto}
                                                    label="PRESENTACION"
                                                    onChange={(e) => setUnidadMedidaProducto(e.target.value)}>
                                                    {unidadMedidaActivos &&
                                                        unidadMedidaActivos.map(item => (
                                                            <MenuItem key={item.unidad_medida} value={item.unidad_medida} >
                                                                {item.unidad_medida}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* COSTO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>COSTO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={costo}
                                            onChange={(e) => setCosto(e.target.value)}
                                            name="costoProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* FECHA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>FECHA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                <FormControl fullWidth>
                                                    <DatePicker
                                                        renderInput={(props) => <TextField {...props} size="small" />}
                                                        label="Seleccione"
                                                        value={fecha}
                                                        onChange={(newValue) => {
                                                            setFecha(newValue);
                                                        }}
                                                    />
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>DEPRECIACION</strong></p> */}

                                {/* VIDA UTIL */}

                                {/* <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>VIDA UTIL:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={vidaUtilOnly}
                                                    label="VIDA UTIL"
                                                    onChange={(e) => setVidaUtilOnly(e.target.value)}>
                                                    {vidaUtil &&
                                                        vidaUtil.map(item => (
                                                            <MenuItem key={item.vida_util} value={item.vida_util} >
                                                                {item.vida_util}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box> */}

                                {/* PORCENTAJE DE DEPRECIACION */}

                                {/* <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>%:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={porcentajeDeDepreciacionOnly}
                                                label="VIDA UTIL"
                                                onChange={(e) => setPorcentajeDeDepreciacionOnly(e.target.value)}>
                                                {porcentajeDeDepreciacion &&
                                                    porcentajeDeDepreciacion.map(item => (
                                                        <MenuItem key={item.procentaje_de_depreciacion} value={item.procentaje_de_depreciacion} >
                                                            {item.procentaje_de_depreciacion}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}

                                {/* METODO DE DEPRECIACION */}

                                {/* <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>METODO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={metodoDeDepreciacionOnly}
                                                    label="METODO DE DEPRECIACION"
                                                    onChange={(e) => setMetodoDeDepreciacionOnly(e.target.value)}>
                                                    {metodoDeDepreciacion &&
                                                        metodoDeDepreciacion.map(item => (
                                                            <MenuItem key={item.metodo_de_depreciacion} value={item.metodo_de_depreciacion} >
                                                                {item.metodo_de_depreciacion}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box> */}

                                {/* FRECUENCIA DE DEPRECIACION */}

                                {/* <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>FRECUENCIA DE DEPRECIACION:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={frecuenciaDeDepreciacionOnly}
                                                label="VIDA UTIL"
                                                onChange={(e) => setFrecuenciaDeDepreciacionOnly(e.target.value)}>
                                                {frecuenciaDeDepreciacion &&
                                                    frecuenciaDeDepreciacion.map(item => (
                                                        <MenuItem key={item.frecuencia_depreciacion} value={item.frecuencia_depreciacion} >
                                                            {item.frecuencia_depreciacion}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}



                            </Grid>

                        </Grid>
                    </Box>

                    {/* INFORMACION TECNICA */}


                    <Box px={2} py={4}>

                        <Box pb={2}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>INFORMACION TECNICA</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* CODIGO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>MARCAS:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={marcaproducto}
                                                label="MARCA"
                                                onChange={(e) => setMarcaProducto(e.target.value)}>
                                                {marcaActivos &&
                                                    marcaActivos.map(item => (
                                                        <MenuItem key={item.marca_activos} value={item.marca_activos} >
                                                            {item.marca_activos}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* MODELO */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>MODELO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={modeloproducto}
                                                    label="MODELO"
                                                    onChange={(e) => setModeloProducto(e.target.value)}>
                                                    {modeloActivos &&
                                                        modeloActivos.map(item => (
                                                            <MenuItem key={item.modelo_activos} value={item.modelo_activos} >
                                                                {item.modelo_activos}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* ANIO MODELO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>ANIO MODELO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={anioModelo}
                                            onChange={(e) => setAnioModelo(e.target.value)}
                                            name="anioModelo"
                                            size="small"
                                            fullWidth
                                            type='number'
                                        />
                                    </Grid>
                                </Grid>

                                {/* PLACA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>PLACA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={placa}
                                                onChange={(e) => setPlaca(e.target.value)}
                                                name="placa"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* NUMERO SERIE */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>NUMERO SERIE:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={numeroSerie}
                                            onChange={(e) => setNumeroSerie(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                            type='number'
                                        />
                                    </Grid>
                                </Grid>

                                <Box py={4}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ACTUALIZAR</strong></p>
                                </Box>

                                {/* ULTIMO MANTENIMIENTO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>ULTIMO MANTENIMIENTO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ultimoMantenimiento}
                                            onChange={(e) => setUltimoMantenimiento(e.target.value)}
                                            name="ultimoMantenimiento"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* SEGUIMIENTO MANTENIMIENTO */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>SEGUIMIENTO MANTENIMIENTO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={seguimientoMantenimiento}
                                                onChange={(e) => setSeguimientoMantenimiento(e.target.value)}
                                                name="descripcionProducto"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* INFORMACION 1 */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>INFORMACION 1:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={informacion1}
                                            onChange={(e) => setInformacion1(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={6}>

                                {/* FECHA GARANTIA */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>FECHA GARANTIA:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <FormControl fullWidth>
                                                <DatePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="Seleccione"
                                                    value={fechaGarantia}
                                                    onChange={(newValue) => {
                                                        setFechaGarantia(newValue);
                                                    }}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                {/* INFORMACION TECNICA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>INFORMACION TECNICA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={informacionTecnica}
                                                onChange={(e) => setInformacionTecnica(e.target.value)}
                                                name="descripcionProducto"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* INFORMACION TECNICA 2 */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>INFORMACION TECNICA 2:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={informacionTecnica2}
                                            onChange={(e) => setInformacionTecnica2(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                </Grid>

                                {/* INFORMACION 2 */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>INFORMACION 2:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={informacion2}
                                                onChange={(e) => setInformacion2(e.target.value)}
                                                name="descripcionProducto"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* CONDICION ACTIVO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>CONDICION ACTIVO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={condicionDeActivoOnly}
                                                label="MODELO"
                                                onChange={(e) => setCondicionDeActivoOnly(e.target.value)}>
                                                {condicionDeActivo &&
                                                    condicionDeActivo.map(item => (
                                                        <MenuItem key={item.condicion_de_activo} value={item.condicion_de_activo} >
                                                            {item.condicion_de_activo}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Box>






                    {/* DEPRECIACION */}







                    <Box px={2} py={2}>

                        <Box>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>DEPRECIACION</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* VIDA UTIL */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>VIDA UTIL:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={vidaUtilOnly}
                                                    label="VIDA UTIL"
                                                    onChange={(e) => setVidaUtilOnly(e.target.value)}>
                                                    {vidaUtil &&
                                                        vidaUtil.map(item => (
                                                            <MenuItem key={item.vida_util} value={item.vida_util} >
                                                                {item.vida_util}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* PORCENTAJE DE DEPRECIACION */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>%:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={porcentajeDeDepreciacionOnly}
                                                label="VIDA UTIL"
                                                onChange={(e) => setPorcentajeDeDepreciacionOnly(e.target.value)}>
                                                {porcentajeDeDepreciacion &&
                                                    porcentajeDeDepreciacion.map(item => (
                                                        <MenuItem key={item.procentaje_de_depreciacion} value={item.procentaje_de_depreciacion} >
                                                            {item.procentaje_de_depreciacion}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* METODO DE DEPRECIACION */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>METODO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={metodoDeDepreciacionOnly}
                                                    label="METODO DE DEPRECIACION"
                                                    onChange={(e) => setMetodoDeDepreciacionOnly(e.target.value)}>
                                                    {metodoDeDepreciacion &&
                                                        metodoDeDepreciacion.map(item => (
                                                            <MenuItem key={item.metodo_de_depreciacion} value={item.metodo_de_depreciacion} >
                                                                {item.metodo_de_depreciacion}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* FRECUENCIA DE DEPRECIACION */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>FRECUENCIA DE DEPRECIACION:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={frecuenciaDeDepreciacionOnly}
                                                label="VIDA UTIL"
                                                onChange={(e) => setFrecuenciaDeDepreciacionOnly(e.target.value)}>
                                                {frecuenciaDeDepreciacion &&
                                                    frecuenciaDeDepreciacion.map(item => (
                                                        <MenuItem key={item.frecuencia_depreciacion} value={item.frecuencia_depreciacion} >
                                                            {item.frecuencia_depreciacion}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>



                            </Grid>

                            <Grid item xs={6}>


                                {/* FECHA COMPRA */}


                                <Box py={4}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA COMPRA:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <FormControl fullWidth>
                                                <DatePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="Seleccione"
                                                    value={fechaCompra}
                                                    onChange={(newValue) => {
                                                        setFechaCompra(newValue);
                                                    }}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </Grid>
                                </Box>

                                {/* FECHA TERMINO */}

                                <Grid item xs={2}>
                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA COMPRA:</p>
                                </Grid>
                                <Grid item xs={5}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <FormControl fullWidth>
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                label="Seleccione"
                                                value={fechaCompra}
                                                onChange={(newValue) => {
                                                    setFechaCompra(newValue);
                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Box>


                    {/* SEGUROS */}





                    <Box px={2} py={4}>
                        <Box pb={2}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>SEGUROS</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* REFERENCIAS */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>POLIZA NO.:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={polizaNo}
                                            onChange={(e) => setPolizaNo(e.target.value)}
                                            name="polizaNo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* COMENTARIOS */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>VIGENCIA DOCUMENTO:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={vigenciaDocumento}
                                                onChange={(e) => setVigenciaDocumento(e.target.value)}
                                                name="vigenciaDocumento"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* CADUCIDAD DOCUMENTO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CADUCIDAD DOCUMENTO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={caducidadDocumento}
                                            onChange={(e) => setCaducidadDocumento(e.target.value)}
                                            name="caducidadDocumento"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* SUMA ASEGURADA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>SUMA ASEGURADA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={sumaAsegurada}
                                                onChange={(e) => setSumaAsegurada(e.target.value)}
                                                name="sumaAsegurada"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* DURACION SEGURO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>DURACION SEGURO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={duracionSeguro}
                                            onChange={(e) => setDuracionSeguro(e.target.value)}
                                            name="duracionSeguro"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>



                            </Grid>

                            <Grid item xs={6}>

                                {/* NOMBRE ASEGURADO */}


                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>NOMBRE ASEGURADO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombreAsegurado}
                                            onChange={(e) => setNombreAsegurado(e.target.value)}
                                            name="nombreAsegurado"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* OBSERVACIONES */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>OBSERVACIONES:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={observaciones}
                                                onChange={(e) => setObservaciones(e.target.value)}
                                                name="observaciones"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* PROVEEDOR */}


                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>PROVEEDOR:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={proveedor}
                                            onChange={(e) => setProveedor(e.target.value)}
                                            name="proveedor"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                    </Box>






                    {/* LOCALIDAD */}








                    <Box px={2} py={4}>

                        <Box pb={2}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>LOCALIDAD</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* LOCALIDAD */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>LOCALIDAD:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={localidadDeProducto}
                                                label="MODELO"
                                                onChange={(e) => setLocalidadDeProducto(e.target.value)}>
                                                {localidadDeProductos &&
                                                    localidadDeProductos.map(item => (
                                                        <MenuItem key={item.localidad_de_producto} value={item.localidad_de_producto} >
                                                            {item.localidad_de_producto}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* MEMORIA DE LOCALIDAD */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>MEMORIA DE LOCALIDAD:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={memoriaDeLocalidad}
                                                onChange={(e) => setMemoriaDeLocalidad(e.target.value)}
                                                name="memoriaDeLocalidad"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* ARCHIVADOR / PERCHA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>ARCHIVADOR / PERCHA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={archivadorPercha}
                                                onChange={(e) => setArchivadorPercha(e.target.value)}
                                                name="archivadorPercha"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* ASIGNACION */}

                                <Box py={4}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ASIGNACION</strong></p>
                                </Box>

                                {/* DEPARTAMENTO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>DEPARTAMENTO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={departamento}
                                                label="MODELO"
                                                onChange={(e) => setDepartamento(e.target.value)}>
                                                {departamentos &&
                                                    departamentos.map(item => (
                                                        <MenuItem key={item.departamento} value={item.departamento} >
                                                            {item.departamento}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* RESPONSABLE */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>RESPONSABLE:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                name={"cuenta"}
                                                value={users}
                                                onChange={(e) => setUsers(e.target.value)}
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenDialogUsuarios(true)}>
                                                            <SearchIcon color='primary' />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* CODIGO DE BARRA */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>CODIGO DE BARRA:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={codigoDeBarra}
                                                onChange={(e) => setCodigoDeBarra(e.target.value)}
                                                name="codigoDeBarra"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>

                                {/* CONCESION */}


                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>CONCESION</strong></p>

                                {/* NOTA DE CONCESION */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>NOTA DE CONCESION:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={notaDeConcesion}
                                                onChange={(e) => setNotaDeConcesion(e.target.value)}
                                                name="notaDeConcesion"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* PROPIETARIO DEL TITULO */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>PROPIETARIO DEL TITULO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={propietarioDelTitulo}
                                            onChange={(e) => setPropietarioDelTitulo(e.target.value)}
                                            name="propietarioDelTitulo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* NUMERO DE REFERENCIA GIS */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>NUMERO DE REFERENCIA GIS:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={numeroDeReferenciaGis}
                                                onChange={(e) => setNumeroDeReferenciaGis(e.target.value)}
                                                name="descripcionProducto"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                {/* DIALOG USUARIOS*/}
                                <Dialog open={openDialogUsuarios} onClose={() => setOpenDialogUsuarios(false)} maxWidth="xl">
                                    <DialogTitle><strong>SELECCIONAR UN RESPONSABLE</strong></DialogTitle>
                                    <DialogContent>
                                        <FormControl fullWidth>
                                            <Box pb={36}>

                                                <Box pb={2}>
                                                    <Autocomplete
                                                        size='small'
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={usuarios}
                                                        getOptionLabel={(option) => option.nombre}
                                                        onInputChange={(e, newInputValue) => {
                                                            console.log(item)
                                                            setItemUsuarios(newInputValue)
                                                            setUsuarios(newInputValue)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="BUSCAR CUENTA" />}
                                                    />
                                                </Box>

                                                <Table size="small" stickyHeader aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {usuarios && usuarios
                                                            .filter((val) => {
                                                                if (itemUsuarios === "") {
                                                                    return val;
                                                                } else if (val.nombre.toLowerCase().includes(itemUsuarios.toLowerCase())) {
                                                                    return val;
                                                                }
                                                            }).map((row) => (
                                                                <TableRow key={row.nombre}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                    <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                                    <TableCell style={{ fontSize: '11px' }}><Button
                                                                        size='small'
                                                                        variant='contained'
                                                                        color='success' style={{ fontSize: '11px' }}
                                                                        onClick={() => addCountUsers(row)}
                                                                    >Seleccionar</Button></TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>

                                            </Box>
                                        </FormControl>
                                    </DialogContent>

                                    <DialogActions>
                                        <Button onClick={() => setOpenDialogUsuarios(false)}><strong>Cancelar</strong></Button>
                                        <Button onClick={() => setOpenDialogUsuarios(false)} size="small" variant="contained">
                                            {" "}
                                            ACEPTAR
                                        </Button>
                                    </DialogActions>

                                </Dialog>

                                {/* INFORMACION TECNICA */}



                                {/* INFORMACION TECNICA 2 */}



                                {/* INFORMACION 2 */}





                            </Grid>

                        </Grid>
                    </Box>













                    {/* REFERENCIAS Y NOTAS */}

                    <Box px={2} py={4}>


                        <Box pb={2}>
                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>REFERENCIAS Y NOTAS</strong></p>
                        </Box>

                        <Grid container spacing={5}>

                            <Grid item xs={6}>

                                {/* REFERENCIAS */}

                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>REFERENCIAS:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={referencias}
                                            onChange={(e) => setReferencias(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* COMENTARIOS */}

                                <Box py={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>COMENTARIOS:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={comentarios}
                                                onChange={(e) => setComentarios(e.target.value)}
                                                name="descripcionProducto"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>

                            <Grid item xs={6}>

                                {/* LOCALIDAD DEL DOCUMENTO */}


                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>LOCALIDAD DEL DOCUMENTO:</p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={localidadDelDocumento}
                                            onChange={(e) => setLocalidadDelDocumento(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                {/* REESTRICCIONES DE CANCELACION */}

                                <Box pt={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>REESTRICCIONES DE CANCELACION:</p>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={reestriccionesDeCancelacion}
                                                onChange={(e) => setReestriccionesDeCancelacion(e.target.value)}
                                                name="reestriccionesDeCancelacion"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* DIALOG CUENTAS*/}
                    <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                        <DialogTitle><strong>SELECCIONAR UNA CUENTA</strong></DialogTitle>
                        <DialogContent>
                            <FormControl fullWidth>
                                <Box pb={36}>

                                    <Box pb={2}>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => option.cuenta}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setItem(newInputValue)
                                                setCuenta(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CUENTA" />}
                                        />
                                    </Box>

                                    <Table size="small" stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>GRUPO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {productos && productos
                                                .filter((val) => {
                                                    if (item === "") {
                                                        return val;
                                                    } else if (val.cuenta.toLowerCase().includes(item.toLowerCase())) {
                                                        return val;
                                                    }
                                                }).map((row) => (
                                                    <TableRow key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell style={{ fontSize: '11px' }}>{row.cuenta}</TableCell>
                                                        <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                        <TableCell style={{ fontSize: '11px' }}>{row.estado}</TableCell>
                                                        <TableCell style={{ fontSize: '11px' }}><Button
                                                            size='small'
                                                            variant='contained'
                                                            color='success' style={{ fontSize: '11px' }}
                                                            onClick={() => addCount(row)}
                                                        >Seleccionar</Button></TableCell>
                                                    </TableRow>
                                                ))}

                                        </TableBody>
                                    </Table>

                                </Box>

                                {/*  */}


                            </FormControl>
                        </DialogContent>

                        <DialogActions>
                            {/* <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                            <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                                {" "}
                                ACEPTAR
                            </Button> */}
                        </DialogActions>

                    </Dialog>

                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button variant="contained" color="primary" disabled={tipoDeProducto === ''
                            || codigoProducto === ''
                            || descripcionProducto === ''
                            || SubCategoria === ''
                            || claseDeProducto === ''
                            || categoria === ''
                            || cantidad === ''
                            || unidadMedidaProducto === ''
                            || costo === ''
                            || fecha === ''
                            || vidaUtil === ''
                            || porcentajeDeDepreciacion === ''
                            || metodoDeDepreciacion === ''
                            || frecuenciaDeDepreciacion === ''
                        }>
                        CONTINUAR
                        </Button>
                    </Box> */}

                    <img src={props.imagen_activo_fijo} style={{ width: "40%" }} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"

                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};