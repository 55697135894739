import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    TextField
} from "@mui/material";
import { db } from "../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from "react";

import Swal from 'sweetalert2'

export const EditSector = ({ props, provincia, canton }) => {

    const [total, setTotal] = useState(0);

    const [openModal, setOpenModal] = useState(false)

    const [sector, setSector] = useState(props.nombre);

    const updateSector = () => {
        let ref = db.collection("parameters").doc("cantones").collection("provincias").doc(provincia).collection("canton").doc(canton).collection("parroquia").doc("TODOS").collection("sector").doc(props.id).update({
            nombre: sector
        })
    }

    const handleConfirm = () => {
        updateSector()
        setOpenModal(false)
        Swal.fire({ icon: "success", text: 'Se ha actualizado el sector con exito', });
    }

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* DIALOG CUENTAS*/}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm" fullWidth>
                <DialogTitle>EDITAR SECTOR: <strong>{props.nombre}</strong></DialogTitle>
                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>Sector:</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    name={"cuenta"}
                                    value={sector}
                                    onChange={(e) => setSector(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => handleConfirm()}>ACTUALIZAR</Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
