import { IconButton, Table, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Box, Paper, Divider, Chip, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'
import { auth, db, firebase } from '../../firebase'
import { useSelector } from 'react-redux';
import { ExportarCSV } from '../../components/utils/ExportarCSV';
import DatePicker from "react-datepicker";
import Collapse from '@mui/material/Collapse';
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from '../../components/utils/SearchInput';
import FilterListIcon from '@mui/icons-material/FilterList';
function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
        name = 'ESPERANDO..'
        color = 'warning'
    } else if (estado === 1) {
        name = 'NO FACTIBLE'
        color = 'info'
    } else if (estado === 2) {
        name = 'FACTIBILIDAD'
        color = 'success'
    }
    return (
        <Chip label={name} color={color} />
    )
}
export const GrupoVentas = () => {
    const [open, setOpen] = useState(null);
    const [data, setData] = useState()
    const [isLastPage, setIsLastPage] = useState(0)
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [reData, setreData] = useState([])
    //
    const [search, setSearch] = useState('')
    //var current = new Date();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const [value, setValue] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(() => {
        LoadData()
    }, [startDate])
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const LoadData = (startAfterDoc, persistMessages = []) => {
        console.log(startDate, '..', endDate)
        let ref = db.collection("solicitudes").orderBy('created', 'desc').where('grupo', '==', 1)
        if (userInfo.rol === 1) ref = ref.where('email', '==', 'joffre10gar@gmail.com')
        
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }
    }
    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        const reData = [
            ...data.map(doc => {
                return {
                    ...doc,
                    assigned: doc.assigned.toDate(),
                    created: doc.created.toDate(),
                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Licencias");
        XLSX.writeFile(wb, `Reporte_de_Licencias_${date1}.xlsx`)
    }
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }
    const onChange2 = () => {
        LoadData()
    }
    
    return (
        <>
            <Box pt={1} pl={1}>
                <Typography >
                    <strong>({data && data.length})</strong> SOLICITUDES APROBADAS
                </Typography >
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                </Box>
                <Box>
                    <Box display="flex" alignItems='center' py={2}>
                        <SearchInput setSearch={setSearch} search={search} />
                        <IconButton id="basic-button" onClick={() => setOpen(!open)}>
                            <FilterListIcon />
                        </IconButton>
                        <ExportarCSV data={data} />
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
                <TableContainer sx={{ maxHeight: 540 }}>
                    <Table size="small" stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>ESTADO</TableCell>
                                {/* <TableCell>ACCIONES</TableCell> */}
                                <TableCell>CLIENTE</TableCell>
                                <TableCell>DIRECCION</TableCell>
                                <TableCell>CEDULA</TableCell>
                                <TableCell>FECHA NACIMIENTO</TableCell>
                                <TableCell>COORDENADAS</TableCell >
                                <TableCell>CAJA</TableCell >
                                <TableCell>CREACIÓN</TableCell>
                                <TableCell>VENDEDOR</TableCell>
                                <TableCell>CARGO</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.filter((val) => {
                                if (search === '') {
                                    return val
                                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                } else if (val.direccion.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }else if (val.caja.toLowerCase().includes(search.toLowerCase())) {
                                    return val
                                }
                            }).map((row, key) => (
                                <TableRow hover key={key}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Estado estado={row.estado} />
                                    </TableCell>
                                    {/* <TableCell component="th" scope="row">
                                        <ViewFactibilitySale props={row} />
                                    </TableCell> */}
                                    <TableCell component="th" scope="row">
                                        {row.nombres}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.direccion}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.cedula}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.fechaNacimiento}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.coordenadas._lat},{row.coordenadas._long}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.caja}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.created && typeof row.created === 'number' &&
                                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                                        {row.created && typeof row.created === 'object' &&
                                            moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        { row.vendedor}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.cargo}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    )
}