import {
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableHead,
  Button,
  LinearProgress,
  Select,
  TableCell,
  IconButton,
  Autocomplete,
  TableRow,
  TableContainer,
  TableBody,
  Divider,
  Box,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  MenuItem,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import UploadIcon from "@mui/icons-material/Upload";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import React, { useEffect, useState, useRef } from "react";
import { db, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";

import {
  listsBodegas,
  listsUbicaciones,
  listsMotivoIngresoProductos,
} from "../../../redux/actions/parametersActions";
import { listFlotas } from "../../../redux/actions/flotas/flotasActions";
import { CargaSeriesIngresoProductos } from "./carga_series/CargaSeriesIngresoProductos";


import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import { DateTimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const MotivoIngreso = () => {

  const [loadingButtonIngreso, setLoadingButtonIngreso] = useState(false);

  const [fechaDocumento, setFechaDocumento] = useState('')

  const [sumaBodegas, setSumaBodegas] = useState("");

  const dataProduct = async (newInputValue) => {
    /* const agendaDB = await db.collection("productos").where("codigo_producto", "==", newInputValue).get()
    const data = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))


    const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", newInputValue).get()

    const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))



    let suma = 0;
    for (let i = 0; i < data3.length; i++) {
      console.log(data3[i]["stock_actual"])
      suma += parseFloat(data3[i]["stock_actual"])
      setSumaBodegas(suma)
      datoSumaTotalBodegas = suma
    } */
  }

  const dispatch = useDispatch();

  const [reporteStockArray, setReporteStockArray] = useState('');
  const [reporteStockArrayFilter, setReporteStockArrayFilter] = useState();

  const recorrido = () => {

    let docRefReporteStockArray = db.collection("reporte_stock");
    docRefReporteStockArray
      .get()
      .then((querySnapshot) => {
        const arrayMessage = querySnapshot.docs.map((item) => {
          return { ...item.data(), id: item.id };
        });
        setReporteStockArray(arrayMessage);
        addInputFilterReporteStock(arrayMessage)
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  const addInputFilterReporteStock = (row) => {
    console.log(row, "row response");
    /* console.log(row, "row response"); */
    /* console.log(reporteStockArray, 'reporte stock array') */
    if (reporteStockArrayFilter.find((x) => x.codigo_producto === row.codigo_producto)) {

      console.log('Ya se agrego este producto a la lista')
    } else {
      setReporteStockArrayFilter([
        ...reporteStockArrayFilter,
        {
          codigo_producto: row.codigo_producto,
          descripcion: row.descripcion,
          stock_actual: row.stock_actual
        },
      ]);
    }
    /* setItem("");
    setModal(false); */
  };

  useEffect(() => {
    /* recorrido() */
  }, [/* recorrido() */]);

  const CargaSeriesIngresoProductos = ({
    props,
    bodega,
    codigo,
    numPedido,
  }) => {

    /* esxcel upload */

    const [openUploadExcelSeries, setOpenUploadExcelSeries] = useState(false)

    /* const handleOpenDialogUploadExcel = () => {
        setOpenUploadExcelSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadExcel = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadExcelSeries(false)
    } */

    const [openDialogExcelSuccess, setOpenDialogExcelSuccess] = useState(false)

    /*  */

    const userAuth = useSelector((state) => state.userAuth);
    const { loadin, userInfo } = userAuth;

    const [open, setOpen] = useState(null);
    //
    const onData = () => {
      setOpen(true);
    };

    const [openIngresoManual, setOpenIngresoManual] = useState(null);

    const onDataIngresoManual = () => {
      setOpenIngresoManual(true);
      setOpen(false);
    };

    const onCloseIngresoManual = () => {
      setOpen(true);
      setOpenIngresoManual(false);
    };

    const [openIngresoSeriesAutomaticas, setOpenIngresoSeriesAutomaticas] =
      useState(null);

    const onDataIngresoSeriesAutomaticas = () => {
      setOpenIngresoSeriesAutomaticas(true);
      setOpen(false);
    };

    const onCloseIngresoSeriesAutomaticas = () => {
      setOpen(true);
      setOpenIngresoSeriesAutomaticas(false);
    };

    const [openIngresoSeriesExcel, setOpenIngresoSeriesExcel] = useState(null);

    const onDataIngresoSeriesExcel = () => {
      setOpenIngresoSeriesExcel(true);
      setOpen(false);
    };

    const onCloseIngresoSeriesExcel = () => {
      setOpen(true);
      setOpenIngresoSeriesExcel(false);
    };

    const [dataExcel, setDataExcel] = useState([]);

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleUploadFileLocal = async (e) => {
      const file = e.target.files[0];
      setFileName(file.name);
      setFile(file);
    };

    const fileRef = useRef();

    const handleRemove = () => {
      setFileName(null);
      setFile(null);
      fileRef.current.value = "";
    };

    const [openCaja, setOpenCaja] = React.useState(false);

    const [openIngresoErrorCargaSeries, setOpenIngresoErrorCargaSeries] =
      useState(null);

    const onDataIngresoErrorCargaSeries = () => {
      setOpenIngresoErrorCargaSeries(true);
    };

    const onCloseIngresoErrorCargaSeries = () => {
      setOpenIngresoErrorCargaSeries(false);
    };

    /* CARGA EXCEL PARA MOSTARR EN EL GRID */

    const handleUploadFile = async () => {
      try {
        if (file) {
          const data = await file.arrayBuffer();
          const workbook = XLSX.read(data);
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          setDataExcel(jsonData);
          setData(jsonData)
          setOpenCaja(false)
        } else {
          console.log("No se cargado ningun archivo");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    /* CARGA SERIES MANUALES */

    const cargarSeriesManuales = (data) => {
      //console.log(data, 'data')
      const onSeries = async () => {
        console.log(data, "data");
        console.log(props.series, "data");

        props.series.forEach(async (obj) => {
          /* const uidSolicitud = `ING-` + numPedido;

          console.log('uidSolicitud', uidSolicitud)
          console.log(obj)
          console.log(obj.serie)
          console.log(props.codigo)
          console.log(codigo)

          db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
            .doc(codigo).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            })

          db.collection(`ingreso_productos/${uidSolicitud}/items`)
            .doc(codigo).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            })

          const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

          const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            }) */

          Swal.fire({
            icon: "success",
            title: "Series cargadas con exito",
          });
          setOpenIngresoManual(false);
        });

      };
      return (
        <>
          {props.series.map((row, index) => (
            <Box pt={1} pb={1}>
              <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                <strong>SERIE: {row.id}</strong>
              </p>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { Wtop: 0 },
                }}
                size="small"
                fullWidth
                name={index}
                //value={[i]}
                onChange={(e) => handleSeries(index, e, data)}
              />
            </Box>
          ))}
          <Button onClick={() => onCloseIngresoManual()}>
            <strong>Cancelar</strong>
          </Button>
          <Button
            size="small"
            color="success"
            variant="contained"
            onClick={() => onSeries()}
          >
            CREAR SERIES
          </Button>
        </>
      );
    };

    /* CARGA SERIES AUTOMATICAS */

    var automaticSeries = [];

    const [loadingButtonSeriesAutomaticas, setLoadingButtonSeriesAutomaticas] = useState(false);

    const cargarSeriesManualesAutomaticas = () => {

      const uidSolicitud = `ING-` + numPedido;
      /* console.log(resultado) */
      var fieldsArray = [];

      for (var i = 1; i <= props.cantidad_pedida; i++) {
        const resultado = Math.floor(
          Math.pow(10, 14 - 1) +
          Math.random() * (Math.pow(10, 14) - Math.pow(10, 5 - 1) - 1),
        );

        automaticSeries.push({ serie: resultado })

        function createData(serie) {
          return { serie };
        }

        var rows = [createData(resultado)];

        /* setAddSeriesAutomaticasArray(createData(resultado)) */

        fieldsArray.push(
          <>
            <Card>
              <CardContent
                style={{
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                <TableContainer sx={{ maxHeight: 540 }}>
                  <Table size="small" stickyHeader aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>SERIE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {i}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.serie}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </>
        );
      }

      /* FUNCION PARA CARGAR LAS SERIES AUTOMATICAS */

      const uploadSeries = () => {
        setLoadingButtonSeriesAutomaticas(true)
        /* const uidSolicitud = `ING-` + numPedido;
        console.log(codigo)
        console.log(props.codigo) */
        automaticSeries.forEach(async (obj) => {
          console.log(obj)
          db
            .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
            .doc(codigo).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie,
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            })

          db
            .collection(`ingreso_productos/${uidSolicitud}/items`)
            .doc(codigo).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie,
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            })

          const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

          const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
            .set({
              serie:
                obj.serie === "" ||
                  obj.serie === null ||
                  obj.serie === undefined
                  ? ""
                  : obj.serie,
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: codigo,
              bodega: bodega,
              estado: 0
            })

          Swal.fire({
            icon: "success",
            title: "Series cargadas con exito",
          });
          setOpenIngresoSeriesAutomaticas(false);

        })
      }

      return (
        <>
          {fieldsArray}
          <Button onClick={() => onCloseIngresoSeriesAutomaticas()}>
            <strong>Cancelar</strong>
          </Button>
          {/* <Button onClick={() => uploadSeries()} size="small" color="success" variant="contained">
            {" "}
            CREAR SERIES
          </Button> */}
          <LoadingButton
            color="success"
            size="small"
            onClick={() => uploadSeries()}
            loading={loadingButtonSeriesAutomaticas}
            endIcon={<SendIcon />}
            variant="contained"
            disabled={loadingButtonSeriesAutomaticas === true}
            loadingPosition="end"
          >CREAR SERIES</LoadingButton>
        </>
      );
    };

    /* FUNCION QUE SUBE LAS SERIES EN EXCEL A FIREBASE */

    const SubirExcelFirebase = async () => {
      console.log(bodega);
      console.log(codigo);
      console.log(dataExcel.length);
      console.log(props.cantidad_pedida);

      {
        parseFloat(dataExcel.length) != parseFloat(props.cantidad_pedida)
          ? onDataIngresoErrorCargaSeries()
          : props.series.map((row, index) => {
            dataExcel.forEach((obj, indexExcel) => {
              handleSeriesExcel(indexExcel, obj.SERIE.toString(), props)
            })
            Swal.fire({
              icon: "success",
              title: "Series cargadas con exito",
            });
            setOpenIngresoSeriesExcel(false);
          })
      }
    };

    /* const SubirExcelFirebase = async () => {
      console.log(bodega);
      console.log(codigo);
      console.log(dataExcel.length);
      console.log(props.cantidad_pedida);

      const agendaDB2 = await db
        .collection("reporte_stock")
        .where("codigo_producto", "==", codigo)
        .where("bodega", "==", bodega)
        .get();

      const data2 = await agendaDB2.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      {
        parseFloat(data.length) != parseFloat(props.cantidad_pedida)
          ? onDataIngresoErrorCargaSeries()
          : data.forEach(async (obj) => {
            const uidSolicitud = `ING-` + numPedido;

            db
              .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
              .doc(codigo).collection('series').doc(obj.SERIE.toString())
              .set({
                serie:
                  obj.SERIE === "" ||
                    obj.SERIE === null ||
                    obj.SERIE === undefined
                    ? ""
                    : obj.SERIE.toString(),
                created:
                  firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_cargo_serie: userInfo.displayName,
                bodega: bodega,
                estado: 0
              })

            db
              .collection(`ingreso_productos/${uidSolicitud}/items`)
              .doc(codigo).collection('series').doc(obj.SERIE.toString())
              .set({
                serie:
                  obj.SERIE === "" ||
                    obj.SERIE === null ||
                    obj.SERIE === undefined
                    ? ""
                    : obj.SERIE.toString(),
                created:
                  firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_cargo_serie: userInfo.displayName,
                bodega: bodega,
                estado: 0
              })

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.SERIE.toString())
              .set({
                serie:
                  obj.SERIE === "" ||
                    obj.SERIE === null ||
                    obj.SERIE === undefined
                    ? ""
                    : obj.SERIE.toString(),
                created:
                  firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_cargo_serie: userInfo.displayName,
                utilizado_por_codigo: codigo,
                bodega: bodega,
                estado: 0
              })

            Swal.fire({
              icon: "success",
              title: "Series cargadas con exito",
            });
            setOpenIngresoSeriesExcel(false);
          });
      }
    }; */

    const [data, setData] = useState([]);

    const [total, setTotal] = useState(0);

    function CustomFooterTotalComponent(props) {
      console.log(props)
      return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
      { field: 'SERIE', headerName: 'SERIE', width: 180 },
    ];

    const CustomToolbar = (props) => (
      <GridToolbarContainer {...props}>
        <GridToolbarFilterButton />
        {/* <GridToolbarQuickFilter /> */}

      </GridToolbarContainer>
    );


    return (
      <>
        {dataExcel === "" || dataExcel === undefined || dataExcel === null ? (
          <Tooltip title="INGRESAR SERIES">
            <Button onClick={() => onData()}>
              <QrCodeScannerIcon />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="INGRESAR SERIES">
            <Button onClick={() => onData()}>
              <QrCodeScannerIcon color="success" />
            </Button>
          </Tooltip>
        )}

        {/* LISTA DE OPCIONES */}

        <Dialog
          open={open}
          fullWidth
          onClose={() => setOpen(false)}
          maxWidth="sm"
        >
          <DialogTitle>
            <strong>SERIES PERTENECIENTES A: {props.codigo}</strong>
          </DialogTitle>
          <DialogContent>
            <Box pt={1}>
              <FormControl fullWidth>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      /* onClick={() => onDataIngresoSeriesExcel()} */
                      /* onClick={() => window.open('/#/inventario/carga-series/', '_blank')} */
                      onClick={() => onDataIngresoSeriesExcel()}
                      /* component={RouterLink}
                      to={`/inventario/carga-series/`} */
                      size="small"
                      color="success"
                      variant="outlined"
                      startIcon={<FileUploadIcon />}
                    >
                      CARGAR EXCEL
                    </Button>
                  </Grid>

                  {/* <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="small"
                      color="info"
                      variant="outlined"
                      startIcon={<FormatAlignCenterIcon />}
                    >
                      CARGAR TXT
                    </Button>
                  </Grid> */}

                  <Grid item xs={6}>
                    {/* <CargaManualSeries props={props} /> */}
                    <Button
                      onClick={() => onDataIngresoManual()}
                      fullWidth
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<FolderSharedIcon />}
                    >
                      CARGA MANUAL
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      onClick={() => onDataIngresoSeriesAutomaticas()}
                      fullWidth
                      size="small"
                      color="warning"
                      variant="outlined"
                      startIcon={<AllInclusiveIcon />}
                    >
                      CARGA SERIES AUTOMATICAS
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <strong>Cancelar</strong>
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG INGRESO MANUAL */}

        <Dialog
          open={openIngresoManual}
          fullWidth
          onClose={() => setOpenIngresoManual(false)}
          maxWidth="sm"
        >
          <DialogTitle>
            <strong>INGRESO DE SERIES MANUAL {props.codigo}</strong>
          </DialogTitle>
          <DialogContent dividers>{cargarSeriesManuales(props)}</DialogContent>
        </Dialog>

        {/* DIALOG INGRESO SERIES AUTOMATICAS */}

        <Dialog
          open={openIngresoSeriesAutomaticas}
          fullWidth
          onClose={() => setOpenIngresoSeriesAutomaticas(false)}
          maxWidth="sm"
        >
          <DialogTitle>
            <strong>INGRESO DE SERIES AUTOMATICAS</strong>
          </DialogTitle>
          <DialogContent>{cargarSeriesManualesAutomaticas()}</DialogContent>
        </Dialog>

        {/* DIALOG INGRESO SERIES MEDIANTE EXCEL */}

        <Dialog
          open={openIngresoSeriesExcel}
          fullWidth
          onClose={() => setOpenIngresoSeriesExcel(false)}
          maxWidth="md"
        >
          <DialogTitle>
            <strong>INGRESO DE SERIES MEDIANTE EXCEL</strong>
          </DialogTitle>
          <DialogContent>
            <Card>
              <CardContent>
                <Button
                  color="success"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                  size="small"
                  /* onClick={() => handleUploadFile()} */ onClick={() =>
                    setOpenCaja(true)
                  }
                  variant="outlined"
                  startIcon={<UploadIcon />}
                >
                  SUBIR EXCEL
                </Button>

                <Paper>
                  <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                    <DataGrid
                      density="compact"
                      rows={data ? data : []}
                      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                      // disableColumnFilter
                      // disableColumnSelector
                      // disableDensitySelector
                      //hideFooterPagination
                      columns={columns}
                      getRowId={(row) => row.SERIE}
                      components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterTotalComponent,

                      }}
                      onStateChange={(state) => {
                        console.log(state.pagination.rowCount);
                        setTotal(state.pagination.rowCount)
                      }}
                    />
                  </Box>
                </Paper>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              onClick={() => onCloseIngresoSeriesExcel()}
            >
              <strong>Cancelar</strong>
            </Button>
            <Button
              /* disabled={dataExcel === ""} */
              disabled={data.length === 0}
              variant="contained"
              color="success"
              size="small"
              onClick={() => SubirExcelFirebase()}
            >
              <strong>SUBIR EXCEL</strong>
            </Button>
          </DialogActions>
        </Dialog>

        {/* SUBIR EXCEL */}
        <Dialog
          open={openCaja}
          onClose={() => setOpenCaja(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Subir Excel</DialogTitle>
          <DialogContent>
            <Box pb={2}>
              {fileName
                ? `Nombre el archivo cargado:  ${fileName}`
                : "Por favor, cargar el archivo..."}
            </Box>
            {!file ? (
              <>
                <input
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadFileLocal(e)}
                  multiple
                  id="buttonFile"
                  type="file"
                  ref={fileRef}
                />
                <label htmlFor="buttonFile">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    component="span"
                  >
                    Cargar archivo
                  </Button>
                </label>
              </>
            ) : (
              <Box>
                <Box display="flex">
                  <Box pr={5}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => handleRemove()}
                    >
                      Borrar archivo
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size="small"
                      onClick={() => handleUploadFile()}
                      variant="contained"
                      color="primary"
                    >
                      Subir archivo
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </DialogContent>
        </Dialog>

        {/* ERROR INGRESO SERIES */}

        <Dialog
          open={openIngresoErrorCargaSeries}
          fullWidth
          onClose={() => setOpenIngresoErrorCargaSeries(false)}
          maxWidth="sm"
        >
          <DialogTitle>
            <strong>
              LA CANTIDAD DE SERIES QUE INTENTA SUBIR NO ES IGUAL A LA CANTIDAD
              SOLICITADA
            </strong>
          </DialogTitle>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={() => onCloseIngresoErrorCargaSeries()}
            >
              <strong>VOLVER</strong>
            </Button>
          </DialogActions>
        </Dialog>

        {/* DIALOG UPLOAD EXCEL */}

        <Dialog
          open={openUploadExcelSeries}
          fullWidth
          onClose={() => setOpenUploadExcelSeries(false)}
          maxWidth="md"
        >
          <DialogTitle>
            <strong>SUBA SU SERIES MEDIANTE EXCEL</strong>
          </DialogTitle>
          <DialogContent>

            <Box >
              {fileName
                ? `Nombre el archivo cargado:  ${fileName}`
                : "Por favor, cargar el archivo..."}
            </Box>

            {!file ? (
              <>
                <input
                  style={{ display: "none" }}
                  onChange={(e) => handleUploadFileLocal(e)}
                  multiple
                  id="buttonFile"
                  type="file"
                  ref={fileRef}
                />
                <label htmlFor="buttonFile">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    component="span"
                    size='small'
                  >
                    Cargar archivo
                  </Button>
                </label>
              </>
            ) : (
              <Box>
                <Box display="flex">
                  <Box pr={2}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={(e) => handleRemove()}
                    >
                      Borrar archivo
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size="small"
                      onClick={handleUploadFile}
                      variant="contained"
                      color="primary"
                    >
                      Subir archivo
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}

            {/* <Button variant='outlined' size='small' color='success'>
                        Cargar series
                    </Button> */}

            <Paper>
              <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                <DataGrid
                  density="compact"
                  rows={data ? data : []}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  // disableColumnFilter
                  // disableColumnSelector
                  // disableDensitySelector
                  //hideFooterPagination
                  columns={columns}
                  getRowId={(row) => row.SERIE}
                  components={{
                    Toolbar: CustomToolbar,
                    Footer: CustomFooterTotalComponent,

                  }}
                  onStateChange={(state) => {
                    console.log(state.pagination.rowCount);
                    setTotal(state.pagination.rowCount)
                  }}
                />
              </Box>
            </Paper>

          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              onClick={() => setOpenUploadExcelSeries(false)}
            >
              <strong>Regresar</strong>
            </Button>
          </DialogActions>
        </Dialog>

      </>
    );
  };

  var cantidadRecibidaForm;

  const [totalDataReporteStock, setTotalDataReporteStock] = useState([])

  const validacionTextFormFieldReporteStock = () => {
    inputFields.forEach(async (codigo) => {
      console.log(codigo.fullName)

      const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo.fullName).where("bodega", "==", bodega).get()

      const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

      console.log(data3[0].id)

      if (totalDataReporteStock.find(idReporteStock => idReporteStock.id === data3[0].id)) {
        console.log('Objeto ya ingresado')
      } else {
        setTotalDataReporteStock([...totalDataReporteStock, {
          id: data3[0].id,
        }])
      }

    });
  }

  const validacionCodigosYCantidadRecibida = () => {
    inputFields.forEach(async (codigo) => {
      console.log(`VAR ARRAY CANTIDAD RECIBIDA ${codigo.cantidad_pedida}`)
      cantidadRecibidaForm = codigo.cantidad_pedida
      console.log(`VAR CANTIDAD RECIBIDA ${cantidadRecibidaForm}`)
      console.log(codigo.fullName)
      const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo.fullName).where("bodega", "==", bodega).get()
      const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      console.log(data3[0].id)
      if (totalDataReporteStock.find(idReporteStock => idReporteStock.id === data3[0].id)) {
        console.log('Objeto ya ingresado')
      } else {
        setTotalDataReporteStock([...totalDataReporteStock, {
          id: data3[0].id,
        }])
      }

      /* if (arrayCantidadRecibida.find(cantidadRecibida => cantidadRecibida.cantidad_recibida === codigo.cantidad_recibida)) {
          console.log('codigo nulo')
      } else {
          setArrayCantidadRecibida([...arrayCantidadRecibida, {
              id: data3[0].id,
              cantidad_recibida: codigo.cantidad_recibida,
          }])
      } */
    });

    console.log(totalDataReporteStock)
    /* console.log(arrayCantidadRecibida, 'Array cantidad recibida') */
    console.log(inputFields, 'input')
  }

  const handleButtonClick = async () => {

    let fechaDate = Date(fechaDocumento);

    const fechaTimestamp = firebase.firestore.Timestamp.fromDate(new Date(fechaDate));

    /* console.log('userInfo', userInfo)
    console.log('numPedido', numPedido)
    console.log('bodega', bodega)
    console.log('ubicacion', ubicacion)
    console.log('motivoIngresoProducto', motivoIngresoProducto)
    console.log('observacionAdicional', observacionAdicional)
    console.log('inputFields', inputFields)
    console.log('fechaTimestamp', fechaTimestamp)
    console.log('flota', flota)
    console.log('lider', lider)
    console.log('auxiliar', auxiliar)
    console.log('placa', placa)
    console.log('marca', marca)
    console.log('modelo', modelo) */

    setLoadingButtonIngreso(true)

    try {
      const ingresoProductos = firebase.functions().httpsCallable("ingresoProductos");

      const data = {
        userInfo: userInfo,
        numPedido: numPedido,
        bodega: bodega,
        ubicacion: ubicacion,
        motivoIngresoProducto: motivoIngresoProducto,
        observacionAdicional: observacionAdicional,
        inputFields: inputFields,
        fechaDocumento: fechaTimestamp,
        flota: flota,
        liderFlota: lider,
        auxiliarFlota: auxiliar,
        placaFlota: placa,
        marcaFlota: marca,
        modeloFlota: modelo
      }

      const response = await ingresoProductos(data);

      setModalConfirm(false)

      Swal.fire({ icon: "success", text: "Se realizo el ingreso de productos con exito", });

      setTimeout(() => {
        navigate(`/inventario/documentacion/reporte-de-movimientos/`)
      }, 3000);

      console.log(response.data.message); // Mensaje de éxito de la Cloud Function
    } catch (error) {
      console.error("Error al llamar a la Cloud Function:", error);

      setModalConfirm(false)

      Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };


  const validacionProductosReporteStock = async () => {
    validacionCodigosYCantidadRecibida()
    console.log(totalDataReporteStock)
    const openAndCloseModal = () => {
      console.log('No existe id')
      setModalConfirm(false)

      Swal.fire({ icon: "warning", text: `No todos los productos estan asociados a la ${bodega}` })
    }

    const openAndCloseModalCantidadRecibida = () => {
      console.log('No existe id')
      setModalConfirm(false)

      Swal.fire({ icon: "warning", text: `Por favor vuelva a ingresar las cantidades o recargue la pagina` })
    }


    if (totalDataReporteStock.length != inputFields.length) {
      console.log('No todos los items estan asociados')
      openAndCloseModal()
    } else if (totalDataReporteStock.length === inputFields.length) {
      console.log('Todos los items estan asociados')
      /* validacionCodigosYCantidadRecibida()
      handleSubmit()
      sendSeriesToFirebase() */
      handleButtonClick()
    }

  }

  const [openCodigo, setOpenCodigo] = useState(null);

  /* EXCEL */

  let navigate = useNavigate();

  const flotasList = useSelector((state) => state.flotasList);
  const { flotas, loadingFlota } = flotasList;
  const [flota, setFlota] = useState("");

  const [numFlota, setNumFlota] = useState("");
  const [lider, setLider] = useState("");
  const [auxiliar, setAuxiliar] = useState("");
  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [observacionAdicional, setObservacionAdicional] = useState("");
  const [array_series, setArraySeries] = useState([]);
  const handleFlota = (e) => {
    setFlota(e);
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0];
    setNumFlota(data.numeroFlota);
    setLider(data.nombreLider);
    setAuxiliar(data.nombreAuxiliar);
    setPlaca(data.placa);
    setMarca(data.marca);
    setModelo(data.modelo);
  };

  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const [fecha, setFecha] = useState({ campo: new Date(), valido: true });
  const [numero, setNumero] = useState({ campo: "", valido: true });
  const [departamento, setDepartamento] = useState({ campo: "", valido: true });
  const [tipo_material, setTipoMaterial] = useState({
    campo: "",
    valido: true,
  });
  const [egreso, setEgreso] = useState({ campo: "", valido: true });
  const [loading, setLoading] = useState(false);
  var [numPedido, setNumPedido] = useState('');
  const [productos, setProductos] = useState("");
  //
  const ubicacionesList = useSelector((state) => state.ubicacionesList);
  const { ubicaciones, loadingUbicaciones } = ubicacionesList;
  const [ubicacion, setUbicacion] = useState("");
  //
  const bodegasList = useSelector((state) => state.bodegasList);
  const { bodegas, loadingBodegas } = bodegasList;
  const [bodega, setBodegas] = useState("");
  //
  const motivoIngresoProductosList = useSelector(
    (state) => state.motivoIngresoProductosList
  );
  const { motivoIngresoProductos, loadingMotivoIngresoProductos } =
    motivoIngresoProductosList;
  const [motivoIngresoProducto, setMotivoIngresoProducto] = useState("");
  //
  const [cantidadProducto, setCantidadProducto] = useState("");
  //
  const [cantidad, setCantidad] = useState("");
  const [item, setItem] = useState("");
  //
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const functionModalConfirm = () => {
    setModalConfirm(true);
  };

  const functionCancelModalConfirm = () => {
    setModalConfirm(false);
  };

  //VARIABLE CAPTURA CANTIDAD TOTAL DE PRODUCTOS
  var cantidadTotalProducto;

  //
  const [inputFields, setInputFields] = useState([]);
  //

  /* FUNCION PARA CARGAR LAS SERIES A FIREBASE */
  const sendSeriesToFirebase = async () => {

    /* inputFields.forEach(async (obj, index) => {
      console.log('obj.series', obj.series)

      db
        .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
        .doc(codigo).collection('series').doc(obj.SERIE.toString())
        .set({
          serie:
            obj.SERIE === "" ||
              obj.SERIE === null ||
              obj.SERIE === undefined
              ? ""
              : obj.SERIE.toString(),
          created:
            firebase.firestore.FieldValue.serverTimestamp(),
          usuario_que_cargo_serie: userInfo.displayName,
          bodega: bodega,
          estado: 0
        })

      db
        .collection(`ingreso_productos/${uidSolicitud}/items`)
        .doc(codigo).collection('series').doc(obj.SERIE.toString())
        .set({
          serie:
            obj.SERIE === "" ||
              obj.SERIE === null ||
              obj.SERIE === undefined
              ? ""
              : obj.SERIE.toString(),
          created:
            firebase.firestore.FieldValue.serverTimestamp(),
          usuario_que_cargo_serie: userInfo.displayName,
          bodega: bodega,
          estado: 0
        })

      const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

      const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.SERIE.toString())
        .set({
          serie:
            obj.SERIE === "" ||
              obj.SERIE === null ||
              obj.SERIE === undefined
              ? ""
              : obj.SERIE.toString(),
          created:
            firebase.firestore.FieldValue.serverTimestamp(),
          usuario_que_cargo_serie: userInfo.displayName,
          utilizado_por_codigo: codigo,
          bodega: bodega,
          estado: 0
        })
    }) */

    inputFields.forEach(async (obj, index) => {
      console.log('obj.fullName', obj.fullName)
      console.log('obj.series', obj.series)

      obj.series.forEach(async (objSerie, index) => {

        if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
          console.log('No se envia')
        } else {
          console.log('objSerie', objSerie)
          console.log('serie', objSerie.serie)

          const uidSolicitud = `ING-` + numPedido;

          db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
            .doc(obj.fullName).collection('series').doc(objSerie.serie.toString())
            .set({
              serie:
                objSerie.serie === "" ||
                  objSerie.serie === null ||
                  objSerie.serie === undefined
                  ? ""
                  : objSerie.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: obj.fullName,
              bodega: bodega,
              estado: 0
            })

          db.collection(`ingreso_productos/${uidSolicitud}/items`)
            .doc(obj.fullName).collection('series').doc(objSerie.serie.toString())
            .set({
              serie:
                objSerie.serie === "" ||
                  objSerie.serie === null ||
                  objSerie.serie === undefined
                  ? ""
                  : objSerie.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: obj.fullName,
              bodega: bodega,
              estado: 0
            })

          const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', bodega).get()

          const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(objSerie.serie.toString())
            .set({
              serie:
                objSerie.serie === "" ||
                  objSerie.serie === null ||
                  objSerie.serie === undefined
                  ? ""
                  : objSerie.serie.toString(),
              created:
                firebase.firestore.FieldValue.serverTimestamp(),
              usuario_que_cargo_serie: userInfo.displayName,
              utilizado_por_codigo: obj.fullName,
              bodega: bodega,
              estado: 0
            })
        }

      })

    })

    setTimeout(() => {
      navigate(`/inventario/documentacion/reporte-de-movimientos/`)
    }, 7000);

  }

  //addInputPRODUCTOS AGREGADOS A LA TABLA
  const addInput = (row) => {
    console.log(row, "row AddInput");
    if (inputFields.find((x) => x.fullName === row.codigo_producto)) {
      Swal.fire({
        icon: "info",
        text: "Ya se agrego este producto a la lista",
      });
    } else {
      setInputFields([
        ...inputFields,
        {
          fullName: row.codigo_producto,
          cantidad_pedida: 0,
          descripcion: row.descripcion_producto,
          codigo: row.codigo_producto,
          modelo_producto: row.modelo_producto,
          categoria_producto: row.categoria_producto,
          subcategoria_producto: row.subcategoria_producto,
          marca_producto: row.marca_producto,
          modelo_producto: row.modelo_producto,
          presentacion_producto: row.presentacion_producto,
          series: [{ serie: 0 }],
          unidad_Medida_Producto: row.unidad_Medida_Producto,
          usuario_que_creo_el_producto: row.usuario_que_creo_el_producto,
          bodega: bodega.toUpperCase(),
          ubicacion: ubicacion.toUpperCase(),
          motivo: motivoIngresoProducto.toUpperCase(),
          tipo_movimiento: "ingreso",
        },
      ]);
    }
    setItem("");
    setModal(false);
  };

  //PRODUCTOS REMOVIDOS DE LA TABLA
  const removeInputFields = (codigo) => {
    /* const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows); */

    /* const rows = [...inputFields];
        rows.splice(codigo, 1);
        setInputFields(rows); */

    console.log(codigo);

    const rows = [...inputFields];

    const indexOfObject = rows.findIndex((object) => {
      return object.codigo === codigo;
    });

    console.log(indexOfObject);

    rows.splice(indexOfObject, 1);

    setInputFields(rows);

    console.log(rows);
  };

  //INGRESAMOS LAS SERIES DE LOS PRODUCTOS
  const seriesInputFields = (codigo) => {
    /* const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows); */
    /* console.log(index) */
    console.log(inputFields);
    console.log(codigo);
  };

  //SE DETECTAN LOS CAMBIOS EN LA DIGITACION
  //DE LA CANTIDAD
  const handleChange = async (index, evnt) => {
    if (Math.sign(evnt.target.value) === -1) {
      console.log("Numero negativo no pasa");
    } else {
      if (evnt.target) {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        //
        setInputFields(list);
        //Cantidad
        //console.log(evnt.target.value, 'cantidad')
        const num = evnt.target.value;
        let array = [];
        for (var i = 0; i < num; i++) {
          //console.log('Hola')
          let n;
          n += i;
          //console.log('for', n)
          array.push({
            index_serie: index,
            id: i,
            serie: 0,
          });
        }
        list[index]["series"] = array;
        //
        console.log(list, "--");
        //console.log(inputFields, 'array')
      } else {
        const list = [...inputFields];
        list[index]["fullName"] = evnt;
        setInputFields(list);
      }
    }
  };
  //
  const handleSeries = async (index, evnt, row) => {
    if (Math.sign(evnt.target.value) === -1) {
      console.log("Numero negativo no pasa");
    } else {
      if (evnt.target) {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        let index_serie = row.series[0].index_serie;
        let array = {
          id: index,
          index_serie: index_serie,
          serie: value.toUpperCase(),
        };
        list[index_serie]["series"][index] = array;
      } else {
        const list = [...inputFields];
        list[index]["fullName"] = evnt;
        setInputFields(list);
      }
    }
    //console.log('input files', inputFields)
  };

  const handleSeriesExcel = async (index, evnt, row) => {
    if (evnt) {
      const list = [...inputFields];
      let index_serie = row.series[0].index_serie;
      let array = {
        id: index,
        index_serie: index_serie,
        serie: evnt.toUpperCase(),
      };
      list[index_serie]["series"][index] = array;
      console.log(inputFields)
    } else {
      const list = [...inputFields];
      /* list[index]["fullName"] = evnt; */
      setInputFields(list);
      console.log(inputFields)
    }
    //console.log('input files', inputFields)
  };

  const getProductos = (e) => {
    /* var docRef = db.collection("productos"); */
    var docRef = db.collection("reporte_stock").where('bodega', '==', e)
    docRef
      .get()
      .then((querySnapshot) => {
        const arrayMessage = querySnapshot.docs.map((item) => {
          return { ...item.data(), id: item.id };
        });
        setProductos(arrayMessage);
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };
  /*  */
  function detalleMate(data, index) {
    return (
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell style={{ fontSize: "12px" }} component="th" scope="row">
          {index + 1}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.codigo}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.categoria_producto}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.subcategoria_producto}
        </TableCell>

        <TableCell style={{ fontSize: "12px" }} align="center">
          {data.descripcion}
        </TableCell>
        <TableCell align="center">
          <TextField
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            name={"cantidad_pedida"}
            onChange={(e) => {
              validacionTextFormFieldReporteStock()
              handleChange(index, e)
            }}
            value={data.cantidad_pedida}
            size="small"
            type="number"
          />
        </TableCell>

        <TableCell align="center">
          {/* <CargaSeriesIngresoProductos props={data} bodega={bodega} codigo={data.codigo} /> */}
          {bodega === "" ? (
            <Tooltip title="SELECCIONE UNA BODEGA">
              <Button disabled>
                <QrCodeScannerIcon
                  onClick={() => {
                    console.log(data.cdogio);
                  }}
                />
              </Button>
            </Tooltip>
          ) : (
            <CargaSeriesIngresoProductos
              props={data}
              bodega={bodega}
              codigo={data.codigo}
              numPedido={numPedido}
            />
          )}
        </TableCell>

        <TableCell align="center">
          <IconButton
            onClick={() => removeInputFields(data.codigo)}
            color="error"
          >
            <DeleteForeverIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  /* const [sumaBodegas, setSumaBodegas] = useState('')

    const array = async () => {
        const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", 'ET-00014').get()

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))
s
        console.log(data3)

        console.log(sumaBodegas)

        let suma = 0;
        for (let i = 0; i < data3.length; i++) {
            console.log(data3[i]["stock_actual"])
            suma += parseFloat(data3[i]["stock_actual"])
            setSumaBodegas(suma)
        }
    } */

  //
  useEffect(() => {
    /* getProductos(); */
    dispatch(listsBodegas());
    dispatch(listsUbicaciones());
    dispatch(listsMotivoIngresoProductos());
    dispatch(listFlotas());
  }, []);
  //
  const loadNumPedido = () => {
    const ref = db
      .collection("ingreso_productos")
      .orderBy("created", "desc")
      .limit(1);
    ref.onSnapshot((snapshot) => {
      if (snapshot.size > 0) {
        const solicitudes = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        const solicitud = solicitudes[0];
        const numeroSolicitud = solicitud.numero_solicitud + 1;
        const uidSolicitud = `ING-` + numeroSolicitud;
        setNumPedido(numeroSolicitud);
      }
    });
  };

  /*  */

  const blockButtonAddItem = async (row) => {
    /* addInput(row) */
    /* console.log(row)
    console.log(row.id)
    console.log(row.codigo_producto) */

    const agendaDB3 = await db.collection("productos").where("codigo_producto", "==", row.codigo_producto).get()

    const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    const openAndCloseModal = () => {
      console.log('No existe id')
      setModal(false)
      Swal.fire({ icon: "warning", text: `La ${bodega} no esta asociada a este producto` })
    }

    {
      data3[0] === '' ||
        data3[0] === null ||
        data3[0] === undefined ?
        /* console.log('No existe id') */
        openAndCloseModal() :
        addInput(data3[0])
    }

    console.log(`RESP: ${data3[0].id}`)

    /* {
        data3[0].id === '' ||
            data3[0].id === null ||
            data3[0].id === undefined ?
            console.log('No existe id') :
            console.log('Si existe id')
    } */

    /* const resp = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).get()

    const data1 = resp.docs.map(doc => ({ id: doc.id, ...doc.data() })) */

  }

  /* CONFIRMAR INGRESO */

  const handleSubmit = () => {
    let rol = "";
    if (userInfo.rol === 0) {
      rol = "ADMIN";
    }
    if (userInfo.rol === 1) {
      rol = "COMERCIAL";
    }
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
      rol = "OPERACIONES";
    }
    if (userInfo.rol === 3) {
      rol = "PLANEACION";
    }
    if (userInfo.rol === 4) {
      rol = "FLOTAS";
    }
    if (userInfo.rol === 5) {
      rol = "REPORTES";
    }
    if (userInfo.rol === 6) {
      rol = "RECURSOS HUMANOS";
    }
    //
    const uidSolicitud = `ING-` + numPedido;

    console.log(`UidSolicitud ${uidSolicitud}`)

    const data = {
      uidSolicitud: uidSolicitud,
      estado: 0,
      numero_solicitud: numPedido,
      uid_user: userInfo.uid,
      user: userInfo.displayName,
      departamento: userInfo.departamentoText,
      rol: rol,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      bodega: bodega.toUpperCase(),
      ubicacion: ubicacion.toUpperCase(),
      motivo: motivoIngresoProducto.toUpperCase(),
      motivo_movimiento: "INGRESO",
      observacion_adicional:
        observacionAdicional === ""
          ? "NINGUNA"
          : observacionAdicional.toUpperCase(),
      flota: flota,
      lider_flota: lider,
      auxiliar_flota: auxiliar,
      placa_flota: placa,
      marca_flota: marca,
      modelo_flota: modelo,
      bodega: bodega.toUpperCase(),
      ubicacion: ubicacion.toUpperCase(),
      motivo: motivoIngresoProducto.toUpperCase(),
      tipo_movimiento: "ingreso",
      precio_compra: 0,
      fecha_documento: fechaDocumento
    };
    //
    db.collection("ingreso_productos").doc(uidSolicitud).set(data)

    db.collection(`reporte_movimientos`).doc(uidSolicitud).set(data)

    /* db.collection("reporte_movimientos")
      .doc(uidSolicitud)
      .set(data) */

    inputFields.forEach(async function (obj, index) {

      const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get();

      const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', bodega).get()

      const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

      const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

      const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_pedida)

      const saldo_valorizado =
        parseFloat(obj.cantidad_pedida) *
        parseFloat(data2[0].costo_promedio);

      const Total =
        parseFloat(data2[0].total_valorizado) +
        parseFloat(saldo_valorizado);

      const cantidad_total = parseFloat(data2[0].stock_actual) + parseFloat(obj.cantidad_pedida)

      console.log('SALDO VALORIZADO', saldo_valorizado)
      console.log('TOTAL', Total)
      console.log('CANTIDAD TOTAL', cantidad_total)

      console.log('data2[0].id', data2[0].id);

      const agendaDB3 = await db
        .collection("reporte_stock")
        .where("codigo_producto", "==", obj.fullName)
        .get();

      const data3 = agendaDB3.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log(data3);

      console.log(sumaBodegas);

      /* FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS */

      var datoSumaStockTotalBodegas;

      let sumaStock = 0;
      for (let i = 0; i < data3.length; i++) {
        console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
        sumaStock += parseFloat(data3[i]["stock_actual"])
        datoSumaStockTotalBodegas = sumaStock
      }


      /* FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS */

      var sumaValorTotalValorizadoBodegas;

      let sumaTotalValorizado = 0;
      for (let i = 0; i < data3.length; i++) {
        console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
        sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
        sumaValorTotalValorizadoBodegas = sumaTotalValorizado
      }

      const total_bodegas = parseFloat(data4[0].cantidad_producto).toFixed(2) - Math.abs(parseFloat(obj.cantidad_pedida).toFixed(2))

      console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

      const sumaTotalBodegas =
        parseFloat(sumaValorTotalValorizadoBodegas) + parseFloat(Total);

      console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS')

      db.collection("reporte_stock").doc(data2[0].id).update({
        stock_actual: parseFloat(data2[0].stock_actual) + parseInt(obj.cantidad_pedida),
        total_valorizado: Total.toFixed(2),
        costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio
      })

      /* SE ANADE EL STOCK DEL INGRESO AL STOCK ACTUAL */

      db.collection("productos").doc(data4[0].id).update({
        cantidad_producto: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
      });

      db.collection(`ingreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
        cantidad_solicitada: parseInt(obj.cantidad_pedida),
        cantidad_total_antes: data2[0].stock_actual,
        cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
        ...obj,
        uid_solicitud_pedido: uidSolicitud,
        uid_user: userInfo.uid,
        user: userInfo.displayName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        categoria_producto: obj.categoria_producto,
        subcategoria_producto: obj.subcategoria_producto,
        marca_producto: obj.marca_producto,
        modelo_producto: obj.modelo_producto,
        presentacion_producto: obj.presentacion_producto,
        unidad_Medida_Producto: obj.unidad_Medida_Producto,
        usuario_que_creo_el_producto: obj.usuario_que_creo_el_producto,
        costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
        saldo_valorizado: parseFloat(saldo_valorizado),
        total: Number.isNaN(Number(Total)) ? 0 : Total.toFixed(2),
        observacion_adicional:
          observacionAdicional === ""
            ? "NINGUNA"
            : observacionAdicional.toUpperCase(),
        flota: flota,
        lider_flota: lider,
        auxiliar_flota: auxiliar,
        placa_flota: placa,
        marca_flota: marca,
        modelo_flota: modelo,
        bodega: bodega.toUpperCase(),
        ubicacion: ubicacion.toUpperCase(),
        motivo: motivoIngresoProducto.toUpperCase(),
        tipo_movimiento: "ingreso",
        precio_compra: 0,
        total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
        costo_promedio_general: data2[0].costo_promedio,
      })

      const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)

      const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

      console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

      console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

      const dataRef = {
        cantidad_solicitada: parseInt(obj.cantidad_pedida),
        cantidad_total_antes: data2[0].stock_actual,
        cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
        ...obj,
        uid_solicitud_pedido: uidSolicitud,
        uid_user: userInfo.uid,
        user: userInfo.displayName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        categoria_producto: obj.categoria_producto,
        subcategoria_producto: obj.subcategoria_producto,
        marca_producto: obj.marca_producto,
        modelo_producto: obj.modelo_producto,
        presentacion_producto: obj.presentacion_producto,
        unidad_Medida_Producto: obj.unidad_Medida_Producto,
        usuario_que_creo_el_producto: obj.usuario_que_creo_el_producto,
        costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
        saldo_valorizado: parseFloat(saldo_valorizado),
        total: Number.isNaN(Number(Total)) ? 0 : Total.toFixed(2),
        observacion_adicional:
          observacionAdicional === ""
            ? "NINGUNA"
            : observacionAdicional.toUpperCase(),
        flota: flota,
        lider_flota: lider,
        auxiliar_flota: auxiliar,
        placa_flota: placa,
        marca_flota: marca,
        modelo_flota: modelo,
        bodega: bodega.toUpperCase(),
        ubicacion: ubicacion.toUpperCase(),
        motivo: motivoIngresoProducto.toUpperCase(),
        tipo_movimiento: "ingreso",
        precio_compra: 0,
        total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
        costo_promedio_general: data2[0].costo_promedio,
        fecha_documento: fechaDocumento
      }

      const dataRef2 = {
        cantidad_solicitada: parseInt(obj.cantidad_pedida),
        cantidad_total_antes: data2[0].stock_actual,
        cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) ? 0 : kardexGeneralSumaTotalStock,
        ...obj,
        uid_solicitud_pedido: uidSolicitud,
        uid_user: userInfo.uid,
        user: userInfo.displayName,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        categoria_producto: obj.categoria_producto,
        subcategoria_producto: obj.subcategoria_producto,
        marca_producto: obj.marca_producto,
        modelo_producto: obj.modelo_producto,
        presentacion_producto: obj.presentacion_producto,
        unidad_Medida_Producto: obj.unidad_Medida_Producto,
        usuario_que_creo_el_producto: obj.usuario_que_creo_el_producto,
        costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
        saldo_valorizado: parseFloat(saldo_valorizado),
        total: Number.isNaN(Number(Total)) ? 0 : Total.toFixed(2),
        observacion_adicional:
          observacionAdicional === ""
            ? "NINGUNA"
            : observacionAdicional.toUpperCase(),
        flota: flota,
        lider_flota: lider,
        auxiliar_flota: auxiliar,
        placa_flota: placa,
        marca_flota: marca,
        modelo_flota: modelo,
        bodega: bodega.toUpperCase(),
        ubicacion: ubicacion.toUpperCase(),
        motivo: motivoIngresoProducto.toUpperCase(),
        tipo_movimiento: "ingreso",
        precio_compra: 0,
        total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
        costo_promedio_general: data2[0].costo_promedio,
        fecha_documento: fechaDocumento
      }

      db.collection(`total_movimientos`).doc().set(dataRef)

      // db.collection(`total_movimientos_general`).doc().set(dataRef2)

      db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)

      setModalConfirm(false);
      Swal.fire({
        icon: "success",
        text: "Se realizo el ingreso de productos correctamente",
      });

    });

  };
  useEffect(() => {
    loadNumPedido();
  }, [inputFields]);

  const viewNumber = (row) => {
    console.log(`cantidad stock${row}`)
    var sumaBodegasBar;
    const result = async () => {

      const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", row.codigo_producto).get()

      const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

      console.log('data3', data3)

      let suma = 0;
      for (let i = 0; i < data3.length; i++) {
        console.log(data3[i]["stock_actual"])
        suma += parseFloat(data3[i]["stock_actual"])
        setSumaBodegas(suma)
        sumaBodegasBar = suma
      }

    }
    result()
    return sumaBodegas
  }

  return (
    <>
      <Card>
        <CardContent>
          {/* <Box pb={2}>
                        <p style={{ fontSize: '16px', marginBottom: '0px', marginLeft: '0px', fontWeight: 'bold' }}>
                            NUEVO MOVIMIENTO
                        </p>
                    </Box> */}
          <form>
            <Grid container spacing={5}>
              <Grid item md={6}>
                <Typography gutterBottom style={{ fontSize: "14px" }}>
                  <strong>NUMERO DE MOVIMIENTO</strong>
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  ING-{numPedido}
                </Typography>
                <Box pt={1} pb={1}>
                  <Typography gutterBottom style={{ fontSize: "14px" }}>
                    <strong>FECHA DE CREACION</strong>
                  </Typography>
                  <Typography style={{ fontSize: "14px" }}>
                    {moment().format("YYYY-MM-D h:mm:ss A")}
                  </Typography>
                </Box>
                <Divider />
              </Grid>
              <Grid item md={6}>
                <Typography gutterBottom style={{ fontSize: "14px" }}>
                  <strong>USUARIO</strong>
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  {userInfo.displayName}
                </Typography>
              </Grid>
            </Grid>
            <Box py={1}></Box>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <Box pb={2}>
                  <FormControl fullWidth>
                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                      <strong>FECHA DE DOCUMENTO</strong>
                    </Typography>

                    <Box pb={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          renderInput={(props) => <TextField sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }} fullWidth {...props} size='small' />}
                          inputFormat="dd/MM/yyyy"
                          value={fechaDocumento}
                          onChange={(newValue) => {
                            setFechaDocumento(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Box>

                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                      <strong>BODEGA</strong>
                    </Typography>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      value={bodega}
                      label="MOTIVO"
                      onChange={(e) => {
                        setBodegas(e.target.value)
                        getProductos(e.target.value)
                      }}
                    >
                      {bodegas &&
                        bodegas.map((item) => (
                          <MenuItem
                            key={item.nombre_bodega}
                            value={item.nombre_bodega}
                          >
                            {item.nombre_bodega}
                          </MenuItem>
                        ))}
                    </Select>
                    {loadingMotivoIngresoProductos && <LinearProgress />}
                  </FormControl>
                </Box>

                <FormControl fullWidth>
                  <Typography gutterBottom style={{ fontSize: "14px" }}>
                    <strong>UBICACION</strong>
                  </Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    value={ubicacion}
                    label="MOTIVO"
                    onChange={(e) => setUbicacion(e.target.value)}
                  >
                    {ubicaciones &&
                      ubicaciones.map((item) => (
                        <MenuItem
                          key={item.nombre_ubicacion}
                          value={item.nombre_ubicacion}
                        >
                          {item.nombre_ubicacion}
                        </MenuItem>
                      ))}
                  </Select>
                  {loadingMotivoIngresoProductos && <LinearProgress />}
                </FormControl>

                <Box pt={2}>
                  <FormControl fullWidth>
                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                      <strong>OBSERVACION ADICIONAL</strong>
                    </Typography>
                    <TextField
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      value={observacionAdicional}
                      onChange={(e) => setObservacionAdicional(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box pb={2}>
                  <FormControl fullWidth>
                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                      <strong>MOTIVO</strong>
                    </Typography>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      size="small"
                      value={motivoIngresoProducto}
                      label="MOTIVO"
                      onChange={(e) => setMotivoIngresoProducto(e.target.value)}
                    >
                      {motivoIngresoProductos &&
                        motivoIngresoProductos.map((item) => (
                          <MenuItem
                            key={item.nombre_motivo_ingreso_producto}
                            value={item.nombre_motivo_ingreso_producto}
                          >
                            {item.nombre_motivo_ingreso_producto}
                          </MenuItem>
                        ))}
                    </Select>
                    {loadingMotivoIngresoProductos && <LinearProgress />}
                  </FormControl>
                </Box>

                <FormControl fullWidth>
                  <Typography gutterBottom style={{ fontSize: "14px" }}>
                    <strong>UNIDAD DE TRANSPORTE</strong>
                  </Typography>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    label="Flota"
                    value={flota}
                    onChange={(e) => handleFlota(e.target.value)}
                  >
                    {flotas &&
                      flotas.map((row, key) => (
                        <MenuItem value={row.numeroFlota}>
                          {row.numeroFlota}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {flota === "" || flota === undefined || flota === null ? (
                  ""
                ) : (
                  <>
                    <Box py={2}>
                      <Typography gutterBottom style={{ fontSize: "14px" }}>
                        <strong>TRANSPORTISTA</strong>
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                          }}
                          size="small"
                          disabled
                          value={lider}
                        >
                          <MenuItem value={lider}>{lider}</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Typography gutterBottom style={{ fontSize: "14px" }}>
                      <strong>PLACA</strong>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        size="small"
                        disabled
                        value={placa}
                      >
                        <MenuItem value={placa}>{placa}</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>

            <Box display="flex" flexDirection="row-reverse" py={1}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setModal(true)}
              >
                Agregar Items
              </Button>
            </Box>

            <Dialog open={modal} maxWidth="md" fullWidth>
              <DialogTitle>AGREGAR ITEMS</DialogTitle>
              <DialogContent dividers>
                <Box pb={3}>
                  <Autocomplete
                    disablePortal
                    size="small"
                    id="combo-box-demo"
                    options={productos}
                    disableClearable
                    clearOnBlur={false}
                    autoComplete={false}
                    getOptionLabel={(option) => option.descripcion}
                    sx={{ width: 300 }}
                    onInputChange={(e, newInputValue) => {
                      console.log(item);
                      setItem(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="BUSCAR PRODUCTO" />
                    )}
                  />
                </Box>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>CODIGO</TableCell>
                        <TableCell>DESCRIPCION</TableCell>
                        <TableCell>STOCK</TableCell>
                        <TableCell>ACCIONES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productos &&
                        productos
                          .filter((val) => {
                            if (item === "") {
                              return val;
                            } else if (
                              val.descripcion
                                .toLowerCase()
                                .includes(item.toLowerCase())
                            ) {
                              return val;
                            }
                          })
                          .map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>{row.codigo_producto}</TableCell>
                              <TableCell>{row.descripcion/* descripcion */}</TableCell>
                              <TableCell>{
                                /* viewNumber(row) */
                                row./* stock_actual */stock_actual
                              }</TableCell>
                              <TableCell>
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="success"
                                  onClick={() => blockButtonAddItem(row)/* addInput(row) */}
                                >
                                  Agregar
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
              <DialogActions dividers>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setModal(false)}
                >
                  Cerrar Ventana
                </Button>
              </DialogActions>
            </Dialog>

            <Box pb={1}>
              <Typography gutterBottom style={{ fontSize: "14px" }}>
                <strong>PRODUCTOS A INGRESAR</strong>
              </Typography>
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>NO.</TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>CODIGO</strong>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>CATEGORIA</strong>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>SUBCATEGORIA</strong>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>DESCRIPCION</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ width: "160px", fontSize: "12px" }}
                    >
                      <strong>CANTIDAD</strong>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>SERIES</strong>
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "12px" }}>
                      <strong>ELIMINAR</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputFields.map((data, index) => {
                    return detalleMate(data, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Divider />
            <Box display="flex" flexDirection="row-reverse" pt={1}>
              <LoadingButton
                loading={loadingButtonIngreso}
                endIcon={loadingButtonIngreso === true ? <SendIcon /> : ''}
                loadingPosition="end"
                size="small"
                variant="contained"
                disabled={
                  inputFields.length <= 0 ||
                  bodega === "" ||
                  motivoIngresoProducto === "" ||
                  ubicacion === "" ||
                  flota === ""
                }
                onClick={() => setModalConfirm(true)}
              >
                INGRESAR PRODUCTOS
              </LoadingButton>
            </Box>
          </form>
        </CardContent>
      </Card>

      {/* MODAL CONFIRM */}

      <Dialog open={modalConfirm} maxWidth="xs" fullWidth>
        <DialogTitle>CONFIRMAR INGRESO</DialogTitle>
        <DialogContent dividers>
          <Typography>
            <strong>
              ¿Esta seguro que todos los productos ingresados son correctos?
            </strong>
          </Typography>
        </DialogContent>
        <DialogActions dividers>
          <LoadingButton
            loading={loadingButtonIngreso}
            endIcon={loadingButtonIngreso === true ? <SendIcon /> : ''}
            loadingPosition="end"
            size="small"
            variant="outlined"
            color="error"
            onClick={() => functionCancelModalConfirm()}
          >
            CANCELAR
          </LoadingButton>
          <LoadingButton
            loading={loadingButtonIngreso}
            endIcon={loadingButtonIngreso === true ? <SendIcon /> : ''}
            loadingPosition="end"
            size="small"
            variant="contained"
            color="success"
            onClick={() => validacionProductosReporteStock()}
          >
            CONFIRMAR INGRESO
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};