import {
    TextField,
    Button,
    Box,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CheckIcon from "@mui/icons-material/Check";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";

export const UpdateFlotasInactivas = ({ props }) => {
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    // const siFactible = (id) => {
    //     //Validaciones
    //     db.collection("service_request")
    //         .doc(id)
    //         .update({
    //             estado: 2,
    //             observacion: ""
    //         })
    //         .then((docRef) => {
    //             console.log("licencia ", docRef);
    //             Swal.fire({
    //                 icon: "success",
    //                 title: "Has confirmado que hay factibilidad.",
    //             });
    //         })
    //         .catch((error) => {
    //             console.error("Error adding document: ", error);
    //         });
    // };



    // const noFactible = (id) => {
    //     db.collection("service_request")
    //         .doc(id)
    //         .update({
    //             estado: 1,
    //             observacion: ""
    //         })
    //         .then((docRef) => {
    //             Swal.fire({
    //                 icon: "success",
    //                 title: "No hay factibilidad",
    //             });
    //         })
    //         .catch((error) => {
    //             console.error("Error adding document: ", error);
    //         });
    // };


    const handleSubmit = (id) => {
        db.collection("flotas")
            .doc(id)
            .update({
                estado: 0,
                observacion: ""
            })
            .then((docRef) => {
                setOpen(false)
                Swal.fire({
                    icon: "success",
                    text: "Se ha activado la flota exitosamente.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }


    return (
        <>
            <Tooltip title="Visualizar">
                <Button onClick={handleClickOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={handleClose} maxWidth="md">
                <DialogTitle>
                    Proveedor {props.proveedor}
                </DialogTitle>

                <DialogContent>
                    <Box py={2}>
                        <TextField fullWidth label="Nombre Lider" value={props.nombreLider} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Nombre Auxiliar" value={props.nombreAuxiliar} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Proveedor" value={props.proveedor} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Placa" value={props.placa} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Motor" value={props.motor} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Modelo" value={props.modelo} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Marca" value={props.marca} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Año" value={props.anio} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Numero de Contrato" value={props.numContrato} />
                    </Box>

                    <Box py={2}>
                        <TextField fullWidth label="Localidad" value={props.localidad} />
                    </Box>

                    <Box py={2}>
                        <TextField
                            fullWidth
                            label="Observaciones"
                            value={props.observacion}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>

                    <Tooltip title="Activar Flota">
                        <Button onClick={() => handleSubmit(props.id)}>
                            <CheckIcon />
                        </Button>
                    </Tooltip>

                    <Button variant="contained" onClick={() => setOpen(false)}><strong>regresar</strong></Button>

                    {/* <Tooltip title="Suspender Flota">
                        <Button>
                            <ClearIcon />
                        </Button>
                    </Tooltip> */}

                    {/* <Button onClick={() => handleSubmit(props.id)} variant="contained">
                        {" "}
                        Actualizar localidad
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};
