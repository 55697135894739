import { Container, Card, Typography, Box, Tab, Paper } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ReasignarTicketsPlaneacion } from "./ReasignacionTickets/ReasignarTicketsPlaneacion";
import { TicketsAprobadosPlaneacion } from "./TicketsAprobadosPlaneacion/TicketsAprobadosPlaneacion";
import { TicketsCerradosPlaneacion } from "./TicketsCerradosPlaneacion/TicketsCerradosPlaneacion";
import { TicketsEnEsperaPlaneacion } from "./TicketsEnEpseraPlaneacion/TicketsEnEsperaPlaneacion";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export const TicketsPlaneacionPage = () => {
  const params = useParams();
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (params.id) {
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setValue(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Container maxWidth="lx" disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1">
            <strong>TICKETS</strong>
          </Typography>
        </Box>
        <Card>
          <Box
            sx={{
              maxWidth: { xs: 340, sm: "100%" },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={value}
              variant="scrollable"
              indicatorColor="secondary"
              scrollButtons
              textColor="secondary"
              allowScrollButtonsMobile
              onChange={(e, newValue) => setValue(newValue)}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              <Tab label="Tickets en espera" />
              <Tab label="Tickets Gestionados" />
              <Tab label="Tickets por reasignar" />
              <Tab label="Tickets Cerrados" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <TicketsEnEsperaPlaneacion />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TicketsAprobadosPlaneacion />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ReasignarTicketsPlaneacion />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TicketsCerradosPlaneacion />
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  );
};
