import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, Box, Button, DialogActions, DialogTitle } from '@mui/material'
import { PDFObject } from "react-pdfobject";
import { jsPDF } from "jspdf";
import Swal from 'sweetalert2'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../../firebase'
import autoTable from 'jspdf-autotable'
const VerItems = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [items, setItems] = useState([]);
    useEffect(() => {
        //console.log(items, 'items2')
        //const ray = 
        // const data = [
        //     ...items.docs.map((doc) => {
        //         return {
        //             ...doc.data(),
        //             id: doc.id,
        //         };
        //     }),
        // ];
        // console.log(data, 'items2')
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
    }
    const loadItems = () => {
        var docRef = db.collection("orden_compra").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('a', props.uidSolicitud)
            setItems(data);
        });

        console.log('enviar')
    }
    const onSubmit = () => {
        setLoading(true)
        //console.log('enviar', items)
        db.collection('solicitud_compra').doc(props.uidSolicitud).update({
            estado: 1,
            egreso: firebase.firestore.FieldValue.increment(1),
            fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            Swal.fire({ icon: "success", text: 'Se actualizo su solicitud de pedido' });
            setLoading(false)
        })
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'codigo', headerName: 'CODIGO', width: 100 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 500 },

        { field: 'cantidad', headerName: 'CANTIDAD SOLICITADA', width: 190 },
        { field: 'cant_entrega', headerName: 'CANTIDAD ENTREGADA', width: 190 },
        {
            field: 'diferencia', headerName: 'DIFERENCIA', width: 100,
            renderCell: (params) => {
                return (
                    params.row.cant_entrega ? params.row.cantidad - params.row.cant_entrega : 0
                )
            }
        },
    ];
    const PreView = () => {
        const doc = new jsPDF();
        doc.text("Hello world!", 0, 10);
        autoTable(doc, {
            theme: 'grid',
            head: [['CODIGO', 'DESCRIPCION', 'CANTIDAD SOLICITADA', '']],
            body: [
                ['David', 'david@example.com', 'Sweden'],
                ['Castille', 'castille@example.com', 'Spain'],
                // ...
            ],
        })
        const url = doc.output("bloburi");

        return <PDFObject url={url} />;
    };
    return (
        <>
            <IconButton size='small' onClick={() => onData()}>
                <RemoveRedEyeIcon fontSize='small' />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle>
                    VER ORDEN DE COMPRA: {props.id}
                </DialogTitle>
                <DialogContent dividers>
                    {/* <PreView /> */}
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CANCELAR</Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerItems