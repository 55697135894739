import {
    Button, Dialog, DialogContent, DialogTitle,
    Grid, Paper, Table, TableCell, TableHead,
    TableRow, Tooltip, Typography, DialogActions,
    TableBody
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { cambioTiempo, LogTicket } from './LogTicket';
import { db } from '../../firebase';
import { useSelector } from 'react-redux';


export function cargarTop(setLogs, tipo, ticket) {
    let requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    let campo = ticket.id
    if (tipo === 'PWSU' || tipo === 'PWMI') {
        campo = ticket.codigo
    }
    fetch(`https://serverbd.intercommerce.com.ec//apitopwisprespuesta/?id=${campo}&categoria=${tipo}`,
        requestOptions)
        .then(response => response.text())
        .then(result2 => {
            const re = JSON.parse(result2)
            setLogs(
                {
                    comentarios: [...re.map((val) => {
                        return {
                            id: val.id,
                            fecha: val.fecha_ingreso,
                            accion: '--',
                            tiempo: '--',
                            usuario: val.usuario_ingreso,
                            comentario: val.accion
                        }
                    })]
                }
            )
        }).catch(error => {
            console.log('error', error)
        })
}


export function cargarLog(coleccion, ticket, setLogs, setSeries, setTiempo, setTiempoTotal) {
    try {
        let ref = db.collection("CRM_logs").where('idTicket', '==', coleccion + ticket.id).orderBy('fecha', 'asc')
        ref.onSnapshot(snapshot => {
            let tiempoTotal = 0
            let tiempoTotal2 = 0
            const arrayMessage = [
                ...snapshot.docs.map((doc, index) => {
                    if (index !== 0) tiempoTotal += doc.data().accion.includes('REABRIR') ? 0 : doc.data().tiempo
                    tiempoTotal2 += doc.data().tiempo < 0 ? doc.data().tiempo * -1 : doc.data().tiempo
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                })
            ]
            setSeries(arrayMessage)
            setLogs({ comentarios: arrayMessage })
            setTiempo(cambioTiempo(tiempoTotal))
            setTiempoTotal(cambioTiempo(tiempoTotal2))
        })
    } catch (error) {
        console.log(error)
    }
}


export function handleOpen(ticket, setOpen, tipo, historial, userAuth) {
    setOpen(true)
}

export const DetalleTicketContratar = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const ticket = props.ticket
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')
    const [series, setSeries] = useState([])

    useEffect(() => {
        if ((!props.historial) || (props.historial && props.ticket.id.includes('CRM'))) {
            let campo = props.ticket
            if (typeof props.ticket.id === "string" ? props.ticket.id.includes('CRM') : false) {
                campo = {
                    ...props.ticket,
                    id: props.ticket.id.split('CRM-')[1]
                }
            }
            cargarLog('contratar_', campo, setLogs, setSeries, setTiempo, setTiempoTotal)
        }
    }, [props.ticket]);


    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_contratar', props.historial, userAuth)
                    if (props.historial && !ticket.id.includes('CRM')) {
                        cargarTop(setLogs, 'PWCO', ticket)
                    }
                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong> {ticket.unidad} <br />
                                            <strong>ID TICKET:</strong> {ticket.id}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}<br />
                                            <strong>NOMBRES:</strong> {ticket.nombres}<br />
                                            <strong>CORREO:</strong> {ticket.correo}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>TELÉFONO 1:</strong>{ticket.telefono1}<br />
                                            <strong>TELÉFONO 2:</strong>{ticket.telefono2}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>PLAN IDEAL:</strong>
                                                        {ticket.planideal}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>COORDENADAS:</strong>
                                                        {ticket.coordenadas}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>REFERENCIA DOMICILIO:</strong>
                                                        {ticket.referencias}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.id.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                                {((props.historial && !props.ticket.id.includes('CRM')) ||
                                    (props.historial && props.ticket.id.includes('CRM'))) &&
                                    <LogTicket ticket={logs} />
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />
                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export const DetalleTicketSuspension = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const ticket = props.ticket
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);

    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')
    const [series, setSeries] = useState([])

    useEffect(() => {
        if ((!props.historial) || (props.historial && props.ticket.codigo.includes('CRM'))) {
            let campo = props.ticket
            if (props.ticket.codigo.includes('CRM')) {
                campo = {
                    ...props.ticket,
                    id: props.ticket.codigo.split('CRM-')[1]
                }
            }
            cargarLog('suspension_', campo, setLogs, setSeries, setTiempo, setTiempoTotal)
        }
    }, [props.ticket]);


    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_suspension', props.historial, userAuth)
                    if (props.historial && !ticket.codigo.includes('CRM')) {
                        cargarTop(setLogs, 'PWSU', ticket)
                    }
                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong> {ticket.unidadnegocio} <br />
                                            <strong>ID TICKET:</strong> {ticket.codigo}<br />
                                            <strong>ID CLIENTE:</strong>{ticket.idcliente}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>NOMBRES:</strong> {ticket.nombres} <br />
                                            <strong>CORREO:</strong> {ticket.email}<br />
                                            <strong>TELÉFONO:</strong>{ticket.telefono}<br />
                                            <strong>DIRECCIÓN:</strong>{ticket.direccion}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>TIPO SUSPENSIÓN:</strong>
                                                        {ticket.tipo}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>DESCRIPCIÓN:</strong>
                                                        {ticket.motivo_suspension}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.codigo.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>


                                {((props.historial && !props.ticket.codigo.includes('CRM')) ||
                                    (props.historial && props.ticket.codigo.includes('CRM'))) &&
                                    <LogTicket ticket={logs} />
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />
                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export const DetalleTicketCambioPlan = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const ticket = props.ticket
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);

    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')
    const [series, setSeries] = useState([])

    useEffect(() => {
        if ((!props.historial) || (props.historial && props.ticket.id.includes('CRM'))) {
            let campo = props.ticket
            if (typeof props.ticket.id === "string" ? props.ticket.id.includes('CRM') : false) {
                campo = {
                    ...props.ticket,
                    id: props.ticket.id.split('CRM-')[1]
                }
            }
            cargarLog('cambio_plan_', campo, setLogs, setSeries, setTiempo, setTiempoTotal)
        }
    }, [props.ticket]);


    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_cambio_plan', props.historial, userAuth)
                    if (props.historial && !ticket.id.includes('CRM')) {
                        cargarTop(setLogs, 'PWCP', ticket)
                    }

                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong> {ticket.unidad} <br />
                                            <strong>ID TICKET:</strong> {ticket.id}<br />
                                            <strong>ID CLIENTE:</strong>{ticket.idcliente}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>NOMBRES:</strong> {ticket.nombres} <br />
                                            <strong>CORREO:</strong> {ticket.email}<br />
                                            <strong>TELÉFONO:</strong>{ticket.telefono}<br />
                                            <strong>DIRECCIÓN:</strong>{ticket.direccion}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>PLAN NUEVO:</strong>
                                                        {ticket.plannuevo}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>MOTIVO DEL CAMBIO DE PLAN:</strong>
                                                        {ticket.motivo}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.id.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                                {((props.historial && props.ticket.id.includes('CRM'))) &&
                                    <>
                                        <LogTicket ticket={logs} />

                                    </>
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />


                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export const DetalleTicketCambioDomicilio = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const ticket = props.ticket
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')
    const [series, setSeries] = useState([])

    useEffect(() => {
        if ((!props.historial) || (props.historial && props.ticket.id.includes('CRM'))) {
            let campo = props.ticket
            if (typeof props.ticket.id === "string" ? props.ticket.id.includes('CRM') : false) {
                campo = {
                    ...props.ticket,
                    id: props.ticket.id.split('CRM-')[1]
                }
            }
            cargarLog('cambio_domicilio_', campo, setLogs, setSeries, setTiempo, setTiempoTotal)
        }
    }, [props.ticket]);

    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_cambio_domicilio', props.historial, userAuth)
                    if (props.historial && !ticket.id.includes('CRM')) {
                        cargarTop(setLogs, 'PWCD', ticket)
                    }
                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong> {ticket.unidadnegocio} <br />
                                            <strong>ID TICKET:</strong> {ticket.id}<br />
                                            <strong>ID CLIENTE:</strong>{ticket.idcliente}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>NOMBRES:</strong> {ticket.nombres} <br />
                                            <strong>CORREO:</strong> {ticket.email}<br />
                                            <strong>TELÉFONO:</strong>{ticket.telefonos}<br />
                                            <strong>DIRECCIÓN:</strong>{ticket.direccion}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>REFERENCIA NUEVA DIRECCION:</strong>
                                                        {ticket.referencianuevadireccion}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>COORDENADAS:</strong>
                                                        {ticket.coordenadasnuevadireccion}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.id.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                                {((props.historial && !props.ticket.id.includes('CRM')) ||
                                    (props.historial && props.ticket.id.includes('CRM'))) &&
                                    <LogTicket ticket={logs} />
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />

                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const DetalleTicketMigracion = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const ticket = props.ticket
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')
    const [series, setSeries] = useState([])


    useEffect(() => {
        if ((!props.historial) || (props.historial && props.ticket.codigo.includes('CRM'))) {
            let campo = props.ticket
            if (props.ticket.codigo.includes('CRM')) {
                campo = {
                    ...props.ticket,
                    id: props.ticket.codigo.split('CRM-')[1]
                }
            }
            cargarLog('migracion_', campo, setLogs, setSeries, setTiempo, setTiempoTotal)
        }
    }, [props.ticket]);



    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_migracion', props.historial, userAuth)
                    if (props.historial && !ticket.codigo.includes('CRM')) {
                        cargarTop(setLogs, 'PWMI', ticket)
                    }
                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong>
                                            {ticket.unidadnegocio} <br />
                                            <strong>ID TICKET:</strong> {ticket.codigo}<br />
                                            <strong>ID CLIENTE:</strong>{ticket.idcliente}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>NOMBRES:</strong> {ticket.nombres} <br />
                                            <strong>CORREO:</strong> {ticket.email}<br />
                                            <strong>TELÉFONO:</strong>{ticket.contacto}<br />
                                            <strong>DIRECCIÓN:</strong>{ticket.direccion}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>NUEVO SERVICIO:</strong>
                                                        {ticket.nuevo_servicio}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.codigo.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Grid>
                                {((props.historial && !props.ticket.codigo.includes('CRM')) ||
                                    (props.historial && props.ticket.codigo.includes('CRM'))) &&
                                    <LogTicket ticket={logs} />
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />

                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    )
}


