import { Tabs, Container, Typography, Box, Tab, Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { NuevoPlan } from '../../../components/config/planes/NuevoPlan';
import { Planes } from '../../../components/config/planes/Planes';
import { PlanesInactivos } from '../../../components/config/planes/PlanesInactivos';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const PlanesPage = () => {
    const [value, setValue] = useState(0)
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Box pt={1} pl={1}>
                <Typography>
                    Planes
                </Typography>
            </Box>
            <Card>
                <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                    <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
                        <Tab label="Planes" />
                        <Tab label="Planes Inactivos" />
                        <Tab label="Configurar Nuevo Plan" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Planes />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PlanesInactivos />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <NuevoPlan />
                </TabPanel>
            </Card>
        </Container>
    )
}