import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Button,
  Collapse,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../../firebase";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx/xlsx.mjs";
import IconButton from "@mui/material/IconButton";
import { listFlotas } from "../../../redux/actions/flotas/flotasActions";
import { listLiders } from "../../../redux/actions/flotas/flotasLiders";
import { listAuxiliares } from "../../../redux/actions/flotas/flotasAuxiliares";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridExportMenuItemProps,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridApi,
  GridToolbar,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { TransferenciaMateriales } from "../../flotas/TransferenciaMateriales";
export const SolicitudesMaterialesInstalacionEspera = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const dispatch = useDispatch();
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = "POR ENTREGAR";
      color = "warning";
    } else if (estado === 1) {
      name = "NO RESPONDE LLAMADAS";
      color = "error";
    } else if (estado === 2) {
      name = "NO TIENE DINERO";
      color = "info";
    }
    return <Chip label={name} color={color} />;
  }
  const [open, setOpen] = useState(false);
  const [nombreZona, setNombreZona] = useState();
  const [reData, setreData] = useState([]);
  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  const [search, setSearch] = useState("");
  //
  const [total, setTotal] = useState(0);
  //
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData();
    dispatch(listFlotas());
    dispatch(listLiders());
    dispatch(listAuxiliares());
  }, [dispatch]);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("solicitudes_materiales_instalaciones").where("estado", "==", 0);
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("created", ">=", startfulldate);
      ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
        setIsLastPage(totalCount < 1);
      });
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onChange2 = () => {
    LoadData();
  };
  //
  const handleExport = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    var date1;
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`;
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`;
    }
    const reData = [
      ...data.map((doc) => {
        return {
          CEDULA: doc.cedula,
          CAJA: doc.caja,
          ZONA: doc.zona ? doc.zona : "",
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,
          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,
          ASESOR: doc.vendedor,
          created: doc.created.toDate(),
        };
      }),
    ];
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`);
  };
  //
  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }
  const columns = [
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado1', headerName: 'ESTADO', width: 160,
      renderCell: (params) => {
        return <Estado
          estado={
            params.row.estado_color === undefined
              ? params.row.estado
              : params.row.estado_color
          }
        />
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'camara', headerName: 'CAMARA', width: 150 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'numCam', headerName: 'NUM CAMERA', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },

    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'correo', headerName: 'CORREO', width: 150 },
    { field: 'direccion', headerName: 'DIRECCION', width: 300 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'caja', headerName: 'CAJA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'canton', headerName: 'CANTON', width: 200 },
    { field: 'planNombre', headerName: 'PLAN', width: 300 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
    { field: 'empleado', headerName: 'EMPLEADO', width: 200 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado', headerName: 'DISCAPACIDAD', width: 140,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'DISCAP DESCUENTO', width: 200,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : `${value.descuento} - ${value.discapacidad} - $${value.valor}`
    },
    {
      field: 'referido', headerName: 'REFERIDO', width: 100,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },
    {
      field: 'created', headerName: 'FECHA DE GENERACION DE PRE-VENTA', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },

    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 200 },

    { field: 'observacion', headerName: 'OBSERVACIONES', width: 200 },
    { field: 'numFlota', headerName: 'FLOTA', width: 150 },
    { field: 'nombreLider', headerName: 'NOMBRE LIDER', width: 250 },
    { field: 'nombreAuxiliar', headerName: 'NOMBRE AUXILIAR', width: 250 },
    {
      field: 'estado2', headerName: '', width: 100,
      renderCell: (params) => {
        return <TransferenciaMateriales props={params.row} />
      }
    },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <CustomExportButton />
      {/* <GridToolbarQuickFilter /> */}
    </GridToolbarContainer>
  );
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          VENTAS POR AGENDAR
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box display="flex" alignItems="center" py={2}>
            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
