import React, { useState, useEffect } from 'react'
import { Box, CssBaseline } from '@mui/material';
import {
    BrowserRouter,
    Routes,
    Route,
    HashRouter
} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Header } from '../components/appbar/Header';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { auth, db, firebase } from '../firebase'
import { LoginPage } from '../pages/authentication/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { NuevaVentaPage } from '../pages/ventas/NuevaVentaPage';
import { StatusPlans } from '../pages/config/planes/StatusPlans';

import { PostesPage } from '../pages/config/PostesPage';
import { HomePage } from '../pages/HomePage';
import { VentasRealizadas } from '../pages/ventas/VentasRealizadas';
import { PlanesPage } from '../pages/config/planes/PlanesPage';
import { VentasPreAprobadasPage } from '../pages/ventas/pre_aprobada/VentasPreAprobadasPage';
import { CargarDocumento } from '../pages/ventas/solicitud/venta/CargarDocumento';
import { CodigoVerificacion } from '../pages/ventas/solicitud/venta/CodigoVerificacion';
import { SolicitudServicioPage } from '../pages/ventas/solicitud/SolicitudServicioPage';
import { AgendaPage } from '../pages/agenda/AgendaPage';
import { FlotaPage } from '../pages/flotas/FlotaPage';
import { InstaladoresPage } from '../pages/tecnicos/InstaladoresPage';
import { RecursosHumanosPage } from '../pages/recursosHumanos/RecursosHumanosPage';
import { CajasPage } from '../pages/operaciones/factibilidad/CajasPage'
import { AsignacionOrdenDeTrabajo } from '../pages/planeacion/AsignacionOrdenDeTrabajo';
import { VentasPreAgendamiento } from '../pages/ventas/agenda/VentasPreAgendamiento';

import { UpdateCargarDocumentos } from '../pages/ventas/solicitud/venta/UpdateCargarDocumentos';
import { VentasPorReagendar } from '../components/ventas/agendadas/VentasPorReagendar';
import { PhotoInstaladores } from '../components/instaladores/photos/PhotoInstaladores';
import { RegistroOrdenDeTrabajo } from '../pages/planeacion/RegistroOrdenDeTrabajo';
import { Clientes } from '../components/clientes/Clientes';
import { VentasReagendadas } from '../components/ventas/agendadas/VentasReagendadas';
import { GrupoVentas } from '../pages/ventas/GrupoVentas';

import { ContratosCliente } from '../components/clientes/ContratosClientes';
import { MaterialesUtilizados } from '../components/inventario/MaterialesUtilizados';
import { ProductosPage } from '../pages/inventario/productos/ProductosPage';
import { CategoriasPage } from '../pages/inventario/categorias/CategoriasPage';
import { SubCategoriasPage } from '../pages/inventario/subcategoria/SubCategoriaPage';
import { ModeloProductoPage } from '../pages/inventario/modelo_producto/ModeloProductoPage';
import { MarcaProductoPage } from '../pages/inventario/marca_producto/MarcaProductoPage';
import { UnidadadesMedidaPage } from '../pages/inventario/unidad_medida/UnidadadesMedidaPage';
import { PresentacionPage } from '../pages/inventario/presentacion/PresentacionPage';
import { BodegaPage } from '../pages/inventario/bodega/BodegaPage';
import { UbicationPage } from '../pages/inventario/ubicacion/UbicationPage';
import { MotivoIngresoProductoPage } from '../pages/inventario/motivo_ingreso_producto/MotivoIngresoProductoPage';
import { MotivoEgresoProductoPage } from '../pages/inventario/motivo_egreso_producto/MotivoEgresoProductoPage';
import { IngresoProductosPage } from '../pages/inventario/IngresoProducto/IngresoProductosPage.jsx';
import { SolicitudesPage } from '../pages/solicitud/SolicitudPage';
import { SolicitudBodegaPage } from '../pages/bodega/SolicitudBodegaPage';
import { AgendaVerificacion } from '../components/ventas/agendadas/AgendaVerificacion';
import { NaturalezaDeCuentaPage } from '../pages/contabilidad/naturaleza_de_cuentas/NaturalezaDeCuentaPage';
import { ItemEstadoFinancieroPage } from '../pages/contabilidad/item_estado_financiero/ItemEstadoFinancieroPage';
import { TipoNombreDeCuentaPage } from '../pages/contabilidad/tipo_nombre_de_cuenta/TipoNombreDeCuentaPage';
import { TipoDeCuentaPage } from '../pages/contabilidad/tipo_de_cuenta/TipoDeCuentaPage';
import { ConfiguracionPlanDeCuentaPage } from '../pages/contabilidad/configuracion_plan_cuentas/CsonfiguracionPlanCuentasPage';
import { RecepcionMercaderia } from '../pages/inventario/recepcionMercaderia/RecepcionMercaderia';
import { ParametrosContablesPage } from '../pages/contabilidad/parametros_contables/ParametrosContablesPage';
import { CompraTabsPage } from '../pages/compras/CompraTabsPage';
import { FiscalizacionPage } from '../pages/operaciones/Fiscalizacion/FiscalizacionPage';
import { InventarioCompraPage } from '../pages/compras/InventarioCompraPage';
import { ProveedoresPage } from '../pages/compras/Proveedores/ProveedoresPage';
import { KardexPage } from '../pages/compras/Kardex/KardexPage';
import { EgresoProductosPage } from '../pages/inventario/EgresoProducto/EgresoProductosPage';
import { TipoProductoPage } from '../pages/inventario/tipo_producto/TipoProductoPage';
import { PagoClientes } from '../components/clientes/PagoClientes';
import { ReportesPage } from '../pages/Reportes/ReportesPage';
import { CargaInicial } from '../pages/inventario/carga_inicial/CargaInicial';
import { ReporteStock } from '../pages/inventario/reporte_stock/ReporteStock';
import { ReporteMovimientos } from '../pages/inventario/reporte_de_movimientos/ReporteMovimientos';
import { OrdenComprasPage } from '../pages/compras/OrdenCompras/OrdenComprasPage';
import { LogsPage } from '../pages/administracion/LogsPage';
import { SolicitudRequerimientoPage } from '../pages/requerimientos-sistema/SolicitudRequerimientoPage';
import { Nomina } from '../pages/recursosHumanos/Nomina/Nomina';
import { LibroDiario } from '../pages/contabilidad/asientos/LibroDiario';
import { PagoDebitoClientes } from '../components/clientes/PagoDebitoClientes';
import { CentrosDeCostosPage } from '../pages/contabilidad/centros_de_costos/CentrosDeCostosPage';
import { EjercicioContable } from '../pages/contabilidad/ejercicio_contable/EjercicioContable';
import { NetworkingPage } from '../pages/networking/NetworkingPage';
import { CargaSeries } from '../pages/inventario/carga_series/CargaSeries';
import { PlanDeCuentas } from '../pages/contabilidad/plan-de-cuentas/PlanDeCuentas';
import { NewUsers } from '../pages/networking/radius/NewUsers';
import { NewUserPage } from '../pages/networking/radius/new_user/NewUserPage';
import { ListUsersPage } from '../pages/networking/radius/ListUsersPage';
import { QuickUser } from '../pages/networking/radius/QuickUser';
import { VisitaDeNodosPage } from '../pages/operaciones/VisitaDeNodos/VisitaDeNodosPage';
import { AprobarSolicitudPage } from '../pages/bodega/AprobarSolicitudPage';
import { EstadoDeResultados } from '../pages/contabilidad/reportes_financieros/EstadoDeResultados';
import { BalanceGeneral } from '../pages/contabilidad/reportes_financieros/BalanceGeneral';
import { BalanceDeComprobacion } from '../pages/contabilidad/reportes_financieros/BalanceDeComprobacion.jsx';
import { EditAccount } from '../pages/authentication/EditAccount';
import { FlujoDeCaja } from '../pages/contabilidad/reportes_financieros/FlujoDeCaja';
import { Financiero } from '../pages/ReportesResumen/financiero';
import { ProductosInventarioPage } from '../pages/contabilidad/ProductosInventario/ProductosInventarioPage';
import { ProductosActivosFijo, ProductosActivosFijoPage } from '../pages/contabilidad/ProductosActivosFijo/ProductosActivosFijoPage';
import { ProductosContabilidadPage } from '../pages/contabilidad/ProductosContabilidad/ProductosContabilidadPage';
import { NuevoProducto } from '../pages/inventario/productos/NuevoProducto';
import { NuevoProductoSelects } from '../pages/inventario/productos/NuevoProductoSelects';
import { CrearProducto } from '../pages/ActivosFijos/CrearProducto';
import { TipoActivosFijosPage } from '../pages/ActivosFijos/parametros/TipoActivosFijos/TipoActivosFijosPage';
import { EstadoProductoPage } from '../pages/ActivosFijos/parametros/EstadoProducto/EstadoProductoPage';
import { VidaUtilPage } from '../pages/ActivosFijos/parametros/VidaUtil/VidaUtilPage';
import { ClaseDeActivoPage } from '../pages/ActivosFijos/parametros/ClaseDeActivo/ClaseDeActivoPage';
import { MetodoDeDepreciacionPage } from '../pages/ActivosFijos/parametros/MetodoDeDepreciacion/MetodoDeDepreciacionPage';
import { TipoDeProductoPage } from '../pages/ActivosFijos/parametros/General/TipoDeProducto/TipoDeProductoPage';
import { ClaseDeProductoPage } from '../pages/ActivosFijos/parametros/General/ClaseDeProducto/ClaseDeProductoPage';

import { FrecuenciaDepreciacionPage } from '../pages/ActivosFijos/parametros/Depreciacion/FrecuenciaDepreciacion/FrecuenciaDepreciacionPage';

import { CondicionDeActivoPage } from '../pages/ActivosFijos/parametros/InformacionTecnica/CondicionDeActivo/CondicionDeActivoPage';

import { LocalidadPage } from '../pages/ActivosFijos/parametros/Localidad/LocalidadProducto/LocalidadPage';
import { DepartamentoPage } from '../pages/ActivosFijos/parametros/Localidad/Departamento/DepartamentoPage';

import { GestionTeleventasPage } from '../pages/gestion_post_venta/televentas/GestionTeleventasPage';
import CrearProductoSteeper from '../pages/ActivosFijos/CrearProductoSteeper';
import { UsuariosPage } from '../pages/clientes/UsuariosPage';
import { ViewUsuariosPage } from '../pages/clientes/usuarios/ViewUsuariosPage';
import { ListProductosActivosFijos } from '../pages/ActivosFijos/ListProductosActivosFijos';
import { CargaMasiva } from '../pages/clientes/CargaMasiva';
import { ZonasPage } from '../pages/config/Zonas/ZonasPage';
import { MantenimientoFechaDeCortePage } from '../pages/config/MantenimientoFechaDeCorte/MantenimientoFechaDeCortePage';
import { PromocionesPage } from '../pages/config/Promociones/PromocionesPage';
import { FamilyCardPage } from '../pages/clientes/FamilyCardPage';
import { SubcategoriaActivosPage } from '../pages/ActivosFijos/parametros/General/subcategoria/SubcategoriaActivosPage';
import { TipoTransaccionPage } from '../pages/ActivosFijos/parametros/Depreciacion/TipoTransaccion/TipoTransaccionPage';
import { ResidualPage } from '../pages/ActivosFijos/parametros/Depreciacion/Residual/ResidualPage';
import { CategoriaActivoPage } from '../pages/ActivosFijos/parametros/General/categoria/CategoriaActivoPage';
import { UnidadMedidaPage } from '../pages/ActivosFijos/parametros/General/UnidadMedida/UnidadMedidaPage';
import { ModeloActivosPage } from '../pages/ActivosFijos/parametros/InformacionTecnica/ModeloActivos/ModeloActivosPage';
import { MarcasActivosPage } from '../pages/ActivosFijos/parametros/InformacionTecnica/MarcasActivos/MarcasActivosPage';
import { PorcentajeDeDepreciacionPage } from '../pages/ActivosFijos/parametros/Depreciacion/PorcentajeDeDepreciacion/PorcentajeDeDepreciacionPage';
import { TransferenciaActivos } from '../pages/ActivosFijos/Transferencias/TransferenciasPage';
import { Cargando } from '../components/utils/Cargando';
import { SectorPage } from '../pages/config/Sector/SectorPage';
import { NuevoTicket } from '../pages/Tickets/NuevoTicket/NuevoTicket';
import { DepartamentosPage } from '../pages/Tickets/Parametros/Departamentos/DepartamentosPage';
import { CallCenterPage } from '../pages/call_center/CallCenterPage';
import { CentroCostoPage } from '../pages/ventas/centro_costo/CentroCostoPage';
import { CodigosOtpPage } from '../pages/administracion/CodigosOtpPage';
import { VentasAgendadas } from '../pages/agenda/VentasAgendadas';
import { VentasSubirVoucher } from '../pages/ventas/VentasSubirVoucher';
import { PlanificadorLogisticos } from '../pages/operaciones/planificador/PlanificadorLogisticosPage';
import { FactibilidadPage } from '../pages/operaciones/factibilidad/FactibilidadPage';
import { ConfiguracionPlantillas } from '../pages/administracion/ConfiguracionPlantillas';
import { CambioPropietarioPage } from '../pages/gestion_post_venta/cambio_propietario/CambioPropietarioPage';
import { SolicitudCambioDomicilio } from '../pages/gestion_post_venta/cambio_domicilio/NuevoCambioDomicilio';
import { CambioDomicilioPage } from '../pages/gestion_post_venta/cambio_domicilio/CambioDomicilioPage';
import { DepartamentoInventarioPage } from '../pages/inventario/departamentos/DepartamentoInventarioPage';
import { CrearKitsInventarioPage } from '../pages/inventario/Kits/CrearKitsInventarioPage';
import { SolicitudesDeInstalacionesPage } from '../pages/bodega/SolicitudesDeInstalaciones/SolicitudesDeInstalacionesPage';
import { TransferenciaMercaderiaAprobacion } from '../pages/inventario/transferenciaMercaderia/AprobacionTranferencias/TransferenciaMercaderiaAprobacion';
import { RecepcionTransferenciaPage } from '../pages/inventario/transferenciaMercaderia/RecepcionTransferencia/RecepcionTransferenciaPage';
import { TransferenciaMercaderia } from "../pages/inventario/transferenciaMercaderia/TransferirProductos/TransferenciaMercaderia";
import { ReportesDeVentas } from '../pages/Reportes/ReportesDeVentas';
import { ListsBox } from '../pages/operaciones/pruebas/ListBox';
import { MigracionPage } from '../pages/gestion_post_venta/migracion/MigracionPage';
import { PostVentaSolicitudServicioPage } from '../pages/gestion_post_venta/PostVentaSolicitudServicioPage';
import { VentasAgendaPage } from '../pages/ventas/solicitud/VentasAgendaPage';
import { CorreosPage } from '../components/clientes/correos/CorreosPage';
import { ConfiguracionFactura } from '../pages/config/Facturas/ConfiguracionFactura';
import { ActualizarFechaScreen } from '../pages/inventario/ActualizarFecha/ActualizarFechaScreen';
import { ConsultaServicios } from '../pages/call_center/ConsultaServicios';
import { UsuariosServicios } from '../pages/call_center/UsuariosServicios';
import { AsuntoTicketPage } from '../pages/Tickets/Parametros/AsuntoTicket/AsuntoTicketPage';
import { OficinasPage } from '../pages/Tickets/Parametros/Oficinas/OficinasPage';
import { TurnoPage } from '../pages/Tickets/Parametros/Turno/TurnoPage';
import { TicketsPage } from '../pages/Tickets/TicketsPage';
import { TicketsPlaneacionPage } from '../pages/planeacion/Tickets/TicketsPlaneacionPage';
import { TicketsInstaladorOperacionesPage } from '../pages/operaciones/TicketsInstalacionOperaciones/TicketsInstaladorOperacionesPage';
import { TicketsEnEsperaInstaladorPage } from '../pages/instaladores/TicketsInstalador/TicketsEnEsperaInstaladorPage';
import { TicketsResumen } from '../pages/Tickets/TicketsResumen';
import { SolicitudMarketingPage } from '../pages/marketing/SolicitudMarketingPage';
import { MaterialesAsignadosScreen } from '../pages/instaladores/MaterialesAsignados/MaterialesAsignadosScreen';
import { Facturas } from '../pages/cobranzas/Facturas';
import { Transacciones } from '../pages/cobranzas/Transacciones';
import { MaterialesUtilizados2 } from '../pages/instaladores/MaterialesUtilizados2';
import { KardexInstaladores } from '../pages/instaladores/KardexInstaladores/KardexInstaladores';
import { Debito } from '../pages/cobranzas/Debito';
import { CajasNoUsadasPage } from '../pages/operaciones/factibilidad/CajasNoUsadasPage';
import { Credito } from '../pages/cobranzas/Credito';
import { AddMasiveClients } from '../pages/clientes/facturacion/tabs/AddMasiveClients';
import { CargaMasivaCobranza } from '../pages/cobranzas/CargaMasivaCobranza';
import { BtnSoporte1 } from '../pages/call_center/BtnSoporte1';
import { ClientesMaps } from '../pages/ClientesMaps';

import { CondicionesPagoPage } from '../pages/compras_pagar/parametros/condiciones_pago/CondicionesPagoPage';
import { DescuentosProntoPagoPage } from '../pages/compras_pagar/parametros/descuentos_pronto_pago/DescuentosProntoPagoPage';
import { Retenciones } from '../pages/compras_pagar/Retenciones';
import { ParametrosGeograficos } from '../pages/compras_pagar/parametros/geografico/ParametrosGeograficos';
import { CatalogoRetenciones } from '../pages/compras_pagar/parametros/CatalogoRetenciones';
import { BlockFactura } from '../pages/compras_pagar/parametros/BlockFactura';
import { CreditosProvedor2 } from '../pages/compras_pagar/documentos/creditos_proveedor/CreditosProveedor2';
import { FormasPago } from '../pages/compras_pagar/parametros/formas_pago/FormasPago';
import { TipoIdentificacion } from '../pages/compras_pagar/parametros/tipo_identificacion/TipoIdentificacion';
import { TipoComprobantes } from '../pages/compras_pagar/parametros/tipo_comprobantes/TipoComprobantes';
import { TipoPersonas } from '../pages/compras_pagar/parametros/tipo_personas/TipoPersonas';
import { GrupoProveedor } from '../pages/compras_pagar/parametros/tipo_grupo/GrupoProveedor';
import { TipoContribuyente } from '../pages/compras_pagar/parametros/tipo_contribuyente/TipoContribuyente';
import { TipoSustento } from '../pages/compras_pagar/parametros/sustentos_tributario/TipoSustento';
import { TipoCompras } from '../pages/compras_pagar/parametros/tipos_compras/TipoCompras';
import { RetencionesIva } from '../pages/compras_pagar/parametros/retenciones_iva/RetencionesIva';
import { RetencionesFuente } from '../pages/compras_pagar/parametros/retenciones_fuentes/RetencionesFuente';
import { CatalogoIva } from '../pages/compras_pagar/parametros/CatalogoIva';
import { ProductoRetencion } from '../pages/compras_pagar/parametros/ProductoRetencion';
import { Secuenciales } from '../pages/compras_pagar/Secuenciales';
import { ProveedorProducto } from '../pages/compras_pagar/parametros/ProveedorProducto';
import { DocumentosIngresados } from '../pages/compras_pagar/DocumentoIngresados';
import { GenerarATS } from '../pages/compras_pagar/GenerarATS';
import { LuktvPage } from '../pages/clientes/luktv/LuvktvPage';
import { LogZimbra } from '../pages/extra/LogZimbra';
import { CobranzaPichincha } from '../pages/extra/CobranzaPichincha';
import { ConciliacionBanco } from '../pages/extra/ConciliacionBanco';
import { ReporteChurn } from '../pages/Reportes/ReporteChurn';
import { EncuestasPage } from '../pages/encuestas/EncuestasPage';
import { ReingresoPage } from '../pages/cobranzas/ReingresoPage';
import { MaterialesTecnicos } from '../pages/instaladores/MaterialesTecnicos/MaterialesTecnicos';
import { ReporteStockHistorico } from '../pages/inventario/reporte_stock/ReporteStockHistorico';
import { PantallaPdf } from '../pages/bodega/PantallaPdf';
import { EgresoFlotas } from '../pages/inventario/EgresoFlotas/EgresoFlotas';
import { PremiosList } from '../pages/premios/PremiosList.js';

export const AppRouters = () => {
    let theme = createTheme({
        // typography: {
        //     fontFamily: 'Raleway, Arial',
        // },
        palette: {
            primary: {
                main: '#2D033B',
            },
            secondary: {
                main: '#672780'
            },
            background: {
                default: '#f0f2f5'
            },
            paper: {
                main: '#67308F',
            }
        },
        shape: {
            borderRadius: 4
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#2D033B",
                        color: "#eaeff1",
                        boxSizing: "border-box",
                    }
                }
            }
        }
    });
    theme = responsiveFontSizes(theme);
    const dispatch = useDispatch();
    const [checking, setChecking] = useState(true);
    const [isAuth, setIsAuth] = useState(false)
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            if (userAuth) {
                db.collection("usuarios").doc(`${userAuth.uid}`).get()
                    .then((doc) => {
                        if (doc.exists) {
                            let dataClient = doc.data()
                            const dataT = {
                                ...dataClient,
                                displayName: dataClient.nombres,
                                rol: '',
                                departamento: '',
                                cargo: '',
                                departamentoText: '',
                                user: {}
                            }
                            setChecking(false)
                            setIsAuth(true)
                            dispatch({
                                type: 'AUTH_ADD_ITEMS',
                                payload: dataT,
                            })
                            const userStatusFirestoreRef = firebase.firestore().doc(`/status/${dataClient.uid}`);
                            //Onlinea users
                            const userStatusDatabaseRef = firebase.database().ref(`/status/${dataClient.uid}`)
                            const isOfflineForDatabase =
                            {
                                state: 'offline',
                                last_changed: firebase.database.ServerValue.TIMESTAMP
                            }
                            const isOnlineForDatabase = {
                                state: 'online',
                                last_changed: firebase.database.ServerValue.TIMESTAMP,
                            }
                            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                                if (snapshot.val() == false) {
                                    return;
                                };
                                userStatusDatabaseRef.onDisconnect().update(isOfflineForDatabase).then(function () {
                                    userStatusDatabaseRef.update(isOnlineForDatabase);
                                });
                            });
                            // Firestore uses a different server timestamp value, so we'll
                            // create two more constants for Firestore state.
                            const isOfflineForFirestore = {
                                state: 'offline',
                                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                            }
                            const isOnlineForFirestore = {
                                state: 'online',
                                last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                            }
                            firebase.database().ref('.info/connected').on('value', function (snapshot) {
                                if (snapshot.val() == false) {
                                    // En lugar de simplemente regresar, también estableceremos el estado de Firestore
                                    // a 'sin conexión'. Esto asegura que nuestro caché de Firestore esté al tanto
                                    // del cambio a 'sin conexión'. 
                                    //userStatusFirestoreRef.update({
                                    //state: 'offline',
                                    //last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                                    //});
                                    return;
                                };
                                userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                                    userStatusDatabaseRef.set(isOnlineForDatabase);
                                    // We'll also add Firestore set here for when we come online.
                                    //userStatusFirestoreRef.update({
                                    //state: 'online',
                                    //last_changed: firebase.firestore.FieldValue.serverTimestamp(),
                                    //});
                                });
                            });

                        } else {
                            console.log("No se encontro usuario");
                        }
                    })
            } else {
                setChecking(false)
                setIsAuth(false)
            }
        });
    }, [setIsAuth, setChecking, dispatch])
    if (checking) {
        return (<Cargando />)
    }
    return (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header isAuth={isAuth}>
                    <Routes>
                        <Route path='encuestas' element={
                            <PrivateRoute isAuth={isAuth}>
                                <EncuestasPage />
                            </PrivateRoute>
                        } />

                        <Route path='/' element={
                            <PrivateRoute isAuth={isAuth}>
                                <HomePage />
                            </PrivateRoute>
                        } />
                        <Route path='/maps' element={
                            <PrivateRoute isAuth={isAuth}>
                                <ClientesMaps />
                            </PrivateRoute>
                        } />
                        <Route path='account'>
                            <Route path='login' element={
                                <PublicRoute isAuth={isAuth}>
                                    <LoginPage />
                                </PublicRoute>
                            } />
                            <Route path='edit' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EditAccount />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="ventas">
                            <Route path='solicitud-de-servicio/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudServicioPage />
                                </PrivateRoute>
                            } />
                            <Route path='solicitudes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudesPage />
                                </PrivateRoute>
                            } />
                            <Route path='centro_costo/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CentroCostoPage />
                                </PrivateRoute>
                            } />
                            <Route path='televentas' element={
                                <PrivateRoute isAuth={isAuth}>

                                </PrivateRoute>
                            } />
                            <Route path='ventas-pre-aprobadas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasPreAprobadasPage />
                                </PrivateRoute>
                            } />

                            <Route path='ventas-por-agendar/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasPreAgendamiento />
                                </PrivateRoute>
                            } />

                            <Route path='ventas-por-reagendar/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasPorReagendar />
                                </PrivateRoute>
                            } />

                            <Route path='ventas-agendadas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasSubirVoucher />
                                </PrivateRoute>
                            } />

                            <Route path='ventas-reagendadas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasReagendadas />
                                </PrivateRoute>
                            } />
                            <Route path='ventas-realizadas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasRealizadas />
                                </PrivateRoute>
                            } />
                            <Route path='actualizar-foto/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NuevaVentaPage />
                                </PrivateRoute>
                            } />
                            <Route path='nueva-venta/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NuevaVentaPage />
                                </PrivateRoute>
                            } />
                            <Route path='cargar-documento/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargarDocumento />
                                </PrivateRoute>
                            } />

                            <Route path='actualizar-documento/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UpdateCargarDocumentos />
                                </PrivateRoute>
                            } />
                            <Route path='codigo-verificacion/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CodigoVerificacion />
                                </PrivateRoute>
                            } />
                            <Route path='gestion-vendedores/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <GestionTeleventasPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="operaciones">

                            <Route path='fiscalizacion/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FiscalizacionPage />
                                </PrivateRoute>
                            } />
                            <Route path='planificador_logistico/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PlanificadorLogisticos />
                                </PrivateRoute>
                            } />
                            <Route path='factibilidad/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FactibilidadPage />
                                </PrivateRoute>
                            } />

                            <Route
                                path="tickets/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TicketsInstaladorOperacionesPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route path='postes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PostesPage />
                                </PrivateRoute>
                            } />

                            <Route path='cajas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CajasPage />
                                </PrivateRoute>
                            } />

                            <Route path='cajas-no-usadas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CajasNoUsadasPage />
                                </PrivateRoute>
                            } />

                            <Route path='visita-de-nodos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VisitaDeNodosPage />
                                </PrivateRoute>
                            } />
                            <Route path='pruebas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ListsBox />
                                </PrivateRoute>
                            } />
                        </Route>

                        <Route path="networking">
                            <Route path='configured' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NetworkingPage />
                                </PrivateRoute>
                            } />
                            <Route path='radius'>
                                <Route path='list-user' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ListUsersPage />
                                    </PrivateRoute>
                                } />
                                <Route path='new-user' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <NewUserPage />
                                    </PrivateRoute>
                                } />
                                <Route path='quick-user' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <QuickUser />
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path="grupos">
                            <Route path='grupo-ventas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <GrupoVentas />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="gestion_post_ventas">
                            <Route path='solicitude_de_servicio/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PostVentaSolicitudServicioPage />
                                </PrivateRoute>
                            } />
                            <Route path='agenda/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VentasAgendaPage />
                                </PrivateRoute>
                            } />
                            <Route path='cambio_domicilio/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CambioDomicilioPage />
                                </PrivateRoute>
                            } />

                            <Route path='cambio_domicilio/ticket/:ticket' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CambioDomicilioPage />
                                </PrivateRoute>
                            } />

                            <Route path='cambio_propietario/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CambioPropietarioPage />
                                </PrivateRoute>
                            } />
                            <Route path='migracion/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MigracionPage />
                                </PrivateRoute>
                            } />
                            <Route path='migracion/ticket/:ticket' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MigracionPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="Flota">
                            <Route path='GestionDeFlota/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FlotaPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="Planeacion">

                            <Route path='Agenda/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AgendaPage />
                                </PrivateRoute>
                            } />

                            <Route path='tickets/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPlaneacionPage />
                                </PrivateRoute>
                            } />

                        </Route>


                        <Route path="configuraciones">
                            <Route path='planes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PlanesPage />
                                </PrivateRoute>
                            } />
                            <Route path='zonas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ZonasPage />
                                </PrivateRoute>
                            } />
                            <Route path='facturas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConfiguracionFactura />
                                </PrivateRoute>
                            } />

                            <Route path='sectores/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SectorPage />
                                </PrivateRoute>
                            } />

                            <Route path='mantenimiento-fecha-de-corte/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MantenimientoFechaDeCortePage />
                                </PrivateRoute>
                            } />

                            <Route path='promociones/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PromocionesPage />
                                </PrivateRoute>
                            } />

                            {/* <Route path='GestionDeUsuarios/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <InstaladoresPage />
                                    </PrivateRoute>
                                } /> */}

                            <Route path='Estado%20planes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <StatusPlans />
                                </PrivateRoute>
                            } />

                            <Route path='instaladores/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <InstaladoresPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="RecursosHumanos">
                            <Route path='CrearUsuario/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <RecursosHumanosPage />
                                </PrivateRoute>
                            } />

                            <Route path='Nomina/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Nomina />
                                </PrivateRoute>
                            } />

                        </Route>
                        <Route path="call_center" element={
                            <PrivateRoute isAuth={isAuth}>
                                <CallCenterPage />
                            </PrivateRoute>
                        } />

                        <Route path="call_center">
                            <Route path="consulta_servicios/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConsultaServicios />
                                </PrivateRoute>
                            } />
                            <Route path="consulta_usuarios/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UsuariosServicios />
                                </PrivateRoute>
                            } />
                            <Route path="soporte1/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <BtnSoporte1 />
                                </PrivateRoute>
                            } />
                        </Route>

                        {/* Instaladores */}
                        <Route path="Instaladores">
                            <Route path='GestionInstaladores/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <InstaladoresPage />
                                </PrivateRoute>
                            } />
                            <Route path='asignacion-orden-trabajo/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AsignacionOrdenDeTrabajo />
                                </PrivateRoute>
                            } />
                            <Route path='registro-orden-de-trabajo/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    {/* <RegistroOrdenDeTrabajo /> */}
                                    <MaterialesUtilizados2 />
                                </PrivateRoute>
                            } />
                            <Route path='subir-foto/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PhotoInstaladores />
                                </PrivateRoute>
                            } />
                            <Route path='tickets/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsEnEsperaInstaladorPage />
                                </PrivateRoute>
                            } />

                            {/* <Route path='materiales-disponibles/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MaterialesAsignadosScreen />
                                </PrivateRoute>
                            } /> */}
                        </Route>

                        {/* Clientes */}
                        <Route path="clientes">
                            <Route path='usuarios' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UsuariosPage />
                                </PrivateRoute>
                            } />
                            <Route path='correos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CorreosPage />
                                </PrivateRoute>
                            } />
                            <Route path='family_card' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FamilyCardPage />
                                </PrivateRoute>
                            } />
                            <Route path='luktv' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <LuktvPage />
                                </PrivateRoute>
                            } />
                            <Route path='usuarios/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ViewUsuariosPage />
                                </PrivateRoute>
                            } />
                            <Route path='agenda-verificacion/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AgendaVerificacion />
                                </PrivateRoute>
                            } />
                            <Route path='gestionClientes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Clientes />
                                </PrivateRoute>
                            } />
                            <Route path='contratos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ContratosCliente />
                                </PrivateRoute>
                            } />
                            <Route path='pagos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PagoClientes />
                                </PrivateRoute>
                            } />
                            <Route path='pagos/debito' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PagoDebitoClientes />
                                </PrivateRoute>
                            } />

                            <Route path='carga-masiva' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargaMasiva />
                                </PrivateRoute>
                            } />

                            <Route path='carga-facturas' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AddMasiveClients />
                                </PrivateRoute>
                            } />

                        </Route>

                        {/* Marketing */}
                        <Route path="marketing">
                            <Route
                                path="solicitud/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <SolicitudMarketingPage />
                                    </PrivateRoute>
                                }
                            />
                        </Route>
                        {/* Reportes */}
                        <Route path="reportes">
                            <Route path='ver-reportes/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReportesPage />
                                </PrivateRoute>
                            } />
                            <Route path='reportes-de-ventas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReportesDeVentas />
                                </PrivateRoute>
                            } />
                            <Route path='reportes-retirados/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReporteChurn />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='cuentas_pagar'>
                            {/* <Route path='retenciones/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Retenciones />
                                </PrivateRoute>
                            } /> */}
                            <Route path='secuenciales/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Secuenciales />
                                </PrivateRoute>
                            } />

                            <Route path='parametros' >
                                <Route path='tipo_identificacion/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoIdentificacion />
                                    </PrivateRoute>
                                } />
                                <Route path='centro-de-costos/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CentrosDeCostosPage />
                                    </PrivateRoute>
                                } />
                                <Route path='tipo_comprobantes/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoComprobantes />
                                    </PrivateRoute>
                                } />
                                <Route path='condiciones_pago/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CondicionesPagoPage />
                                    </PrivateRoute>
                                } />
                                <Route path='descuentos_pronto_pago/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <DescuentosProntoPagoPage />
                                    </PrivateRoute>
                                } />
                                <Route path='tipo_personas/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoPersonas />
                                    </PrivateRoute>
                                } />
                                <Route path='grupo_proveedor/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <GrupoProveedor />
                                    </PrivateRoute>
                                } />
                                <Route path='tipo_contribuyentes/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoContribuyente />
                                    </PrivateRoute>
                                } />
                                <Route path='tipo_transaccion/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                    </PrivateRoute>
                                } />
                                <Route path='sustento_tributario/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoSustento />
                                    </PrivateRoute>
                                } />
                                <Route path='formas_pago/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <FormasPago />
                                    </PrivateRoute>
                                } />
                                <Route path='retenciones_iva/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <RetencionesIva />
                                    </PrivateRoute>
                                } />

                                <Route path='tipos_compras/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoCompras />
                                    </PrivateRoute>
                                } />
                                <Route path='catalogo_iva/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CatalogoIva />
                                    </PrivateRoute>
                                } />
                                <Route path='retenciones_fuentes/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <RetencionesFuente />
                                    </PrivateRoute>
                                } />
                                <Route path='catalogo_retenciones/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CatalogoRetenciones />
                                    </PrivateRoute>
                                } />
                                <Route path='producto_retencion/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ProductoRetencion />
                                    </PrivateRoute>
                                } />
                                <Route path='proveedor_producto/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ProveedorProducto />
                                    </PrivateRoute>
                                } />
                                <Route path='block_factura/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <BlockFactura />
                                    </PrivateRoute>
                                } />
                                <Route path='parametros_geograficos/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ParametrosGeograficos />
                                    </PrivateRoute>
                                } />
                            </Route>
                            <Route path='documentos' >
                                <Route path='proveedores_nuevo/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ProveedoresPage />
                                    </PrivateRoute>
                                } />
                                <Route path='solicitud_compras/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CompraTabsPage />
                                    </PrivateRoute>
                                } />
                                <Route path='orden_compras/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <OrdenComprasPage />
                                    </PrivateRoute>
                                } />
                                <Route path='creditos_proveedores/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CreditosProvedor2 />
                                    </PrivateRoute>
                                } />
                                <Route path='documentos/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <Retenciones />
                                    </PrivateRoute>
                                } />
                                <Route path='documentosIngresados/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <DocumentosIngresados />
                                    </PrivateRoute>
                                } />
                                <Route path='ats/' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <GenerarATS />
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        {/* Inventario */}
                        <Route path="inventario">
                            {/* PARAMETROS DE INVENTARIO */}
                            <Route
                                path="parametros-de-inventario/categorias/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CategoriasPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/subCategorias/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <SubCategoriasPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/modelo-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ModeloProductoPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/marca-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <MarcaProductoPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/unidad-medida-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <UnidadadesMedidaPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/presentacion-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <PresentacionPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/bodegas-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <BodegaPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='parametros-de-inventario/ubicaciones-productos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UbicationPage />
                                </PrivateRoute>
                            } />
                            <Route
                                path="parametros-de-inventario/departamentos-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <DepartamentoInventarioPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/motivo-ingreso-producto/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <MotivoIngresoProductoPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/motivo-egreso-producto/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <MotivoEgresoProductoPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/tipos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TipoProductoPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="parametros-de-inventario/crear-kits/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CrearKitsInventarioPage />
                                    </PrivateRoute>
                                }
                            />

                            {/* SOLICITUDES */}

                            <Route
                                path="solicitudes/compras/:id"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <InventarioCompraPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="solicitudes/solicitud-inventario/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <SolicitudBodegaPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route path='solicitudes/solicitud-inventario/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PantallaPdf />
                                </PrivateRoute>
                            } />

                            <Route
                                path="solicitudes/solicitudes-de-materiales/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <SolicitudesDeInstalacionesPage />
                                    </PrivateRoute>
                                }
                            />

                            {/* PRODUCTOS */}

                            <Route
                                path="productos/actualizar-fecha/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ActualizarFechaScreen />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/ingreso-de-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <IngresoProductosPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/egreso-de-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <EgresoProductosPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/recepcion-de-mercaderia/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <RecepcionMercaderia />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/egreso-flotas/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <EgresoFlotas />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/carga-inicial/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CargaInicial />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ProductosPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/carga-series/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CargaSeries />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/transferencias/transferir-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TransferenciaMercaderia />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/transferencias/aprobar-transferencias/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <TransferenciaMercaderiaAprobacion />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/transferencias/recepcion-transferencias/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <RecepcionTransferenciaPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="productos/transferencias/ubicaciones-productos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <UbicationPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route path='productos/carga-inicial/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargaInicial />
                                </PrivateRoute>
                            } />

                            {/* DOCUMENTACION */}

                            <Route
                                path="documentacion/reporte-de-stock/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ReporteStock />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="documentacion/reporte-de-stock-historico/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ReporteStockHistorico />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="documentacion/reporte-de-movimientos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <ReporteMovimientos />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="documentacion/kardex"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <KardexPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="documentacion/kardex-instalaciones/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <KardexInstaladores />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="documentacion/materiales-tecnicos/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <MaterialesTecnicos />
                                    </PrivateRoute>
                                }
                            />

                        </Route>

                        <Route path="inventario">
                            <Route path='gestionInventario/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MaterialesUtilizados />
                                </PrivateRoute>
                            } />
                        </Route>
                        {/* 
                            <Route path='recepcionMercaderia/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <RecepcionMercaderia />
                                </PrivateRoute>
                            } />

                            <Route path='TransferenciaMercaderia/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TransferenciaMercaderia />
                                </PrivateRoute>
                            } />

                            <Route path='productos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ProductosPage />
                                </PrivateRoute>
                            } />

                            <Route path='categorias/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CategoriasPage />
                                </PrivateRoute>
                            } />

                            <Route path='subCategorias/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SubCategoriasPage />
                                </PrivateRoute>
                            } />

                            <Route path='modeloProductos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ModeloProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='marcaProductos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MarcaProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='unidadMedidaProducto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UnidadadesMedidaPage />
                                </PrivateRoute>
                            } />

                            <Route path='presentacion/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PresentacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='bodega/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <BodegaPage />
                                </PrivateRoute>
                            } />

                            <Route path='ubicaciones/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UbicationPage />
                                </PrivateRoute>
                            } />

                            <Route path='motivo-ingreso-producto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MotivoIngresoProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='motivo-egreso-producto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MotivoEgresoProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='ingresoProducto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <IngresoProductosPage />
                                </PrivateRoute>
                            } />

                            <Route path='egresoProducto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EgresoProductosPage />
                                </PrivateRoute>
                            } />

                            <Route path='solicitud/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudBodegaPage />
                                </PrivateRoute>
                            } />

                            <Route path='compras/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <InventarioCompraPage />
                                </PrivateRoute>
                            } />

                            <Route path='tipo/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='reporte-de-movimientos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReporteMovimientos />
                                </PrivateRoute>
                            } />

                            <Route path='reporte-stock/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReporteStock />
                                </PrivateRoute>
                            } />

                            <Route path='carga-inicial/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargaInicial />
                                </PrivateRoute>
                            } />

                            <Route path='carga-series/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargaSeries />
                                </PrivateRoute>
                            } />

                            <Route path='kardex' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <KardexPage />
                                </PrivateRoute>
                            } />
                        </Route> */}

                        <Route path="supervision-operacion">

                            <Route path='agenda/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AgendaPage />
                                </PrivateRoute>
                            } />

                            <Route path='tecnicos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <InstaladoresPage />
                                </PrivateRoute>
                            } />

                            <Route
                                path="kardex-instalaciones/"
                                element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <KardexInstaladores />
                                    </PrivateRoute>
                                }
                            />

                        </Route>

                        {/* Clientes */}
                        <Route path="codigos">
                            <Route path='codigosOtp/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CodigosOtpPage />
                                </PrivateRoute>
                            } />
                        </Route>

                        <Route path="solicitud">
                            <Route path='compra/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudesPage />
                                </PrivateRoute>
                            } />
                            <Route path='bodega' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudBodegaPage />
                                </PrivateRoute>
                            } />
                            <Route path='compra' element={
                                <PrivateRoute isAuth={isAuth}>

                                </PrivateRoute>
                            } />
                            <Route path='aprobar/0' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AprobarSolicitudPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="contabilidad">
                            <Route path='asientos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <LibroDiario />
                                </PrivateRoute>
                            } />
                            <Route path='ejercicios-contables/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EjercicioContable />
                                </PrivateRoute>
                            } />
                            {/* <Route path='plan-de-cuentas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ListPlanDeCuentas />
                                </PrivateRoute>
                            } /> */}
                            <Route path='plan-cuentas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PlanDeCuentas />
                                </PrivateRoute>
                            } />
                            <Route path='configuracion-plan-de-cuenta/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConfiguracionPlanDeCuentaPage />
                                </PrivateRoute>
                            } />

                            <Route path='centro-de-costos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CentrosDeCostosPage />
                                </PrivateRoute>
                            } />

                            <Route path='reportes-financieros/estado-de-resultados/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EstadoDeResultados />
                                </PrivateRoute>
                            } />

                            <Route path='reportes-financieros/balance-general/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <BalanceGeneral />
                                </PrivateRoute>
                            } />

                            <Route path='reportes-financieros/Balance-de-Comprobacion/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <BalanceDeComprobacion />
                                </PrivateRoute>
                            } />

                            <Route path='reportes-financieros/flujo-de-caja/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FlujoDeCaja />
                                </PrivateRoute>
                            } />

                        </Route>

                        <Route path="administracion">
                            <Route path='plantillas_configuracion/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConfiguracionPlantillas />
                                </PrivateRoute>
                            } />
                            <Route path='parametros-contables/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ParametrosContablesPage />
                                </PrivateRoute>
                            } />
                            {/* PARAMTROS CONTABLES */}
                            <Route path='parametros-contables/tipo-de-cuentas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoDeCuentaPage />
                                </PrivateRoute>
                            } />
                            <Route path='parametros-contables/tipo-nombre-de-cuentas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoNombreDeCuentaPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros-contables/naturaleza-de-cuentas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NaturalezaDeCuentaPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros-contables/item-de-estado-financierp/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ItemEstadoFinancieroPage />
                                </PrivateRoute>
                            } />
                            <Route path='actividades-sistema' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <LogsPage />
                                </PrivateRoute>
                            } />

                            <Route path='productos/inventario/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ProductosInventarioPage />
                                </PrivateRoute>
                            } />

                            <Route path='productos/activo-fijo/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ProductosActivosFijoPage />
                                </PrivateRoute>
                            } />

                            <Route path='productos/contabilidad/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ProductosContabilidadPage />
                                </PrivateRoute>
                            } />

                            <Route path='productos/nuevo-producto/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NuevoProductoSelects />
                                </PrivateRoute>
                            } />

                        </Route>

                        <Route path="compras">
                            <Route path='compra/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudesPage />
                                </PrivateRoute>
                            } />

                            <Route path='bodega' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudBodegaPage />
                                </PrivateRoute>
                            } />

                            <Route path='solicitud' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CompraTabsPage />
                                </PrivateRoute>
                            } />

                            <Route path='proveedores' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ProveedoresPage />
                                </PrivateRoute>
                            } />

                            <Route path='orden-compra' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <OrdenComprasPage />
                                </PrivateRoute>
                            } />
                            <Route path="cuentas_por_pagar">
                                <Route path="parametros" element={
                                    <PrivateRoute isAuth={isAuth}>

                                    </PrivateRoute>
                                } />
                                <Route path="documentos" element={
                                    <PrivateRoute isAuth={isAuth}>
                                        a
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path="logs" element={
                            <PrivateRoute isAuth={isAuth}>
                                <LogsPage />
                            </PrivateRoute>
                        }>
                        </Route>


                        {/* REQUERIMIENTOS SISTEMA */}

                        <Route path="requerimientos-sistema">
                            <Route path='solicitud' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SolicitudRequerimientoPage />
                                </PrivateRoute>
                            } />
                        </Route>

                        {/* REPORTE RESUMEN */}

                        <Route path="reportes-resumen">
                            <Route path='Financiero' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Financiero />
                                </PrivateRoute>
                            } />
                        </Route>

                        {/* TICKETS */}

                        <Route path="tickets">

                            <Route path='generar-ticket' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <NuevoTicket />
                                </PrivateRoute>
                            } />

                            <Route path='lista-tickets' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPage />
                                </PrivateRoute>
                            } />
                            <Route path='lista-tickets/0' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPage valor={0} />
                                </PrivateRoute>
                            } />
                            <Route path='lista-tickets/1' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPage valor={1} />
                                </PrivateRoute>
                            } />
                            <Route path='lista-tickets/2' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPage valor={3} />
                                </PrivateRoute>
                            } />
                            <Route path='lista-tickets/3' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsPage valor={2} />
                                </PrivateRoute>
                            } />

                            <Route path='resumen' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TicketsResumen />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/departamentos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <DepartamentosPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/asunto-de-ticket/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AsuntoTicketPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/oficinas/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <OficinasPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/turnos/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TurnoPage />
                                </PrivateRoute>
                            } />

                        </Route>

                        {/* COBRANZA */}
                        <Route path="cobranza" element={
                            <PrivateRoute isAuth={isAuth}>
                                {/* <RequerimientosSitema /> */}
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route path="premios/list" element={
                            <PrivateRoute isAuth={isAuth}>
                                <PremiosList />
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route path="cobranzas" >
                            <Route path='reingreso/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ReingresoPage />
                                </PrivateRoute>
                            } />
                            <Route path='debito/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Debito />
                                </PrivateRoute>
                            } />
                            <Route path="facturas/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Facturas />
                                </PrivateRoute>
                            } />
                            <Route path="transacciones/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Transacciones />
                                </PrivateRoute>
                            } />
                            <Route path="credito/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <Credito />
                                </PrivateRoute>
                            } />
                            <Route path="facturas-masivas-cobranza/" element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CargaMasivaCobranza />
                                </PrivateRoute>
                            } />
                        </Route>

                        <Route path="activos-fijos">
                            <Route path='crear-producto/' element={
                                <PrivateRoute isAuth={isAuth}>

                                    {/* <CrearProductoReferenciasYNotas /> */}

                                    {/* <CrearProductoLocalidad /> */}

                                    {/* <CrearProductoInformacionTecnica /> */}

                                    {/* <CrearProductoGeneral /> */}

                                    {/* <CrearProducto /> */}

                                    <CrearProductoSteeper />

                                </PrivateRoute>
                            } />

                            <Route path='lista-activos-fijos/' element={
                                <PrivateRoute isAuth={isAuth}>

                                    <ListProductosActivosFijos />

                                </PrivateRoute>
                            } />

                            <Route path='transferencias-activos/' element={
                                <PrivateRoute isAuth={isAuth}>

                                    <TransferenciaActivos />

                                </PrivateRoute>
                            } />



                            <Route path='parametros/porcentaje-de-depreciacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PorcentajeDeDepreciacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/estado-producto' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <EstadoProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/clase-de-activo' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ClaseDeActivoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/tipo-de-activos-fijos' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoActivosFijosPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/categoria' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CategoriaActivoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/subcategoria' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <SubcategoriaActivosPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/tipo-de-producto' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoDeProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/unidad-de-medida' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UnidadMedidaPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/clase-de-producto' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ClaseDeProductoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/general/frecuencia-de-depreciacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FrecuenciaDepreciacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/informacion-tecnica/marcas' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MarcasActivosPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/informacion-tecnica/modelo' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ModeloActivosPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/informacion-tecnica/condicion-de-activo' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CondicionDeActivoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/localidad/localidad-producto' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <LocalidadPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/localidad/departamento' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <DepartamentoPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/tipo-transaccion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <TipoTransaccionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/porcentaje-de-depreciacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <PorcentajeDeDepreciacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/metodo-de-depreciacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <MetodoDeDepreciacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/frecuencia-de-depreciacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <FrecuenciaDepreciacionPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/residual' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ResidualPage />
                                </PrivateRoute>
                            } />

                            <Route path='parametros/depreciacion/vida-util' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <VidaUtilPage />
                                </PrivateRoute>
                            } />

                        </Route>

                        <Route path="extras">
                            <Route path='log-zimbra/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <LogZimbra />
                                </PrivateRoute>
                            } />
                            <Route path='conciliacion-banco/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConciliacionBanco />
                                </PrivateRoute>
                            } />
                            <Route path='cobranza-pichincha/'element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CobranzaPichincha />
                                </PrivateRoute>
                            }/>
                        </Route>

                    </Routes>
                </Header>
            </ThemeProvider>
        </HashRouter>
    )
}