import { Box, Button, Card, CardContent, CircularProgress, Collapse, Dialog, DialogTitle, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { GridToolbarFilterButton, GridToolbarContainer, GridToolbarQuickFilter, DataGrid, esES } from '@mui/x-data-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';


export const Credito = () => {
    let current = new Date();
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }


    useEffect(() => {
        LoadData()
    }, [])

    function LoadData() {
        setLoad(true)
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispTransacciones/?credito=`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                re.reverse()
                setData(re)
                setLoad(false)
            }).catch(error => {
                console.log('error', error)
                setLoad(false)
            });
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    const columns = [
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Id', width: 75 },
        { field: 'idfactura', headerClassName: 'super-app-theme--header', headerName: 'Factura', width: 100 },
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', width: 300 },
        { field: 'NUM_CONTRATO', headerClassName: 'super-app-theme--header', headerName: 'Contrato', width: 200 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Cedula', width: 100 },
        { field: 'fechaingreso', headerClassName: 'super-app-theme--header', headerName: 'F. Ingreso', width: 100 },
        { field: 'fecha_limite', headerClassName: 'super-app-theme--header', headerName: 'F. Limite', width: 100 },
    ];

    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>PROMESAS DE PAGO ACTIVOS</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                                >
                                    {" "}
                                    DESCARGAR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{
                                        height: 600, width: '100%', '& .super-app-theme--header': {
                                            backgroundColor: '#672780',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        },
                                    }}>
                                        <DataGrid
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                            }}
                                            density="compact"
                                            loading={load}
                                            hideFooterSelectedRowCount={true}
                                            rows={data ? data : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                </Paper>

                                <ExcelExport onExportComplete={handleExport2} data={data} fileName={"Promesas de Pago Activos.xlsx"}
                                    ref={exporter} filterable={true} >
                                    <ExcelExportColumn field="id" title="ID" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="idfactura" title="Factura" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="nombre" title="Nombre" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="NUM_CONTRATO" title="Contrato" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="cedula" title="Cedula" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="fechaingreso" title="F. Ingreso" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="fecha_limite" title="F. Limite" headerCellOptions={{ background: "#672780" }} width={200} />
                                </ExcelExport>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>

        </>
    )
}
