import {
    Button, Dialog, DialogContent,
    DialogTitle, Grid, Paper, Table,
    TableCell, TableHead, TableRow, Tooltip,
    Typography, DialogActions, TableBody
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { LogTicket } from './LogTicket';

import "hammerjs";
import { cargarLog, handleOpen } from './Tickets';

export const DetalleTicketSoporteNOC = (props) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [ticket, setTicket] = useState({})
    const [open, setOpen] = useState(false);
    const [tiempo, setTiempo] = useState('')
    const [tiempoTotal, setTiempoTotal] = useState('')

    const [logs, setLogs] = useState({})

    const [series, setSeries] = useState([])


    useEffect(() => {
        setTicket(props.ticket)
    }, [props]);


    function cargarTop() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec//apitopwisprespuesta/?id=${props.ticket.id}&categoria=PWST`,
            requestOptions)
            .then(response => response.text())
            .then(result2 => {
                const re = JSON.parse(result2)
                setLogs(
                    {
                        comentarios: [...re.map((val) => {
                            return {
                                id: val.id,
                                fecha: val.fecha_ingreso,
                                accion: '--',
                                tiempo: '--',
                                usuario: val.usuario_ingreso,
                                comentario: val.accion
                            }
                        })]
                    }
                )
            }).catch(error => {
                console.log('error', error)
            })
    }


    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='info' onClick={() => {
                    handleOpen(ticket, setOpen, 'CRM_soporte_tecnico', props.historial, userAuth)
                    if (!props.historial) {
                        cargarLog('soporte_tecnico_', ticket, setLogs, setSeries, setTiempo, setTiempoTotal)
                    } else if (props.historial && !props.ticket.id.includes('CRM')) {
                        cargarTop()
                    } else if (props.historial && props.ticket.id.includes('CRM')) {
                        cargarLog('soporte_tecnico_',
                            { ...ticket, id: ticket.id.split('CRM-')[1] }, setLogs,
                            setSeries, setTiempo, setTiempoTotal)
                    }
                }} fontSize='medium' />
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle><strong>INFORMACIÓN DEL TICKET</strong></DialogTitle>
                <DialogContent>
                    {ticket &&
                        <>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>UNIDAD:</strong> {ticket.unidadnegocio} <br />
                                            <strong>ID TICKET:</strong> {ticket.id}<br />
                                            <strong>ID CLIENTE:</strong>{ticket.idcliente}<br />
                                            <strong>IDENTIFICACION:</strong>{ticket.cedula}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            <strong>NOMBRES:</strong> {ticket.nombres} <br />
                                            <strong>CORREO:</strong> {ticket.email}<br />
                                            <strong>TELÉFONO:</strong>{ticket.telefonos}<br />
                                            <strong>DIRECCIÓN:</strong>{ticket.direccion}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Table >
                                            <TableHead >
                                                <TableRow>
                                                    <TableCell sx={{
                                                        fontSize: '14px',
                                                        backgroundColor: '#672780', color: 'white'
                                                    }} >
                                                        <strong>Detalles del Requerimiento</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>TIPO DEL PROBLEMA:</strong>
                                                        {ticket.tipoproblema}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>DESCRIPCION:</strong>
                                                        {ticket.descripciontipootro}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>INGRESADO POR:</strong>
                                                        {ticket.usuario_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE INGRESO:</strong>
                                                        {ticket.fecha_ingreso}<br /></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ fontSize: '14px' }}>
                                                        <strong>FECHA DE MODIFICACION:</strong>
                                                        {ticket.fecha_modificacion}<br /></TableCell>
                                                </TableRow>
                                                {((!props.historial) || (props.historial &&
                                                    props.ticket.id.includes('CRM'))) &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  ACTIVO:</strong>
                                                                {tiempo}<br /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: '14px' }}>
                                                                <strong>TIEMPO  TOTAL:</strong>
                                                                {tiempoTotal}<br /></TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>

                                    </Paper>
                                </Grid>
                                {((props.historial && props.ticket.id.includes('CRM'))) &&
                                    <>
                                        <LogTicket ticket={logs} />
                                    </>
                                }

                                {!props.historial &&
                                    <>
                                        <LogTicket ticket={logs} />
                                    </>
                                }

                            </Grid>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}