import { Box, Button, Collapse, Typography, Chip, Paper } from "@mui/material"
import React, { useEffect, useState } from "react";
import { db, auth, firebase } from "../../firebase";
import { useSelector } from 'react-redux';
import { ExportarCSV } from "../../components/utils/ExportarCSV";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from '../../components/utils/SearchInput';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES,
} from '@mui/x-data-grid';
//
export const VentasRealizadas = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    } else {
      name = 'INSTALACION'
      color = 'success'
    }
    return (
      <Chip size='small' variant='outlined' label={name} color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  let current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 86400000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const [total, setTotal] = useState();
  const [data, setData] = useState();
  const [search, setSearch] = useState('')

  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  useEffect(() => {
    LoadData()
  }, [])

  const LoadData = () => {
    let lista = []
    let ref = db.collection("tecnicos").where("estado", "==", 2)
    if (userInfo?.roles?.find(role =>
      ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
    if (userInfo?.roles?.find(role =>
      ['lideres_ventas']?.includes(role)))
      ref = ref.where("centro_costo.lider_venta", "==", userInfo.nombres)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);

      let ref5 = ref.where('fechaDeInstalacion', '>=', startfulldate)
      ref5 = ref5.where('fechaDeInstalacion', '<=', endfulldate)
      ref5.onSnapshot((snapshot) => {
        const data5 = [
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              debito2: doc.data().debito === 1 ? "SI" : "NO"
            };
          }),
        ];
        console.log(data)
        lista = lista.concat(data5)

        let ref3 = ref.where('fechaInstalacionCambio', '>=', startfulldate)
        ref3 = ref3.where('fechaInstalacionCambio', '<=', endfulldate)
        ref3.onSnapshot((snapshot) => {
          const data = [
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
                debito2: doc.data().debito === 1 ? "SI" : "NO"
              };
            }),
          ];
          console.log(data)
          lista = lista.concat(data)


          let ref2 = ref.where('fechaInstalacionVenta', '>=', startfulldate)
          ref2 = ref2.where('fechaInstalacionVenta', '<=', endfulldate)
          ref2.onSnapshot((snapshot) => {
            const data2 = [
              ...snapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                  debito2: doc.data().debito === 1 ? "SI" : "NO"
                };
              }),
            ];
            console.log(data2)
            lista = lista.concat(data2)


            let ref4 = ref.where('fechaInstalacionMigracion', '>=', startfulldate)
            ref4 = ref4.where('fechaInstalacionMigracion', '<=', endfulldate)
            ref4.onSnapshot((snapshot) => {
              const data3 = [
                ...snapshot.docs.map((doc) => {
                  return {
                    ...doc.data(),
                    id: doc.id,
                    debito2: doc.data().debito === 1 ? "SI" : "NO"
                  };
                }),
              ];
              lista = lista.concat(data3)
              let listaFinal = []
              let contratos = []
              for(const el of lista){
                if(!contratos[el.numContrato]){
                  contratos[el.numContrato] = 'aaa'
                  listaFinal.push(el)
                }
              }
              setData(listaFinal)
            });
          });
        });
      });
    }
    //}
  }
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start)
    setEndDate(end)
  }
  //
  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }
  const columns = [
    {
      field: 'created', headerName: 'FECHA SOLICITUD', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value &&
        moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'estado', headerName: 'ESTADO', width: 175,
      renderCell: (params) => {
        return <Estado estado={params.row.cambio_domicilio} />
      }
    },
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'nombres', headerName: 'NOMBRE CLIENTE', width: 300 },
    { field: 'fechaNacimiento', headerName: 'FECHA DE NACIMIENTO', width: 200 },
    { field: 'camara', headerName: 'CAMARA', width: 150 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'ups', headerName: 'MINI UPS', width: 100 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM REFERENCIA', width: 150 },
    { field: 'direccion', headerName: 'DIRECCION', width: 300 },
    { field: 'canton', headerName: 'CANTON', width: 150 },
    {
      field: 'fechaHora', headerName: 'FECHA INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    { field: 'planNombre', headerName: 'PLAN NOMBRE', width: 300 },
    { field: 'contrato', headerName: 'TIPO', width: 100 },
    { field: 'observacion2', headerName: 'OBSERVACION DEL METRAJE	', width: 400 },

    {
      // valueGetter: ({ value }) => value == 1 ? 'SI' : 'NO'
      field: 'debito2', headerName: 'DEBITO', width: 125
    },
    {
      field: 'anexo_', headerName: 'ANEXO DEBITO', width: 125,
      renderCell: (params) => {
        return params.row.debito === 1 ? <>
          <a target="_blank" href={params.row.foto_debito} >VER FOTO</a>
        </> : ''
      }
    },
    { field: 'banco', headerName: 'BANCO', width: 200 },
    { field: 'tipoCuenta', headerName: 'TIPO BANCO', width: 100 },
    { field: 'numBanco', headerName: 'NUM BANCO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
      valueGetter: ({ value }) => value == 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado', headerName: 'DISCAPACIDAD', width: 150,
      valueGetter: ({ value }) => value == 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'GRADO DISCAPACIDAD', width: 200,
      valueGetter: ({ value }) => value == "" ? 'NINGUNO' : value.discapacidad
    },

    {
      field: 'discapacidad_', headerName: 'DESCUENTO', width: 125,
      renderCell: (params) => {
        return params.row.paramsDiscapacidad === "" ? 'NINGUNO' : params.row.paramsDiscapacidad.valor
      }
    },

    { field: 'planValor', headerName: 'VALOR PLAN', width: 100 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      valueGetter: (params) => {
        return params.row.centro_costo &&
          params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo &&
          params.row.centro_costo.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo &&
          params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo &&
          params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo &&
          params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido_', headerName: 'REFERIDO', width: 125,
      valueGetter: (params) => {
        return params.row.referido === undefined ? 'NO' : params.row.referido
      }
    },
    { field: 'cambio_domicilio', headerName: 'CAMBIO DE DOMICILIO', width: 0 },
    { field: 'cableInicio', headerName: 'CABLE INICIO', width: 150 },
    { field: 'cableFinaliza', headerName: 'CABLE FIN', width: 150 },
    { field: 'cableUtilizado', headerName: 'CABLE UTILIZADO', width: 150 },
    { field: 'metrajeExtraCableUtilizado', headerName: 'METRAJE EXTRA CABLE UTILIZADO', width: 150 },

    { field: 'noCuotas', headerName: 'No CUOTAS', width: 150 },
    { field: 'personaAutorizaMetraje', headerName: 'PERSONA AUTORIZA METRAJE', width: 150 },
    { field: 'observacionInstalacionRealizada', headerName: 'OBSERVACION INSTALACION REALIZADA', width: 150 },


    { field: 'valorExtra', headerName: 'VALOR EXTRA', width: 150 },
    { field: 'materialesExtra', headerName: 'MATERIALES EXTRA', width: 150 },

    { field: 'observacion', headerName: 'OBSERVACION', width: 200 },
    { field: 'observacion2', headerName: 'OBSERVACION2', width: 200 },
    // { field: 'observacionInstalacionRechazada', headerName: 'OBSERVACION RECHAZADA', width: 200 },
    // { field: 'metraje', headerName: 'METRAJE', width: 150 },

    // { field: 'valorIntsalacionYNumeroDeCuotas', headerName: 'INSTALACION Y CUOTAS', width: 150 },

    // { field: 'valorCuotas', headerName: 'VALOR CUOTAS', width: 150 },

    { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
  ]
  const csvOptions = { delimiter: ';' };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>VENTAS</strong>/VENTAS INSTALADAS 2
        </Typography >
      </Box>
      <Box display='flex' style={{ textTransform: 'uppercase' }} pl={1}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>
      <Box display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <ExportarCSV data={data} />
      </Box>
      <Paper>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ?? []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>
    </>
  );
};