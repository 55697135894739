import {
    TextField, Typography, Box, Grid, Button, AlertTitle, Alert,
    FormControl, MenuItem, Select, InputLabel, Card, DialogContent, DialogTitle, Dialog
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputValidation from "../../../components/utils/InputValidation";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from "@mui/lab";
import { auth, firebase, functions } from '../../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
export const QuickUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    //Estado de crear nueva solicitud
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    //
    const [linea, setLinea] = useState('')
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [password, setPassword] = useState({ campo: "", valido: true });
    const [loading, setLoading] = useState(false)
    useEffect(() => {
    }, [dispatch]);
    const submitHandler = () => {
    }
    return (
        <>
            <Box pl={2} pb={2} pt={2}>
                <Typography variant="h5" component="h1">RADIUS/<strong>QUICK USER</strong></Typography>
            </Box>
            <Card p={1} variant='outlined'>
                <Box p={2}>
                    <form onSubmit={submitHandler}>
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Box pb={1}>
                                    <InputValidation
                                        estado={nombre}
                                        cambiarEstado={setNombre}
                                        label="USERNAME"
                                        name="USERNAME"
                                    />
                                </Box>
                                <InputValidation
                                    estado={password}
                                    cambiarEstado={setPassword}
                                    label="PASSWORD"
                                    name="password"
                                />
                                <Box pb={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>PASSWORD TYPE</InputLabel>
                                        <Select
                                            value={linea}
                                            label="LINEA DE CONTRATO"
                                            onChange={(e) => { setLinea(e.target.value) }}
                                        >
                                            <MenuItem value="">-</MenuItem>
                                            <MenuItem value="L1">L1</MenuItem>
                                            <MenuItem value="L2">L2</MenuItem>
                                            <MenuItem value="L3">L3</MenuItem>
                                            <MenuItem value="L4">L4</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <FormControl fullWidth>
                                    <InputLabel>GROUP</InputLabel>
                                    <Select
                                        value={linea}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { setLinea(e.target.value) }}
                                    >
                                        <MenuItem value="">-</MenuItem>
                                        <MenuItem value="L1">L1</MenuItem>
                                        <MenuItem value="L2">L2</MenuItem>
                                        <MenuItem value="L3">L3</MenuItem>
                                        <MenuItem value="L4">L4</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pb={1}>

                                </Box>

                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row-reverse" pt={4}>
                            {
                                !loading ?
                                    <Button
                                        type="submit"
                                        disableElevation
                                        color="secondary"
                                        variant="contained"
                                        disabled={loading}
                                    > CREAR USUARIO
                                    </Button>
                                    :
                                    <LoadingButton
                                        variant="contained"
                                        loading
                                    >
                                        CREAR USUARIO
                                    </LoadingButton>
                            }
                        </Box>
                    </form>
                </Box>
            </Card>
        </>
    );
};