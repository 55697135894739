import {
  Table, TableContainer, Box, Button, Collapse, Typography,
  TableRow, TableCell, TableBody, TableHead, Chip, Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, auth, firebase } from "../../../firebase";
import { useSelector } from 'react-redux';
/* import { ExportarCSV } from "../../utils/ExportarCSV"; */
import { ExportarCSV } from "../../../components/utils/ExportarCSV";
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from '../../../components/utils/SearchInput';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridExportMenuItemProps,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridApi,
  GridToolbar,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
//
export const TeleventasRechazada = () => {
  function Estado({ estado }) {
    let name;
    let color;
    console.log(estado, 'estado')
    //Controlamos el estado de la factiblidad
    if (estado === 3) {
      name = 'RECHAZADA'
      color = 'error'
    }
  
    return (
      <Chip size='small' variant='outlined' label={name} color={color} />
    )
  }

  const [open, setOpen] = useState(false);
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 5184000000))
  const [endDate, setEndDate] = useState(new Date())
  const [total, setTotal] = useState();
  const [data, setData] = useState();
  const [search, setSearch] = useState('')
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("solicitudes_televentas").where("estado", "==", 3)
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fecha_aprobada', '>=', startfulldate)
      ref = ref.where('fecha_aprobada', '<=', endfulldate)
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
        console.log('data: ', data)
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
        setIsLastPage(totalCount < 1);
      });
    }
    //}
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  //
  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }
  const columns = [
    {
      field: 'created', headerName: 'FECHA SOLICITUD', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fecha_aprobada', headerName: 'FECHA APROBADA', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'estado', headerName: 'ESTADO', width: 150,
      renderCell: (params) => {
        return <Estado estado={params.row.estado} />
      }
    },
 
    { field: 'uid', headerName: 'REFERENCIA', width: 200 },

    { field: 'nombres', headerName: 'CLIENTE', width: 300 },
    { field: 'motivo', headerName: 'MOTIVO DE RECHAZO', width: 500 },
    { field: 'direccion', headerName: 'DIRECCION', width: 300 },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'correo', headerName: 'CORREO', width: 300 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 300 },
    { field: 'televenta', headerName: 'TELEVENTA', width: 300 },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <CustomExportButton />
      {/* <GridToolbarQuickFilter /> */}
    </GridToolbarContainer>
  );
  return (
    <>
      <Box pl={2} pt={2} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>

        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <ExportarCSV data={data} />


      </Box>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>RECHAZADA</strong>/TELEVENTAS
        </Typography >
      </Box>

      <Box display='flex' style={{ textTransform: 'uppercase' }} py={2} pl={2}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>

      <Paper>
        <Box sx={{ height: 500, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>



    </>
  );
};