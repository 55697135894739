import { db } from '../../../firebase'
export const listLiders = () => async (dispatch) => {
    try {
        dispatch({ type: 'LIDER_LIST_REQUEST' })
        db.collection(`flotas`).where('estado', '==', 0)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'LIDER_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
            
    } catch (error) {
        dispatch({
            type: 'LIDER_LIST_FAIL',
            payload: error
        })
    }
}