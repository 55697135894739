/* import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExpandLess from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const ListCuadrillas = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const [open, setOpen] = useState(false)
    return (
        <>
            {userInfo && userInfo.rol === 'CUADRILLAS' || userInfo.rol === 'SUPERVISOR_CUADRILLAS' || userInfo.rol === 'ADMIN' ? <>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <LocalShippingIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cuadrillas" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </> : ''}
        </>
    )
} */

import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
export const ListCuadrillas = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'flotas']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <AirportShuttleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Flotas" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton component={RouterLink} to={`Flota/GestionDeFlota`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion de Flotas" />
                        </ListItemButton>
                        
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}