import {
  Container,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Collapse,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";

import FilterListIcon from '@mui/icons-material/FilterList';

import Tooltip from "@mui/material/Tooltip";

import { firebase, db, storage } from "../../firebase";
import moment from 'moment'



import { useSelector } from 'react-redux';



export const AsignacionOrdenDeTrabajo = () => {

  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'Asignada'
      color = 'warning'
    } else if (estado === 1) {
      name = 'Agendada'
      color = 'success'
    }
    return (
      <Chip label={name} color={color} />
    )
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //LoadData()
}
  
  const onChange2 = () => {
    LoadData()
  }

  var current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 172800000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

  const [nombreZona, setNombreZona] = useState();

  const [codigoZona, setCodigoZona] = useState("");

  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);

  useEffect(() => {
    LoadData();
  }, []);

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("asignacion_orden_trabajo").where("estado", "==", 0).limit(30);

    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(0, 0, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)

    //Si tiene algun doc anterior se agrega acontinuacion
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
        
      ];

      setData(data);
      console.log(data, 'data')
      setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
      setIsLastPage(totalCount < 1);
    });
    }
  };

  return (
    <>
    <Box pt={1} pl={1}>
        <Typography >
          <strong>({data && data.length})</strong> SOLICITUDES APROBADAS
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems='center' py={2}>
       
            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>
     
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <br />
      {data && (
        <TableContainer sx={{ maxHeight: 540 }} component={Paper}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ESTADO</TableCell>
                <TableCell>#CONTRATO</TableCell>
                <TableCell>TIPO CONTRATO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>CLIENTE</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DESCUENTO</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>#REFERENCIA</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                <TableCell>NUMERO CONTRATADO</TableCell>
                <TableCell>NOMBRE LIDER</TableCell>
                <TableCell>NOMBRE AUXILIAR</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>FECHA CREACION</TableCell>
                <TableCell>FECHA INSTALACION</TableCell>
                <TableCell>VENDEDOR</TableCell>
                <TableCell>CARGO</TableCell>
                {/* <TableCell>Acciones</TableCell> */}

                {/* <TableCell>Acciones</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, key) => (
                <TableRow key={key}>
                  {/* Estado venta */}
                  <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                  </TableCell>
                  {/* cedula */}
                  <TableCell component="th" scope="row">
                    {row.numContrato}
                  </TableCell>
                  {/* tipoContrato */}
                  <TableCell component="th" scope="row">
                    {row.contrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>
                  {/* Nombres */}
                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>
                  {/* Tercera Edad */}
                  <TableCell component="th" scope="row">
                    {row.terceraEdad === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  {/* Nombres */}
                  <TableCell component="th" scope="row">
                    {row.discapacitado === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  {/* Nombres */}
                  <TableCell component="th" scope="row">
                    {row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.discapacidad}
                  </TableCell>
                  {/* Descuento */}
                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.descuento
                    }
                  </TableCell>
                  {/* Valor */}
                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.valor
                    }
                  </TableCell>
                  {/* Direccion */}
                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>
                  {/* Coordenadas */}
                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>
                  {/* Telefono */}
                  <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>
                  {/* celular */}
                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>
                  {/* Whatsapp */}
                  <TableCell component="th" scope="row">
                    {row.whatsapp}
                  </TableCell>
                  {/* numReferencia */}
                  <TableCell component="th" scope="row">
                    {row.numbRef}
                  </TableCell>
                  {/* planContratado */}
                  <TableCell component="th" scope="row">
                    {row.planNombre}
                  </TableCell>
                  {/* Nombre del lider */}
                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>
                  {/* Nombre del lider */}
                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>
                  {/* Nombre Auxiliar */}
                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>
                  {/* Valor Plan */}
                  <TableCell component="th" scope="row">
                    {row.planValor}
                  </TableCell>
                  {/* fecha de creaacion */}
                  <TableCell component="th" scope="row">
                    {row.created && typeof row.created === 'number' &&
                      moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    {row.created && typeof row.created === 'object' &&
                      moment(row.created.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                  {/* fecha instalacion */}
                  <TableCell component="th" scope="row">
                    {row.fechaHora && typeof row.fechaHora === 'number' &&
                      moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    {row.fechaHora && typeof row.fechaHora === 'object' &&
                      moment(row.fechaHora.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                  {/* Vendedor */}
                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>
                  {/* <TableCell component="th" scope="row">
                      {auth.currentUser.displayName}
                    </TableCell> */}

                  <TableCell component="th" scope="row">
                    {userInfo.cargo}
                  </TableCell>
                  {/* Acciones */}
                  {/* <TableCell component="th" scope="row">
                        <UpdateInformation props={row} />
                      </TableCell> */}


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};