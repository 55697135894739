import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Paper, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";
import { useNavigate } from "react-router-dom";

export const NuevoAsuntoTicket = () => {

    let navigate = useNavigate()

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [departamento, setDepartamento] = useState('')
    const [valorACobrar, setValorACobrar] = useState('')

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async () => {
        await db.collection('tickets_parametros').doc('asuntos').collection('list').doc(departamento.toUpperCase()).set({
            asunto: departamento.toUpperCase(),
            fecha_creacion_asunto: firebase.firestore.FieldValue.serverTimestamp(),
            valor_a_cobrar: valorACobrar
        }).then(() => {
            setDepartamento('')
            setValorACobrar('')
            navigate("/tickets/parametros/asunto-de-ticket/1")
            Swal.fire({ icon: "success", title: "Se ha creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>

            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>
                        <Box pb={2}>

                            <Grid container spacing={5}>

                                <Grid item xs={6}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ASUNTO DEL TICKET:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={departamento}
                                        onChange={(e) => setDepartamento(e.target.value)}
                                        name="departamento"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ASIGNAR UN VALOR A COBRAR:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        type="number"
                                        value={valorACobrar}
                                        onChange={(e) => setValorACobrar(e.target.value)}
                                        name="valorACobrar"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                        </Box>

                        <Button
                            onClick={() => createProduct()}
                            fullWidth
                            variant="contained"
                            disableElevation
                            disabled={departamento === '' || valorACobrar === ''}
                        >
                            CREAR
                        </Button>
                    </Box>
                </Paper>
            </Box>



        </>
    );
};
