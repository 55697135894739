import { Typography, Box, Paper, Chip, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase, functions } from '../../firebase';

import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
function Estado(estado) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado.props == 0) {
        name = "Esperando..";
        color = "warning";
    } else if (estado.props === 1) {
        name = "No factible";
        color = "info";
    } else if (estado.props === 2) {
        name = "APROBADAS";
        color = "success";
    }
    return <Chip size='small' label={name} color={color} />;
}
export const ListEncuesta3 = () => {
    const [open, setOpen] = useState(null);
    const [data, setData] = useState();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 518400000))
    const [endDate, setEndDate] = useState(new Date())
    useEffect(() => {
        LoadData();
    }, [startDate]);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth;

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("encuestas3").where("idcliente", "!=", "")

        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                setData(data);
            });
        }

    }
    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }
    const onChange2 = () => {
        LoadData()
    }
    const [total, setTotal] = useState(0);
    const columns = [
        {
            field: 'created', headerName: 'FECHA DE CREACION DE SOLICITUD', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
        },
        { field: 'id', headerName: 'CONTRATO', width: 200 },
        { field: 'nombre', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
        { field: 'idcliente', headerName: 'ID CLIENTE', width: 200 },
        { field: 'cedula', headerName: 'CEDULA', width: 150 },
        { field: 'telefono', headerName: 'TELEFONO', width: 200 },
        { field: 'movil', headerName: 'MOVIL', width: 200 },
        { field: 'res1', headerName: 'Ciudad', width: 200 },
        { field: 'res2', headerName: 'Resultado', width: 200 },
        { field: 'res3', headerName: '¿Se cumplió la hora coordinada para la instalación?', width: 200 },
        { field: 'res4', headerName: '¿Al momento de llegar a su domicilio el técnico incluyó en su presentación:“su nombre”,“nombre de la empresa”y “motivo de su visita”?', width: 200 },
        { field: 'res5', headerName: '¿El técnico dió a conocer los lugares de pago del servicio y medios de contacto con los que cuenta la empresa para atender sus requerimientos?', width: 200 },
        { field: 'res6', headerName: '¿Cómo califica el asesoramiento del técnico en la ubicación del equipo para asegurar una óptima cobertura del servicio?', width: 200 },
        { field: 'res7', headerName: '¿Cómo califica el trato brindado por el técnico durante la instalación?', width: 200 },
        { field: 'res8', headerName: '¿Se encuentra conforme con la atención que recibió por parte del personal?', width: 200 },
        { field: 'res9', headerName: 'Luego de la instalación, ¿ Se encuentra conforme con el servicio de internet?', width: 200 } 
    ]
    const csvOptions = {
        delimiter: ';',
        fileName: `ENCUESTAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    function CustomFooterTotalComponent(props) {
        return ''
    }
    const funcion23 = () => {
        let inc = 0
        setInterval(() => {
            //console.log(data.length, ' conteo')
            if (inc < data.length) {
                const data1 = data[inc++]
                //console.log(data1, ' cedula')
                //     const mikrowisp = functions.httpsCallable('mikrowisp2')
                //     mikrowisp(data1.idcliente)
                //         .then(res => {
                //             const info1 = res
                //             console.log(info1, ' info')
                //         })
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                let raw = JSON.stringify({
                    "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
                    "idcliente": data1.idcliente
                });
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                fetch(`https://serverbd.intercommerce.com.ec/?cedula=${data1.cedula}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const info1 = JSON.parse(result)
                        db.collection("encuestas").doc(data1.numContrato).update({
                            fechanacimiento: info1[0].fechanacimiento
                        }).then(() => {
                            console.log('succes')
                        })
                        //console.log(result)
                    })
                    .catch(error => console.log('error', error));
            }
        }, 100)
        // setInterval(() => {
        //     //console.log(data1.cedula, ' data1.cedula')
        //     //if (inc < data.length) {
        //     const data1 = data[inc++]
        //     console.log(data1, ' data1')
        //     let myHeaders = new Headers();
        //     myHeaders.append("Content-Type", "application/json");
        //     let raw = JSON.stringify({
        //         "dni": data1.cedula,
        //         "contract_numbers": data1.numContrato
        //     });
        //     let requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: 'follow'
        //     };
        //     console.log('hola')
        //     fetch("http://localhost:5000/sistema-gestion-intercommerce/us-central1/api/luktv/cuenta", requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //             console.log(result)
        //             const info = JSON.parse(result)
        //             console.log(info.message)
        //             db.collection("encuestas").doc(data1.numContrato).update({
        //                 documentopdf: info.message
        //             })
        //         })
        //         .catch(error => console.log('error', error));
        //     //}
        // }, 100)
    }

    const funcion234 = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "dni": "0953864782",
            "contract_numbers": "GUAYAQUIL-L1-11494-HOG"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("http://localhost:5000/sistema-gestion-intercommerce/us-central1/api/luktv/cuenta", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
    return (
        <>
          
            <Box pt={1} pl={1}>
                <Typography>
                    <strong>({data?.length})</strong> ENCUESTAS 3
                </Typography>
                <Box display="flex" style={{ textTransform: "uppercase" }}>
                    <Typography variant="button">
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
                </Box>
                <Box>
                    <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                        </Box>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                onClick={onChange2}
                            >
                                Aplicar busqueda
                            </Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}
                        hideFooter
                        density="compact"
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
