import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Paper,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export const CheckTransferencia = ({ props }) => {

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const [total, setTotal] = useState(0);

    const [data, setData] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])

    const [open, setOpen] = useState(null);

    const [openAcceptTransferencia, setOpenAcceptTransferencia] = useState(null);

    const [openRechazoTransferencia, setOpenRechazoTransferencia] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("transferencias").doc(props.uidSolicitud).collection('items')

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    const dispatch = useDispatch();

    useEffect(() => {
        LoadData()

    }, [dispatch])

    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 165 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 350 },
        { field: 'bodega_origen', headerName: 'BODEGA ORIGEN', width: 190 },
        { field: 'bodega_destino', headerName: 'BODEGA DESTINO', width: 190 },
        { field: 'cantidad_transferencia', headerName: 'CANTIDAD A TRANSFERIR', width: 205 },
    ];

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const aprobarSolicitud = () => {


        db.collection(`reporte_movimientos`).doc(props.uidSolicitud).set({
            uidSolicitud: props.uidSolicitud,
            estado: 3,
            numero_solicitud: props.numeroSolicitud,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: ''/* rol */,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega_origen: props.bodega_origen,
            bodega_destino: props.bodega_destino,
            /* ubicacion: ubicacion.toUpperCase(), */
            /* motivo: motivoIngresoProducto.toUpperCase(), */
            motivo_movimiento: 'TRANSFERENCIA',
            motivo: 'TRANSFERENCIA',
            observacion_adicional: props.observacion === '' ? 'NINGUNA' : props.observacion.toUpperCase(),
            /* flota: flota,
            lider_flota: lider,
            auxiliar_flota: auxiliar,
            placa_flota: placa,
            marca_flota: marca,
            modelo_flota: modelo, */
            tipo_movimiento: 'transferencia'
        })

        console.log(data)

        data.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", obj.bodega_destino).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", obj.bodega_origen).get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            if (data3[0] === undefined) {
                console.log('NO EXISTE LA BODEGA')
                db.collection("reporte_stock").doc().set({
                    bodega: obj.bodega_destino,
                    categoria: '',
                    codigo_producto: obj.codigo,
                    costo_promedio: 0,
                    descripcion: obj.descripcion,
                    fecha_carga_producto: firebase.firestore.FieldValue.serverTimestamp(),
                    motivo: 'CREACION POR TRANSFERENCIA',
                    precio_compra: 0,
                    stock_actual: parseInt(obj.cantidad_transferencia),
                    tipo_movimiento: 'TRANSFERENCIA',
                    total_valorizado: 0,
                    unidad_medida: '',
                    usuario_que_subio_carga_producto: userInfo.displayName
                })

                db.collection("reporte_stock").doc(data4[0].id).update({
                    stock_actual: parseInt(data4[0].stock_actual) - parseInt(obj.cantidad_transferencia),
                })

                db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                    fullName: obj.codigo,
                    categoria_producto: obj.categoria_producto,
                    descripcion: obj.descripcion,
                    costo_promedio: data4[0].costo_promedio,
                    precio_compra: data4[0].precio_compra,
                    cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                    bodega_origen: obj.bodega_origen,
                    bodega_destino: obj.bodega_destino,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })

            } else {
                console.log('SI EXISTE LA BODEGA')

                db.collection("reporte_stock").doc(data4[0].id).update({
                    stock_actual: parseInt(data4[0].stock_actual) - parseInt(obj.cantidad_transferencia),
                })

                db.collection("reporte_stock").doc(data3[0].id).update({
                    stock_actual: parseInt(data3[0].stock_actual) + parseInt(obj.cantidad_transferencia),
                })

                db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                    fullName: obj.codigo,
                    categoria_producto: obj.categoria_producto,
                    descripcion: obj.descripcion,
                    costo_promedio: data4[0].costo_promedio,
                    precio_compra: data4[0].precio_compra,
                    cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                    bodega_origen: obj.bodega_origen,
                    bodega_destino: obj.bodega_destino,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })

            }

        });

        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 2,
            usuario_que_aprobo: userInfo.displayName,
            fecha_de_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenAcceptTransferencia(false)
        Swal.fire({ icon: "success", title: `Solicitud aprobada con exito` });
    }

    const rechazarSolicitud = () => {
        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 1,
            usuario_que_rechazo: userInfo.displayName,
            fecha_de_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenRechazoTransferencia(false)
        Swal.fire({ icon: "success", title: `Solicitud rechazada con exito` });
    }

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => onData()}>
                    <RemoveRedEyeIcon />
                </Button>
            </Tooltip>
            {/* DIALOG SOLICITUDES DE TRANSFERENCIAS */}
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">

                <DialogTitle>
                    <strong>SOLICITUD DE TRANSFERENCIA: {props.uidSolicitud}</strong>
                </DialogTitle>

                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper>
                            <Box sx={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,
                                    }}

                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>REGRESAR</strong></Button>
                    {/* <Button onClick={() => {
                        setOpen(false)
                        setOpenRechazoTransferencia(true)
                    }} variant="contained" color="warning" size="small"><strong>RECHAZAR TRANSFERENCIA</strong></Button> */}
                    {/* <Button onClick={() => {
                        setOpen(false)
                        setOpenAcceptTransferencia(true)
                    }} variant="contained"
                        color="secondary"
                        size="small">
                        {" "}
                        APROBAR TRANSFERENCIA
                    </Button> */}
                </DialogActions>

            </Dialog>

            {/* DIALOG ACEPTAR SOLICITUDES */}
            <Dialog open={openAcceptTransferencia} fullWidth onClose={() => setOpenAcceptTransferencia(false)} maxWidth="xs">

                <DialogTitle>
                    <strong>¿ESTA SEGURO DE APROBAR LA TRANSFERENCIA?</strong>
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        setOpenAcceptTransferencia(false)
                        setOpen(true)
                    }} variant="contained" color="warning" size="small"><strong>REGRESAR</strong></Button>
                    <Button onClick={() => aprobarSolicitud()} variant="contained" color="secondary" size="small"><strong>APROBAR</strong></Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG RECHAZAR SOLICITUDES */}
            <Dialog open={openRechazoTransferencia} fullWidth onClose={() => setOpenRechazoTransferencia(false)} maxWidth="xs">

                <DialogTitle>
                    <strong>¿ESTA SEGURO DE RECHAZAR LA TRANSFERENCIA?</strong>
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        setOpenRechazoTransferencia(false)
                        setOpen(true)
                    }} variant="contained" color="warning" size="small"><strong>REGRESAR</strong></Button>
                    <Button onClick={() => rechazarSolicitud()} variant="contained" color="error" size="small"><strong>RECHAZAR</strong></Button>
                </DialogActions>

            </Dialog>
        </>
    );
};