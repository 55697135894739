import React, { useState, useRef, useEffect } from 'react';
import { Typography, Grid, Hidden, Box, CssBaseline, TextField, Button, Link, CardContent, Paper, FormControlLabel, Avatar, Checkbox } from '@mui/material';
import Alert from '@mui/material/Alert';
import InputValidation from '../../components/utils/InputValidation';
import logo from '../../assets/img/bienvenido_yiga5.png'
import { login } from '../../redux/actions/account/authActions'
import { Link as RouterLink } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo2 from '../../assets/img/fondo.jpg'
import yiga5_bg from '../../assets/img/yiga5_bg.png'
//
const theme = createTheme();
export const LoginPage = () => {
    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { error, loading } = userLogin
    const [email, setEmail] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: null })
    const submitHandler = (e) => {
        e.preventDefault()
        if (email.valido === true && password.valido === true) {
            dispatch(login({
                'email': email.campo,
                'password': password.campo
            }))
        }
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={5}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: '100vh' }}
                        >
                            <Grid item>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}>
                                    <img width={200} src={logo} />
                                </div>
                                <Box py={4}>
                                    <Typography variant='h6' component='h2' align='center' gutterBottom>Sistema de Operación Empresarial Intercommerce.</Typography>
                                </Box>
                                {error && <Alert severity="error">{error.message}</Alert>}
                                <form onSubmit={submitHandler}>
                                    <Box pt={2} pb={1}>
                                        <InputValidation
                                            estado={email}
                                            cambiarEstado={setEmail}
                                            type="email"
                                            label="CORREO INSTITUCIONAL"
                                            name="email"
                                        //helperText="Sólo correos asociados a la institución '@ups.edu.ec'."
                                        //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                                        //disabled={loading}
                                        />

                                    </Box>
                                    <Box pb={1}>
                                        <InputValidation
                                            estado={password}
                                            cambiarEstado={setPassword}
                                            type="password"
                                            label="CONTRASEÑA"
                                            name="password"
                                            helperText="Minimo 8 caracteres"
                                            patterns={/^.{8,30}$/}
                                        //disabled={loading}
                                        />

                                    </Box>
                                    <Box py={2}>
                                        {!loading ?
                                            <Button variant="contained" fullWidth disableElevation
                                                disabled={!password.campo || !email.campo || !email.valido || !password.valido}
                                                type="submit"
                                                color="secondary">
                                                Iniciar sesión
                                            </Button>
                                            :
                                            <LoadingButton
                                                fullWidth
                                                loading
                                                variant="contained"
                                                disabled
                                            >Cargando</LoadingButton>
                                        }



                                    </Box>
                                </form>
                                <Typography variant="body2" color="text.secondary" align="center" >
                                    {'Departamento de Desarrollo, '}
                                    SOEI
                                    {' '}
                                    {new Date().getFullYear()}
                                    {'.'}
                                </Typography>
                                
                                <Typography variant="body2" color="text.secondary" align="center" >
                                    v{process.env.npm_package_version}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            backgroundImage: `url(${logo2})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
                        <Hidden mdDown> <Box pt={5}>    </Box>
                            <Box pt={5}>    </Box>
                            <img width="100%" src={yiga5_bg} />
                        </Hidden>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    )
}