import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";

export const GetClienteApi = ({ props, cedula }) => {
    const [open, setOpen] = useState(null);

    const onData = () => {
        console.log('cedula', cedula)
        console.log('get api')

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            "cedula": cedula
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://45.236.168.15/api/v1/GetClientsDetails", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log('result', result)
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <Tooltip title="get api">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="success" />
                </Button>
            </Tooltip>
        </>
    );
};