import {
    Button,
    Typography,
    Paper,
    Box,
    Collapse,
    Autocomplete,
    TextField,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogActions,
    Stack,
    CircularProgress,
    DialogTitle,
    LinearProgress
} from "@mui/material";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES,
} from '@mui/x-data-grid';

import { process } from "@progress/kendo-data-query";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import moment from 'moment'
import DatePicker from "react-datepicker";
import { useDispatch } from 'react-redux'

import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { KardexGPDF } from "./KardexGPDF";
import Swal from "sweetalert2";


export const KardexGeneral = () => {

    const dispatch = useDispatch()

    const [dataFiltro, setDataFiltro] = useState([]);
    const [dataFiltro2, setDataFiltro2] = useState([]);

    const [loading, setLoading] = useState(false);

    const [codigoProd, setCodigoProd] = useState([]);
    const [agrupado, setAgrupado] = useState(null);

    useEffect(() => {
        LoadData()
    }, [dispatch])


    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);

    const [codProd, setCodProd] = useState([])

    const [data, setData] = useState([]);
    const [data3, setData3] = useState([]);
    let current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 7 * (86400000)))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

    if (startDate) startDate.setHours(0, 0, 0, 0)
    if (endDate) endDate.setHours(23, 59, 0, 0)

    const onChange = (dates) => {
        const [start, end] = dates;

        if (start) start.setHours(0, 0, 0, 0)
        if (end) end.setHours(23, 59, 0, 0)
        setStartDate(start);
        setEndDate(end);
    }

    const [item, setItem] = useState('')
    const [item2, setItem2] = useState('')



    const LoadData = () => {
        let docRef = db.collection("reporte_stock")
        let productos = []
        docRef.get().then((querySnapshot) => {
            const stock = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            let agrupado = stock.reduce((formato, { codigo_producto, descripcion, bodega }) => {
                if (codigo_producto && bodega) {
                    if (!formato[bodega]) {
                        formato[bodega] = [];
                    }
                    formato[bodega][codigo_producto] = [0, 0, 0];
                }
                if (codigo_producto) {
                    if (!productos[codigo_producto]) {
                        productos[codigo_producto] = descripcion;
                    }
                }
                return formato;
            }, {});

            let datos = []

            let bodegas2 = Object.keys(agrupado)
            let productos2 = Object.keys(productos)
            agrupado['BODEGA FLOTA INTERNA'] = []
            agrupado['BODEGA FLOTA EXTERNA'] = []
            let labels = []
            productos2.map(((val) => {
                labels.push(productos[val])
                datos.push({
                    nombre: productos[val],
                    codigo: val
                })
                agrupado['BODEGA FLOTA INTERNA'][val] = [0, 0, 0]
                agrupado['BODEGA FLOTA EXTERNA'][val] = [0, 0, 0]
                bodegas2.map((val2) => {
                    agrupado[val2][val] = [0, 0, 0]
                })
            }))

            /////////////////
            setCodigoProd(datos)
            labels.push("TODOS")
            const conjunto = Array.from(new Set(labels));
            setDataFiltro(conjunto.map(item => { return item }))
            bodegas2 = Object.keys(agrupado)
            bodegas2.push("TODOS")
            const conjunto2 = Array.from(new Set(bodegas2));
            setDataFiltro2(conjunto2.map(item => { return item }))
            setAgrupado(agrupado)
            /////////////////

            return agrupado
        }).then(agrupado => {
            // let docRef = db.collection("total_movimientos").orderBy("fecha_documento", "desc")
            // let bodegas = Object.keys(agrupado)
            // docRef.get().then((querySnapshot) => {
            //     const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //     const data2 = []
            //     const valores = []
            //     arrayMessage.reverse()
            //     arrayMessage.map((item) => {
            //         if (item.codigo && item.uid_solicitud_pedido && item.bodega && item.motivo) {
            //             if (valores.indexOf(item.codigo + item.uid_solicitud_pedido + item.bodega + item.motivo) < 0) {
            //                 let bodega = item.bodega
            //                 let codigo = item.codigo
            //                 if (agrupado[bodega] && agrupado[bodega][codigo]) {
            //                     let stockActual = agrupado[bodega][codigo][0]
            //                     let stockNuevo = (item.tipo_movimiento === 'ingreso'
            //                         ? stockActual + parseInt(item.cantidad_solicitada) : stockActual - parseInt(item.cantidad_solicitada))
            //                     let costoPromedio = parseFloat(agrupado[bodega][codigo][1].toFixed(4))
            //                     let saldoActual = parseFloat(agrupado[bodega][codigo][2].toFixed(4))
            //                     let saldoNuevo = 0
            //                     let precioUnitario = parseFloat(item.costo_promedio)
            //                     if (item.uid_orden_compra) {
            //                         let movimiento = (parseFloat(item.precio_compra)
            //                             * parseInt(item.cantidad_solicitada))
            //                         saldoNuevo = (item.tipo_movimiento === 'ingreso'
            //                             ? saldoActual + movimiento : saldoActual - movimiento)
            //                         saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
            //                         if (stockNuevo === 0) {
            //                             costoPromedio = 0
            //                         } else {
            //                             costoPromedio = parseFloat((saldoNuevo / stockNuevo).toFixed(4))
            //                         }
            //                         for (const element of bodegas) {
            //                             if (agrupado[element][codigo]) {
            //                                 agrupado[element][codigo][1] = costoPromedio
            //                             }
            //                         }
            //                     } else if (item.motivo === 'CARGA INICIAL') {
            //                         let movimiento = (parseFloat(item.costo_promedio)
            //                             * parseInt(item.cantidad_solicitada))
            //                         saldoNuevo = (item.tipo_movimiento === 'ingreso'
            //                             ? saldoActual + movimiento : saldoActual - movimiento)
            //                         saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
            //                         if (stockNuevo === 0) {
            //                             costoPromedio = 0
            //                         } else {
            //                             costoPromedio = parseFloat((saldoNuevo / stockNuevo).toFixed(4))
            //                         }
            //                         for (const element of bodegas) {
            //                             if (agrupado[element][codigo]) {
            //                                 agrupado[element][codigo][1] = costoPromedio
            //                             }
            //                         }
            //                     } else {
            //                         let movimiento = (costoPromedio * parseInt(item.cantidad_solicitada))
            //                         saldoNuevo = (item.tipo_movimiento === 'ingreso'
            //                             ? saldoActual + movimiento : saldoActual - movimiento)
            //                         saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
            //                         precioUnitario = costoPromedio
            //                     }
            //                     let saldoMovimiento = precioUnitario * parseInt(item.cantidad_solicitada)
            //                     data2.push({
            //                         ...item,
            //                         costo_promedio: precioUnitario,
            //                         costo_promedio2: precioUnitario,
            //                         saldo_valorizado: saldoMovimiento,
            //                         saldo_valorizado2: saldoMovimiento,
            //                         total_bodegas: stockNuevo,
            //                         total: saldoNuevo,
            //                         costo_promedio_general: (costoPromedio).toFixed(2),
            //                         saldo: ((item.tipo_movimiento
            //                             === 'ingreso' ? saldoNuevo - saldoMovimiento :
            //                             saldoNuevo + saldoMovimiento)).toFixed(2),
            //                     })
            //                     agrupado[bodega][codigo][0] = stockNuevo
            //                     agrupado[bodega][codigo][1] = costoPromedio
            //                     agrupado[bodega][codigo][2] = saldoNuevo
            //                     valores.push(item.codigo + item.uid_solicitud_pedido + item.bodega + item.motivo)
            //                 }
            //             }
            //         }
            //     })
            //     // data2.reverse()
            //     setData(data2)
            //     setData3([...data2.filter((val) => {
            //         if (val.fecha_documento ? filtrarFecha(val.fecha_documento) : false) return val;
            //     })])
            //     const labels = ["TODOS"]
            //     const bodegas2 = ["TODOS"]
            //     let codigos = []
            //     arrayMessage.map((item) => {
            //         if (item.descripcion) labels.push(item.descripcion)
            //         if (!codigos[item.codigo]) codigos[item.codigo] = item.descripcion
            //         if (item.bodega) bodegas2.push(item.bodega)
            //     })
            //     setCodProd(codigos)
            //     const conjunto = Array.from(new Set(labels));
            //     const conjunto2 = Array.from(new Set(bodegas2));
            //     setDataFiltro(conjunto.map(item => { return { descripcion: item } }))
            //     setDataFiltro2(conjunto2.map(item => { return { descripcion: item } }))
            // }).catch((error) => {
            //     console.log("Error getting document:", error);
            // });
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

    };

    function cargarKardex(producto) {
        setLoading(true)
        let copiaAgrupado = agrupado
        let bodegas = Object.keys(copiaAgrupado)
        let docRef = db.collection("total_movimientos").orderBy("fecha_documento", "desc")
        if (producto !== 'TODOS') {
            let lista = codigoProd.filter((val) => val.nombre === producto)
            if (lista.length > 0) {
                docRef = docRef.where('codigo', 'in', [...lista.map((val) => { return val.codigo })])
            }
        }
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            const data2 = []
            const valores = []
            arrayMessage.reverse()
            arrayMessage.map((item) => {
                if (item.codigo && item.uid_solicitud_pedido && item.bodega && item.motivo) {
                    if (valores.indexOf(item.codigo + item.uid_solicitud_pedido + item.bodega + item.motivo) < 0) {
                        let bodega = item.bodega
                        let codigo = item.codigo
                        if (copiaAgrupado[bodega] && copiaAgrupado[bodega][codigo]) {
                            let stockActual = copiaAgrupado[bodega][codigo][0]
                            let stockNuevo = (item.tipo_movimiento === 'ingreso'
                                ? stockActual + parseInt(item.cantidad_solicitada) :
                                stockActual - parseInt(item.cantidad_solicitada))
                            let costoPromedio = parseFloat(copiaAgrupado[bodega][codigo][1].toFixed(4))
                            let saldoActual = parseFloat(copiaAgrupado[bodega][codigo][2].toFixed(4))

                            let saldoNuevo = 0
                            let precioUnitario = parseFloat(item.costo_promedio)
                            if (item.uid_orden_compra) {
                                let movimiento = (parseFloat(item.precio_compra)
                                    * parseInt(item.cantidad_solicitada))
                                saldoNuevo = (item.tipo_movimiento === 'ingreso'
                                    ? saldoActual + movimiento : saldoActual - movimiento)
                                saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
                                if (stockNuevo === 0) {
                                    costoPromedio = 0
                                } else {
                                    costoPromedio = parseFloat((saldoNuevo / stockNuevo).toFixed(4))
                                }
                                for (const element of bodegas) {
                                    if (copiaAgrupado[element][codigo]) {
                                        copiaAgrupado[element][codigo][1] = costoPromedio
                                    }
                                }
                            } else if (item.motivo === 'CARGA INICIAL') {
                                let movimiento = (parseFloat(item.costo_promedio)
                                    * parseInt(item.cantidad_solicitada))
                                saldoNuevo = (item.tipo_movimiento === 'ingreso'
                                    ? saldoActual + movimiento : saldoActual - movimiento)
                                saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
                                if (stockNuevo === 0) {
                                    costoPromedio = 0
                                } else {
                                    costoPromedio = parseFloat((saldoNuevo / stockNuevo).toFixed(4))
                                }

                                for (const element of bodegas) {
                                    if (copiaAgrupado[element][codigo]) {
                                        copiaAgrupado[element][codigo][1] = costoPromedio
                                    }
                                }
                            } else {
                                let movimiento = (costoPromedio * parseInt(item.cantidad_solicitada))
                                saldoNuevo = (item.tipo_movimiento === 'ingreso'
                                    ? saldoActual + movimiento : saldoActual - movimiento)
                                saldoNuevo = parseFloat(saldoNuevo.toFixed(4))
                                precioUnitario = costoPromedio
                            }
                            let saldoMovimiento = precioUnitario * parseInt(item.cantidad_solicitada)
                            data2.push({
                                ...item,
                                costo_promedio: precioUnitario,
                                costo_promedio2: precioUnitario,
                                saldo_valorizado: saldoMovimiento,
                                saldo_valorizado2: saldoMovimiento,
                                total_bodegas: stockNuevo,
                                total: saldoNuevo,
                                costo_promedio_general: (costoPromedio).toFixed(2),
                                saldo: ((item.tipo_movimiento
                                    === 'ingreso' ? saldoNuevo - saldoMovimiento :
                                    saldoNuevo + saldoMovimiento)).toFixed(2),
                            })
                            copiaAgrupado[bodega][codigo][0] = stockNuevo
                            copiaAgrupado[bodega][codigo][1] = costoPromedio
                            copiaAgrupado[bodega][codigo][2] = saldoNuevo

                            valores.push(item.codigo + item.uid_solicitud_pedido + item.bodega + item.motivo)
                        }
                    }

                }
            })
            // data2.reverse()
            setData(data2)
            setData3([...data2.filter((val) => {
                if (val.fecha_documento ? filtrarFecha(val.fecha_documento) : false) return val;
            })])
            let codigos = []
            arrayMessage.map((item) => {
                if (!codigos[item.codigo]) codigos[item.codigo] = item.descripcion
            })
            setCodProd(codigos)
            if (data2.length === 0) {
                Swal.fire({ icon: "info", text: "No hay movimientos de ese producto" });
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            Swal.fire({ icon: "error", text: "Error " + error });
            console.log("Error getting document:", error);
        });
    }

    const columns = [
        {
            field: 'fecha_documento', headerName: 'FECHA DOCUMENTO', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value &&
                moment(new Date(value.seconds * 1000)
                    .toISOString()).format("MMMM Do YYYY, h:mm:ss a"),
        },
        { field: 'uid_solicitud_pedido', headerName: 'NUMERO DE REFERENCIA', width: 210 },

        { field: 'bodega', headerName: 'BODEGA', width: 200 },
        { field: 'motivo', headerName: 'CONCEPTO', width: 350 },
        {
            field: 'o', headerName: 'OBSERVACION', width: 500,
            renderCell: (params) => {
                const respuesta = params.row.observacion_adicional === undefined
                    || params.row.observacion_adicional === null ||
                    params.row.observacion_adicional === '' ? 'SIN OBSERVACION' : params.row.observacion_adicional
                return respuesta
            }
        },
        { field: 'codigo', headerName: 'CODIGO', width: 100 },
        { field: 'flota', headerName: 'FLOTA', width: 230 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 340 },
        {
            field: 'saldo', headerName: 'SALDO ACTUAL', width: 150,
            renderCell: (params) => {
                return `$${parseFloat(params.row.saldo).toFixed(4)}`
            }
        },

        {
            field: '1', headerName: 'CANTIDAD', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'ingreso' ? params.row.cantidad_solicitada : ''
                return respuesta === '' ? '' : parseInt(respuesta)
            }
        },

        {
            field: '2', headerName: 'COSTO UNITARIO', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'ingreso' ? params.row.costo_promedio : ''
                return respuesta === undefined || respuesta === null
                    || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(4)}`
            }
        },

        {
            field: '3', headerName: 'TOTAL VALORIZADO', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'ingreso'
                    ? parseFloat(params.row.saldo_valorizado).toFixed(4) : ''
                return respuesta === undefined || respuesta === null
                    || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(4)}`
            }
        },

        {
            field: '4', headerName: 'CANTIDAD', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'egreso' ? params.row.cantidad_solicitada : ''
                return respuesta === '' ? '' : parseInt(respuesta)
            }
        },

        {
            field: '5', headerName: 'COSTO UNITARIO', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'egreso'
                    ? params.row.costo_promedio : ''
                return respuesta === undefined || respuesta === null
                    || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(4)}`
            }
        },

        {
            field: '6', headerName: 'TOTAL VALORIZADO', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento ===
                    'egreso' ? parseFloat(params.row.saldo_valorizado).toFixed(4) : ''
                return respuesta === undefined ||
                    respuesta === null || respuesta === '' ? ''
                    : `$${parseFloat(respuesta).toFixed(4)}`
            }
        },

        {
            field: '7', headerName: 'STOCK ACTUAL', width: 160,
            renderCell: (params) => {
                return params.row.total_bodegas
            }
        },

        {
            field: '8', headerName: 'COSTO PROMEDIO ACTUAL', width: 220,
            renderCell: (params) => {
                const respuesta = parseFloat(params.row.costo_promedio_general).toLocaleString('en')
                return respuesta === undefined || respuesta === null
                    || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(4)}`
            }
        },

        {
            field: '9', headerName: 'SALDO VALORIZADO', width: 160,
            renderCell: (params) => {
                return `$${parseFloat(params.row.total).toFixed(4)}`
            }
        },

    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />

        </GridToolbarContainer>
    );

    const exporter = React.createRef(null);
    const exporter2 = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const excelExport2 = () => {
        if (exporter2.current) {
            setLoading(true)
            exporter2.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }

    const CustomGroupHeader = (props) => {
        return codProd[props.value] ? props.value + ' - ' + codProd[props.value] : 'NO ENCONTRADO';
    };

    const CustomGroupHeader2 = (props) => {
        return props.value.toUpperCase();
    };

    const tabla = () => {
        let datos = []
        data.map((item) => {
            datos.push({
                fecha_documento: item.fecha_documento === undefined ?
                    "No asignado" : moment(item.fecha_documento.toDate().toISOString())
                        .format("MMMM Do YYYY, h:mm:ss a"),
                uid_solicitud_pedido: item.uid_solicitud_pedido === undefined ?
                    "No asignado" : item.uid_solicitud_pedido,
                bodega: item.bodega === undefined ? "No asignado" : item.bodega,
                motivo: item.motivo === undefined ? "No asignado" : item.motivo,
                observacion_adicional: item.observacion_adicional === undefined ?
                    "SIN OBSERVACION" : item.observacion_adicional,
                codigo: item.codigo === undefined ? "No asignado" : item.codigo,
                flota: item.flota === undefined ? "No asignado" : item.flota,
                descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,
                saldo: item.saldo === undefined ? 0 : Number((parseFloat(item.saldo)).toFixed(2)),

                cantidad_solicitada: (item.tipo_movimiento ===
                    'ingreso' && item.cantidad_solicitada !== undefined) ?
                    Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                costo_promedio: (item.tipo_movimiento ===
                    'ingreso' && item.costo_promedio !== undefined) ?
                    Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                saldo_valorizado: (item.tipo_movimiento ===
                    'ingreso' && item.saldo_valorizado !== undefined) ?
                    Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                cantidad_solicitada2: (item.tipo_movimiento === 'egreso'
                    && item.cantidad_solicitada !== undefined) ?
                    Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                costo_promedio2: (item.tipo_movimiento === 'egreso' &&
                    item.costo_promedio !== undefined) ?
                    Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                saldo_valorizado2: (item.tipo_movimiento === 'egreso' &&
                    item.saldo_valorizado !== undefined) ?
                    Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                total_bodegas: item.total_bodegas === undefined ? 0
                    : Number((parseFloat(item.total_bodegas)).toFixed(0)),
                costo_promedio_general: item.costo_promedio_general === undefined
                    ? 0.00 : Number((parseFloat(item.costo_promedio_general)).toFixed(4)),
                total: item.total === undefined ? 0.00 : Number((parseFloat(item.total)).toFixed(4)),
            })
        })


        let datos2 = []
        data3.map((item) => {
            datos2.push({
                fecha_documento: item.fecha_documento === undefined
                    ? "No asignado" : moment(item.fecha_documento.toDate()
                        .toISOString()).format("MMMM Do YYYY, h:mm:ss a"),
                uid_solicitud_pedido: item.uid_solicitud_pedido ===
                    undefined ? "No asignado" : item.uid_solicitud_pedido,
                bodega: item.bodega === undefined ? "No asignado" : item.bodega,
                motivo: item.motivo === undefined ? "No asignado" : item.motivo,
                observacion_adicional: item.observacion_adicional ===
                    undefined ? "SIN OBSERVACION" : item.observacion_adicional,
                codigo: item.codigo === undefined ? "No asignado" : item.codigo,
                flota: item.flota === undefined ? "No asignado" : item.flota,
                descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,
                saldo: item.saldo === undefined ? 0 : Number((parseFloat(item.saldo)).toFixed(2)),

                cantidad_solicitada: (item.tipo_movimiento === 'ingreso'
                    && item.cantidad_solicitada !== undefined)
                    ? Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                costo_promedio: (item.tipo_movimiento === 'ingreso'
                    && item.costo_promedio !== undefined)
                    ? Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                saldo_valorizado: (item.tipo_movimiento === 'ingreso'
                    && item.saldo_valorizado !== undefined)
                    ? Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                cantidad_solicitada2: (item.tipo_movimiento === 'egreso'
                    && item.cantidad_solicitada !== undefined)
                    ? Number((parseFloat(item.cantidad_solicitada)).toFixed(0)) : null,
                costo_promedio2: (item.tipo_movimiento === 'egreso'
                    && item.costo_promedio !== undefined)
                    ? Number((parseFloat(item.costo_promedio)).toFixed(2)) : null,
                saldo_valorizado2: (item.tipo_movimiento === 'egreso'
                    && item.saldo_valorizado !== undefined)
                    ? Number((parseFloat(item.saldo_valorizado)).toFixed(2)) : null,

                total_bodegas: item.total_bodegas ===
                    undefined ? 0 : Number((parseFloat(item.total_bodegas)).toFixed(0)),
                costo_promedio_general: item.costo_promedio_general
                    === undefined ? 0.00 : Number((parseFloat(item.costo_promedio_general)).toFixed(2)),
                total: item.total === undefined ? 0.00 :
                    Number((parseFloat(item.total)).toFixed(2)),
            })
        })

        const group2 = [{ field: "bodega" }, { field: "codigo" }];

        let excelFiltrado = process(
            datos2.filter((val) => {
                if (item2 === '' || item2 === "TODOS" || val.bodega?.includes(item2)) {
                    if (item === '' || item === "TODOS" || (val.descripcion?.includes(item))) {
                        return val;
                    }
                }
            }).map((row) => (
                row
            ))
            , {
                group: group2,
            }).data;

        let excelGeneral = process(
            datos.map((row) => (
                row
            ))
            , {
                group: group2,
            }).data;


        return (
            <>
                <ExcelExport onExportComplete={handleExport2}
                    data={excelGeneral} group={group2} fileName="KardexGeneral.xlsx"
                    ref={exporter} filterable={true} >
                    <ExcelExportColumn field="fecha_documento"
                        title="FECHA DOCUMENTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="uid_solicitud_pedido"
                        title="NUMERO DE REFERENCIA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="bodega"
                        title="BODEGA" headerCellOptions={{ background: "#672780" }}
                        groupHeaderCellOptions={{ background: "#65C8D7" }}
                        groupHeader={CustomGroupHeader2} />
                    <ExcelExportColumn field="motivo"
                        title="CONCEPTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="observacion_adicional"
                        title="OBSERVACION" headerCellOptions={{ background: "#672780" }} width={400} />
                    <ExcelExportColumn field="codigo"
                        title="CODIGO" headerCellOptions={{ background: "#672780" }}
                        groupHeader={CustomGroupHeader} />
                    <ExcelExportColumn field="flota"
                        title="FLOTA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="descripcion"
                        title="DESCRIPCION" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo"
                        title="SALDO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada"
                        title="CANTIDAD" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio"
                        title="COSTO UNITARIO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo_valorizado"
                        title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada2"
                        title="CANTIDAD" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio2"
                        title="COSTO UNITARIO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo_valorizado2"
                        title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="total_bodegas"
                        title="STOCK ACTUAL" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio_general"
                        title="COSTO PROMEDIO ACTUAL" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="total"
                        title="SALDO VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                </ExcelExport>

                <ExcelExport onExportComplete={handleExport2}
                    data={excelFiltrado} group={group2} fileName={"KardexGeneralFiltrado" +
                        (startDate ? moment(startDate.toString()).format('YYYY-MM-DD') : '') + " - " +
                        (endDate ? moment(endDate.toString()).format('YYYY-MM-DD') : '')
                        + ".xlsx"} ref={exporter2} filterable={true} >
                    <ExcelExportColumn field="fecha_documento"
                        title="FECHA DOCUMENTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="uid_solicitud_pedido"
                        title="NUMERO DE REFERENCIA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="bodega"
                        title="BODEGA" headerCellOptions={{ background: "#672780" }}
                        groupHeaderCellOptions={{ background: "#65C8D7" }}
                        groupHeader={CustomGroupHeader2} />
                    <ExcelExportColumn field="motivo"
                        title="CONCEPTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="observacion_adicional"
                        title="OBSERVACION" headerCellOptions={{ background: "#672780" }} width={400} />
                    <ExcelExportColumn field="codigo"
                        title="CODIGO" headerCellOptions={{ background: "#672780" }}
                        groupHeader={CustomGroupHeader} />
                    <ExcelExportColumn field="flota"
                        title="FLOTA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="descripcion"
                        title="DESCRIPCION" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo"
                        title="SALDO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada"
                        title="CANTIDAD" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio"
                        title="COSTO UNITARIO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo_valorizado"
                        title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada2"
                        title="CANTIDAD" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio2"
                        title="COSTO UNITARIO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="saldo_valorizado2"
                        title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="total_bodegas"
                        title="STOCK ACTUAL" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="costo_promedio_general"
                        title="COSTO PROMEDIO ACTUAL" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="total" title="SALDO VALORIZADO"
                        headerCellOptions={{ background: "#672780" }} />
                </ExcelExport>
            </>
        )
    };

    function filtrarFecha(fecha) {
        let valor = new Date(fecha.seconds * 1000).getTime()
        return valor >= startDate.getTime() && valor <= endDate.getTime()
    }

    function cambiarFechas() {
        setData3([...data.filter((val) => {
            if (val.fecha_documento ? filtrarFecha(val.fecha_documento) : false) return val;
        })])
    }

    return (
        <>
            <br />

            <Stack direction="row" spacing={2}>
                <Button onClick={() => excelExport()}
                    size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                >
                    {" "}
                    DESCARGAR EXCEL
                </Button>
                <Button onClick={() => setOpen3(true)}
                    size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />}
                >
                    {" "}
                    DESCARGAR PDF
                </Button>
            </Stack >
            <Dialog open={open3} fullWidth onClose={() => setOpen3(false)} maxWidth="xl">
                <DialogContent>
                    <KardexGPDF contenido={data} />
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained"
                        onClick={() => setOpen3(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xl">
                <DialogContent>
                    <KardexGPDF contenido={data3.filter((val) => {
                        if (item2 === '' || item2 === "TODOS" || (val.bodega?.includes(item2))) {
                            if (item === '' || item === "TODOS" || (val.descripcion?.includes(item))) {
                                return val;
                            }
                        }
                    }).map((row) => (
                        row
                    ))} />
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() =>
                        setOpen2(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
            {tabla()}
            <Box pt={1}>
                <Card>
                    <CardContent>
                        <Box pt={2} pb={2}>
                            <p style={{ fontSize: "12px", marginBottom: "8px" }}>
                                <strong>BUSQUEDA POR BODEGA:</strong></p>
                            <Autocomplete
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                disableClearable
                                fullWidth
                                size='small'
                                disablePortal
                                id="combo-box-demo"
                                options={dataFiltro2}
                                getOptionLabel={(option) => option}
                                onInputChange={(e, newInputValue) => {
                                    if (item !== newInputValue) {
                                        setItem2(newInputValue)
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <br />
                            <p style={{ fontSize: "12px", marginBottom: "8px" }}>
                                <strong>BUSQUEDA POR NOMBRE:</strong></p>
                            <Autocomplete
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                disableClearable
                                fullWidth
                                size='small'
                                disablePortal
                                id="combo-box-demo"
                                options={dataFiltro}
                                getOptionLabel={(option) => option}
                                onInputChange={(e, newInputValue) => {
                                    if (item !== newInputValue) {
                                        setItem(newInputValue)
                                        if (dataFiltro.filter((val) => val
                                            === newInputValue).length !== 0) {
                                            cargarKardex(newInputValue)
                                        }
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                        <Box display="flex" style={{ textTransform: "uppercase" }}>
                            <Typography variant="button">
                                <strong>Desde</strong>{" "}
                                {startDate && moment(startDate.toString()).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography variant="button" style={{ paddingLeft: 10 }}>
                                <strong>Hasta</strong>{" "}
                                {endDate && moment(endDate.toString()).format("DD/MM/YYYY")}
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <Button onClick={() => setOpen(!open)}>
                                Buscar por fecha
                            </Button>
                        </Box>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={() => cambiarFechas()}
                            >
                                Aplicar busqueda
                            </Button>
                        </Collapse>
                        <br />

                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => excelExport2()}
                                size="small" color="success" variant="outlined"
                                startIcon={<SimCardDownloadIcon />}
                            >
                                {" "}
                                EXCEL FILTRADO
                            </Button>
                            <Button onClick={() => setOpen2(true)}
                                size="small" color="error" variant="outlined"
                                startIcon={<PictureAsPdfIcon />}
                            >
                                {" "}
                                PDF FILTRADO
                            </Button>
                        </Stack >

                        <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >

                            <Box sx={{ height: 500, width: '100%' }}>

                                <DataGrid
                                    density="compact"
                                    slots={{
                                        loadingOverlay: LinearProgress,
                                    }}
                                    loading={dataFiltro2.length === 0}

                                    rows={data3 ? data3
                                        .filter((val) => {
                                            if (item2 === '' || item2 === "TODOS" || (val.bodega?.includes(item2))) {
                                                if (item === '' || item === "TODOS"
                                                    || (val.descripcion?.includes(item))) {
                                                    return val;
                                                }
                                            }
                                        }).map((row) => (
                                            row
                                        )) : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 100,
                                            },
                                        },
                                    }}
                                    hideFooterSelectedRowCount={true}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}

                                />

                            </Box>

                        </Paper>


                        <Dialog open={loading} >
                            <DialogTitle>
                                <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress align="center" />
                                    <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                                </Stack>
                            </DialogTitle>
                        </Dialog>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};