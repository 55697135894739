import {
    Box, Typography, IconButton, Dialog, Paper, Chip, DialogTitle, CircularProgress, Hidden, Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { ViewReasignacionTicket, ViewTicketReasignacion } from "./ViewTicketReasignacion";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
export const ReasignarTicketsPlaneacion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //
    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("tickets").where('estado', '==', 3);
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        requiere_materiales2: doc.data().requiere_materiales === 0 ? 'SI' : 'NO',
                        estado2: 'POR REASIGNAR',

                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])
    function CustomFooterTotalComponent(props) {
        return ''
    }
    const columns = [
        /* {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="REASIGNAR" color="warning" />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        { field: 'flota', headerName: 'FLOTA', width: 100 },
        { field: 'lider_flota', headerName: 'LIDER', width: 280 },
        { field: 'auxiliar_flota', headerName: 'AUXILIAR', width: 250 },
        { field: 'motivo_rechazo', headerName: 'MOTIVO RECHAZO', width: 250 },
        {
            field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'fecha_asignacion_flota', headerName: 'FECHA RESPUESTA', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'usuario_que_asigno_flota', headerName: 'USUARIO QUE RESPONDIO', width: 250 },
        {
            field: '', headerName: 'REASIGNAR', width: 120,
            renderCell: (params) => {
                return <ViewTicketEnEsperaPlaneacion props={params.row} />
            }
        }, */
        {
            field: 'estado', headerName: 'ESTADO', width: 160,
            renderCell: (params) => {
                return <Chip size="small" label="POR REASIGNAR" color="warning" />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 120 },
        { field: 'correo', headerName: 'CORREO', width: 220 },
        { field: 'direccion', headerName: 'DIRECCION', width: 600 },
        { field: 'zona', headerName: 'ZONA', width: 350 },
        { field: 'coordenadas', headerName: 'COORDENADAS', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 130 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        {
            field: 'requiere_materiales', headerName: 'REQUIERE MATERIALES', width: 210,
            renderCell: (params) => {
                return params.row.requiere_materiales === 0 ? 'SI' : 'NO'
            }
        },
        { field: 'motivo_rechazo', headerName: 'MOTIVO RECHAZO', width: 250 },
        {
            field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'fecha_rechazo', headerName: 'FECHA RECHAZO', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <ViewTicketReasignacion props={params.row} />
            }
        },

    ]
    const csvOptions = { delimiter: ';' }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    const exporter = React.createRef(null);
    const [open2, setOpen2] = useState(false);
    const handleExport2 = () => {
        setOpen2(false)
    }
    const handleExport = () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        var date1;
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`;
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`;
        }

        const reData = [
            ...data.map((doc) => {
                return {
                    CEDULA: doc.cedula,
                    CAJA: doc.caja,
                    DETALLE: `${doc.nombre_cliente}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}\n${doc.correo}\n${doc.coordenadas}\n${doc.caja}\n${doc.numContrato}`,
                    COORD_CAJA: doc.coordenadas,
                    TICKET: doc.uid_ticket,
                    NOMBRE: doc.nombre_cliente,
                    TELEFONO: doc.telefono,
                    CELULAR: doc.celular,
                    CORREO: doc.correo,
                    DIRECCION: doc.direccion,
                    ZONA: doc.zona,
                    CONTRATO: doc.linea_contrato,
                    AGENDADO_DESDE: doc.agendado_desde,
                    DEPARTAMENTO: doc.departamento,
                    ASUNTO_TICKET: doc.asunto_del_ticket,
                    VALOR: doc.valor_a_cobrar,
                    DETALLES_TICKET: doc.detalles_del_ticket,
                    TURNO: doc.turno,
                 
                    DETALLES_TICKET: doc.detalles_del_ticket,
                    REQUIERE_MATERIALES: doc.requiere_materiales2,
                    MOTIVO_RECHAZO: doc.motivo_rechazo,
                    FECHA_RECHAZO: doc.fecha_rechazo ? doc.fecha_rechazo.toDate() : '',
                    created: doc.created.toDate(),
                };
            }),
        ];
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Licencias");
        XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`);
    }
    return (
        <>
            <IconButton color="primary" onClick={handleExport}>
                <SimCardDownloadIcon />
            </IconButton>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                        Footer: CustomFooterTotalComponent,
                    }}
                    onStateChange={(state) => {
                        setTotal(state.pagination.rowCount)
                    }}
                />
            </Box>
            <ExcelExport onExportComplete={handleExport2} data={data ? data : []} fileName={"TicketsReasignar.xlsx"} ref={exporter} filterable={true} >
                <ExcelExportColumn field="estado2" title="ESTADO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="uid_ticket" title="ID TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="nombre_cliente" title="NOMBRE CLIENTE" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="linea_contrato" title="LINEA CONTRATO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="agendado_desde" title="AGENDADO DESDE" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="departamento" title="DEPARTAMENTO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="asunto_del_ticket" title="ASUNTO DEL TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="valor_a_cobrar" title="VALOR" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="detalles_del_ticket" title="DETALLES DEL TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="requiere_materiales2" title="REQUIERE MATERIALES" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="motivo_rechazo" title="MOTIVO RECHAZO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="created" title="FECHA CREACION" headerCellOptions={{ background: "#672780" }} width={150} />
            </ExcelExport>
            <Dialog open={open2} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>DESCARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    );
};