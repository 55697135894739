import React, { useContext, useEffect, useState } from 'react'
import { Button, Breadcrumbs, Box } from '@mui/material';
import { detallesSolicitud } from '../../redux/actions/ventasActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/utils/Loader';
import { useParams } from 'react-router-dom';
import { NuevaVenta } from '../../components/ventas/NuevaVenta';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
export const NuevaVentaPage = () => {
  const params = useParams()
  const solicitudDetalle = useSelector(state => state.solicitudDetalle)
  const { loading, solicitud } = solicitudDetalle
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(detallesSolicitud(params.id))
    console.log('test')
  }, [dispatch])
  return (
    <>
      <Box py={2} pl={2}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Button component={RouterLink} to={`/ventas/solicitud-de-servicio/1`}>
            Solicitudes aprobadas
          </Button>
          <Button>
            Nueva venta
          </Button>
        </Breadcrumbs>
      </Box>
      {!loading ? <NuevaVenta items={solicitud} /> : <Loader />}
    </>
  )
}