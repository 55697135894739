export const detalleSolicitudReducers = (state = { solicitud: [] }, action) => {
    switch (action.type) {
        case 'SOLICITUD_DETAILS_REQUEST':
            return { loading: true }
        case 'SOLICITUD_DETAILS_SUCCESS':
            return { loading: false, solicitud: action.payload }
        case 'SOLICITUD_DETAILS_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const detalleInstalacionReducers = (state = { instalacion: [] }, action) => {
    switch (action.type) {
        case 'INSTALACION_DETAILS_REQUEST':
            return { loading: true }
        case 'INSTALACION_DETAILS_SUCCESS':
            return { loading: false, instalacion: action.payload }
        case 'INSTALACION_DETAILS_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updateSolicitudReducers = (state = { updateSolicitud: [] }, action) => {
    switch (action.type) {
        case 'SOLICITUD_UPDATE_REQUEST':
            return { loading: true }
        case 'SOLICITUD_UPDATE_SUCCESS':
            return { loading: false, updateSolicitud: action.payload }
        case 'SOLICITUD_UPDATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const detalleInstalacionTicketsReducers = (state = { instalacionTickets: [] }, action) => {
    switch (action.type) {
        case 'INSTALACIONTICKETS_DETAILS_REQUEST':
            return { loading: true }
        case 'INSTALACIONTICKETS_DETAILS_SUCCESS':
            return { loading: false, instalacionTickets: action.payload }
        case 'INSTALACIONTICKETS_DETAILS_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}