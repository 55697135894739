/* import {
    Radio,
    Tabs,
    Typography,
    Box,
    Tab,
    RadioGroup,
    Button,
    FormLabel,
    Divider,
    Select,
    FormControl,
    FormControlLabel,
    CardContent,
    Paper
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { ListaAsuntoTickets } from './ListaAsuntoTickets';
import { NuevoAsuntoTicket } from './NuevoAsuntoTicket';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const AsuntoTicketPage = () => {
    const params = useParams()
    let navigate = useNavigate()
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(`/tickets/parametros/asunto-de-ticket/${newValue}`)
    }
    useEffect(() => {

        if (params.id) {

            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
            setValue(parseInt(params.id));
        } else {
            setValue(0)
        }
    }, [params.id])

    return (
        <>
            <Box px={2}>
                <Typography variant='h5' component='h1'> <strong>ASUNTO DE TICKETS</strong> </Typography>
            </Box>
            <Box pt={1} px={2}>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Crear" {...a11yProps(0)} />
                            <Tab label="Lista" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Paper>
            </Box>

            <TabPanel value={value} index={0}>
                <NuevoAsuntoTicket />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListaAsuntoTickets />
            </TabPanel>
        </>
    )
} */

import { Box, Card, Tab, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { NuevoAsuntoTicket } from './NuevoAsuntoTicket';
import { ListaAsuntoTickets } from './ListaAsuntoTickets';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const AsuntoTicketPage = () => {
    const params = useParams()
    let navigate = useNavigate()
    const [tab, setTab] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
        navigate(`/tickets/parametros/asunto-de-ticket/${newValue}`)
    }
    useEffect(() => {

        if (params.id) {

            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
            setTab(parseInt(params.id));
        } else {
            setTab(0)
        }
    }, [params.id])
    return (
        <>
            <Box pl={2} pb={2} pt={2}>
                <Typography variant="h5" component="h1"><strong>ASUNTO DE TICKETS</strong></Typography>
            </Box>
            <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>

                <Tabs
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    value={tab}
                    onChange={handleChange}
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                    aria-label="basic tabs example">

                    <Tab label="Crear" {...tabs(0)} />
                    <Tab label="Lista" {...tabs(1)} />
                </Tabs>
            </Box>
            <Box pt={2}>

                <Card >
                    <TabPanel value={tab} index={0}>
                        <NuevoAsuntoTicket />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ListaAsuntoTickets />
                    </TabPanel>
                </Card>
            </Box>
        </>
    )
}