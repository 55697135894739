import {
    Typography,
    Card,
    CardContent,
    Grid,
    Button,
    FormControlLabel,
    AlertTitle,
    Alert,
    Box,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
    InputLabel,
    Stack,
    Select,
    MenuItem

} from '@mui/material'
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { db, firebase, storage, functions } from '../../firebase';
import Swal from "sweetalert2";

export const CrearEncuesta = () => {

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const [loading, setLoading] = useState(false)

    const [contrato, setContrato] = useState("");
    const [contractArray, setContractArray] = useState([]);
    //
    const [statusClient, setStatusClient] = useState();

    const [cedula2, setCedula2] = useState('');

    const [tipoID, setTipoID] = useState('')

    const [data, setData] = useState('')
    const [datos_contrato, setDatosContrato] = useState('')

    //
    const [res1, setRes1] = useState('')
    const [res2, setRes2] = useState('')
    const [res3, setRes3] = useState('')
    const [res4, setRes4] = useState('')
    const [res5, setRes5] = useState('')
    const [res6, setRes6] = useState('')
    const [res7, setRes7] = useState('')
    const [res8, setRes8] = useState('')
    const [res9, setRes9] = useState('')
    const [res10, setRes10] = useState('')
    //

    const handleSubmit = async () => {
        setLoading(true)
        await db.collection('encuestas2').doc(contrato).set({
            created: firebase.firestore.FieldValue.serverTimestamp(),
            numContrato: data.NUM_CONTRATO,
            idcliente: data.idcliente,
            telefono: data.telefono,
            movil: data.movil,
            //fechanacimiento: data.fechanacimiento,
            cedula: data.cedula,
            nombre: data.nombre,
            res1: res1,
            res2: res2,
            res3: res3,
            res4: res4,
            res5: res5,
            res6: res6,
            res7: res7,
            res8: res8,
            res9: res9,
            res10: res10

        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha enviado su solicitud de requerimiento con exito", });
        })
    }
    const apiCivil = (cedula2) => {
        setStatusClient('')
        setContractArray('')
        setCedula2(cedula2)
        if (cedula2 && cedula2.length >= 10) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            }
            fetch(`https://serverbd.intercommerce.com.ec/?cedula=${cedula2}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataParse = JSON.parse(result);
                    const data = dataParse;
                    console.log('Registro Civil: ', data);
                    if (data) {
                        setCedula2(cedula2)
                        const mikrowisp = functions.httpsCallable('mikrowisp')
                        mikrowisp(cedula2)
                            .then(res => {
                                if (res) {
                                    if (res.mensaje == "No existe el cliente con el filtro indicado.") {
                                        setStatusClient("Este cliente es nuevo en Yiga5")
                                    } else {
                                        const contracts = res.data;
                                        const data = [
                                            ...contracts.map((doc) => {
                                                return {
                                                    idcliente: doc.id,
                                                    cedula: doc.cedula,
                                                    NUM_CONTRATO: doc.NUM_CONTRATO,
                                                    estado: doc.estado,
                                                    REFERENCIA: doc.REFERENCIA,
                                                    nombre: doc.nombre,
                                                    correo: doc.correo,
                                                    direccion_principal: doc.direccion_principal,
                                                    telefono: doc.telefono,
                                                    movil: doc.movil
                                                }
                                            })
                                        ]
                                        setContractArray(data)
                                        setStatusClient("")
                                    }
                                }
                            }).catch(error => {
                                console.log(`CODE 1: ${error}`)
                            })
                    }
                }).catch(error => { console.log(`CODE 0: ${error}`) })
        }
    }
    const handleContrato = (e) => {
        setContrato(e)
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);

        const index = contractArray.findIndex(item => item.NUM_CONTRATO === e)
        const data = p[index]
        console.log(data, ' data')
        setData(data)
    }
    return (
        <>
            <Card>
                <CardContent>
                    <Box pb={2}>
                        {statusClient && <Alert severity="info">{statusClient}</Alert>}
                        {contractArray.length > 0 && (
                            <Alert severity="info">
                                {contractArray &&
                                    <>
                                        <AlertTitle>
                                            Este usuario es parte de Yiga 5 y sus contratos son:
                                        </AlertTitle>
                                        {contractArray.map((item, index) => (
                                            <Box key={index}>
                                                <Typography key={index}>
                                                    {item.NUM_CONTRATO} - <strong>{item.estado}</strong>
                                                </Typography>
                                            </Box>
                                        ))}
                                    </>
                                }
                            </Alert>
                        )}
                    </Box>
                    {datos_contrato && (
                        <Alert severity="info">
                            <AlertTitle>
                                Información del contrato {datos_contrato.NUM_CONTRATO}
                            </AlertTitle>
                            <Grid container spacing={5}>
                                <Grid item lg={6} md={6} xs={12}>
                                    <Typography >
                                        <strong>Nombres: </strong>{datos_contrato.nombre}
                                    </Typography>
                                    <Typography >
                                        <strong>Correo: </strong>{datos_contrato.correo}
                                    </Typography>
                                    <Typography >
                                        <strong>Direccion1: </strong>{datos_contrato.REFERENCIA}
                                    </Typography>
                                    <Typography >
                                        <strong>Direccion2: </strong>{datos_contrato.direccion_principal}
                                    </Typography>
                                    <Typography >
                                        <strong>Telefonos: </strong>{datos_contrato.telefono}
                                    </Typography>
                                    <Typography >
                                        <strong>Celular: </strong>{datos_contrato.movil}
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={12}>

                                </Grid>
                            </Grid>
                        </Alert>
                    )}
                    <Stack spacing={2}>
                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <InputLabel>CÉDULA | RUC | PASAPORTE</InputLabel>
                                <TextField
                                    type={(tipoID === "PASAPORTE" ? 'text' : 'number')}
                                    pattern={(tipoID === "PASAPORTE" ? '^[a-zA-Z0-9]{5,15}$' : (tipoID === "RUC" ? '^[0-9]{10}(001)$' : '^[0-9]{10}$'))}
                                    value={cedula2}
                                    onChange={(e) => apiCivil(e.target.value)}
                                    size='small'

                                />
                            </Grid>
                            <Grid item md={6}>
                            </Grid>
                        </Grid>
                        {
                            contractArray && cedula2.length >= 10 &&
                            <>
                                <Box pb={3}>

                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <Select
                                        value={contrato}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { handleContrato(e.target.value) }}
                                    >

                                        {contractArray?.map((item, index) => (
                                            <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                        ))}
                                    </Select>
                                </Box>

                                <Box pb={3}>
                                    <InputLabel>¿Tuvo algún problema con el servicio durante los 15 días de prueba?</InputLabel>
                                    <Select
                                        value={res1}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes1(e.target.value) }}
                                    >
                                        <MenuItem value="Sin Novedad">Sin Novedad</MenuItem>
                                        <MenuItem value="Descargar App">Descargar App</MenuItem>
                                        <MenuItem value="Ingreso App">Ingreso App</MenuItem>
                                        <MenuItem value="Funcionalidad">Funcionalidad</MenuItem>
                                        <MenuItem value="Calidad">Calidad</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>
                                        ¿Qué tan satisfecho esta con la calidad de la imagen y el sonido?
                                    </InputLabel>
                                    <Select
                                        value={res2}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes2(e.target.value) }}
                                    >
                                     <MenuItem value="Muy Satifecho">Muy Satifecho</MenuItem>
                                        <MenuItem value="Satifecho">Satifecho</MenuItem>
                                        <MenuItem value="Ni Satifecho ni inatifecho">Ni Satifecho ni inatifecho</MenuItem>
                                        <MenuItem value="Insatifecho">Insatifecho</MenuItem>
                                        <MenuItem value="Muy insatifecho">Muy insatifecho</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>¿Qué tan satisfecho esta con la variedad de contenido disponible?</InputLabel>
                                    <Select
                                        value={res3}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes3(e.target.value) }}
                                    >
                                          <MenuItem value="Muy Satifecho">Muy Satifecho</MenuItem>
                                        <MenuItem value="Satifecho">Satifecho</MenuItem>
                                        <MenuItem value="Ni Satifecho ni inatifecho">Ni Satifecho ni inatifecho</MenuItem>
                                        <MenuItem value="Insatifecho">Insatifecho</MenuItem>
                                        <MenuItem value="Muy insatifecho">Muy insatifecho</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>¿Qué tan satisfecho esta con la factibilidad de uso del servicio?</InputLabel>
                                    <Select
                                        value={res4}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes4(e.target.value) }}
                                    >
                                        <MenuItem value="Muy Satifecho">Muy Satifecho</MenuItem>
                                        <MenuItem value="Satifecho">Satifecho</MenuItem>
                                        <MenuItem value="Ni Satifecho ni inatifecho">Ni Satifecho ni inatifecho</MenuItem>
                                        <MenuItem value="Insatifecho">Insatifecho</MenuItem>
                                        <MenuItem value="Muy insatifecho">Muy insatifecho</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>En general, ¿cómo calificar su experiencia con el servicio de Tv por internet?</InputLabel>
                                    <Select
                                        value={res5}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes5(e.target.value) }}
                                    >
                                        <MenuItem value="Muy Satifecho">Muy Satifecho</MenuItem>
                                        <MenuItem value="Satifecho">Satifecho</MenuItem>
                                        <MenuItem value="Ni Satifecho ni inatifecho">Ni Satifecho ni inatifecho</MenuItem>
                                        <MenuItem value="Insatifecho">Insatifecho</MenuItem>
                                        <MenuItem value="Muy insatifecho">Muy insatifecho</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>¿Recomendaría este servicio un amigo o familiar?</InputLabel>
                                    <Select
                                        value={res6}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes6(e.target.value) }}
                                    >
                                        <MenuItem value="Si lo haria">Si lo haria</MenuItem>
                                        <MenuItem value="No lo haria">No lo haria</MenuItem>
                                        <MenuItem value="Programacion">Programacion</MenuItem>
                                        <MenuItem value="Facilidad de Uso">Facilidad de Uso</MenuItem>
                                        <MenuItem value="Calidad">Calidad</MenuItem>
                                    </Select>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>Del contenido que disfruto, ¿qué tipo de canales fueron sus favoritos?</InputLabel>
                                    <Select
                                        value={res7}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes7(e.target.value) }}
                                    >
                                        <MenuItem value="Nacionales">Nacionales</MenuItem>
                                        <MenuItem value="Peliculas">Peliculas</MenuItem>
                                        <MenuItem value="Series">Series</MenuItem>
                                        <MenuItem value="Documentales">Documentales</MenuItem>
                                        <MenuItem value="Musica">Musica</MenuItem>
                                        <MenuItem value="Deportes">Deportes</MenuItem>
                                    </Select>
                                </Box>

                                <Box>
                                    <Typography>Adicional al contenido que disfruto, ¿qué canales les gustaría poder visualizar posteriormente?</Typography>
                                    <Box >

                                        <TextField
                                            value={res8}
                                            size='small'
                                            onChange={(e) => setRes8(e.target.value)}
                                        />
                                        <Select
                                            value={res10}
                                            fullWidth
                                            size='small'
                                            onChange={(e) => { setRes10(e.target.value) }}
                                        >
                                            <MenuItem value="Nacionales">Nacionales</MenuItem>
                                            <MenuItem value="Peliculas">Peliculas</MenuItem>
                                            <MenuItem value="Series">Series</MenuItem>
                                            <MenuItem value="Documentales">Documentales</MenuItem>
                                            <MenuItem value="Musica">Musica</MenuItem>
                                            <MenuItem value="Deportes">Deportes</MenuItem>
                                        </Select>
                                    </Box>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>¿Porque no ha contratado este tipo de servicios, anteriormente?</InputLabel>
                                    <Select
                                        value={res9}
                                        fullWidth
                                        size='small'
                                        onChange={(e) => { setRes9(e.target.value) }}
                                    >
                                        <MenuItem value="Programacion">Programacion</MenuItem>
                                        <MenuItem value="Precio">Precio</MenuItem>
                                        <MenuItem value="Facilidad de Uso">Facilidad de Uso</MenuItem>
                                        <MenuItem value="Calidad">Calidad</MenuItem>
                                        <MenuItem value="Desconocimiento">Desconocimiento</MenuItem>
                                    </Select>
                                </Box>
                            </>
                        }
                    </Stack>
                    <Box display="flex" flexDirection="row-reverse" pt={1}>
                        <Button
                            onClick={() => handleSubmit()}
                            disabled={!cedula2 || !res1}
                            variant="contained"
                        >ENVIAR ENCUESTA
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}