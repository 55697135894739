import React, { useState, useRef, useEffect } from 'react'
import { FormControl, Grid, Box, Select, MenuItem, Card, InputLabel, CardContent, Button } from '@mui/material'
import { db } from '../../../firebase'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const NuevoCorreo = () => {
    const [plantillas_list, setPlantillasList] = useState('')
    const [plantilla, setPlantilla] = useState('')
    const [plantilla_html, setPlantillaHtml] = useState('')
    //
    const [estado, setEstado] = useState('')
    useEffect(() => {
        plantillasLoad()
    }, [])
    const plantillasLoad = () => {
        db.collection("system/settings/plantillas_correo")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setPlantillasList(arrayMessage)
                //console.log(arrayMessage, ' ')
            })
    }
    const plantillaHandler = (e) => {
        setPlantilla('')
        const p = plantillas_list.filter((item) => item.plantilla === e);
        const html = p[0]
        setPlantilla(html.plantilla)
        setPlantillaHtml(html.plantilla_html)
        console.log(html, ' contrato cliente')
    }
    return (
        <CardContent>
            <Grid container spacing={5}>
                <Grid item md={3} xs={12}>
                    <Box pb={2}>
                        <InputLabel>
                            PLANTILLA DE CORREO
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 }
                                }}
                                value={plantilla}
                                onChange={(e) => plantillaHandler(e.target.value)}
                                size="small"
                            >
                                <MenuItem value=''>--</MenuItem>
                                {plantillas_list && plantillas_list.map((row, key) => (
                                    <MenuItem key={key} value={row.plantilla}>{row.plantilla}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pb={2}>
                        <InputLabel>
                            ESTADO CLIENTE
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 }
                                }}
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                <MenuItem value="SUSPENDIDO">SUSPENDIDO</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box pb={2}>
                        <InputLabel>
                            SELECCIONAR GRUPO
                        </InputLabel>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 }
                                }}
                                //value={crearFactura}
                                //onChange={(e) => setCrearFactura(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="">--</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Button variant='contained'>Enviar correos</Button>
                </Grid>
                <Grid item md={9} xs={12}>
                    <Card>
                        <CardContent>
                            <div dangerouslySetInnerHTML={{ __html: plantilla_html }}></div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </CardContent>
    )
}