import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Divider,
    Typography,
    Alert
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, storage, firebase, auth, functions } from "../../../../firebase";
import { listsSubCategorias } from "../../../../redux/actions/parametersActions";
import {
    listsTiposInfracciones
} from "../../../../redux/actions/parametersActions";
import { UpdateFileUploadFiscalizador } from "./UpdateFileUploadFiscalizador";

export const FiscalizarInstalacion = ({ props }) => {

    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);
    const [file6, setFile6] = useState(null);

    const [dataPhotos, setDataPhotos] = useState([]);

    const getPhotosCollectionFirebase = () => {
        const ref = db
            .collection('registro_orden_trabajo')
            .where("numContrato", "==", props.numContrato);

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setDataPhotos(data);
            setFile1(data[0].imagen_caja_abierta_antes)
            setFile2(data[0].imagen_caja_abierta_despues)
            setFile3(data[0].imagen_potencia_caja)
            setFile4(data[0].imagen_codigo_caja)
            setFile5(data[0].imagen_equipo_caja)
            setFile6(data[0].imagen_serial_equipo_caja)
        });
    };

    const dataFiscalizacion = async () => {
        const agendaDB = await db.collection("instalaciones_fiscalizadas").where("numContrato", "==", props.numContrato).get()

        const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        setCajaAbiertaAntesFiscalizacion(data1[0].imagen_caja_abierta_antes)
        setCajaAbiertaDespuesFiscalizacion(data1[0].imagen_caja_abierta_despues)
        setPotenciaCajaFiscalizacion(data1[0].imagen_potencia_caja)
        setCodigooCajaFiscalizacion(data1[0].imagen_codigo_caja)
        setEquipoFiscalizacion(data1[0].imagen_equipo_caja)
        setSerialEquipo(data1[0].imagen_serial_equipo_caja)

        console.log(cajaAbiertaAntesFiscalizacion)
    };

    const tiposInfraccionesList = useSelector(state => state.tiposInfraccionesList)
    const { tiposInfracciones, loadingTiposInfracciones } = tiposInfraccionesList
    const [tiposInfraccion, setTiposInfraccion] = useState('')
    const [porcentajeInfraccion, setPorcentajeInfraccion] = useState('')
    const [valorInfraccion, setValorInfraccion] = useState('')

    const [tipoInfraccionOtros, setTipoInfraccionOtros] = useState('')
    const [valorPorcentajeOtros, setValorPorcentajeOtros] = useState('')

    const [metrajeCorrectoText, setMetrajeCorrectoText] = useState('')

    const changeTipoInfraccionOtros = (e) => {
        setTipoInfraccionOtros(e)
    }

    const handleTipoInfraccion = (e) => {
        setTiposInfraccion(e)
        const p = tiposInfracciones.filter((item) => item.tipo_infraccion === e);
        const data = p[0]
        setPorcentajeInfraccion(data.porcentaje_multa)
        setValorInfraccion(data.valor_multa)
    }

    const [geografico, setGeografico] = useState('');

    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()

    const [position, setPosition] = useState({
        lat: latitude,
        lng: longitude,
    })

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [file, setFile] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [ref, setRef] = useState(null);
    const [Imagen, setImagen] = useState('');
    const [viewImagen, setViewImagen] = useState(null)
    const useStorage = storage;

    const [cajaAbiertaAntesFiscalizacion, setCajaAbiertaAntesFiscalizacion] = useState('');
    const [cajaAbiertaDespuesFiscalizacion, setCajaAbiertaDespuesFiscalizacion] = useState('');
    const [codigooCajaFiscalizacion, setCodigooCajaFiscalizacion] = useState('');
    const [potenciaCajaFiscalizacion, setPotenciaCajaFiscalizacion] = useState('');
    const [equipoFiscalizacion, setEquipoFiscalizacion] = useState('');
    const [serialEquipo, setSerialEquipo] = useState('');

    useEffect(() => {
        getPhotosCollectionFirebase()
        setFile(Imagen)
        setFileCajaAbiertaDespues(ImagenCajaAbiertaDespues)
    }, [])

    const changeImagen = async (e) => {
        //
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaAntes64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaAntes64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);
        //
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }

    /* CAJA ABIERTA DESPUES */

    const [fileCajaAbiertaDespues, setFileCajaAbiertaDespues] = useState(null);
    const [openImageCajaAbiertaDespues, setOpenImageCajaAbiertaDespues] = useState(false);
    const [refCajaAbiertaDespues, setRefCajaAbiertaDespues] = useState(null);
    const [ImagenCajaAbiertaDespues, setImagenCajaAbiertaDespues] = useState('');
    const [viewImagenCajaAbiertaDespues, setViewImagenCajaAbiertaDespues] = useState(null)
    const useStorageCajaAbiertaDespues = storage;

    const changeImagenCajaAbiertaDespues = async (e) => {
        //
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaDespues64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaDespues64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);
        //
        setImagenCajaAbiertaDespues(e.target.files[0]);
        setViewImagenCajaAbiertaDespues(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenCajaAbiertaDespues);
    }

    var imagenUrlCajaAbiertaDespues;

    /* POTENCIA CAJA */

    const [filePotenciaCaja, setFilePotenciaCaja] = useState(null);
    const [openImagePotenciaCaja, setOpenImagePotenciaCaja] = useState(false);
    const [refPotenciaCaja, setRefPotenciaCaja] = useState(null);
    const [ImagenPotenciaCaja, setImagenPotenciaCaja] = useState('');
    const [viewImagenPotenciaCaja, setViewImagenPotenciaCaja] = useState(null)
    const useStoragePotenciaCaja = storage;

    const changeImagenPotenciaCaja = async (e) => {
        //
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaPotencia64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaPotencia64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);
        //
        setImagenPotenciaCaja(e.target.files[0]);
        setViewImagenPotenciaCaja(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenPotenciaCaja);
    }

    var imagenUrlPotenciaCaja;

    /* CODIGO CAJA */

    const [fileCodigoCaja, setFileCodigoCaja] = useState(null);
    const [openImageCodigoCaja, setOpenImageCodigoCaja] = useState(false);
    const [refCodigoCaja, setRefCodigoCaja] = useState(null);
    const [ImagenCodigoCaja, setImagenCodigoCaja] = useState('');
    const [viewImagenCodigoCaja, setViewImagenCodigoCaja] = useState(null)
    const useStorageCodigoCaja = storage;

    const changeImagenCodigoCaja = async (e) => {

        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaCodigo64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaCodigo64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);

        //console.log(base64)


        setImagenCodigoCaja(e.target.files[0]);
        setViewImagenCodigoCaja(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenCodigoCaja);
    }

    var imagenUrlCodigoCaja;

    /* EQUIPO CAJA */

    const [fileEquipo, setFileEquipo] = useState(null);
    const [openImageEquipo, setOpenImageEquipo] = useState(false);
    const [refEquipo, setRefEquipo] = useState(null);
    const [ImagenEquipo, setImagenEquipo] = useState('');
    const [viewImagenEquipo, setViewImagenEquipo] = useState(null)
    const useStorageEquipo = storage;

    const changeImagenEquipo = async (e) => {
        //
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaEquipo64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaEquipo64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);
        //
        setImagenEquipo(e.target.files[0]);
        setViewImagenEquipo(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenEquipo);
    }

    var imagenUrlEquipo;

    /* SERIAL EQUIPO CAJA */

    const [fileSerialEquipo, setFileSerialEquipo] = useState(null);
    const [openImageSerialEquipo, setOpenImageSerialEquipo] = useState(false);
    const [refSerialEquipo, setRefSerialEquipo] = useState(null);
    const [ImagenSerialEquipo, setImagenSerialEquipo] = useState('');
    const [viewImagenSerialEquipo, setViewImagenSerialEquipo] = useState(null)
    const useStorageSerialEquipo = storage;

    const changeImagenSerialEquipo = async (e) => {
        //
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
            setUrlImagenCajaAbiertaSerialEquipo64(reader.result)
            db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
                urlImagenCajaAbiertaSerialEquipo64: reader.result === '' ? '' : reader.result,
            })
        }
        reader.readAsDataURL(file);
        //
        setImagenSerialEquipo(e.target.files[0]);
        setViewImagenSerialEquipo(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenSerialEquipo);
    }

    var imagenUrlSerialEquipo;

    /* ----------------------------------------------------------------------------- */

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())
        geolocationPosition()
        geo()
        dispatch(listsTiposInfracciones())
        /* dataFiscalizacion() */
    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const [openImagenesSubidas, setOpenImagenesSubidas] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const onDataImagen = () => {
        setOpenImagenesSubidas(true)
    }

    const [openRechazoFiscalizacion, setOpenRechazoFiscalizacion] = useState(null);

    const onDataRechazoFiscalizacion = () => {
        setOpen(false)
        setOpenRechazoFiscalizacion(true)
    }

    const cancelrechazoInstalacion = () => {
        setOpenRechazoFiscalizacion(false)
        setOpen(true)
    }

    const [nombrePresentacionProducto, setNombrePresentacionProducto] = useState(props.id);
    const [observacionFizcalizacion, setObservacionFizcalizacion] = useState('');

    const [metrajeCorrecto, setMetrajeCorrecto] = useState('');

    const [observacionRechazoFizcalizacion, setObservacionRechazoFizcalizacion] = useState('');

    const [observacionAdicional, setObservacionAdicional] = useState('');

    const changeObbservacionAdicional = (e) => {
        setObservacionAdicional(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    var imagenUrl;
    var imagenUrlCajaAbiertaDespues;
    var imagenUrlPotenciaCaja;
    var imagenUrlCodigoCaja;
    var imagenUrlEquipoCaja;
    var imagenUrlSerialEquipoCaja;

    const [numeroSolicitudPdf, setNumeroSolicitudPdf] = useState('')
    const [uidSolicitudPdf, setUidSolicitudPdf] = useState('')
    //
    const [img1, setImg1] = useState('')
    const [img2, setImg2] = useState('')
    const [img3, setImg3] = useState('')
    const [img4, setImg4] = useState('')
    const [img5, setImg5] = useState('')
    const [img6, setImg6] = useState('')
    //

    //
    const updateProducto = async () => {

        var numeroSolicitud
        const ref = db.collection("instalaciones_fiscalizadas").orderBy("fecha_fiscalizacion", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                numeroSolicitud = solicitud.numFiscalizacion + 1;
                const uidSolicitud = `FIS-` + numeroSolicitud;
                setNumeroSolicitudPdf(numeroSolicitud)
                setUidSolicitudPdf(uidSolicitud)
                /* setNumPedido(numeroSolicitud) */
            }
        })
        const data = {
            numFiscalizacion: props.numContrato,
            fiscalizacion: uidSolicitudPdf,
            reporte: 'REPORTE DE FISCALIZACION INSTALACION MAL REALIZADA',
            elaborada: userInfo.displayName,
            nombre: props.nombres,
            cedula: props.cedula,
            correo: props.correo,
            telefonos: props.telefono,
            direccion: props.direccion,
            gpon: props.caja,
            coordenadas_cliente: props.coordenadas,
            coordeanadas_caja: props.coordenadas_caja,
            unidad_instalacion: props.numFlota,
            potencia: props.potencia,
            novedades: observacionAdicional,
            imagen_caja_abierta_antes: file1,
            imagen_caja_abierta_despues: file2,
            imagen_potencia_caja: file3,
            imagen_codigo_caja: file4,
            imagen_equipo_caja: file5,
            imagen_serial_equipo_caja: file6,
        }

        console.log(data, 'data')
        const createUser = functions.httpsCallable('PdfFiscalizacion')
        createUser(data)
            .then(res => {
                console.log('Se creo su pdf fiscalizacion', res)
            })
            .catch(error => console.log('ERROR: ', error))

        await db.collection('instalaciones_fiscalizadas').doc(props.numContrato).set({
            observaciones_fiscalizacion: observacionFizcalizacion.toUpperCase(),
            fecha_fiscalizacion: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_fiscalizo: userInfo.displayName,
            numFlota: props.numFlota,
            numContrato: props.numContrato,
            nombres: props.nombres,
            cedula: props.cedula,
            provincia: props.provincia,
            sector: props.sector,
            vendedor: props.vendedor,
            /* zona: props.zona, */
            canton: props.canton,
            celular: props.celular,
            contrato: props.contrato,
            correo: props.correo,
            correo_flota: props.correo_flota,
            direccion: props.direccion,
            /* linea: props.linea, */
            nacionalidad: props.nacionalidad,
            nombreLider: props.nombreLider,
            nombreAuxiliar: props.nombreAuxiliar,
            planNombre: props.planNombre,
            planValor: props.planValor,
            estado: 0,
            coordenadas: props.coordenadas,
            /* metraje_instalacion_inicio: props.cableInicio,
            metraje_instalacion_final: props.cableFinaliza, */
            /* cableInicio: props.cableInicio,
            cableFinaliza: props.cableFinaliza, */
            metraje: props.metraje,
            metraje_correcto: metrajeCorrectoText === undefined || metrajeCorrectoText === null || metrajeCorrectoText === '' ? 0 : metrajeCorrectoText,
            cableInicioCorrecto: cableInicioCorrecto === undefined || cableInicioCorrecto === null || cableInicioCorrecto === '' ? 0 : cableInicioCorrecto,
            cableFinalizaCorrecto: cableInicioFinaliza === undefined || cableInicioFinaliza === null || cableInicioFinaliza === '' ? 0 : cableInicioFinaliza,
            metrajeCorrecto: metrajeCorrecto === 'SI' ? 'SI' : 'NO',
            motivo_otros: motivoOtros,
            observacion_adicional: observacionAdicional,
            coordenadas_fiscalizador: new firebase.firestore.GeoPoint(
                1,
                1
            ),
            tipo_infraccion: tiposInfraccion,
            porcentaje_infraccion: porcentajeInfraccion === '' || porcentajeInfraccion === 0 || porcentajeInfraccion === undefined ? 0 : porcentajeInfraccion,
            valor_infraccion: valorInfraccion === '' || valorInfraccion === 0 || valorInfraccion === undefined ? 0 : valorInfraccion,
            tipo_infraccion_otros: tipoInfraccionOtros === '' || tipoInfraccionOtros === undefined || tipoInfraccionOtros === null ? '' : tipoInfraccionOtros,
            coordeanadas_caja: props.coordenadas_caja === null || props.coordenadas_caja === undefined || props.coordenadas_caja === '' ? '' : props.coordenadas_caja,
            numFiscalizacion: numeroSolicitudPdf,
            fiscalizacion: uidSolicitudPdf,
            fiscalizado: true,
            imagen_caja_abierta_antes: file1,
            imagen_caja_abierta_despues: file2,
            imagen_potencia_caja: file3,
            imagen_codigo_caja: file4,
            imagen_equipo_caja: file5,
            imagen_serial_equipo_caja: file6,
        })

        setOpen(false)

        await db.collection('registro_orden_trabajo').doc(props.id).update({
            fiscalizado: 0,
            estado: 1
        }).then(() => {

            Swal.fire({ icon: "success", text: "Fiscalizacion realizada con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });

    }

    const updateProductoPdf = async () => {

        const ref = db.collection("instalaciones_fiscalizadas").orderBy("fecha_fiscalizacion", "desc").limit(1)
        ref.onSnapshot(async snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numFiscalizacion + 1;
                const uidSolicitud = `FIS-` + numeroSolicitud;
                /* setNumPedido(numeroSolicitud) */
                await db.collection('instalaciones_fiscalizadas').doc(props.numContrato).set({
                    observaciones_fiscalizacion: observacionFizcalizacion.toUpperCase(),
                    fecha_fiscalizacion: firebase.firestore.FieldValue.serverTimestamp(),
                    usuario_que_fiscalizo: userInfo.displayName,
                    numFlota: props.numFlota,
                    numContrato: props.numContrato,
                    nombres: props.nombres,
                    cedula: props.cedula,
                    provincia: props.provincia,
                    sector: props.sector,
                    vendedor: props.vendedor,
                    /* zona: props.zona, */
                    canton: props.canton,
                    celular: props.celular,
                    contrato: props.contrato,
                    correo: props.correo,
                    correo_flota: props.correo_flota,
                    direccion: props.direccion,
                    /* linea: props.linea, */
                    nacionalidad: props.nacionalidad,
                    nombreLider: props.nombreLider,
                    nombreAuxiliar: props.nombreAuxiliar,
                    planNombre: props.planNombre,
                    planValor: props.planValor,
                    estado: 0,
                    coordenadas: props.coordenadas,
                    /* metraje_instalacion_inicio: props.cableInicio,
                    metraje_instalacion_final: props.cableFinaliza, */
                    /* cableInicio: props.cableInicio,
                    cableFinaliza: props.cableFinaliza, */
                    cableInicioCorrecto: cableInicioCorrecto,
                    cableFinalizaCorrecto: cableInicioFinaliza,
                    metrajeCorrecto: metrajeCorrecto === 'SI' ? 'SI' : 'NO',
                    motivo_otros: motivoOtros,
                    observacion_adicional: observacionAdicional,
                    coordenadas_fiscalizador: new firebase.firestore.GeoPoint(
                        position.lat,
                        position.lng
                    ),
                    tipo_infraccion: tiposInfraccion,
                    porcentaje_infraccion: porcentajeInfraccion === '' || porcentajeInfraccion === 0 || porcentajeInfraccion === undefined ? 0 : porcentajeInfraccion,
                    valor_infraccion: valorInfraccion === '' || valorInfraccion === 0 ? 0 : valorInfraccion,
                    tipo_infraccion_otros: tipoInfraccionOtros === '' ? '' : tipoInfraccionOtros,
                    coordeanadas_caja: props.coordenadas_caja === null || props.coordenadas_caja === undefined || props.coordenadas_caja === '' ? '' : props.coordenadas_caja,
                    numFiscalizacion: numeroSolicitud,
                    fiscalizacion: uidSolicitud

                    /* fiscalizado: true */
                })

                const data = {
                    /* numFiscalizacion: numeroSolicitud,
                    fiscalizacion: uidSolicitud, */
                    reporte: 'REPORTE DE FISCALIZACION INSTALACION MAL REALIZADA',
                    elaborada: userInfo.displayName,
                    numFiscalizacion: uidSolicitud,
                    nombre: props.nombres,
                    cedula: props.cedula,
                    correo: props.correo,
                    telefonos: props.telefono,
                    direccion: props.direccion,
                    gpon: props.caja,
                    coordenadas_cliente: props.coordenadas,
                    coordeanadas_caja: props.coordenadas_caja === null || props.coordenadas_caja === undefined || props.coordenadas_caja === '' ? '' : props.coordenadas_caja,
                    unidad_instalacion: props.numFlota,
                    potencia: props.potencia,
                    novedades: observacionAdicional,
                    img1: urlImagenCajaAbiertaAntes,
                    img2: urlImagenCajaAbiertaDespues,
                    img3: urlImagenCajaAbiertaPotencia,
                    img4: urlImagenCajaAbiertaCodigo,
                    img5: urlImagenCajaAbiertaEquipo,
                    img6: urlImagenCajaAbiertaSerialEquipo,
                }
                const createUser = functions.httpsCallable('PdfFiscalizacion')
                createUser(data)
                    .then(res => {
                        console.log('Se creo su pdf fiscalizacion', res)
                    })
                    .catch(error => console.log('ERROR: ', error))

                /* crearPdf() */

                await db.collection('registro_orden_trabajo').doc(props.id).update({
                    fiscalizado: 0,
                    estado: 1
                }).then(() => {

                    Swal.fire({ icon: "success", text: "Fiscalizacion realizada con exito", });
                })
                    .catch((error) => { console.error("Error adding document: ", error); });
                setOpen(false)
            }
        })

    }

    /* const validacionData = () => {
        tiposInfraccion === 'NO REALIZAR TENDIDO AMERICANO'
            || tiposInfraccion === 'EMPALME EN TRAYECTO DE FIBRA'
            || tiposInfraccion === 'MAL ADOSAMIENTO DE FIBRA'
            || tiposInfraccion === 'SIN ETIQUETA EN CAJA'
            || tiposInfraccion === 'DESCONECTAR CLIENTE ACTIVO EN CAJA'
            || tiposInfraccion === 'MAL MECANIQUEADO EN CAJA O EN CASA'
            || tiposInfraccion === 'NO PASAR FIBRA POR ACOMETIDA O POR TUBO EN PARED'
            || tiposInfraccion === 'OTROS' ?
            updateProductoPdf()
            : updateProducto()
    } */

    const rechazarFiscalizacion = async () => {

        await db.collection('instalaciones_fiscalizadas').doc(props.numContrato).set({
            observacion_rechazo_fiscalizacion: observacionRechazoFizcalizacion,
            fecha_rechazo_fiscalizacion: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_rechazo_fiscalizacion: userInfo.displayName,
            numFlota: props.numFlota,
            numContrato: props.numContrato,
            nombres: props.nombres,
            cedula: props.cedula,
            provincia: props.provincia,
            sector: props.sector,
            vendedor: props.vendedor,
            /* zona: props.zona, */
            canton: props.canton,
            celular: props.celular,
            contrato: props.contrato,
            correo: props.correo,
            correo_flota: props.correo_flota,
            direccion: props.direccion,
            /* linea: props.linea, */
            nacionalidad: props.nacionalidad,
            nombreLider: props.nombreLider,
            nombreAuxiliar: props.nombreAuxiliar,
            planNombre: props.planNombre,
            planValor: props.planValor,
            estado: 1,
            coordenadas: props.coordenadas,
            coordenadas_fiscalizador: new firebase.firestore.GeoPoint(
                position.lat,
                position.lng
            ),
            /* fiscalizado: true */
        })

        await db.collection('registro_orden_trabajo').doc(props.id).update({
            fiscalizado: 0,
            estado: 1
        }).then(() => {

            Swal.fire({ icon: "success", text: "Fiscalizacion rechazada con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpenRechazoFiscalizacion(false)
    }

    const [cableInicioCorrecto, setCableInicioCorrecto] = useState('')

    const changeCableInicioCorrecto = (e) => {
        setCableInicioCorrecto(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [cableInicioFinaliza, setCableInicioFinaliza] = useState('')

    const changeCableInicioFinaliza = (e) => {
        setCableInicioFinaliza(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [motivoOtros, setMotivoOtros] = useState('')

    const changeMotivoOtros = (e) => {
        setMotivoOtros(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const geo = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        function success(pos) {
            var crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        };
        function error(err) {
            console.warn('ERROR(' + err.code + '): ' + err.message);
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
    }

    const geolocationPosition = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        function success(pos) {
            var crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        }

        function error(err) {
            console.warn("ERROR(" + err.code + "): " + err.message);
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
    };

    function encodeImageFileAsURL(element) {
        var file = element.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            console.log('RESULT', reader.result)
        }
        reader.readAsDataURL(file);
    }

    const crearPdf = () => {

        const ref = db.collection("instalaciones_fiscalizadas").orderBy("fecha_fiscalizacion", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numFiscalizacion + 1;
                const uidSolicitud = `FIS-` + numeroSolicitud;
                /* setNumPedido(numeroSolicitud) */
            }
        })

        const data = {
            /* numFiscalizacion: numeroSolicitud,
            fiscalizacion: uidSolicitud, */
            reporte: 'REPORTE DE FISCALIZACION INSTALACION MAL REALIZADA',
            elaborada: userInfo.displayName,
            nombre: props.nombres,
            cedula: props.cedula,
            correo: props.correo,
            telefonos: props.telefono,
            direccion: props.direccion,
            gpon: props.caja,
            coordenadas_cliente: props.coordenadas,
            coordeanadas_caja: props.coordenadas_caja,
            unidad_instalacion: props.numFlota,
            potencia: props.potencia,
            novedades: observacionAdicional,
            img1: imagenUrl,
            img2: imagenUrlCajaAbiertaDespues,
            img3: imagenUrlPotenciaCaja,
            img4: imagenUrlCodigoCaja,
            img5: imagenUrlEquipoCaja,
            img6: imagenUrlSerialEquipo,
        }
        const createUser = functions.httpsCallable('PdfFiscalizacion')
        createUser(data)
            .then(res => {
                console.log('Se creo su pdf fiscalizacion', res)
            })
            .catch(error => console.log('ERROR: ', error))
    }

    const [urlImagenCajaAbiertaAntes, setUrlImagenCajaAbiertaAntes] = useState('');
    const [urlImagenCajaAbiertaDespues, setUrlImagenCajaAbiertaDespues] = useState('');
    const [urlImagenCajaAbiertaPotencia, setUrlImagenCajaAbiertaPotencia] = useState('');
    const [urlImagenCajaAbiertaCodigo, setUrlImagenCajaAbiertaCodigo] = useState('');
    const [urlImagenCajaAbiertaEquipo, setUrlImagenCajaAbiertaEquipo] = useState('');
    const [urlImagenCajaAbiertaSerialEquipo, setUrlImagenCajaAbiertaSerialEquipo] = useState('');
    //
    const [urlImagenCajaAbiertaAntes64, setUrlImagenCajaAbiertaAntes64] = useState('');
    const [urlImagenCajaAbiertaDespues64, setUrlImagenCajaAbiertaDespues64] = useState('');
    const [urlImagenCajaAbiertaPotencia64, setUrlImagenCajaAbiertaPotencia64] = useState('');
    const [urlImagenCajaAbiertaCodigo64, setUrlImagenCajaAbiertaCodigo64] = useState('');
    const [urlImagenCajaAbiertaEquipo64, setUrlImagenCajaAbiertaEquipo64] = useState('');
    const [urlImagenCajaAbiertaSerialEquipo64, setUrlImagenCajaAbiertaSerialEquipo64] = useState('');

    return (
        <>
            <Tooltip title="FISCALIZAR">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md" >
                <DialogTitle>
                    <strong>FISCALIZACION DE: {props.nombres}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box py={2}>
                        <FormControl fullWidth>
                            {/* <Grid item xs={6}> */}
                            <Box py={1}>
                                <p style={{ fontSize: "12px" }}><strong>MOTIVO DE FISCALIZACION</strong></p>

                                <Select
                                    fullWidth
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    label="MOTIVO DEL RECHAZO"
                                    size='small'
                                    value={observacionFizcalizacion}
                                    onChange={(e) => setObservacionFizcalizacion(e.target.value)}
                                >
                                    <MenuItem value="METRAJE">METRAJE</MenuItem>
                                    <MenuItem value="ESTANDAR(INSTALACIONES, CONSTRUCCION)">ESTANDAR(INSTALACIONES, CONSTRUCCION)</MenuItem>
                                    <MenuItem value="CAJA LLENA O OLT LLENA">CAJA LLENA O OLT LLENA</MenuItem>
                                    <MenuItem value="SOLICITADO">SOLICITADO</MenuItem>
                                    <MenuItem value="OTROS">OTROS</MenuItem>
                                </Select>
                            </Box>

                            {
                                observacionFizcalizacion === 'OTROS' ?

                                    <Box py={1}>
                                        <p style={{ fontSize: "12px" }}><strong>ESCRIBA EL MOTIVO</strong></p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={motivoOtros}
                                            onChange={(e) => setMotivoOtros(e.target.value)}
                                            size="small"
                                            fullWidth
                                        />
                                    </Box> : ''
                            }

                            <Box py={3}>
                                <p style={{ fontSize: "12px" }}><strong>METRAJE</strong></p>
                                <Stack direction="row"
                                    alignItems="center" justifyContent="center" spacing={8}>

                                    <TextField
                                        value={props.metraje}
                                        label='METRAJE UTILIZADO'
                                        size="small"
                                        type="number"
                                        fullWidth
                                    />

                                    {/* <TextField
                                        value={props.cableFinaliza}
                                        label='CABLE FINALIZA'
                                        size="small"
                                        type="number"
                                        fullWidth
                                    /> */}
                                </Stack>
                            </Box>

                            <Box py={1}>
                                <p style={{ fontSize: "12px" }}><strong>¿EL METRJAE UTILIZADO ES CORRECTO?</strong></p>
                                <FormControl fullWidth>
                                    <InputLabel></InputLabel>
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        size='small'
                                        value={metrajeCorrecto}
                                        onChange={(e) => setMetrajeCorrecto(e.target.value)}
                                    >
                                        <MenuItem value="SI">SI</MenuItem>
                                        <MenuItem value="NO">NO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            {
                                metrajeCorrecto === '' || metrajeCorrecto === 'SI' ? '' : <Box py={3}>
                                    <p style={{ fontSize: "12px" }}><strong>INGRESAR METRAJE CORRECTO</strong></p>
                                    <Stack direction="row"
                                        alignItems="center" justifyContent="center" spacing={8}>

                                        <TextField
                                            value={metrajeCorrectoText}
                                            onChange={(e) => setMetrajeCorrectoText(e.target.value)}
                                            label='METRAJE CORRECTO'
                                            size="small"
                                            type="number"
                                            fullWidth
                                        />

                                        {/* <TextField
                                            value={cableInicioFinaliza}
                                            onChange={(e) => setCableInicioFinaliza(e.target.value)}
                                            label='CABLE FINALIZA'
                                            size="small"
                                            type="number"
                                            fullWidth
                                        /> */}
                                    </Stack>
                                </Box>
                            }

                       <Box py={3}>
                               <p style={{ fontSize: "12px" }}><strong>COORDENADAS ACTUALES</strong></p>
                        <Stack direction="row"
                                    alignItems="center" justifyContent="center" spacing={8}>

                                    <TextField
                                        disabled
                                        label='LATITUD'
                                        type="number"
                                        onChange={(e) => setPosition({ ...position, lat: parseFloat(e.target.value) })}
                                        value={position.lat}
                                        size="small"
                                        fullWidth
                                    />

                                    <TextField
                                        disabled
                                        label='LONGITUD'
                                        type="number"
                                        onChange={(e) => setPosition({ ...position, lng: parseFloat(e.target.value) })}
                                        value={position.lng}
                                        size="small"
                                        fullWidth
                                    />
                                </Stack>
                            </Box>

                            <Box py={1}>
                                <p style={{ fontSize: "12px" }}><strong>OBSERVACION ADICIONAL</strong></p>
                                <TextField
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={observacionAdicional}
                                    onChange={(e) => changeObbservacionAdicional(e.target.value)}
                                    size="small"
                                    fullWidth
                                />
                            </Box>

                            <Box py={1}>
                                <p style={{ fontSize: "12px" }}><strong>TIPO DE INFRACCION</strong></p>
                                <Select
                                    fullWidth
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    size='small'
                                    label="Flota"
                                    value={tiposInfraccion}
                                    onChange={(e) => handleTipoInfraccion(e.target.value)}
                                >
                                    {tiposInfracciones && tiposInfracciones.map((row, key) => (
                                        <MenuItem value={row.tipo_infraccion}>{row.tipo_infraccion}</MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            {
                                tiposInfraccion === 'OTROS' ? <Box py={3}>
                                    <Stack direction="row"
                                        alignItems="center" justifyContent="center" spacing={8}>

                                        <TextField
                                            label='TIPO INFRACCION'
                                            value={tipoInfraccionOtros}
                                            onChange={(e) => changeTipoInfraccionOtros(e.target.value)}
                                            size="small"
                                            fullWidth
                                        />

                                        <FormControl fullWidth>
                                            <InputLabel>PORCENTAJE MULTA</InputLabel>
                                            <Select
                                                size="small"
                                                fullWidth
                                                value={valorPorcentajeOtros}
                                                onChange={(e) => setValorPorcentajeOtros(e.target.value)}
                                            >
                                                <MenuItem value="50">50</MenuItem>
                                                <MenuItem value="100">100</MenuItem>
                                                <MenuItem value="200">200</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                </Box> : <Box py={3}>
                                    <Stack direction="row"
                                        alignItems="center" justifyContent="center" spacing={8}>

                                        <TextField
                                            disabled
                                            label='PORCENTAJE MULTA'
                                            type="number"
                                            value={porcentajeInfraccion}
                                            size="small"
                                            fullWidth
                                        />
                                    </Stack>
                                </Box>
                            }

                            {/* </Grid> */}
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Caja abierta antes</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_caja_abierta_antes ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_caja_abierta_antes} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile1}
                                            estado={file1}
                                            nameFile="imagen_caja_abierta_antes"
                                        />}

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Caja abierta despues</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_caja_abierta_despues ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_caja_abierta_despues} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile2}
                                            estado={file2}
                                            nameFile="imagen_caja_abierta_despues"
                                        />}

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Potencia caja</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_potencia_caja ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_potencia_caja} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile3}
                                            estado={file3}
                                            nameFile="imagen_potencia_caja"
                                        />}
                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Codigo caja</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_codigo_caja ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_codigo_caja} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile4}
                                            estado={file4}
                                            nameFile="imagen_codigo_caja"
                                        />}
                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Equipo caja</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_equipo_caja ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_equipo_caja} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile5}
                                            estado={file5}
                                            nameFile="imagen_equipo_caja"
                                        />}

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={5}>
                                        <Typography>Serial equipo caja</Typography>
                                        <Divider />
                                    </Box>
                                    {dataPhotos[0] && dataPhotos[0].imagen_serial_equipo_caja ?
                                        <>
                                            <img width={100} src={dataPhotos[0].imagen_serial_equipo_caja} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <UpdateFileUploadFiscalizador
                                            instalacion={props}
                                            cambiarEstado={setFile6}
                                            estado={file6}
                                            nameFile="imagen_serial_equipo_caja"
                                        />}
                                    {/* <Box py={1}>
                                        <img style={{ width: "50%" }} src={viewImagenSerialEquipo} />
                                    </Box>
                                    <Button
                                        size="small"
                                        onChange={changeImagenSerialEquipo}
                                        variant="contained"
                                        component="label"
                                    >
                                        SERIAL EQUIPO
                                        <input
                                            type="file"
                                            hidden
                                        />
                                    </Button> */}

                                </Grid>

                            </Grid>

                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    {/* <Button onClick={() => crearPdf()}><strong>Crear PDF</strong></Button> */}
                    <Button onClick={() => onDataRechazoFiscalizacion()} size="small" variant="contained" color="error">
                        {" "}
                        RECHAZAR FISCALIZACION
                    </Button>
                    <Button size="small" disabled={
                        observacionFizcalizacion === ''
                        || metrajeCorrecto === ''
                        || file1 === ''
                        || file2 === ''
                        || file3 === ''
                        || file4 === ''
                        || file5 === ''
                        || file6 === ''
                        || tiposInfraccion === ''
                    } onClick={() => {
                        updateProducto()
                    }} variant="contained">
                        {" "}
                        ENVIAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* RECHAZAR FISCALIZACION */}

            <Dialog open={openRechazoFiscalizacion} fullWidth onClose={() => setOpenRechazoFiscalizacion(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>RECHAZAR FISCALIZACION DE: {props.nombres}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box py={2}>
                        <FormControl fullWidth>
                            {/* <Grid item xs={6}> */}
                            <Box py={1}>
                                <FormControl fullWidth>
                                    <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
                                    <Select
                                        label="MOTIVO DEL RECHAZO"

                                        value={observacionRechazoFizcalizacion}
                                        onChange={(e) => setObservacionRechazoFizcalizacion(e.target.value)}
                                    >
                                        <MenuItem value="ZONA PELIGROSA">ZONA PELIGROSA</MenuItem>
                                        <MenuItem value="TEMAS CLIMATICOS">TEMAS CLIMATICOS</MenuItem>
                                        <MenuItem value="CAJA LLENA O OLT LLENA">CAJA LLENA O OLT LLENA</MenuItem>
                                        <MenuItem value="SOLICITADO">SOLICITADO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => cancelrechazoInstalacion()}><strong>Cancelar</strong></Button>
                    <Button onClick={() => rechazarFiscalizacion()} size="small" variant="contained" color="error">
                        {" "}
                        RECHAZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/*  */}


            <Dialog open={openImagenesSubidas} onClose={() => setOpenImagenesSubidas(false)} maxWidth="xs">
                <DialogTitle>
                    IMAGEN SUBIDA CON EXITO
                </DialogTitle>
                <DialogActions>
                    <Button size="small" variant="contained" color='success' onClick={() => setOpenImagenesSubidas(false)}><strong>OK</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};