export const clientesReducers = (state = { clientes: [] }, action) => {
    switch (action.type) {
        case 'CLIENTES_LIST_REQUEST':
            return { loading: true }
        case 'CLIENTES_LIST_SUCCESS':
            return { loading: false, clientes: action.payload }
        case 'CLIENTES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const detailsClientesReducers = (state = {}, action) => {
    switch (action.type) {
        case 'CLIENT_DETAILS_REQUEST':
            return { loading: true }
        case 'CLIENT_DETAILS_SUCCESS':
            return { loading: false, client_details: action.payload }
        case 'CLIENT_DETAILS_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const configPlantillasReducers = (state = {}, action) => {
    switch (action.type) {
        case 'CONFIG_PLANTILLAS_REQUEST':
            return { loading: true }
        case 'CONFIG_PLANTILLAS_SUCCESS':
            return { loading: false, config_plantillas: action.payload }
        case 'CONFIG_PLANTILLAS__FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const detailsMikrotiksReducers = (state = {}, action) => {
    switch (action.type) {
        case 'MIKROTIK_DETAILS_REQUEST':
            return { loading: true }
        case 'MIKROTIK_DETAILS_SUCCESS':
            return { loading: false, mikrotik_details: action.payload }
        case 'MIKROTIP_DETAILS__FAIL':
            return { loading: true, error: action.payload }
        default:
            return state
    }
}
export const clienteServicioReducers = (state = { cliente_servicio: [] }, action) => {
    switch (action.type) {
        case 'CLIENT_SERVICE_REQUEST':
            return { loading: true }
        case 'CLIENT_SERVICE_SUCCESS':
            return { loading: false, cliente_servicio: action.payload }
        case 'CLIENT_SERVICE__FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}