import React, { useEffect, useState } from "react"
import { Box, Typography, CardContent, Card, LinearProgress, Grid, Container, Chip, Button, CardHeader } from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'



function Estado({ estado }) {
    let color
    let label
    if (estado === 1) {
        color = "success"
        label = 'ACTIVO'
    } else if (estado === 0) {
        color = "warning"
        label = 'SUSPENDIDO'
    } else if (estado === 3) {
        color = "error"
        label = 'RETIRADO'
    }
    return <Chip variant='outlined' size='small' label={label} color={color} />
}
export const ListCustomers = () => {
    const userAuth = useSelector((state) => state.userAuth)
    const { loading, userInfo } = userAuth
    const clientesList = useSelector((state) => state.clientesList)
    // const { clientes } = clientesList
    const [clientes, setClientes] = useState([]);
    const [data, setData] = useState();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 518400000))
    const [endDate, setEndDate] = useState(new Date())
    //
    const dispatch = useDispatch()
    useEffect(() => {
        if (clientes.length === 0) {
            // dispatch(listClientes())
            LoadData()
        }
    }, [])
    //
    const LoadData = () => {
        let requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
        console.log('hola')
        fetch("http://localhost:5000/sistema-gestion-intercommerce/us-central1/api/minerva/get_customer", requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                console.log(re)
                setClientes(re.response.customerList)
            })
            .catch(error => console.log('error', error));
    }
    // 
    const csvOptions = {
        delimiter: ';',
        fileName: `CLIENTES SOEI ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    const columns = [
        {
            field: 'customerId',
            valueGetter: (params) => {
                return params.row.customer.customerId;
            },
            headerName: 'CUSTOMER ID',
            width: 200
        },
        {
            field: 'firstName',
            valueGetter: (params) => {
                return params.row.customer.lastName;
            },
            headerName: 'NOMBRES Y APELLIDOS',
            width: 200
        },
        {
            field: 'accountId',
            valueGetter: (params) => {
                return params.row.customerAccount.accountId;
            },
            headerName: 'ACCOUNT ID',
            width: 125
        },
        {
            field: 'status',
            valueGetter: (params) => {
                return params.row.customer.status;
            },
            headerName: 'STATUS',
            width: 75
        },
        {
            field: 'effectiveDt',
            valueGetter: (params) => {
                return params.row.customerAccount.effectiveDt;
            },
            headerName: 'EFFECTIVE DT',
            width: 150
        },
        {
            field: 'channelLineupName',
            valueGetter: (params) => {
                return params.row.customer.channelLineupName;
            },
            headerName: 'CHANNEL LINE UP',
            width: 200
        },
    ]
    
    return (
        <>
            <Card>
                <button onClick={() => LoadData()}>hola</button>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={clientes}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        getRowId={(row) => row.customerAccount.accountId}
                        hideFooter
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        onStateChange={(state) => {
                        }}
                    />
                </Box>
            </Card>
        </>
    )
}
