import {
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse, Hidden, Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES,
    GridToolbarExport
} from '@mui/x-data-grid';
import { ViewTicketAprobadoInstaladorOperaciones } from "../../../operaciones/TicketsInstalacionOperaciones/ViewTicketAprobadoInstaladorOperaciones";
import { ViewTicketAprobadoSinMaterialesInstaladorOperaciones } from "../../../operaciones/TicketsInstalacionOperaciones/ViewTicketAprobadoSinMaterialesInstaladorOperaciones";

export const TicketsAprobadosPlaneacion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("tickets").where('estado', '==', 1);

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })

    };

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    /* const LoadData = (startAfterDoc, persistMessages = []) => {
  
      let ref = db.collection("productos").where("estado_producto", "==", 0);
  
      //Si tiene algun doc anterior se agrega acontinuacion
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
  
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("fecha_creacion_producto", '>=', startfulldate)
        ref = ref.where("fecha_creacion_producto", '<=', endfulldate)
  
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
          setData(data)
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
          setIsLastPage(totalCount < 1)
        })
      }
    }; */

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    function Estado({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 0) {
            name = "EN ESPERA";
            color = "warning";
        } else if (estado === 1) {
            name = "REALIZADA";
            color = "success";
        }
        return <Chip label={name} color={color} variant='outlined' size="small" />;
    }

    const columns = [
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="RESPONDIDO" color="success" />
            }
        },
        {
            field: 'visita_realizada', headerName: 'VISITA REALIZADA', width: 150,
            renderCell: (params) => {
                return <Estado
                    estado={params.row.visita_realizada}
                />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 120 },
        { field: 'correo', headerName: 'CORREO', width: 220 },
        { field: 'direccion', headerName: 'DIRECCION', width: 350 },
        { field: 'zona', headerName: 'ZONA', width: 350 },
        { field: 'coordenadas', headerName: 'COORDENADAS', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 130 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        { field: 'flota', headerName: 'FLOTA', width: 100 },
        { field: 'lider_flota', headerName: 'LIDER', width: 280 },
        { field: 'auxiliar_flota', headerName: 'AUXILIAR', width: 250 },
        {
            field: 'fecha_asignacion_flota', headerName: 'FECHA RESPUESTA', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'usuario_que_asigno_flota', headerName: 'USUARIO QUE RESPONDIO', width: 250 },
        /* {
            field: '2', headerName: 'CERRAR TICKET', width: 150,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.visita_realizada === 1 ?
                                <>
                                    {
                                        params.row.requiere_materiales === 0 ? <ViewTicketAprobadoInstaladorOperaciones props={params.row} /> : ''
                                    }
                                    {
                                        params.row.requiere_materiales === 1 ? <ViewTicketAprobadoSinMaterialesInstaladorOperaciones props={params.row} /> : ''
                                    }
                                </>
                                : ''
                        }

                    </>
                )
            }
        }, */

    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarExport /> */}
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {

                    CODIGO_PRODUCTO: doc.codigo_producto,

                    DESCRIPCION: doc.descripcion_producto,

                    CATEGORIA: doc.categoria_producto,

                    SUBCATEGORIA: doc.subcategoria_producto,

                    PRESENTACION: doc.presentacion_producto,

                    MARCA: doc.marca_producto,

                    MODELO: doc.modelo_producto,

                    UNIDAD_MEDIDA: doc.unidad_Medida_Producto,

                    COSTO_ESTANDAR: doc.costo_estandar_producto,

                    PRECIO_COMPRA: doc.precio_compra_producto,

                    PRECIO_VENTA: doc.precio_venta_producto,

                    CANTIDAD: doc.cantidad_producto,

                    ESTADO_PRODUCTO: doc.estado_producto === 0 ? 'ACTIVO' : 'INACTIVO',

                    STOCK_MINIMO: doc.stock_minimo_producto,

                    STOCK_SEGURIDAD: doc.stock_seguridad_producto,

                    STOCK_MAXIMO: doc.stock_maximo_producto,

                    BODEGA: doc.bodega,

                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "productos");
        XLSX.writeFile(wb, `PRODUCTOS_${date1}.xlsx`)
    }

    const onChange2 = () => {
        LoadData();
    };

    return (
        <>
            <Box px={2} py={2}>
                <Paper>
                    <Box px={2} py={2} sx={{ height: 850, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}

                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

        </>
    );
};