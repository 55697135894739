import React, { useState, useEffect } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { db } from '../firebase'
export const ClientesMaps = () => {
    const [locations, setLocations] = useState([])
    useEffect(() => {
        loadsolicitudes()
    }, [])
    const mapStyles = {
        height: '400px',
        width: '100%'
    };
    // Cambia esto a tus propias credenciales de Google Maps
    const apiKey = 'AIzaSyBjtUvCBhC9tio12UcIa4HBT8hPqUBjYl0';
    // Centro inicial del mapa (puede ser ajustado según tus necesidades)
    const defaultCenter = {
        lat: 37.7749,
        lng: -122.4194
    };

    const loadsolicitudes = () => {
        db.collection('solicitudes').get()
            .then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage)
                setLocations(arrayMessage)

            })
    }

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={8}
                center={defaultCenter}
            >
                {
                locations.coordenadas && locations.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.coordenadas.lat, lng: location.coordenadas.lng }}
                    />
                ))}
            </GoogleMap>
        </LoadScript>
    )
}