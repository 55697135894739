import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GroupsIcon from '@mui/icons-material/Groups';

export const ListGrupoVentas = () => {
    const location = useLocation();
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [openVent, setOpenVent] = useState(false)

    return (
        <>
            {userInfo && userInfo.uid === 'lZVep1v7zQSJ1J3SLT4HX213FMK2' || userInfo.rol === 0 ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon >
                        <GroupsIcon  />
                    </ListItemIcon>
                    <ListItemText primary="Grupos" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton selected={location.pathname === '/grupos/grupo-ventas/'} component={RouterLink} to={`grupos/grupo-ventas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion ventas grupos" />
                        </ListItemButton>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}