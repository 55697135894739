import {
    Box,
    Button,
    Container,
    Grid
} from '@mui/material'
import React from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack } from '@mui/system';
import logo1 from '../../../assets/img/contrato/image005.jpg'


export const Anexo5 = () => {
    const container = React.useRef(null);

    const Plantilla1 = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                </div>
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                </p>
            </div >
        )
    }

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: Plantilla1,
            paperSize: "A4",
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.6,
            fileName: `ANEXO 5`,
        });

    };


    return (
        <>
            <Container>
                <div className="example-config">
                    <Button
                        onClick={exportPDFWithMethod}
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        color="error" size='small' variant="outlined"
                        startIcon={<PictureAsPdfIcon />}>
                        <strong>PDF</strong>
                    </Button>
                </div>
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth="md" >
                            <Grid container spacing={1} sx={{ fontSize: "12px", marginTop: "5px" }}>
                                <Grid item xs={12} sx={{ marginBottom: "10px", fontSize: "16px" }}>
                                    <p align="center"><strong>ANEXO 5 <br />
                                        AUTORIZACIÓN DE DÉBITO</strong></p>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                    <p>Lugar y fecha: ________________________</p>
                                    <br />
                                    <p align="justify">Por medio del presente y en virtud del contrato
                                        de servicios que tengo suscrito con la empresa
                                        __________________________________________________ (Cliente cobrador),
                                        autorizo(amos) de manera incondicional e irrevocable al BANCO
                                        PICHINCHA C.A. (Institución cobradora), debitar de mi(nuestra)
                                        Cuenta Ahorros o Corriente N° _____________________________
                                        del  Banco o  Institución Financiera
                                        ___________________________________________________, de la
                                        cual soy(somos) titular(es), los valores correspondientes
                                        por dichos servicios.<br /><br />
                                        Me(nos) comprometo(comprometemos) a mantener los fondos necesarios,
                                        para que el BANCO PICHINCHA C.A., pueda realizar los débitos de la
                                        cuenta en mención, de manera efectiva.<br /><br />
                                    </p>
                                    <p>Atentamente, <br /> ___________________________ <br /><br /><br /><br />
                                        En caso que sea persona natural:<br /><br /><br />
                                        Nombre: ________________________________________________________<br /><br />
                                        CI., PA: _________________________________________________________<br /><br />
                                        En caso que sea persona jurídica:<br /><br /><br />
                                        Nombre: ___________________________________________________________<br /><br />
                                        CI., PA: _____________________________________________________________<br /><br />
                                        Representante Legal<br /><br />
                                        Nombre de la empresa: _______________________________________________<br /><br />
                                        RUC: ______________________________________________________________<br /><br />
                                    </p>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </PDFExport>
            </Container>

        </>
    )
}
