import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TvIcon from '@mui/icons-material/Tv';
export const ListIPTV = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [openVent, setOpenVent] = useState(false)
    return (
        userInfo.rol === 0 ? <>
    
            <ListItemButton onClick={() => setOpenVent(!openVent)}>
                <ListItemIcon>
                    <TvIcon  />
                </ListItemIcon>
                <ListItemText primary="IPTV" />
                {openVent ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openVent} timeout="auto" unmountOnExit >
                <List component="div" disablePadding dense>

                    <ListItemButton component={RouterLink} to={`ipvt/solicitud`}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="IPVT de Sistema" />
                    </ListItemButton>
                </List>
            </Collapse>

        </> : ''
    )
}