import {
    Box,
    Typography,
    Button,
    Paper,
    IconButton,
    Chip,
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import { EditUser } from "./EditUser";
import { db } from '../../../firebase';
import Update from '../../../'
import UpdatePago from "./new_user/UpdatePago";
export const ListClientsPage = () => {
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData();
    }, [startDate]);
    //
    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("nms/clients/clients/")
        ref.onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                const data = [
                    ...querySnapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(arrayMessage)
                setLoading(false)
            });
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const onChange2 = () => {
        LoadData();
    };
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    function Estado2({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 0) {
            name = "NO PAGADO";
            color = "info";
        } else if (estado === 1) {
            name = "PAGADO";
            color = "success";
        } else if (estado === 2) {
            name = "PAGADO";
            color = "secondary";
        }
        return <Chip variant='outlined' label={name} size='small' color={color} />;
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'username', headerName: 'USERNAME', width: 150 },
  
        { field: 'ipv4', headerName: 'ipv4', width: 150 },
        { field: 'ipv6', headerName: 'ipv6', width: 150 },
        {
            field: 'estado_pagado', headerName: 'ESTADO PAGO', width: 175,
            renderCell: (params) => {
                return <Estado2 estado={params.row.estado_pagado} />
            }
        },
        {
            field: '2', headerName: 'ESTADO 2', width: 175,
            renderCell: (params) => {
                return <UpdatePago props={params.row} />
            }
        }
    ];
    return (
        <>
   
            <Box pl={1} pt={1} display="flex" alignItems="center">
                <Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                onClick={onChange2}
                            >
                                Aplicar busqueda
                            </Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        loading={loading}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {

                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
