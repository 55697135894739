import React, { useState, useRef, useEffect } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const ListExtra = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'extras']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <AccountBalanceIcon  />
                    </ListItemIcon>
                    <ListItemText primary="Extras" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        
                         {userAuth?.roles?.find(role => ['admin']?.includes(role)) ? <ListItemButton component={RouterLink} to={`extras/log-zimbra/`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Logs Zimbra" />
                            </ListItemButton> : ''}
                        
                        {userAuth?.roles?.find(role => ['admin','conciliador']?.includes(role)) ? <ListItemButton component={RouterLink} to={`extras/conciliacion-banco/`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Conciliacion Banco" />
                            </ListItemButton> : '' }
                        
                            {userAuth?.roles?.find(role => ['admin','conciliador_pichincha']?.includes(role)) ? <ListItemButton component={RouterLink} to={`extras/cobranza-pichincha/`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Recaudacion Pichincha" />
                            </ListItemButton> : '' }
                        
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}