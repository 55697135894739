import { Typography, Box, Tabs, Tab, Card, CardContent } from '@mui/material';
import React, { useState } from 'react'
import { UploadBox } from '../../../components/operations/UploadBox';
import { ListsBox } from '../../../components/operations/ListsBox';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const CajasPage = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box px={2}>
                <Box py={2} pl={2}>
                    <Typography variant='h5' component='h1'><strong>Cajas</strong></Typography>
                </Box>
                <Card variant="outlined">
                    <CardContent>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Cajas" {...tabs(0)} />
                            <Tab label="Nueva caja" {...tabs(1)} />
                            {/* <Tab label="Buscar Caja" {...tabs(2)} /> */}
                            {/* <Tab label="Buscar Series" {...tabs(2)} /> */}
                            {/* <Tab label="Autocomplete" {...tabs(3)} /> */}
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <ListsBox />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <UploadBox />
                        </TabPanel>
                        {/* <TabPanel value={value} index={2}>
                        <SearchCajas />
                    </TabPanel> */}
                        {/* <TabPanel value={value} index={3}>
                        <PantallaIndependienteSeriesList />
                    </TabPanel> */}
                        {/* <TabPanel value={value} index={3}>
                        <AutocompleteCajas />
                    </TabPanel> */}
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}