import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
    Grid
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export const UpdateZona = ({ props }) => {

    const [open, setOpen] = useState(false)

    const [codigo, setCodigo] = useState(props.codigo);

    const [zona, setZona] = useState(props.zona);

    const [nombre, setNombre] = useState(props.nombre)
    const [ubicacion, setUbicacion] = useState(props.ubicacion);

    const handleSubmit = async () => {

        await db
            .collection("zonas").doc(props.id).update({
                zona: zona,
                nombre: nombre,
                ubicacion: ubicacion,
                codigo: codigo,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                setOpen(false)
                Swal.fire({ icon: "success", title: "Zona creada con exito" });
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    };

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => setOpen(true)} color='secondary'>
                    <EditIcon />
                </Button>
            </Tooltip>


            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>EDICION DE: {props.nombre}</DialogTitle>

                <DialogContent>
                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={7}>
                                <Grid item xs={10} md={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={codigo}
                                        /* onChange={(e) => setCodigo(e.target.value)} */
                                        name="codigo"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value)}
                                            name="nombre"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>


                                {/* *********************************** */}
                                <Grid item xs={10} md={6}>


                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ZONA:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={zona}
                                        /* onChange={(e) => setZona(e.target.value)} */
                                        name="zona"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>UBICACION:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ubicacion}
                                            onChange={(e) => setUbicacion(e.target.value)}
                                            name="ubicacion"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                </Grid>

                            </Grid>

                        </Box>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="warning" size="small" onClick={() => setOpen(false)}>Regresar</Button>
                    <Button onClick={() => handleSubmit()}
                        disabled={codigo === ''
                            || nombre === ''
                            || ubicacion === ''
                            || zona === ''}
                        variant="contained"
                        color="secondary"
                        size="small"
                    >ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>



        </>
    );
};
