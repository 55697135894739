
import React, { useState } from 'react'
import { Stack, CardContent, Box, Card, Button, TextField, FormLabel } from '@mui/material';
import { db } from '../../firebase'
export const BtnSoporte1 = ({ props }) => {
    const [contrato, setContrato] = useState('');
    const [loading, setLoading] = useState(false);
    const regresar = () => {
        setLoading(true)
        db.collection("agenda").doc(`${contrato}`).get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data()
                    if (data.estado !== 0) {
                        console.log('Se regreso a pre agenda')
                        db.collection("agenda").doc(`${contrato}`).update({
                            estado: 0,
                        })
                        setLoading(false)
                        alert('Se regreso contrato a pre agenda')
                        setContrato('')
                    } else {
                        setLoading(false)
                        alert('Este contrato ya esta en pre agenda')
                    }
                } else {
                    setLoading(false)
                    alert('Este contrato no existe')
                }
            })
    }
    const verpago = () => {
        db.collection("agenda").doc(`${contrato}`).get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data()
                    console.log(data, ' data 0')
                    if (data.id_factura) {
                        let requestOptions = {
                            method: "GET"
                        }
                        fetch(`http://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?id=${data.id_factura}`,
                            requestOptions)
                            .then((response) => response.text())
                            .then((result) => {
                                const re = JSON.parse(result);

                                if (re.length > 0) {
                                    const dataF = re[0];

                                    if (dataF.estado === "pagado") {
                                        db.collection("agenda").doc(`${contrato}`).update({
                                            estado_pagado: 2
                                        })
                                        db.collection("tecnicos").doc(`${contrato}`).update({
                                            estado_pagado: 2
                                        })
                                        setLoading(false)
                                        alert('Contrato pagado')
                                        setContrato('')
                                    }
                                    if (dataF.estado === "No pagado") {
                                        alert('Este contrato no esta pagado')
                                    }
                                }
                            })
                    } else {
                        setLoading(false)
                        alert('No se le ha generado factura a este contrato')
                    }
                } else {
                    setLoading(false)
                    alert('Este contrato no existe')
                }
            })
    }
    return (
        <>
            <Box pt={5}></Box>

            <Card variant='outlined'>
                <CardContent>
                    <FormLabel>Numero de Contrato</FormLabel>
                    <TextField
                        size='small'
                        value={contrato}
                        onChange={(e) => setContrato(e.target.value)}
                    />
                    <Box pt={5}></Box>
                    <Stack direction="row" spacing={2}>
                        <Button
                            disabled={loading}
                            onClick={() => regresar()}
                            variant='contained'>
                            Regresar PreAgendamiento
                        </Button>
                        <Button
                            onClick={() => verpago()}
                            variant='contained'
                        >
                            Consultar Pago
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </>
    )
}