/* import React, { useState, useRef, useEffect } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import ExpandLess from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const ListInventario = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'inventario']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inventario" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`inventario/gestionInventario/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Materiales Utilizados" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/productos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/categorias/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Categorias" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/subCategorias/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="SubCategorias" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/modeloProductos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Modelo Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/marcaProductos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Marca Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/unidadMedidaProducto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Unidad Medida Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/presentacion/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Presentacion Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/bodega/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Bodegas" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/ubicaciones/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ubicaciones" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/motivo-ingreso-producto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Motivos Ingreso Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/motivo-egreso-producto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Motivos Egreso Productos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/ingresoProducto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ingreso de Producto" />
                        </ListItemButton>
                        
                        <ListItemButton component={RouterLink} to={`inventario/egresoProducto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Egreso de Producto" />
                        </ListItemButton>


                        <ListItemButton 
                            component={RouterLink} 
                            to={`inventario/solicitud`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Inventario" />
                        </ListItemButton>
                      

                        <ListItemButton 
                            component={RouterLink} 
                            to={`inventario/compras/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Compras" />
                        </ListItemButton>

                        <ListItemButton 
                            component={RouterLink} 
                            to={`inventario/recepcionMercaderia`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Recepcion de Mercaderia" />
                        </ListItemButton>

                        <ListItemButton 
                            component={RouterLink} 
                            to={`inventario/transferenciaMercaderia`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Transferencia de Mercaderia" />
                        </ListItemButton>

                        <ListItemButton 
                            component={RouterLink} 
                            to={`inventario/tipo`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tipos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`Instaladores/GestionInstaladores/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Tecnicos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`clientes/gestionClientes/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Clientes" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/reporte-de-movimientos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reporte de Movimientos" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/reporte-stock/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reporte Stock" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/carga-inicial/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga Inicial" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/carga-series/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga Series" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`inventario/kardex/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Kardex" />
                        </ListItemButton>
                        
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
} */

import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const ListInventario = () => {

    const userAuth = useSelector((state) => state.userAuth.userInfo)

    const [openVent, setOpenVent] = useState(false)

    const [open7, setOpen7] = useState(false);

    const [openParametrosDeInventario, setOpenParametrosDeInventario] = useState(false);

    const [openSolicitudes, setOpenSolicitudes] = useState(false);

    const [openProductos, setOpenProductos] = useState(false);

    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'inventario']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inventario" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {/* <ListItemButton component={RouterLink} to={`inventario/gestionInventario/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Materiales Utilizados" />
                        </ListItemButton> */}
                        <ListItemButton onClick={() => setOpenParametrosDeInventario(!openParametrosDeInventario)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros de inventario" />
                            {openParametrosDeInventario ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openParametrosDeInventario} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Categorias'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/categorias`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Subcategorias'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/subCategorias`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Modelo Productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/modelo-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Marca Productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/marca-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Unidad Medida Productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/unidad-medida-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Presentacion Productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/presentacion-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Bodegas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/bodegas-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Ubicaciones'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/ubicaciones-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Departamentos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/departamentos-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Motivos Ingreso'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/motivo-ingreso-producto`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Motivos Egreso'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/motivo-egreso-producto`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Tipos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/tipos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Crear kits'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/parametros-de-inventario/crear-kits`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                        {/* SOLCITUDES */}

                        <ListItemButton onClick={() => setOpenSolicitudes(!openSolicitudes)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitudes" />
                            {openSolicitudes ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSolicitudes} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Solicitud de Compras'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/solicitudes/compras/0`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Solicitud de Inventario'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/solicitudes/solicitud-inventario`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            {/* <List component="div" disablePadding dense>
                                {['Solicitudes de Materiales'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/solicitudes/solicitudes-de-materiales`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List> */}

                        </Collapse>

                        {/* PRODUCTOS */}

                        <ListItemButton onClick={() => setOpenProductos(!openProductos)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Productos" />
                            {openProductos ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openProductos} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Actualizar fecha'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/actualizar-fecha`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Ingreso de productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/ingreso-de-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Egreso de productos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/egreso-de-productos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Recepcion de Mercaderia'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/recepcion-de-mercaderia`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Egreso flotas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/egreso-flotas`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <ListItemButton onClick={() => setOpen7(!open7)}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Transferencias" />
                                {open7 ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={open7} timeout="auto" unmountOnExit>

                                <List component="div" disablePadding dense>
                                    {['Transferir productos'].map((text, index) => (
                                        <ListItemButton
                                            key={text}
                                            component={RouterLink} to={`/inventario/productos/transferencias/transferir-productos`}
                                        >
                                            <ListItemIcon
                                                style={{ marginLeft: 25 }}
                                            >
                                            </ListItemIcon>
                                            <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                        </ListItemButton>
                                    ))}
                                </List>

                                <List component="div" disablePadding dense>
                                    {['Aprobar transferencias'].map((text, index) => (
                                        <ListItemButton
                                            key={text}
                                            component={RouterLink} to={`/inventario/productos/transferencias/aprobar-transferencias`}
                                        >
                                            <ListItemIcon
                                                style={{ marginLeft: 25 }}
                                            >
                                            </ListItemIcon>
                                            <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                        </ListItemButton>
                                    ))}
                                </List>

                                <List component="div" disablePadding dense>
                                    {['Recepcion de transferencias'].map((text, index) => (
                                        <ListItemButton
                                            key={text}
                                            component={RouterLink} to={`/inventario/productos/transferencias/recepcion-transferencias`}
                                        >
                                            <ListItemIcon
                                                style={{ marginLeft: 25 }}
                                            >
                                            </ListItemIcon>
                                            <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                        </ListItemButton>
                                    ))}
                                </List>

                            </Collapse>

                            <List component="div" disablePadding dense>
                                {['Carga Inicial'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/carga-inicial`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Carga series'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/productos/carga-series`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                        {/* DOCUMENTACION */}

                        <ListItemButton onClick={() => setOpenDocumentacion(!openDocumentacion)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Documentacion" />
                            {openDocumentacion ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openDocumentacion} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Reporte de Stock'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/reporte-de-stock`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Reporte de Stock Historico'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/reporte-de-stock-historico`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Reporte de Movimientos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/reporte-de-movimientos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Kardex'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/kardex`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Materiales tecnicos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/materiales-tecnicos`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            {/* <List component="div" disablePadding dense>
                                {['Kardex instalaciones'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`inventario/documentacion/kardex-instalaciones`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List> */}

                        </Collapse>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}