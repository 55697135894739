import {
    Box,
    Button,
    Container,
    Grid
} from '@mui/material'
import React from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Stack } from '@mui/system';
import logo1 from '../../../assets/img/contrato/image005.jpg'

export const Anexo4 = () => {
    const container = React.useRef(null);

    const Plantilla1 = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                </div>
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                </p>
            </div >
        )
    }

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: Plantilla1,
            paperSize: "A4",
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `ANEXO 4`,
        });

    };


    return (
        <>
            <Container>
                <div className="example-config">
                    <Button
                        onClick={exportPDFWithMethod}
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                        color="error" size='small' variant="outlined"
                        startIcon={<PictureAsPdfIcon />}>
                        <strong>PDF</strong>
                    </Button>
                </div>
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth="md" >
                            <Grid container spacing={1} sx={{ fontSize: "12px", marginTop: "5px" }}>
                                <Grid item xs={12} sx={{ marginBottom: "10px", fontSize: "16px" }}>
                                    <p align="center"><strong>ANEXO 4. ACTA DE ENTREGA - RECEPCION</strong></p>
                                </Grid>

                                <Grid item xs={5} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                    <p align="center"><strong>CLIENTE:</strong></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                    <p align="center"><strong>ORDEN DE TRABAJO:</strong></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>NOMBRE:</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>NUMERO DE ORDEN</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>C.I./R.U.C.:</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>FECHA</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>TELEFONO CONTACTO</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>ESTADO</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>E MAIL</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>EJECUTIVO</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>PLAN</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>DIRECCION DE INSTALACION</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>SERVICIOS</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={12} sx={{ marginBottom: "10px" }} />


                                <Grid item xs={5} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                    <p align="center"><strong>EQUIPO</strong></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={5} sx={{ backgroundColor: '#a6a4a4', border: 1, marginBottom: "10px" }}>
                                    <p align="center"><strong>ESTADO DE LA INSTALACION</strong></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>NOMBRE:</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>NUMERO DE ORDEN</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>MARCA:</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>TOMA DE CORRIENTE</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>ESTADO</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"><strong>OBSERVACIONES</strong></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={2} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>
                                <Grid item xs={3} sx={{ border: 1 }}>
                                    <p align="center"></p>
                                </Grid>

                            </Grid>
                            <br />
                            <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '12px' }}>
                                <Grid item xs={5}>
                                    <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                        <p align="center" ><strong>
                                            ING. JUAN CARLOS GONZALEZ ORTEGA <br />
                                            REPRESENTANTE DE INTERCOMMERCE S:A. <br />
                                            PERMISIONARIO <br />
                                            RUC: 0992988061001 <br />
                                        </strong></p>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                                        <p align="center" ><strong>
                                            ABONADO <br />
                                            NOMBRE:  <br />
                                            CRI/RUC: <br /><br />
                                        </strong></p>
                                    </Box>
                                </Grid>
                            </Grid >
                        </Container>
                    </div>
                </PDFExport>
            </Container>

        </>
    )
}
