import { Paper, Collapse, Button, Typography, Box, Tab } from '@mui/material'
import Chart from "react-apexcharts";

import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';


export const ReporteChurn = () => {

    const [categorias, setCategorias] = useState([])
    const [valores, setValores] = useState([])

    const [valores2, setValores2] = useState([])
    const current = new Date();
    current.setHours(0, 0, 0, 0)
    const [startDate, setStartDate] = useState(new Date(current.getTime() - (14 * 86400000)))
    const [endDate, setEndDate] = useState(new Date())


    const [open2, setOpen2] = useState(false)


    if (startDate) startDate.setHours(0, 0, 0, 0)
    if (endDate) endDate.setHours(23, 59, 0, 0)


    const [startDate2, setStartDate2] = useState(startDate)
    const [endDate2, setEndDate2] = useState(endDate)

    const onChange = (dates) => {
        const [start, end] = dates;

        if (start) start.setHours(0, 0, 0, 0)
        if (end) end.setHours(23, 59, 0, 0)
        setStartDate(start);
        setEndDate(end);
    }


    const local = {
        defaultLocale: 'es',
        locales: [{
            name: 'es',
            options: {
                shortMonths: ['Ene', 'Feb', 'Mar', 'Abr',
                    'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                months: ['Enero', 'Febrero', 'Marzo',
                    'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
                    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            }
        }],
    }
    function options() {
        return {
            chart: {
                ...local,
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false,
                },
            },
            legend: {
                show: false,
            },
            noData: {
                text: 'CARGANDO...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '20px',
                }
            },

            // zoom: {
            //     enabled: false,
            //     autoScaleYaxis: true
            // },

            // dataLabels: {
            //     enabled: true,
            //     enabledOnSeries: [1],
            //     offsetY: -20,
            //     style: {
            //         fontSize: '12px',
            //         colors: ["#304758"]
            //     }
            // },
            stroke: {
                curve: 'smooth'
            },
            grid: {
                padding: {
                    right: 30,
                    left: 20
                }
            },
            xaxis: {
                type: "datetime",
                categories: categorias,
                min: startDate2.getTime(),
                max: endDate2.getTime(),
                labels: {
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM',
                        day: 'dd MMM',
                        hour: 'HH:mm'
                    }
                }

            },
            tooltip: {
                x: {
                    format: 'dd MMMM yyyy'
                }
            }
        }
    };

    function options2() {
        return {
            chart: {
                ...local,
                height: 350,
                type: 'bar',
            },
            noData: {
                text: 'CARGANDO...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '20px',
                }
            },
            plotOptions: {
                bar: {
                    // borderRadius: 10,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: false,
                // formatter: function (val) {
                //     return val + "%";
                // },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                // position: 'top',
                // axisBorder: {
                //     show: true
                // },
                // axisTicks: {
                //     show: false
                // },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            // yaxis: {
            //     axisBorder: {
            //         show: false
            //     },
            //     axisTicks: {
            //         show: false,
            //     },
            //     labels: {
            //         show: false,
            //         formatter: function (val) {
            //             return val + "%";
            //         }
            //     }

            // },
        }
    }

    function options3() {
        return {
            chart: {
                ...local,
                height: 350,
                type: 'bar',
            },
            noData: {
                text: 'CARGANDO...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '20px',
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val === 0 ? '' : val;
                },
                offsetY: -20,
                style: {
                    // fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            fill: {
                colors: ['#F44336']
            }

        }
    }

    function options4() {
        return {
            chart: {
                ...local,
                height: 350,
                type: 'bar',
            },
            noData: {
                text: 'CARGANDO...',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '20px',
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val === 0 ? '' : val;
                },
                offsetY: -20,
                style: {
                    // fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: [...valores2.map((item) => {
                    return item.name
                })],
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            fill: {
                colors: ['#4576b5']
            }

        }
    }

    useEffect(() => {
        cargarRetirados()
        cargarMeses()
    }, []);

    function cargarRetirados() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiRetirados/?diario`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                let fechas = []
                let valores = []
                for (const el of re) {
                    fechas.push(el.fecha)
                    valores.push(parseInt(el.cantidad))
                }
                setValores([{ name: '# Retirados', data: valores, total: valores.reduce((a, b) => a + b, 0) }])
                setCategorias(fechas)

            }).catch(error => console.log('error', error));
    }

    function cargarMeses() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiRetirados/?mes`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                let anios = []
                for (const el of re) {
                    if (!anios[el.anio]) {
                        anios[el.anio] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }
                    anios[el.anio][parseInt(el.mes) - 1] = parseInt(el.cantidad)
                }

                let anios2 = Object.keys(anios)
                anios2 = anios2.sort((a, b) => parseInt(a.sector) < parseInt(b.sector) ? 1 : -1)
                let lista = []
                for (const ele of anios2) {
                    lista.push({
                        name: ele,
                        data: anios[ele],
                        total: anios[ele].reduce((a, b) => a + b, 0)
                    })
                }
                setValores2(lista)

            }).catch(error => console.log('error', error));
    }


    const [tab, setTab] = useState('0');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Typography sx={{
                    fontSize: '18', display:
                        'inline-block', width: '50%'
                }}>
                    <strong>Reporte de Retirados</strong></Typography>
                <br />
                <Box display="flex" alignItems="center">
                    <Button variant="contained" color='warning' onClick={() => setOpen2(!open2)}>
                        Buscar por fecha
                    </Button>
                </Box>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                    <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />

                    <Button
                        color='success'
                        variant="contained"
                        onClick={() => {
                            setStartDate2(startDate)
                            setEndDate2(endDate)
                        }}
                    >
                        Aplicar busqueda
                    </Button>
                </Collapse>
                <br />
                <Typography variant="button">
                    <strong>Desde: </strong>
                    {startDate2 && moment(startDate2.toString()).format('DD/MM/YYYY')}
                </Typography >
                <Typography variant="button" style={{ paddingLeft: 10 }}>
                    <strong>Hasta: </strong>
                    {endDate2 && moment(endDate2.toString()).format('DD/MM/YYYY')}
                </Typography>

                <Chart
                    options={options()}
                    series={valores[0] ? [{ ...valores[0], type: 'line' }, { ...valores[0], type: 'column' }] : []}
                    height={385}
                />
            </Paper >
            <br />
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Typography sx={{
                    fontSize: '16px', display:
                        'inline-block', width: '50%'
                }}>
                    <strong>Reporte Mensual</strong></Typography>
                <Chart type="bar"
                    options={options2()}
                    series={valores2}
                    height={385}
                />
            </Paper>
            <br />
            <Paper elevation={2} sx={{ padding: '10px' }}>
                {valores2 &&
                    <>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} variant="scrollable">
                                    {valores2.map((item, index) => (
                                        <Tab key={item.name} label={item.name} value={index + ''} />
                                    ))}
                                </TabList>
                            </Box>
                            {valores2.map((item, index) => (
                                <TabPanel key={item.name} value={index + ''}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>Usuarios Retirados en {item.name}</strong>
                                        <br />
                                        <strong>Total: {item.total} clientes</strong>
                                        <Chart type="bar"
                                            options={options3()}
                                            series={[item]}
                                            height={385}
                                        />
                                    </Paper>
                                </TabPanel>
                            ))}
                        </TabContext>
                    </>
                }
            </Paper>

            <br />
            <Paper elevation={2} sx={{ padding: '10px' }}>
                <Typography sx={{
                    fontSize: '16px',
                    display: 'inline-block', width: '50%'
                }}>
                    <strong>Reporte Anual</strong></Typography>
                <br />
                <Typography sx={{
                    fontSize: '16px',
                    display: 'inline-block', width: '50%'
                }}>
                    <strong>Total: {valores[0] ?
                        valores[0].total : ''} clientes</strong></Typography>
                <Chart type="bar"
                    options={options4()}
                    series={[{
                        name: 'Año', data: [...valores2.map((item) => {
                            return item.total
                        })]
                    }]}
                    height={385}
                />
            </Paper>


        </>
    )
}
