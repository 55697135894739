import React, { useEffect, useState } from "react";
import {
    Grid, Stack, TextField, Select, MenuItem, Card, FormControl,
    InputLabel, CardContent, Button, Box, Typography, Dialog, DialogContent
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { listsTipoNombreCuenta, listsNaturalezaCuenta, listsTiposCuentas, listsItemsEstadoFinanciero } from "../../../redux/actions/parametersActions";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { db, firebase, auth } from "../../../firebase";

import Swal from "sweetalert2";

export const NewPlanDeCuentasMayor = () => {

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsTipoNombreCuenta())
        dispatch(listsNaturalezaCuenta())
        dispatch(listsTiposCuentas())
        dispatch(listsItemsEstadoFinanciero())
        ultimoDato()
        loadNumCuenta()
    }, [dispatch]);

    const [nombreCuenta, setNombreCuenta] = useState('')

    const changeNombreCuenta = (e) => {
        setNombreCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [codigoCuenta, setCodigoCuenta] = useState('')

    const changeTipoNombreCuenta = (e) => {
        setTipoNombreCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const [codigoNombreDeCuenta, setCodigoNombreDeCuenta] = useState('')

    const changeCodigoNombreDeCuenta = (e) => {
        setCodigoNombreDeCuenta(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const tipoNombreCuentasList = useSelector(state => state.tipoNombreCuentasList);
    const { tipoNombreCuentas, loading } = tipoNombreCuentasList
    const [tipoNombreCuenta, setTipoNombreCuenta] = useState('')

    const handleDCodigoCuenta = (e) => {
        setCodigoCuenta(e)
    }

    const naturalezaCuentaList = useSelector(state => state.naturalezaCuentaList);
    const { naturalezaCuentas, loadingNaturalezaCuentas } = naturalezaCuentaList
    const [naturalezaCuenta, setNaturalezaCuenta] = useState('')

    const handleNaturalezaCuenta = (e) => {
        setNaturalezaCuenta(e)
    }

    const tiposCuentasList = useSelector(state => state.tiposCuentasList);
    const { tipoCuentas, loadingTipoCuentas } = tiposCuentasList
    const [tipoCuenta, setTipoCuenta] = useState('')

    const handleTipoCuenta = (e) => {
        setTipoCuenta(e)
    }

    const itemsEtsadoFinancierosList = useSelector(state => state.itemsEtsadoFinancierosList);
    const { itemsEstadoFinancieros, loadingItemsEstadoFinanciero } = itemsEtsadoFinancierosList
    const [itemsEstadoFinanciero, setItemsEstadoFinanciero] = useState('')

    const handleItemsEstadoFinanciero = (e) => {
        setItemsEstadoFinanciero(e)
    }

    const [catalogoDeCostoObligatorio, setCatalogoDeCostoObligatorio] = useState(false);
    const [cuentaUtilidad, setCuentaUtilidad] = useState(false);
    const [cuentaGanancia, setCuentaGanancia] = useState(false);
    const [cuentaPerdida, setCuentaPerdida] = useState(false);
    const [agrupador101, setAgrupador101] = useState();

    const handleAgrupador101 = (e) => {
        setAgrupador101(e)
    }

    const [estado, setEstado] = useState(false);

    const [nivelDeCuenta, setNivelDeCuenta] = useState();

    const [numCuenta, setNumCuenta] = useState();

    const handleNivelDeCuenta = (e) => {
        setNivelDeCuenta(e)
    }

    const loadNumCuenta = () => {
        const ref = db.collection("plan_cuentas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_plan_cuenta + 1;
                setNumCuenta(numeroSolicitud)
            }
        })
    }

    const CrearCuentaMayor = async () => {
        const numeroCuenta = 'CTA-' + numCuenta;
        await db.collection('plan_cuentas').doc(numeroCuenta).set({
            codigo_contable: numeroCuenta,
            codigo_nombre_cuenta: codigoNombreDeCuenta.toUpperCase(),
            tipo_nombre_cuenta: tipoNombreCuenta,
            nombre_cuenta_contable: nombreCuenta.toUpperCase(),
            naturaleza: naturalezaCuenta,
            tipo_cuenta: tipoCuenta,
            item_estado_financiero: itemsEstadoFinanciero,
            catalogo_costo_obligatorio: catalogoDeCostoObligatorio,
            cuenta_ganancia: cuentaGanancia,
            cuenta_perdida: cuentaPerdida,
            agrupador_101: agrupador101.toUpperCase(),
            // estado: estado,
            nivel: numCuenta,
            codigo_padre: numPedido,
            codigo_cuenta: numPedido,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_creador_cuenta_mayor: userInfo.displayName,
            numeroNivel: numPedido,
            estado: 1,
            numero_plan_cuenta: numCuenta,
        })

        setOpen(false)

        /* await db.collection('cuenta_mayor').doc(id).collection('cuenta_menor').add({
            fecha_creacion_cuenta_menor: firebase.firestore.FieldValue.serverTimestamp(),
            numeroNivel: 0
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva cuenta mayor creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            }); */
    }

    const [numPedido, setNumPedido] = useState('')

    const ultimoDato = () => {
        const ref = db.collection("cuenta_mayor").orderBy("fecha_creacion_cuenta_mayor", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size >= 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                console.log(solicitudes, 'data ')
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroNivel + 1;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const [open, setOpen] = useState(false);

    return (
        <>
            <Button style={{ marginRight: "8px" }} size='small' onClick={() => setOpen(true)} variant="contained" startIcon={<AddCircleIcon />}>
                CUENTA MAYOR
            </Button>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                <DialogContent>
                    <Card sx={{ minWidth: 100 }}>
                        <CardContent>
                            <Box paddingBottom={2}>
                                <Typography >
                                    <strong>CUENTA MAYOR</strong>
                                </Typography >
                            </Box>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CODIGO NOMBRE DE CUENTA</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={codigoNombreDeCuenta}
                                            onChange={(e) => changeCodigoNombreDeCuenta(e.target.value)}
                                            name="descripcionProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NIVEL DE CUENTA</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={numCuenta}
                                            /* onChange={(e) => handleNivelDeCuenta(e.target.value)} */
                                            name="agrupador101"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>TIPO DE NOMBRE DE CUENTA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={tipoNombreCuenta}
                                            label="TIPO NOMBRE DE CUENTA"
                                            onChange={(e) => changeTipoNombreCuenta(e.target.value)}>
                                            {tipoNombreCuentas &&
                                                tipoNombreCuentas.map(item => (
                                                    <MenuItem key={item.tipo_nombre_cuenta} value={item.tipo_nombre_cuenta} >
                                                        {item.tipo_nombre_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>

                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NOMBRE DE CUENTA</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            value={nombreCuenta}
                                            onChange={(e) => changeNombreCuenta(e.target.value)}
                                            name="NOMBRE CUENTA"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>NATURALEZA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={naturalezaCuenta}
                                            onChange={(e) => handleNaturalezaCuenta(e.target.value)}>
                                            {naturalezaCuentas &&
                                                naturalezaCuentas.map(item => (
                                                    <MenuItem key={item.naturaleza_cuenta} value={item.naturaleza_cuenta} >
                                                        {item.naturaleza_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>

                                </Grid>

                                <Grid item xs={4}>

                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>TIPO CUENTA</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={tipoCuenta}
                                            onChange={(e) => handleTipoCuenta(e.target.value)}>
                                            {tipoCuentas &&
                                                tipoCuentas.map(item => (
                                                    <MenuItem key={item.tipo_cuenta} value={item.tipo_cuenta} >
                                                        {item.tipo_cuenta}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>ITEM DE ESTADO FINANCIERO</p>
                                        <Select sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            size="small"
                                            value={itemsEstadoFinanciero}
                                            onChange={(e) => handleItemsEstadoFinanciero(e.target.value)}>
                                            {itemsEstadoFinancieros &&
                                                itemsEstadoFinancieros.map(item => (
                                                    <MenuItem key={item.nombre_item_estado_financiero} value={item.nombre_item_estado_financiero} >
                                                        {item.nombre_item_estado_financiero}
                                                    </MenuItem>
                                                ))}
                                        </Select></FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CATALOGO DE COSTO OBLIGATORIO</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={catalogoDeCostoObligatorio}
                                            onChange={(e) => setCatalogoDeCostoObligatorio(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CUENTA GANANCIA</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={cuentaGanancia}
                                            onChange={(e) => setCuentaGanancia(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>CUENTA PERDIDA</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={cuentaPerdida}
                                            onChange={(e) => setCuentaPerdida(e.target.value)}
                                        >
                                            <MenuItem value="SI">SI</MenuItem>
                                            <MenuItem value="NO">NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <p style={{ fontSize: "12px" }}>AGRUPADOR 101</p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={agrupador101}
                                            onChange={(e) => handleAgrupador101(e.target.value)}
                                            name="agrupador101"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4}>
                                    <p style={{ fontSize: "12px" }}>ESTADO</p>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}
                                        >
                                            <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                            <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button onClick={() => setOpen(false)} color="error" size='small' style={{ margin: "10px" }} variant="contained" endIcon={<KeyboardArrowRightIcon />}>
                            <strong>SALIR</strong>
                        </Button>
                        <Button disabled={
                            !codigoNombreDeCuenta ||
                            !tipoNombreCuenta ||
                            !nombreCuenta ||
                            !naturalezaCuenta ||
                            !tipoCuenta ||
                            !itemsEstadoFinanciero ||
                            !catalogoDeCostoObligatorio ||
                            !cuentaGanancia ||
                            !cuentaPerdida ||
                            !agrupador101 ||
                            !estado
                        } size='small'
                            style={{ margin: "10px" }}
                            variant="contained"
                            endIcon={<AddCircleIcon />}
                            onClick={() => CrearCuentaMayor()}>
                            <strong>CREAR</strong>
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>

        </>
    )
}