import React, { useState, useRef, useEffect } from 'react';
import { Card, Tab, Box, Typography, Container, AppBar } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NuevoCorreo } from './NuevoCorreo';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const CorreosPage = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const params = useParams()
    const [tab, setTab] = useState(0)
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Box pl={2} pb={2} pt={2}>
                <Typography variant="h5" component="h1">CLIENTES/<strong>CORREOS</strong></Typography>
            </Box>
            <Card>
                <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                    <AppBar elevation={0} color='default' position="static">
                        <Tabs
                            value={tab}
                            variant="scrollable"
                            indicatorColor="secondary"
                            scrollButtons
                            textColor="secondary"
                            allowScrollButtonsMobile
                            onChange={(e, newValue) => setTab(newValue)}
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 }
                                }
                            }}
                        >
                            <Tab label="Nueva Correo" />
                            <Tab label="Solicitudes Aprobadas" />
                            <Tab label="Solicitudes en Espera" />
                            <Tab label="Solicitudes Rechazadas" />
                        </Tabs>
                    </AppBar>
                </Box>
                <Box>
                    <TabPanel value={tab} index={0}>
                        <NuevoCorreo />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>

                    </TabPanel>
                    <TabPanel value={tab} index={2}>

                    </TabPanel>
                    <TabPanel value={tab} index={3}>

                    </TabPanel>
                </Box>
            </Card>
        </Container>
    )
}