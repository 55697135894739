import { Box, Grid } from '@mui/material'
import React from 'react'

export const FirmaComprobanteEgreso = (props) => {
    return (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>{props.empleado}</strong></p>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>Responsable de Bodega</strong></p>
                </Box>
            </Grid>
        </Grid >
    )
}


export const FirmaComprobanteIngreso = () => {
    return (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>Proveedor</strong></p>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>Responsable de Bodega</strong></p>
                </Box>
            </Grid>
        </Grid >
    )
}


export const FirmaTransferenciaActivos = (props) => {
    return (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px', textAlign: 'center' }}>
            <Grid item xs={4}>
                <Box sx={{ marginTop: "30px" }} >
                    <a><strong>ENTREGADO:</strong></a>
                </Box>
                <Box sx={{ border: 1, borderWidth: '1px', height: "100px" }} />
                <Box sx={{ border: 1, borderWidth: '1px' }} >
                    <a><strong>
                        {props.bodeguero}
                        <br />
                        Jefe Bodeguero
                    </strong></a>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ marginTop: "30px" }} >
                    <a><strong>RECIBIDO POR:</strong></a>
                </Box>
                <Box sx={{ border: 1, borderWidth: '1px', height: "100px" }} />
                <Box sx={{ border: 1, borderWidth: '1px' }} >
                    <a><strong>
                        {props.empleado}
                        <br />
                        Empleado
                    </strong></a>
                </Box>
            </Grid>
        </Grid >
    )
}

export const FirmaReporteStock = () => {
    return (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>Responsable de Bodega</strong></p>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ borderTop: 1, marginTop: "100px" }} >
                    <p align="center" ><strong>Jefe de Bodega</strong></p>
                </Box>
            </Grid>
        </Grid >
    )
}