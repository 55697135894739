import React from 'react'
import { Box } from '@mui/material'
import * as XLSX from 'xlsx/xlsx.mjs'
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
export const ExportarCSV = ({ data }) => {
    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        let date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        const reData = [
            ...data.map(doc => {
                return {
                    ...doc,
                    debito: doc.debito == 1 ? 'SI' : 'NO', 
                    created: doc.created.toDate(),
                }
            })
        ]
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Licencias");
        XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
    }
    return (
        <>
            <Box display='flex' flexDirection="row-reverse" >
                <IconButton color="primary" onClick={handleExport}>
                    <GetAppIcon />
                </IconButton>
            </Box>
        </>
    )
}