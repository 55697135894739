import React, { useEffect, useState } from "react";
import {
    Grid, Paper, TextField, Select, MenuItem, Card, FormControl,
    CardContent, Button, Box, Dialog, DialogContent,
    Tooltip,
    IconButton,
    Typography,
    DialogTitle,
    Container,
    Autocomplete,
    DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { db, firebase } from "../../../../firebase";

import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid";

export const UpdateProveedor = ({ props }) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [tipo, setTipo] = useState(props.tipo)


    const [identificacion, setIdentificacion] = useState(props.identificacion)

    const handleIdentificacion = (e) => {
        setIdentificacion(e)
    }

    const [cuenta, setCuenta] = useState(props.cuentaGastos ? props.cuentaGastos : props.cuenta)
    const [cuenta2, setCuenta2] = useState(props.cuentaAnticipo)


    const [razonSocial, setRazonSocial] = useState(props.razon_social)

    const handleRazonSocial = (e) => {
        setRazonSocial(e)
    }

    const [nombreComercial, setNombreComercial] = useState(props.nombre_comercial)

    const handleNombreComercial = (e) => {
        setNombreComercial(e)
    }

    const [direccion, setDireccion] = useState(props.direccion)

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };

    const handleDireccion = (e) => {
        setDireccion(e)
    }

    const [email, setEmail] = useState(props.email)

    const handleEmail = (e) => {
        setEmail(e)
    }

    const [grupoGeneral, setGrupoGeneral] = useState(props.grupo_general)

    const [telefono1, setTelefono1] = useState(props.telefono1)

    const handleTelefono1 = (e) => {
        setTelefono1(e)
    }

    const [telefono2, setTelefono2] = useState(props.telefono2)

    const handleTelefono2 = (e) => {
        setTelefono2(e)
    }
    const [tipoDeProveedor, setTipoDeproeveedor] = useState(props.tipo_de_proveedor)

    const [regimen, setRegimen] = useState(props.regimen)

    const [sustentoTributario, setSustentoTributario] = useState(props.sustento_tributario)

    const [formaPagoAsignada, setFormaPagoAsignada] = useState(props.forma_pago_asignada)

    const [pagoA, setPagoA] = useState(props.pago_a)

    const [retencionIvaBienes, setRetencionIvaBienes] = useState(props.retencion_iva_bienes)

    const [retencionIvaServicios, setRetencionIvaServicios] = useState(props.retencion_iva_servicios)

    const [pais, setPais] = useState(props.pais)
    const [region, setRegion] = useState(props.region)
    const [provincia, setProvincia] = useState(props.provincia)
    const [canton, setCanton] = useState(props.canton)


    const [selectedValue, setSelectedValue] = React.useState('LOCAL');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(selectedValue)
    };

    const crearNuevoProveedor = async () => {
        await db.collection('proveedores').doc(props.id).update({
            tipo: tipo.toUpperCase(),
            identificacion: identificacion,
            cuenta: cuenta,
            razon_social: razonSocial.toUpperCase(),
            nombre_comercial: nombreComercial.toUpperCase(),
            direccion: direccion.toUpperCase(),
            email: email.toUpperCase(),
            grupo_general: grupoGeneral,
            telefono1: telefono1,
            telefono2: telefono2,
            tipo_de_proveedor: tipoDeProveedor,
            regimen: regimen,
            sustento_tributario: sustentoTributario.toUpperCase(),
            forma_pago_asignada: formaPagoAsignada,
            pago_a: pagoA,
            retencion_iva_bienes: retencionIvaBienes.toUpperCase(),
            retencion_iva_servicios: retencionIvaServicios.toUpperCase(),
            // estado: estado === true ? 0 : 1,
            // relacionado: relacionado === true ? 0 : 1,
            compras: selectedValue,
            cuentasBancarias: bancarias,
            // pais_pago: paisPago === '' ? 'RESIDENTE' : paisPago,
            canton: canton,
            provincia: provincia,
            region: region,
            pais: pais,
            cuentaGastos: cuenta,
            cuentaAnticipo: cuenta2,
        }).then(() => {
            Swal.fire({ icon: "success", title: "Proveedor actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpen(false)
    }

    const [productos, setProductos] = useState('')
    const [formasPago, setFormasPago] = useState([]);
    const [sustentos, setSustentos] = useState([]);

    const [tipos, setTipos] = React.useState([]);
    const [identificaciones, setIdentificaciones] = React.useState([]);

    const [personas, setPersonas] = React.useState([]);
    const [compras, setCompras] = React.useState([]);
    const [grupos, setGrupos] = React.useState([]);

    const [paises, setPaises] = React.useState([]);
    const [regiones, setRegiones] = React.useState([]);
    const [provincias, setProvincias] = React.useState([]);
    const [cantones, setCantones] = React.useState([]);

    const [retenciones, setRetenciones] = React.useState([]);

    const [bancos, setBancos] = React.useState([]);
    const [bancarias, setBancarias] = React.useState(props.cuentasBancarias);

    const [tipoId, setTipoId] = React.useState('');
    const [idBanco, setIdBanco] = React.useState('');
    const [banco, setBanco] = React.useState('');
    const [cuentaBancaria, setCuentaBancaria] = React.useState('');
    const [tipoCuenta, setTipoCuenta] = React.useState('');
    const [observacion, setObservacion] = React.useState('');
    const [open2, setOpen2] = React.useState(false);

    const columns = [
        { field: 'tipoIdentificacion', headerClassName: 'super-app-theme--header', headerName: 'Tipo ID', width: 150 },
        {
            field: 'identificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Identificación', width: 150
        },
        { field: 'banco', headerClassName: 'super-app-theme--header', headerName: 'Banco', width: 300 },
        { field: 'numeroCuenta', headerClassName: 'super-app-theme--header', headerName: 'Número Cuenta', width: 300 },
        { field: 'tipoCuenta', headerClassName: 'super-app-theme--header', headerName: 'Tipo Cuenta', width: 150 },
        {
            field: 'bo', headerClassName: 'super-app-theme--header', headerName: 'Borrar', width: 100,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => {
                        let indice = bancarias.indexOf(params.row)
                        setBancarias(bancarias.filter((val, index) => index !== indice))
                    }}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];

    function handleBanco() {
        let datos = [{
            tipoIdentificacion: tipoId,
            identificacion: idBanco,
            banco: banco,
            numeroCuenta: cuentaBancaria,
            tipoCuenta: tipoCuenta,
            observacion: observacion,
        }]

        setBancarias(bancarias.concat(datos))
        setTipoId('')
        setIdBanco('')
        setBanco('')
        setCuentaBancaria('')
        setTipoCuenta('')
        setObservacion('')
        setOpen2(false)
    }

    const getProductos = () => {
        let docRef = db.collection("plan_cuentas_test").where('nivel', '==', 5)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getGrupos = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipos_grupos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setGrupos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const getPaises = () => {
        let docRef = db.collection("cuentas_pagar/parametros/pais")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setPaises(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getRegiones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/region")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRegiones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getProvincias = () => {
        let docRef = db.collection("cuentas_pagar/parametros/provincia")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProvincias(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getCantones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/canton")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setCantones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getPersonas = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_personas")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setPersonas(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getTipoCompra = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipos_compras")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setCompras(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const getTipoContribuyente = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_contribuyente")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setTipos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const getTipoIdentificacion = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_identificacion")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setIdentificaciones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getFormasPago = () => {
        let docRef = db.collection("cuentas_pagar/parametros/formas_pago")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setFormasPago(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getSustentos = () => {
        let docRef = db.collection("cuentas_pagar/parametros/sustentos_tributario")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setSustentos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    useEffect(() => {
        getProductos()
        getTipoContribuyente()
        getTipoIdentificacion()
        getPersonas()
        getSustentos()
        getFormasPago()
        getTipoCompra()
        getGrupos()

        getPaises()
        getProvincias()
        getCantones()
        getRegiones()
        getRetenciones()
        getBancos()
    }, []);

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);
    const [openDialogCuentas2, setOpenDialogCuentas2] = useState(false);


    const [open, setOpen] = useState(false);

    const onData = () => {
        setOpen(true)
    }
    const getRetenciones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_iva")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRetenciones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getBancos = () => {
        let docRef = db.collection("bancos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setBancos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    return (
        <>
            <br />

            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogContent>
                    <Card sx={{ minWidth: 100 }}>
                        <CardContent>
                            {/* <Box paddingBottom={2}>
                        <Typography >
                            <strong>NUEVO PROVEEDOR</strong>
                        </Typography >
                    </Box> */}
                            <Grid container spacing={1} direction="row" alignItems="center" >
                                <Grid item xs={3}>
                                    <Box pb={3}>
                                        <FormControl fullWidth>

                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>TIPO</strong></p>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                value={tipo}
                                                onChange={(e) => setTipo(e.target.value)}
                                                fullWidth
                                            >
                                                {identificaciones ? identificaciones.map((item, index) => (
                                                    <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                                )) : <></>}
                                            </Select>

                                        </FormControl>
                                    </Box>
                                </Grid>

                            </Grid>

                            <Grid container spacing={1} direction="row" alignItems="center" >
                                <Grid item xs={3}>
                                    <Box pb={3}>

                                        {
                                            tipo === 'CEDULA' || tipo === 'PASAPORTE' ? <FormControl fullWidth>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                    <strong>IDENTIFICACION</strong></p>
                                                <TextField
                                                    type="number"
                                                    inputProps={{ maxLength: 10 }}
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={identificacion}
                                                    onChange={(e) => handleIdentificacion(e.target.value)}
                                                    name="tipo"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl> : <FormControl fullWidth>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                    <strong>IDENTIFICACION</strong></p>
                                                <TextField
                                                    type="number"
                                                    inputProps={{ maxLength: 13 }}
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={identificacion}
                                                    onChange={(e) => handleIdentificacion(e.target.value)}
                                                    name="tipo"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        }
                                    </Box>
                                </Grid>

                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    <Box pb={3}>

                                        <FormControl fullWidth>

                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>CUENTA PASIVA</strong></p>
                                            <TextField
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={cuenta}
                                                name="tipo"
                                                size="small"
                                                fullWidth
                                            />

                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>

                                    <Box pt={3} style={{ paddingBottom: "0px" }}>
                                        <Button onClick={() => setOpenDialogCuentas(true)}
                                            style={{ paddingBottom: "0px" }} variant="outlined"
                                            startIcon={<SearchIcon />}>
                                        </Button>
                                    </Box>



                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    <Box pb={3}>

                                        <FormControl fullWidth>

                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>CUENTA DE ANTICIPOS</strong></p>
                                            <TextField
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={cuenta2}
                                                name="tipo"
                                                size="small"
                                                fullWidth
                                            />

                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={3}>


                                    <Box pt={3} style={{ paddingBottom: "0px" }}>
                                        <Button onClick={() => setOpenDialogCuentas2(true)}
                                            style={{ paddingBottom: "0px" }} variant="outlined"
                                            startIcon={<SearchIcon />}>
                                        </Button>
                                    </Box>



                                </Grid>
                            </Grid>

                            <Box pb={3}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>RAZON SOCIAL</strong></p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={razonSocial}
                                            onChange={(e) => handleRazonSocial(e.target.value)}
                                            name="tipo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>NOMBRE COMERCIAL</strong></p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={nombreComercial}
                                            onChange={(e) => handleNombreComercial(e.target.value)}
                                            name="tipo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>
                            </Box>


                            <Box pb={3}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>DIRECCION</strong></p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={direccion}
                                            onChange={(e) => handleDireccion(e.target.value)}
                                            name="tipo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>


                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>EMAIL</strong></p>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={email}
                                            onChange={(e) => handleEmail(e.target.value)}
                                            name="tipo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>

                            </Box>

                            <Box pb={3}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>GRUPO</strong></p>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}

                                            size="small"
                                            value={grupoGeneral}
                                            onChange={(e) => setGrupoGeneral(e.target.value)}
                                            fullWidth
                                            MenuProps={MenuProps}
                                        >
                                            {grupos ? grupos.map((item, index) => (
                                                <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                            )) : <></>}
                                        </Select>
                                    </Grid>



                                    <Grid item xs={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>TELEFONO 1</strong></p>
                                        <TextField
                                            type="number"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={telefono1}
                                            onChange={(e) => handleTelefono1(e.target.value)}
                                            name="tipo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>

                            </Box>

                            <Box pb={3}>
                                <FormControl fullWidth>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>TELEFONO 2</strong></p>
                                            <TextField
                                                type="number"
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={telefono2}
                                                onChange={(e) => handleTelefono2(e.target.value)}
                                                name="tipo"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Box>


                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>PAIS</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={pais}
                                                    onChange={(e) => setPais(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {paises ? paises.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.descripcion}>
                                                            {item.descripcion}</MenuItem>
                                                    )) : <></>}

                                                </Select>

                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>REGION</strong></p>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={region}
                                                    onChange={(e) => setRegion(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {regiones ? regiones.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.descripcion}>
                                                            {item.descripcion}</MenuItem>
                                                    )) : <></>}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>PROVINCIA</strong></p>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={provincia}
                                                    onChange={(e) => setProvincia(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {provincias ? provincias.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.descripcion}>
                                                            {item.descripcion}</MenuItem>
                                                    )) : <></>}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>CANTON</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}

                                                    size="small"
                                                    value={canton}
                                                    onChange={(e) => setCanton(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {cantones ? cantones.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.descripcion}>
                                                            {item.descripcion}</MenuItem>
                                                    )) : <></>}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <br />
                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Box pb={1}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                        <strong>TIPO DE COMPRA</strong></p>
                                    <FormControl fullWidth>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={selectedValue}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {compras ? compras.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.nombre}>
                                                            {item.nombre}</MenuItem>
                                                    )) : <></>}

                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Box>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>TIPO DE PERSONA</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={tipoDeProveedor}
                                                    onChange={(e) => setTipoDeproeveedor(e.target.value)}
                                                    fullWidth
                                                >
                                                    {personas ? personas.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.nombre}>
                                                            {item.nombre}</MenuItem>
                                                    )) : <></>}

                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>TIPO DE CONTRIBUYENTE</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={regimen}
                                                    onChange={(e) => setRegimen(e.target.value)}
                                                    fullWidth
                                                >
                                                    {tipos ? tipos.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.nombre}>
                                                            {item.nombre}</MenuItem>
                                                    )) : <></>}

                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper><br />

                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>SUSTENTO TRIBUTARIO</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={sustentoTributario}
                                                    onChange={(e) => setSustentoTributario(e.target.value)}
                                                    MenuProps={MenuProps}
                                                >
                                                    {sustentos ? sustentos.map((item, index) => (
                                                        <MenuItem key={item.id}
                                                            value={(item.codigo + "-" + item.nombre).toUpperCase()}>
                                                            {item.codigo + "-" + item.nombre}</MenuItem>
                                                    )) : <></>}

                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>FORMA DE PAGO ASIG.</strong></p>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={formaPagoAsignada}
                                                    onChange={(e) => setFormaPagoAsignada(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {formasPago ? formasPago.map((item, index) => (
                                                        <MenuItem key={item.id} value={item.nombre}>
                                                            {item.nombre}</MenuItem>
                                                    )) : <></>}

                                                </Select>

                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>PAGO A</strong></p>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={pagoA}
                                                    onChange={(e) => setPagoA(e.target.value)}
                                                    fullWidth
                                                >
                                                    <MenuItem value="RESIDENTE">RESIDENTE</MenuItem>
                                                    <MenuItem value="NO RESIDENTE">NO RESIDENTE</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>% RET. IVA BIENES</strong></p>
                                            <FormControl fullWidth>

                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={retencionIvaBienes}
                                                    onChange={(e) => setRetencionIvaBienes(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {retenciones ? retenciones.map((item, index) => (
                                                        <MenuItem key={item.id}
                                                            value={(`${item.codigo}  ${item.nombre}`).toUpperCase()}>
                                                            {`${item.codigo}  ${item.nombre}`}</MenuItem>
                                                    )) : <></>}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box pb={1}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                <strong>% RET. IVA SERVICIOS</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}

                                                    size="small"
                                                    value={retencionIvaServicios}
                                                    onChange={(e) => setRetencionIvaServicios(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    {retenciones ? retenciones.map((item, index) => (
                                                        <MenuItem key={item.id}
                                                            value={(`${item.codigo}  ${item.nombre}`).toUpperCase()}>
                                                            {`${item.codigo}  ${item.nombre}`}</MenuItem>
                                                    )) : <></>}

                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pt={4}>
                                            <Button
                                                onClick={() => setOpen2(true)}
                                                variant="contained" color='success'>
                                                Agregar Cuenta
                                            </Button>
                                        </Box>
                                        <Box sx={{
                                            height: 250, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                            '& .super-app-theme--header': {
                                                backgroundColor: '#672780',
                                                color: 'white',
                                                fontSize: '16px'
                                            },
                                        }}>
                                            <DataGrid
                                                hideFooterSelectedRowCount={true}
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                columns={columns}
                                                density='compact'
                                                rows={bancarias ?? []}
                                                getRowId={(row) => (row.banco + '-' + row.numeroCuenta + '-')}
                                                getRowHeight={() => 'auto'}
                                                getEstimatedRowHeight={() => 150}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </CardContent>
                    </Card>
                    <Grid container>
                        <Button onClick={() => crearNuevoProveedor()}
                            size='small' style={{ margin: "10px" }}
                            variant="contained" endIcon={<AddCircleIcon />}>
                            <strong>ACTUALIZAR</strong>
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/* </CardContent>
            </Card> */}

            {/* DIALOG CUENTAS*/}
            <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                <DialogTitle><strong>CUENTAS</strong></DialogTitle>
                <Container maxWidth="xl">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>CUENTAS</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                setCuenta(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialogCuentas2} onClose={() => setOpenDialogCuentas2(false)} maxWidth="xl">
                <DialogTitle><strong>CUENTAS</strong></DialogTitle>
                <Container maxWidth="xl">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>CUENTAS</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                setCuenta2(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentas2(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCuentas2(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={open2}
                fullWidth onClose={() => {
                    setOpen2(false)
                }} maxWidth="md">
                <DialogTitle>
                    <strong>
                        AGREGAR CUENTA BANCARIA
                    </strong>
                </DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>
                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: '14px' }}><strong>Tipo de ID:</strong></Typography>
                                <Select
                                    fullWidth
                                    size="small"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                        fontSize: '14px'
                                    }}
                                    value={tipoId}
                                    MenuProps={MenuProps}
                                    onChange={(e) => setTipoId(e.target.value)}
                                >
                                    {identificaciones ? identificaciones.map((item, index) => (
                                        <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                    )) : <></>}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}><strong>Identificación:</strong></Typography>

                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={idBanco}
                                    onChange={(e) => setIdBanco(e.target.value)}
                                    size="small"
                                    fullWidth />

                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}><strong>Nombre Banco:</strong></Typography>
                                <Select
                                    fullWidth
                                    size="small"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                        fontSize: '14px'
                                    }}
                                    value={banco}
                                    MenuProps={MenuProps}
                                    onChange={(e) => setBanco(e.target.value)}
                                >
                                    {bancos ? bancos.map((item, index) => (
                                        <MenuItem key={item.codigo + item.nombre}
                                            value={item.codigo}>{item.nombre}</MenuItem>
                                    )) : <></>}

                                </Select>
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}><strong>Número Cuenta:</strong></Typography>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={cuentaBancaria}
                                    type='number'
                                    onChange={(e) => setCuentaBancaria(e.target.value)}
                                    size="small"
                                    fullWidth />
                            </Box>
                        </Grid>

                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: '14px' }}><strong>Tipo Cuenta:</strong></Typography>
                                <Select
                                    fullWidth
                                    size="small"
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                        fontSize: '14px'
                                    }}
                                    value={tipoCuenta}
                                    onChange={(e) => setTipoCuenta(e.target.value)}
                                >
                                    <MenuItem key={0} value="AHORROS">AHORROS</MenuItem>
                                    <MenuItem key={1} value="CORRIENTE">CORRIENTE</MenuItem>
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item md={6} lg={6} xs={12}>
                            <Box py={1}>
                                <Typography sx={{ fontSize: "14px" }}><strong>Observación:</strong></Typography>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={observacion}
                                    onChange={(e) => setObservacion(e.target.value)}
                                    multiline={true}
                                    rows={2}
                                    size="small"
                                    fullWidth />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box pt={4}>
                                <Button
                                    disabled={banco === '' ||
                                        cuentaBancaria === '' ||
                                        tipoId === '' ||
                                        idBanco === '' ||
                                        tipoCuenta === ''}
                                    onClick={() => handleBanco()}
                                    fullWidth variant="contained" color='success'>
                                    CREAR CUENTA BANCARIA
                                </Button>
                            </Box>
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen2(false)
                    }} variant="contained"><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    )
}