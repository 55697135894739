import {
  TextField, Typography, Box, Grid, Button, AlertTitle, Alert,
  FormControl, MenuItem, Select, InputLabel, CardContent, Card
} from "@mui/material"
import React, { useEffect, useState } from "react"
import InputValidation from "../../../components/utils/InputValidation"
import moment from "moment"
import Swal from "sweetalert2"
import SelectGeografico from '../../../components/utils/SelectGeograficos'
import { useDispatch, useSelector } from 'react-redux'
import { auth, firebase, functions, db } from '../../../firebase'
import { useParams, useNavigate } from 'react-router-dom'
import { GeolocationGoogle } from '../../../components/utils/GeolocationGoogle'
import { listsPlanes } from "../../../redux/actions/parametersActions"
import { listFlotas } from "../../../redux/actions/flotas/flotasActions";
import { listAuxiliares } from "../../../redux/actions/flotas/flotasAuxiliares"
import { listLiders } from "../../../redux/actions/flotas/flotasLiders"
export const NuevaSolicitudServicio = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const params = useParams()
  //Estado de crear nueva solicitud
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  const [loading, setLoading] = useState(false)
  const { planes } = useSelector(state => state.planesList)
  const [statusClient, setStatusClient] = useState();
  const [contractArray, setContractArray] = useState([]);
  const [linea, setLinea] = useState('')
  const [referido, setReferido] = useState('')
  //
  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loadingf } = flotasList
  //
  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList
  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')
  const [cedula2, setCedula2] = useState('');
  const [nombre, setNombre] = useState({ campo: "", valido: true });
  const [fechaNacimiento, setFechaNacimiento] = useState({ campo: "", valido: true });
  const [direccion, setDireccion] = useState({ campo: "", valido: true });
  const [telefono, setTelefono] = useState({ campo: "", valido: true });
  const [celular, setCelular] = useState({ campo: "", valido: true });
  const [correo, setCorreo] = useState({ campo: "", valido: true });
  const [codVendedor, setCodVendedor] = useState('')
  const [contrato, setContrato] = useState('')
  const [nacionalidad, setNacionalidad] = useState({ campo: "", valido: true });
  const [plan, setPlan] = useState([]);
  const [planValor, setPlanValor] = useState();
  const [tipoVenta, setTipoVenta] = useState('VENTA')
  const [tipoID, setTipoID] = useState('')
  const [contratoCliente, setContratoCliente] = useState('')
  const [geografico, setGeografico] = useState('');
  const [suspendido, setSuspendido] = useState(false)
  const [list_planes, setListPlanes] = useState([])
  const [miniups, setUPS] = useState('NO')
  const [planName, setPlanName] = useState('')
  const [planId, setPlanId] = useState('')
  const [mesh, setMesh] = useState('NO')
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  })
  const [listPremios, setListPremios] = useState([])
  const [list_codigos, setListCodigos] = useState([])
  const [datos_contrato, setDatosContrato] = useState('')
  const [premios, setPremios] = useState('')
  const regexCedula = new RegExp('^[0-9]{10}$');
  const regexRUC = new RegExp('^[0-9]{10}(001)$');
  const regexPasaporte = new RegExp('^[a-zA-Z0-9]{5,15}$');
  const regexCell = new RegExp('^[0-9]{10}$')
  const list_premios = [
    {
      id: 380,
      plan: 'PLAN POWER DEFENDER PLUS_50',
      premios: ['NINGUNO']
    },
    {
      id: 382,
      plan: 'PLAN TENDENCIA DEFENDER PLUS_150',
      premios: ['AUDIFONOS INALAMBRICOS', 'NINGUNO']
    },
    {
      id: 381,
      plan: 'PLAN MAX DEFENDER PLUS_100',
      premios: ['NINGUNO']
    },
    {
      id: 383,
      plan: 'PLAN INFLUENCER DEFENDER PLUS_200',
      premios: ['AUDIFONOS INALAMBRICOS', 'NINGUNO']
    },
    {
      id: 384,
      plan: 'PLAN EPICO DEFENDER PLUS_300',
      premios: ['AUDIFONOS INALAMBRICOS', 'NINGUNO']
    },
    {
      id: 385,
      plan: 'PLAN LEGENDARIO DEFENDER PLUS_400',
      premios: ['AUDIFONOS INALAMBRICOS', 'NINGUNO']
    },
    {
      id: 379,
      plan: 'PLAN UNLIMITED DEFENDER PLUS',
      premios: ['ALTAVOZ INTELIGENTE', 'NINGUNO']
    }
  ]
  const validarId = (id) => {
    if (id === '') return true
    if (tipoID === "PASAPORTE") {
      return regexPasaporte.test(id)
    }
    else if (tipoID === "RUC") {
      return regexRUC.test(id)
    } else {
      return regexCedula.test(id)
    }
  }

  const handleFlota = (e) => {
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e)
    const data = p[0]
    setNumFlota(data.numeroFlota)
    setLider(data.nombreLider)
    setAuxiliar(data.nombreAuxiliar)
  }
  const handleLider = (e) => {
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e)
    const data = p[0]
    setLider(data.nombreLider)
  }
  const handleAuxiliar = (e) => {
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e)
    const data = p[0]
    setAuxiliar(data.nombreAuxiliar)
  }

  useEffect(() => {
    if (telefono.campo === celular.campo) {
      if (regexCell.test(telefono.campo) && regexCell.test(celular.campo)) {
        Swal.fire({ icon: "warning", text: "Número de Teléfono y Celular no debe ser igual" })
      }
    }
    console.log(userInfo)
  }, [telefono.campo, celular.campo])

  useEffect(() => {
    geolocationPosition();
    dispatch(listsPlanes())
    dispatch(listFlotas())
    dispatch(listLiders())
    dispatch(listAuxiliares())
    onLoadCodigos()
    geo()
    if (userInfo.vendedor == null) {
      Swal.fire({ icon: 'warning', text: 'Su usuario no tiene actualizado su centro de costo, por favor actualizar su centro de costo' })
      //navigate('/ventas/centro_costo/')
    }
  }, [dispatch])
  const geo = () => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      var crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    };
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }
  const apiCivil = (cedula2) => {
    setStatusClient('')
    setContractArray('')
    setSuspendido(false)
    setNombre({ campo: '', valido: true })
    setDireccion({ campo: '', valido: true })
    setNacionalidad({ campo: '', valido: true })
    setFechaNacimiento({ campo: '', valido: true })
    setCedula2(cedula2)
    if (cedula2 && cedula2.length >= 10) {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      }
      fetch(`https://serverbd.intercommerce.com.ec/?cedula=${cedula2}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const dataParse = JSON.parse(result);
          const data = dataParse;
          console.log('Registro Civil: ', data);
          function calcularEdad(fecha_nacimiento) {
            var hoy = new Date();
            var cumpleanos = new Date(fecha_nacimiento);
            var edad = hoy.getFullYear() - cumpleanos.getFullYear();
            var m = hoy.getMonth() - cumpleanos.getMonth();
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
              edad--;
            }
            return edad;
          }
          //if (data[0].descripcion === "FALLECIDO") {
          //Swal.fire({ icon: "warning", text: "CLIENTE SE ENCUENTRA FALLECIDO, NO PUEDE VENDERLE A ESTE CLIENTE" })
          //} else {
          if (data) {
            //Cambiar formato de fecha de es a us
            //const formatBirth = moment(data[0].fechanacimiento, "DD MM YYYY").format("YYYY/MM/DD")
            //var age = calcularEdad(formatBirth)
            //Validacion de mayor de edad
            //if (age >= 18) {  
            setCedula2(cedula2)
            setNombre({ campo: data[0].nombre, valido: true })
            setDireccion({ campo: data[0].direccion, valido: true })
            setNacionalidad({ campo: data[0].nacionalidad, valido: true })
            setFechaNacimiento({ campo: data[0].fechanacimiento, valido: true })
            const mikrowisp = functions.httpsCallable('mikrowisp')
            mikrowisp(cedula2)
              .then(res => {
                if (res) {
                  if (res.mensaje == "No existe el cliente con el filtro indicado.") {
                    setStatusClient("Este cliente es nuevo en Yiga5")
                  } else {
                    const contracts = res.data;
                    const data = [
                      ...contracts.map((doc) => {
                        return {
                          NUM_CONTRATO: doc.NUM_CONTRATO,
                          estado: doc.estado,
                          REFERENCIA: doc.REFERENCIA,
                          nombre: doc.nombre,
                          correo: doc.correo,
                          REFERENCIA: doc.REFERENCIA,
                          direccion_principal: doc.direccion_principal,
                          telefono: doc.telefono,
                          movil: doc.movil
                        }
                      })
                    ]
                    setContractArray(data)
                    setStatusClient("")
                    if (res.data.find((element) => element.estado.includes('SUSPENDIDO'))) {
                      Swal.fire({ icon: "warning", text: "CLIENTE CON CONTRATO SUSPENDIDO, NO PUEDE VENDERLE A ESTE CLIENTE" })
                    }
                  }
                }
              }).catch(error => {
                console.log(`CODE 1: ${error}`)
              })
            // } else {
            //   Swal.fire({ icon: "warning", text: "CLIENTE NO ES MAYOR DE EDAD, NO PUEDE VENDERLE A ESTE CLIENTE" })
            // }
          }
          //}
        }).catch(error => { console.log(`CODE 0: ${error}`) })
    }
  }
  const submitHandler = (e) => {
    e.preventDefault()
    setLoading(true)
    //Enviamos la solicitud
    db.collection("solicitudes").orderBy("created", "desc").limit(1).get()
      .then((solicitudesDb) => {
        let numeroSolicitud = 1
        if (solicitudesDb.size > 0) {
          const solicitudes = solicitudesDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          const solicitud = solicitudes[0]
          numeroSolicitud = solicitud.numeroSolicitud + 1;
        } else {
          alert('Error al subir la venta contactar con soporte')
        }
        const uidSolicitud = `SO-SER-` + numeroSolicitud
        //
        db.collection('solicitudes').doc(uidSolicitud).set({
          uid: uidSolicitud,

          linea: linea,
          numeroSolicitud: numeroSolicitud,
          user_uid: auth.currentUser.uid,
          cedula: cedula2,
          nombres: nombre.campo.toUpperCase(),
          direccion: direccion.campo.toUpperCase(),
          nacionalidad: nacionalidad.campo.toUpperCase(),
          fechaNacimiento: fechaNacimiento.campo,
          telefono: telefono.campo,
          celular: celular.campo,
          correo: correo.campo,
          contrato: contrato,
          coordenadas: new firebase.firestore.GeoPoint(
            position.lat,
            position.lng
          ),
          camara:"NO",
          numCam:"",
          ups: miniups,
          planId: parseInt(planId),
          planNombre: planName,
          planValor: planValor,
          estado: 0,
          provincia: geografico.provincia,
          canton: geografico.canton,
          parroquia: geografico.parroquia,
          sector: geografico.sector,
          cargo: userInfo.cargo,
          vendedor: userInfo.displayName,
          tipo_venta: 'VENTA',
          tipo_id: tipoID,
          referido: referido,
          flotaReferida: {
            numeroFlota: numFlota,
            nombreLider: lider,
            nombreAuxiliar: auxiliar,
          },
          centro_costo: {
            codigo_vendedor: codVendedor,
            lider_venta: userInfo.vendedor.lider_venta,
            categoria: userInfo.vendedor.categoria,
            centro_costo: userInfo.vendedor.centro_costo,
            canal: userInfo.vendedor.canal
          },
          premios: premios,
          mesh: mesh,
          observacion: 'No hay observaciones',
          created: firebase.firestore.FieldValue.serverTimestamp()
        }).then((docRef) => {
          navigate("/ventas/solicitud-de-servicio/2")
          Swal.fire({ icon: "success", text: 'Solicitud enviada' })
          setLoading(false)
          dispatch({
            type: 'SOLICITUD_CREATE_SUCCESS',
            payload: docRef,
          })
        }).catch((e) => {
          setLoading(false)
          alert(e)
        })
      }).catch((e) => {
        setLoading(false)
        alert(e)
      })
  }
  const geolocationPosition = () => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      var crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    }

    function error(err) {
      console.warn("ERROR(" + err.code + "): " + err.message);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }
  const handlePlan = (e) => {
    const p = planes.filter((item) => item.id === e)
    
    const plan = p[0]
    if (plan.id !== '380' && plan.id !== '381' && plan.id !== '379') {
      setMesh('NO')
    }
    setPlan(e)
    setPlanName(plan.plan)
    setPlanId(plan.id)
    setPlanValor(parseFloat(plan.costo))
    const premios = list_premios.find(i => i.id == plan.id)
    if(premios){
      setPremios('')
      setListPremios(premios.premios)
    }
    else if (geografico && geografico.canton &&
      geografico.canton === 'MILAGRO'
      && geografico.canton === 'NARANJITO'
      && geografico.canton === 'VIRGEN-FATIMA'
      && geografico.canton === 'YAGUACHI') {
      setListPremios(['NINGUNO'])
      setPremios('')
    }
    else {
      setPremios('')
      setListPremios(['NINGUNO'])
    }
  }
  const handleContrato = (e) => {
    setContrato(e)
    if (e == 'HOGAR') {
      console.log('hola')
      const hogar = planes.filter(row => (row.plan.includes('DEFENDER PLUS_') 
      || row.plan.includes('UNLIMITED') 
      || row.plan.includes('ADULTO') 
      || row.plan.includes('SOLIDARIO')
      || row.plan.includes('TENDENCIA NAVIDEÑO')
      || row.plan.includes('TENDENCIA UPS')))
      setPremios('')
      setListPlanes(hogar)
    } else {
      const corpor = planes.filter(row => !row.plan.includes('DEFENDER'))
      setListPlanes(corpor)
      setPremios('')
      setListPremios(['NINGUNO'])
    }
  }

  const handleContratoSelecionado = (e) => {
    setDatosContrato('')
    const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
    const contrato_cliente = p[0]
    setContratoCliente(contrato_cliente)
    console.log(contrato_cliente, ' contrato cliente')
  }
  const onLoadCodigos = () => {
    if (userInfo.email === 'giovanny.rodas@distribuidor1.santaelena.com.ec') {
      db.collection('system/centro_de_costos/codigo_vendedor').where("email", "==", "jazmin.perez@distribuidor1.santaelena.com.ec").where("estado", "==", 1)
        .onSnapshot((querySnapshot) => {
          const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
          if (arrayMessage.length > 0) {
            setListCodigos(arrayMessage)
          } else {
            Swal.fire({ icon: 'warning', text: "No tiene asignado un codigo vendedor debe crearlo en el modulo de centro de costo" })
          }
        })
    }
    else {
      db.collection('system/centro_de_costos/codigo_vendedor').where("email", "==", userInfo.email).where("estado", "==", 1)
        .onSnapshot((querySnapshot) => {
          const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
          if (arrayMessage.length > 0) {
            setListCodigos(arrayMessage)
          } else {
            Swal.fire({ icon: 'warning', text: "No tiene asignado un codigo vendedor debe crearlo en el modulo de centro de costo" })
          }
        })
    }
  }

  return (
    <Box p={1}>
      <form onSubmit={submitHandler}>
        <Box pb={2}>
          {statusClient && <Alert severity="info">{statusClient}</Alert>}
          {contractArray.length > 0 && (
            <Alert severity="info">
              {contractArray &&
                <>
                  <AlertTitle>
                    Este usuario es parte de Yiga 5 y sus contratos son:
                  </AlertTitle>
                  {contractArray.map((item, index) => (
                    <Box key={index}>
                      <Typography key={index}>
                        {item.NUM_CONTRATO} - <strong>{item.estado}</strong>
                      </Typography>
                    </Box>
                  ))}
                </>
              }
            </Alert>
          )}
        </Box>
        {datos_contrato && (
          <Alert severity="info">
            <AlertTitle>
              Información del contrato {datos_contrato.NUM_CONTRATO}
            </AlertTitle>
            <Grid container spacing={5}>
              <Grid item lg={6} md={6} xs={12}>
                <Typography >
                  <strong>Nombres: </strong>{datos_contrato.nombre}
                </Typography>
                <Typography >
                  <strong>Correo: </strong>{datos_contrato.correo}
                </Typography>
                <Typography >
                  <strong>Direccion1: </strong>{datos_contrato.REFERENCIA}
                </Typography>
                <Typography >
                  <strong>Direccion2: </strong>{datos_contrato.direccion_principal}
                </Typography>
                <Typography >
                  <strong>Telefonos: </strong>{datos_contrato.telefono}
                </Typography>
                <Typography >
                  <strong>Celular: </strong>{datos_contrato.movil}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>

              </Grid>
            </Grid>
          </Alert>
        )}
        <Box py={4}>
          <Grid container spacing={5}>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>TIPO DE ID</InputLabel>
                <Select
                  value={tipoID}
                  label="TIPO DE ID"
                  onChange={(e) => { setTipoID(e.target.value) }}
                >
                  <MenuItem value="CEDULA">CEDULA</MenuItem>
                  <MenuItem value="RUC">RUC</MenuItem>
                  <MenuItem value="PASAPORTE">PASAPORTE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel>CODIGO VENDEDOR</InputLabel>
                <Select
                  value={codVendedor}
                  label="CODIGO VENDEDOR"
                  onChange={(e) => { setCodVendedor(e.target.value.toString()) }}
                >
                  <MenuItem value="">-</MenuItem>
                  {list_codigos && list_codigos.map((row, key) => (
                    <MenuItem key={key} value={row.codigo}>{row.codigo} - {row.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>

            </Grid>
          </Grid>
        </Box>
        <Box py={4}>
          <Grid container spacing={5}>
            <Grid item md={4} xs={12}>
              <TextField
                type={(tipoID === "PASAPORTE" ? 'text' : 'number')}
                pattern={(tipoID === "PASAPORTE" ? '^[a-zA-Z0-9]{5,15}$' : (tipoID === "RUC" ? '^[0-9]{10}(001)$' : '^[0-9]{10}$'))}
                value={cedula2}
                onChange={(e) => apiCivil(e.target.value)}
                label="CÉDULA | RUC | PASAPORTE"
                error={!validarId(cedula2)}
                helperText={!validarId(cedula2) ? 'Identificación no válida' : ''}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {tipoVenta !== 'VENTA' && contractArray && cedula2.length >= 10 &&
                <FormControl fullWidth disabled={!contractArray}>
                  <InputLabel>CONTRATO CLIENTE</InputLabel>
                  <Select
                    label="CONTRATO CLIENTE"
                    onChange={(e) => { handleContratoSelecionado(e.target.value) }}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {contractArray && contractArray.map((item, index) => (
                      <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </Grid>
            <Grid item md={4} xs={12}>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={5}>
          <Grid item md={4} xs={12}>
            <Box pt={3} pb={3}>
              <FormControl fullWidth>
                <InputLabel >LINEA DE CONTRATO</InputLabel>
                <Select
                  value={linea}
                  label="LINEA DE CONTRATO"
                  onChange={(e) => { setLinea(e.target.value) }}
                >
                  <MenuItem value="">-</MenuItem>
                  <MenuItem value="L1">L1</MenuItem>
                  <MenuItem value="L2">L2</MenuItem>
                  <MenuItem value="L3">L3</MenuItem>
                  <MenuItem value="L4">L4</MenuItem>
                  <MenuItem value="L5">L5</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <InputValidation
              estado={nombre}
              cambiarEstado={setNombre}
              label="NOMBRES"
              name="name"
              helperText="Minimo 8 caracteres"
            />
            <Box pt={3}></Box>
            <SelectGeografico cambiarEstado={setGeografico} />
          </Grid>
          <Grid item md={4} xs={12}>
            <InputValidation
              estado={direccion}
              cambiarEstado={setDireccion}
              label="DIRECCIÓN"
              name="name"
              helperText="Minimo 8 caracteres"
            />
            <InputValidation
              estado={celular}
              cambiarEstado={setCelular}
              label="CELULAR"
              type="number"
              placeholder=""
              name="name"
              helperText="Iniciar con 09 y minimo 10 digitos"
              patterns={/^\d{10}$/}
            />
            <InputValidation
              estado={telefono}
              type="number"
              cambiarEstado={setTelefono}
              label="TELEFONO"
              name="name"
              helperText="Minimo 10 caracteres"
              patterns={/^\d{10}$/}
            />
            <Box pb={3}>
              <TextField
                placeholder="LATITUD"
                type="number"
                onChange={(e) => setPosition({ ...position, lat: parseFloat(e.target.value) })}
                value={position.lat}
              />
            </Box>
            <TextField
              placeholder="LONGITUD"
              type="number"
              onChange={(e) => setPosition({ ...position, lng: parseFloat(e.target.value) })}
              value={position.lng}
            />
            <Box pt={3}>
              <FormControl fullWidth>
                <InputLabel>TIPO DE CONTRATO</InputLabel>
                <Select
                  label="TIPO DE CONTRATO"
                  value={contrato}
                  onChange={(e) => handleContrato(e.target.value)}
                >
                  <MenuItem value="HOGAR">HOGAR</MenuItem>
                  <MenuItem value="CORPORATIVO">CORPORATIVO</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box py={3}>
              <FormControl fullWidth>
                <InputLabel>PLAN</InputLabel>
                <Select
                  value={plan}
                  label="PLAN"
                  onChange={(e) => handlePlan(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {list_planes && list_planes.map((row, key) => (
                    <MenuItem key={key} value={row.id}>{row.plan}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box pb={3}>
              <FormControl fullWidth>
                <InputLabel>PREMIOS</InputLabel>
                <Select
                  value={premios}
                  label="PREMIOS"
                  onChange={(e) => setPremios(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {listPremios && geografico.canton !== 'MILAGRO'
                    && geografico.canton !== 'NARANJITO'
                    && geografico.canton !== 'VIRGEN-FATIMA'
                    && geografico.canton !== 'YAGUACHI'
                    && listPremios.map((row, key) => (
                      <MenuItem key={key} value={row}>{row}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <InputValidation
              estado={nacionalidad}
              cambiarEstado={setNacionalidad}
              label="NACIONALIDAD"
              name="name"
              helperText="Minimo 8 caracteres"
            />
            <InputValidation
              estado={fechaNacimiento}
              cambiarEstado={setFechaNacimiento}
              label="FECHA DE NACIMIENTO"
              name="name"
              helperText="Formato correcto 30/03/2002"
              patterns={/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/}
            />
            <InputValidation
              estado={correo}
              type="email"
              cambiarEstado={setCorreo}
              label="CORREO"
              name="name"
              helperText="Minimo 8 caracteres"
            />
            <Box py={2}>
              <Card variant='outlined'>
                <CardContent>
                  <FormControl fullWidth>
                    <InputLabel>REFERIDO FLOTA</InputLabel>
                    <Select
                      label="REFERIDO FLOTA"
                      value={referido}
                      onChange={(e) => setReferido(e.target.value)}
                    >
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                  {
                    referido === 'SI' ?
                      <Box py={2}>
                        <FormControl fullWidth>
                          <InputLabel>FLOTA</InputLabel>
                          <Select
                            label="FLOTA"
                            value={flota}
                            onChange={(e) => handleFlota(e.target.value)}
                          >
                            <MenuItem value="">--</MenuItem>
                            {flotas && flotas.map((row, key) => (
                              <MenuItem key={key} value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </Box> : ''
                  }
                  {referido === 'SI' ?
                    <Box py={2}>
                      <FormControl fullWidth>
                        <InputLabel>AUXILIAR</InputLabel>
                        <Select
                          disabled
                          label="Auxiliar"
                          value={auxiliar}
                          onChange={(e) => handleAuxiliar(e.target.value)}
                        >
                          <MenuItem value="">--</MenuItem>
                          {auxiliars && auxiliars.map((row, key) => (
                            <MenuItem key={key} value={row.nombreAuxiliar}>{row.nombreAuxiliar}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box> : ''}
                  {
                    referido === 'SI' ?
                      <Box pt={2}>
                        <FormControl fullWidth>
                          <InputLabel>LIDER FLOTA</InputLabel>
                          <Select
                            disabled
                            label="LiderFlota"
                            value={lider}
                            onChange={(e) => handleLider(e.target.value)}
                          >
                            <MenuItem value="">--</MenuItem>
                            {liders && liders.map((row, key) => (
                              <MenuItem key={key} value={row.nombreLider}>{row.nombreLider}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </Box> : ''
                  }
                </CardContent>
              </Card>
            </Box>
            {userInfo.vendedor.centro_costo === 'AGENCIA FORTIN'
            || userInfo.vendedor.centro_costo === 'ISLA FORTIN' ?
            <Box pb={3}>
              <FormControl fullWidth>
                <InputLabel>MESH</InputLabel>
                <Select
                  value={mesh}
                  label="MESH"
                  onChange={(e) => setMesh(e.target.value)}
                >

                  {(planId === '380' || planId === '381' || planId === '379') &&
                    <MenuItem value="SI">SI</MenuItem>

                  }
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </FormControl>
            </Box> :''}
            {userInfo.vendedor.centro_costo === 'AGENCIA FORTIN'
            || userInfo.vendedor.centro_costo === 'ISLA FORTIN' || userInfo?.roles?.find(role => ['admin']?.includes(role)) ?
            <Box pb={3}>
              <FormControl fullWidth>
                <InputLabel>MINI UPS</InputLabel>
                <Select
                  value={miniups}
                  label="MINI UPS"
                  onChange={(e) => setUPS(e.target.value)}
                >
                  <MenuItem value="SI">SI</MenuItem>
                  <MenuItem value="NO">NO</MenuItem>
                </Select>
              </FormControl>
            </Box> : ''}

            <span style={{ textAlign: "center", color: "olive" }}>¿Desea seleccionar manualmente la ubicacion?</span>
            {
              position &&
              <GeolocationGoogle position={position} setPosition={setPosition} />
            }
          </Grid>
        </Grid>
        <Box display="flex" flexDirection="row-reverse" >
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={loading || !nombre.campo || !cedula2 || !celular.campo ||
              !position.lat || !position.lng || (tipoID === '') ||
              !telefono.campo || !correo.campo  || !geografico.sector
              || celular.valido === false || telefono.valido === false || !fechaNacimiento.campo || fechaNacimiento.valido === false
              || !linea 
              || !miniups 
              || !codVendedor || (telefono.campo === celular.campo) || (!validarId(cedula2))
            }
          > Enviar nueva solicitud
          </Button>
        </Box>
      </form>
    </Box >
  );
};