import {
    Box, Button, Paper, Chip, Collapse
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../../../firebase";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from 'moment';//jesus
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { ViewTicketAprobadoInstaladorOperaciones } from "../ViewTicketAprobadoInstaladorOperaciones";

export const TicketsAprobadosOperaciones = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("tickets").where('estado', '==', 1).where('visita_realizada', '==', 1);

        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)

            const startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            const endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where("fecha_gestion_ticket_operaciones", '>=', startfulldate)
            ref = ref.where("fecha_gestion_ticket_operaciones", '<=', endfulldate)

            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }

        //Si tiene algun doc anterior se agrega acontinuacion

    };

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    function Estado({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 0) {
            name = "EN ESPERA";
            color = "warning";
        } else if (estado === 1) {
            name = "REALIZADA";
            color = "success";
        }
        return <Chip label={name} color={color} variant='outlined' size="small" />;
    }

    const columns = [
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="RESPONDIDO" color="success" />
            }
        },
        {
            field: 'visita_realizada', headerName: 'VISITA REALIZADA', width: 150,
            renderCell: (params) => {
                return <Estado
                    estado={params.row.visita_realizada}
                />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 350 },
        { field: 'celular', headerName: 'MOVIL', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        { field: 'flota', headerName: 'FLOTA', width: 100 },
        { field: 'lider_flota', headerName: 'LIDER', width: 280 },
        { field: 'auxiliar_flota', headerName: 'AUXILIAR', width: 250 },
        {
            field: 'fechaTicketRealizado',
            headerName: 'FECHA VISITA REALIZADA',
            type: 'dateTime',
            width: 180,
            valueGetter: ({ value }) => {
                if (value && value.seconds) {
                    return moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss');
                } else {
                    return ''; // Or any other default value or message you want to display
                }
            }
        },
                
        { field: 'usuario_que_asigno_flota', headerName: 'USUARIO QUE RESPONDIO', width: 250 },
        { field: 'zona', headerName: 'ZONA', width: 250 },
        {
            field: 'contactar_con_cliente', headerName: 'CONTACTAR CON CLIENTE', width: 200,
            renderCell: (params) => {
                const respuesta = params.row.contactar_con_cliente === undefined || params.row.contactar_con_cliente === null || params.row.contactar_con_cliente === '' ? '' : params.row.contactar_con_cliente
                return `${respuesta}`
            }
        },
        {
            field: '2', headerName: 'CERRAR TICKET', width: 150,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.visita_realizada === 1 ? <ViewTicketAprobadoInstaladorOperaciones props={params.row} />
                                : ''
                        }
                    </>
                )
            }
        },

    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <Button onClick={() => handleExport()}>
                EXPORTAR
            </Button>
        </GridToolbarContainer>
    );

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {
                    ESTADO: 'RESPONDIDO',
                    VISITA_REALIZADA: doc.visita_realizada === 0 ? 'EN ESPERA' : (doc.visita_realizada === 1 ? 'REALIZADA' : ''),
                    ID_TICKET: doc.uid_ticket,
                    NOMBRE_CLIENTE: doc.nombre_cliente,
                    TELEFONO: doc.telefono,
                    MOVIL: doc.celular,
                    AGENDADO_DESDE: doc.agendado_desde,
                    DEPARTAMENTO: doc.agendado_desde,
                    ASUNTO: doc.asunto_del_ticket,
                    VALOR: doc.valor_a_cobrar,
                    DETALLES_TICKET: doc.detalles_del_ticket,
                    TURNO: doc.turno,
                    FLOTA: doc.flota,
                    LIDER: doc.lider_flota,
                    AUXILIAR: doc.auxiliar_flota,
                    FECHA_VISITA: doc.fechaTicketRealizado === undefined || doc.fechaTicketRealizado === null || doc.fechaTicketRealizado === '' ? '' : moment(new Date(doc.fechaTicketRealizado.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A'),
                    USUARIO_QUE_RESPONDIO: doc.usuario_que_asigno_flota,
                    ZONA: doc.zona,
                    CONTACTAR_CON_CLIENTE: doc.contactar_con_cliente === undefined || doc.contactar_con_cliente === null || doc.contactar_con_cliente === '' ? '' : doc.contactar_con_cliente,
                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "tickets");
        XLSX.writeFile(wb, `Tickets_Gestionados ${date1}.xlsx`)
    }

    return (
        <>
            <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>

                    <Box px={1} pt={1} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Button size="small" variant="contained" color="secondary" onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                        </Box>
                    </Box>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box pt={1}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box>

            <Box px={2} py={2}>
                <Paper>
                    <Box px={2} py={2} sx={{ height: 850, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

        </>
    );
};