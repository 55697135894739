import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper
} from "@mui/material";
import { db, firebase } from "../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from "react";

import Swal from 'sweetalert2'

export const ViewTransferenciaActivosFijos = ({ props }) => {

    const [total, setTotal] = useState(0);

    const [openModal, setOpenModal] = useState(false)

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false)

    const [openDialogRechazar, setOpenDialogRechazar] = useState(false)

    const [data, setData] = useState('')

    const openConfirmModal = () => {
        setOpenDialogConfirm(true)
        setOpenModal(false)
    }

    const openRechazoModal = () => {
        setOpenDialogRechazar(true)
        setOpenModal(false)
    }

    const closeConfirmModal = () => {
        setOpenDialogConfirm(false)
        setOpenModal(true)
    }

    const closeRechazoModal = () => {
        setOpenDialogRechazar(false)
        setOpenModal(true)
    }

    const aprobarTransferencia = () => {
        db.collection('transferencia_activos_fijos').doc(props.uidSolicitud).update({
            estado: 2,
            fecha_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenDialogConfirm(false)
        Swal.fire({ icon: "success", text: 'Se ha aprobado la transferencia con exito', });

    }

    const rechazarTransferencia = () => {
        db.collection('transferencia_activos_fijos').doc(props.uidSolicitud).update({
            estado: 1,
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenDialogRechazar(false)
        Swal.fire({ icon: "success", text: 'Se ha rechazado la transferencia con exito', });

    }

    const getItems = () => {
        let ref = db.collection('transferencia_activos_fijos').doc(props.uidSolicitud).collection('items')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
        })
    }

    const dispatch = useDispatch();
    useEffect(() => {
        getItems()
    }, [dispatch])

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'codigo', headerName: 'CODIGO', width: 140 },
        { field: 'categoria', headerName: 'CATEGORIA', width: 140 },
        { field: 'sub_categoria', headerName: 'SUBCATEGORIA', width: 140 },
        { field: 'descripcion_producto', headerName: 'DESCRIPCION', width: 140 },
        { field: 'categoria', headerName: 'CATEGORIA', width: 140 },
        { field: 'cantidad_pedida', headerName: 'CANTIDAD PEDIDA', width: 140 },
        /* {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <UpdateActivoFijo props={params.row} />
            }
        },
        {
            field: '2', headerName: 'ELIMINAR', width: 90,
            renderCell: (params) => {
                return <DeleteActivoFijo props={params.row} />
            }
        }, */

    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* DIALOG CUENTAS*/}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                <DialogTitle><strong>ITEMS</strong></DialogTitle>
                <DialogContent>

                    <Box py>
                        <Paper>
                            <Box sx={{ height: 600, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,

                                    }}

                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    <Button variant="contained" color="warning" size="small" onClick={() => openRechazoModal()}>RECHAZAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => openConfirmModal()}>APROBAR</Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG CONFIRMAR*/}
            <Dialog open={openDialogConfirm} onClose={() => setOpenDialogConfirm(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>ESTA SEGURO SE APROBAR LA TRANSFERENCIA</strong></DialogTitle>
                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => closeConfirmModal()}>CANCELAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => aprobarTransferencia()}>APROBAR</Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG RECHAZAR*/}
            <Dialog open={openDialogRechazar} onClose={() => setOpenDialogRechazar(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>ESTA SEGURO SE RECHAZAR LA TRANSFERENCIA</strong></DialogTitle>
                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => closeRechazoModal()}>CANCELAR</Button>
                    <Button variant="contained" color="error" size="small" onClick={() => rechazarTransferencia()}>RECHAZAR</Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
