import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConstructionIcon from '@mui/icons-material/Construction';
export const ListInstaladores = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'tecnico']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tecnicos" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`Instaladores/GestionInstaladores/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Asignacion orden de trabajo" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`Instaladores/registro-orden-de-trabajo/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Registro orden de trabajo" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`solicitud/compra/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Pedido" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`Instaladores/tickets`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets" />
                        </ListItemButton>

                        {/* <ListItemButton component={RouterLink} to={`Instaladores/materiales-disponibles`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Materiales disponibles" />
                        </ListItemButton> */}

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}