import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase, storage } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

export const NuevaVisitaNodo = () => {

    const useStorage = storage;

    const [ImagenAntes, setImagenAntes] = useState(null);

    const [viewImagenAntes, setViewImagenAntes] = useState(null)

    const [ImagenDespues, setImagenDespues] = useState(null);

    const [viewImagenDespues, setViewImagenDespues] = useState(null)


    const changeImagenAntes = async (e) => {
        setImagenAntes(e.target.files[0]);
        setViewImagenAntes(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenAntes);
    }

    const changeImagenDespues = async (e) => {
        setImagenDespues(e.target.files[0]);
        setViewImagenDespues(URL.createObjectURL(e.target.files[0]));
        console.log(ImagenDespues);
    }

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [open, setOpen] = useState(false);

    const [nombres, setNombres] = useState('')

    const [cedula, setCedula] = useState('')

    const [fecha, setFecha] = useState(null)

    const [horaEntrada, setHoraEntrada] = useState(null)

    const [horaSalida, setHoraSalida] = useState(null)

    const [tareaRealizada, setTareaRealizada] = useState('')

    const [observacion, setObservacion] = useState('')

    const [firma, setFirma] = useState('')

    const [numeroVisitaNodo, setNumeroVisitaNodo] = useState('')

    const [uidVisitaNodo, setUidVisitaNodo] = useState('')

    const [ref, setRef] = useState(null);

    const [refDespues, setRefDespues] = useState(null);

    var imagenUrl;

    useEffect(() => {
        consultaUid()
    }, [])

    const consultaUid = () => {
        const ref = db
            .collection("visitas_nodos_realizadas")
            .orderBy("created", "desc")
            .limit(1);
        ref.onSnapshot((snapshot) => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                const solicitud = solicitudes[0];
                const numeroSolicitud = solicitud.numero_visita_nodo + 1;
                const uidSolicitud = `VISITA-NODO-` + numeroSolicitud;
                setNumeroVisitaNodo(numeroSolicitud);
                setUidVisitaNodo(uidSolicitud)
            }
        });
    }

    const handleSubmit = async () => {

        const newRef = useStorage.ref('visita_nodos').child(uidVisitaNodo).child('IMAGEN-ANTES'); // nombre del archivo
        setRef(newRef);
        await newRef.put(ImagenAntes);
        const urlImagen = await newRef.getDownloadURL()
        // imagenUrl = urlImagen;
        console.log('la url de la imagen es' + urlImagen);

        const newRefDespues = useStorage.ref('visita_nodos').child(uidVisitaNodo).child('IMAGEN-DESPUES'); // nombre del archivo
        setRefDespues(newRefDespues);
        await newRefDespues.put(ImagenDespues);
        const urlImagenDespues = await newRefDespues.getDownloadURL()
        // imagenUrl = urlImagenDespues;
        console.log('la url de la imagen es' + urlImagenDespues);

        db.collection("visitas_nodos_realizadas").doc(uidVisitaNodo).set({
            nombres_apellidos: nombres.toUpperCase(),
            cedula: cedula,
            fecha: fecha,
            hora_entrada: horaEntrada,
            hora_salida: horaSalida,
            tarea_realizada: tareaRealizada.toUpperCase(),
            observacion: observacion.toUpperCase(),
            firma: firma.toUpperCase(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            /* usuario_que_realizo_la_visita: userInfo.displayName, */
            numero_visita_nodo: numeroVisitaNodo,
            uid_visita_nodo: uidVisitaNodo,
            imagen_antes: urlImagen,
            imagen_despues: urlImagenDespues,
        }).then(() => {
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha realizado la gestion con exito", });
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        })
            .catch((error) => { console.error("Error adding document: ", error); });
    }

    return (
        <>
            <br />
            <Card>
                <CardContent>

                    <Card>
                        <CardContent>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRES Y APELLIDOS</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={nombres}
                                        onChange={(e) => setNombres(e.target.value)}
                                        name="codigoProducto"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FECHA</strong></p>
                                            <FormControl fullWidth>
                                                <DatePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="Seleccione"
                                                    value={fecha}
                                                    onChange={(newValue) => {
                                                        setFecha(newValue);
                                                    }}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </Box>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>HORA SALIDA</strong></p>
                                        <FormControl fullWidth>
                                            <TimePicker
                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                label="Seleccione"
                                                value={horaSalida}
                                                onChange={(newValue) => {
                                                    setHoraSalida(newValue);
                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>OBSERVACION</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={observacion}
                                            onChange={(e) => setObservacion(e.target.value)}
                                            name="codigoProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <Box
                                        py={2}>
                                        <Button
                                            size="small"
                                            onChange={changeImagenAntes}
                                            variant="contained"
                                            component="label"
                                        >
                                            AGREGAR IMAGEN ANTES
                                            <input
                                                type="file"
                                                hidden
                                            />
                                        </Button>
                                    </Box>

                                    <img width={200} src={viewImagenAntes} />


                                </Grid>

                                <Grid item xs={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CEDULA</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={cedula}
                                        onChange={(e) => setCedula(e.target.value)}
                                        name="codigoProducto"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>HORA ENTRADA</strong></p>
                                            <FormControl fullWidth>
                                                <TimePicker
                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                    label="Seleccione"
                                                    value={horaEntrada}
                                                    onChange={(newValue) => {
                                                        setHoraEntrada(newValue);
                                                    }}
                                                />
                                            </FormControl>
                                        </LocalizationProvider>
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TAREA REALIZADA</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={tareaRealizada}
                                        onChange={(e) => setTareaRealizada(e.target.value)}
                                        name="codigoProducto"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FIRMA</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={firma}
                                            onChange={(e) => setFirma(e.target.value)}
                                            name="codigoProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <Box
                                        py={2}>
                                        <Button
                                            size="small"
                                            onChange={changeImagenDespues}
                                            variant="contained"
                                            component="label"
                                        >
                                            AGREGAR IMAGEN DESPUES
                                            <input
                                                type="file"
                                                hidden
                                            />
                                        </Button>
                                    </Box>

                                    <img width={200} src={viewImagenDespues} />

                                </Grid>

                            </Grid>


                        </CardContent>

                    </Card>

                </CardContent>
                <CardActions>

                    <br />
                    <br />
                    <Button
                        onClick={() => { setOpen(true) }}
                        fullWidth
                        variant="contained"
                        disableElevation
                        disabled={
                            !nombres ||
                            !cedula ||
                            !fecha ||
                            !horaEntrada ||
                            !horaSalida ||
                            !tareaRealizada ||
                            !observacion ||
                            !firma ||
                            !ImagenAntes ||
                            !ImagenDespues
                        }
                    >
                        {" "}
                        CONFIRMAR VISITA{" "}
                    </Button>
                </CardActions>
            </Card>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="sm">
                <DialogTitle><strong>¿ESTA SEGURO DE QUE TODOS LOS CAMPOS SE LLENARON CORRECTAMENTE?</strong>
                </DialogTitle>
                <DialogContent dividers>

                </DialogContent>
                <DialogActions>

                    <Button color="error" disableElevation onClick={() => {
                        setOpen(false)
                    }} variant="contained">
                        {" "}
                        REGRESAR
                    </Button>
                    <Button color="success"
                        disableElevation
                        onClick={() => {
                            handleSubmit()
                        }} variant="contained">

                        CONFIRMAR
                    </Button>
                </DialogActions>
            </Dialog>


        </>
    );
};
