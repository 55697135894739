import React, { useState, useEffect } from 'react'
import {
    Card, Autocomplete, Checkbox, Stack,
    CardContent, InputLabel, FormControl,
    Select, Box, MenuItem, Grid, TextField,
    Button, Typography, CardActions, Paper,
    Dialog, DialogTitle, Container, DialogContent, DialogActions, CircularProgress
} from '@mui/material';
import {
    DataGrid,
    esES,
    useGridApiContext
} from '@mui/x-data-grid'
import xml2js from "xml2js";
import { auth, db, firebase } from '../../../../firebase';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Swal from 'sweetalert2';
export const CreditosProvedor2 = () => {

    const [proveedores, setProveedores] = useState([])
    const [proveedor, setProveedor] = useState('')
    const [direccion, setDireccion] = useState('')
    const [tributario, setTributario] = useState('')
    const [formaPago, setFormaPago] = useState('')
    const [comprobante, setComprobante] = useState('')

    const [manual, setManual] = useState(true)

    const [guardar, setGuardar] = useState(false)

    const [comprobantes, setComprobantes] = useState([])
    const [tipos, setTipos] = useState([])
    const [contribuyente, setContribuyente] = useState('')
    const [totalImpuestos, setTotalImpuestos] = useState('')
    const [pagar, setPagar] = useState('')
    const [asiento1, setAsiento1] = useState(0)
    const [asiento2, setAsiento2] = useState(0)

    const [loading, setLoading] = useState(false)


    const [objProveedor, setObjProveedor] = useState(null)

    const fechaRegistro = new Date()
    const [fechaEmision, setFechaEmision] = useState(new Date())
    const periodo = moment(fechaRegistro.toISOString()).format('MM/YYYY')
    const [fechaVencimiento, setFechaVencimiento] = useState(new Date())

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [data4, setData4] = useState([])
    //

    const [secuenciales, setSecuenciales] = useState([])


    const [identificacion, setIdentificacion] = useState('')
    const [establecimiento, setEstablecimiento] = useState('')
    const [emision, setEmision] = useState('')
    const [secuencial, setSecuencial] = useState('')
    // const [autorizacion, setAutorizacion] = useState('')
    // const [diario, setDiario] = useState('')
    // const [categoria, setCategoria] = useState('')

    const [archivo, setArchivo] = useState('')
    const [orden, setOrden] = useState('')

    const [ordenes, setOrdenes] = useState([])

    const [todo, setTodo] = useState(false)


    // const [descripcion, setDescripcion] = useState('')
    const [IVAS, setIVAS] = useState([])

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const [gastos, setGastos] = React.useState([]);
    const [inventario, setInventario] = React.useState([]);

    const [formasPago, setFormasPago] = React.useState([]);
    const [sustentos, setSustentos] = React.useState([]);
    const [retenciones, setRetenciones] = React.useState([]);
    const [retenciones2, setRetenciones2] = React.useState([]);
    const [contribuyentes, setContribuyentes] = React.useState([]);


    const [subtotal, setSubtotal] = React.useState(0);
    const [totalICE, setTotalICE] = React.useState(0);
    const [totalIVA, setTotalIVA] = React.useState(0);
    const [totalRetenido, setTotalRetenido] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [descuento, setDescuento] = useState(0)

    const [electronica, setElectronica] = useState("NO")
    const [caja, setCaja] = useState("NO")


    const [compras, setCompras] = useState([])
    // const [secuencialRetencion, setSecuencialRetencion] = useState("")

    const [cuentaProveedor, setCuentaProveedor] = React.useState('');
    const [cuentaICE, setCuentaICE] = React.useState('');
    const [cuentaCompra, setCuentaCompra] = React.useState('');

    // const meses = ['01', '02', '03', '04', '05', '06',
    //     '07', '08', '09', '10', '11', '12']
    // const años = [(fechaRegistro.getFullYear() + ''), ((fechaRegistro.getFullYear() - 1) + '')]

    const [cuentas, setCuentas] = useState([]);

    const [catalogo, setCatalogo] = useState([]);

    const [catalogoRenta, setCatalogoRenta] = useState([]);
    const [catalogoIVA, setCatalogoIVA] = useState([]);

    const [blocks, setBlocks] = useState([]);
    const [blockProveedor, setBlockProveedor] = useState('')


    const [glosaCompra, setGlosaCompra] = useState('')
    const [glosaRetencion, setGlosaRetencion] = useState('')


    // useEffect(() => {
    //     setSecuencialRetencion(secuenciales
    //         .filter((val) => (val.documento === comprobante)
    //             && (val.correo) === auth.currentUser.email)[0] ?? '')

    // }, [secuenciales, comprobante])


    function limpiar() {
        setCatalogoIVA([])
        setComprobante('')
        setCatalogoRenta([])
        setCuentaCompra('')
        setCuentaProveedor('')
        setCaja('NO')
        setElectronica('NO')
        setGuardar(false)
        setData([])
        setData2([])
        setData3([])
        setData4([])
        setTodo(false)

        setSubtotal(0)
        setTotalICE(0)
        setTotalIVA(0)
        setTotalRetenido(0)
        setTotal(0)
        setDescuento(0)

        setGlosaCompra('')
        setGlosaRetencion('')

        setBlockProveedor('')

        setIdentificacion('')
        setEstablecimiento('')
        setEmision('')
        setSecuencial('')
        setArchivo('')
        setOrden('')

        setFechaEmision(new Date())
        setProveedor('')
        setTributario('')
        setLoading(false)
    }


    function limpiar2() {
        setCatalogoIVA([])
        setCatalogoRenta([])
        setCuentaCompra('')
        setGlosaCompra('')
        setGlosaRetencion('')
        setCuentaProveedor('')
        setCaja('NO')
        setElectronica('NO')
        setData([])
        setData2([])
        setData3([])
        setData4([])
        setGuardar(false)
        setSubtotal(0)
        setTodo(false)
        setTotalICE(0)
        setTotalIVA(0)
        setTotalRetenido(0)
        setTotal(0)
        setDescuento(0)
        setBlockProveedor('')
        setIdentificacion('')
        setEstablecimiento('')
        setEmision('')
        setSecuencial('')
        setArchivo('')
        setOrden('')
        setFechaEmision(new Date())
        setProveedor('')
        setTributario('')
    }


    function validarInfo(datos) {
        if (datos.length === 0) {
            setTodo(false)
            setGuardar(false)
        }
        let lista = []
        let lista2 = []
        let lista3 = []
        for (const el of datos) {
            if (el.relacionProducto === '' || el.codigoIva === '' || el.codigoRenta === '') {
                setTodo(false)
                setGuardar(false)
            }
            else {
                lista.push(el.relacionProducto.codigo + '-' + el.relacionProducto.descripcion)
                lista2.push(el.codigoRenta)
                lista3.push(el.codigoIva)
            }
        }

        lista = Array.from(new Set(lista));
        lista2 = Array.from(new Set(lista2));
        lista3 = Array.from(new Set(lista3));
        setTodo((lista.length === 1 && lista2.length === 1 && lista3.length === 1))
    }


    useEffect(() => {
        if (proveedores.length === 0) {
            getProveedores()
            getComprobantes()
            getTipos()
            getIVAS()
            getOrdenes()
            getFormasPago()
            getSecuencial()
            getSustentos()
            getTipoContribuyente()
            getRetenciones()
            getRetenciones2()
            getCuentas()
            cargarGastos()
            getCompras()
            getBlock()
            getInventario()
            getCatalogo()
        }
    }, [proveedores])


    useEffect(() => {
        if (contribuyente !== '' && contribuyente !== null
            && formaPago !== '' && formaPago !== null) {
            let prov = proveedores.filter((item) =>
                item.identificacion === proveedor)

            if (prov[0] && prov[0].productos) {
                let lista1 = filtroIVA()
                lista1 = lista1.concat([prov[0].productos.retencionIva])
                lista1 = Array.from(new Set(lista1));
                setCatalogoIVA(lista1)

                let lista2 = filtroRENTA()
                lista2 = lista2.concat([prov[0].productos.retencionRenta])
                lista2 = Array.from(new Set(lista2));
                setCatalogoRenta(lista2)
            } else {
                setCatalogoIVA(filtroIVA())
                setCatalogoRenta(filtroRENTA())
            }

        }
    }, [contribuyente, formaPago])

    useEffect(() => {
        let prov = proveedores.filter((item) =>
            item.identificacion === proveedor)
        setObjProveedor(prov[0] ? prov[0] : null)
        setContribuyente(prov[0] ? prov[0].regimen : '')
        setIdentificacion(prov[0] ? prov[0].identificacion : '')
        setDireccion(prov[0] ? prov[0].direccion : '')

        if (prov[0]) {
            let sustent = sustentos.filter((item) =>
                item.codigo === prov[0].sustento_tributario.split('-')[0])
            setTributario(sustent[0] ? sustent[0].codigo + "-" + sustent[0].nombre : '')
        } else {
            setTributario('')
        }


        setFormaPago(prov[0] ? prov[0].forma_pago_asignada : '')
        if (prov[0] ? prov[0].credito : false) {
            setFechaVencimiento(new Date(fechaRegistro.getTime() + (parseInt(prov[0].credito) * 86400000)))
        } else {
            setFechaVencimiento(new Date())
        }
        if (prov[0] ? prov[0].cuenta : false) {
            let campo = cuentas.filter((item) =>
                item.id === prov[0].cuenta.split('-')[0].split(' ')[0])[0]
            setCuentaProveedor(campo ?? '')
        } else {
            setCuentaProveedor('')
        }

        if (prov[0] ? prov[0].compras : false) {
            let cuentaCompra = compras.filter((item) =>
                item.nombre === prov[0].compras)
            if (cuentaCompra.length > 0) {
                setCuentaCompra(cuentas.filter((item) =>
                    item.id === cuentaCompra[0].cuenta_contable.split('-')[0].split(' ')[0])[0])
            } else {
                setCuentaCompra('')
            }
        } else {
            setCuentaCompra('')
        }



        let bloque = []
        for (const bl of blocks) {
            if (bl.idProveedor === proveedor) {
                let inicio = bl.fechaInicio.split("/")
                let fin = bl.fechaFin.split("/")
                let hoy = new Date()
                inicio = new Date(inicio[1] + '-' + inicio[0] + '-' + inicio[2])
                fin = new Date(fin[1] + '-' + fin[0] + '-' + fin[2])
                if (fin >= hoy && inicio <= hoy) {
                    bloque.push(bl)
                }
            }
        }
        if (bloque[0]) {
            setBlockProveedor(bloque)
        } else {
            setBlockProveedor('')
        }

    }, [proveedor])

    function filtroIVA(tipoPro2) {
        let lista = []
        catalogo.map((item) => {
            if (item.formaPago === formaPago && item.tipoContribuyente === contribuyente && item.tipoRetencion === 'IVA') {
                if (tipoPro2 !== undefined) {
                    if (tipoPro2 === item.tipoProducto) {
                        lista.push(item.porcentajeCod)
                    }
                } else {
                    lista.push(item.porcentajeCod)
                }
            }
        })
        lista = Array.from(new Set(lista));
        if (lista.length !== 0) return lista
        return retenciones2 ? [...retenciones2.map(val => { return `${val.codigo}-(${val.porcentaje}%)` })] : [];
    }

    function filtroRENTA(tipoPro2) {
        let lista = []
        catalogo.map((item) => {
            if (item.formaPago === formaPago && item.tipoContribuyente === contribuyente && item.tipoRetencion === 'RENTA') {
                if (tipoPro2 !== undefined) {
                    if (tipoPro2 === item.tipoProducto) {
                        lista.push(item.porcentajeCod)
                    }
                } else {
                    lista.push(item.porcentajeCod)
                }
            }
        })
        lista = Array.from(new Set(lista));
        if (lista.length !== 0) return lista
        return retenciones ? [...retenciones.map(val => { return `${val.codigo}-(${val.porcentaje}%)` })] : [];
    }

    function getCuentas() {
        let docRef = db.collection("plan_cuentas_test").where('nivel', '==', 5)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setCuentas(arrayMessage)
            let cu = arrayMessage.filter((val) => val.nombre.includes(' ICE'))
            setCuentaICE(cu[0] ? cu[0] : '')
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function getBlock() {
        let docRef = db.collection("cuentas_pagar/colecciones/block")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setBlocks(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function getCompras() {
        let docRef = db.collection("cuentas_pagar/parametros/tipos_compras")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setCompras(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function getSecuencial() {
        let docRef = db.collection("cuentas_pagar/parametros/secuencial")
        docRef.onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setSecuenciales(arrayMessage)
        })
    }

    function calcularCompra(datos, total, totalIVA, totalICE) {
        if (datos.length > 0) {
            let lista6 = []
            let cuentasProducto = []
            for (const el of datos) {
                if (el.relacionProducto !== '') {
                    for (const cuen of el.relacionProducto.cuentas_contables) {
                        if (!cuen.nombre.includes('IVA ')) {
                            if (!cuentasProducto[cuen.id]) {
                                cuentasProducto[cuen.id] = {
                                    tipo: 'DEBE',
                                    cuenta: cuen.id,
                                    nombre: cuen.id + ' - ' + cuen.nombre,
                                    valor: parseFloat((el.subtotal).toFixed(2)),
                                }
                            } else {
                                cuentasProducto[cuen.id] = {
                                    ...cuentasProducto[cuen.id],
                                    valor: cuentasProducto[cuen.id].valor + parseFloat((el.subtotal).toFixed(2)),
                                }
                            }

                        }
                    }
                }
            }
            for (const cu of Object.keys(cuentasProducto)) {
                lista6.push({
                    ...cuentasProducto[cu],
                    valor: parseFloat((cuentasProducto[cu].valor).toFixed(2)),
                    id: lista6.length,
                })
            }

            if (cuentaCompra !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaCompra.id,
                    nombre: cuentaCompra.id + ' - ' + cuentaCompra.nombre,
                    valor: parseFloat((totalIVA).toFixed(2)),
                })
            }
            if (cuentaICE !== '' && totalICE !== 0) {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaICE.id,
                    nombre: cuentaICE.id + ' - ' + cuentaICE.nombre,
                    valor: parseFloat((totalICE).toFixed(2)),
                })
            }
            if (cuentaProveedor !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'HABER',
                    cuenta: cuentaProveedor.id,
                    nombre: cuentaProveedor.id + ' - ' + cuentaProveedor.nombre,
                    valor: parseFloat(parseFloat(total).toFixed(2)),
                })
            }
            setData4(lista6)
            validarInfo(datos)
            setData(datos)
        }
    }

    function calcularRetencion(datos) {
        if (datos.length > 0) {
            let lista = []
            let codigos = []
            for (const el of datos) {
                if (el.codigoRenta && el.codigoRenta !== '') {
                    if (!lista[el.codigoRenta]) {
                        lista[el.codigoRenta] = 0
                        codigos.push(el.codigoRenta)
                    }
                    lista[el.codigoRenta] = lista[el.codigoRenta] +
                        (el.subtotal !== undefined && el.subtotal !== ''
                            ? parseFloat(el.subtotal) : 0)
                    lista[el.codigoRenta] = parseFloat(lista[el.codigoRenta].toFixed(4))
                }

                if (el.codigoIva && el.codigoIva !== '') {
                    if (!lista[el.codigoIva]) {
                        lista[el.codigoIva] = 0
                        codigos.push(el.codigoIva)
                    }
                    lista[el.codigoIva] = lista[el.codigoIva] +
                        (el.valor_iva !== undefined && el.valor_iva !== ''
                            ? parseFloat(el.valor_iva) : 0)
                    lista[el.codigoIva] = parseFloat(lista[el.codigoIva].toFixed(4))
                }
            }
            let lista2 = []
            let lista3 = []

            for (const el1 of codigos) {
                let retencion = retenciones.filter((item) => item.codigo === el1.split('-')[0])[0];
                if (retencion === undefined) {
                    retencion = retenciones2.filter((item) => item.codigo === el1.split('-')[0])[0];
                }
                let porcentaje = parseFloat((parseFloat(retencion.porcentaje) / 100).toFixed(4))

                let cuenta = retencion.cuenta_contable.split(' ')[0]
                cuenta = cuentas.filter((item) => item.id === cuenta)[0]
                if (cuenta) {
                    lista3.push({
                        tipo: 'HABER',
                        cuenta: cuenta.id,
                        nombre: retencion.cuenta_contable,
                        valor: parseFloat((lista[el1] * porcentaje).toFixed(2))
                    })
                }
                lista2.push({
                    codigo: el1,
                    impuesto: retencion.tipo,
                    porcentaje: porcentaje,
                    cuentaRetencion: retencion.cuenta_contable,
                    descripcion: retencion.nombre,
                    baseImponible: lista[el1],
                    valorRetenido: parseFloat((lista[el1] * porcentaje).toFixed(2)),
                })
            }
            setData2(lista2)

            let listaCuentas = []
            for (const el2 of lista3) {
                if (!listaCuentas[el2.cuenta]) {
                    listaCuentas[el2.cuenta] = { ...el2 }
                } else {
                    let dato = listaCuentas[el2.cuenta]
                    dato.valor = dato.valor + el2.valor
                    listaCuentas[el2.cuenta] = { ...dato }
                }
            }
            let lista4 = Object.keys(listaCuentas)
            let lista5 = []
            let total2 = 0
            lista4.map((item, index) => {
                total2 = total2 + listaCuentas[item].valor
                lista5.push({
                    ...listaCuentas[item],
                    id: index,
                })
            })
            setTotalRetenido((total2).toFixed(2))

            if (cuentaProveedor !== '' && cuentaProveedor.id !== undefined) {
                lista5.push({
                    id: lista5.length,
                    tipo: 'DEBE',
                    cuenta: cuentaProveedor.id,
                    nombre: cuentaProveedor.id + ' - ' + cuentaProveedor.nombre,
                    valor: parseFloat((total2).toFixed(2)),
                })
            }
            setData3(lista5)

            let lista6 = []
            let cuentasProducto = []
            for (const el of datos) {
                if (el.relacionProducto !== '') {
                    for (const cuen of el.relacionProducto.cuentas_contables) {
                        if (!cuen.nombre.includes('IVA ')) {
                            if (!cuentasProducto[cuen.id]) {
                                cuentasProducto[cuen.id] = {
                                    tipo: 'DEBE',
                                    cuenta: cuen.id,
                                    nombre: cuen.id + ' - ' + cuen.nombre,
                                    valor: parseFloat((el.subtotal).toFixed(2)),
                                }
                            } else {
                                cuentasProducto[cuen.id] = {
                                    ...cuentasProducto[cuen.id],
                                    valor: cuentasProducto[cuen.id].valor + parseFloat((el.subtotal).toFixed(2)),
                                }
                            }

                        }
                    }
                }
            }
            for (const cu of Object.keys(cuentasProducto)) {
                lista6.push({
                    ...cuentasProducto[cu],
                    valor: parseFloat((cuentasProducto[cu].valor).toFixed(2)),
                    id: lista6.length,
                })
            }

            if (cuentaCompra !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaCompra.id,
                    nombre: cuentaCompra.id + ' - ' + cuentaCompra.nombre,
                    valor: parseFloat((totalIVA).toFixed(2)),
                })
            }
            if (cuentaICE !== '' && totalICE !== 0) {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaICE.id,
                    nombre: cuentaICE.id + ' - ' + cuentaICE.nombre,
                    valor: parseFloat((totalICE).toFixed(2)),
                })
            }
            if (cuentaProveedor !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'HABER',
                    cuenta: cuentaProveedor.id,
                    nombre: cuentaProveedor.id + ' - ' + cuentaProveedor.nombre,
                    valor: parseFloat(parseFloat(total).toFixed(2)),
                })
            }
            setData4(lista6)
            validarInfo(datos)
            setData(datos)
        }
    }

    function calcularRetencion2(datos, total, totalIVA, totalICE) {
        if (datos.length > 0) {
            let lista = []
            let codigos = []
            for (const el of datos) {
                if (el.codigoRenta && el.codigoRenta !== '') {
                    if (!lista[el.codigoRenta]) {
                        lista[el.codigoRenta] = 0
                        codigos.push(el.codigoRenta)
                    }
                    lista[el.codigoRenta] = lista[el.codigoRenta] +
                        (el.subtotal !== undefined && el.subtotal !== ''
                            ? parseFloat(el.subtotal) : 0)
                    lista[el.codigoRenta] = parseFloat(lista[el.codigoRenta].toFixed(4))
                }

                if (el.codigoIva && el.codigoIva !== '') {
                    if (!lista[el.codigoIva]) {
                        lista[el.codigoIva] = 0
                        codigos.push(el.codigoIva)
                    }
                    lista[el.codigoIva] = lista[el.codigoIva] +
                        (el.valor_iva !== undefined && el.valor_iva !== ''
                            ? parseFloat(el.valor_iva) : 0)
                    lista[el.codigoIva] = parseFloat(lista[el.codigoIva].toFixed(4))
                }
            }
            let lista2 = []
            let lista3 = []

            for (const el1 of codigos) {
                let retencion = retenciones.filter((item) => item.codigo === el1.split('-')[0])[0];
                if (retencion === undefined) {
                    retencion = retenciones2.filter((item) => item.codigo === el1.split('-')[0])[0];
                }
                let porcentaje = parseFloat((parseFloat(retencion.porcentaje) / 100).toFixed(4))

                let cuenta = retencion.cuenta_contable.split(' ')[0]
                cuenta = cuentas.filter((item) => item.id === cuenta)[0]
                if (cuenta) {
                    lista3.push({
                        tipo: 'HABER',
                        cuenta: cuenta.id,
                        nombre: retencion.cuenta_contable,
                        valor: parseFloat((lista[el1] * porcentaje).toFixed(2))
                    })
                }
                lista2.push({
                    codigo: el1,
                    impuesto: retencion.tipo,
                    porcentaje: porcentaje,
                    cuentaRetencion: retencion.cuenta_contable,
                    descripcion: retencion.nombre,
                    baseImponible: lista[el1],
                    valorRetenido: parseFloat((lista[el1] * porcentaje).toFixed(2)),
                })
            }
            setData2(lista2)

            let listaCuentas = []
            for (const el2 of lista3) {
                if (!listaCuentas[el2.cuenta]) {
                    listaCuentas[el2.cuenta] = { ...el2 }
                } else {
                    let dato = listaCuentas[el2.cuenta]
                    dato.valor = dato.valor + el2.valor
                    listaCuentas[el2.cuenta] = { ...dato }
                }
            }
            let lista4 = Object.keys(listaCuentas)
            let lista5 = []
            let total2 = 0
            lista4.map((item, index) => {
                total2 = total2 + listaCuentas[item].valor
                lista5.push({
                    ...listaCuentas[item],
                    id: index,
                })
            })
            setTotalRetenido((total2).toFixed(2))

            if (cuentaProveedor !== '' && cuentaProveedor.id !== undefined) {
                lista5.push({
                    id: lista5.length,
                    tipo: 'DEBE',
                    cuenta: cuentaProveedor.id,
                    nombre: cuentaProveedor.id + ' - ' + cuentaProveedor.nombre,
                    valor: parseFloat((total2).toFixed(2)),
                })
            }
            setData3(lista5)

            let lista6 = []
            let cuentasProducto = []
            for (const el of datos) {
                if (el.relacionProducto !== '') {
                    for (const cuen of el.relacionProducto.cuentas_contables) {
                        if (!cuen.nombre.includes('IVA ')) {
                            if (!cuentasProducto[cuen.id]) {
                                cuentasProducto[cuen.id] = {
                                    tipo: 'DEBE',
                                    cuenta: cuen.id,
                                    nombre: cuen.id + ' - ' + cuen.nombre,
                                    valor: parseFloat((el.subtotal).toFixed(2)),
                                }
                            } else {
                                cuentasProducto[cuen.id] = {
                                    ...cuentasProducto[cuen.id],
                                    valor: cuentasProducto[cuen.id].valor + parseFloat((el.subtotal).toFixed(2)),
                                }
                            }

                        }
                    }
                }
            }
            for (const cu of Object.keys(cuentasProducto)) {
                lista6.push({
                    ...cuentasProducto[cu],
                    valor: parseFloat((cuentasProducto[cu].valor).toFixed(2)),
                    id: lista6.length,
                })
            }

            if (cuentaCompra !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaCompra.id,
                    nombre: cuentaCompra.id + ' - ' + cuentaCompra.nombre,
                    valor: parseFloat((totalIVA).toFixed(2)),
                })
            }
            if (cuentaICE !== '' && totalICE !== 0) {
                lista6.push({
                    id: lista6.length,
                    tipo: 'DEBE',
                    cuenta: cuentaICE.id,
                    nombre: cuentaICE.id + ' - ' + cuentaICE.nombre,
                    valor: parseFloat((totalICE).toFixed(2)),
                })
            }
            if (cuentaProveedor !== '') {
                lista6.push({
                    id: lista6.length,
                    tipo: 'HABER',
                    cuenta: cuentaProveedor.id,
                    nombre: cuentaProveedor.id + ' - ' + cuentaProveedor.nombre,
                    valor: parseFloat(parseFloat(total).toFixed(2)),
                })
            }
            setData4(lista6)
            validarInfo(datos)
            setData(datos)
        }
    }

    const getCatalogo = () => {
        let docRef = db.collection("cuentas_pagar/colecciones/catalogo_retenciones")
        docRef.onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setCatalogo(arrayMessage)
        })
    }

    const getFormasPago = () => {
        let docRef = db.collection("cuentas_pagar/parametros/formas_pago")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setFormasPago(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getSustentos = () => {
        let docRef = db.collection("cuentas_pagar/parametros/sustentos_tributario")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setSustentos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getRetenciones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_fuentes")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id2: item.id, id: item.codigo, tipo: 'RENTA' } })
            setRetenciones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function cargarGastos() {
        db.collection(`gastos`)
            .get().then(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setGastos(arrayMessage)
            })
    }

    function getInventario() {
        db.collection("productos").orderBy('tipoProducto', 'desc')
            .get().then(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                            codigo: doc.data().codigo_producto,
                            descripcion: doc.data().descripcion_producto,
                        }
                    })
                ]
                setInventario(arrayMessage)
            })
    }

    const getRetenciones2 = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_iva")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id2: item.id, id: item.codigo, tipo: 'IVA' } })
            setRetenciones2(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const getTipoContribuyente = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_contribuyente")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setContribuyentes(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    function cargarInfo(orden) {
        const valor = ordenes.filter((item) => item.id === orden)[0];
        let prov = proveedores.filter((item) => item.nombre_comercial === valor.bodega)
        if (prov[0]) {
            setProveedor(prov[0].identificacion)
        } else {
            console.log('No hay proveedor')
        }
        setFechaEmision(valor.created.toDate())
        setTotal(parseFloat((valor.totalCompra).toFixed(2)))
        setTotalIVA(parseFloat((valor.valorIva).toFixed(2)))
        setSubtotal(parseFloat((valor.subTotal).toFixed(2)))
        setDescuento(0.00)
        setTotalICE(0.00)
        let tieneIva = parseFloat(valor.valorIva) > 0
        let docRef = db.collection("orden_compra").doc(orden).collection('items')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            let porce = '0%'
            if (tieneIva) {
                porce = '12%'
            }
            const lista = [...arrayMessage.map((item, index) => {
                let subtotal = parseFloat((parseFloat(item.costo_estandar_producto) * parseInt(item.cantidad_pedida)).toFixed(2))
                return {
                    id: index,
                    codigoPrincipal: item.id,
                    cantidad: item.cantidad_pedida,
                    descripcion: item.descripcion,
                    base_imponible: item.costo_estandar_producto,
                    retencion: porce,
                    subtotal: subtotal,
                    descuento: 0.00,
                    ice: 0.00,
                    codigo: '',
                    codigoPorcentaje: '',
                    tipoProducto: 'INVENTARIO',
                    relacionProducto: inventario.filter((val) => val.id === item.id)[0] ?? inventario.filter((val) => val.descripcion_producto === item.descripcion)[0],
                    codigoRenta: prov[0] && prov[0].productos ? prov[0].productos.retencionRenta : '',
                    codigoIva: prov[0] && prov[0].productos ? prov[0].productos.retencionIva : '',
                    valor_iva: tieneIva ? parseFloat((parseFloat(subtotal) * 0.12).toFixed(2)) : 0.00,
                    total: tieneIva ? parseFloat((parseFloat(subtotal) * 0.12).toFixed(2)) + parseFloat(item.precio_total) : parseFloat(item.precio_total),
                }
            })]
            validarInfo(lista)
            setData(lista)
            setData3([])
            setData4([])
            setData2([])

        }).catch((error) => {
            console.log("Error getting document:", error);
        });


    }


    const getOrdenes = () => {
        let docRef = db.collection("orden_compra").where("estado", "==", 2)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setOrdenes(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const getComprobantes = () => {
        let docRef = db.collection("cuentas_pagar/parametros/tipo_comprobantes")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setComprobantes(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getTipos = () => {
        let docRef = db.collection("configuracion_productos/config/tipo")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setTipos(arrayMessage.filter((val) => val.id !== 'VENTAS' && val.id !== 'ACTIVO FIJO'))
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }



    const getProveedores = () => {
        let docRef = db.collection("proveedores").orderBy('nombre_comercial', 'asc')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProveedores(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const getIVAS = () => {
        let docRef = db.collection("cuentas_pagar/parametros/catalogo_iva")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setIVAS(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function tipoImpuesto(valor) {
        const valores = {
            '2': 'IVA',
            '3': 'ICE',
        }
        return valores[valor]
    }

    function validarCatalogo(datos) {
        let lista = []
        let prov = proveedores.filter((item) => item.identificacion === identificacion)
        let bien = ""
        let servicio = ""
        if (prov[0] ? prov[0].retencion_iva_servicios !== '' : false) {
            let cuenta = retenciones2.filter((item) =>
                item.codigo === prov[0].retencion_iva_servicios.split(" ")[0])
            if (cuenta.length > 0) {
                servicio = `${cuenta[0].codigo}-(${cuenta[0].porcentaje}%)`
            }
        }

        if (prov[0] ? prov[0].retencion_iva_bienes !== '' : false) {
            let cuenta = retenciones2.filter((item) =>
                item.codigo === prov[0].retencion_iva_bienes.split(" ")[0])
            if (cuenta.length > 0) {
                bien = `${cuenta[0].codigo}-(${cuenta[0].porcentaje}%)`
            }
        }

        for (const elemento of datos) {
            let raw = {
                ...elemento
            }
            if (elemento.relacionProducto !== '') {
                if (prov[0] && prov[0].productos &&
                    prov[0].productos.producto.codigo === elemento.relacionProducto.codigo) {
                    // raw = {
                    //     ...raw,
                    //     codigoRenta: prov[0].productos.retencionRenta,
                    //     codigoIva: prov[0].productos.retencionIva
                    // }
                } else {
                    let lista1 = filtroRENTA(elemento.relacionProducto.tipoProducto)
                    let check1 = lista1.every(function (e, i) {
                        if (i == 0) {
                            let start = catalogoRenta.indexOf(e);
                            if (start == -1) return false;
                            else {
                                this.start = start;
                                return true;
                            }
                        } else {
                            return e == catalogoRenta[this.start += 1]
                        }
                    }, {});
                    if (lista1[0] && check1) {
                        raw = {
                            ...raw,
                            codigoRenta: lista1[0]
                        }
                    } else {
                        raw = {
                            ...raw,
                            codigoRenta: catalogoRenta[0]
                        }
                    }
                    // let servicio = ""
                    // if (prov[0] ? prov[0].retencion_iva_servicios !== '' : false) {
                    //     let cuenta = retenciones2.filter((item) =>
                    //         item.codigo === prov[0].retencion_iva_servicios.split(" ")[0])
                    //     if (cuenta.length > 0) {
                    //         servicio = `${cuenta[0].codigo}-(${cuenta[0].porcentaje}%)`
                    //     }
                    // }

                    // let bien = ""
                    // if (prov[0] ? prov[0].retencion_iva_bienes !== '' : false) {
                    //     let cuenta = retenciones2.filter((item) =>
                    //         item.codigo === prov[0].retencion_iva_bienes.split(" ")[0])
                    //     if (cuenta.length > 0) {
                    //         bien = `${cuenta[0].codigo}-(${cuenta[0].porcentaje}%)`
                    //     }
                    // }

                    let lista2 = filtroIVA(elemento.relacionProducto.tipoProducto)
                    let check2 = lista2.every(function (e, i) {
                        if (i == 0) {
                            let start = catalogoIVA.indexOf(e);
                            if (start == -1) return false;
                            else {
                                this.start = start;
                                return true;
                            }
                        } else {
                            return e == catalogoIVA[this.start += 1]
                        }
                    }, {});
                    if (elemento.relacionProducto.tipoProducto === 'BIEN' && bien !== '') {
                        raw = {
                            ...raw,
                            codigoIva: bien
                        }
                    } else if (elemento.relacionProducto.tipoProducto === 'SERVICIO' && servicio !== '') {
                        raw = {
                            ...raw,
                            codigoIva: servicio
                        }
                    }
                    else if (lista2[0] && check2) {
                        raw = {
                            ...raw,
                            codigoIva: lista2[0]
                        }
                    }
                    else {
                        raw = {
                            ...raw,
                            codigoIva: catalogoIVA[0]
                        }
                    }
                }
            }
            lista.push({
                ...raw,
            })
        }
        calcularRetencion(lista)
        validarInfo(lista)
        setData(lista)

    }

    function cargarXML(event) {
        let archivo = event.target.files[0];
        setArchivo(event.target.files[0].name)
        let lector = new FileReader();
        lector.onload = function (e) {
            let contenidoXml = e.target.result;

            let parser = new xml2js.Parser();
            parser.parseString(contenidoXml, function (err, result) {
                if (err) {
                    console.error(err);
                } else {
                    let json = JSON.stringify(result);
                    json = JSON.parse(json);
                    // setAutorizacion(json.autorizacion.numeroAutorizacion[0])
                    parser.parseString(json.autorizacion.comprobante[0], function (err, result) {
                        if (err) {
                            console.error(err);
                        } else {
                            let json2 = JSON.stringify(result);
                            json2 = JSON.parse(json2);

                            let infoTributaria = json2.factura.infoTributaria[0]
                            let prov = proveedores.filter((item) => item.identificacion === infoTributaria.ruc[0])
                            if (prov[0]) {
                                setProveedor(prov[0].identificacion)
                            } else {
                                console.log('No hay proveedor')
                            }
                            let infoFactura = json2.factura.infoFactura[0]
                            setEstablecimiento(infoTributaria.estab[0])
                            setEmision(infoTributaria.ptoEmi[0])
                            setSecuencial(infoTributaria.secuencial[0])
                            let fecha = infoFactura.fechaEmision[0]
                            fecha = fecha.split('/')
                            let fecha44 = new Date(fecha[1] + '-' + fecha[0] + '-' + fecha[2])

                            setFechaEmision(fecha44)

                            setTotalImpuestos(infoFactura.totalConImpuestos[0].totalImpuesto[0].valor[0])

                            let detalles = json2.factura.detalles[0].detalle
                            let lista = []
                            let impuestos = 0.00
                            let impuestos2 = 0.00
                            for (const elemento1 of infoFactura.totalConImpuestos) {
                                for (const elemento2 of elemento1.totalImpuesto) {
                                    let tipoI = tipoImpuesto(elemento2.codigo[0])
                                    if (tipoI === 'IVA') {
                                        impuestos = impuestos + parseFloat(elemento2.valor[0])
                                    }
                                    if (tipoI === 'ICE') {
                                        impuestos2 = impuestos2 + parseFloat(elemento2.valor[0])
                                    }
                                }

                            }
                            setTotalIVA(impuestos)
                            setTotalICE(impuestos2)

                            setTotal(infoFactura.importeTotal[0])
                            setSubtotal(infoFactura.totalSinImpuestos[0])
                            setDescuento(infoFactura.totalDescuento[0])

                            detalles.map((item, index) => {
                                let valorIva = 0
                                let porIva = []
                                let valorIce = 0
                                for (const elemento of detalles[index].impuestos) {
                                    let tipoI = tipoImpuesto(elemento.impuesto[0].codigo[0])
                                    if (tipoI === 'ICE') {
                                        valorIce = parseFloat(elemento.impuesto[0].valor[0])
                                    }
                                    if (tipoI === 'IVA') {
                                        valorIva = parseFloat(elemento.impuesto[0].valor[0])
                                        porIva = IVAS.filter((item) => item.codigo === elemento.impuesto[0].codigoPorcentaje[0])
                                    }
                                }




                                lista.push({
                                    id: index,
                                    codigoPrincipal: detalles[index].codigoPrincipal[0],
                                    cantidad: parseFloat(detalles[index].cantidad[0]),
                                    descripcion: detalles[index].descripcion[0],
                                    base_imponible: parseFloat(parseFloat(detalles[index].precioUnitario[0]).toFixed(4)),
                                    // base_imponible: parseFloat(detalles[index].impuestos[0].impuesto[0].baseImponible[0]),
                                    retencion: porIva.length > 0 ? porIva[0].nombre : '',
                                    subtotal: parseFloat(detalles[index].precioTotalSinImpuesto[0]),
                                    descuento: parseFloat(detalles[index].descuento[0]).toFixed(2),
                                    ice: parseFloat(valorIce.toFixed(2)),
                                    codigo: detalles[index].impuestos[0].impuesto[0].codigo[0],
                                    codigoPorcentaje: detalles[index].impuestos[0].impuesto[0].codigoPorcentaje[0],
                                    tipoProducto: prov[0] && prov[0].productos ? 'GASTOS' : '',
                                    relacionProducto: prov[0] && prov[0].productos ? prov[0].productos.producto : '',
                                    codigoRenta: prov[0] && prov[0].productos ? prov[0].productos.retencionRenta : '',
                                    codigoIva: prov[0] && prov[0].productos ? prov[0].productos.retencionIva : '',
                                    valor_iva: parseFloat(valorIva.toFixed(2)),
                                    total: parseFloat(parseFloat((parseFloat(detalles[index].precioTotalSinImpuesto[0]) +
                                        parseFloat(valorIva + valorIce))).toFixed(2)),
                                })
                            })

                            if (json2.factura.otrosRubrosTerceros) {
                                let rubros = json2.factura.otrosRubrosTerceros[0].rubro
                                rubros.map((item, index) => {
                                    lista.push({
                                        id: lista.length,
                                        codigoPrincipal: "0",
                                        cantidad: 1.00,
                                        descripcion: item.concepto[0],
                                        base_imponible: parseFloat(parseFloat(item.total[0]).toFixed(4)),
                                        // base_imponible: parseFloat(detalles[index].impuestos[0].impuesto[0].baseImponible[0]),
                                        retencion: '0%',
                                        subtotal: parseFloat(parseFloat(item.total[0]).toFixed(4)),
                                        descuento: parseFloat(0.00).toFixed(2),
                                        ice: 0.00,
                                        codigo: "2",
                                        codigoPorcentaje: "0",
                                        tipoProducto: prov[0] && prov[0].productos ? 'GASTOS' : '',
                                        relacionProducto: prov[0] && prov[0].productos ? prov[0].productos.producto : '',
                                        codigoRenta: prov[0] && prov[0].productos ? prov[0].productos.retencionRenta : '',
                                        codigoIva: prov[0] && prov[0].productos ? prov[0].productos.retencionIva : '',
                                        valor_iva: "0.00",
                                        total: parseFloat(item.total[0]).toFixed(2),
                                    })
                                })
                            }
                            validarInfo(lista)
                            setData(lista)
                            setData3([])
                            setData4([])
                            setData2([])
                        }
                    })
                }
            });

        };
        lector.readAsText(archivo);
    }

    function cargarXML2(event) {
        let archivo = event.target.files[0];
        setArchivo(event.target.files[0].name)
        let lector = new FileReader();
        lector.onload = function (e) {
            let contenidoXml = e.target.result;

            let parser = new xml2js.Parser();
            parser.parseString(contenidoXml, function (err, result) {
                if (err) {
                    console.error(err);
                } else {
                    let json = JSON.stringify(result);
                    json = JSON.parse(json);
                    // setAutorizacion(json.autorizacion.numeroAutorizacion[0])
                    parser.parseString(json.autorizacion.comprobante[0], function (err, result) {
                        if (err) {
                            console.error(err);
                        } else {
                            let json2 = JSON.stringify(result);
                            json2 = JSON.parse(json2);
                            let infoTributaria = json2.factura.infoTributaria[0]
                            let prov = proveedores.filter((item) => item.identificacion === infoTributaria.ruc[0])
                            if (prov[0]) {
                                setProveedor(prov[0].identificacion)
                            }
                            let infoFactura = json2.factura.infoFactura[0]
                            setEstablecimiento(infoTributaria.estab[0])
                            setEmision(infoTributaria.ptoEmi[0])
                            setSecuencial(infoTributaria.secuencial[0])
                            let fecha = infoFactura.fechaEmision[0]
                            fecha = fecha.split('/')
                            let fecha44 = new Date(fecha[1] + '-' + fecha[0] + '-' + fecha[2])
                            setFechaEmision(fecha44)
                        }
                    })
                }
            });

        };
        lector.readAsText(archivo);
    }

    function tipoIdentificacion(valor) {
        const valores = {
            'RUC': '04',
            'CEDULA': '05',
            'PASAPORTE': '06',
            'PLACA': '09',
            'RUT': '08'
        }
        return valores[valor]
    }


    function emitirRetencion() {
        setLoading(true)
        let secuencialRetencion = secuenciales
            .filter((val) => (val.documento === 'COMPROBANTE DE RETENCION')
                && (val.correo) === auth.currentUser.email)[0] ?? ''
        if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion !== '') {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                "ambiente": 1,
                "secuencial": parseInt(secuencialRetencion.valor),
                "tipo_emision": 1,
                "periodo_fiscal": periodo,
                "ruc": "0992988061001",
                "establecimiento": {
                    "punto_emision": secuencialRetencion.emision,
                    "codigo": secuencialRetencion.establecimiento,
                    "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
                },
                "sujeto": {
                    "email": "desarrollo@intercommerce.com.ec",
                    "identificacion": identificacion,
                    "tipo_identificacion": tipoIdentificacion(objProveedor.tipo),
                    "razon_social": objProveedor.razon_social,
                    "direccion": objProveedor.direccion,
                    "telefono": objProveedor.telefono1,
                },
                "items": [
                    ...data2.map((val) => {
                        return {
                            "base_imponible": val.baseImponible,
                            "codigo": (val.impuesto === 'IVA' ? '2' : '1'),
                            "codigo_porcentaje": val.codigo.split('-')[0],
                            "fecha_emision_documento_sustento": fechaEmision.toISOString(),
                            "numero_documento_sustento": establecimiento + '-' + emision + '-' + secuencial,
                            "porcentaje": parseFloat(parseFloat(val.porcentaje * 100).toFixed(2)),
                            "tipo_documento_sustento": comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
                            "valor_retenido": val.valorRetenido,
                        }
                    })
                ]
            });
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://serverapiolt.intercommerce.com.ec/datil/datil_retenc/",
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    const re = JSON.parse(result)
                    console.log(re)
                    if (re.es_valida) {
                        db.collection('cuentas_pagar/parametros/secuencial').doc(secuencialRetencion.id).update({
                            valor: re.secuencial + 1,
                        }).then(() => {
                            guardarRetencion(re)
                        }).catch((error) => {
                            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                            setLoading(false)
                        });
                    } else {
                        Swal.fire({ icon: "error", text: re[0].message });
                        setLoading(false)

                    }

                })
                .catch(error => {
                    Swal.fire({ icon: "error", text: "Se ha producido un error " });
                    setLoading(false)
                    console.log('error', error)
                })
        } else if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion === '') {
            setLoading(false)
            Swal.fire({ icon: "error", text: "No tiene asignado un secuencial para retención " });
        }
        else {
            guardarDocumento()
        }
    }

    function emitirRetencion2(datil) {
        setLoading(true)
        let secuencialRetencion = secuenciales
            .filter((val) => (val.documento === 'COMPROBANTE DE RETENCION')
                && (val.correo) === auth.currentUser.email)[0] ?? ''
        if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion !== '') {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                "ambiente": 1,
                "secuencial": parseInt(secuencialRetencion.valor),
                "tipo_emision": 1,
                "periodo_fiscal": periodo,
                "ruc": "0992988061001",
                "establecimiento": {
                    "punto_emision": secuencialRetencion.emision,
                    "codigo": secuencialRetencion.establecimiento,
                    "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
                },
                "sujeto": {
                    "email": "desarrollo@intercommerce.com.ec",
                    "identificacion": identificacion,
                    "tipo_identificacion": tipoIdentificacion(objProveedor.tipo),
                    "razon_social": objProveedor.razon_social,
                    "direccion": objProveedor.direccion,
                    "telefono": objProveedor.telefono1,
                },
                "items": [
                    ...data2.map((val) => {
                        return {
                            "base_imponible": val.baseImponible,
                            "codigo": (val.impuesto === 'IVA' ? '2' : '1'),
                            "codigo_porcentaje": val.codigo.split('-')[0],
                            "fecha_emision_documento_sustento": fechaEmision.toISOString(),
                            "numero_documento_sustento": datil.numero,
                            "porcentaje": parseFloat(parseFloat(val.porcentaje * 100).toFixed(2)),
                            "tipo_documento_sustento": '03',
                            "valor_retenido": val.valorRetenido,
                        }
                    })
                ]
            });
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://serverapiolt.intercommerce.com.ec/datil/datil_retenc/",
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    const re = JSON.parse(result)
                    console.log(re)
                    if (re.es_valida) {
                        db.collection('cuentas_pagar/parametros/secuencial').doc(secuencialRetencion.id).update({
                            valor: re.secuencial + 1,
                        }).then(() => {
                            guardarRetencion(re)
                        }).catch((error) => {
                            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                            setLoading(false)
                        });
                    } else {
                        Swal.fire({ icon: "error", text: re[0].message });
                        setLoading(false)

                    }

                })
                .catch(error => {
                    Swal.fire({ icon: "error", text: "Se ha producido un error " });
                    setLoading(false)
                    console.log('error', error)
                })
        } else if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion === '') {
            setLoading(false)
            Swal.fire({ icon: "error", text: "No tiene asignado un secuencial para retención " });
        } else {
            guardarDocumento()
        }
    }

    function emitirLiquidacion() {
        setLoading(true)
        let secuencialRetencion = secuenciales
            .filter((val) => (val.documento === comprobante)
                && (val.correo) === auth.currentUser.email)[0] ?? ''
        if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion !== '') {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let impuestosIVAS = []
            data.map((val) => {
                if (val.retencion.includes('%')) {
                    if (!impuestosIVAS[val.retencion]) {
                        let tipoIva = IVAS.filter((item) => item.nombre === val.retencion)
                        impuestosIVAS[val.retencion] = {
                            "base_imponible": val.subtotal,
                            "valor": val.valor_iva,
                            "codigo": "2",
                            "codigo_porcentaje": tipoIva[0].codigo + ''
                        }
                    } else {
                        impuestosIVAS[val.retencion] = {
                            ...impuestosIVAS[val.retencion],
                            "base_imponible": impuestosIVAS[val.retencion].base_imponible + val.subtotal,
                            "valor": impuestosIVAS[val.retencion].valor + val.valor_iva,
                        }
                    }
                }
            })
            let impu = []
            for (const ke of Object.keys(impuestosIVAS)) {
                impu.push(impuestosIVAS[ke])
            }
            let raw = JSON.stringify({
                "ambiente": 1,
                "secuencial": parseInt(secuencialRetencion.valor),
                "tipo_emision": 1,
                "ruc": "0992988061001",
                "establecimiento": {
                    "punto_emision": secuencialRetencion.emision,
                    "codigo": secuencialRetencion.establecimiento,
                    "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
                },
                "moneda": "USD",
                "totales": {
                    "total_sin_impuestos": subtotal,
                    "importe_total": total,
                    "descuento": parseFloat(descuento),
                    "impuestos": impu
                },
                "proveedor": {
                    "email": "desarrollo@intercommerce.com.ec",
                    "identificacion": identificacion,
                    "tipo_identificacion": tipoIdentificacion(objProveedor.tipo),
                    "razon_social": objProveedor.razon_social,
                    "direccion": objProveedor.direccion,
                    "telefono": objProveedor.telefono1,
                },
                "items": [
                    ...data.map((val) => {
                        let imp = []
                        let tipoIva = IVAS.filter((item) => item.nombre === val.retencion)
                        if (tipoIva[0]) {
                            imp.push({
                                "base_imponible": val.subtotal,
                                "valor": val.valor_iva,
                                "tarifa": parseInt(tipoIva[0].valor),
                                "codigo": "2",
                                "codigo_porcentaje": tipoIva[0].codigo + ''
                            })
                        }
                        return {
                            "codigo_principal": val.id + '',
                            "cantidad": parseInt(val.cantidad),
                            "precio_unitario": parseFloat(val.base_imponible),
                            "descripcion": val.descripcion,
                            "precio_total_sin_impuestos": parseFloat(val.subtotal),
                            "impuestos": imp,
                            "descuento": val.descuento,
                        }
                    })
                ],
                "pagos": [
                    // ...['01', '16', '19', '18', '20'].map((item) => {
                    ...['20'].map((item) => {
                        return {
                            "forma_pago": item,
                            "total": total,
                        }
                    })
                ]
            });
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch("https://serverapiolt.intercommerce.com.ec/datil/datil_liquidacion_compra/",
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    const re = JSON.parse(result)
                    if (re.es_valida) {
                        db.collection('cuentas_pagar/parametros/secuencial').doc(secuencialRetencion.id).update({
                            valor: re.secuencial + 1,
                        }).then(() => {
                            // guardarLiquidacion(re)
                            let datil = re
                            db.collection("cuentas_pagar/colecciones/documentos").doc().set({
                                tipo: comprobante,
                                proveedor: proveedores.filter((val) => val.identificacion === proveedor)[0].nombre_comercial,
                                modo: manual,
                                tipoDocumento: comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
                                glosaCompra: glosaCompra,
                                idPago: formasPago.filter((val) => val.nombre === formaPago)[0].codigo,
                                glosaRetencion: glosaRetencion,
                                idProveedor: identificacion,
                                codigoSustento: tributario.split("-")[0],
                                codigoContribuyente: contribuyentes.filter((val) => val.nombre === contribuyente)[0].codigo,
                                sustentoTributario: tributario,
                                tipoContribuyente: contribuyente,
                                caja_interna: caja,
                                formaPago: formaPago,
                                listaRetencion: data2,
                                items: data,
                                totalIce: totalICE,
                                totalIVA: totalIVA,
                                asientoCompra: data4,
                                asientoRetencion: data3,
                                idDocumento: datil.emisor.establecimiento.codigo + '-' +
                                    datil.emisor.establecimiento.punto_emision + '-' + datil.secuencial,
                                emision: fechaEmision,
                                subtotal: subtotal,
                                total: total,
                                totalRetenido: totalRetenido,
                                registro: firebase.firestore.FieldValue.serverTimestamp(),
                                vencimiento: fechaVencimiento,
                                created: firebase.firestore.FieldValue.serverTimestamp(),
                                infoDatil: datil ?? '',
                            }).then(() => {
                                Swal.fire({ icon: "success", text: "Se ha creado", });
                                // guardarFactura()
                                setEstablecimiento(datil.numero.split('-')[0])
                                setEmision(datil.numero.split('-')[1])
                                setSecuencial(datil.numero.split('-')[2])
                                emitirRetencion2(datil);
                            }).catch((error) => {
                                setLoading(false)
                                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                                console.error("Error adding document: ", error)
                            });
                        }).catch((error) => {
                            setLoading(false)
                            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                        });
                    } else {
                        setLoading(false)
                        Swal.fire({ icon: "error", text: re[0].message });
                    }
                })
                .catch(error => {
                    Swal.fire({ icon: "error", text: "Se ha producido un error " });
                    setLoading(false)
                    console.log('error', error)
                })
        } else if (objProveedor && electronica === 'ELECTRONICA' && secuencialRetencion === '') {
            setLoading(false)
            Swal.fire({ icon: "error", text: "No tiene asignado un secuencial para liquidación " });
        } else {
            guardarDocumento()
        }
    }

    function guardarRetencion(datil) {
        db.collection("cuentas_pagar/colecciones/documentos").doc().set({
            tipo: 'COMPROBANTE DE RETENCION',
            proveedor: proveedores.filter((val) => val.identificacion === proveedor)[0].nombre_comercial,
            modo: manual,
            glosaCompra: glosaCompra,
            glosaRetencion: glosaRetencion,
            idProveedor: identificacion,
            codigoSustento: tributario.split("-")[0],
            codigoContribuyente: contribuyentes.filter((val) => val.nombre === contribuyente)[0].codigo,
            sustentoTributario: tributario,
            tipoContribuyente: contribuyente,
            factura: establecimiento + '-' + emision + '-' + secuencial,
            caja_interna: caja,
            idPago: formasPago.filter((val) => val.nombre === formaPago)[0].codigo,
            formaPago: formaPago,
            listaRetencion: data2,
            totalIce: totalICE,
            totalIVA: totalIVA,
            items: data,
            asientoCompra: data4,
            asientoRetencion: data3,
            tipoDocumento: comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
            emision: fechaEmision,
            subtotal: subtotal,
            idDocumento: datil.emisor.establecimiento.codigo + '-' +
                datil.emisor.establecimiento.punto_emision + '-' + datil.secuencial,
            total: total,
            totalRetenido: totalRetenido,
            registro: firebase.firestore.FieldValue.serverTimestamp(),
            vencimiento: fechaVencimiento,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            infoDatil: datil ?? '',
        }).then(() => {
            Swal.fire({ icon: "success", text: "Se ha creado", });
            guardarFactura();
        }).catch((error) => {
            setLoading(false)
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function guardarDocumento() {
        setLoading(true)
        db.collection("cuentas_pagar/colecciones/documentosIngresados").doc().set({
            proveedor: proveedores.filter((val) => val.identificacion === proveedor)[0].nombre_comercial,
            tipoDocumento: comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
            glosaCompra: glosaCompra,
            glosaRetencion: glosaRetencion,
            idProveedor: identificacion,
            codigoSustento: tributario.split("-")[0],
            codigoContribuyente: contribuyentes.filter((val) => val.nombre === contribuyente)[0].codigo,
            sustentoTributario: tributario,
            tipoContribuyente: contribuyente,
            factura: establecimiento + '-' + emision + '-' + secuencial,
            caja_interna: caja,
            formaPago: formaPago,
            items: data,
            tipoDocumento: comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
            totalIce: totalICE,
            totalIVA: totalIVA,
            asientoCompra: data4,
            emision: fechaEmision,
            subtotal: subtotal,
            total: total,
            totalRetenido: totalRetenido,
            registro: firebase.firestore.FieldValue.serverTimestamp(),
            vencimiento: fechaVencimiento,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            tipo: comprobante,
            modo: manual,
            idPago: formasPago.filter((val) => val.nombre === formaPago)[0].codigo,
            listaRetencion: data2,
            asientoRetencion: data3,
            establecimiento: establecimiento,
            secuencial: secuencial,
            ptoemision: emision,
        }).then(() => {
            Swal.fire({ icon: "success", text: "Se ha creado", });
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function guardarFactura() {
        db.collection("cuentas_pagar/colecciones/documentosIngresados").doc().set({
            proveedor: proveedores.filter((val) => val.identificacion === proveedor)[0].nombre_comercial,
            tipoDocumento: comprobantes.filter((val) => val.nombre === comprobante)[0].codigo,
            glosaCompra: glosaCompra,
            glosaRetencion: glosaRetencion,
            idProveedor: identificacion,
            codigoSustento: tributario.split("-")[0],
            codigoContribuyente: contribuyentes.filter((val) => val.nombre === contribuyente)[0].codigo,
            sustentoTributario: tributario,
            tipoContribuyente: contribuyente,
            factura: establecimiento + '-' + emision + '-' + secuencial,
            caja_interna: caja,
            formaPago: formaPago,
            items: data,
            totalIce: totalICE,
            totalIVA: totalIVA,
            asientoCompra: data4,
            emision: fechaEmision,
            subtotal: subtotal,
            total: total,
            totalRetenido: totalRetenido,
            registro: firebase.firestore.FieldValue.serverTimestamp(),
            vencimiento: fechaVencimiento,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            tipo: comprobante,
            modo: manual,
            idPago: formasPago.filter((val) => val.nombre === formaPago)[0].codigo,
            listaRetencion: data2,
            asientoRetencion: data3,
            establecimiento: establecimiento,
            secuencial: secuencial,
            ptoemision: emision,
        }).then(() => {
            limpiar()
        }).catch((error) => {
            limpiar()
        });

    }

    function guardarLiquidacion(datil) {

    }



    function CustomEditComponent(props) {
        const { id, field } = props;
        const apiRef = useGridApiContext();
        const ref = React.useRef();

        const handleChange = (event, newValue) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };
        let lista = []
        if (props.row.tipoProducto === 'GASTOS') {
            lista = gastos
        }
        if (props.row.tipoProducto === 'INVENTARIO') {
            lista = inventario
        }
        return <Autocomplete
            size='small'
            ref={ref}
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={lista}
            getOptionLabel={(option) => `${option.codigo}: ${option.descripcion}`}
            onInputChange={(e, newInputValue) => {
                let producto = lista.filter((item) => `${item.codigo}: ${item.descripcion}` === newInputValue)[0];
                handleChange(e, producto)
            }}
            renderInput={(params) => <TextField  {...params} />}
        />
    }


    const columns = [
        // {
        //     field: 'codigoPrincipal', headerClassName: 'super-app-theme--header', headerName: 'Código', width: 100,
        //     editable: 'true'
        // },
        {
            field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 200,
        },
        {
            field: 'cantidad', headerClassName: 'super-app-theme--header', headerName: 'Cantidad', width: 75,
        },
        {
            field: 'tipoProducto', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 125,
            editable: true,
            type: 'singleSelect',
            valueOptions: tipos ? [...tipos.map(val => { return val.id })] : [],
        },
        {
            field: 'relacionProducto', headerClassName: 'super-app-theme--header', headerName: 'Relación Producto', width: 200,
            editable: true,
            // type: 'singleSelect',
            // valueOptions: ({ row }) => {
            //     if(row.tipoProducto==='GASTOS'){
            //         return gastos ? [...gastos.map(val => { return val.descripcion })] : [];
            //     }
            //     return []
            // },
            renderCell: (params) => {
                return <a>
                    {params.row.relacionProducto ? `${params.row.relacionProducto.codigo}: ${params.row.relacionProducto.descripcion}` : ''}</a>
            },
            renderEditCell: (params) => (
                <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'base_imponible', headerClassName: 'super-app-theme--header', headerName: 'P. Unitario', width: 100,
        },

        {
            field: 'retencion', headerClassName: 'super-app-theme--header', headerName: 'Tipo IVA', width: 75,
            type: 'singleSelect',
            valueOptions: IVAS ? [...IVAS.map(val => { return val.nombre })] : [],
        },
        {
            field: 'codigoRenta', headerClassName: 'super-app-theme--header', headerName: 'Ret. Renta', width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                if (row.relacionProducto !== '') {
                    return filtroRENTA(row.relacionProducto.tipoProducto)
                }
                return catalogoRenta.length > 0 ? catalogoRenta : []
            },
            // valueOptions: catalogoRenta.length > 0 ? catalogoRenta : [],

        },
        {
            field: 'codigoIva', headerClassName: 'super-app-theme--header', headerName: 'Ret. Iva', width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                if (row.relacionProducto !== '') {
                    return filtroIVA(row.relacionProducto.tipoProducto)
                }
                return catalogoIVA.length > 0 ? catalogoIVA : []
            },
            // valueOptions: ({ row }) => {
            //     return filtroIVA()
            // },
            // valueOptions: catalogoIVA.length > 0 ? catalogoIVA : [],
        },
        {
            field: 'descuento', headerClassName: 'super-app-theme--header', headerName: 'Dto.', width: 85,
            // renderCell: (params) => {
            //     return <Box sx={{ borderBottom: 1, width: '100%' }} >
            //         {params.row.descuento}
            //     </Box>
            // }
        },
        { field: 'subtotal', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'Subtotal', width: 75 },
        {
            field: 'ice', headerClassName: 'super-app-theme--header', headerName: 'ICE', width: 80,
            // editable: 'true', type: 'number',
            // renderCell: (params) => {
            //     return <Box sx={{ borderBottom: 1, width: '100%' }} >
            //         {params.row.ice ? params.row.ice : '0.00'}
            //     </Box>
            // }
        },

        { field: 'valor_iva', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'IVA', width: 85 },
        { field: 'total', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'TOTAL', width: 85 },
    ]

    const columns2 = [
        {
            field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 200,
            editable: true,
        },
        {
            field: 'cantidad', headerClassName: 'super-app-theme--header', headerName: 'Cantidad', width: 75,
            editable: true, type: 'number',
        },
        {
            field: 'tipoProducto', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 125,
            editable: true,
            type: 'singleSelect',
            valueOptions: tipos ? [...tipos.map(val => { return val.id })] : [],
        },
        {
            field: 'relacionProducto', headerClassName: 'super-app-theme--header', headerName: 'Relación Producto', width: 200,
            editable: true,
            renderCell: (params) => {
                return <a>
                    {params.row.relacionProducto ? `${params.row.relacionProducto.codigo}: ${params.row.relacionProducto.descripcion}` : ''}</a>
            },
            renderEditCell: (params) => (
                <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'base_imponible', headerClassName: 'super-app-theme--header', headerName: 'P. Unitario', width: 100,
            editable: true, type: 'number',
        },

        {
            field: 'retencion', headerClassName: 'super-app-theme--header', headerName: 'Tipo IVA', width: 75,
            editable: true,
            type: 'singleSelect',
            valueOptions: IVAS ? [...IVAS.map(val => { return val.nombre })] : [],
        },
        {
            field: 'codigoRenta', headerClassName: 'super-app-theme--header', headerName: 'Ret. Renta', width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                if (row.relacionProducto !== '') {
                    return filtroRENTA(row.relacionProducto.tipoProducto)
                }
                return catalogoRenta.length > 0 ? catalogoRenta : []
            },
            // valueOptions: catalogoRenta.length > 0 ? catalogoRenta : [],

        },
        {
            field: 'codigoIva', headerClassName: 'super-app-theme--header', headerName: 'Ret. Iva', width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: ({ row }) => {
                if (row.relacionProducto !== '') {
                    return filtroIVA(row.relacionProducto.tipoProducto)
                }
                return catalogoIVA.length > 0 ? catalogoIVA : []
            },
        },
        {
            field: 'descuento', headerClassName: 'super-app-theme--header', headerName: 'Dto.', width: 85,
            editable: true, type: 'number',
        },
        { field: 'subtotal', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'Subtotal', width: 75 },
        {
            field: 'ice', headerClassName: 'super-app-theme--header', headerName: 'ICE', width: 80,
            editable: true, type: 'number',
        },
        { field: 'valor_iva', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'IVA', width: 85 },
        { field: 'total', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'TOTAL', width: 85 },
    ]

    const columnsSusento = [
        {
            field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 200,
            editable: true,
        },
        {
            field: 'cantidad', headerClassName: 'super-app-theme--header', headerName: 'Cantidad', width: 75,
            editable: true, type: 'number',
        },
        {
            field: 'tipoProducto', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 125,
            editable: true,
            type: 'singleSelect',
            valueOptions: tipos ? [...tipos.map(val => { return val.id })] : [],
        },
        {
            field: 'relacionProducto', headerClassName: 'super-app-theme--header', headerName: 'Relación Producto', width: 200,
            editable: true,
            renderCell: (params) => {
                return <a>
                    {params.row.relacionProducto ? `${params.row.relacionProducto.codigo}: ${params.row.relacionProducto.descripcion}` : ''}</a>
            },
            renderEditCell: (params) => (
                <CustomEditComponent {...params} />
            ),
        },
        {
            field: 'base_imponible', headerClassName: 'super-app-theme--header', headerName: 'P. Unitario', width: 100,
            editable: true, type: 'number',
        },

        {
            field: 'retencion', headerClassName: 'super-app-theme--header', headerName: 'Tipo IVA', width: 75,
            editable: true,
            type: 'singleSelect',
            valueOptions: IVAS ? [...IVAS.map(val => { return val.nombre })] : [],
        },
        {
            field: 'descuento', headerClassName: 'super-app-theme--header', headerName: 'Dto.', width: 85,
            editable: true, type: 'number',

        },
        { field: 'subtotal', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'Subtotal', width: 75 },
        {
            field: 'ice', headerClassName: 'super-app-theme--header', headerName: 'ICE', width: 80,
            editable: true, type: 'number',
        },
        { field: 'valor_iva', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'IVA', width: 85 },
        { field: 'total', headerClassName: 'super-app-theme--header', cellClassName: 'super-app-theme--cell', headerName: 'TOTAL', width: 85 },
    ]


    const columnsTipoRetencion = [
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', flex: 4 },
        { field: 'cuenta', headerClassName: 'super-app-theme--header', headerName: 'Cuenta', flex: 2 },
        {
            field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'Tipo', flex: 1,
            // editable: true,
            // type: 'singleSelect',
            // valueOptions: ['HABER', 'DEBE'],
        },
        { field: 'valor', headerClassName: 'super-app-theme--header', headerName: 'Valor', flex: 1 },
    ]
    const columnsRetencion = [
        { field: 'impuesto', headerClassName: 'super-app-theme--header', headerName: 'Impuesto', flex: 1 },
        {
            field: 'porcentaje', headerClassName: 'super-app-theme--header', headerName: '%', flex: 0.5,
            renderCell: (params) => {
                return <a>
                    {parseFloat((parseFloat(params.row.porcentaje) * 100).toFixed(2))}%</a>
            }
        },
        { field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', flex: 2 },
        { field: 'baseImponible', headerClassName: 'super-app-theme--header', headerName: 'Base Imponible', flex: 1 },
        { field: 'valorRetenido', headerClassName: 'super-app-theme--header', headerName: 'Valor Retenido', flex: 1 },

    ]

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };

    function footerDATIL() {
        let totalDeber = 0
        for (const elemento of data2) {
            totalDeber += elemento.valorRetenido
        }
        return <Box >
            <strong>Total RETENIDO :</strong> ${totalDeber.toFixed(2)}
        </Box>
    }

    function cuadrar(lista) {
        let totalDeber = 0
        let totalHaber = 0
        for (const elemento of lista) {
            if (elemento.tipo === 'HABER') {
                totalHaber += elemento.valor
            } else {
                totalDeber += elemento.valor
            }
        }
        return totalHaber.toFixed(2) === totalDeber.toFixed(2)
    }

    function validarBlock() {
        if (!manual) return true
        if (blockProveedor === '') {
            return false
        }
        if (blockProveedor.filter((val) => (val.establecimiento === establecimiento)
            && (val.emision === emision)).length > 0) {
            return true
        }
        return false
    }

    function footerRetencion() {
        let totalDeber = 0
        let totalHaber = 0
        for (const elemento of data3) {
            if (elemento.tipo === 'HABER') {
                totalHaber += elemento.valor
            } else {
                totalDeber += elemento.valor
            }
        }
        return <Box >
            <strong>Total HABER :</strong> ${totalHaber.toFixed(2)}
            <br />
            <strong>Total DEBER :</strong> ${totalDeber.toFixed(2)}
        </Box>
    }

    function footerCompra(props) {
        let totalDeber = 0
        let totalHaber = 0
        for (const elemento of data4) {
            if (elemento.tipo === 'HABER') {
                totalHaber += elemento.valor
            } else {
                totalDeber += elemento.valor
            }
        }
        return <Box >
            <strong>Total HABER :</strong> ${totalHaber.toFixed(2)}
            <br />
            <strong>Total DEBER :</strong> ${totalDeber.toFixed(2)}
        </Box>
    }

    function agregar() {
        let lista = []
        lista.push({
            id: data.length,
            codigoPrincipal: "",
            cantidad: 0,
            descripcion: "",
            base_imponible: 0,
            retencion: '',
            subtotal: 0,
            descuento: 0,
            ice: 0,
            codigo: "",
            codigoPorcentaje: "",
            tipoProducto: '',
            relacionProducto: '',
            codigoRenta: '',
            codigoIva: '',
            valor_iva: 0,
            total: 0,
        })
        setData(data.concat(lista))
    }

    function guardarRelacion() {
        // db.collection(tabla).doc(proveedor.id).update({
        //     productos: {
        //         producto: producto,
        //         retencionIva: retencionIva,
        //         retencionRenta: retencionRenta
        //     },
        // })
    }


    return (
        <>
            <Box px={2}>
                <Card>
                    <CardContent>
                        <Typography variant='h5'> <strong>INGRESO FACTURA </strong><br /></Typography>
                        <br />
                        {/* <Box pb={2}>
                            <InputLabel><strong>
                                Comprobante a Generar</strong>
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    value={comprobante}
                                    onChange={(e) => {
                                        limpiar2()
                                        setComprobante(e.target.value)
                                        if (e.target.value === 'DOCUMENTO SIN SUSTENTO') {
                                            setManual(true)
                                        } else if (e.target.value === 'LIQUIDACION DE COMPRA') {
                                            setManual(true)
                                        }
                                    }}
                                    size="small"
                                    MenuProps={MenuProps}
                                >
                                    {comprobantes ?
                                        comprobantes.filter((val) => (val.nombre === 'DOCUMENTO SIN SUSTENTO')
                                            || (val.nombre === 'COMPROBANTE DE RETENCION')
                                            || (val.nombre === 'LIQUIDACION DE COMPRA')).map((item) => (
                                                <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                            )) : <></>}
                                </Select>
                            </FormControl>
                        </Box> */}


                        <Stack direction='row' spacing={3} >
                            <Typography ><strong>
                                DOCUMENTO MANUAL</strong>
                            </Typography>
                            <Checkbox sx={{ paddingTop: "0px" }}
                                checked={manual}
                                disabled={comprobante === 'DOCUMENTO SIN SUSTENTO'
                                    || comprobante === 'LIQUIDACION DE COMPRA'}
                                onChange={(e) => {
                                    setManual(e.target.checked)
                                    limpiar2()
                                }}
                            />
                            <Button px={2}
                                variant="contained"
                                color="error"
                                onClick={() => limpiar()}
                                disableElevation
                                component="span"
                                size='small'
                            >
                                LIMPIAR
                            </Button>
                        </Stack>
                        {!manual &&
                            <>
                                <Grid container spacing={5} >
                                    <Grid item md={6} xs={12}>
                                        <input
                                            style={{ display: "none" }}
                                            onChange={(e) => cargarXML(e)}
                                            multiple
                                            disabled={manual || orden !== ''}
                                            id="buttonFile"
                                            type="file"
                                        />
                                        <label htmlFor="buttonFile">
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                disabled={manual || orden !== ''}
                                                disableElevation
                                                component="span"
                                                size='small'
                                            >
                                                CARGAR XML
                                            </Button>
                                        </label><br />
                                        <a><strong>Archivo XML:</strong>  {archivo}</a>
                                    </Grid>

                                    <Grid item md={6} xs={12} >
                                        <Button
                                            variant="contained"
                                            color="info"
                                            disabled={manual || archivo !== ''}
                                            disableElevation
                                            component="span"
                                            onClick={() => setOpenDialogCuentas(true)}
                                            size='small'
                                        >
                                            CARGAR ORDEN DE COMPRA
                                        </Button><br />
                                        <a><strong>Orden de compra:</strong>  {orden}</a>
                                        {orden !== '' &&
                                            <>
                                                <br />
                                                <input
                                                    style={{ display: "none" }}
                                                    onChange={(e) => cargarXML2(e)}
                                                    multiple
                                                    disabled={manual || orden === ''}
                                                    id="buttonFile2"
                                                    type="file"
                                                />
                                                <label htmlFor="buttonFile2">
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        disabled={manual || orden === ''}
                                                        disableElevation
                                                        component="span"
                                                        size='small'
                                                    >
                                                        CARGAR XML CABECERA
                                                    </Button>
                                                </label><br />
                                                <a><strong>Archivo XML:</strong>  {archivo}</a>
                                            </>}
                                    </Grid>
                                </Grid>
                            </>
                        }

                        <br />
                        <Grid container spacing={5}>
                            <Grid item md={6} xs={12}>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Proveedor</strong>
                                    </InputLabel>
                                    <Autocomplete
                                        size='small'
                                        fullWidth
                                        disabled={!manual}
                                        disablePortal
                                        value={proveedor !== '' ? proveedores.filter(
                                            (item) => item.identificacion === proveedor)[0] : null}
                                        id="combo-box-demo"
                                        options={comprobante !== 'LIQUIDACION DE COMPRA' ? proveedores : 
                                        proveedores.filter((item) => item.tipo === 'RUT' || item.tipo === 'CEDULA')}
                                        getOptionLabel={(option) => option.identificacion + ": " + option.nombre_comercial}
                                        onInputChange={(e, newInputValue) => {
                                            let prov = proveedores.filter(
                                                (item) => item.identificacion + ": " + item.nombre_comercial === newInputValue)[0]
                                            setProveedor(prov ? prov.identificacion : '')
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />

                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Identificación</strong>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        disabled={comprobante === ''}
                                        size='small'
                                        value={identificacion}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Dirección</strong>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        disabled={comprobante === ''}
                                        size='small'
                                        value={direccion}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Sustento Tributario</strong>
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        size="small"
                                        value={tributario}
                                        onChange={(e) => setTributario(e.target.value)}
                                        fullWidth
                                        MenuProps={MenuProps}
                                    >
                                        {sustentos ? sustentos.map((item, index) => (
                                            <MenuItem key={item.id} value={item.codigo + "-" + item.nombre}>{item.codigo + "-" + item.nombre}</MenuItem>
                                        )) : <></>}
                                    </Select>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Tipo Contribuyente</strong>
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        size="small"
                                        value={contribuyente}
                                        onChange={(e) => setContribuyente(e.target.value)}
                                        fullWidth
                                        MenuProps={MenuProps}
                                    >
                                        {contribuyentes ? contribuyentes.map((item, index) => (
                                            <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                        )) : <></>}
                                    </Select>

                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Forma de Pago</strong>
                                    </InputLabel>
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        size="small"
                                        value={formaPago}
                                        onChange={(e) => setFormaPago(e.target.value)}
                                        fullWidth
                                        MenuProps={MenuProps}
                                    >
                                        {formasPago ? formasPago.map((item, index) => (
                                            <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                        )) : <></>}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Tipo de Documento</strong>
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={comprobante}
                                            onChange={(e) => {
                                                setComprobante(e.target.value)
                                                if (e.target.value === 'LIQUIDACION DE COMPRA'
                                                    || e.target.value === 'DOCUMENTO SIN SUSTENTO') {
                                                    limpiar2()
                                                    setManual(true)
                                                }
                                                if(e.target.value === 'FACTURA'){
                                                    setElectronica('ELECTRONICA')
                                                }
                                            }}
                                            size="small"
                                            MenuProps={MenuProps}
                                        >
                                            {comprobantes ? comprobantes.map((item) => (
                                                <MenuItem key={item.id} value={item.nombre}>{item.nombre}</MenuItem>
                                            )) : <></>}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Fecha de Emisión Documento</strong>
                                    </InputLabel>
                                    <TextField
                                        disabled={comprobante === ''}
                                        fullWidth
                                        size='small'
                                        value={moment(fechaEmision.toISOString()).format('DD/MM/YYYY')}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Fecha de Registro</strong>
                                    </InputLabel>
                                    <TextField
                                        disabled={comprobante === ''}
                                        fullWidth
                                        size='small'
                                        value={moment(new Date().toISOString()).format('DD/MM/YYYY')}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel><strong>
                                        Fecha de Vencimiento</strong>
                                    </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <FormControl fullWidth>
                                            <DateTimePicker
                                                disabled={comprobante === ''}
                                                renderInput={(props) => <TextField {...props} sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 }
                                                }} size="small" />}
                                                value={fechaVencimiento}
                                                onChange={(newValue) => {
                                                    setFechaVencimiento(newValue);
                                                }}
                                                inputFormat="dd/MM/yyyy"
                                            />
                                        </FormControl>
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                        </Grid>
                        <br />


                        <Grid container spacing={1}>
                            {manual && comprobante !== 'LIQUIDACION DE COMPRA' &&
                                <>
                                    {blockProveedor !== '' && blockProveedor.filter((val) => (val.establecimiento === establecimiento)
                                        && (val.emision === emision)).length === 0 &&
                                        <>
                                            <Grid item xs={12} sx={{ color: 'red' }}>
                                                <strong> FACTURA NO ES VALIDA CON EL BLOCK ACTUAL </strong>
                                            </Grid>
                                        </>
                                    }
                                    {blockProveedor === '' &&
                                        <>
                                            <Grid item xs={12} sx={{ color: 'red' }}>
                                                <strong> NO HAY UN BLOCK VIGENTE PARA ESTE PROVEEDOR </strong>
                                            </Grid>
                                        </>
                                    }
                                </>}
                            {comprobante !== 'LIQUIDACION DE COMPRA' &&
                                <>
                                    <Grid item md={2} xs={12}>
                                        <strong>N° Factura:</strong>
                                    </Grid>
                                    <Grid item md={10} xs={12}>
                                        <Stack direction='row' spacing={1}>
                                            <TextField
                                                fullWidth
                                                disabled={archivo !== ''}
                                                onChange={(e) => setEstablecimiento(e.target.value)}
                                                size='small'
                                                value={establecimiento}
                                            />
                                            <a>-</a>
                                            <TextField
                                                fullWidth
                                                disabled={archivo !== ''}
                                                size='small'
                                                onChange={(e) => setEmision(e.target.value)}
                                                value={emision}
                                            />
                                            <a>-</a>
                                            <TextField
                                                fullWidth
                                                disabled={archivo !== ''}
                                                size='small'
                                                onChange={(e) => setSecuencial(e.target.value)}
                                                value={secuencial}
                                            />
                                        </Stack>
                                    </Grid>
                                </>}

                        </Grid>
                        <br />

                        <Stack direction='row' spacing={1}>
                            {/* <Button py={2}
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            console.log(data[0].relacionProducto)
                                            console.log(data[0].codigoRenta)
                                            console.log(data[0].codigoIva)
                                        }}
                                        disableElevation
                                        component="span"
                                        size='small'
                                    >
                                        VER
                                    </Button> */}



                            {manual &&
                                <Button py={2}
                                    variant="contained"
                                    color="success"
                                    onClick={() => agregar()}
                                    disableElevation
                                    component="span"
                                    size='small'
                                >
                                    AGREGAR ITEM
                                </Button>
                            }
                            <Button py={2}
                                variant="contained"
                                color="warning"
                                disabled={catalogoRenta.length == 0 ||
                                    catalogoIVA.length == 0 ||
                                    data.length == 0
                                }
                                onClick={() => validarCatalogo(data)}
                                disableElevation
                                component="span"
                                size='small'
                            >
                                Selección Por Defecto
                            </Button>
                        </Stack>


                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box pt={1}>
                                    <Typography><strong>DETALLE DE FACTURA</strong></Typography>
                                    {comprobante !== 'DOCUMENTO SIN SUSTENTO' &&


                                        // {comprobante === 'LIQUIDACION DE COMPRA' &&
                                        <>
                                            <Box py={2} sx={{
                                                height: 500, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                                '& .super-app-theme--header': {
                                                    fontWeight: 'bold',
                                                    backgroundColor: '#672780',
                                                    color: 'white',
                                                },
                                                '& .super-app-theme--cell': {
                                                    fontWeight: 'bold',
                                                    color: 'blue',
                                                },
                                            }}>

                                                <DataGrid
                                                    rows={data ?? []}
                                                    onCellEditCommit={(params) => {
                                                        let datos = data

                                                        datos[params.id][params.field] = params.value

                                                        let lista2 = []
                                                        let totalFactura = 0
                                                        let subtotalFactura = 0
                                                        let ivaFactura = 0
                                                        let ice = 0
                                                        for (const el of datos) {
                                                            let subtotal = 0
                                                            let total = 0
                                                            let iva = 0
                                                            let valor = IVAS.filter((item) => item.nombre === el.retencion)
                                                            subtotal = parseFloat((el.cantidad * el.base_imponible).toFixed(2))
                                                            subtotal = parseFloat((subtotal - el.descuento).toFixed(2))
                                                            let subtotal2 = parseFloat((subtotal + el.ice).toFixed(2))
                                                            if (valor[0]) {
                                                                let porcentaje = parseFloat(valor[0].valor) / 100
                                                                iva = parseFloat((subtotal2 * porcentaje).toFixed(2))
                                                            }
                                                            total = parseFloat((iva + subtotal2).toFixed(2))
                                                            ivaFactura = ivaFactura + iva
                                                            totalFactura = totalFactura + total
                                                            subtotalFactura = subtotalFactura + subtotal
                                                            ice = ice + el.ice
                                                            lista2.push({
                                                                ...el,
                                                                subtotal: subtotal,
                                                                valor_iva: iva,
                                                                total: total
                                                            })
                                                        }

                                                        setTotal(totalFactura)
                                                        setTotalIVA(ivaFactura)
                                                        setSubtotal(subtotalFactura)
                                                        setTotalICE(ice)
                                                        if (params.field === 'relacionProducto') {
                                                            validarCatalogo(lista2)
                                                        } else {
                                                            calcularRetencion2(lista2, totalFactura, ivaFactura, ice)
                                                        }
                                                    }}

                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                    columns={columns2}
                                                    density='compact'
                                                    getRowId={(row) => row.id}

                                                />
                                            </Box>
                                        </>
                                    }
                                    {/* {comprobante === 'COMPROBANTE DE RETENCION' &&
                                        <>
                                            {!manual && (orden !== '' || archivo !== '') ?
                                                <>
                                                    <Box py={2} sx={{
                                                        height: 500, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                                        '& .super-app-theme--header': {
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#672780',
                                                            color: 'white',
                                                        },
                                                        '& .super-app-theme--cell': {
                                                            fontWeight: 'bold',
                                                            color: 'blue',
                                                        },
                                                    }}>

                                                        <DataGrid
                                                            rows={data ?? []}
                                                            onCellEditCommit={(params) => {
                                                                let datos = data

                                                                datos[params.id][params.field] = params.value

                                                                if (params.field !== 'codigoRenta' && params.field !== 'codigoIva') {
                                                                    validarCatalogo(datos)
                                                                } else {
                                                                    calcularRetencion(datos)
                                                                }
                                                            }}

                                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                            columns={columns}
                                                            density='compact'
                                                            getRowId={(row) => row.id}
                                                        />
                                                    </Box>

                                                </> :
                                                <Box py={2} sx={{
                                                    height: 500, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                                    '& .super-app-theme--header': {
                                                        fontWeight: 'bold',
                                                        backgroundColor: '#672780',
                                                        color: 'white',
                                                    },
                                                    '& .super-app-theme--cell': {
                                                        fontWeight: 'bold',
                                                        color: 'blue',
                                                    },
                                                }}>
                                                    <DataGrid
                                                        rows={data ?? []}
                                                        onCellEditCommit={(params) => {
                                                            let datos = data

                                                            datos[params.id][params.field] = params.value

                                                            let lista2 = []
                                                            let totalFactura = 0
                                                            let subtotalFactura = 0
                                                            let ivaFactura = 0
                                                            let ice = 0
                                                            for (const el of datos) {
                                                                let subtotal = 0
                                                                let total = 0
                                                                let iva = 0
                                                                let valor = IVAS.filter((item) => item.nombre === el.retencion)
                                                                subtotal = parseFloat((el.cantidad * el.base_imponible).toFixed(2))
                                                                subtotal = parseFloat((subtotal - el.descuento).toFixed(2))
                                                                let subtotal2 = parseFloat((subtotal + el.ice).toFixed(2))
                                                                if (valor[0]) {
                                                                    let porcentaje = parseFloat(valor[0].valor) / 100
                                                                    iva = parseFloat((subtotal2 * porcentaje).toFixed(2))
                                                                }
                                                                total = parseFloat((iva + subtotal2).toFixed(2))

                                                                ivaFactura = ivaFactura + iva
                                                                totalFactura = totalFactura + total
                                                                subtotalFactura = subtotalFactura + subtotal
                                                                ice = ice + el.ice
                                                                lista2.push({
                                                                    ...el,
                                                                    subtotal: subtotal,
                                                                    valor_iva: iva,
                                                                    total: total
                                                                })
                                                            }

                                                            setTotal(totalFactura)
                                                            setTotalIVA(ivaFactura)
                                                            setSubtotal(subtotalFactura)
                                                            setTotalICE(ice)
                                                            if (params.field === 'relacionProducto') {
                                                                validarCatalogo(lista2)
                                                            } else {
                                                                calcularRetencion2(lista2, totalFactura, ivaFactura, ice)
                                                            }
                                                        }}

                                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                        columns={columns2}
                                                        density='compact'
                                                        getRowId={(row) => row.id}

                                                    />
                                                </Box>
                                            }
                                        </>
                                    } */}
                                    {comprobante === 'DOCUMENTO SIN SUSTENTO' &&
                                        <>
                                            <>
                                                <Box py={2} sx={{
                                                    height: 500, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                                    '& .super-app-theme--header': {
                                                        fontWeight: 'bold',
                                                        backgroundColor: '#672780',
                                                        color: 'white',
                                                    },
                                                    '& .super-app-theme--cell': {
                                                        fontWeight: 'bold',
                                                        color: 'blue',
                                                    },
                                                }}>
                                                    <DataGrid
                                                        rows={data ?? []}
                                                        onCellEditCommit={(params) => {
                                                            let datos = data

                                                            datos[params.id][params.field] = params.value

                                                            let lista2 = []
                                                            let totalFactura = 0
                                                            let subtotalFactura = 0
                                                            let ivaFactura = 0
                                                            let ice = 0
                                                            for (const el of datos) {
                                                                let subtotal = 0
                                                                let total = 0
                                                                let iva = 0
                                                                let valor = IVAS.filter((item) => item.nombre === el.retencion)
                                                                subtotal = parseFloat((el.cantidad * el.base_imponible).toFixed(2))
                                                                subtotal = parseFloat((subtotal - el.descuento).toFixed(2))
                                                                let subtotal2 = parseFloat((subtotal + el.ice).toFixed(2))
                                                                if (valor[0]) {
                                                                    let porcentaje = parseFloat(valor[0].valor) / 100
                                                                    iva = parseFloat((subtotal2 * porcentaje).toFixed(2))
                                                                }

                                                                total = parseFloat((iva + subtotal2).toFixed(2))
                                                                ivaFactura = ivaFactura + iva
                                                                totalFactura = totalFactura + total
                                                                subtotalFactura = subtotalFactura + subtotal
                                                                ice = ice + el.ice
                                                                lista2.push({
                                                                    ...el,
                                                                    subtotal: subtotal,
                                                                    valor_iva: iva,
                                                                    total: total
                                                                })
                                                            }

                                                            setTotal(totalFactura)
                                                            setTotalIVA(ivaFactura)
                                                            setSubtotal(subtotalFactura)
                                                            setTotalICE(ice)
                                                            calcularCompra(lista2, totalFactura, ivaFactura, ice)
                                                        }}

                                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                        columns={columnsSusento}
                                                        density='compact'
                                                        getRowId={(row) => row.id}

                                                    />
                                                </Box>
                                            </></>

                                    }

                                </Box>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pt={2}>
                                    <Paper elevation={3} sx={{ padding: '10px' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={{
                                                backgroundColor: '#672780',
                                                textAlign: 'center', color: 'white'
                                            }}>
                                                <strong>TOTALES</strong>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        fullWidth
                                                        label='DESCUENTO'
                                                        disabled={comprobante === ''}
                                                        size='small'
                                                        value={descuento}
                                                    />
                                                </Stack>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        fullWidth
                                                        label='SUBTOTAL'
                                                        disabled={comprobante === ''}
                                                        size='small'
                                                        value={subtotal}
                                                    />
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        label='VALOR IVA'
                                                        fullWidth
                                                        disabled={comprobante === ''}
                                                        size='small'
                                                        value={totalIVA}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        label='VALOR TOTAL'
                                                        fullWidth
                                                        disabled={comprobante === ''}
                                                        size='small'
                                                        value={total}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        label='VALOR A PAGAR'
                                                        fullWidth
                                                        size='small'
                                                        value={total - totalRetenido}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </Grid>

                            <Grid item md={8} xs={12}>
                                <br />
                                <Grid container spacing={1} >
                                    {todo &&
                                        <>
                                            <Grid item md={6} xs={12}>
                                                <Typography><strong>Guardar Relación Producto</strong></Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Checkbox sx={{ paddingTop: "0px" }}
                                                    checked={guardar}
                                                    disabled={!todo}
                                                    onChange={(e) => {
                                                        setGuardar(e.target.checked)
                                                    }}
                                                />
                                                {/* <Button size='small' variant='contained'
                                                    disabled={!todo}
                                                    color='success'
                                            onClick={() => emitirRetencion()}>GUARDAR RELACION</Button> */}
                                            </Grid>
                                        </>}

                                    <Grid item md={6} xs={12}>
                                        <Typography><strong>Caja Interna</strong></Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size="small"
                                            value={caja}
                                            onChange={(e) => setCaja(e.target.value)}
                                            fullWidth
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key={'SI'} value={'SI'}>{'SI'}</MenuItem>
                                            <MenuItem key={'NO'} value={'NO'}>{'NO'}</MenuItem>

                                        </Select>
                                    </Grid>
                                    {(comprobante !== 'DOCUMENTO SIN SUSTENTO') &&
                                        <>
                                            <Grid item md={6} xs={12}>
                                                <Typography><strong>Retención</strong></Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={electronica}
                                                    onChange={(e) => setElectronica(e.target.value)}
                                                    fullWidth
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem key={'ELECTRONICA'} value={'ELECTRONICA'}>{'SI'}</MenuItem>
                                                    <MenuItem key={'NO'} value={'NO'}>{'NO'}</MenuItem>

                                                </Select>
                                            </Grid>
                                        </>
                                    }
                                    {/* {secuencialRetencion === '' &&
                                        <Grid item md={6} xs={12} sx={{ color: 'red' }}>
                                            <>
                                                <strong> No tiene asignado un secuencial para su usuario </strong>
                                            </>
                                        </Grid>
                                    } */}

                                    {electronica === 'ELECTRONICA' &&
                                        comprobante === 'LIQUIDACION DE COMPRA' &&
                                        secuenciales.filter((val) => (val.documento === comprobante)
                                            && (val.correo) === auth.currentUser.email) < 1 &&
                                        secuenciales.filter((val) => (val.documento === 'COMPROBANTE DE RETENCION')
                                            && (val.correo) === auth.currentUser.email) < 1 &&
                                        < Grid item md={6} xs={12} sx={{ color: 'red' }}>
                                            <>
                                                <strong> No tiene asignado un secuencial de retención o liquidación para su usuario </strong>
                                            </>
                                        </Grid>
                                    }

                                    {electronica === 'ELECTRONICA' &&
                                        comprobante !== 'LIQUIDACION DE COMPRA' &&
                                        secuenciales.filter((val) => (val.documento === 'COMPROBANTE DE RETENCION')
                                            && (val.correo) === auth.currentUser.email) < 1 &&
                                        < Grid item md={6} xs={12} sx={{ color: 'red' }}>
                                            <>
                                                <strong> No tiene asignado un secuencial de retención para su usuario </strong>
                                            </>
                                        </Grid>
                                    }

                                    {/* <Grid item md={6} xs={12}>
                                        <Typography><strong>Número de Documento</strong></Typography>

                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size="small"
                                            value={secuencialRetencion}
                                            onChange={(e) => setSecuencialRetencion(e.target.value)}
                                            fullWidth
                                            MenuProps={MenuProps}
                                        >
                                            {secuenciales ? secuenciales
                                                .filter((val) => val.documento === comprobante).map((item) => (
                                                    <MenuItem key={item.establecimiento + '-' + item.emision + '-' + item.valor}
                                                        value={item}>
                                                        {item.establecimiento + '-' + item.emision + '-' + item.valor}</MenuItem>
                                                )) : ''}

                                        </Select>
                                    </Grid> */}
                                </Grid>
                            </Grid>

                            {electronica === 'ELECTRONICA' &&
                                <>
                                    <Grid item md={12} xs={12} sx={{ overflowX: 'scroll' }}>
                                        <Typography><strong>Detalle de Retención</strong></Typography>
                                        <Box py={2} sx={{
                                            minWidth: 700,
                                            height: 300, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                            '& .super-app-theme--header': {
                                                fontWeight: 'bold',
                                                backgroundColor: '#672780',
                                                color: 'white',
                                            },
                                            '& .super-app-theme--cell': {
                                                fontWeight: 'bold',
                                                color: 'blue',
                                            },
                                        }}>
                                            <DataGrid
                                                rows={data2 ?? []}
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                columns={columnsRetencion}
                                                density='compact'
                                                hideFooterPagination
                                                components={{
                                                    Footer: footerDATIL,
                                                }}
                                                getRowId={(row) => row.codigo}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            }
                        </Grid>

                        <br />



                        <Card sx={{ overflowX: 'scroll' }}>
                            <Typography><strong>CUENTAS CONTABLE TIPO COMPRA</strong></Typography>
                            <Box py={2} sx={{
                                minWidth: 600,
                                height: 300, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                '& .super-app-theme--header': {
                                    fontWeight: 'bold',
                                    backgroundColor: '#672780',
                                    color: 'white',
                                },
                                '& .super-app-theme--cell': {
                                    fontWeight: 'bold',
                                    color: 'blue',
                                },
                            }}>
                                <DataGrid

                                    rows={data4 ?? []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={columnsTipoRetencion}
                                    hideFooterPagination
                                    components={{
                                        Footer: footerCompra,
                                    }}
                                    density='compact'
                                    getRowId={(row) => row.id}
                                />
                            </Box>
                            <InputLabel><strong>
                                Glosa Compra</strong>
                            </InputLabel>
                            <TextField
                                fullWidth
                                onChange={(e) => setGlosaCompra(e.target.value)}
                                size='small'
                                value={glosaCompra}
                            />
                            <br />
                            <br />
                            {comprobante !== 'DOCUMENTO SIN SUSTENTO' &&
                                <>
                                    <Typography><strong>CUENTAS CONTABLE TIPO RETENCION</strong></Typography>
                                    <Box py={2} sx={{
                                        minWidth: 600,
                                        height: 300, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                        '& .super-app-theme--header': {
                                            fontWeight: 'bold',
                                            backgroundColor: '#672780',
                                            color: 'white',
                                        },
                                        '& .super-app-theme--cell': {
                                            fontWeight: 'bold',
                                            color: 'blue',
                                        },
                                    }}>
                                        <DataGrid

                                            rows={data3 ?? []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columnsTipoRetencion}
                                            hideFooterPagination
                                            components={{
                                                Footer: footerRetencion,
                                            }}
                                            density='compact'
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                    <InputLabel><strong>
                                        Glosa Retención</strong>
                                    </InputLabel>
                                    <TextField
                                        fullWidth
                                        onChange={(e) => setGlosaRetencion(e.target.value)}
                                        size='small'
                                        value={glosaRetencion}
                                    />
                                </>
                            }

                        </Card>

                    </CardContent>
                    <CardActions>


                        {comprobante === 'DOCUMENTO SIN SUSTENTO' &&
                            <Button size='small' variant='contained'
                                disabled={!cuadrar(data4) || !data4.length > 0 || !validarBlock()}
                                color='success'
                                onClick={() => guardarDocumento()}>GUARDAR DOCUMENTO</Button>
                        }

                        {comprobante === 'LIQUIDACION DE COMPRA' &&
                            <Button size='small' variant='contained'
                                disabled={!cuadrar(data3) || !data3.length > 0 ||
                                    !cuadrar(data4) || !data4.length > 0}
                                color='success'
                                onClick={() => emitirLiquidacion()}>GUARDAR LIQUIDACION</Button>
                        }


                        {comprobante !== 'LIQUIDACION DE COMPRA' && comprobante !== 'DOCUMENTO SIN SUSTENTO' &&
                            <Button size='small' variant='contained'
                                disabled={!cuadrar(data3) || !validarBlock() || !cuadrar(data4) || !data3.length > 0 || !data4.length > 0}
                                color='success'
                                onClick={() => emitirRetencion()}>GUARDAR DOCUMENTO</Button>
                        }

                    </CardActions>
                </Card>
            </Box >

            <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                <DialogTitle><strong>ORDENES DE COMPRA</strong></DialogTitle>
                <Container maxWidth="xl">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>CUENTAS</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={ordenes}
                                            getOptionLabel={(option) => option.id}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                setOrden(newInputValue)
                                                cargarInfo(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    )
}