import React, { useState, useEffect } from 'react'
import { Tabs, Typography, Box, Tab, Card, CardContent } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { CrearNomina } from './CrearNomina';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const Nomina = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    console.log(newValue)
  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Tabs value={tab} onChange={handleChange} aria-label="solicitud de servicio">
            <Tab label="Nomina" {...tabs(0)} />
            <Tab label="Lista Nominas" {...tabs(1)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <CrearNomina />
          </TabPanel>
          {/* <TabPanel value={tab} index={1}>
            <ListNomina />
          </TabPanel> */}
        </CardContent>
      </Card>
    </>
  )
}