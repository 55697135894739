import {
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    Card,
    CardContent
} from "@mui/material";
import React, { useEffect, useState } from "react";
//import { VentasPorAgendar } from "../../pages/agenda/VentasPorAgendar";
import { SolicitudesReAgendadas } from "../../pages/agenda/SolicitudesReAgendadas";
import { VentasPorReAgendar } from "../../pages/agenda/VentasPorReAgendar";
import { Clientes } from "../../components/clientes/Clientes";
import { PagoClientes } from "../../components/clientes/PagoClientes";
import { InstalacionesRealizadas } from "../tecnicos/InstalacionesRealizadas";
import { InstalacionesRechazadas } from "../tecnicos/InstalacionesRechazadas";
import { VentasPreAprobadas } from "../../pages/ventas/pre_aprobada/VentasPreAprobadas"
import { VentasRechazadas } from "../../pages/ventas/pre_aprobada/VentasRechazadas";
import { VentasRealizadas } from "../ventas/VentasRealizadas";
import { VentasPorAgendar } from "../agenda/VentasPorAgendar";
import { FactibilidadPage } from "../operaciones/factibilidad/FactibilidadPage";
export const ReportesPage = () => {
    const [reportesList, setReportesList] = useState(false);
    return (
        <>
            <Box pt={1}>
                <Card>
                    <CardContent>
                        <Box py={2}>
                            <Typography><strong>REPORTES GENERALES:</strong></Typography>
                            <FormControl fullWidth>
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    size="small"
                                    value={reportesList}
                                    onChange={(e) => setReportesList(e.target.value)}
                                >
                                    <MenuItem value="VENTAS PRE APROBADAS">VENTAS PRE APROBADAS</MenuItem>
                                    <MenuItem value="VENTAS RECHAZADAS">VENTAS RECHAZADAS</MenuItem>
                                    <MenuItem value="VENTAS POR AGENDAR">VENTAS POR AGENDAR</MenuItem>
                                    <MenuItem value="VENTAS AGENDADAS">VENTAS AGENDADAS</MenuItem>
                                    <MenuItem value="VENTAS POR REAGENDAR">VENTAS POR REAGENDAR</MenuItem>
                                    <MenuItem value="VENTAS REAGENDADAS">VENTAS REAGENDADAS</MenuItem>
                                    <MenuItem value="VENTAS INSTALADAS">VENTAS INSTALADAS</MenuItem>
                                    <MenuItem value="INSTALACIONES RECHAZADAS">INSTALACIONES RECHAZADAS</MenuItem>
                                    <MenuItem value="INSTALACIONES REALIZADAS">INSTALACIONES REALIZADAS</MenuItem>
                                    <MenuItem value="PAGO INSTALACIONES">PAGO INSTALACIONES</MenuItem>
                                    <MenuItem value="GESTION CLIENTE">GESTION CLIENTE</MenuItem>
                                    <MenuItem value="FACTIBILIDAD">FACTIBILIDAD</MenuItem>
                                   
                           </Select>
                            </FormControl>
                        </Box>
                        
                        {
                            reportesList === 'VENTAS PRE APROBADAS' ? <VentasPreAprobadas/>: ''
                        }
                         {
                            reportesList === 'VENTAS RECHAZADAS' ? <VentasRechazadas />: ''
                        }
                        {
                            reportesList === 'VENTAS POR AGENDAR' ? <VentasPorAgendar/> : ''
                        }
                        {
                            reportesList === 'VENTAS AGENDADAS' ? '<VentasAgendadas />' : ''
                        }
                         {
                            reportesList === 'VENTAS POR REAGENDAR' ? <VentasPorReAgendar/> : ''
                        }
                        {
                            reportesList === 'VENTAS REAGENDADAS' ? <SolicitudesReAgendadas /> : ''
                        }
                        {
                            reportesList === 'VENTAS INSTALADAS' ? <VentasRealizadas/> : ''
                        }
                        {
                            reportesList === 'INSTALACIONES RECHAZADAS' ? <InstalacionesRechazadas/>: ''
                        }
                        {
                            reportesList === 'INSTALACIONES REALIZADAS' ? <InstalacionesRealizadas /> : ''
                        }
                        {
                            reportesList === 'PAGO INSTALACIONES' ? <PagoClientes/>: ''
                        }
                       {
                            reportesList === 'GESTION CLIENTE' ? <Clientes/>: ''
                        }
                         {
                            reportesList === 'FACTIBILIDAD' ? <FactibilidadPage />: ''
                        }
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

