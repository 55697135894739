import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, storage, firebase, auth } from "../../../firebase";
import { listsSubCategorias } from "../../../redux/actions/parametersActions";

export const VerImagenes = ({ props }) => {

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    return (
        <>
            <Tooltip title="VER IMAGENES">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>FISCALIZACION DE: {props.id}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box py={2}>
                        <p><strong>Imagen Antes</strong></p>
                        <img src={props.imagen_antes} style={{ width: "50%" }} />
                    </Box>
                    <Box py={2}>
                        <p><strong>Imagen Despues</strong></p>
                        <img src={props.imagen_despues} style={{ width: "50%" }} />
                    </Box>
                </DialogContent>

                <DialogActions >
                    <Button onClick={() => setOpen(false)}><strong>REGRESAR</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};