import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase";
import {
    listsSubCategorias,
    listsCategoriasFirebase,
    listsSubCategoriasFirebase,
    listsPresentacionFirebase,
    listsMarcaFirebase,
    listsModeloFirebase,
    listsUnidadMedidaFirebase
} from "../../../../redux/actions/parametersActions";

export const UpdateProduct = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())
        dispatch(listsCategoriasFirebase())
        dispatch(listsSubCategoriasFirebase())
        dispatch(listsPresentacionFirebase())
        dispatch(listsMarcaFirebase())
        dispatch(listsModeloFirebase())
        dispatch(listsUnidadMedidaFirebase())

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [descripcionProducto, setDescripcionProducto] = useState(props.descripcion_producto)

    const handleDescriptionProducto = (e) => {
        setDescripcionProducto(e)
    }

    const [codigoProducto, setCodigoProducto] = useState(props.codigo_producto);
    const [nombreProducto, setNombreProducto] = useState(props.descripcion_producto);

    const [costoEstandarProducto, setCostoEstandarProducto] = useState(props.costo_estandar_producto);
    const [precioCompraProducto, setPrecioCompraProducto] = useState(props.precio_compra_producto);
    const [precioVentaProducto, setPrecioVentaProducto] = useState(props.precio_venta_producto);
    const [cantidadProducto, setCantidadProducto] = useState(props.cantidad_producto);
    const [stockMinimoProducto, setStockMinimoProducto] = useState(props.stock_minimo_producto);
    const [stockMaximoProducto, setStockMaximoProducto] = useState(props.stock_maximo_producto);
    const [stockSeguridadProducto, setStockSeguridadProducto] = useState(props.stock_seguridad_producto);

    const categoriasFirebaseList = useSelector(state => state.categoriasFirebaseList);
    const { categorias, loading } = categoriasFirebaseList
    const [categoria, setCategoria] = useState(props.categoria_producto)

    const subCategoriasFirebaseList = useSelector(state => state.subCategoriasFirebaseList);
    const { subCategorias, loadingSubCategorias } = subCategoriasFirebaseList
    const [SubCategoria, setSubCategoria] = useState(props.subcategoria_producto)

    const ModelosFirebaseList = useSelector(state => state.ModelosFirebaseList);
    const { modeloProductos, loadingModeloProductos } = ModelosFirebaseList
    const [modeloproducto, setModeloProducto] = useState(props.modelo_producto)

    const MarcasFirebaseList = useSelector(state => state.MarcasFirebaseList);
    const { marcaProductos, loadingMarcaProductos } = MarcasFirebaseList
    const [marcaproducto, setMarcaProducto] = useState(props.marca_producto)

    const UnidadesMedidaList = useSelector(state => state.UnidadesMedidaList);
    const { unidadMedidaProductos, loadingUnidadMedidaProductos } = UnidadesMedidaList
    const [unidadMedidaProducto, setUnidadMedidaProducto] = useState(props.unidad_Medida_Producto)

    const PresentacionesFirebaseList = useSelector(state => state.PresentacionesFirebaseList);
    const { presentacionProductos, loadingPresentacionProductos } = PresentacionesFirebaseList
    const [presentacionProducto, setPresentacionProducto] = useState(props.presentacion_producto)
    const changeCategoria = (e) => {
        setCategoria(e)
    }

    const changeSubCategoria = (e) => {
        setSubCategoria(e)
    }

    const changeMarcaProducto = (e) => {
        setMarcaProducto(e)
    }

    const changeModeloProducto = (e) => {
        setModeloProducto(e)
    }

    const changeUnidadMedidaProducto = (e) => {
        setUnidadMedidaProducto(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const changePresentacionProducto = (e) => {
        setPresentacionProducto(e)
        /* dispatch(listsSubCategorias(categoria)) */
    }

    const updateProducto = async () => {
        await db.collection('productos').doc(props.id).update({

            codigo_producto: codigoProducto,
            descripcion_producto: descripcionProducto.toUpperCase(),
            categoria_producto: categoria,
            subcategoria_producto: SubCategoria,
            marca_producto: marcaproducto,
            modelo_producto: modeloproducto,
            unidad_Medida_Producto: unidadMedidaProducto,
            presentacion_producto: presentacionProducto,
            costo_estandar_producto: costoEstandarProducto,
            precio_compra_producto: precioCompraProducto,
            precio_venta_producto: precioVentaProducto,
            stock_minimo_producto: stockMinimoProducto,
            stock_maximo_producto: stockMaximoProducto,
            cantidad_producto: cantidadProducto,
            stock_seguridad_producto: stockSeguridadProducto

        }).then(() => {
            Swal.fire({ icon: "success", text: "Se ha actualizado el producto con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>EDICION DE PRODUCTO: {props.descripcionProducto}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box py={2}>
                        <FormControl fullWidth>

                            {/* <TextField
                                fullWidth
                                label="CODIGO"
                                value={codigoProducto}
                                onChange={(e) => setCodigoProducto(e.target.value)}
                            /> */}
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {/* <Grid item xs={6}>


                                    <Box py={3}>
                                        <TextField
                                            fullWidth
                                            label="NOMBRE PRODUCTO"
                                            value={nombreProducto}
                                            onChange={(e) => setNombreProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid> */}

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row"
                                            alignItems="center" justifyContent="center" spacing={6}>
                                            <FormControl disabled={loading} fullWidth>
                                                <InputLabel>CATEGORIA</InputLabel>
                                                <Select
                                                    value={categoria}
                                                    label="CATEGORIA"
                                                    onChange={(e) => changeCategoria(e.target.value)}>
                                                    {categorias &&
                                                        categorias.map(item => (
                                                            <MenuItem key={item.nombre_categoria_producto} value={item.nombre_categoria_producto} >
                                                                {item.nombre_categoria_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loading && <LinearProgress />}
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={descripcionProducto}
                                        onChange={(e) => handleDescriptionProducto(e.target.value)}
                                        name="descripcionProducto"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                            <FormControl fullWidth>
                                                <InputLabel>SUBCATEGORIA</InputLabel>
                                                <Select
                                                    value={SubCategoria}
                                                    label="SUBCATEGORIA"
                                                    onChange={(e) => changeSubCategoria(e.target.value)}>
                                                    {subCategorias &&
                                                        subCategorias.map(item => (
                                                            <MenuItem key={item.nombre_subcategoria_producto} value={item.nombre_subcategoria_producto} >
                                                                {item.nombre_subcategoria_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loadingSubCategorias && <LinearProgress />}
                                            </FormControl>
                                        </Stack>
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row"
                                            alignItems="center" justifyContent="center" spacing={10}>

                                            <FormControl fullWidth>
                                                <InputLabel>MARCA</InputLabel>
                                                <Select
                                                    value={marcaproducto}
                                                    label="MARCA"
                                                    onChange={(e) => changeMarcaProducto(e.target.value)}>
                                                    {marcaProductos &&
                                                        marcaProductos.map(item => (
                                                            <MenuItem key={item.nombre_marca_producto} value={item.nombre_marca_producto} >
                                                                {item.nombre_marca_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loadingMarcaProductos && <LinearProgress />}
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row"
                                            alignItems="center" justifyContent="center" spacing={9}>

                                            <FormControl fullWidth>
                                                <InputLabel>MODELO</InputLabel>
                                                <Select
                                                    value={modeloproducto}
                                                    label="MODELO"
                                                    onChange={(e) => changeModeloProducto(e.target.value)}>
                                                    {modeloProductos &&
                                                        modeloProductos.map(item => (
                                                            <MenuItem key={item.nombre_modelo_producto} value={item.nombre_modelo_producto} >
                                                                {item.nombre_modelo_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loadingModeloProductos && <LinearProgress />}
                                            </FormControl>
                                        </Stack>
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row"
                                            alignItems="center" justifyContent="center" spacing={7}>


                                            <FormControl fullWidth>
                                                <InputLabel>UNIDAD MEDIDA</InputLabel>
                                                <Select
                                                    value={unidadMedidaProducto}
                                                    label="UNIDAD MEDIDA"
                                                    onChange={(e) => changeUnidadMedidaProducto(e.target.value)}>
                                                    {unidadMedidaProductos &&
                                                        unidadMedidaProductos.map(item => (
                                                            <MenuItem key={item.nombre_unidad_medida_producto} value={item.nombre_unidad_medida_producto} >
                                                                {item.nombre_unidad_medida_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loadingPresentacionProductos && <LinearProgress />}
                                            </FormControl>

                                        </Stack>
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <Stack direction="row"
                                            alignItems="center" justifyContent="center" spacing={2}>


                                            <FormControl disabled={loading} fullWidth>
                                                <InputLabel>PRESENTACION</InputLabel>
                                                <Select
                                                    value={presentacionProducto}
                                                    label="PRESENTACION"
                                                    onChange={(e) => changePresentacionProducto(e.target.value)}>
                                                    {presentacionProductos &&
                                                        presentacionProductos.map(item => (
                                                            <MenuItem key={item.nombre_presentacion_producto} value={item.nombre_presentacion_producto} >
                                                                {item.nombre_presentacion_producto}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {loading && <LinearProgress />}
                                            </FormControl>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <TextField
                                            fullWidth
                                            label="CODIGO"
                                            value={codigoProducto}
                                            onChange={(e) => setCodigoProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <TextField
                                            fullWidth
                                            label="COSTO ESTANDAR"
                                            value={costoEstandarProducto}
                                            onChange={(e) => setCostoEstandarProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="PRECIO COMPRA"
                                        value={precioCompraProducto}
                                        onChange={(e) => setPrecioCompraProducto(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label="PRECIO VENTA"
                                        value={precioVentaProducto}
                                        onChange={(e) => setPrecioVentaProducto(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={2}>
                                        <TextField
                                            fullWidth
                                            label="STOCK ACTUAL"
                                            value={cantidadProducto}
                                            onChange={(e) => setCantidadProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={2}>
                                        <TextField
                                            fullWidth
                                            label="STOCK MINIMO"
                                            value={stockMinimoProducto}
                                            onChange={(e) => setStockMinimoProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <TextField
                                            fullWidth
                                            label="STOCK MAXIMO"
                                            value={stockMaximoProducto}
                                            onChange={(e) => setStockMaximoProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box py={1}>
                                        <TextField
                                            fullWidth
                                            label="STOCK SEGURIDAD"
                                            value={stockSeguridadProducto}
                                            onChange={(e) => setStockSeguridadProducto(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <img src={props.imagenProducto} style={{ width: "100%" }} />

                        </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"

                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};