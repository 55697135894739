import React, { useState, useEffect } from "react";
import {
  TextField, FormControl, Select, MenuItem, Button, Box,
  DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, CircularProgress
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";

export const UpdateCambioDomicilio = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth

  const [data, setData] = useState('')
  //
  const agendaCreate = useSelector(state => state.agendaCreate)
  const { loading: loadingCreateAgenda } = agendaCreate
  //
  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loading } = flotasList
  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList
  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList
  const [open, setOpen] = useState(false);
  const [openFoto, setOpenFoto] = useState(false);
  const [openRechazo, setOpenRechazo] = useState(false);
  const [openChangeColor, setOpenChangeColor] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [value, setValue] = useState(null);
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')
  const [correo, setCorreo] = useState('')
  const [observacion2, setObservacion2] = useState(false);

  const [noSeAgendCliente, setNoSeAgendCliente] = useState(false);




  const handleFlota = (e) => {
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0]
    setNumFlota(data.numeroFlota)
    setLider(data.nombreLider)
    setAuxiliar(data.nombreAuxiliar)
    setCorreo(data.correo)
  }

  const handleLider = (e) => {
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e);
    const data = p[0]
    setLider(data.nombreLider)
  }
  const handleAuxiliar = (e) => {
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e);
    const data = p[0]
    setAuxiliar(data.nombreAuxiliar)
  }

  const handleSubmit = (id) => {
    const formData = {
      ...data,
      estado_pagado: 0,
      fechaHora: value,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      correo_flota: correo,
      numFlota: numFlota,
      estado: 0,
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName,
    }
    const agendaData = {
      estado_pagado: 0,
      fechaHora: value,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      numFlota: numFlota,
      estado: 7,
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName,
    }
    db.collection("registro_orden_trabajo").doc(id).set(formData)
    db.collection("tecnicos").doc(id).set(formData)
    db.collection("agenda").doc(id).update({
      ...agendaData,
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName
    }).then(() => {
      //setOpen(false)
      Swal.fire({ icon: "success", text: "Se hizo su cambio domicilio", })
    })
    db.collection('solicitudes_instalaciones').doc(id).set({
      ...data,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      numFlota: numFlota,
    })
  }

  const rechazarVenta = (id) => {

    db.collection("pre_ventas").doc(id).update({
      estado: 1,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName
    })


    db.collection("agenda").doc(id).update({
      estado: 4,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName
    }).then(() => {
      setOpen(false)


      Swal.fire({ icon: "success", text: "Se ha rechazado la preventa", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });

  }
  //
  const onData = () => {
    setData(props)
    setOpenFoto(true)
    console.log('Verifcamos si hay duplicados', data.cedula)
    //
    db.collection("tecnicos").where("cedula", "==", data.cedula).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
        });
      }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  const clienteNoRespondeLlamada = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 1
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }

  const clienteNoDisponeDinero = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 2
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }
  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => onData()}>
          <EditIcon color='warning' />
        </Button>
      </Tooltip>
      {/* ----------------------------------------------------------------------- */}
      {/* Verificar fotos */}
      <Dialog open={openFoto} fullWidth onClose={() => setOpenFoto(false)} maxWidth="md">
        <DialogTitle><strong>VERIFICAMIENTO FOTOS DE: {data.nombres}</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpenFoto(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <p>CEDULA FRONTAL</p>
                <a target="_blank" href={props.foto_cedula_frontal}>
                  <img src={props.foto_cedula_frontal} width={200} />
                </a>
              </Grid>
              <Grid item xs={6}>
                <p>CEDULA POSTERIOR</p>
                <a target="_blank" href={props.foto_cedula_trasera}>
                  <img src={props.foto_cedula_trasera} width={200} />
                </a>
              </Grid>
              <Grid item xs={6}>
                <p>CEDULA PLANILLA/SENSO</p>
                <a target="_blank" href={props.foto_cliente_planilla}>
                  <img src={props.foto_cliente_planilla} width={200} />
                </a>
              </Grid>
              <Grid item xs={6}>
                <p><strong>CLIENTE LETRERO</strong></p>
                <a target="_blank" href={props.foto_cliente_letrero}>
                  <img src={props.foto_cliente_letrero} width={200} />
                </a>
              </Grid>
              <Grid item xs={6}>
                <p><strong>VIVIENDA CLIENTE</strong></p>
                <a target="_blank" href={props.foto_cliente_vivienda}>
                  <img src={props.foto_cliente_vivienda} width={200} />
                </a>
              </Grid>
              {
                props.foto_carnet_discapacidad &&
                <Grid item xs={6}>
                  <p>Foto carnet discapacidad</p>
                  <img src={props.foto_carnet_discapacidad} style={{ width: "360px" }} />
                </Grid>
              }
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>

          {/* cambioColorEstado */}

          <Button color="error" disableElevation onClick={() => setOpenChangeColor(true)/* cambioColorEstado(data.id) */} variant="contained">
            {" "}
            Motivo no se agenda cliente
          </Button>

          <Button color="error" disableElevation onClick={() => {
            setOpenRechazo(true)
            setOpenFoto(false)
          }} variant="contained">
            {" "}
            Rechazar venta
          </Button>
          <Button color="success"

            disableElevation
            /* onClick={() => verUsuario()} */
            onClick={() => {
              setOpen(true)
              setOpenFoto(false)
            }} variant="contained">

            Agendar
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------------------------------------------- */}
      {/* Formulario agendamiento */}
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>
          <strong>VERIFICAR AGENDAMIENTO DE 3: {data.nombres}</strong>

          <IconButton
            aria-label="close"
            onClose={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box py={2}>
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Seleccione"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </FormControl>
            </Box>

            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Flota</InputLabel>
                <Select
                  label="Flota"
                  value={flota}
                  onChange={(e) => handleFlota(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {flotas && flotas.map((row, key) => (
                    <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>
            {/* --------------------------------------------------------------- */}
            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Lider Flota</InputLabel>
                <Select
                  disabled
                  label="LiderFlota"
                  value={lider}
                  onChange={(e) => handleLider(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {liders && liders.map((row, key) => (
                    <MenuItem value={row.nombreLider}>{row.nombreLider}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>
            <Box py={2}>
              <FormControl fullWidth>
                <InputLabel>Auxiliar</InputLabel>
                <Select
                  disabled
                  label="Auxiliar"
                  value={auxiliar}
                  onChange={(e) => handleAuxiliar(e.target.value)}
                >
                  <MenuItem value="">--</MenuItem>
                  {auxiliars && auxiliars.map((row, key) => (
                    <MenuItem value={row.nombreAuxiliar}>{row.nombreAuxiliar}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Box>
          </LocalizationProvider>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => {
            setOpenConfirm(true)
            setOpen(false)
            /* datosFlota() */
          }} variant="contained"
            disabled={!flota || !value}
          >
            {" "}
            Agendar
          </Button>
        </DialogActions>
      </Dialog>
      {/* ---------------------------------------------------------- */}
      {/* Aceptacion agendamiento */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xs">
        <DialogTitle><strong>¿Esta seguro de agendar?</strong></DialogTitle>
        <DialogContent>
          <p>Agendar cambio domiclio</p>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}><strong>Cancelar</strong></Button>
          <Button
         
            onClick={() => handleSubmit(data.id)} variant="contained">
            Agendar cambio domiclio
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------------------------------- */}
      {/* Rechazar venta */}
      <Dialog open={openRechazo} onClose={() => setOpenRechazo(false)} maxWidth="md">
        <DialogTitle>Seleccione el motivo por el cual rechaza esta venta.</DialogTitle>
        <DialogContent>

          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
              <Select
                label="MOTIVO DEL RECHAZO"

                value={observacion2}
                onChange={(e) => setObservacion2(e.target.value)}
              >
                <MenuItem value="Datos incorrectos">Datos incorrectos</MenuItem>
                <MenuItem value="Coordenadas no concuerdan con direccion">Coordenadas no concuerdan con direccion</MenuItem>
                <MenuItem value="Cambio por baja de plan (Diferente al contrato)">Cambio por baja de plan (Diferente al contrato)</MenuItem>
                <MenuItem value="Cliente con linea suspendida">Cliente con linea suspendida</MenuItem>
                <MenuItem value="Imagenes subidas erroneamente">Imagenes subidas erroneamente</MenuItem>
                <MenuItem value="Imagenes faltantes por el vendedor">Imagenes faltantes por el vendedor</MenuItem>
                <MenuItem value="Cliente no contesta">Cliente no contesta</MenuItem>
                <MenuItem value="Riesgo electrico">Riesgo electrico</MenuItem>
                <MenuItem value="Cliente desiste del servicio">Cliente desiste del servicio</MenuItem>
                <MenuItem value="Cliente no desea cancelar metraje adicional">Cliente no desea cancelar metraje adicional</MenuItem>
                <MenuItem value="Zona sin posteria">Zona sin posteria</MenuItem>
                <MenuItem value="Coordenadas erroneas">Coordenadas erroneas</MenuItem>
                <MenuItem value="Sector sin propiedad legal / invasion">Sector sin propiedad legal / invasion</MenuItem>
                <MenuItem value="Zona peligrosa">Zona peligrosa</MenuItem>
                <MenuItem value="Caja llena sin disponibilidad de puertos">Caja llena sin disponibilidad de puertos</MenuItem>
                <MenuItem value="Ventas duplicadas">Ventas duplicadas</MenuItem>
                <MenuItem value="No tiene dinero">No tiene dinero</MenuItem>
                <MenuItem value="Dificil Acceso">Dificil Acceso</MenuItem>
                {/* <MenuItem value="Cliente Retirado con deuda">Cliente Retirado con deuda</MenuItem>
                <MenuItem value="Cliente cuenta con linea suspendida">Cliente cuenta con linea suspendida</MenuItem>
                <MenuItem value="Cliente extrajero debe cancelar instalacion">Cliente extrajero debe cancelar instalacion</MenuItem>
                <MenuItem value="Datos incorrectos">Datos incorrectos</MenuItem>
                <MenuItem value="Venta repetida">Venta repetida</MenuItem>
                <MenuItem value="Decisión cambio de plan cliente">Decisión cambio de plan cliente</MenuItem>
                <MenuItem value="Imagenes faltantes por el vendedor">Imagenes faltantes por el vendedor</MenuItem>
                <MenuItem value="Imagenes subidas erroneamente">Imagenes subidas erroneamente</MenuItem>
                <MenuItem value="No existe unidad">No existe unidad</MenuItem>
                <MenuItem value="PLAN INCORRECTO">Cliente Retirado con deuda</MenuItem>
                <MenuItem value="NUMEROS TELEFONICOS INCORRECTOS">Cliente Retirado con deuda</MenuItem>
                <MenuItem value="DIRECCION INCORRECTA">Cliente Retirado con deuda</MenuItem>
                <MenuItem value="No hay caña o posteria">No hay caña o posteria</MenuItem>
                <MenuItem value="No hay disponibilidad">No hay disponibilidad</MenuItem>
                <MenuItem value="No hay dinero">No hay dinero</MenuItem>
                <MenuItem value="Sector inseguro">Sector inseguro</MenuItem>
                <MenuItem value="Caja llena">Caja llena</MenuItem>
                <MenuItem value="Coordenadas erroneas del vendedor">Coordenadas erroneas del vendedor</MenuItem>
                <MenuItem value="Cliente no disponible">Cliente no disponible</MenuItem>
                <MenuItem value="No existe unidad">No existe unidad</MenuItem> */}
              </Select>
            </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRechazo(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => rechazarVenta(data.id)} variant="contained">
            {" "}
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>

      {/* ----------------------------------------------------------------------------------------------------------- */}

      <Dialog open={openChangeColor} onClose={() => setOpenChangeColor(false)} maxWidth="md">
        <DialogTitle>Seleccione el motivo por el cual no se agendo el cliente.</DialogTitle>
        <DialogContent>

          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
              <Select
                label="MOTIVO DEL RECHAZO"

                value={noSeAgendCliente}
                onChange={(e) => setNoSeAgendCliente(e.target.value)}
              >
                <MenuItem value="NO RESPONDEN LLAMADAS">NO RESPONDEN LLAMADAS</MenuItem>
                <MenuItem value="NO DISPONE DE DINERO">NO DISPONE DE DINERO</MenuItem>
              </Select>
            </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangeColor(false)}><strong>Cancelar</strong></Button>
          {
            noSeAgendCliente === 'NO RESPONDEN LLAMADAS' ? <Button onClick={() => clienteNoRespondeLlamada(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

          {
            noSeAgendCliente === 'NO DISPONE DE DINERO' ? <Button onClick={() => clienteNoDisponeDinero(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

        </DialogActions>
      </Dialog>

    </>
  );
};