import {
    Tabs,
    Typography,
    Box,
    Tab,
    Paper
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { TicketsCerradosInstalador } from './TicketsCerradosInstalador/TicketsCerradosInstalador';
import { TicketsEnEsperaInstalador } from './TicketsEnEsperaInstalador/TicketsEnEsperaInstalador';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const TicketsEnEsperaInstaladorPage = () => {
    const params = useParams()
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (params.id) {
            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
            setValue(parseInt(params.id));
        }
    }, [params.id]);
    return (
        <>
            <Box px={2}>
                <Typography variant='h5' component='h1'> <strong>TICKETS</strong> </Typography>
            </Box>
            <Box pt={1} px={2}>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Tickets en espera" {...a11yProps(0)} />
                            <Tab label="Tickets cerrados" {...a11yProps(1)} />
                            {/* <Tab label="Tickets rechazados" {...a11yProps(2)} /> */}
                        </Tabs>
                    </Box>
                </Paper>
            </Box>

            <TabPanel value={value} index={0}>
                <TicketsEnEsperaInstalador />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TicketsCerradosInstalador />
            </TabPanel>
        </>
    )
}