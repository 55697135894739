import { Card, Typography, Chip, Box, Paper, Container, Button, Stack, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,

    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../firebase'
import { EdditProducts } from './EdditProducts';
import { DeletProducts } from './DeletProducts';
import { ButtonBack } from '../../components/utils/ButtonBack';
import { AddPremios } from './AddPremios';
function Estado(estado) {
    let name;
    let color;
    console.log(estado.props, 'estado')
    if (estado.props === 1) {
        name = "Activo";
        color = "success";
    } else {
        name = "Borrador";
        color = 'info';
    }
    return <Chip label={name} color={color} />;
}
export const PremiosList = () => {
    const params = useParams()
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData()
    }, []);
    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("premios")
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                console.log(data, 'data')
                setData(data);
            });
        }
    };
    const columns = [
        {
            field: 'product_photo', headerName: '', width: 100,
            renderCell: (params) => {
                return params.row.product_photo ? <img src={params.row.product_photo[0]} width='100%' alt='' /> : ''
            }
        },
        { field: 'product_name', headerName: 'Producto', width: 200 },
        {
            field: 'estado', headerName: 'Estado', width: 125,
            renderCell: (params) => {
                return <Estado props={params.row} />
            }
        },
        { field: 'product_quantity', headerName: 'Cantidad', width: 100 },
        { field: 'product_price', headerName: 'Precio', width: 100 },
        { field: 'product_description', headerName: 'Descripcion', width: 200 },
        {
            field: 'created', headerName: 'Creacion', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
        },
        {
            field: 'acciones', headerName: 'Acciones', width: 125,
            renderCell: (params) => {
                return <Stack direction="row">
                    <EdditProducts props={params.row} />
                    <DeletProducts props={params.row} />
                </Stack>
            }
        },
    ];
    const csvOptions = {
        delimiter: ';',
        fileName: `PRODUCTOS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    };
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            {/* <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton /> */}
        </GridToolbarContainer>
    );
    function NoRowsOverlay() {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">

            </Stack>
        );
    }
    return (
        <>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                <Box pt={5}>
                    <Typography variant="h4" component="h1"><strong>Premios</strong></Typography>
                </Box>
                <Stack my={2} direction="row" spacing={2}>
                    <AddPremios />
                </Stack>
                <Paper>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={data ? data : []}

                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            hideFooter
                            components={{
                                toolbar: CustomToolbar,
                                NoRowsOverlay: NoRowsOverlay
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
                <Box pt={2}>
                    <ButtonBack />
                </Box>
            </Container>
        </>
    )
}