import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, tabsClasses, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { InstalacionesEspera } from './InstalacionesEspera';
import { InstalacionesRechazadas } from './InstalacionesRechazadas';
import { InstalacionesRealizadas } from './InstalacionesRealizadas';
import { InstalacionesDelDia } from './InstalacionesDelDia';
import { InstalacionesConObservacion } from '../../components/instaladores/InstalacionesConObservacion';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
export const InstaladoresPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      setTab(parseInt(params.id))
    }
  }, [params.id])
  return (
    <>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1">TECNICOS/<strong>INSTALADORES</strong></Typography>
      </Box>
      <Box sx={{ maxWidth: { xs: 300, sm: '100%' }, bgcolor: 'background.paper' }}>
        <Tabs
          variant="scrollable"
          indicatorColor="primary"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
        >
          <Tab label="En Espera" />
          <Tab label="Realizadas" />
          <Tab label="Rechazadas" />
          {/* <Tab label="Del Dia" /> */}
          <Tab label="Instalaciones con observacion" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <InstalacionesEspera />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <InstalacionesRealizadas />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <InstalacionesRechazadas />
      </TabPanel>
      {/* <TabPanel value={tab} index={3}>
        <InstalacionesDelDia />
      </TabPanel> */}

      <TabPanel value={tab} index={3}>
        <InstalacionesConObservacion />
      </TabPanel>

    </>
  )
}