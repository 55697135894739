import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Typography, Box, Breadcrumbs, TextField, CardContent, Card, CircularProgress } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { detallesSolicitud } from '../../../../redux/actions/ventasActions';
import Loader from '../../../../components/utils/Loader'
import { useParams } from 'react-router-dom';
import { sendCodeOTP, submitCodeOTP } from '../../../../redux/actions/ventas/solicitudesActions';
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";
export const CodigoVerificacion = ({ items }) => {
    let navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const solicitudDetalle = useSelector(state => state.solicitudDetalle)
    const { loading, solicitud } = solicitudDetalle
    //
    const sendCode = useSelector(state => state.sendCode)
    const { loading: loadingSendCode, success: successSendCode } = sendCode
    //
    const codigoOTPSubmit = useSelector(state => state.codigoOTPSubmit)
    const { loading: loadingCodeOTP } = codigoOTPSubmit
    //
    const [codigo, setCodigo] = useState('')
    const [active, setActive] = useState(false)
    const [count, setCount] = useState(false)
    useEffect(() => {
        dispatch(detallesSolicitud(params.id))
    }, [dispatch])
    //
    const enviarCodigo = (data) => {
        let celular = data.celular.substring(1, 10);
        const newData = {
            ...data,
            celular: celular,
        }
        console.log('Datos enviado2:', newData)
        dispatch(sendCodeOTP(newData, navigate))
    }
    const onSubmitOTP = (e) => {
        console.log('submit')
        dispatch(submitCodeOTP(solicitud, codigo, navigate))
    }
    const handleActive = (solicitud) => {
        enviarCodigo(solicitud)
        setActive(true)
    }
    return (
        <>{
            !loading ?
                <>
                    <Box py={2}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            <Button component={RouterLink} to={`/ventas/solicitud-de-servicio/`}>
                                Solicitud de servicio
                            </Button>
                            <Button component={RouterLink} to={`/ventas/nueva-venta/${params.id}`}>
                                Nueva venta
                            </Button>
                            <Button component={RouterLink} to={`/ventas/cargar-documento/${params.id}`}>
                                Carga documento
                            </Button>
                            <Button >
                                Codigo OTP
                            </Button>
                        </Breadcrumbs>
                    </Box>
                    <Card variant='outlined'>
                        <CardContent>
                            {
                                !active ? <>
                                    <Box pb={2}>
                                     
                                        <Typography variant='h5' component='h1'><strong>Verificar numero celular</strong> </Typography>
                                        <Typography>Envia un SMS para verificar el numero celular {solicitud.celular}</Typography>
                                    </Box>
                                    <Button
                                        disabled={loadingSendCode}
                                        variant='contained'
                                        disableElevation
                                        onClick={() => {
                                            handleActive(solicitud)
                                            setCount(true)
                                        }
                                        } >
                                        Enviar SMS
                                    </Button>
                                </>
                                    :
                                    <>
                                        <Box pb={4}>
                                        <Typography>SOLICITUD: {solicitud.uid}</Typography>
                                            <Typography variant='h5' component='h1'><strong>Codigo de confirmacion</strong> </Typography>
                                            <Typography>Introduce el codigo que hemos enviado al numero de celular que es {solicitud.celular}</Typography>
                                        </Box>
                                        <TextField
                                            variant='filled'
                                            type="number"
                                            onChange={(e) => setCodigo(e.target.value)}
                                            value={codigo}
                                            label="Codigo"
                                        />

                                        <Box pt={4} >
                                            <Box pb={4}>
                                                <Button
                                                    disabled={!codigo || loadingSendCode || loadingCodeOTP || codigo.length !== 4}
                                                    variant='contained'
                                                    disableElevation
                                                    onClick={() => onSubmitOTP(codigo)}
                                                >
                                                    {!loadingSendCode ? <strong>Verificar codigo</strong> : <CircularProgress size={24} />}

                                                    {loadingCodeOTP && <CircularProgress size={24} />}

                                                </Button>
                                            </Box>
                                            <Button disabled={count} disableElevation onClick={() => enviarCodigo(solicitud)}>
                                                Reenvíar SMS {
                                                    count === true &&
                                                    <>
                                                        En<div style={{ paddingLeft: 5 }}>
                                                            <Countdown
                                                                date={Date.now() + 100000}
                                                                onComplete={() => setCount(false)}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </Button>
                                        </Box>
                                    </>
                            }
                        </CardContent>
                    </Card>
                </>
                : <Loader />
        }
        </>
    )
}