import { Chip, Box, Button, Collapse, Typography, Container, Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../firebase";
import moment from "moment";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import SubirVoucher from "../../components/ventas/SubirVoucher";
export const VentasSubirVoucher = () => {
  function EstadoPagado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad

    if (d.estado_pagado === 0 || !d.estado_pagado) {
      name = 'NO PAGADO'
      color = 'info'
    }
    if (d.estado_pagado === 1) {
      name = 'VERIFICANDO'
      color = 'info'
    }
    if (d.estado_pagado === 2) {
      name = 'PAGADO'
      color = 'secondary'
    }
    if (d.estado_pagado === 3) {
      name = 'NO PAGADO'
      color = 'error'
    }

    return (
      <Chip label={name} size="small" color={color} />
    )
  }
  function EstadoAgenda({ estado }) {
    
    let name
    let color
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'PRE AGENDAMIENTO'
      color = 'info'
    }
    if (estado === 1) {
      name = 'POR AGENDAR'
      color = 'warning'
    }
    if (estado === 2) {
      name = 'AGENDADA'
      color = 'secondary'
    }
    if (estado === 3) {
      name = 'RE AGENDADAS'
      color = 'error'
    }
    if (estado === 4) {
      name = 'RECHAZADA'
      color = 'error'
    }
    if (estado === 5) {
      name = 'DOMICILIO INSTALADO'
      color = 'secondary'
    }
    if (estado === 6) {
      name = 'CAMBIO DOMICILIO'
      color = 'success'
    }
    if (estado === 7) {
      name = 'AGENDADA'
      color = 'success'
    }
    return (
      <Chip variant='outlined' label={name} size="small" color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  )
  useEffect(() => {
    LoadData()
  }, [])

  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("agenda")
    if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
      ref = ref.where("user_uid", "==", auth.currentUser.uid);
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("created", ">=", startfulldate);
      ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ]
        setData(data)
      })
    }
  }
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const csvOptions = {
    delimiter: ';',
    fileName: `VENTAS AGENDADAS 2 ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  }
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  function CustomFooterTotalComponent(props) {
    return ''
  }
  const columns = [
    {
      field: 'fechaHora', headerClassName: 'super-app-theme--header', headerName: 'FECHA DE INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'estadopago1', headerName: 'ESTADO PAGADO', width: 140,
      renderCell: (params) => {
        return (
          <EstadoPagado estado={params.row} />
        )
      }
    },
    // {
    //   field: 'estado_agenda', headerName: 'ESTADO AGENDA', width: 150,
    //   valueGetter: ({ value }) => value === 0 ? 'PRE AGENDAMIENTO' : value === 1 ? 'POR AGENDAR' : value === 2 ? 'AGENDADA' : value === 3 ? 'RE AGENDADAS' : value === 4 ? 'RECHAZADA' : ''
    // },
    {
      field: 'estado_agenda', headerName: 'ESTADO AGENDA', width: 200,
      renderCell: (params) => {
        return (
          <EstadoAgenda estado={params.row.estado} />
        )
      }
    },
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    {
      field: '', headerName: '', width: 90,
      renderCell: (params) => {
        return (
          params.row.estado_pagado == 0 && <SubirVoucher props={params.row} />
        )
      }
    },

    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'nombres', headerName: 'CLIENTE', width: 250 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 125,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado', headerName: 'DISCAPACIDAD', width: 125,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 150 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 100 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      valueGetter: (params) => { 
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido', headerName: 'REFERIDO', width: 150,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },

    {
      field: 'flotaReferida', headerName: 'FLOTA REFERIDA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },

    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 200,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    {
      field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'personaQueAgendo', headerName: 'USUARIO QUE AGENDO', width: 200 },
    {
      field: 'fechaDeAgendamiento', headerName: 'FECHA DE AGENDAMIENTO', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'estado_pagado', headerName: 'estado_pagado', width: 200 },
  ];

  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Card>
          <Box pl={1}>
            <Typography>
              <strong>({data && data.length})</strong> AGENDA
            </Typography>
            <Box display="flex" style={{ textTransform: "uppercase" }}>
              <Typography variant="button">
                <strong>Desde</strong>{" "}
                {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
              </Typography>
              <Typography variant="button" style={{ paddingLeft: 10 }}>
                <strong>Hasta</strong>{" "}
                {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
              </Typography>
            </Box>
          </Box>
          <Box pl={1} >
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                </Box>
              </Box>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
                <Button
                  disableElevation
                  variant="contained"
                  onClick={() => LoadData()}
                >
                  Aplicar busqueda
                </Button>
              </Collapse>
            </Box>

          </Box>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              density='compact'
              rows={data ? data : []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              columns={columns}
              components={{
                Toolbar: CustomToolbar,
                Footer: CustomFooterTotalComponent,
              }}
              onStateChange={(state) => {
              }}
            />
          </Box>
        </Card>
      </Container>
    </>
  );
};
