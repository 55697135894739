import React, { useState, useEffect } from "react";
import {
    Grid, Card, Typography, Select, Box,
    MenuItem, InputLabel, CardContent, FormControl
} from "@mui/material";
import { db } from '../../firebase';
export const CentroCosto = () => {
    const [lider_, setLider_] = useState('')
    const [centro_, setCentro_] = useState('')
    const [categoria_, setCategoria_] = useState('')
    const [canal_, setCanal_] = useState('')
    //
    const [list_lider, setListLider] = useState([])
    const [list_centro, setListCentro] = useState([])
    const [list_categoria, setListCategoria] = useState([])
    const [list_canal, setListCanal] = useState([])
    useEffect(() => {
        loadRoles()
    }, [])
    const loadRoles = () => {
        db.collection('system/centro_de_costos/lider_de_ventas').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListLider(arrayMessage)
        })
        db.collection('system/centro_de_costos/centro_de_costos').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCentro(arrayMessage)
        })
        db.collection('system/centro_de_costos/categoria').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCategoria(arrayMessage)
        })
        db.collection('system/centro_de_costos/canal').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setListCanal(arrayMessage)
        })
    }
    return (
        <>
            <Card>
                <CardContent>
                    <Typography>Centro de Costo</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Box pb={4}>
                                <InputLabel >LIDER DE VENTA</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        fullWidth
                                        size='small'
                                        value={lider_}
                                        onChange={(e) => setLider_(e.target.value)}>
                                        {list_lider.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <InputLabel >CENTRO DE COSTO</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    size='small'
                                    value={centro_}
                                    onChange={(e) => setCentro_(e.target.value)}>
                                    {list_centro.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box pb={4}>
                                <InputLabel >CATEGORIA</InputLabel>
                                <FormControl fullWidth>
                                    <Select
                                        fullWidth
                                        size='small'
                                        value={categoria_}
                                        onChange={(e) => setCategoria_(e.target.value)}>
                                        {list_categoria.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <InputLabel >CANAL</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    size='small'
                                    value={canal_}
                                    onChange={(e) => setCanal_(e.target.value)}>
                                    {list_canal.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};
