import {
  Typography,
  Box,
  Paper,
  Chip,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../firebase";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
function Estado(estado) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado.props == 0) {
    name = "Esperando..";
    color = "warning";
  } else if (estado.props === 1) {
    name = "APROBADAS";
    color = "success";
  }
  return <Chip size='small' label={name} color={color} />;
}
export const CallCenterAprobada = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState();

  let current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 5184000000)
  );
  const [endDate, setEndDate] = useState(
    new Date()
  );
  useEffect(() => {
    LoadData();
  }, [startDate]);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth;
  const LoadData = (startAfterDoc, persistMessages = []) => {
    //
    let ref = db.collection("solicitudes_call_center").orderBy("fecha_aprobada", "desc").where("estado", "==", 1);

    if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
      ref = ref.where("user_uid", "==", auth.currentUser.uid)

    if (userInfo?.roles?.find(role => ['corporativo']?.includes(role)))
      ref = ref.where("contrato", "==", "CORPORATIVO")

    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("fecha_aprobada", ">=", startfulldate);
      ref = ref.where("fecha_aprobada", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);

      });
    }

  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const onChange2 = () => {
    LoadData();
  };

  const columns = [
    {
      field: 'fecha_aprobada', headerName: 'FECHA APROBACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && 
      moment(new Date(value.seconds * 1000).toISOString())
      .format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'estado', headerName: 'ESTADO', width: 125,
      renderCell: (params) => {
        return <Estado props={params.row.estado} />
      }
    },
    { field: 'id', headerName: 'SOLICITUD', width: 125 },
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 125 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
    { field: 'motivo', headerName: 'MOTIVO', width: 300 },
    { field: 'direccion', headerName: 'DIRECCION', width: 400 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 250 },
    { field: 'correo', headerName: 'CORREO', width: 250 },
  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `SOLICITUD APROBADAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          <strong>({data ?  data.length : 0})</strong> CALL CENTER/SOLICITUDES APROBADAS
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>

          {/* <Box display="flex" alignItems="center" py={2}>
              <SearchInput setSearch={setSearch} search={search} />
          
              <ExportarCSV data={data} />
            </Box> */}
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ?? []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
