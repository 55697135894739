import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux"
import Swal from "sweetalert2";
import { funcitons, db } from '../../../firebase'
import { Grid, Box, Button, DialogActions, Paper, Chip, Card, CardContent, Typography, TextField } from "@mui/material"
export const Herramientas = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { loading, client_details } = clientDetails
    const params = useParams()
    const [open, setOpen] = useState(null)
    const [plan_nombre, setPlan] = useState('')
    useEffect(() => {
       
    }, [])
    const activar = () => {
        console.log(client_details.username, 'activar', client_details.assigned_ipv4)
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "username": client_details.username,
            "ip_nav": `${client_details.assigned_ipv4}`
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://radiusapi.intercommerce.com.ec/api/reactivarClienteStatic", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                Swal.fire({ icon: "success", title: "Cliente activado correctamente" })
                db.collection(`clientes`).doc(client_details.numContrato).update({
                    estado: 1,
                    ipv4: client_details.assigned_ipv4,
                })
            })
            .catch(error => console.log('error', error));
    }
    const desactivar = () => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "username": client_details.username,
            "ip_no_nav": `${client_details.ipv4_no_navigation}`
        
        })
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }
        fetch("https://radiusapi.intercommerce.com.ec/api/corteClienteStatic", requestOptions)
            .then(response => response.text())
            .then(result => {
                Swal.fire({ icon: "success", title: "Cliente suspendido correctamente" })
                console.log(result)
                db.collection(`clientes`).doc(client_details.numContrato).update({
                    estado: 0,
                    ipv4: client_details.ipv4_no_navigation,
                })
            })
            .catch(error => console.log('error', error))
    }
    const cambio_plan = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        console.log({
            "username": client_details.username,
            "plan": plan_nombre
        
        })
        var raw = JSON.stringify({
            "username": client_details.username,
            "plan": plan_nombre
        
        })
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }
        fetch("https://radiusapi.intercommerce.com.ec/api/cambioPlan ", requestOptions)
            .then(response => response.text())
            .then(result => {
                Swal.fire({ icon: "success", title: "Cliente suspendido correctamente" })
                console.log(result)
                db.collection(`clientes`).doc(client_details.numContrato).update({
                    estado: 0,
                    ipv4: client_details.ipv4_no_navigation,
                })
            })
            .catch(error => console.log('error', error))
    }
    return (
        <>
            <DialogActions>
                <Typography>
                    username:     {client_details.username}
                </Typography>
                <Typography>
                    ipv4:     {client_details.ipv4}
                </Typography>
                <TextField 
                    onChange={(e) => setPlan(e.target.value)}
                    label='plan nombre'
                    value={plan_nombre}
                />
                <Button
                    disabled={!plan_nombre}
                    onClick={() => cambio_plan()}
                    variant='contained'
                >cambio plan</Button>
                <Button
                    color='success'
                    onClick={() => activar()}
                    variant='contained'
                >ACTIVAR</Button>
                <Button
                    onClick={() => desactivar()}
                    
                    variant='contained'
                    color='warning'
                >SUSPENDER</Button>
            </DialogActions>
        </>
    )
}
