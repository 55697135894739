import React, { useState } from "react";
import {
  Typography, Button, 
  DialogActions, DialogContent, DialogTitle, Dialog,  Tooltip
} from "@mui/material";
import {  useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from "../../firebase";
import Swal from "sweetalert2";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
import InputValidation from "../../components/utils/InputValidation";
export const UpdateCallCenter = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth

  const [data, setData] = useState('')

  const [open, setOpen] = useState(false);
  const [openRechazo, setOpenRechazo] = useState(false);
  const [motivo, setMotivo] = useState({ campo: "", valido: true })

  const handleSubmit = (id) => {
    db.collection("solicitudes_call_center").doc(id).update({
      estado: 1,
      fecha_aprobada:  firebase.firestore.FieldValue.serverTimestamp()
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se aprobo su solicitud" });
    })
  }
  const handleRechazar = (id) => {
    db.collection("solicitudes_call_center").doc(id).update({
      estado: 3,
      motivo: motivo.campo,
      fechaDeRechazo: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazo: userInfo.displayName
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha rechazado la solcitud", });
    })
  }
  const onData = () => {
    setData(props)
    setOpen(true)
  }
  return (
    <>
      <Tooltip title="Opciones">
        <Button onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle><strong>{data.nombres}</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogActions>
          <Button color="error" disableElevation onClick={() => {
            setOpen(false)
            setOpenRechazo(true)
          }} variant="contained">
            RECHAZAR
          </Button>
          <Button color="success"
            disableElevation
            onClick={() => handleSubmit(data.uid)} variant="contained">
            APROBAR
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openRechazo} fullWidth onClose={() => setOpenRechazo(false)} maxWidth="md">
        <DialogTitle>RECHAZAR</DialogTitle>
        <DialogContent dividers>
          <Typography>Escriba el motivo por cual rechaza esta solicitud.</Typography>
          <InputValidation
            estado={motivo}
            cambiarEstado={setMotivo}
            label="MOTIVO"
            name="motivo"
          />
        </DialogContent>
        <DialogActions>
          <Button 
            disabled={  motivo.valido === false ||!motivo.campo}
            onClick={() => handleRechazar(data.uid)} 
            variant="contained"
            >
            Rechazar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};