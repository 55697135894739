import {
  Container, Table, Typography, TableContainer, TableRow, TableCell,
  TableBody, TableHead, Box, Paper, IconButton, Chip, Button, Collapse
} from '@mui/material';
import React, { useEffect, useContext, useState } from 'react'
import moment from 'moment'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { auth, db, firebase } from '../../../firebase'
import { useSelector } from 'react-redux';

import DatePicker from "react-datepicker";
import FilterListIcon from '@mui/icons-material/FilterList';
import { VerificarFactibilidad } from '../../../components/operations/factibilidad/VerificarFactibilidad';
function Estado({ estado }) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado == 0) {
    name = 'Esperando..'
    color = 'warning'
  } else if (estado === 1) {
    name = 'No factible'
    color = 'info'
  } else if (estado === 2) {
    name = 'FACTIBILIDAD'
    color = 'success'
  }
  return (
    <Chip label={name} color={color} />
  )
}

function FACTIBILIDAD({ factibilidad }) {
  let name;
  //Controlamos el estado de la factiblidad
  if (factibilidad == 0) {
    name = ''
  } else if (factibilidad === 1) {
    name = ''
  } else if (factibilidad === 2) {
    name = 'CONFIRMAR VENTA'
  }
  return (
    <Chip label={name} />
  )
}

const center = {
  lat: -3.745,
  lng: -38.523
};

export const SolicitudesEnEspera = () => {
  //Google APIS
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCCYpBE0mJCwN_Zs3hbX4iXQozoKlScLPk"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [open, setOpen] = useState(false);
  const [data, setData] = useState()
  const [isLastPage, setIsLastPage] = useState(0)
  const [queryDoc, setQueryDocqueryDoc] = useState([])
  const [reData, setreData] = useState([]);
  const [search, setSearch] = useState('')
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const onChange2 = () => {
    LoadData()
  }

  useEffect(() => {
    LoadData()
    if (data) {
      const data2 = [
        ...data.map(doc => {
          return {
            ...doc,
            //created: doc.created.toDate(),
            latitud: doc.coordenadas._lat,
            longitud: doc.coordenadas._long,
          }
        })
      ]
      console.log('hola', data2)
      setreData(data2)

    }
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    //Validaciones
  }

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  const LoadData = (startAfterDoc, persistMessages = []) => {
   

      let ref = db.collection("solicitudes").orderBy('created', 'desc').where('estado', '==', 0)

      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('created', '>=', startfulldate)
        ref = ref.where('created', '<=', endfulldate)

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
         
          setData(data)
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
          setIsLastPage(totalCount < 1)

        })
      }
    
  }

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          FACTIBILIDAD<strong>({data && data.length})</strong> SOLICITUDES EN ESPERA
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems='center' py={2}>
          
            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>
          
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>FECHA DE CREACION DE SOLICITUD</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>DIRECCION</TableCell>
               
                <TableCell>CANTON</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR </TableCell>
                <TableCell> ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA</TableCell>
                <TableCell>ACCIONES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.filter((val) => {
                if (search === '') {
                  return val
                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                  return val
                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                  return val
                }
              }).map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.created &&
                      typeof row.created === "number" &&
                      moment(new Date().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    {row.created &&
                      typeof row.created === "object" &&
                      moment(row.created.toDate().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sector}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>
                  
                  <TableCell component="th" scope="row">
                    {row.canton}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.referido === '' ? 'NO' : row.referido}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <VerificarFactibilidad props={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}