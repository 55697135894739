import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Card,
    CardContent,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import moment from 'moment'
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { db } from "../../../../firebase";
export const MostrarSeriesReporteMovimientos = ({ props, row }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        LoadData()

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [data, setData] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])

    const [total, setTotal] = useState(0);

    const LoadData = async () => {

        var docRef = db.collection("reporte_movimientos").doc(row.uidSolicitud).collection('items').doc(props.fullName).collection('series')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

        /* let ref = db.collection("reporte_movimientos").doc(row.uidSolicitud).collection('items').doc(props.fullName).collection('series').get()
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            console.log(data)
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        }) */

    };

    const columns = [
        /* { field: 'bodega', headerName: 'BODEGA', width: 140 }, */
        { field: 'serie', headerName: 'SERIE', width: 180 },
        { field: 'usuario_que_cargo_serie', headerName: 'USUARIO QUE CARGO LA SERIE', width: 360 },
        {
            field: 'created', headerName: 'FECHA DE GENERACION SERIE', type: 'dateTime', width: 260,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
        },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total :{total}</Box>
        </div>
    }

    const verId = async () => {
        console.log(row.uidSolicitud)
        console.log(props.id)
        const agendaDB3 = await db.collection("reporte_movimientos").doc(row.uidSolicitud).collection('items').doc(props.fullName).collection('series').get()

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        console.log(data3[0].serie)
    }

    return (
        <>
            <Tooltip title="VER SERIES">
                <Button onClick={() => onData()}>
                    <QrCodeScannerIcon />
                </Button>
            </Tooltip>

            {/* LISTA DE OPCIONES */}

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    SERIES PERTENECIENTES A: <strong> {props.id} - {props.descripcion}</strong>
                </DialogTitle>
                <DialogContent>

                    <p style={{ fontSize: "13px" }}><strong>CODIGO: </strong>{props.codigo_producto}</p>

                    <Paper>
                        <Box sx={{ height: 460, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                // disableColumnFilter
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,

                                }}

                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>



        </>
    );
};