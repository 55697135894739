import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Paper,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase";


import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

export const ViewTransferenciaAprobada = ({ props }) => {

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const [total, setTotal] = useState(0);

    const [data, setData] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])

    const [open, setOpen] = useState(null);

    const [openAcceptTransferencia, setOpenAcceptTransferencia] = useState(null);

    const [openRechazoTransferencia, setOpenRechazoTransferencia] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("transferencias").doc(props.uidSolicitud).collection('items')

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    const dispatch = useDispatch();

    useEffect(() => {
        LoadData()

    }, [dispatch])

    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 165 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 350 },
        { field: 'bodega_origen', headerName: 'BODEGA ORIGEN', width: 190 },
        { field: 'bodega_destino', headerName: 'BODEGA DESTINO', width: 190 },
        { field: 'cantidad_transferencia', headerName: 'CANTIDAD TRANSFERIDA', width: 205 },
    ];

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const aprobarSolicitud = () => {
        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 2,
            usuario_que_aprobo: userInfo.displayName,
        })
        setOpenAcceptTransferencia(false)
        Swal.fire({ icon: "success", title: `Solicitud aprobada con exito` });
    }

    const rechazarSolicitud = () => {
        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 1,
            usuario_que_rechazo: userInfo.displayName,
        })
        setOpenRechazoTransferencia(false)
        Swal.fire({ icon: "success", title: `Solicitud rechazada con exito` });
    }

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => onData()}>
                    <RemoveRedEyeIcon />
                </Button>
            </Tooltip>
            {/* DIALOG SOLICITUDES DE TRANSFERENCIAS */}
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">

                <DialogTitle>
                    <strong>SOLICITUD DE TRANSFERENCIA: {props.uidSolicitud}</strong>
                </DialogTitle>

                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper>
                            <Box sx={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,
                                    }}

                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)} color='secondary' variant="contained" size="small"><strong>SALIR</strong></Button>
                </DialogActions>

            </Dialog>


        </>
    );
};