import { Box, Typography, Button, Paper, IconButton, Chip } from "@mui/material";
import {
    DataGrid, GridToolbarContainer, GridToolbarExportContainer, GridCsvExportMenuItem, GridToolbarFilterButton, esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../../firebase";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GenerarOrdenCompra } from "./GenerarOrdenCompra";
//
//
export const OrdenComprasEspera = () => {
    const [open, setOpen] = useState(null);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth;
    const [orden, setOrden] = useState("");
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData();
        loadNumOrden();
    }, [startDate]);
    //
    const LoadData = (startAfterDoc, persistMessages = []) => {

        
        let ref = db.collection("solicitud_compra").orderBy("created", "desc").where("estado", "==", 1);
        if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
            ref = ref.where("user_uid", "==", auth.currentUser.uid);
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where("created", ">=", startfulldate);
            ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                setData(data);
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
                setIsLastPage(totalCount < 1);
            });
        }
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    };
    const onChange2 = () => {
        LoadData();
    };
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const loadNumOrden = () => {
        const ref = db.collection("orden_compra").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                var uidSolicitud = `ORD-COM-` + numeroSolicitud;
                setOrden(numeroSolicitud)
            }
        })
    }
    const columns = [
        {
            field: 'created', headerName: 'FECHA SOLICITUD', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="ESPERA" color="warning" />
            }
        },
        { field: 'id', headerName: 'SOLICITUD', width: 200 },
        {
            field: '', headerName: 'ACCIONES', width: 100,
            renderCell: (params) => {
                return <GenerarOrdenCompra items={params.row} />
            }
        },
        { field: 'motivo', headerName: 'MOTIVO', width: 800 },
        { field: 'rol', headerName: 'DEPARTAMENTO', width: 200 },
        { field: 'user', headerName: 'USUARIO', width: 400 },

    ];
    return (
        <>
            <Box pt={1} pl={1}>
                <Typography>
                    EN ESPERA
                </Typography>
                <Box display="flex" style={{ textTransform: "uppercase" }}>
                    <Typography variant="button">
                        <strong>Desde</strong>{" "}
                        {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
                    </Typography>
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>{" "}
                        {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} display="flex" flexDirection="row-reverse">
                <Box display="flex" alignItems="center" pb={1}>
                    <IconButton id="basic-button" onClick={() => setOpen(!open)}>
                        <FilterListIcon />
                    </IconButton>
                </Box>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box p={2}>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button
                            size="small"
                            disableElevation
                            variant="contained"
                            onClick={onChange2}
                        >
                            Aplicar busqueda
                        </Button>
                    </Box>
                </Collapse>

            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {

                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
