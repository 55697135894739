import {
  Container,
  TextField,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Paper,
  Chip,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export const UpdateFeasibilityRechazado = ({ props }) => {

  const [nombres, setNombre] = useState(false);
  const [cedula, setCedula] = useState(false);
  const [direccion, setDireccion] = useState(false);
  const [coordenadas, setCoordenadas] = useState(false);
  const [observaciones, setObservaciones] = useState({ campo: "", valido: true });

  const [open, setOpen] = useState(false);
  const [estado, setEstado] = useState(props.estado);
  const [cajas, setCajas] = useState(false);

  useEffect(() => {
    console.log(props)
    setNombre(props.nombres);
    setCedula(props.cedula);
    setDireccion(props.direccion);
    setObservaciones(props.observacion);
    setCajas(props.caja);
    /* setObservaciones(props.observaciones); */
    /* setCoordenadas(props.coordenadas._lat, props.coordenadas._long) */
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const siFactible = (id) => {
    //Validaciones
    db.collection("solicitudes")
      .doc(id)
      .update({
        estado: 2,
        observacion: observaciones
      })
      .then((docRef) => {
        console.log("licencia ", docRef);
        Swal.fire({
          icon: "success",
          title: "Has confirmado que hay factibilidad.",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const handleSubmit = (id) => {
    db.collection("solicitudes")
      .doc(id)
      .update({
        nombres: nombres,
        observacion: observaciones,
        cedula: cedula,
        direccion: direccion,
        caja: cajas
      })
      .then((docRef) => {
        Swal.fire({
          icon: "success",
          title: "Se ha actualizado la informacion exitosamente.",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }



  const spot = () => { };

  return (
    <>


      {/* <Tooltip title="FACTIBILIDAD">
          <Button onClick={() => siFactible(props.id)}>
            <CheckIcon />
          </Button>
        </Tooltip> */}



      <Tooltip title="Editar">
        <Button onClick={handleClickOpen}>
          <EditIcon />
        </Button>
      </Tooltip>
      {/* <Tooltip title="Ver mapa">
          <Button component={RouterLink} to={`/configuraciones/googleapi/`}>
            <RoomIcon />
          </Button>
        </Tooltip> */}

      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        {/* <DialogTitle>Verificar la FACTIBILIDAD de {props.nombre}</DialogTitle> */}

        <DialogTitle>Numero de solicitud {props.numeroSolicitud} - vendedor: {props.cedula}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Se creo{" "}
            {moment(props.created.toDate().toString()).format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
          </DialogContentText>
          <Box py={2}>
            <TextField
              fullWidth
              label="Nombres"
              value={nombres}

              onChange={(e) => setNombre(e.target.value)}
            />
          </Box>
          <Box py={2}>
            <TextField
              fullWidth
              label="Cedula"
              value={cedula}

              onChange={(e) => setCedula(e.target.value)}
            />
          </Box>

          <Box py={2}>
            <TextField
              fullWidth
              label="Direccion"
              value={direccion}

              onChange={(e) => setDireccion(e.target.value)}
            />
          </Box>
          {/* <Box py={1}>
                        <TextField
                            fullWidth
                            label="Coordenadas"
                            value={coordenadas }
                            variant="filled"
                        />
                    </Box> */}



          <Box py={2}>
            <TextField
              fullWidth
              label="Caja"
              value={cajas}
              onChange={(e) => setCajas(e.target.value)}
            />
          </Box>



          {/* <Box py={1}>
            <InputValidation
              estado={observaciones}
              cambiarEstado={setObservaciones}
              label="Observaciones*"
              name="observaciones"
            />
          </Box> */}
          <Box py={1}>
            <FormControl fullWidth>
              <InputLabel>Observaciones</InputLabel>
              <Select
                label="Observaciones"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              >
                <MenuItem value="Area sin cobertura de red">Area sin cobertura de red</MenuItem>
                <MenuItem value="Inexistencia de posteria autorizada">Inexistencia de posteria autorizada</MenuItem>
                <MenuItem value="Distancia mayor a 250 mts">Distancia mayor a 250 mts</MenuItem>
                <MenuItem value="Inaccesibilidad geografica">Inaccesibilidad geografica</MenuItem>
                <MenuItem value="No disponibilidad de puerto fisico (caja segundaria llena fisicamente)">No disponibilidad de puerto fisico (caja segundaria llena fisicamente)</MenuItem>
                <MenuItem value="No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)">No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Tooltip title="FACTIBILIDAD">
            <Button onClick={() => siFactible(props.id)}>
              <CheckIcon />
            </Button>
          </Tooltip>

          <Tooltip title="Ver mapa">
            <Button component={RouterLink} to={`/configuraciones/googleapi/`}>
              <RoomIcon />
            </Button>
          </Tooltip>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button onClick={() => handleSubmit(props.id)} variant="contained">
            {" "}
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
