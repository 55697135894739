import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import { InstalacionesPorFiscalizara } from './InstalacionesPorFiscalizara';
import { InstalacionesFiscalizadas } from './InstalacionesFiscalizadas';
import { FiscalizacionesRechazadas } from './FiscalizacionesRechazadas';
import { InstalacionesPorGestionar } from './InstalacionesPorGestionar';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const FiscalizacionPage = () => {

    const params = useParams()

    const [tab, setTab] = useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    useEffect(() => {
        if (params.id) {
            console.log(typeof params.id)
            setTab(parseInt(params.id));
        }
    }, [params.id]);
    return (
        <>
            <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="solicitud de servicio">
                <Tab label="Instalaciones por Fiscalizar" {...tabs(0)} />
                <Tab label="Instalaciones Fiscalizadas" {...tabs(1)} />
                <Tab label="Fiscalizaciones Rechazadas" {...tabs(2)} />
                <Tab label="Fiscalizaciones por Gestionar" {...tabs(3)} />

            </Tabs>
            <TabPanel value={tab} index={0}>
                <InstalacionesPorFiscalizara />
            </TabPanel>

            <TabPanel value={tab} index={1}>
                <InstalacionesFiscalizadas />
            </TabPanel>

            <TabPanel value={tab} index={2}>
                <FiscalizacionesRechazadas />
            </TabPanel>

            <TabPanel value={tab} index={3}>
                <InstalacionesPorGestionar />
            </TabPanel>
        </>
    )
}