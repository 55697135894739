import {
    Paper,
    Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { UpdateProveedor } from "./ButtonActions/UpdateProveedor";
import { DeleteProveedor } from "./ButtonActions/DeleteProveedor";

export const ListProveedores = () => {


    const [data, setData] = useState();

    useEffect(() => {
        LoadData()
    }, []);



    const LoadData = () => {
        let ref = db.collection("proveedores");

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
        });
    };

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />

            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );


    const columns = [
        { field: 'tipo', headerName: 'TIPO', width: 120 },
        { field: 'identificacion', headerName: 'IDENTIFICACION', width: 160 },
        { field: 'cuenta', headerName: 'CUENTA', width: 500 },
        { field: 'razon_social', headerName: 'RAZON SOCIAL', width: 320 },
        { field: 'nombre_comercial', headerName: 'NOMBRE COMERCIAL', width: 350 },
        { field: 'direccion', headerName: 'DIRECCION', width: 540 },
        { field: 'email', headerName: 'EMAIL', width: 300 },
        { field: 'grupo_general', headerName: 'GRUPO GENERAL', width: 150 },
        { field: 'telefono1', headerName: 'TELEFONO 1', width: 130 },
        { field: 'telefono2', headerName: 'TELEFONO 2', width: 130 },
        { field: 'tipo_de_proveedor', headerName: 'TIPO DE PROVEEDOR', width: 200 },
        { field: 'regimen', headerName: 'REGIMEN', width: 240 },
        { field: 'sustento_tributario', headerName: 'SUSTENTO TRIBUTARIO', width: 520 },

        { field: 'forma_pago_asignada', headerName: 'FORMA DE PAGO ASIGNADA', width: 220 },
        { field: 'pago_a', headerName: 'PAGO A', width: 150 },
        { field: 'retencion_iva_bienes', headerName: 'RETENCION IVA BIENES', width: 300 },

        { field: 'retencion_iva_servicios', headerName: 'RETENCION IVA SERVICIOS', width: 300 },

        // { field: 'estado', headerName: 'ESTADO', width: 120 },
        // { field: 'relacionado', headerName: 'RELACIONADO', width: 150 },

        { field: 'compras', headerName: 'COMPRAS', width: 150 },
        { field: 'pais', headerName: 'PAIS PAGO', width: 150 },
        {
            field: '1', headerName: 'EDITAR', width: 90,
            renderCell: (params) => {
                return <UpdateProveedor props={params.row} />
            }
        },
        {
            field: '2', headerName: 'ELIMINAR', width: 90,
            renderCell: (params) => {
                return <DeleteProveedor props={params.row} />
            }
        },
    ];

    return (
        <>
            <Box pt={1} pl={1}>
            </Box>

            <Paper>
                <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={data ?? []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        density="compact"
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}

                    />
                </Box>
            </Paper>
        </>
    );
};

