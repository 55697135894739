import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Paper,
  Chip,
  Collapse,
  Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../../firebase";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  esES
} from '@mui/x-data-grid';
import { SeriesCargaInicial } from "../carga_inicial/IconButtonsCargaInicial/SeriesCargaInicial";
import { MostrarSeries } from "./MostrarSeries";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { ReporteStockPDF } from "./ReporteStockPDF";

export const ReporteStock = () => {

  const [copyCollectionReporteStock, setCopyCollectionReporteStock] = useState([]);

  const cronReporteStock = async () => {

    const agendaDB2 = await db.collection("reporte_stock")

    agendaDB2.onSnapshot(snapshot => {
      const data = [
        ...snapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      ];
      setCopyCollectionReporteStock(data)
      console.log('data reporte stock', data)
    })

  }

  const dispatch = useDispatch()

  useEffect(() => {
    LoadData()
    dataProduct()
    setTotal()
    setSumaBodegas()
    setTotalValorizado()
    cronReporteStock()
  }, [])

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  function Estado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 0) {
      name = 'POR INSTALAR'
      color = 'warning'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }
    return (
      <Chip label={name} size="small" variant="outlined" color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  var totalInstalaciones;
  const [item, setItem] = useState();
  const [nombreZona, setNombreZona] = useState();
  const [codigoZona, setCodigoZona] = useState("");
  const [data, setData] = useState();
  const [data2, setData2] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([])
  const [isLastPage2, setIsLastPage2] = useState(0);
  const [queryDoc2, setQueryDocqueryDoc2] = useState([])
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0);
  const [dataPdf, setDataPdf] = useState(0);
  //
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  //

  useEffect(() => {
    tabla()
  }, [data])

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    //LoadData()
  }

  const totalSumaValorizada = async () => {

    /* const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", newInputValue).get()

    const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    var saldoTotalValorizado;
    let sumaTotal = 0;
    for (let i = 0; i < data3.length; i++) {
      console.log(data3[i]["total_valorizado"])
      sumaTotal += parseFloat(data3[i]["total_valorizado"])
      setTotalValorizado(sumaTotal)
      saldoTotalValorizado = sumaTotal
    } */
  }

  const LoadData = async () => {
    var docRef = await db.collection("reporte_stock")
    docRef.get().then((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      setData(arrayMessage)
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  };

  function CustomFooterTotalComponent(props) {
    return <div style={{ display: "inline-flex" }}>
      <Box sx={{ padding: "10px", display: "flex" }}>Total: {total}</Box>
      <Box sx={{ padding: "10px", display: "flex" }}>Stock Actual: {sumaBodegas}</Box>
      <Box sx={{ padding: "10px", display: "flex" }}>Total valorizado: {totalValorizado}</Box>
    </div>
  }

  const columns = [
    { field: 'codigo_producto', headerName: 'CODIGO', width: 140 },
    /* { field: 'bodega', headerName: 'BODEGA', width: 140 }, */
    {
      field: 'bodega', headerName: 'BODEGA', width: 200,
      renderCell: (params) => {
        const respuesta = params.row.bodega
        return respuesta.toUpperCase()
      }
    },
    { field: 'descripcion', headerName: 'DESCRIPCION', width: 520 },
    { field: 'categoria', headerName: 'SUBTIPO', width: 120 },
    { field: 'unidad_medida', headerName: 'UNIDAD MEDIDA', width: 140 },
    {
      field: '21', headerName: 'PRECIO COMPRA', width: 160,
      renderCell: (params) => {
        const respuesta = parseFloat(Math.abs(params.row.precio_compra))
        return respuesta.toLocaleString('en', { maximumFractionDigits: 2 })
      }
    },
    /* { field: 'precio_compra', headerName: 'PRECIO COMPRA', width: 130 }, */
    {
      field: '20', headerName: 'STOCK ACTUAL', width: 160,
      renderCell: (params) => {
        const respuesta = parseFloat(Math.abs(params.row.stock_actual))
        return respuesta.toLocaleString('en', { maximumFractionDigits: 2 })
      }
    },
    /* { field: 'stock_actual', headerName: 'STOCK ACTUAL', width: 130 }, */
    /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
    /* { field: 'costo_promedio', headerName: 'COSTO PROMEDIO', width: 150 }, */
    {
      field: '8', headerName: 'COSTO PROMEDIO', width: 160,
      renderCell: (params) => {
        const respuesta = parseFloat(params.row.costo_promedio)
        return `$${respuesta.toFixed(2)}`
      }
    },
    /* { field: '', headerName: 'MOVIMIENTO VALORIZADO', width: 110 }, */
    {
      field: '', headerName: 'TOTAL VALORIZADO', width: 160,
      renderCell: (params) => {
        const respuesta = parseFloat(Math.abs(params.row.total_valorizado))
        return `$${respuesta.toLocaleString('en', { maximumFractionDigits: 2 })}`
      }
    },
    {
      field: '2', headerName: 'MOSTRAR SERIES', width: 150,
      renderCell: (params) => {
        return <MostrarSeries props={params.row} />
      }
    },


  ];

  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
      {/* <GridToolbarQuickFilter /> */}

    </GridToolbarContainer>
  );

  const esVerdadero = 0;

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    const reData = [
      ...data.map(doc => {
        return {

          LOCALIDAD: doc.bodega,

          CODIGO_PRODUCTO: doc.codigo_producto,

          DESCRIPCION: doc.descripcion,

          SUBTIPO: doc.categoria,

          UNIDAD_MEDIDA: doc.unidad_medida,

          STOCK: doc.stock_actual,

          COSTO_PROMEDIO: doc.costo_promedio,

          TOTAL: doc.total_valorizado,

        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "reporte_stock");
    XLSX.writeFile(wb, `REPORTE_STOCK_${date1}.xlsx`)
  }

  const [sumaBodegas, setSumaBodegas] = useState()

  const [totalValorizado, setTotalValorizado] = useState()

  const [totalReporteStock, setTotalReporteStock] = useState()

  const dataProduct = async () => {

    var docRef = await db.collection("reporte_stock")
    docRef.get().then((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      setData(arrayMessage)

      var datoSumaTotalBodegas;

      let suma = 0;
      for (let i = 0; i < arrayMessage.length; i++) {
        suma += parseFloat(arrayMessage[i]["stock_actual"])
        datoSumaTotalBodegas = suma
      }

      setSumaBodegas(parseFloat(suma).toLocaleString('en'))

      var saldoTotalValorizado;
      let sumaTotal = 0;
      for (let i = 0; i < arrayMessage.length; i++) {
        sumaTotal += parseFloat(arrayMessage[i]["total_valorizado"])
        setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))
        saldoTotalValorizado = sumaTotal
      }

      setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))

    }).catch((error) => {
      console.log("Error getting document:", error);
    });

    /* console.log('dataProduct')

    console.log(`TOTAL REPORTE STOCK ${data && data.length}`)

    const contadorTotal = data && data.length

    var datoSumaTotalBodegas;

    let suma = 0;
    for (let i = 0; i < contadorTotal.length; i++) {
      console.log(contadorTotal[i]["stock_actual"])
      suma += parseFloat(contadorTotal[i]["stock_actual"])
      datoSumaTotalBodegas = suma
    }

    setSumaBodegas(parseFloat(suma).toLocaleString('en'))

    var saldoTotalValorizado;
    let sumaTotal = 0;
    for (let i = 0; i < await contadorTotal.length; i++) {
      console.log(contadorTotal[i]["total_valorizado"])
      sumaTotal += parseFloat(contadorTotal[i]["total_valorizado"])
      setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))
      saldoTotalValorizado = sumaTotal
    }

    setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en')) */

  }

  const exporter = React.createRef(null);

  const excelExport = () => {
    if (exporter.current) {
      exporter.current.save();
    }
  };

  const tabla = () => {
    let datos = []
    if (data) {
      data.map((item) => {
        datos.push({
          unidad_medida: item.unidad_medida === undefined ? "No asignado" : item.unidad_medida,
          codigo_producto: item.codigo_producto === undefined ? "No asignado" : item.codigo_producto,
          bodega: item.bodega === undefined ? "No asignado" : item.bodega,
          descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,
          categoria: item.categoria === undefined ? "No asignado" : item.categoria,
          total_valorizado: item.total_valorizado === undefined ? 0.00 : parseFloat(item.total_valorizado).toFixed(2),
          costo_promedio: item.costo_promedio === undefined ? 0.00 : parseFloat(item.costo_promedio).toFixed(2),
          stock_actual: item.stock_actual === undefined ? 0.00 : parseFloat(item.stock_actual).toFixed(2),
          precio_compra: item.precio_compra === undefined ? 0.00 : parseFloat(item.precio_compra).toFixed(2),
          total_valorizado2: item.total_valorizado === undefined ? 0.00 : Number(parseFloat(item.total_valorizado).toFixed(2)),
          costo_promedio2: item.costo_promedio === undefined ? 0.00 : Number(parseFloat(item.costo_promedio).toFixed(2)),
          stock_actual2: item.stock_actual === undefined ? 0.00 : Number(parseFloat(item.stock_actual).toFixed(2)),
          precio_compra2: item.precio_compra === undefined ? 0.00 : Number(parseFloat(item.precio_compra).toFixed(2)),
        })
      })
      setDataPdf(datos)
    }
  };

  const createDailyCopyReporteStock = async () => {

    /* const agendaDB4 = await db.collection("reporte_stock").get();

    const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    console.log('data4', data4) */

    const currentDate = new Date();

    // Días de la semana y meses en español
    const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    // Obtiene el nombre del día, día del mes, nombre del mes y año
    const nombreDia = diasSemana[currentDate.getDay()];
    const diaMes = currentDate.getDate();
    const nombreMes = meses[currentDate.getMonth()];
    const anio = currentDate.getFullYear();

    // Construye el string con el formato deseado
    const fechaFormateada = `${nombreDia} ${diaMes} de ${nombreMes} del ${anio}`;

    // Imprime la fecha formateada por consola
    console.log('Fecha actual:', fechaFormateada);

    await db.collection('daily_copy_reporte_stock').doc(fechaFormateada).set({
      id_copia: fechaFormateada,
      fecha_copia: firebase.firestore.FieldValue.serverTimestamp(),
    })

    copyCollectionReporteStock.forEach(async (obj, index) => {
      console.log('obj', obj)
      console.log('obj.id', obj.id)
      console.log('index', index)

      const dataRef = {
        ...obj
      }

      await db.collection('daily_copy_reporte_stock').doc(fechaFormateada).collection('copy').doc(obj.id).set(dataRef)
    })
  }

  return (
    <>
      {/* <Button onClick={() => dataProduct()}>
        Press
      </Button> */}

      {/* <Box pt={1}>

        <Card>
          <CardContent>
            <Box pt={2} pb={2}>
              <p style={{ fontSize: "12px", marginBottom: "8px" }}><strong>BUSQUEDA POR CODIGO:</strong></p>

            </Box>

            



            <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
              <TableContainer sx={{ maxHeight: 540 }}>
                <Table size="small" stickyHeader aria-label="simple table">

                  <TableHead>

                    <TableRow>

                      <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>BODEGA</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>DESCRIPCION</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>CATEGORIA</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>UNIDAD MEDIDA</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>STOCK ACTUAL</strong></TableCell>

                      <TableCell style={{ fontSize: '11px' }}><strong>COSTO PROMEDIO</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>TOTAL VALORIZADO</strong></TableCell>
                      <TableCell style={{ fontSize: '11px' }}><strong>INGRESAR SERIES'</strong></TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data.map((row) => (


                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                        <TableCell style={{ fontSize: '11px' }}>{row.codigo_producto}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{row.bodega}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{row.descripcion}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{row.categoria}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{row.unidad_medida}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{`${row.stock_actual.toLocaleString('en')}`}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{`$${row.costo_promedio.toLocaleString('en')}`}</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{
                          `$${parseFloat(row.stock_actual) * parseFloat(row.costo_promedio)}`
                        }</TableCell>

                        <TableCell style={{ fontSize: '11px' }}>{<SeriesCargaInicial props={row} />}</TableCell>





                      </TableRow>


                    ))}

                  </TableBody>

                </Table>

              </TableContainer>

              <TableRow>
                <TableCell colSpan={5}></TableCell>
                <TableCell align="center" ><p style={{ fontSize: "12px" }}><strong>STOCK ACTUAL</strong></p></TableCell>
                <TableCell align="center"><p style={{ fontSize: "11px" }}>{sumaBodegas}</p></TableCell>

                <TableCell align="center" ><p style={{ fontSize: "12px" }}><strong>SALDO VALORIZADO</strong></p></TableCell>
                <TableCell align="center"><p style={{ fontSize: "11px" }}>{parseFloat(totalValorizado).toLocaleString('en')}</p></TableCell>

                <TableCell colSpan={2}></TableCell>
              </TableRow>
            </Paper>
          </CardContent>
        </Card>
      </Box> */}

      {/* <Paper>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGridPremium
                    density="compact"
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    // disableColumnFilter
                    // disableColumnSelector
                    // disableDensitySelector
                    //hideFooterPagination
                    columns={columns}
                    components={{
                        Toolbar: GridToolbar,
                        Footer: CustomFooterTotalComponent,

                    }}

                    onStateChange={(state) => {
                        console.log(state.pagination.rowCount);
                        setTotal(state.pagination.rowCount)
                    }}
                />
            </Box>
        </Paper> */}

      {/* <Button color="primary" size="small" variant="contained" onClick={() => { createDailyCopyReporteStock() }}>
        daily copy
      </Button> */}

      <Box pt={0} pl={1}>
        <Typography variant="button" >

          <Typography >
            <strong>REPORTE STOCK</strong>
          </Typography >
        </Typography >
      </Box>
      <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          {/* <Stack direction="row">

            <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
            >
              {" "}
              DESCARGAR EXCEL
            </Button>
          </Stack > */}

          <Stack direction="row" spacing={2}>

            <Button onClick={excelExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
            >
              {" "}
              EXCEL
            </Button>
            <Button onClick={() => setOpen2(true)} size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />}
            >
              {" "}
              PDF
            </Button>
          </Stack >

          <ExcelExport data={dataPdf} fileName="REPORTE STOCK.xlsx" ref={exporter} filterable={true} >
            <ExcelExportColumn field="codigo_producto" title="CODIGO" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="bodega" title="BODEGA" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="descripcion" title="DESCRIPCION" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="categoria" title="SUBTIPO" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="unidad_medida" title="UNIDAD MEDIDA" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="precio_compra2" title="PRECIO COMPRA" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="stock_actual2" title="STOCK ACTUAL" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="costo_promedio2" title="COSTO PROMEDIO" headerCellOptions={{ background: "#672780" }} />
            <ExcelExportColumn field="total_valorizado2" title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
          </ExcelExport>

          <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xl">

            <DialogContent>
              <ReporteStockPDF contenido={dataPdf} />
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
            </DialogActions>
          </Dialog>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pt={1}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Box>
            <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
      </Box>

      <Paper>
        <Box sx={{ height: 690, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            // disableColumnFilter
            // disableColumnSelector
            // disableDensitySelector
            //hideFooterPagination
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}

            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>

    </>
  );
};