import { TextField, Button, Box, Grid } from "@mui/material";
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Estado } from '../../utils/Estado'
export const ViewEsperaSale = ({ props }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  const onData = () => {
    setOpen(true)
    setData(props)
  }
  return (
    <>
      <Tooltip title="Visualizar">
        <Button onClick={() => onData()}>
          <VisibilityIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen aria-labelledby={data.uid} aria-describedby={data.uid} open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle> NUMERO DE SOLICITUD {data.uid}</DialogTitle>
        <DialogContent>
          <Grid container >
            <Grid item xs={6}>
              <Box py={2}>
                <TextField
                  fullWidth
                  disabled
                  label="NOMBRES"
                  value={data.nombres}
                />
              </Box>
              <Box py={2}>
                <TextField
                  fullWidth
                  disabled
                  label="CEDULA"
                  value={data.cedula}
                />
              </Box>
              <Box py={2}>
                <TextField
                  fullWidth
                  label="DIRECCION"
                  value={data.direccion}
                />
              </Box>

            </Grid>
            <Grid item xs={6}>
              <Box py={2}>
                <TextField
                  fullWidth
                  label="CORREO ELECTRONICO"
                  value={data.correo}
                />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="CELULAR" value={data.celular} />
              </Box>
              <Box py={2}>
                <TextField
                  fullWidth
                  label="TELEFONO "
                  value={data.telefono}
                />
              </Box>
              <Box py={2}>
                <TextField
                  fullWidth
                  label="OBSERVACIONES"
                  value={data.observacion}
                />
              </Box>
            </Grid>
          </Grid>



        </DialogContent>
        <DialogActions>
          <Estado estado={data.estado} />
          <Button onClick={() => setOpen(false)} disableElevation variant="contained">
            {" "}
            CERRAR VENTANA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
