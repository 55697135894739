import { Typography, Box, Tabs, Tab, Card, CardContent } from '@mui/material';
import React, { useState } from 'react'
import { ListaNodosVisitados } from './ListaNodosVisitados';
import { NuevaVisitaNodo } from './NuevaVisitaNodo';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const VisitaDeNodosPage = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Visita De Nodos" {...tabs(0)} />
                        <Tab label="Nodos Visitados" {...tabs(1)} />
                        {/* <Tab label="Buscar Series" {...tabs(2)} /> */}
                        {/* <Tab label="Autocomplete" {...tabs(3)} /> */}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <NuevaVisitaNodo />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ListaNodosVisitados />
                    </TabPanel>
                </CardContent>
            </Card>
        </>
    )
}