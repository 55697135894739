import {
    TextField, Typography, Box, Grid, Button, AlertTitle, Alert,
    FormControl, MenuItem, Select, InputLabel, Container, Card, CardContent, Dialog
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputValidation from "../../../components/utils/InputValidation";
import Swal from "sweetalert2";
import SelectGeografico from '../../../components/utils/SelectGeograficos';
import { useDispatch, useSelector } from 'react-redux';
import { createSolicitud, listSolicitudes } from "../../../redux/actions/ventas/solicitudesActions";
import { LoadingButton } from "@mui/lab";
import { auth, firebase, functions, db } from '../../../firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { GeolocationGoogle } from '../../../components/utils/GeolocationGoogle'
import { listsPlanes } from "../../../redux/actions/parametersActions";
import { Divider } from "@mui/material";
export const NuevoCambioDomicilio = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    //Estado de crear nueva solicitud
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { planes } = useSelector(state => state.planesList)
    const [statusClient, setStatusClient] = useState();
    const [contractArray, setContractArray] = useState([]);
    const [linea, setLinea] = useState('')
    const [latitude, setLatitude] = useState()
    const [longitude, setLongitude] = useState()
    const [cedula2, setCedula2] = useState('');
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [fechaNacimiento, setFechaNacimiento] = useState({ campo: "", valido: true });
    const [direccion, setDireccion] = useState({ campo: "", valido: true });
    const [telefono, setTelefono] = useState({ campo: "", valido: true });
    const [celular, setCelular] = useState({ campo: "", valido: true });
    const [correo, setCorreo] = useState({ campo: "", valido: true });
    const [nacionalidad, setNacionalidad] = useState({ campo: "", valido: true });
    const [plan, setPlan] = useState([]);
    const [planValor, setPlanValor] = useState();
    const [geografico, setGeografico] = useState('');
    const [file, setFile] = useState(null);
    const [suspendido, setSuspendido] = useState(false)
    const [position, setPosition] = useState({
        lat: latitude,
        lng: longitude,
    })

    const [planId, setPlanId] = useState();
    //
    const [planName, setPlanName] = useState('')

    const [camara, setCamara] = useState('NO')
    const [numCam, setNumCam] = useState('')
    //
    const [num_contrato, setNumContrato] = useState('')
    const [contrato, setContrato] = useState('')
    const [tipoContrato, setTipoContrato] = useState('')
    const [list_planes, setListPlanes] = useState([])

    useEffect(() => {
        if (props.ticket) {
            setCedula2(props.ticket.cedula)
            apiCivil(props.ticket.cedula)
        }
    }, [props]);
    useEffect(() => {
        geolocationPosition();
        dispatch(listSolicitudes())
        dispatch(listsPlanes())
        geo()
    }, [dispatch]);
    const geo = () => {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        function success(pos) {
            let crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        };
        function error(err) {
            console.warn('ERROR(' + err.code + '): ' + err.message);
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
    }

    const apiCivil = (cedula2) => {
        setStatusClient('')
        setContractArray('')
        setSuspendido(false)
        setNombre({ campo: "", valido: true })
        setDireccion({ campo: "", valido: true })
        setNacionalidad({ campo: "", valido: true })
        setFechaNacimiento({ campo: "", valido: true })
        setCedula2(cedula2)
        if (cedula2 && cedula2.length >= 10) {
            const mikrowisp = functions.httpsCallable('mikrowisp')
            mikrowisp(cedula2)
                .then(res => {

                    if (res) {
                        if (res.mensaje == "No existe el cliente con el filtro indicado.") {
                            setStatusClient("Este cliente es nuevo en Yiga5");
                        } else {
                            const contracts = res.data;
                            const data = [
                                ...contracts.map((doc) => {
                                    return {
                                        ...doc,
                                        NUM_CONTRATO: doc.NUM_CONTRATO,
                                        estado: doc.estado,
                                        REFERENCIA: doc.REFERENCIA,
                                    };
                                }),
                            ];
                            setContractArray(data);
                            setStatusClient("");
                            if (res.data.find((element) => element.estado.includes('SUSPENDIDO'))) {
                                setSuspendido(true)
                                Swal.fire({ icon: "warning", text: "Cliente con contrato suspendido" })
                            }
                        }
                    }
                })
                .catch(error => console.log('Function: ', error))
        }

    };
    const submitHandler = () => {

        //Enviamos la solicitud
        const dataForm = {
            ...contrato,
            uid: auth.currentUser.uid,
            tipo_venta: 'CAMBIO DOMICILIO',
            cambio_domicilio: true,
            cedula: cedula2,
            nombres: nombre.campo,
            direccion: direccion.campo,
            nacionalidad: nacionalidad.campo,
            telefono: telefono.campo,
            celular: celular.campo,
            correo: correo.campo,
            coordenadas: new firebase.firestore.GeoPoint(
                position.lat,
                position.lng
            ),
            fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
            centro_costo: {
                codigo_vendedor: '40',
                lider_venta: userInfo.vendedor.lider_venta,
                categoria: userInfo.vendedor.categoria,
                centro_costo: userInfo.vendedor.centro_costo,
                canal: userInfo.vendedor.canal
            },
            contrato: '',

            fechaNacimiento: fechaNacimiento.campo,
            observacion: 'No hay observaciones',
            estado: 0,
            camara: camara,
            numCam: numCam,
            planId: planId,
            planNombre: planName,
            planValor: planValor,
            provincia: geografico.provincia,
            canton: geografico.canton,
            parroquia: geografico.parroquia,
            sector: geografico.sector,
            cargo: userInfo.cargo,
            vendedor: userInfo.displayName,
            linea: linea,
            // camara: 'NO'
        }
        if (props.ticket !== null) {
            let comentarios = []
            comentarios.push({
                departamento: props.ticket.departamento ? props.ticket.departamento : 'SOPORTE NIVEL 1',
                comentario: 'Solicitud enviada en soei',
                usuario: userInfo.displayName,
                accion: 'FINALIZAR',
            })
            comentarios = props.ticket.comentarios ? props.ticket.comentarios.concat(comentarios) : comentarios
            let raw = {
                comentarios: comentarios,
                motivo: 'RESUELTO',
                estadosolicitud: '2',
                usuario_modificacion: userInfo.displayName,
                fecha_fin_ticket: firebase.firestore.FieldValue.serverTimestamp(),
                fecha_modificacion: firebase.firestore.FieldValue.serverTimestamp(),
            }
            if (props.ticket.estadosolicitud !== '2' && props.ticket.estadosolicitud !== '4') {
                db.collection("CRM_cambio_domicilio").doc(props.ticket.id + '').update({
                    ...raw,
                }).then(() => {
                    console.log('enviado')
                }).catch((error) => {
                    console.log('Error', error)
                })
            }

        }
        dispatch(createSolicitud(dataForm, navigate))


    };
    const geolocationPosition = () => {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        function success(pos) {
            let crd = pos.coords;

            setPosition({
                lat: crd.latitude,
                lng: crd.longitude,
            })
        }

        function error(err) {
            console.warn("ERROR(" + err.code + "): " + err.message);
        }
        navigator.geolocation.getCurrentPosition(success, error, options);
    };

    const handlePlan = (e) => {
        const p = planes.filter((item) => item.id === e)
        const plan = p[0]
        setPlan(e)
        setPlanName(plan.plan)
        setPlanId(plan.id)
        setPlanValor(parseFloat(plan.costo))
    }
    //Escoger el contrato
    const handleContrato = (e) => {
        setContrato('')
        setNumContrato(e)
        const p = contractArray.filter((item) => item.NUM_CONTRATO === e);
        const data = p[0]
        setContrato(data)
    }

    const handleTipoContrato = (e) => {
        setTipoContrato(e)
        if (e == 'HOGAR') {
            const hogar = planes.filter(row => (row.plan.includes('DEFENDER PLUS_')
                || row.plan.includes('UNLIMITED')))
            setListPlanes(hogar)
        } else {
            const corpor = planes.filter(row => !row.plan.includes('DEFENDER'))
            setListPlanes(corpor)
        }
    }
    return (
        <>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                {statusClient && <Alert severity="info">{statusClient}</Alert>}
                {contrato && (
                    <Alert severity="info">
                        <AlertTitle>
                            Información del contrato {contrato.NUM_CONTRATO}
                        </AlertTitle>

                        <Grid container spacing={5}>
                            <Grid item lg={6} md={6} xs={12}>
                                <Typography >
                                    <strong>Nombres: </strong>{contrato.nombre}
                                </Typography>
                                <Typography >
                                    <strong>Correo: </strong>{contrato.correo}
                                </Typography>
                                <Typography >
                                    <strong>Direccion1: </strong>{contrato.REFERENCIA}
                                </Typography>
                                <Typography >
                                    <strong>Direccion2: </strong>{contrato.direccion_principal}
                                </Typography>
                                <Typography >
                                    <strong>Telefonos: </strong>{contrato.telefono}
                                </Typography>
                                <Typography >
                                    <strong>Celular: </strong>{contrato.movil}
                                </Typography>
                            </Grid>
                            <Grid item lg={6} md={6} xs={12}>
                                <Typography >
                                    <strong>PLANES: </strong>
                                </Typography>
                                {contrato?.servicios.map((item, index) => (
                                    <Typography key={index} value={item.NUM_CONTRATO}>{item.perfil} - <strong>{item.estado}</strong></Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </Alert>
                )}
                <Grid container spacing={5}>
                    <Grid item md={4} xs={12}>
                        <Box py={2}>
                            <TextField
                                value={cedula2}
                                disabled={props.ticket !== null}
                                onChange={(e) => apiCivil(e.target.value)}
                                label="CÉDULA | RUC"
                                size='small'
                            />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box py={2}>
                            {
                                contractArray && cedula2.length >= 10 &&
                                <FormControl fullWidth disabled={!contractArray}>
                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <Select
                                        value={num_contrato}
                                        label="LINEA DE CONTRATO"
                                        size='small'
                                        onChange={(e) => { handleContrato(e.target.value) }}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {contractArray?.map((item, index) => (
                                            <MenuItem key={index} value={item.NUM_CONTRATO}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                    </Grid>
                </Grid>

                <Box pb={2}><Divider /></Box>
                {
                    contrato && <>
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Box py={3}>
                                    <InputLabel >LINEA DE CONTRATO</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            value={linea}
                                            label="LINEA DE CONTRATO"
                                            size='small'
                                            onChange={(e) => { setLinea(e.target.value) }}
                                        >
                                            <MenuItem value="">-</MenuItem>
                                            <MenuItem value="L1">L1</MenuItem>
                                            <MenuItem value="L2">L2</MenuItem>
                                            <MenuItem value="L3">L3</MenuItem>
                                            <MenuItem value="L4">L4</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <InputValidation

                                    estado={nombre}
                                    cambiarEstado={setNombre}
                                    label="NOMBRES"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                //patterns={/^.{8,30}$/}
                                />
                                <InputValidation
                                    estado={celular}
                                    cambiarEstado={setCelular}
                                    label="CELULAR"
                                    type="number"
                                    placeholder=""
                                    name="name"
                                    helperText="Iniciar con 09 y minimo 10 digitos"
                                    patterns={/^\d{10}$/}
                                />
                                <SelectGeografico cambiarEstado={setGeografico} />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputValidation
                                    estado={direccion}
                                    cambiarEstado={setDireccion}
                                    label="DIRECCIÓN"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                //patterns={/^.{8,30}$/}
                                />
                                <InputValidation
                                    estado={telefono}
                                    type="number"
                                    cambiarEstado={setTelefono}
                                    label="TELEFONO"
                                    name="name"
                                    helperText="Minimo 10 caracteres"
                                    patterns={/^.{0,10}$/}

                                />
                                <Box py={3}>
                                    <TextField
                                        placeholder="LATITUD"
                                        type="number"
                                        onChange={(e) => setPosition({ ...position, lat: parseFloat(e.target.value) })}
                                        value={position.lat}
                                    />
                                </Box>
                                <TextField
                                    placeholder="LONGITUD"
                                    type="number"
                                    onChange={(e) => setPosition({ ...position, lng: parseFloat(e.target.value) })}
                                    value={position.lng}
                                />
                                <Box pt={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>TIPO DE CONTRATO</InputLabel>
                                        <Select
                                            label="TIPO DE CONTRATO"
                                            value={tipoContrato}
                                            onChange={(e) => handleTipoContrato(e.target.value)}
                                        >
                                            <MenuItem value="HOGAR">HOGAR</MenuItem>
                                            <MenuItem value="CORPORATIVO">CORPORATIVO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box py={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>PLAN</InputLabel>
                                        <Select
                                            value={plan}
                                            label="PLAN"
                                            onChange={(e) => handlePlan(e.target.value)}
                                        >
                                            <MenuItem value="">--</MenuItem>
                                            {list_planes?.map((row) => (
                                                <MenuItem key={row.id} value={row.id}>{row.plan}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {
                                    camara == 'SI' &&
                                    <Box pb={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>NUM CAMARA</InputLabel>
                                            <Select
                                                value={numCam}
                                                label="CAMARA INTELIGENTE"
                                                onChange={(e) => setNumCam(e.target.value)}
                                            >
                                                <MenuItem value="1">1</MenuItem>
                                                <MenuItem value="2">2</MenuItem>
                                                <MenuItem value="3">3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputValidation
                                    estado={nacionalidad}
                                    cambiarEstado={setNacionalidad}
                                    label="NACIONALIDAD"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                //patterns={/^.{8,30}$/}
                                />
                                <InputValidation
                                    estado={correo}
                                    type="email"
                                    cambiarEstado={setCorreo}
                                    label="CORREO"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                //patterns={/^.{8,30}$/}

                                />
                                <span style={{ textAlign: "center", color: "olive" }}>¿Desea seleccionar manualmente la ubicacion?</span>
                                {
                                    position &&
                                    <GeolocationGoogle position={position} setPosition={setPosition} />
                                }
                            </Grid>
                        </Grid>
                        {/* <GeolocationGoogle />  */}
                    </>
                }
            </Container>
            <Box display="flex" flexDirection="row-reverse" pt={4}>
                {
                    !loading ?
                        <Button
                            type="submit"
                            disableElevation
                            color="secondary"
                            variant="contained"
                            onClick={() => submitHandler()}
                            disabled={loading || !cedula2 || !celular.campo ||
                                !position.lat || !position.lng ||
                                !telefono.campo || !correo.campo || !geografico.sector
                                || celular.valido === false || !linea
                            }
                        > <strong>Enviar solicitud</strong>
                        </Button>
                        :
                        <LoadingButton
                            variant="contained"
                            loading
                        >
                            Enviar solicitud
                        </LoadingButton>
                }
            </Box>
        </>
    )
}