import {
  Container,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { db,  } from "../../firebase";
import { UpdateFlotasInactivas } from "./views/UpdateFlotasInactivas";



export const FlotasInactivas = () => {

  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
        name = 'ACTIVA'
        color = 'success'
    } else if (estado === 1) {
        name = 'INACTIVA'
        color = 'info'
    }
    return (
        <Chip label={name} color={color} />
    )
}

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [nombreZona, setNombreZona] = useState();

  const [codigoZona, setCodigoZona] = useState("");

  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("flotas").where("estado", "==", 1);
    //Si tiene algun doc anterior se agrega acontinuacion
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];

      setData(data);
      setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
      setIsLastPage(totalCount < 1);
    });
  };

  return (
    <>
    <br/>
      {data && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Estado</TableCell>
                <TableCell>Acciones</TableCell>
                <TableCell>Lider</TableCell>
                <TableCell>Auxiliar</TableCell>
                <TableCell>#Flota</TableCell>
                <TableCell>Localidad</TableCell>
                <TableCell>Proveedor</TableCell>
                <TableCell>#Contrato</TableCell>
                <TableCell>Placa</TableCell>
                <TableCell>Motor</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Año</TableCell>

                <TableCell>SEGURO</TableCell>
                <TableCell>INICIO VIGENCIA SEGURO</TableCell>
                <TableCell>FIN VIGENCIA SEGURO</TableCell>
                <TableCell>GPS</TableCell>
                <TableCell>INICIO VIGENCIA GPS</TableCell>
                <TableCell>FIN VIGENCIA GPS</TableCell>
               
                
                {/* <TableCell>Acciones</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, key) => (
                <TableRow key={key}>
                  {/* Estado */}
                  <TableCell component="th" scope="row">
                  <Estado estado={row.estado} />
                  </TableCell>

                  <TableCell component="th" scope="row">
                  <UpdateFlotasInactivas props={row} />
                  </TableCell>
                  
                  <TableCell component="th" scope="row">
                  {row.nombreLider}
                  </TableCell>
                  {/* cedula */}
                  <TableCell component="th" scope="row">
                  {row.nombreAuxiliar}
                  </TableCell>
                  {/* Numero de Flota */}
                  <TableCell component="th" scope="row">
                    {row.numeroFlota}
                  </TableCell>
                  {/* Localidad */}
                  <TableCell component="th" scope="row">
                  {row.localidad}
                  </TableCell>
                  {/* Nombres */}
                  <TableCell component="th" scope="row">
                  {row.proveedor}
                  </TableCell>
                  {/* Direccion */}
                  <TableCell component="th" scope="row">
                  {row.numContrato}
                  </TableCell>
                  {/* Coordenadas */}
                  <TableCell component="th" scope="row">
                  {row.placa}
                  </TableCell>
                  {/* Telefono */}
                  <TableCell component="th" scope="row">
                  {row.motor}
                  </TableCell>
                  {/* celular */}
                  <TableCell component="th" scope="row">
                  {row.modelo}
                  </TableCell>
                  {/* Whatsapp */}
                  <TableCell component="th" scope="row">
                  {row.marca}
                  </TableCell>
                  {/* numReferencia */}
                  <TableCell component="th" scope="row">
                  {row.anio}
                  </TableCell>
                  {/* planContratado */}


                  <TableCell component="th" scope="row">
                    {row.seguro === undefined ? '' : row.seguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.inicioVigenciaSeguro === undefined ? '' : row.inicioVigenciaSeguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.FinVigenciaSeguro === undefined ? '' : row.FinVigenciaSeguro}
                  </TableCell>

                  {/* GPS */}

                  <TableCell component="th" scope="row">
                    {row.gps === undefined ? '' : row.seguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.inicioVigenciaGps === undefined ? '' : row.inicioVigenciaGps}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.FinVigenciaGps === undefined ? '' : row.FinVigenciaGps}
                  </TableCell>
              
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

