import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../firebase";

export const DeletePlan = ({ props }) => {
    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const deleteProducto = async () => {
        await db.collection('perfiles').doc(props.id).delete().then(() => {

            Swal.fire({ icon: "success", text: "Se ha eliminado el plan con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>ESTA SEGURO DE ELIMINAR EL PLAN: {props.plan}</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        deleteProducto()
                        /* datosFlota() */
                    }} variant="contained"
                        color="error"
                    >
                        {" "}
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


// import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
// import React, { useEffect, useState } from "react";
// export const DeletePlan = ({ props }) => {
//     const [open, setOpen] = useState(null)
//     const onData = () => {
//         setOpen(true)
//     }
//     const deleteProducto = async () => {
//         // await db.collection('planes').doc(props.id).delete().then(() => {
//         //     Swal.fire({ icon: "success", text: "Se ha eliminado el plan con exito", });
//         // })
//         //     .catch((error) => { console.error("Error adding document: ", error); });
//         setOpen(false)
//     }
//     return (
//         <>
//             <Tooltip title="Eliminar">
//                 <Button onClick={() => onData()}>
//                     <DeleteIcon color="error" />
//                 </Button>
//             </Tooltip>
//             <Dialog open={open} fullWidth maxWidth="xs">
//                 <DialogTitle>
//                     ELIMINAR
//                 </DialogTitle>
//                 <DialogContent dividers>
//                     <Typography>ESTA SEGURO DE ELIMINAR EL PLAN:</Typography>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={() => setOpen(false)}>Cancelar</Button>
//                     <Button 
//                         onClick={() => deleteProducto()} 
//                         variant="contained"
//                         color="error"
//                     >
//                         ELIMINAR
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </>
//     );
// };