import React, { useEffect, useState, } from 'react'
import { useParams } from 'react-router-dom';
import {
    Grid, Box, Card, Button, Divider, Typography, Breadcrumbs, Alert, CardContent
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateFileUploadInstaladores } from '../../../components/utils/UpdateFileUploadInstaladores';
import Swal from 'sweetalert2'
import { firebase, storage, db } from '../../../firebase'
import Loader from '../../../components/utils/Loader'
import { detallesInstalacion } from '../../../redux/actions/instalacionesAction';
export const PhotoInstaladores = ({ items }) => {
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);
    const [file6, setFile6] = useState(null);
    const [file7, setFile7] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameClient, setNameClient] = useState('');
    const [name, setName] = useState('');

    const params = useParams()
    const dispatch = useDispatch()
    const instalacionDetalle = useSelector(state => state.instalacionDetalle)
    const { instalacion } = instalacionDetalle
    useEffect(() => {

        dispatch(detallesInstalacion(params.id))
        setFile1(instalacion.foto_potencia_casa)
        setFile2(instalacion.foto_potencia_caja)
        setFile3(instalacion.foto_router_casa)
        setFile4(instalacion.foto_etiqueta)

        setFile6(instalacion.foto_caja)
        console.log(instalacion.nombres, 'instalacion')
        console.log(params.id)
    }, [dispatch])


    const ActualizarFoto = async () => {

        // const agendaDB = await db.collection("tecnicos").where("uid", "==", params.id).get()
        // const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        // console.log('a', data1)
        // const data = data1[0]

        // console.log(data.id)
        // console.log('Hola 2')

        db.collection("tecnicos").doc(params.id).update({
            estado: 2,
            created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            setOpen(false)

            Swal.fire({ icon: "success", text: "Se ha guardado su instalacion con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });


        try {
            const agendaDB = await db.collection("tecnicos").where("numContrato", "==", params.id).get()
            agendaDB.docs.map(doc => {
                let data2 = doc.data()
                db.collection("agenda").doc(data2.numContrato).update({
                    created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp(),
                }).catch((error) => { console.error("Error adding document: ", error); });
                // if (data2.cambio_domicilio === undefined) {
                //     if (data2.tipo_venta === 'MIGRACION') {
                //         db.collection("agenda").doc(data2.numContrato).update({
                //             created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp(),
                //             fechaInstalacionMigracion: firebase.firestore.FieldValue.serverTimestamp(),
                //         }).catch((error) => { console.error("Error adding document: ", error); });
                //         db.collection("tecnicos").doc(data2.numContrato).update({
                //             fechaInstalacionMigracion: firebase.firestore.FieldValue.serverTimestamp(),
                //         }).catch((error) => { console.error("Error adding document: ", error); });

                //     } else {
                //         db.collection("agenda").doc(data2.numContrato).update({
                //             created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp(),
                //             fechaInstalacionVenta: firebase.firestore.FieldValue.serverTimestamp(),
                //         }).catch((error) => { console.error("Error adding document: ", error); });
                //         db.collection("tecnicos").doc(data2.numContrato).update({
                //             fechaInstalacionVenta: firebase.firestore.FieldValue.serverTimestamp(),
                //         }).catch((error) => { console.error("Error adding document: ", error); });
                //     }


                // } else {
                //     db.collection("agenda").doc(data2.numContrato).update({
                //         created_instalacion_foto: firebase.firestore.FieldValue.serverTimestamp(),
                //         fechaInstalacionCambio: firebase.firestore.FieldValue.serverTimestamp(),
                //     }).catch((error) => { console.error("Error adding document: ", error); });
                //     db.collection("tecnicos").doc(data2.numContrato).update({
                //         fechaInstalacionCambio: firebase.firestore.FieldValue.serverTimestamp(),
                //     }).catch((error) => { console.error("Error adding document: ", error); });
                // }
            })
        } catch (error) {
            console.log("error", error);
        }
    }


    const info = async () => {

        const agendaDB = await db.collection("tecnicos").where("uid", "==", params.id).get()
        const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log('a', data1)
        const data = data1[0]

        console.log(data.id)
        console.log(data.numContrato)

    }


    const verUid = (nombres) => (
        console.log(nombres)
    )

    return (
        <>
            <Box py={2} pl={2}>

            </Box>
            <Card variant='outlined'>
                <CardContent>

                    {/* <Alert severity="info">Recuerde, debe subir fotos claras al sistema de la instalacion</Alert> */}

                    <Grid container>
                        <Grid md={6}>
                            <Box pb={5}>
                                <Typography>Documento potencia casa</Typography>
                                <Divider />
                            </Box>
                            {instalacion && instalacion.foto_potencia_casa ?
                                <>
                                    <img width={100} src={instalacion.foto_potencia_casa} />
                                    <Alert>Archivo subido</Alert>
                                </>
                                :
                                <UpdateFileUploadInstaladores
                                    instalacion={instalacion}
                                    cambiarEstado={setFile1}
                                    estado={file1}
                                    nameFile="foto_potencia_casa"
                                />}




                            <Box py={5}>
                                <Typography>Documento potencia caja</Typography>
                                <Divider />
                            </Box>
                            {instalacion && instalacion.foto_potencia_caja ?
                                <>
                                    <img width={100} src={instalacion.foto_potencia_caja} />
                                    <Alert>Archivo subido</Alert>
                                </>
                                :
                                <UpdateFileUploadInstaladores
                                    instalacion={instalacion}
                                    cambiarEstado={setFile2}
                                    estado={file2}
                                    nameFile="foto_potencia_caja"
                                />}
                            <Box py={5}>
                                <Typography>Documento router casa</Typography>
                                <Divider />
                            </Box>
                            {instalacion && instalacion.foto_router_casa ?
                                <>
                                    <img width={100} src={instalacion.foto_router_casa} />
                                    <Alert>Archivo subido</Alert>
                                </>
                                :
                                <UpdateFileUploadInstaladores
                                    instalacion={instalacion}
                                    cambiarEstado={setFile3}
                                    estado={file3}
                                    nameFile="foto_router_casa"
                                />}
                        </Grid>
                        <Grid md={6}>
                            <Box pb={5}>
                                <Typography>Documento foto etiqueta</Typography>
                                <Divider />
                            </Box>
                            {
                                instalacion && instalacion.foto_etiqueta ?
                                    <>
                                        <img width={100} src={instalacion.foto_etiqueta} />
                                        <Alert>Archivo subido</Alert>
                                    </>
                                    :
                                    <UpdateFileUploadInstaladores
                                        instalacion={instalacion}
                                        cambiarEstado={setFile4}
                                        estado={file4}
                                        nameFile="foto_etiqueta"
                                    />}

                            <Box py={5}>
                                <Typography>Documento foto caja</Typography>
                                <Divider />
                            </Box>

                            {
                                instalacion && instalacion.foto_caja ?
                                    <>
                                        <img width={100} src={instalacion.foto_caja} />
                                        <Alert>Archivo subido</Alert>
                                    </>
                                    :
                                    <UpdateFileUploadInstaladores
                                        instalacion={instalacion}
                                        cambiarEstado={setFile6}
                                        estado={file6}
                                        nameFile="foto_caja"
                                    />
                            }

                            <Box py={5}>
                                <Typography>Documento foto premio</Typography>
                                <Divider />
                            </Box>

                            {
                                instalacion && instalacion.foto_premio ?
                                    <>
                                        <img width={100} src={instalacion.foto_premio} />
                                        <Alert>Archivo subido</Alert>
                                    </>
                                    :
                                    <UpdateFileUploadInstaladores
                                        instalacion={instalacion}
                                        cambiarEstado={setFile7}
                                        estado={file7}
                                        nameFile="foto_premio"
                                    />
                            }

                        </Grid>
                    </Grid>




                    <Box pt={2} display="flex" flexDirection="row-reverse">
                        {instalacion &&
                            <Button component={RouterLink} disabled={
                                !instalacion.foto_potencia_casa

                                || !instalacion.foto_potencia_caja
                                || !instalacion.foto_router_casa
                                || !instalacion.foto_etiqueta

                                || !instalacion.foto_caja} variant='contained'
                                disableElevation onClick={() => ActualizarFoto()}
                                to={`/Instaladores/GestionInstaladores/`}>
                                {" "}
                                SUBIR ARCHIVOS
                            </Button>}



                    </Box>
                </CardContent>
            </Card>
        </>
    )
}