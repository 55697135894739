import {
    Button, Box, Dialog, DialogContent, TableContainer, Table, TableHead,
    Paper, TableBody, TextField, IconButton,
    TableRow, TableCell, DialogActions, DialogTitle, Grid
} from "@mui/material";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css"
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Clear'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db, firebase } from "../../../../firebase";
import { useParams } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
export const RubroDeServicios = () => {
    const clientDetails = useSelector(state => state.clientDetails)
    const { client_details } = clientDetails
    //
    const configPlantillas = useSelector(state => state.configPlantillasList)
    const { config_plantillas } = configPlantillas
    //
    const clientServicio = useSelector(state => state.clientServicio)
    const { cliente_servicio } = clientServicio
    var current = new Date()
    const params = useParams()
    const [dataSaldos, setDataSaldos] = useState([])
    const [saldoPendiente, setSaldoPendiente] = useState(0)
    const [items, setItems] = useState([])
    const [open, setOpen] = useState(false)
    const [subtotal, setSubtotal] = useState(0)
    const [impuesto, setImpuesto] = useState(0)
    const [total, setTotal] = useState(0)
    const [vencimiento, setVencimiento] = useState(null)
    const [fechaEmision, setFechaEmision] = useState(Date(current.getTime() + 43200000))
    const [fechaVencimiento, setFechaVencimiento] = useState(null)
    const [numRubro, setNumRubro] = useState()
    const [servicie, setService] = useState('')
    useEffect(() => {
        getSaldoTotal()
        getLastItem()
        loadNumRubro()
    }, [])
    function detalleMate(data, index) {
        return (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    <TextField
                        name={"descripcion"}
                        fullWidth
                        onChange={(e) => handleChange(index, e)}
                        value={data.descripcion}
                        size='small'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={125}>
                    <TextField
                        name={"precio"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.precio}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    <TextField
                        name={"imp"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.impuesto}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    <TextField
                        name={"cant"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    ${parseFloat(data.precio).toFixed(2)}
                </TableCell>
                <TableCell align="center" width={5}>
                    {index !== 0 &&
                        <IconButton onClick={() => removeInputFields(index)}>
                            <CloseIcon />
                        </IconButton>}

                </TableCell>
            </TableRow>
        )
    }
    function sumar(row) {
        let iva = 0
        let subtotal = 0
        let total = 0
        for (let i = 0; i < row.length; i++) {
            const elemento = row[i]
            subtotal += parseFloat(elemento.subTotal)
            iva += parseFloat(elemento.iva)
            total += parseFloat(elemento.total)
        }
        let ref = db.collection('clientes').doc(params.id).collection('saldos').where('estado', '==', 0)
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setDataSaldos(data)
            console.log('dataSaldos', data);
            var datoSumaStockTotalBodegas;
            let sumaStock = 0;
            for (let i = 0; i < data.length; i++) {
                console.log(data[i]["monto"], 'MONTO')
                sumaStock += parseFloat(data[i]["monto"])
                datoSumaStockTotalBodegas = sumaStock
            }
            if (datoSumaStockTotalBodegas === undefined || datoSumaStockTotalBodegas === null) {
                console.log('SALDO PENDIENTE ES UNDEFINED')
                setTotal(subtotal)
            } else if (datoSumaStockTotalBodegas > 0) {
                console.log('SALDO PENDIENTE ES MAYOR')
                let total_menos_saldos = subtotal + datoSumaStockTotalBodegas
                setTotal(total_menos_saldos)
            } else {
                console.log('SALDO PENDIENTE ES MENOR')
                let total_menos_saldos = subtotal + datoSumaStockTotalBodegas
                setTotal(total_menos_saldos)
            }
            const subtotalOperacion = parseFloat(subtotal).toFixed(2) - parseFloat(iva).toFixed(2)
            setImpuesto(iva)
            setSubtotal(subtotalOperacion)
        })
    }
    const handleChange = (index, evnt) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target
                const list = [...items]
                list[index][name] = value
                var precio = list[index]['precio']
                var impuesto = list[index]['impuesto']
                var cantidad = list[index]['cantidad']
                var iva = precio * (impuesto / 112.2)
                var total = parseInt(precio) + iva

                list[index]['iva'] = iva * cantidad
                list[index]['subTotal'] = precio * cantidad
                list[index]['total'] = total * cantidad
                setItems(list)
                console.log(list, ' list2')
                sumar(list)
            } else {
                const list = [...items]
                list[index]['fullName'] = evnt
                setItems(list)
            }
        }
    }
    const removeInputFields = (index) => {
        const rows = [...items]
        rows.splice(index, 1)
        setItems(rows)
        sumar(rows)
    }
    const addInput = () => {
        setItems([...items, {
            descripcion: '',
            precio: 0,
            impuesto: 12,
            cantidad: 1,
            total: 0,
            iva: 0,
            subTotal: 0
        }])
    }
    const loadNumRubro = () => {
        const ref = db.collection('clientes').doc(params.id).collection('rubros').orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_rubro + 1;
                const uidSolicitud = `RUBRO-` + numeroSolicitud;
                setNumRubro(numeroSolicitud)
            }
        })
    }
    const getSaldoTotal = async () => {
        let ref = db.collection('clientes').doc(params.id).collection('saldos').where('estado', '==', 0)
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setDataSaldos(data)
            console.log('dataSaldos', data);
            var datoSumaStockTotalBodegas;
            let sumaStock = 0;
            for (let i = 0; i < data.length; i++) {
                console.log(data[i]["monto"], 'MONTO')
                sumaStock += parseFloat(data[i]["monto"])
                datoSumaStockTotalBodegas = sumaStock
            }
            setSaldoPendiente(datoSumaStockTotalBodegas)
            console.log('TOTAL MONTO', datoSumaStockTotalBodegas)
        })
    }
    const getLastItem = async () => {
        // const agendaDB4 = await db.collection('clientes').doc(params.id).collection('rubros').orderBy("created", "desc").limit(1).get();
        // const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        // //console.log('data4[0].id', data4[0].id)
        // let ref = db.collection('clientes').doc(params.id).collection('rubros').doc(data4[0].id).collection('items').orderBy("created", "desc").limit(1);
        // ref.onSnapshot(snapshot => {
        //     const data = [
        //         ...snapshot.docs.map(doc => {
        //             return {
        //                 ...doc.data(),
        //                 id: doc.id,
        //             }
        //         })
        //     ];
        //     setItems(data)
        //     sumar(data)
        // })
    }
    const crearFacturaLibre = () => {
        //RUBROS
        db.collection('rubros').orderBy('created', 'desc').limit(1).get()
            .then((rubrosDb) => {
                let id_rubro = 1
                if (!rubrosDb.empty) {
                    const ultimoDocumento = rubrosDb.docs[0]
                    id_rubro = ultimoDocumento.data().id_rubro
                    console.log(ultimoDocumento.data(), ' id rubro')
                }
                const nuevoID = id_rubro + 1;
                id_rubro = nuevoID;
                db.collection('rubros').doc(`${nuevoID}`).set({
                    idcliente: client_details.id,
                    tipo: 'SERVICIO',
                    estado: 0,
                    numero_rubro: 1,
                    id_rubro: id_rubro,
                 
                    /* fecha_emision: fechaEmision, */
                    fecha_emision: firebase.firestore.FieldValue.serverTimestamp(),
                    fecha_vencimiento: fechaVencimiento,
                    impuesto: parseFloat(impuesto).toFixed(2),
                    total: parseFloat(total).toFixed(2),
                    subTotal: parseFloat(subtotal).toFixed(2),
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    //nombre: data2[0].nombres,
                    //cedula: data2[0].cedula,
                    //direccion: data2[0].direccion,
                    //telefono: data2[0].telefono,
                }).then(async (querySnapshot) => {
                    items.forEach((obj) => {
                        db.collection(`rubros/${nuevoID}/items`).doc(obj.descripcion).set({
                            descripcion: obj.descripcion,
                            precio: obj.precio,
                            impuesto: obj.iva.toFixed(2),
                            cantidad: obj.cantidad,
                            total: obj.total,
                            subTotal: obj.subTotal,
                            iva: obj.impuesto,
                            created: firebase.firestore.FieldValue.serverTimestamp(),
                            //nombre: data2[0].nombres,
                            //cedula: data2[0].cedula,
                            //direccion: data2[0].direccion,
                            //telefono: data2[0].telefono,
                        })
                    })
                })

            })
        Swal.fire({ icon: "success", text: "Se ha creado el rubro de servicio con exito", });
        setOpen(false)
    }
    const onData = () => {
        setOpen(true)
        loadConfigPlantilla(client_details.configPlantillaId)
        const productos = []
        cliente_servicio.forEach(item => {
            productos.push({
                descripcion: item.perfil,
                precio: item.costo,
                impuesto: 12,
                cantidad: 1,
                total: 0,
                iva: 0,
                subTotal: 0
            })
        })
        setItems([...productos])
        console.log(items, ' items')
        sumar(items)
    }
    const loadConfigPlantilla = (e) => {
        const p = config_plantillas.filter((item) => item.id === e)
        console.log(p, 'configu plantilla')
        var date = new Date()
        const mes = date.getMonth()
        date.setMonth(mes + 1)
        date.setDate(p[0].aplicarCorte)
        setFechaVencimiento(date)
    }
    return (
        <>
            <Button
                size="small"
                color="secondary"
                variant='outlined'
                onClick={() => onData()}
            >Rubro De Servicios
            </Button>
            <Dialog
                maxWidth='md'
                open={open}
                fullWidth
            >
                <DialogTitle>NUEVO RUBRO SERVICIO
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Box py={2}>
                                        <DatePicker
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label="FECHA VENCIMIENTO"
                                            inputFormat="dd/MM/yyyy"
                                            value={fechaVencimiento}
                                            onChange={(newValue) => {
                                                setFechaVencimiento(newValue);
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Box py={2}>
                                        <DatePicker
                                            disabled
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label="FECHA EMISION"
                                            inputFormat="dd/MM/yyyy"
                                            value={fechaEmision}

                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Descripcion</TableCell>
                                    <TableCell align="center">Precio</TableCell>
                                    <TableCell align="center">Imp%</TableCell>
                                    <TableCell align="center">Cant.</TableCell>
                                    <TableCell align="center">Total</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map((data, index) => {
                                    return detalleMate(data, index)
                                })}
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={5}>
                                    </TableCell>
                                    <TableCell align="center" width={5}>
                                        <IconButton onClick={() => addInput()}>
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3}>
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        SUBTOTAL
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        ${subtotal.toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3}>
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        IMPUESTO
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        ${impuesto.toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3}>
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        SALDOS PENDIENTES
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        ${saldoPendiente === undefined || saldoPendiente === null ? 0 : saldoPendiente}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={3}>
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        TOTAL
                                    </TableCell>
                                    <TableCell align="center" width={100}>
                                        ${total.toFixed(2)}
                                    </TableCell>
                                    <TableCell colSpan={1}>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Box pr={1}>
                        <Button
                            disabled={fechaEmision === '' || fechaVencimiento === ''}
                            size="small"
                            color="secondary"
                            variant='contained'
                            onClick={() => crearFacturaLibre()}
                        >Crear rubro</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};