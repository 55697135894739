import { Typography, Box, Paper, Checkbox, Chip, Button, Stack, DialogTitle, Dialog, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { db, firebase } from "../../firebase";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import ButtonReingreso from "./ButtonReingreso";
function Estado(props) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad

  if (props.props.estado_pagado == 0) {
    name = "NO PAGADO";
    color = "info";
  } else if (props.props.estado_pagado === 2) {
    name = "PAGADO";
    color = "primary";
  }
  return <Chip size='small' label={name} color={color} />;
}
export const VentasReingreso = () => {
  const [open, setOpen] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [data, setData] = useState([]);
  let current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 1036800000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() - 318400000))
  useEffect(() => {
    LoadData();
  }, [startDate]);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("agenda").orderBy("fechaDeAgendamiento", "desc").where("estado", "==", 1)

    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("fechaDeAgendamiento", ">=", startfulldate);
      ref = ref.where("fechaDeAgendamiento", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {

        const data2 = [
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              estadoBorrado: data.filter((val) => val.numContrato
                === doc.data().numContrato).length > 0 ? data.filter((val) => val.numContrato
                  === doc.data().numContrato)[0].estadoBorrado : false,
            };
          }),
        ];
        setData(data2);
      });
    }

  }
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const onChange2 = () => {
    LoadData()
  }

  const columns = [
    {
      field: 'fechaDeAgendamiento', headerName: 'FECHA DE AGENDAMIENTO', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value &&
        moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'es2', headerName: 'ESTADO', width: 125,
      renderCell: (params) => {
        return <Estado props={params.row} />
      }
    },
    { field: 'id', headerName: 'CONTRATO', width: 200 },

    { field: 'cedula', headerName: 'CEDULA', width: 125 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
    { field: 'user_id_microwisp', headerName: 'ID MIKROWISP', width: 100 },
    { field: 'canton', headerName: 'CANTON', width: 100 },
    { field: 'direccion', headerName: 'DIRECCION', width: 400 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'caja', headerName: 'CAJA', width: 100 },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR	', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo ?
          params.row.centro_costo.codigo_vendedor : ''
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ?
          params.row.centro_costo.centro_costo : ''
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ?
          params.row.centro_costo.canal : ''
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ?
          params.row.centro_costo.lider_venta : ''
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ?
          params.row.centro_costo.categoria : ''
      }
    },
    {
      field: 'usuario_que_dio_factibilidad', headerName: 'USUARIO FACTIBILIDAD', width: 200
    },
    {
      field: 're', headerName: 'VENTA REFERIDA FLOTA', width: 200,
      renderCell: (params) => {
        return params.row.referido == 'SI' ?
          params.row.flotaReferida.numeroFlota : "NINGUNA"
      }
    },
    {
      field: 'acciones', headerName: '', width: 100,
      renderCell: (params) => {
        return <ButtonReingreso props={params.row} />
      }
    },
    {
      field: 'estadoBorrado', headerClassName: 'super-app-theme--header', headerName: '', width: 100,
      renderCell: (params) => {
        return <Checkbox
          checked={params.row.estadoBorrado || false}
          onClick={(event) => {
            handleCheck(event, params.row)
          }}
        >
        </Checkbox>
      }
    },
  ]

  function handleCheck(event, item) {
    let datos = []

    for (const el of data) {
      if (event.target.checked && el.id === item.id) {
        datos.push({
          ...el,
          estadoBorrado: true
        })
      } else if (!event.target.checked && el.id === item.id) {
        datos.push({
          ...el,
          estadoBorrado: false
        })
      } else {
        datos.push({
          ...el,
        })
      }
    }
    setData(datos)
  }


  async function eliminarMasivo() {
    setOpen2(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let lista = data.filter((val) => val.estadoBorrado)
    console.log(lista)
    for (const el of lista) {
      const data2 = {
        ...el,
        user_uid: el.user_uid,
        cedula: el.cedula,
        nombres: el.nombres,
        direccion: el.direccion,
        telefono: el.telefono,
        nacionalidad: el.nacionalidad,
        fechaNacimiento: el.fechaNacimiento,
        celular: el.celular,
        correo: el.correo,
        planNombre: el.planNombre,
        estado: 0,
      };
      let raw = JSON.stringify({
        data: {
          ...data2
        },
      });
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      await fetch(`https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiBodega/reingresoventa`,
        requestOptions)
        .then(response => response.text())
        .then(result => {
          let re2 = JSON.parse(result)
          console.log(re2)
        }).catch(error => console.log('error', error));
    }
    setOpen2(false)
  }

  const csvOptions = {
    delimiter: ';',
    fileName: `SOLICITUD APROBADAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  }
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          <strong>({data ? data.length : 0})</strong> VENTAS/RECHAZADAS
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Collapse>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row-reverse">
        <Stack spacing={2} direction='row'>
          <Button
            color='error'
            variant='contained'
            onClick={() => {
              let lista = []
              for (const el of data) {
                lista.push({
                  ...el,
                  estadoBorrado: true,
                })
              }
              setData(lista)
            }}
          >Seleccionar todo</Button>
          <Button
            color='warning'
            variant='contained'
            onClick={() => {
              let lista = []
              for (const el of data) {
                lista.push({
                  ...el,
                  estadoBorrado: false,
                })
              }
              setData(lista)
            }}
          >Limpiar todo</Button>
        </Stack>

      </Box>
      <Box display="flex" flexDirection="row-reverse" pt={1}>
        <Button
          color='info'
          variant='contained'
          onClick={() => eliminarMasivo()}
        >Eliminar Clientes</Button>
      </Box>


      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ?? []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>
      <Dialog open={open2} >
        <DialogTitle>
          <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress align="center" />
            <strong><p align="center" style={{ fontSize: '14px' }}>
              ELMINANDO...</p></strong>
          </Stack>
        </DialogTitle>
      </Dialog>
    </>
  );
};
