import { Button, Typography, Card, CardContent } from '@mui/material'
import React, { useState, useEffect } from 'react'

export const ConvertRoles = ({id}) => {

  return (
    <>
        {id === 0 && 'ADMIN'}
        {id === 1 && 'COMERCIAL'}
        {id === 2 && 'OPERACIONES'}
        {id === 3 && 'PLANEACION'}
        {id === 4 && 'FLOTAS'}
        {id === 5 && 'REPORTES'}
        {id === 6 && 'RECURSOS HUMANOS'}

    </>
  )
}