import React, { useState, useEffect } from 'react'
import {  Button, Stack } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { db } from '../../../firebase'
import { detailsMikrotik } from '../../../redux/clientes/clientesActions';
export const HerramientasPage = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { loading, client_details } = clientDetails
    //
    const mikrotiksDetails = useSelector((state) => state.mikrotiksDetails)
    const { mikrotik_details } = mikrotiksDetails
    //
    const [loading2, setLoading2] = useState(false)
    const [servicios, setServicios] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const LoadData = () => {
        db.collection(`servicios`).where('idcliente', '==', client_details.idcliente).orderBy("created", "desc")
            .onSnapshot((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                ]
                setServicios(data[0])
                dispatch(detailsMikrotik(data[0].nodocrr))
            })
    }
    const reactivaCliente = () => {
        setLoading2(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        console.log({
            "host": mikrotik_details.ip,
            "username": mikrotik_details.username,
            "password": mikrotik_details.pass,
            "port": 22,
            "description_cliente": servicios.pppuser
        })
        var raw = JSON.stringify({
            "host": mikrotik_details.ip,
            "username": mikrotik_details.username,
            "password": mikrotik_details.pass,
            "port": 22,
            "description_cliente": servicios.pppuser
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://serverapiolt.intercommerce.com.ec/apimk/reactivaCliente", requestOptions)
            .then(response => response.text())
            .then(result => {
                const message = JSON.parse(result)
                console.log(`reactiva`)
                console.log(message)
                if(message.status == '200'){
                    db.collection('clientes').doc(`${client_details.id}`).update({
                        estado: 1
                    })
                }
                setLoading2(false)
            })
            .catch(error => {
                setLoading2(false)
                console.log('error', error)
            });
    }
    const corteCliente = () => {
        setLoading2(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "host": mikrotik_details.ip,
            "username": mikrotik_details.username,
            "password": mikrotik_details.pass,
            "port": 22,
            "ip_cliente": servicios.ipv4,
            "description_cliente": servicios.pppuser
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://serverapiolt.intercommerce.com.ec/apimk/corteCliente", requestOptions)
            .then(response => response.text())
            .then(result => {
                const message = JSON.parse(result)
                console.log(`activa`)
                console.log(message)
                if(message.status == 200){
                    db.collection('clientes').doc(`${client_details.id}`).update({
                        estado: 0
                    })
                }
                setLoading2(false)
            })
            .catch(error => {
                setLoading2(false)
                console.log('error', error)
            });
    }
    return (
        <Stack direction="row" spacing={2}>
            {client_details.estado === 1 ?
                <Button color='warning' variant='contained' onClick={() => corteCliente()} disabled={loading2}>SUSPENDER</Button> :
                <Button color='success' variant='contained' onClick={() => reactivaCliente()} disabled={loading2}>ACTIVAR CLIENTE</Button>
            }
            <Button color='error' variant='contained' onClick={() => corteCliente()}>RETIRAR</Button>
        </Stack>
    )
}