import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ListOperaciones = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [open, setOpen] = useState(false)
    return (

        <>
            {userAuth?.roles?.find(role => ['admin', 'operaciones']?.includes(role))

                ? <>
                    <ListItemButton onClick={() => setOpen(!open)}>
                        <ListItemIcon>
                            <EngineeringIcon />
                        </ListItemIcon>
                        <ListItemText primary="Operaciones" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding dense>

                            <ListItemButton
                                component={RouterLink} to={`/operaciones/fiscalizacion/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Fiscalizacion" />
                            </ListItemButton>

                            <ListItemButton
                                component={RouterLink} to={`/operaciones/factibilidad/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Factibilidad" />
                            </ListItemButton>

                            <ListItemButton
                                component={RouterLink} to={`/operaciones/tickets/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Tickets" />
                            </ListItemButton>

                            <ListItemButton
                                component={RouterLink} to={`/operaciones/Postes/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Postes" />
                            </ListItemButton>

                            <ListItemButton

                                component={RouterLink} to={`/operaciones/proyectos/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Proyectos" />
                            </ListItemButton>

                            <ListItemButton

                                component={RouterLink} to={`/operaciones/cajas/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Cajas" />
                            </ListItemButton>

                            <ListItemButton

                                component={RouterLink} to={`/operaciones/cajas-no-usadas/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Cajas no usadas" />
                            </ListItemButton>

                            <ListItemButton component={RouterLink} to={`/operaciones/nodos/`}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Nodos" />
                            </ListItemButton>

                            <ListItemButton component={RouterLink} to={`/operaciones/visita-de-nodos/`}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Visita de Nodos" />
                            </ListItemButton>

                            <ListItemButton component={RouterLink} to={`/operaciones/pruebas/`}>
                                <ListItemIcon></ListItemIcon>
                                <ListItemText primary="Pruebas" />
                            </ListItemButton>

                        </List>
                    </Collapse>
                </> : ''}
        </>
    )
}