import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import { Box, Button, IconButton, Card, CardContent, Grid, LinearProgress, MenuItem, Paper, Select, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { db } from '../../../../firebase';
import Swal from 'sweetalert2';


export const AdRegion = () => {

    const [estado, setEstado] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [codigo, setCodigo] = useState('')
    const [categoria, setCategoria] = useState('')

    const [id, setId] = useState('')

    const [categorias, setCategorias] = useState([])
    const [subCategorias, setSubCategorias] = useState(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        cargarSubCategorias()
        cargarCategorias()
    }, [])

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )

    const columns = [
        { field: 'codigo', headerClassName: 'super-app-theme--header', headerName: 'Código', width: 100 },

        { field: 'pais', headerClassName: 'super-app-theme--header', headerName: 'País', width: 150 },
        { field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Region', width: 200 },
        { field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 100 },
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'Accion', width: 100,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}
                >
                    <EditIcon />
                </IconButton>
            }
        },
    ];

    function cargarCategorias() {
        db.collection(`cuentas_pagar/parametros/pais`)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setCategorias(arrayMessage)
            })
    }

    function cargarSubCategorias() {
        db.collection(`cuentas_pagar/parametros/region`)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setSubCategorias(arrayMessage)
            })
    }

    function crearSubCategoria() {
        db.collection("cuentas_pagar/parametros/region").doc().set({
            pais: categoria,
            codigo: codigo,
            descripcion: descripcion,
            estado: estado,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        db.collection("cuentas_pagar/parametros/region").doc(id).update({
            pais: categoria,
            codigo: codigo,
            descripcion: descripcion,
            estado: estado,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha actualizado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function limpiar() {
        setId('')
        setCategoria('')
        setCodigo('')
        setDescripcion('')
        setEstado('')
    }

    function handleOpen(item) {
        setId(item.id)
        setDescripcion(item.descripcion)
        setCategoria(item.pais)
        setCodigo(item.codigo)
        setEstado(item.estado)
        setOpen(true)
    }


    return (
        <>
            <Typography variant="h5"><strong>Región</strong> </Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={12} lg={4} xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Box py={1}>
                                    <Typography sx={{ fontSize: '14px' }}><strong>País:  *</strong></Typography>
                                    <Select
                                        fullWidth
                                        size="small"
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                            fontSize: '14px'
                                        }}
                                        value={!open ? categoria : ''}
                                        onChange={(e) => setCategoria(e.target.value)}
                                    >
                                        {categorias ? categorias.map((item, index) => (
                                            <MenuItem key={index} value={item.descripcion}>{item.descripcion}</MenuItem>
                                        )) : <></>}

                                    </Select>
                                </Box>
                                <Box py={1}>
                                    <Typography sx={{ fontSize: "14px" }}><strong>Código:  *</strong></Typography>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={!open ? codigo : ''}
                                        onChange={(e) => setCodigo(e.target.value)}
                                        size="small"
                                        fullWidth />
                                </Box>
                                <Box py={1}>
                                    <Typography sx={{ fontSize: "14px" }}><strong>Descripción:  *</strong></Typography>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={!open ? descripcion : ''}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        size="small"
                                        fullWidth />
                                </Box>
                                <Box py={1}>
                                    <Typography sx={{ fontSize: '14px' }}><strong>Estado:  *</strong></Typography>
                                    <Select
                                        fullWidth
                                        size="small"
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                            fontSize: '14px'
                                        }}
                                        value={!open ? estado : ''}
                                        onChange={(e) => setEstado(e.target.value)}
                                    >
                                        <MenuItem key={0} value="ACTIVO">ACTIVO</MenuItem>
                                        <MenuItem key={1} value="INACTIVO">INACTIVO</MenuItem>

                                    </Select>
                                </Box>

                                <Box py={1}>
                                    <Button
                                        disabled={
                                            codigo == '' ||
                                            categoria === '' ||
                                            estado === '' ||
                                            descripcion === ''}
                                        onClick={() => crearSubCategoria()}
                                        fullWidth variant="contained" color='success'>
                                        CREAR
                                    </Button>
                                </Box>

                            </Paper>
                        </Grid>
                        <Grid item md={12} lg={8} xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Box sx={{
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={subCategorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={subCategorias ? subCategorias : []}

                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>EDITAR: {descripcion}</strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item md={6} lg={6} xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Código:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={codigo}
                                            disabled={true}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item md={6} lg={6} xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Descripción:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={descripcion}
                                            disabled={true}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                </Grid>

                                <Grid item md={6} lg={6} xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: '14px' }}><strong>País:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={categoria}
                                            disabled={true}
                                        >
                                            {categorias ? categorias.map((item, index) => (
                                                <MenuItem key={index} value={item.descripcion}>{item.descripcion}</MenuItem>
                                            )) : <></>}

                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item md={6} lg={6} xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: '14px' }}><strong>Estado:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={estado}
                                            onChange={(e) => setEstado(e.target.value)}
                                        >
                                            <MenuItem key={0} value="ACTIVO">ACTIVO</MenuItem>
                                            <MenuItem key={1} value="INACTIVO">INACTIVO</MenuItem>

                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                codigo == '' ||
                                                categoria === '' ||
                                                estado === '' ||
                                                descripcion === ''}
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            ACTUALIZAR
                                        </Button>
                                    </Box>
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>
        </>
    )
}
