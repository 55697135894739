import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Grid,
    Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase";
import { listsSubCategorias } from "../../../../redux/actions/parametersActions";

export const UpdateTurno = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [nombreCategoriaProducto, setNombreNombreCategoriaProducto] = useState(props.turno);

    const updateProducto = async () => {
        await db.collection('tickets_parametros').doc('turnos').collection('list').doc(props.id).update({
            turno: nombreCategoriaProducto,
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado el turno con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Actualizar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="sm">
                <DialogTitle>EDICION DE TURNO: <strong> {props.turno}</strong></DialogTitle>
                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <FormControl fullWidth>
                                    <Grid item xs={6}>
                                        <Box py={1}>
                                            <TextField
                                                fullWidth
                                                label="TURNO"
                                                value={nombreCategoriaProducto}
                                                onChange={(e) => setNombreNombreCategoriaProducto(e.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined" size="small" color="primary"><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"
                        size="small"
                        color="secondary"
                    >
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};