import React, { useEffect, useState } from "react"
import { Scheduler, useScheduler } from "@aldabil/react-scheduler"
import { Container, Box, Typography, Button } from "@mui/material"
import { useDispatch, useSelector } from 'react-redux'
import { listFlotas } from "../../../redux/actions/flotas/flotasActions"
import { db } from '../../../firebase'
const EVENTS = []
export const PlanificadorLogisticos = () => {
    const { triggerDialog, setView } = useScheduler()
    const [flotas, setFlotas] = useState([])
    const [fields, setFields] = useState([
        {
            name: "user_id",
            type: "select",
            // Should provide options with type:"select"
            options: [
                { id: 1, text: "D2", value: 1 },
                { id: 2, text: "F2", value: 2 }
            ],
            config: { label: "UNIDAD FLOTA", required: true }
        },
        {
            name: "zona_id",
            type: "select",
            // Should provide options with type:"select"
            options: [
                { id: 1, text: "SUR", value: 1 },
                { id: 2, text: "NORTE", value: 2 }
            ],
            config: { label: "ZONA", required: true }
        },
    ])
    useEffect(() => {
        onLoad()
    }, [])
    const onLoad = () => {
        db.collection(`flotas`).where('estado', '==', 0).orderBy("numeroFlota")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => {
                    return {
                        id:  item.data().numeroFlota,
                        text: item.data().numeroFlota,
                        value: item.data().numeroFlota
                    }
                })
                setFlotas(arrayMessage)
                console.log(arrayMessage, ' array2')
            })
    }
    return <>
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Box pl={2} pb={2} pt={2}>
                <Typography variant="h5" component="h1">Operaciones/<strong>Planificador Logisticos</strong></Typography>
            </Box>
            <Scheduler
                fields={fields}
                events={EVENTS} />
        </Container>
    </>
}
