import React, { useState, useEffect } from 'react'
import { Tabs, Container, Box, Tab, Card, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { CrearUsuario } from './CrearUsuario';
import { UsuariosListRH } from './UsuariosListRH'
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
export const RecursosHumanosPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id))
    }
  }, [params.id])
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1"><strong>Administracion de Usuarios del Sistema</strong></Typography>
        </Box>
        <Card>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} >
            <Tab label="Creacion de Usuario" />
            <Tab label="Listado de Usuarios" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <CrearUsuario />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <UsuariosListRH />
          </TabPanel>
        </Card>
      </Container>
    </>
  )
}