import { Button, Card, CardContent, Divider, Grid, IconButton, Typography, Box, Hidden } from '@mui/material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import React, { useEffect, useState } from 'react'
import { db, firebase } from '../../../firebase'
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { ListUsersOnline } from './ListUsersOnline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
export const Dashboard = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    //

    const [instaladas, setInstaladas] = useState('')
    const [ventasRechazadas, setVentasRechazadas] = useState('')
    const [instalacionesRechazadas, setInstalacionesRechazadas] = useState('')
    const [cambioDomicilio, setCambioDomicilio] = useState('')
    //
    useEffect(() => {
        venta_instaladas()
        ventas_rechazadas()
        instalaciones_rechazadas()
        //cambio_domicilio()
    }, [])
    const venta_instaladas = () => {
        //Ventas instaladas
        let ref = db.collection("tecnicos").where("estado", "==", 2);
        if (userInfo?.roles?.find(role => ['tecnico']?.includes(role)))
            ref = ref.where('correo_flota', '==', userInfo.email)

        if (userInfo?.roles?.find(role => ['ventas']?.includes(role)))
            ref = ref.where("user_uid", "==", userInfo.uid);
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
           
                setInstaladas(totalCount)
            });
        }
        //Ventas Rechazadas
    }
    const ventas_rechazadas = () => {
        //Ventas instaladas
        let ref = db.collection("pre_ventas").where("estado", "==", 1);
        if (userInfo?.roles?.find(role => ['tecnico']?.includes(role)))
            ref = ref.where('correo_flota', '==', userInfo.email)

        if (userInfo?.roles?.find(role => ['ventas']?.includes(role)))
            ref = ref.where("user_uid", "==", userInfo.uid);
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
            
                setVentasRechazadas(totalCount)
            });
        }
    }
    const instalaciones_rechazadas = () => {
        //Ventas instaladas
        let ref = db.collection("tecnicos").where("estado", "==", 1);

        if (userInfo?.roles?.find(role => ['tecnico']?.includes(role)))
            ref = ref.where('correo_flota', '==', userInfo.email)

        if (userInfo?.roles?.find(role => ['ventas']?.includes(role)))
            ref = ref.where("user_uid", "==", userInfo.uid);
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
          
                setInstalacionesRechazadas(totalCount)
            });
        }
        //Ventas Rechazadas
    }
    const cambio_domicilio = () => {
        //Ventas instaladas
        let ref = db.collection("tecnicos").where("estado", "==", 1).where("cambio_domicilio", "==", true)
        if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
            ref = ref.where("user_uid", "==", userInfo.uid);
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
              
                setCambioDomicilio(totalCount)
            });
        }
        //Ventas Rechazadas
    }
    const data2 = [
        { name: 'VENTAS_INSTALADAS', value: instaladas },
        { name: 'VENTAS RECHAZADAS', value: ventasRechazadas },
        { name: 'INSTALACIONES REALIZADAS', value: instaladas },
        { name: 'INSTALACIONES RECHAZADAS', value: instalacionesRechazadas },
        //{ name: 'CAMBIO DOMICILIO', value: cambioDomicilio },
    ];
    const data3 = [
        { name: 'VENTAS_INSTALADAS', VENTAS_INSTALADAS: instaladas },
        { name: 'VENTAS RECHAZADAS', VENTAS_RECHAZADAS: ventasRechazadas },
        { name: 'INSTALACIONES REALIZADAS', INSTALACIONES_REALIZADA: instaladas },
        { name: 'INSTALACIONES RECHAZADAS', INSTALACIONES_RECHAZADAS: instalacionesRechazadas },
        //{ name: 'CAMBIO DOMICILIO', CAMBIO_DOMICILIO: cambioDomicilio },
    ];
    function addThousandSeparator(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
           <Grid container spacing={3}>
                <Grid item md={6} lg={3} xs={12} >
                    <Card >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={3}>
                                    <AttachMoneyIcon color='disabled' fontSize='large' />
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant='body2' align='right' color="text.secondary" gutterBottom>
                                        VENTAS INSTALADAS
                                    </Typography>
                                    <Typography align='right' variant='h3' color="text.secondary" gutterBottom>
                                        <strong>{addThousandSeparator(instaladas)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} lg={3} xs={12}>
                    <Card >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <BackspaceIcon color='disabled' fontSize='large' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' align='right' color="text.secondary" gutterBottom>
                                        VENTAS RECHAZADAS
                                    </Typography>
                                    <Typography color="text.secondary" align='right' variant='h3' gutterBottom>
                                        <strong>{addThousandSeparator(ventasRechazadas)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} lg={3} xs={12}>
                    <Card >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <LocalShippingIcon color='disabled' fontSize='large' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant='body2' align='right' color="text.secondary" gutterBottom>
                                        INSTALACIONES REALIZADAS
                                    </Typography>
                                    <Typography color="text.secondary" align='right' variant='h3'  gutterBottom>
                                        <strong>{addThousandSeparator(instaladas)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={6} lg={3} xs={12}>
                    <Card >
                        <CardContent>
                            <Grid container>
                                <Grid item xs={2}>
                                    <MapsHomeWorkIcon color='disabled' fontSize='large' />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant='body2' align='right' color="text.secondary" gutterBottom>
                                        INSTALACIONES RECHAZADAS
                                    </Typography>
                                    <Typography color="text.secondary" align='right' variant='h3'  gutterBottom>
                                        <strong>{addThousandSeparator(instalacionesRechazadas)}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box py={5}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        {/* <Card >
                            <CardContent>
                                <PieChart width={300} height={350}>
                                    <Pie
                                        data={data2}
                                        labelLine={false}
                                        label
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data2.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Legend />
                                    <Tooltip />
                                </PieChart>
                            </CardContent>
                        </Card> */}
                    </Grid>
                    <Grid item md={5}>
                    <Hidden lgDown>
                        {/* <Card>
                            <CardContent>
                            
                                    <BarChart
                                        width={600}
                                        height={350}
                                        data={data3}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <YAxis />
                                        <XAxis dataKey="acctstarttime" />
                                        <Legend />
                                        <Bar name="VENTAS INSTALADAS" dataKey="VENTAS_INSTALADAS" fill="#0088FE" />
                                        <Bar name="VENTAS RECHAZADAS" dataKey="VENTAS_RECHAZADAS" fill="#00C49F" />
                                        <Bar name="INSTALACIONES REALIZADAS" dataKey="INSTALACIONES_REALIZADA" fill="#FFBB28" />
                                        <Bar name="INSTALACIONES RECHAZADAS" dataKey="INSTALACIONES_RECHAZADAS" fill="#8884d8" />
                                        <Bar name="CAMBIO DOMICILIO" dataKey="CAMBIO_DOMICILIO" fill="#FF8042" />
                                    </BarChart>
                         
                            </CardContent>
                        </Card> */}
                        </Hidden>
                    </Grid>
                    <Grid item md={4}>
                 
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}
