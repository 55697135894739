import {
    Box,
    CardHeader,
    Paper,
    Chip,
    Card,
} from "@mui/material";
import {
    DataGrid,
    esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import VerItems from "./VerItems";
export const SolicitudRequeRevision = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo);
    const [data, setData] = useState([]);

    useEffect(() => {
        LoadData()
    }, [])
    //
    const LoadData = () => {
        let ref = db.collection("requerimientos_sistema").orderBy("created", "desc").where("estado", "==", 2);
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setData(data);
        });
    }

    
    const columns = [
        {
            field: 'created', headerName: 'FECHA EMISION', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds
                 * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        {
            field: 'estado', headerName: 'ESTADO', width: 120,
            renderCell: (params) => {
                return <Chip size="small" label="EN REVISION" color="warning" />
            }
        },
        { field: 'id', headerName: 'NO. DE PEDIDO', width: 200 },

        { field: 'requerimiento', headerName: 'REQUERIMIENTO', width: 300 },
        {
            field: '', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <VerItems props={params.row} />
                </>
            }
        },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 400 },
        { field: 'observacion', headerName: 'OBSERVACION', width: 400 },
        { field: 'asignado', headerName: 'ASIGNADO', width: 200 },
        { field: 'prioridad', headerName: 'PRIORIDAD', width: 200 },
        {
            field: 'fechaMaxima', headerName: 'FECHA MAXIMA', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && 
            moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 200 },
        { field: 'user', headerName: 'SOLICITANTE', width: 200 },
    ];
    const columns2 = [
        {
            field: 'created', headerName: 'FECHA EMISION', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && 
            moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        {
            field: 'estado', headerName: 'ESTADO', width: 120,
            renderCell: (params) => {
                return <Chip size="small" label="EN REVISION" color="warning" />
            }
        },
        { field: 'id', headerName: 'NO. DE PEDIDO', width: 200 },

        { field: 'requerimiento', headerName: 'REQUERIMIENTO', width: 300 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 400 },
        { field: 'observacion', headerName: 'OBSERVACION', width: 400 },
        { field: 'asignado', headerName: 'ASIGNADO', width: 200 },
        { field: 'prioridad', headerName: 'PRIORIDAD', width: 200 },
        {
            field: 'fechaMaxima', headerName: 'FECHA MAXIMA', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && 
            moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 200 },
        { field: 'user', headerName: 'SOLICITANTE', width: 200 },
    ];
    return (
        <>
            <Card>
                <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }}
                subheader={<strong> REQUERIMIENTOS SISTEMAS/SOLICITUDES EN REVISION</strong>} />
                <Paper>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={data ? data.filter((val) => {
                                if(userAuth.displayName === val.user 
                                    || userAuth?.roles?.find(role => ['sistemas', 'admin']?.includes(role))){
                                    return val
                                }
                            }) : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={userAuth?.roles?.find(role => ['sistemas', 'admin']?.includes(role)) &&
                             userAuth?.roles?.find(role => ['jefe', 'admin']?.includes(role)) ? columns : columns2}
                            hideFooterSelectedRowCount={true}
                        />
                    </Box>
                </Paper>
            </Card>
        </>
    );
};
