import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, storage, firebase, auth } from "../../firebase";
import { listsSubCategorias } from "../../redux/actions/parametersActions";
import ImageIcon from '@mui/icons-material/Image';
export const ViewPhotosInstalaciones = ({ props }) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [file, setFile] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const [Imagen, setImagen] = useState(null);

    useEffect(() => {
        setFile(Imagen)
    }, [])

    const [image, setImage] = useState(null)

    const [viewImagen, setViewImagen] = useState(null)

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagen(URL.createObjectURL(event.target.files[0]));
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())
    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }
    const [observacionFizcalizacion, setObservacionFizcalizacion] = useState(props.observaciones_fiscalizacion);
    var imagenUrl;
    return (
        <>
            <Tooltip title="VER FOTOS">
                <Button onClick={() => onData()}>
                    <ImageIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>VER IMAGENES DE: {props.id}</strong>
                </DialogTitle>
                <DialogContent dividers>
                    <Box py={2}>
                        <FormControl fullWidth>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO CAJA</strong></p>
                                    <a href={props.foto_caja} target="_blank"><img src={props.foto_caja} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO ETIQUETA</strong></p>
                                    <a href={props.foto_etiqueta} target="_blank"><img src={props.foto_etiqueta} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO POTENCIA CAJA</strong></p>
                                    <a href={props.foto_potencia_caja} target="_blank"> <img src={props.foto_potencia_caja} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO POTENCIA CASA</strong></p>
                                    <a href={props.foto_potencia_casa} target="_blank">  <img src={props.foto_potencia_casa} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO RECIBO</strong></p>
                                    <a href={props.foto_recibo} target="_blank"> <img src={props.foto_recibo} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>FOTO ROUTER CASA</strong></p>
                                    <a href={props.foto_router_casa} target="_blank">  <img src={props.foto_router_casa} width="50%" /></a>
                                </Grid>
                                <Grid item xs={6}>
                                    <p ><strong>TEST VELOCIDAD</strong></p>
                                    <a href={props.foto_test_velocidad} target="_blank">  <img src={props.foto_test_velocidad} width="50%" /></a>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};