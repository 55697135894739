import { Tabs, Typography, Box, Tab, Paper } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { SolicitudesMaterialesInstalacionEspera } from './SolicitudesMaterialesInstalacionEspera';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const SolicitudesDeInstalacionesPage = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box >
                <Typography variant='h5' component='h1'><strong>SOLICITUDES DE MATERIALES</strong></Typography>
            </Box>
            <Box py={1} px={1}>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="En espera" {...a11yProps(0)} />
                            <Tab label="Aprobadas" {...a11yProps(1)} />
                            <Tab label="Rechazadas" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                </Paper>
            </Box>
            <TabPanel value={value} index={0}>
                <SolicitudesMaterialesInstalacionEspera />
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* <SolicitudAprobadas /> */}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/* <SolicitudRechazado /> */}
            </TabPanel>
        </>
    )
}