import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
export const ListAdministracion = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [options, setOptions] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'administracion']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOptions(!options)}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Administracion" />
                    {options ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={options} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {/* Productos */}
                        <ListItemButton onClick={() => setOpen8(!open8)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Productos" />
                            {open8 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open8} timeout="auto" unmountOnExit >
                            <List component="div" disablePadding dense>
                                {['Inventario'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/productos/inventario/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding dense>
                                {['Activo fijo'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/productos/activo-fijo/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding dense>
                                {['Contabilidad'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/productos/contabilidad/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding dense>
                                {['Nuevo producto'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/productos/nuevo-producto/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton component={RouterLink} to={`administracion/gestion-empresas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Empresas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`administracion/usuarios/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`administracion/datos-empresa/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Datos Empresa" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`administracion/parametros-productos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros Productos" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`administracion/parametros-contables/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros Contables" />
                        </ListItemButton>

                        <ListItemButton onClick={() => setOpen7(!open7)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Opciones parametros contables" />
                            {open7 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open7} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Tipo de Cuentas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/parametros-contables/tipo-de-cuentas/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding>
                                {['Tipo Nombre de Cuentas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/parametros-contables/tipo-nombre-de-cuentas/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding>
                                {['Naturaleza de Cuentas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/parametros-contables/naturaleza-de-cuentas/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                            <List component="div" disablePadding>
                                {['Item de Estado Financiero'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/administracion/parametros-contables/item-de-estado-financierp/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>

                        <ListItemButton component={RouterLink} to={`administracion/parametros-facturacion/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros Facturacion" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`administracion/parametros-facturacion-electronica/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros Facturacion Electronica" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`administracion/parametros-smtp/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros SMTP" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`administracion/administrador-sucursales/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Administrador Sucursales" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`administracion/integraciones/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Integraciones" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`administracion/actividades-sistema/`}>
                            <ListItemIcon>
                                <SettingsEthernetIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Actividades Sistema" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`administracion/plantillas_configuracion/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Plantillas Configuracion" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}