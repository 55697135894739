import {
    Box,
    TableCell,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableContainer,
    Card,
    CardContent,
    Autocomplete,
    Typography,
    Button,
    TableHead,
    Paper,
    Chip,
    IconButton,
    Collapse,
    Hidden,
    Stack,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { UpdateInformation } from "./UpdateInformation";
import { DeleteInformation } from "./DeleteInformation";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export const VerMasCentrosDeCostos = ({ props }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        LoadData()
    }, [dispatch])

    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [item, setItem] = useState();
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    const LoadData = async () => {

        const agendaDB2 = await db.collection("centros_de_costos").where("centro_de_costo", "==", props.centro_de_costo).get()

        const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        console.log(`ID ${data2[0].id}`)

        let ref = db.collection("centros_de_costos").doc(data2[0].id).collection('items')

        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
        })
    };

    const columns = [
        { field: 'centro_de_costo', headerName: 'TODOS LOS CENTROS DE COSTOS', width: 740 },
        /* { field: 'estado', headerName: 'ESTADO', width: 120 }, */
        {
            field: 'estado', headerName: 'ESTADO', width: 120,
            renderCell: (params) => {
                return params.row.estado === 'ACTIVO' ? <Chip label="ACTIVO" size="small" color="success" /> : <Chip label="INACTIVO" size="small" color="error" />
            }
        },
    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {

                    CODIGO_PRODUCTO: doc.codigo_producto,

                    LOCALIDAD: doc.bodega,

                    DESCRIPCION: doc.descripcion,

                    SUBTIPO: doc.categoria,

                    UNIDAD_MEDIDA: doc.unidad_medida,

                    STOCK: doc.stock_actual,

                    COSTO_PROMEDIO: doc.costo_promedio,

                    TOTAL: doc.total,

                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "reporte_stock");
        XLSX.writeFile(wb, `REPORTE_STOCK_${date1}.xlsx`)
    }

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total: {total}</Box>
        </div>
    }

    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>

            <Tooltip title="Ver mas">
                <Button onClick={() => setOpenDialog(true)}>
                    <MoreHorizIcon color="info" />
                </Button>
            </Tooltip>

            <Dialog open={openDialog} fullWidth onClose={() => setOpenDialog(false)} maxWidth="lg">

                <DialogTitle><strong>CENTRO DE COSTOS PERTENECIENTES A: {props.centro_de_costo}</strong></DialogTitle>

                <DialogContent>
                    <Paper>
                        <Box sx={{ height: 690, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,
                                }}

                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>
                </DialogContent>
            </Dialog>

        </>
    );
};