import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";

export const NewUbication = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [nombreUbicacion, setNombreUbicacion] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createNewUbication = async (NombreUbicacion) => {
        await db.collection('ubicaciones').doc().set({
            nombre_ubicacion: NombreUbicacion,
            fecha_creacion_ubicacion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva Ubicacion creada con exito" });
        })
        .catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={nombreUbicacion}
                cambiarEstado={setNombreUbicacion}
                label="NOMBRE UBICACION"
                name="nombreUbicacion"
            />

            <br />
            <Button
                onClick={() => createNewUbication(nombreUbicacion.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !nombreUbicacion.campo}
            >
                {" "}
                CREAR NUEVA UBICACION{" "}
            </Button>
        </>
    );
};
