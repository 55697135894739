import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Grid,
    TextField,
    FormControl,
} from "@mui/material";

import { useState } from "react";

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { db, firebase } from "../../firebase";
import moment from "moment";

export const ViewTicketEnEspera = ({ props }) => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)

    const [linea] = useState(props.linea_contrato)

    const [cedula] = useState(props.cedula)

    const [celular, setCelular] = useState(props.celular)

    const [telefono, setTelefono] = useState(props.telefono)

    const [ticketsDepartamento] = useState(props.departamento);

    const [ticketsOficina] = useState(props.agendado_desde);

    const [ticketsAsunto] = useState(props.asunto_del_ticket);

    const [ticketsTurno] = useState(props.turno);

    const [nombreSolicitante] = useState(props.nombre_cliente)

    const [detallesTicket] = useState(props.detalles_del_ticket)

    const [openModal, setOpenModal] = useState(false)

    const [modalConfirmAprobacion, setModalConfirmAprobacion] = useState(false)

    const [modalConfirmRechazo, setModalConfirmRechazo] = useState(false)

    const [modalConfirmFlota, setModalConfirmFlota] = useState(false)


    const closeModalConfirmar = () => {
        setOpenModal(true)
        setModalConfirmAprobacion(false)
    }

    const closeModalrechazo = () => {
        setOpenModal(true)
        setModalConfirmRechazo(false)
    }


    const closeConfirmaFlota = () => {
        setModalConfirmAprobacion(true)
        setModalConfirmFlota(false)
    }

    const confirmAprobacion = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 2,
            usuario_que_aprobo: userAuth.nombres,
            fecha_hora_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
            flota: flota,
            lider: lider,
            auxiliar: auxiliar
        })
        Swal.fire({ icon: "success", text: 'Se ha aprobado y enviado a la flota el ticket con exito' });
        setModalConfirmAprobacion(false)
    }

    const confirmRechazo = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 1,
            usuario_que_rechazo: userAuth.nombres,
            fecha_hora_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        Swal.fire({ icon: "success", text: 'Se ha rechazado el ticket con exito' });
        modalConfirmAprobacion(false)
    }

    const updateNumeros = () => {
        db.collection('tickets').doc(props.id).update({
            celular: celular,
            telefono: telefono,
        })
        Swal.fire({ icon: "success", text: 'Se ha actualizado la informacion correctamente' });
        setOpenModal(false)
    }
    const [flota] = useState('')

    const [lider] = useState('')
    const [auxiliar] = useState('')


    const fechaFirestoreVisita = props.fecha_sugerida_visita;
    const fechaDateVisita = fechaFirestoreVisita.toDate();

    const [fechaVisita] = useState(fechaDateVisita)


    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <RemoveRedEyeIcon />
                </Button>
            </Tooltip>

            {/* DIALOG VIEW TICKET ESPERA */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                <DialogTitle sx={{ bgcolor: '#1A6EB9', color: "white" }}>
                    <strong>{props.uid_ticket}</strong></DialogTitle>
                <DialogContent>

                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>CEDULA:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cedula}

                                            name="cedula"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>LINEA:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={linea}

                                            name="linea"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>NOMBRE SOLICITANTE:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombreSolicitante}
                                            name="nombreSolicitante"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CELULAR:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={celular}
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value =
                                                    Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            }}
                                            name="celular"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setCelular(e.target.value)}
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>TELEFONO:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={telefono}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0,
                                                        parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                                name="telefono"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => setTelefono(e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>DEPARTAMENTO:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsDepartamento}
                                                name="ticketsDepartamento"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                        <strong>ASUNTO DEL TICKET:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsAsunto}
                                            name="ticketsAsunto"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>FECHA SUGERIDA DE VISITA</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={moment(fechaVisita.toString()).format('DD/MM/YYYY')}
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>TURNO:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsTurno}
                                            name="ticketsTurno"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>AGENDADO DESDE:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsOficina}
                                                name="ticketsOficina"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>FOTO VIVIENDA CLIENTE:</strong></p>
                                    </Box>

                                    <Box py={2}>
                                        <img width={400} src={props.imagen} />
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>FOTO OPCIONAL:</strong></p>
                                    </Box>

                                    <Box py={2}>
                                        <img width={400} src={props.imagen_opcional} />
                                    </Box>

                                </Grid>

                            </Grid>

                            <Box py={2}>
                                <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                    <strong>INGRESE DETALLES DEL TICKET</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={detallesTicket}
                                    name="detallesTicket"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={8}
                                />
                            </Box>
                            {props.estado === 2 &&
                                <>
                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>SOLUCION VISITA DE LA UNIDAD</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={props.observaciones_extra}
                                            size="small"
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    </Box>
                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}>
                                            <strong>MOTIVO CIERRE TICKET PYL</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={props.motivo_cierre_ticket}
                                            size="small"
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    </Box>
                                </>

                            }
                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" size="small"
                        onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    {props.estado === 0 &&
                        <Button variant="contained" color="info" size="small"
                            onClick={() => updateNumeros()}>ACTUALIZAR</Button>

                    }
                </DialogActions>

            </Dialog>

            {/* DIALOG CONFIRM*/}
            <Dialog open={modalConfirmAprobacion} onClose={() => setModalConfirmAprobacion(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE APROBAR EL TICKET?</strong></DialogTitle>
                <DialogActions>
                    <Button onClick={() => closeModalConfirmar()}><strong>Regresar</strong></Button>
                    <Button disabled={flota === '' || lider === '' || auxiliar === ''}
                        onClick={() => confirmAprobacion/* openConfirmaFlota */()} size="small"
                        variant="contained" color="secondary">
                        Aceptar
                        {/* ENVIAR A LA FLOTA */}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG RECHAZO*/}
            <Dialog open={modalConfirmRechazo} onClose={() => setModalConfirmRechazo(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE RECHAZAR EL TICKET?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalrechazo()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmRechazo()} size="small" variant="contained" color="error">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG CONFIRM FLOTA*/}
            <Dialog open={modalConfirmFlota} onClose={() => setModalConfirmFlota(false)} maxWidth="xs">
                <DialogTitle>¿ESTA SEGURO DE ENVIAR EL TICKET A LA FLOTA <strong> {flota}?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeConfirmaFlota()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmAprobacion()} size="small" variant="contained" color="secondary">
                        {" "}
                        ENVIAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}