import {
    IconButton,
    Typography,
    Paper,
    Chip,
    Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import moment from 'moment'

import { db } from '../../../firebase'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useSelector } from 'react-redux';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { FiscalizarInstalacion } from "./IconButtonsFiscalizacion/FiscalizarInstalacion";

export const InstalacionesPorGestionar = () => {

    const [data, setData] = useState()

    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth

    useEffect(() => {
        LoadData()
    }, []);


    const LoadData = () => {
        let ref = db.collection("tecnicos")
            .where('estado', '==', 2)
            .where('estado_pagado', '==', 2)
            .orderBy('fechaHora', 'desc')

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            // data.reverse()
            if (userInfo.email === "luis.vinan@intercommerce.com.ec" ||
                userInfo.email === "fernando.malave@intercommerce.com.ec") {
                setData(data.filter((item) => item.numContrato.includes('GUAYAQUIL')));

            }
            else if (userInfo.email === "diego.macias@intercommerce.com.ec") {
                setData(data.filter((item) => item.numContrato.includes('SANTA-ELENA')));
            }
            else {
                setData(data);
            }
        });
    };

    const columns = [
        { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
        {
            field: 'estado', headerName: 'ESTADO', width: 150,
            renderCell: () => {
                return <Chip label={'POR GESTIONAR'} size="small" variant="filled" color={'warning'} />
            }
        },
        {
           field: '1', headerName: 'MOTIVO', width: 160,
            renderCell: (params) => {
               const respuesta = params.row.observacion_adicional === true ? 'CAMBIO DOMICILIO' : 'INSTALACION'
               return `${respuesta}`
            }
            //field: 'tipo_orden_trabajo', headerName: 'MOTIVO', width: 110
        },
        { field: 'cedula', headerName: 'CEDULA', width: 110 },
        { field: 'nombres', headerName: 'CLIENTE', width: 350 },
        { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 180 },
        {
            field: '2', headerName: 'TERCERA EDAD', width: 140,
            renderCell: (params) => {
                const respuesta = params.row.terceraEdad === 1 ? 'SI' : 'NO'
                return `${respuesta}`
            }
        },
        {
            field: '3', headerName: 'DISCAPACIDAD', width: 140,
            renderCell: (params) => {
                const respuesta = params.row.discapacitado === 1 ? 'SI' : 'NO'
                return `${respuesta}`
            }
        },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 130 },
        { field: 'whatsapp', headerName: 'WHATSAPP', width: 120 },
        { field: 'numbRef', headerName: 'NUM. REF', width: 120 },
        {
            field: 'coordenadas', headerName: 'COORDENADAS DEL CLIENTE', width: 300,
            valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
        },
        { field: 'planNombre', headerName: 'PLAN', width: 280 },
        { field: 'planValor', headerName: 'PLAN VALOR', width: 120 },
        { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
        {
            field: 'fechaHora', headerName: 'FECHA  INSTALACION', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
        },
        /* { field: 'fechaInstalacion', headerName: 'FECHA DE INSTALACION', width: 260 }, */
        //{ field: 'cableInicio', headerName: 'CABLE INICIO', width: 130 },
        //{ field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 140 },
        {
            field: 'metraje', headerName: 'METRAJE', width: 100
        },
        {
            field: 'metraje_cable_extra', headerName: 'METRAJE_EXTRA', width: 100
        },
        {
            field: 'direccion', headerName: 'DIRECCION', width: 500
        },
        { field: 'zona', headerName: 'ZONA', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 140 },
        /* { field: 'metraje_instalacion_inicio', headerName: 'COORDENADAS CAJA', width: 270 }, */
        {
            field: '4', headerName: 'COORDENADAS CAJA', width: 250,
            renderCell: (params) => {
                const respuesta = params.row.coordenadas_caja === undefined ? '' : params.row.coordenadas_caja._lat
                const respuesta1 = params.row.coordenadas_caja === undefined ? '' : params.row.coordenadas_caja._long
                return `${respuesta}, ${respuesta1}`
            }
        },
        { field: 'sector', headerName: 'SECTOR', width: 230 },
        {
            field: 'numFlota', headerName: 'NUMERO FLOTA', width: 160
        },
        {
            field: 'nombreLider', headerName: 'LIDER FLOTA', width: 280
        },
        {
            field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 280
        },
        // {
        //     field: '5', headerName: 'FISCALIZAR', width: 150,
        //     renderCell: (params) => {
        //         return <FiscalizarInstalacion props={params.row} />
        //     }
        // },
    ];

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        let date1 = null
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        function instalacion(fechaHora) {
            if (fechaHora && fechaHora.seconds) {
                return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
            }
        }
        //

        //
        const reData = [
            ...data.map(doc => {
                return {
                    NUM_CONTRATO: doc.numContrato,
                    MOTIVO: doc.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION',
                    TIPO_CONTRATO: doc.contrato,
                    CEDULA: doc.cedula,
                    CLIENTE: doc.nombres,
                    CORREO: doc.correo,

                    TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
                    DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
                    GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.discapacidad,
                    DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.descuento,
                    VALOR_DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.valor,
                    DIRECCION: doc.direccion,
                    SECTOR: doc.sector,
                    // COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,
                    TELEFONO: doc.telefono,
                    CELULAR: doc.celular,
                    WHATSAPP: doc.whatsapp,
                    NUM_REFERENCIA: doc.numbRef,
                    PLAN: doc.planNombre,
                    NUMERO_PLAN: doc.numFlota,
                    LIDER: doc.nombreLider,
                    FECHA_AGENDA: doc.created.toDate(),
                    FECHA_INSTALACION: instalacion(doc.fechaHora),

                    COORDENADAS: doc.coordenadas,

                    METRAJE: doc.metraje,
                    METRAJE_EXTRA: doc.metraje_cable_extra,
                    //FIBRA_UTILIZADA: parseInt(doc.cableInicio) - parseInt(doc.cableFinaliza),
                    CAJA: doc.caja,
                    DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,

                    COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
                    COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,

                    VENDEDOR_RESPONSABLE: doc.vendedor,
                    NUM_FLOTA: doc.numFlota,
                    LIDER_FLOTA: doc.nombreLider,


                }
            })
        ]
        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Licencias");
        XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );


    return (
        <>
            <Box pt={1} pl={1}>
                <Typography >
                    <strong>({data ? data.length : ''})</strong> INSTALACIONES POR GESTIONAR
                </Typography >
                {/* <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography>
                </Box> */}
            </Box>

            {/* <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Box display="flex" alignItems='center' >
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box> */}
            <IconButton color="primary" onClick={handleExport}>
                <SimCardDownloadIcon />
            </IconButton>

            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>

                    <DataGrid
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        density='compact'
                        columns={columns}
                        components={{
                            // Footer: CustomFooterTotalComponent,
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};