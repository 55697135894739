import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

export const ListTickets = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    const [open11, setOpen11] = useState(false);

    return (
        <>
            {userAuth?.roles?.find(role => ['admin','tickets']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <ConfirmationNumberIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tickets" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`tickets/generar-ticket/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Generar nuevo ticket" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`tickets/resumen/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Resumen Tickets" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`tickets/lista-tickets/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets" />
                        </ListItemButton>

                        {/* <ListItemButton component={RouterLink} to={`tickets/tickets-en-espera/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets en espera" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`tickets/tickets-respondidos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets aprobadors" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`tickets/tickets-cerrados/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Tickets cerrados" />
                        </ListItemButton> */}

                        {/* COLLAPSE PARAMETROS */}

                        <ListItemButton onClick={() => setOpen11(!open11)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros" />
                            {open11 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open11} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Departamentos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/tickets/parametros/departamentos/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Asunto de ticket'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/tickets/parametros/asunto-de-ticket/${index}`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Oficinas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/tickets/parametros/oficinas/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Turnos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/tickets/parametros/turnos/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}