import { Chip, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import moment from 'moment'
export const Promociones = () => {
  const [data, setData] = useState('')
  useEffect(() => {
    LoadData()
  }, [])
  const LoadData = () => {
    let ref = db.collection("promociones")
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      console.log(data)
      setData(data)
    })
  }
  const columns = [
    // { field: 'id', headerName: 'ID', width: 25 },
    { field: 'nombre', headerName: 'NOMBRE', width: 250 },
    {
      field: 'inicio', headerName: 'INICIO DE PROMOCION', type: 'dateTime', width: 175,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
    },
    {
      field: 'fin', headerName: 'FIN DE PROMOCION', type: 'dateTime', width: 175,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
    },
    // {
    //   field: 'estado', headerName: 'ESTADO', width: 100,
    //   renderCell: (params) => {
    //     return <Chip size="small" variant='outlined' label="VIGENTE" color="success" />
    //   }
    // },
    {
      field: 'descuento', headerName: 'DESCUENTO', width: 125,
      renderCell: (params) => {
        return <a>{params.row.descuento} %</a>
      }
    },
    {
      field: 'cuotas', headerName: 'CUOTAS', width: 150,
      renderCell: (params) => {
        return <a>{params.row.cuotas.join(', ')}</a>
      }
    },
    {
      field: 'zonas', headerName: 'ZONAS', width: 200,
      renderCell: (params) => {
        return <a>{params.row.zonas.join(', ')}</a>
      }
    },
    {
      field: 'planes', headerName: 'PLANES', width: 200,
      renderCell: (params) => {
        return <a>{params.row.planes.join(', ')}</a>
      }
    },
  ]


  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        density="compact"
        rows={data ? data : []}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        hideFooter
        components={{
          Toolbar: CustomToolbar,

        }}
        getRowId={(row) => row.id}
      />
    </Box>
  );
};
