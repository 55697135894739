import { db } from '../../firebase'
export const listsParameters = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: 'PARAMETERS_LIST_REQUEST',
        })
        db.collection('parameters')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                console.log(arrayMessage)
                dispatch({
                    type: 'PARAMETERS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PARAMETERS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsProvincia = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PROVINCIA_LIST_REQUEST' })
        db.collection(`parameters/cantones/provincias`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'PROVINCIA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PROVINCIA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsKardex = () => async (dispatch) => {
    try {
        dispatch({ type: 'KARDEX_LIST_REQUEST' })
        db.collection('kardex')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'KARDEX_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'KARDEX_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCategorias = () => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORIA_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeicategoria/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'CATEGORIA_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'CATEGORIA_LIST_FAIL',
            payload: error
        })
    }
}
export const listsFormaPagoRubros = () => async (dispatch) => {
    try {
        dispatch({ type: 'FORMAPAGORUBROS_LIST_REQUEST' })
        db.collection('system').doc('forma_pago_rubro').collection('formas_pago')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'FORMAPAGORUBROS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'FORMAPAGORUBROS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsSubCategorias = () => async (dispatch) => {
    try {
        dispatch({ type: 'SUBCATEGORIA_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeisubcategoria/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'SUBCATEGORIA_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'SUBCATEGORIA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsModeloProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'MODELOPRODUCTO_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeimodeloproducto/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'MODELOPRODUCTO_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'MODELOPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMarcaProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'MARCAPRODUCTO_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeimarcaproducto/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'MARCAPRODUCTO_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'MARCAPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsUnidadMedidaProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'UNIDADMEDIDA_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeiunidadmedidaproducto/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'UNIDADMEDIDA_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'UNIDADMEDIDA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsPresentacionProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'PRESENTACIONPRODUCTO_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apisoeipresentacionproducto/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'PRESENTACIONPRODUCTO_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
        // db.collection(`planes`).where("estado", "==", "VIGENTE")
        //     /* db.collection("planes").where("codigoPlan", ">", planesLength) */
        //     .onSnapshot((querySnapshot) => {
        //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
        //         dispatch({
        //             type: 'PLANES_LIST_SUCCESS',
        //             payload: arrayMessage
        //         })

        //     })
    } catch (error) {
        dispatch({
            type: 'PRESENTACIONPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsProductos = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PRODUCTOS_LIST_REQUEST' })
        db.collection(`productos`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'PRODUCTOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PRODUCTOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsBodegas = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'BODEGAS_LIST_REQUEST' })
        db.collection(`bodegas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'BODEGAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'BODEGAS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsUbicaciones = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'UBICACIONES_LIST_REQUEST' })
        db.collection(`ubicaciones`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'UBICACIONES_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'UBICACIONES_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMotivoIngresoProductos = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'MOTIVOINGRESOPRODUCTO_LIST_REQUEST' })
        db.collection(`motivos_ingreso_productos`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'MOTIVOINGRESOPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MOTIVOINGRESOPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMotivoEgresoProductos = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'MOTIVOEGRESOPRODUCTO_LIST_REQUEST' })
        db.collection(`motivos_egreso_productos`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'MOTIVOEGRESOPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MOTIVOEGRESOPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}


export const listsZonas = () => async (dispatch) => {
    try {
        dispatch({ type: 'ZONAS_LIST_REQUEST' })
        db.collection(`zonas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'ZONAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'ZONAS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCantones = (provinciaId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CANTONES_LIST_REQUEST' })
        db.collection(`parameters/cantones/provincias/${provinciaId}/canton`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CANTONES_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CANTONES_LIST_FAIL',
            payload: error
        })
    }
}
export const listsParroquia = (provinciaId, cantonId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PARROQUIA_LIST_REQUEST' })
        db.collection(`parameters/cantones/provincias/${provinciaId}/canton/${cantonId}/parroquia`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'PARROQUIA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PARROQUIA_LIST_FAIL',
            payload: error
        })
    }
}
export const listsSector = (provinciaId, cantonId, parroquiaId) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'SECTOR_LIST_REQUEST' })

        db.collection(`parameters/cantones/provincias/${provinciaId}/canton/${cantonId}/parroquia/${parroquiaId}/sector`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'SECTOR_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'SECTOR_LIST_FAIL',
            payload: error
        })
    }
}
export const listsServicios = () => async (dispatch) => {
    try {
        dispatch({ type: 'SERVICIOS_LIST_REQUEST' })
        db.collection(`parameters/config/servicio_contratar`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'SERVICIOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'SERVICIOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsPlanes = () => async (dispatch) => {
    try {
        dispatch({ type: 'PLANES_LIST_REQUEST' })
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/apiMikrowispPerfilesplanes/", requestOptions)
            .then(response => response.text())
            .then(result => {

                const data = JSON.parse(result)

                dispatch({ type: 'PLANES_LIST_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'PLANES_LIST_FAIL',
            payload: error
        })
    }
}

export const changePlanes = () => async (dispatch) => {
    try {
        dispatch({ type: 'CHANGE_PLANES_REQUEST' })
            .onSnapshot((querySnapshot) => {
                db.collection("planes")
                    .where("codigoPlan", ">=", 1)
                    .orderBy("codigoPlan", "asc")
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CHANGE_PLANES_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CHANGE_PLANES_FAIL',
            payload: error
        })
    }
}

export const listMarcas = () => async (dispatch) => {
    try {

        dispatch({ type: 'MARCAS_LIST_REQUEST' })
        db.collection(`parameters/marca_vehiculos/marcas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'MARCAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MARCAS_LIST_FAIL',
            payload: error
        })
    }
}


export const listsSplitter = () => async (dispatch) => {
    try {
        dispatch({ type: 'SPLITTER_LIST_REQUEST' })
        db.collection(`parameters/config/splitter`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'SPLITTER_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'SPLITTER_LIST_FAIL',
            payload: error
        })
    }
}
export const listsNivel = () => async (dispatch) => {
    try {
        dispatch({ type: 'NIVEL_LIST_REQUEST' })
        db.collection(`parameters/config/nivel`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'NIVEL_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'NIVEL_LIST_FAIL',
            payload: error
        })
    }
}
export const listsNodos = () => async (dispatch) => {
    try {
        dispatch({ type: 'NODOS_LIST_REQUEST' })
        db.collection(`parameters/config/nodos`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'NODOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'NODOS_LIST_FAIL',
            payload: error
        })
    }
}
export const listsModelos = () => async (dispatch) => {
    try {
        dispatch({ type: 'MODELO_LIST_REQUEST' })
        db.collection(`parameters/config/modelo`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'MODELO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MODELO_LIST_FAIL',
            payload: error
        })
    }
}
export const listsDiscapacidad = () => async (dispatch) => {
    try {
        dispatch({ type: 'DISCAPACIDAD_LIST_REQUEST' })
        db.collection(`parameters/config/discapacidad`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'DISCAPACIDAD_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'DISCAPACIDAD_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTipoNombreCuenta = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'TIPONOMBRECUENTAS_LIST_REQUEST' })
        db.collection(`tipo_nombre_cuentas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'TIPONOMBRECUENTAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TIPONOMBRECUENTAS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsNaturalezaCuenta = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'NATURALEZACUENTA_LIST_REQUEST' })
        db.collection(`naturaleza_de_cuenta`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'NATURALEZACUENTA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'NATURALEZACUENTA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTiposCuentas = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'TIPOCUENTA_LIST_REQUEST' })
        db.collection(`tipo_cuentas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'TIPOCUENTA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TIPOCUENTA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsItemsEstadoFinanciero = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'ITEMSESTADOFINANCIERO_LIST_REQUEST' })
        db.collection(`item_estado_financiero`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'ITEMSESTADOFINANCIERO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'ITEMSESTADOFINANCIERO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDatacuenta1 = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CUENTA1_LIST_REQUEST' })
        db.collection("plan_cuentas_test").where('cuenta', '<', "2").limit(150)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CUENTA1_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CUENTA1_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDatacuenta2 = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CUENTA2_LIST_REQUEST' })
        db.collection("plan_cuentas_test").where('cuenta', '>=', "2").where('cuenta', '<', "3").limit(50)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CUENTA2_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CUENTA2_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDatacuenta3 = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CUENTA3_LIST_REQUEST' })
        db.collection("plan_cuentas_test").where('cuenta', '>=', "3").where('cuenta', '<', "4").limit(50)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CUENTA3_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CUENTA3_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDatacuenta4 = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CUENTA4_LIST_REQUEST' })
        db.collection("plan_cuentas_test").where('cuenta', '>=', "4").where('cuenta', '<', "5").limit(50)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CUENTA4_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CUENTA4_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDatacuenta5 = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CUENTA5_LIST_REQUEST' })
        db.collection("plan_cuentas_test").where('cuenta', '>=', "5").limit(100)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CUENTA5_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CUENTA5_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCategoriasFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CATEGORIASFIREBASE_LIST_REQUEST' })
        db.collection("categorias_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'CATEGORIASFIREBASE_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CATEGORIASFIREBASE_LIST_FAIL',
            payload: error
        })
    }
}

export const listsSubCategoriasFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'SUBCATEGORIASFIREBASE_LIST_REQUEST' })
        db.collection("subcategorias_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'SUBCATEGORIASFIREBASE_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'SUBCATEGORIASFIREBASE_LIST_FAIL',
            payload: error
        })
    }
}

export const listsPresentacionFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'PRESENTACION_LIST_REQUEST' })
        db.collection("presentaciones_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'PRESENTACION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PRESENTACION_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMarcaFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'MARCA_LIST_REQUEST' })
        db.collection("marcas_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'MARCA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MARCA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsModeloFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'MODELO_LIST_REQUEST' })
        db.collection("modelos_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'MODELO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MODELO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsUnidadMedidaFirebase = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'UNIDADMEDIDA_LIST_REQUEST' })
        db.collection("unidades_medidas_productos")
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

                dispatch({
                    type: 'UNIDADMEDIDA_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'UNIDADMEDIDA_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTiposInfracciones = () => async (dispatch) => {
    try {
        dispatch({ type: 'TIPOSINFRACCIONES_LIST_REQUEST' })
        db.collection('parameters').doc('tipo_infracciones_fiscalizacion').collection('tipo_infracciones')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TIPOSINFRACCIONES_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TIPOSINFRACCIONES_LIST_FAIL',
            payload: error
        })
    }
}

export const listsNominas = () => async (dispatch) => {
    try {
        dispatch({ type: 'NOMINAS_LIST_REQUEST' })
        db.collection('nomina')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'NOMINAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'NOMINAS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCentrosDeCostos = () => async (dispatch) => {
    try {
        dispatch({ type: 'CENTROSCOSTOS_LIST_REQUEST' })
        db.collection('centros_de_costos')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CENTROSCOSTOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CENTROSCOSTOS_LIST_FAIL',
            payload: error
        })
    }
}


export const listsMateriales = () => async (dispatch) => {
    try {
        dispatch({ type: 'MATERIALES_LIST_REQUEST' })
        db.collection('materiales')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'MATERIALES_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MATERIALES_LIST_FAIL',
            payload: error
        })
    }
}

/* ACTIVOS FIJOS PARAMETERS */

export const listsTiposActivosFijos = () => async (dispatch) => {
    try {
        dispatch({ type: 'ACTIVOSFIJOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('tipos_de_activos_fijos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'ACTIVOSFIJOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MACTIVOSFIJOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsPorcentajeDeDepreciacion = () => async (dispatch) => {
    try {
        dispatch({ type: 'PORCENTAJEDEPRECIACION_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('porcentaje_de_depreciacion').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'PORCENTAJEDEPRECIACION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'PORCENTAJEDEPRECIACION_LIST_FAIL',
            payload: error
        })
    }
}

export const listsEstadoProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'ESTADOPRODUCTO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('estado_producto').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'ESTADOPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'ESTADOPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsVidaUtil = () => async (dispatch) => {
    try {
        dispatch({ type: 'VIDAUTIL_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('vida_util').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'VIDAUTIL_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'VIDAUTIL_LIST_FAIL',
            payload: error
        })
    }
}

export const listsClaseDeActivo = () => async (dispatch) => {
    try {
        dispatch({ type: 'CLASEACTIVO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('clase_de_activo').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CLASEACTIVO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CLASEACTIVO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMetodoDeDepreciacion = () => async (dispatch) => {
    try {
        dispatch({ type: 'METODODEPRECIACION_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('metodo_de_depreciacion').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'METODODEPRECIACION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'METODODEPRECIACION_LIST_FAIL',
            payload: error
        })
    }
}

/* GENERAL/TIPO DE PRODUCTO */

export const listsTipoDeProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'TIPODEPRODUCTO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('tipos_de_activos_fijos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TIPODEPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TIPODEPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsClaseDeProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'CLASEPRODUCTO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('clase_de_producto').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CLASEPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CLASEPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsFrecuenciaDeDepreciacion = () => async (dispatch) => {
    try {
        dispatch({ type: 'FRECUENCIADEPRECIACION_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('frecuencia_depreciacion').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'FRECUENCIADEPRECIACION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'FRECUENCIADEPRECIACION_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCondicionDeActivo = () => async (dispatch) => {
    try {
        dispatch({ type: 'CONDICIONACTIVO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('condicion_de_activo').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CONDICIONACTIVO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CONDICIONACTIVO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsLocalidadProducto = () => async (dispatch) => {
    try {
        dispatch({ type: 'LOCALIDADPRODUCTO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('localidad_de_producto').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'LOCALIDADPRODUCTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'LOCALIDADPRODUCTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDepartamento = () => async (dispatch) => {
    try {
        dispatch({ type: 'DEPARTAMENTO_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('departamento').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'DEPARTAMENTO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'DEPARTAMENTO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsSubcategoriaActivos = () => async (dispatch) => {
    try {
        dispatch({ type: 'SUBCATEGORIASACTIVOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('subcategoria_activos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'SUBCATEGORIASACTIVOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'SUBCATEGORIASACTIVOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTipoTransaccion = () => async (dispatch) => {
    try {
        dispatch({ type: 'TIPOTRANSACCION_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('tipo_transaccion').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TIPOTRANSACCION_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TIPOTRANSACCION_LIST_FAIL',
            payload: error
        })
    }
}

export const listsResidual = () => async (dispatch) => {
    try {
        dispatch({ type: 'RESIDUAL_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('residual').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'RESIDUAL_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'RESIDUAL_LIST_FAIL',
            payload: error
        })
    }
}

export const listsCategoriaActivos = () => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORIAACTIVOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('categoria_activos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CATEGORIAACTIVOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'CATEGORIAACTIVOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsUnidadMedidaActivos = () => async (dispatch) => {
    try {
        dispatch({ type: 'UNIDADMEDIDAACTIVOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('unidad_medida').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'UNIDADMEDIDAACTIVOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'UNIDADMEDIDAACTIVOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsMarcaActivos = () => async (dispatch) => {
    try {
        dispatch({ type: 'MARCAACTIVOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('marca_activos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'MARCAACTIVOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MARCAACTIVOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsModeloActivos = () => async (dispatch) => {
    try {
        dispatch({ type: 'MODELOACTIVOS_LIST_REQUEST' })
        db.collection('activos_fijos_parametros').doc('modelo_activos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'MODELOACTIVOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'MODELOACTIVOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDepartamentosInventario = () => async (dispatch) => {
    try {
        dispatch({ type: 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_REQUEST' })
        db.collection('inventario_parametros').doc('departamento').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_FAIL',
            payload: error
        })
    }
}

export const listsDepartamentos = () => async (dispatch) => {
    try {
        dispatch({ type: 'DEPARTAMENTOS_LIST_REQUEST' })
        db.collection('system/settings/departamentos')
            .onSnapshot((querySnapshot) => {
                const arrayMessge = querySnapshot.docs.map(item => { return { ...item.data(), departamento: item.id } })
                dispatch({
                    type: 'DEPARTAMENTOS_LIST_SUCCESS',
                    payload: arrayMessge
                })
            })
    } catch (error) {
        dispatch({
            type: 'DEPARTAMENTOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTicketsDepartamentos = () => async (dispatch) => {
    try {
        dispatch({ type: 'TICKETSDEPARTAMENTOS_LIST_REQUEST' })
        db.collection('tickets_parametros').doc('departamentos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TICKETSDEPARTAMENTOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TICKETSDEPARTAMENTOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTicketsAsuntos = () => async (dispatch) => {
    try {
        dispatch({ type: 'TICKETSASUNTOS_LIST_REQUEST' })
        db.collection('tickets_parametros').doc('asuntos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TICKETSASUNTOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TICKETSASUNTOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTicketsOficinas = () => async (dispatch) => {
    try {
        dispatch({ type: 'TICKETSOFICINAS_LIST_REQUEST' })
        db.collection('tickets_parametros').doc('oficinas').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TICKETSOFICINAS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TICKETSOFICINAS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsTicketsTurnos = () => async (dispatch) => {
    try {
        dispatch({ type: 'TICKETSTURNOS_LIST_REQUEST' })
        db.collection('tickets_parametros').doc('turnos').collection('list')
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'TICKETSTURNOS_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (error) {
        dispatch({
            type: 'TICKETSTURNOS_LIST_FAIL',
            payload: error
        })
    }
}

export const listsRelationFamilyUsers = () => async (dispatch) => {
    try {
        dispatch({ type: 'RELACIONFAMILYUSER_LIST_REQUEST' })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cedula": "0943638171"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiOLT/consultarParientes", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log('result', result)
                dispatch({ type: 'RELACIONFAMILYUSER_LIST_SUCCESS', payload: result })
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'RELACIONFAMILYUSER_LIST_FAIL',
            payload: error
        })
    }
}