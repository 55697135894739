import {
    Box, CardHeader, Card, Chip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from 'moment'
import { db } from '../../../firebase'
import { useSelector, useDispatch } from 'react-redux'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
function Estado(estado) {
    let name;
    let color;
    if (estado.props == 0) {
        name = "DESACTIVADO";
        color = "warning";
    } else if (estado.props === 1) {
        name = "ACTIVO";
        color = "success";
    }
    return <Chip size='small' label={name} color={color} />
}
export const ServiciosRecurrentes = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { client_details } = clientDetails
    const [data, setData] = useState([])
    //var current = new Date()
    useEffect(() => {
        LoadData()
    }, [])
    const LoadData = () => {
        db.collection(`servicios_recurrentes`).where('idcliente', '==', client_details.idcliente).orderBy("created", "desc")
            .onSnapshot((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                ]
                setData(data)
            })
    }
    function CustomFooterTotalComponent(props) {
        return ''
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 25 },
        { field: 'categoriaProducto', headerName: 'PRODUCTO', width: 300 },
        {
            field: 'monto', headerName: 'MONTO', width: 75,
            valueGetter: ({ value }) => value && `$${value.toFixed(2)}`,
        },
        {
            field: 'created', headerName: 'FECHA INICIO', type: 'dateTime', width: 125,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        { field: 'coutasPagadas', headerName: 'PAGADAS', width: 100 },
        { field: 'couta', headerName: 'COUTAS', width: 75 },
        {
            field: 'estado', headerName: 'ESTADO', width: 100,
            renderCell: (params) => {
                return <Estado props={params.row.estado} />
            }
        }
    ]
    const csvOptions = {
        delimiter: ';',
        fileName: `SERVICIOS RECURRENTES ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <Card variant='outlined'>
            <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='PRODUCTOS Y OTROS SERVICIOS RECURRENTES (CUOTAS Y MENSUAL)' />
            <Box p={1}>
                <Box sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                        density="compact"
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent
                        }}
                        onStateChange={(state) => {
                        }}
                    />
                </Box>
            </Box>
        </Card>
    )
}