import {
    Box,
    Typography,
    Button,
    Paper,
    IconButton,
    Chip,
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import { EditUser } from "./EditUser";
export const ListUsersPage = () => {
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData();
    }, [startDate]);
    //
    const LoadData = (startAfterDoc, persistMessages = []) => {
        setLoading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'manual'
        };

        fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadcheck/", requestOptions)
            .then(response => response.text())
            .then(result => {

                const re = JSON.parse(result)
                console.log(re, 're')
                const data = [
                    ...re.map((doc) => {
                        return {
                            ...doc,
                            id: doc.id,
                        };
                    }),
                ];
                console.log(data, 'data')
                setData(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)
            });

    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const onChange2 = () => {
        LoadData();
    };
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 150 },
        { field: 'username', headerName: 'USERNAME', width: 150 },
        { field: 'attribute', headerName: 'ATTRIBUTE', width: 150 },
        { field: 'op', headerName: 'OP', width: 150 },
        { field: 'value', headerName: 'VALUE', width: 150 },
        {
            field: 'acciones', headerName: 'ACCIONES', width: 100,
            renderCell: (params) => {
                return <EditUser props={params.row} />
            }
        }
    ];
    return (
        <>
            <Box pl={2} pb={2} pt={2}>
                <Typography variant="h5" component="h1">RADIUS/<strong>USERS LIST</strong></Typography>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems="center">
                <Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                onClick={onChange2}
                            >
                                Aplicar busqueda
                            </Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        density='compact'
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        loading={loading}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {

                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
