import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
    Grid
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveIcon from '@mui/icons-material/Remove';

import { listMarcas } from "../../../redux/actions/parametersActions";


import InputValidation from "../../utils/InputValidation";
import { margin } from "@mui/system";

export const UpdateFlotasActivas = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [estado, setEstado] = useState(props.estado);
    const [localidad, setLocalidad] = useState(false);
    const [gps, setGps] = useState(false);
    const [fechaGpsInicioVigencia, setFechaGpsInicioVigencia] = useState(false);
    const [fechaGpsFinVigencia, setFechaGpsFinVigencia] = useState(false);
    const [seguro, setSeguro] = useState(false);
    const [fechaSeguroInicioVigencia, setFechaSeguroInicioVigencia] = useState(false);
    const [fechaSeguroFinVigencia, setFechaSeguroFinVigencia] = useState(false);
    const [nombreLider, setNombreLider] = useState(false);
    const [nombreAuxiliar, setNombreAuxiliar] = useState(false);
    const [proveedor, setProveedor] = useState(false);
    const [placa, setPlaca] = useState(false);
    const [motor, setMotor] = useState(false);
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [anio, setAnio] = useState(false);
    const [modelo, setModelo] = useState(false);
    const [numeroFlota, setNumeroFlota] = useState(false);
    const [numContrato, setNumContrato] = useState(false);
    const [marca, setMarca] = useState(false);

    const [observaciones, setObservaciones] = useState({
        campo: "",
        valido: true,
    });

    const [nombres, setNombres] = useState({
        campo: props.nombres,
        valido: true,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const siFactible = (id) => {
        //Validaciones
        db.collection("service_request")
            .doc(id)
            .update({
                estado: 2,
            })
            .then((docRef) => {
                console.log("licencia ", docRef);
                Swal.fire({
                    icon: "success",
                    title: "Has confirmado que hay factibilidad.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const handleMarca = (e) => {
        //console.log(e)
        setMarca(e)
        const p = marcas.filter((item) => item.marca === e);
        const plan = p[0]
        //console.log(plan)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        setNombres(props.nombres);
        setLocalidad(props.localidad);
        setGps(props.gps);
        setFechaGpsInicioVigencia(props.inicioVigenciaGps)
        setFechaGpsFinVigencia(props.FinVigenciaGps)
        setSeguro(props.seguro);
        setFechaSeguroInicioVigencia(props.inicioVigenciaSeguro)
        setFechaSeguroFinVigencia(props.FinVigenciaSeguro)
        setNombreLider(props.nombreLider)
        setNombreAuxiliar(props.nombreAuxiliar)
        setProveedor(props.proveedor)
        setPlaca(props.placa)
        setMotor(props.motor)
        setAnio(props.anio)
        setModelo(props.modelo)
        setNumeroFlota(props.numeroFlota)
        setNumContrato(props.numContrato)
        setMarca(props.marca)
        dispatch(listMarcas())
    }, [dispatch]);

    const noFactible = (id) => {
        db.collection("service_request")
            .doc(id)
            .update({
                estado: 1,
                observaciones: observaciones.campo
            })
            .then((docRef) => {
                Swal.fire({
                    icon: "success",
                    title: "No hay factibilidad",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const suspendFlota = (id) => {
        db.collection("flotas")
            .doc(id)
            .update({
                estado: 2,
                observacion: observaciones.campo
            })
            .then((docRef) => {
                setOpen(false)
                Swal.fire({
                    icon: "success",
                    text: "Se ha suspendido la cuadrilla exitosamente.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }

    const handleSubmit = (id) => {
        db.collection("flotas")
            .doc(id)
            .update({
                estado: 1,
                observacion: observaciones.campo
            })
            .then((docRef) => {
                setOpen(false)
                Swal.fire({
                    icon: "success",
                    text: "Se ha inactivado la cuadrilla exitosamente.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }

    const updatelocalidad = (id) => {
        db.collection("flotas")
            .doc(id)
            .update({
                localidad: localidad,
                nombreLider: nombreLider,
                nombreAuxiliar: nombreAuxiliar,
                proveedor: proveedor,
                placa: placa,
                motor: motor,
                anio: anio,
                modelo: modelo,
                numeroFlota: numeroFlota,
                numContrato: numContrato,
                marca: marca,
                gps: gps,
                seguro: seguro,
                inicioVigenciaSeguro: fechaSeguroInicioVigencia,
                FinVigenciaSeguro: fechaSeguroFinVigencia,
                inicioVigenciaGps: fechaGpsInicioVigencia,
                FinVigenciaGps: fechaGpsFinVigencia
            })
            .then((docRef) => {
                setOpen(false)
                Swal.fire({
                    icon: "success",
                    text: "Se ha actualizado la localidad con exito.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }


    return (
        <>
            <Tooltip title="Visualizar">
                <Button onClick={handleClickOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={handleClose} maxWidth="md">
                <DialogTitle>
                    Proveedor {props.proveedor}
                </DialogTitle>

                <DialogContent>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Nombre Lider" value={nombreLider} onChange={(e) => setNombreLider(e.target.value)} />
                            </Box>



                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Nombre Auxiliar" value={nombreAuxiliar} onChange={(e) => setNombreAuxiliar(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Proveedor" value={proveedor} onChange={(e) => setProveedor(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <FormControl fullWidth variant="filled">
                                    <FormControl fullWidth>
                                        <InputLabel >MARCA</InputLabel>
                                        <Select
                                            value={marca}
                                            label="MARCA"
                                            onChange={(e) => handleMarca(e.target.value)}
                                        >
                                            {marcas && marcas.map((row, key) => (
                                                <MenuItem key={key} value={row.marca}>{row.marca}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </FormControl>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Placa" value={placa} onChange={(e) => setPlaca(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Motor" value={motor} onChange={(e) => setMotor(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Año" value={anio} onChange={(e) => setAnio(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Modelo" value={modelo} onChange={(e) => setModelo(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Numero de Flota" value={numeroFlota} onChange={(e) => setNumeroFlota(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Numero de Contrato" value={numContrato} onChange={(e) => setNumContrato(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Localidad</InputLabel>
                                    <Select
                                        label="Localidad"

                                        value={localidad}
                                        onChange={(e) => setLocalidad(e.target.value)}
                                    >
                                        <MenuItem value="Guayaquil">Guayaquil</MenuItem>
                                        <MenuItem value="Duran">Duran</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Gps</InputLabel>
                                    <Select
                                        label="Gps"

                                        value={gps}
                                        onChange={(e) => setGps(e.target.value)}
                                    >
                                        <MenuItem value="SI">SI</MenuItem>
                                        <MenuItem value="NO">NO</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Fecha Gps Inicio Vigencia" value={fechaGpsInicioVigencia} onChange={(e) => setFechaGpsInicioVigencia(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Fecha Gps Fin Vigencia" value={fechaGpsFinVigencia} onChange={(e) => setFechaGpsFinVigencia(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <FormControl fullWidth>
                                    <InputLabel>Seguro</InputLabel>
                                    <Select
                                        label="Seguro"

                                        value={seguro}
                                        onChange={(e) => setSeguro(e.target.value)}
                                    >
                                        <MenuItem value="SI">SI</MenuItem>
                                        <MenuItem value="NO">NO</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Fecha Seguro Inicio Vigencia" value={fechaSeguroInicioVigencia} onChange={(e) => setFechaSeguroInicioVigencia(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>
                                <TextField fullWidth label="Fecha Seguro Fin Vigencia" value={fechaSeguroFinVigencia} onChange={(e) => setFechaSeguroFinVigencia(e.target.value)} />
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box py={2}>

                                <InputValidation
                                    estado={observaciones}
                                    cambiarEstado={setObservaciones}
                                    label="Observacion"
                                    name="observacion"
                                />
                            </Box>
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>

                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>

                    <Button onClick={() => suspendFlota(props.id)}>
                        {" "}
                        SUSPENDER FLOTA
                    </Button>


                    <Button onClick={() => handleSubmit(props.id)}>
                        {" "}
                        INACTIVAR FLOTA
                    </Button>
                    <Button onClick={() => updatelocalidad(props.id)} variant="contained">
                        {" "}
                        Actualizar Flota
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
