import { Tabs, Box, Tab, Container,Typography,Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { NuevaPromocion } from './NuevaPromocion';
import { Promociones } from './Promociones';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    )
}
export const PromocionesPage = () => {
    const [value, setValue] = useState(0)
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1"><strong>PROMOCIONES</strong></Typography>
        </Box>
            <Card>
                <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                    <Tabs value={value} onChange={(event, newValue) => setValue(newValue)}>
                        <Tab label="Promociones" />
                        <Tab label="Nueva Promocion" />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Promociones />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <NuevaPromocion />
                </TabPanel>
            </Card>
        </Container>
    )
}