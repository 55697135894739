import React, { useState, useEffect } from "react";
import { Dialog, Box, Grid, Button, DialogActions, DialogTitle, TextField, DialogContent } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import { DataF } from "@react-google-maps/api";
import { db, firebase, auth } from "../../../firebase";
import Swal from "sweetalert2";
const TeleventasButtons = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [data, setData] = useState(false);
    const [motivo, setMotivo] = useState('');
    useEffect(() => {
    }, []);
    const onData = () => {
        setOpen(true)
        setData(props)
        //console.log(props, 'pros')
    }
    const onData1 = () => {
        setOpen2(true)
        setOpen(false)
    }
    const onSubmit = () => {
        db.collection("solicitudes_televentas").doc(data.id).update({
            estado: 1,
            user_uid: auth.currentUser.uid,
            televenta: auth.currentUser.displayName,
            fecha_aprobada: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
            Swal.fire({ icon: "success", text: "Se aprobo", });
        })
    }
    const onRechazada = () => {
        db.collection("solicitudes_televentas").doc(data.id).update({
            estado: 3,
            user_uid: auth.currentUser.uid,
            motivo: motivo,
            televenta: auth.currentUser.displayName,
            fecha_aprobada: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
            setOpen2(false)
            Swal.fire({ icon: "error", text: "Se rechazo", });
        })
    }
    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle >
                    {data.uid} - {data.nombres}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Box pb={5}>
                                <TextField
                                    label='NOMBRES'
                                    fullWidth
                                    value={data.nombres}
                                />
                            </Box>
                            <Box pb={5}>
                                <TextField
                                    label='CELULAR'
                                    fullWidth
                                    value={data.celular}
                                />
                            </Box>
                            <Box pb={5}>
                                <TextField
                                    label='CEDULA'
                                    fullWidth
                                    value={data.cedula}
                                />
                            </Box>
                            <TextField
                                label='CORREO'
                                fullWidth
                                value={data.correo}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <Box pb={5}>

                                <TextField
                                    label='DIRECCION'
                                    fullWidth
                                    value={data.direccion}
                                />
                            </Box>
                            {/* <Box pb={5}>
                                <TextField
                                    label='FECHA NACIMIENTO'
                                    fullWidth
                                    value={data.fechaNacimiento}
                                />
                            </Box>
                            <Box pb={5}>
                                <TextField
                                    label='NACIONALIDAD'
                                    fullWidth
                                    value={data.nacionalidad}
                                />
                            </Box> */}

                            <Box pb={5}>
                                <TextField
                                    label='TELEFONO'
                                    fullWidth
                                    value={data.telefono}
                                />
                            </Box>
                   
                                <TextField
                                    label='COORDENADAS'
                                    fullWidth
                                    value={`${props.coordenadas._lat}, ${props.coordenadas._long}`}
                                />
                        
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <Button color="error" variant="contained" onClick={() => onData1()}>RECHAZAR</Button>
                    <Button color="success" variant="contained" onClick={() => onSubmit()}>APROBAR</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open2}
            >
                <DialogTitle >
                   MOTIVO DE RECHAZO
                </DialogTitle>
                <DialogContent dividers>
                    <TextField 
                        fullWidth
                        value={motivo}
                        label='MOTIVO'
                      
                        onChange={(e) => setMotivo(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen2(false)}>CERRAR VENTANA</Button>
                    <Button color="error" variant="contained"   disabled={!motivo} onClick={() => onRechazada()}>RECHAZAR</Button>
       
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TeleventasButtons