import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
    Box,
    Chip,
    TextField
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';

export const ChangeStatePuerto = ({ props, idCaja }) => {

    const [openDialogUsarPuerto, setOpenDialogUsarPuerto] = useState(null);

    const [openUsarPuerto, setOpenUsarPuerto] = useState(null);

    const [openLiberarPuerto, setOpenLiberarPuerto] = useState(null);

    const [openConfirmUpdateState, setOpenConfirmUpdateState] = useState(null);

    const [nombreCliente, setNombreCliente] = useState('');

    const [cedulaCliente, setCedulaCliente] = useState('');

    const usarPuerto = () => {
        db.collection('cajas').doc(idCaja).collection('puertos').doc(props.id).update({
            usado: 1,
            puerto_de_usuario: nombreCliente,
            cedula_usuario: cedulaCliente
        })
        setOpenConfirmUpdateState(true)
        setOpenUsarPuerto(false)
    }

    const liberarPuerto = () => {
        db.collection('cajas').doc(idCaja).collection('puertos').doc(props.id).update({
            usado: 0,
            puerto_de_usuario: '',
            cedula_usuario: ''
        })
        setOpenConfirmUpdateState(true)
        setOpenLiberarPuerto(false)
    }

    const openDialogDigitarNombrePuertoFunction = () => {
        setOpenUsarPuerto(true)
        setOpenDialogUsarPuerto(false)
    }

    const closeDialogDigitarNombrePuertoFunction = () => {
        setOpenDialogUsarPuerto(true)
        setOpenUsarPuerto(false)
        /* setOpenUsarPuerto(true) */
    }

    return (
        <>
            {
                props.usado === 0 ? <>
                    <Tooltip title="USAR PUERTO">
                        <Button onClick={() => setOpenDialogUsarPuerto(true)/* setOpenUsarPuerto(true) */}>
                            <ToggleOnIcon color="error" />
                        </Button>
                    </Tooltip>
                    <Dialog open={openUsarPuerto} fullWidth onClose={() => setOpenUsarPuerto(false)} maxWidth="xs">

                        <DialogTitle>
                            ESTA SEGURO DE HACER USO DEL PUERTO <strong>{props.puerto}</strong>
                        </DialogTitle>

                        <DialogContent>

                        </DialogContent>

                        <DialogActions>
                            <Button size="small" color="secondary" variant="outlined" onClick={() => closeDialogDigitarNombrePuertoFunction()/* setOpenUsarPuerto(false) */}><strong>REGRESAR</strong></Button>
                            <Button size="small" color="error" variant="contained" onClick={() => usarPuerto()}><strong>CONFIRMAR</strong></Button>
                        </DialogActions>
                    </Dialog>
                </>
                    :

                    <>
                        <Tooltip title="LIBERAR PUERTO">
                            <Button onClick={() => setOpenLiberarPuerto(true)}>
                                <ToggleOnIcon color="success" />
                            </Button>
                        </Tooltip>
                        <Dialog open={openLiberarPuerto} fullWidth onClose={() => setOpenLiberarPuerto(false)} maxWidth="xs">

                            <DialogTitle>
                                ESTA SEGURO DE LIBERAR EL PUERTO <strong>{props.puerto}</strong>
                            </DialogTitle>

                            <DialogContent>

                            </DialogContent>

                            <DialogActions>
                                <Button size="small" color="secondary" variant="outlined" onClick={() => setOpenLiberarPuerto(false)}><strong>Regresar</strong></Button>
                                <Button size="small" color="success" variant="contained" onClick={() => liberarPuerto()}><strong>CONFIRMAR</strong></Button>
                            </DialogActions>
                        </Dialog>
                    </>
            }

            <Dialog open={openConfirmUpdateState} fullWidth onClose={() => setOpenConfirmUpdateState(false)} maxWidth="xs">

                <DialogTitle>
                    PUERTO ACTUALIZADO CON EXITO
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="contained" onClick={() => setOpenConfirmUpdateState(false)}><strong>REGRESAR</strong></Button>
                </DialogActions>
            </Dialog>

            {/* OPEN DIALOG USAR PUERTO */}

            <Dialog open={openDialogUsarPuerto} fullWidth onClose={() => setOpenDialogUsarPuerto(false)} maxWidth="xs">

                <DialogTitle>
                    DIGITE EL NOMBRE Y CEDULA DEL CLIENTE AL CUAL LE ASIGNARA EL PUERTO
                </DialogTitle>

                <DialogContent>

                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE DEL CLIENTE:</strong></p>
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={nombreCliente}
                        onChange={(e) => setNombreCliente(e.target.value)}
                        name="nombreCliente"
                        size="small"
                        fullWidth
                    />

                    <Box pt={2}>
                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CEDULA DEL CLIENTE:</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={cedulaCliente}
                            onChange={(e) => setCedulaCliente(e.target.value)}
                            name="cedulaCliente"
                            size="small"
                            fullWidth
                        />
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="contained" onClick={() => setOpenDialogUsarPuerto(false)}><strong>REGRESAR</strong></Button>
                    <Button disabled={nombreCliente === '' || cedulaCliente === ''} size="small" color="success" variant="contained" onClick={() => openDialogDigitarNombrePuertoFunction()}><strong>ASIGNAR PUERTO</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    );
};