import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import {
    Box, Button, Card, CardContent, Grid,
    LinearProgress, Paper, TextField, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2';
import { db } from '../../../firebase';

export const CatalogoIva = () => {
    const tabla = 'cuentas_pagar/parametros/catalogo_iva'
    const [nombre, setNombre] = useState('')
    const [valor, setValor] = useState('')
    const [codigo, setCodigo] = useState('')
    const ventana = 'Catálogo IVA'

    const [id, setId] = useState('')

    const [categorias, setCategorias] = useState(null)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    useEffect(() => {
        cargarCategorias()
    }, [])


    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )


    const columns = [
        { field: 'codigo', headerClassName: 'super-app-theme--header', headerName: 'Código SRI', flex: 1 },

        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Descripción', flex: 2 },

        { field: 'valor', headerClassName: 'super-app-theme--header', headerName: 'Valor', flex: 1 },

        {
            field: 'EDITAR', headerClassName: 'super-app-theme--header', headerName: 'EDITAR', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}
                >
                    <EditIcon color='info' />
                </IconButton>
            }
        },
        {
            field: 'BORRAR', headerClassName: 'super-app-theme--header', headerName: 'BORRAR', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen2(params.row)}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];

    function cargarCategorias() {
        db.collection(tabla)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setCategorias(arrayMessage)
            })
    }


    function crearSubCategoria() {
        db.collection(tabla).doc().set({
            codigo: codigo,
            nombre: nombre,
            valor: valor,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        if (id !== '') {
            db.collection(tabla).doc(id).update({
                codigo: codigo,
                nombre: nombre,
                valor: valor,
            }).then(() => {
                limpiar()
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha actualizado", });
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
        } else {
            crearSubCategoria()
        }
    }

    function limpiar() {
        setId('')
        setNombre('')
        setValor('')
        setCodigo('')
    }

    function handleOpen(item) {
        if (item) {
            setId(item.id)
            setValor(item.valor)
            setNombre(item.nombre)
            setCodigo(item.codigo)
        }
        setOpen(true)
    }

    function handleOpen2(item) {
        if (item) {
            setId(item.id)
            setValor(item.valor)
            setNombre(item.nombre)
            setCodigo(item.codigo)
        }
        setOpen2(true)
    }

    function deleteProducto() {
        db.collection(tabla).doc(id).delete()
            .then(() => {
                Swal.fire({ icon: "success", text: "Se ha eliminado" })
                setOpen2(false)
                limpiar()
            })
    }


    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>{ventana}</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleOpen()}
                                variant="contained" color='success'>
                                AGREGAR
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px', overflowX: 'scroll' }}>
                                <Box sx={{
                                    minWidth: 500,
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid 
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={categorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={categorias ? categorias : []}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 100}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} {ventana} </strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Código SRI:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={codigo}
                                            onChange={(e) => setCodigo(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Descripción:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Valor:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={valor}
                                            onChange={(e) => setValor(e.target.value)}
                                            size="small"
                                            type='number'
                                            fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                codigo == '' ||
                                                valor == '' ||
                                                nombre === ''
                                            }
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>

            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: {nombre}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
