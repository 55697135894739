import React, { useState, useRef, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Button, Box, Paper
} from '@mui/material';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarExport,
    esES,
} from '@mui/x-data-grid';
import { db } from '../../../firebase';

export const CajasNoUsadasPage = () => {

    const [total, setTotal] = useState(0);

    const [data, setData] = useState()
    const [isLastPage, setIsLastPage] = useState(0)
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [loteData, setLoteData] = useState();
    const [numberLote, setNumberLote] = useState();
    const [lote, setLote] = useState(0);
    useEffect(() => {
        LoadData()
        LoadLotes()
        LoadLote()
    }, [])


    const LoadLote = () => {
        const ref = db.collection('lots').doc(`${lote}`)
            .onSnapshot((doc) => {
                const data = doc.data()
                setNumberLote(data)
            });
    }
    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection('cajas-no-usadas')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];

            setData(data)
        })
    }
    const LoadLotes = () => {
        let ref = db.collection("lots")
        //Si tiene algun doc anterior se agrega acontinuacion
        //if (lote !== 0) ref = ref.where('lote', '==', lote)
        //if (startAfterDoc) ref = ref.startAfter(startAfterDoc)

        ref.onSnapshot(snapshot => {
            const arrayMessage = snapshot.docs.map(item => item.data())
            setLoteData(arrayMessage)

        })
    }
    const handlerLoadMore = () => {
        if (!isLastPage) {
            LoadData(queryDoc, data)
        }
    }

    const columns = [
        { field: 'caja', headerName: 'CAJA', width: 200 },
    ]

    function CustomFooterTotalComponent(props) {
        return `CAJAS TOTALES: ${total}`
    }

    const csvOptions = { delimiter: ';' }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )

    const deleteCajas = async () => {

        const CajaSantaElena = await db.collection("cajas").where("sector", "==", "SANTA ELENA").get()

        const dataCajaSantaElena = CajaSantaElena.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        console.log('dataCajaSantaElena', dataCajaSantaElena)

        dataCajaSantaElena.forEach((obj) => {
            console.log('dataCajaSantaElena', obj.id)
            db.collection('cajas').doc(obj.id).delete()
        })

        const CajaLibertad = await db.collection("cajas").where("sector", "==", "LIBERTAD").get()

        const dataCajaLibertad = CajaLibertad.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        console.log('dataCajaLibertad', dataCajaLibertad)

        dataCajaLibertad.forEach((obj) => {
            console.log('dataCajaLibertad', obj.id)
            db.collection('cajas').doc(obj.id).delete()
        })

        const CajaBallenita = await db.collection("cajas").where("sector", "==", "BALLENITA").get()

        const dataCajaBallenita = CajaBallenita.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        console.log('dataCajaBallenita', dataCajaBallenita)

        dataCajaBallenita.forEach((obj) => {
            console.log('dataCajaBallenita', obj.id)
            db.collection('cajas').doc(obj.id).delete()
        })

    }

    return (

        <>
            <Paper>
                {/* <Button color='secondary' size='small' variant='contained' onClick={() => deleteCajas()}>Eliminar</Button> */}
                <Box sx={{ height: 800, width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        density="compact"
                        pagination={true}
                        // disableColumnSelector
                        // disableDensitySelector
                        //hideFooterPagination
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>

            {/* {data &&
                <>
                    {numberLote && numberLote.lote_number === 0 &&
                        <Typography variant="h4" component="h1">
                            {`Total de licencias ${numberLote.total_licenses}`}
                        </Typography>}
                    {numberLote && numberLote.lote_number !== 0 &&
                        <Typography variant="h4" component="h1">
                            {`${numberLote.license_number} Licencia del Lote #${numberLote.lote_number}`}
                        </Typography>}

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>CAJA</TableCell>
                                    <TableCell width={200}>SPLITTER</TableCell>
                                    <TableCell>NIVEL</TableCell>
                                    <TableCell>MODELO</TableCell>
                                    <TableCell>LATITUD LONGITUD</TableCell>
                                    <TableCell>SECTOR</TableCell>
                                    <TableCell>NODO</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, key) => (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">
                                            {key + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.CAJA}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.SPLITTER}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.NIVEL}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.MODELO}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.LATITUD}/{row.LONGITUD}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.SECTOR}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.NODO}
                                        </TableCell>
                                    </TableRow>))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={handlerLoadMore}
                        hasMore={true}
                        loader={!isLastPage && data.length > 20 && <Loader />}
                    >
                    </InfiniteScroll>
                </>
            } */}

        </>
    )
}