import React, { useState} from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
export const ListGestionPostVentas = () => {
    const location = useLocation();
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'gestion_post_ventas']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon >
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gestion Post Ventas" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton component={RouterLink} to={`gestion_post_ventas/solicitude_de_servicio/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Servicio" />
                        </ListItemButton>
                        <ListItemButton selected={location.pathname === '/ventas/ventas-pre-aprobadas/'} component={RouterLink} to={`ventas/ventas-pre-aprobadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Pre Aprobadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`gestion_post_ventas/agenda/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Agendas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`gestion_post_ventas/cambio_propietario/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Cambio Propietario" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`gestion_post_ventas/cambio_domicilio/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Cambio domicilio" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`gestion_post_ventas/migracion/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Migracion" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/gestion-vendedores/`}>
                            <ListItemIcon>
                                <PersonAddIcon color='warning' />
                            </ListItemIcon>
                            <ListItemText primary="Gestion Televentas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`codigos/codigosOtp/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion de Codigos" />
                        </ListItemButton>

                  
                        <ListItemButton component={RouterLink} to={`ventas/ventas-por-agendar/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Por agendar" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-por-reagendar/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Por Reagendar" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-agendadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Agendadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-reagendadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Reagendadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-realizadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Instaladas" />
                        </ListItemButton>


                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}