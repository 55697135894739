import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
  } from "@mui/material";
  import React, { useContext, useState, useEffect } from "react";
  import moment from "moment";
  import EditIcon from "@mui/icons-material/Edit";
  import { db } from "../../../firebase";
  import Dialog from "@mui/material/Dialog";
  import DialogActions from "@mui/material/DialogActions";
  import DialogContent from "@mui/material/DialogContent";
  import DialogContentText from "@mui/material/DialogContentText";
  import DialogTitle from "@mui/material/DialogTitle";
  import InputLabel from "@mui/material/InputLabel";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select from "@mui/material/Select";
  import CheckIcon from "@mui/icons-material/Check";
  import ClearIcon from "@mui/icons-material/Clear";
  import Swal from "sweetalert2";
  import RoomIcon from "@mui/icons-material/Room";
  import { Link as RouterLink } from "react-router-dom";
  import Tooltip from "@mui/material/Tooltip";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  
  export const ViewRechazadoSale = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [estado, setEstado] = useState(props.estado);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const [age, setAge] = React.useState("");
  
    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
    const siFactible = (id) => {
      //Validaciones
      db.collection("service_request")
        .doc(id)
        .update({
          estado: 2,
        })
        .then((docRef) => {
        
          Swal.fire({
            icon: "success",
            title: "Has confirmado que hay factibilidad.",
          });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    };
  
    const noFactible = (id) => {
      db.collection("service_request")
        .doc(id)
        .update({
          estado: 1,
        })
        .then((docRef) => {
          Swal.fire({
            icon: "success",
            title: "No hay factibilidad",
          });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
  
        /* db.collection("solicitudes")
        .doc(id).collection('rechazadas')
        .add({
              cedula: props.cedula,
              nombres: props.nombres,
              direccion: props.direccion,
              nacionalidad: props.nacionalidad,
              telefono: props.telefono,
              celular: props.celular,
              correo: props.correo,
              coordenadas: props.coordenadas,
              estado: 1,
              created: props.created
        }); */
        
  
    };
  
    const spot = () => {};
  
    return (
      <>
        
  
        <Tooltip title="Visualizar">
          <Button onClick={handleClickOpen}>
          <VisibilityIcon />
          </Button>
        </Tooltip>
   
  
        <Dialog open={open} fullWidth onClose={handleClose} maxWidth="md">
        <DialogTitle>Numero de solicitud {props.uid}, preteneciente a {props.nombres}</DialogTitle>
  
          <DialogContent>

          <Box py={2}>
              <TextField
                fullWidth
                label="Nombres"
                value={props.nombres}
              
              />
            </Box>

            <Box py={2}>
              <TextField
                fullWidth
                label="Cedula"
                value={props.cedula}
              
              />
            </Box>

          

<Box py={2}>
              <TextField
                fullWidth
                label="Direccion"
                value={props.direccion}
              
              />
            </Box>

            <Box py={2}>
              <TextField
                fullWidth
                label="Correo"
                value={props.correo}
              
              />
            </Box>




            <Box py={2}>
              <TextField
                fullWidth
                label="Celular"
                value={props.celular}
     
              />
            </Box>

            <Box py={2}>
              <TextField
                fullWidth
                label="Telefono"
                value={props.telefono}
              
              />
            </Box>

            <Box py={2}>
              <TextField
                fullWidth
                label="Observaciones"
                value={props.observacion}
  
              />
            </Box>

           
            
          </DialogContent>
          
          <DialogActions>
          <h2 style={{color: "red", marginRight: "40px"}}>Rechazado</h2>
            <Button onClick={handleClose} variant="contained">
              {" "}
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  