import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../../../firebase";
export const DeleteItem = ({ props, tabla }) => {
    const [open, setOpen] = useState(null)
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const onData = () => {
        setOpen(true)
        setData(props)
    }
    const deleteProducto = async () => {
        setLoading(true)
        await db.collection(tabla).doc(data.id.toString()).delete()
            .then(() => {
                Swal.fire({ icon: "success", text: "Se ha eliminado la categoria con exito" })
                setOpen(false)
                setLoading(false)
            })
    }
    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: {data.nombre}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                        disabled={loading}
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};