import {
    Radio,
    Tabs,
    Typography,
    Box,
    Tab,
    RadioGroup,
    Button,
    FormLabel,
    Divider,
    Select,
    FormControl,
    FormControlLabel,
    Paper
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { NuevoSector } from './NuevoSector';
import { Sectores } from './Sectores';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const SectorPage = () => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box py={1} px={2}>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Sectores" {...tabs(0)} />
                            <Tab label="Nuevo sector" {...tabs(2)} />
                        </Tabs>
                    </Box>
                </Paper>
            </Box>
            <TabPanel value={value} index={0}>
                <Sectores />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <NuevoSector />
            </TabPanel>
        </>
    )
}