import {
  Box, DialogTitle, Button, Dialog, DialogContent, DialogActions,
  MenuItem, ListItemText, OutlinedInput, Checkbox,
  IconButton, InputLabel, FormControl, Select
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { db } from "../../firebase";

export const UpdateUser = ({ props }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  const [roles, setRoles] = useState([])
  const [rol, setRol] = useState([])
  const onData = () => {
    setOpen(true)
    setData(props)
    console.log(props, ' props')
  }
  useEffect(() => {
    loadRoles()
  }, [])
  const loadRoles = () => {
    db.collection('system/settings/roles').onSnapshot((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return item.id })

      setRoles(arrayMessage)
    })
  }
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  }
  const handleChange = (event) => {
    console.log(event, ' event')
    const {
      target: { value },
    } = event
    setRol(
      typeof value === 'string' ? value.split(',') : value,
    )
  }
  const onupdate = () => {
    db.collection("usuarios").doc(`${props.uid}`).update({
      roles: rol
    }).then(() => {
      setOpen(false)
      alert('Se actulizo los roles')
    })
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => onData()}
        size="small"
      >
        EDITAR
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{data.nombres}</DialogTitle>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">ROLES</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={rol}
              onChange={handleChange}
              input={<OutlinedInput label="ROLES" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {roles.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={rol.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => onupdate()}
          >
            Actualizar Roles
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}