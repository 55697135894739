export const flotasReducers = (state = { flotas: [] }, action) => {
    switch (action.type) {
        case 'FLOTAS_LIST_REQUEST':
            return { loading: true }
        case 'FLOTAS_LIST_SUCCESS':
            return { loading: false, flotas: action.payload }
        case 'FLOTAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}