import {
    Button,
    Typography,
    Dialog,
    DialogTitle,
    Stack,
    CircularProgress,
    Paper,
    Box,
    Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useSelector } from 'react-redux';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SearchInput from '../../../components/utils/SearchInput';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from "../../../firebase";
import { VerImagenes } from "./VerImagenes";


export const ListaNodosVisitados = () => {

    const userAuth = useSelector(state => state.userAuth)

    const [open, setOpen] = useState(false);

    const [data, setData] = useState()
    const [search, setSearch] = useState('')
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    var current = new Date();
    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }
    console.log(startDate, 'date')
    useEffect(() => {
        LoadData()
    }, []);

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("visitas_nodos_realizadas");
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)
            console.log(startDate, 'fecha1')
            console.log(endDate, 'fecha2')
            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                            fecha2: doc.data().fecha && moment(new Date(doc.data().fecha.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
                            hora_entrada2: doc.data().hora_entrada && moment(new Date(doc.data().hora_entrada.seconds * 1000).toISOString()).format('HH:mm:ss'),
                            hora_salida2: doc.data().hora_salida && moment(new Date(doc.data().hora_salida.seconds * 1000).toISOString()).format('HH:mm:ss'),

                        };
                    }),
                ];

                setData(data);
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
                setIsLastPage(totalCount < 1);
            });
        }
    };

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'nombres_apellidos', headerName: 'NOMBRES Y APELLIDOS', width: 270 },
        { field: 'cedula', headerName: 'CEDULA', width: 120 },
        { field: 'tarea_realizada', headerName: 'TAREA REALIZADA', width: 270 },
        { field: 'observacion', headerName: 'OBSERVACION', width: 200 },
        { field: 'firma', headerName: 'FIRMA', width: 150 },
        {
            field: 'fecha', headerName: 'FECHA', type: 'dateTime', width: 100,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        {
            field: 'hora_entrada', headerName: 'HORA ENTRADA', type: 'dateTime', width: 140,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('HH:mm:ss'),
        },
        {
            field: 'hora_salida', headerName: 'HORA SALIDA', type: 'dateTime', width: 120,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('HH:mm:ss'),
        },
        {
            field: '1', headerName: 'VER IMAGENES', width: 150,
            renderCell: (params) => {
                return <VerImagenes props={params.row} />
            }
        },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter/> */}
        </GridToolbarContainer>
    );

    const exporter = React.createRef(null);
    const [open2, setOpen2] = useState(false);

    const handleExport = () => {
        if (exporter.current) {
            setOpen2(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setOpen2(false)
    };


    return (
        <>
            <Box pt={1} pl={1}>
                <Typography >
                    <strong>VISITAS DE NODOS REALIZADAS</strong>
                </Typography >
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} pt={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Box display="flex" alignItems='center' >
                        <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <Button onClick={handleExport} size="small" color="success"
                        variant="outlined" startIcon={<SimCardDownloadIcon />}
                    >
                        {" "}
                        EXCEL
                    </Button>
                    <DataGrid
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        // disableColumnFilter
                        // disableColumnSelector
                        // disableDensitySelector
                        //hideFooterPagination
                        density="compact"
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {
                            console.log(state.pagination.rowCount);
                            setTotal(state.pagination.rowCount)
                        }}
                    // componentsProps={{
                    //   toolbar: {
                    //     showQuickFilter: true,
                    //     quickFilterProps: { debounceMs: 500 },
                    //   },
                    // }}
                    />
                </Box>
            </Paper>

            <ExcelExport onExportComplete={handleExport2} data={data ? data : []} fileName={"TicketsCerrado.xlsx"} ref={exporter} filterable={true} >
                <ExcelExportColumn field="nombres_apellidos" title="NOMBRES Y APELLIDOS" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="cedula" title="CEDULA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="tarea_realizada" title="TAREA REALIZADA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="observacion" title="OBSERVACION" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="firma" title="FIRMA" headerCellOptions={{ background: "#672780" }} width={150} />

                <ExcelExportColumn field="fecha2" title="FECHA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="hora_entrada2" title="HORA ENTRADA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="hora_salida2" title="HORA SALIDA" headerCellOptions={{ background: "#672780" }} width={150} />
            </ExcelExport>

            <Dialog open={open2} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>DESCARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    );
};