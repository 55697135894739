import React, { useState, useEffect } from "react";
import {
    Dialog, DialogContent, Box, Button, DialogActions,
    Table, TableHead, TableRow, DialogTitle, Paper, TextField, TableContainer, TableBody, TableCell
} from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../firebase'
import { SaveItem } from "../inventario/recepcionMercaderia/SaveItem";
const AprobarButton = ({ props }) => {
    const [observacionExtra, setObservacionExtra] = useState('');
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [motivoRechazo, setMotivoRechazo] = useState('');
    const [items, setItems] = useState([]);

    const [numPedido, setNumPedido] = useState('')

    const loadNumPedido = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    useEffect(() => {
        loadNumPedido()
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
        console.log(props, 'props')
    }
    const loadItems = () => {
        var docRef = db.collection("solicitud_pedido").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            data.forEach(async (obj, index) => {

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', obj.bodega).get()

                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                console.log('data2[0]', data2[0])

                try {// Obtiene los resultados de la consulta

                    if (!data2[0].empty) {

                        const list = [...data];

                        list[index]['stock_actual'] = data2[0].stock_actual;

                        console.log('Stock obtenido correctamente:', data2[0].stock_actual);
                        console.log('Objeto "items" actualizado:', items);
                    } else {
                        console.log('No se encontraron resultados para el código');
                    }
                } catch (error) {
                    console.error('Error al obtener el stock del código:', error);
                }
            })

            setTimeout(() => {
                console.log('items', data)
                setItems(data);
            }, 500);

        });
    }
    const onSubmit = () => {

        /* const uidSolicitud = `EGR-` + numPedido;

        db.collection('egreso_productos').doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: props.departamento_seleccionado,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega,
            ubicacion: '',
            motivo: props.motivo.toUpperCase(),
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'SOLICITUD PEDIDO'
        })

        db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: props.departamento_seleccionado,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega,
            ubicacion: '',
            motivo: props.motivo.toUpperCase(),
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'SOLICITUD PEDIDO'
        })

        items.forEach(async function (obj, index) {

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', props.bodega).get()

            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const costoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_pedida)

            const saldo_valorizado = parseFloat(data2[0].costo_promedio).toFixed(2) * parseFloat(obj.cantidad_pedida).toFixed(2)

            const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            const cantidad_total = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_pedida)

            const totalRestaSaldoTotal = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_pedida)

            console.log('PRECIO COMPRA', data2[0].precio_compra)

            console.log(`COSTO PROMEDIO ${costoPromedio.toFixed(2)}`)

            console.log(`TOTAL EGRESO ${totalEgreso.toFixed(2)}`)

            console.log(`TOTAL OPERACION ${total_operacion.toFixed(2)}`)

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            const total_bodegas = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseFloat(obj.cantidad_pedida).toFixed(2)

            console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

            const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

            console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS')

            db.collection("reporte_stock").doc(data2[0].id).update({
                stock_actual: parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cantidad_pedida).toFixed(2),
                total_valorizado: total_operacion.toFixed(2),
                costo_promedio: costoPromedio.toFixed(2)
            })

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            db.collection("productos").doc(data4[0].id).update({
                cantidad_producto: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)
            })

            db.collection(`egreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: '',
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: '',
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: props.bodega,
                ubicacion: '',
                motivo: props.motivo.toUpperCase(),
                tipo_movimiento: 'egreso',
                total_bodegas: total_bodegas.toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2)
            })

            const kardexGeneralSumaTotalStock = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)

            const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

            console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

            console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

            const dataRef = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: '',
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: '',
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: props.bodega,
                ubicacion: '',
                motivo: props.motivo.toUpperCase(),
                tipo_movimiento: 'egreso',
                total_bodegas: total_bodegas.toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2)
            }

            const dataRef2 = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || kardexGeneralSumaTotalStock === NaN ? 0 : kardexGeneralSumaTotalStock,
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: '',
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: '',
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: kardexGeneralSumaTotal === 'NaN' || kardexGeneralSumaTotal === NaN ? 0 : Math.abs(kardexGeneralSumaTotal),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: props.bodega,
                ubicacion: '',
                motivo: props.motivo.toUpperCase(),
                tipo_movimiento: 'egreso',
                total_bodegas: total_bodegas.toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2)
            }

            db.collection(`total_movimientos`).doc().set(dataRef)

            db.collection(`total_movimientos_general`).doc().set(dataRef2)

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)

            Swal.fire({ icon: "success", text: `Se ha realizado el egreso de productos con exito` })
        }) */

        setLoading(true)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 0,
            egreso: firebase.firestore.FieldValue.increment(1),
            user_entrego: userInfo.displayName,
            fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
            observacion_extra: observacionExtra
        }).then((docRef) => {
            Swal.fire({ icon: "success", text: 'Se aprobo su solicitud de pedido' });
            setLoading(false)
        })
    }
    //
    const onRechazar = () => {
        setLoading(true)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 4,
            motivo_rechazo: motivoRechazo,
            user_rechazo: userInfo.displayName,
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
            observacion_extra: observacionExtra
        }).then((docRef) => {
            Swal.fire({ icon: "info", text: 'Se rechazo su solicitud de pedido' });
            setLoading(false)
        })
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 100 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 500 },
        { field: 'cantidad', headerName: 'CANTIDAD SOLICITADA', width: 200 },

    ];

    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
            >
                <DialogTitle>
                    CANTIDAD A ENTREGAR3: {props.id}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 550, width: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CODIGO</TableCell>
                                        <TableCell align="center">DESCRIPCION</TableCell>
                                        <TableCell align="center">STOCK ACTUAL</TableCell>
                                        <TableCell align="center">CANTIDAD SOLICITADA</TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        items && items.map((item, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{item.codigo}</TableCell>
                                                <TableCell align="center">{item.descripcion}</TableCell>
                                                <TableCell align="center">{item.stock_actual}</TableCell>
                                                <TableCell align="center">{item.cantidad}</TableCell>


                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        /> */}
                    </Box>



                </DialogContent>
                <Box p={2}>
                    <TextField
                        label='OBSERVACION'
                        fullWidth
                        value={observacionExtra}
                        onChange={(e) => setObservacionExtra(e.target.value)}
                    />
                </Box>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        setOpen1(true)
                    }} color='error' variant="contained" disabled={loading} >RECHAZAR SOLICITUD</Button>
                    <Button onClick={onSubmit} color='success' variant="contained" disabled={loading} >APROBAR SOLICITUD</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth

                open={open1}
            >
                <DialogTitle>
                    ESCRIBIR EL RECHAZO DE: {props.id}
                </DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <TextField
                            label='RECHAZO DE LA ENTREGA'
                            fullWidth
                            multiline
                            onChange={(e) => setMotivoRechazo(e.target.value)}
                            rows={4}
                        />
                    </Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setOpen1(false)}>CERRAR VENTANA</Button>
                    <Button onClick={onRechazar} color='error' variant="contained" disabled={loading || !motivoRechazo} >RECHAZAR ENTREGA</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AprobarButton