import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, CardContent, Card, Grid, Typography, Paper, Box, LinearProgress } from '@mui/material';
import {
    DataGrid, esES, GridToolbarContainer, GridToolbarColumnsButton,
    GridToolbarFilterButton, GridToolbarDensitySelector
} from "@mui/x-data-grid";
export const LogZimbra = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://dataguardian.intercommerce.com.ec/funcion/logZimbra/')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error:', error));
    }, []);

    function createExcelFile() {
        // Crear un libro de Excel
        const workbook = XLSX.utils.book_new();
        // Crear una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Agregar la hoja de cálculo al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mi Hoja de Cálculo');
        // Guardar el libro en un archivo
        XLSX.writeFile(workbook, 'mi_archivo.xlsx');
        console.log('Archivo de Excel creado con éxito.');
    }
    
    const columns = [
        { field: 'Fecha', headerClassName: 'super-app-theme--header', headerName: 'Fecha', flex: 1 },
        { field: 'Identificador', headerClassName: 'super-app-theme--header', headerName: 'Identificador', flex: 1 },
        { field: 'From', headerClassName: 'super-app-theme--header', headerName: 'From', flex: 1 },
        { field: 'To', headerClassName: 'super-app-theme--header', headerName: 'To', flex: 1 },
        {
            field: 'Status_Aditional', headerClassName:
                'super-app-theme--header', headerName: 'Estado Adicional', flex: 3
        },
    ];
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Button
                onClick={() => createExcelFile()}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                color="success" size='small' variant="outlined"
                startIcon={<UploadFileIcon />}>
                <strong>DESCARGAR EXCEL</strong>
            </Button>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>Logs Zimbra</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px' }}>
                                <Box sx={{
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={data === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={data ?? []}
                                        getRowId={(row) => data.indexOf(row)}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
