import React, { useState, useEffect } from "react";
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    InputLabel,
    Tooltip,
    CircularProgress
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import 'moment/locale/es'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoveDownIcon from '@mui/icons-material/MoveDown';

export const IconsMovimientos = ({ props }) => {

    return (
        <>
            {
                props.motivo_movimiento === 'INGRESO' ? <AddCircleOutlineIcon fontSize="small" color="success" /> : ''
            }
            {
                props.motivo_movimiento === 'EGRESO' ? <RemoveCircleOutlineIcon fontSize="small" color="error" /> : ''
            }
            {
                props.motivo_movimiento === 'TRANSFERENCIA' ? <MoveDownIcon fontSize="small" color="secondary" /> : ''
            }
        </>
    );
};