import React, { useState, useEffect } from 'react';
import { db } from '../../../../firebase';


export const SerialPDF = (props) => {
    const [serial, setSerial] = useState('')


    useEffect(() => {
        onData()
    }, [])

    const onData = async () => {
        let seriesTotales = '';
        let docRef = db.collection("reporte_movimientos").doc(props.id).collection('items').doc(props.fullName).collection('series')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            arrayMessage.map((vari) => {
                let numero = vari.serie + '';
                seriesTotales = `${numero} ${seriesTotales}`;
            })
            
            if (seriesTotales != '') {
                setSerial(seriesTotales)
            } else {
                // setSerial('No asignado')
                let docRef2 = db.collection("reporte_movimientos").doc(props.id).collection('items').doc(props.fullName)
                docRef2.get().then((doc) => {
                    let series = doc.data().series ? doc.data().series : []
                    series.map((vari) => {
                        let numero = vari.serie + '';
                        if(vari.serie!=0){
                            seriesTotales = `${numero} ${seriesTotales}`;
                        }
                    })
                    if (seriesTotales != '') {
                        setSerial(seriesTotales)
                    } else{
                        setSerial('No asignado')
                    }
                })

            }

        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }



    return (
        <td>{serial}</td>
    )
}
