import {
    Box,
    FormControl,
    MenuItem,
    Select,
    Paper,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
    InputLabel
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
export const PoolIpv4 = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [ipPool, setIpPool] = useState('');
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData();
    }, [startDate]);
    //
    const LoadData = (startAfterDoc, persistMessages = []) => {
        setLoading(true)
        //
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ipPool": "45.236.150.0/24"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("http://192.168.80.242:3000/listIpPoolIpv4", requestOptions)
            .then(response => response.text())
            .then(result => {
                var re = JSON.parse(result)
                console.log(re, 'red')
                // const data = [
                //     ...re.map((doc) => {
                //       return {
                //         ...doc.data(),
                //         id: doc.id,
                //       };
                //     }),
                // ]
                // console.log(data, 'data 5')
                // setLoading(false)
                setData(re)
            })
            .catch(error => {
                setLoading(false)

            });
    };
    return (
        <>
            <FormControl size="small">
                <InputLabel>IpPool</InputLabel>
                <Select
                    value={ipPool}
                    label="IpPool"
                    onChange={(e) => setIpPool()}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <Table size="small">
                        <TableHead>
                            <TableCell>IPV4</TableCell>
                        </TableHead>
                        <TableBody>
                            {data && data.slice(0, 10).map((row) => (
                                <TableRow>
                                    <TableCell >{row}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Paper>
        </>
    );
};
