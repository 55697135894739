export const flotasLidersReducers = (state = { liders: [] }, action) => {
    switch (action.type) {
        case 'LIDER_LIST_REQUEST':
            return { loading1: true }
        case 'LIDER_LIST_SUCCESS':
            return { loading1: false, liders: action.payload }
        case 'LIDER_LIST_FAIL':
            return { loading1: false, error: action.payload }
        default:
            return state
    }
}