import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    FormHelperText,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material'
import React, { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { db, firebase } from '../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import { ConvertRoles } from '../../components/config/ConvertRoles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { Grade } from '@mui/icons-material';

import * as XLSX from "xlsx/xlsx.mjs";
import UploadIcon from "@mui/icons-material/Upload";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import {
    listsBodegas,
    listsUbicaciones,
    listsMotivoIngresoProductos,
} from "../../redux/actions/parametersActions";

import {
    listFlotas
} from "../../redux/actions/flotas/flotasActions";

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import EditIcon from "@mui/icons-material/Edit";

export const TransferenciaMateriales = ({ props }) => {

    const flotasList = useSelector(state => state.flotasList)
    const { flotas, loadingFlotas } = flotasList
    const [flota, setFlota] = useState('')

    const [numFlota, setNumFlota] = useState('')

    const handleFlota = (e) => {
        setFlota(e)
        const p = flotas.filter((item) => item.numeroFlota === e);
        const data = p[0]
        setNumFlota(data.numeroFlota)
        setBodegaDestino(data.numeroFlota)
    }

    const [observacionAdicional, setObservacionAdicional] = useState("");

    const bodegasList = useSelector((state) => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList;
    const [bodega, setBodegas] = useState("");
    const [bodegaDestino, setBodegaDestino] = useState("");



    let navigate = useNavigate()
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [fecha, setFecha] = useState({ campo: new Date, valido: true })
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState({ campo: '', valido: true })
    const [tipo_material, setTipoMaterial] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [numPedidoEgreso, setNumPedidoEgreso] = useState(false)
    const [numPedidoIngreso, setNumPedidoIngreso] = useState(false)
    const [productos, setProductos] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    //
    const [modal, setModal] = useState(false)
    //
    //
    const [inputFields, setInputFields] = useState([
    ]);

    //OPCIONES DE CARGA DE SERIES

    const [openDialogSelectsOptionUploadSeries, setOpenDialogSelectsOptionUploadSeries] = useState(false)

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false)

    /* MODAL CARGA SERIES MEDIANTE EXCEL */

    const [openUploadExcelSeries, setOpenUploadExcelSeries] = useState(false)

    const handleOpenDialogUploadExcel = () => {
        setOpenUploadExcelSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadExcel = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadExcelSeries(false)
    }

    const [openDialogExcelSuccess, setOpenDialogExcelSuccess] = useState(false)

    /* MODAL CARGA SERIES MANUALES */

    const [openUploadManualSeries, setOpenUploadManualSeries] = useState(false)

    const handleOpenDialogUploadManual = () => {
        setOpenUploadManualSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadManual = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadManualSeries(false)
    }

    /* MODAL CARGA SERIES AUTOMATICAS */

    const [openUploadAutomaticasSeries, setOpenUploadAutomaticasSeries] = useState(false)

    const handleOpenDialogUploadAutomaticas = () => {
        setOpenUploadAutomaticasSeries(true)
        setOpenDialogSelectsOptionUploadSeries(false)
    }

    const handleCloseDialogUploadAutomaticas = () => {
        setOpenDialogSelectsOptionUploadSeries(true)
        setOpenUploadAutomaticasSeries(false)
    }

    //
    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }
    const addInput = async (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else if (parseFloat(row.stock_actual) <= 0) {
            Swal.fire({ icon: "info", text: 'No existe stock disponible', });
        } else {
            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", row.codigo_producto).get();

            const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 0,
                descripcion: row.descripcion,
                codigo: row.codigo_producto,
                categoria_producto: row.categoria,
                subcategoria_producto: data4[0].subcategoria_producto,
                marca_producto: data4[0].marca_producto,
                modelo_producto: data4[0].modelo_producto,
                presentacion_producto: data4[0].presentacion_producto,
                unidad_Medida_Producto: data4[0].unidad_Medida_Producto,
                series: [{ serie: 0 }],
            }])
        }
        setItem('')
        setModal(false)
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = async (index, evnt) => {
        console.log(inputFields)
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                //
                setInputFields(list);
                //Cantidad
                //console.log(evnt.target.value, 'cantidad')
                const num = evnt.target.value;
                let array = [];
                for (var i = 0; i < num; i++) {
                    //console.log('Hola')
                    let n;
                    n += i;
                    //console.log('for', n)
                    array.push({
                        index_serie: index,
                        id: i,
                        serie: 0,
                    });
                }
                list[index]["series"] = array;
                //
                console.log(list, "--");
                //console.log(inputFields, 'array')
            } else {
                const list = [...inputFields];
                list[index]["fullName"] = evnt;
                setInputFields(list);
            }
        }
    };

    const getProductos = (e) => {
        let ref = db.collection("reporte_stock").where('bodega', '==', e)

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setProductos(data)
        })
        /* var docRef = db.collection("reporte_stock").where('bodega', '==', bodega)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
    }

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        dispatch(listsBodegas());
        dispatch(listFlotas());
    }, []);

    const handleSeries = async (index, evnt, row) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                let index_serie = row.series[0].index_serie;
                let array = {
                    id: index,
                    index_serie: index_serie,
                    serie: value.toUpperCase(),
                };
                list[index_serie]["series"][index] = array;
            } else {
                const list = [...inputFields];
                list[index]["fullName"] = evnt;
                setInputFields(list);
            }
        }
        //console.log('input files', inputFields)
    };

    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            var docRef = db.collection("productos").where("codigoProducto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcionProducto
                    console.log(data.codigo)
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
            console.log(inputFields, 'da')
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>

                <TableCell align="center">
                    {data.fullName}
                </TableCell>

                <TableCell align="center">{data.descripcion}</TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.categoria_producto}
                </TableCell>

                <TableCell style={{ fontSize: "12px" }} align="center">
                    {data.subcategoria_producto}
                </TableCell>

                <TableCell align="center">
                    <TextField
                        name={"cantidad_pedida"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>

                <TableCell align="center">
                    {/* <CargaSeriesIngresoProductos props={data} bodega={bodega} codigo={data.codigo} /> */}
                    {/* {bodega === "" ? (
                        <Tooltip title="SELECCIONE UNA BODEGA">
                            <Button disabled>
                                <QrCodeScannerIcon
                                    onClick={() => {
                                        console.log(data.cdogio);
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <CargaSeriesIngresoProductos
                            props={data}
                            bodega={bodega}
                            codigo={data.codigo}
                            numPedido={numPedido}
                        />
                    )} */}

                    <UploadSeriesFunction id={props.id} props={data} codigo={data.codigo} descripcionItem={data.descripcion} />

                </TableCell>

                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    //
    useEffect(() => {
        /* getProductos() */
        console.log(productos, 'productos')
    }, []);
    //
    const loadNumPedido = () => {
        const ref = db.collection("transferencias").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `SO-TRAN-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const loadNumPedidoEgreso = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                setNumPedidoEgreso(numeroSolicitud)
            }
        })
    }

    const loadNumPedidoIngreso = () => {
        const ref = db.collection("ingreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                setNumPedidoIngreso(numeroSolicitud)
            }
        })
    }

    const handleSubmitTransferencia = () => {
        //
        const uidSolicitud = `SO-TRAN-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }
        console.log(data, 'DATA')
        console.log(inputFields, 'INPUT FIELDS')

        console.log(bodegaDestino, 'BODEGA DESTINO')
        //

        db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 3,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega_origen: bodega,
            bodega_destino: props.numFlota,
            ubicacion: '',
            motivo_movimiento: 'TRANSFERENCIA',
            motivo: 'TRANSFERENCIA A FLOTAS',
            observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'transferencia'
        })

        inputFields.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", props.numFlota).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", bodega).get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            db.collection('transferencias').doc(uidSolicitud).set({
                subcategoria_producto: obj.subcategoria_producto,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega_origen: bodega,
                bodega_destino: props.numFlota,
                numeroSolicitud: numPedido,
                uidSolicitud: uidSolicitud,
                fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_transfiere: userInfo.displayName,
                estado: 0,
                motivo: 'TRANSFERENCIA A FLOTAS',
                observacion: observacionAdicional === '' ? 'SIN OBSERVACIONES' : observacionAdicional
            })

            db.collection('transferencias').doc(uidSolicitud).collection('items').doc(obj.codigo).set({
                subcategoria_producto: obj.subcategoria_producto,
                codigo: obj.codigo,
                bodega_origen: bodega,
                bodega_destino: props.numFlota,
                numeroSolicitud: numPedido,
                uidSolicitud: uidSolicitud,
                fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_transfiere: userInfo.displayName,
                descripcion: obj.descripcion,
                cantidad_transferencia: parseInt(obj.cantidad_pedida),
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto
            })

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set({
                fullName: obj.codigo,
                categoria_producto: obj.categoria_producto,
                descripcion: obj.descripcion,
                costo_promedio: data4[0].costo_promedio,
                precio_compra: data4[0].precio_compra,
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                bodega_origen: bodega,
                bodega_destino: props.numFlota,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })

            /* Swal.fire({ icon: "success", text: 'Se creo su solicitud de transferencia' }); */

        });
    }

    var totalBodegasOrigenDestino;

    const handleSubmitEgreso = () => {

        const uidSolicitud = `EGR-` + numPedidoEgreso;

        console.log('uidSolicitud', uidSolicitud)

        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedidoEgreso,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: bodega,
            ubicacion: '',
            motivo: 'TRANSFERENCIA A FLOTAS',
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso'
        }
        console.log(data, 'DATA')
        /* console.log(items, 'INPUT FIELDS') */

        //SE INGRESAN LOS PRODUCTOS A 'EGRESO DE PRODUCTOS'

        db.collection('egreso_productos').doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: bodega,
            ubicacion: '',
            motivo: 'TRANSFERENCIA A FLOTAS',
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso',
        })

        db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: bodega,
            ubicacion: '',
            motivo: 'TRANSFERENCIA A FLOTAS',
            motivo_movimiento: 'EGRESO',
            observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso'
        })

        //
        /* const uidSolicitud = `SO-TRAN-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }
        console.log(data, 'DATA')
        console.log(inputFields, 'INPUT FIELDS')

        console.log(bodegaDestino, 'BODEGA DESTINO') */
        //

        inputFields.forEach(async function (obj, index) {

            /* console.log(obj, 'OBJ') */

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB5 = await db.collection("flotas").where("numeroFlota", "==", props.numFlota).get()

            const data5 = await agendaDB5.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB6 = await db.collection("flotas").doc(data5[0].id).collection('items').where('codigo_producto', '==', obj.codigo.toString()).get()

            const data6 = await agendaDB6.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.codigo).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_pedida)

            /* const DivisionTotalStockActual = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual) */

            /* const costoPromedio = parseFloat(DivisionTotalStockActual) * parseFloat(obj.cantidad_pedida) */

            const saldo_valorizado = parseFloat(data2[0].costo_promedio).toFixed(2) * parseFloat(obj.cantidad_pedida).toFixed(2)

            /* const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(saldo_valorizado) */

            const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            totalBodegasOrigenDestino = total_operacion.toFixed(2)

            const cantidad_total = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_pedida)

            const totalRestaSaldoTotal = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_pedida)

            /* console.log(`DIVISION TOTAL Y STOCK VALORIZADO ${DivisionTotalStockActual.toFixed(2)}`) */

            console.log('PRECIO COMPRA', data2[0].precio_compra)

            console.log(`COSTO PROMEDIO ${costoPromedio.toFixed(2)}`)

            console.log(`TOTAL EGRESO ${totalEgreso.toFixed(2)}`)

            /* console.log(`SALDO VALORIZADO ${saldo_valorizado.toFixed(2)}`) */

            console.log(`TOTAL OPERACION ${total_operacion.toFixed(2)}`)

            /* CALCULA EL TOTAL DE LAS BODEGAS */

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            /* FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS */

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            /* FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS */

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            /* TOTAL STOCK ACTUAL + CANTIDAD DE LA OPERACION (EG8RESO) */

            const total_bodegas = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseFloat(obj.cantidad_pedida).toFixed(2)

            console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

            /* TOTAL VALORIZADO + TOTAL DE LA OPERACION (EG8RESO)*/

            const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

            /* console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS') */

            console.log(parseFloat(sumaTotalBodegas).toFixed(2), 'SUMA TOTAL BODEGAS')

            db.collection("reporte_stock").doc(data2[0].id).update({
                stock_actual: parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cantidad_pedida).toFixed(2),
                total_valorizado: total_operacion.toFixed(2),
                costo_promedio: costoPromedio.toFixed(2)
            })

            /* OBTENEMOS EL ID DE LA COLECCION PRODUCTOS */

            db.collection("productos").doc(data4[0].id).update({
                cantidad_producto: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)
            })

            db.collection(`egreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: bodega,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: 'egreso',
                total_bodegas: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
            })

            /* INGRESO DATOS A REPORTE DE MOVIMIENTOS Y KARDEX */

            const kardexGeneralSumaTotalStock = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)

            const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

            console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

            console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

            const dataRef = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: bodega,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: 'egreso',
                total_bodegas: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
            }

            const dataRef2 = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) ? 0 : kardexGeneralSumaTotalStock,
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: kardexGeneralSumaTotal === 'NaN' || isNaN(kardexGeneralSumaTotal) ? 0 : Math.abs(kardexGeneralSumaTotal),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: bodega,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: 'egreso',
                total_bodegas: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
            }

            db.collection(`total_movimientos`).doc().set(dataRef)

            // db.collection(`total_movimientos_general`).doc().set(dataRef2)

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)

            db.collection('solicitudes_materiales_instalaciones').doc(props.id).update({
                estado: 2,
            })

            db.collection('tecnicos').doc(props.id).update({
                estado: 0,
                bodega_origen: bodega
            })

            db.collection('tecnicos').doc(props.id).collection('materiales_instalacion').doc(obj.codigo).set({
                bodega: data2[0].bodega === undefined ? '' : data2[0].bodega,
                categoria: data2[0].categoria === undefined ? '' : data2[0].categoria,
                codigo_producto: data2[0].codigo_producto === undefined ? '' : data2[0].codigo_producto,
                costo_promedio: data2[0].costo_promedio === undefined ? '' : data2[0].costo_promedio,
                descripcion: data2[0].descripcion === undefined ? '' : data2[0].descripcion,
                motivo: data2[0].motivo === undefined ? '' : data2[0].motivo,
                precio_compra: data2[0].precio_compra === undefined ? '' : data2[0].precio_compra,
                stock_actual: parseFloat(obj.cantidad_pedida),
                tipo_movimiento: data2[0].tipo_movimiento === undefined ? '' : data2[0].tipo_movimiento,
                total_valorizado: data2[0].total_valorizado === undefined ? '' : data2[0].total_valorizado,
                unidad_medida: data2[0].unidad_medida === undefined ? '' : data2[0].unidad_medida,
                usuario_que_subio_carga_producto: data2[0].usuario_que_subio_carga_producto === undefined ? '' : data2[0].usuario_que_subio_carga_producto
            })

            /* if (data6[0] === undefined) {
                console.log('cantidad_pedida', parseFloat(obj.cantidad_pedida))
                db.collection(`flotas`).doc(data5[0].id).collection('items').doc(obj.codigo).set({
                    bodega: data2[0].bodega === undefined ? '' : data2[0].bodega,
                    categoria: data2[0].categoria === undefined ? '' : data2[0].categoria,
                    codigo_producto: data2[0].codigo_producto === undefined ? '' : data2[0].codigo_producto,
                    costo_promedio: data2[0].costo_promedio === undefined ? '' : data2[0].costo_promedio,
                    descripcion: data2[0].descripcion === undefined ? '' : data2[0].descripcion,
                    motivo: data2[0].motivo === undefined ? '' : data2[0].motivo,
                    precio_compra: data2[0].precio_compra === undefined ? '' : data2[0].precio_compra,
                    stock_actual: parseFloat(obj.cantidad_pedida),
                    tipo_movimiento: data2[0].tipo_movimiento === undefined ? '' : data2[0].tipo_movimiento,
                    total_valorizado: data2[0].total_valorizado === undefined ? '' : data2[0].total_valorizado,
                    unidad_medida: data2[0].unidad_medida === undefined ? '' : data2[0].unidad_medida,
                    usuario_que_subio_carga_producto: data2[0].usuario_que_subio_carga_producto === undefined ? '' : data2[0].usuario_que_subio_carga_producto
                })

            } else {
                console.log('cantidad_pedida', parseFloat(obj.cantidad_pedida))
                db.collection(`flotas`).doc(data5[0].id).collection('items').doc(obj.codigo).update({
                    bodega: data2[0].bodega === undefined ? '' : data2[0].bodega,
                    categoria: data2[0].categoria === undefined ? '' : data2[0].categoria,
                    codigo_producto: data2[0].codigo_producto === undefined ? '' : data2[0].codigo_producto,
                    costo_promedio: data2[0].costo_promedio === undefined ? '' : data2[0].costo_promedio,
                    descripcion: data2[0].descripcion === undefined ? '' : data2[0].descripcion,
                    motivo: data2[0].motivo === undefined ? '' : data2[0].motivo,
                    precio_compra: data2[0].precio_compra === undefined ? '' : data2[0].precio_compra,
                    stock_actual: parseFloat(data6[0].stock_actual) + parseFloat(obj.cantidad_pedida),
                    tipo_movimiento: data2[0].tipo_movimiento === undefined ? '' : data2[0].tipo_movimiento,
                    total_valorizado: data2[0].total_valorizado === undefined ? '' : data2[0].total_valorizado,
                    unidad_medida: data2[0].unidad_medida === undefined ? '' : data2[0].unidad_medida,
                    usuario_que_subio_carga_producto: data2[0].usuario_que_subio_carga_producto === undefined ? '' : data2[0].usuario_que_subio_carga_producto
                })
            } */

            /* setModalConfirm(false) */
            /* setOpenConfirm(false)

            Swal.fire({ icon: "success", text: `Se ha realizado el egreso de productos con exito` }) */

        });
    }

    const handleSubmitIngreso = () => {
        //
        const uidSolicitud = `ING-` + numPedidoIngreso;

        console.log(`UidSolicitud ${uidSolicitud}`)

        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedidoIngreso,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.numFlota,
            ubicacion: '',
            motivo: 'INGRESO TRANSFERENCIA FLOTA',
            motivo_movimiento: 'INGRESO',
            observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: "ingreso",
            precio_compra: 0,
        };

        db.collection("ingreso_productos").doc(uidSolicitud).set(data)

        db.collection("reporte_movimientos").doc(uidSolicitud).set(data)

        inputFields.forEach(async function (obj, index) {

            /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', bodegaDest).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() })); */

            const agendaDB5 = await db.collection("flotas").where("numeroFlota", "==", props.numFlota).get()

            const data5 = await agendaDB5.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB6 = await db.collection("flotas").doc(data5[0].id).collection('items').where('codigo_producto', '==', obj.codigo.toString()).get()

            const data6 = await agendaDB6.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.codigo).get();

            const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const operacionCostoPromedio = parseFloat(data5[0].total_valorizado) / parseFloat(data5[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_pedida)

            const saldo_valorizado =
                parseFloat(obj.cantidad_pedida) *
                parseFloat(data6[0].costo_promedio);

            const Total =
                parseFloat(data6[0].total_valorizado) +
                parseFloat(saldo_valorizado);

            /* const cantidad_total =
                parseFloat(data6[0].stock_actual) + parseFloat(obj.cantidad_pedida); */

            const cantidad_total = parseFloat(data6[0].stock_actual);

            console.log('SALDO VALORIZADO', saldo_valorizado)
            console.log('TOTAL', Total)
            console.log('CANTIDAD TOTAL', cantidad_total)

            console.log('data5[0].id', data5[0].id);

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).get();

            const data3 = agendaDB3.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            const total_bodegas = parseFloat(data4[0].cantidad_producto).toFixed(2) - Math.abs(parseFloat(obj.cantidad_pedida).toFixed(2))

            console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

            const sumaTotalBodegas =
                parseFloat(sumaValorTotalValorizadoBodegas) + parseFloat(Total);

            console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS')

            console.log(data5[0].id, 'DATA 2 ID')

            console.log(parseFloat(parseFloat(data5[0].stock_actual).toFixed(2) + parseFloat(obj.cantidad_pedida).toFixed(2)).toFixed(0), 'SUMA')

            db.collection("flotas").doc(data5[0].id).collection('items').doc(obj.codigo.toString()).update({
                /* stock_actual: parseFloat(data6[0].stock_actual) + parseFloat(obj.cantidad_pedida), */
                total_valorizado: Total.toFixed(2),
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data6[0].costo_promedio
            })

            /* Quitar */

            /* db.collection("productos").doc(data4[0].id).update({
                cantidad_producto: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
            }); */

            db.collection(`ingreso_productos/${uidSolicitud}/items`).doc(obj.codigo.toString()).set({
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data6[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data6[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                observacion_adicional: observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: props.numFlota,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data6[0].costo_promedio,
            })

            const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_pedida).toFixed(2)

            const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

            console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

            console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

            const dataRef = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data6[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data6[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                oobservacion_adicional: observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: props.numFlota,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),/* Math.abs(total_bodegas.toFixed(2)), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data6[0].costo_promedio,
            }

            const dataRef2 = {
                cantidad_solicitada: parseInt(obj.cantidad_pedida),
                cantidad_total_antes: data6[0].stock_actual,
                cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) ? 0 : kardexGeneralSumaTotalStock,
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: userInfo.displayName,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data6[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                oobservacion_adicional: observacionAdicional.toUpperCase(),
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: bodega,
                ubicacion: '',
                motivo: 'TRANSFERENCIA A FLOTAS',
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida),/* Math.abs(total_bodegas.toFixed(2)), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data6[0].costo_promedio,
            }

            db.collection(`total_movimientos`).doc().set(dataRef)

            // db.collection(`total_movimientos_general`).doc().set(dataRef2)

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)
        });

    }

    const [openDialogTransferencia, setOpenDialogTransferencia] = useState(false);

    const handleSubmit = () => {
        setLoadingButtonHandleSubmit(true)
        handleSubmitTransferencia()
        handleSubmitEgreso()

        setTimeout(function () {
            handleSubmitIngreso()
            setOpenDialogConfirm(false)
            setOpenDialogTransferencia(false)
            Swal.fire({ icon: "success", text: 'Se han transferido los materiales con exito' });
        }, 3000);

    }

    useEffect(() => {
        loadNumPedido()
        loadNumPedidoEgreso()
        loadNumPedidoIngreso()
    }, [inputFields])

    /* UPLOAD EXCEL */

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [data, setData] = useState();

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData)

                /* FOR EACH DE LA DATA DEL EXCEL DE CLIENTES */

                const uidSolicitud = `SO-TRAN-` + numPedido;

                jsonData.forEach((obj) => {

                    console.log(obj)

                    db.collection('transferencias').doc(uidSolicitud).collection('series').doc(obj.SERIE).set({
                        serie: obj.SERIE,
                        usuario_que_ingresa_serie: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                });

                let timerInterval
                if (jsonData.length <= 200) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 8000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 400) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 16000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 600) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 24000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                } else if (jsonData.length <= 1500) {
                    Swal.fire({
                        title: 'Subiendo datos, esto tardara un poco...',
                        html: 'La ventana se autocerrara en <b></b> segundos',
                        timer: 40000,
                        timerProgressBar: true,
                        didOpen: () => {
                            Swal.showLoading()
                            const b = Swal.getHtmlContainer().querySelector('b')
                            timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()
                            }, 100)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            console.log('I was closed by the timer')
                            setOpenDialogExcelSuccess(true)
                        }
                    })
                }
                setOpenUploadExcelSeries(false);
                /* Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false); */
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            /* setMessage(error.toString()); */
        }
    };

    /* GRID TABLE UI */

    const [total, setTotal] = useState(0);

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'SERIE', headerName: 'SERIE', width: 180 },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );


    /* FUNCION PARA SELECCIONAR QUE TIPO DE INGRESO DE SERIES SE QUIERE REALIZAR */

    const UploadSeriesFunction = ({ id, props, codigo, descripcionItem }) => {

        const [inputFieldsSeries, setInputFieldsSeries] = useState([]);
        const [seriesList, setSeriesList] = useState([]);
        const [series, setSeries] = useState([]);
        const [seriesAutomaticas, setSeriesAutomaticas] = useState([]);

        const [modalSelectedSeries, setModalSelectedSeries] = useState(false)

        const [openListSeriesAEgresar, setOpenListSeriesAEgresar] = useState(false)

        const [openSelectMetodoAEgresar, setOpenSelectMetodoAEgresar] = useState(null)

        const [openListSeriesAEgresarAutomaticas, setOpenListSeriesAEgresarAutomaticas] = useState(false)

        const uploadSeriesManuales = () => {

            console.log('id', id)

            const uidSolicitud = `SO-TRAN-` + numPedido;

            inputFieldsSeries.forEach(async function (obj, index) {

                console.log('inputFieldsSeries', obj)

                console.log('inputFieldsSeries', obj.serie)

                console.log('uidSolicitud', uidSolicitud)

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0,
                        descripcion: descripcionItem
                    })

                /* db.collection(`egreso_productos/${uidSolicitud}/items`)
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    }) */

                db.collection('tecnicos').doc(id).collection('materiales_instalacion')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0,
                        descripcion: descripcionItem
                    })

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                    .update({
                        estado: 1
                    })

            })

            setOpenListSeriesAEgresarAutomaticas(false)
            setOpenListSeriesAEgresar(false)

            Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            });

        }

        const [itemSerie, setItemSerie] = useState('')

        const uploadSeriesAutomaticas = () => {

            console.log('id', id)

            const uidSolicitud = `SO-TRAN-` + numPedido;

            seriesAutomaticas.forEach(async function (obj, index) {

                console.log('seriesAutomaticas', obj)

                console.log('seriesAutomaticas', obj.serie)

                console.log('uidSolicitud', uidSolicitud)

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0,
                        descripcion: descripcionItem
                    })

                /* db.collection(`egreso_productos/${uidSolicitud}/items`)
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    }) */

                db.collection('tecnicos').doc(id).collection('materiales_instalacion')
                    .doc(codigo).collection('series').doc(obj.serie.toString())
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0,
                        descripcion: descripcionItem
                    })

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                    .update({
                        estado: 1
                    })

            })

            setOpenListSeriesAEgresarAutomaticas(false)

            Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            });

        }

        /* ADD SERIES */

        const addInputSeries = (row) => {
            console.log(row, 'row')
            if (inputFieldsSeries.find(x => x.serie === row.serie)) {
                Swal.fire({ icon: "info", text: 'Ya se agrego esta serie a la lista', });
            } else {
                setInputFieldsSeries([...inputFieldsSeries, {
                    serie: row.serie
                }])
            }
            setModalSelectedSeries(false)
            /* setItem('')
            setModal(false) */
        }

        const removeInputFieldsSeries = (index) => {
            const rows = [...inputFieldsSeries];
            rows.splice(index, 1);
            setInputFieldsSeries(rows);
        }

        function detalleMateSeries(data, index) {
            return (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell align="center">
                        {data.serie}
                    </TableCell>

                    <TableCell align="center">
                        <IconButton onClick={() => removeInputFieldsSeries(index)} color='error'>
                            <DeleteForeverIcon />
                        </IconButton>
                    </TableCell>

                </TableRow>
            )
        }

        const getSeries = async (row) => {
            console.log('ROW SERIES', row)
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.fullName).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)
            docRef.get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage)
                setSeries(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const getSeriesAutomaticas = async (row) => {
            console.log('ROW SERIES AUTOMATICAS', row)
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.fullName).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)
            docRef.limit(parseFloat(row.cantidad_pedida)).get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage)
                setSeriesAutomaticas(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const onData = () => {
            getSeries(props)
            getSeriesAutomaticas(props)
            setOpenSelectMetodoAEgresar(true)
        };

        return (
            <>
                {/* SELECCIONAR METODO DE EGRESAR SERIES */}

                <Tooltip title="INGRESAR SERIES">
                    <Button onClick={() => onData()}>
                        <QrCodeScannerIcon />
                    </Button>
                </Tooltip>


                <Dialog
                    open={openSelectMetodoAEgresar}
                    maxWidth='xs'
                    fullWidth
                    onClose={() => setOpenSelectMetodoAEgresar(false)}
                >
                    <DialogTitle>
                        METODO A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Grid container rowSpacing={2} columnSpacing={2}>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresar(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='primary'>Seleccion manual</Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresarAutomaticas(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='secondary'>Seleccion automatica</Button>
                            </Grid>

                        </Grid>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='primary' onClick={() => setOpenSelectMetodoAEgresar(false)}>
                            REGRESAR
                        </Button>
                    </DialogActions>

                </Dialog>


                {/* LISTA DE SERIES A EGRESAR */}

                <Dialog
                    open={openListSeriesAEgresar}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Box pb={1}>
                            <Button disabled={props.cantidad_pedida <= inputFieldsSeries.length} onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        inputFieldsSeries.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresar(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={inputFieldsSeries.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesManuales()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>

                {/* LISTA PARA SELECCIONAR Y BUSCAR LAS SERIES A EGRESAR */}

                <Dialog
                    open={modalSelectedSeries}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        AGREGAR ITEMS
                    </DialogTitle>
                    <DialogContent dividers >
                        <Box pb={3}>
                            <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={series}
                                clearOnBlur={false}
                                autoComplete={false}
                                disableClearable
                                getOptionLabel={(option) => option.serie}
                                sx={{ width: 300 }}
                                onInputChange={(e, newInputValue) => {
                                    console.log(itemSerie)
                                    setItemSerie(newInputValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SERIES</TableCell>
                                        <TableCell>ACCIONES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {series && series
                                        .filter((val) => {
                                            if (itemSerie === "") {
                                                return val;
                                            } else if (val.serie.toLowerCase().includes(itemSerie.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow
                                                key={row.serie}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.serie}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => /* blockButtonAddItem */addInputSeries(row)}
                                                    >Agregar</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions dividers>
                        <Button size='small' variant='contained' onClick={() => setModalSelectedSeries(false)}>
                            Cerrar Ventana
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* LISTA DE SERIES A EGRESAR AUTOMATICAS */}

                <Dialog
                    open={openListSeriesAEgresarAutomaticas}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Box pb={1}>
                            <Button onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        seriesAutomaticas.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresarAutomaticas(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={seriesAutomaticas.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesAutomaticas()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>
            </>
        )
    }

    const [loadingButtonHandleSubmit, setLoadingButtonHandleSubmit] = useState(false);

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => setOpenDialogTransferencia(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={openDialogTransferencia} fullWidth onClose={() => setOpenDialogTransferencia(false)} maxWidth="lg">
                <DialogTitle><strong>TRANSFERENCIA DE MATERIALES</strong></DialogTitle>
                <DialogContent>
                </DialogContent>
                <Card>
                    <CardContent>
                        <form onSubmit={handleSubmitEgreso}>

                            <Grid container spacing={5}>
                                <Grid item md={6}>
                                    <Typography gutterBottom><strong>NUMERO DE REFERENCIA</strong></Typography>
                                    <Typography>SO-TRAN-{numPedido}</Typography>
                                    <Typography gutterBottom><strong>FECHA DE CREACION</strong></Typography>
                                    <Typography>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item md={6}>
                                    <Typography gutterBottom><strong>USUARIO</strong></Typography>
                                    <Typography>{userInfo.displayName}</Typography>
                                    <Typography gutterBottom><strong>METRAJE</strong></Typography>
                                    <Typography>{props.metraje}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <Box py={2}>
                                        <FormControl fullWidth>
                                            <Typography gutterBottom style={{ fontSize: "14px" }}>
                                                <strong>BODEGA ORIGEN</strong>
                                            </Typography>
                                            <Select
                                                sx={{
                                                    "& legend": { display: "none" },
                                                    "& fieldset": { top: 0 },
                                                }}
                                                size="small"
                                                value={bodega}
                                                label="MOTIVO"
                                                onChange={(e) => {
                                                    setBodegas(e.target.value)
                                                    getProductos(e.target.value)
                                                }}
                                            >
                                                {bodegas &&
                                                    bodegas.map((item) => (
                                                        <MenuItem
                                                            key={item.nombre_bodega}
                                                            value={item.nombre_bodega}
                                                        >
                                                            {item.nombre_bodega}
                                                        </MenuItem>
                                                    ))}
                                            </Select>

                                        </FormControl>
                                    </Box>


                                    <FormControl fullWidth>
                                        <Typography gutterBottom style={{ fontSize: "14px" }}>
                                            <strong>OBSERVACION ADICIONAL</strong>
                                        </Typography>
                                        <TextField
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            value={observacionAdicional}
                                            onChange={(e) => setObservacionAdicional(e.target.value)}
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                </Grid>

                                <Grid item md={6}>
                                    <Box py={2}>
                                        <FormControl fullWidth>
                                            <Typography gutterBottom style={{ fontSize: "14px" }}>
                                                <strong>BODEGA DESTINO</strong>
                                            </Typography>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={props.numFlota}
                                                size="small"
                                                fullWidth
                                                disabled
                                            />
                                            {/* <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            size="small"
                                            label="Flota"
                                            value={flota}
                                            onChange={(e) => handleFlota(e.target.value)}
                                        >
                                            {flotas && flotas.map((row, key) => (
                                                <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                                            ))}
                                        </Select> */}

                                        </FormControl>
                                    </Box>
                                    {/* <Box pb={2}>
                                    <FormControl fullWidth>
                                        <Typography gutterBottom style={{ fontSize: "14px" }}>
                                            <strong>MOTIVO</strong>
                                        </Typography>
                                        <Select
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                            size="small"
                                            value={motivoIngresoProducto}
                                            label="MOTIVO"
                                            onChange={(e) => setMotivoIngresoProducto(e.target.value)}
                                        >
                                            {motivoIngresoProductos &&
                                                motivoIngresoProductos.map((item) => (
                                                    <MenuItem
                                                        key={item.nombre_motivo_ingreso_producto}
                                                        value={item.nombre_motivo_ingreso_producto}
                                                    >
                                                        {item.nombre_motivo_ingreso_producto}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        {loadingMotivoIngresoProductos && <LinearProgress />}
                                    </FormControl>
                                </Box> */}
                                </Grid>
                            </Grid>

                            <Box display="flex" flexDirection="row-reverse" py={1}>
                                <Button
                                    variant='outlined'
                                    disabled={bodega === ''}
                                    onClick={() => setModal(true)}>
                                    Agregar Items
                                </Button>
                            </Box>

                            <Dialog
                                open={modal}
                                maxWidth='md'
                                fullWidth
                            >
                                <DialogTitle>
                                    AGREGAR ITEMS
                                </DialogTitle>
                                <DialogContent dividers >
                                    <Box pb={3}>
                                        <Autocomplete
                                            disablePortal
                                            size="small"
                                            id="combo-box-demo"
                                            options={productos}
                                            clearOnBlur={false}
                                            autoComplete={false}
                                            disableClearable
                                            getOptionLabel={(option) => option.descripcion}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setItem(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                                        />
                                    </Box>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>CODIGO</TableCell>
                                                    <TableCell>DESCRIPCION</TableCell>
                                                    <TableCell>CANTIDAD</TableCell>
                                                    <TableCell>ACCIONES</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productos && productos
                                                    .filter((val) => {
                                                        if (item === "") {
                                                            return val;
                                                        } else if (val.descripcion.toLowerCase().includes(item.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{row.codigo_producto}</TableCell>
                                                            <TableCell>{row.descripcion}</TableCell>
                                                            <TableCell>{row.stock_actual}</TableCell>
                                                            <TableCell><Button
                                                                variant='contained'
                                                                color='success'
                                                                onClick={() => addInput(row)}
                                                            >Agregar</Button></TableCell>
                                                        </TableRow>
                                                    ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box py={5} ></Box>
                                </DialogContent>
                                <DialogActions dividers>
                                    <Button onClick={() => setModal(false)}>
                                        Cerrar ventana
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Typography gutterBottom>DETALLE DE MATERIALES {item}</Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NO.</TableCell>
                                            <TableCell align="center">CODIGO</TableCell>
                                            <TableCell align="center">DESCRIPCION</TableCell>
                                            <TableCell align="center">CATEGORIA</TableCell>
                                            <TableCell align="center">SUBCATEGORIA</TableCell>
                                            <TableCell align="center">CANT PEDIDA</TableCell>
                                            <TableCell align="center">SERIES</TableCell>
                                            <TableCell align="center">ACCIONES</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            inputFields.map((data, index) => {
                                                const { cantidad_pedida, codigo, descripcion } = data;
                                                return (
                                                    detalleMate(data, index)
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Divider />
                            <Box display="flex" flexDirection="row-reverse" pt={1}>
                                <LoadingButton
                                    /* component={RouterLink} */
                                    variant='contained'
                                    /* type="submit" */
                                    disabled={inputFields.length <= 0}
                                    onClick={() => setOpenDialogConfirm(true)}
                                /* to={`/inventario/transferencias/aprobar-transferencias`} */
                                >Enviar solicitud</LoadingButton>
                            </Box>
                        </form>
                    </CardContent>
                </Card>
                <DialogActions>
                    <Button size='small' color='primary' variant='outlined' onClick={() => setOpenDialogTransferencia(false)}>REGRESAR</Button>
                </DialogActions>
            </Dialog>

            {/* MODAL CONFIRM */}

            <Dialog
                open={openDialogConfirm}
                fullWidth
                onClose={() => setOpenDialogConfirm(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>ESTA SEGURO DE REALIZAR LA TRANSFERENCIA DE MATERIALES</strong>
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        color='success'
                        variant='contained'
                        size='small'
                        loading={loadingButtonHandleSubmit}
                        endIcon={loadingButtonHandleSubmit === true ? <SendIcon /> : ''}
                        disabled={loadingButtonHandleSubmit === true}
                        loadingPosition="end"
                        onClick={() => handleSubmit()}>
                        <strong>TRANSFERIR</strong>
                    </LoadingButton>
                    <Button
                        color='primary'
                        size="small"
                        variant="outlined"
                        onClick={() => setOpenDialogSelectsOptionUploadSeries(false)}
                    >
                        <strong>REGRESAR</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* SELECCIONAR LA OPCION DE CARGA DE SERIES */}

            <Dialog
                open={openDialogSelectsOptionUploadSeries}
                fullWidth
                onClose={() => setOpenDialogSelectsOptionUploadSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SELECCIONE UNA OPCION DE CARGA {/* PERTENECIENTES A: {props.codigo} */}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box pt={1}>
                        <FormControl fullWidth>
                            <Grid container rowSpacing={2} columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        onClick={() => handleOpenDialogUploadExcel()}
                                        size="small"
                                        color="success"
                                        variant="outlined"
                                        startIcon={<FileUploadIcon />}
                                    >
                                        CARGAR EXCEL
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        size="small"
                                        color="info"
                                        variant="outlined"
                                        startIcon={<FormatAlignCenterIcon />}
                                    >
                                        CARGAR TXT
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        onClick={() => handleOpenDialogUploadManual()}
                                        fullWidth
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        startIcon={<FolderSharedIcon />}
                                    >
                                        CARGA MANUAL
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        onClick={() => handleOpenDialogUploadAutomaticas()}
                                        fullWidth
                                        size="small"
                                        color="warning"
                                        variant="outlined"
                                        startIcon={<AllInclusiveIcon />}
                                    >
                                        CARGA SERIES AUTOMATICAS
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => setOpenDialogSelectsOptionUploadSeries(false)}
                    >
                        <strong>Cancelar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES EXCEL */}

            <Dialog
                open={openUploadExcelSeries}
                fullWidth
                onClose={() => setOpenUploadExcelSeries(false)}
                maxWidth="md"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES MEDIANTE EXCEL</strong>
                </DialogTitle>
                <DialogContent>

                    <Box >
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>

                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                    size='small'
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={2}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {/* <Button variant='outlined' size='small' color='success'>
                        Cargar series
                    </Button> */}

                    <Paper>
                        <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                // disableColumnFilter
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                getRowId={(row) => row.SERIE}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,

                                }}
                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadExcel()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES MANUALES */}

            <Dialog
                open={openUploadManualSeries}
                fullWidth
                onClose={() => setOpenUploadManualSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES MANUALES</strong>
                </DialogTitle>
                <DialogContent>

                    <>
                        {/* {props.series.map((row, index) => (
                            <Box pt={1} pb={1}>
                                <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                                    <strong>SERIE: {row.id}</strong>
                                </p>
                                <TextField
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { Wtop: 0 },
                                    }}
                                    size="small"
                                    fullWidth
                                    name={index}
                                    //value={[i]}
                                    onChange={(e) => handleSeries(index, e, data)}
                                />
                            </Box>
                        ))}
                        <Button onClick={() => onCloseIngresoManual()}>
                            <strong>Cancelar</strong>
                        </Button>
                        <Button
                            size="small"
                            color="success"
                            variant="contained"
                            onClick={() => onSeries()}
                        >
                            CREAR SERIES
                        </Button> */}
                    </>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadManual()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PARA CARGAR SERIES AUTOMATICAS */}

            <Dialog
                open={openUploadAutomaticasSeries}
                fullWidth
                onClose={() => setOpenUploadAutomaticasSeries(false)}
                maxWidth="sm"
            >
                <DialogTitle>
                    <strong>SUBA SU SERIES AUTOMATICAS</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleCloseDialogUploadAutomaticas()}
                    >
                        <strong>Regresar</strong>
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL EXCELS CARGADOS CON EXITO */}

            <Dialog
                open={openDialogExcelSuccess}
                fullWidth
                onClose={() => setOpenDialogExcelSuccess(false)}
                maxWidth="xs"
            >
                <DialogTitle>
                    <strong>SERIES SUBIDAS CON EXITO</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>

                    <Button
                        size="small"
                        variant="contained"
                        color='success'
                        onClick={() => setOpenDialogExcelSuccess(false)}
                    >
                        <strong>OK</strong>
                    </Button>

                </DialogActions>
            </Dialog>

        </>
    )
}