import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import {
    Box, Button, Card, CardContent, Grid, Select, MenuItem,
    LinearProgress, Paper, TextField, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Autocomplete
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2';
import { db } from '../../../firebase';

export const ProductoRetencion = () => {
    const tabla = 'proveedores'
    const ventana = 'Producto - Proveedor - Retencion'
    // const tipo = 'GASTOS'
    // const tipoP = 'SERVICIO'
    const [proveedores, setProveedores] = useState([])
    const [proveedor, setProveedor] = useState('')

    const [retencionIva, setRetencionIva] = useState('')
    const [retencionRenta, setRetencionRenta] = useState('')
    const [producto, setProducto] = useState('')


    const [retenciones, setRetenciones] = useState([])
    const [retenciones2, setRetenciones2] = useState([])

    const [id, setId] = useState('')

    const [categorias, setCategorias] = useState(null)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [gastos, setGastos] = useState([])


    useEffect(() => {
        getGastos()
        getProveedores()
        getRetenciones()
        getRetenciones2()
    }, [])


    const getRetenciones = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_fuentes")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRetenciones(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getRetenciones2 = () => {
        let docRef = db.collection("cuentas_pagar/parametros/retenciones_iva")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setRetenciones2(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    function getProveedores() {
        let docRef = db.collection("proveedores")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProveedores(arrayMessage)
            let lista = []
            for (const elemento of arrayMessage) {
                if (elemento.productos) {
                    lista.push({
                        ...elemento.productos,
                        id: lista.length,
                        proveedor: elemento.nombre_comercial,
                        idProveedor: elemento.id,
                    })
                }
            }
            setCategorias(lista)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200
            },
        },
    };

    function getGastos() {
        let docRef = db.collection("gastos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setGastos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )


    const columns = [
        { field: 'proveedor', headerClassName: 'super-app-theme--header', headerName: 'Proveedor', flex: 2 },
        {
            field: 'producto', headerClassName: 'super-app-theme--header', headerName: 'Producto', flex: 2,
            renderCell: (params) => {
                return <a>
                    {params.row.producto.descripcion}</a>
            }
        },

        { field: 'retencionIva', headerClassName: 'super-app-theme--header', headerName: 'Ret. IVA', flex: 1 },
        { field: 'retencionRenta', headerClassName: 'super-app-theme--header', headerName: 'Ret. RENTA', flex: 1 },

        {
            field: 'EDITAR', headerClassName: 'super-app-theme--header', headerName: 'EDITAR', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}
                >
                    <EditIcon color='info' />
                </IconButton>
            }
        },
        {
            field: 'BORRAR', headerClassName: 'super-app-theme--header', headerName: 'BORRAR', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen2(params.row)}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];



    function crearSubCategoria() {
        // let lista = retencion.lista ? retencion.lista : []
        // let label = tipo + '--' + producto.id + '--' + producto.descripcion
        // if (proveedor !== '' && proveedor !== undefined) {
        //     label = label + '--' + proveedor.id + '--' + proveedor.nombre_comercial
        // }
        // if (!lista.includes(label)) {
        //     lista.push(label)
        // }
        db.collection(tabla).doc(proveedor.id).update({
            productos: {
                producto: producto,
                retencionIva: retencionIva,
                retencionRenta: retencionRenta
            },
        }).then(() => {
            limpiar()
            setOpen(false)
            getProveedores()
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        if (id !== '') {
            db.collection(tabla).doc(proveedor.id).update({
                productos: {
                    producto: producto,
                    retencionIva: retencionIva,
                    retencionRenta: retencionRenta
                },
            }).then(() => {
                limpiar()
                setOpen(false)
                getProveedores()
                Swal.fire({ icon: "success", text: "Se ha creado", });
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
        } else {
            crearSubCategoria()
        }
    }

    function limpiar() {
        setId('')
        setProveedor('')
        setProducto('')
        setRetencionRenta('')
        setRetencionIva('')
    }

    function handleOpen(item) {
        if (item) {
            setId(item.idProveedor)
            setProveedor(proveedores.filter((val) => val.id === item.idProveedor)[0])
            setProducto(gastos.filter((val) => val.id === item.producto.id)[0])
            setRetencionIva(item.retencionIva)
            setRetencionRenta(item.retencionRenta)
            console.log(item)
        }
        setOpen(true)
    }

    function handleOpen2(item) {
        if (item) {
            setId(item.idProveedor)
        }
        setOpen2(true)
    }

    function deleteProducto() {
        db.collection(tabla).doc(id).update({
            productos: null,
        }).then(() => {
            limpiar()
            setOpen2(false)
            getProveedores()
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }




    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>{ventana}</strong> </Typography>
                    {/* <Button
                        onClick={() => crearAleatorio()}
                        variant="contained" color='success'>
                        AGREGAR ALEATORIO
                    </Button> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Button
                                onClick={() => handleOpen()}
                                variant="contained" color='success'>
                                AGREGAR
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px', overflowX: 'scroll' }}>
                                <Box sx={{
                                    minWidth: 1000,
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={categorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        getRowId={(row) => row.id}
                                        density='compact'
                                        rows={categorias ? categorias : []}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 100}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} {ventana} </strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {/* <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Tipo Producto Contable:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={tipo}
                                            disabled={id !== ''}
                                            onChange={(e) => setTipo(e.target.value)}
                                        >
                                            {tipos ? tipos.map((item, index) => (
                                                <MenuItem key={item} value={item}>{item}</MenuItem>
                                            )) : ''}

                                        </Select>
                                    </Box> */}
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Proveedor:  *</strong></Typography>
                                        {id !== '' ?
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={proveedor !== '' ? proveedor.identificacion + ": " + proveedor.nombre_comercial : ''}
                                                disabled={true}
                                                size="small"
                                                fullWidth />
                                            :
                                            <Autocomplete
                                                size='small'
                                                fullWidth
                                                disablePortal
                                                id="combo-box-demo"
                                                options={proveedores}
                                                getOptionLabel={(option) => option.identificacion + ": " + option.nombre_comercial}
                                                onInputChange={(e, newInputValue) => {
                                                    console.log(proveedores.filter(
                                                        (item) => item.identificacion + ": " + item.nombre_comercial === newInputValue)[0])
                                                    setProveedor(proveedores.filter(
                                                        (item) => item.identificacion + ": " + item.nombre_comercial === newInputValue)[0])
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        }
                                    </Box>


                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Producto:  *</strong></Typography>
                                        {id !== '' ?
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={producto !== '' ? producto.descripcion : ''}
                                                disabled={true}
                                                size="small"
                                                fullWidth />
                                            :
                                            <Autocomplete
                                                size='small'
                                                fullWidth
                                                disablePortal
                                                id="combo-box-demo"
                                                options={gastos}
                                                getOptionLabel={(option) => `${option.codigo}: ${option.descripcion}`}
                                                onInputChange={(e, newInputValue) => {
                                                    let producto = gastos.filter((item) => `${item.codigo}: ${item.descripcion}` === newInputValue)[0];
                                                    setProducto(producto)
                                                }}
                                                renderInput={(params) => <TextField  {...params} />}
                                            />
                                        }
                                    </Box>

                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Retención IVA:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={retencionIva}
                                            MenuProps={MenuProps}
                                            onChange={(e) => setRetencionIva(e.target.value)}
                                        >
                                            {retenciones2 ? retenciones2.map((item) => (
                                                <MenuItem key={item.codigo} value={`${item.codigo}-(${item.porcentaje}%)`}>{`${item.codigo}-(${item.porcentaje}%)`}</MenuItem>
                                            )) : ''
                                            }

                                        </Select>
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Retención Renta:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={retencionRenta}
                                            MenuProps={MenuProps}
                                            onChange={(e) => setRetencionRenta(e.target.value)}
                                        >
                                            {retenciones ? retenciones.map((item) => (
                                                <MenuItem key={item.codigo} value={`${item.codigo}-(${item.porcentaje}%)`}>{`${item.codigo}-(${item.porcentaje}%)`}</MenuItem>
                                            )) : ''
                                            }

                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                proveedor === ''
                                                || producto === ''
                                                || retencionRenta === ''
                                                || retencionIva === ''
                                            }
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>

            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: { }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
