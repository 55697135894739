import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import { db, functions, firebase } from '../../firebase'
export default function ButtonReingreso({ props }) {
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false)

    const onSubmit = () => {
        if (window.confirm("¿Estas seguro de eliminar cliente de mikro?") === true) {
            const deleteClient = functions.httpsCallable('deleteClient')
            deleteClient(data.user_id_microwisp)
                .then(res => {
                    db.collection("solicitudes_televentas").orderBy("created", "desc").limit(1).get()
                        .then((solicitudesDb) => {
                            if (solicitudesDb.size > 0) {
                                const solicitudes = solicitudesDb.docs.map((doc) => ({
                                    id: doc.id,
                                    ...doc.data(),
                                }));
                                const solicitud = solicitudes[0];
                                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                                const uidSolicitud = `SO-TEL-` + numeroSolicitud;
                                //
                                console.log(data, ' data')
                                const data2 = {
                                    ...data,
                                    uid: uidSolicitud,
                                    numeroSolicitud: numeroSolicitud,
                                    user_uid: data.user_uid,
                                    cedula: data.cedula,
                                    nombres: data.nombres,
                                    direccion: data.direccion,
                                    telefono: data.telefono,
                                    nacionalidad: data.nacionalidad,
                                    fechaNacimiento: data.fechaNacimiento,
                                    celular: data.celular,
                                    correo: data.correo,
                                    coordenadas: new firebase.firestore.GeoPoint(
                                        data.coordenadas.latitude,
                                        data.coordenadas.longitude
                                    ),
                                    planNombre: data.planNombre,
                                    estado: 0,
                                    created: firebase.firestore.FieldValue.serverTimestamp(),
                                };
                                db.collection("solicitudes_televentas")
                                    .doc(uidSolicitud)
                                    .set(data2)
                                    .then((docRef) => {
                                        setOpen(false)
                                        Swal.fire({ icon: "success", text: "Se ha elimino con exito.", });
                                    })
                            }
                        })

                })
        }
    }
    const onData = () => {
        setOpen(true)
        setData(props)
    }
    return (
        <>
            <Button
                onClick={() => onData()}
            >
                <EditIcon />
            </Button>
            <Dialog

                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>Eliminar Cliente</DialogTitle>
                <DialogContent dividers>
                    id microwisp: {data.user_id_microwisp}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                    >Cerrar ventana</Button>
                    <Button
                        color='error'
                        variant='contained'

                        onClick={() => onSubmit()}
                    >Eliminar</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
