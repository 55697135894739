import React, { useEffect, useState, } from 'react'
import { useParams } from 'react-router-dom';
import {
    Grid, Box, Card, Button, Divider, Typography, DialogContent, Alert, CardContent, Tooltip, Dialog
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Swal from 'sweetalert2'
import { firebase, storage, db } from '../../../firebase'
import { detallesSolicitud } from '../../../redux/actions/ventasActions';
import Loader from '../../../components/utils/Loader'
import { submitDomicilio } from '../../../redux/actions/ventas/solicitudesActions';
import { Container } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { SubirDocumento } from './SubirDocumento';

export const CambioPropietarioCargarDocumento = ({ props }) => {
    let navigate = useNavigate()

    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file5, setFile5] = useState(null);
    const [file6, setFile6] = useState(null);
    const [file7, setFile7] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [solicitud, setSolicitud] = useState('');
    const params = useParams()

    //const solicitudDetalle = useSelector(state => state.solicitudDetalle)
    //const { solicitud } = solicitudDetalle
    useEffect(() => {
        setFile1(solicitud.foto_cedula_frontal)
        setFile2(solicitud.foto_cedula_trasera)
        setFile3(solicitud.foto_cliente_letrero)
        setFile4(solicitud.foto_cliente_planilla)
        setFile5(solicitud.foto_cliente_vivienda)
        setFile5(solicitud.foto_cliente_vivienda)
        setFile7(solicitud.foto_debito)
    }, [])

    const onData = () => {
        setOpen(true)
        setSolicitud(props)
        console.log(props, ' props')
    }
    const onSubmitAgenda = () => {
        //dispatch(submitDomicilio(solicitud, navigate))
    }
    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()} >
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>SUBIR DOCUMENTOS
                </DialogTitle>
                <DialogContent dividers>
                    {
                        solicitud ?
                            <Grid container spacing={4}>
                                <Grid item md={6}>
                                    <Box pb={5}>
                                        <Typography>DOCUMENTO CEDULA FRONTAL</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cedula_frontal ?
                                        <>
                                            <img width={100} src={solicitud.foto_cedula_frontal} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <SubirDocumento
                                            solicitud={solicitud}
                                            cambiarEstado={setFile1}
                                            estado={file1}
                                            nameFile="foto_cedula_frontal"
                                        />}
                                    <Box py={5}>
                                        <Typography>DOCUMENTO CEDULA TRASERA</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_cedula_trasera ?
                                        <>
                                            <img width={100} src={solicitud.foto_cedula_trasera} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <SubirDocumento
                                            solicitud={solicitud}
                                            cambiarEstado={setFile2}
                                            estado={file2}
                                            nameFile="foto_cedula_trasera"
                                        />}

                                </Grid>
                                <Grid item md={6}>
                                    <Box pb={5}>
                                        <Typography>CARTA DE CESION</Typography>
                                        <Divider />
                                    </Box>
                                    {solicitud && solicitud.foto_carta_cesion ?
                                        <>
                                            <img width={100} src={solicitud.foto_carta_cesion} />
                                            <Alert>Archivo subido</Alert>
                                        </>
                                        :
                                        <SubirDocumento
                                            solicitud={solicitud}
                                            cambiarEstado={setFile4}
                                            estado={file4}
                                            nameFile="foto_carta_cesion"
                                        />}
                                    <Box py={5}>
                                        <Typography>FOTO CLIENTE DE ACCEPTACION</Typography>
                                        <Divider />
                                    </Box>
                                    {
                                        solicitud && solicitud.foto_cliente_letrero ?
                                            <>
                                                <img width={100} src={solicitud.foto_cliente_letrero} />
                                                <Alert>Archivo subido</Alert>
                                            </>
                                            :
                                            <SubirDocumento
                                                solicitud={solicitud}
                                                cambiarEstado={setFile5}
                                                estado={file5}
                                                nameFile="foto_cliente_letrero"
                                            />
                                    }
                                </Grid>
                            </Grid>
                            :
                            <Loader />
                    }
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}