import {
    Container,
    Table,
    Button,
    Typography,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Dialog,
    DialogTitle,
    Paper,
    Chip,
    Box,
    Collapse,
    Autocomplete,
    TextField,
    Select,
    MenuItem,
    FormControl,
    CircularProgress,
    Stack,
    InputLabel,
    Card,
    CardContent,
    Grid,
    Divider
} from "@mui/material";
import DatePicker from "react-datepicker";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES,
    GridToolbarExport
} from '@mui/x-data-grid';

import React, { useEffect, useState } from "react";

import { db, storage, firebase, auth } from "../../../firebase";

import moment from 'moment'

import { useSelector } from 'react-redux';

import { useDispatch } from 'react-redux'
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";

export const KardexInstaladores = () => {

    const dispatch = useDispatch()

    const kardexList = useSelector(state => state.kardexList);
    const { kardexs, loadingKardexs } = kardexList
    const [kardex, setKardex] = useState('')
    const [codProd, setCodProd] = useState([])

    const [inputFields, setInputFields] = useState([]);
    const [dataFiltro, setDataFiltro] = useState([]);
    const [dataFiltro2, setDataFiltro2] = useState([]);
    const [data3, setData3] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        /* getProductos() */
        LoadData()
        /* listsKardex() */
        /* LoadItems() */
        /* LoadItems() */
        /* getKardexIndividual() */
        // getProductos()
        /* dataProduct() */
    }, [dispatch])


    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };


    // const changeKardexIndividual = (e) => {
    //     setKardex(e)
    // }

    const userAuth = useSelector(state => state.userAuth)
    // const { loading, userInfo } = userAuth

    // function Estado({ estado }) {
    //     let name;
    //     let color;
    //     //Controlamos el estado de la factiblidad
    //     if (estado === 0) {
    //         name = 'Por agendar'
    //         color = 'warning'
    //     } else if (estado === 1) {
    //         name = 'Rechazada'
    //         color = 'warning'
    //     }
    //     return (
    //         <Chip label={name} color={color} />
    //     )
    // }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleExport2 = () => {
        setLoading(false)
    }


    const [nombreZona, setNombreZona] = useState();

    const [codigoZona, setCodigoZona] = useState("");

    const [data, setData] = useState([]);
    const [data2, setData2] = useState('');
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    const [search, setSearch] = useState('')
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 7 * (86400000)))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

    if (startDate) startDate.setHours(0, 0, 0, 0)
    if (endDate) endDate.setHours(23, 59, 0, 0)

    const [stockMinimo, setStockMinimo] = useState();
    const [stockMaximo, setStockMaximo] = useState();
    const [stockSeguridad, setStockSeguridad] = useState();
    const [stockActual, setStockActual] = useState();

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const [codigoKardex, setCodigoKardex] = useState('')

    // const [productosData, setProductosData] = useState('')

    const [descripcionData, setDescripcionData] = useState('')

    const [item, setItem] = useState('')
    const [item2, setItem2] = useState('')

    // const [codigoProducto, setCodigoProducto] = useState('')

    // const [total, setTotal] = useState(0);

    // const handleCodigoProducto = (e) => {
    //     setCodigoProducto(e)
    // }

    // const getProductos = () => {
    //     var docRef = db.collection("kardex")
    //     docRef.get().then((querySnapshot) => {
    //         const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
    //         console.log(arrayMessage)
    //         setProductosData(arrayMessage)
    //     }).catch((error) => {
    //         console.log("Error getting document:", error);
    //     });
    // }

    const LoadData = () => {
        let docRef = db.collection("total_movimientos_flotas").orderBy("created", "asc")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            const labels = ["TODOS"]
            let productos = []
            let bodegas = ["TODOS"]
            let codigos = []
            let flotas = []
            arrayMessage.map((item) => {
                if (item.descripcion) labels.push(item.descripcion)
                if (item.bodega_general) bodegas.push(item.bodega_general)
                if (!codigos[item.codigo]) codigos[item.codigo] = item.descripcion
                if (item.codigo) productos.push(item.codigo)
                if (item.flota) flotas.push(item.flota)
            })
            const conjunto = Array.from(new Set(labels));
            setDataFiltro(conjunto.map(item => { return { descripcion: item } }))


            productos = Array.from(new Set(productos));
            setCodProd(codigos)

            bodegas = Array.from(new Set(bodegas));
            setDataFiltro2(bodegas.map(item => { return { descripcion: item } }))

            // setData(arrayMessage)
            let agrupado = []
            const data2 = []
            flotas.map(item => {
                if (!agrupado[item]) {
                    agrupado[item] = []
                }
                productos.map(item2 => {
                    if (!agrupado[item][item2]) {
                        agrupado[item][item2] = [0]
                    }
                })

            })
            const valores = []
            arrayMessage.map((item) => {
                if (valores.indexOf(item.codigo + item.uid_solicitud_pedido + item.bodega_general + item.flota) < 0) {
                    let bodega = item.flota
                    let codigo = item.codigo
                    if (agrupado[bodega] && agrupado[bodega][codigo]) {
                        let stockActual = agrupado[bodega][codigo][0]
                        let cantidad = item.cant_entrega && item.cant_entrega!== NaN ? item.cant_entrega : 0
                        let stockNuevo = (item.id_orden_trabajo.includes('SO-PED-') ? stockActual + parseInt(cantidad) : stockActual - parseInt(cantidad))

                        data2.push({
                            ...item,
                            cant_entrega: cantidad,
                            total_bodegas: stockNuevo,
                        })
                        agrupado[bodega][codigo][0] = stockNuevo
                        valores.push(item.codigo + item.uid_solicitud_pedido + item.bodega_general + item.flota)

                    }

                }
            })
            setData(data2)
            setData3([...data2.filter((val) => {
                if (val.fecha_documento ? filtrarFecha(val.fecha_documento) : false) return val;
            })])
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    };


    const tabla = () => {
        let datos2 = []
        data3.map((item) => {
            datos2.push({
                created: item.created === undefined ? "No asignado" : moment(item.created.toDate().toISOString()).format("MMMM Do YYYY, h:mm:ss a"),
                uid_solicitud_pedido: item.uid_solicitud_pedido === undefined ? "No asignado" : item.uid_solicitud_pedido,
                bodega_general: item.bodega_general === undefined ? "No asignado" : item.bodega_general,
                id_orden_trabajo: item.id_orden_trabajo === undefined ? "No asignado" : item.id_orden_trabajo,
                observacion_adicional: item.observacion_adicional === undefined ? "SIN OBSERVACION" : item.observacion_adicional,
                codigo: item.codigo === undefined ? "No asignado" : item.codigo,
                flota: item.flota === undefined ? "No asignado" : item.flota,
                descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,

                // cant_entrega: item.cant_entrega === undefined ? 0 : Number((parseFloat(item.cant_entrega)).toFixed(0)),
                cantidad_solicitada: (item.id_orden_trabajo.includes('SO-PED') && item.cant_entrega !== undefined) ? Number((parseFloat(item.cant_entrega)).toFixed(0)) : null,
                cantidad_solicitada2: (!item.id_orden_trabajo.includes('SO-PED') && item.cant_entrega !== undefined) ? Number((parseFloat(item.cant_entrega)).toFixed(0)) : null,
                total_bodegas: item.total_bodegas === undefined ? 0 : Number((parseFloat(item.total_bodegas)).toFixed(0)),
            })
        })

        const group2 = [{ field: "flota" }, { field: "codigo" }];

        let excelFiltrado = process(
            datos2.filter((val) => {
                if (item2 === '' || item2 === "TODOS") {
                    if (item === '' || item === "TODOS") {
                        return val;
                    } else {
                        if (val.descripcion?.includes(item)) {
                            return val;
                        }
                    }
                } else if (val.bodega_general?.includes(item2)) {
                    if (item === '' || item === "TODOS") {
                        return val;
                    } else {
                        if (val.descripcion?.includes(item)) {
                            return val;
                        }
                    }
                }
            }).map((row) => (
                row
            ))
            , {
                group: group2,
            }).data;

        const CustomGroupHeader = (props) => {
            return codProd[props.value] ? props.value + ' - ' + codProd[props.value] : 'NO ENCONTRADO';
        };

        const CustomGroupHeader2 = (props) => {
            return props.value.toUpperCase();
        };

        return (
            <>
                <ExcelExport onExportComplete={handleExport2} data={excelFiltrado} group={group2} fileName={"KardexInstalacionesFiltrado" +
                    (startDate ? moment(startDate.toString()).format('YYYY-MM-DD') : '') + " - " +
                    (endDate ? moment(endDate.toString()).format('YYYY-MM-DD') : '')
                    + ".xlsx"} ref={exporter} filterable={true} >
                    <ExcelExportColumn field="created" title="FECHA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="uid_solicitud_pedido" title="NO. MOVIMIENTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="bodega_general" title="BODEGA" headerCellOptions={{ background: "#672780" }} 
                       />
                    <ExcelExportColumn field="id_orden_trabajo" title="NUMERO DE REFERENCIA" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="flota" title="No. FLOTA" headerCellOptions={{ background: "#672780" }} 
                     groupHeader={CustomGroupHeader2} groupHeaderCellOptions={{ background: "#65C8D7" }}/>

                    <ExcelExportColumn field="observacion_adicional" title="OBSERVACION / NOMBRE DEL CLIENTE" headerCellOptions={{ background: "#672780" }} width={400} />
                    <ExcelExportColumn field="codigo" title="CODIGO PRODUCTO" headerCellOptions={{ background: "#672780" }}
                        groupHeader={CustomGroupHeader} />
                    <ExcelExportColumn field="descripcion" title="PRODUCTO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada" title="CANTIDAD INGRESO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="cantidad_solicitada2" title="CANTIDAD EGRESO" headerCellOptions={{ background: "#672780" }} />
                    <ExcelExportColumn field="total_bodegas" title="STOCK ACTUAL" headerCellOptions={{ background: "#672780" }} />
                </ExcelExport>
            </>
        )
    }

    function cambiarFechas() {
        setData3([...data.filter((val) => {
            if (val.fecha_documento ? filtrarFecha(val.fecha_documento) : false) return val;
        })])
    }

    const [sumaBodegas, setSumaBodegas] = useState('')

    const [totalValorizado, setTotalValorizado] = useState('')

    const [totalCostoPromedio, setTotalCostoPromedio] = useState('')

    // const dataProduct = async (newInputValue) => {
    //     const agendaDB = await db.collection("productos").where("codigo_producto", "==", newInputValue).get()
    //     const data = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    //     setStockMaximo(data[0].stock_maximo_producto)
    //     setStockMinimo(data[0].stock_minimo_producto)
    //     setStockSeguridad(data[0].stock_seguridad_producto)

    //     console.log(item)
    //     console.log(data[0].stock_maximo_producto)
    //     console.log(data[0].stock_minimo_producto)
    //     console.log(data[0].stock_seguridad_producto)


    //     /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", newInputValue).get()
    //     const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    //     setStockActual(data2[0].stock_actual) */

    //     const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", newInputValue).get()

    //     const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    //     /* const total2 = parseFloat(data3[0].stock_actual) */

    //     const costoPromedio = parseFloat(data3[0].total_valorizado) / parseFloat(data3[0].stock_actual)

    //     setTotalCostoPromedio(data3[0].costo_promedio)

    //     console.log(data3)

    //     console.log(sumaBodegas)

    //     var datoSumaTotalBodegas;

    //     let suma = 0;
    //     for (let i = 0; i < data3.length; i++) {
    //         console.log(data3[i]["stock_actual"])
    //         suma += parseFloat(data3[i]["stock_actual"])
    //         setSumaBodegas(suma)
    //         datoSumaTotalBodegas = suma
    //     }

    //     var saldoTotalValorizado;
    //     let sumaTotal = 0;
    //     for (let i = 0; i < data3.length; i++) {
    //         console.log(data3[i]["total_valorizado"])
    //         sumaTotal += parseFloat(data3[i]["total_valorizado"])
    //         setTotalValorizado(sumaTotal)
    //         saldoTotalValorizado = sumaTotal
    //     }
    // }


    function filtrarFecha(fecha) {
        let valor = new Date(fecha.seconds * 1000).getTime()
        return valor >= startDate.getTime() && valor <= endDate.getTime()
    }

    const columns = [
        {
            field: 'created', headerName: 'FECHA', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format("MMMM Do YYYY, h:mm:ss a"),
        },
        /* {
            field: 'hora', headerName: 'HORA', type: 'dateTime', width: 160,
            renderCell: (params) => {
                const respuesta = params && moment(new Date(params.seconds * 1000).toISOString()).format('h:mm:ss')
                return respuesta
            }
        }, */
        { field: 'uid_solicitud_pedido', headerName: 'NO. MOVIMIENTO', width: 160 },
        { field: 'bodega_general', headerName: 'BODEGA', width: 200 },
        { field: 'id_orden_trabajo', headerName: 'NUMERO DE REFERENCIA', width: 210 },
        { field: 'flota', headerName: 'NO. FLOTA', width: 120 },
        { field: 'observacion_adicional', headerName: 'OBSERVACION / NOMBRE DEL CLIENTE', width: 600 },
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 170 },
        { field: 'descripcion', headerName: 'PRODUCTO', width: 340 },
        {
            field: '1', headerName: 'CANTIDAD INGRESO', width: 190,
            renderCell: (params) => {
                const respuesta = params.row.id_orden_trabajo.includes('SO-PED') ? params.row.cant_entrega : ''
                return parseFloat(Math.abs(respuesta)).toLocaleString('en', {
                    maximumFractionDigits: 2
                })/* `${parseFloat(respuesta).toFixed(2)}` */
            }
        },
        {
            field: '2', headerName: 'CANTIDAD EGRESO', width: 190,
            renderCell: (params) => {
                const respuesta = !params.row.id_orden_trabajo.includes('SO-PED') ? params.row.cant_entrega : ''
                return parseFloat(Math.abs(respuesta)).toLocaleString('en', {
                    maximumFractionDigits: 2
                })/* `${parseFloat(respuesta).toFixed(2)}` */
            }
        },

        /* {
            field: '2', headerName: 'COSTO UNITARIO', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.tipo_movimiento === 'egreso' ? params.row.costo_promedio : ''
                return respuesta === undefined || respuesta === null || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(2)}`
            }
        }, */

        {
            field: '3', headerName: 'STOCK ACTUAL', width: 160,
            renderCell: (params) => {
                const respuesta = params.row.total_bodegas ? parseFloat(params.row.total_bodegas).toLocaleString('en') : 0
                return respuesta
            }
        },

        /* {
            field: '4', headerName: 'COSTO PROMEDIO ACTUAL', width: 220,
            renderCell: (params) => {
                const respuesta = parseFloat(params.row.costo_promedio_general).toLocaleString('en')
                return respuesta === undefined || respuesta === null || respuesta === '' ? '' : `$${parseFloat(respuesta).toFixed(2)}`
            }
        }, */

        /* {
            field: '5', headerName: 'SALDO VALORIZADO', width: 160,
            renderCell: (params) => {
                const respuesta = parseFloat(Math.abs(params.row.total))
                return respuesta === undefined || respuesta === null || respuesta === '' ? '' : `$${respuesta.toLocaleString('en', { maximumFractionDigits: 2 })}`
            }
        }, */

    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    // function CustomFooterTotalComponent(props) {
    //     console.log(props)
    //     return <Box sx={{ padding: "10px", display: "flex" }}>Total : {total}</Box>
    // }

    return (
        <>

            {/* <Button color="success" variant="outlined" size="small">
                Descargar Excel
            </Button> */}

            <Box px={2}>
                <p style={{ fontSize: '20px' }}><strong>Kardex de instalaciones</strong></p>
            </Box>

            <Box px={2}>

                <Paper elevation={3}>
                    <Box py={2} px={2}>
                        <p style={{ fontSize: "12px", marginBottom: "8px" }}><strong>BUSQUEDA POR BODEGA:</strong></p>
                        <Autocomplete
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            disableClearable
                            fullWidth
                            size='small'
                            disablePortal
                            id="combo-box-demo"
                            options={dataFiltro2}
                            getOptionLabel={(option) => option.descripcion}
                            onInputChange={(e, newInputValue) => {
                                setItem2(newInputValue)
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <br />

                        <p style={{ fontSize: "12px", marginBottom: "8px" }}><strong>BUSQUEDA POR NOMBRE:</strong></p>
                        <Box pb={1}>
                            <Autocomplete
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                disableClearable
                                fullWidth
                                size='small'
                                disablePortal
                                id="combo-box-demo"
                                options={dataFiltro}
                                getOptionLabel={(option) => option.descripcion}
                                onInputChange={(e, newInputValue) => {
                                    console.log(item)
                                    setItem(newInputValue)
                                    // dataProduct(newInputValue)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                        <Box display="flex" style={{ textTransform: "uppercase" }}>
                            <Typography variant="button">
                                <strong>Desde</strong>{" "}
                                {startDate && moment(startDate.toString()).format("DD/MM/YYYY")}
                            </Typography>
                            <Typography variant="button" style={{ paddingLeft: 10 }}>
                                <strong>Hasta</strong>{" "}
                                {endDate && moment(endDate.toString()).format("DD/MM/YYYY")}
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <Button onClick={() => setOpen(!open)}>
                                Buscar por fecha
                            </Button>
                        </Box>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                disableElevation
                                variant="contained"
                                onClick={() => cambiarFechas()}
                            >
                                Aplicar busqueda
                            </Button>
                        </Collapse>
                        <br />

                        <Stack direction="row" spacing={2}>
                            <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                            >
                                {" "}
                                EXCEL FILTRADO
                            </Button>
                        </Stack >

                        {/* {
                            sumaBodegas === '' || sumaBodegas === null ? '' : <Box sx={{ flexGrow: 1 }}>
                                <Grid container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">

                                    <Grid item xs={2} sm={4} md={3}>
                                        <Box py={2} px={1}>
                                            <Paper>
                                                <Card>
                                                    <p style={{ fontSize: "18px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>STOCK MAXIMA</strong></p>
                                                    <p style={{ fontSize: "12px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>{stockMaximo}</strong></p>
                                                </Card>
                                            </Paper>
                                        </Box>
                                    </Grid>

                                    <Divider orientation="vertical" flexItem />

                                    <Grid item xs={2} sm={4} md={3}>
                                        <Box py={2} px={1}>
                                            <Paper>
                                                <Card>
                                                    <p style={{ fontSize: "18px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>STOCK MINIMA</strong></p>
                                                    <p style={{ fontSize: "12px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>{stockMinimo}</strong></p>
                                                </Card>
                                            </Paper>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2} sm={4} md={3}>
                                        <Box py={2} px={1}>
                                            <Paper>
                                                <Card>
                                                    <p style={{ fontSize: "18px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>STOCK SEGURIDAD</strong></p>
                                                    <p style={{ fontSize: "12px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>{stockSeguridad}</strong></p>
                                                </Card>
                                            </Paper>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2} sm={4} md={3}>
                                        <Box py={2} px={1}>
                                            <Paper>
                                                <Card>
                                                    <p style={{ fontSize: "18px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>COSTO UNITARIO</strong></p>
                                                    <p style={{ fontSize: "12px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>{parseFloat(totalCostoPromedio).toFixed(2)}</strong></p>
                                                </Card>
                                            </Paper>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2} sm={4} md={6}>
                                        <Box py={2} px={1}>
                                            <Paper>
                                                <Card>
                                                    <p style={{ fontSize: "18px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>STOCK ACTUAL</strong></p>
                                                    <p style={{ fontSize: "12px", marginBottom: "8px", display: "flex", justifyContent: "center" }}><strong>{parseFloat(sumaBodegas).toLocaleString('en')}</strong></p>
                                                </Card>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        } */}

                        <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >

                            <Box sx={{ height: 800, width: '100%' }}>

                                {/* {data && data
                                    .filter((val) => {
                                        if (item === '') {
                                            return val;
                                        } else if (val.codigo?.includes(item)) {
                                            return val;
                                        }
                                    }).map((row) => (

                                            ))} */}

                                <DataGrid
                                    density="compact"
                                    rows={data3 && data3
                                        .filter((val) => {
                                            if (item2 === '' || item2 === "TODOS") {
                                                if (item === '' || item === "TODOS") {
                                                    return val;
                                                } else {
                                                    if (val.descripcion?.includes(item)) {
                                                        return val;
                                                    }
                                                }
                                            } else if (val.bodega_general?.includes(item2)) {
                                                if (item === '' || item === "TODOS") {
                                                    return val;
                                                } else {
                                                    if (val.descripcion?.includes(item)) {
                                                        return val;
                                                    }
                                                }
                                            }
                                        }).map((row) => (
                                            row
                                        ))}/* {data ? data : []} *//* {data && data
                                        .filter((val) => {
                                            if (item === '') {
                                                return val;
                                            } else if (val.codigo?.includes(item)) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            row
                                        ))} */
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        // Footer: CustomFooterTotalComponent,

                                    }}
                                /* getRowId={(row) => row.codigo} */

                                // onStateChange={(state) => {
                                //     console.log(state.pagination.rowCount);
                                //     setTotal(state.pagination.rowCount)
                                // }}
                                />
                            </Box>

                            {/* {
                                sumaBodegas === '' || sumaBodegas === null ? '' : <TableRow>
                                    <TableCell colSpan={5}></TableCell>
                                    <TableCell align="center" ><p style={{ fontSize: "12px" }}><strong>STOCK ACTUAL</strong></p></TableCell>
                                    <TableCell align="center"><p style={{ fontSize: "11px" }}>{parseFloat(Math.abs(sumaBodegas)).toLocaleString('en')}</p></TableCell>

                                    <TableCell align="center" ><p style={{ fontSize: "12px" }}><strong>SALDO VALORIZADO</strong></p></TableCell>
                                    <TableCell align="center"><p style={{ fontSize: "11px" }}>{parseFloat(Math.abs(totalValorizado)).toLocaleString('en')}</p></TableCell>

                                    <TableCell colSpan={2}></TableCell>
                                </TableRow>
                            } */}
                        </Paper>
                        <Dialog open={loading} >
                            <DialogTitle>
                                <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress align="center" />
                                    <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                                </Stack>
                            </DialogTitle>
                        </Dialog>
                        {tabla()}
                    </Box>
                </Paper>
            </Box>
        </>
    );
}