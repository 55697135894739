import React, { useEffect, useState, useRef } from "react";
import { db, firebase, storage } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Container,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import {
    listsCategorias,
    listsModeloProducto,
    listsSubCategorias,
    listsMarcaProducto,
    listsPresentacionProducto,
    listsUnidadMedidaProducto,
    listsCategoriasFirebase,
    listsSubCategoriasFirebase,
    listsPresentacionFirebase,
    listsMarcaFirebase,
    listsModeloFirebase,
    listsUnidadMedidaFirebase
} from "../../../redux/actions/parametersActions";
import * as XLSX from "xlsx/xlsx.mjs";
import SearchIcon from '@mui/icons-material/Search';
import { CentroDeCostos } from "./CentroDeCostos";

export const NuevoGrupoCentroDeCosto = () => {

    const [cuenta, setCuenta] = useState('')

    const [desde, setDesde] = useState(null);

    const [hasta, setHasta] = useState(null);

    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const categoriasFirebaseList = useSelector(state => state.categoriasFirebaseList);
    const { categorias, loading } = categoriasFirebaseList
    const [categoria, setCategoria] = useState('')

    const subCategoriasFirebaseList = useSelector(state => state.subCategoriasFirebaseList);
    const { subCategorias, loadingSubCategorias } = subCategoriasFirebaseList
    const [SubCategoria, setSubCategoria] = useState('')

    const ModelosFirebaseList = useSelector(state => state.ModelosFirebaseList);
    const { modeloProductos, loadingModeloProductos } = ModelosFirebaseList
    const [modeloproducto, setModeloProducto] = useState('')

    const MarcasFirebaseList = useSelector(state => state.MarcasFirebaseList);
    const { marcaProductos, loadingMarcaProductos } = MarcasFirebaseList
    const [marcaproducto, setMarcaProducto] = useState('')

    const UnidadesMedidaList = useSelector(state => state.UnidadesMedidaList);
    const { unidadMedidaProductos, loadingUnidadMedidaProductos } = UnidadesMedidaList
    const [unidadMedidaProducto, setUnidadMedidaProducto] = useState('')

    const PresentacionesFirebaseList = useSelector(state => state.PresentacionesFirebaseList);
    const { presentacionProductos, loadingPresentacionProductos } = PresentacionesFirebaseList
    const [presentacionProducto, setPresentacionProducto] = useState('')

    const [lider, setLider] = useState({ campo: '', valido: true })
    const [auxiliar, setAuxiliar] = useState({ campo: "", valido: true });
    const [proveedor, setProveedor] = useState({
        campo: "",
        valido: true,
    });
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [Imagen, setImagen] = useState(null);
    const dispatch = useDispatch()

    const [item, setItem] = useState('')
    const [productos, setProductos] = useState('')

    const [codigo, setCodigo] = useState('')

    const [estado, setEstado] = useState('')

    const [filtro, setFiltro] = useState('')

    const [centroCosto, setCentroCosto] = useState('')

    const [proyecto, setProyecto] = useState('')

    const [tipo, setTipo] = useState('')

    const getProductos = () => {
        var docRef = db.collection("plan_cuentas_test")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
            /* setCcInventario(arrayMessage)
            setCcVentas(arrayMessage)
            setCcCosto(arrayMessage)
            setCcInventarioConIva(arrayMessage)
            setCcVentasConIva(arrayMessage)
            setCcCostoConIva(arrayMessage) */
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    useEffect(() => {
        LoadData()
        getProductos()
    }, [dispatch]);

    const changeCategoria = (e) => {
        setCategoria(e)
    }

    const changeSubCategoria = (e) => {
        setSubCategoria(e)
    }

    const changeModeloProducto = (e) => {
        setModeloProducto(e)
    }

    const changeMarcaProducto = (e) => {
        setMarcaProducto(e)
    }

    const changeUnidadMedidaProducto = (e) => {
        setUnidadMedidaProducto(e)
    }

    const changePresentacionProducto = (e) => {
        setPresentacionProducto(e)
    }

    const [descripcionProducto, setDescripcionProducto] = useState('')

    const handleDescriptionProducto = (e) => {
        setDescripcionProducto(e)
    }

    const [codigoProducto, setCodigoProducto] = useState('')

    const handleCodigoProducto = (e) => {
        setCodigoProducto(e)
    }

    const [numeroSerieProducto, setNumeroSerieProducto] = useState('')

    const handleNumeroSerieProducto = (e) => {
        setNumeroSerieProducto(e)
    }

    const [cantidadProducto, setCantidadProducto] = useState('')

    const handleCantidadProducto = (e) => {
        setCantidadProducto(e)
    }

    const [costoEstandarProducto, setCostoEstandarProducto] = useState('')

    const handleCostoEstandarProducto = (e) => {
        setCostoEstandarProducto(e)
    }

    const [precioCompra, setPrecioCompra] = useState('')

    const handlePrecioCompra = (e) => {
        setPrecioCompra(e)
    }

    const [precioVenta, setPrecioVenta] = useState('')

    const handlePrecioVenta = (e) => {
        setPrecioVenta(e)
    }

    const [checkValue, setCheckValue] = useState("")

    const [localidad, setLocalidad] = useState('');
    const [marca, setMarca] = useState([]);

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [stockMinimoProducto, setStockMinimoProducto] = useState('')

    const handleStockMinimoProducto = (e) => {
        setStockMinimoProducto(e)
    }

    const [stockMaximoProducto, setStockMaximoProducto] = useState('')

    const handleStockMaximoProducto = (e) => {
        setStockMaximoProducto(e)
    }

    const [stockSeguridadProducto, setsStockSeguridadProducto] = useState('')

    const handleStockSeguridadProducto = (e) => {
        setsStockSeguridadProducto(e)
    }

    var imagenUrl;
    const onHandleClick = async () => {
        console.log('Ok')
    }

    const [data, setData] = useState();

    const [image, setImage] = useState(null)

    const [viewImagen, setViewImagen] = useState(null)

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagen(URL.createObjectURL(event.target.files[0]));
        }
    }

    const [dataExcel, setDataExcel] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("productos").where("estadoProducto", "==", 0);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });
    };

    const obtenerId = () => {
        const p = data.filter((item) => item.codigoProducto);
        console.log(data)
        console.log(`p ${p}`);
    }

    const [openCaja, setOpenCaja] = React.useState(false);

    const [message, setMessage] = useState();

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [openConfirm, setOpenConfirm] = React.useState(false);


    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setDataExcel(jsonData)
                jsonData.forEach((obj) => {
                    db.collection("productos")
                        .doc(obj.CODIGO_PRODUCTO)
                        .set({
                            codigo_producto: obj.CODIGO_PRODUCTO === '' ||
                                obj.CODIGO_PRODUCTO === null ||
                                obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO,

                            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),

                            descripcion_producto: obj.DESCRIPCION === '' ||
                                obj.DESCRIPCION === null ||
                                obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                            categoria_producto: obj.CATEGORIA === '' ||
                                obj.CATEGORIA === null ||
                                obj.CATEGORIA === undefined ? '' : obj.CATEGORIA.toUpperCase(),

                            subcategoria_producto: obj.SUBCATEGORIA === '' ||
                                obj.SUBCATEGORIA === null ||
                                obj.SUBCATEGORIA === undefined ? '' : obj.SUBCATEGORIA.toUpperCase(),

                            presentacion_producto: obj.PRESENTACION === '' ||
                                obj.PRESENTACION === null ||
                                obj.PRESENTACION === undefined ? '' : obj.PRESENTACION.toUpperCase(),

                            marca_producto: obj.MARCA === '' ||
                                obj.MARCA === null ||
                                obj.MARCA === undefined ? '' : obj.MARCA.toUpperCase(),

                            modelo_producto: obj.MODELO === '' ||
                                obj.MODELO === null ||
                                obj.MODELO === undefined ? '' : obj.MODELO.toUpperCase(),

                            unidad_Medida_Producto: obj.UNIDAD_MEDIDA === '' ||
                                obj.UNIDAD_MEDIDA === null ||
                                obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                            costo_estandar_producto: obj.COSTO_ESTANDAR === '' ||
                                obj.COSTO_ESTANDAR === null ||
                                obj.COSTO_ESTANDAR === undefined ? 0 : obj.COSTO_ESTANDAR,

                            precio_compra_producto: obj.PRECIO_COMPRA === '' ||
                                obj.PRECIO_COMPRA === null ||
                                obj.PRECIO_COMPRA === undefined ? 0 : obj.PRECIO_COMPRA,

                            precio_venta_producto: obj.PRECIO_VENTA === '' ||
                                obj.PRECIO_VENTA === null ||
                                obj.PRECIO_VENTA === undefined ? 0 : obj.PRECIO_VENTA,

                            cantidad_producto: obj.CANTIDAD === '' ||
                                obj.CANTIDAD === null ||
                                obj.CANTIDAD === undefined ? 0 : obj.CANTIDAD,

                            estado_producto: obj.ESTADO_PRODUCTO === '' ||
                                obj.ESTADO_PRODUCTO === undefined ||
                                obj.ESTADO_PRODUCTO === 'ACTIVO' ? 0 : 1,

                            stock_minimo_producto: obj.STOCK_MINIMO === '' ||
                                obj.STOCK_MINIMO === null ||
                                obj.STOCK_MINIMO === undefined ? 0 : obj.STOCK_MINIMO,

                            stock_seguridad_producto: obj.STOCK_SEGURIDAD === '' ||
                                obj.STOCK_SEGURIDAD === null ||
                                obj.STOCK_SEGURIDAD === undefined ? 0 : obj.STOCK_SEGURIDAD,

                            stock_maximo_producto: obj.STOCK_MAXIMO === '' ||
                                obj.STOCK_MAXIMO === null ||
                                obj.STOCK_MAXIMO === undefined ? 0 : obj.STOCK_MAXIMO,

                            /* imagen_producto: imagenUrl, */
                            usuario_que_creo_el_producto: userInfo.displayName
                        })
                });
                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const columns = [
        { field: 'cuenta', headerName: 'CUENTA', width: 150 },
        { field: 'nombre', headerName: 'NOMBRE', width: 150 },
        { field: 'estado', headerName: 'ESTADO', width: 150 },
    ];

    const addCount = (row) => {
        console.log(row.nombre)
        setCuenta(row.nombre)
        setOpenDialogCuentas(false)
    }

    const handleConfirm = () => {
        db.collection("centros_de_costos").doc(centroCosto.toUpperCase()).set({
            centro_de_costo: centroCosto.toUpperCase(),
            estado: estado.toUpperCase(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            user_created: userInfo.displayName
        }).then(() => {
            setOpenConfirm(false)
            Swal.fire({ icon: "success", text: "Se creado el centro de costo con exito", });
            setTimeout(
                function () {
                    window.location.reload();
                }, 600);
        })
            .catch((error) => { console.error("Error adding document: ", error); });
    }

    return (
        <>
            <br />
            <Card>

                <Box paddingTop={2} paddingLeft={2}>
                    <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>AGREGAR CENTRO DE COSTO</strong></p>
                </Box>

                <CardContent>
                    <Card>
                        <CardContent>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <Box py={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>GRUPO:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={centroCosto}
                                            onChange={(e) => setCentroCosto(e.target.value)}
                                            name="codigoProducto"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>

                                    <Box py={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={estado}
                                                onChange={(e) => setEstado(e.target.value)}
                                            >
                                                <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                                <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>

                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>

                </CardContent>
                <CardActions>

                    <br />
                    <br />
                    <Button
                        disabled={
                            centroCosto === '' ||
                            estado === ''
                        }
                        onClick={() => { setOpenConfirm(true) }}
                        fullWidth
                        variant="contained"
                        disableElevation>
                        {" "}
                        CREAR NUEVO CENTRO DE COSTO{" "}
                    </Button>
                </CardActions>
            </Card>

            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="md">

                <DialogTitle>¿Esta seguro de crear este centro de costo?</DialogTitle>

                <DialogActions>
                    <Button onClick={() => setOpenConfirm(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => handleConfirm()} variant="contained" color="success"><strong>Crear</strong></Button>
                </DialogActions>

            </Dialog>

        </>
    );
};
