import {
    Button,
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    Typography
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { db, firebase, auth } from "../../firebase";
import Swal from "sweetalert2";
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarExport,
    esES,
} from '@mui/x-data-grid';

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

export const CargaMasivaCobranza = () => {

    const [loadingButtonUploadExcel, setLoadingButtonUploadExcel] = useState(false);

    const [data, setData] = useState([]);

    const [numPagosTotalesClientes, setNumPagosTotalesClientes] = useState()

    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const loadNumPagosTotalesClientes = () => {
        const ref = db.collection('pagos_totales_clientes').orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_pago + 1;
                setNumPagosTotalesClientes(numeroSolicitud)
            }
        })
    }

    useEffect(() => {
        LoadData()
        /* loadNumPagosTotalesClientes() */
    }, [])

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                await new Promise(resolve => setTimeout(resolve, 1000)); // Pausa de 1 segundo

                const ref = await db.collection('pagos_totales_clientes').orderBy("created", "desc").limit(1);
                const snapshot = await ref.get();
                const solicitudes = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                const solicitud = solicitudes[0];
                let numeroSolicitudBase = solicitud ? solicitud.numero_pago + 1 : 1;

                for (const obj of jsonData) {

                    await new Promise(resolve => setTimeout(resolve, 1000)); // Pausa de 1 segundo

                    let numeroSolicitud = numeroSolicitudBase++;

                    await db.collection('pagos_totales_clientes').doc(`PAGO-${numeroSolicitud}`).set({
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        id_pago: `PAGO-${numeroSolicitud}`,
                        numero_pago: numeroSolicitud
                    })

                    const valorIva = (parseFloat(parseFloat(obj.precio_unitario)) * 12) / 100
                    const subtotalSinIva = parseFloat(parseFloat(obj.precio_unitario)).toFixed(2) - parseFloat(valorIva).toFixed(2)
                    console.log('valorIva', valorIva.toFixed(2))
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "ambiente": 1,
                        "secuencial": parseInt(numeroSolicitud),
                        "ruc": "0992988061001",
                        "establecimiento": {
                            "punto_emision": "002",
                            "codigo": "002",
                            "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
                        },
                        "totales": {
                            "total_sin_impuestos": parseFloat(subtotalSinIva),
                            "total_del_impuesto": parseFloat(valorIva),
                            "importe_total": parseFloat(obj.precio_unitario),
                            "descuento": parseFloat(obj.descuento)
                        },
                        "cliente": {
                            "email": `${obj.email}`,
                            "identificacion": `${obj.identificacion}`,
                            "tipo_identificacion": `0${obj.tipo_identificacion}`,
                            "razon_social": `${obj.razon_social}`,
                            "direccion": `${obj.direccion}`,
                            "telefono": `${obj.telefono}`
                        },
                        "items": [
                            {
                                "cantidad": parseInt(obj.cantidad),
                                "precio_unitario": parseFloat(obj.precio_unitario),
                                "descripcion": obj.descripcion,
                                "precio_total_sin_impuestos": parseFloat(obj.precio_unitario),
                                "impuestos": [
                                    {
                                        "base_imponible": parseFloat(obj.base_imponible),
                                        "valor": parseFloat(valorIva),
                                        "tarifa": 12.0,
                                        "codigo": "2",
                                        "codigo_porcentaje": "2",
                                    },
                                ],
                                "descuento": parseFloat(obj.descuento),
                            }
                        ],
                        "pagos": [
                            {
                                "medio": "102",
                                "total": parseFloat(obj.precio_unitario),
                            }
                        ]
                    })

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch("https://serverapiolt.intercommerce.com.ec/datil/datil_fact/", requestOptions)
                        .then(response => response.text())
                        .then(result => console.log(result))
                        .catch(error => console.log('error', error));
                }

                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpen(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const handleButtonClick = async () => {

        setLoadingButtonUploadExcel(true)

        try {
            if (!file) {
                console.log("No se ha seleccionado ningún archivo.");
                return;
            }

            const fileData = await readFileData(file);
            console.log('Valores del Excel:', fileData);

            // Convertir el array de enteros a base64 antes de enviarlo a la Cloud Function
            const base64FileData = arrayBufferToBase64(fileData);
            const cargaMasivaCobranzas = firebase.functions().httpsCallable('cargaMasivaCobranzas');
            const response = await cargaMasivaCobranzas({ fileData: base64FileData });

            setOpen(false)

            Swal.fire({ icon: "success", text: "Se realizado la carga de informacion con exito", });

            console.log('Respuesta de la Cloud Function:', response.data);
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpen(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);

            /* setOpenConfirm(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", }); */

            /* setTimeout(() => {
                window.location.reload();
            }, 3000); */
        }
    };

    // Función para convertir un array de enteros a base64
    const arrayBufferToBase64 = (arrayBuffer) => {
        let binary = '';
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

    // Función para leer los datos del archivo Excel
    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = event.target.result;
                const arrayBuffer = new Uint8Array(data);
                resolve(arrayBuffer);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const LoadData = () => {
        let ref = db.collection('facturas_masivas_cobranza')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];

            setData(data)
        })
    }

    const columns = [
        { field: 'id_factura', headerName: 'ID FACTURA', width: 200 },
        { field: 'tipo', headerName: 'TIPO', width: 200 },
        { field: 'contrapartida', headerName: 'CONTRAPARTIDA', width: 200 },
        { field: 'moneda', headerName: 'MONEDA', width: 200 },
        { field: 'valor', headerName: 'VALOR', width: 200 },
        { field: 'forma_de_cobro', headerName: 'FORMA DE COBRO', width: 200 },
        { field: 'tipo_id', headerName: 'TIPO ID', width: 200 },
        { field: 'numero_id', headerName: 'NUMERO ID', width: 200 },
        { field: 'nombre_beneficiario', headerName: 'NOMBRE BENEFICIARIO', width: 200 },
    ]

    function CustomFooterTotalComponent(props) {
        return ``
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )

    const csvOptions = { delimiter: ';' }

    return (
        <>

            {/* <div>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleUpload}>Subir Archivo</button>
            </div> */}

            <Typography>CARGA MASIVA DE PAGOS</Typography>
            <Button variant="contained" size="small" onClick={() => setOpen(true)}>Cargar excel</Button>

            <Box pt={1} px={1}>
                <Paper>
                    {/* <Button color='secondary' size='small' variant='contained' onClick={() => deleteCajas()}>Eliminar</Button> */}
                    <Box sx={{ height: 800, width: '100%' }}>
                        <DataGrid
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            density="compact"
                            pagination={true}
                            // disableColumnSelector
                            // disableDensitySelector
                            //hideFooterPagination
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                        /* onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }} */
                        />
                    </Box>
                </Paper>
            </Box>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir archivo</DialogTitle>
                <DialogContent>
                    <Box pb={2}>
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>
                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={5}>
                                    <LoadingButton
                                        loading={loadingButtonUploadExcel}
                                        endIcon={loadingButtonUploadExcel === true ? <SendIcon /> : ''}
                                        loadingPosition="end"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </LoadingButton>
                                </Box>
                                <Box>
                                    <LoadingButton
                                        loading={loadingButtonUploadExcel}
                                        endIcon={loadingButtonUploadExcel === true ? <SendIcon /> : ''}
                                        loadingPosition="end"
                                        onClick={() => { handleButtonClick() }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Subir archivo
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}