import {
    Box, Button,
    Container, Dialog, DialogActions,
    DialogContent, DialogTitle, Paper,
    Tab
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import moment from 'moment';
import {
    DetalleTicketCambioDomicilio,
    DetalleTicketCambioPlan, DetalleTicketContratar,
    DetalleTicketMigracion, DetalleTicketSuspension
} from './Tickets';
import { ChipTickets, ChipTickets2 } from './ChipTickets';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { db } from '../../firebase';
import { DetalleTicketSoporteNOC } from './DetalleTicketNOC';
import { tabla } from './Tabla';
import { ViewTicketEnEspera } from './ViewTicket';


export const HistorialTickets = (props) => {
    const [tecnico, setTecnico] = useState(null);
    const [migracion, setMigracion] = useState(null);
    const [plan, setPlan] = useState(null);
    const [domicilio, setDomicilio] = useState(null);
    const [suspension, setSuspension] = useState(null);
    const [contratar, setContratar] = useState(null);
    const [visita, setVisita] = useState(null);
    const [open, setOpen] = useState(false);

    const [cedula, setCedula] = useState(props.cedula);

    useEffect(() => {
        setCedula(props.cedula)
    }, []);

    useEffect(() => {
        setCedula(props.cedula)
    }, [props.cedula]);

    function cargar() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        try {
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?migracionCedula=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_migracion").where('cedula', '==', cedula)
                    ref.onSnapshot(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    codigo: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate().toISOString())
                                        .format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate().toISOString())
                                            .format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setMigracion(lista)
                    })
                })
                .catch(error => {
                    console.log('error', error)
                });
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?suspensionCedula=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_suspension").where('cedula', '==', cedula)
                    ref.onSnapshot(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    codigo: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate()
                                        .toISOString()).format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate().toISOString())
                                            .format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setSuspension(lista)
                    })
                })
                .catch(error => {
                    console.log('error', error)
                });
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?contratarCedula=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_contratar").where('cedula', '==', cedula)
                    ref.onSnapshot(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate()
                                        .toISOString()).format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate()
                                            .toISOString()).format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setContratar(lista)
                    })
                })
                .catch(error => {
                    console.log('error', error)
                });
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?tecnicoCedula=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_soporte_tecnico").where('cedula', '==', cedula)
                    ref.get().then(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate()
                                        .toISOString()).format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate()
                                            .toISOString()).format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setTecnico(lista)

                    })
                })
                .catch(error => {
                    console.log('error', error)
                });
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?cambioplanCedula=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_cambio_plan").where('cedula', '==', cedula)
                    ref.get().then(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate()
                                        .toISOString()).format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate()
                                            .toISOString()).format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setPlan(lista)
                    })
                })
                .catch(error => {
                    console.log('error', error)
                });
            fetch(`https://serverbd.intercommerce.com.ec/apitopwisptickets/?cambiodomicilioCedula=${cedula}`,
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result)
                    res.reverse()
                    let ref = db.collection("CRM_cambio_domicilio").where('cedula', '==', cedula)
                    ref.onSnapshot(snapshot => {
                        const arrayMessage = [
                            ...snapshot.docs.map(doc => {
                                return {
                                    ...doc.data(),
                                    id: 'CRM-' + doc.id,
                                    fecha_ingreso: moment(doc.data().fecha_ingreso.toDate()
                                        .toISOString()).format('DD/MM/YYYY  HH:mm'),
                                    fecha_modificacion: doc.data().fecha_modificacion &&
                                        doc.data().fecha_modificacion !== ''
                                        ? moment(doc.data().fecha_modificacion.toDate()
                                            .toISOString()).format('DD/MM/YYYY  HH:mm') : undefined,
                                }
                            })
                        ]
                        arrayMessage.reverse()
                        let lista = res.concat(arrayMessage)
                        lista.reverse()
                        setDomicilio(lista)
                    })
                })
                .catch(error => {
                    console.log('error', error)
                });

            let ref = db.collection("tickets").where('cedula', '==', cedula)
            ref.onSnapshot((snapshot) => {
                console.log(...snapshot.docs)
                const data = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                ]
                setVisita(data)
            })
        } catch (error) {
            console.log('error', error)
        }
        setOpen(true)
    }




    const columnsContratar = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'VER', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketContratar ticket={params.row} historial={'si'} />
                </>
            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estadosolicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estadosolicitud &&
                        <>
                            <ChipTickets estado={params.row.estadosolicitud} />
                        </>
                    }
                </>

            }
        },
        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header', headerName: 'Fecha Ingreso', width: 170,
        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,
        },
        { field: 'planideal', headerClassName: 'super-app-theme--header', headerName: 'Plan', width: 250 },
        { field: 'telefono1', headerClassName: 'super-app-theme--header', headerName: 'Teléfono 1', width: 150 },
        { field: 'telefono2', headerClassName: 'super-app-theme--header', headerName: 'Teléfono 2', width: 150 },
        { field: 'unidad', headerClassName: 'super-app-theme--header', headerName: 'Unidad', width: 150 },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },
        {
            field: 'usuario_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Modificación', width: 200
        },
    ]

    const columnsSuspension = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'VER', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketSuspension ticket={params.row} historial={'si'} />
                </>
            }
        },
        { field: 'codigo', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estado_solicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estado_solicitud &&
                        <>
                            <ChipTickets estado={params.row.estado_solicitud} />
                        </>
                    }
                </>

            }
        },

        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header', headerName: 'Fecha Ingreso', width: 170,
        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,
        },
        { field: 'motivo_suspension', headerClassName: 'super-app-theme--header', headerName: 'Motivo', width: 250 },
        { field: 'unidadnegocio', headerClassName: 'super-app-theme--header', headerName: 'Unidad', width: 150 },
        { field: 'direccion', headerClassName: 'super-app-theme--header', headerName: 'Dirección', width: 200 },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },
    ]

    const columnsCambioDomiclio = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'VER', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketCambioDomicilio ticket={params.row} historial={'si'} />
                </>

            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estadosolicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estadosolicitud &&
                        <>
                            <ChipTickets estado={params.row.estadosolicitud} />
                        </>
                    }
                </>
            }
        },
        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header', headerName: 'Fecha Ingreso', width: 170,

        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,

        },
        {
            field: 'direccion', headerClassName: 'super-app-theme--header',
            headerName: 'Dirección', width: 250
        },
        {
            field: 'unidadnegocio', headerClassName: 'super-app-theme--header',
            headerName: 'Unidad', width: 150
        },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },

    ]

    const columnsCambioPlan = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'VER', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketCambioPlan ticket={params.row} historial={'si'} />
                </>
            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estadosolicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estadosolicitud &&
                        <>
                            <ChipTickets estado={params.row.estadosolicitud} />
                        </>
                    }
                </>
            }
        },
        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header', headerName: 'Fecha Ingreso', width: 170,
        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,
        },
        { field: 'plan', headerClassName: 'super-app-theme--header', headerName: 'Plan Actual', width: 250 },
        { field: 'plannuevo', headerClassName: 'super-app-theme--header', headerName: 'Plan Nuevo', width: 250 },
        { field: 'unidad', headerClassName: 'super-app-theme--header', headerName: 'Unidad', width: 150 },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },

    ]

    const columnsTecnico = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketSoporteNOC ticket={params.row} historial={'si'} tipo={'PWST'} />
                </>
            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estadosolicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estadosolicitud &&
                        <>
                            <ChipTickets estado={params.row.estadosolicitud} />
                        </>
                    }
                </>

            }
        },
        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header', headerName: 'Fecha Ingreso', width: 170,

        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,
        },
        {
            field: 'tipoproblema', headerClassName: 'super-app-theme--header',
            headerName: 'Asunto', width: 200
        },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },
        {
            field: 'usuario_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Modificación', width: 200
        },

    ]

    const columnsMigracion = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: 'VER', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleTicketMigracion ticket={params.row} historial={'si'} />
                </>
            }
        },
        { field: 'codigo', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Identificación', width: 150 },
        { field: 'nombres', headerClassName: 'super-app-theme--header', headerName: 'Nombres', width: 200 },
        {
            field: 'estado_solicitud', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estado_solicitud &&
                        <>
                            <ChipTickets estado={params.row.estado_solicitud} />
                        </>
                    }
                </>

            }
        },
        {
            field: 'fecha_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Ingreso', width: 170,
        },
        {
            field: 'fecha_modificacion', headerClassName: 'super-app-theme--header',
            headerName: 'Fecha Modificación', width: 170,
        },
        {
            field: 'plan', headerClassName: 'super-app-theme--header',
            headerName: 'Plan', width: 250
        },
        {
            field: 'nuevo_servicio', headerClassName: 'super-app-theme--header',
            headerName: 'Nuevo servicio', width: 250
        },
        {
            field: 'unidadnegocio', headerClassName: 'super-app-theme--header',
            headerName: 'Unidad', width: 150
        },
        {
            field: 'usuario_ingreso', headerClassName: 'super-app-theme--header',
            headerName: 'Usuario Ingreso', width: 200
        },
    ]

    const [tab, setTab] = useState('0');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    const columns = [
        {
            field: '2', headerName: 'VER', width: 90, headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <ViewTicketEnEspera props={params.row} />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120, headerClassName: 'super-app-theme--header', },
        {
            field: 'estado', headerName: 'ESTADO', width: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <ChipTickets2 estado={params.row.estado} />
            }
        },
        {
            field: 'created', headerName: 'FECHA CREACION',
            type: 'dateTime', width: 170, headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value &&
                moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm'),
        },
        {
            field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE',
            width: 200, headerClassName: 'super-app-theme--header',
        },
        {
            field: 'linea_contrato', headerName: 'LINEA CONTRATO',
            width: 200, headerClassName: 'super-app-theme--header',
        },
        {
            field: 'agendado_desde', headerName: 'AGENDADO DESDE',
            width: 250, headerClassName: 'super-app-theme--header',
        },

        {
            field: 'departamento', headerName: 'DEPARTAMENTO',
            width: 250, headerClassName: 'super-app-theme--header',
        },
        {
            field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET',
            width: 350, headerClassName: 'super-app-theme--header',
        },
        {
            field: '1', headerName: 'VALOR', width: 100, headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar ===
                    undefined || params.row.valor_a_cobrar === null ||
                    params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'turno', headerName: 'TURNO', width: 100, headerClassName: 'super-app-theme--header', },
        {
            field: 'fecha_cierra_ticket', headerName: 'FECHA CIERRE',
            type: 'dateTime', width: 190, headerClassName: 'super-app-theme--header',
            valueGetter: ({ value }) => value &&
                moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'usuario_que_cerro_ticket', headerName: 'USUARIO QUE CERRO',
            width: 250, headerClassName: 'super-app-theme--header',
        },
    ];



    return (
        <>

            <Button /*label={'CONSUMO'} size='medium'*/
                fullWidth variant="contained" onClick={() => {
                    cargar()
                }} color='info' >
                HISTORIAL
            </Button>
            <Box px={2}>
                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xl">
                    <DialogTitle sx={{ bgcolor: '#1A6EB9', color: "white" }}>
                        <strong>HISTORIAL DE TICKETS: {props.cedula}</strong></DialogTitle>
                    <DialogContent>

                        <Container maxWidth="lg">
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} variant="scrollable">
                                        <Tab label="SOPORTE TECNICO" value={'0'} />
                                        <Tab label="CAMBIO DE PLAN" value={'1'} />
                                        <Tab label="CAMBIO DE DOMICILIO" value={'2'} />
                                        <Tab label="CONTRATAR" value={'3'} />
                                        <Tab label="MIGRACION" value={'4'} />
                                        <Tab label="SUSPENSION" value={'5'} />
                                        <Tab label="VISITA TECNICA" value={'6'} />
                                    </TabList>
                                </Box>

                                <TabPanel value={'0'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>TECNICO</strong>
                                        {tabla(columnsTecnico, tecnico, false)}
                                    </Paper>
                                </TabPanel>

                                <TabPanel value={'1'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>PLAN</strong>
                                        {tabla(columnsCambioPlan, plan, false)}
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={'2'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>DOMICILIO</strong>
                                        {tabla(columnsCambioDomiclio, domicilio, false)}
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={'3'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>CONTRATAR</strong>
                                        {tabla(columnsContratar, contratar, false)}
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={'4'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>MIGRACION</strong>
                                        {tabla(columnsMigracion, migracion, false)}
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={'5'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>SUSPENSION</strong>
                                        {tabla(columnsSuspension, suspension, false)}
                                    </Paper>
                                </TabPanel>
                                <TabPanel value={'6'}>
                                    <Paper elevation={2} sx={{ padding: '10px' }}>
                                        <strong>VISITA TECNICA</strong>
                                        {tabla(columns, visita, false)}
                                    </Paper>
                                </TabPanel>
                            </TabContext>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                    </DialogActions>
                </Dialog>

            </Box>

        </>
    )
}
