import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../../../components/utils/InputValidation";
import { db, firebase } from "../../../../../firebase";
export const NewTipoTransaccion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [subcategoria, setSubcategoria] = useState('')

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async (subcategoria) => {
        await db.collection('activos_fijos_parametros').doc('tipo_transaccion').collection('list').doc(subcategoria).set({
            tipo_transaccion: subcategoria.toUpperCase(),
            fecha_creacion_tipo_transaccion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nuevo tipo de transaccion creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <Box pb={1}>
            <TextField
                value={subcategoria}
                onChange={(e) => setSubcategoria(e.target.value)}
                label='Tipo Transaccion'
                name="tipo transaccion"
                size="small"
                fullWidth
            />
            </Box>

            <Button
                onClick={() => createProduct(subcategoria)}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !subcategoria}
            >
                {" "}
                CREAR{" "}
            </Button>



        </>
    );
};
