import {
    Container,
    Table,
    Typography,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Paper,
    Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import { db, storage } from "../../../firebase";

import Swal from "sweetalert2";

import EditIcon from "@mui/icons-material/Edit";

import ClearIcon from "@mui/icons-material/Clear";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
    Grid,
    Box,
    InputLabel,
    Button,
    Divider,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
} from "@mui/material";
/*  import { UpdatePlanes } from "./UpdatePlanes"; */

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { UpdateZona } from "./UpdateZona";
import { DeleteZona } from "./DeleteZona";
/* import { DeletePlan } from "./DeletePlan"; */


export const Zonas = () => {

    const [total, setTotal] = useState(0);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [nombreZona, setNombreZona] = useState();

    const [codigoZona, setCodigoZona] = useState("");

    const [data, setData] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);
    function Estado({ estado }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 'VIGENTE') {
            name = "VIGENTE";
            color = "success";
        } else if (estado === 'INACTIVO') {
            name = "No factible";
            color = "info";
        }
        return <Chip label={name} color={color} />;
    }
    useEffect(() => {
        LoadData();
    }, []);

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("zonas");
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });
    };

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'codigo', headerName: 'CODIGO', width: 100 },
        { field: 'zona', headerName: 'ZONA', width: 130 },
        { field: 'nombre', headerName: 'NOMBRE', width: 160 },
        { field: 'ubicacion', headerName: 'UBICACION', width: 250 },
        {
            field: '1', headerName: 'EDITAR', width: 100,
            renderCell: (params) => {
              return <UpdateZona props={params.row} />
            }
          },
         {
          field: '2', headerName: 'ELIMINAR', width: 100,
          renderCell: (params) => {
            return <DeleteZona props={params.row} />
          }
        },
    ];

    const csvOptions = { delimiter: ';' };

    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    return (
        <>

            <Box py={2} px={2}>
                <Paper>
                    <Box sx={{ height: 800, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            // disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                            //hideFooterPagination
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}

                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

            {/* {data && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Nombre de Plan</TableCell>
                  <TableCell>Codigo Plan</TableCell>
                  <TableCell>Velocidad de subida</TableCell>
                  <TableCell>Velocidad de bajada</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Comparticion</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.nombrePlanes}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.codigoPlan}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.velocidadSubida}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.velocidadBajada}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.valor}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.comparticion}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.tipo}
                    </TableCell>
                    <TableCell component="th" scope="row">
                   <UpdatePlanes props={row}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )} */}
        </>
    );
};
