import {
  Grid,
  Box,
  Button,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Card,
  CardContent,
  TableContainer,
  CardActions,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material"; import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


export const PuntosDeEmision = () => {

  const data = [{}]

  return (
    <>
      <Box px={2}>
        <Card>
          <Box paddingLeft={2}>
            <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>INGRESO Y MODIFICACION DE PARAMETROS</strong></p>
          </Box>
          <CardContent>
            <Grid container spacing={5} >
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box py={1} px={1} style={{ margin: "20px" }}>
                    <Grid container spacing={7}>
                      <Grid item xs={4}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>NOMBRE:</strong></p>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            //value={0992988061001}
                            // onChange={(e) => setNombrePlan(e.target.value)}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                        <FormControl fullWidth>
                          <Select
                            sx={{
                              '& legend': { display: 'none' },
                              '& fieldset': { top: 0 }
                            }}
                            //value={plantilla}
                            //onChange={(e) => plantillaHandler(e.target.value)}
                            size="small"
                          >
                            <MenuItem value='ACTIVO'>ACTIVO</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <br /> <br />
              <Grid item xs={12}>
                <Paper elevation={3}>
                  <Box py={1} px={1} style={{ margin: "20px" }}>
                    <Grid container spacing={5}>
                      <Grid item xs={3}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            //value={0992988061001}
                            // onChange={(e) => setNombrePlan(e.target.value)}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>VALOR:</strong></p>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            //value={0992988061001}
                            // onChange={(e) => setNombrePlan(e.target.value)}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ORDEN:</strong></p>
                        <FormControl fullWidth>
                          <TextField
                            type="text"
                            //value={0992988061001}
                            // onChange={(e) => setNombrePlan(e.target.value)}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          /*onClick={() => setOpen(true)}*/
                          style={{ marginTop: "30px" }}
                          color="success" size='small' variant="contained"
                          startIcon={<AddIcon />}>
                          <strong>AGREGAR</strong>
                        </Button>
                      </Grid>

                    </Grid>
                  </Box>
                </Paper>
              </Grid>


            </Grid>
            <TableContainer component={Paper}>
              <br />
              <Table size="small" aria-label="purchases" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>DESCRIPCION</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>VALOR</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>ORDEN</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>EDITAR</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>ELIMINAR</strong></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data && data.map((row) => (
                    <TableRow>
                      <TableCell>{''}</TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>
                        <Tooltip title="MODIFICAR">
                          <Button /*onClick={() => setOpenEditPeriodoContable(true)}*/>
                            <EditIcon />
                          </Button>
                        </Tooltip>
                      </strong>
                      </TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>
                        <Tooltip title="ELIMINAR">
                          <Button /*onClick={() => onData()}*/>
                            <DeleteIcon color="error" />
                          </Button>
                        </Tooltip>
                      </strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table >
            </TableContainer >
          </CardContent >
        </Card >
      </Box >
    </>
  )
}
