import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from 'react-redux';

import VisibilityIcon from "@mui/icons-material/Visibility";

import { listsPlanes } from "../../../redux/actions/parametersActions";

import { detallesSolicitud } from '../../../redux/actions/ventasActions';

export const ViewInformationSolicitudesRechazadas = ({ props }) => {

    const dispatch = useDispatch()

    const { planes, loading: loadingPlanes } = useSelector(state => state.planesList)
    const [nombres, setNombre] = useState(false);
    const [cedula, setCedula] = useState(false);
    const [direccion, setDireccion] = useState(false);
    const [coordenadas, setCoordenadas] = useState(false);
    const [observaciones, setObservaciones] = useState({ campo: "", valido: true });

    const [celular, setCelular] = useState(false);
    const [movil, setMovil] = useState(false);
    const [numbRef, setNumbRef] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [plan, setPlan] = useState([]);

    const [planValor, setPlanValor] = useState();

    const [coordenadasLat, setCoordenadasLat] = useState(false);
    const [coordenadasLong, setCoordenadasLong] = useState(false);

    const [open, setOpen] = useState(false);
    const [estado, setEstado] = useState(props.estado);
    const [cajas, setCajas] = useState(false);


    useEffect(() => {
        console.log(props)
        setCoordenadasLat(props.coordenadas._lat)
        setCoordenadasLong(props.coordenadas._long)
    }, []);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handlePlan = (e) => {
        //console.log(e)
        setPlan(e)
        const p = planes.filter((item) => item.nombrePlanes === e);
        const plan = p[0]
        setPlanValor(plan.valor)
        //console.log(plan)
      }

    const siFactible = (id) => {
        //Validaciones
        db.collection("solicitudes")
            .doc(id)
            .update({
                estado: 2,
                observacion: observaciones
            })
            .then((docRef) => {
                console.log("licencia ", docRef);
                Swal.fire({
                    icon: "success",
                    title: "Has confirmado que hay factibilidad.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const handleSubmit = async (id) => {

        db.collection("solicitudes").doc(id).update({
            estado: 0,
            coordenadas: new firebase.firestore.GeoPoint(
                coordenadasLat,
                coordenadasLong
              ),
        }).then(() => {
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha actualizado la ubicacion con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });

        /* db.collection("solicitudes")
            .doc(id)
            .update({
                nombres: nombres,
                observacion: observaciones,
                cedula: cedula,
                direccion: direccion,
                caja: cajas
            })
            .then((docRef) => {
                Swal.fire({
                    icon: "success",
                    title: "Se ha actualizado la informacion exitosamente.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            }); */
    }



    const MostrarId = (id) => {
        console.log(id)
    };

    return (
        <>
            <Tooltip title="Editar Informacion">
                <Button onClick={handleClickOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">

                <DialogTitle>Numero de solicitud {props.numeroSolicitud} - vendedor: {props.cedula}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Se creo{" "}
                        {moment(props.created.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </DialogContentText>

                   <Box py={2}>
                        <TextField
                            fullWidth
                            label="Coordenadas"
                            value={coordenadasLat}

                            onChange={(e) => setCoordenadasLat(e.target.value)}
                        />
                    </Box>

                   <Box py={2}>
                        <TextField
                            fullWidth
                            label="Coordenadas"
                            value={coordenadasLong}

                            onChange={(e) => setCoordenadasLong(e.target.value)}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>

                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button onClick={() => handleSubmit(props.id)} variant="contained">
                        {" "}
                        Actualizar
                    </Button>
                    {/* <Button onClick={() => MostrarId(props.id)} variant="contained">
                        {" "}
                        Id
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};