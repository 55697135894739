import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import DataUsageIcon from '@mui/icons-material/DataUsage';
export const ListNetworking = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    const [openVent2, setOpenVent2] = useState(false)
    return (
        <>

         {userAuth?.roles?.find(role => ['admin']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <PodcastsIcon  />
                    </ListItemIcon>
                    <ListItemText primary="Networking" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton style={{ paddingLeft: 50 }} component={RouterLink} to={`networking/configured/`}>
                            <ListItemIcon>
                            <PodcastsIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary="OLT" />
                        </ListItemButton>
                    </List>
                    <List  component="div" disablePadding dense>
                        <ListItemButton style={{ paddingLeft: 50 }}  onClick={() => setOpenVent2(!openVent2)}>
                            <ListItemIcon>
                                <DataUsageIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary="RADIUS" />
                            {openVent2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openVent2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                <ListItemButton style={{ paddingLeft: 50 }} component={RouterLink} to={`networking/radius/list-user`}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText style={{ paddingLeft: 10 }}  primary="LIST USER" />
                                </ListItemButton>
                            </List>
                            <List component="div" disablePadding dense>
                                <ListItemButton style={{ paddingLeft: 50 }} component={RouterLink} to={`networking/radius/new-user`}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText style={{ paddingLeft: 10 }} primary="NEW USER" />
                                </ListItemButton>
                            </List>
                            <List component="div" disablePadding dense>
                                <ListItemButton style={{ paddingLeft: 50 }} component={RouterLink} to={`networking/radius/quick-user`}>
                                    <ListItemIcon>
                                    </ListItemIcon>
                                    <ListItemText style={{ paddingLeft: 10 }} primary="QUICK USER" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        {/* <ListItemButton component={RouterLink} to={`networking/radius/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="RADIUS" />
                        </ListItemButton> */}
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}