import { Radio, Tabs, Typography, Box, Tab, RadioGroup, Button, FormLabel, Divider, Select, FormControl, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { NuevaZona } from './NuevaZona';
import { Zonas } from './Zonas';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const ZonasPage = () => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Zonas" {...tabs(0)} />
                <Tab label="Nueva Zona" {...tabs(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Zonas />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <NuevaZona />
            </TabPanel>
        </>
    )
}