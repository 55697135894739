import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from '@mui/material'
import React, { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import InputValidation from '../../components/utils/InputValidation'
import { FileUpload } from '../utils/FileUpload';
import Swal from 'sweetalert2';
import { db } from '../../firebase'

export default function SubirVoucher({ props }) {
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false)
    const [file, setFile] = useState('')
    const [voucher, setVocuher] = useState({ campo: "", valido: true })
    //
    useEffect(() => {
        setFile(props.foto_foto_vaucher)
    }, [])

    const onSubmit = () => {
        if (window.confirm("¿Estas seguro de enviar el voucher a verificacion?") == true) {
            db.collection('agenda').doc(data.numContrato).update({
                estado_pagado: 1,
                num_pago: voucher.campo
            }).then((res) => {
                setOpen(false)
                Swal.fire({ icon: "success", text: "El voucher se envio a verificacion", });
            })
            db.collection('tecnicos').doc(data.numContrato).update({
                estado_pagado: 1,
                num_pago: voucher.campo
            }).then((res) => {

            })
        }
    }
    const onData = () => {
        setOpen(true)
        console.log(props)
        setData(props)
    }
    return (
        <>
            <Button
                onClick={() => onData()}
            >
                <EditIcon />
            </Button>
            <Dialog

                fullScreen
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>SUBIR VOUCHER {data.numContrato}</DialogTitle>
                <DialogContent dividers>
                    <InputValidation
                        estado={voucher}
                        cambiarEstado={setVocuher}
                        label="NUMERO VOUCHER*"
                        name="voucher"
                    />
                    
                    {props && props.foto_foto_vaucher ?
                        <>
                          
                            <img width={100} src={props.foto_foto_vaucher} />
                            <Alert>Archivo subido</Alert>
                        </>
                        :
                        <FileUpload
                            solicitud={data}
                            cambiarEstado={setFile}
                            estado={file}
                            nameFile="foto_foto_vaucher"
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                    >Cerrar ventana</Button>
                    <Button
                        color='success'
                        variant='contained'
                        disabled={
                            !props.foto_foto_vaucher && !voucher.campo
                        }
                        onClick={() => onSubmit()}
                    >ENVIAR A VERIFICACION</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
