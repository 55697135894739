import React, { useEffect, useState, useRef } from "react";
import { db, firebase, storage } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Container,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableContainer
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx/xlsx.mjs";
import SearchIcon from '@mui/icons-material/Search';
import { listsCentrosDeCostos } from "../../../redux/actions/parametersActions";

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const NuevoAsiento = () => {

    const [cuenta, setCuenta] = useState('')

    const [glosa, setGlosa] = useState('')

    const [fecha, setFecha] = useState(null);

    const [hasta, setHasta] = useState(null);

    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [Imagen, setImagen] = useState(null);
    const dispatch = useDispatch()

    const [item, setItem] = useState('')
    const [productos, setProductos] = useState('')

    const [filtro, setFiltro] = useState('')

    const [centroCosto, setCentroCosto] = useState('')

    const [centrosDeCostos, setCentrosDeCostos] = useState('')

    const [itemCentroCosto, setItemCentroCosto] = useState('')

    const [proyecto, setProyecto] = useState('')

    const [tipo, setTipo] = useState('')

    const [proyectosData, setProyectosData] = useState('')

    const [indexRow, setIndexRow] = useState('')

    const [itemProyecto, setItemProyecto] = useState('')

    const getCuentas = () => {
        var docRef = db.collection("plan_cuentas_test")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getCentrosCostos = () => {
        var docRef1 = db.collection("centros_de_costos")
        docRef1.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setCentrosDeCostos(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const getProyectos = () => {
        var docRef1 = db.collection("contabilidad_proyectos")
        docRef1.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setProyectosData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const centrosDeCostosList = useSelector(state => state.centrosDeCostosList)
    const { centroDeCostos, loadingCentroDeCosto } = centrosDeCostosList
    const [centroDeCosto, setCentroDeCosto] = useState('')

    const [inputFields, setInputFields] = useState([]);

    const [totalDebe, setTotalDebe] = useState('')

    const [totalHaber, setTotalHaber] = useState('')

    useEffect(() => {
        getCuentas()
        getCentrosCostos()
        getProyectos()
        loadNumsAsiento()
        contadorDebe()
        contadorHaber()
    }, [inputFields, totalDebe, totalHaber]);


    const onHandleClick = async () => {
        console.log('Ok')
    }

    const [data, setData] = useState();

    const [dataExcel, setDataExcel] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const obtenerId = () => {
        const p = data.filter((item) => item.codigoProducto);
        console.log(data)
        console.log(`p ${p}`);
    }

    const [openCaja, setOpenCaja] = React.useState(false);

    const [message, setMessage] = useState();

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };


    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setDataExcel(jsonData)
                jsonData.forEach((obj) => {
                    db.collection("productos")
                        .doc(obj.CODIGO_PRODUCTO)
                        .set({
                            codigo_producto: obj.CODIGO_PRODUCTO === '' ||
                                obj.CODIGO_PRODUCTO === null ||
                                obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO,

                            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),

                            descripcion_producto: obj.DESCRIPCION === '' ||
                                obj.DESCRIPCION === null ||
                                obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                            categoria_producto: obj.CATEGORIA === '' ||
                                obj.CATEGORIA === null ||
                                obj.CATEGORIA === undefined ? '' : obj.CATEGORIA.toUpperCase(),

                            subcategoria_producto: obj.SUBCATEGORIA === '' ||
                                obj.SUBCATEGORIA === null ||
                                obj.SUBCATEGORIA === undefined ? '' : obj.SUBCATEGORIA.toUpperCase(),

                            presentacion_producto: obj.PRESENTACION === '' ||
                                obj.PRESENTACION === null ||
                                obj.PRESENTACION === undefined ? '' : obj.PRESENTACION.toUpperCase(),

                            marca_producto: obj.MARCA === '' ||
                                obj.MARCA === null ||
                                obj.MARCA === undefined ? '' : obj.MARCA.toUpperCase(),

                            modelo_producto: obj.MODELO === '' ||
                                obj.MODELO === null ||
                                obj.MODELO === undefined ? '' : obj.MODELO.toUpperCase(),

                            unidad_Medida_Producto: obj.UNIDAD_MEDIDA === '' ||
                                obj.UNIDAD_MEDIDA === null ||
                                obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                            costo_estandar_producto: obj.COSTO_ESTANDAR === '' ||
                                obj.COSTO_ESTANDAR === null ||
                                obj.COSTO_ESTANDAR === undefined ? 0 : obj.COSTO_ESTANDAR,

                            precio_compra_producto: obj.PRECIO_COMPRA === '' ||
                                obj.PRECIO_COMPRA === null ||
                                obj.PRECIO_COMPRA === undefined ? 0 : obj.PRECIO_COMPRA,

                            precio_venta_producto: obj.PRECIO_VENTA === '' ||
                                obj.PRECIO_VENTA === null ||
                                obj.PRECIO_VENTA === undefined ? 0 : obj.PRECIO_VENTA,

                            cantidad_producto: obj.CANTIDAD === '' ||
                                obj.CANTIDAD === null ||
                                obj.CANTIDAD === undefined ? 0 : obj.CANTIDAD,

                            estado_producto: obj.ESTADO_PRODUCTO === '' ||
                                obj.ESTADO_PRODUCTO === undefined ||
                                obj.ESTADO_PRODUCTO === 'ACTIVO' ? 0 : 1,

                            stock_minimo_producto: obj.STOCK_MINIMO === '' ||
                                obj.STOCK_MINIMO === null ||
                                obj.STOCK_MINIMO === undefined ? 0 : obj.STOCK_MINIMO,

                            stock_seguridad_producto: obj.STOCK_SEGURIDAD === '' ||
                                obj.STOCK_SEGURIDAD === null ||
                                obj.STOCK_SEGURIDAD === undefined ? 0 : obj.STOCK_SEGURIDAD,

                            stock_maximo_producto: obj.STOCK_MAXIMO === '' ||
                                obj.STOCK_MAXIMO === null ||
                                obj.STOCK_MAXIMO === undefined ? 0 : obj.STOCK_MAXIMO,

                            /* imagen_producto: imagenUrl, */
                            usuario_que_creo_el_producto: userInfo.displayName
                        })
                });
                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const [openDialogCentrosCostos, setOpenDialogCentrosCostos] = useState(false);

    const [openDialogProyectos, setOpenDialogProyectos] = useState(false);

    const columns = [
        { field: 'cuenta', headerName: 'CUENTA', width: 150 },
        { field: 'nombre', headerName: 'NOMBRE', width: 150 },
        { field: 'estado', headerName: 'ESTADO', width: 150 },
    ];

    const addCount = (row) => {
        console.log(row.nombre)
        setCuenta(row.nombre)
        setOpenDialogCuentas(false)
    }

    const addCentroCosto = (row) => {
        setCentroCosto(row.centro_de_costo)
        setOpenDialogCentrosCostos(false)
    }

    const addProyecto = (row) => {
        console.log(inputFields, '...', row)
        setProyecto(row.proyecto)
        setOpenDialogProyectos(false)
    }

    const [open, setOpen] = useState();

    const [openModalConfirm, setOpenModalConfirm] = useState();

    const addInput = () => {
        console.log(inputFields)
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }

    const handleChangeDebe = async (index, evnt) => {

        console.log(inputFields)

        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                //
                setInputFields(list);

            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }

    const handleChangeProyecto = async () => {
        setOpenDialogProyectos(true)
    }

    const handleChangeProyectoDialog = async (index, evnt) => {

        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                //
                setInputFields(list);

            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }

    const removeInputFields = (index) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }

    function detalleMate(data, index) {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell style={{ fontSize: '12px' }} component="th" scope="row">
                    {index + 1}
                </TableCell>

                {/* CAMPO CUENTA */}

                <TableCell align="center">
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        name={"cuenta"}
                        value={data.cuenta}
                        onChange={(e) => handleChangeDebe(index, e)}
                        size="small"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setOpenDialogCuentas(true)}>
                                    <SearchIcon color='primary' />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                </TableCell>

                {/* CAMPO DEBE */}

                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        disabled={
                            parseFloat(data.haber) === 0 ||
                            parseFloat(data.haber) > 0 ||
                            parseFloat(data.haber) < 0
                        }
                        name={"debe"}
                        onChange={(e) => {
                            handleChangeDebe(index, e)
                            console.log(`DATA DEBE ${data.haber}`)
                        }}
                        value={data.debe}
                        size='small'
                        type='number'
                    />
                </TableCell>

                {/* CAMPO HABER */}

                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        disabled={
                            parseFloat(data.debe) === 0 ||
                            parseFloat(data.debe) > 0 ||
                            parseFloat(data.debe) < 0
                        }
                        name={"haber"}
                        onChange={(e) => {
                            handleChangeDebe(index, e)
                            console.log(`DATA DEBE ${data.debe}`)
                        }}
                        value={data.haber}
                        size='small'
                        type='number'
                    />
                </TableCell>

                {/* CAMPO CENTRO DE COSTO */}

                <TableCell align="center">
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        onChange={(e) => handleChangeDebe(index, e)}
                        value={data.centro_costo}
                        name={"centro_de_costo"}
                        size="small"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => centroDeCostosFunctions(index)}>
                                    <SearchIcon color='primary' />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                </TableCell>

                {/* CAMPO PROYECTO */}

                <TableCell align="center">
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={data.proyecto}
                        name={"proyecto"}
                        onChange={(e) => handleChangeDebe(index, e)}
                        size="small"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    handleChangeProyecto()
                                }}>{/* onClick={() => setOpenDialogProyectos(true)} */}
                                    <SearchIcon color='primary' />
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                </TableCell>

                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)} color='error'>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>

            </TableRow>
        )
    }

    const [numAsiento, setNumAsiento] = useState()

    const loadNumsAsiento = () => {
        const ref = db.collection("asientos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_asiento + 1;
                setNumAsiento(numeroSolicitud)
            }
        })
    }

    const handleConfirm = () => {
        console.log(inputFields)
        inputFields.forEach(async function (obj, index) {

            const numeroAsiento = 'ASIENTO-' + numAsiento
            const dataRef = {
                /* debe: obj.debe,
                haber: obj.haber,
                centro_de_costo: obj.centro_de_costo,
                proyecto: obj.proyecto, */
                created: firebase.firestore.FieldValue.serverTimestamp(),
                fecha: fecha,
                glosa: glosa.toUpperCase(),
                numero_asiento: numAsiento,
                asiento: numeroAsiento,
                estado: estado === true ? 0 : 1,
                total_debe: totalDebe,
                total_haber: totalHaber
            }

            const dataRef2 = {
                debe: obj.debe === undefined || obj.debe === null || obj.debe === '' ? '' : obj.debe,
                haber: obj.haber === undefined || obj.haber === null || obj.haber === '' ? '' : obj.haber,
                cuenta_contable: obj.cuenta,
                centro_de_costo: obj.centro_de_costo,
                proyecto: obj.proyecto,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                fecha: fecha,
                glosa: glosa.toUpperCase(),
                numero_asiento: numAsiento,
                asiento: numeroAsiento,
                total_debe: totalDebe,
                total_haber: totalHaber
            }

            console.log(numeroAsiento)

            db.collection(`asientos`).doc(numeroAsiento).set(dataRef)

            db.collection('asientos').doc(numeroAsiento).collection('items').doc().set(dataRef2)

            setOpenModalConfirm(false)

            setOpen(false)

            Swal.fire({ icon: "success", text: 'Se ha registrado el asiento con exito' });

            /* db.collection(`asientos`).doc(numeroAsiento).set(dataRef)

            db.collection('asientos').doc(numeroAsiento).collection('items').doc().set(dataRef2)

            setOpenModalConfirm(false)
            setOpen(false)

            Swal.fire({ icon: "success", text: 'Se ha registrado el asiento con exito' }); */

        });
    }

    const contadorDebe = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.debe)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.debe);
                }
                const total = contenido[i]["debe"] + contenido[i]["debe"]
                /* list[i]["precio_total"] = total ? total : 0 */
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                /* var docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                }); */

            }
            return suma;
        }
        const list = inputFields
        const total = sumarContenidoArreglo(inputFields)
        setTotalDebe(total)
    }

    const contadorHaber = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.haber)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.haber);
                }
                const total = contenido[i]["haber"] + contenido[i]["haber"]
                /* list[i]["precio_total"] = total ? total : 0 */
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                /* var docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                }); */

            }
            return suma;
        }
        const list = inputFields
        const total = sumarContenidoArreglo(inputFields)
        setTotalHaber(total)
    }

    const [estado, setEstado] = React.useState(false);

    const handleEstado = (event) => {
        setEstado(event.target.checked);
    };

    const centroDeCostosFunctions = (index) => {
        console.log(inputFields)
        console.log(index)
        setOpenDialogCentrosCostos(true)
        /* setIndexRow(index)
        console.log(indexRow) */
    }

    return (
        <>

            {/* <Tooltip title="NUEVO">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip> */}

            <Button
                onClick={() => setOpen(true)}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
                color="success" size='small' variant="contained"
                startIcon={<AddIcon />}>
                <strong>NUEVO</strong>
            </Button>

            <br />
            <Dialog open={open} fullScreen onClose={() => setOpen(false)}>
                <DialogContent>
                    <Card>

                        <Box paddingTop={2} paddingLeft={2}>
                            <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>REGISTRAR ASIENTO</strong></p>
                        </Box>

                        <CardContent>

                            <Card>
                                <CardContent>
                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FECHA:</strong></p>

                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        renderInput={(props) => <TextField {...props} sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 }
                                                        }} size="small" />}
                                                        value={fecha}
                                                        onChange={(newValue) => {
                                                            setFecha(newValue);
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>


                                        </Grid>

                                        <Grid item xs={6}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>GLOSA:</strong></p>

                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={glosa}
                                                onChange={(e) => setGlosa(e.target.value)}
                                                name="codigoProducto"
                                                size="small"
                                                fullWidth
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <Box pl={1} pb={1}>
                                    <FormControlLabel
                                        label={
                                            <Typography sx={{ fontSize: 15 }}>
                                                Gasto no Deducible
                                            </Typography>
                                        }
                                        control={
                                            <Checkbox
                                                checked={estado}
                                                onChange={handleEstado}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                    />
                                </Box>

                                <Box pb={1}>
                                    <Button
                                        onClick={() => addInput()}
                                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                                        color="secondary" size='small' variant="contained"
                                        startIcon={<AddIcon />}>
                                        <strong>AGREGAR DETALLE</strong>
                                    </Button>
                                </Box>

                                {/* <Button
                                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                                    color="pr" size='small' variant="outlined"
                                    startIcon={<AddIcon />}>
                                    <strong>AGREGAR DETALLE</strong>
                                </Button> */}

                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NO.</TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>CUENTA</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>DEBE</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>HABER</strong></TableCell>
                                                <TableCell align="center" style={{ /* width: '120px', */ fontSize: '12px' }}><strong>CENTRO DE COSTO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>PROYECTO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>ELIMINAR</strong></TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                inputFields.map((data, index) => {
                                                    return (
                                                        detalleMate(data, index)
                                                    )
                                                })
                                            }
                                        </TableBody>
                                        <TableRow>
                                            <TableCell colSpan={9}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}></TableCell>
                                            {/* <TableCell align="center">{totalHistorico ? totalHistorico.toFixed(2) : 0.00}</TableCell> */}


                                            {
                                                totalDebe === totalHaber ?
                                                    <>
                                                        <TableCell align="center"><Typography variant='h6'><strong>${totalDebe && totalDebe.toFixed(2)}</strong> </Typography></TableCell>
                                                        <TableCell align="center"><Typography variant='h6'><strong>${totalHaber && totalHaber.toFixed(2)}</strong> </Typography></TableCell>
                                                    </>
                                                    : <>
                                                        <TableCell align="center"><Typography style={{ color: "red" }} variant='h6'><strong>${totalDebe && totalDebe.toFixed(2)}</strong> </Typography></TableCell>
                                                        <TableCell align="center"><Typography style={{ color: "red" }} variant='h6'><strong>${totalHaber && totalHaber.toFixed(2)}</strong> </Typography></TableCell>
                                                    </>
                                            }

                                            <TableCell colSpan={2}></TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>

                            </Card>

                        </CardContent>
                        <CardActions>

                            <br />
                            <br />
                            <Button
                                disabled={
                                    fecha === '' ||
                                    glosa === '' ||
                                    totalDebe != totalHaber ||
                                    inputFields.length === 0
                                }
                                onClick={() => { setOpenModalConfirm(true) }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation>
                                {" "}
                                GUARDAR{" "}
                            </Button>
                        </CardActions>
                    </Card>
                </DialogContent>
            </Dialog>

            {/* DIALOG CUENTAS*/}
            <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UNA CUENTA</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={productos}
                                    getOptionLabel={(option) => option.cuenta}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        setItem(newInputValue)
                                        setCuenta(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CUENTA" />}
                                />
                            </Box>

                            {/* <DataGrid
                                rows={productos ? productos : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                density="compact"
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,
                                }}
                                onStateChange={(state) => {
                                    setTotal(state.pagination.rowCount)
                                }}
                            /> */}

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>GRUPO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productos && productos
                                        .filter((val) => {
                                            if (item === "") {
                                                return val;
                                            } else if (val.cuenta.toLowerCase().includes(item.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.cuenta}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.estado}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCount(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>


            {/* DIALOG CENTROS COSTOS*/}
            <Dialog open={openDialogCentrosCostos} fullWidth onClose={() => setOpenDialogCentrosCostos(false)} maxWidth="md">
                <DialogTitle><strong>SELECCIONAR UN CENTRO DE COSTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={centrosDeCostos}
                                    getOptionLabel={(option) => option.centro_de_costo}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(itemCentroCosto)
                                        setItemCentroCosto(newInputValue)
                                        setCentroCosto(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CENTRO DE COSTO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CENTRO DE COSTO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {centrosDeCostos && centrosDeCostos
                                        .filter((val) => {
                                            if (itemCentroCosto === "") {
                                                return val;
                                            } else if (val.centro_de_costo.toLowerCase().includes(itemCentroCosto.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.centro_de_costo}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <Button
                                                        name={"centroDeCosto"}
                                                        size='small'
                                                        variant='contained'
                                                        color='success' style={{ fontSize: '11px' }}
                                                        /* onClick={() => addCentroCosto(indexRow, row)} */
                                                        onClick={() => handleChangeDebe(indexRow, row.centro_de_costo)}
                                                    >
                                                        Seleccionar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogCentrosCostos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCentrosCostos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG PROYECTOS*/}
            <Dialog open={openDialogProyectos} fullWidth onClose={() => setOpenDialogProyectos(false)} maxWidth="md">
                <DialogTitle><strong>SELECCIONAR UN CENTRO DE COSTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={proyectosData}
                                    getOptionLabel={(option) => option.centro_de_costo}
                                    onInputChange={(e, newInputValue) => {
                                        /* handleChangeProyecto(e) */
                                        console.log(itemCentroCosto)
                                        setItemProyecto(newInputValue)
                                        setProyecto(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CENTRO DE COSTO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>PROYECTO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {proyectosData && proyectosData
                                        .filter((val) => {
                                            if (itemProyecto === "") {
                                                return val;
                                            } else if (val.proyecto.toLowerCase().includes(itemProyecto.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.proyecto}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    /* onClick={() => addProyecto(row)} */
                                                    onClick={() => addProyecto(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogProyectos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogProyectos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

            {/* Modal Confirm*/}
            <Dialog open={openModalConfirm} fullWidth onClose={() => setOpenModalConfirm(false)} maxWidth="xs">
                <DialogTitle><strong>ESTA SEGURO DE REGISTRAR ESTE ASIENTO</strong></DialogTitle>

                <DialogActions>
                    <Button onClick={() => setOpenModalConfirm(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => handleConfirm()} size="small" variant="contained" color="secondary">
                        {" "}
                        CONFIRMAR
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
};
