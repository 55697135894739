import { Box, Button, Card, CardContent, CircularProgress, Collapse, Dialog, DialogTitle, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { GridToolbarFilterButton, GridToolbarContainer, GridToolbarQuickFilter, DataGrid, esES } from '@mui/x-data-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';


export const Transacciones = () => {
    let current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getFullYear() + '-' + (current.getMonth() + 1) + "-01"))
    const [data, setData] = useState([])
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);


    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }


    useEffect(() => {
        LoadData()
    }, [])

    function LoadData() {
        setLoad(true)
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let inicio = moment(startDate.toString()).format('YYYY-MM-DD')
        let fin = moment(endDate.toString()).format('YYYY-MM-DD')
        fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispTransacciones/?inicio=${inicio}%2000:00:00&fin=${fin}%2023:59:59`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                re.reverse()
                setData(re)
                setLoad(false)
            }).catch(error => {
                console.log('error', error)
                setLoad(false)
            });
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    const columns = [
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Id', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Cedula', width: 100 },
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', width: 300 },
        { field: 'nfactura', headerClassName: 'super-app-theme--header', headerName: '#Factura', width: 100 },
        { field: 'fecha_pago', headerClassName: 'super-app-theme--header', headerName: 'Fecha de Pago', width: 220 },
        { field: 'transaccion', headerClassName: 'super-app-theme--header', headerName: '#Transaccion', width: 100 },

        { field: 'NUM_CONTRATO', headerClassName: 'super-app-theme--header', headerName: 'Contrato', width: 200 },
        {
            field: 'cobrado', headerClassName: 'super-app-theme--header', headerName: 'Cobrado', width: 100,
            valueGetter: ({ value }) => '$ ' + value
        },
        { field: 'forma_pago', headerClassName: 'super-app-theme--header', headerName: 'Forma de Pago', width: 300 },

        { field: 'descripcion_pago', headerClassName: 'super-app-theme--header', headerName: 'Descripcion Pago', width: 150 },
    ];

    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>TRANSACCIONES</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Grid container >

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                                                <Box display="flex" alignItems='center' >
                                                    <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                                                </Box>
                                            </Box>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <ReactDatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                />
                                                <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box display='flex' style={{ textTransform: 'uppercase' }} >
                                            <Typography variant="button">
                                                <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('DD/MM/YYYY')}
                                            </Typography >
                                            <Typography variant="button" style={{ paddingLeft: 10 }}>
                                                <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                                >
                                    {" "}
                                    DESCARGAR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{
                                        height: 600, width: '100%', '& .super-app-theme--header': {
                                            backgroundColor: '#672780',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        },
                                    }}>
                                        <DataGrid
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                            }}
                                            density="compact"
                                            loading={load}
                                            hideFooterSelectedRowCount={true}
                                            rows={data ? data : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                </Paper>

                                <ExcelExport onExportComplete={handleExport2} data={data} fileName={"Transacciones " +
                                    (startDate ? moment(startDate.toString()).format('YYYY-MM-DD') : '') + " - " +
                                    (endDate ? moment(endDate.toString()).format('YYYY-MM-DD') : '') + " .xlsx"}
                                    ref={exporter} filterable={true} >
                                    <ExcelExportColumn field="id" title="ID" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="cedula" title="Cedula" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="nombre" title="Nombre" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="NUM_CONTRATO" title="Contrato" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="nfactura" title="#Factura" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="fecha_pago" title="FECHA PAGO" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="transaccion" title="#Transaccion" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="cobrado" title="Cobrado" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="forma_pago" title="Forma Pago" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="descripcion_pago" title="Descripción Pago" headerCellOptions={{ background: "#672780" }} width={200} />
                                </ExcelExport>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>

        </>
    )
}
