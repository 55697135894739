import { TextField, Button, Box } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";

import { db } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";

import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const UpdatePhotoVendedor = ({ props }) => {

  const handleSubmit = (id) => {

    db.collection("agenda")
      .doc(id)
      .update({
        estado: 2,
      })
      .then((docRef) => {
        setOpen(false)
        Swal.fire({
          icon: "success",
          text: "Se ha actualizado la informacion con exito.",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

  }

  const [open, setOpen] = useState(false);
  useEffect(() => {
  }, []);
  return (
    <>
      <Tooltip title="Editar Fotos">
        <Button onClick={() => setOpen(true)}>
          <VisibilityIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>
          ACTUALIZAR FOTOS DE: {props.nombres}
        </DialogTitle>
        <DialogContent dividers>
        </DialogContent>
        <DialogActions>
          <Button  onClick={() => setOpen(false)} >
            CERRAR VENTANA
          </Button>
          {/* <Button onClick={() => handleSubmit(props.id)} variant="contained">
            {" "}
            Actualizar Fotos
          </Button> */}
          <Button component={RouterLink}
            disableElevation
            style={{color: 'white'}}
            to={`/ventas/actualizar-documento/${props.uid}`} variant="contained">
            Enviar Actualizacion
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
