import React, { useState, useEffect } from 'react'
import { Tabs, Typography, Box, Tab, Card, CardContent } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { SolicitudesAprobadas } from './SolicitudesAprobadas';
import { SolicitudesEnEspera } from './SolicitudesEnEspera';
import { SolicitudesRechazadas } from './SolicitudesRechazadas';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
          {value === index && (
              <Box>
                  {children}
              </Box>
          )}
      </div>
  );
}
export const FactibilidadPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      setTab(parseInt(params.id))
    }
  }, [params.id])
  return (
    <>
      <Box pl={2} pb={4} pt={2}>
        <Typography variant="h5" component="h1"><strong>FACTIBILIDAD</strong></Typography>
      </Box>
      <Tabs
        value={tab}
        onChange={(event, newValue) => setTab(newValue)}
        aria-label="solicitud de servicio">
        <Tab label="Solicitudes Aprobadas" />
        <Tab label="Solicitudes en Espera" />
        <Tab label="Solicitudes Rechazadas" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <SolicitudesAprobadas />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <SolicitudesEnEspera />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <SolicitudesRechazadas />
      </TabPanel>
    </>
  )
}