import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Box,
    Paper
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import moment from 'moment'
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { db } from "../../../firebase";

export const SeriesMaterialesAsignados = ({ props, email, id, flota }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        /* LoadData() */
    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [data, setData] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])

    const [total, setTotal] = useState(0);

    const LoadData = async (startAfterDoc, persistMessages = []) => {

        setTimeout(async function () {

            console.log('props', props)

            console.log('id', id)

            const agendaDB2 = await db.collection("flotas").where("numeroFlota", "==", flota).limit(1).get()

            const data2 = agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB3 = await db.collection("flotas").doc(data2[0].id).collection('materiales').where('codigo_producto', '==', props.codigo_producto).get()

            const data3 = agendaDB3.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            let ref = db.collection("flotas").doc(data2[0].id).collection('materiales').doc(data3[0].id).collection('series').where('estado', '==', 0)

            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }, 2500);

    };

    const columns = [
        { field: 'serie', headerName: 'SERIE', width: 200 },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total :{total}</Box>
        </div>
    }

    return (
        <>
            <Tooltip title="VER SERIES">
                <Button onClick={() => {
                    console.log('id', id)
                    console.log('props.id', props.id)
                    LoadData()
                    onData()
                    /* console.log('props', props)
                    console.log('props.id', props.id)
                    console.log('email', email)
                    console.log('id', id) */
                }}>
                    <QrCodeScannerIcon />
                </Button>
            </Tooltip>

            {/* LISTA DE OPCIONES */}

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>SERIES PERTENECIENTES A: {props.descripcion}</strong>
                </DialogTitle>
                <DialogContent>

                    <p style={{ fontSize: "13px" }}><strong>CODIGO: </strong>{props.codigo_producto}</p>

                    <Paper>
                        <Box sx={{ height: 450, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                // disableColumnFilter
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,

                                }}

                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>



        </>
    );
};