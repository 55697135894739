import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, Typography, Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { CrearNueva } from './CrearNueva';
import { Lista } from './Lista';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
export const CondicionesPagoPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id))
    }
  }, [params.id])
  const tabla = 'cuentas_pagar/parametros/condiciones_pago'
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1">CUENTAS POR PAGAR/<strong>CONDICIONES DE PAGO</strong></Typography>
        </Box>
        <Box sx={{ maxWidth: { xs: 300, sm: '100%' }, bgcolor: 'background.paper' }}>
          <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
            <Tab label="Crear Nueva" />
            <Tab label="Lista" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <CrearNueva tabla={tabla} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Lista tabla={tabla} />
          </TabPanel>
        </Box>
      </Container>
    </>
  )
}