import React, { useEffect, useState, useRef } from "react";
import { CrearProductoGeneral } from './CrearProductoGeneral';
import { CrearProductoInformacionTecnica } from './CrearProductoInformacionTecnica';
import { CrearProductoLocalidad } from './CrearProductoLocalidad';
import { CrearProductoReferenciasYNotas } from './CrearProductoReferenciasYNotas';

import {
    Grid,
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    IconButton,
    Table,
    DialogActions,
    InputAdornment,
    Stepper,
    Step,
    StepLabel,
    Paper
} from "@mui/material";

import {
    listsTiposActivosFijos,
    listsClaseDeProducto,
    listsUnidadMedidaFirebase,
    listsVidaUtil,
    listsMetodoDeDepreciacion,
    listsPorcentajeDeDepreciacion,
    listsFrecuenciaDeDepreciacion,
    listsPresentacionProducto,
    listsMarcaFirebase,
    listsModeloFirebase,
    listsCondicionDeActivo,
    listsLocalidadProducto,
    listsDepartamento,
    listsTipoDeProducto,
    listsTipoTransaccion,
    listsSubcategoriaActivos,
    listsResidual,
    listsCategoriaActivos,
    listsUnidadMedidaActivos,
    listsMarcaActivos,
    listsModeloActivos
} from "../../redux/actions/parametersActions";

import { useDispatch, useSelector } from 'react-redux';

import SearchIcon from '@mui/icons-material/Search';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { db, storage } from "../../firebase";

import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";

const steps = ['GENERAL', 'INFORMACION TECNICA', 'LOCALIDAD', 'DEPRECIACION', 'SEGUROS', 'REFERENCIAS Y NOTAS'];

export default function CrearProductoSteeper() {

    let navigate = useNavigate()

    const getCuentas = () => {
        var docRef = db.collection("plan_cuentas_test")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const [item, setItem] = useState('')

    const [productos, setProductos] = useState('')

    /* GENERAL */

    const tipoDeProductList = useSelector(state => state.tipoDeProductList);
    const { tipoDeProductos, loadingtipoDeProductos } = tipoDeProductList
    const [tipoDeProducto, setTipoDeProducto] = useState('')

    const [codigoProducto, setCodigoProducto] = useState('')

    const [cuenta, setCuenta] = useState('')

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const [descripcionProducto, setDescripcionProducto] = useState('')

    const categoriaActivosList = useSelector((state) => state.categoriaActivosList);
    const { categoriaActivos, loadingCategoriaActivos } = categoriaActivosList;
    const [categoriaActivosOnly, setCategoriaActivos] = useState('');

    const subcategoriaActivosList = useSelector((state) => state.subcategoriaActivosList);
    const { subcategoriaActivos, loadingSubcategoriaActivos } = subcategoriaActivosList;
    const [subcategoriaActivosOnly, setSubcategoriaActivos] = useState('');

    const claseDeProductoList = useSelector(state => state.claseDeProductoList);
    const { claseDeProductos, loadingclaseDeProductos } = claseDeProductoList
    const [claseDeProducto, setClaseDeProducto] = useState('')

    const [cantidad, setCantidad] = useState('')

    const unidadMedidaActivosList = useSelector(state => state.unidadMedidaActivosList);
    const { unidadMedidaActivos, loadingUnidadMedidaActivos } = unidadMedidaActivosList
    const [unidadMedidaProducto, setUnidadMedidaProducto] = useState('')

    const [costo, setCosto] = useState('')

    const [fecha, setFecha] = useState(null)

    const addCount = (row) => {
        console.log(row.nombre)
        setCuenta(row.CUENTA)
        setOpenDialogCuentas(false)
    }

    const dispatch = useDispatch()



    /* INFORMACION TECNICA */

    const marcaActivosList = useSelector(state => state.marcaActivosList);
    const { marcaActivos, loadingMarcaActivos } = marcaActivosList
    const [marcaproducto, setMarcaProducto] = useState('')

    const modeloActivosList = useSelector(state => state.modeloActivosList);
    const { modeloActivos, loadingModeloActivos } = modeloActivosList
    const [modeloproducto, setModeloProducto] = useState('')

    const [anioModelo, setAnioModelo] = useState('')

    const [placa, setPlaca] = useState('')

    const [numeroSerie, setNumeroSerie] = useState('')

    const [ultimoMantenimiento, setUltimoMantenimiento] = useState('')

    const [seguimientoMantenimiento, setSeguimientoMantenimiento] = useState('')

    const [informacion1, setInformacion1] = useState('')

    const [informacion2, setInformacion2] = useState('')

    const [informacionTecnica, setInformacionTecnica] = useState('')

    const [informacionTecnica2, setInformacionTecnica2] = useState('')

    const [fechaGarantia, setFechaGarantia] = useState('')

    const condicionDeActivoList = useSelector((state) => state.condicionDeActivoList);
    const { condicionDeActivo, loadingCondicionDeActivo } = condicionDeActivoList;
    const [condicionDeActivoOnly, setCondicionDeActivoOnly] = useState("");

    /* LOCALIDAD */

    const localidadProductoList = useSelector((state) => state.localidadProductoList);
    const { localidadDeProductos, loadingLocalidadDeProductos } = localidadProductoList;
    const [localidadDeProducto, setLocalidadDeProducto] = useState("");

    const [memoriaDeLocalidad, setMemoriaDeLocalidad] = useState('')

    const [archivadorPercha, setArchivadorPercha] = useState('')

    const departamentoList = useSelector((state) => state.departamentoList);
    const { departamentos, loadingDepartamentos } = departamentoList;
    const [departamento, setDepartamento] = useState("");

    const [usuarios, setUsuarios] = useState('')
    const [users, setUsers] = useState('')

    const [openDialogUsuarios, setOpenDialogUsuarios] = useState(false);

    const [itemUsuarios, setItemUsuarios] = useState('')

    const getUsers = () => {
        var docRef = db.collection("usuarios")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(arrayMessage)
            setUsuarios(arrayMessage)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    const addCountUsers = (row) => {
        console.log(row.nombre)
        setUsers(row.nombre)
        setOpenDialogUsuarios(false)
    }

    const [codigoDeBarra, setCodigoDeBarra] = useState('')

    const [notaDeConcesion, setNotaDeConcesion] = useState('')

    const [propietarioDelTitulo, setPropietarioDelTitulo] = useState('')

    const [numeroDeReferenciaGis, setNumeroDeReferenciaGis] = useState('')




    /* DEPRECIACION */




    const vidaUtilList = useSelector((state) => state.vidaUtilList);
    const { vidaUtil, loadingVidaUtil } = vidaUtilList;
    const [vidaUtilOnly, setVidaUtilOnly] = useState("");

    const porcentajeDeDepreciacionList = useSelector((state) => state.porcentajeDeDepreciacionList);
    const { porcentajeDeDepreciacion, loadingPorcentajeDeDepreciacion } = porcentajeDeDepreciacionList;
    const [porcentajeDeDepreciacionOnly, setPorcentajeDeDepreciacionOnly] = useState("");

    const metodoDeDepreciacionList = useSelector((state) => state.metodoDeDepreciacionList);
    const { metodoDeDepreciacion, loadingMetodoDeDepreciacion } = metodoDeDepreciacionList;
    const [metodoDeDepreciacionOnly, setMetodoDeDepreciacionOnly] = useState("");

    const frecuenciaDeDepreciacionList = useSelector((state) => state.frecuenciaDeDepreciacionList);
    const { frecuenciaDeDepreciacion, loadingFrecuenciaDeDepreciacion } = frecuenciaDeDepreciacionList;
    const [frecuenciaDeDepreciacionOnly, setFrecuenciaDeDepreciacionOnly] = useState("");

    const tipoTransaccionList = useSelector((state) => state.tipoTransaccionList);
    const { tipoTransaccion, loadingTipoTransaccion } = tipoTransaccionList;
    const [tipoTransaccionOnly, setTipoTransaccionOnly] = useState("");

    const residualList = useSelector((state) => state.residualList);
    const { residual, loadingResidual } = residualList;
    const [residualOnly, setResidualOnly] = useState("");


    const [fechaCompra, setFechaCompra] = useState('')

    const [fechaTermino, setFechaTermino] = useState('')





    /* SEGUROS */



    const [polizaNo, setPolizaNo] = useState('')
    const [vigenciaDocumento, setVigenciaDocumento] = useState('')
    const [caducidadDocumento, setCaducidadDocumento] = useState('')
    const [sumaAsegurada, setSumaAsegurada] = useState('')
    const [duracionSeguro, setDuracionSeguro] = useState('')
    const [nombreAsegurado, setNombreAsegurado] = useState('')
    const [observaciones, setObservaciones] = useState('')
    const [proveedor, setProveedor] = useState('')




    /* REFERENCIAS Y NOTAS */

    const [referencias, setReferencias] = useState('')

    const [comentarios, setComentarios] = useState('')

    const [localidadDelDocumento, setLocalidadDelDocumento] = useState('')

    const [reestriccionesDeCancelacion, setReestriccionesDeCancelacion] = useState('')

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

    /* VEHICULO */

    const [vehiculo, setVehiculo] = useState('');

    /*  */

    const leerCodigo = async (e) => {
        const agendaDB3 = await db.collection("activos_fijos").where("codigo_producto", "==", e).get()
        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log(data3[0].codigo_producto)

        if (data3[0].codigo_producto === undefined) {
            console.log('nulo')
        } else {
            Swal.fire({ icon: "warning", text: 'El codigo que digito ya se encuentra ingresado' });
        }
    }

    useEffect(() => {
        dispatch(listsTiposActivosFijos())
        dispatch(listsTipoDeProducto())
        dispatch(listsClaseDeProducto())
        dispatch(listsPresentacionProducto())
        dispatch(listsUnidadMedidaFirebase())
        dispatch(listsVidaUtil())
        dispatch(listsMetodoDeDepreciacion())
        dispatch(listsPorcentajeDeDepreciacion())
        dispatch(listsFrecuenciaDeDepreciacion())
        dispatch(listsMarcaFirebase())
        dispatch(listsModeloFirebase())
        dispatch(listsCondicionDeActivo())
        dispatch(listsLocalidadProducto())
        dispatch(listsDepartamento())
        dispatch(listsTipoTransaccion())
        dispatch(listsSubcategoriaActivos())
        dispatch(listsResidual())
        dispatch(listsCategoriaActivos())
        dispatch(listsUnidadMedidaActivos())
        dispatch(listsMarcaActivos())
        dispatch(listsModeloActivos())
        getCuentas()
        getUsers()
        /* leerCodigo() */
    }, [dispatch]);

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [ref, setRef] = useState(null);
    const useStorage = storage;

    var imagenUrl;

    const handleSubmit = async () => {

        const newRef = useStorage.ref('imagenes_activos_fijos').child(`${codigoProducto + descripcionProducto.toUpperCase()}`); // nombre del archivo
        setRef(newRef);
        await newRef.put(Imagen);
        const urlImagen = await newRef.getDownloadURL()
        imagenUrl = urlImagen;
        console.log('la url de la imagen es' + urlImagen);

        console.log('Enviado')
        db.collection('activos_fijos').doc().set({
            tipo_de_producto: tipoDeProducto,
            codigo_producto: codigoProducto,
            cuenta_contable_mayor: cuenta,
            descripcion_producto: descripcionProducto.toUpperCase(),
            sub_categoria: subcategoriaActivosOnly,
            clase_de_producto: claseDeProducto,
            categoria: categoriaActivosOnly,
            cantidad: cantidad,
            unidad_medida: unidadMedidaProducto,
            costo: costo,
            fecha: fecha,
            marca_producto: marcaproducto,
            modelo_producto: modeloproducto,
            anio_Modelo: anioModelo,
            vehiculo: vehiculo === 'true' ? 'SI' : 'NO',
            placa: placa === '' ? 'SN' : placa,
            numero_serie: numeroSerie,
            ultimo_mantenimiento: ultimoMantenimiento === '' ? 'SN' : ultimoMantenimiento,
            seguimiento_mantenimiento: seguimientoMantenimiento === '' ? 'SN' : seguimientoMantenimiento,
            informacion1: informacion1 === '' ? 'SN' : informacion1,
            fecha_garantia: fechaGarantia,
            informacion_tecnica: informacionTecnica === '' ? 'SN' : informacionTecnica,
            informacion_tecnica2: informacionTecnica2 === '' ? 'SN' : informacionTecnica2,
            informacion2: informacion2 === '' ? 'SN' : informacion2,
            condicion_de_activo: condicionDeActivoOnly,
            localidad_de_producto: localidadDeProducto,
            memoria_de_localidad: memoriaDeLocalidad === '' ? 'SN' : memoriaDeLocalidad,
            archivador_percha: archivadorPercha === '' ? 'SN' : archivadorPercha,
            departamento: departamento,
            responsable: users,
            codigo_de_barra: codigoDeBarra === '' ? 'SN' : codigoDeBarra,
            nota_de_concesion: notaDeConcesion === '' ? 'SN' : notaDeConcesion,
            propietario_del_titulo: propietarioDelTitulo === '' ? 'SN' : propietarioDelTitulo,
            numero_de_referencia_gis: numeroDeReferenciaGis === '' ? 'SN' : numeroDeReferenciaGis,

            vida_util: vidaUtilOnly,
            porcentaje_de_depreciacion: porcentajeDeDepreciacionOnly,
            metodo_de_depreciacion: metodoDeDepreciacionOnly,
            frecuencia_de_depreciacion: frecuenciaDeDepreciacionOnly,
            fecha_compra: fechaCompra,
            fecha_termino: fechaTermino,

            poliza_no: polizaNo === '' ? 'SN' : polizaNo,
            vigencia_documento: vigenciaDocumento === '' ? 'SN' : vigenciaDocumento,
            caducidad_documento: caducidadDocumento === '' ? 'SN' : caducidadDocumento,
            suma_asegurada: sumaAsegurada === '' ? 'SN' : sumaAsegurada,
            duracion_seguro: duracionSeguro === '' ? 'SN' : duracionSeguro,
            nombre_asegurado: nombreAsegurado === '' ? 'SN' : nombreAsegurado,
            observaciones: observaciones === '' ? 'SN' : observaciones,
            proveedor: proveedor === '' ? 'SN' : proveedor,

            referencias: referencias === '' ? 'SN' : referencias,
            comentarios: comentarios === '' ? 'SN' : comentarios,
            localidad_del_documento: localidadDelDocumento === '' ? 'SN' : localidadDelDocumento,
            reestricciones_de_cancelacion: reestriccionesDeCancelacion === '' ? 'SN' : reestriccionesDeCancelacion,
            imagen_activo_fijo: imagenUrl,
        })

        setOpenDialogConfirm(false)

        navigate("/activos-fijos/crear-producto/")

        Swal.fire({ icon: "success", text: "Se ha creado el activo fijo con exito", });


    }

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [Imagen, setImagen] = useState(null);

    const [viewImagen, setViewImagen] = useState(null)

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }



    return (
        <Box px={2} py={1}>
            <Paper elevation={3} >
                <Box px={2} py={2}>
                    <Paper elevation={1}>
                        <Box px={2} py={2}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                    </Paper>

                    {/* GENERAL */}

                    {activeStep === 0 ? <>

                        <Box py={2}>
                            <Paper elevation={3}>
                                <Box px={2} py={2}>


                                    <Box pb={2}>
                                        <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>IDENTIFICACION</strong></p>
                                    </Box>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            {/* IDENTIFICACION */}


                                            <Box pb={2}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>IDENTIFICACION</strong></p>
                                            </Box>

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>TIPOS DE ACTIVOS FIJOS:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={tipoDeProducto}
                                                            label="TIPO DE ACTIVO FIJO"
                                                            onChange={(e) => setTipoDeProducto(e.target.value)}>
                                                            {tipoDeProductos &&
                                                                tipoDeProductos.map(item => (
                                                                    <MenuItem key={item.activo_fijo} value={item.activo_fijo} >
                                                                        {item.activo_fijo}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* CODIGO */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CODIGO DE ACTIVO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={codigoProducto}
                                                            onChange={(e) => {
                                                                setCodigoProducto(e.target.value)
                                                                leerCodigo(e.target.value)
                                                            }}
                                                            name="codigoProducto"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* CTA CONTABLE */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CUENTA CONTABLE MAYOR:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cuenta}
                                                        /* onChange={(e) => setCuenta(e.target.value)} */
                                                        name="codigoProducto"
                                                        size="small"
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                <IconButton onClick={() => setOpenDialogCuentas(true)}>
                                                                    <SearchIcon color='primary' />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Box py={4}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>DESCRIPCION</strong></p>
                                            </Box>

                                            {/* Descripcion */}
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>NOMBRE:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={descripcionProducto}
                                                        onChange={(e) => setDescripcionProducto(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Box py={4}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>TIPO</strong></p>
                                            </Box>


                                            {/* SUBCATEGORIA */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>SUBCATEGORIA:</p>
                                                </Grid>

                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={subcategoriaActivosOnly}
                                                            label="CATEGORIA"
                                                            onChange={(e) => setSubcategoriaActivos(e.target.value)}>
                                                            {subcategoriaActivos &&
                                                                subcategoriaActivos.map(item => (
                                                                    <MenuItem key={item.subcategoria_activos} value={item.subcategoria_activos} >
                                                                        {item.subcategoria_activos}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* CLASE DE PRODUCTO */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CLASE DE ACTIVO:</p>
                                                    </Grid>

                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={claseDeProducto}
                                                                label="SUBCATEGORIA"
                                                                onChange={(e) => setClaseDeProducto(e.target.value)}>
                                                                {claseDeProductos &&
                                                                    claseDeProductos.map(item => (
                                                                        <MenuItem key={item.clase_de_producto} value={item.clase_de_producto} >
                                                                            {item.clase_de_producto}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* CATEGORIA */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CATEGORIA:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={categoriaActivosOnly}
                                                            label="CATEGORIA"
                                                            onChange={(e) => setCategoriaActivos(e.target.value)}>
                                                            {categoriaActivos &&
                                                                categoriaActivos.map(item => (
                                                                    <MenuItem key={item.categoria_activos} value={item.categoria_activos} >
                                                                        {item.categoria_activos}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={6}>

                                            {/* CANTIDAD */}

                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>CANTIDAD</strong></p>


                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cantidad}
                                                        onChange={(e) => setCantidad(e.target.value)}
                                                        name="cantidadProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* UNIDAD DE MEDIDA */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>UNIDAD MEDIDA:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={unidadMedidaProducto}
                                                                label="PRESENTACION"
                                                                onChange={(e) => setUnidadMedidaProducto(e.target.value)}>
                                                                {unidadMedidaActivos &&
                                                                    unidadMedidaActivos.map(item => (
                                                                        <MenuItem key={item.unidad_medida} value={item.unidad_medida} >
                                                                            {item.unidad_medida}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* COSTO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>COSTO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={costo}
                                                        onChange={(e) => setCosto(e.target.value)}
                                                        name="costoProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* FECHA */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                            <FormControl fullWidth>
                                                                <DatePicker
                                                                    renderInput={(props) => <TextField {...props} size="small" />}
                                                                    label="Seleccione"
                                                                    value={fecha}
                                                                    onChange={(newValue) => {
                                                                        setFecha(newValue);
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            </Box>


                                        </Grid>

                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>

                        {/* DIALOG CUENTAS*/}
                        <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                            <DialogTitle><strong>SELECCIONAR UNA CUENTA</strong></DialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>

                                        <Box pb={2}>
                                            <Autocomplete
                                                size='small'
                                                disablePortal
                                                id="combo-box-demo"
                                                options={productos}
                                                getOptionLabel={(option) => option.cuenta}
                                                onInputChange={(e, newInputValue) => {
                                                    console.log(item)
                                                    setItem(newInputValue)
                                                    setCuenta(newInputValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} label="BUSCAR CUENTA" />}
                                            />
                                        </Box>

                                        <Table size="small" stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                                    <TableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></TableCell>
                                                    <TableCell style={{ fontSize: '11px' }}><strong>GRUPO</strong></TableCell>
                                                    <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {productos && productos
                                                    .filter((val) => {
                                                        if (item === "") {
                                                            return val;
                                                        } else if (val.CUENTA.toLowerCase().includes(item.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell style={{ fontSize: '11px' }}>{row.cuenta}</TableCell>
                                                            <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                            <TableCell style={{ fontSize: '11px' }}>{row.estado}</TableCell>
                                                            <TableCell style={{ fontSize: '11px' }}><Button
                                                                size='small'
                                                                variant='contained'
                                                                color='success' style={{ fontSize: '11px' }}
                                                                onClick={() => addCount(row)}
                                                            >Seleccionar</Button></TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>

                                    </Box>
                                </FormControl>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                                <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                                    {" "}
                                    ACEPTAR
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>

                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button variant="contained" color="primary" onClick={handleNext} disabled={tipoDeProducto === ''
                                || codigoProducto === ''
                                || cuenta === ''
                                || descripcionProducto === ''
                                || subcategoriaActivosOnly === ''
                                || claseDeProducto === ''
                                || categoriaActivosOnly === ''
                                || cantidad === ''
                                || unidadMedidaProducto === ''
                                || costo === ''
                                || fecha === ''
                                /* || vidaUtilOnly === ''
                                || porcentajeDeDepreciacionOnly === ''
                                || metodoDeDepreciacionOnly === ''
                                || frecuenciaDeDepreciacionOnly === '' */
                            }>
                                {activeStep === steps.length - 1 ? 'Finish' : 'CONTINUAR'}
                            </Button>
                        </Box>
                    </> : ''}





                    {/* INFORMACION TECNICA */}





                    {activeStep === 1 ? <>

                        {/* <CrearProductoInformacionTecnica /> */}

                        <Box py={2}>
                            <Paper elevation={3}>
                                <Box px={2} py={2}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>INFORMACION TECNICA</strong></p>
                                    </Box>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            {/* CODIGO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>MARCAS:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={marcaproducto}
                                                            label="MARCA"
                                                            onChange={(e) => setMarcaProducto(e.target.value)}>
                                                            {marcaActivos &&
                                                                marcaActivos.map(item => (
                                                                    <MenuItem key={item.marca_activos} value={item.marca_activos} >
                                                                        {item.marca_activos}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* MODELO */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>MODELO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={modeloproducto}
                                                                label="MODELO"
                                                                onChange={(e) => setModeloProducto(e.target.value)}>
                                                                {modeloActivos &&
                                                                    modeloActivos.map(item => (
                                                                        <MenuItem key={item.modelo_activos} value={item.modelo_activos} >
                                                                            {item.modelo_activos}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* ANIO MODELO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>ANIO MODELO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={anioModelo}
                                                        onChange={(e) => setAnioModelo(e.target.value)}
                                                        name="anioModelo"
                                                        size="small"
                                                        fullWidth
                                                        type='number'
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* VEHICULO */}


                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>VEHICULO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Select
                                                            fullWidth
                                                            size="small"
                                                            value={vehiculo}
                                                            onChange={(e) => setVehiculo(e.target.value)}
                                                        >
                                                            <MenuItem value="true">SI</MenuItem>
                                                            <MenuItem value="false">NO</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* PLACA */}

                                            {
                                                vehiculo === 'true' ? <Box py={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={2}>
                                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>PLACA:</p>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={placa}
                                                                onChange={(e) => setPlaca(e.target.value)}
                                                                name="placa"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box> : ''
                                            }

                                            {/* NUMERO SERIE */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>NUMERO SERIE:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={numeroSerie}
                                                        onChange={(e) => setNumeroSerie(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Box py={4}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ACTUALIZAR</strong></p>
                                            </Box>

                                            {/* ULTIMO MANTENIMIENTO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>ULTIMO MANTENIMIENTO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={ultimoMantenimiento}
                                                        onChange={(e) => setUltimoMantenimiento(e.target.value)}
                                                        name="ultimoMantenimiento"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* SEGUIMIENTO MANTENIMIENTO */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>SEGUIMIENTO MANTENIMIENTO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={seguimientoMantenimiento}
                                                            onChange={(e) => setSeguimientoMantenimiento(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* INFORMACION 1 */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>INFORMACION 1:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={informacion1}
                                                        onChange={(e) => setInformacion1(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                        <Grid item xs={6}>

                                            {/* FECHA GARANTIA */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA GARANTIA:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <FormControl fullWidth>
                                                            <DatePicker
                                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                                label="Seleccione"
                                                                value={fechaGarantia}
                                                                onChange={(newValue) => {
                                                                    setFechaGarantia(newValue);
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>

                                            {/* INFORMACION TECNICA */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>INFORMACION TECNICA:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={informacionTecnica}
                                                            onChange={(e) => setInformacionTecnica(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* INFORMACION TECNICA 2 */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>INFORMACION TECNICA 2:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={informacionTecnica2}
                                                        onChange={(e) => setInformacionTecnica2(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* INFORMACION 2 */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>INFORMACION 2:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={informacion2}
                                                            onChange={(e) => setInformacion2(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* CONDICION ACTIVO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CONDICION ACTIVO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={condicionDeActivoOnly}
                                                            label="MODELO"
                                                            onChange={(e) => setCondicionDeActivoOnly(e.target.value)}>
                                                            {condicionDeActivo &&
                                                                condicionDeActivo.map(item => (
                                                                    <MenuItem key={item.condicion_de_activo} value={item.condicion_de_activo} >
                                                                        {item.condicion_de_activo}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Box>
                            </Paper>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="warning"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant="contained"
                            >
                                Regresar
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button variant="contained" color="primary" onClick={handleNext} disabled={marcaproducto === ''
                                || modeloproducto === ''
                                || anioModelo === ''
                                || vehiculo === ''
                                || numeroSerie === ''
                                || fechaGarantia === ''
                                || condicionDeActivoOnly === ''
                            }>
                                {activeStep === steps.length - 1 ? 'Finish' : 'CONTINUAR'}
                            </Button>
                        </Box>
                    </> : ''}



                    {/* LOCALIDAD */}



                    {activeStep === 2 ? <>

                        <Box py={2}>
                            <Paper elevation={3}>
                                <Box px={2} py={2}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>LOCALIDAD</strong></p>
                                    </Box>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            {/* LOCALIDAD */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>LOCALIDAD:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={localidadDeProducto}
                                                            label="MODELO"
                                                            onChange={(e) => setLocalidadDeProducto(e.target.value)}>
                                                            {localidadDeProductos &&
                                                                localidadDeProductos.map(item => (
                                                                    <MenuItem key={item.localidad_de_producto} value={item.localidad_de_producto} >
                                                                        {item.localidad_de_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* MEMORIA DE LOCALIDAD */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>MEMORIA DE LOCALIDAD:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={memoriaDeLocalidad}
                                                            onChange={(e) => setMemoriaDeLocalidad(e.target.value)}
                                                            name="memoriaDeLocalidad"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* ARCHIVADOR / PERCHA */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>ARCHIVADOR / PERCHA:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={archivadorPercha}
                                                            onChange={(e) => setArchivadorPercha(e.target.value)}
                                                            name="archivadorPercha"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* ASIGNACION */}

                                            <Box py={4}>
                                                <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ASIGNACION</strong></p>
                                            </Box>

                                            {/* DEPARTAMENTO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>DEPARTAMENTO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={departamento}
                                                            label="MODELO"
                                                            onChange={(e) => setDepartamento(e.target.value)}>
                                                            {departamentos &&
                                                                departamentos.map(item => (
                                                                    <MenuItem key={item.departamento} value={item.departamento} >
                                                                        {item.departamento}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* RESPONSABLE */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>RESPONSABLE:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            name={"cuenta"}
                                                            value={users}
                                                            onChange={(e) => setUsers(e.target.value)}
                                                            size="small"
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">
                                                                    <IconButton onClick={() => setOpenDialogUsuarios(true)}>
                                                                        <SearchIcon color='primary' />
                                                                    </IconButton>
                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* CODIGO DE BARRA */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CODIGO DE BARRA:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={codigoDeBarra}
                                                            onChange={(e) => setCodigoDeBarra(e.target.value)}
                                                            name="codigoDeBarra"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>


                                        </Grid>

                                        <Grid item xs={6}>

                                            {/* CONCESION */}


                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>CONCESION</strong></p>

                                            {/* NOTA DE CONCESION */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>NOTA DE CONCESION:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={notaDeConcesion}
                                                            onChange={(e) => setNotaDeConcesion(e.target.value)}
                                                            name="notaDeConcesion"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* PROPIETARIO DEL TITULO */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>PROPIETARIO DEL TITULO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={propietarioDelTitulo}
                                                        onChange={(e) => setPropietarioDelTitulo(e.target.value)}
                                                        name="propietarioDelTitulo"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* NUMERO DE REFERENCIA GIS */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>NUMERO DE REFERENCIA GIS:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={numeroDeReferenciaGis}
                                                            onChange={(e) => setNumeroDeReferenciaGis(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* INFORMACION TECNICA */}



                                            {/* INFORMACION TECNICA 2 */}



                                            {/* INFORMACION 2 */}





                                        </Grid>

                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>

                        {/* DIALOG USUARIOS*/}
                        <Dialog open={openDialogUsuarios} onClose={() => setOpenDialogUsuarios(false)} maxWidth="xl">
                            <DialogTitle><strong>SELECCIONAR UN RESPONSABLE</strong></DialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>

                                        <Box pb={2}>
                                            <Autocomplete
                                                size='small'
                                                disablePortal
                                                id="combo-box-demo"
                                                options={usuarios}
                                                getOptionLabel={(option) => option.nombre}
                                                onInputChange={(e, newInputValue) => {
                                                    setItemUsuarios(newInputValue)
                                                    setUsuarios(newInputValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} label="BUSCAR" />}
                                            />
                                        </Box>

                                        <Table size="small" stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {usuarios && usuarios
                                                    .filter((val) => {
                                                        if (itemUsuarios === "") {
                                                            return val;
                                                        } else if (val.nombre.toLowerCase().includes(itemUsuarios.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((row) => (
                                                        <TableRow key={row.nombre}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                            <TableCell style={{ fontSize: '11px' }}><Button
                                                                size='small'
                                                                variant='contained'
                                                                color='success' style={{ fontSize: '11px' }}
                                                                onClick={() => addCountUsers(row)}
                                                            >Seleccionar</Button></TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>

                                    </Box>
                                </FormControl>
                            </DialogContent>

                            <DialogActions>
                                <Button onClick={() => setOpenDialogUsuarios(false)}><strong>Cancelar</strong></Button>
                                <Button onClick={() => setOpenDialogUsuarios(false)} size="small" variant="contained">
                                    {" "}
                                    ACEPTAR
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="warning"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant="contained"
                            >
                                Regresar
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button variant="contained" color="primary" onClick={handleNext} disabled={localidadDeProducto === ''
                                || departamento === ''
                                || users === ''
                            }>
                                {activeStep === steps.length - 1 ? 'Finish' : 'CONTINUAR'}
                            </Button>
                        </Box>
                    </> : ''}




                    {/* DEPRECIACION */}





                    {activeStep === 3 ? <>

                        <Box py={2}>
                            <Paper elevation={3}>
                                <Box px={2} py={2}>

                                    <Box>
                                        <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>DEPRECIACION</strong></p>
                                    </Box>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            {/* VIDA UTIL */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>VIDA UTIL:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={vidaUtilOnly}
                                                                label="VIDA UTIL"
                                                                onChange={(e) => setVidaUtilOnly(e.target.value)}>
                                                                {vidaUtil &&
                                                                    vidaUtil.map(item => (
                                                                        <MenuItem key={item.vida_util} value={item.vida_util} >
                                                                            {item.vida_util}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* PORCENTAJE DE DEPRECIACION */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>%:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={porcentajeDeDepreciacionOnly}
                                                            label="VIDA UTIL"
                                                            onChange={(e) => setPorcentajeDeDepreciacionOnly(e.target.value)}>
                                                            {porcentajeDeDepreciacion &&
                                                                porcentajeDeDepreciacion.map(item => (
                                                                    <MenuItem key={item.procentaje_de_depreciacion} value={item.procentaje_de_depreciacion} >
                                                                        {item.procentaje_de_depreciacion}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* METODO DE DEPRECIACION */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>METODO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={metodoDeDepreciacionOnly}
                                                                label="METODO DE DEPRECIACION"
                                                                onChange={(e) => setMetodoDeDepreciacionOnly(e.target.value)}>
                                                                {metodoDeDepreciacion &&
                                                                    metodoDeDepreciacion.map(item => (
                                                                        <MenuItem key={item.metodo_de_depreciacion} value={item.metodo_de_depreciacion} >
                                                                            {item.metodo_de_depreciacion}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* FRECUENCIA DE DEPRECIACION */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>FRECUENCIA DE DEPRECIACION:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={frecuenciaDeDepreciacionOnly}
                                                            label="VIDA UTIL"
                                                            onChange={(e) => setFrecuenciaDeDepreciacionOnly(e.target.value)}>
                                                            {frecuenciaDeDepreciacion &&
                                                                frecuenciaDeDepreciacion.map(item => (
                                                                    <MenuItem key={item.frecuencia_depreciacion} value={item.frecuencia_depreciacion} >
                                                                        {item.frecuencia_depreciacion}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            {/* TIPO TRANSACCION */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>TIPO TRANSACCION:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <FormControl fullWidth>
                                                            <Select sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                size="small"
                                                                value={tipoTransaccionOnly}
                                                                label="METODO DE DEPRECIACION"
                                                                onChange={(e) => setTipoTransaccionOnly(e.target.value)}>
                                                                {tipoTransaccion &&
                                                                    tipoTransaccion.map(item => (
                                                                        <MenuItem key={item.tipo_transaccion} value={item.tipo_transaccion} >
                                                                            {item.tipo_transaccion}
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            {/* RESIDUAL */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>RESIDUAL:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={residualOnly}
                                                            label="VIDA UTIL"
                                                            onChange={(e) => setResidualOnly(e.target.value)}>
                                                            {residual &&
                                                                residual.map(item => (
                                                                    <MenuItem key={item.residual} value={item.residual} >
                                                                        {item.residual}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>


                                        </Grid>

                                        <Grid item xs={6}>


                                            {/* FECHA COMPRA */}


                                            <Box py={4}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA COMPRA:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <FormControl fullWidth>
                                                            <DatePicker
                                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                                label="Seleccione"
                                                                value={fechaCompra}
                                                                onChange={(newValue) => {
                                                                    setFechaCompra(newValue);
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Box>

                                            {/* FECHA TERMINO */}

                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA TERMINO:</p>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <FormControl fullWidth>
                                                        <DatePicker
                                                            renderInput={(props) => <TextField {...props} size="small" />}
                                                            label="Seleccione"
                                                            value={fechaTermino}
                                                            onChange={(newValue) => {
                                                                setFechaTermino(newValue);
                                                            }}
                                                        />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="warning"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                            variant="contained"
                                        >
                                            Regresar
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />

                                        <Button variant="contained" color="primary" onClick={handleNext} disabled={vidaUtilOnly === ''
                                            || porcentajeDeDepreciacionOnly === ''
                                            || metodoDeDepreciacionOnly === ''
                                            || frecuenciaDeDepreciacionOnly === ''
                                            || fechaCompra === ''
                                            || fechaCompra === ''
                                        }>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'CONTINUAR'}
                                        </Button>
                                    </Box>

                                </Box>
                            </Paper>
                        </Box>

                    </> : ''}





                    {/* SEGUROS */}





                    {activeStep === 4 ?
                        <>
                            <Box py={2}>
                                <Paper elevation={3}>
                                    <Box px={2} py={4}>


                                        <Box pb={2}>
                                            <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>SEGUROS</strong></p>
                                        </Box>

                                        <Grid container spacing={5}>

                                            <Grid item xs={6}>

                                                {/* REFERENCIAS */}

                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>POLIZA NO.:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={polizaNo}
                                                            onChange={(e) => setPolizaNo(e.target.value)}
                                                            name="polizaNo"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {/* COMENTARIOS */}

                                                <Box py={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={2}>
                                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>VIGENCIA DOCUMENTO:</p>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={vigenciaDocumento}
                                                                onChange={(e) => setVigenciaDocumento(e.target.value)}
                                                                name="vigenciaDocumento"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                {/* CADUCIDAD DOCUMENTO */}

                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CADUCIDAD DOCUMENTO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={caducidadDocumento}
                                                            onChange={(e) => setCaducidadDocumento(e.target.value)}
                                                            name="caducidadDocumento"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {/* SUMA ASEGURADA */}

                                                <Box py={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={2}>
                                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>SUMA ASEGURADA:</p>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={sumaAsegurada}
                                                                onChange={(e) => setSumaAsegurada(e.target.value)}
                                                                name="sumaAsegurada"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                {/* DURACION SEGURO */}

                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>DURACION SEGURO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={duracionSeguro}
                                                            onChange={(e) => setDuracionSeguro(e.target.value)}
                                                            name="duracionSeguro"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>



                                            </Grid>

                                            <Grid item xs={6}>

                                                {/* NOMBRE ASEGURADO */}


                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>NOMBRE ASEGURADO:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={nombreAsegurado}
                                                            onChange={(e) => setNombreAsegurado(e.target.value)}
                                                            name="nombreAsegurado"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>

                                                {/* OBSERVACIONES */}

                                                <Box py={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={2}>
                                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>OBSERVACIONES:</p>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                                value={observaciones}
                                                                onChange={(e) => setObservaciones(e.target.value)}
                                                                name="observaciones"
                                                                size="small"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                {/* PROVEEDOR */}


                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>PROVEEDOR:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={proveedor}
                                                            onChange={(e) => setProveedor(e.target.value)}
                                                            name="proveedor"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>

                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button
                                                color="warning"
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                                variant="contained"
                                            >
                                                Regresar
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />

                                            <Button variant="contained" color="primary" onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'CONTINUAR'}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                        </> : ''}







                    {/* REFERENCIAS Y NOTAS */}








                    {activeStep === 5 ? <>

                        <Box py={2}>
                            <Paper elevation={3}>
                                <Box px={2} py={4}>


                                    <Box pb={2}>
                                        <p style={{ fontSize: "16px", marginBottom: "10px" }}><strong>REFERENCIAS Y NOTAS</strong></p>
                                    </Box>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>

                                            {/* REFERENCIAS */}

                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>REFERENCIAS:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={referencias}
                                                        onChange={(e) => setReferencias(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* COMENTARIOS */}

                                            <Box py={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>COMENTARIOS:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={comentarios}
                                                            onChange={(e) => setComentarios(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Box py={2}>
                                                <Button
                                                    onChange={changeImagen}
                                                    variant="contained"
                                                    component="label"
                                                >
                                                    AGREGAR IMAGEN
                                                    <input
                                                        type="file"
                                                        hidden
                                                    />
                                                </Button>
                                            </Box>

                                            <Box py={2}>
                                                <img width={200} src={viewImagen} />
                                            </Box>



                                        </Grid>

                                        <Grid item xs={6}>

                                            {/* LOCALIDAD DEL DOCUMENTO */}


                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>LOCALIDAD DEL DOCUMENTO:</p>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={localidadDelDocumento}
                                                        onChange={(e) => setLocalidadDelDocumento(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>

                                            {/* REESTRICCIONES DE CANCELACION */}

                                            <Box pt={4}>
                                                <Grid container spacing={6}>
                                                    <Grid item xs={2}>
                                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>REESTRICCIONES DE CANCELACION:</p>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={reestriccionesDeCancelacion}
                                                            onChange={(e) => setReestriccionesDeCancelacion(e.target.value)}
                                                            name="reestriccionesDeCancelacion"
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="warning"
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                                variant="contained"
                            >
                                Regresar
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button variant="contained" color="secondary" onClick={() => setOpenDialogConfirm(true)}>
                                CREAR
                            </Button>
                        </Box>

                        {/* CONFIRMAR CREACION*/}
                        <Dialog open={openDialogConfirm} onClose={() => setOpenDialogConfirm(false)} maxWidth="xs">
                            <DialogTitle><strong>¿ESTA SEGURO DE CREAR EL ACTIVO FIJO?</strong></DialogTitle>

                            <DialogActions>
                                <Button onClick={() => setOpenDialogConfirm(false)}><strong>Cancelar</strong></Button>
                                <Button onClick={() => handleSubmit()} size="small" variant="contained" color="secondary">
                                    {" "}
                                    CREAR
                                </Button>
                            </DialogActions>

                        </Dialog>

                    </> : ''}
                </Box>
            </Paper>
        </Box>
    );
}