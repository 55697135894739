import {
    Box, Button, Card, CardContent, Chip,
    CircularProgress, Collapse, Dialog, DialogTitle,
    Grid, LinearProgress, Paper, Stack, Typography
} from '@mui/material';
import {
    GridToolbarFilterButton, GridToolbarContainer,
    GridToolbarQuickFilter, DataGrid, esES
} from '@mui/x-data-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { DetalleFactura } from '../call_center/DetalleFactura';
import { db } from "../../firebase";


export const Debito = () => {
    let current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getFullYear() + '-' + (current.getMonth() + 1) + "-01"))
    const [data, setData] = useState([])
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contratos, setContratos] = useState([]);


    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }


    useEffect(() => {

        if (data.length === 0) {
            LoadData()
            // loadContrato()
        }
    }, [])

    async function loadContrato(data) {
        try {
            const ref = await db.collection("agenda").where('debito', '==', 1).get()
            const arrayMessage = []
            ref.forEach(doc => {
                let info = doc.data()
                arrayMessage.push({
                    id: doc.id,
                    numContrato: info.numContrato,
                    debito: info.debito === 1 ? 'SI' : 'NO',
                    banco: info.banco,
                    numBanco: info.numBanco,
                })
            })
            setContratos(arrayMessage)
            const reData = [
                ...data.map((item) => {
                    return {
                        ...item,
                        debito: (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0] ?
                            (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0].debito)
                            : arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0] ?
                                (arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0].debito)
                                : 'NO'),
                        banco: (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0] ?
                            (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0].banco)
                            : arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0] ?
                                (arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0].banco)
                                : '---'),
                        numBanco: (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0] ?
                            (arrayMessage.filter((item2) => item2.id === item.NUM_CONTRATO)[0].numBanco)
                            : arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0] ?
                                (arrayMessage.filter((item2) => item2.NUM_CONTRATO === item.NUM_CONTRATO)[0].numBanco)
                                : '---'),
                    }
                })
            ]
            setData(reData)

        } catch (error) {
            console.log("error", error);
        }

    }

    function LoadData() {
        try {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            let inicio = moment(startDate.toString()).format('YYYY-MM-DD')
            let fin = moment(endDate.toString()).format('YYYY-MM-DD')
            let ruta = `https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?inicioVencido2=${inicio}&finVencido2=${fin}`
            fetch(ruta, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const re = JSON.parse(result)
                    re.reverse()
                    loadContrato(re)
                })

        } catch (error) {
            console.log("error", error);
        }


    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    const columns = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleFactura id={params.row.id} />
                </>
            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Id', width: 100 },
        { field: 'legal', headerClassName: 'super-app-theme--header', headerName: 'Legal', width: 100 },
        {
            field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 120,
            renderCell: (params) => {
                return <>
                    {params.row.tipo && params.row.tipo === "2" &&
                        <Chip label={"SERVICIOS"} color='info' />}
                    {params.row.tipo && params.row.tipo === "1" &&
                        <Chip label={"LIBRE"} color='success' />}

                </>
            }

        },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Cedula', width: 100 },
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', width: 300 },
        { field: 'NUM_CONTRATO', headerClassName: 'super-app-theme--header', headerName: 'Contrato', width: 200 },
        { field: 'emitido', headerClassName: 'super-app-theme--header', headerName: 'F. Emitido', width: 120 },
        { field: 'vencimiento', headerClassName: 'super-app-theme--header', headerName: 'F. Vencimiento', width: 120 },
        {
            field: 'total', headerClassName: 'super-app-theme--header', headerName: 'Total', width: 100,
            valueGetter: ({ value }) => '$ ' + value
        },
        {
            field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    <Chip label={params.row.estado} color='warning' style={{ textTransform: 'uppercase' }} />
                </>
            }
        },
        { field: 'debito', headerClassName: 'super-app-theme--header', headerName: 'Debito', width: 120 },
        { field: 'banco', headerClassName: 'super-app-theme--header', headerName: 'Banco', width: 250 },
        { field: 'numBanco', headerClassName: 'super-app-theme--header', headerName: 'NumBanco', width: 120 }
        // {
        //     field: 'debito', headerClassName: 'super-app-theme--header', headerName: 'Debito', width: 120,
        //     renderCell: (params) => {
        //         return <>

        //             {contratos.length === 0 && 'CARGANDO'}
        //             {contratos.length > 0 &&
        //                 <>
        //                     {contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0] ?
        //                         (contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0].debito)
        //                         : contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0] ?
        //                             (contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0].debito)
        //                             : '---'
        //                     }
        //                 </>
        //             }
        //         </>
        //     }
        // },
        // {
        //     field: 'banco', headerClassName: 'super-app-theme--header', headerName: 'Banco', width: 250,
        //     renderCell: (params) => {
        //         return <>

        //             {contratos.length === 0 && 'CARGANDO'}
        //             {contratos.length > 0 &&
        //                 <>
        //                     {contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0] ?
        //                         (contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0].banco)
        //                         : contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0] ?
        //                             (contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0].banco)
        //                             : '---'
        //                     }
        //                 </>
        //             }

        //         </>
        //     }
        // },
        // {
        //     field: 'numBanco', headerClassName: 'super-app-theme--header', headerName: 'NumBanco', width: 120,
        //     renderCell: (params) => {
        //         return <>
        //             {contratos.length === 0 && 'CARGANDO'}
        //             {contratos.length > 0 &&
        //                 <>
        //                     {contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0] ?
        //                         (contratos.filter((item2) => item2.id === params.row.NUM_CONTRATO)[0].numBanco)
        //                         : contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0] ?
        //                             (contratos.filter((item2) => item2.NUM_CONTRATO === params.row.NUM_CONTRATO)[0].numBanco)
        //                             : '---'
        //                     }
        //                 </>
        //             }
        //         </>
        //     }
        // },
    ];

    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>FACTURAS NO PAGADAS</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                                                <Box display="flex" alignItems='center' >
                                                    <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                                                </Box>
                                            </Box>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <ReactDatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                />
                                                <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box display='flex' style={{ textTransform: 'uppercase' }} >
                                            <Typography variant="button">
                                                <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('DD/MM/YYYY')}
                                            </Typography >
                                            <Typography variant="button" style={{ paddingLeft: 10 }}>
                                                <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                                >
                                    {" "}
                                    DESCARGAR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{
                                        height: 600, width: '100%', '& .super-app-theme--header': {
                                            backgroundColor: '#672780',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        },
                                    }}>
                                        <DataGrid
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                            }}
                                            density="compact"
                                            loading={data.length === 0}
                                            hideFooterSelectedRowCount={true}
                                            rows={data ? data : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                </Paper>
                                <ExcelExport onExportComplete={handleExport2} data={data} fileName={"Facturas Pendientes" +
                                    (startDate ? moment(startDate.toString()).format('YYYY-MM-DD') : '') + " - " +
                                    (endDate ? moment(endDate.toString()).format('YYYY-MM-DD') : '')
                                    + " .xlsx"}
                                    ref={exporter} filterable={true} >
                                    <ExcelExportColumn field="id" title="ID" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="legal" title="Legal" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="tipo" title="Tipo" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="idcliente" title="Id Cliente" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="nombre" title="Nombre" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="emitido" title="F. Emitido" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="vencimiento" title="F. Vencimiento" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="total" title="Total" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="estado" title="Estado" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="debito" title="Debito" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="banco" title="Banco" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="numBanco" title="NumBanco" headerCellOptions={{ background: "#672780" }} width={200} />
                                </ExcelExport>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={loading || contratos.length === 0} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>

        </>
    )
}
