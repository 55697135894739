import {
  Box, Chip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  esES
} from '@mui/x-data-grid';
import moment from 'moment'
import { UpdateUser } from "./UpdateUser";
export const UsuariosListRH = () => {
  const [total, setTotal] = useState('');
  const [data, setData] = useState();
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("usuarios")
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setData(data);
    });
  };
  const columns2 = [

    { field: 'nombres', headerName: 'NOMBRE', width: 250 },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'email', headerName: 'EMAIL', width: 300 },
    { field: 'password', headerName: 'password', width: 200 },
    {
      field: 'state', headerName: 'ESTADO', width: 125,
      renderCell: (params) => {
        let status = params.row.state === 'online' ? 'success' : 'default'
        let label = params.row.state === 'online' ? 'ONLINE' : 'OFFLINE'
        return <Chip size='small' variant='outlined' color={status} label={label} />
      }
    },
    {
      field: 'last_changed', headerName: 'ULTIMA CONEXCION', type: 'dateTime', width: 175,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'roles', headerName: 'ROLES', width: 300 },
    {
      field: 'acciones', headerName: '', width: 90,
      renderCell: (params) => {
        return <UpdateUser key={params.row.uid} props={params.row} />
      }
    },

  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  function CustomFooterTotalComponent(props) {
    return ''
  }
  return (
    <>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          density="compact"
          rows={data ? data : []}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          columns={columns2}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooterTotalComponent,
          }}
          onStateChange={(state) => {
            setTotal(state.pagination.rowCount);
          }}
        >
        </DataGrid>
      </Box>
    </>
  );
}