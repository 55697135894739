import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import InputValidation from "../utils/InputValidation";
import { listMarcas } from "../../redux/actions/parametersActions";
export const CrearFlotas = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [lider, setLider] = useState({ campo: '', valido: true })
    const [auxiliar, setAuxiliar] = useState({ campo: "", valido: true });
    const [proveedor, setProveedor] = useState({
        campo: "",
        valido: true,
    });
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listMarcas())
    }, [dispatch]);
    const handleMarca = (e) => {
        //console.log(e)
        setMarca(e)
        const p = marcas.filter((item) => item.marca === e);
        const plan = p[0]
        //console.log(plan)
    }
    const [numContrato, setNumContrato] = useState({
        campo: "",
        valido: true,
    })
    const [placa, setPlaca] = useState({
        campo: "",
        valido: true,
    })

    const [correo, setCorreo] = useState({
        campo: "",
        valido: true,
    })

    const [motor, setMotor] = useState({
        campo: "",
        valido: true,
    })
    const [modelo, setModelo] = useState({
        campo: "",
        valido: true,
    })
    const [anio, setAnio] = useState({
        campo: "",
        valido: true,
    })
    const [numeroFlota, setNumeroFlota] = useState({
        campo: "",
        valido: true,
    })
    const [fechaSeguroInicioVigencia, setFechaSeguroInicioVigencia] = useState({
        campo: "",
        valido: true,
    })

    const [fechaSeguroFinVigencia, setFechaSeguroFinVigencia] = useState({
        campo: "",
        valido: true,
    })

    const [fechaGpsInicioVigencia, setFechaGpsInicioVigencia] = useState({
        campo: "",
        valido: true,
    })

    const [fechaGpsFinVigencia, setFechaGpsFinVigencia] = useState({
        campo: "",
        valido: true,
    })

    const [localidad, setLocalidad] = useState('');
    const [gps, setGps] = useState('');
    const [seguro, setSeguro] = useState('');
    const [marca, setMarca] = useState([]);
    const onHandleClick = async () => {
        console.log("Hola");
        const solicitudDb = await db
            .collection("flotas")
            .add({
                nombreLider: lider.campo,
                nombreAuxiliar: auxiliar.campo,
                proveedor: proveedor.campo,
                numContrato: numContrato.campo,
                placa: placa.campo,
                motor: motor.campo,
                anio: anio.campo,
                marca: marca,
                modelo: modelo.campo,
                localidad: localidad,
                numeroFlota: numeroFlota.campo,
                estado: 0,
                gps: gps,
                seguro: seguro,
                inicioVigenciaSeguro: fechaSeguroInicioVigencia.campo,
                FinVigenciaSeguro: fechaSeguroFinVigencia.campo,
                inicioVigenciaGps: fechaGpsInicioVigencia.campo,
                FinVigenciaGps: fechaGpsFinVigencia.campo,
                correo: correo.campo
            })
            .then(() => {
                Swal.fire({ icon: "success", title: "Flota creada con exito" });
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    };

    return (
        <>
            <br />
            <Grid container spacing={7}>
                <Grid item xs={10} md={6}>
                    <InputValidation
                        estado={lider}
                        cambiarEstado={setLider}
                        label="LIDER DE FLOTA"
                        name="lider"
                    />
                    <InputValidation
                        estado={proveedor}
                        cambiarEstado={setProveedor}
                        label="PROVEEDOR"
                        name="proveedor"
                    />
                    <InputValidation
                        estado={placa}
                        cambiarEstado={setPlaca}
                        label="PLACA"
                        name="placa"
                    />
                    <InputValidation
                        estado={anio}
                        cambiarEstado={setAnio}
                        label="AÑO"
                        name="anio"
                    />
                    <InputValidation
                        estado={modelo}
                        cambiarEstado={setModelo}
                        label="MODELO"
                        name="modelo"
                    />
                    <InputValidation
                        estado={numeroFlota}
                        cambiarEstado={setNumeroFlota}
                        label="NUMERO DE FLOTA"
                        name="numeroFlota"
                    />


                    <FormControl fullWidth variant="filled">
                        <InputLabel>SEGURO</InputLabel>
                        <Select
                            value={seguro}
                            onChange={(e) => setSeguro(e.target.value)}
                        >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />

                    {
                        seguro === 'SI' ? [

                            <InputValidation
                                estado={fechaSeguroInicioVigencia}
                                cambiarEstado={setFechaSeguroInicioVigencia}
                                label="FECHA INICIO VIGENCIA SEGURO"
                                name="vigenciaSeguroInicio"
                            />, <InputValidation
                                estado={fechaSeguroFinVigencia}
                                cambiarEstado={setFechaSeguroFinVigencia}
                                label="FECHA FIN VIGENCIA SEGURO"
                                name="vigenciaSeguroFin"
                            />]
                            : ''
                    }
                </Grid>
                <Grid item xs={10} md={6}>
                    <InputValidation
                        estado={auxiliar}
                        cambiarEstado={setAuxiliar}
                        label="AUXILIAR DE FLOTA"
                        name="auxiliar"
                    />
                    <InputValidation
                        estado={numContrato}
                        cambiarEstado={setNumContrato}
                        label="NUMERO DE CONTRATO"
                        name="numContrato"
                    />

                    <InputValidation
                        estado={motor}
                        cambiarEstado={setMotor}
                        label="MOTOR"
                        name="motor"
                    />



                    <InputValidation
                        estado={correo}
                        cambiarEstado={setCorreo}
                        label="CORREO FLOTA"
                        name="CORREO FLOTA"
                    />


                    <FormControl fullWidth variant="filled">
                        <FormControl fullWidth>
                            <InputLabel >MARCA</InputLabel>
                            <Select
                                value={marca}
                                label="MARCA"
                                onChange={(e) => handleMarca(e.target.value)}
                            >
                                {marcas && marcas.map((row, key) => (
                                    <MenuItem key={key} value={row.marca}>{row.marca}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormControl>





                    <Box py={3}>
                        <FormControl fullWidth variant="filled">
                            <InputLabel>LOCALIDAD</InputLabel>
                            <Select
                                value={localidad}
                                onChange={(e) => setLocalidad(e.target.value)}
                            >
                                <MenuItem value="Guayaquil">Guayaquil</MenuItem>
                                <MenuItem value="Duran">Duran</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>GPS</InputLabel>
                        <Select
                            value={gps}
                            onChange={(e) => setGps(e.target.value)}
                        >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <br />
                    {
                        gps === 'SI' ? [



                            <InputValidation
                                estado={fechaGpsInicioVigencia}
                                cambiarEstado={setFechaGpsInicioVigencia}
                                label="FECHA INICIO VIGENCIA GPS"
                                name="vigenciaGpsInicio"
                            />, <InputValidation
                                estado={fechaGpsFinVigencia}
                                cambiarEstado={setFechaGpsFinVigencia}
                                label="FECHA FIN VIGENCIA GPS"
                                name="vigenciaGpsFin"
                            />
                        ] : ''
                    }

                </Grid>
            </Grid>
            <br />
            <Button
                onClick={onHandleClick}
                fullWidth
                variant="contained"
                disabled={!lider.campo || !auxiliar.campo || !proveedor.campo || !numContrato.campo || !placa.campo || !motor.campo || !anio.campo || !marca || !modelo.campo || !localidad || !numeroFlota.campo || !gps || !seguro}
            >
                {" "}
                Enviar{" "}
            </Button>
        </>
    );
};
