export const parametersReducers = (state = { parameters: [] }, action) => {
    switch (action.type) {
        case 'PARAMETERS_LIST_REQUEST':
            return { loading: true }
        case 'PARAMETERS_LIST_SUCCESS':
            return { loading: false, parameters: action.payload }
        case 'PARAMETERS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const productosReducers = (state = { productos: [] }, action) => {
    switch (action.type) {
        case 'PRODUCTOS_LIST_REQUEST':
            return { loading: true }
        case 'PRODUCTOS_LIST_SUCCESS':
            return { loading: false, productos: action.payload }
        case 'PRODUCTOS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const zonasReducers = (state = { zonas: [] }, action) => {
    switch (action.type) {
        case 'ZONAS_LIST_REQUEST':
            return { loading: true }
        case 'ZONAS_LIST_SUCCESS':
            return { loading: false, zonas: action.payload }
        case 'ZONAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const provinciaReducers = (state = { provincias: [] }, action) => {
    switch (action.type) {
        case 'PROVINCIA_LIST_REQUEST':
            return { loading: true }
        case 'PROVINCIA_LIST_SUCCESS':
            return { loading: false, provincias: action.payload }
        case 'PROVINCIA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const FormaPagoRubrosReducer = (state = { formaPagoRubros: [] }, action) => {
    switch (action.type) {
        case 'FORMAPAGORUBROS_LIST_REQUEST':
            return { loading: true }
        case 'FORMAPAGORUBROS_LIST_SUCCESS':
            return { loading: false, formaPagoRubros: action.payload }
        case 'FORMAPAGORUBROS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const kardexReducers = (state = { kardexs: [] }, action) => {
    switch (action.type) {
        case 'KARDEX_LIST_REQUEST':
            return { loading: true }
        case 'KARDEX_LIST_SUCCESS':
            return { loading: false, kardexs: action.payload }
        case 'KARDEX_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const categoriaReducers = (state = { categorias: [] }, action) => {
    switch (action.type) {
        case 'CATEGORIA_LIST_REQUEST':
            return { loading: true }
        case 'CATEGORIA_LIST_SUCCESS':
            return { loading: false, categorias: action.payload }
        case 'CATEGORIA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const subCategoriaReducers = (state = { subCategorias: [] }, action) => {
    switch (action.type) {
        case 'SUBCATEGORIA_LIST_REQUEST':
            return { loading: true }
        case 'SUBCATEGORIA_LIST_SUCCESS':
            return { loading: false, subCategorias: action.payload }
        case 'SUBCATEGORIA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const modeloProductosReducers = (state = { modeloProductos: [] }, action) => {
    switch (action.type) {
        case 'MODELOPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'MODELOPRODUCTO_LIST_SUCCESS':
            return { loading: false, modeloProductos: action.payload }
        case 'MODELOPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const bodegasReducers = (state = { bodegas: [] }, action) => {
    switch (action.type) {
        case 'BODEGAS_LIST_REQUEST':
            return { loading: true }
        case 'BODEGAS_LIST_SUCCESS':
            return { loading: false, bodegas: action.payload }
        case 'BODEGAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ubicacionesReducers = (state = { ubicaciones: [] }, action) => {
    switch (action.type) {
        case 'UBICACIONES_LIST_REQUEST':
            return { loading: true }
        case 'UBICACIONES_LIST_SUCCESS':
            return { loading: false, ubicaciones: action.payload }
        case 'UBICACIONES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const motivoIngresoProductosReducers = (state = { motivoIngresoProductos: [] }, action) => {
    switch (action.type) {
        case 'MOTIVOINGRESOPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'MOTIVOINGRESOPRODUCTO_LIST_SUCCESS':
            return { loading: false, motivoIngresoProductos: action.payload }
        case 'MOTIVOINGRESOPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const motivoEgresoProductosReducers = (state = { motivoEgresoProductos: [] }, action) => {
    switch (action.type) {
        case 'MOTIVOEGRESOPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'MOTIVOEGRESOPRODUCTO_LIST_SUCCESS':
            return { loading: false, motivoEgresoProductos: action.payload }
        case 'MOTIVOEGRESOPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const unidadMedidaProductosReducers = (state = { unidadMedidaProductos: [] }, action) => {
    switch (action.type) {
        case 'UNIDADMEDIDA_LIST_REQUEST':
            return { loading: true }
        case 'UNIDADMEDIDA_LIST_SUCCESS':
            return { loading: false, unidadMedidaProductos: action.payload }
        case 'UNIDADMEDIDA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cantonesReducers = (state = { cantones: [] }, action) => {
    switch (action.type) {
        case 'CANTONES_LIST_REQUEST':
            return { loading: true }
        case 'CANTONES_LIST_SUCCESS':
            return { loading: false, cantones: action.payload }
        case 'CANTONES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const parroquiaReducers = (state = { parroquias: [] }, action) => {
    switch (action.type) {
        case 'PARROQUIA_LIST_REQUEST':
            return { loading: true }
        case 'PARROQUIA_LIST_SUCCESS':
            return { loading: false, parroquias: action.payload }
        case 'PARROQUIA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const sectorReducers = (state = { sectores: [] }, action) => {
    switch (action.type) {
        case 'SECTOR_LIST_REQUEST':
            return { loading: true }
        case 'SECTOR_LIST_SUCCESS':
            return { loading: false, sectores: action.payload }
        case 'SECTOR_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const serviciosReducers = (state = { servicios: [] }, action) => {
    switch (action.type) {
        case 'SERVICIOS_LIST_REQUEST':
            return { loading: true }
        case 'SERVICIOS_LIST_SUCCESS':
            return { loading: false, servicios: action.payload }
        case 'SERVICIOS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const planesReducers = (state = { planes: [] }, action) => {
    switch (action.type) {
        case 'PLANES_LIST_REQUEST':
            return { loading: true }
        case 'PLANES_LIST_SUCCESS':
            return { loading: false, planes: action.payload }
        case 'PLANES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const changePlanesReducers = (state = { planess: [] }, action) => {
    switch (action.type) {
        case 'CHANGE_PLANES_REQUEST':
            return { loading: true }
        case 'CHANGE_PLANES_SUCCESS':
            return { loading: false, planess: action.payload }
        case 'CHANGE_PLANES_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const marcasReducers = (state = { marcas: [] }, action) => {
    switch (action.type) {
        case 'MARCAS_LIST_REQUEST':
            return { loading: true }
        case 'MARCAS_LIST_SUCCESS':
            return { loading: false, marcas: action.payload }
        case 'MARCAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const splitterReducers = (state = { splitter: [] }, action) => {
    switch (action.type) {
        case 'SPLITTER_LIST_REQUEST':
            return { loading: true }
        case 'SPLITTER_LIST_SUCCESS':
            return { loading: false, splitter: action.payload }
        case 'SPLITTER_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const nivelReducers = (state = { nivel: [] }, action) => {
    switch (action.type) {
        case 'NIVEL_LIST_REQUEST':
            return { loading: true }
        case 'NIVEL_LIST_SUCCESS':
            return { loading: false, nivel: action.payload }
        case 'NIVEL_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const nodoReducers = (state = { nodos: [] }, action) => {
    switch (action.type) {
        case 'NODOS_LIST_REQUEST':
            return { loading: true }
        case 'NODOS_LIST_SUCCESS':
            return { loading: false, nodos: action.payload }
        case 'NODOS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const modelosReducers = (state = { modelos: [] }, action) => {
    switch (action.type) {
        case 'MODELO_LIST_REQUEST':
            return { loading: true }
        case 'MODELO_LIST_SUCCESS':
            return { loading: false, modelos: action.payload }
        case 'MODELO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const discapacidadReducers = (state = { discapacidad: [] }, action) => {
    switch (action.type) {
        case 'DISCAPACIDAD_LIST_REQUEST':
            return { loading: true }
        case 'DISCAPACIDAD_LIST_SUCCESS':
            return { loading: false, discapacidad: action.payload }
        case 'DISCAPACIDAD_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const tipoNombreCuentasReducers = (state = { tipoNombreCuentas: [] }, action) => {
    switch (action.type) {
        case 'TIPONOMBRECUENTAS_LIST_REQUEST':
            return { loading: true }
        case 'TIPONOMBRECUENTAS_LIST_SUCCESS':
            return { loading: false, tipoNombreCuentas: action.payload }
        case 'TIPONOMBRECUENTAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const naturalezaCuentaReducers = (state = { naturalezaCuentas: [] }, action) => {
    switch (action.type) {
        case 'NATURALEZACUENTA_LIST_REQUEST':
            return { loading: true }
        case 'NATURALEZACUENTA_LIST_SUCCESS':
            return { loading: false, naturalezaCuentas: action.payload }
        case 'NATURALEZACUENTA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const tipoCuentaReducers = (state = { tipoCuentas: [] }, action) => {
    switch (action.type) {
        case 'TIPOCUENTA_LIST_REQUEST':
            return { loading: true }
        case 'TIPOCUENTA_LIST_SUCCESS':
            return { loading: false, tipoCuentas: action.payload }
        case 'TIPOCUENTA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const itemsEstadoFinancieroReducers = (state = { itemsEstadoFinancieros: [] }, action) => {
    switch (action.type) {
        case 'ITEMSESTADOFINANCIERO_LIST_REQUEST':
            return { loading: true }
        case 'ITEMSESTADOFINANCIERO_LIST_SUCCESS':
            return { loading: false, itemsEstadoFinancieros: action.payload }
        case 'ITEMSESTADOFINANCIERO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cuenta1Reducers = (state = { cuentas1: [] }, action) => {
    switch (action.type) {
        case 'CUENTA1_LIST_REQUEST':
            return { loading: true }
        case 'CUENTA1_LIST_SUCCESS':
            return { loading: false, cuentas1: action.payload }
        case 'CUENTA1_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cuenta2Reducers = (state = { cuentas2: [] }, action) => {
    switch (action.type) {
        case 'CUENTA2_LIST_REQUEST':
            return { loading: true }
        case 'CUENTA2_LIST_SUCCESS':
            return { loading: false, cuentas2: action.payload }
        case 'CUENTA2_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cuenta3Reducers = (state = { cuentas3: [] }, action) => {
    switch (action.type) {
        case 'CUENTA3_LIST_REQUEST':
            return { loading: true }
        case 'CUENTA3_LIST_SUCCESS':
            return { loading: false, cuentas3: action.payload }
        case 'CUENTA3_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cuenta4Reducers = (state = { cuentas4: [] }, action) => {
    switch (action.type) {
        case 'CUENTA4_LIST_REQUEST':
            return { loading: true }
        case 'CUENTA4_LIST_SUCCESS':
            return { loading: false, cuentas4: action.payload }
        case 'CUENTA4_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const cuenta5Reducers = (state = { cuentas5: [] }, action) => {
    switch (action.type) {
        case 'CUENTA5_LIST_REQUEST':
            return { loading: true }
        case 'CUENTA5_LIST_SUCCESS':
            return { loading: false, cuentas5: action.payload }
        case 'CUENTA5_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const categoriasFirebaseReducer = (state = { categorias: [] }, action) => {
    switch (action.type) {
        case 'CATEGORIASFIREBASE_LIST_REQUEST':
            return { loading: true }
        case 'CATEGORIASFIREBASE_LIST_SUCCESS':
            return { loading: false, categorias: action.payload }
        case 'CATEGORIASFIREBASE_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const subCategoriasFirebaseReducer = (state = { subCategorias: [] }, action) => {
    switch (action.type) {
        case 'SUBCATEGORIASFIREBASE_LIST_REQUEST':
            return { loading: true }
        case 'SUBCATEGORIASFIREBASE_LIST_SUCCESS':
            return { loading: false, subCategorias: action.payload }
        case 'SUBCATEGORIASFIREBASE_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const PresentacionFirebaseReducer = (state = { presentacionProductos: [] }, action) => {
    switch (action.type) {
        case 'PRESENTACION_LIST_REQUEST':
            return { loading: true }
        case 'PRESENTACION_LIST_SUCCESS':
            return { loading: false, presentacionProductos: action.payload }
        case 'PRESENTACION_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const MarcasFirebaseReducer = (state = { marcaProductos: [] }, action) => {
    switch (action.type) {
        case 'MARCA_LIST_REQUEST':
            return { loading: true }
        case 'MARCA_LIST_SUCCESS':
            return { loading: false, marcaProductos: action.payload }
        case 'MARCA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ModelosFirebaseReducer = (state = { modeloProductos: [] }, action) => {
    switch (action.type) {
        case 'MODELO_LIST_REQUEST':
            return { loading: true }
        case 'MODELO_LIST_SUCCESS':
            return { loading: false, modeloProductos: action.payload }
        case 'MODELO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}


export const UnidadMedidaFirebaseReducer = (state = { unidadMedidaProductos: [] }, action) => {
    switch (action.type) {
        case 'UNIDADMEDIDA_LIST_REQUEST':
            return { loading: true }
        case 'UNIDADMEDIDA_LIST_SUCCESS':
            return { loading: false, unidadMedidaProductos: action.payload }
        case 'UNIDADMEDIDA_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TiposInfraccionesReducer = (state = { tiposInfracciones: [] }, action) => {
    switch (action.type) {
        case 'TIPOSINFRACCIONES_LIST_REQUEST':
            return { loading: true }
        case 'TIPOSINFRACCIONES_LIST_SUCCESS':
            return { loading: false, tiposInfracciones: action.payload }
        case 'TIPOSINFRACCIONES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const NominaReducer = (state = { nominas: [] }, action) => {
    switch (action.type) {
        case 'NOMINAS_LIST_REQUEST':
            return { loading: true }
        case 'NOMINAS_LIST_SUCCESS':
            return { loading: false, nominas: action.payload }
        case 'NOMINAS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CentroDeCostosReducer = (state = { centroDeCostos: [] }, action) => {
    switch (action.type) {
        case 'CENTROSCOSTOS_LIST_REQUEST':
            return { loading: true }
        case 'CENTROSCOSTOS_LIST_SUCCESS':
            return { loading: false, centroDeCostos: action.payload }
        case 'CENTROSCOSTOS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const MaterialesReducer = (state = { materiales: [] }, action) => {
    switch (action.type) {
        case 'MATERIALES_LIST_REQUEST':
            return { loading: true }
        case 'MATERIALES_LIST_SUCCESS':
            return { loading: false, materiales: action.payload }
        case 'MATERIALES_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

/* ACTIVOS FIJOS PARAMETERS */

export const TiposActivosFijosReducer = (state = { tiposActivosFijos: [] }, action) => {
    switch (action.type) {
        case 'ACTIVOSFIJOS_LIST_REQUEST':
            return { loading: true }
        case 'ACTIVOSFIJOS_LIST_SUCCESS':
            return { loading: false, tiposActivosFijos: action.payload }
        case 'MACTIVOSFIJOS_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const PorcentajeDeDepreciacionReducer = (state = { porcentajeDeDepreciacion: [] }, action) => {
    switch (action.type) {
        case 'PORCENTAJEDEPRECIACION_LIST_REQUEST':
            return { loading: true }
        case 'PORCENTAJEDEPRECIACION_LIST_SUCCESS':
            return { loading: false, porcentajeDeDepreciacion: action.payload }
        case 'PORCENTAJEDEPRECIACION_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const EstadoProductoReducer = (state = { estadoDeDepreciacion: [] }, action) => {
    switch (action.type) {
        case 'ESTADOPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'ESTADOPRODUCTO_LIST_SUCCESS':
            return { loading: false, estadoDeDepreciacion: action.payload }
        case 'ESTADOPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const VidaUtilReducer = (state = { vidaUtil: [] }, action) => {
    switch (action.type) {
        case 'VIDAUTIL_LIST_REQUEST':
            return { loading: true }
        case 'VIDAUTIL_LIST_SUCCESS':
            return { loading: false, vidaUtil: action.payload }
        case 'VIDAUTIL_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ClaseDeActivoReducer = (state = { claseDeActivos: [] }, action) => {
    switch (action.type) {
        case 'CLASEACTIVO_LIST_REQUEST':
            return { loading: true }
        case 'CLASEACTIVO_LIST_SUCCESS':
            return { loading: false, claseDeActivos: action.payload }
        case 'CLASEACTIVO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const MetodoDeDepreciacionReducer = (state = { metodoDeDepreciacion: [] }, action) => {
    switch (action.type) {
        case 'METODODEPRECIACION_LIST_REQUEST':
            return { loading: true }
        case 'METODODEPRECIACION_LIST_SUCCESS':
            return { loading: false, metodoDeDepreciacion: action.payload }
        case 'METODODEPRECIACION_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

/* GENERAL/TIPO DE PRODUCTO */

export const TipoDeProductReducer = (state = { tipoDeProductos: [] }, action) => {
    switch (action.type) {
        case 'TIPODEPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'TIPODEPRODUCTO_LIST_SUCCESS':
            return { loading: false, tipoDeProductos: action.payload }
        case 'TIPODEPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ClaseDeProductoReducer = (state = { claseDeProductos: [] }, action) => {
    switch (action.type) {
        case 'CLASEPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'CLASEPRODUCTO_LIST_SUCCESS':
            return { loading: false, claseDeProductos: action.payload }
        case 'CLASEPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const FrecuenciaDeDepreciacionReducer = (state = { frecuenciaDeDepreciacion: [] }, action) => {
    switch (action.type) {
        case 'FRECUENCIADEPRECIACION_LIST_REQUEST':
            return { loading: true }
        case 'FRECUENCIADEPRECIACION_LIST_SUCCESS':
            return { loading: false, frecuenciaDeDepreciacion: action.payload }
        case 'FRECUENCIADEPRECIACION_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CondicionDeActivoReducer = (state = { condicionDeActivo: [] }, action) => {
    switch (action.type) {
        case 'CONDICIONACTIVO_LIST_REQUEST':
            return { loading: true }
        case 'CONDICIONACTIVO_LIST_SUCCESS':
            return { loading: false, condicionDeActivo: action.payload }
        case 'CONDICIONACTIVO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const LocalidadProductoReducer = (state = { localidadDeProductos: [] }, action) => {
    switch (action.type) {
        case 'LOCALIDADPRODUCTO_LIST_REQUEST':
            return { loading: true }
        case 'LOCALIDADPRODUCTO_LIST_SUCCESS':
            return { loading: false, localidadDeProductos: action.payload }
        case 'LOCALIDADPRODUCTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const DepartamentoReducer = (state = { departamentos: [] }, action) => {
    switch (action.type) {
        case 'DEPARTAMENTO_LIST_REQUEST':
            return { loading: true }
        case 'DEPARTAMENTO_LIST_SUCCESS':
            return { loading: false, departamentos: action.payload }
        case 'DEPARTAMENTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const SubcategoriaActivosReducer = (state = { subcategoriaActivos: [] }, action) => {
    switch (action.type) {
        case 'SUBCATEGORIASACTIVOS_LIST_REQUEST':
            return { loading: true }
        case 'SUBCATEGORIASACTIVOS_LIST_SUCCESS':
            return { loading: false, subcategoriaActivos: action.payload }
        case 'SUBCATEGORIASACTIVOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ResidualReducer = (state = { residual: [] }, action) => {
    switch (action.type) {
        case 'RESIDUAL_LIST_REQUEST':
            return { loading: true }
        case 'RESIDUAL_LIST_SUCCESS':
            return { loading: false, residual: action.payload }
        case 'RESIDUAL_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TipoTransaccionReducer = (state = { tipoTransaccion: [] }, action) => {
    switch (action.type) {
        case 'TIPOTRANSACCION_LIST_REQUEST':
            return { loading: true }
        case 'TIPOTRANSACCION_LIST_SUCCESS':
            return { loading: false, tipoTransaccion: action.payload }
        case 'TIPOTRANSACCION_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const CategoriaActivosReducer = (state = { categoriaActivos: [] }, action) => {
    switch (action.type) {
        case 'CATEGORIAACTIVOS_LIST_REQUEST':
            return { loading: true }
        case 'CATEGORIAACTIVOS_LIST_SUCCESS':
            return { loading: false, categoriaActivos: action.payload }
        case 'CATEGORIAACTIVOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const UnidadMedidaActivosReducer = (state = { unidadMedidaActivos: [] }, action) => {
    switch (action.type) {
        case 'UNIDADMEDIDAACTIVOS_LIST_REQUEST':
            return { loading: true }
        case 'UNIDADMEDIDAACTIVOS_LIST_SUCCESS':
            return { loading: false, unidadMedidaActivos: action.payload }
        case 'UNIDADMEDIDAACTIVOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const MarcaActivosReducer = (state = { marcaActivos: [] }, action) => {
    switch (action.type) {
        case 'MARCAACTIVOS_LIST_REQUEST':
            return { loading: true }
        case 'MARCAACTIVOS_LIST_SUCCESS':
            return { loading: false, marcaActivos: action.payload }
        case 'MARCAACTIVOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const ModeloActivosReducer = (state = { modeloActivos: [] }, action) => {
    switch (action.type) {
        case 'MODELOACTIVOS_LIST_REQUEST':
            return { loading: true }
        case 'MODELOACTIVOS_LIST_SUCCESS':
            return { loading: false, modeloActivos: action.payload }
        case 'MODELOACTIVOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const DepartamentosInventarioReducer = (state = { departamentosInventario: [] }, action) => {
    switch (action.type) {
        case 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_REQUEST':
            return { loading: true }
        case 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_SUCCESS':
            return { loading: false, departamentosInventario: action.payload }
        case 'TICKETSDEPARTAMENTOSINVENTARIO_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const DepartamentosReducer = (state = { departamentos: [] }, action) => {
    switch (action.type) {
        case 'DEPARTAMENTOS_LIST_REQUEST':
            return { loading: true }
        case 'DEPARTAMENTOS_LIST_SUCCESS':
            return { loading: false, departamentos: action.payload }
        case 'TDEPARTAMENTOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TicketsDepartamentosReducer = (state = { ticketsDepartamentos: [] }, action) => {
    switch (action.type) {
        case 'TICKETSDEPARTAMENTOS_LIST_REQUEST':
            return { loading: true }
        case 'TICKETSDEPARTAMENTOS_LIST_SUCCESS':
            return { loading: false, ticketsDepartamentos: action.payload }
        case 'TICKETSDEPARTAMENTOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TicketsAsuntosReducer = (state = { ticketsAsuntos: [] }, action) => {
    switch (action.type) {
        case 'TICKETSASUNTOS_LIST_REQUEST':
            return { loading: true }
        case 'TICKETSASUNTOS_LIST_SUCCESS':
            return { loading: false, ticketsAsuntos: action.payload }
        case 'TICKETSASUNTOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TicketsOficinasReducer = (state = { ticketsOficinas: [] }, action) => {
    switch (action.type) {
        case 'TICKETSOFICINAS_LIST_REQUEST':
            return { loading: true }
        case 'TICKETSOFICINAS_LIST_SUCCESS':
            return { loading: false, ticketsOficinas: action.payload }
        case 'TICKETSOFICINAS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const TicketsTurnosReducer = (state = { ticketsTurnos: [] }, action) => {
    switch (action.type) {
        case 'TICKETSTURNOS_LIST_REQUEST':
            return { loading: true }
        case 'TICKETSTURNOS_LIST_SUCCESS':
            return { loading: false, ticketsTurnos: action.payload }
        case 'TICKETSTURNOS_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const RelationFamilyUsersReducer = (state = { relacionFamilyUsers: [] }, action) => {
    switch (action.type) {
        case 'RELACIONFAMILYUSER_LIST_REQUEST':
            return { loading: true }
        case 'RELACIONFAMILYUSER_LIST_SUCCESS':
            return { loading: false, relacionFamilyUsers: action.payload }
        case 'RELACIONFAMILYUSER_LIST_FAIL ':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}