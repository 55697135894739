import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button, Card, CardContent, CardActions, Container } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";

export const NewBodega = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [nombreBodega, setNombreBodega] = useState({ campo: '', valido: true })
    const [direccionBodega, setDireccionBodega] = useState({ campo: '', valido: true })
    const [responsableBodega, setResponsableBodega] = useState({ campo: '', valido: true })
    const [auxiliarBodega1, setAuxiliarBodega1] = useState({ campo: '', valido: true })
    const [auxiliarBodega2, setAuxiliarBodega2] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createNewBodega = async (NombreBodega, DireccionBoodega, ResponsableBodega, AuxiliarBodega1, AuxiliarBodega2) => {
        await db.collection('bodegas').doc(NombreBodega).set({
            nombre_bodega: NombreBodega,
            fecha_creacion_bodega: firebase.firestore.FieldValue.serverTimestamp(),
            direccion_bodega: DireccionBoodega,
            responsable_bodega: ResponsableBodega,
            auxiliarBodega1_bodega: AuxiliarBodega1,
            auxiliarBodega2_bodega: AuxiliarBodega2
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva Bodega creada con exito" });
        }).catch((error) => {
                Swal.fire({ icon: "error", title: error });
        });
    }

    return (
        <>
            <br />
            <Card>

                <Container maxWidth="lg">
                    <CardContent>
                        <InputValidation

                            fullWidth
                            estado={nombreBodega}
                            cambiarEstado={setNombreBodega}
                            label="NOMBRE BODEGA"
                            name="nombreBodega"
                        />

                        <InputValidation
                            fullWidth
                            estado={direccionBodega}
                            cambiarEstado={setDireccionBodega}
                            label="DIRECCION BODEGA"
                            name="direccionBodega"
                        />

                        <InputValidation
                            fullWidth
                            estado={responsableBodega}
                            cambiarEstado={setResponsableBodega}
                            label="RESPONSABLE DE BODEGA"
                            name="responsableBodega"
                        />
                        <InputValidation
                            fullWidth
                            estado={auxiliarBodega1}
                            cambiarEstado={setAuxiliarBodega1}
                            label="AUXILIAR DE BODEGA 1"
                            name="auxiliarBodega1"
                        />
                        <InputValidation
                            fullWidth
                            estado={auxiliarBodega2}
                            cambiarEstado={setAuxiliarBodega2}
                            label="AUXILIAR DE BODEGA 2"
                            name="auxiliarBodega2"
                        />

                    </CardContent>

                    <CardActions>

                        <Button
                            onClick={() => createNewBodega(nombreBodega.campo.toUpperCase(), direccionBodega.campo.toUpperCase(), responsableBodega.campo.toUpperCase(), auxiliarBodega1.campo.toUpperCase(), auxiliarBodega2.campo.toUpperCase())}
                            fullWidth
                            variant="contained"
                            disableElevation
                            disabled={
                                !nombreBodega.campo
                                || !direccionBodega.campo
                                || !responsableBodega.campo
                                || !auxiliarBodega1.campo
                                || !auxiliarBodega2.campo
                            }
                        >
                            {" "}
                            CREAR NUEVA BODEGA{" "}
                        </Button>
                    </CardActions>
                </Container>
            </Card>
        </>
    );
};
