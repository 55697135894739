import {
    Box,
    TableCell,
    Table,
    TableBody,
    TableRow,
    TextField,
    TableContainer,
    Card,
    CardContent,
    Autocomplete,
    Typography,
    Button,
    TableHead,
    Paper,
    Chip,
    IconButton,
    Collapse,
    Hidden,
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    CardActions,
    Grid,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import UploadIcon from '@mui/icons-material/Upload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Swal from "sweetalert2";
import { EditPeriodoContable } from "./IconsButtonsActions/EditPeriodoContable";
import { ChangeStatePeriodoContable } from "./IconsButtonsActions/ChangeStatePeriodoContable";

export const EjercicioContable = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        LoadData()
    }, [])

    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [item, setItem] = useState();
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    const [valueFechaInicio, setValueFechaInicio] = useState("");
    const [valueFechaFin, setValueFechaFin] = useState("");


    const LoadData = async () => {
        var docRef = await db.collection("periodos_contables")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    };

    const columns = [
        /* { field: '1', headerName: 'ACCIONES', width: 250 }, */
        {
            field: '1', headerName: 'MODIFICAR', width: 100,
            renderCell: (params) => {
                return <EditPeriodoContable props={params.row} />
            }
        },
        {
            field: '2', headerName: 'ESTADO', width: 90,
            renderCell: (params) => {
                return <ChangeStatePeriodoContable props={params.row} />
            }
        },
        {
            field: 'fecha_inicio', headerName: 'FECHA INICIO', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        {
            field: 'fecha_fin', headerName: 'FECHA FIN', type: 'dateTime', width: 250,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        {
            field: 'estado_cierre_mensual', headerName: 'ESTADO', width: 150,
            valueGetter: ({ value }) => value === true ? 'ABIERTO' : 'CERRADO'
        },
        { field: '5', headerName: 'DIA DE CIERRE MENSUAL', width: 250 },
    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {

                    CODIGO_PRODUCTO: doc.codigo_producto,

                    LOCALIDAD: doc.bodega,

                    DESCRIPCION: doc.descripcion,

                    SUBTIPO: doc.categoria,

                    UNIDAD_MEDIDA: doc.unidad_medida,

                    STOCK: doc.stock_actual,

                    COSTO_PROMEDIO: doc.costo_promedio,

                    TOTAL: doc.total,

                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "reporte_stock");
        XLSX.writeFile(wb, `REPORTE_STOCK_${date1}.xlsx`)
    }

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total: {total}</Box>
        </div>
    }

    const [openCrearNuevoAsiento, setOpenCrearNuevoAsiento] = useState(false);

    const [stateCierreMensual, setStateCierreMensual] = React.useState(false);

    const handleEstado = (event) => {
        setStateCierreMensual(event.target.checked);
    };

    const createPeriodoContable = async () => {
        console.log('Hola')
        console.log(valueFechaInicio)
        console.log(valueFechaFin)
        console.log(stateCierreMensual)

        await db.collection('periodos_contables').doc().set({
            fecha_inicio: valueFechaInicio,
            fecha_fin: valueFechaFin,
            estado_cierre_mensual: stateCierreMensual,
            fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Periodo contable creado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });

        setOpenCrearNuevoAsiento(false)

    }

    return (
        <>

            <Box pt={1} pl={1}>
                <Typography >
                    <Typography >
                        <strong>EJERCICIO CONTABLE</strong>
                    </Typography >
                </Typography >
            </Box>

            <Box pt={2} pb={1}>
                <Button
                    onClick={() => setOpenCrearNuevoAsiento(true)}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="success" size='small' variant="contained"
                    startIcon={<AddIcon />}>
                    <strong>NUEVO</strong>
                </Button>

                <Button
                    /* onClick={() => setOpenCaja(true)} */
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="success" size='small' variant="outlined"
                    startIcon={<UploadIcon />}>
                    <strong>EXCEL</strong>
                </Button>

                <Button
                    /* onClick={() => setOpenCaja(true)} */
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </Box>

            <Paper>
                <Box sx={{ height: 690, width: '100%' }}>
                    <DataGrid
                        density="compact"
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}

                        onStateChange={(state) => {
                            console.log(state.pagination.rowCount);
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>

            {/* Dialog Crear nuevo asiento Contable */}

            <Dialog
                open={openCrearNuevoAsiento}
                onClose={() => setOpenCrearNuevoAsiento(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>AGREGAR PERIODO CONTABLE</DialogTitle>
                <DialogContent>

                    <Card>
                        <CardContent>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box py={2}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>FECHA INICIO</p>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label=""
                                            value={valueFechaInicio}
                                            onChange={(fechaInicio) => {
                                                setValueFechaInicio(fechaInicio);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box py={2}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>FECHA FIN</p>
                                    <FormControl fullWidth>

                                        <DatePicker
                                            renderInput={(props) => <TextField {...props} size='small' />}
                                            label=""
                                            value={valueFechaFin}
                                            onChange={(fechaFin) => {
                                                setValueFechaFin(fechaFin);
                                            }}
                                        />
                                    </FormControl>
                                </Box>

                                <Grid item xs={3} style={{ paddingBottom: "0px" }}>
                                    <Box pb={3} style={{ paddingBottom: "0px" }}>
                                        <FormControlLabel
                                            label={
                                                <Typography sx={{ fontSize: 11 }}>
                                                    CIERRE MENSUAL
                                                </Typography>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={stateCierreMensual}
                                                    onChange={handleEstado}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                        />
                                    </Box>
                                </Grid>

                            </LocalizationProvider>

                        </CardContent>
                        <CardActions>
                            <Box pb={2} pl={1}>
                                <Button disabled={
                                    valueFechaInicio === '' ||
                                    valueFechaFin === '' ||
                                    valueFechaInicio === valueFechaFin
                                } variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => createPeriodoContable()}>
                                    Aceptar
                                </Button>
                            </Box>
                            <Box pb={2} onClick={() => setOpenCrearNuevoAsiento(false)}>
                                <Button variant="outlined" size="small">
                                    CANCELAR
                                </Button>
                            </Box>
                        </CardActions>
                    </Card>

                </DialogContent>
            </Dialog>

        </>
    );
};