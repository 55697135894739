import PropTypes from 'prop-types';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Typography,
    Paper,
    TableContainer,
    Stack,
    Grid,
    Select,
    MenuItem,
} from '@mui/material';
import moment from 'moment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from "react";
import { db, firebase } from '../../../firebase';
import { IconsMovimientos } from './IconsMovimientos';
import { MostrarSeriesReporteMovimientos } from './series/MostrarSeriesReporteMovimientos';
import DatePicker from "react-datepicker";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DescargarPdf } from './DescargarPdf';
import { DescargarMovimientos } from './DescargarMovimientos';


export function ReporteMovimientos() {

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    const [open, setOpen] = useState(false);

    let current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 86400000))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))


    const [data, setData] = useState();

    const [bodega, setBodega] = useState('TODOS');
    const [bodegas, setBodegas] = useState([]);

    // const dispatch = useDispatch()

    useEffect(() => {
        cargarBodegas()
    }, []);


    async function cargarBodegas() {
        let docRef = db.collection("bodegas")
        await docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => {
                if (item.data().nombre_bodega.includes('FLOTA')) {
                    return 'BODEGA ' + item.data().nombre_bodega
                }
                else {
                    return item.data().nombre_bodega
                }
            })
            setBodegas(['TODOS'].concat(arrayMessage))
            LoadData('TODOS')
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    function LoadData(bodega2) {

        console.log('aaa')
        let ref = db.collection("reporte_movimientos").orderBy("fecha_documento", "desc")

        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)

            let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('fecha_documento', '>=', startfulldate)
            ref = ref.where('fecha_documento', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            // if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.get().then((snapshot) => {
                const data2 = [
                    // ...persistMessages,
                    ...snapshot.docs.map((doc) => {

                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                console.log(data2.length)
                setData(data2);

            });
        }

    };


    Row.propTypes = {
        row: PropTypes.shape({

            history: PropTypes.arrayOf(
                PropTypes.shape({

                }),
            ).isRequired,

        }).isRequired,
    };


    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        const [dataItems, setDataItems] = useState();

        const desplegarTablaOpen = async (props) => {

            console.log('aaaa')

            if (!open) {
                await db.collection("reporte_movimientos").doc(props.id).collection('items').get().then((snapshot) => {
                    const data = [
                        ...snapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                id: doc.id,
                            };
                        }),
                    ];

                    setDataItems(data);
                });
            } else {
                setDataItems([]);
            }
            setOpen(!open)


        }

        return (
            row.estado === 3 ? <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell ><IconsMovimientos props={row} /></TableCell>
                    <TableCell style={{ fontSize: '12px' }} component="th" scope="row">
                        {row.fecha_documento && typeof row.fecha_documento === 'number' &&
                            moment(new Date().toString()).format('h:mm:ss a')}
                        {row.fecha_documento && typeof row.fecha_documento === 'object' &&
                            moment(row.fecha_documento.toDate().toString()).format('h:mm:ss a')}
                    </TableCell>
                    <TableCell style={{ fontSize: '12px' }} component="th" scope="row">
                        {row.fecha_documento && typeof row.fecha_documento === 'number' &&
                            moment(new Date().toString()).format('MMMM DD YYYY')}
                        {row.fecha_documento && typeof row.fecha_documento === 'object' &&
                            moment(row.fecha_documento.toDate().toString()).format('MMMM DD YYYY')}
                    </TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.uidSolicitud}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.motivo}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.bodega}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.ubicacion}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.flota}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.placa_flota}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.observacion_adicional}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.user}</TableCell>
                    <TableCell>
                        <Stack direction='row' spacing={-2}>
                            <DescargarPdf props={row} />
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => desplegarTablaOpen(row)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box py={1}>
                                <p style={{
                                    fontSize: '14px', marginBottom: '0px',
                                    marginLeft: '0px', marginRight: '0px', fontWeight: 'bold'
                                }}>
                                    PRODUCTOS
                                </p>
                                <Paper elevation={1}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CODIGO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CATEGORIA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>DESCRIPCION</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>COSTO PROMEDIO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>PRECIO COMPRA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>BODEGA ORIGEN</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>BODEGA DESTINO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CANTIDAD TRANSFERIDA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>FECHA CREACION MOVIMIENTO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>VER SERIES</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataItems ? dataItems.map((historyRow) => (
                                                <TableRow key={historyRow}>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.fullName}
                                                    </TableCell>

                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.categoria_producto}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.descripcion}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {`$${parseFloat(historyRow.costo_promedio).toLocaleString('en', { maximumFractionDigits: 2 })}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {`$${historyRow.precio_compra}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.bodega_origen}
                                                    </TableCell>

                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.bodega_destino}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {parseFloat(historyRow.cantidad_solicitada)}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {row.created && typeof row.created === 'number' &&
                                                            moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                                        {row.created && typeof row.created === 'object' &&
                                                            moment(row.created.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        <MostrarSeriesReporteMovimientos props={historyRow} row={row} />
                                                    </TableCell>

                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </> : <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell ><IconsMovimientos props={row} /></TableCell>
                    <TableCell style={{ fontSize: '12px' }} component="th" scope="row">
                        {row.fecha_documento && typeof row.fecha_documento === 'number' &&
                            moment(new Date().toString()).format('h:mm:ss a')}
                        {row.fecha_documento && typeof row.fecha_documento === 'object' &&
                            moment(row.fecha_documento.toDate().toString()).format('h:mm:ss a')}
                    </TableCell>
                    <TableCell style={{ fontSize: '12px' }} component="th" scope="row">
                        {row.fecha_documento && typeof row.fecha_documento === 'number' &&
                            moment(new Date().toString()).format('MMMM DD YYYY')}
                        {row.fecha_documento && typeof row.fecha_documento === 'object' &&
                            moment(row.fecha_documento.toDate().toString()).format('MMMM DD YYYY')}
                    </TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.uidSolicitud}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.motivo}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.bodega}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.ubicacion}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.flota}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.placa_flota}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.observacion_adicional}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.user}</TableCell>
                    <TableCell>
                        <Stack direction='row' spacing={-2}>
                            <DescargarPdf props={row} />
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => desplegarTablaOpen(row)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box py={1}>
                                <p style={{
                                    fontSize: '14px',
                                    marginBottom: '0px', marginLeft: '0px',
                                    marginRight: '0px', fontWeight: 'bold'
                                }}>
                                    PRODUCTOS
                                </p>
                                <Paper elevation={1}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CODIGO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CATEGORIA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>DESCRIPCION</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>COSTO PROMEDIO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>PRECIO COMPRA</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>CANTIDAD</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>STOCK ACTUAL</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>VALOR DEL MOVIMIENTO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>FECHA CREACION MOVIMIENTO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>
                                                    <strong>VER SERIES</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataItems ? dataItems.map((historyRow) => (
                                                <TableRow key={historyRow}>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.fullName}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.categoria_producto}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.descripcion}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {`$${parseFloat(historyRow.costo_promedio).toLocaleString('en', { maximumFractionDigits: 2 })}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {`$${historyRow.precio_compra}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {parseFloat(historyRow.cantidad_solicitada)}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {parseFloat(historyRow.cantidad_total_despues).toLocaleString('en')}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {`$${parseFloat(historyRow.saldo_valorizado).toLocaleString('en')}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {row.created && typeof row.created === 'number' &&
                                                            moment(new Date().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                                        {row.created && typeof row.created === 'object' &&
                                                            moment(row.created.toDate().toString()).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        <MostrarSeriesReporteMovimientos props={historyRow} row={row} />
                                                    </TableCell>

                                                </TableRow>
                                            )) : <></>}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }



    return (
        <>
            <Box pb={1}>
                <p style={{ fontSize: '14px', marginBottom: '0px', marginLeft: '0px', fontWeight: 'bold' }}>
                    REPORTE DE MOVIMIENTOS
                </p>
            </Box>

            <Box pb={1}>
                <Stack direction="row">
                    {data &&
                        <Stack direction="row" spacing={2}>
                            <DescargarMovimientos props={data.filter((val) => {
                                if (bodega === 'TODOS' || val.bodega === bodega) {
                                    return val
                                }
                            })} desde={startDate} hasta={endDate} modo={"EXCEL"} />
                            <DescargarMovimientos props={data.filter((val) => {
                                if (bodega === 'TODOS' || val.bodega === bodega) {
                                    return val
                                }
                            })} desde={startDate} hasta={endDate} />
                        </Stack >
                    }
                </Stack >
            </Box>
            <Box pt={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>BODEGA</strong></Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Select sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            fullWidth
                            size="small"
                            value={bodega}
                            label="BODEGA"
                            onChange={(e) => {
                                setBodega(e.target.value)
                                LoadData(e.target.value)
                            }}>
                            {bodegas.map(item => (
                                <MenuItem key={item} value={item} >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </Box>


            <Box pt={1}>
                <Box display='flex' style={{ textTransform: 'uppercase' }} >
                    <Typography variant="button" >
                        <strong>Desde</strong>
                        {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography >
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>
                        {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>

            <Box display="flex" alignItems='center'>
                <Box sx={{ flexGrow: 1 }}>
                    <Box py={1} px={1} display="flex" alignItems='center' >
                        <Button size='small' variant='contained'
                            onClick={() =>
                                setOpen(!open)}>Buscar por fecha {open ?
                                    <ExpandLess /> : <ExpandMore />}</Button>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            inline
                        />
                        <Box pb={1} px={1}>
                            <Button size='small'
                                disableElevation variant='contained' onClick={() =>
                                    LoadData(bodega)}>Aplicar busqueda</Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>

            <TableContainer component={Paper}>

                <Table size="small" aria-label="purchases" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{''}</TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>HORA DOCUMENTO</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>FECHA DOCUMENTO</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>NUM. MOV</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>MOTIVO</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>BODEGA</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>UBICACION</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>FLOTA</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>PLACA</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>OBSERVACION</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}>
                                <strong>USUARIO QUE REALIZO EL MOVIMIENTO</strong></TableCell>
                            <TableCell>{''}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data ? data.filter((val) => {
                            if (bodega === 'TODOS' || val.bodega === bodega) {
                                return val
                            }
                        }).map((row) => (
                            <Row key={row} row={row} />
                        )) : <></>}
                    </TableBody>

                </Table>
            </TableContainer>
        </>
    );
}