import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Box, Button, DialogActions, DialogTitle, TextField } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../../firebase'
import { SaveItem } from "./SaveItem";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const VerBodega = ({props}) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [motivoRechazo, setMotivoRechazo] = useState('');
    const [items, setItems] = useState([]);
    useEffect(() => {
    
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
    }
    const loadItems = () => {
        var docRef = db.collection("orden_compra").doc(props.id).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setItems(data);
        });
    }
    const onSubmit = () => {
        setLoading(true)
        db.collection('orden_compra').doc(props.uidSolicitud).update({
            estado: 2,
            user_recepcion: userInfo.displayName,
            fecha_recepcion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            Swal.fire({ icon: "success", text: 'Se actulizo su recepcion de mercaderia' });
            setLoading(false)
        })
        //
        //
        console.log(items, 'items')
        items.forEach(function (obj, index) {
            // const dataRef = {
            //     uid_orden_compra: `ORD-COM-` + numPedido,
            //     cantidad: parseInt(obj.cantidad_pedida),
            //     cantidad_pedida: parseInt(obj.cantidad_pedida),
            //     ...obj,
            //     estado: 1,
            //     //costo_estandar_producto: 0,
            //     created: firebase.firestore.FieldValue.serverTimestamp(),
            // }
            db.collection('productos').doc(obj.codigo).update({
                precio_compra_producto: obj.costo_estandar_producto,
                fecha_precio: firebase.firestore.FieldValue.serverTimestamp(),
            })
            // const doc = db.collection(`productos/ORD-COM-${orden}/items`).doc(dataRef.codigo).set(dataRef)
            //     .then((docRef) => {
            //     })
            //     .catch((error) => {
            //         console.error("Error adding document: ", error);
            //     });
        });
        
    }
    //
    const onRechazar = () => {
        setLoading(true)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 2,
            motivo_rechazo: motivoRechazo,
            user_rechazo: userInfo.displayName,
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            Swal.fire({ icon: "info", text: 'Se rechazo su solicitud de pedido' });
            setLoading(false)
        })
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );

    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 100 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 300 },
        { field: 'cantidad', headerName: 'CANTIDAD SOLICITADA', width: 175 },
        { field: 'cantidad_pedida', headerName: 'CANTIDAD PEDIDA', width: 175 },
        { field: 'cantidad_pendiente', headerName: 'CANTIDAD PENDIENTE', width: 175 },

    ];
    return (
        <>
            <Button onClick={() => onData()}>
                <RemoveRedEyeIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle>
                    RECEPCION DE MERCADERIA 5: {props.uidSolicitud}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    {/* <Button onClick={() => {
                        setOpen(false)
                        setOpen1(true)
                    }} color='error' variant="contained" disabled={loading} >RECHAZAR ENTREGA</Button>
                    <Button onClick={onSubmit} color='success' variant="contained" disabled={loading} >APROBAR ENTREGA</Button> */}
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={open1}
            >
                <DialogTitle>
                    ESCRIBIR EL RECHAZO DE:
                </DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <TextField
                            label='RECHAZO DE LA ENTREGA'
                            fullWidth
                            multiline
                            onChange={(e) => setMotivoRechazo(e.target.value)}
                            rows={4}
                        />
                    </Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setOpen1(false)}>CERRAR VENTANA</Button>
                    <Button onClick={onRechazar} color='error' variant="contained" disabled={loading || !motivoRechazo} >RECHAZAR ENTREGA</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerBodega