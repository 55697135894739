import React, { useEffect, useState } from "react";
import { db, storage, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Divider,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import {
    listsProvincia
} from "../../../redux/actions/parametersActions";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { EditSector } from "./EditSector";

export const NuevoSector = () => {

    const provinciaList = useSelector(state => state.provinciaList);
    const { provincias, loading } = provinciaList
    const [provincia, setProvincia] = useState('')

    /* const cantonesList = useSelector(state => state.cantonesList);
    const { cantones, loading: loadingCantones } = cantonesList */
    const [canton, setCanton] = useState('')
    const [cantones, setCantones] = useState('')

    const [sector, setSector] = useState('')
    const [sectores, setSectores] = useState('')

    const [codigo, setCodigo] = useState('');

    const [zona, setZona] = useState('');

    const [modal, setModal] = useState(false);

    const getCanton = (e) => {

        let ref = db.collection("parameters").doc("cantones").collection("provincias").doc(e).collection("canton")

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setCantones(data)
        })
    };

    const createSector = () => {

        db.collection("parameters").doc("cantones").collection("provincias").doc(provincia).collection("canton").doc(canton).collection("parroquia").doc("TODOS").collection("sector").doc(sector.toUpperCase()).set({
            nombre: sector.toUpperCase(),
            uid: sector.toUpperCase(),
            fecha_creacion: firebase.firestore.FieldValue.serverTimestamp(),
        })

        setModal(false)

        Swal.fire({ icon: "success", text: 'Se ha creado el nuevo sector con exito', });

    };

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        /* getLastCodigoZona() */
        dispatch(listsProvincia())
    }, [dispatch]);

    const [nombre, setNombre] = useState('')
    const [ubicacion, setUbicacion] = useState('');

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>

                        <p style={{ fontSize: "11px", marginBottom: "00px" }}><strong>PROVINCIA:</strong></p>
                        <Box py={2}>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                fullWidth
                                value={provincia}
                                label="PROVINCIA"
                                onChange={(e) => {
                                    setProvincia(e.target.value)
                                    getCanton(e.target.value)
                                    /* getLastCodigoZona(e.target.value) */
                                }}>
                                {provincias &&
                                    provincias.map(item => (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.id}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Box>

                        {
                            provincia === '' ? '' :
                                <>
                                    <p style={{ fontSize: "11px", marginBottom: "00px" }}><strong>CANTON:</strong></p>
                                    <Box py={2}>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size="small"
                                            fullWidth
                                            value={canton}
                                            label="CANTON"
                                            onChange={(e) => {
                                                setCanton(e.target.value)
                                            }}>
                                            {cantones &&
                                                cantones.map(item => (
                                                    <MenuItem key={item.id} value={item.id} >
                                                        {item.id}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Box>
                                </>
                        }

                        {
                            canton === '' ? '' :
                                <>
                                    <p style={{ fontSize: "12px", marginBottom: "00px" }}><strong>NOMBRE DEL NUEVO SECTOR</strong></p>
                                    <Box pt={2}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={sector}
                                            onChange={(e) => setSector(e.target.value)}
                                            name="sector"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box pt={2}>
                                        <Button variant="contained" color="secondary" size="small" fullWidth onClick={() => setModal(true)}>
                                            Crear
                                        </Button>
                                    </Box>
                                    {/* <Box py={2} px={2}>
                                        <Paper>
                                            <Box py={2} px={2}>
                                                <p style={{ fontSize: "14px", marginBottom: "00px" }}><strong>NOMBRE DEL NUEVO SECTOR</strong></p>
                                                <Box pt={2}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={sector}
                                                        onChange={(e) => setSector(e.target.value)}
                                                        name="sector"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>
                                                <Box pt={2}>
                                                    <Button variant="contained" color="secondary" size="small" fullWidth>
                                                        Crear
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box> */}
                                </>
                        }
                    </Box>
                </Paper>
            </Box>

            {/* CONFIRM SECTOR */}
            <Dialog open={modal} onClose={() => setModal(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>¿ESTA SEGURO DE CREAR EL NUEVO SECTOR?</strong></DialogTitle>
                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="error" size="small" onClick={() => setModal(false)}>REGRESAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => createSector()}>CREAR</Button>
                </DialogActions>

            </Dialog>
        </>
    );
};
