import {
  Box, Card, CardContent, IconButton, Grid, Typography, Button,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { GridToolbarContainer, GridToolbarQuickFilter, DataGrid, esES } from '@mui/x-data-grid'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { fillWithZeros } from './Secuenciales'

export const DocumentosIngresados = () => {

  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])
  const [data5, setData5] = useState([])

  const [proveedores, setProveedores] = useState([])

  const [open, setOpen] = useState(false)


  useEffect(() => {
    getRetenciones()
    getProveedores()
  }, [])

  const getProveedores = () => {
    let docRef = db.collection("proveedores").orderBy('nombre_comercial', 'asc')
    docRef.get().then((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      setProveedores(arrayMessage)
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  const getRetenciones = () => {
    let docRef = db.collection("cuentas_pagar/colecciones/documentosIngresados")
    docRef.onSnapshot((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      setData(arrayMessage)
    })
  }


  const columns = [
    {
      field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 200,
    },
    {
      field: 'cantidad', headerClassName: 'super-app-theme--header', headerName: 'Cantidad', width: 75,
    },
    //   {
    //     field: 'tipoProducto', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 125,
    //   },
    //   {
    //     field: 'relacionProducto', headerClassName: 'super-app-theme--header', headerName: 'Relación Producto', width: 200,
    //     renderCell: (params) => {
    //       return <a>
    //         {params.row.relacionProducto ? `${params.row.relacionProducto.codigo}: ${params.row.relacionProducto.descripcion}` : ''}</a>
    //     },
    //   },
    {
      field: 'base_imponible', headerClassName: 'super-app-theme--header', headerName: 'P. Unitario', width: 100,
    },

    {
      field: 'retencion', headerClassName: 'super-app-theme--header', headerName: 'Tipo IVA', width: 75,
    },
    //   {
    //     field: 'codigoRenta', headerClassName: 'super-app-theme--header', headerName: 'Ret. Renta', width: 100,
    //   },
    //   {
    //     field: 'codigoIva', headerClassName: 'super-app-theme--header', headerName: 'Ret. Iva', width: 100,
    //   },
    {
      field: 'descuento', headerClassName: 'super-app-theme--header', headerName: 'Dto.', width: 85,
    },
    { field: 'subtotal', headerClassName: 'super-app-theme--header', headerName: 'Subtotal', width: 75 },
    {
      field: 'ice', headerClassName: 'super-app-theme--header', headerName: 'ICE', width: 80,
    },

    { field: 'valor_iva', headerClassName: 'super-app-theme--header', headerName: 'IVA', width: 85 },
    { field: 'total', headerClassName: 'super-app-theme--header', headerName: 'TOTAL', width: 85 },
  ]

  const columnsTipoRetencion = [
    { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', flex: 4 },
    { field: 'cuenta', headerClassName: 'super-app-theme--header', headerName: 'Cuenta', flex: 2 },
    {
      field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'Tipo', flex: 1,
    },
    { field: 'valor', headerClassName: 'super-app-theme--header', headerName: 'Valor', flex: 1 },
  ]
  // const columnsRetencion = [
  //   { field: 'impuesto', headerClassName: 'super-app-theme--header', headerName: 'Impuesto', flex: 1 },
  //   {
  //     field: 'porcentaje', headerClassName: 'super-app-theme--header', headerName: '%', flex: 0.5,
  //     renderCell: (params) => {
  //       return <a>
  //         {parseFloat((parseFloat(params.row.porcentaje) * 100).toFixed(2))}%</a>
  //     }
  //   },
  //   { field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', flex: 2 },
  //   { field: 'baseImponible', headerClassName: 'super-app-theme--header', headerName: 'Base Imponible', flex: 1 },
  //   { field: 'valorRetenido', headerClassName: 'super-app-theme--header', headerName: 'Valor Retenido', flex: 1 },

  // ]

  const columnsDocumento = [
    {
      field: 'proveedor', headerClassName: 'super-app-theme--header', headerName: 'Proveedor', flex: 1.5,
      renderCell: (params) => {
        return <a>
          {proveedores && proveedores.filter((val) => params.row.proveedor === val.identificacion)[0] ?
            proveedores.filter((val) => params.row.proveedor === val.identificacion)[0].nombre_comercial
            : params.row.proveedor}</a>
      }
    },
    { field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'Tipo', flex: 1 },
    {
      field: 'created', headerClassName: 'super-app-theme--header', headerName: 'Fecha Registro', flex: 1,
      renderCell: (params) => {
        return <a>
          {params.row.created ? moment(params.row.created.toDate().toString()).format('DD/MM/YYYY HH:mm') :''}</a>
      }
    },
    {
      field: 'emision', headerClassName: 'super-app-theme--header', headerName: 'Fecha Emision', flex: 1,
      renderCell: (params) => {
        return <a>
          {params.row.emision ? moment(params.row.emision.toDate().toString()).format('DD/MM/YYYY HH:mm') :''}</a>
      }
    },
    { field: 'subtotal', headerClassName: 'super-app-theme--header', headerName: 'Subtotal', flex: 0.5 },
    { field: 'total', headerClassName: 'super-app-theme--header', headerName: 'Total', flex: 0.5 },
    //   { field: 'totalRetenido', headerClassName: 'super-app-theme--header', headerName: 'Retenido', flex: 0.5},
    {
      field: 'aa', headerClassName: 'super-app-theme--header', headerName: 'Ver', flex: 0.5,
      renderCell: (params) => {
        return <IconButton size='small' onClick={() => handleOpen(params.row)}>
          <RemoveRedEyeIcon color='info' />
        </IconButton>
      }
    },

  ]


  function handleOpen(row) {
    setData2(row.items)
    setData3(row.asientoCompra)
    setData4(row.asientoRetencion)
    // setData5(row.listaRetencion)
    setOpen(true)
  }

  function limpiar() {
    setData2([])
    setData3([])
    setData4([])
    setData5([])
  }

  function footerRetencion() {
    let totalDeber = 0
    let totalHaber = 0
    for (const elemento of data3) {
      if (elemento.tipo === 'HABER') {
        totalHaber += elemento.valor
      } else {
        totalDeber += elemento.valor
      }
    }
    return <Box >
      <strong>Total HABER :</strong> ${totalHaber.toFixed(2)}
      <br />
      <strong>Total DEBER :</strong> ${totalDeber.toFixed(2)}
    </Box>
  }

  function footerCompra() {
    let totalDeber = 0
    let totalHaber = 0
    for (const elemento of data4) {
      if (elemento.tipo === 'HABER') {
        totalHaber += elemento.valor
      } else {
        totalDeber += elemento.valor
      }
    }
    return <Box >
      <strong>Total HABER :</strong> ${totalHaber.toFixed(2)}
      <br />
      <strong>Total DEBER :</strong> ${totalDeber.toFixed(2)}
    </Box>
  }



  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .filter((value) => value !== '')
        } />
    </GridToolbarContainer>
  )


  return (
    <>

      <Card>
        <CardContent>
          <Typography variant="h5"><strong>Documentos Ingresados</strong> </Typography>
          <Box py={2} sx={{
            minWidth: 600,
            height: 500, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
            '& .super-app-theme--header': {
              fontWeight: 'bold',
              backgroundColor: '#672780',
              color: 'white',
            },
            '& .super-app-theme--cell': {
              fontWeight: 'bold',
              color: 'blue',
            },
          }}>
            <DataGrid
              rows={data ?? []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              columns={columnsDocumento}
              density='compact'
              hideFooterSelectedRowCount={true}
              getRowId={(row) => row.id}
              getRowHeight={() => 'auto'}
              getEstimatedRowHeight={() => 100}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </Box>

          <Dialog open={open} fullWidth onClose={() => {
            limpiar()
            setOpen(false)
          }} maxWidth="md">
            <DialogTitle><strong>Info Documento</strong></DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>

                {data2 &&
                  <>
                    <Grid item xs={12}>
                      <Paper py={1} elevation={3} sx={{ padding: '10px' }}>
                        <Typography><strong>Items Factura</strong></Typography>
                        <Box py={2} sx={{
                          minWidth: 600,
                          height: 400, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                          '& .super-app-theme--header': {
                            fontWeight: 'bold',
                            backgroundColor: '#672780',
                            color: 'white',
                          },
                          '& .super-app-theme--cell': {
                            fontWeight: 'bold',
                            color: 'blue',
                          },
                        }}>
                          <DataGrid
                            rows={data2 ?? []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            hideFooter
                            density='compact'
                            getRowId={(row) => row.id}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                }
                {/* {data5 &&
                  <>
                    <Grid item xs={12}>
                      <Paper py={1} elevation={3} sx={{ padding: '10px' }}>
                        <Typography><strong>Detalle Retención</strong></Typography>
                        <Box py={2} sx={{
                          minWidth: 600,
                          height: 200, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                          '& .super-app-theme--header': {
                            fontWeight: 'bold',
                            backgroundColor: '#672780',
                            color: 'white',
                          },
                          '& .super-app-theme--cell': {
                            fontWeight: 'bold',
                            color: 'blue',
                          },
                        }}>
                          <DataGrid
                            rows={data5 ? data5 : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columnsRetencion}
                            hideFooter
                            density='compact'
                            getRowId={(row) => row.codigo}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                } */}
                {data3 &&
                  <>
                    <Grid item xs={12}>
                      <Paper py={1} elevation={3} sx={{ padding: '10px' }}>
                        <Typography><strong>Asiento Compra</strong></Typography>
                        <Box py={2} sx={{
                          minWidth: 600,
                          height: 300, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                          '& .super-app-theme--header': {
                            fontWeight: 'bold',
                            backgroundColor: '#672780',
                            color: 'white',
                          },
                          '& .super-app-theme--cell': {
                            fontWeight: 'bold',
                            color: 'blue',
                          },
                        }}>
                          <DataGrid
                            rows={data3 ? data3 : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columnsTipoRetencion}
                            components={{
                              Footer: footerCompra,
                            }}
                            density='compact'
                            getRowId={(row) => row.id}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                }
                {data4 &&
                  <>
                    <Grid item xs={12}>
                      <Paper py={1} elevation={3} sx={{ padding: '10px' }}>
                        <Typography><strong>Asiento Retención</strong></Typography>
                        <Box py={2} sx={{
                          minWidth: 600,
                          height: 300, width: '100%', '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                          '& .super-app-theme--header': {
                            fontWeight: 'bold',
                            backgroundColor: '#672780',
                            color: 'white',
                          },
                          '& .super-app-theme--cell': {
                            fontWeight: 'bold',
                            color: 'blue',
                          },
                        }}>
                          <DataGrid
                            rows={data4 ?? []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columnsTipoRetencion}
                            components={{
                              Footer: footerRetencion,
                            }}
                            density='compact'
                            getRowId={(row) => row.id}
                          />
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                }


              </Grid>

            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                limpiar()
                setOpen(false)
              }} variant="contained"><strong>Regresar</strong></Button>
            </DialogActions>
          </Dialog>

        </CardContent>
      </Card >
    </>
  )
}

