import React, { useState, useEffect } from "react";
import {
  IconButton, FormControl, Select, MenuItem, Button, Box,
  DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, CircularProgress, Typography, TextField
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";
import { createAgenda } from "../../../redux/actions/agenda/agendaActions";
import { ListaInstalaciones } from "./ListaInstalaciones";
export const UpdateInformation = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [data, setData] = useState('')
  //
  const agendaCreate = useSelector(state => state.agendaCreate)
  const { loading: loadingCreateAgenda } = agendaCreate
  //
  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loading } = flotasList
  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList
  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList
  const [open, setOpen] = useState(false);
  const [openFoto, setOpenFoto] = useState(false);
  const [openRechazo, setOpenRechazo] = useState(false);
  const [openChangeColor, setOpenChangeColor] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [value, setValue] = useState(null);
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')
  const [correo, setCorreo] = useState('')
  const [observacion2, setObservacion2] = useState(false);

  const [noSeAgendCliente, setNoSeAgendCliente] = useState(false);




  const handleFlota = (e) => {
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0]
    setNumFlota(data.numeroFlota)
    setLider(data.nombreLider)
    setAuxiliar(data.nombreAuxiliar)
    setCorreo(data.correo)
  }

  const handleLider = (e) => {
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e);
    const data = p[0]
    setLider(data.nombreLider)
  }
  const handleAuxiliar = (e) => {
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e);
    const data = p[0]
    setAuxiliar(data.nombreAuxiliar)
  }

  const test = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
      "nombre": "PRUEBA",
      "cedula": "PRUEBA",
      "correo": "prueba@prueba.com",
      "telefono": "prueba",
      "movil": "prueba",
      "direccion_principal": "prueba"
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("http://192.168.90.72/api/v1/NewUser", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  const handleSubmit = (id) => {
    const formData = {
      ...data,
      estado_pagado: 0,
      fechaHora: value,
      nombreLider: '',
      nombreAuxiliar: '',
      correo_flota: correo,
      numFlota: 'UPYL',
      estado: 0,
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName,
    }
    const agendaData = {
      estado_pagado: 0,
      fechaHora: value,
      nombreLider: '',
      nombreAuxiliar: '',
      numFlota: 'UPYL',
      estado: 1,

      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName,

    }
    dispatch(createAgenda(id, formData, agendaData, navigate))

    db.collection("agenda").doc(id).update({
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueAgendo: userInfo.displayName
    })

    db.collection('solicitudes_instalaciones').doc(id).set({
      ...data,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      numFlota: numFlota,
    })
  }

  const rechazarVenta = (id) => {

    db.collection("pre_ventas").doc(id).update({
      estado: 1,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName,
     
    })


    db.collection("agenda").doc(id).update({
      estado: 4,
      rechazoVenta: observacion2,
      fechaDeRechazoAgenda: firebase.firestore.FieldValue.serverTimestamp(),
      personaQueRechazoAgendamiento: userInfo.displayName,
      fechaDeAgendamiento: firebase.firestore.FieldValue.serverTimestamp(),
    }).then(() => {
      setOpen(false)


      Swal.fire({ icon: "success", text: "Se ha rechazado la preventa", });
    })


  }

  const rechazarVentaSinEnvioVendedor = (id) => {
    db.collection("agenda").doc(id).update({
      estado: 4,
      rechazoVenta: observacion2
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha rechazado la preventa", });
    })

  }
  //
  const onData = () => {
    setData(props)
    setOpenFoto(true)
  }
  const clienteNoRespondeLlamada = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 1
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
    setOpenChangeColor(false)
    setOpenFoto(false)
  }

  const clienteNoDisponeDinero = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 2
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
    setOpenChangeColor(false)
    setOpenFoto(false)
  }
  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={openFoto} fullWidth onClose={() => setOpenFoto(false)} maxWidth="md">
        <DialogTitle>
        <strong>VERIFICACION DOCUMENTOS</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpenFoto(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
        VERIFICACION DOCUMENTOS DE: {data.nombres}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item md={6} xs={12}>
                <p><strong>CEDULA FRONTAL</strong></p>
                <a target="_blank" href={props.foto_cedula_frontal}>
                  <img src={props.foto_cedula_frontal} width={200} />
                </a>
              </Grid>
              <Grid item md={6} xs={12}>
                <p><strong>CEDULA POSTERIOR</strong></p>
                <a target="_blank" href={props.foto_cedula_trasera}>
                  <img src={props.foto_cedula_trasera} width={200} />
                </a>
              </Grid>
              <Grid item md={6} xs={12}>
                <p><strong>CEDULA PLANILLA/SENSO</strong></p>
                <a target="_blank" href={props.foto_cliente_planilla}>
                  <img src={props.foto_cliente_planilla} width={200} />
                </a>
              </Grid>
              <Grid item md={6}xs={12}>
                <p><strong>CLIENTE LETRERO</strong></p>
                <a target="_blank" href={props.foto_cliente_letrero}>
                  <img src={props.foto_cliente_letrero} width={200} />
                </a>
              </Grid>
              <Grid item md={6}  xs={12}>
                <p><strong>VIVIENDA CLIENTE</strong></p>
                <a target="_blank" href={props.foto_cliente_vivienda}>
                  <img src={props.foto_cliente_vivienda} width={200} />
                </a>
              </Grid>
              {
                props.foto_carnet_discapacidad &&
                <Grid item xs={6}>
                  <p>Foto carnet discapacidad</p>
                  <img src={props.foto_carnet_discapacidad} style={{ width: "360px" }} />
                </Grid>
              }
 {
  //se agrego la visualizacion de foto de anexo de tercera edad
  //agregado por jesus
 }             
                {
                props.foto_terceraEdad ?
                <Grid item xs={6}>
                   <p><strong>ANEXO TERCERA EDAD</strong></p>
                  <a target="_blank" href={props.foto_terceraEdad}>
                  <img src={props.foto_terceraEdad} width={200} />
                </a>
                </Grid> : ''
              }
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          {/* <Button color="error" disableElevation onClick={() => setOpenChangeColor(true)} variant="contained">
            {" "}
            Motivo no se agenda cliente
          </Button> */}
          <Button color="error" disableElevation onClick={() => {
            setOpenRechazo(true)
            setOpenFoto(false)
          }} variant="contained">
            {" "}
            Rechazar venta
          </Button>
          <Button color="success"
            disableElevation
            onClick={() => {
              setOpenConfirm(true)
              setOpenFoto(false)
            }} variant="contained">
            Agendar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirm} maxWidth="xs">
        <DialogTitle>
          CREAR CLIENTE

          <IconButton
            aria-label="close"
            onClick={() => setOpenConfirm(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>¿Esta seguro de agendar?, Esto puede tardar un tiempo, ya que se enviara el contrato por correo..</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleSubmit(data.id)} variant="contained">
            Agendar
          </Button>
        </DialogActions>
      </Dialog>
      {/* ----------------------------------------------------------- */}
      {/* Rechazar venta */}
      <Dialog open={openRechazo} maxWidth="md">
        <DialogTitle>RECHAZAR VENTA

        <IconButton
            aria-label="close"
            onClick={() => setOpenRechazo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          
          <Typography>Por favor, seleccione el motivo por el cual ha decidido rechazar esta venta.</Typography>
          
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
              <Select
                label="MOTIVO DEL RECHAZO"
                value={observacion2}
                onChange={(e) => setObservacion2(e.target.value)}
              >
                <MenuItem value="Datos incorrectos">Datos incorrectos</MenuItem>
                <MenuItem value="Coordenadas no concuerdan con direccion">Coordenadas no concuerdan con direccion</MenuItem>
                <MenuItem value="Cambio por baja de plan (Diferente al contrato)">Cambio por baja de plan (Diferente al contrato)</MenuItem>
                <MenuItem value="Cliente con linea suspendida">Cliente con linea suspendida</MenuItem>
                <MenuItem value="Imagenes subidas erroneamente">Imagenes subidas erroneamente</MenuItem>
                {/* <MenuItem value="Imagenes faltantes por el vendedor">Imagenes faltantes por el vendedor</MenuItem> */}
                <MenuItem value="Cliente no contesta">Cliente no contesta</MenuItem>
                {/* <MenuItem value="Riesgo electrico">Riesgo electrico</MenuItem> */}
                <MenuItem value="Cliente desiste del servicio">Cliente desiste del servicio</MenuItem>
                {/* <MenuItem value="Cliente no desea cancelar metraje adicional">Cliente no desea cancelar metraje adicional</MenuItem> */}
                {/* <MenuItem value="Zona sin posteria">Zona sin posteria</MenuItem> */}
                {/* <MenuItem value="Coordenadas erroneas">Coordenadas erroneas</MenuItem> */}
                {/*   <MenuItem value="Sector sin propiedad legal / invasion">Sector sin propiedad legal / invasion</MenuItem>
              <MenuItem value="Zona peligrosa">Zona peligrosa</MenuItem>
                <MenuItem value="Caja llena sin disponibilidad de puertos">Caja llena sin disponibilidad de puertos</MenuItem> */}
                <MenuItem value="Ventas duplicadas">Ventas duplicadas</MenuItem>
                {/* <MenuItem value="No tiene dinero">No tiene dinero</MenuItem> */}
                {/* <MenuItem value="Dificil Acceso">Dificil Acceso</MenuItem> */}
              </Select>
            </FormControl>
                <Box pt={5}></Box>
                <TextField 
                  value={observacion2}
                  fullWidth
                  label='MOTIVO DE RECHAZO ESCRITO'
                  onChange={(e) => setObservacion2(e.target.value)}
                />
          </Box>
        </DialogContent>
        <DialogActions>

          <Button onClick={() => rechazarVenta(data.id)} variant="contained">

            Rechazar
          </Button>
        </DialogActions>
      </Dialog>

      {/* ----------------------------------------------------------------------------------------------------------- */}

      <Dialog open={openChangeColor} onClose={() => setOpenChangeColor(false)} maxWidth="md">
        <DialogTitle>Seleccione el motivo por el cual no se agendo el cliente.</DialogTitle>
        <DialogContent>

          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>MOTIVO DEL RECHAZO</InputLabel>
              <Select
                label="MOTIVO DEL RECHAZO"
                size="small"
                value={noSeAgendCliente}
                onChange={(e) => setNoSeAgendCliente(e.target.value)}
              >
                <MenuItem value="NO RESPONDEN LLAMADAS">NO RESPONDEN LLAMADAS</MenuItem>
                <MenuItem value="NO DISPONE DE DINERO">NO DISPONE DE DINERO</MenuItem>
              </Select>
            </FormControl>
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChangeColor(false)}><strong>Cancelar</strong></Button>
          {
            noSeAgendCliente === 'NO RESPONDEN LLAMADAS' ? <Button onClick={() => clienteNoRespondeLlamada(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

          {
            noSeAgendCliente === 'NO DISPONE DE DINERO' ? <Button onClick={() => clienteNoDisponeDinero(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }

        </DialogActions>
      </Dialog>

    </>
  );
};