import React, { useState, useRef } from 'react'
import { Box, Button, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux';
import { uploadFile } from '../../../../redux/actions/ventasActions';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';
import { storage, firebase, db } from '../../../../firebase';
export const UpdateFileUploadFiscalizador = ({ instalacion, cambiarEstado: setFile, estado: file, nameFile }) => {

    const [fileLoading, setFileLoading] = useState(false)
    const fileRef = useRef()
    const [fileName, setFileName] = useState(null)
    const [fileImg, setFileImg] = useState('')

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        if (file.type === "image/png" || file.type === "image/jpeg") {
            setFileName(file.name)
            setFile(file)
            const img = URL.createObjectURL(file)
            setFileImg(img)
        } else {
            Swal.fire({ icon: "warning", text: "No aceptamos este tipo de archivos", });
        }
    }
    const handleRemove = () => {
        setFileName('')
        setFile('')
        setFileLoading(false)
        fileRef.current.value = ""
    }
    const handleUploadFile = async () => {

        if (file) {
            setFileLoading(true)
            new Compressor(file, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {

                    const pictureRef = storage.ref().child('fiscalizacion').child(`${instalacion.numContrato}`).child(nameFile)
                    const letreroUpload = pictureRef.put(compressedResult)
                    letreroUpload.on("state_changed",
                        (snapshot) => {

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            setFileLoading(false)
                            Swal.fire({ icon: 'error', text: error, })
                        },
                        () => {
                            letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                console.log('File available at', downloadURL)
                                //CAJA ABIERTA ANTES
                                if (nameFile === 'imagen_caja_abierta_antes') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_caja_abierta_antes: downloadURL
                                    })
                                    //CAJA ABIERTA DESPUES
                                } else if (nameFile === 'imagen_caja_abierta_despues') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_caja_abierta_despues: downloadURL
                                    })
                                    //POTENCIA CAJA
                                } else if (nameFile === 'imagen_potencia_caja') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_potencia_caja: downloadURL
                                    })
                                    //CODIGO CAJA
                                } else if (nameFile === 'imagen_codigo_caja') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_codigo_caja: downloadURL
                                    })
                                    //EQUIPO CAJA
                                } else if (nameFile === 'imagen_equipo_caja') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_equipo_caja: downloadURL
                                    })
                                    //SERIAL EQUIPO CAJA
                                } else if (nameFile === 'imagen_serial_equipo_caja') {
                                    db.collection('registro_orden_trabajo').doc(instalacion.numContrato).update({
                                        imagen_serial_equipo_caja: downloadURL
                                    })
                                }
                                setFileLoading(false)

                            });

                        }
                    )
                },
                error: (error) => {
                    Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                }
            });
        }
    }
    return (
        <>
            {fileName ? <Box display='block'>
                <img width={100} src={fileImg} />
                <Box>Nombre el archivo: {fileName}</Box>
            </Box> : 'Por favor, cargue el archivo...'}
            {!file ?
                <Box>
                    <input
                        onChange={(e) => handleUploadFileLocal(e)}
                        style={{ display: 'none' }}
                        id={`contained-button-file${nameFile}`}
                        multiple
                        type="file"
                        ref={fileRef}
                    />
                    <label htmlFor={`contained-button-file${nameFile}`}>
                        <Button style={{ textTransform: 'none' }} variant="contained" color="primary" disableElevation component="span">
                            Cargar archivo...
                        </Button>
                    </label>
                </Box> :
                <Box display="flex">
                    <Box pr={1}>
                        <Button
                            disableElevation
                            variant='outlined'
                            disabled={fileLoading}
                            onClick={(e) => handleRemove()}
                        >Borrar archivo</Button>
                    </Box>
                    <Box>
                        <Button
                            onClick={handleUploadFile}
                            variant="contained"
                            disabled={fileLoading}
                            color="primary"
                            disableElevation
                        >{!fileLoading ? <strong> Subir archivo</strong> : <CircularProgress size={24} />}</Button>
                    </Box>
                </Box>}
        </>
    )
}