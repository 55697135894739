import React, { useEffect, useState } from "react";
import { db, storage, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Divider,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
    Paper
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DatePicker, TimePicker } from "@mui/x-date-pickers";

export const NuevaFechaCorte = () => {

    const [diaCorte, setDiaCorte] = useState('');

    const [descripcion, setDescripcion] = useState('');

    const [diaMaximoPago, setDiaMaximoPago] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
    }, []);

    const [nombre, setNombre] = useState('')
    const [ubicacion, setUbicacion] = useState('');

    const onsubmit = async () => {

        await db
            .collection("fechas_de_corte").doc().set({
                dia_de_corte: diaCorte,
                descripcion: descripcion,
                dia_maximo_pago: diaMaximoPago,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                Swal.fire({ icon: "success", title: "Fecha de corte creada con exito" });
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    };

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>
                        <Grid container spacing={7}>
                            <Grid item xs={10} md={6}>

                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FECHA DE CORTE:</strong></p>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>

                                    <FormControl fullWidth>
                                        <DatePicker
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            label="Seleccione"
                                            value={diaCorte}
                                            onChange={(newValue) => {
                                                setDiaCorte(newValue);
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>

                                <Box py={4}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                        name="zona"
                                        size="small"
                                        fullWidth
                                    />

                                </Box>
                            </Grid>


                            {/* *********************************** */}
                            <Grid item xs={10} md={6}>

                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DIA MAXIMO DE PAGO:</strong></p>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <FormControl fullWidth>

                                        <DatePicker
                                            renderInput={(props) => <TextField {...props} size="small" />}
                                            label="Seleccione"
                                            value={diaMaximoPago}
                                            onChange={(newValue) => {
                                                setDiaMaximoPago(newValue);
                                            }}
                                        />
                                    </FormControl>
                                </LocalizationProvider>

                            </Grid>

                        </Grid>

                        <Button
                            onClick={() => onsubmit()}
                            disabled={diaCorte === ''
                                || descripcion === ''
                                || diaMaximoPago === ''
                            }
                            fullWidth
                            variant="contained"
                            color="secondary"
                        >
                            {" "}
                            CREAR{" "}
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </>
    );
};
