import React, { useEffect, useState } from "react";
import {
    Grid, Stack, TextField, Select, MenuItem, Card, FormControl,
    InputLabel, CardContent, Button, Box, Typography, Dialog, DialogContent,
    Tooltip, DialogTitle, DialogActions
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Swal from "sweetalert2";
import { db } from "../../../../firebase";

export const ChangeStatePeriodoContable = ({ props }) => {

    const [openPeriodo, setOpenPeriodo] = useState(null);
    const [closePeriodo, setClosePeriodo] = useState(null);

    const abrirPeriodo = async () => {
        await db.collection("periodos_contables").doc(props.id).update({
            estado: true
        })
        window.location.reload(false);
        setOpenPeriodo(false)
        Swal.fire({
            icon: "success",
            title: "Se ha abierto el periodo",
        });
    }

    const cerrarPeriodo = async () => {
        await db.collection("periodos_contables").doc(props.id).update({
            estado: false
        })
        window.location.reload(false);
        setClosePeriodo(false)
        Swal.fire({
            icon: "success",
            title: "Se ha cerrado el periodo",
        });
    }

    return (
        <>
            {
                props.estado === true ? <Tooltip title="CERRAR PERIODO">
                    <Button onClick={() => setClosePeriodo(true)} >
                        <LockOpenIcon color="success" />
                    </Button>
                </Tooltip> : <Tooltip title="ABRIR PERIODO">
                    <Button onClick={() => setOpenPeriodo(true)}>
                        <LockIcon />
                    </Button>
                </Tooltip>
            }

            <Dialog open={openPeriodo} fullWidth onClose={() => setOpenPeriodo(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>ABRIR PERIODO</strong>
                </DialogTitle>
                <DialogContent>
                    <p>¿Está seguro que desea abrir el periodo contable?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        abrirPeriodo()
                    }} variant="contained"
                        color="primary"
                        size="small"
                    >
                        {" "}
                        ACEPTAR
                    </Button>
                    <Button variant="outlined" color="error" size="small" onClick={() => setOpenPeriodo(false)}><strong>Cancelar</strong></Button>
                </DialogActions>
            </Dialog>

            <Dialog open={closePeriodo} fullWidth onClose={() => setClosePeriodo(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>CERRAR PERIODO</strong>
                </DialogTitle>
                <DialogContent>
                    <p>¿Está seguro que desea cerrar el periodo contable?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        cerrarPeriodo()
                    }} variant="contained"
                        color="primary"
                        size="small"
                    >
                        {" "}
                        ACEPTAR
                    </Button>
                    <Button variant="outlined" color="error" size="small" onClick={() => setClosePeriodo(false)}><strong>Cancelar</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    )
}