import React, { useState, useEffect } from "react";
import {
  TextField, FormControl, Select, MenuItem, Button, Box,
  DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, Typography
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Grid from '@mui/material/Grid';
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";
import { createAgenda } from "../../../redux/actions/agenda/agendaActions";

export const AsignarFlota = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [data, setData] = useState('')
  //
  const agendaCreate = useSelector(state => state.agendaCreate)
  const { loading: loadingCreateAgenda } = agendaCreate
  //
  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loading } = flotasList

  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList
  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList
  const [open, setOpen] = useState(false);
  const [openFoto, setOpenFoto] = useState(false);
  const [openRechazo, setOpenRechazo] = useState(false);
  const [openChangeColor, setOpenChangeColor] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [value, setValue] = useState(null);
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')
  const [correo, setCorreo] = useState('')
  const [observacion2, setObservacion2] = useState(false);

  const [noSeAgendCliente, setNoSeAgendCliente] = useState(false);

  const handleFlota = (e) => {
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0]
    setNumFlota(data.numeroFlota)
    setLider(data.nombreLider)
    setAuxiliar(data.nombreAuxiliar)
    setCorreo(data.correo)
  }

  const handleLider = (e) => {
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e);
    const data = p[0]
    setLider(data.nombreLider)
  }
  const handleAuxiliar = (e) => {
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e);
    const data = p[0]
    setAuxiliar(data.nombreAuxiliar)
  }
  //
  const onData = () => {
    setOpenFoto(true)
    setData(props)
  }


  const clienteNoRespondeLlamada = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 1
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }

  const clienteNoDisponeDinero = async (id) => {
    console.log('Ok');
    db.collection("agenda").doc(id).update({
      estado_color: 2
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Se ha cambiado el color del estado para su facil identificacion", });
    })
      .catch((error) => { console.error("Error adding document: ", error); });
    setOpenChangeColor(false)
    setOpenFoto(false)
  }
  const onSendData = async () => {
    db.collection("agenda").doc(props.id).update({
      estado: 7,
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      correo_flota: correo,
      numFlota: numFlota,
      fechaHora: value,
      estado_pagado: 2,
      fecha_actualizacion_estado_pago: firebase.firestore.FieldValue.serverTimestamp(),
    })

    db.collection("tecnicos").doc(props.id).update({
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      correo_flota: correo,
      numFlota: numFlota,
      fechaHora: value,
      estado_pagado: 2,
      fecha_actualizacion_estado_pago: firebase.firestore.FieldValue.serverTimestamp(),
    })

    db.collection("registro_orden_trabajo").doc(props.id).update({
      nombreLider: lider,
      nombreAuxiliar: auxiliar,
      correo_flota: correo,
      numFlota: numFlota,
      fechaHora: value,
      fecha_actualizacion_estado_pago: firebase.firestore.FieldValue.serverTimestamp(),
    }).then(() => {
      setOpen(false)
      Swal.fire({ icon: "success", text: "Datos enviados correctamente", });
    })
  }
  const onOpen = () => {
    setOpenConfirm(true)
    setOpenFoto(false)
  }
  useEffect(() => {
 
  },[])
  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={openFoto} fullWidth
        maxWidth="xs">
        <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>AISGNAR FLOTA
          <IconButton
            aria-label="close"
            onClick={() => setOpenFoto(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            <Typography>
            {data.nombres}
            </Typography>
          <Box py={2}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="SELECCIONE"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>FLOTA</InputLabel>
              <Select
                label="Flota"
                value={flota}
                onChange={(e) => handleFlota(e.target.value)}
              >
                <MenuItem value="">--</MenuItem>
                {flotas && flotas.map((row, key) => (
                  <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                ))}

              </Select>
            </FormControl>
          </Box>
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>Lider Flota</InputLabel>
              <Select
                disabled
                label="LiderFlota"
                value={lider}
                onChange={(e) => handleLider(e.target.value)}
              >
                <MenuItem value="">--</MenuItem>
                {liders && liders.map((row, key) => (
                  <MenuItem value={row.nombreLider}>{row.nombreLider}</MenuItem>
                ))}

              </Select>
            </FormControl>
          </Box>
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>Auxiliar</InputLabel>
              <Select
                disabled
                label="Auxiliar"
                value={auxiliar}
                onChange={(e) => handleAuxiliar(e.target.value)}
              >
                <MenuItem value="">--</MenuItem>
                {auxiliars && auxiliars.map((row, key) => (
                  <MenuItem value={row.nombreAuxiliar}>{row.nombreAuxiliar}</MenuItem>
                ))}

              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>

          {
            noSeAgendCliente === 'NO RESPONDEN LLAMADAS' ? <Button onClick={() => clienteNoRespondeLlamada(data.id)} >
              {" "}
              Confirmar
            </Button> : ''
          }
          {
            noSeAgendCliente === 'NO DISPONE DE DINERO' ? <Button onClick={() => clienteNoDisponeDinero(data.id)} variant="contained">
              {" "}
              Confirmar
            </Button> : ''
          }
          <Button disabled={!value || !flota}
            variant='outlined'
            onClick={() => onOpen()}
          >CONFIRMAR</Button>

        </DialogActions>
      </Dialog>
      {/* ------------------------------------------------------------------------------------ */}
      <Dialog open={openConfirm} fullWidth onClose={() => setOpenConfirm(false)} maxWidth="xs">
        <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>CONFIRMAR
        </DialogTitle>
        <DialogContent dividers>
          CONFIRMAR INSTALACION DE {data.nombres}
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>
          <Button variant='outlined' onClick={() => onSendData()}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};