import {
  Container,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Collapse,
  IconButton
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db, storage, firebase } from "../../firebase";
import moment from "moment";
import { UpdateVentasReAgendadas } from "./views/UpdateVentasReAgendadas";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DatePicker from "react-datepicker";
import SearchInput from "../../components/utils/SearchInput";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from 'xlsx/xlsx.mjs'
export const SolicitudesReAgendadas = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = "Por agendar";
      color = "warning";
    } else if (estado === 1) {
      name = "Rechazada";
      color = "info";
    } else if (estado === 2) {
      name = "Reagendada";
      color = "warning";
    } else if (estado === 3) {
      name = "Reagendada";
      color = "success";
    }
    return <Chip label={name} color={color} />;
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [nombreZona, setNombreZona] = useState();

  const [reData, setreData] = useState([]);

  const [search, setSearch] = useState("");

  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);

  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );

  useEffect(() => {
    LoadData();

    /* if (data) {
      const data2 = [
        ...data.map((doc) => {
          return {
            ...doc,
            NUM_CONTRATO: doc.numContrato
          };
        }),
      ];

      setreData(data2);
    } */
  }, []);

  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;

  const LoadData = (startAfterDoc, persistMessages = []) => {

      let ref = db.collection("agenda").where("estado", "==", 3);

      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("created", ">=", startfulldate);
        ref = ref.where("created", "<=", endfulldate);

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];

          setData(data);

          setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
          setIsLastPage(totalCount < 1);
        });
      }
    
  };

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.numContrato,
          CEDULA: doc.cedula,
          NOMBRES: doc.nombres,
          TELEFONO: doc.telefono,
          CELULAR: doc.celular,
          WHATSAPP: doc.whatsapp,
          NUMERO_REFERENCIA: doc.numbRef,
          FECHA_NACIMIENTO: doc.fechaNacimiento,
          DIRECCION: doc.direccion,
          ZONA: doc.zona === undefined ? '' : doc.zona,
          CANTON: doc.canton,
          SECTOR: doc.sector,
          COORDENADAS: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          NOMBRE_PLAN: doc.planNombre,
          CONTRATO: doc.contrato,
          TERCERA_EDAD: doc.terceraEdad === 1 ? "SI" : "NO",
          DISCAPACITADO: doc.discapacitado === 1 ? "SI" : "NO",
          GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === "" ? "NINGUNO" : doc.paramsDiscapacidad.discapacidad,
          DESCUENTO: doc.paramsDiscapacidad === "" ? "NINGUNO" : doc.paramsDiscapacidad.descuento,
          VALOR_DESCUENTO: doc.paramsDiscapacidad === "" ? "NINGUNO" : doc.paramsDiscapacidad.valor,
          VALOR_PLAN: doc.planValor,
          NUMERO_FLOTA: doc.numFlota,
          NOMBRE_LIDER: doc.nombreLider,
          NOMBRE_AUXILIAR: doc.nombreAuxiliar,
          FECHA_Y_HORA_INSTALACION: doc.fechaHora.toDate(),
          USUARIO_QUE_REAGENDO: doc.personaQueReAgendo,
          FECHA_DE_REAGENDAMIENTO: doc.fechaDeReAgendamiento.toDate(),
          FECHA_DE_GENERACION_DE_PREVENTA: doc.created.toDate(),
          NOMBRE_VENDEDOR: doc.vendedor,
          ES_REFERIDO: doc.referido === undefined ? "NO" : doc.referido,
          VENTA_REFERIDA_POR_FLOTA: doc.referido === "SI" ? doc.flotaReferida.numeroFlota : "NINGUNA",
        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "ventas_reagendadas");
    XLSX.writeFile(wb, `VENTAS_REAGENDADAS${date1}.xlsx`)
  }

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          <strong>({data && data.length})</strong> VENTAS RE AGENDADAS
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
          <Box display="flex" alignItems="center">
            <Button onClick={() => setOpen(!open)}>
              Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button
              disableElevation
              variant="contained"
              onClick={() => LoadData()}
            >
              Aplicar busqueda
            </Button>
          </Collapse>
        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <IconButton color="primary" onClick={handleExport}>
          <SimCardDownloadIcon />
        </IconButton>
      </Box>

      <Paper sx={{ maxWidth: { xs: 340, sm: "100%" }, overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>CONTRATO</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>#REFERENCIA</TableCell>
                <TableCell>FECHA NACIMIENTO</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>ZONA</TableCell>
                <TableCell>CANTON</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                <TableCell>TIPO CONTRATO</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DESCUENTO</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR</TableCell>
                <TableCell>CODIGO DEL VENDEDOR</TableCell>
                <TableCell>CENTRO DE COSTO</TableCell>
                <TableCell>CANAL DE VENTAS</TableCell>
                <TableCell>LIDER DE VENTA</TableCell>
                <TableCell>CATEGORIA</TableCell>
                <TableCell>NUMERO FLOTA</TableCell>
                <TableCell>NOMBRE LIDER</TableCell>
                <TableCell>NOMBRE AUXILIAR</TableCell>
                <TableCell>FECHA Y HORA INSTALACION</TableCell>
                <TableCell>USUARIO QUE REAGENDO</TableCell>
                <TableCell>FECHA DE REAGENDAMIENTO</TableCell>
                <TableCell>FECHA DE GENERACION DE PRE-VENTA</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR</TableCell>
                <TableCell>ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA</TableCell>
                <TableCell>ACCIONES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data
                  .filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val.nombres.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.cedula.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row, key) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {row.numContrato}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Estado estado={row.estado} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cedula}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.nombres}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.telefono}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.celular}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.whatsapp}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.numbRef}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.fechaNacimiento}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.direccion}
                      </TableCell>
                      {row.zona === undefined ? (
                        <TableCell component="th" scope="row">
                          {""}
                        </TableCell>
                      ) : (
                        <TableCell component="th" scope="row">
                          {row.zona}
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {row.canton}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.sector}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.coordenadas._lat},{row.coordenadas._long}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.planNombre}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.contrato}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.terceraEdad === 1 ? "SI" : "NO"}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.discapacitado === 1 ? "SI" : "NO"}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.discapacidad}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.descuento}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.valor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.planValor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.vendedor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.codigo_vendedor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.centro_costo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.canal}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.lider_venta}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.categoria}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.numFlota}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.nombreLider}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.nombreAuxiliar}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.fechaHora &&
                          typeof row.fechaHora === "number" &&
                          moment(new Date().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        {row.fechaHora &&
                          typeof row.fechaHora === "object" &&
                          moment(row.fechaHora.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.personaQueReAgendo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.fechaDeReAgendamiento &&
                          typeof row.fechaDeReAgendamiento === "number" &&
                          moment(new Date().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        {row.fechaDeReAgendamiento &&
                          typeof row.fechaDeReAgendamiento === "object" &&
                          moment(row.fechaDeReAgendamiento.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </TableCell>





                      <TableCell component="th" scope="row">
                        {row.created &&
                          typeof row.created === "number" &&
                          moment(new Date().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        {row.created &&
                          typeof row.created === "object" &&
                          moment(row.created.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.vendedor}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.referido === undefined ? "NO" : row.referido}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.referido === "SI"
                          ? row.flotaReferida.numeroFlota
                          : "NINGUNA"}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <UpdateVentasReAgendadas props={row} />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
