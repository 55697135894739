import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import {
    Box, Button, Card, CardContent, Grid, TextField, Typography,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import Swal from 'sweetalert2';
import { db } from '../../firebase';
import moment from 'moment/moment';

export const GenerarATS = () => {

    const tabla = 'cuentas_pagar/colecciones/documentos'
    const [nombre, setNombre] = useState('')
    const [codigo, setCodigo] = useState('')
    const ventana = 'ATS'

    const [id, setId] = useState('')

    const [info, setInfo] = useState('')

    const [categorias, setCategorias] = useState(null)
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)


    useEffect(() => {
        cargarCategorias()
    }, [])

    
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )



    function cargarCategorias() {
        db.collection(tabla)
            .onSnapshot(snapshot => {
                let lista = []
                snapshot.docs.map(doc => {
                    if (doc.data().tipo == "COMPROBANTE DE RETENCION") {
                        lista.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    }

                })
                console.log(lista.length)
                setCategorias(lista)
            })
    }


    function formato() {
       


        let lista = []
        for (const el of categorias) {

            if (el.infoDatil && el.listaRetencion.length !== 0) {
                let idf = el.infoDatil.sujeto.tipo_identificacion
                if (idf === '04') idf = '01'
                if (idf === '05') idf = '02'
                if (idf === '06') idf = '03'


                let var10 = 0
                let var20 = 0
                let var30 = 0
                let var50 = 0
                let var70 = 0
                let var100 = 0
                let listaR = []
                for (const ele of el.listaRetencion) {
                    if (ele.impuesto === 'IVA') {
                        if (ele.codigo.includes('10%')) {
                            var10 = ele.valorRetenido
                        }
                        if (ele.codigo.includes('20%')) {
                            var20 = ele.valorRetenido
                        }
                        if (ele.codigo.includes('30%')) {
                            var30 = ele.valorRetenido
                        }
                        if (ele.codigo.includes('50%')) {
                            var50 = ele.valorRetenido
                        }
                        if (ele.codigo.includes('70%')) {
                            var70 = ele.valorRetenido
                        }
                        if (ele.codigo.includes('100%')) {
                            var100 = ele.valorRetenido
                        }
                    } else {
                        listaR.push({
                            detalleAir: {
                                codRetAir: ele.codigo.split("-")[0],
                                baseImpAir: (ele.baseImponible).toFixed(2),
                                porcentajeAir: (ele.porcentaje * 100).toFixed(2),
                                valRetAir: (ele.valorRetenido).toFixed(2),
                            }
                        })

                    }
                }


                let basenoiva = 0
                let baseimponible = 0
                let basegraba = 0
                let baseexenta = 0
                let totalice = 0
                let totaliva = 0

                for (const ele of el.items) {
                    if (ele.retencion === '0%') {
                        baseimponible += parseFloat(parseFloat(ele.subtotal).toFixed(2))
                    }
                    else if (ele.retencion.includes("Exento")) {
                        baseexenta += parseFloat(parseFloat(ele.subtotal).toFixed(2))
                    }
                    else if (ele.retencion.includes("No Objeto")) {
                        basenoiva += parseFloat(parseFloat(ele.subtotal).toFixed(2))
                    }
                    else {
                        basegraba += parseFloat(parseFloat(ele.subtotal).toFixed(2))
                    }
                    totaliva += parseFloat(ele.valor_iva)
                    totalice += parseFloat(ele.ice)
                }

                lista.push({
                    detalleCompras: {
                        codSustento: el.codigoSustento,
                        tpIdProv: idf,
                        idProv: el.infoDatil.sujeto.identificacion,
                        tipoComprobante: el.tipoDocumento,
                        parteRel: "NO",
                        fechaRegistro: moment(new Date(el.created.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
                        establecimiento: el.factura.split("-")[0],
                        puntoEmision: el.factura.split("-")[1],
                        secuencial: el.infoDatil.secuencial,
                        fechaEmision: moment(new Date(el.infoDatil.fecha_emision.toString()).toISOString()).format('DD/MM/YYYY'),
                        autorizacion: el.infoDatil.clave_acceso,
                        baseNoGraIva: basenoiva.toFixed(2),
                        baseImponible: baseimponible.toFixed(2),
                        baseImpGrav: basegraba.toFixed(2),
                        baseImpExe: baseexenta.toFixed(2),
                        montoIce: totalice.toFixed(2),
                        montoIva: totaliva.toFixed(2),
                        valRetBien10: var10.toFixed(2),
                        valRetServ20: var20.toFixed(2),
                        valorRetBienes: var30.toFixed(2),
                        valRetServ50: var50.toFixed(2),
                        valorRetServicios: var70.toFixed(2),
                        valRetServ100: var100.toFixed(2),
                        totbasesImpReemb: "0",
                        pagoExterior: {
                            pagoLocExt: "01",
                            paisEfecPago: "NA",
                            aplicConvDobTrib: "NA",
                            pagExtSujRetNorLeg: "NA",
                        },
                        formasDePago: {
                            formaPago: el.idPago,
                        },
                        air: listaR,
                    }
                })
            }
        }

        let json2 = {
            iva: {
                TipoIDInformante: 'R',
                IdInformante: '0992988061001',
                razonSocial: 'INTERCOMMERCE S A ',
                Anio: '2023',
                Mes: '08',
                totalVentas: '0.00',
                codigoOperativo: 'IVA',
                compras: lista
            }
        }

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(json2)
        }
        fetch("https://dataguardian.intercommerce.com.ec/funcion/jsonTransform", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                let filename = "file.xml";
                let pom = document.createElement('a');
                let bb = new Blob([result.toString().replaceAll('"', '')], { type: 'text/plain' });

                pom.setAttribute('href', window.URL.createObjectURL(bb));
                pom.setAttribute('download', filename);

                pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
                pom.draggable = true;
                pom.classList.add('dragout');
                pom.click();
            }).catch(e => {
                console.log(`error ${e}`)
            })
    }

    function crearSubCategoria() {
        // db.collection(tabla).doc().set({
        //     codigo: codigo,
        //     nombre: nombre
        // }).then(() => {
        //     limpiar()
        //     setOpen(false)
        //     Swal.fire({ icon: "success", text: "Se ha creado", });
        // }).catch((error) => {
        //     Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
        //     console.error("Error adding document: ", error)
        // });
    }

    function actualizarSubCategoria() {
        // if (id !== '') {
        //     db.collection(tabla).doc(id).update({
        //         codigo: codigo,
        //         nombre: nombre
        //     }).then(() => {
        //         limpiar()
        //         setOpen(false)
        //         Swal.fire({ icon: "success", text: "Se ha actualizado", });
        //     }).catch((error) => {
        //         Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
        //         console.error("Error adding document: ", error)
        //     });
        // } else {
        //     crearSubCategoria()
        // }
    }

    function limpiar() {
        setId('')
        setNombre('')
        setCodigo('')
    }

    function handleOpen(item) {
        if (item) {
            setId(item.id)
            setNombre(item.nombre)
            setCodigo(item.codigo)
        }
        setOpen(true)
    }

    function handleOpen2(item) {
        if (item) {
            setId(item.id)
            setNombre(item.nombre)
            setCodigo(item.codigo)
        }
        setOpen2(true)
    }

    function deleteProducto() {
        // db.collection(tabla).doc(id).delete()
        //     .then(() => {
        //         Swal.fire({ icon: "success", text: "Se ha eliminado" })
        //         setOpen2(false)
        //         limpiar()
        //     })
    }


    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>{ventana}</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => formato()}
                                variant="contained" color='success'>
                                GENERAR ATS
                            </Button>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px', overflowX: 'scroll' }}>
                                <Box sx={{
                                    minWidth: 500,
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={categorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={categorias ? categorias : []}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 100}
                                    />
                                </Box>
                            </Paper>
                        </Grid> */}
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} {ventana} </strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Código:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={codigo}
                                            onChange={(e) => setCodigo(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Tipo:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombre}
                                            onChange={(e) => setNombre(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                codigo == '' ||
                                                nombre === ''
                                            }
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>

            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR: {nombre}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
