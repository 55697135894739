import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const ListConfig = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [options, setOptions] = useState(false);
    const [open7, setOpen7] = useState(false);
    const [open12, setOpen12] = useState(false);
    const [open13, setOpen13] = useState(false);
    const [openSegu, setOpenSegu] = useState(false);
    const [open9, setOpen9] = useState(false);
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'configuraciones']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOptions(!options)}>
                    <ListItemIcon>
                        <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configuraciones" />
                    {options ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={options} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>


                        {/* <ListItemButton onClick={() => setOpen11(!open11)}>
                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                            {open11 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton> */}

                        {/* <Collapse in={open11} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Gestion usuario'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/configuraciones/GestionDeUsuarios/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse> */}

                        <ListItemButton component={RouterLink} to={`/configuraciones/planes/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Planes" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`/configuraciones/zonas/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Zonas" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`/configuraciones/facturas/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Facturas" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`/configuraciones/sectores/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Sectores" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`/configuraciones/mantenimiento-fecha-de-corte/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Mantenimiento fecha de corte" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`/configuraciones/promociones/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Promociones" />
                        </ListItemButton>

                        <ListItemButton onClick={() => setOpen7(!open7)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Promociones" />
                            {open7 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open7} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Mantenimiento de promociones'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/dashboard/${text}/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpen9(!open9)}>
                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText primary="Zonas" />
                            {open9 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={open9} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Mantenimiento de zonas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/dashboard/${text}/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpen12(!open12)}>
                            <ListItemIcon>

                            </ListItemIcon>
                            <ListItemText primary="Fecha de cortes" />
                            {open12 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={open12} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Mantenimiento de fechas de cortes'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/dashboard/${text}/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpenSegu(!openSegu)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Seguridad" />
                            {openSegu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openSegu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Roles', 'Permisos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/dashboard/${text}/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setOpen13(!open13)}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Estado" />
                            {open13 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open13} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {['Mantenimiento de estado'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/dashboard/${text}/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>



                        <ListItemButton component={RouterLink} to={`/configuraciones/instaladores/`}>
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary="Instaladores" />
                        </ListItemButton>



                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}