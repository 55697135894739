import React, { useEffect, useState} from 'react'
import {
    CircularProgress,
    Stack,
    Typography,
    Box,
    Grid,
    Paper,
} from '@mui/material';
import {  ChartLegend, ChartSeries, ChartSeriesItem, Chart as Char2 } from '@progress/kendo-react-charts';
import { db } from '../../firebase';
import moment from 'moment';

import Chart from "react-apexcharts";
import { useNavigate } from 'react-router-dom';

export const TicketsResumen = () => {

    const [datosVisita, setDatosVisita] = useState([])
    const estadosVisita = ['EN ESPERA', 'GESTIONADOS', 'CERRADOS', 'POR REASIGNAR']
    const [loading, setLoading] = useState(false)

    const [categorias, setCategorias] = useState([])
    const [valores, setValores] = useState([])

    const options = {
        chart: {
            type: 'area',
            toolbar: {
                show: true,
            },

            zoom: {
                enabled: true,
                autoScaleYaxis: true
            },
            height: 350,
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            padding: {
                right: 30,
                left: 20
            }
        },
        labels: categorias,
        yaxis: {
            // title: {
            //   text: 'Tickets Abiertos'
            // },
        },
        xaxis: {
            type: "datetime"
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },

    };

    const series = [
        {
            name: 'Tickets Abiertos',
            data: valores
        },
    ]

    useEffect(() => {
        LoadData()
    }, [])


    const LoadData = () => {
        let ref = db.collection("tickets").orderBy("created", "asc");
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            cargarVisita(data)
            cargarVisitaTiempo(data)
        })
    };

    function cargarVisitaTiempo(data) {
        let fechas = []
        let agrupado4 = data.reduce((formato, { created, estado }) => {
            let campo = 'NO ASIGNADO'
            if (created) {
                campo = moment(created.toDate().toString()).format('YYYY-MM-DD')
                if (!formato[campo]) {
                    fechas.push(campo)
                    formato[campo + ''] = 0;
                }
                formato[campo + ''] = formato[campo + ''] + 1;
            }
            return formato;
        }, {});
        let data4 = [];
        fechas.map((item) => {
            data4.push((!agrupado4[item + '']) ? 0 : agrupado4[item + ''])
        })
        setCategorias(fechas)
        setValores(data4)
    }



    function cargarVisita(data) {
        setLoading(true)
        let agrupado4 = data.reduce((formato, { created, estado }) => {
            let campo = 'NO ASIGNADO'
            if (created) {
                if (estado !== undefined) {
                    campo = estado + ''
                }
                if (!formato[campo]) formato[campo] = 0;
                formato[campo] = formato[campo] + 1;
            }
            return formato;
        }, {});
        let data4 = [];
        let colores = ['red','blue','darkGreen','yellow']
        estadosVisita.map((item, index) => {
            data4.push({
                estado: item,
                valor: (!agrupado4[index + '']) ? 0 : agrupado4[index + ''],
                color: colores[index]
            })
        })
        setDatosVisita([data4[0],data4[1],data4[3],data4[2]])
        setLoading(false)
    }

    const loader = (
        <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <br />
            <CircularProgress align="center" />
            <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
        </Stack>
    )

    const seriesLabels = {
        visible: true,
        font: "11px sans-serif",
    }

    let navigate = useNavigate()
    const redireccion = (opcion) => {
        navigate("/tickets/lista-tickets/"+estadosVisita.indexOf(opcion))
    } 

    
    

    return (
        <>
            <Box px={2}>
                <Typography variant='h5' component='h1'> <strong>RESUMEN TICKETS VISITA TECNICA</strong> </Typography>
            </Box>
            <Box py={2} px={2}>
                <Grid container spacing={5}>
                    <Grid item md={6} lg={6} xs={12}>
                        <Paper elevation={2} sx={{ padding: '10px' }}>
                            <Typography sx={{ fontSize: '16px', display: 'inline-block', width: '50%' }}><strong>Estado De Tickets</strong></Typography>
                            {/* <Typography sx={{ fontSize: '12px', display: 'inline-block', width: '50%', textAlign: 'right' }}>Últimos 7 días </Typography> */}
                            {!loading &&
                                <Char2 transitions={false} onAxisLabelClick={(event) => redireccion(event.text)}
                                onSeriesClick={(event) => redireccion(event.category)}>
                                    <ChartLegend position="right" orientation="vertical" spacing={20} />
                                    <ChartSeries>
                                        <ChartSeriesItem type="column"
                                            labels={seriesLabels}
                                            data={datosVisita}
                                            field="valor"
                                            categoryField="estado"
                                            colorField="color" />
                                    </ChartSeries>
                                </Char2>
                            }
                            {loading &&
                                <>
                                    {loader}
                                </>
                            }
                        </Paper>
                    </Grid>
                    <Grid item md={6} lg={6} xs={12}>
                        <Paper elevation={2} sx={{ padding: '10px' }}>
                            <Typography sx={{ fontSize: '16px', display: 'inline-block', width: '50%' }}><strong>Historial Tickets Creados</strong></Typography>
                            <Chart type="line"
                                options={options}
                                series={series}
                                height={385}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
