import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper
} from "@mui/material";
import { db } from "../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from "react";

import Swal from 'sweetalert2'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export const ViewItemsKitInventario = ({ props }) => {

    const [total, setTotal] = useState(0);

    const [openModal, setOpenModal] = useState(false)

    const [openDialogConfirm, setOpenDialogConfirm] = useState(false)

    const [openDialogRechazar, setOpenDialogRechazar] = useState(false)

    const [data, setData] = useState('')

    const openConfirmModal = () => {
        setOpenDialogConfirm(true)
        setOpenModal(false)
    }

    const openRechazoModal = () => {
        setOpenDialogRechazar(true)
        setOpenModal(false)
    }

    const closeConfirmModal = () => {
        setOpenDialogConfirm(false)
        setOpenModal(true)
    }

    const closeRechazoModal = () => {
        setOpenDialogRechazar(false)
        setOpenModal(true)
    }

    const aprobarTransferencia = () => {
        db.collection('transferencia_activos_fijos').doc(props.uidSolicitud).update({
            estado: 2
        })
        setOpenDialogConfirm(false)
        Swal.fire({ icon: "success", text: 'Se ha aprobado la transferencia con exito', });

    }

    const rechazarTransferencia = () => {
        db.collection('transferencia_activos_fijos').doc(props.uidSolicitud).update({
            estado: 1
        })
        setOpenDialogRechazar(false)
        Swal.fire({ icon: "success", text: 'Se ha rechazado la transferencia con exito', });

    }

    const getItems = () => {
        let ref = db.collection('kits_inventario').doc(props.uid_kit).collection('list')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
        })
    }

    const dispatch = useDispatch();
    useEffect(() => {
        getItems()
    }, [dispatch])

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'codigo_producto', headerName: 'CODIGO', width: 140 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 440 },
        { field: 'categoria_producto', headerName: 'CATEGORIA', width: 140 },
        { field: 'subcategoria_producto', headerName: 'SUBCATEGORIA', width: 160 },
        { field: 'marca_producto', headerName: 'MARCA', width: 140 },
        { field: 'modelo_producto', headerName: 'MODELO', width: 140 },
        { field: 'unidad_medida_producto', headerName: 'UNIDAD MEDIDA', width: 150 },
        { field: 'cantidad', headerName: 'CANTIDAD', width: 130 },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <RemoveRedEyeIcon />
                </Button>
            </Tooltip>

            {/* DIALOG CUENTAS*/}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="xl" fullWidth>
                <DialogTitle><strong>{props.uid_kit}</strong></DialogTitle>

                <DialogContent>
                    <Paper elevation={3}>
                        <Box py={1} px={1} sx={{ height: 700, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,

                                }}

                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG CONFIRMAR*/}
            <Dialog open={openDialogConfirm} onClose={() => setOpenDialogConfirm(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>ESTA SEGURO SE APROBAR LA TRANSFERENCIA</strong></DialogTitle>
                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => closeConfirmModal()}>CANCELAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => aprobarTransferencia()}>APROBAR</Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG RECHAZAR*/}
            <Dialog open={openDialogRechazar} onClose={() => setOpenDialogRechazar(false)} maxWidth="xs" fullWidth>
                <DialogTitle><strong>ESTA SEGURO SE RECHAZAR LA TRANSFERENCIA</strong></DialogTitle>
                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => closeRechazoModal()}>CANCELAR</Button>
                    <Button variant="contained" color="error" size="small" onClick={() => rechazarTransferencia()}>RECHAZAR</Button>
                </DialogActions>

            </Dialog>

        </>
    )
}
