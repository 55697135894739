import PropTypes from 'prop-types';
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Typography,
    Paper,
    Dialog,
    DialogContent,
    DialogTitle,
    TableContainer,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    Grid,
    MenuItem,
    Select,
    Card,
    CardContent,
    CircularProgress,
    Stack,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import moment from 'moment'
import * as XLSX from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React, { useEffect, useState, useRef } from "react";
import { db, firebase, auth } from '../../../firebase';
import { PlanCuentaMayor } from '../../../pages/contabilidad/plan_de_cuentas/PlanCuentaMayor';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteItemListCuentas } from '../../../pages/contabilidad/plan-de-cuentas/IconsButtonsPlanesCuenta/DeleteItemListCuentas';
import { PlanCuentaMenor } from '../../../pages/contabilidad/plan_de_cuentas/PlanCuentaMenor';
import UploadIcon from '@mui/icons-material/Upload';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NewCuentaHija } from '../../../pages/contabilidad/plan-de-cuentas/IconsButtonsPlanesCuenta/NewCuentaHija';
import { DataCuenta1 } from '../../../pages/contabilidad/plan-de-cuentas/DataCuenta1';
import { NewPlanDeCuentasMayor } from './NewPlanDeCuentasMayor';
import { UploadExcel } from './UploadExcel';
import { DeleteChildCounts } from './DeleteChildCounts';
import { EditChildCount } from './EditChildCount';
/* import { IconsMovimientos } from './IconsMovimientos'; */

export function PlanDeCuentas() {

    const [value, setValue] = React.useState(0);

    const openSelectsCuentas = (event) => {
        setValue(event.target.value);
    };

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [file, setFile] = useState(null);

    const [openCuentaMayor, setOpenCuentaMayor] = useState(false);

    const [allData, setAllData] = useState();
    const [data, setData] = useState();
    const [dataCuenta2, setDataCuenta2] = useState();
    const [itemsProductosActivos, setItemsProductosActivos] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const [data2, setData2] = useState();

    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([]);


    const [message, setMessage] = useState()

    const cuenta2List = useSelector(state => state.cuenta2List);
    const { cuentas2, loadingCuentas2 } = cuenta2List
    const [cuenta2, setCuenta2] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        LoadData()
        /* LoadData2() */
    }, [dispatch]);

    const LoadData = (startAfterDoc, persistMessages = []) => {

        /* let ref = db.collection("cuenta_mayor"); */
        let ref = db.collection("plan_cuentas").orderBy("created", "asc");
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });
    };

    /*     const LoadData2 = () => {
            let ref = db.collection("total_movimientos");
            //Si tiene algun doc anterior se agrega acontinuacion
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
    
                setData2(data);
                setQueryDocqueryDoc2(snapshot.docs[totalCount - 1]);
                setIsLastPage2(totalCount < 1);
            });
        }; */

    function createData(name) {
        return {
            name,
        };
    }

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {/* LISTA DE NOMBRES PRINCIPALES LISTA */}
                        {row.name}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>NUM. MOV</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell>{row.uidSolicitud}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    Row.propTypes = {
        row: PropTypes.shape({

            history: PropTypes.arrayOf(
                PropTypes.shape({

                }),
            ).isRequired,

        }).isRequired,
    };

    const rows = [
        createData('Producto'),
    ];

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const [open2, setOpen2] = React.useState(false);

    const [openCaja, setOpenCaja] = React.useState(false);

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                jsonData.forEach((obj) => {
                    db.collection("plan_cuentas_test")
                        .doc(obj.CUENTA)
                        .set({
                            cuenta: obj.CUENTA,
                            nombre: obj.NOMBRE,
                            nivel: obj.NIVEL,
                            naturaleza: obj.NATURALEZA,
                            tipo_de_saldo: obj.TIPO_DE_SALDO,
                            tipo_de_cuenta: obj.TIPO_DE_CUENTA,
                            item_estado_financiero: obj.ITEM_ESTADO_FINANCIERO,
                            catalogo_de_costo_obligatorio: obj.CATALOGO_DE_COSTO_OBLIGATORIO,
                            cuenta_de_ganancia: obj.CUENTA_DE_GANANCIA,
                            cuenta_de_perdida: obj.CUENTA_DE_PERDIDA,
                            estado: obj.ESTADO,
                            fecha_carga_plan_cuentas: firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_excel: userInfo.displayName
                        })
                        .then((docRef) => {
                            console.log("CAJA: ", docRef);
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                });
                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const onData = () => {
        setOpenCuentaMayor(true)
    }

    const [estado, setEstado] = useState();

    const [nivelDeCuenta, setNivelDeCuenta] = useState();

    const handleNivelDeCuenta = (e) => {
        setNivelDeCuenta(e)
    }

    const generateItems = (amount) => {
        const arr = Array.from(Array(amount))
        return arr.map((number, i) => ({
            id: i,
            name: `Name ${i + 1}`,
            type: `Item Type ${i + 1}`,
        }))
    }

    const desplegarTabla = (props) => {
        setOpen2(!open2)
        console.log('desplegado')
        console.log(props)
    }

    const [dataItems, setDataItems] = useState();

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        const [dataItems, setDataItems] = useState();
        const [isLastPageItems, setIsLastPageItems] = useState(0);
        const [queryDocItems, setQueryDocqueryDocItems] = useState([]);

        const desplegarTablaOpen = async (props) => {
            setOpen(!open)
            console.log(props.id)
            /* console.log('desplegado')
            console.log(props.id)
            const agendaDB = await db.collection("plan_cuentas").where("codigo_contable", "==", props.id).get()
            const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            console.log(data1[0].motivo) */

            let ref = db.collection("plan_cuentas").doc(props.id).collection('items');
            //Si tiene algun doc anterior se agrega acontinuacion
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];

                setDataItems(data);
                setQueryDocqueryDocItems(snapshot.docs[totalCount - 1]);
                setIsLastPageItems(totalCount < 1);
                console.log(data)
            });
        }

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell style={{ fontSize: '12px' }}>{row.numero_plan_cuenta}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.nombre_cuenta_contable}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}>{row.nivel}</TableCell>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => desplegarTablaOpen(row)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box py={1}>
                                <Box py={1}>
                                    <p style={{ fontSize: '16px', marginBottom: '0px', marginLeft: '0px', marginRight: '0px', fontWeight: 'bold' }}>
                                        CUENTAS
                                    </p>
                                </Box>
                                <Paper elevation={1}>
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>NIVEL</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>ESTADO</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>EDITAR</strong></TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><strong>ELIMINAR</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataItems && dataItems.map((historyRow) => (
                                                <TableRow>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.cuenta}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.nombre}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.nivel}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.estado}
                                                    </TableCell>
                                                    <TableCell>
                                                        <EditChildCount props={historyRow} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <DeleteChildCounts props={historyRow} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //

        const reData = [
            ...data2.map(doc => {
                return {

                    NUMERO_MOVIMIENTO: doc.uid_solicitud_pedido,

                    CODIGO_PRODUCTO: doc.codigo,

                    CATEGORIA: doc.categoria_producto,

                    DESCRIPCION: doc.descripcion,

                    COSTO_PROMEDIO: doc.costo_promedio,

                    PRECIO_COMPRA: doc.precio_compra,

                    CANTIDAD_SOLICITADA: doc.cantidad_solicitada,

                    STOCK_ACTUAL: doc.cantidad_total_despues,

                    TOTAL: doc.total,

                    FECHA_MOVIMIENTO: doc.created.toDate(),

                }
            }),
        ]

        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "reporte_movimientos");
        XLSX.writeFile(wb, `REPORTE_MOVIMIENTOS_${date1}.xlsx`)
    }


    const getData = () => {
        console.log(data)
    }

    return (
        <>

            <Box px={2}>
                <p style={{ fontSize: '14px', marginBottom: '0px', marginLeft: '0px', fontWeight: 'bold' }}>
                    PLAN DE CUENTAS
                </p>
            </Box>

            <Box pt={2} pb={1} px={2}>
                <NewPlanDeCuentasMayor />
                <UploadExcel />
            </Box>

            {/* <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
            >
                {" "}
                DESCARGAR EXCEL
            </Button> */}

            <TableContainer component={Paper}>

                <Table size="small" aria-label="purchases" sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontSize: '12px' }}><strong>CODIGO CONTABLE</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>NOMBRE CUENTA CONTABLE</strong></TableCell>
                            <TableCell style={{ fontSize: '12px' }}><strong>NIVEL</strong></TableCell>
                            <TableCell>{''}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data && data.map((row) => (
                            <Row key={row} row={row} />
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

            <Dialog open={openCuentaMayor} fullWidth onClose={() => setOpenCuentaMayor(false)} maxWidth="lg">
                <DialogContent>
                    <NewPlanDeCuentasMayor />
                </DialogContent>
            </Dialog>

        </>
    );
}