import {
  Tooltip,
  Box,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, storage } from "../../../firebase";
import { UpdatePlanes } from "./UpdatePlanes"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid'
import { DeletePlan } from "./DeletePlan"
export const Planes = () => {
  const [total, setTotal] = useState(0)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([]);
  useEffect(() => {
    LoadData()
  }, [])
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("perfiles").where('estado', '==', 'VIGENTE');
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      ]
      setData(data)
    })
  }
  function CustomFooterTotalComponent(props) {
    return ''
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'estado', headerName: 'ESTADO', width: 100,
      renderCell: (params) => {
        return <Chip size="small" variant='outlined' label="ACTIVO" color="success" />
      }
    },
    { field: 'plan', headerName: 'NOMBRE', width: 300 },
    { field: 'velocidadSubida', headerName: 'SUBIDA', width: 100 },
    { field: 'velocidadBajada', headerName: 'BAJADA', width: 100 },
    { field: 'valor', headerName: 'VALOR', width: 100 },
    { field: 'comparticion', headerName: 'COMPARTICION', width: 100 },
    { field: 'tipo', headerName: 'TIPO', width: 100 },
    {
      field: '1', headerName: 'EDITAR', width: 80,
      renderCell: (params) => {
        return <UpdatePlanes props={params.row} />
      }
    },
    {
      field: '2', headerName: 'ELIMINAR', width: 80,
      renderCell: (params) => {
        return <DeletePlan props={params.row} />
      }
    },
  ]
  const csvOptions = {
    delimiter: ';',
    fileName: `SOLICITUD APROBADAS`
  }
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  function CustomFooterTotalComponent(props) {
    return ''
  }
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        density="compact"
        rows={data ? data : []}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
          Footer: CustomFooterTotalComponent,
        }}

        onStateChange={(state) => {
          setTotal(state.pagination.rowCount)
        }}
      />
    </Box>
  );
};