import { Radio, TextField, Typography, Box, Grid, RadioGroup, Button, FormLabel, Divider, Select, AlertTitle, Alert } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InputValidation from '../../../components/utils/InputValidation';
import { db, firebase } from '../../../firebase';
import moment from 'moment'
import Swal from 'sweetalert2'


export const StatusPlans = () => {
    const [statusClient, setStatusClient] = useState()
    const [contractArray, setContractArray] = useState([])

    const [longitude, setLongitude] = useState({ campo: '', valido: true })

    const [latitude, setLatitude] = useState({ campo: '', valido: true })

    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [cedula2, setCedula2] = useState()
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [direccion, setDireccion] = useState({ campo: '', valido: true })
    const [telefono, setTelefono] = useState({ campo: '', valido: true })
    const [movil, setMovil] = useState({ campo: '', valido: true })
    const [correo, setCorreo] = useState({ campo: 'sebastian_mite@hotmail.com', valido: true })
    const [nacionalidad, setNacionalidad] = useState({ campo: '', valido: true })
    const [provincia, setProvincia] = useState({ campo: '', valido: true })
    const [canton, setCanton] = useState({ campo: '', valido: true })
    const [parroquia, setParroquia] = useState({ campo: '', valido: true })
    const [coordenadas, setCoordenadas] = useState({ campo: '1234', valido: true })
    useEffect(() => {
    }, [])
    const verifyAge = (fechaInstalacion) => {
        console.log('Entro funcion', fechaInstalacion.campo)
        if (fechaInstalacion.campo) {
            var birth = new Date(fechaInstalacion.campo);
            var date_limit = new Date('2002-03-01');
            //Verificamos 
            if (birth && birth > date_limit) {
                console.log('Es mayor de edad')
            }
        } else {
            console.log('No entra fecha aun')
        }

    }
    const apiCivil = (cedula2) => {
        setCedula2(cedula2)
        if (cedula2 && cedula2.length >= 10) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`http://192.168.90.18:5000/${cedula2}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    function calcularEdad(fecha_nacimiento) {
                        var hoy = new Date();
                        var cumpleanos = new Date(fecha_nacimiento);
                        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
                        var m = hoy.getMonth() - cumpleanos.getMonth();
                        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                            edad--;
                        }
                        return edad;
                    }
                    // if (typeof result !== 'undefined' && result.length > 0) {
                    // console.log('a')
                    // Swal.fire({
                    //     icon: 'info',
                    //     title: 'Esta cedula no existe en nuestro base de registro civil.',
                    // })
                    //} else {
                    const data = JSON.parse(result)
                    console.log(data[0].DES_CIUDADANIA)
                    if (data[0].DES_CIUDADANIA === 'FALLECIDO') {

                        Swal.fire({
                            icon: 'warning',
                            text: 'Este cliente se encuentra fallecido.',
                            timer: 5000,
                        })
                    } else {
                        if (data) {
                            //Cambiar formato de fecha de es a us
                            const formatBirth = moment(data[0].FECHA_NACIM, 'DD MM YYYY').format('YYYY/MM/DD')
                            var age = calcularEdad(formatBirth);
                            //Validacion de mayor de edad
                            if (age >= 18) {
                                console.log('si entro 2')
                                setCedula2(cedula2)
                                setNombre({ campo: data[0].NOMBRES, valido: true })
                                setDireccion({ campo: data[0].NOMBRE_CALLE, valido: true })
                                setNacionalidad({ campo: data[0].DES_NACIONALID, valido: true })

                                var myHeaders = new Headers();
                                myHeaders.append("Content-Type", "application/json");
                                var raw = JSON.stringify({
                                    "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
                                    "cedula": cedula2
                                });
                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: raw,
                                    redirect: 'follow'
                                };
                                fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetClientsDetails", requestOptions)
                                    .then(response => response.text())
                                    .then(result => {
                                        const dataJSON = JSON.parse(result)
                                        //console.log('Hola', dataJSON.mensaje)
                                        if (dataJSON) {
                                            if (dataJSON.mensaje == 'No existe el cliente con el filtro indicado.') {
                                                console.log('No existe cliente')
                                                setStatusClient('Este cliente es nuevo en Yiga5')
                                            } else {
                                                console.log('Si existe cliente ')

                                                const contracts = dataJSON.datos
                                                const data = [...contracts.map(doc => {
                                                    return {
                                                        'NUM_CONTRATO': doc.NUM_CONTRATO,
                                                        'estado': doc.estado,
                                                        'REFERENCIA': doc.REFERENCIA
                                                    }
                                                })]
                                                setContractArray(data)
                                                setStatusClient('')
                                                console.log(contractArray, 'array')
                                            }
                                        }
                                    })
                                    .catch(error => console.log('error', error));

                            } else {
                                console.log('No es mayor de edad')
                                Swal.fire({
                                    icon: 'error',

                                    text: 'Este cliente no es mayor de edad.'
                                })
                            }
                        }
                    }

                    //}

                })
                .catch(error => console.log('error', error));
        }
    }
    const submitHandler = (e) => {
        e.preventDefault()
        console.log(firebase.firestore.FieldValue.serverTimestamp())
        //Validaciones
        db.collection('service_request').add({
            cedula: cedula2,
            nombre: nombre.campo,
            direccion: direccion.campo,
            nacionalidad: nacionalidad.campo,
            telefono: telefono.campo,
            movil: movil.campo,
            correo: correo.campo,
            coordenadas: new firebase.firestore.GeoPoint(latitude.campo, longitude.campo),
            estado: 0,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            console.log("licencia ", docRef);
            Swal.fire({
                icon: 'success',
                title: 'Solicitud enviado con exito.',
            })

        }).catch((error) => {
            console.error("Error adding document: ", error);
        });

    }
    return (
        <>
            <Typography>Mantenimiento Planes</Typography>
            <form onSubmit={submitHandler}>
                <Box py={2}>
                    {statusClient && <Alert severity="info">{statusClient}</Alert>}
                    {contractArray.length > 0 &&

                        <Alert severity="info">
                            {contractArray && contractArray.map((item, index) => (
                                <>
                                    <AlertTitle>Este usuario es parte de Yiga 5 y sus contratos son: </AlertTitle>
                                    <Typography key={index}>{item.NUM_CONTRATO} - <strong>{item.estado}</strong></Typography>
                                </>
                            ))}
                        </Alert>
                    }
                </Box>               
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <InputValidation
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="Nombres plan"
                            name="name"
                            helperText="Minimo 8 caracteres"
                            //patterns={/^.{8,30}$/}
                        />
                        <InputValidation
                            estado={movil}
                            cambiarEstado={setMovil}
                            label="Velocidad de subida"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                        <InputValidation
                            estado={movil}
                            cambiarEstado={setMovil}
                            label="Comparticion"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <InputValidation
                            estado={direccion}
                            cambiarEstado={setDireccion}

                            label="Estado"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                        <InputValidation
                            estado={telefono}
                            type="number"
                            cambiarEstado={setTelefono}
                            label="Codigo de plan"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputValidation
                            estado={nacionalidad}

                            cambiarEstado={setNacionalidad}
                            label="Velocidad de baja"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                        <InputValidation
                            estado={correo}
                            type="email"
                            cambiarEstado={setCorreo}
                            label="Tipo"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />

                    </Grid>
                </Grid>
                <Typography>Datos de Producto</Typography>
                <Grid container spacing={2}>
                    
                    <Grid item>
                        <InputValidation
                            estado={nacionalidad}
                            cambiarEstado={setNacionalidad}
                            label="Categoria"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />

                        <InputValidation
                            estado={latitude.campo}
                            type="number"
                            cambiarEstado={setLatitude}
                            label="Codigo"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                    </Grid>
                    <Grid item>
                        <InputValidation
                            estado={longitude.campo}
                            type="number"
                            cambiarEstado={setLongitude}
                            label="Tipo"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                    </Grid>
                    <Grid item>
                        <InputValidation
                            estado={longitude.campo}
                            type="number"
                            cambiarEstado={setLongitude}
                            label="Descripcion"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        //patterns={/^.{8,30}$/}
                        />
                    </Grid>
                </Grid>


                <Box display="flex" flexDirection="row-reverse">
                    <Button type="submit" disableElevation color='secondary' variant='contained'>Enviar solicitud</Button>
                </Box>
            </form>
        </>
    )
}