import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Paper, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";
export const NuevoDepartamento = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [departamento, setDepartamento] = useState('')

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async () => {
        await db.collection('tickets_parametros').doc('departamentos').collection('list').doc(departamento.toUpperCase()).set({
            departamento: departamento.toUpperCase(),
            fecha_creacion_departamento: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            setDepartamento('')
            Swal.fire({ icon: "success", title: "Se ha creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>

            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>
                        <Box pb={2}>
                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DEPARTAMENTO:</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                value={departamento}
                                onChange={(e) => setDepartamento(e.target.value)}
                                name="departamento"
                                size="small"
                                fullWidth
                            />
                        </Box>

                        <Button
                            onClick={() => createProduct()}
                            fullWidth
                            variant="contained"
                            disableElevation
                            disabled={departamento === ''}
                        >
                            CREAR
                        </Button>
                    </Box>
                </Paper>
            </Box>



        </>
    );
};
