import React, { useState, useRef } from 'react'
import { Box, Button, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2'
import { storage, firebase, db } from '../../../../firebase'
import { useSelector, useDispatch } from 'react-redux';
import { uploadFile } from '../../../../redux/actions/ventasActions';
import { useParams } from 'react-router-dom';
import Compressor from 'compressorjs';
export const UpdateFileUploadTicketsInstaladores = ({ instalacion, cambiarEstado: setFile, estado: file, nameFile }) => {

    const [fileLoading, setFileLoading] = useState(false)
    const fileRef = useRef()
    const [fileName, setFileName] = useState(null)
    const [fileImg, setFileImg] = useState('')

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        if (file.type === "image/png" || file.type === "image/jpeg") {
            setFileName(file.name)
            setFile(file)
            const img = URL.createObjectURL(file)
            setFileImg(img)
        } else {
            Swal.fire({ icon: "warning", text: "No aceptamos este tipo de archivos", });
        }
    }
    const handleRemove = () => {
        setFileName('')
        setFile('')
        setFileLoading(false)
        fileRef.current.value = ""
    }
    const handleUploadFile = async () => {

        console.log('instalacion', instalacion)

        if (file) {
            setFileLoading(true)
            new Compressor(file, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {

                    const pictureRef = storage.ref().child('tickets_tecnicos').child(`${instalacion.uid_ticket}-${instalacion.nombre_cliente}`).child(nameFile)
                    const letreroUpload = pictureRef.put(compressedResult)
                    letreroUpload.on("state_changed",
                        (snapshot) => {

                            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            setFileLoading(false)
                            Swal.fire({ icon: 'error', text: error, })
                        },
                        () => {
                            letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                console.log('File available at', downloadURL)
                                //FOTO ANTES
                                if (nameFile === 'foto_antes') {
                                    db.collection('tickets').doc(instalacion.uid_ticket).update({
                                        foto_antes: downloadURL
                                    })
                                    db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                        foto_antes: downloadURL
                                    })

                                    //FOTO DESPUES
                                } else if (nameFile === 'foto_despues') {
                                    db.collection('tickets').doc(instalacion.uid_ticket).update({
                                        foto_despues: downloadURL
                                    })
                                    db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                        foto_despues: downloadURL
                                    })
                                    
                                    //FOTO OPCIONAL 1
                                } else if (nameFile === 'foto_opcional1') {
                                    db.collection('tickets').doc(instalacion.uid_ticket).update({
                                        foto_opcional1: downloadURL
                                    })
                                    db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                        foto_opcional1: downloadURL
                                    })

                                    //FOTO OPCIONAL 2
                                } else if (nameFile === 'foto_opcional2') {
                                    db.collection('tickets').doc(instalacion.uid_ticket).update({
                                        foto_opcional2: downloadURL
                                    })
                                    db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                        foto_opcional2: downloadURL
                                    })

                                }else if (nameFile === 'foto_test') {
                                        db.collection('tickets').doc(instalacion.uid_ticket).update({
                                            foto_test: downloadURL
                                        })
                                        db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                            foto_test: downloadURL
                                        })
                                }

                            else if (nameFile === 'foto_potencia') {
                                    db.collection('tickets').doc(instalacion.uid_ticket).update({
                                        foto_potencia: downloadURL
                                    })
                                    db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                        foto_potencia: downloadURL
                                    })
                            }

                            else if (nameFile === 'foto_solucion') {
                                db.collection('tickets').doc(instalacion.uid_ticket).update({
                                    foto_solucion: downloadURL
                                })
                                db.collection('tickets_tecnicos').doc(instalacion.uid_ticket).update({
                                    foto_solucion: downloadURL
                                })
                        }
                                
                                setFileLoading(false)

                            });

                        }
                    )
                },
                error: (error) => {
                    Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                }
            });
        }
    }
    return (
        <>
            {fileName ? <Box display='block'>
                <img width={100} src={fileImg} />
                <Box>Nombre el archivo: {fileName}</Box>
            </Box> : 'Por favor, cargue el archivo...'}
            {!file ?
                <Box>
                    <input
                        onChange={(e) => handleUploadFileLocal(e)}
                        style={{ display: 'none' }}
                        id={`contained-button-file${nameFile}`}
                        multiple
                        type="file"
                        ref={fileRef}
                    />
                    <label htmlFor={`contained-button-file${nameFile}`}>
                        <Button style={{ textTransform: 'none' }} variant="contained" color="primary" disableElevation component="span">
                            Cargar archivo...
                        </Button>
                    </label>
                </Box> :
                <Box display="flex">
                    <Box pr={1}>
                        <Button
                            disableElevation
                            variant='outlined'
                            disabled={fileLoading}
                            onClick={(e) => handleRemove()}
                        >Borrar archivo</Button>
                    </Box>
                    <Box>
                        <Button
                            onClick={handleUploadFile}
                            variant="contained"
                            disabled={fileLoading}
                            color="primary"
                            disableElevation
                        >{!fileLoading ? <strong> Subir archivo</strong> : <CircularProgress size={24} />}</Button>
                    </Box>
                </Box>}
        </>
    )
}