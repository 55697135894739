import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Button,
  Collapse,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UpdateInformation } from "./views/UpdateInformation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import IconButton from "@mui/material/IconButton";
import { listFlotas } from "../../redux/actions/flotas/flotasActions";
import { listLiders } from "../../redux/actions/flotas/flotasLiders";
import { listAuxiliares } from "../../redux/actions/flotas/flotasAuxiliares";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  esES,
  GridToolbarExport
} from '@mui/x-data-grid';
export const VentasCrearCliente = () => {
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const dispatch = useDispatch();
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = "POR AGENDAR";
      color = "warning";
    } else if (estado === 1) {
      name = "NO RESPONDE LLAMADAS";
      color = "error";
    } else if (estado === 2) {
      name = "NO TIENE DINERO";
      color = "info";
    }
    return <Chip label={name} size='small' color={color} />;
  }
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 43200000)
  );
  useEffect(() => {
    LoadData();
    dispatch(listFlotas());
    dispatch(listLiders());
    dispatch(listAuxiliares());
  }, [dispatch]);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("agenda").where("estado", "==", 0);
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("created", ">=", startfulldate);
      ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ]
        setData(data)
      })
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onChange2 = () => {
    LoadData()
  }

  function CustomFooterTotalComponent(props) {
    return ''
  }
  const columns = [
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado1', headerName: 'ESTADO', width: 150,
      renderCell: (params) => {
        return <Estado
          estado={
            params.row.estado_color === undefined
              ? params.row.estado
              : params.row.estado_color
          }
        />
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'camara', headerName: 'CAMARA', width: 150 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'premios', headerName: 'PREMIO', width: 200 },
    { field: 'numCam', headerName: 'NUM CAMERA', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },

    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'banco', headerName: 'BANCO', width: 200 },
    { field: 'tipoCuenta', headerName: 'TIPO CUENTA', width: 125 },
    { field: 'numBanco', headerName: 'NUM DE CUENTA', width: 150 },
    {
      field: 'foto_debito', headerName: 'FOTO DEBITO', width: 125,
      renderCell: (params) => {
        return params.row?.foto_debito &&
          <a target="_blank" href={params.row.foto_debito}>VER FOTO</a>
      }
    },
    { field: 'correo', headerName: 'CORREO', width: 150 },
    { field: 'direccion', headerName: 'DIRECCION', width: 300 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'caja', headerName: 'CAJA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'canton', headerName: 'CANTON', width: 200 },
    { field: 'planNombre', headerName: 'PLAN', width: 300 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
    { field: 'empleado', headerName: 'EMPLEADO', width: 200 },

    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado', headerName: 'DISCAPACIDAD', width: 140,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'DISCAP DESCUENTO', width: 200,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : `${value.descuento} - ${value.discapacidad} - $${value.valor}`
    },
    {
      field: 'referido', headerName: 'REFERIDO', width: 100,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDE.', width: 100,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },

    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'created', headerName: 'FECHA DE GENERACION DE PRE-VENTA', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    
   
    {
      field: 'foto_cliente_vivienda', headerName: 'FOTO VIVIENDA', width: 150,
      renderCell: (params) => {
        return params.row.foto_cliente_vivienda &&
          <a target="_blank" href={params.row.foto_cliente_vivienda}>VER FOTO</a>

      }
    },

    { field: 'observacion2', headerName: 'OBSERVACIONES', width: 250 },
    {
      field: 'estado2', headerName: '', width: 100,
      renderCell: (params) => {
        return <div key={params.row.id}><UpdateInformation props={params.row} /></div>
      }
    },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      {/* <CustomExportButton /> */}
      <GridToolbarExport />
    </GridToolbarContainer>
  )
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography>
          PRE AGENDAMIENTO
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button
              disableElevation
              variant="contained"
              onClick={() => LoadData()}
            >
              Aplicar busqueda
            </Button>
          </Collapse>
        </Box>

      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
       
            onStateChange={(state) => {
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
