import {
  IconButton,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Collapse,
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import React, { useEffect, useState } from "react";
import { ExportarCSV } from '../../components/utils/ExportarCSV';
import Tooltip from "@mui/material/Tooltip";
import { ViewRechazoInstaladores } from "../../components/instaladores/ViewRechazoInstaladores";
import { db, storage, firebase } from "../../firebase";
import moment from 'moment'

import { auth } from '../../firebase'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useSelector } from 'react-redux';
import { ViewInstalacionesRealizadas } from "../../components/instaladores/ViewInstalacionesRealizadas";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SearchInput from '../../components/utils/SearchInput';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridExportMenuItemProps,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridApi,
  GridToolbar,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES,
  GridToolbarExport
} from '@mui/x-data-grid';

export const InstalacionesRechazadas = () => {

  const [total, setTotal] = useState(0);

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  /* function Estado({ estado }) {

    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'Por agendar'
      color = 'warning'
    } else if (estado === 1) {
      name = 'Rechazada'
      color = 'info'
    } else if (estado === 2) {
      name = 'Realizada'
      color = 'success'
    }
    return (
      <Chip label={name} color={color} />
    )
  } */

  function Estado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 0) {
      name = 'POR INSTALAR'
      color = 'warning'
    } else if (d.estado === 1) {
      name = 'Rechazada'
      color = 'info'
    } else if (d.estado === 2) {
      name = 'Realizada'
      color = 'success'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO RECHAZADO'
      color = 'info'
    }
    return (
      <Chip label={name} size="small" variant="outlined" color={color} />
    )
  }


  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  useEffect(() => {
    LoadData()
  }, []);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    if (userInfo.email === "jazmin.perez@distribuidor1.santaelena.com.ec" || userInfo.email === "giovanny.rodas@distribuidor1.santaelena.com.ec" || userInfo.email === "jonathan.mendoza@intercommerce.com.ec" || userInfo.email === "daniela.galve@intercommerce.com.ec" || userInfo.email === "daniela.galve@intercommerce.com.ec") {
      let ref = db.collection("tecnicos").where("provincia", "==", "SANTA ELENA").where("estado", "==", 1);

      /* if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('fechaHora', '>=', startfulldate)
        ref = ref.where('fechaHora', '<=', endfulldate) */
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(12, 0, 0, 0)

        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('fechaHora', '>=', startfulldate)
        ref = ref.where('fechaHora', '<=', endfulldate)

        //Si tiene algun doc anterior se agrega acontinuacion
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
        
          setData(data);
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
          setIsLastPage(totalCount < 1);
        });
      }
    } else {
      let ref = db.collection("tecnicos").where("estado", "==", 1);

      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('fechaHora', '>=', startfulldate)
        ref = ref.where('fechaHora', '<=', endfulldate)

        //Si tiene algun doc anterior se agrega acontinuacion
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          // console.log(data, 'data')
          data.map((doc) => {
            if(doc.DSerie){
              console.log(doc.DSerie)
            }
          })
          setData(data);
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
          setIsLastPage(totalCount < 1);
        });
      }
    }
  };

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    function instalacion(fechaHora) {
      if (fechaHora && fechaHora.seconds) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }
    }
    //
    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.id,
          MOTIVO: doc.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION',
          TIPO_CONTRATO: doc.contrato,
          CEDULA: doc.cedula,
          CLIENTE: doc.nombres,
          CORREO: doc.correo,

          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
          DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
          GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.discapacidad,
          DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.descuento,
          VALOR_DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.valor,
          DIRECCION: doc.direccion,
          SECTOR: doc.sector,
          COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,
          TELEFONO: doc.telefono,
          CELULAR: doc.celular,
          WHATSAPP: doc.whatsapp,
          NUM_REFERENCIA: doc.numbRef,
          PLAN: doc.planNombre,
          NUMERO_PLAN: doc.numFlota,
          LIDER: doc.nombreLider,
          FECHA_AGENDA: doc.created.toDate(),
          FECHA_INSTALACION: instalacion(doc.fechaHora),

          SECTOR: doc.sector,
          DIRECCION: doc.direccion,
          COORDENADAS: doc.coordenadas,
          CELULAR: doc.celular,
          TELEFONO: doc.telefono,

          PLAN: doc.planNombre,
          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',

          CABLE_INICIO: doc.cableInicio,
          CABLE_FINAL: doc.cableFinaliza,
          FIBRA_UTILIZADA: parseFloat(doc.cableInicio) - parseFloat(doc.cableFinaliza),
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,

          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,

          VENDEDOR_RESPONSABLE: doc.vendedor,
          NUM_FLOTA: doc.numFlota,
          LIDER_FLOTA: doc.nombreLider,


        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }

  const columns = [
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado', headerName: 'ESTADO', width: 175,
      renderCell: (params) => {
        return <Estado estado={params.row} />
      }
    },
    {
      field: 'observacionInstalacionRechazada', headerName: 'MOTIVO DE RECHAZO', width: 300,
      valueGetter: ({ value }) => value === undefined ? '' : value
    },
    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 160 },
    /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'camara', headerName: 'CAMARA', width: 90 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'numCam', headerName: 'NUM CAMERA', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 120 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 120 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 120 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 160,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },

    { field: 'planNombre', headerName: 'PLAN', width: 300 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 120 },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido', headerName: 'ES REFERIDO', width: 120,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },

    {
      field: 'flotaReferida', headerName: 'VENTA REFERIDA POR FLOTA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },
    {
      field: 'created', headerName: 'FECHA AGENDAMIENTO', type: 'dateTime', width: 190,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 210,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    { field: 'direccion', headerName: 'DIRECCION', width: 350 },
    { field: 'zona', headerName: 'ZONA', width: 250 },
    { field: 'canton', headerName: 'CANTON', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'caja', headerName: 'CAJA', width: 130 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 270 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 270 },
    { field: 'numFlota', headerName: 'FLOTA QUE RECHAZO', width: 170 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
  ];

  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <GridToolbarExport />
      {/* <CustomExportButton /> */}
      {/* <GridToolbarQuickFilter /> */}
    </GridToolbarContainer>
  );

  function CustomFooterTotalComponent(props) {

    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>({data && data.length})</strong> GESTIONES RECHAZADAS
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <Box display="flex" alignItems='center' py={2}>
          <SearchInput setSearch={setSearch} search={search} />
          <IconButton id="basic-button" onClick={() => setOpen(!open)}>
            <FilterListIcon />
          </IconButton>
          {/* <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton> */}


        </Box>

      </Box>

      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density="compact"
            // disableColumnSelector
            // disableDensitySelector
            //hideFooterPagination
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              // Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>

      {/* <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#CONTRATO</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>MOTIVO DE RECHAZO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>#REFERENCIA</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                <TableCell>TIPO CONTRATO</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DESCUENTO</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>ZONA</TableCell>
                <TableCell>CANTON</TableCell>
                <TableCell>CAJA</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>NUMERO FLOTA</TableCell>
                <TableCell>NOMBRE LIDER</TableCell>
                <TableCell>NOMBRE AUXILIAR</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>FECHA AGENDAMIENTO</TableCell>
                <TableCell>FECHA PARA INSTALACION</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR</TableCell>
                <TableCell>ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA</TableCell>
                <TableCell>USUARIO QUE RECHAZA</TableCell>
                <TableCell>FECHA DE RECHAZO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.filter((val) => {
                if (search === '') {
                  return val
                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                  return val
                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                  return val
                } else if (val.numFlota.toLowerCase().includes(search.toLowerCase())) {
                  return val
                }
              }).map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.numContrato && row.cambio_domicilio === true ? row.NUM_CONTRATO : row.numContrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.observacionInstalacionRechazada === undefined ? '' : row.observacionInstalacionRechazada}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.whatsapp}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numbRef}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.planNombre}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.terceraEdad === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.discapacitado === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.discapacidad}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.descuento
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.valor
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sector}
                  </TableCell>

                  {row.zona === undefined ? <TableCell component="th" scope="row">
                    {''}
                  </TableCell> : <TableCell component="th" scope="row">
                    {row.zona}
                  </TableCell>}
                  <TableCell component="th" scope="row">
                    {row.canton}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.caja}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.planValor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.created && typeof row.created === 'number' &&
                      moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                    {row.created && typeof row.created === 'object' &&
                      moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      row.fechaHora && row.fechaHora.seconds ?
                        moment(new Date(row.fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
                        : <>
                          {row.fechaHora && typeof row.fechaHora === 'number' &&
                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                          {row.fechaHora && typeof row.fechaHora === 'object' &&
                            moment(row.fechaHora.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                        </>
                    }

                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.referido === undefined ? 'NO' : row.referido}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      row.fechaHora && row.fechaHora.seconds ?
                        moment(new Date(row.fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
                        : <>
                          {row.fechaHora && typeof row.fechaHora === 'number' &&
                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                          {row.fechaHora && typeof row.fechaHora === 'object' &&
                            moment(row.fechaHora.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                        </>
                    }

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>|
          </Table>
        </TableContainer>
      </Paper> */}
    </>
  );
};