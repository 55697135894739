import React, { useState, useEffect } from "react";
import {
  CircularProgress, Stack, Button,
  DialogActions, DialogContent, DialogTitle, Dialog, Tooltip
} from "@mui/material";
import { db } from '../../firebase'
import 'moment/locale/es'
import Embed from 'react-embed';
import MenuBookIcon from '@mui/icons-material/MenuBook';
export const ViewPdfClient = ({ props }) => {
  const [open, setOpen] = useState(false)
  const [contrato, setContrato] = useState('')
  useEffect(() => {

  }, []);

  const loadData = (id) => {
    let docRef = db.collection("contratos").doc(id)

    docRef.get().then((doc) => {
      if (doc.exists) {
        // console.log("Document data:", doc.data())
        const data = doc.data()
        setContrato(data)
      } else {
        setContrato('NO HAY')
        // doc.data() will be undefined in this case
        console.log("No such document!");

      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });


  }

  const onData = async () => {
    setContrato(props)
    loadData(props.numContrato)
    setOpen(true)
  }


  return (
    <>
      <Tooltip title="Ver Contrato">
        <Button onClick={() => onData()}>
          <MenuBookIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle><strong>CONTRATO PERTENECIENTE A: {contrato.nombres ? contrato.nombres : '--'}</strong></DialogTitle>
        <DialogContent>

          {contrato !== '' ? <>
            {/* <Embed url={props.url_instalacion} /> */}
            <Embed url={contrato.url_contrato} />
            {/* <Embed url={contrato.url_anexo5} />
            <Embed url={contrato.url_fiscalizacion} /> */}
          </> :
            <>
              <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress align="center" />
                <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
              </Stack>
            </>}

          {contrato === 'NO HAY' &&
            <>
              <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                <p align="center" style={{ fontSize: '14px' }}>NO HAY CONTRATO CARGADO EN SOEI</p>
              </Stack>
            </>}



        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained"><strong>oK</strong></Button>
        </DialogActions>
      </Dialog>
    </>
  );
};