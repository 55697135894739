import { db, storage, firebase } from '../../firebase'
import Swal from 'sweetalert2'

export const detallesSolicitud = (solicitudId) => async (dispatch) => {
    try {
        dispatch({ type: 'SOLICITUD_DETAILS_REQUEST' })
        const solicitudDb = await db.collection('solicitudes')
            .doc(solicitudId)
            .onSnapshot((querySnapshot) => {
                const data = querySnapshot.data(item => { return { ...item.data(), id: item.id } })
                console.log(data, 'data')
                dispatch({
                    type: 'SOLICITUD_DETAILS_SUCCESS',
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: 'SOLICITUD_DETAILS_FAIL',
            payload: error
        })
    }
}

export const actualizarSolicitud = (solicitudId, data, navigate) => async (dispatch) => {
    try {

        dispatch({ type: 'SOLICITUD_UPDATE_REQUEST' })
        console.log(solicitudId, data, navigate)
        const solicitudDb = await db.collection('solicitudes').doc(solicitudId).update(data).then(() => {
            navigate(`/ventas/cargar-documento/${solicitudId}`)
            
            Swal.fire({ icon: 'success', text: 'Nueva venta' })
        }).catch((error) => {
            console.log('Hola', error)
            Swal.fire({ icon: 'error', text: error, })
        });
        console.log('Si paso', data)
        dispatch({
            type: 'SOLICITUD_UPDATE_SUCCESS',
            payload: solicitudDb
        })
    } catch (error) {
        dispatch({
            type: 'SOLICITUD_UPDATE_FAIL',
            payload: error
        })
    }
}
export const uploadFile = (ventaId, nameFile, uploadFile) => async (dispatch) => {
    try {
        dispatch({ type: 'CARGA_DOCUMENTO_REQUEST' })
        const pictureRef = await storage.ref().child('documentos_cliente').child(ventaId.nombres).child(nameFile)
        const letreroUpload = pictureRef.put(uploadFile)
        letreroUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                Swal.fire({ icon: 'error', text: error, })
            },
            () => {
                letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)

                    if (nameFile === 'cedula_front') {
                         db.collection('solicitudes').doc(ventaId.uid).update({
                            foto_cedula_frontal: downloadURL
                        })
                    } else if (nameFile === 'cedula_back') {
                         db.collection('solicitudes').doc(ventaId.uid).update({
                            foto_cedula_trasera: downloadURL
                        })
                    } else if (nameFile === 'planilla') {
                         db.collection('solicitudes').doc(ventaId.uid).update({
                            foto_cliente_planilla: downloadURL
                        })
                    } else if (nameFile === 'vivienda') {
                         db.collection('solicitudes').doc(ventaId.uid).update({
                            foto_cliente_vivienda: downloadURL
                        })
                    } else if (nameFile === 'cliente') {
                         db.collection('solicitudes').doc(ventaId.uid).update({
                            foto_cliente_letrero: downloadURL
                        })
                    }
                    dispatch({
                        type: 'CARGA_DOCUMENTO_SUCCESS',
                        payload: downloadURL
                    })
                    
                    // const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                    //     foto_cliente_letrero: downloadURL
                    // }).then(() => {
                    //     console.info("Finished uploading CEDULA FRONTAL");

                    // }).catch((error) => {
                    //     Swal.fire({ icon: 'error', text: error, })
                    // });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }
        )




       
    } catch (error) {
        dispatch({
            type: 'CARGA_DOCUMENTO_FAIL',
            payload: error
        })
    }
}