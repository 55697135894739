import {
    Card,
    CardContent,
    TextField,
    FormControl,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Container,
    Stack,
    Autocomplete
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from "react";
import { db } from '../../../firebase';
import Swal from "sweetalert2";

export const Producto = () => {

    const [ccInventario, setCcInventario] = useState('')

    const handleCcInventario = (e) => {
        setCcInventario(e)
    }

    const [data, setData] = useState();


    const LoadData = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 1);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcInventario(data[0].cc_inventario_sin_iva)
        });
    };

    const LoadData2 = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 2);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcVentas(data[0].cc_ventas_sin_iva)
        });
    };

    const LoadData3 = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 3);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcCosto(data[0].cc_costos_sin_iva)
        });
    };

    const LoadData4 = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 4);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcInventarioConIva(data[0].cc_inventario_con_iva)
        });
    };

    const LoadData5 = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 5);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcVentasConIva(data[0].cc_ventas_con_iva)
        });
    };

    const LoadData6 = (persistMessages = []) => {
        let ref = db.collection("cuentas_contables").where('value', '==', 6);
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
            setCcCostoConIva(data[0].cc_costos_con_iva)
        });
    };

    const [productos, setProductos] = useState('')

    const [item, setItem] = useState('')

    const getPlanDeCuentas = () => {
        let docRef = db.collection("plan_cuentas").doc("CTA-5").collection("items")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
            /* setCcInventario(arrayMessage)
            setCcVentas(arrayMessage)
            setCcCosto(arrayMessage)
            setCcInventarioConIva(arrayMessage)
            setCcVentasConIva(arrayMessage)
            setCcCostoConIva(arrayMessage) */
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    useEffect(() => {
        getPlanDeCuentas()
        LoadData()
        LoadData2()
        LoadData3()
        LoadData4()
        LoadData5()
        LoadData6()
        console.log(productos, 'plan cuentas')
    }, []);

    const [ccVentas, setCcVentas] = useState('')

    const handleCcVentas = (e) => {
        setCcVentas(e)
    }

    const [ccCosto, setCcCosto] = useState('')

    const handleCcCosto = (e) => {
        setCcCosto(e)
    }

    const [ccInventarioConIva, setCcInventarioConIva] = useState('')


    const [ccVentasConIva, setCcVentasConIva] = useState('')

  

    const [ccCostoConIva, setCcCostoConIva] = useState('')

    

    const [openDialogCuentasSinIva, setOpenDialogCuentasSinIva] = useState(false);

    const [openDialogCcInventario, setOpenDialogCcInventario] = useState(false);
    const [openDialogCcVentas, setOpenDialogCcVentas] = useState(false);
    const [openDialogCcCosto, setOpenDialogCcCostos] = useState(false);

    const [openDialogCcInventarioConIva, setOpenDialogCcInventarioConIva] = useState(false);
    const [openDialogCcVentasConIva, setOpenDialogCcVentasConIva] = useState(false);
    const [openDialogCcCostoConIva, setOpenDialogCcCostosConIva] = useState(false);

   

    const createCuentaInventarioSinIva = async () => {
        await db.collection('cuentas_contables').doc('cc_inventario_sin_iva').set({
            cc_inventario_sin_iva: ccInventario,
            value: 1
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcInventario(false)
    }

    const createCuentaVentasSinIva = async () => {
        await db.collection('cuentas_contables').doc('cc_ventas_sin_iva').set({
            cc_ventas_sin_iva: ccVentas,
            value: 2
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcVentas(false)
    }

    const createCuentaCostosSinIva = async () => {
        await db.collection('cuentas_contables').doc('cc_costos_sin_iva').set({
            cc_costos_sin_iva: ccCosto,
            value: 3
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcCostos(false)
    }

    const createCuentaInventarioConIva = async () => {
        await db.collection('cuentas_contables').doc('cc_inventario_con_iva').set({
            cc_inventario_con_iva: ccInventarioConIva,
            value: 4
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcInventarioConIva(false)
    }

    const createCuentaVentasConIva = async () => {
        await db.collection('cuentas_contables').doc('cc_ventas_con_iva').set({
            cc_ventas_con_iva: ccVentasConIva,
            value: 5
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcVentasConIva(false)
    }

    const createCuentaCostosConIva = async () => {
        await db.collection('cuentas_contables').doc('cc_costos_con_iva').set({
            cc_costos_con_iva: ccCostoConIva,
            value: 6
        }).then(() => {
            Swal.fire({ icon: "success", title: "Actualizado con exito" });
        }).catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
        setOpenDialogCcCostosConIva(false)
    }

    return (
        <>
            <br />
            <Card>
                <CardContent>
                    <p style={{ fontSize: "16px" }}><strong>Cuentas Contables Productos sin IVA</strong></p>
                    <p style={{ fontSize: "12px" }}>c.c Inventario</p>
                    {/* c.c Inventario SIN IVA */}
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                fullWidth
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={ccInventario}
                                /* onChange={(e) => handleCcInventario(e.target.value)} */
                                name="ccInventario"
                                size="small"
                            />
                            <Button onClick={() => setOpenDialogCcInventario(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                <strong>Modificar</strong>
                            </Button>
                        </Stack>
                    </FormControl>
                    {/* c.c Ventas SIN IVA */}
                    <Box py={2}>
                        <p style={{ fontSize: "12px" }}>c.c Ventas</p>
                        <FormControl fullWidth>
                            <Stack direction="row" spacing={2}>

                                <TextField
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={ccVentas}
                                    /* onChange={(e) => handleCcVentas(e.target.value)} */
                                    name="ccVentas"
                                    size="small"
                                />
                                <Button onClick={() => setOpenDialogCcVentas(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                    <strong>Modificar</strong>
                                </Button>
                            </Stack>
                        </FormControl>
                    </Box>
                    <p style={{ fontSize: "12px" }}>c.c Costo</p>
                    {/* c.c Costo SIN IVA */}
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={ccCosto}
                                /* onChange={(e) => handleCcCosto(e.target.value)} */
                                name="ccCosto"
                                size="small"
                            />
                            <Button onClick={() => setOpenDialogCcCostos(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                <strong>Modificar</strong>
                            </Button>
                        </Stack>
                    </FormControl>
                    <br />
                    <br />
                    {/* Cuentas Contables Productos con IVA */}
                    <p style={{ fontSize: "16px" }}><strong>Cuentas Contables Productos con IVA</strong></p>
                    <p style={{ fontSize: "12px" }}>c.c Inventario</p>
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={ccInventarioConIva}
                                /* onChange={(e) => handleCcInventarioConIva(e.target.value)} */
                                name="ccInventario"
                                size="small"

                            />
                            <Button onClick={() => setOpenDialogCcInventarioConIva(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                <strong>Modificar</strong>
                            </Button>
                        </Stack>
                    </FormControl>
                    <Box py={2}>
                        <p style={{ fontSize: "12px" }}>c.c Ventas</p>
                        <FormControl fullWidth>
                            <Stack direction="row" spacing={2}>
                                <TextField
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={ccVentasConIva}
                                    /* onChange={(e) => handleCcVentasConIva(e.target.value)} */
                                    name="ccVentas"
                                    size="small"

                                />
                                <Button onClick={() => setOpenDialogCcVentasConIva(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                    <strong>Modificar</strong>
                                </Button>
                            </Stack>
                        </FormControl>
                    </Box>
                    <p style={{ fontSize: "12px" }}>c.c Costo</p>
                    <FormControl fullWidth>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={ccCostoConIva}
                                /* onChange={(e) => handleCcCostoConIva(e.target.value)} */
                                name="ccCosto"
                                size="small"

                            />
                            <Button onClick={() => setOpenDialogCcCostosConIva(true)} style={{ paddingLeft: "14px", paddingRight: "14px", fontSize: "12px" }} size="small" variant="contained" endIcon={<SearchIcon />}>
                                <strong>Modificar</strong>
                            </Button>
                        </Stack>
                    </FormControl>
                </CardContent>
            </Card>

            {/* DIALOGS SIN IVA */}
            <Dialog open={openDialogCuentasSinIva} onClose={() => setOpenDialogCuentasSinIva(false)} maxWidth="lg">
                <DialogTitle><strong>Modificar Cuenta Contable Productos sin IVA</strong></DialogTitle>
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <p style={{ fontSize: "12px" }}>c.c Inventario</p>
                                <FormControl fullWidth>
                                    <TextField
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        value={ccInventario}
                                        onChange={(e) => handleCcInventario(e.target.value)}
                                        name="ccInventario"
                                        size="small"
                                        fullWidth
                                    />
                                </FormControl>
                                <Box py={2}>
                                    <p style={{ fontSize: "12px" }}>c.c Ventas</p>

                                    <FormControl fullWidth>
                                        <TextField
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={ccVentas}
                                            onChange={(e) => handleCcVentas(e.target.value)}
                                            name="ccVentas"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                </Box>
                                <p style={{ fontSize: "12px" }}>c.c Costo</p>
                                <FormControl fullWidth>
                                    <TextField
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        value={ccCosto}
                                        onChange={(e) => handleCcCosto(e.target.value)}
                                        name="ccCosto"
                                        size="small"
                                        fullWidth
                                    />
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentasSinIva(false)}><strong>Cancelar</strong></Button>
                    <Button variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOGS CON IVA c.c Inventario*/}
            <Dialog open={openDialogCcInventario} onClose={() => setOpenDialogCcInventario(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Inventario sin iva</strong></DialogTitle>
                <Container maxWidth="xl">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Inventario</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                /* setItem(newInputValue) */
                                                setCcInventario(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcInventario(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaInventarioSinIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOGS CON IVA c.c Ventas*/}
            <Dialog open={openDialogCcVentas} onClose={() => setOpenDialogCcVentas(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Ventas sin iva</strong></DialogTitle>
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Ventas</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setCcVentas(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcVentas(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaVentasSinIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOGS CON IVA c.c Costos*/}
            <Dialog open={openDialogCcCosto} onClose={() => setOpenDialogCcCostos(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Costo sin iva</strong></DialogTitle>
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Costo</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setCcCosto(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcCostos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaCostosSinIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOGS SIN IVA c.c Inventario*/}
            <Dialog open={openDialogCcInventarioConIva} onClose={() => setOpenDialogCcInventarioConIva(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Inventario con iva</strong></DialogTitle>
                <Container maxWidth="xl">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Inventario</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setCcInventarioConIva(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcInventarioConIva(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaInventarioConIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>


            {/* DIALOGS SIN IVA c.c Ventas*/}
            <Dialog open={openDialogCcVentasConIva} onClose={() => setOpenDialogCcVentasConIva(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Ventas con iva</strong></DialogTitle>
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Ventas</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setCcVentasConIva(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcVentasConIva(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaVentasConIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOGS CON IVA c.c Costos*/}
            <Dialog open={openDialogCcCostoConIva} onClose={() => setOpenDialogCcCostosConIva(false)} maxWidth="xl">
                <DialogTitle><strong>c.c Costo con iva</strong></DialogTitle>
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Box pb={36}>
                                        <p style={{ fontSize: "12px" }}>c.c Costo</p>
                                        <Autocomplete
                                            size='small'
                                            disablePortal
                                            id="combo-box-demo"
                                            options={productos}
                                            getOptionLabel={(option) => `${option.cuenta} - ${option.nombre}`}
                                            sx={{ width: 300 }}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(item)
                                                setCcCostoConIva(newInputValue)
                                            }}
                                            renderInput={(params) => <TextField {...params} label="BUSCAR CODIGO" />}
                                        />
                                    </Box>
                                </FormControl>
                            </DialogContent>
                        </CardContent>
                    </Card>
                </Container>
                <DialogActions>
                    <Button onClick={() => setOpenDialogCcCostosConIva(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => createCuentaCostosConIva()} size="small" variant="contained">
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}