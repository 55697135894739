import { Tab, Tabs, Box, Grid, RadioGroup, Button, FormLabel, Divider, Select, AlertTitle, Alert } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InputValidation from '../../components/utils/InputValidation';
import { db, firebase } from '../../firebase';
import moment from 'moment'
import Swal from 'sweetalert2'
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export const PostesPage = () => {
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
    }, [])

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Postes" {...tabs(0)} />
                <Tab label="Nuevo poste" {...tabs(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
            </TabPanel>
            <TabPanel value={value} index={1}>
            </TabPanel>
        </>
    )
}