import {
    Box, CardHeader, Card, CardContent
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from 'moment'
import { db } from '../../../firebase'
import { useSelector, useDispatch } from 'react-redux'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
export const ServiciosInternet = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { client_details } = clientDetails
    const [data, setData] = useState([])
    //var current = new Date()
    useEffect(() => {
        LoadData()
    }, [])
    const LoadData = () => {
        db.collection(`servicios`).where('idcliente','==', client_details.idcliente).orderBy("created", "desc")
            .onSnapshot((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                ]
                setData(data)
            })
    }
    function CustomFooterTotalComponent(props) {
        return ''
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 25 },
        { field: 'perfil', headerName: 'PLAN', width: 300 },
        {
            field: 'costo', headerName: 'COSTO', width: 100,
            valueGetter: ({ value }) => value && `$${value.toFixed(2)}`,
        },
        { field: 'ipv4', headerName: 'IP', width: 125 },
      
        { field: 'nodocrr', headerName: 'NODO CCR', width: 100 },
        { field: 'pppuser', headerName: 'PPPUSER', width: 100 },
        { field: 'pppass', headerName: 'PPPPASS', width: 100 },
        { field: 'addres_list', headerName: 'ADDRES LIST', width: 200 }
    ]
    const csvOptions = {
        delimiter: ';',
        fileName: `SOLICITUD APROBADAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <Card variant='outlined'>
            <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='SERVICIOS DE INTERNET' />
            <Box p={1}>
                <Box sx={{ height: 300, width: '100%' }}>
                    <DataGrid
                        density="compact"
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent
                        }}
                        onStateChange={(state) => {
                        }}
                    />
                </Box>
            </Box>
        </Card>
    )
}