import React, { useState, useEffect } from 'react';
import {
    Grid as Grid2,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
    Button,
    Container,
    Grid,
} from '@mui/material'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Formato1 } from '../reporte_de_movimientos/PlantillasPDF/Headers';
import { FirmaReporteStock } from '../reporte_de_movimientos/PlantillasPDF/Firmas';
import moment from 'moment';
import { useSelector } from 'react-redux';


export const ReporteStockPDF = (props) => {
    const container = React.useRef(null);
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const [total, setTotal] = useState(0)

    const pdfExportComponent = React.useRef(null);

    useEffect(() => {
        cargarTotal()
      }, [props.contenido])

    const cargarTotal = () => {
        if (props.contenido && props.contenido.length > 0) {
            var total = 0
            props.contenido.map((item) => {
                total = total + parseFloat(item.stock_actual)
            })
            setTotal((total).toFixed(2))
        }
    }

    const celdaTotal = props => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <strong>Total:</strong>
            </td>
        );
    };

    const unidadesTotales = props => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {total}
            </td>
        );

    };

    const headerColumn = (props) => {
        return (
            <a>
                <span ><strong>{props.title}</strong></span>
                {props.children}
            </a>
        );
    };

    const tabla = (
        <div>
            <br />
            <Grid2 data={props.contenido} >
                <Column field="codigo_producto" title="Codigo" headerCell={headerColumn} footerCell={celdaTotal} />
                <Column field="bodega" title="Bodega" headerCell={headerColumn} />
                <Column field="descripcion" title="Descripcion" headerCell={headerColumn} />
                <Column field="categoria" title="Subtipo" headerCell={headerColumn} />
                <Column field="unidad_medida" title="Unidad Medida" headerCell={headerColumn} />
                <Column field="precio_compra" title="Precio Compra" headerCell={headerColumn} />
                <Column field="costo_promedio" title="Costo Promedio" headerCell={headerColumn} />
                <Column field="total_valorizado" title="Total Valorizado" headerCell={headerColumn} />
                <Column field="stock_actual" title="Stock Actual" headerCell={headerColumn} footerCell={unidadesTotales} />
            </Grid2>
        </div>

    );

    const plantilla = (props) => {
        const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
        return (
            <div>
                {props.pageNum == 1 &&
                    <Formato1 usuario={userInfo.displayName} />
                }
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                    Pag {props.pageNum} de {props.totalPages}
                </p>
            </div >
        )
    };


    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            pageTemplate: plantilla,
            paperSize: "A4",
            landscape: true,
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `REPORTE STOCK`,
        });
    };



    return (
        <Container>
            <div className="example-config">
                <Button
                    onClick={exportPDFWithMethod}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </div>
            <div className="border rounded p-2">
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth='xl'>

                            <Grid container spacing={1}>
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>INTERCOMMERCE S A</strong>
                                </Grid>
                                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                    <strong>REPORTE STOCK</strong>
                                </Grid>
                                <Grid item xs={4} align="center" />
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>0992988061001</strong>
                                </Grid>
                                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                    <strong>{moment((new Date()).toString()).format("DD/MM/YYYY")}</strong>
                                </Grid>
                                <Grid item xs={4} align="center" />
                                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                    <strong>AV. NARCISA DE JESUS 6421 Y PUENTE DE LA JOYA</strong>
                                </Grid>
                            </Grid>
                        </Container>
                        {tabla}
                        <FirmaReporteStock />
                    </div>
                </PDFExport>
            </div>
        </Container>
    );
}
