import React, { useState, useEffect } from 'react';
import {
  Grid as Grid2,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  Button,
  Grid,
  Container,
} from "@mui/material";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Formato1 } from './Headers';
import moment from 'moment'
import { useSelector } from 'react-redux';


export const ReporteMovimientoStock = (props) => {
  const container = React.useRef(null);
  const [items, setItems] = useState([]);
  const [fecha1, setFecha1] = useState();
  const [fecha2, setFecha2] = useState();
  const [totalIngresos, setTotalIngresos] = useState(0);
  const [totalEgresos, setTotalEgresos] = useState(0);
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  useEffect(() => {
    setItems(props.contenido)
    console.log(props.contenido.length)
  }, [])

  useEffect(() => {
    let ingresos = 0;
    let egresos = 0;
    let valor = 0;
    for (const element of props.contenido) {
      valor = element.cantidad_solicitada
      if (element.tipo_movimiento == "ingreso") {
        ingresos = ingresos + valor
      } else if (element.tipo_movimiento == "egreso") {
        egresos = egresos + valor
      }
    }
    setTotalEgresos(egresos);
    setTotalIngresos(ingresos);
  }, [items])


  useEffect(() => {
    setFecha1(moment(props.inicio.toString()).format('DD/MM/YYYY'));
    setFecha2(moment(props.fin.toString()).format('DD/MM/YYYY'));
  }, [props.inicio, props.fin])

  const plantilla = (props) => {
    const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
    return (
      <div>
        {props.pageNum == 1 &&
          <Formato1 usuario={userInfo.displayName} />
        }
        <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
          Pag {props.pageNum} de {props.totalPages}
        </p>
      </div >
    )
  };


  const unidadesTotales = props => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {(totalIngresos).toFixed(2)}
        <hr />
        {(totalEgresos).toFixed(2)}
      </td>
    );

  };
  const unidadesTotales2 = props => {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Total Ingreso:
        <hr />
        Total Egreso:
      </td>
    );

  };

  const headerColumn = (props) => {
    return (
      <a >
        <span><strong>{props.title}</strong></span>
        {props.children}
      </a>
    );
  };

  const customCell = (props) => {
    return (
      <td >
        <span >
          {(props.dataItem[props.field] === undefined || props.dataItem[props.field] === '') ? "No Asignado" : props.dataItem[props.field]}
        </span>
      </td>
    );
  };

  // const customCell22 = (props) => {
  //   return (
  //     <>
  //       {(props.dataItem[props.field].length === 0) ? <td ><span >No Asignado</span> </td> : <>

  //         <td ><span >{props.dataItem[props.field].toString()}</span> </td>

  //         {/* {props.dataItem[props.field].map((item) => {
  //             <>
  //               <td >{item}</td>
  //             </>
  //           })} */}
  //       </>}
  //     </>

  //   );
  // };

  const tabla = (
    <div>
      <br />
      <Grid2 data={items} >
        <Column field="id2" title="Id" headerCell={headerColumn} cell={customCell} />
        <Column field="fecha" title="Fecha" headerCell={headerColumn} cell={customCell} />
        <Column field="fullName" title="Codigo" headerCell={headerColumn} cell={customCell} />
        <Column field="descripcion" title="Producto" headerCell={headerColumn} cell={customCell} />
        <Column field="series" title="Series" headerCell={headerColumn} cell={customCell} />
        <Column field="estado_producto" title="Estado Producto" headerCell={headerColumn} cell={customCell} />
        <Column field="motivo" title="Subtipo" headerCell={headerColumn} cell={customCell} />
        <Column field="flota" title="Cuadrilla" headerCell={headerColumn} cell={customCell} />
        <Column field="cantidad_solicitada" title="Cant." headerCell={headerColumn} cell={customCell} />
        <Column field="user" title="Usuario" footerCell={unidadesTotales2} headerCell={headerColumn} cell={customCell} />
        <Column field="comentario" title="Comentario" footerCell={unidadesTotales} headerCell={headerColumn} cell={customCell} />
      </Grid2>
    </div>
  )


  const exportPDFWithMethod = () => {
    let element = container.current;
    savePDF(element, {
      pageTemplate: plantilla,
      landscape: true,
      paperSize: "A4",
      repeatHeaders: 'true',
      margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
      scale: 0.45,
      fileName: `Reporte Movimiento Stock ${fecha1} - ${fecha2}`,
    });
  };


  return (
    <Container>
      <div className="example-config">
        <Button
          onClick={exportPDFWithMethod}
          style={{ marginLeft: "10px", marginBottom: "10px" }}
          color="error" size='small' variant="outlined"
          startIcon={<PictureAsPdfIcon />}>
          <strong>PDF</strong>
        </Button>
      </div>
      <div className="border rounded p-2">
        <PDFExport>
          <div ref={container}>
            <Container maxWidth='xl'>
              <Grid container spacing={1}>
                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                  <strong>INTERCOMMERCE S A</strong>
                </Grid>
                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                  <strong>REPORTE MOVIMIENTO STOCK</strong>
                </Grid>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                  <strong>0992988061001</strong>
                </Grid>
                <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                  <strong>{fecha1} - {fecha2}</strong>
                </Grid>
                <Grid item xs={4} align="center" />
                <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                  <strong>AV. NARCISA DE JESUS 6421 Y PUENTE DE LA JOYA</strong>
                </Grid>
              </Grid>
            </Container>
            {tabla}
          </div>
        </PDFExport>
      </div>
    </Container>
  );
}
