import {
  Grid,
  Box,
  Button,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Card,
  CardContent,
  TableContainer,
  CardActions,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material"; import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';



export const Sucursales = () => {

  const data = [{}]

  return (
    <>
      <Box px={2}>
        <Card>
          <Box paddingLeft={2}>
            <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>ESTABLECIMIENTOS</strong></p>
          </Box>
          <CardContent>
            <Button
              /*onClick={() => setOpen(true)}*/
              color="success" size='small' variant="contained"
              startIcon={<AddIcon />}>
              <strong>NUEVO</strong>
            </Button>
            <TableContainer component={Paper}>
              <br />
              <Table size="small" aria-label="purchases" sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{''}</TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>EMPRESA</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>AGENCIA</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>COD. AGENCIA</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>CIUDAD</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>DESCRIPCION</strong></TableCell>
                    <TableCell style={{ fontSize: '12px' }}><strong>ACCION</strong></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data && data.map((row) => (
                    <TableRow>
                      <TableCell>{''}</TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong>{row.EMPRESA}</strong></TableCell>
                      <TableCell style={{ fontSize: '12px' }}><strong><Tooltip title="MODIFICAR">
                        <Button /*onClick={() => setOpenEditPeriodoContable(true)}*/>
                          <EditIcon />
                        </Button>
                      </Tooltip></strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table >
            </TableContainer >
          </CardContent >
        </Card >
      </Box >
    </>
  )
}
