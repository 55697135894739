import {
    Box, Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase.js";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
export const TransaccionesTab = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { client_details } = clientDetails
    const [data, setData] = useState()
    useEffect(() => {
        LoadData()
    }, [])
    const LoadData = () => {
        let ref = db.collection('transacciones').where('idcliente', '==', client_details.idcliente).orderBy('created', 'desc')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ]
            setData(data)
        })
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'numero_transaccion', headerName: 'N RUBRO', width: 100 },
        { field: 'numero_tributario', headerName: 'N TRIBUTARIO', width: 125 },
        {
            field: 'fecha_pago', headerName: 'FECHA PAGO', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'valor_rubro', headerName: 'VALOR RUBRO', width: 125,
            renderCell: (params) => {
                return params.row.valor_rubro === undefined || params.row.valor_rubro === null ? '' : `$${params.row.valor_rubro}`
            }
        },
        {
            field: 'total_pagado', headerName: 'PAGADO', width: 100,
            renderCell: (params) => {
                return params.row.total_pagado === undefined || params.row.total_pagado === null ? '' : `$${params.row.total_pagado}`
            }
        },
        {
            field: 'comision', headerName: 'COMISION', width: 100,
            renderCell: (params) => {
                return params.row.comision === undefined || params.row.comision === null ? '' : `$${params.row.comision}`
            }
        },
        {
            field: 'monto', headerName: 'SALDO', width: 100,
            renderCell: (params) => {
                return params.row.monto === undefined || params.row.monto === null ? '' : `$${params.row.monto}`
            }
        },
     
        { field: 'forma_pago', headerName: 'FORMA PAGO', width: 125 },
        { field: 'operador', headerName: 'OPERADOR', width: 200 }
    ]
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
                density="compact"
                rows={data ? data : []}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                hideFooter
                columns={columns}

                components={{
                    Toolbar: CustomToolbar
                }}
            />
        </Box>
    )
}