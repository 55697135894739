import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { db, firebase } from "../../../firebase";

import Swal from 'sweetalert2'

import {
    listFlotas
} from "../../../redux/actions/flotas/flotasActions";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export const ViewTicketEnEspera = ({ props }) => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listFlotas())
        /* leerCodigo() */
    }, [dispatch]);

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [numTicket, setNumTicket] = useState(props.uid_ticket)

    const [linea, setLinea] = useState(props.linea_contrato)

    const [cedula, setCedula] = useState(props.cedula)

    const [celular, setCelular] = useState(props.celular)

    const [telefono, setTelefono] = useState(props.telefono)

    const [ticketsDepartamento, setTicketsDepartamento] = useState(props.departamento);

    const [ticketsOficina, setTicketsOficinas] = useState(props.agendado_desde);

    const [ticketsAsunto, setTicketsAsunto] = useState(props.asunto_del_ticket);

    const [ticketsTurno, setTicketsTurnos] = useState(props.turno);

    const [nombreSolicitante, setNombreSolicitante] = useState(props.nombre_cliente)

    const [detallesTicket, setDetallesTicket] = useState(props.detalles_del_ticket)

    /* const fechaFirestoreVisita = props.fecha_visita;
    const fechaDateVisita = fechaFirestoreVisita.toDate();

    const [fechaVisita, setFechaVisita] = useState(fechaDateVisita) */

    const [openModal, setOpenModal] = useState(false)

    const [viewImagen, setViewImagen] = useState('')

    const [modalConfirmAprobacion, setModalConfirmAprobacion] = useState(false)

    const [modalConfirmRechazo, setModalConfirmRechazo] = useState(false)

    const [modalConfirmFlota, setModalConfirmFlota] = useState(false)

    const openModalConfirmar = () => {
        setModalConfirmAprobacion(true)
        setOpenModal(false)
    }

    const closeModalConfirmar = () => {
        setOpenModal(true)
        setModalConfirmAprobacion(false)
    }

    const openModalrechazo = () => {
        setModalConfirmRechazo(true)
        setOpenModal(false)
    }

    const closeModalrechazo = () => {
        setOpenModal(true)
        setModalConfirmRechazo(false)
    }

    const openConfirmaFlota = () => {
        setModalConfirmFlota(true)
        setModalConfirmAprobacion(false)
    }

    const closeConfirmaFlota = () => {
        setModalConfirmAprobacion(true)
        setModalConfirmFlota(false)
    }

    const confirmAprobacion = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 2,
            usuario_que_aprobo: userInfo.displayName,
            fecha_hora_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
            flota: flota,
            lider: lider,
            auxiliar: auxiliar
        })
        Swal.fire({ icon: "success", text: 'Se ha aprobado y enviado a la flota el ticket con exito' });
        /* setModalConfirmFlota(false) */
        setModalConfirmAprobacion(false)
    }

    const confirmRechazo = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 1,
            usuario_que_rechazo: userInfo.displayName,
            fecha_hora_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        Swal.fire({ icon: "success", text: 'Se ha rechazado el ticket con exito' });
        modalConfirmAprobacion(false)
    }

    const updateNumeros = () => {
        db.collection('tickets').doc(props.id).update({
            celular: celular,
            telefono: telefono,
        })
        Swal.fire({ icon: "success", text: 'Se ha actualizado la informacion correctamente' });
        setOpenModal(false)
    }

    const flotasList = useSelector(state => state.flotasList)
    const { flotas, loading } = flotasList
    const [flota, setFlota] = useState('')

    const [numFlota, setNumFlota] = useState('')
    const [lider, setLider] = useState('')
    const [auxiliar, setAuxiliar] = useState('')

    const handleFlota = (e) => {
        setFlota(e)
        const p = flotas.filter((item) => item.numeroFlota === e);
        const data = p[0]
        setNumFlota(data.numeroFlota)
        setLider(data.nombreLider)
        setAuxiliar(data.nombreAuxiliar)
    }

    const fechaFirestoreVisita = props.fecha_sugerida_visita;
    const fechaDateVisita = fechaFirestoreVisita.toDate();

    const [fechaVisita, setFechaVisita] = useState(fechaDateVisita)


    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <RemoveRedEyeIcon />
                </Button>
            </Tooltip>

            {/* DIALOG VIEW TICKET ESPERA */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                <DialogTitle><strong>{props.uid_ticket}</strong></DialogTitle>
                <DialogContent>

                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CEDULA:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cedula}

                                            name="cedula"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>LINEA:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={linea}

                                            name="linea"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>NOMBRE SOLICITANTE:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombreSolicitante}
                                            name="nombreSolicitante"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CELULAR:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={celular}
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            }}
                                            name="celular"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => setCelular(e.target.value)}
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>TELEFONO:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={telefono}
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                                name="telefono"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => setTelefono(e.target.value)}
                                            />
                                        </FormControl>
                                    </Box>

                                    {/* <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>AGENDADO DESDE:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsOficina}
                                            name="ticketsOficina"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl> */}

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>DEPARTAMENTO:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsDepartamento}
                                                name="ticketsDepartamento"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>ASUNTO DEL TICKET:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsAsunto}
                                            name="ticketsAsunto"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FECHA SUGERIDA DE VISITA</strong></p>
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size='small' fullWidth />}
                                                inputFormat="dd/MM/yyyy"
                                                value={fechaVisita}
                                            />
                                        </LocalizationProvider>
                                    </Box>

                                    {/* <Box py={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FECHA VISITA</strong></p>
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size='small' fullWidth />}
                                                inputFormat="dd/MM/yyyy"
                                                value={fechaVisita}
                                            />
                                        </LocalizationProvider>
                                    </Box> */}

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>TURNO:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsTurno}
                                            name="ticketsTurno"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>AGENDADO DESDE:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsOficina}
                                                name="ticketsOficina"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO VIVIENDA CLIENTE:</strong></p>
                                    </Box>

                                    <Box py={2}>
                                        <img width={400} src={props.imagen} />
                                    </Box>

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pt={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO OPCIONAL:</strong></p>
                                    </Box>

                                    <Box py={2}>
                                        <img width={400} src={props.imagen_opcional} />
                                    </Box>

                                </Grid>

                            </Grid>

                            <Box py={2}>
                                <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>INGRESE DETALLES DEL TICKET</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={detallesTicket}
                                    /* onChange={(e) => setDetallesTicket(e.target.value)} */
                                    name="detallesTicket"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={8}
                                />
                            </Box>
                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => updateNumeros()}>ACTUALIZAR</Button>
                    {/* <Button variant="contained" color="warning" size="small" onClick={() => { openModalrechazo() }}>RECHAZAR</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={() => { openModalConfirmar() }} >APROBAR</Button> */}
                </DialogActions>

            </Dialog>

            {/* DIALOG CONFIRM*/}
            <Dialog open={modalConfirmAprobacion} onClose={() => setModalConfirmAprobacion(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE APROBAR EL TICKET?</strong></DialogTitle>

                <DialogContent>

                    {/* <Box py={2}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FLOTA:</strong></p>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                value={flota}
                                onChange={(e) => handleFlota(e.target.value)}
                            >
                                <MenuItem value="">--</MenuItem>
                                {flotas && flotas.map((row, key) => (
                                    <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>

                    <FormControl fullWidth>
                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>LIDER:</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={lider}
                            name="lider"
                            size="small"
                            fullWidth
                        />
                    </FormControl>

                    <Box py={2}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>AUXILIAR:</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                value={auxiliar}
                                name="auxiliar"
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Box> */}


                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalConfirmar()}><strong>Regresar</strong></Button>
                    <Button disabled={flota === '' || lider === '' || auxiliar === ''} onClick={() => confirmAprobacion/* openConfirmaFlota */()} size="small" variant="contained" color="secondary">
                        Aceptar
                        {/* ENVIAR A LA FLOTA */}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG RECHAZO*/}
            <Dialog open={modalConfirmRechazo} onClose={() => setModalConfirmRechazo(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE RECHAZAR EL TICKET?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalrechazo()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmRechazo()} size="small" variant="contained" color="error">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG CONFIRM FLOTA*/}
            <Dialog open={modalConfirmFlota} onClose={() => setModalConfirmFlota(false)} maxWidth="xs">
                <DialogTitle>¿ESTA SEGURO DE ENVIAR EL TICKET A LA FLOTA <strong> {flota}?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeConfirmaFlota()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmAprobacion()} size="small" variant="contained" color="secondary">
                        {" "}
                        ENVIAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}