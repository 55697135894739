import { Box, Card, Tab, Typography, Container, AppBar } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import Swal from 'sweetalert2';
import { NuevoCambioDomicilio } from './NuevoCambioDomicilio';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  )
}
export const CambioDomicilioPage = () => {
  const params = useParams()
  const [tab, setTab] = React.useState(0);
  const [ticket, setTicket] = React.useState(null);
  const handleChange = (event, newValue) => {
    setTab(newValue)
  }
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id))
    } else {
      setTab(0)
    }
  }, [params.id])
  useEffect(() => {
    if (params.ticket) {
      let docRef = db.collection("CRM_cambio_domicilio").doc(params.ticket)
      docRef.get().then((doc) => {
        if (doc.exists) {
          if (doc.data().estadosolicitud === '2' || doc.data().estadosolicitud === '4') {
            Swal.fire({ icon: "warning", text: "El ticket ya fue resuelto" })
          }
          setTicket({
            ...doc.data(),
            id: doc.id
          })
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }
  }, [params.ticket])
  return (
    //disableGutters={window.innerWidth <= 767}
    <Box p={2}>
      <Card>
        <Box py={2} pl={5}>
          <Typography variant="h5" component="h1"><strong>CAMBIO DOMICILIO</strong></Typography>
        </Box>
        <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
          <AppBar elevation={0} color='default' position="static">
            <Tabs
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              value={tab}
              onChange={handleChange}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 }
                }
              }}
            >
              <Tab label="Nuevo Cambio Domicilio" />
            </Tabs>
          </AppBar>
        </Box>
        <TabPanel value={tab} index={0}>
          <NuevoCambioDomicilio ticket={ticket} />
        </TabPanel>
      </Card>
    </Box>
  )
}