import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    LinearProgress,
    Select,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    MenuItem,
    Tooltip
} from '@mui/material'
import * as XLSX from "xlsx/xlsx.mjs";
import UploadIcon from "@mui/icons-material/Upload";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import React, { useEffect, useState, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from '../../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'

import {
    listsBodegas,
    listsUbicaciones,
    listsMotivoEgresoProductos
} from "../../../redux/actions/parametersActions";
import { ConvertRoles } from '../../../components/config/ConvertRoles';
import {
    listFlotas
} from "../../../redux/actions/flotas/flotasActions";
import { CargaSeriesEgresoProductos } from '../EgresoProducto/carga_series/CargaSeriesEgresoProductos';
import { CargaSeriesIngresoProductosCompra } from './carga_series/CargaSeriesIngresoProductosCompra';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const VerMercaderia = ({ items }) => {

    const [fechaDocumento, setFechaDocumento] = useState('')

    const CargaSeriesIngresoProductos = ({
        props,
        bodega,
        codigo,
        numPedido,
    }) => {

        /* esxcel upload */

        const [openUploadExcelSeries, setOpenUploadExcelSeries] = useState(false)

        const [openSuccessUploadSeries, setOpenSuccessUploadSeries] = useState(false)

        /* const handleOpenDialogUploadExcel = () => {
            setOpenUploadExcelSeries(true)
            setOpenDialogSelectsOptionUploadSeries(false)
        }
    
        const handleCloseDialogUploadExcel = () => {
            setOpenDialogSelectsOptionUploadSeries(true)
            setOpenUploadExcelSeries(false)
        } */

        const [openDialogExcelSuccess, setOpenDialogExcelSuccess] = useState(false)

        /*  */

        const userAuth = useSelector((state) => state.userAuth);
        const { loadin, userInfo } = userAuth;

        const [open, setOpen] = useState(null);
        //
        const onData = () => {
            setOpen(true);
        };

        const [openIngresoManual, setOpenIngresoManual] = useState(null);

        const [valorSeriesAutomaticas, setValorSeriesAutomaticas] = useState([]);

        const onDataIngresoManual = () => {
            setOpenIngresoManual(true);
            setOpen(false);
        };

        const onCloseIngresoManual = () => {
            setOpen(true);
            setOpenIngresoManual(false);
        };

        const [openIngresoSeriesAutomaticas, setOpenIngresoSeriesAutomaticas] =
            useState(null);

        const onDataIngresoSeriesAutomaticas = () => {
            setOpenIngresoSeriesAutomaticas(true);
            setOpen(false);
        };

        const onCloseIngresoSeriesAutomaticas = () => {
            setOpen(true);
            setOpenIngresoSeriesAutomaticas(false);
        };

        const [openIngresoSeriesExcel, setOpenIngresoSeriesExcel] = useState(null);

        const onDataIngresoSeriesExcel = () => {
            setOpenIngresoSeriesExcel(true);
            setOpen(false);
        };

        const onCloseIngresoSeriesExcel = () => {
            setOpen(true);
            setOpenIngresoSeriesExcel(false);
        };

        const [dataExcel, setDataExcel] = useState([]);

        const [file, setFile] = useState(null);
        const [fileName, setFileName] = useState(null);

        const handleUploadFileLocal = async (e) => {
            e.stopPropagation(); // Evitar que se propague el evento
            const file = e.target.files[0];
            setFileName(file.name);
            setFile(file);
        };

        const fileRef = useRef();

        const handleRemove = () => {
            setFileName(null);
            setFile(null);
            fileRef.current.value = "";
        };

        const [openCaja, setOpenCaja] = React.useState(false);

        const [openIngresoErrorCargaSeries, setOpenIngresoErrorCargaSeries] =
            useState(null);

        const onDataIngresoErrorCargaSeries = () => {
            setOpenIngresoErrorCargaSeries(true);
        };

        const onCloseIngresoErrorCargaSeries = () => {
            setOpenIngresoErrorCargaSeries(false);
        };

        /* CARGA EXCEL PARA MOSTARR EN EL GRID */

        const handleUploadFile = async () => {
            console.log('presionaste este boton')
            try {
                if (file) {
                    const data = await file.arrayBuffer();
                    const workbook = XLSX.read(data);
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);
                    setDataExcel(jsonData)
                    setData(jsonData)
                    setOpenCaja(false)
                } else {
                    console.log("No se cargado ningun archivo");
                }
            } catch (error) {
                console.log("error", error);
            }
        };

        /* CARGA SERIES MANUALES */

        const cargarSeriesManuales = (data) => {
            //console.log(data, 'data')
            const onSeries = () => {
                console.log(data, "data");
                console.log(props.series, "data");
                /* const agendaDB2 = await db
                  .collection("reporte_stock")
                  .where("codigo_producto", "==", props.codigo)
                  .where("bodega", "==", bodega)
                  .get();
        
                const data2 = await agendaDB2.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                })); */
                /* props.series.forEach(async (obj) => {
                    const uidSolicitud = `ING-` + numPedido3;

                    console.log('uidSolicitud', uidSolicitud)
                    console.log(obj)
                    console.log(obj.serie)
                    console.log(props.codigo)
                    console.log(codigo)

                    db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                        })

                    db
                        .collection(`ingreso_productos/${uidSolicitud}/items`)
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                        })

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                        })
                    setOpenSuccessUploadSeries(true)
                    setOpenIngresoManual(false);
                }); */
                setOpenIngresoManual(false);
            };
            return (
                <>
                    {props.series.map((row, index) => (
                        <Box pt={1} pb={1}>
                            <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                                <strong>SERIE: {row.id}</strong>
                            </p>
                            <TextField
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { Wtop: 0 },
                                }}
                                size="small"
                                fullWidth
                                name={index}
                                //value={[i]}
                                onChange={(e) => handleSeries(index, e, data)}
                            />
                        </Box>
                    ))}
                    <Button onClick={() => onCloseIngresoManual()}>
                        <strong>Cancelar</strong>
                    </Button>
                    <Button
                        size="small"
                        color="success"
                        variant="contained"
                        onClick={() => onSeries()}
                    >
                        CREAR SERIES
                    </Button>
                </>
            );
        };

        var automaticSeries = [];

        const [loadingButtonSeriesAutomaticas, setLoadingButtonSeriesAutomaticas] = useState(false);

        /* CARGA SERIES AUTOMATICAS */

        const cargarSeriesManualesAutomaticas = () => {
            /* console.log(resultado) */
            var fieldsArray = [];

            for (var i = 1; i <= props.cantidad_pedida; i++) {
                const resultado = Math.floor(
                    Math.pow(10, 14 - 1) +
                    Math.random() * (Math.pow(10, 14) - Math.pow(10, 5 - 1) - 1),
                );

                automaticSeries.push({ serie: resultado })

                function createData(serie) {
                    return { serie };
                }

                var rows = [createData(resultado)];

                /* setAddSeriesAutomaticasArray(createData(resultado)) */

                fieldsArray.push(
                    <>
                        <Card>
                            <CardContent
                                style={{
                                    paddingTop: "0px",
                                    paddingBottom: "0px",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                }}
                            >
                                <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table size="small" stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>SERIE</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        {i}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.serie}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </>
                );
            }

            /* FUNCION PARA CARGAR LAS SERIES AUTOMATICAS */

            const uploadSeries = () => {
                setLoadingButtonSeriesAutomaticas(true);
                /* const uidSolicitud = `ING-` + numPedido;
                console.log(codigo)
                console.log(props.codigo) */
                automaticSeries.forEach(async (obj) => {
                    const uidSolicitud = `ING-` + numPedido3;
                    console.log(obj)
                    db
                        .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 0
                        })

                    db
                        .collection(`ingreso_productos/${uidSolicitud}/items`)
                        .doc(codigo).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 0
                        })

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie.toString())
                        .set({
                            serie:
                                obj.serie === "" ||
                                    obj.serie === null ||
                                    obj.serie === undefined
                                    ? ""
                                    : obj.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigo,
                            bodega: bodega,
                            estado: 0
                        })

                    Swal.fire({
                        icon: "success",
                        title: "Series cargadas con exito",
                    });

                    setOpenIngresoSeriesAutomaticas(false);

                })
            }
            return (
                <>
                    {fieldsArray}
                    <Button onClick={() => onCloseIngresoSeriesAutomaticas()}>
                        <strong>Cancelar</strong>
                    </Button>
                    {/* <Button onClick={() => uploadSeries()} size="small" color="success" variant="contained">
                        {" "}
                        CREAR SERIES
                    </Button> */}
                    <LoadingButton
                        color="success"
                        size="small"
                        onClick={() => uploadSeries()}
                        loading={loadingButtonSeriesAutomaticas}
                        endIcon={loadingButtonSeriesAutomaticas === true ? <SendIcon /> : ''}
                        variant="contained"
                        disabled={loadingButtonSeriesAutomaticas === true}
                        loadingPosition="end"
                    >CREAR SERIES</LoadingButton>
                </>
            );
        };

        /* FUNCION QUE SUBE LAS SERIES A FIREBASE */

        /* const SubirExcelFirebase = async () => {
            console.log(bodega);
            console.log(codigo);
            console.log(dataExcel.length);
            console.log(props.cantidad_pedida);

            const agendaDB2 = await db
                .collection("reporte_stock")
                .where("codigo_producto", "==", codigo)
                .where("bodega", "==", bodega)
                .get();

            const data2 = await agendaDB2.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            {
                parseFloat(data.length) != parseFloat(props.cantidad_pedida)
                    ? onDataIngresoErrorCargaSeries()
                    : data.forEach(async (obj) => {
                        const uidSolicitud = `ING-` + numPedido;

                        db
                            .collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                            .doc(codigo).collection('series').doc(obj.SERIE.toString())
                            .set({
                                serie:
                                    obj.SERIE === "" ||
                                        obj.SERIE === null ||
                                        obj.SERIE === undefined
                                        ? ""
                                        : obj.SERIE.toString(),
                                created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                usuario_que_cargo_serie: userInfo.displayName,
                            })

                        db
                            .collection(`ingreso_productos/${uidSolicitud}/items`)
                            .doc(codigo).collection('series').doc(obj.SERIE.toString())
                            .set({
                                serie:
                                    obj.SERIE === "" ||
                                        obj.SERIE === null ||
                                        obj.SERIE === undefined
                                        ? ""
                                        : obj.SERIE.toString(),
                                created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                usuario_que_cargo_serie: userInfo.displayName,
                            })

                        const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                        const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                        db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.SERIE.toString())
                            .set({
                                serie:
                                    obj.SERIE === "" ||
                                        obj.SERIE === null ||
                                        obj.SERIE === undefined
                                        ? ""
                                        : obj.SERIE.toString(),
                                created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                usuario_que_cargo_serie: userInfo.displayName,
                                utilizado_por_codigo: codigo,
                                bodega: bodega,
                            })

                        setOpenSuccessUploadSeries(true)
                        setOpenIngresoSeriesExcel(false);
                    });
            }
        }; */

        const SubirExcelFirebase = async () => {
            console.log(bodega);
            console.log(codigo);
            console.log(dataExcel.length);
            console.log(props.cantidad_pedida);

            {
                parseFloat(dataExcel.length) != parseFloat(props.cantidad_pedida)
                    ? onDataIngresoErrorCargaSeries()
                    : props.series.map((row, index) => {
                        dataExcel.forEach((obj, indexExcel) => {
                            handleSeriesExcel(indexExcel, obj.SERIE.toString(), props)
                        })
                        Swal.fire({
                            icon: "success",
                            title: "Series cargadas con exito",
                        });
                        setOpenIngresoSeriesExcel(false);
                    })
            }
        };

        const [data, setData] = useState([]);

        const [total, setTotal] = useState(0);

        function CustomFooterTotalComponent(props) {
            console.log(props)
            return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
        }

        const columns = [
            { field: 'SERIE', headerName: 'SERIE', width: 180 },
        ];

        const CustomToolbar = (props) => (
            <GridToolbarContainer {...props}>
                <GridToolbarFilterButton />
                {/* <GridToolbarQuickFilter /> */}

            </GridToolbarContainer>
        );


        return (
            <>
                {dataExcel === "" || dataExcel === undefined || dataExcel === null ? (
                    <Tooltip title="INGRESAR SERIES">
                        <Button onClick={() => onData()}>
                            <QrCodeScannerIcon />
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title="INGRESAR SERIES">
                        <Button onClick={() => onData()}>
                            <QrCodeScannerIcon color="success" />
                        </Button>
                    </Tooltip>
                )}

                {/* LISTA DE OPCIONES */}

                <Dialog
                    open={open}
                    fullWidth
                    onClose={() => setOpen(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>SERIES PERTENECIENTES A: {props.codigo}</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Box pt={1}>
                            <FormControl fullWidth>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            /* onClick={() => onDataIngresoSeriesExcel()} */
                                            /* onClick={() => window.open('/#/inventario/carga-series/', '_blank')} */
                                            onClick={() => onDataIngresoSeriesExcel()}
                                            /* component={RouterLink}
                                            to={`/inventario/carga-series/`} */
                                            size="small"
                                            color="success"
                                            variant="outlined"
                                            startIcon={<FileUploadIcon />}
                                        >
                                            CARGAR EXCEL
                                        </Button>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                        <Button
                          fullWidth
                          size="small"
                          color="info"
                          variant="outlined"
                          startIcon={<FormatAlignCenterIcon />}
                        >
                          CARGAR TXT
                        </Button>
                      </Grid> */}

                                    <Grid item xs={6}>
                                        {/* <CargaManualSeries props={props} /> */}
                                        <Button
                                            onClick={() => onDataIngresoManual()}
                                            fullWidth
                                            size="small"
                                            color="secondary"
                                            variant="outlined"
                                            startIcon={<FolderSharedIcon />}
                                        >
                                            CARGA MANUAL
                                        </Button>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                                        <Button
                                            onClick={() => onDataIngresoSeriesAutomaticas()}
                                            fullWidth
                                            size="small"
                                            color="warning"
                                            variant="outlined"
                                            startIcon={<AllInclusiveIcon />}
                                        >
                                            CARGA SERIES AUTOMATICAS
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => setOpen(false)}
                        >
                            <strong>Cancelar</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* DIALOG INGRESO MANUAL */}

                <Dialog
                    open={openIngresoManual}
                    fullWidth
                    onClose={() => setOpenIngresoManual(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES MANUAL {props.codigo}</strong>
                    </DialogTitle>
                    <DialogContent dividers>{cargarSeriesManuales(props)}</DialogContent>
                </Dialog>

                {/* DIALOG INGRESO SERIES AUTOMATICAS */}

                <Dialog
                    open={openIngresoSeriesAutomaticas}
                    fullWidth
                    onClose={() => setOpenIngresoSeriesAutomaticas(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES AUTOMATICAS</strong>
                    </DialogTitle>
                    <DialogContent>{cargarSeriesManualesAutomaticas()}</DialogContent>
                </Dialog>

                {/* DIALOG INGRESO SERIES MEDIANTE EXCEL */}

                <Dialog
                    open={openIngresoSeriesExcel}
                    fullWidth
                    onClose={() => setOpenIngresoSeriesExcel(false)}
                    maxWidth="md"
                >
                    <DialogTitle>
                        <strong>INGRESO DE SERIES MEDIANTE EXCEL</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Card>
                            <CardContent>
                                <Button
                                    color="success"
                                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                                    size="small"
                      /* onClick={() => handleUploadFile()} */ onClick={() =>
                                        setOpenCaja(true)
                                    }
                                    variant="outlined"
                                    startIcon={<UploadIcon />}
                                >
                                    SUBIR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                                        <DataGrid
                                            density="compact"
                                            rows={data ? data : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            // disableColumnFilter
                                            // disableColumnSelector
                                            // disableDensitySelector
                                            //hideFooterPagination
                                            columns={columns}
                                            getRowId={(row) => row.SERIE}
                                            components={{
                                                Toolbar: CustomToolbar,
                                                Footer: CustomFooterTotalComponent,

                                            }}
                                            onStateChange={(state) => {
                                                console.log(state.pagination.rowCount);
                                                setTotal(state.pagination.rowCount)
                                            }}
                                        />
                                    </Box>
                                </Paper>
                            </CardContent>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => onCloseIngresoSeriesExcel()}
                        >
                            <strong>Cancelar</strong>
                        </Button>
                        <Button
                            /* disabled={dataExcel === ""} */
                            disabled={data.length === 0}
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => SubirExcelFirebase()}
                        >
                            <strong>SUBIR EXCEL</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* SUBIR EXCEL */}
                <Dialog
                    open={openCaja}
                    onClose={() => setOpenCaja(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Subir Excel</DialogTitle>
                    <DialogContent>
                        <Box pb={2}>
                            {fileName
                                ? `Nombre el archivo cargado:  ${fileName}`
                                : "Por favor, cargar el archivo..."}
                        </Box>
                        {!file ? (
                            <>
                                <input
                                    style={{ display: "none" }}
                                    onChange={(e) => handleUploadFileLocal(e)}
                                    multiple
                                    id="buttonFile"
                                    type="file"
                                    ref={fileRef}
                                />
                                <label htmlFor="buttonFile" onClick={(e) => e.stopPropagation()}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        component="span"
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </>
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box pr={5}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={(e) => handleRemove()}
                                        >
                                            Borrar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={() => handleUploadFile()}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Subir archivo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                </Dialog>

                {/* ERROR INGRESO SERIES */}

                <Dialog
                    open={openIngresoErrorCargaSeries}
                    fullWidth
                    onClose={() => setOpenIngresoErrorCargaSeries(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>
                            LA CANTIDAD DE SERIES QUE INTENTA SUBIR NO ES IGUAL A LA CANTIDAD
                            SOLICITADA
                        </strong>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => onCloseIngresoErrorCargaSeries()}
                        >
                            <strong>VOLVER</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* DIALOG UPLOAD EXCEL */}

                <Dialog
                    open={openUploadExcelSeries}
                    fullWidth
                    onClose={() => setOpenUploadExcelSeries(false)}
                    maxWidth="md"
                >
                    <DialogTitle>
                        <strong>SUBA SU SERIES MEDIANTE EXCEL</strong>
                    </DialogTitle>
                    <DialogContent>

                        <Box >
                            {fileName
                                ? `Nombre el archivo cargado:  ${fileName}`
                                : "Por favor, cargar el archivo..."}
                        </Box>

                        {!file ? (
                            <>
                                <input
                                    style={{ display: "none" }}
                                    onChange={(e) => handleUploadFileLocal(e)}
                                    multiple
                                    id="buttonFile"
                                    type="file"
                                    ref={fileRef}
                                />
                                <label htmlFor="buttonFile">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        component="span"
                                        size='small'
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </>
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box pr={2}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={(e) => handleRemove()}
                                        >
                                            Borrar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={handleUploadFile}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Subir archivo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        {/* <Button variant='outlined' size='small' color='success'>
                            Cargar series
                        </Button> */}

                        <Paper>
                            <Box sx={{ height: 600, width: '100%' }} py={2} px={2}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    getRowId={(row) => row.SERIE}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,

                                    }}
                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => setOpenUploadExcelSeries(false)}
                        >
                            <strong>Regresar</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* SERIES CARGADAS CON EXITO */}

                <Dialog
                    open={openSuccessUploadSeries}
                    fullWidth
                    onClose={() => setOpenSuccessUploadSeries(false)}
                    maxWidth="sm"
                >
                    <DialogTitle>
                        <strong>
                            LAS SERIES SE HAN SUBIDO CON EXITO
                        </strong>
                    </DialogTitle>
                    <DialogActions>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setOpenSuccessUploadSeries(false)}
                        >
                            <strong>VOLVER</strong>
                        </Button>
                    </DialogActions>
                </Dialog>

            </>
        );
    };

    const [loadingButtonCerrarRecepcion, setLoadingButtonCerrarRecepcion] = useState(false);

    const [loadingButtonGuardarRecepcion, setLoadingButtonGuardarRecepcion] = useState(false);

    var cantidadRecibidaForm;

    let navigate = useNavigate()
    const [open, setOpen] = useState(false);


    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    //
    const [totalCompra, setTotalCompra] = useState('')
    //
    const [total, setTotal] = useState(0)
    const [totalHistorico, setTotalHistorico] = useState('')
    //
    const [data, setData] = useState([])
    const [dataBodega, setDataBodega] = useState('')
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState({ campo: '', valido: true })
    const [tipo_material, setTipoMaterial] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [numPedido2, setNumPedido2] = useState(false)
    const [numPedido3, setNumPedido3] = useState(false)
    const [productos, setProductos] = useState('')
    //
    const [provedores, setProvedores] = useState('')
    //
    const ubicacionesList = useSelector(state => state.ubicacionesList);
    const { ubicaciones, loadingUbicaciones } = ubicacionesList
    const [ubicacion, setUbicacion] = useState('')
    //
    const bodegasList = useSelector(state => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList
    const [bodega, setBodegas] = useState('')
    //
    const motivoEgresoProductosList = useSelector(state => state.motivoEgresoProductosList);
    const { motivoEgresoProductos, loadingMotivoIngresoProductos } = motivoEgresoProductosList
    const [motivoIngresoProducto, setMotivoIngresoProducto] = useState('')
    //
    const [cantidadProducto, setCantidadProducto] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')
    //
    const [modal, setModal] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    //
    const [formaPago, setFormaPago] = useState('')

    const [inputFields, setInputFields] = useState([]);

    const sendSeriesToFirebase = async () => {

        inputFields.forEach(async (obj, index) => {
            console.log('obj.fullName', obj.fullName)
            console.log('obj.series', obj.series)

            obj.series.forEach(async (objSerie, index) => {

                if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                    console.log('No se envia')
                } else {
                    console.log('objSerie', objSerie)
                    console.log('serie', objSerie.serie)

                    const uidSolicitud = `ING-` + numPedido2;

                    db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(obj.fullName).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: bodega,
                            estado: 0
                        })

                    db.collection(`ingreso_productos/${uidSolicitud}/items`)
                        .doc(obj.fullName).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: bodega,
                            estado: 0
                        })

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: bodega,
                            estado: 0
                        })
                }

            })

        })

        /* setTimeout(() => {
            navigate(`/inventario/documentacion/reporte-de-movimientos/`)
        }, 9000); */

    }
    //
    const [orden, setOrden] = useState("");
    const [precioHistorico, setPrecioHistorico] = useState("")
    //SE DETECTAN LOS CAMBIOS EN LA DIGITACION
    //DE LA CANTIDAD
    const handleChange = async (index, evnt, data) => {
        // const agendaDB = await db.collection("productos").where("codigo_producto", "==", codigoProducto).get()
        // const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        // console.log(codigoProducto)
        // data1[0].categoria
        const { name, value } = evnt.target;
        const list = [...inputFields];
        //list[index][name] = value;
        //cantidad pedida
        const cantidad_pedida = list[index]["cantidad_pendiente"]
        const cantidad_entregada = parseInt(evnt.target.value)
        console.log(cantidad_pedida, '--', cantidad_entregada)
        //
        //console.log(inputFields, 'input fiels')
        if (cantidad_pedida < cantidad_entregada) {
            console.log('Si entra')
        } else if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;

                const cantidad_recibida = list[index]["cantidad_recibida"]
                const diferencia_recepcion = list[index]["cantidad_pendiente"] - parseInt(cantidad_recibida)
                list[index]["diferencia_recepcion"] = parseInt(diferencia_recepcion)

                console.log('data handle change', data)

                if (data.usa_serie === true) {
                    const num = evnt.target.value;
                    let array = [];
                    for (var i = 0; i < num; i++) {
                        //console.log('Hola')
                        let n;
                        n += i;
                        //console.log('for', n)
                        array.push({
                            index_serie: index,
                            id: i,
                            serie: 0,
                        });
                    }
                    list[index]["series"] = array;
                    //
                    console.log(list, "--");

                    setInputFields(list, { series: [{ serie: 0 }], });
                    contador();
                } else {
                    contador();
                    return;
                }

                // const num = evnt.target.value;
                // let array = [];
                // for (var i = 0; i < num; i++) {
                //     //console.log('Hola')
                //     let n;
                //     n += i;
                //     //console.log('for', n)
                //     array.push({
                //         index_serie: index,
                //         id: i,
                //         serie: 0,
                //     });
                // }
                // list[index]["series"] = array;
                // //
                // console.log(list, "--");

                // setInputFields(list, { series: [{ serie: 0 }], });
                console.log(inputFields, 'input')
            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list, { series: [{ serie: 0 }], });
            }
        }
    }
    const handleSeries = async (index, evnt, row) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log("Numero negativo no pasa");
        } else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                let index_serie = row.series[0].index_serie;
                let array = {
                    id: index,
                    index_serie: index_serie,
                    serie: value.toUpperCase(),
                };
                list[index_serie]["series"][index] = array;
            } else {
                const list = [...inputFields];
                list[index]["fullName"] = evnt;
                setInputFields(list);
            }
        }
        //console.log('input files', inputFields)
    };

    const handleSeriesExcel = async (index, evnt, row) => {
        if (evnt) {
            const list = [...inputFields];
            let index_serie = row.series[0].index_serie;
            let array = {
                id: index,
                index_serie: index_serie,
                serie: evnt.toUpperCase(),
            };
            list[index_serie]["series"][index] = array;
            console.log('inputFields', inputFields)
        } else {
            const list = [...inputFields];
            /* list[index]["fullName"] = evnt; */
            setInputFields(list);
            console.log('inputFields', inputFields)
        }
        //console.log('input files', inputFields)
    };

    const getProductos = () => {
        var docRef = db.collection("productos")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const productoPrecioHistorico = (codigo) => {
        let sum = 0;
        var docRef = db.collection("productos").doc(codigo);
        docRef.get().then((doc) => {
            if (doc.exists) {
                const data = doc.data()
                sum = data.precio_compra_producto
                return sum
                console.log(data.precio_compra_producto, '--', codigo)
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const flotasList = useSelector(state => state.flotasList)
    const { flotas, loadingFlota } = flotasList
    const [flota, setFlota] = useState('')

    const [numFlota, setNumFlota] = useState('')
    const [lider, setLider] = useState('')
    const [auxiliar, setAuxiliar] = useState('')

    const [placa, setPlaca] = useState('')
    const [marca, setMarca] = useState('')
    const [modelo, setModelo] = useState('')

    const [observacionAdicional, setObservacionAdicional] = useState('')

    const handleFlota = (e) => {
        setFlota(e)
        const p = flotas.filter((item) => item.numeroFlota === e);
        const data = p[0]
        setNumFlota(data.numeroFlota)
        setLider(data.nombreLider)
        setAuxiliar(data.nombreAuxiliar)
        setPlaca(data.placa)
        setMarca(data.marca)
        setModelo(data.modelo)
    }

    useEffect(() => {
        dispatch(listFlotas())
        contador()
        getDataBodega()
        validacionCodigosYCantidadRecibida()
    }, []);
    //
    const contador = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.costo_estandar_producto)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.precio_total);
                }
                const total = contenido[i]["costo_estandar_producto"] * contenido[i]["cantidad_pedida"]
                list[i]["precio_total"] = total ? total : 0
                //
                const id = contenido[i]["codigo"]
                const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                var docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        console.log('Actualizacion de dato', data.precio_compra, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });

            }
            return suma;
        }
        //
        function sumarDiferencia(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.diferencia_recepcion)) {
                    suma += parseFloat(elemento.diferencia_recepcion);
                }
            }
            return suma;
        }
        const list = inputFields
        const total = sumarContenidoArreglo(inputFields)
        var pendiente_total = sumarDiferencia(inputFields)
        setTotalCompra(total)
        setTotal(pendiente_total)
    }

    function detalleMate(data, index) {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">
                    {data.codigo}
                </TableCell>
                <TableCell align="center">{data.descripcion}</TableCell>

                <TableCell align="center">
                    {data.cantidad_pedida}
                </TableCell>
                <TableCell align="center">
                    {data.cantidad_pendiente}
                </TableCell>
                <TableCell align="center">
                    <TextField
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        name={"cantidad_recibida"}
                        onChange={(e) => {
                            validacionTextFormFieldReporteStock()
                            handleChange(index, e, data)
                        }}
                        value={data.cantidad_recibida}
                        size='small'
                        type='number'
                    />
                </TableCell>
                <TableCell style={{ fontSize: '12px' }} align="center">
                    {data.diferencia_recepcion ? data.diferencia_recepcion : 0}
                </TableCell>

                <TableCell align="center">
                    {/* <CargaSeriesIngresoProductos props={data} bodega={bodega} codigo={data.codigo} /> */}
                    {bodega === "" ? (
                        <Tooltip title="SELECCIONE UNA BODEGA">
                            <Button disabled>
                                <QrCodeScannerIcon
                                    onClick={() => {
                                        console.log(data.codigo);
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    ) : (
                        <CargaSeriesIngresoProductos
                            props={data}
                            bodega={bodega}
                            codigo={data.codigo}
                            numPedido={numPedido}
                        />
                    )}
                </TableCell>

                {/* <TableCell align="center">
                    {
                        bodega === '' ? <Tooltip title="SELECCIONE UNA BODEGA">
                            <Button disabled>
                                <QrCodeScannerIcon />
                            </Button>
                        </Tooltip> : <CargaSeriesIngresoProductosCompra props={data} bodega={bodega} codigo={data.codigo} numPedido={orden} />
                    }
                </TableCell> */}

            </TableRow>
        )
    }
    const getProvedores = () => {
        var docRef = db.collection("proveedores")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProvedores(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    const getFormaPago = () => {
        var docRef = db.collection("forma_pago")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setFormaPago(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    //
    const loadNumOrden = () => {
        const ref = db.collection("bodega").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                //console.log(solicitudes, 'solicitudes')
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                var uidSolicitud = `ORD-COM-` + numeroSolicitud;
                setOrden(numeroSolicitud)
            }
        })
    }

    const dispatch = useDispatch()
    useEffect(() => {
        //console.log(items, 'items')
        getProvedores()
        getProductos()
        dispatch(listsBodegas())
        dispatch(listsUbicaciones())
        dispatch(listsMotivoEgresoProductos())
        loadNumPedido()
        loadNumPedido2()
        getFormaPago()
        loadNumOrden()
        getDataBodega()
        validacionCodigosYCantidadRecibida()
    }, [inputFields, totalCompra]);
    //
    const loadNumPedido = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })

        const ref2 = db.collection("ingreso_productos").orderBy("created", "desc").limit(1)
        ref2.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `ING-` + numeroSolicitud;
                setNumPedido2(numeroSolicitud)
            }
        })
    }

    const loadItems = () => {
        var docRef = db.collection("orden_compra").doc(items.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        diferencia_recepcion: 0,
                        series: [{ serie: 0 }],
                    };
                }),
            ];
            setInputFields([...data])
        });
    }

    const loadNumPedido2 = () => {
        const ref = db.collection("ingreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `ING-` + numeroSolicitud;
                setNumPedido3(numeroSolicitud)
            }
        })
    }
    const [sumaBodegas, setSumaBodegas] = useState('')
    const handleRechazar = () => {
        var opcion = window.confirm("¿Esta seguro de rechazar?");
        if (opcion == true) {
            db.collection("orden_compra").doc(items.uidSolicitud).update({
                estado: 4,
            })
        }

    }

    const consultInput = () => {
        inputFields.forEach((obj, index) => {
            console.log('obj', obj)
        })
    }

    const [dataLoaded, setDataLoaded] = useState(false);

    const handleSubmit = (type) => {

        var opcion = window.confirm("¿Esta seguro en generar la orden de compra?");
        if (opcion == true) {
            let rol = ''
            if (userInfo.rol === 0) {
                rol = 'ADMIN'
            }
            if (userInfo.rol === 1) {
                rol = 'COMERCIAL'
            }
            if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
                rol = 'OPERACIONES'
            }
            if (userInfo.rol === 3) {
                rol = 'PLANEACION'
            }
            if (userInfo.rol === 4) {
                rol = 'FLOTAS'
            }
            if (userInfo.rol === 5) {
                rol = 'REPORTES'
            }
            if (userInfo.rol === 6) {
                rol = 'RECURSOS HUMANOS'
            }
            //
            const uidSolicitud = `REC-MER-` + numPedido;
            const data = {
                /* uidSolicitud: `REC-MER-${orden}`, */
                uidSolicitud: `ING-${numPedido2}`,
                uid_recepcion_mercaderia: `REC-MER-${orden}`,
                estado: 0,
                numeroSolicitud: orden,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: userInfo.departamentoText,
                rol: rol,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: bodega.toUpperCase(),
                ubicacion: ubicacion.toUpperCase(),
                motivo: motivoIngresoProducto.toUpperCase(),
                observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                fecha_documento: fechaDocumento,
                relacion: items.relacion
            }

            console.log(inputFields)

            //console.log(data, 'enviar')

            var updatePromises = [];

            db.collection('bodega').doc(`REC-MER-${orden}`).set({
                ...data,
                estado: 1,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            }).then(async (docRef) => {

                //
                var estado_or = type == 1 ? 3 : 2
                db.collection("orden_compra").doc(items.uidSolicitud).update({
                    estado: estado_or,
                })

                inputFields.forEach(async function (obj, index) {

                    console.log(`VAR CANTIDAD RECIBIDA ${cantidadRecibidaForm}`)
                    console.log(obj)
                    console.log(`CANTIDAD RECIBIDA ${obj.cantidad_recibida}`)

                    console.log(`NUZMERO INGRESO ${numPedido3}`)

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', bodega).get()

                    const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

                    const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    const NuevaOperacionCostoPromedio = parseFloat(data2[0].costo_promedio) + parseFloat(data2[0].precio_compra)

                    /* const NuevaOperacionCostoPromedioResultado = NuevaOperacionCostoPromedio * */

                    const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(obj.cantidad_recibida)/* parseFloat(data2[0].stock_actual) */

                    const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

                    console.log('IDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', data2[0].id)

                    const precioCompraData2 = data2[0].precio_compra === undefined || data2[0].precio_compra === null || data2[0].precio_compra === '' ? 0 : data2[0].precio_compra

                    const saldo_valorizado = parseFloat(obj.cantidad_recibida) * parseFloat(precioCompraData2)

                    const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).get()

                    const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    db.collection("productos").doc(data4[0].id).update({
                        cantidad_producto: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_recibida)
                    })

                    var datoSumaTotalBodegas;

                    var sumaValorTotalBodegas;

                    let suma = 0;
                    for (let i = 0; i < data3.length; i++) {
                        console.log(data3[i]["stock_actual"])
                        suma += parseFloat(data3[i]["stock_actual"])
                        setSumaBodegas(suma)
                        datoSumaTotalBodegas = suma
                    }

                    let sumaTotal = 0;
                    for (let i = 0; i < data3.length; i++) {
                        console.log(data3[i]["total_valorizado"])
                        sumaTotal += parseFloat(data3[i]["total_valorizado"])
                        sumaValorTotalBodegas = sumaTotal
                    }

                    const Total = parseFloat(data2[0].total_valorizado) + parseFloat(saldo_valorizado)

                    const total_bodegas = parseFloat(data4[0].cantidad_producto) + parseFloat(obj.cantidad_recibida)

                    const sumaTotalBodegas = parseFloat(sumaValorTotalBodegas) + parseFloat(Total)

                    const cantidad_total = parseFloat(data2[0].stock_actual) + parseFloat(obj.cantidad_recibida)

                    const NuevoCostoPromedio = parseFloat(Total) / parseFloat(cantidad_total)

                    const dataRef = {
                        numero_solicitud: numPedido3,
                        uid_solicitud_pedido: `ING-${numPedido3}`,
                        /* ...obj, */

                        cantidad_recibida: obj.cantidad_recibida,
                        codigo: obj.codigo,
                        descripcion: obj.descripcion,
                        diferencia_recepcion: obj.diferencia_recepcion,
                        fullName: obj.fullName,
                        id: obj.id,
                        precio_total: obj.precio_total,

                        /*  */
                        precio_compra_producto: obj.precio_compra_producto ? obj.precio_compra_producto : 0,
                        cantidad_solicitada: parseFloat(obj.cantidad_recibida),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        bodega: bodega.toUpperCase(),
                        ubicacion: ubicacion.toUpperCase(),
                        motivo: 'INGRESO POR COMPRA',
                        motivo_movimiento: 'INGRESO',
                        observacion_adicional: 'NINGUNA',
                        uid_orden_compra: `REC-MER-` + orden,
                        uid_recepcion_mercaderia: `REC-MER-` + orden,
                        cantidad: parseInt(obj.cantidad_pedida),
                        cantidad_pedida: parseInt(obj.cantidad_pedida),
                        cantidad_pendiente: parseInt(obj.cantidad_pedida),
                        estado: 1,
                        costo_estandar_producto: parseFloat(obj.costo_estandar_producto),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        uidSolicitud: `ING-` + numPedido3,
                        uid_orden_compra: `ORD-COM-` + orden,
                        observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                        flota: flota,
                        lider_flota: lider,
                        auxiliar_flota: auxiliar,
                        placa_flota: placa,
                        marca_flota: marca,
                        modelo_flota: modelo,
                        precio_compra: data3[0].precio_compra,
                        fecha_documento: fechaDocumento,
                        relacion: items.relacion
                    }
                    console.log(dataRef, 'dataREF')
                    console.log(obj, 'OBJ')
                    var datoSumaTotalBodegas;

                    let suma8 = 0;
                    for (let i = 0; i < data3.length; i++) {
                        console.log(data3[i]["stock_actual"])
                        suma8 += parseFloat(data3[i]["stock_actual"])
                        datoSumaTotalBodegas = suma8
                    }

                    var saldoTotalValorizado;
                    let sumaTotal8 = 0;
                    for (let i = 0; i < data3.length; i++) {
                        console.log(data3[i]["total_valorizado"])
                        sumaTotal8 += parseFloat(data3[i]["total_valorizado"])
                        saldoTotalValorizado = sumaTotal8
                    }

                    const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto) + parseInt(obj.cantidad_pedida)

                    const kardexGeneralSumaTotal = parseFloat(saldoTotalValorizado) + parseFloat(saldo_valorizado)

                    console.log(`kardexGeneralSumaTotal ${kardexGeneralSumaTotal}`)

                    console.log(`DATO SUMA TOTAL BODEGAS ${total_bodegas}`)

                    console.log(`TOTAL ${Total}`)

                    console.log(`CANTIDAD TOTAL ${cantidad_total}`)

                    const dataRef2 = {
                        numero_solicitud: numPedido3,
                        ...obj,
                        uid_solicitud_pedido: `ING-${numPedido3}`,
                        precio_compra_producto: obj.precio_compra_producto ? obj.precio_compra_producto : 0,
                        cantidad_solicitada: parseFloat(obj.cantidad_recibida),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),

                        uid_orden_compra: `REC-MER-` + orden,
                        uid_recepcion_mercaderia: `REC-MER-` + orden,
                        cantidad: parseInt(obj.cantidad_pedida),
                        cantidad_solicitada: parseInt(obj.cantidad_recibida),
                        estado: 1,
                        /* costo_estandar_producto: parseFloat(obj.costo_estandar_producto), */
                        costo_estandar_producto: parseFloat(obj.costo_estandar_producto),
                        created: firebase.firestore.FieldValue.serverTimestamp(),

                        total: Total === 'NaN' || Total === NaN ? 0 : Total,
                        saldo_valorizado: parseFloat(saldo_valorizado),
                        costo_promedio: obj.precio_compra_producto/* NuevoCostoPromedio.toFixed(2) <= 0 || Number.isNaN(Number(NuevoCostoPromedio)) ? 0 : NuevoCostoPromedio.toFixed(2) *//* costoPromedio.toFixed(2) *//* data2[0].precio_compra */,/* parseFloat(kardexGeneralSumaTotal) / parseFloat(total_bodegas).toFixed(2), */
                        bodega: bodega.toUpperCase(),
                        ubicacion: ubicacion.toUpperCase(),
                        motivo: 'INGRESO POR COMPRA',
                        motivo_movimiento: 'INGRESO',
                        tipo_movimiento: 'ingreso',
                        total_bodegas: cantidad_total,
                        suma_valores_total_blodegas: sumaTotalBodegas,
                        precio_compra: data3[0].precio_compra,
                        uidSolicitud: `ING-` + numPedido3,
                        uid_orden_compra: `ORD-COM-` + orden,
                        total_compra_valorizada: parseFloat(saldo_valorizado),
                        observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),
                        flota: flota,
                        lider_flota: lider,
                        auxiliar_flota: auxiliar,
                        placa_flota: placa,
                        marca_flota: marca,
                        modelo_flota: modelo,
                        costo_promedio_general: NuevoCostoPromedio.toFixed(2) <= 0 || Number.isNaN(Number(NuevoCostoPromedio)) ? 0 : NuevoCostoPromedio.toFixed(2),/* parseFloat(kardexGeneralSumaTotal) / parseFloat(total_bodegas).toFixed(2), */
                        fecha_documento: fechaDocumento
                    }

                    const dataRef3 = {
                        numero_solicitud: numPedido3,
                        ...obj,
                        uid_solicitud_pedido: `ING-${numPedido2}`,

                        cantidad_solicitada: parseFloat(obj.cantidad_recibida),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || kardexGeneralSumaTotalStock === NaN ? 0 : kardexGeneralSumaTotalStock,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        uid_orden_compra: `REC-MER-` + orden,

                        cantidad_solicitada: parseInt(obj.cantidad_recibida),
                        estado: 1,

                        saldo_valorizado: parseFloat(saldo_valorizado),
                        costo_promedio: obj.precio_compra_producto,

                        motivo: 'INGRESO POR COMPRA',
                        motivo_movimiento: 'INGRESO',
                        tipo_movimiento: 'ingreso',

                        precio_compra: data3[0].precio_compra,

                        uid_orden_compra: `ORD-COM-` + orden,

                        observacion_adicional: observacionAdicional === '' ? 'NINGUNA' : observacionAdicional.toUpperCase(),

                        fecha_documento: fechaDocumento
                    }

                    const uidSol = `ING-COM-` + orden;

                    const solPedido = `ING-${numPedido3}`

                    const collection3Ref = db.collection(`reporte_movimientos`).doc(solPedido).set(dataRef)
                        .then((dataRef) => {
                            console.log('items id: ', dataRef)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                    updatePromises.push(collection3Ref);

                    const collection4Ref = db.collection(`reporte_movimientos/${solPedido}/items`).doc(obj.codigo).set(dataRef2)
                        .then((dataRef2) => {
                            console.log('items id: ', dataRef2)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                    const collection1Ref = db.collection(`ingreso_productos`).doc(solPedido).set(dataRef2)
                        .then((dataRef2) => {
                            console.log('items id: ', dataRef2)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })

                    updatePromises.push(collection1Ref);

                    const collection2Ref = db.collection(`ingreso_productos/${solPedido}/items`).doc(obj.codigo).set(dataRef2)
                        .then((dataRef2) => {
                            console.log('items id: ', dataRef2)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })

                    updatePromises.push(collection2Ref);


                    if (data2[0].stock_actual >= 0) {
                        obj.subcategoria_producto === 'COSTEABLE' ? db.collection("reporte_stock").doc(data2[0].id).update({
                            stock_actual: parseFloat(data2[0].stock_actual) + parseFloat(obj.cantidad_recibida),
                            total_valorizado: Total,
                            /* costo_promedio: parseFloat(kardexGeneralSumaTotal) / parseFloat(total_bodegas).toFixed(2) */
                            costo_promedio: NuevoCostoPromedio.toFixed(2) <= 0 || Number.isNaN(Number(NuevoCostoPromedio)) ? 0 : NuevoCostoPromedio.toFixed(2)/* costoPromedio.toFixed(2) */
                        }) : db.collection("reporte_stock").doc(data2[0].id).update({
                            stock_actual: parseFloat(data2[0].stock_actual) + parseFloat(obj.cantidad_recibida),
                            total_valorizado: Total,
                            costo_promedio: NuevoCostoPromedio.toFixed(2) <= 0 || Number.isNaN(Number(NuevoCostoPromedio)) ? 0 : NuevoCostoPromedio.toFixed(2)/* costoPromedio.toFixed(2) */
                            /* costo_promedio: data2[0].costo_promedio */
                        })
                    }




                    updatePromises.push(collection4Ref);

                    const collection5Ref = db.collection(`total_movimientos`).doc().set(dataRef2)
                        .then((dataRef2) => {
                            console.log('items id: ', dataRef2)
                            //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                    updatePromises.push(collection5Ref);

                    const collection7Ref = db.collection(`bodega/REC-MER-${orden}/items`).doc(dataRef.codigo).set({
                        ...dataRef,
                        cantidad_pendiente: dataRef.diferencia_recepcion ? dataRef.diferencia_recepcion : 0
                    })
                        .then((docRef) => {
                        })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                    updatePromises.push(collection7Ref);
                    //
                    //
                    const collection8Ref = db.collection(`orden_compra/${items.uidSolicitud}/items`).doc(dataRef.codigo).update({
                        cantidad_pendiente: dataRef.diferencia_recepcion ? dataRef.diferencia_recepcion : 0
                    }).then((docRef) => {
                        console.log('entro succes')
                    })
                        .catch((error) => {
                            console.error("Error adding document: ", error);
                        });
                    //

                    updatePromises.push(collection8Ref);

                    const collection9Ref = db.collection("productos").doc(dataRef.codigo).update({
                        precio_compra_producto: data3[0].precio_compra,
                        precio_compra: data3[0].precio_compra,
                    })

                    updatePromises.push(collection9Ref);
                    //
                    //

                    db.collection("reporte_stock").doc(data2[0].id).update({
                        /* costo_promedio: parseFloat(kardexGeneralSumaTotal) / parseFloat(total_bodegas).toFixed(2) */
                        /* costo_promedio: costoPromedio.toFixed(2) */
                    })

                });

                try {
                    setLoading(false)
                    Swal.fire({
                        title: 'Se estan cargando todos los datos',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    await Promise.all(updatePromises);
                    setDataLoaded(true);

                    if (inputFields.length >= 0 && inputFields.length <= 8) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 24000);
                    } else if (inputFields.length >= 9 && inputFields.length <= 18) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 44000);
                    } else if (inputFields.length >= 19 && inputFields.length <= 30) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 74000);
                    } else if (inputFields.length >= 31 && inputFields.length <= 40) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 90000);
                    } else if (inputFields.length >= 41 && inputFields.length <= 50) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 120000);
                    } else if (inputFields.length >= 51) {
                        Swal.fire({
                            title: 'Se estan cargando todos los datos',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        setTimeout(() => {

                            Swal.fire({
                                title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                                text: 'Por favor espere',
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                            setTimeout(() => {
                                window.location.reload();
                            }, 8000);

                        }, 250000);
                    }

                } catch (error) {
                    console.error('Error al cargar los datos:', error);

                    Swal.fire({
                        icon: 'error',
                        title: 'Error al cargar los datos',
                        text: 'Ha ocurrido un error al cargar los datos. Inténtalo de nuevo.'
                    });
                }

            })
        } else {
            //mensaje = "Has clickado Cancelar";
        }
    }
    const onData = () => {
        setOpen(true)
        setData(items)
        loadItems()
        getDataBodega()
        setInputFields([...inputFields])
    }
    //
    const getDataBodega = async () => {

        let ref = db.collection("bodega");

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setDataBodega(data)
        })
    }
    const [totalDataReporteStock, setTotalDataReporteStock] = useState([])
    const [arrayCantidadRecibida, setArrayCantidadRecibida] = useState([])

    const validacionTextFormFieldReporteStock = () => {
        inputFields.forEach(async (codigo) => {
            console.log(codigo.fullName)

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo.fullName).where("bodega", "==", bodega).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            console.log(data3[0].id)

            if (totalDataReporteStock.find(idReporteStock => idReporteStock.id === data3[0].id)) {
                console.log('Objeto ya ingresado')
            } else {
                setTotalDataReporteStock([...totalDataReporteStock, {
                    id: data3[0].id,
                }])
            }

        });
    }

    const validacionCodigosYCantidadRecibida = () => {
        inputFields.forEach(async (codigo) => {
            console.log(`VAR ARRAY CANTIDAD RECIBIDA ${codigo.cantidad_recibida}`)
            cantidadRecibidaForm = codigo.cantidad_recibida
            console.log(`VAR CANTIDAD RECIBIDA ${cantidadRecibidaForm}`)
            console.log(codigo.fullName)
            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo.fullName).where("bodega", "==", bodega).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            console.log(data3[0].id)
            if (totalDataReporteStock.find(idReporteStock => idReporteStock.id === data3[0].id)) {
                console.log('Objeto ya ingresado')
            } else {
                setTotalDataReporteStock([...totalDataReporteStock, {
                    id: data3[0].id,
                }])
            }

            /* if (arrayCantidadRecibida.find(cantidadRecibida => cantidadRecibida.cantidad_recibida === codigo.cantidad_recibida)) {
                console.log('codigo nulo')
            } else {
                setArrayCantidadRecibida([...arrayCantidadRecibida, {
                    id: data3[0].id,
                    cantidad_recibida: codigo.cantidad_recibida,
                }])
            } */
        });

        console.log(totalDataReporteStock)
        /* console.log(arrayCantidadRecibida, 'Array cantidad recibida') */
        console.log(inputFields, 'input')
    }

    const validacionProductosReporteStock = async (estado) => {

        setLoadingButtonCerrarRecepcion(true)

        setLoadingButtonGuardarRecepcion(true)

        validacionCodigosYCantidadRecibida()

        const openAndCloseModal = () => {
            console.log('No existe id')
            setOpen(false)
            /* setModal(false) */
            /*  Swal.fire({ icon: "warning", text: `La ${bodega} no esta asociada a este producto` }) */
            Swal.fire({ icon: "warning", text: `No todos los productos estan asociados a la ${bodega}` })
        }

        const openAndCloseModalCantidadRecibida = () => {
            console.log('No existe id')
            setOpen(false)
            /* setModal(false) */
            /*  Swal.fire({ icon: "warning", text: `La ${bodega} no esta asociada a este producto` }) */
            Swal.fire({ icon: "warning", text: `Por favor asegurese de haber digitado todas las cantidades solicitadas` })
        }

        if (totalDataReporteStock.length === inputFields.length) {
            console.log('Todos los items estan asociados')
            validacionCodigosYCantidadRecibida()
            handleSubmit(estado)
            sendSeriesToFirebase()
        } else {
            console.log('No todos los items estan asociados')
            openAndCloseModal()
        }

        /* if (totalDataReporteStock.length != inputFields.length) {
            console.log('No todos los items estan asociados')
            openAndCloseModal()
        } else if (arrayCantidadRecibida.length != inputFields.length) {
            console.log('Undefined cantidad recibida')
            openAndCloseModalCantidadRecibida()
        } else {
            console.log('Todos los items estan asociados')
            handleSubmit(estado)
        } */

    }

    const loadingSwal = async () => {
        setOpen(false)
        let timerInterval
        Swal.fire({
            title: 'Se esta realizando la recepcion de mercaderia',
            html: 'Esto se cerrara cuando todo se haya realizado exitosamente',
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft()
                }, 100)
            },
            willClose: () => {
                clearInterval(timerInterval)
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
            }
        })
    }

    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
            >
                <DialogTitle>
                    RECEPCION DE MERCADERIA: {data.uidSolicitud}
                </DialogTitle>
                <DialogContent dividers>
                    <Card>
                        <CardContent>
                            <form>
                                <Grid container spacing={5}>
                                    <Grid item md={6}>
                                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>ORDEN DE COMPRA</strong></Typography>
                                        <Typography style={{ fontSize: '14px' }}>{data.uidSolicitud}</Typography>

                                        <Box pt={1}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>NUMERO DE MOVIMIENTO</strong></Typography>
                                            <Typography style={{ fontSize: '14px' }}>ING-{numPedido3}</Typography>
                                        </Box>

                                        <Box pt={1} pb={1}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>FECHA DE CREACION</strong></Typography>
                                            <Typography style={{ fontSize: '14px' }}>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>
                                        </Box>
                                        <Divider />
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>USUARIO</strong></Typography>
                                        <Typography style={{ fontSize: '14px' }}>{userInfo.displayName}</Typography>
                                        <Box pt={1} pb={1}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>DEPARTAMENTO</strong></Typography>
                                            <ConvertRoles id={userInfo.rol} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box py={1}></Box>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Box pb={2}>
                                            <FormControl fullWidth>
                                                <Typography gutterBottom style={{ fontSize: '14px' }}><strong>BODEGA</strong></Typography>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={bodega}
                                                    label="MOTIVO"
                                                    onChange={(e) => setBodegas(e.target.value)}>
                                                    {bodegas &&
                                                        bodegas.map(item => (
                                                            <MenuItem key={item.nombre_bodega} value={item.nombre_bodega} >
                                                                {item.nombre_bodega}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box pt={2}>
                                            <Typography gutterBottom style={{ fontSize: "14px" }}>
                                                <strong>FECHA DE DOCUMENTO</strong>
                                            </Typography>

                                            <Box pb={2}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DateTimePicker
                                                        sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }}
                                                        renderInput={(props) => <TextField sx={{
                                                            "& legend": { display: "none" },
                                                            "& fieldset": { top: 0 },
                                                        }} fullWidth {...props} size='small' />}
                                                        inputFormat="dd/MM/yyyy"
                                                        value={fechaDocumento}
                                                        onChange={(newValue) => {
                                                            setFechaDocumento(newValue);
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        </Box>

                                        {/* <Box pt={2}>
                                            <FormControl fullWidth>
                                                <Typography gutterBottom style={{ fontSize: '14px' }}><strong>OBSERVACION ADICIONAL</strong></Typography>
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={observacionAdicional}
                                                    onChange={(e) => setObservacionAdicional(e.target.value)}
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Box> */}
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box pb={4}>
                                            <FormControl fullWidth>
                                                <Typography gutterBottom style={{ fontSize: '14px' }}><strong>UBICACION</strong></Typography>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={ubicacion}
                                                    label="FORMA DE PAGO"
                                                    onChange={(e) => setUbicacion(e.target.value)}>
                                                    {ubicaciones &&
                                                        ubicaciones.map(item => (
                                                            <MenuItem key={item.nombre_ubicacion} value={item.nombre_ubicacion} >
                                                                {item.nombre_ubicacion}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <FormControl fullWidth>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}><strong>UNIDAD DE TRANSPORTE</strong></Typography>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size="small"
                                                label="Flota"
                                                value={flota}
                                                onChange={(e) => handleFlota(e.target.value)}
                                            >

                                                {flotas && flotas.map((row, key) => (
                                                    <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>

                                        {
                                            flota === ''
                                                || flota === undefined
                                                || flota === null
                                                ?
                                                ''
                                                :
                                                <>
                                                    <Box py={2}>
                                                        <Typography gutterBottom style={{ fontSize: '14px' }}><strong>TRANSPORTISTA</strong></Typography>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                sx={{
                                                                    '& legend': { display: 'none' },
                                                                    '& fieldset': { top: 0 },
                                                                }}
                                                                size="small"
                                                                disabled
                                                                value={lider}
                                                            >
                                                                <MenuItem value={lider}>{lider}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                    <Typography gutterBottom style={{ fontSize: '14px' }}><strong>PLACA</strong></Typography>
                                                    <Box pb={1}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                sx={{
                                                                    '& legend': { display: 'none' },
                                                                    '& fieldset': { top: 0 },
                                                                }}
                                                                size="small"
                                                                disabled
                                                                value={placa}
                                                            >
                                                                <MenuItem value={placa}>{placa}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </>
                                        }

                                        <Box pt={2}>
                                            <FormControl fullWidth>
                                                <Typography gutterBottom style={{ fontSize: '14px' }}><strong>OBSERVACION ADICIONAL</strong></Typography>
                                                <TextField
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={observacionAdicional}
                                                    onChange={(e) => setObservacionAdicional(e.target.value)}
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Box>

                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NO.</TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>CODIGO</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>DESCRIPCION</strong></TableCell>
                                                <TableCell align="center" style={{ width: '120px', fontSize: '12px' }}><strong>CANTIDAD SOLICITADA</strong></TableCell>
                                                <TableCell align="center" style={{ width: '120px', fontSize: '12px' }}><strong>CANTIDAD PENDIENTE</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>CANTIDAD RECIBIDA</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>DIFERENCIA</strong></TableCell>
                                                <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIES</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                inputFields.map((data, index) => {
                                                    const {
                                                        cantidad_pedida,
                                                        categoria_producto,
                                                        subcategoria_producto,
                                                        marca_producto,
                                                        modelo_producto,
                                                        presentacion_producto,
                                                        unidad_Medida_Producto,
                                                        usuario_que_creo_el_producto,
                                                        descripcion_producto,
                                                        codigo_producto,
                                                    } = data;
                                                    return (
                                                        detalleMate(data, index)
                                                    )
                                                })
                                            }
                                        </TableBody>
                                        <TableRow>
                                            <TableCell colSpan={6}></TableCell>
                                            <TableCell align="center"><Typography variant='h6'><strong>{total && total}</strong> </Typography></TableCell>
                                            <TableCell colSpan={1}></TableCell>
                                        </TableRow>

                                    </Table>
                                </TableContainer>
                                <Divider />
                            </form>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <LoadingButton
                        size='small'
                        variant='contained'
                        color='warning'
                        loading={loadingButtonCerrarRecepcion}
                        endIcon={loadingButtonCerrarRecepcion === true ? <SendIcon /> : ''}
                        disabled={
                            fechaDocumento === ''
                            /* loadingButtonCerrarRecepcion === true
                            || */ || inputFields.length <= 0
                            || bodega === ''
                            || ubicacion === ''
                            || flota === ''
                            || isNaN(totalCompra)
                            || total != 0
                        }
                        loadingPosition="end"
                        onClick={() => /* consultInput() */validacionProductosReporteStock(0)}
                    >CERRAR RECEPCION DE MERCADERIA</LoadingButton>
                    {/* <Button
                        size='small'
                        variant='contained'
                        color='warning'
                        disabled={
                            inputFields.length <= 0
                            || bodega === ''
                            || ubicacion === ''
                            || flota === ''
                            || isNaN(totalCompra)
                            || total != 0
                        }
                        onClick={() => validacionProductosReporteStock(0)}
                    >CERRAR RECEPCION DE MERCADERIA</Button> */}
                    <Button size='small' color='error' variant='contained' onClick={() => /* handleRechazar */console.log(inputFields)}>RECHAZAR ORDEN</Button>
                    {/* <Button
                        size='small'
                        variant='contained'
                        color='success'
                        disabled={
                            inputFields.length <= 0
                            || bodega === ''
                            || ubicacion === ''
                            || flota === ''
                            || isNaN(totalCompra)
                            || total == 0
                        }

                        onClick={() => validacionProductosReporteStock(1)}
                    >GUARDAR RECEPCION MERCADERIA</Button> */}

                    <LoadingButton
                        size='small'
                        variant='contained'
                        color='success'
                        loading={loadingButtonGuardarRecepcion}
                        endIcon={loadingButtonGuardarRecepcion === true ? <SendIcon /> : ''}
                        disabled={
                            fechaDocumento === ''
                            /* loadingButtonGuardarRecepcion === true
                            || */ || inputFields.length <= 0
                            || bodega === ''
                            || ubicacion === ''
                            || flota === ''
                            || isNaN(totalCompra)
                            || total == 0
                        }
                        loadingPosition="end"
                        onClick={() => /* consultInput() */validacionProductosReporteStock(1)}
                    >GUARDAR RECEPCION MERCADERIA</LoadingButton>

                    <Button size='small' variant='contained' onClick={() => { loadingSwal() }}>Press</Button>

                </DialogActions>
            </Dialog>
        </>
    )
}