import { Box, Card, Tab, Typography, Container } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect } from 'react'
import { VentasCrearCliente } from './VentasCrearCliente';
import { VentasPorAgendar } from './VentasPorAgendar';
import { SolicitudesReAgendadas } from './SolicitudesReAgendadas';
import { VentasPorReAgendar } from './VentasPorReAgendar';
import { VentasRechazadasAgenda } from './ventas_rechazadas/VentasRechazadasAgenda';
import { useParams, useNavigate } from 'react-router-dom';
import { CambioDeDomicilio } from './CambioDeDomicilio';
import { VentasAgendadas } from './VentasAgendadas';
import { VentasServicio } from './VentasServicio';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  )
}
export const AgendaPage = () => {
  const params = useParams()
  let navigate = useNavigate()
  const [tab, setTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    navigate(`/Planeacion/Agenda/${newValue}`)
  }
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id));
    } else {
      setTab(0)
    }
  }, [params.id])
  return (

    <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1"><strong>AGENDAS</strong></Typography>
      </Box>
      <Card>
        <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={tab}
            onChange={handleChange}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Pre Agendamiento" />
            <Tab label="Ventas por agendar" />
            <Tab label="Ventas Agendadas" />
            <Tab label="Ventas Por ReAgendar" />
            <Tab label="Ventas Re Agendadas" />
            <Tab label="Ventas Rechazadas" />
            <Tab label="Cambio Domicilio" />
            <Tab label="Ventas Servicio" />
          </Tabs>
        </Box>
          <Card>
            <TabPanel value={tab} index={0}>
              <VentasCrearCliente />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <VentasPorAgendar />
            </TabPanel>
            <TabPanel value={tab} index={2}>
             <VentasAgendadas />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <VentasPorReAgendar />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <SolicitudesReAgendadas />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <VentasRechazadasAgenda />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <CambioDeDomicilio />
            </TabPanel>
            {/* <TabPanel value={tab} index={7}>
              <VentasServicio />
            </TabPanel> */}
          </Card>
      </Card>
    </Container>
  )
}