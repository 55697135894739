import React, { useEffect, useState, useRef } from "react";
import { db, firebase, storage } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    FormLabel,
    RadioGroup,
    Radio
} from "@mui/material";
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import {
    listsCategorias,
    listsModeloProducto,
    listsSubCategorias,
    listsMarcaProducto,
    listsPresentacionProducto,
    listsUnidadMedidaProducto,
    listsCategoriasFirebase,
    listsSubCategoriasFirebase,
    listsPresentacionFirebase,
    listsMarcaFirebase,
    listsModeloFirebase,
    listsUnidadMedidaFirebase
} from "../../../redux/actions/parametersActions";
import * as XLSX from "xlsx/xlsx.mjs";
import {
    listsBodegas
} from "../../../redux/actions/parametersActions";

export const NuevoProductoSelects = () => {

    const bodegasList = useSelector((state) => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList;
    const [bodega, setBodegas] = useState("");

    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const categoriasFirebaseList = useSelector(state => state.categoriasFirebaseList);
    const { categorias, loading } = categoriasFirebaseList
    const [categoria, setCategoria] = useState('')

    const subCategoriasFirebaseList = useSelector(state => state.subCategoriasFirebaseList);
    const { subCategorias, loadingSubCategorias } = subCategoriasFirebaseList
    const [SubCategoria, setSubCategoria] = useState('')

    const ModelosFirebaseList = useSelector(state => state.ModelosFirebaseList);
    const { modeloProductos, loadingModeloProductos } = ModelosFirebaseList
    const [modeloproducto, setModeloProducto] = useState('')

    const MarcasFirebaseList = useSelector(state => state.MarcasFirebaseList);
    const { marcaProductos, loadingMarcaProductos } = MarcasFirebaseList
    const [marcaproducto, setMarcaProducto] = useState('')

    const UnidadesMedidaList = useSelector(state => state.UnidadesMedidaList);
    const { unidadMedidaProductos, loadingUnidadMedidaProductos } = UnidadesMedidaList
    const [unidadMedidaProducto, setUnidadMedidaProducto] = useState('')

    const PresentacionesFirebaseList = useSelector(state => state.PresentacionesFirebaseList);
    const { presentacionProductos, loadingPresentacionProductos } = PresentacionesFirebaseList
    const [presentacionProducto, setPresentacionProducto] = useState('')

    const [lider, setLider] = useState({ campo: '', valido: true })
    const [auxiliar, setAuxiliar] = useState({ campo: "", valido: true });
    const [proveedor, setProveedor] = useState({
        campo: "",
        valido: true,
    });
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [Imagen, setImagen] = useState(null);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsBodegas());
        dispatch(listsSubCategorias())
        dispatch(listsModeloProducto())
        dispatch(listsMarcaProducto())
        dispatch(listsUnidadMedidaProducto())
        dispatch(listsPresentacionProducto())
        dispatch(listsPresentacionProducto())
        dispatch(listsCategoriasFirebase())
        dispatch(listsSubCategoriasFirebase())
        dispatch(listsPresentacionFirebase())
        dispatch(listsMarcaFirebase())
        dispatch(listsModeloFirebase())
        dispatch(listsUnidadMedidaFirebase())
        LoadData()
    }, [dispatch]);

    const changeCategoria = (e) => {
        setCategoria(e)
    }

    const changeSubCategoria = (e) => {
        setSubCategoria(e)
    }

    const changeModeloProducto = (e) => {
        setModeloProducto(e)
    }

    const changeMarcaProducto = (e) => {
        setMarcaProducto(e)
    }

    const changeUnidadMedidaProducto = (e) => {
        setUnidadMedidaProducto(e)
    }

    const changePresentacionProducto = (e) => {
        setPresentacionProducto(e)
    }

    const [descripcionProducto, setDescripcionProducto] = useState('')

    const handleDescriptionProducto = (e) => {
        setDescripcionProducto(e)
    }

    const [codigoProducto, setCodigoProducto] = useState('')

    const handleCodigoProducto = (e) => {
        setCodigoProducto(e)
    }

    const [numeroSerieProducto, setNumeroSerieProducto] = useState('')

    const handleNumeroSerieProducto = (e) => {
        setNumeroSerieProducto(e)
    }

    const [cantidadProducto, setCantidadProducto] = useState('')

    const handleCantidadProducto = (e) => {
        setCantidadProducto(e)
    }

    const [costoEstandarProducto, setCostoEstandarProducto] = useState('')

    const handleCostoEstandarProducto = (e) => {
        setCostoEstandarProducto(e)
    }

    const [precioCompra, setPrecioCompra] = useState('')

    const handlePrecioCompra = (e) => {
        setPrecioCompra(e)
    }

    const [precioVenta, setPrecioVenta] = useState('')

    const handlePrecioVenta = (e) => {
        setPrecioVenta(e)
    }

    /* Activos fijos */

    const [tipoDetalleActivoFijo, setTipoDetalleActivoFijo] = useState('')

    const [vidaUtilActivo, setVidaUtilActivo] = useState('')

    const [porcentajeDeDepreciacion, setPorcentajeDeDepreciacion] = useState('')

    const [clasesDeActivos, setClasesDeActivos] = useState('')

    /*  */

    const [checkValue, setCheckValue] = useState("")

    const [localidad, setLocalidad] = useState('');
    const [marca, setMarca] = useState([]);

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [stockMinimoProducto, setStockMinimoProducto] = useState('')

    const handleStockMinimoProducto = (e) => {
        setStockMinimoProducto(e)
    }

    const [stockMaximoProducto, setStockMaximoProducto] = useState('')

    const handleStockMaximoProducto = (e) => {
        setStockMaximoProducto(e)
    }

    const [stockSeguridadProducto, setsStockSeguridadProducto] = useState('')

    const handleStockSeguridadProducto = (e) => {
        setsStockSeguridadProducto(e)
    }

    var imagenUrl;

    const onHandleClick = async () => {

        //CONSULTA A LA COLECCION DE REPORTE DE STOCK

        const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", codigoProducto).where("bodega", "==", bodega).get()

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        //SI EL PRODUCTO NO EXISTE EN ALGUNA BODEGA DENTRO
        //DE REPORTE DE STOCK SE CREARA EN LA BODEGA ELEGIDA

        if (data3[0] === undefined) {

            console.log(`Data reporte de stock ${data3[0]}`)

            //CONSULTA A LA COLECCION DE PRODUCTOS

            const consultCollectionProductos = await db.collection("productos").where("codigoProducto", "==", codigoProducto).get()
            const dataCollectionProducts = await consultCollectionProductos.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            console.log(`Data Productos ${dataCollectionProducts[0]}`)

            db.collection("reporte_stock")
                .doc()
                .set({
                    bodega: bodega.toUpperCase(),

                    codigo_producto: codigoProducto.toUpperCase(),

                    descripcion: descripcionProducto.toUpperCase(),

                    categoria: categoria.toUpperCase(),

                    motivo: 'CREACION PRODUCTO',

                    tipo_movimiento: 'ingreso',

                    unidad_medida: unidadMedidaProducto.toUpperCase(),

                    stock_actual: 0,

                    costo_promedio: 0,

                    total_valorizado: 0,

                    precio_compra: 0,

                    fecha_creacion_carga_inicial: firebase.firestore.FieldValue.serverTimestamp(),

                    usuario_que_subio_carga_producto: userInfo.displayName,
                })

            //SI EL PRODUCTO NO EXISTE SE CREARA UNO NUEVO
            //SI YA EXISTE NO PASARA NADA

            if (dataCollectionProducts[0] === undefined) {
                const newRef = useStorage.ref('imagenes_productos').child(codigoProducto); // nombre del archivo
                setRef(newRef);
                await newRef.put(Imagen);
                const urlImagen = await newRef.getDownloadURL()
                imagenUrl = urlImagen;
                console.log('la url de la imagen es' + urlImagen);

                await db.collection("productos").doc(codigoProducto).set({
                    codigo_producto: codigoProducto,
                    fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),
                    descripcion_producto: descripcionProducto.toUpperCase(),
                    categoria_producto: categoria,
                    subcategoria_producto: SubCategoria,
                    marca_producto: marcaproducto,
                    modelo_producto: modeloproducto,
                    unidad_Medida_Producto: unidadMedidaProducto,
                    presentacion_producto: presentacionProducto,
                    costo_estandar_producto: 0,
                    precio_compra_producto: 0,
                    precio_venta_producto: 0,
                    cantidad_producto: 0,
                    estado_producto: checked === true ? 0 : 1,
                    stock_minimo_producto: stockMinimoProducto,
                    stock_maximo_producto: stockMaximoProducto,
                    stock_seguridad_producto: stockSeguridadProducto,
                    imagen_producto: imagenUrl,
                    usuario_que_creo_el_producto: userInfo.displayName
                })

                Swal.fire({ icon: "success", title: `Producto creado con exito` });
            } else {
                Swal.fire({ icon: "success", title: `Producto reemplazado con exito` });
            }

        } else {
            console.log(data3[0])
            Swal.fire({ icon: "error", title: `El producto que intenta crear ya existe en la bodega ${bodega}` });
        }

    }

    const [data, setData] = useState();

    const [image, setImage] = useState(null)

    const [viewImagen, setViewImagen] = useState(null)

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagen(URL.createObjectURL(event.target.files[0]));
        }
    }

    const [dataExcel, setDataExcel] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("productos").where("estadoProducto", "==", 0);
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...persistMessages,
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setData(data);
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
            setIsLastPage(totalCount < 1);
        });
    };

    const obtenerId = () => {
        const p = data.filter((item) => item.codigoProducto);
        console.log(data)
        console.log(`p ${p}`);
    }

    const [openCaja, setOpenCaja] = React.useState(false);

    const [message, setMessage] = useState();

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };


    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setDataExcel(jsonData)

                /* RECORRIDO DE LOS DATOS PROVENIENTES DE EXCEL */

                jsonData.forEach(async (obj) => {

                    /* CONSULTA DEL CODIGO Y BODEGA EN LA COLECCION DE REPORTE DE STOCK */

                    const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.CODIGO_PRODUCTO).where("bodega", "==", obj.BODEGA).get()
                    const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    if (data3[0] === undefined) {

                        console.log(`Data reporte de stock ${data3[0]}`)

                        //CONSULTA A LA COLECCION DE PRODUCTOS

                        const consultCollectionProductos = await db.collection("productos").where("codigoProducto", "==", obj.CODIGO_PRODUCTO).get()
                        const dataCollectionProducts = await consultCollectionProductos.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                        console.log(`Data Productos ${dataCollectionProducts[0]}`)

                        db.collection("reporte_stock")
                            .doc()
                            .set({
                                bodega: obj.BODEGA === '' ||
                                    obj.BODEGA === null ||
                                    obj.BODEGA === undefined ? '' : obj.BODEGA.toUpperCase(),

                                codigo_producto: obj.CODIGO_PRODUCTO === '' ||
                                    obj.CODIGO_PRODUCTO === null ||
                                    obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                                descripcion: obj.DESCRIPCION === '' ||
                                    obj.DESCRIPCION === null ||
                                    obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                                categoria: obj.SUBCATEGORIA === '' ||
                                    obj.SUBCATEGORIA === null ||
                                    obj.SUBCATEGORIA === undefined ? '' : obj.SUBCATEGORIA.toUpperCase(),

                                motivo: 'CREACION PRODUCTO',

                                tipo_movimiento: 'ingreso',

                                unidad_medida: obj.UNIDAD_MEDIDA === '' ||
                                    obj.UNIDAD_MEDIDA === null ||
                                    obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                                stock_actual: 0,

                                costo_promedio: 0,

                                total_valorizado: 0,

                                precio_compra: 0,

                                fecha_carga_producto: firebase.firestore.FieldValue.serverTimestamp(),

                                usuario_que_subio_carga_producto: userInfo.displayName,
                            })

                        //SI EL PRODUCTO NO EXISTE SE CREARA UNO NUEVO
                        //SI YA EXISTE NO PASARA NADA

                        if (dataCollectionProducts[0] === undefined) {

                            await db.collection("productos").doc(obj.CODIGO_PRODUCTO).set({

                                codigo_producto: obj.CODIGO_PRODUCTO === '' ||
                                    obj.CODIGO_PRODUCTO === null ||
                                    obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO,

                                fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),

                                descripcion_producto: obj.DESCRIPCION === '' ||
                                    obj.DESCRIPCION === null ||
                                    obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                                categoria_producto: obj.CATEGORIA === '' ||
                                    obj.CATEGORIA === null ||
                                    obj.CATEGORIA === undefined ? '' : obj.CATEGORIA.toUpperCase(),

                                subcategoria_producto: obj.SUBCATEGORIA === '' ||
                                    obj.SUBCATEGORIA === null ||
                                    obj.SUBCATEGORIA === undefined ? '' : obj.SUBCATEGORIA.toUpperCase(),

                                marca_producto: obj.MARCA === '' ||
                                    obj.MARCA === null ||
                                    obj.MARCA === undefined ? '' : obj.MARCA.toUpperCase(),

                                modelo_producto: obj.MODELO === '' ||
                                    obj.MODELO === null ||
                                    obj.MODELO === undefined ? '' : obj.MODELO.toUpperCase(),

                                unidad_Medida_Producto: obj.UNIDAD_MEDIDA === '' ||
                                    obj.UNIDAD_MEDIDA === null ||
                                    obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                                presentacion_producto: obj.PRESENTACION === '' ||
                                    obj.PRESENTACION === null ||
                                    obj.PRESENTACION === undefined ? '' : obj.PRESENTACION.toUpperCase(),

                                costo_estandar_producto: 0,
                                precio_compra_producto: 0,
                                precio_venta_producto: 0,
                                cantidad_producto: 0,
                                estado_producto: 0,
                                /* estado_producto: checked === true ? 0 : 1, */
                                stock_minimo_producto: obj.STOCK_MINIMO === '' ||
                                    obj.STOCK_MINIMO === null ||
                                    obj.STOCK_MINIMO === undefined ? 0 : obj.STOCK_MINIMO,

                                stock_maximo_producto: obj.STOCK_MAXIMO === '' ||
                                    obj.STOCK_MAXIMO === null ||
                                    obj.STOCK_MAXIMO === undefined ? 0 : obj.STOCK_MAXIMO,

                                stock_seguridad_producto: obj.STOCK_SEGURIDAD === '' ||
                                    obj.STOCK_SEGURIDAD === null ||
                                    obj.STOCK_SEGURIDAD === undefined ? 0 : obj.STOCK_SEGURIDAD,

                                usuario_que_creo_el_producto: userInfo.displayName,

                                imagen_producto: '',
                            })

                            Swal.fire({ icon: "success", title: `Producto creado con exito` });
                        } else {
                            Swal.fire({ icon: "success", title: `Producto reemplazado con exito` });
                        }

                    } else {
                        console.log(data3[0])
                        console.log('El producto que intenta crear ya existe en la bodega')
                        /* Swal.fire({ icon: "error", title: `El producto que intenta crear ya existe en la bodega ${bodega}` }); */
                    }

                });
                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [radioInventario, setRadioInventario] = useState('')

    const sendInventarioCollection = async () => {
        const newRef = useStorage.ref('imagenes_productos_inventario').child(codigoProducto).child('imagen'); // nombre del archivo
        setRef(newRef);
        await newRef.put(Imagen);
        const urlImagen = await newRef.getDownloadURL()
        imagenUrl = urlImagen;
        console.log('la url de la imagen es' + urlImagen);

        await db.collection("productos_inventario").doc(codigoProducto).set({
            codigo_producto: codigoProducto,
            descripcion_producto: descripcionProducto.toUpperCase(),
            categoria_producto: categoria,
            subcategoria_producto: SubCategoria,
            marca_producto: marcaproducto,
            modelo_producto: modeloproducto,
            unidad_Medida_Producto: unidadMedidaProducto,
            presentacion_producto: presentacionProducto,
            costo_estandar_producto: 0,
            precio_compra_producto: 0,
            precio_venta_producto: 0,
            cantidad_producto: 0,
            stock_minimo_producto: stockMinimoProducto,
            stock_maximo_producto: stockMaximoProducto,
            stock_seguridad_producto: stockSeguridadProducto,
            imagen_producto: imagenUrl,
            bodega: bodega,
            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_creo_el_producto: userInfo.displayName
        })

        Swal.fire({ icon: "success", title: `Producto creado con exito` });

        setCodigoProducto('')
        setDescripcionProducto('')
        setCategoria('')
        setSubCategoria('')
        setMarcaProducto('')
        setModeloProducto('')
        setUnidadMedidaProducto('')
        setPresentacionProducto('')

        setStockMinimoProducto('')
        setStockMaximoProducto('')
        setsStockSeguridadProducto('')

        setBodegas('')
    }

    const sendInventarioActivosFijos = async () => {
        const newRef = useStorage.ref('imagenes_productos_inventario').child(codigoProducto).child('imagen'); // nombre del archivo
        setRef(newRef);
        await newRef.put(Imagen);
        const urlImagen = await newRef.getDownloadURL()
        imagenUrl = urlImagen;
        console.log('la url de la imagen es' + urlImagen);

        await db.collection("productos_activos_fijos").doc(codigoProducto).set({
            codigo_producto: codigoProducto,
            descripcion_producto: descripcionProducto.toUpperCase(),
            marca_producto: marcaproducto,
            categoria_producto: categoria,
            subcategoria_producto: SubCategoria,
            unidad_Medida_Producto: unidadMedidaProducto,
            bodega: bodega,
            tipo: tipoDetalleActivoFijo,
            vidaUtilActivo: vidaUtilActivo,
            porcentaje_depreciacion: porcentajeDeDepreciacion,
            clases_activos: clasesDeActivos,
            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_creo_el_producto: userInfo.displayName
        })

        Swal.fire({ icon: "success", title: `Producto creado con exito` });

        setCodigoProducto('')
        setDescripcionProducto('')
        setMarcaProducto('')
        setCategoria('')
        setSubCategoria('')
        setUnidadMedidaProducto('')
        setBodegas('')
        setTipoDetalleActivoFijo('')
        setVidaUtilActivo('')
        setPorcentajeDeDepreciacion('')
        setClasesDeActivos('')
        setClasesDeActivos('')

    }

    const sendInventarioContabilidad = async () => {
        await db.collection("productos_contabilidad").doc(codigoProducto).set({
            codigo_producto: codigoProducto,
            descripcion_producto: descripcionProducto.toUpperCase(),
            categoria_producto: categoria,
            subcategoria_producto: SubCategoria,
            unidad_Medida_Producto: unidadMedidaProducto,
            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_creo_el_producto: userInfo.displayName
        })

        Swal.fire({ icon: "success", title: `Producto creado con exito` });

        setCodigoProducto('')
        setDescripcionProducto('')
        setCategoria('')
        setSubCategoria('')
        setUnidadMedidaProducto('')

    }


    return (
        <>
            <Box px={2}>
                <Card>
                    <CardContent>

                        {/* <Button
                            onClick={() => setOpenCaja(true)}
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                            color="success" size='small' variant="outlined"
                            startIcon={<UploadIcon />}>
                            <strong>CARGAR EXCEL</strong>
                        </Button> */}

                        <FormControl>
                            <FormLabel>Tipo de producto a crear</FormLabel>
                            <RadioGroup
                                row
                                value={radioInventario}
                                onChange={(e) => setRadioInventario(e.target.value)}
                            >
                                <FormControlLabel value="INVENTARIO" control={<Radio />} label="INVENTARIO" />
                                <FormControlLabel value="ACTIVO_FIJO" control={<Radio />} label="ACTIVO FIJO" />
                                <FormControlLabel value="CONTABILIDAD" control={<Radio />} label="CONTABILIDAD" />
                            </RadioGroup>
                        </FormControl>

                        <Card>
                            <CardContent>
                                <Grid container spacing={5}>

                                    {
                                        radioInventario === 'INVENTARIO' ? <>
                                            <Grid item xs={6}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO:</strong></p>

                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={codigoProducto}
                                                    onChange={(e) => handleCodigoProducto(e.target.value)}
                                                    name="codigoProducto"
                                                    size="small"
                                                    fullWidth
                                                />

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={descripcionProducto}
                                                        onChange={(e) => handleDescriptionProducto(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CATEGORIA:</strong></p>

                                                <FormControl disabled={loading} fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={categoria}
                                                        label="CATEGORIA"
                                                        onChange={(e) => changeCategoria(e.target.value)}>
                                                        {categorias &&
                                                            categorias.map(item => (
                                                                <MenuItem key={item.nombre_categoria_producto} value={item.nombre_categoria_producto} >
                                                                    {item.nombre_categoria_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loading && <LinearProgress />}
                                                </FormControl>


                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>SUBCATEGORIA:</strong></p>

                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={SubCategoria}
                                                            label="SUBCATEGORIA"
                                                            onChange={(e) => changeSubCategoria(e.target.value)}>
                                                            {subCategorias &&
                                                                subCategorias.map(item => (
                                                                    <MenuItem key={item.nombre_subcategoria_producto} value={item.nombre_subcategoria_producto} >
                                                                        {item.nombre_subcategoria_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {loadingSubCategorias && <LinearProgress />}
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>STOCK MINIMO:</strong></p>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={stockMinimoProducto}
                                                    onChange={(e) => handleStockMinimoProducto(e.target.value)}
                                                    name="descripcionProducto"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                />

                                                <Box pt={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>STOCK SEGURIDAD:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={stockSeguridadProducto}
                                                        onChange={(e) => handleStockSeguridadProducto(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                    />
                                                </Box>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>PRESENTACION:</strong></p>

                                                <FormControl disabled={loading} fullWidth>

                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={presentacionProducto}
                                                        label="PRESENTACION"
                                                        onChange={(e) => changePresentacionProducto(e.target.value)}>
                                                        {presentacionProductos &&
                                                            presentacionProductos.map(item => (
                                                                <MenuItem key={item.nombre_presentacion_producto} value={item.nombre_presentacion_producto} >
                                                                    {item.nombre_presentacion_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loading && <LinearProgress />}
                                                </FormControl>

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>MARCA:</strong></p>

                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={marcaproducto}
                                                            label="MARCA"
                                                            onChange={(e) => changeMarcaProducto(e.target.value)}>
                                                            {marcaProductos &&
                                                                marcaProductos.map(item => (
                                                                    <MenuItem key={item.nombre_marca_producto} value={item.nombre_marca_producto} >
                                                                        {item.nombre_marca_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {loadingMarcaProductos && <LinearProgress />}
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>MODELO:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={modeloproducto}
                                                        label="MODELO"
                                                        onChange={(e) => changeModeloProducto(e.target.value)}>
                                                        {modeloProductos &&
                                                            modeloProductos.map(item => (
                                                                <MenuItem key={item.nombre_modelo_producto} value={item.nombre_modelo_producto} >
                                                                    {item.nombre_modelo_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingModeloProductos && <LinearProgress />}
                                                </FormControl>

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>UNIDAD MEDIDA:</strong></p>

                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={unidadMedidaProducto}
                                                            label="PRESENTACION"
                                                            onChange={(e) => changeUnidadMedidaProducto(e.target.value)}>
                                                            {unidadMedidaProductos &&
                                                                unidadMedidaProductos.map(item => (
                                                                    <MenuItem key={item.nombre_unidad_medida_producto} value={item.nombre_unidad_medida_producto} >
                                                                        {item.nombre_unidad_medida_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {loadingPresentacionProductos && <LinearProgress />}
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>STOCK MAXIMO:</strong></p>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={stockMaximoProducto}
                                                    onChange={(e) => handleStockMaximoProducto(e.target.value)}
                                                    name="descripcionProducto"
                                                    size="small"
                                                    fullWidth
                                                    type="number"
                                                />

                                                <Box pt={4}>
                                                    <FormControl fullWidth>
                                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>BODEGA:</strong></p>
                                                        <Select
                                                            sx={{
                                                                "& legend": { display: "none" },
                                                                "& fieldset": { top: 0 },
                                                            }}
                                                            size="small"
                                                            value={bodega}
                                                            label="MOTIVO"
                                                            onChange={(e) => setBodegas(e.target.value)}
                                                        >
                                                            {bodegas &&
                                                                bodegas.map((item) => (
                                                                    <MenuItem
                                                                        key={item.nombre_bodega}
                                                                        value={item.nombre_bodega}
                                                                    >
                                                                        {item.nombre_bodega}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <Box py={4}>
                                                    <Button
                                                        onChange={changeImagen}
                                                        variant="contained"
                                                        component="label"
                                                    >
                                                        AGREGAR IMAGEN
                                                        <input
                                                            type="file"
                                                            hidden
                                                        />
                                                    </Button>
                                                </Box>

                                                <Box pb={2}>
                                                    <img width={200} src={viewImagen} />
                                                </Box>

                                            </Grid>

                                            <Button
                                                disabled={
                                                    codigoProducto === '' ||
                                                    descripcionProducto === '' ||
                                                    categoria === '' ||
                                                    SubCategoria === '' ||
                                                    marcaproducto === '' ||
                                                    modeloproducto === '' ||
                                                    unidadMedidaProducto === '' ||
                                                    presentacionProducto === '' ||
                                                    stockMinimoProducto === '' ||
                                                    stockMaximoProducto === '' ||
                                                    stockSeguridadProducto === '' ||
                                                    bodega === '' ||
                                                    viewImagen === null
                                                }
                                                onClick={() => { sendInventarioCollection() }}
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                            >
                                                {" "}
                                                CREAR PRODUCTO{" "}
                                            </Button>

                                        </> : <></>
                                    }

                                    {
                                        radioInventario === 'ACTIVO_FIJO' ? <>
                                            <Grid item xs={6}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO:</strong></p>

                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={codigoProducto}
                                                    onChange={(e) => handleCodigoProducto(e.target.value)}
                                                    name="codigoProducto"
                                                    size="small"
                                                    fullWidth
                                                />

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={descripcionProducto}
                                                        onChange={(e) => handleDescriptionProducto(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>SUBCATEGORIA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={SubCategoria}
                                                        label="SUBCATEGORIA"
                                                        onChange={(e) => changeSubCategoria(e.target.value)}>
                                                        {subCategorias &&
                                                            subCategorias.map(item => (
                                                                <MenuItem key={item.nombre_subcategoria_producto} value={item.nombre_subcategoria_producto} >
                                                                    {item.nombre_subcategoria_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingSubCategorias && <LinearProgress />}
                                                </FormControl>


                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>BODEGA:</strong></p>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            sx={{
                                                                "& legend": { display: "none" },
                                                                "& fieldset": { top: 0 },
                                                            }}
                                                            size="small"
                                                            value={bodega}
                                                            label="MOTIVO"
                                                            onChange={(e) => setBodegas(e.target.value)}
                                                        >
                                                            {bodegas &&
                                                                bodegas.map((item) => (
                                                                    <MenuItem
                                                                        key={item.nombre_bodega}
                                                                        value={item.nombre_bodega}
                                                                    >
                                                                        {item.nombre_bodega}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VIDA UTIL ACTIVO:</strong></p>

                                                <FormControl fullWidth>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={vidaUtilActivo}
                                                        onChange={(e) => setVidaUtilActivo(e.target.value)}
                                                        name="vidaUtilActivo"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </FormControl>

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CLASES DE ACTIVOS:</strong></p>

                                                    <FormControl fullWidth>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={clasesDeActivos}
                                                            onChange={(e) => setClasesDeActivos(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Box>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>MARCA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={marcaproducto}
                                                        label="MARCA"
                                                        onChange={(e) => changeMarcaProducto(e.target.value)}>
                                                        {marcaProductos &&
                                                            marcaProductos.map(item => (
                                                                <MenuItem key={item.nombre_marca_producto} value={item.nombre_marca_producto} >
                                                                    {item.nombre_marca_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingMarcaProductos && <LinearProgress />}
                                                </FormControl>

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CATEGORIA:</strong></p>

                                                    <FormControl disabled={loading} fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={categoria}
                                                            label="CATEGORIA"
                                                            onChange={(e) => changeCategoria(e.target.value)}>
                                                            {categorias &&
                                                                categorias.map(item => (
                                                                    <MenuItem key={item.nombre_categoria_producto} value={item.nombre_categoria_producto} >
                                                                        {item.nombre_categoria_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {loading && <LinearProgress />}
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>UNIDAD MEDIDA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={unidadMedidaProducto}
                                                        label="PRESENTACION"
                                                        onChange={(e) => changeUnidadMedidaProducto(e.target.value)}>
                                                        {unidadMedidaProductos &&
                                                            unidadMedidaProductos.map(item => (
                                                                <MenuItem key={item.nombre_unidad_medida_producto} value={item.nombre_unidad_medida_producto} >
                                                                    {item.nombre_unidad_medida_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingPresentacionProductos && <LinearProgress />}
                                                </FormControl>

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TIPOS (DETALLE DE ACTIVOS FIJOS):</strong></p>

                                                    <FormControl fullWidth>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            value={tipoDetalleActivoFijo}
                                                            onChange={(e) => setTipoDetalleActivoFijo(e.target.value)}
                                                            name="descripcionProducto"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </FormControl>
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>PORCENTAJE DE DEPRECIACION:</strong></p>

                                                <FormControl fullWidth>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={porcentajeDeDepreciacion}
                                                        onChange={(e) => setPorcentajeDeDepreciacion(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </FormControl>

                                            </Grid>

                                            <Button
                                                disabled={
                                                    codigoProducto === '' ||
                                                    descripcionProducto === '' ||
                                                    marcaproducto === '' ||
                                                    categoria === '' ||
                                                    SubCategoria === '' ||
                                                    unidadMedidaProducto === '' ||
                                                    bodega === '' ||
                                                    tipoDetalleActivoFijo === '' ||
                                                    vidaUtilActivo === '' ||
                                                    porcentajeDeDepreciacion === '' ||
                                                    clasesDeActivos === ''
                                                }
                                                onClick={() => { sendInventarioActivosFijos() }}
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                            >
                                                {" "}
                                                CREAR PRODUCTO{" "}
                                            </Button>
                                        </> : <></>
                                    }

                                    {
                                        radioInventario === 'CONTABILIDAD' ? <>
                                            <Grid item xs={6}>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO:</strong></p>

                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={codigoProducto}
                                                    onChange={(e) => handleCodigoProducto(e.target.value)}
                                                    name="codigoProducto"
                                                    size="small"
                                                    fullWidth
                                                />

                                                <Box py={4}>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={descripcionProducto}
                                                        onChange={(e) => handleDescriptionProducto(e.target.value)}
                                                        name="descripcionProducto"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Box>

                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>SUBCATEGORIA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={SubCategoria}
                                                        label="SUBCATEGORIA"
                                                        onChange={(e) => changeSubCategoria(e.target.value)}>
                                                        {subCategorias &&
                                                            subCategorias.map(item => (
                                                                <MenuItem key={item.nombre_subcategoria_producto} value={item.nombre_subcategoria_producto} >
                                                                    {item.nombre_subcategoria_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingSubCategorias && <LinearProgress />}
                                                </FormControl>

                                            </Grid>

                                            <Grid item xs={6}>
                                                {/* <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>MARCA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={marcaproducto}
                                                        label="MARCA"
                                                        onChange={(e) => changeMarcaProducto(e.target.value)}>
                                                        {marcaProductos &&
                                                            marcaProductos.map(item => (
                                                                <MenuItem key={item.nombre_marca_producto} value={item.nombre_marca_producto} >
                                                                    {item.nombre_marca_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loadingMarcaProductos && <LinearProgress />}
                                                </FormControl> */}


                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CATEGORIA:</strong></p>

                                                <FormControl disabled={loading} fullWidth>
                                                    <Select sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value={categoria}
                                                        label="CATEGORIA"
                                                        onChange={(e) => changeCategoria(e.target.value)}>
                                                        {categorias &&
                                                            categorias.map(item => (
                                                                <MenuItem key={item.nombre_categoria_producto} value={item.nombre_categoria_producto} >
                                                                    {item.nombre_categoria_producto}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                    {loading && <LinearProgress />}
                                                </FormControl>

                                                <Box py={4}>

                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>UNIDAD MEDIDA:</strong></p>

                                                    <FormControl fullWidth>
                                                        <Select sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value={unidadMedidaProducto}
                                                            label="PRESENTACION"
                                                            onChange={(e) => changeUnidadMedidaProducto(e.target.value)}>
                                                            {unidadMedidaProductos &&
                                                                unidadMedidaProductos.map(item => (
                                                                    <MenuItem key={item.nombre_unidad_medida_producto} value={item.nombre_unidad_medida_producto} >
                                                                        {item.nombre_unidad_medida_producto}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        {loadingPresentacionProductos && <LinearProgress />}
                                                    </FormControl>
                                                </Box>


                                            </Grid>

                                            <Button
                                                disabled={
                                                    codigoProducto === '' ||
                                                    descripcionProducto === '' ||
                                                    categoria === '' ||
                                                    SubCategoria === '' ||
                                                    unidadMedidaProducto === ''
                                                }
                                                onClick={() => { sendInventarioContabilidad() }}
                                                fullWidth
                                                variant="contained"
                                                disableElevation
                                            >
                                                {" "}
                                                CREAR PRODUCTO{" "}
                                            </Button>
                                        </> : <></>
                                    }

                                </Grid>
                            </CardContent>
                        </Card>

                        {/* <FormControlLabel
                            label="ACTIVO"
                            control={
                                <Checkbox

                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        /> */}

                    </CardContent>
                    {/* <CardActions>
                        
                        <Button
                            disabled={
                                codigoProducto === '' ||
                                descripcionProducto === '' ||
                                categoria === '' ||
                                SubCategoria === '' ||
                                marcaproducto === '' ||
                                modeloproducto === '' ||
                                unidadMedidaProducto === '' ||
                                presentacionProducto === '' ||
                                stockMinimoProducto === '' ||
                                stockMaximoProducto === '' ||
                                stockSeguridadProducto === '' ||
                                bodega === '' ||
                                viewImagen === null
                            }
                            onClick={() => { onHandleClick() }}
                            fullWidth
                            variant="contained"
                            disableElevation
                        >
                            {" "}
                            CREAR PRODUCTO{" "}
                        </Button>
                    </CardActions> */}
                </Card>

                <Dialog
                    open={openCaja}
                    onClose={() => setOpenCaja(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Subir Excel</DialogTitle>
                    <DialogContent>
                        <Box pb={2}>
                            {fileName
                                ? `Nombre el archivo cargado:  ${fileName}`
                                : "Por favor, cargar el archivo..."}
                        </Box>
                        {!file ? (
                            <>
                                <input
                                    style={{ display: "none" }}
                                    onChange={(e) => handleUploadFileLocal(e)}
                                    multiple
                                    id="buttonFile"
                                    type="file"
                                    ref={fileRef}
                                />
                                <label htmlFor="buttonFile">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        component="span"
                                    >
                                        Cargar archivo
                                    </Button>
                                </label>
                            </>
                        ) : (
                            <Box>
                                <Box display="flex">
                                    <Box pr={5}>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={(e) => handleRemove()}
                                        >
                                            Borrar archivo
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button
                                            size="small"
                                            onClick={handleUploadFile}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Subir archivo
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )}



                    </DialogContent>
                </Dialog>
            </Box>
        </>
    );
};
