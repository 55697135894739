import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, esES } from "@mui/x-data-grid";
import {
    Box, Button, Select, MenuItem,
    IconButton, Card, CardContent, Grid, LinearProgress,
    Paper, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Autocomplete
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2';
import { db } from '../../../firebase';
import DeleteIcon from "@mui/icons-material/Delete";


export const BlockFactura = () => {

    const [autorizacion, setAutorizacion] = useState('')
    const [establecimiento, setEstablecimiento] = useState('')
    const [emision, setEmision] = useState('')
    const [inicio, setInicio] = useState('')
    const [proveedor, setProveedor] = useState('')
    const [tipo, setTipo] = useState('')

    const [secInicio, setSecInicio] = useState('')
    const [secFinal, setSecFinal] = useState('')

    const [fin, setFin] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [id, setId] = useState('')

    const [categorias, setCategorias] = useState(null)
    const [open, setOpen] = useState(false)
    const [proveedores, setProveedores] = useState([])

    const [open2, setOpen2] = useState(false)

    useEffect(() => {
        cargarCategorias()
        getProveedores()
    }, [])

    // const onChange = (dates) => {
    //     const [start, end] = dates
    //     setStartDate(start)
    //     setEndDate(end)
    //     if (start) {
    //         setInicio(moment(start.toString()).format('DD/MM/YYYY'))
    //     } else {
    //         setInicio('')
    //     }
    //     if (end) {
    //         setFin(moment(end.toString()).format('DD/MM/YYYY'))
    //     } else {
    //         setFin('')
    //     }
    // }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    )

    const columns = [
        { field: 'proveedor', headerClassName: 'super-app-theme--header', headerName: 'Proveedor', flex: 3},
        { field: 'idProveedor', headerClassName: 'super-app-theme--header', headerName: 'ID Proveedor', flex: 2 },
        { field: 'numeroAutorizacion', headerClassName: 'super-app-theme--header', headerName: '# Autorización', flex: 2 },
        { field: 'establecimiento', headerClassName: 'super-app-theme--header', headerName: 'Establecimiento', flex: 2 },
        { field: 'emision', headerClassName: 'super-app-theme--header', headerName: 'Punto Emisión', flex: 2 },
        { field: 'fechaInicio', headerClassName: 'super-app-theme--header', headerName: 'Fecha Inicio', flex: 2 },
        { field: 'fechaFin', headerClassName: 'super-app-theme--header', headerName: 'Fecha Fin', flex: 2 },
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: ' ', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen(params.row)}
                >
                    <EditIcon color='info' />
                </IconButton>
            }
        },
        {
            field: 'BORRAR', headerClassName: 'super-app-theme--header', headerName: ' ', flex: 1,
            renderCell: (params) => {
                return < IconButton
                    onClick={() => handleOpen2(params.row)}
                >
                    <DeleteIcon color='error' />
                </IconButton>
            }
        },
    ];

    function cargarCategorias() {
        db.collection(`cuentas_pagar/colecciones/block`)
            .onSnapshot(snapshot => {
                const arrayMessage = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ]
                setCategorias(arrayMessage)
            })
    }

    const getProveedores = () => {
        let docRef = db.collection("proveedores")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setProveedores(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    function crearSubCategoria() {
        db.collection("cuentas_pagar/colecciones/block").doc().set({
            proveedor: proveedor.split(' -- ')[0],
            idProveedor: proveedor.split(' -- ')[1],
            numeroAutorizacion: autorizacion,
            emision: emision,
            establecimiento: establecimiento,
            fechaInicio: inicio,
            fechaFin: fin,
            secuenciaInicio: secInicio,
            secuenciaFin: secFinal,
            tipo: tipo,
        }).then(() => {
            limpiar()
            setOpen(false)
            Swal.fire({ icon: "success", text: "Se ha creado", });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
            console.error("Error adding document: ", error)
        });
    }

    function actualizarSubCategoria() {
        let inicio2 = inicio.split("/")
        let fin2 = fin.split("/")
        inicio2 = new Date(inicio2[1] + '-' + inicio2[0] + '-' + inicio2[2])
        fin2 = new Date(fin2[1] + '-' + fin2[0] + '-' + fin2[2])
        if (inicio2 >= fin2) {
            Swal.fire({ icon: "error", text: "La fecha de inicio es mayor o igual a la de fin " });
            return
        }

        if (secInicio >= secFinal) {
            Swal.fire({ icon: "error", text: "La secuencia de inicio es mayor o igual a la de fin " });
            return
        }




        if (id !== '') {
            db.collection("cuentas_pagar/colecciones/block").doc(id).update({
                // proveedor: proveedor,
                // idProveedor: proveedores.filter((val) => val.nombre_comercial === proveedor)[0].identificacion,
                numeroAutorizacion: autorizacion,
                emision: emision,
                establecimiento: establecimiento,
                fechaInicio: inicio,
                fechaFin: fin,
                secuenciaInicio: secInicio,
                secuenciaFin: secFinal,
                tipo: tipo,
            }).then(() => {
                limpiar()
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha actualizado", });
            }).catch((error) => {
                Swal.fire({ icon: "error", text: "Se ha producido un error " + error });
                console.error("Error adding document: ", error)
            });
        } else {
            crearSubCategoria()
        }
    }

    function limpiar() {
        setStartDate(null)
        setEndDate(null)
        setProveedor('')
        setId('')
        setTipo('')
        setAutorizacion('')
        setEmision('')
        setEstablecimiento('')
        setFin('')
        setSecInicio('')
        setSecFinal('')
        setInicio('')
    }

    function handleOpen(item) {
        if (item) {
            setId(item.id)
            setProveedor(item.proveedor)
            setAutorizacion(item.numeroAutorizacion)
            setEmision(item.emision)
            setTipo(item.tipo)
            setEstablecimiento(item.establecimiento)
            setFin(item.fechaFin)
            setInicio(item.fechaInicio)
            setSecInicio(item.secuenciaInicio)
            setSecFinal(item.secuenciaFin)
        }
        setOpen(true)
    }


    function handleOpen2(item) {
        if (item) {
            setId(item.id)
        }
        setOpen2(true)
    }

    function deleteProducto() {
        db.collection("cuentas_pagar/colecciones/block").doc(id).delete()
            .then(() => {
                Swal.fire({ icon: "success", text: "Se ha eliminado" })
                setOpen2(false)
                limpiar()
            })
    }


    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5"><strong>Block de Facturas</strong> </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => handleOpen()}
                                variant="contained" color='success'>
                                AGREGAR
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={2} sx={{ padding: '10px',overflowX: 'scroll'  }}>
                                <Box sx={{
                                    minWidth: 1300,
                                    height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        loading={categorias === null}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={categorias ?? []}

                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={open} fullWidth onClose={() => {
                        limpiar()
                        setOpen(false)
                    }} maxWidth="md">
                        <DialogTitle><strong>{id !== '' ? 'EDITAR:' : 'CREAR:'} BLOCK </strong></DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item md={6} lg={6} xs={12}>

                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Número de Autorización:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={autorizacion}
                                            type='number'
                                            onChange={(e) => setAutorizacion(e.target.value)}
                                            disabled={id !== ''}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Establecimiento:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={establecimiento}
                                            type='number'
                                            onChange={(e) => setEstablecimiento(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Punto de Emisión:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={emision}
                                            type='number'
                                            onChange={(e) => setEmision(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Secuencia Inicio:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={secInicio}
                                            type='number'
                                            onChange={(e) => setSecInicio(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Secuencia Fin:  *</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={secFinal}
                                            type='number'
                                            onChange={(e) => setSecFinal(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>

                                </Grid>

                                <Grid item xs={12} md={1} lg={1} />
                                <Grid item xs={12} md={5} lg={5}>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Tipo:  *</strong></Typography>
                                        <Select
                                            fullWidth
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                                fontSize: '14px'
                                            }}
                                            value={tipo}
                                            disabled={id !== ''}
                                            onChange={(e) => setTipo(e.target.value)}
                                        >
                                            <MenuItem key={'NOTA DE VENTA'} value={'NOTA DE VENTA'}>{'NOTA DE VENTA'}</MenuItem>
                                            <MenuItem key={'FACTURA'} value={'FACTURA'}>{'FACTURA'}</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: "14px" }}><strong>Proveedor:  *</strong></Typography>

                                        {id !== '' ? <>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={proveedor}
                                                disabled={true}
                                                size="small"
                                                fullWidth />
                                        </> : <>
                                            <Autocomplete
                                                fullWidth
                                                size="small"
                                                id="combo-box-demo"
                                                options={proveedores}
                                                getOptionLabel={(option) => option.nombre_comercial + ' -- ' + option.identificacion}
                                                onInputChange={(e, newInputValue) => {
                                                    setProveedor(newInputValue)
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </>}

                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: '14px' }}><strong>Fecha Inicio:  *  DD/MM/YYYY</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={inicio}
                                            onChange={(e) => setInicio(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    <Box py={1}>
                                        <Typography sx={{ fontSize: '14px' }}><strong>Fecha Fin:  *  DD/MM/YYYY</strong></Typography>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={fin}
                                            onChange={(e) => setFin(e.target.value)}
                                            size="small"
                                            fullWidth />
                                    </Box>
                                    {/* <Box py={1} >
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            inline
                                        />
                                    </Box> */}
                                    <Box py={1}>
                                        <Button
                                            disabled={
                                                secFinal.toString().length != 9 ||
                                                secInicio.toString().length != 9 ||
                                                autorizacion.toString().length != 10 ||
                                                establecimiento.toString().length != 3 ||
                                                emision.toString().length != 3 ||
                                                tipo === '' ||
                                                !(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/).test(inicio) ||
                                                proveedor === '' ||
                                                !(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/).test(fin)}
                                            onClick={() => actualizarSubCategoria()}
                                            fullWidth variant="contained" color='success'>
                                            {id !== '' ? 'ACTUALIZAR' : 'CREAR'}
                                        </Button>
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    
                                </Grid> */}

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                limpiar()
                                setOpen(false)
                            }} variant="contained"><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                </CardContent>
            </Card>

            <Dialog open={open2} fullWidth onClose={() => {
                limpiar()
                setOpen2(false)
            }} maxWidth="xs">
                <DialogTitle>
                    ELIMINAR
                </DialogTitle>
                <DialogContent dividers>
                    ESTA SEGURO DE ELIMINAR
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen2(false)
                    }}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => deleteProducto()}
                        variant="contained"
                        color="error"
                    >
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
