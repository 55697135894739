import { Button, Paper, Chip, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

import moment from 'moment'
import { UpdateTurno } from "./UpdateTurno";
import { DeleteTurno } from "./DeleteTurno";

export const ListTurnos = () => {

    const [data, setData] = useState();

    const [total, setTotal] = useState(0);

    const ListCategories = () => {
        let ref = db.collection('tickets_parametros').doc('turnos').collection('list');
        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setData(data);
        });
    }

    const dispatch = useDispatch()
    useEffect(() => {
        ListCategories()
    }, [dispatch])

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'turno', headerName: 'TURNOS', width: 250 },
        {
            field: 'fecha_creacion_turno', headerName: 'FECHA DE CREACION', type: 'dateTime', width: 180,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY'),
        },
        {
            field: '1', headerName: 'EDITAR', width: 90,
            renderCell: (params) => {
                return <UpdateTurno props={params.row} />
            }
        },
        {
            field: '2', headerName: 'ELIMINAR', width: 100,
            renderCell: (params) => {
                return <DeleteTurno props={params.row} />
            }
        },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    return (
        <>

            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2} sx={{ height: 800, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            // disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                            //hideFooterPagination
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}

                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

            {/* <TableContainer sx={{ maxHeight: 740 }} >
                <Table size="small" stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell>ID</TableCell>
                            <TableCell>DEPARTAMENTO</TableCell>
                            <TableCell>ACTUALIZAR</TableCell>
                            <TableCell>ELIMINAR</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.map((row, key) => (
                            <TableRow key={key}>

                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.departamento}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                </TableCell>
                                <TableCell component="th" scope="row">
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
        </>
    );
};