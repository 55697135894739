import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
export const ListSIG = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userInfo && userInfo.departamento === 1 || userInfo.rol === 0   ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon >
                        <AdminPanelSettingsIcon  />
                    </ListItemIcon>
                    <ListItemText primary="SIG" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton 
                            component={RouterLink} 
                            to={`sig/dashboard`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}