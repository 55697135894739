export const listSolicitudesReducers = (state = { loading: false, solicitudes: [] }, action) => {
    switch (action.type) {
        case 'SOLICITUD_LIST_REQUEST':
            return { ...state, loading: true }
        case 'SOLICITUD_LIST_SUCCESS':
            return { ...state, loading: false, solicitudes: action.payload, queryDoc: action.queryDoc, isLastPage: action.isLastPage  }
        case 'SOLICITUD_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}
export const uploadFileReducers = (state = { file: [] }, action) => {
    switch (action.type) {
        case 'FILE_UPLOAD_REQUEST':
            return { loading: true }
        case 'FILE_UPLOAD_SUCCESS':
            return { loading: false, file: action.payload }
        case 'FILE_UPLOAD_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}