import React from "react";
import {  Chip } from '@mui/material';
export const Estado = ({ estado }) => {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado == 0) {
        name = 'ESPERANDO'
        color = 'warning'
    } else if (estado === 1) {
        name = 'NO FACTIBLE'
        color = 'info'
    } else if (estado === 2) {
        name = 'FACTIBILIDAD'
        color = 'success'
    }
    return (
        <Chip label={name} color={color} />
    )
}
