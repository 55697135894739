import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    MenuItem,
    Select,
    FormControl,
    Box
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db, firebase } from "../../../firebase";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import moment from 'moment';

const parseDatePickerDate = (selectedDate) => {
    const parsedDate = moment(selectedDate); // Parsea la fecha utilizando Moment.js
    
    const formattedDate = parsedDate.format('DD/MM/YYYY'); // Formatea la fecha como 'DD/MM/YYYY' (puedes ajustar el formato según tus necesidades)
    
    return formattedDate; // Devuelve la fecha formateada
  };

export const ChangeStatePuerto = ({ props, idCaja }) => {

    const [fechaUltimoPago, setFechaUltimoPago] = useState('')

    const [openDialogUsarPuerto, setOpenDialogUsarPuerto] = useState(null);

    const [openUsarPuerto, setOpenUsarPuerto] = useState(null);

    const [openLiberarPuerto, setOpenLiberarPuerto] = useState(null);

    const [openConfirmUpdateState, setOpenConfirmUpdateState] = useState(null);

    const [nombreCliente, setNombreCliente] = useState('');

    const [cedulaCliente, setCedulaCliente] = useState('');

    const [estado, setEstado] = useState('');

    const usarPuerto = () => {
        db.collection('cajas').doc(idCaja).collection('puertos').doc(props.id).update({
            usado: 1,
            puerto_de_usuario: nombreCliente,
            estado: estado,
            fecha_actualizacion: firebase.firestore.FieldValue.serverTimestamp(),
            ultima_fecha_pago: parseDatePickerDate(fechaUltimoPago)
            /* cedula_usuario: cedulaCliente */
        })
        setOpenConfirmUpdateState(true)
        setOpenUsarPuerto(false)
    }

    const liberarPuerto = () => {
        db.collection('cajas').doc(idCaja).collection('puertos').doc(props.id).update({
            usado: 0,
            puerto_de_usuario: '',
            /* cedula_usuario: '' */
        })
        setOpenConfirmUpdateState(true)
        setOpenLiberarPuerto(false)
    }

    const openDialogDigitarNombrePuertoFunction = () => {
        setOpenUsarPuerto(true)
        setOpenDialogUsarPuerto(false)
    }

    const closeDialogDigitarNombrePuertoFunction = () => {
        setOpenDialogUsarPuerto(true)
        setOpenUsarPuerto(false)
        /* setOpenUsarPuerto(true) */
    }

    return (
        <>
            {
                props.usado === 0 ? <>
                    <Tooltip title="USAR PUERTO">
                        <Button onClick={() => setOpenDialogUsarPuerto(true)/* setOpenUsarPuerto(true) */}>
                            <ToggleOnIcon color="error" />
                        </Button>
                    </Tooltip>
                    <Dialog open={openUsarPuerto} fullWidth onClose={() => setOpenUsarPuerto(false)} maxWidth="xs">

                        <DialogTitle>
                            ESTA SEGURO DE HACER USO DEL PUERTO <strong>{props.puerto}</strong>
                        </DialogTitle>

                        <DialogContent>

                        </DialogContent>

                        <DialogActions>
                            <Button size="small" color="secondary" variant="outlined" onClick={() => closeDialogDigitarNombrePuertoFunction()/* setOpenUsarPuerto(false) */}><strong>REGRESAR</strong></Button>
                            <Button size="small" color="error" variant="contained" onClick={() => usarPuerto()}><strong>CONFIRMAR</strong></Button>
                        </DialogActions>
                    </Dialog>
                </>
                    :

                    <>
                        <Tooltip title="LIBERAR PUERTO">
                            <Button onClick={() => setOpenLiberarPuerto(true)}>
                                <ToggleOnIcon color="success" />
                            </Button>
                        </Tooltip>
                        <Dialog open={openLiberarPuerto} fullWidth onClose={() => setOpenLiberarPuerto(false)} maxWidth="xs">

                            <DialogTitle>
                                ESTA SEGURO DE LIBERAR EL PUERTO <strong>{props.puerto}</strong>
                            </DialogTitle>

                            <DialogContent>

                            </DialogContent>

                            <DialogActions>
                                <Button size="small" color="secondary" variant="outlined" onClick={() => setOpenLiberarPuerto(false)}><strong>Regresar</strong></Button>
                                <Button size="small" color="success" variant="contained" onClick={() => liberarPuerto()}><strong>CONFIRMAR</strong></Button>
                            </DialogActions>
                        </Dialog>
                    </>
            }

            <Dialog open={openConfirmUpdateState} fullWidth onClose={() => setOpenConfirmUpdateState(false)} maxWidth="xs">

                <DialogTitle>
                    PUERTO ACTUALIZADO CON EXITO
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="contained" onClick={() => setOpenConfirmUpdateState(false)}><strong>REGRESAR</strong></Button>
                </DialogActions>
            </Dialog>

            {/* OPEN DIALOG USAR PUERTO */}

            <Dialog open={openDialogUsarPuerto} fullWidth onClose={() => setOpenDialogUsarPuerto(false)} maxWidth="xs">

                <DialogTitle>
                    DIGITE EL NOMBRE DEL CLIENTE AL CUAL LE ASIGNARA EL PUERTO
                </DialogTitle>

                <DialogContent>

                    <p style={{ fontSize: "11px", marginBottom: "4px" }}><strong>NOMBRE DEL CLIENTE:</strong></p>
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={nombreCliente}
                        onChange={(e) => setNombreCliente(e.target.value)}
                        name="nombreCliente"
                        size="small"
                        fullWidth
                    />

                    <Box pt={2}>
                        <p style={{ fontSize: "11px", marginBottom: "4px" }}><strong>ESTADO:</strong></p>
                        <FormControl fullWidth>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                label="Motivo del rechazo"
                                size="small"
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                            >
                                <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                <MenuItem value="SUSPENDIDO">SUSPENDIDO</MenuItem>

                            </Select>
                        </FormControl>
                    </Box>

                    <Box pt={2}>
                        <p style={{ fontSize: "11px", marginBottom: "4px" }}><strong>ULTIMO PAGO:</strong></p>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }}
                                renderInput={(props) => <TextField sx={{
                                    "& legend": { display: "none" },
                                    "& fieldset": { top: 0 },
                                }} fullWidth {...props} size='small' />}
                                inputFormat="dd/MM/yyyy"
                                value={fechaUltimoPago}
                                onChange={(newValue) => {
                                    setFechaUltimoPago(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="contained" onClick={() => setOpenDialogUsarPuerto(false)}><strong>REGRESAR</strong></Button>
                    <Button disabled={nombreCliente === '' || estado === '' || fechaUltimoPago === ''} size="small" color="success" variant="contained" onClick={() => openDialogDigitarNombrePuertoFunction()}><strong>ASIGNAR PUERTO</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    );
};