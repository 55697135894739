import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    Card,
    CardContent,
    Grid,
    Collapse,
    Typography,
    Stack,
    CircularProgress,
    Container,
    Chip,
    Dialog,
    DialogTitle,
} from "@mui/material";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {
    esES, DataGrid, GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarFilterButton,
    GridToolbarQuickFilter
} from '@mui/x-data-grid';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import moment from 'moment';
import { ViewPdfClient } from '../../components/clientes/ViewPdfClient';

export const UsuariosServicios = () => {

    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);

    const [open2, setOpen2] = useState(false);

    const [data2, setData2] = useState([]);

    const [filtroEstado, setFiltroEstado] = useState("ACTIVO");
    const [filtroFecha, setFiltroFecha] = useState("FECHA");
    const current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - (7 * 86400000)))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

    const exporter = React.createRef(null);

    useEffect(() => {
        loadInfo()
    }, []);


    useEffect(() => {
        if (filtroFecha === "NINGUNO") {
            setOpen(false)
            setData2(data)
        } else {
            filtrar(data)
        }
    }, [filtroFecha]);


    const filtrar = (datos) => {
        const reData = [
            ...datos.filter((item) => {
                if (item.instalado) {
                    let fechas = item.instalado.split(";")
                    let condicion = false
                    fechas.map((dat) => {
                        let fecha = new Date(dat)
                        if (startDate <= fecha && endDate >= fecha) condicion = true
                    })
                    if (condicion) {
                        return item
                    }
                }
            })
        ]
        setData2(reData)
    }


    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };


    async function loadInfo() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        await fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispSuspendidos/?todos2=`, requestOptions)
            .then(response => response.text())
            .then(async(result) => {
                const redata = JSON.parse(result)
                redata.reverse()
                await fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?countFactsClientes`,
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    let re = JSON.parse(result)
                    re.reverse()
                    let fullData = []
                    redata.forEach((data)=>{
                        let servicioPagado = 0
                        let servicioNoPagado = 0
                        let servicioAnulado = 0
                        let librePagado = 0
                        let libreNoPagado = 0
                        let libreAnulado = 0
                        let sumFacturas = 0
                        let fact = re.filter((factura)=> factura.idcliente == data.id)
                        fact.forEach((detalle)=>{
                            sumFacturas += Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 2 && detalle.estado === 'pagado') 
                                servicioPagado = Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 2 && detalle.estado === 'No pagado') 
                                servicioNoPagado = Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 2 && detalle.estado === 'anulado') 
                                servicioAnulado = Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 1 && detalle.estado === 'pagado') 
                                librePagado = Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 1 && detalle.estado === 'No pagado') 
                                libreNoPagado = Number(detalle.numero_de_facturas)
                            if(Number(detalle.tipo) === 1 && detalle.estado === 'anulado') 
                                libreAnulado = Number(detalle.numero_de_facturas)
                        })
                        let client = {
                            ...data,
                            servicioPagado: servicioPagado,
                            servicioNoPagado: servicioNoPagado,
                            servicioAnulado: servicioAnulado,
                            librePagado: librePagado,
                            libreNoPagado: libreNoPagado,
                            libreAnulado: libreAnulado,
                            sumFacturas: sumFacturas
                        }
                        fullData.push(client)
                    })
                    setData(fullData)
                    filtrar(fullData)
                }).catch(error => console.log('error', error));
            }).catch(error => console.log('error', error));
    }


    const columns = [
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'CEDULA', width: 120 },
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'NOMBRE', width: 300 },
        { field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'ESTADO', width: 150 },
        {
            field: 'instalado', headerClassName: 'super-app-theme--header',
            headerName: 'INSTALADO', width: 120,
            renderCell: (params) => {
                return <>
                    {params.row.instalado ?
                        params.row.instalado.split(';').map((item) => (
                            <>{item}<br /></>
                        )) : ''}
                </>

            }
        },
        {
            field: 'NUM_CONTRATO', headerClassName: 'super-app-theme--header',
            headerName: 'CONTRATO', width: 200
        },
        {
            field:'servicioPagado', headerClassName: 'super-app-theme--header', headerName: 'Servicio Pagadas',
            flex: '1', minWidth:200,
        },
        {
            field:'servicioNoPagado', headerClassName: 'super-app-theme--header', headerName: 'Servicio No Pagadas',
            flex: '1', minWidth:200,
        },
        {
            field:'servicioAnulado', headerClassName: 'super-app-theme--header', headerName: 'Servicio Anulado',
            flex: '1', minWidth:200,
        },
        {
            field:'librePagado', headerClassName: 'super-app-theme--header', headerName: 'Libre Pagadas',
            flex: '1', minWidth:200,
        },
        {
            field:'libreNoPagado', headerClassName: 'super-app-theme--header', headerName: 'Libre No Pagadas',
            flex: '1', minWidth:200,
        },
        {
            field:'libreAnulado', headerClassName: 'super-app-theme--header', headerName: 'Libre Anuladas',
            flex: '1', minWidth:200,
        },
        {
            field:'sumFacturas', headerClassName: 'super-app-theme--header', headerName: 'Facturas Totales',
            flex: '1', minWidth:200,
        },
        {
            field: 'ip', headerClassName: 'super-app-theme--header', headerName: 'IP', width: 150,
            renderCell: (params) => {
                return <Stack>
                    {params.row.ip ?
                        params.row.ip.split(';').map((item) => (
                            <>
                                {item !== "---" ?
                                    <a href={'http://' + item}
                                        target="_blank">{item}</a>
                                    : <a>{item}</a>
                                }
                            </>
                        )) : ''}
                </Stack>
            }
        },
        { field: 'DATOS_GPON', headerClassName: 'super-app-theme--header', headerName: 'DATOS GPON', width: 200 },
        {
            field: 'nodo', headerClassName: 'super-app-theme--header', headerName: 'CCR', width: 200,
            renderCell: (params) => {
                return <>
                    {params.row.nodo ? params.row.nodo.split(';').map((item) => (
                        <>{item}<br /></>
                    )) : ''}
                </>
            }
        },
        {
            field: 'direccion', headerClassName: 'super-app-theme--header',
            headerName: 'DIRECCION SERVICIO', width: 500,
            renderCell: (params) => {
                return <>
                    {params.row.direccion ? params.row.direccion.split(';').map((item) => (
                        <>{item}<br /></>
                    )) : ''}
                </>

            }
        },
        { field: 'codigo_pago', headerClassName: 'super-app-theme--header', headerName: 'CODIGO PAGO', width: 200 },
        { field: 'ROUTER_MAC', headerClassName: 'super-app-theme--header', headerName: 'ROUTER MAC', width: 200 },
        {
            field: 'contrato', headerClassName: 'super-app-theme--header', headerName: 'Contrato', width: 200,
            renderCell: (params) => {
                return <>
                    <ViewPdfClient props={{ numContrato: params.row.NUM_CONTRATO }} />
                </>
            }
        }
    ]

    const handleExport = () => {
        if (exporter.current) {
            setOpen2(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setOpen2(false)
    };

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );

    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}>
                            <strong>LISTA DE USUARIOS ({data.length})</strong></p>
                    </Box>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Card style={{ backgroundColor: 'Green', color: 'white' }}>
                                    <CardContent>
                                        <Typography variant="h5"><strong>Clientes Activos</strong> </Typography>
                                        <Typography variant="h6"><strong>
                                            {data.filter((val) => val.estado === 'ACTIVO').length}
                                        </strong></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card style={{ backgroundColor: 'DarkOrange', color: 'white' }}>
                                    <CardContent>
                                        <Typography variant="h5"><strong>
                                            Clientes Suspendidos</strong> </Typography>
                                        <Typography variant="h6"><strong>
                                            {data.filter((val) => val.estado === 'SUSPENDIDO').length}
                                        </strong></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card style={{ backgroundColor: 'Gray', color: 'white' }}>
                                    <CardContent>
                                        <Typography variant="h5"><strong>
                                            Clientes Retirados</strong> </Typography>
                                        <Typography variant="h6"><strong>
                                            {data.filter((val) => val.estado === 'RETIRADO').length}
                                        </strong></Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                    <CardContent>
                        <Card>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                                    <FormControl fullWidth>
                                        <Select
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={filtroEstado}
                                            onChange={(e) => setFiltroEstado(e.target.value)}
                                        >
                                            <MenuItem key={0} value="TODOS">TODOS</MenuItem>
                                            <MenuItem key={1} value="RETIRADO">RETIRADO</MenuItem>
                                            <MenuItem key={2} value="ACTIVO">ACTIVO</MenuItem>
                                            <MenuItem key={3} value="SUSPENDIDO">SUSPENDIDO</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}><strong>FILTRO:</strong></p>
                                    <FormControl fullWidth>
                                        <Select
                                            size="small"
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={filtroFecha}
                                            onChange={(e) => setFiltroFecha(e.target.value)}
                                        >
                                            <MenuItem key={0} value="NINGUNO">NINGUNO</MenuItem>
                                            <MenuItem key={1} value="FECHA">FECHA</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={12} >
                                    <Box display="flex" style={{ textTransform: "uppercase" }}>
                                        <Typography variant="button">
                                            <strong>Desde</strong>{" "}
                                            {startDate && moment(startDate.toString()).format("DD/MM/yyyy")}
                                        </Typography>
                                        <Typography variant="button" style={{ paddingLeft: 10 }}>
                                            <strong>Hasta</strong>{" "}
                                            {endDate && moment(endDate.toString()).format("DD/MM/yyyy")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box display="flex">
                                        <Button onClick={() => setOpen(!open)} disabled={filtroFecha === "NINGUNO"}>
                                            Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}
                                        </Button>
                                    </Box>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <DatePicker
                                            selected={startDate}
                                            startDate={startDate}
                                            onChange={onChange}
                                            endDate={endDate}
                                            selectsRange
                                            inline
                                        />
                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => {
                                                setOpen(false)
                                                filtrar(data)
                                            }}
                                        >
                                            Aplicar busqueda
                                        </Button>
                                    </Collapse>
                                </Grid>
                            </Grid>


                            <Stack direction="row" spacing={2}>
                                <Button onClick={handleExport} size="small" color="success"
                                    variant="outlined" startIcon={<SimCardDownloadIcon />}
                                >
                                    {" "}
                                    EXCEL-{filtroEstado} {filtroFecha !== "NINGUNO" && <>
                                        {startDate &&
                                            moment(startDate.toString()).format("DD/MM/YYYY")} - {endDate &&
                                                moment(endDate.toString()).format("DD/MM/YYYY")}
                                    </>}
                                </Button>
                                <Chip color="info" style={{ fontSize: "16px", fontWeight: "bold" }} label={
                                    "USUARIOS FILTRADOS: " + data2.filter((val) => {
                                        if (filtroEstado == "TODOS" ||
                                            (val.estado ? val.estado.includes(filtroEstado) : false)) {
                                            return val
                                        }
                                    }).length} />
                            </Stack>

                            <CardContent>
                                <Box sx={{
                                    height: 600, width: '100%', '& .super-app-theme--header': {
                                        backgroundColor: '#672780',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    },
                                }}>
                                    {data2.length > 0 ? <DataGrid
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        getRowId={(row) => data2.indexOf(row)}
                                        density='compact'
                                        rows={data2 ? data2.filter((val) => {
                                            if (filtroEstado == "TODOS" ||
                                                (val.estado ? val.estado.includes(filtroEstado) : false)) {
                                                return val
                                            }
                                        }) : []}

                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                        getRowHeight={() => 'auto'}
                                    /> :
                                        <Stack spacing={2} style={{
                                            alignItems: 'center',
                                            justifyContent: 'center', marginTop: '200px'
                                        }}>
                                            <CircularProgress align="center" size={50} />
                                            <strong><p align="center" style={{ fontSize: '14px' }}>
                                                CARGANDO...</p></strong>
                                        </Stack>
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box >


            <ExcelExport onExportComplete={handleExport2} data={data2 ? data2.filter((val) => {
                if (filtroEstado == "TODOS" ||
                    (val.estado ? val.estado.includes(filtroEstado) : false)) {
                    return val
                }
            }) : []} fileName={"Usuarios " + filtroEstado +
                (filtroFecha !== "NINGUNO" && startDate && endDate ?
                    ": " + moment(startDate.toString()).format("DD/MM/YYYY") +
                    " - " + moment(endDate.toString()).format("DD/MM/YYYY") : '')
                + ".xlsx"} ref={exporter} filterable={true} >
                <ExcelExportColumn field="id" title="ID"
                    headerCellOptions={{ background: "#672780" }} width={100} />
                <ExcelExportColumn field="cedula" title="CEDULA"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="nombre" title="NOMBRE"
                    headerCellOptions={{ background: "#672780" }} width={250} />
                <ExcelExportColumn field="estado" title="ESTADO"
                    headerCellOptions={{ background: "#672780" }} width={100} />
                <ExcelExportColumn field="codigo_pago" title="CODIGO PAGO"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="NUM_CONTRATO" title="CONTRATO"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="servicioPagado" title="Servicio Pagadas"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="servicioNoPagado" title="Servicio No Pagadas"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="servicioAnulado" title="Servicio Anulado"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="librePagado" title="Libre Pagadas"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="libreNoPagado" title="Libre No Pagadas"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="libreAnulado" title="Libre Anuladas"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="sumFacturas" title="Facturas Totales"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="instalado" title="INSTALADO"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="ip" title="IP"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="DATOS_GPON" title="DATOS GPON"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="nodo" title="CCR"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="correo" title="CORREO"
                    headerCellOptions={{ background: "#672780" }} width={250} />
                <ExcelExportColumn field="direccion_principal" title="DIRECCION"
                    headerCellOptions={{ background: "#672780" }} width={250} />
                <ExcelExportColumn field="telefono" title="TELEFONO"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="movil" title="MOVIL"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="ROUTER_MAC" title="ROUTER MAC"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="ROUTER_MODELO" title="ROUTER MODELO"
                    headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="direccion" title="DIRECCION SERVICIO"
                    headerCellOptions={{ background: "#672780" }} width={250} />

            </ExcelExport>

            <Dialog open={open2} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>DESCARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    )
}
