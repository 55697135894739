import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { db, firebase } from "../../../../firebase";

import Swal from 'sweetalert2'

import {
    listFlotas
} from "../../../../redux/actions/flotas/flotasActions";

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const ViewTicketReasignacion = ({ props }) => {

    const [observacionExtraPyl, setObservacionExtraPyl] = useState('');

    const [flotasInternasList, setFlotasInternasList] = useState([]);
    const [flotaInterna, setFlotaInterna] = useState('');

    const getFlotas = () => {
        const ref = db.collection("flotas").orderBy("numeroFlota", "asc")
        /* .where("unidad_interna", "==", true) */
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setFlotasInternasList(data)
        })
    }

    const [fechaVisitaAgenda, setFechaVisitaAgenda] = useState(null);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listFlotas())
        getFlotas()
        /* leerCodigo() */
    }, [dispatch]);

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [requiereMateriales, setRequiereMateriales] = useState('')

    const [observacionMateriales, setObservacionMateriales] = useState('')

    const [numTicket, setNumTicket] = useState(props.uid_ticket)

    const [linea, setLinea] = useState(props.linea_contrato)

    const [cedula, setCedula] = useState(props.cedula)

    const [ticketsDepartamento, setTicketsDepartamento] = useState(props.departamento);

    const [ticketsOficina, setTicketsOficinas] = useState(props.agendado_desde);

    const [ticketsAsunto, setTicketsAsunto] = useState(props.asunto_del_ticket);

    const [ticketsTurno, setTicketsTurnos] = useState(props.turno);

    const [nombreSolicitante, setNombreSolicitante] = useState(props.nombre_cliente)

    const [detallesTicket, setDetallesTicket] = useState(props.detalles_del_ticket)

    const fechaFirestoreVisita = props.fecha_sugerida_visita;
    const fechaDateVisita = fechaFirestoreVisita.toDate();

    const [fechaVisita, setFechaVisita] = useState(fechaDateVisita)

    const [openModal, setOpenModal] = useState(false)

    const [openModal2, setOpenModal2] = useState(false)

    const [viewImagen, setViewImagen] = useState('')

    const [modalConfirmAprobacion, setModalConfirmAprobacion] = useState(false)

    const [modalConfirmRechazo, setModalConfirmRechazo] = useState(false)

    const [modalConfirmFlota, setModalConfirmFlota] = useState(false)

    const openModalConfirmar = () => {
        setModalConfirmAprobacion(true)
        setOpenModal(false)
    }

    const closeModalConfirmar = () => {
        setOpenModal(true)
        setModalConfirmAprobacion(false)
    }

    const openModalrechazo = () => {
        setModalConfirmRechazo(true)
        setOpenModal(false)
    }

    const closeModalrechazo = () => {
        setOpenModal(true)
        setModalConfirmRechazo(false)
    }

    const openConfirmaFlota = () => {
        setModalConfirmFlota(true)
        setModalConfirmAprobacion(false)
    }

    const closeConfirmaFlota = () => {
        setModalConfirmAprobacion(true)
        setModalConfirmFlota(false)
    }
    const [modalClienteYaNoDeseaLaVisita, setModalClienteYaNoDeseaLaVisita] = useState(false)

    /* const confirmAprobacion = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 1, //1 = ABIERTO
            usuario_que_asigno_flota: userInfo.displayName,
            fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
            flota: flota,
            lider_flota: lider,
            auxiliar_flota: auxiliar,
            correo_flota: correoFlota,
            tipo: 1,
            fecha_para_visita_operaciones: fechaVisitaAgenda,
            requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
            visita_realizada: 0,
            observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales
        })

        if (requiereMateriales === 'SI') {

            db.collection('tickets_tecnicos').doc(props.id).set({
                created: props.created,
                numero_ticket: props.numero_ticket,
                uid_ticket: props.uid_ticket,
                nombre_cliente: props.nombre_cliente,
                linea_contrato: props.linea_contrato,
                agendado_desde: props.agendado_desde,
                departamento: props.departamento,
                asunto_del_ticket: props.asunto_del_ticket,
                turno: props.turno,
                detalles_del_ticket: props.detalles_del_ticket,
                imagen: props.imagen,
                estado: 1,
                cedula: props.cedula,
                usuario_generador_ticket: props.usuario_generador_ticket,
                usuario_que_asigno_flota: userInfo.displayName,
                flota: flota,
                lider_flota: lider,
                auxiliar_flota: auxiliar,
                fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
                correo_flota: correoFlota,
                valor_a_cobrar: props.valor_a_cobrar,
                telefono: props.telefono,
                celular: props.celular,
                correo: props.correo,
                direccion: props.direccion,
                tipo: 1,
                fecha_para_visita_operaciones: fechaVisitaAgenda,
                numContrato: props.numContrato,
                planNombre: props.planNombre,
                requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
                visita_realizada: 0,
                observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales
            })

            db.collection('solicitudes_materiales_instalaciones').doc(props.id).set({
                created: props.created,
                numero_ticket: props.numero_ticket,
                uid_ticket: props.uid_ticket,
                nombres: props.nombre_cliente,
                linea_contrato: props.linea_contrato,
                agendado_desde: props.agendado_desde,
                departamento: props.departamento,
                asunto_del_ticket: props.asunto_del_ticket,
                turno: props.turno,
                detalles_del_ticket: props.detalles_del_ticket,
                imagen: props.imagen,
                estado: 0,
                cedula: props.cedula,
                usuario_generador_ticket: props.usuario_generador_ticket,
                usuario_que_asigno_flota: userInfo.displayName,
                numFlota: flota,
                nombreLider: lider,
                nombreAuxiliar: auxiliar,
                fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
                correo_flota: correoFlota,
                valor_a_cobrar: props.valor_a_cobrar,
                estado_pagado: 0,
                telefono: props.telefono,
                celular: props.celular,
                correo: props.correo,
                direccion: props.direccion,
                tipo: 1,
                fecha_para_visita_operaciones: fechaVisitaAgenda,
                numContrato: props.numContrato,
                planNombre: props.planNombre,
                requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
                visita_realizada: 0,
                observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales
            })

        } else {

            console.log('No requiere materiales, no se crea en la coleccion de solicitudes de materiales')

            db.collection('tickets_tecnicos').doc(props.id).set({
                created: props.created,
                numero_ticket: props.numero_ticket,
                uid_ticket: props.uid_ticket,
                nombre_cliente: props.nombre_cliente,
                linea_contrato: props.linea_contrato,
                agendado_desde: props.agendado_desde,
                departamento: props.departamento,
                asunto_del_ticket: props.asunto_del_ticket,
                turno: props.turno,
                detalles_del_ticket: props.detalles_del_ticket,
                imagen: props.imagen,
                estado: 0,
                cedula: props.cedula,
                usuario_generador_ticket: props.usuario_generador_ticket,
                usuario_que_asigno_flota: userInfo.displayName,
                flota: flota,
                lider_flota: lider,
                auxiliar_flota: auxiliar,
                fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
                correo_flota: correoFlota,
                valor_a_cobrar: props.valor_a_cobrar,
                telefono: props.telefono,
                celular: props.celular,
                correo: props.correo,
                direccion: props.direccion,
                tipo: 1,
                fecha_para_visita_operaciones: fechaVisitaAgenda,
                numContrato: props.numContrato,
                planNombre: props.planNombre,
                requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
                visita_realizada: 0,
                observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales
            })
        }

        Swal.fire({ icon: "success", text: 'Se ha aprobado y enviado a la flota el ticket con exito' });
        setModalConfirmFlota(false)
    } */

    const confirmAprobacion = () => {

        db.collection('tickets').doc(props.id).update({
            estado: 1, //1 = ABIERTO
            usuario_que_asigno_flota: userInfo.displayName,
            fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
            flota: flota,
            lider_flota: lider,
            auxiliar_flota: auxiliar,
            correo_flota: correoFlota,
            tipo: 1,
            fecha_para_visita_operaciones: fechaVisitaAgenda,
            requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
            visita_realizada: 0,
            observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales,
            reagendado: true,
            observacion_reagendamiento: observacionExtraPyl === '' ? 'NINGUNA' : observacionExtraPyl.toUpperCase()
        })

        db.collection('tickets_tecnicos').doc(props.id).set({
            created: props.created,
            numero_ticket: props.numero_ticket,
            uid_ticket: props.uid_ticket,
            nombre_cliente: props.nombre_cliente,
            linea_contrato: props.linea_contrato,
            agendado_desde: props.agendado_desde,
            departamento: props.departamento,
            asunto_del_ticket: props.asunto_del_ticket,
            turno: props.turno,
            detalles_del_ticket: props.detalles_del_ticket,
            imagen: props.imagen,
            estado: 0,
            cedula: props.cedula,
            usuario_generador_ticket: props.usuario_generador_ticket,
            usuario_que_asigno_flota: userInfo.displayName,
            flota: flota,
            lider_flota: lider,
            auxiliar_flota: auxiliar,
            fecha_gestion_ticket_operaciones: firebase.firestore.FieldValue.serverTimestamp(),
            correo_flota: correoFlota,
            valor_a_cobrar: props.valor_a_cobrar,
            telefono: props.telefono,
            celular: props.celular,
            correo: props.correo,
            direccion: props.direccion,
            tipo: 1,
            fecha_para_visita_operaciones: fechaVisitaAgenda,
            numContrato: props.numContrato,
            planNombre: props.planNombre,
            requiere_materiales: requiereMateriales === 'SI' ? 0 : 1,
            visita_realizada: 0,
            observacion_materiales: observacionMateriales === '' ? 'NO SE REQUIEREN MATERIALES' : observacionMateriales,
            reagendado: true,
            observacion_reagendamiento: observacionExtraPyl === '' ? 'NINGUNA' : observacionExtraPyl.toUpperCase()
        })

        Swal.fire({ icon: "success", text: 'Se ha aprobado y enviado a la flota el ticket con exito' });
        setModalConfirmFlota(false)
    }

    const confirmRechazo = () => {
        db.collection('tickets').doc(props.id).update({
            estado: 1,
            usuario_que_rechazo: userInfo.displayName,
            fecha_hora_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        Swal.fire({ icon: "success", text: 'Se ha rechazado el ticket con exito' });
        modalConfirmAprobacion(false)

    }

    const flotasList = useSelector(state => state.flotasList)
    const { flotas, loading } = flotasList
    const [flota, setFlota] = useState('')

    const [numFlota, setNumFlota] = useState('')
    const [lider, setLider] = useState('')
    const [auxiliar, setAuxiliar] = useState('')
    const [correoFlota, setCorreoFlota] = useState('')
    const [razonClienteNoDeseaVisita, setRazonClienteNoDeseaVisita] = useState('')

    const handleFlota = (e) => {
        setFlota(e)
        setFlotaInterna(e)
        const p = flotasInternasList.filter((item) => item.numeroFlota === e);
        const data = p[0]
        setNumFlota(data.numeroFlota)
        setLider(data.nombreLider)
        setAuxiliar(data.nombreAuxiliar)
        setCorreoFlota(data.correo)
    }

    const openModalClienteNoDeseaVisita = () => {
        setModalClienteYaNoDeseaLaVisita(true)
        setOpenModal2(false)
    }

    const closeModalClienteNoDeseaVisita = () => {
        setOpenModal2(true)
        setModalClienteYaNoDeseaLaVisita(false)
    }
    const cerrarTicketClienteNoDeseaVisita = () => {
        console.log('Ticket Cerrado')
        db.collection('tickets').doc(props.id).update({
            estado: 2,
            motivo_cierre_ticket: razonClienteNoDeseaVisita.toUpperCase(),
            ticket_cerrado_por_agenda: 0,
            fecha_cierra_ticket: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_cerro_ticket: userInfo.displayName,
        })
        Swal.fire({ icon: "success", text: 'Se ha cerrado el ticket con exito', });
        setModalClienteYaNoDeseaLaVisita(false)
    }
    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => setOpenModal(true)}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* DIALOG VIEW TICKET ESPERA */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                <DialogTitle><strong>{props.uid_ticket}</strong></DialogTitle>
                <DialogContent>

                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CEDULA:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cedula}

                                            name="cedula"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>LINEA:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={linea}

                                            name="linea"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>NOMBRE SOLICITANTE:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nombreSolicitante}
                                            name="nombreSolicitante"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>COORDENADAS CLIENTE:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={props.coordenadas}
                                        name="coordenadas"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>CAJA CLIENTE:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={props.caja}
                                            name="caja"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>ZONA CLIENTE:</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={props.zona}
                                        name="zona"
                                        size="small"
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6}>

                                    <Box pb={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>DEPARTAMENTO:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsDepartamento}
                                                name="ticketsDepartamento"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>ASUNTO DEL TICKET:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsAsunto}
                                            name="ticketsAsunto"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FECHA SUGERIDA DE VISITA</strong></p>
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size='small' fullWidth />}
                                                inputFormat="dd/MM/yyyy"
                                                value={fechaVisita}
                                            />
                                        </LocalizationProvider>
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>TURNO:</strong></p>
                                    <FormControl fullWidth>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={ticketsTurno}
                                            name="ticketsTurno"
                                            size="small"
                                            fullWidth
                                        />
                                    </FormControl>

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>AGENDADO DESDE:</strong></p>
                                        <FormControl fullWidth>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={ticketsOficina}
                                                name="ticketsOficina"
                                                size="small"
                                                fullWidth
                                            />
                                        </FormControl>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Box py={2}>
                                <img width={400} src={props.imagen} />
                            </Box>

                            <Box py={2}>
                                <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>INGRESE DETALLES DEL TICKET</strong></p>
                                <TextField sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={detallesTicket}
                                    /* onChange={(e) => setDetallesTicket(e.target.value)} */
                                    name="detallesTicket"
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={8}
                                />
                            </Box>
                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenModal(false)}>REGRESAR</Button>
                    <Button variant="outlined" color="warning" size="small" onClick={() => { openModalClienteNoDeseaVisita() }}><strong>CLIENTE YA NO DESEA LA VISITA</strong></Button>
                    {/* <Button variant="contained" color="warning" size="small" onClick={() => { openModalrechazo() }}>RECHAZAR</Button> */}
                    <Button variant="contained" color="secondary" size="small" onClick={() => { openModalConfirmar() }} >REASIGNAR</Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG CONFIRM*/}
            <Dialog open={modalConfirmAprobacion} onClose={() => setModalConfirmAprobacion(false)} maxWidth="sm">
                <DialogTitle><strong>SELECCIONE LA FLOTA QUE SE LE REASIGNARA EL TICKET</strong></DialogTitle>

                <DialogContent>

                    <Box pb={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl fullWidth>
                                <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FECHA SUGERIDA DE VISITA</strong></p>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} size='small' sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }} />}
                                    disabled
                                    inputFormat="dd/MM/yyyy"
                                    value={fechaVisita}
                                /* onChange={(newValue) => {
                                    setFechaVisitaAgenda(newValue);
                                }} */
                                />
                            </FormControl>
                        </LocalizationProvider>
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FECHA / HORA PARA LA VISITA:</strong></p>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} size='small' sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }} />}
                                value={fechaVisitaAgenda}
                                onChange={(newValue) => {
                                    setFechaVisitaAgenda(newValue);
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>

                    {/* <Box py={2}>
                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>¿ESTA VISITA REQUIERE MATERIALES?:</strong></p>
                        <Select
                            fullWidth
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            size='small'
                            value={requiereMateriales}
                            onChange={(e) => setRequiereMateriales(e.target.value)}
                        >
                            <MenuItem value="SI">SI</MenuItem>
                            <MenuItem value="NO">NO</MenuItem>
                        </Select>
                    </Box>

                    {
                        requiereMateriales === 'SI' ?
                            <>
                                <Box pb={3}>
                                    <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>DIGITE LOS MATERIALES A USAR EN LA VISITA</strong></p>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={observacionMateriales}
                                        onChange={(e) => setObservacionMateriales(e.target.value)}
                                        name="observacionMateriales"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={4}
                                    /></Box>
                            </> : ''
                    } */}

                    <Box pt={2}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>FLOTA:</strong></p>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                value={flotaInterna}
                                onChange={(e) => handleFlota(e.target.value)}
                            >
                                {flotasInternasList && flotasInternasList.map((row, key) => (
                                    <MenuItem value={row.numeroFlota}>{row.numeroFlota}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>

                    <Box py={2}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>LIDER:</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                value={lider}
                                name="lider"
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Box>

                    <FormControl fullWidth>
                        <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>AUXILIAR:</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={auxiliar}
                            name="auxiliar"
                            size="small"
                            fullWidth
                        />
                    </FormControl>

                    <Box pt={2}>
                        <FormControl fullWidth>
                            <p style={{ fontSize: "11px", marginBottom: "6px" }}><strong>OBSERVACION EXTRA HACIA EL TECNICO:</strong></p>
                            <TextField sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                multiline
                                rows={4}
                                value={observacionExtraPyl}
                                onChange={(e) => setObservacionExtraPyl(e.target.value)}
                                name="observacionExtraPyl"
                                size="small"
                                fullWidth
                            />
                        </FormControl>
                    </Box>


                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalConfirmar()}><strong>Regresar</strong></Button>
                    {/* {
                        requiereMateriales === 'SI' ?
                            <Button disabled={fechaVisitaAgenda === '' || requiereMateriales === '' || observacionMateriales === '' || flota === '' || lider === '' || auxiliar === ''} onClick={() => openConfirmaFlota()} size="small" variant="contained" color="secondary">
                                Aceptar
                            </Button> : <Button disabled={fechaVisitaAgenda === '' || requiereMateriales === '' || flota === '' || lider === '' || auxiliar === ''} onClick={() => openConfirmaFlota()} size="small" variant="contained" color="secondary">
                                Aceptar
                            </Button>
                    } */}
                    <Button disabled={fechaVisitaAgenda === '' || flota === '' || lider === '' || auxiliar === ''} onClick={() => openConfirmaFlota()} size="small" variant="contained" color="secondary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG RECHAZO*/}
            <Dialog open={modalConfirmRechazo} onClose={() => setModalConfirmRechazo(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE RECHAZAR EL TICKET?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalrechazo()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmRechazo()} size="small" variant="contained" color="error">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG CONFIRM FLOTA*/}
            <Dialog open={modalConfirmFlota} onClose={() => setModalConfirmFlota(false)} maxWidth="xs">
                <DialogTitle>¿ESTA SEGURO DE ENVIAR EL TICKET A LA FLOTA <strong> {flota}?</strong></DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeConfirmaFlota()}><strong>Regresar</strong></Button>
                    <Button onClick={() => confirmAprobacion()} size="small" variant="contained" color="secondary">
                        {" "}
                        ENVIAR
                    </Button>
                </DialogActions>
            </Dialog>
            {/* DIALOG CLIENTE YA NO DESEA LA VISITA*/}
            <Dialog open={modalClienteYaNoDeseaLaVisita} onClose={() => setModalClienteYaNoDeseaLaVisita(false)} maxWidth="xs">
                <DialogTitle><strong>¿ESTA SEGURO DE QUE EL CLIENTE YA NO DESEA LA VISITA?</strong></DialogTitle>

                <DialogContent>
                    <p style={{ fontSize: "12px", marginBottom: "3px" }}>
                        <strong>Deje un comentario de la razon por la cual el cliente ya no desea la visita</strong>
                    </p>
                    <Box pt={2}>
                        <TextField
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { Wtop: 0 },
                            }}
                            size="small"
                            fullWidth
                            value={razonClienteNoDeseaVisita}
                            onChange={(e) => setRazonClienteNoDeseaVisita(e.target.value)}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeModalClienteNoDeseaVisita()}><strong>Regresar</strong></Button>
                    <Button disabled={razonClienteNoDeseaVisita === ''} onClick={() => { cerrarTicketClienteNoDeseaVisita() }} size="small" variant="contained" color="error">
                        CERRAR TICKET
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}