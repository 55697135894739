import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Card,
    CardContent,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from "@mui/material";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import moment from 'moment'
import {
    Grid as Grid2,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { db } from "../../../firebase";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { GridPrintExportMenuItem } from "@mui/x-data-grid";
export const MostrarSeries = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        LoadData()

    }, [dispatch]);

    const [open, setOpen] = useState(null);
    const [open2, setOpen2] = useState(false);

    const onData = () => {
        setOpen(true)
    }

    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const container = React.useRef(null);
    const container2 = React.useRef(null);
    const descripcion = props.descripcion

    const [total, setTotal] = useState(0);

    const LoadData = async (startAfterDoc, persistMessages = []) => {

        const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", props.codigo_producto).where('bodega', '==', props.bodega).get()

        const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        console.log(data2[0].id)

        let ref = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)

        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            var datos = []
            if (data) {
                data.map((item) => {
                    datos.push({
                        ...item,
                        created: item.created ?
                            moment(new Date(item.created.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')
                            : ''
                    })
                })
            }
            setData2(datos)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })

    };

    const columns = [
        { field: 'bodega', headerName: 'BODEGA', width: 140 },
        { field: 'serie', headerName: 'SERIE', width: 180 },
        { field: 'usuario_que_cargo_serie', headerName: 'USUARIO QUE CARGO LA SERIE', width: 340 },
        {
            field: 'created', headerName: 'FECHA DE GENERACION SERIE', type: 'dateTime', width: 260,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
        },
    ];

    const printOptions = { hideFooter: true, hideToolbar: true, fileName: `SERIES ${props.descripcion}`}
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            exporter.current.save();
        }
    };

    const exportPDFWithMethod = () => {
        let element = container2.current;
        savePDF(element, {
            pageTemplate: plantilla,
            paperSize: "A4",
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `SERIES ${props.descripcion}`,
        });
    };

    const headerColumn = (props) => {
        return (
            <a>
                <span ><strong>{props.title}</strong></span>
                {props.children}
            </a>
        );
    };
    const plantilla = (props) => {
        return (
            <div>
                <div style={{
                    position: "absolute",
                    top: "20px",
                }}>
                    {props.pageNum == 1 &&
                        <strong>SERIES PERTENECIENTES A: {descripcion}</strong>
                    }
                </div>

                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                    Pag {props.pageNum} de {props.totalPages}
                </p>
            </div >
        )
    };

    return (
        <>
            <Tooltip title="VER SERIES">
                <Button onClick={() => onData()}>
                    <QrCodeScannerIcon />
                </Button>
            </Tooltip>

            {/* LISTA DE OPCIONES */}

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>SERIES PERTENECIENTES A: {props.descripcion}</strong>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                        >
                            {" "}
                            DESCARGAR EXCEL
                        </Button>
                        {/* <Button  size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />}
                        >
                            
                            {" "}
                            DESCARGAR PDF
                        </Button> */}
                    </Stack >
                    <br />

                    <ExcelExport data={data2 ? data2 : []}
                        fileName={props.descripcion ? "SERIES " + props.descripcion : '' + " .xlsx"}
                        ref={exporter} filterable={true} >
                        <ExcelExportColumn field="bodega" title="BODEGA" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="serie" title="SERIE" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="usuario_que_cargo_serie" title="USUARIO QUE CARGO LA SERIE" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="created" title="FECHA DE CREACION" headerCellOptions={{ background: "#672780" }} />
                    </ExcelExport>



                    <Paper>
                        <Box sx={{ height: 450, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                // disableColumnFilter
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    // Footer: CustomFooterTotalComponent,

                                }}

                                onStateChange={(state) => {
                                    console.log(state.pagination.rowCount);
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>


            <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="md">
                <DialogTitle>
                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => exportPDFWithMethod()} size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />}
                        >
                            {" "}
                            PDF
                        </Button>
                    </Stack >
                </DialogTitle>
                <DialogContent>
                    <PDFExport>
                        <div ref={container2}>
                            <p style={{ fontSize: "13px" }}><strong>CODIGO: </strong>{props.codigo_producto}</p>
                            <Grid2 data={data2 ? data2 : []} >
                                <Column field="bodega" title="BODEGA" headerCell={headerColumn} />
                                <Column field="serie" title="SERIE" headerCell={headerColumn} />
                                <Column field="usuario_que_cargo_serie" title="USUARIO QUE CARGO LA SERIE" headerCell={headerColumn} />
                                <Column field="created" title="FECHA DE CREACION" headerCell={headerColumn} />
                            </Grid2>
                        </div>
                    </PDFExport>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    );
};