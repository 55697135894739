import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React, { useState, useEffect } from 'react'
import { FirmaTransferenciaActivos } from './Firmas';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import logo1 from '../../../../assets/img/contrato/image005.jpg'
import { Button, Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment'
import { SerialPDF } from './SerialPDF';

export const TransferenciaActivos = (props) => {
  const container = React.useRef(null);
  const [items, setItems] = useState([]);
  const [id, setId] = useState(null)

  useEffect(() => {
    setId(props.encabezado.id)
    setItems(props.contenido)
  }, [props.contenido])


  const PageTemplate = (props) => {
    return (
      <div>
        {props.pageNum == 1 &&
          <div
            style={{
              position: "absolute",
              top: "20px",
              width: "90%"
            }}>
            <p align="right">
              <img src={logo1} alt="soei" width="90" />
            </p>
          </div>
        }
      </div >
    )
  };

  const headerColumn = (props) => {
    return (
      <a >
        <span ><strong>{props.title}</strong></span>
        {props.children}
      </a>
    );
  };

  const serialColumn = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    return (
      <SerialPDF id={id} fullName={value} />
    );
  };

  const tabla = (
    <div>
      <Grid data={items} >
        <GridColumn field="id" title="No." headerCell={headerColumn} />
        <GridColumn field="fullName" title="Codigo" headerCell={headerColumn} />
        <GridColumn field="categoria_producto" title="Categoria" headerCell={headerColumn} />
        {/* <GridColumn field="UnitPrice" title="Sub-categoria" headerCell={headerColumn} /> */}
        <GridColumn field="descripcion" title="Descripcion" headerCell={headerColumn} />
        <GridColumn field="cantidad_solicitada" title="Cant. Entregada" headerCell={headerColumn} />
        <GridColumn field="fullName" title="Series" headerCell={headerColumn} cell={serialColumn}/>
      </Grid>
    </div>

  );

  const exportPDFWithMethod = () => {
    let element = container.current;
    savePDF(element, {
      repeatHeaders: 'true',
      pageTemplate: PageTemplate,
      paperSize: "A4",
      margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
      scale: 0.5,
      fileName: `Transferencia ${props.encabezado.id}`,
    });

  };

  return (
    <Container >
      <div className="example-config">
        <Button
          onClick={exportPDFWithMethod}
          style={{ marginLeft: "10px", marginBottom: "10px" }}
          color="error" size='small' variant="outlined"
          startIcon={<PictureAsPdfIcon />}>
          <strong>PDF</strong>
        </Button>
      </div>
      <div className="border rounded p-2">
        <PDFExport>
          <div ref={container}>
            <Container maxWidth='xl'>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={12} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a><strong>TRANSFERENCIA DE ACTIVOS</strong></a>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >
                  <TableRow style={{ fontSize: '11px' }}>
                    <TableCell align="left" colSpan={3} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>No.</a>
                    </TableCell>
                    <TableCell align="left" colSpan={3} >
                      <a>{props.encabezado.numero_solicitud}</a>
                    </TableCell>
                    <TableCell align="left" colSpan={3} style={{ backgroundColor: '#672780', color: 'white' }} >
                      <a>Fecha</a>
                    </TableCell>
                    <TableCell align="left" colSpan={3} >
                      <a>{props.encabezado.fecha_documento && typeof props.encabezado.fecha_documento === 'object' &&
                        moment(props.encabezado.fecha_documento.toDate().toString()).format('DD/MM/YYYY')}</a>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontSize: '11px' }}>
                    <TableCell align="left" colSpan={3} style={{ backgroundColor: '#672780', color: 'white' }} >
                      <a>Estado</a>
                    </TableCell>
                    <TableCell align="left" colSpan={3}>
                      {props.encabezado.estado == 0 &&
                        <a>Pendiente</a>
                      }
                      {props.encabezado.estado == 1 &&
                        <a>Rechazado</a>
                      }
                      {props.encabezado.estado == 2 || props.encabezado.estado == 3 &&
                        <a>Aprobado</a>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontSize: '11px' }}>
                    <TableCell align="left" colSpan={12}>
                      <strong>Origen Departamentos</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ fontSize: '11px' }}>
                    <TableCell align="center" colSpan={6} sx={{ border: 1, borderWidth: '1px' }}>
                      <a>DEPARTAMENTOS / BODEGAS</a>
                    </TableCell>
                    <TableCell align="center" colSpan={6} sx={{ border: 1, borderWidth: '1px' }}>
                      <a>ASIGNADOS / RESPONSABLES</a>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left" sx={{ fontSize: '11px' }}>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Origen</a>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <a>{props.encabezado.bodega_origen}</a>
                    </TableCell>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Origen</a>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <a>CARLOS NAREA</a>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left" sx={{ fontSize: '11px' }}>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Destino</a>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <a>{props.encabezado.bodega_destino}</a>
                    </TableCell>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Destino</a>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <a>{props.encabezado.usuario_que_aprobo}</a>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left" sx={{ fontSize: '11px' }}>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Observaciones:</a>
                    </TableCell>
                    <TableCell colSpan={10}>
                      <strong>{props.encabezado.observacion_adicional}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left" sx={{ fontSize: '11px' }}>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Motivo:</a>
                    </TableCell>
                    <TableCell colSpan={10}>
                      <a>{props.encabezado.motivo}</a>
                    </TableCell>
                  </TableRow>
                  <TableRow align="left" sx={{ fontSize: '11px' }}>
                    <TableCell colSpan={2} style={{ backgroundColor: '#672780', color: 'white' }}>
                      <a>Activo Fijo:</a>
                    </TableCell>
                    <TableCell colSpan={10}>
                      <a>NINGUNO</a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Container>
            <Container maxWidth='xl'>
              {tabla}
              <br />
            </Container>
            <FirmaTransferenciaActivos bodeguero={"CARLOS NAREA"} empleado={props.encabezado.usuario_que_aprobo} />
          </div>
        </PDFExport>
      </div>
    </Container>
  )
}
