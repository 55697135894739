import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase";

export const UpdateProductUbication = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [nombreBodega, setNombreBodega] = useState(props.nombre_ubicacion);

    const updateBodega = async () => {
        await db.collection('ubicaciones').doc(props.id).update({
            nombre_ubicacion: nombreBodega,
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado la bodega con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="sm">
                <DialogTitle>
                    <strong>EDICION DE UBICACION: {props.nombre_ubicacion}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box py={2}>
                        <FormControl fullWidth>


                            <Box py={3}>
                                <TextField
                                    fullWidth
                                    label="NOMBRE PRODUCTO"
                                    value={nombreBodega}
                                    onChange={(e) => setNombreBodega(e.target.value)}
                                />
                            </Box>

                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateBodega()
                    }} variant="contained"

                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};