import React, { useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, Divider, Button, Select, FormControl, MenuItem, Typography, InputLabel } from "@mui/material";
import InputValidation from "../../../components/utils/InputValidation";
import { db, functions } from "../../../firebase";
export const CrearNomina = () => {
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [direccion, setDireccion] = useState({ campo: '', valido: true })
    const [fechaNacimiento, setFechaNacimiento] = useState({ campo: '', valido: true })
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [sangre, setSangre] = useState({ campo: '', valido: true })
    const [rol, setRol] = useState({ campo: '', valido: true })

    const [email, setEmail] = useState({ campo: '', valido: true })
    const [telefono, setTelefono] = useState({ campo: '', valido: true })
    const onSubmit = async () => {
        await db.collection('nomina').doc().set({
            email: email.campo,
            direccion: direccion.campo,
            fechaNacimiento: fechaNacimiento.campo,
            nombre: nombre.campo,
            sangre: sangre.campo,
            cedula: cedula.campo,
            telefono: telefono.campo
        }).then(() => {
            Swal.fire({ icon: "success", text: "Se ha creado con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
    }
    return (
        <>
            <Grid container spacing={7}>
                <Grid item xs={12} md={6}>
                    <InputValidation
                        estado={nombre}
                        cambiarEstado={setNombre}
                        label="NOMBRE"
                        name="NOMBRE"
                    />
                    <InputValidation
                        estado={cedula}
                        cambiarEstado={setCedula}
                        label="CEDULA"
                        name="CEDULA"
                    />

                    <InputValidation
                        estado={sangre}
                        cambiarEstado={setSangre}
                        label="SANGRE"
                        name="sangre"
                    />
                    <InputValidation
                        estado={fechaNacimiento}
                        cambiarEstado={setFechaNacimiento}
                        label="FECHA DE NACIMIENTO"
                        name="fechaNacimiento"
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <InputValidation
                        estado={direccion}
                        cambiarEstado={setDireccion}
                        label="DIRECCIÓN"
                        name="direccion"
                    />

                    <InputValidation
                        estado={email}
                        cambiarEstado={setEmail}
                        label="CORREO ELECTRONICO"
                        name="Correo Electronico"
                    />

                    <InputValidation
                        estado={telefono}
                        cambiarEstado={setTelefono}
                        label="TELEFONO"
                        name="telefono"
                    />
                </Grid>
            </Grid>

            <Box display="flex" flexDirection="row-reverse" >
                <Button
                    disabled={sangre === '' || direccion === '' || cedula === '' || nombre === '' || email === '' || telefono === ''}
                    onClick={onSubmit}
                    variant="contained"
                
                ><strong>Crear</strong>
                </Button>
            </Box>
        </>
    );
};
