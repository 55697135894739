import React, { useState } from 'react'
import { Box, Tab, Card, Container, Typography } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { CrearCodigoVendedor } from './CrearCodigoVendedor'
import { CrearVendedor } from './CrearVendedor';
import { CrearCentroCosto } from './CrearCentroCosto';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const CentroCostoPage = () => {
    const [tab, setTab] = useState(0)
    return (
        <>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                <Box pl={2} pb={2} pt={2}>
                    <Typography variant="h5" component="h1">VENTAS/<strong>CENTRO DE COSTO</strong></Typography>
                </Box>
                <Card>
                    <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                        <Tabs
                            value={tab}
                            variant="scrollable"
                            indicatorColor="secondary"
                            scrollButtons
                            textColor="secondary"
                            allowScrollButtonsMobile
                            onChange={(e, newValue) => setTab(newValue)}
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 }
                                }
                            }}
                        >
                            <Tab label="CODIGO VENDEDOR" />
                            <Tab label="CREAR CENTRO DE COSTO" />
                            <Tab label="CREAR VENDEDOR" />
                        </Tabs>
                    </Box>
                    <Box>
                        <TabPanel value={tab} index={0}>
                            <CrearCodigoVendedor />
                        </TabPanel>
                      <TabPanel value={tab} index={2}>
                            <CrearCentroCosto />
                        </TabPanel>
                       <TabPanel value={tab} index={0}>
                            <CrearVendedor />
                        </TabPanel> 
                    </Box>
                </Card>
            </Container>
        </>
    )
}