import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, Typography, Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { EgresoProducto, IngresoProducto } from './EgresoProducto';
import { ListEgresoProductos, ListIngresoProductos } from './ListEgresoProductos';
import { EgresoProductoPanel } from './EgresoProductoPanel';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const EgresoProductosPage = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>

      {/* <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1"><strong>EGRESO DE PRODUCTOS</strong></Typography>
      </Box> */}
      <Tabs value={tab} onChange={handleChange} aria-label="solicitud de servicio">
        <Tab label="EGRESO DE PRODUCTO" {...tabs(0)} />
        <Tab label="LISTA DE PRODUCTOS EGRESADOS" {...tabs(1)} />

      </Tabs>
      <br />
      <TabPanel value={tab} index={0}>
        {/* <EgresoProducto /> */}
        <EgresoProductoPanel />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ListEgresoProductos />
      </TabPanel>

    </>
  )
}