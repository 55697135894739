import {
  Box, Typography, Button, TableHead, Paper, Chip, Collapse
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { ViewInstalaciones } from "./ViewInstalaciones";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridToolbarExport,
  esES
} from '@mui/x-data-grid';
import { ViewCambioDeDomicilio } from "./ViewCambioDeDomicilio";
import { ViewMigraciones } from './ViewMigraciones'
import { ViewInstalacionesNew } from "./ViewInstalacionesNew";
import { ViewCambioDeDomicilioNew } from "./ViewCambioDeDomicilioNew";
import { ViewMigracionesNew } from "./ViewMigracionesNew";
export const InstalacionesEspera = () => {
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  function Estado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 0) {
      name = 'POR INSTALAR'
      color = 'warning'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }
    if (d.tipo_venta === 'MIGRACION') {
      name = 'MIGRACION'
      color = 'secondary'
    }
    return (
      <Chip label={name} size="small" variant="outlined" color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  let totalInstalaciones;
  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  let current = new Date();
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 86400000))
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const dispatch = useDispatch();
  useEffect(() => {
    LoadData()
    LoadData2()
  }, [dispatch])
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const LoadData = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("tecnicos").where("estado", "==", 0)
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)

    //Si tiene algun doc anterior se agrega acontinuacion
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0);

      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fechaHora', '>=', startfulldate)
      ref = ref.where('fechaHora', '<=', endfulldate)

      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];
        setData(data)


      })
    }
  };

  const LoadData2 = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("tecnicos").where("estado", "==", 0)
    if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)

    //Si tiene algun doc anterior se agrega acontinuacion
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0);

      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)

      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];
        totalInstalaciones = data
      })
    }
  };
  function CustomFooterTotalComponent(props) {
    return ''
  }
  function EstadoPagado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado_pagado === 0 || !d.estado_pagado) {
      name = 'NO PAGADO'
      color = 'info'
    }
    if (d.estado_pagado === 1) {
      name = 'NO PAGADO'
      color = 'info'
    }
    if (d.estado_pagado === 2) {
      name = 'PAGADO'
      color = 'secondary'
    }
    if (d.promo_santa_elena === true || d.debito == 1) {
      name = 'PAGO DEBITO'
      color = 'warning'
    }
    return (
      <Chip label={name} size="small" color={color} />
    )

  }
  const columns = [
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado', headerName: 'ESTADO', width: 175,
      renderCell: (params) => {
        return <Estado estado={params.row} />
      }
    },
    {
      field: 'estado2', headerName: 'ESTADO PAGO', width: 200,
      renderCell: (params) => {
        return <EstadoPagado estado={params.row} />
      }
    },

    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUMB REF', width: 150 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    {
      field: 'foto_cliente_vivienda', headerName: 'FOTO VIVIENDA', width: 100,
      renderCell: (params) => {
        return params.row.foto_cliente_vivienda &&
          <a target="_blank" href={params.row.foto_cliente_vivienda}>VER FOTO</a>

      }
    },
    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'premios', headerName: 'PREMIOS', width: 240 },
    { field: 'camara', headerName: 'CAMARA', width: 150 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'ups', headerName: 'MINI UPS', width: 100 },
    { field: 'numCam', headerName: 'NUM CAMERA', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 150 },
    { field: 'caja', headerName: 'CAJA', width: 200 },
    {
      field: 'coordenadas_caja', headerName: 'COORDENADAS CAJA', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'observacion2', headerName: 'OBSERVACION DEL METRAJE', width: 800 },
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'canton', headerName: 'CANTON', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 140,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo ? params.row.centro_costo.codigo_vendedor : ''
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ? params.row.centro_costo.centro_costo : ''
      }
    },

    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ? params.row.centro_costo.canal : ''
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ? params.row.centro_costo.lider_venta : ''
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo ? params.row.centro_costo.categoria : ''
      }
    },
    {
      field: 'referido', headerName: 'ES REFERIDO', width: 100,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },
    {
      field: 'flotaReferida', headerName: 'VENTA REFERIDA POR FLOTA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },
    {
      field: 'created', headerName: 'FECHA AGENDAMIENTO', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: '', headerName: 'ACCIONES', width: 90,
      renderCell: (params) => {
        return (
          <>
            {
              params.row.tipo_venta === 'MIGRACION' ? <ViewMigracionesNew props={params.row} /> : ''
            }
            {
              params.row.estado_pagado === 2 && params.row.cambio_domicilio === false || params.row.cambio_domicilio === undefined ? <ViewInstalacionesNew props={params.row} /> : ''
            }
            {
              params.row.cambio_domicilio === true ? <ViewCambioDeDomicilioNew props={params.row} /> : ''
            }
          </>
        )

      }
    },
    // {
    //   field: '2', headerName: 'ACCIONES ESP', width: 150,
    //   renderCell: (params) => {
    //     return params.row.cambio_domicilio == true || params.row.debito == 1 ? <ViewInstalaciones props={params.row} /> : ''
    //   }
    // },
  ]
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  return (
    <>
      <Box pt={1}>
        <Typography variant="button" >
          <Typography >
            <strong>GESTIONES EN ESPERA</strong>
          </Typography >
        </Typography >
      </Box>

      <Box pt={1}>
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
          </Box>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
          <Button
            disableElevation
            variant="contained"
            onClick={() => LoadData()}
          >
            Aplicar busqueda
          </Button>
        </Collapse>
      </Box>

      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density="compact"
            pagination={true}
            // disableColumnSelector
            // disableDensitySelector
            //hideFooterPagination
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              // Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};