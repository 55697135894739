import { Container, Table, Button, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Chip, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { UpdateFlotasActivas } from "./views/UpdateFlotasActivas";
export const FlotasActivas = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'ACTIVA'
      color = 'success'
    } else if (estado === 1) {
      name = 'Rechazada'
      color = 'info'
    }
    return (
      <Chip label={name} color={color} />
    )
  }
  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  useEffect(() => {
    LoadData()
  }, [])
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("flotas").where("estado", "==", 0);
    //Si tiene algun doc anterior se agrega acontinuacion
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      console.log(data,'data')
      setData(data);
      setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
      setIsLastPage(totalCount < 1);
    });
  }
  return (
    <>
      {data && (
        <TableContainer  sx={{ maxHeight: 740 }} >
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ESTADO</TableCell>
                <TableCell>ACCIONES</TableCell>
                <TableCell>LIDER</TableCell>
                <TableCell>AUXILIAR</TableCell>
                <TableCell>FLOTA</TableCell>
                <TableCell>LOCALIDAD</TableCell>
                <TableCell>PROVEEDOR</TableCell>
                <TableCell>CONTRATO</TableCell>
                <TableCell>PLACA</TableCell>
                <TableCell>MOTOR</TableCell>
                <TableCell>MODELO</TableCell>
                <TableCell>MARCA</TableCell>
                <TableCell>AÑO</TableCell>
                <TableCell>SEGURO</TableCell>
                <TableCell>INICIO VIGENCIA SEGURO</TableCell>
                <TableCell>FIN VIGENCIA SEGURO</TableCell>
                <TableCell>GPS</TableCell>
                <TableCell>INICIO VIGENCIA GPS</TableCell>
                <TableCell>FIN VIGENCIA GPS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <UpdateFlotasActivas props={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numeroFlota}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.localidad}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.proveedor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numContrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.placa}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.motor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.modelo}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.marca}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.anio}
                  </TableCell>

                  {/* SEGURO */}

                  <TableCell component="th" scope="row">
                    {row.seguro === undefined ? '' : row.seguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.inicioVigenciaSeguro === undefined ? '' : row.inicioVigenciaSeguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.FinVigenciaSeguro === undefined ? '' : row.FinVigenciaSeguro}
                  </TableCell>

                  {/* GPS */}

                  <TableCell component="th" scope="row">
                    {row.gps === undefined ? '' : row.seguro}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.inicioVigenciaGps === undefined ? '' : row.inicioVigenciaGps}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.FinVigenciaGps === undefined ? '' : row.FinVigenciaGps}
                  </TableCell>

                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

