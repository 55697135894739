import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, Box, Button, DialogActions, DialogTitle } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import Swal from 'sweetalert2'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { db, firebase } from '../../firebase'

const VerItems = ({ props }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [items, setItems] = useState([]);
    useEffect(() => {
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
        console.log(props, 'props')
    }
    const loadItems = () => {
        var docRef = db.collection("solicitud_pedido").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('a', props.uidSolicitud)
            setItems(data);
        });

        console.log('enviar')
    }
    const onSubmit = () => {
        setLoading(true)
        //console.log('enviar', items)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 1,
            egreso: firebase.firestore.FieldValue.increment(1),
            fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            Swal.fire({ icon: "success", text: 'Se actualizo su solicitud de pedido' });
            setLoading(false)
        })
    }
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }
    const columns = [
        { field: 'codigo', headerName: 'CODIGO', width: 100 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 500 },
        { field: 'cantidad', headerName: 'CANTIDAD SOLICITADA', width: 190 },
        /* { field: 'cant_entrega', headerName: 'CANTIDAD ENTREGADA', width: 190 }, */
        {
            field: '1', headerName: 'CANTIDAD ENTREGADA', width: 190,
            renderCell: (params) => {
                const result = params.row.cantidad - params.row.cant_diferencia
                return (
                    Number.isNaN(Number(result)) ? 0 : Math.abs(result)
                )
            }
        },
        /* { field: 'cant_diferencia', headerName: 'DIFERENCIA', width: 100 }, */
        {
            field: 'cant_diferencia', headerName: 'DIFERENCIA', width: 100,
            renderCell: (params) => {
                const result = params.row.cant_diferencia
                return (
                    Number.isNaN(Number(result)) || result === undefined ? 0 : Math.abs(result)
                )
            }
        },
        /* {
            field: 'cant_diferencia', headerName: 'DIFERENCIA', width: 100,
            renderCell: (params) => {
                return (
                    params.row.cant_entrega ? params.row.cantidad - params.row.cant_entrega : 0
                )
            }
        }, */
        { field: 'motivo', headerName: 'MOTIVO', width: 250 },
        { field: 'observacion_extra', headerName: 'OBSERVACION', width: 250 },
    ];

    return (
        <>
            <IconButton size='small' onClick={() => onData()}>
                <RemoveRedEyeIcon fontSize='small' />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
            >
                <DialogTitle>
                    ITEMS APROBADAS DE: {props.id}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>CANCELAR</Button>

                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerItems