import { Container, Table, Button, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Chip, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../firebase";
import { DeleteCategoria } from "./IconsButtonsCategoria/DeleteCategoria";
import { UpdateCategoria } from "./IconsButtonsCategoria/UpdateCategoria";
export const ListCategories = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'ACTIVA'
      color = 'success'
    } else if (estado === 1) {
      name = 'Rechazada'
      color = 'info'
    }
    return (
      <Chip label={name} color={color} />
    )
  }

  const [tableData, setTableData] = useState([])
  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);

  var productos = [];

  const ListCategories = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("categorias_productos");
    //Si tiene algun doc anterior se agrega acontinuacion
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];

      setData(data);
      setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
      setIsLastPage(totalCount < 1);
    });
  }

  const dispatch = useDispatch()
  useEffect(() => {
    ListCategories()
  }, [dispatch])






  return (
    <>


      <TableContainer sx={{ maxHeight: 740 }} >
        <Table size="small" stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>

              <TableCell>ID CATEGORIA</TableCell>
              <TableCell>PRODUCTO</TableCell>
              <TableCell>ACTUALIZAR</TableCell>
              <TableCell>ELIMINAR</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, key) => (
              <TableRow key={key}>

                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.nombre_categoria_producto}
                </TableCell>
                <TableCell component="th" scope="row">
                  <UpdateCategoria props={row} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <DeleteCategoria props={row} />
                </TableCell>
              </TableRow>


            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID CATEGORIA</TableCell>
              <TableCell>PRODUCTO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow
                key={row.id_categoria}
          
              >
                <TableCell component="th" scope="row">
                  {row.id_categoria}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.nombre_categoria}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* <Button
        onClick={() => ListCategories()}
        fullWidth
        variant="contained"
        disableElevation
      >
        {" "}
        Enviar{" "}
      </Button> */}
    </>
  );
};