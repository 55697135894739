import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
    Box,
    Chip,
    Grid,
    TextField
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

export const EditCaja = ({ props }) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [open, setOpen] = useState(null);

    const [listSplitters, setListSplitters] = useState([]);

    const [total, setTotal] = useState(0);

    const [caja, setCaja] = useState(props.caja);

    const [splitter, setSplitter] = useState(props.splitter);

    const [nivel, setNivel] = useState(props.nivel);

    const [modelo, setModelo] = useState(props.modelo);

    const [latitud, setLatitud] = useState(props.latitude);

    const [longitud, setLongitud] = useState(props.longitude);

    const [sector, setSector] = useState(props.sector);

    const [nodo, setNodo] = useState(props.nodo);

    const [canton, setCanton] = useState(props.canton);

    const [provincia, setProvincia] = useState(props.provincia);

    const onData = () => {
        setOpen(true)
    }

    const updateBox = () => {

        db.collection('cajas').doc(props.id).update({
            caja: caja,
            splitter: splitter,
            nivel: nivel,
            modelo: modelo,
            latitude: latitud,
            longitude: longitud,
            sector: sector,
            nodo: nodo,
            canton: canton,
            provincia: provincia,
            usuario_que_actualizo_caja: userInfo.displayName,
        })

        Swal.fire({ icon: "success", title: `Se han actualizado con exito los datos de la caja ${props.caja}` });

        setOpen(false)

    }

    return (
        <>
            <Tooltip title="VER">
                <Button onClick={() => onData()}>
                    <EditIcon color="secondary" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">

                <DialogTitle>
                    CAJA: <strong>{props.caja}</strong>
                </DialogTitle>

                <DialogContent>

                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={5}>

                                <Grid item xs={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CAJA:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={caja}
                                        onChange={(e) => setCaja(e.target.value)}
                                        name="caja"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NIVEL:</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nivel}
                                            onChange={(e) => setNivel(e.target.value)}
                                            name="nivel"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>LATITUD:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={latitud}
                                        onChange={(e) => setLatitud(e.target.value)}
                                        name="latitud"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>SECTOR:</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={sector}
                                            onChange={(e) => setSector(e.target.value)}
                                            name="sector"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CANTON:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={canton}
                                        onChange={(e) => setCanton(e.target.value)}
                                        name="canton"
                                        size="small"
                                        fullWidth
                                    />

                                </Grid>

                                <Grid item xs={6}>
                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>SPLITTER:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={splitter}
                                        onChange={(e) => setSplitter(e.target.value)}
                                        name="splitter"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>MODELO:</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={modelo}
                                            onChange={(e) => setModelo(e.target.value)}
                                            name="modelo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>LONGITUD:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={longitud}
                                        onChange={(e) => setLongitud(e.target.value)}
                                        name="longitud"
                                        size="small"
                                        fullWidth
                                    />

                                    <Box py={2}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NODO:</strong></p>

                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={nodo}
                                            onChange={(e) => setNodo(e.target.value)}
                                            name="nodo"
                                            size="small"
                                            fullWidth
                                        />
                                    </Box>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>PROVINCIA:</strong></p>

                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={provincia}
                                        onChange={(e) => setProvincia(e.target.value)}
                                        name="provincia"
                                        size="small"
                                        fullWidth
                                    />

                                </Grid>

                            </Grid>
                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="outlined" onClick={() => setOpen(false)}><strong>REGRESAR</strong></Button>
                    <Button size="small" color="success" variant="contained" onClick={() => updateBox()}><strong>ACTUALIZAR</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};