import React, { useEffect, useState } from 'react'
import { listsZonas } from '../../../redux/actions/parametersActions';
import {
  LinearProgress,
  Select, Dialog,
  DialogContent, DialogActions,
  FormControl, IconButton, MenuItem,
  InputLabel, DialogTitle,
  DialogContentText, Tooltip,
  TextField, Button, Box, Stack,
  Autocomplete, Paper, CircularProgress,
  Grid
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from "@mui/icons-material/Edit";
import { listsCajasSector } from '../../../redux/actions/operaciones/cajasActions';
import moment from "moment"
import { db, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import InputValidation from "../../utils/InputValidation";
import { verificarFactibilidad } from "../../../redux/actions/operaciones/factibilidadActions";

export const VerificarFactibilidad = ({ props }) => {

  const [dataFamilyClient, setDataFamilyClient] = useState([]);

  const getDataFamily = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "cedula": `${props.cedula}`
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiOLT/consultarParientes", requestOptions)
      .then(response => response.text())
      .then(result => {
        const dataParseClient = JSON.parse(result);
        const dataClient = dataParseClient;
        setDataFamilyClient(dataClient)
        console.log('dataClient', dataClient)
      })
      .catch(error => console.log('error', error));
  }

  const userAuth = useSelector(state => state.userAuth)
  const { loadin, userInfo } = userAuth

  const dispatch = useDispatch()
  const cajasSectorList = useSelector(state => state.cajasSectorList)
  const { cajas: listCajas, loading: loadingPlanes } = cajasSectorList
  //
  const factibilidadCreate = useSelector(state => state.factibilidadCreate)
  const { verifyfacti, loading: loadingCreate } = factibilidadCreate
  //
  const [nombres, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [direccion, setDireccion] = useState('');
  const [observacion, setObservacion] = useState('');
  const [observacion2, setObservacion2] = useState('');
  const [cajas, setCajas] = useState([]);
  const [caja, setCaja] = useState('')
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [age, setAge] = useState('');
  const [data, setData] = useState('')
  const zonasList = useSelector(state => state.zonasList);
  const { zonas, loading } = zonasList
  const [zona, setZona] = useState('')
  const [splitter, setSplitter] = useState('');

  const [latitud, setLatitud] = useState('');
  const [longitud, setLongitud] = useState('');
  const [metraje, setMetraje] = useState('');

  const [selectedSplitter, setSelectedSplitter] = useState('');
  const [idSplitter, setIdSplitter] = useState('');
  const [listSplitters, setListSplitters] = useState([]);

  useEffect(() => {
    dispatch(listsZonas())
    getCajas()
    getDataFamily()
  }, [dispatch]);

  const handleSubmit = async (id) => {
    setOpen(false)
    const data = {
      id_: id,
      nombres: nombres,
      observacion: observacion,
      zona: zona,
      cedula: cedula,
      direccion: direccion,
      estado: 2,
      // observacion2: observacion2,
      caja: item === '' || item === undefined || item === null ? caja : item,
      createdGis: firebase.firestore.FieldValue.serverTimestamp(),
      coordenadas_caja: new firebase.firestore.GeoPoint(
        latitud,
        longitud
      ),
      observacion2: observacion2 === '' ? 'NINGUNA' : observacion2,
      metraje: metraje === '' ? '' : metraje,
      usuario_que_dio_factibilidad: userInfo.displayName,
      puerto: selectedSplitter.toString()
    }

    const agendaDB2 = await db.collection("cajas").where("caja", "==", caja.toString()).get()

    const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

    if (data2[0] != undefined) {
      db.collection('cajas').doc(caja.toString()).collection('puertos').doc(idSplitter.toString()).update({
        usado: 1,
        puerto_de_usuario: nombres,
        cedula_usuario: cedula
      })
    }

    dispatch(verificarFactibilidad(data))
  }

  const handleZona = (e) => {
    setZona(e)
  }

  const siFactible = (id) => {
    //Validaciones
    db.collection("solicitudes")
      .doc(id)
      .update({
        estado: 2,
      })
      .then((docRef) => {
        console.log("licencia ", docRef);
        Swal.fire({
          icon: "success",
          title: "Has confirmado que hay factibilidad.",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }
  const noFactible = (id) => {
    console.log(id, ' id')
    db.collection("solicitudes").doc(id).update({
      estado: 1,
      observacion: observacion,
      observacion2: observacion2 === '' ? '' : observacion2,
      usuario_que_rechazo_factibilidad: userInfo.displayName,
      fechaRechazoFactibilidad: firebase.firestore.FieldValue.serverTimestamp()
    }).then((docRef) => {
      Swal.fire({ icon: "success", title: "Has confimado que no hay factibilidad." })
    }).catch((error) => {
      console.error("Error adding document: ", error);
    })
  }

  const onData = () => {
    setOpen(true)
    setData(props)
    setNombre(props.nombres);
    setCedula(props.cedula);
    setDireccion(props.direccion);
    setObservacion(props.observacion);
    setCaja(props.caja);
    dispatch(listsCajasSector(props.sector))
  }

  const handleClickOpen2 = () => {
    setOpen(false);
    setOpen2(true);
  };

  const closeOpen2 = () => {
    setOpen2(false);
    setOpen(true);
  };

  const [item, setItem] = useState('')

  const getCajas = () => {
    const docRef = db.collection("cajas")
    docRef.get().then((querySnapshot) => {
      const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
      console.log(arrayMessage)
      setCajas(arrayMessage)
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }

  const getListSplitters = (e) => {

    const p = cajas.filter((item) => item.caja === e);
    const data = p[0]

    console.log('caja', e)

    const datosString = data.splitter;

    const [inicio, fin] = datosString.split(":").map(Number);

    db.collection("cajas")
      .doc(e)
      .collection("puertos")
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size === 0) {
          for (let i = inicio; i <= fin; i++) {
            try {
              await db
                .collection("cajas")
                .doc(e)
                .collection("puertos")
                .doc(`puerto-${i.toString()}`)
                .set({
                  puerto: i,
                  usado: 0,
                  id_puerto: `puerto-${i.toString()}`
                });
              console.log(`Dato ${i} agregado correctamente`);
            } catch (error) {
              console.error(`Error al agregar el dato ${i}: ${error}`);
            }
          }
        } else {
          console.log("La colección no está vacía.");
        }
      });

    setTimeout(function () {
      let ref = db.collection('cajas').doc(e).collection('puertos').where('usado', '==', 0)
      ref.onSnapshot(snapshot => {
        const data = [
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ];

        setListSplitters(data)
        console.log(data)
      })
    }, 2500);
  }

  const handleNombreCaja = (e) => {
    setItem(e)
    const p = cajas.filter((item) => item.caja === e);
    const data = p[0]
    setCaja(data.caja)
    setLatitud(data.latitude)
    setLongitud(data.longitude)
    setSplitter(data.splitter)
  }

  const handleSplitter = (e) => {
    const p = listSplitters.filter((item) => item.puerto === e);
    const data = p[0]
    setIdSplitter(data.id)
  }

  return (
    <>
      <Tooltip title="Editar">
        <Button disabled={data.estado === 2} onClick={() => onData()}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md" >
        <DialogTitle><strong>VERIFICAR FACTIBILIDAD DE: {data.uid}</strong>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <DialogContentText>
          </DialogContentText>

          <Paper elevation={3}>
            <Box px={2} pt={1}>
              <p style={{ fontSize: "18px", marginBottom: "4px" }}><strong>FAMILIARES CON SERVICIO</strong></p>
            </Box>
            {dataFamilyClient.length <= 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box py={2} px={2}>
                  <CircularProgress />
                  <p>ESTAMOS CONSULTANDO SI FAMILIARES DEL CLIENTE <strong>{props.nombres}</strong> CUENTAN CON EL SERVICIO</p>
                </Box>
              </Grid>
            ) : (
              <>
                {dataFamilyClient.mensaje === null || dataFamilyClient.mensaje === undefined || dataFamilyClient.mensaje === '' ? (
                  <Box px={2} pt={1}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="start"
                    >
                      {dataFamilyClient.familiaresServicio === false ? (
                        <p>NINGÚN FAMILIAR DEL CLIENTE <strong>{props.nombres}</strong> CUENTA CON EL SERVICIO YIGA5</p>
                      ) : (
                        <p>EL CLIENTE <strong>{props.nombres}</strong> TIENE FAMILIARES QUE CUENTAN CON EL SERVICIO YIGA5</p>
                      )}
                      {dataFamilyClient.familiares.map((item) => (
                        <div key={item.id}>
                          <p style={{ marginBottom: "4px" }}><strong>NOMBRE:</strong> {item.nombre}</p>
                          <p style={{ marginBottom: "4px" }}><strong>DIRECCION:</strong> {item.direccion_principal}</p>
                          <p style={{ marginBottom: "4px" }}><strong>RELACION:</strong> {item.relacion}</p>
                          <p style={{ marginBottom: "4px" }}><strong>ESTADO:</strong> {item.estado}</p>
                        </div>
                      ))}
                    </Grid>
                  </Box>
                ) : (
                  <Box px={2} pt={1}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box py={2} px={2}>
                        <CircularProgress />
                        <p>EL CLIENTE CON NOMBRE <strong>{props.nombres}</strong> NO APARECE EN LA BASE DE DATOS</p>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </>
            )}
          </Paper>

          {/* <Paper elevation={2}>
            <p><strong>FAMILIARES CON SERVICIO</strong></p>
            {
              dataFamilyClient.length <= 0
                ?
                <>

                  {
                    dataFamilyClient.mensaje === null || dataFamilyClient.mensaje === undefined || dataFamilyClient.mensaje === '' ?
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box py={2} px={2}>
                          <CircularProgress />
                          <p>ESTAMOS CONSULTANDO SI FAMILIARES DEL CLIENTE <strong>{props.nombres}</strong> CUENTAN CON EL SERVICIO</p>
                        </Box>
                      </Grid> :
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box py={2} px={2}>
                          <CircularProgress />
                          <p>EL CLIENTE CON NOMBRE <strong>{props.nombres}</strong> NO APARECE EN LA BASE DE DATOS</p>
                        </Box>
                      </Grid>
                  }

                </>
                :
                <>
                  {
                    dataFamilyClient.mensaje === null || dataFamilyClient.mensaje === undefined || dataFamilyClient.mensaje === '' ?
                      <Box px={2} pt={1}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="start"
                        >
                          {
                            dataFamilyClient.familiaresServicio === false ? <p>NINGUN FAMILIAR DEL CLIENTE <strong>{props.nombres}</strong> CUENTA CON EL SERVICIO YIGA5</p> : <p>EL CLIENTE <strong>{props.nombres}</strong> TIENE FAMILIARES QUE CUENTAN CON EL SERVICIO YIGA5</p>
                          }
    
                          {dataFamilyClient.familiares.map((item) => (
                            <div key={item.id}>
                              <p style={{ marginBottom: "4px" }}><strong>NOMBRE:</strong> {item.nombre}</p>
                              <p style={{ marginBottom: "4px" }}><strong>DIRECCION:</strong> {item.direccion_principal}</p>
                              <p style={{ marginBottom: "4px" }}><strong>RELACION:</strong> {item.relacion}</p>
                              <p style={{ marginBottom: "4px" }}><strong>ESTADO:</strong> {item.estado}</p>

                            </div>
                          ))}
                        </Grid>
                      </Box> : <Box></Box>
                  }


                </>
            }
          </Paper> */}

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>NOMBRES</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              size='small'
              fullWidth
              value={nombres}
              onChange={(e) => setNombre(e.target.value)}
            />
          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>CEDULA</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              size='small'
              fullWidth
              value={cedula}
              onChange={(e) => setCedula(e.target.value)}
            />
          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>DIRECCION</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              size='small'
              fullWidth
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
            />
          </Box>

          {/* <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>CAJA</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              size='small'
              fullWidth
              value={caja}
              onChange={(e) => setCaja(e.target.value)}
            />
          </Box> */}

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>CAJA</strong></p>
            <Autocomplete
              fullWidth
              size="small"
              id="combo-box-demo"
              options={cajas}
              getOptionLabel={(option) => option.caja}
              onInputChange={(e, newInputValue) => {
                console.log('item', item)
                console.log('e.target.value', e.target.value)
                console.log('newInputValue', newInputValue)
                handleNombreCaja(newInputValue)
                getListSplitters(newInputValue)
              }}
              renderInput={(params) => <TextField {...params} label="BUSCAR CAJA" />}
            />
          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>AGREGAR NUEVA CAJA</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              size='small'
              fullWidth
              value={caja}
              onChange={(e) => setCaja(e.target.value)}
            />
          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>COORDENADAS CAJA</strong></p>
            <Stack direction="row"
              alignItems="center" justifyContent="center" spacing={8}>

              <TextField
                label='LATITUD'
                type="number"
                onChange={(e) => setLatitud(e.target.value)}
                value={latitud}
                size="small"
                fullWidth
              />

              <TextField
                label='LONGITUD'
                type="number"
                onChange={(e) => setLongitud(e.target.value)}
                value={longitud}
                size="small"
                fullWidth
              />
            </Stack>
          </Box>

          {
            splitter === '' ? '' : <Box py={2}>
              <p style={{ fontSize: "12px" }}><strong>SPLITTER</strong></p>
              <TextField
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
                value={splitter}
                size="small"
                fullWidth
              />
            </Box>
          }

          {
            splitter === '' ? '' : <Box py={2}>
              <p style={{ fontSize: "12px" }}><strong>ASIGNE UN PUERTO AL CLIENTE</strong></p>
              <Select
                fullWidth
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
                size="small"
                value={selectedSplitter}
                label="CATEGORIA"
                onChange={(e) => {
                  handleSplitter(e.target.value)
                  setSelectedSplitter(e.target.value)
                }}>
                {listSplitters &&
                  listSplitters.map(item => (
                    <MenuItem key={item.puerto} value={item.puerto} >
                      {item.puerto}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          }

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>METRAJE</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              value={metraje}
              onChange={(e) => setMetraje(e.target.value)}
              size="small"
              fullWidth
            />

          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>ZONAS</strong></p>
            <FormControl fullWidth>
              <Select
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                }}
                size='small'
                value={zona}
                label="ZONAS"
                onChange={(e) => handleZona(e.target.value)}>
                {zonas &&
                  zonas.map(item => (
                    <MenuItem key={item.id} value={item.id} >
                      {item.id}
                    </MenuItem>
                  ))}
              </Select>

            </FormControl>
          </Box>

          <Box py={2}>
            <p style={{ fontSize: "12px" }}><strong>OBSERVACION ADICIONAL</strong></p>
            <TextField
              sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
              }}
              value={observacion2}
              onChange={(e) => setObservacion2(e.target.value)}
              size="small"
              fullWidth
            />

          </Box>

          {/* <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>OBSERVACIONES</InputLabel>
              <Select
                size='small'
                label="OBSERVACIONES"
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
              >
                <MenuItem value="No hay observaciones">NO HAY OBSERVACIONES</MenuItem>
                <MenuItem value="Area sin cobertura de red">Area sin cobertura de red</MenuItem>
                <MenuItem value="Inexistencia de posteria autorizada">Inexistencia de posteria autorizada</MenuItem>
                <MenuItem value="Distancia mayor a 250 mts">Distancia mayor a 250 mts</MenuItem>
                <MenuItem value="Inaccesibilidad geografica">Inaccesibilidad geografica</MenuItem>
                <MenuItem value="Coordenadas no concuerdan con direccion">Coordenadas no concuerdan con direccion</MenuItem>
                <MenuItem value="No disponibilidad de puerto fisico (caja segundaria llena fisicamente)">No disponibilidad de puerto fisico (caja segundaria llena fisicamente)</MenuItem>
                <MenuItem value="No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)">No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box py={2}>
            <InputValidation
              size='small'
              estado={observacion2}
              cambiarEstado={setObservacion2}
              label="Observacion 2*"
              name="Observacion2"
            />
          </Box> */}

        </DialogContent>
        <DialogActions>
          <Button
            disabled={data.estado === 2}
            onClick={() => handleClickOpen2()}
            color='error'
            variant="contained"
            disableElevation
          >
            NO HAY FACTIBILIDAD
          </Button>

          <Button
            onClick={() => handleSubmit(data.id)}
            disabled={!direccion || !zona || !longitud || !latitud || !metraje}
            disableElevation
            color='success'
            variant="contained">
            Guardar Cambios y dar factibilidad
          </Button>
        </DialogActions>
      </Dialog>

      {/* RECHAZAR FACTIBILIDAD */}

      <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xs">
        <DialogTitle>
          <strong>VERIFICAR FACTIBILIDAD DE: {data.nombres}</strong>
        </DialogTitle>
        <DialogContent>
          <Box py={2}>
            <FormControl fullWidth>
              <InputLabel>OBSERVACIONES</InputLabel>
              <Select
                size='small'
                label="OBSERVACIONES"
                value={observacion}
                onChange={(e) => setObservacion(e.target.value)}
              >
                 <MenuItem value="ZONA RESTRINGIDA">ZONA RESTRINGIDA</MenuItem>
                <MenuItem value="No hay observaciones">NO HAY OBSERVACIONES</MenuItem>
                <MenuItem value="Area sin cobertura de red">Area sin cobertura de red</MenuItem>
                <MenuItem value="Inexistencia de posteria autori1zada">Inexistencia de posteria autorizada</MenuItem>
                <MenuItem value="Distancia mayor a 250 mts">Distancia mayor a 250 mts</MenuItem>
                <MenuItem value="Inaccesibilidad geografica">Inaccesibilidad geografica</MenuItem>
                <MenuItem value="Coordenadas no concuerdan con direccion">Coordenadas no concuerdan con direccion</MenuItem>
                <MenuItem value="No disponibilidad de puerto fisico (caja segundaria llena fisicamente)">No disponibilidad de puerto fisico (caja segundaria llena fisicamente)</MenuItem>
                <MenuItem value="No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)">No disponibilidad de puerto logico (puerto de equipo olt totalmente lleno)</MenuItem>
                <MenuItem value="Cliente cuenta con servicio Yiga5">Cliente cuenta con servicio Yiga5</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box py={2}>
            <TextField
              value={observacion2}
              onChange={(e) => setObservacion2(e.target.value)}
              size="small"
              fullWidth
              label='OBSERVACION ADICIONAL'
            />

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeOpen2()}><strong>Cancelar</strong></Button>
          <Button disabled={!observacion} onClick={() => noFactible(data.id)} size="small" variant="contained" color="error">
            {" "}
            RECHAZAR FACTIBILIDAD
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};