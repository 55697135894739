import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, Typography, Container } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ListMotivoIngresoProducto } from './ListMotivoIngresoProducto';
import { NewMotivoIngresoProducto } from './NewMotivoIngresoProducto';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const MotivoIngresoProductoPage = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Container maxWidth="lg">
        <Box pl={2} pb={2} pt={2}>
          <Typography variant="h5" component="h1"><strong></strong>MOTIVOS INGRESO DE PRODUCTOS</Typography>
        </Box>
        <Tabs value={tab} onChange={handleChange} aria-label="marcas Productos">
          <Tab label="Crear Nuevo Motivo" {...tabs(0)} />
          <Tab label="Lista De Motivos" {...tabs(1)} />

        </Tabs>
        <TabPanel value={tab} index={0}>
          <NewMotivoIngresoProducto />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <ListMotivoIngresoProducto />
        </TabPanel>
      </Container>
    </>
  )
}