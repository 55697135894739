export const listClientesReducer  = (state = {}, action) => {
    switch (action.type) {
        case 'LIST_CLIENTES_REQUEST':
            return { loading: true }
        case 'LIST_CLIENTES_SUCCESS':
            return { loading: false, listClientes: action.payload }
        case 'LIST_CLIENTES_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}