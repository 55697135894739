import React, { useState, useEffect } from 'react'
import { Box, Tab, Card, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/system';
import { ListCustomers } from './ListCustomers';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const LuktvPage = () => {
    const params = useParams()
    const [tab, setTab] = useState(0)
    useEffect(() => {
        if (params.id) {
            setTab(parseInt(params.id))
        }
    }, [params.id])
    return (
        <>

            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
                <Box pl={2} pb={2} pt={2}>
                    <Typography variant="h5" component="h1">Clientes/<strong>LuvkTv</strong></Typography>
                </Box>
                <Card>
                    <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                        <Tabs
                            value={tab}
                            variant="scrollable"
                            indicatorColor="secondary"
                            scrollButtons
                            textColor="secondary"
                            allowScrollButtonsMobile
                            onChange={(e, newValue) => setTab(newValue)}
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 }
                                }
                            }}
                        >
                            <Tab label="Customers" />
                            <Tab label="Create Customers" />
                        </Tabs>
                    </Box>
                    <Box>
                        <TabPanel value={tab} index={0}>
                            <ListCustomers />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>

                        </TabPanel>
                        <TabPanel value={tab} index={2}>

                        </TabPanel>
                        <TabPanel value={tab} index={3}>

                        </TabPanel>
                    </Box>
                </Card>
            </Container>
        </>
    )
}