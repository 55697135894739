import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const ListVentas = () => {
    const location = useLocation();
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'ventas']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon >
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ventas" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton
                            selected={location.pathname === '/ventas/solicitud-de-servicio/0'}
                            component={RouterLink}
                            to={`ventas/solicitud-de-servicio/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitud de Servicio" />
                        </ListItemButton>
                        <ListItemButton selected={location.pathname === '/ventas/ventas-pre-aprobadas/'} component={RouterLink} to={`ventas/ventas-pre-aprobadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Venta Pre Aprobadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-por-agendar/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Por agendar" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-por-reagendar/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Por Reagendar" />
                        </ListItemButton>
                        {/* <ListItemButton component={RouterLink} to={`ventas/ventas-voucher/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Subir Voucher" />
                        </ListItemButton> */}
                        <ListItemButton component={RouterLink} to={`ventas/ventas-agendadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Agendadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-reagendadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Reagendadas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`ventas/ventas-realizadas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Ventas Instaladas" />
                        </ListItemButton>
                        {userAuth?.email?.includes("distribuidor")  &&  
                        <ListItemButton component={RouterLink} to={`ventas/centro_costo/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Centro de Costo" />
                        </ListItemButton> }
                        {/* {userAuth?.roles?.find(role => ['admin']?.includes(role))  &&   */}
                        <ListItemButton component={RouterLink} to={`ventas/centro_costo/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Centro de Costo" />
                        </ListItemButton>
                       
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}