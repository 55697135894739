import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
export const ListCuentasPagar = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    const [cuentasPagar, setCuentasPagar] = useState(false)
    const [retenciones, setRetenciones] = useState(false)
    const [secuenciales, setSecuenciales] = useState(false)
    const [open2, setOpen2] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'cuentas_pagar']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <RequestQuoteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cuentas por Pagar" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton onClick={() => setCuentasPagar(!cuentasPagar)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros" />
                            {cuentasPagar ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={cuentasPagar} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/centro-de-costos`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Centro de Costos' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/tipo_identificacion`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Tipo de identificacion' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/tipo_comprobantes`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Tipo de comprobantes' />
                                </ListItemButton>
                                {/* <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/condiciones_pago`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Condiciones de Pago' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/descuentos_pronto_pago`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Descuentos Pronto Pago' />
                                </ListItemButton> */}
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/tipo_personas`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Tipo Personas' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/grupo_proveedor`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Grupo Proveedor' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/tipo_contribuyentes`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Tipo Contribuyentes' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/sustento_tributario`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Sustento Tributario' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/formas_pago`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Formas Pago' />
                                </ListItemButton>

                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/tipos_compras`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Tipos Compras' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/parametros_geograficos`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Parámetros Geográficos' />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={() => setRetenciones(!retenciones)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Parametros Retención" />
                            {retenciones ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={retenciones} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/catalogo_iva`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Catálogo IVA' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/retenciones_iva`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Retenciones Iva' />
                                </ListItemButton>

                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/retenciones_fuentes`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Retenciones Fuentes' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/catalogo_retenciones`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Catálogo de Retenciones' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/producto_retencion`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Proveedores Recurrentes' />
                                </ListItemButton>
                               
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/parametros/block_factura`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Block de Facturas' />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton component={RouterLink} to={`cuentas_pagar/secuenciales`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Secuenciales" />
                        </ListItemButton>
                        <ListItemButton onClick={() => setOpen2(!open2)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Documentos" />
                            {open2 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open2} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/proveedores_nuevo`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Proveedores Nuevo' />
                                </ListItemButton>
                                {/* <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/solicitud_compras`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Solicitud de Compras' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/orden_compras`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Orden de Compras' />
                                </ListItemButton> */}

                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/creditos_proveedores`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Ingreso de Documentos' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/documentosIngresados`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Documentos Ingresados' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/documentos`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='Documentos Generados' />
                                </ListItemButton>
                                <ListItemButton
                                    component={RouterLink} to={`cuentas_pagar/documentos/ats`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText primary='ATS' />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        {/* <ListItemButton component={RouterLink} to={`cuentas_pagar/reportes/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes" />
                        </ListItemButton> */}
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}