import { DialogContent, Dialog, Button, Grid, DialogActions, DialogTitle } from '@mui/material';
import jsPDF from 'jspdf';
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import logo from './../../../assets/img/logo_violeta.png'
export const PDFiscalizacion = ({ props }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  //
  const [img1, setImg1] = useState('')
  const [img2, setImg2] = useState('')
  const [img3, setImg3] = useState('')
  const [img4, setImg4] = useState('')
  const [img5, setImg5] = useState('')
  const [img6, setImg6] = useState('')
  //
  useEffect(() => {
    if(data){
      img_r()
    }

  }, [data])
  const img_r = () => {
    console.log('Data')
    convertImgToDataURLviaCanvas(data.imagen_caja_abierta_antes, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg1(resized)
      console.log('Loading 3')
    })
    convertImgToDataURLviaCanvas(data.imagen_caja_abierta_despues, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg2(resized)
    })
    convertImgToDataURLviaCanvas(data.imagen_codigo_caja, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg3(resized)
    })
    convertImgToDataURLviaCanvas(data.imagen_equipo_caja, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg4(resized)
    })
    convertImgToDataURLviaCanvas(data.magen_potencia_caja, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg5(resized)
    })
    convertImgToDataURLviaCanvas(data.imagen_serial_equipo_caja, async function (base64_data) {
      const resized = await reduce_image_file_size(base64_data)
      setImg6(resized)
    })
  }
  const onData = (props) => {
    setOpen(true)
    setData(props)
    //image


  }
  const generarPDF = () => {
    var doc = new jsPDF("P", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        pdf.save(`FISCALIZACION_${data.numContrato}.pdf`)
      }
    })
    console.log("Generar pdf")
  }
  //
  var convertImgToDataURLviaCanvas = function (url, callback) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL();
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
    console.log('Loading 1')
  }

  const reduce_image_file_size = async (base64Str, MAX_WIDTH = 900, MAX_HEIGHT = 900) => {
    let resized_base64 = await new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
        console.log('Loading 2')
      }
    });
    return resized_base64;
  }

  //var base64 = getBase64Image(document.getElementById("imageid"));
  //
  return (
    <>
      <Button onClick={() => onData(props)}>PDF</Button>
      <Dialog maxWidth='md' fullWidth open={open}>
        <DialogTitle>PDF FISCALIZACION</DialogTitle>
        <DialogContent dividers>
          {/* <button onClick={() => getBase64Image() }>Test</button> */}
          {
            data &&
            <div id="content" style={{ margin: 'auto', padding: 5, fontSize: 8, width: 590 }}>
              <div style={{ margin: 'auto', paddingLeft: '25px', paddingRight: '25px', paddingTop: '25px' }}>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>

                    </p>
                  </div>
                  <div style={{ flex: 'auto' }}>
                    <p style={{ textAlign: 'center', fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>

                    </p>
                  </div>
                  <div style={{ flex: 'auto' }}>

                    <p style={{ textAlign: 'right', fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      <img style={{ textAlign: 'right' }} width={50} src={logo} />
                    </p>
                  </div>
                </div>

                <div style={{ display: 'flex', flexGrow: 1, paddingBottom: 20 }}>
                  <div style={{ flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {moment(data.fecha_fiscalizacion.toDate().toString()).format('DD/MM/YYYY')}
                    </p>
                  </div>
                  <div style={{ flex: 'auto' }}>
                    <p style={{ textAlign: 'center', fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      <strong>REPORTE DE FISCALIZACION INSTALACION MAL REALIZADA</strong>
                    </p>
                  </div>
                  <div style={{ flex: 'auto' }}>
                    <p style={{ textAlign: 'right', fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.numContrato}
                    </p>
                  </div>
                </div>

                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>ELABORADA POR:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.usuario_que_fiscalizo}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>NOMBRE: </p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.nombres}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>CEDULA: </p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.cedula}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>CORREO:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.correo}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>TELEFONOS:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.celular}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>DIRECCION:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.direccion}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>DATOS GPON:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.gpon}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>COORDENADAS CLIENTE:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    {
                      data.coordenadas_cliente &&
                      <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                        {data.coordenadas_cliente._lat},   {data.coordenadas_cliente._long}
                      </p>
                    }

                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>COORDENADAS CAJA:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    {
                      data.coordeanadas_caja &&
                      <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                        {data.coordeanadas_caja._lat},   {data.coordeanadas_caja._long}
                      </p>
                    }
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.1px solid DarkGray' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>UNIDAD DE INSTALACION:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.unidad_instalacion}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>POTENCIA:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.potencia}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>NOVEDADES:</p>
                  </div>
                  <div style={{ border: '0.01px solid DarkGray', flex: 'auto' }}>
                    <p style={{ fontSize: 8, marginBottom: 1, marginTop: 1, marginLeft: 1, marginRight: 1 }}>
                      {data.novedades}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img1}
                      width='100px'
                    />
                  </div>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img2}
                      width='100px'
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img3}
                      width='100px'
                    />
                  </div>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img4}
                      width='100px'
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexGrow: 1 }}>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img5}
                      width='100px'
                    />
                  </div>
                  <div style={{ border: '0.01px solid DarkGray' }}>
                    <img
                      src={img6}
                      width='100px'
                    />
                  </div>
                </div>
              </div>
            </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cerrar Pdf</Button>
          <Button onClick={() => generarPDF()}>Generar Pdf</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}