import React, { useState, useRef } from 'react'
import { Box, Button, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2'
import { storage, firebase, db } from '../../../firebase'
import Compressor from 'compressorjs';
export const SubirDocumento = ({ solicitud, cambiarEstado, file, nameFile }) => {
    const [fileLoading, setFileLoading] = useState(false)
    const fileRef = useRef(null)
    const [fileName, setFileName] = useState(null)
    const [fileImg, setFileImg] = useState('')

    const handleUploadFileLocal = async (e) => {

        const file = e.target.files[0]
        
        if (file === undefined) {
            return
        }
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "application/pdf") {
            //setFileName(file.name)
          
            cambiarEstado(file)
            const img = URL.createObjectURL(file)
            console.log(img, ' img')
            setFileImg(img)
        } else {
            Swal.fire({ icon: "warning", text: "No aceptamos este tipo de archivos", });
        }
    }
    const handleRemove = () => {
        setFileName('')
        cambiarEstado('')
        setFileLoading(false)
        //fileRef.current.value = null
    }
    const handleUploadFile = async () => {
        if (file) {
            setFileLoading(true)
            if (file.type === "image/png" || file.type === "image/jpeg") {
                new Compressor(file, {
                    quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
                    success: (compressedResult) => {
                        const pictureRef = storage.ref().child('cambios_propietario').child(solicitud.nombres).child(nameFile)
                        const letreroUpload = pictureRef.put(compressedResult)
                        letreroUpload.on("state_changed",
                            (snapshot) => {

                                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log('Upload is ' + progress + '% done');
                                switch (snapshot.state) {
                                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                                        console.log('Upload is paused');
                                        break;
                                    case firebase.storage.TaskState.RUNNING: // or 'running'
                                        console.log('Upload is running');
                                        break;
                                }
                            },
                            (error) => {
                                // Handle unsuccessful uploads
                                setFileLoading(false)
                                Swal.fire({ icon: 'error', text: error, })
                            },
                            () => {
                                letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                    console.log('File available at', downloadURL)

                                    if (nameFile === 'foto_cedula_frontal') {
                                        db.collection('cambio_propietario').doc(solicitud.id).update({
                                            foto_cedula_frontal: downloadURL
                                        })
                                    } else if (nameFile === 'foto_cedula_trasera') {
                                        db.collection('cambio_propietario').doc(solicitud.id).update({
                                            foto_cedula_trasera: downloadURL
                                        })
                                    } else if (nameFile === 'foto_cliente_letrero') {
                                        db.collection('cambio_propietario').doc(solicitud.id).update({
                                            foto_cliente_letrero: downloadURL
                                        })
                                    } else if (nameFile === 'foto_carta_cesion') {
                                        db.collection('cambio_propietario').doc(solicitud.id).update({
                                            foto_carta_cesion: downloadURL
                                        })
                                    }
                                    setFileLoading(false)
                                });
                            }
                        )
                    },
                    error: (error) => {
                        Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                    }
                });
            } else {
                const pictureRef = storage.ref().child('documentos_cliente').child(solicitud.nombres).child(nameFile)
                const letreroUpload = pictureRef.put(file)
                letreroUpload.on("state_changed",
                    (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED: // or 'paused'
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING: // or 'running'
                                console.log('Upload is running');
                                break;
                        }
                    },
                    (error) => {
                        // Handle unsuccessful uploads
                        setFileLoading(false)
                        Swal.fire({ icon: 'error', text: error, })
                    },
                    () => {
                        letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            console.log('File available at', downloadURL)
                            if (nameFile === 'foto_debito') {
                                db.collection('solicitudes').doc(solicitud.uid).update({
                                    foto_debito: downloadURL
                                })
                            }
                            setFileLoading(false)
                        });
                    }
                )
            }

        }
    }
    return (
        <>
            {fileImg ? <Box display='block'>
             
                    <img width={100} src={fileImg} />
                
                <Box>Nombre el archivo: </Box>
            </Box> : 'Por favor, cargue el archivo...'}
            {!file ?
                <Box>
                    <input
                        onChange={(e) => handleUploadFileLocal(e)}
                        style={{ display: 'none' }}
                        id={`contained-button-file${nameFile}`}
                        multiple
                        type="file"
                        ref={fileRef}
                    />
                    <label htmlFor={`contained-button-file${nameFile}`}>
                        <Button style={{ textTransform: 'none' }} variant="contained" color='inherit' disableElevation component="span">
                            Cargar archivo...
                        </Button>
                    </label>
                </Box> :
                <Box display="flex">
                    <Box pr={1}>
                        <Button
                            disableElevation
                            variant='outlined'
                            disabled={fileLoading}
                            onClick={(e) => handleRemove()}
                        >Borrar archivo</Button>
                    </Box>

                </Box>}
        </>
    )
}