import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const ListReportes = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [open, setOpen] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'reportes']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reportes" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`reportes/ver-reportes/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Visualizar Reportes" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`reportes/reportes-de-ventas/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes De Ventas" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`reportes/reportes-retirados/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reportes CHURN" />
                        </ListItemButton>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}