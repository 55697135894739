import {
    Table, TableContainer, TableRow, TableCell, TableBody,
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { db, firebase } from "../../../firebase";
  import moment from 'moment'
  //import { ViewInstalaciones } from "./ViewInstalaciones";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import ExpandLess from '@mui/icons-material/ExpandLess';
  import ExpandMore from '@mui/icons-material/ExpandMore';
  import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton ,
    esES
  } from '@mui/x-data-grid';
  import VerificarVoucher from "../VerificarVoucher";
  
  export const AgendaVerificacion = () => {
    function Estado(estado) {
      const d = estado.estado
      let name;
      let color;
      //Controlamos el estado de la factiblidad
      if (d.estado === 0) {
        name = 'POR INSTALAR'
        color = 'warning'
      }
      if (d.cambio_domicilio === true) {
        name = 'CAMBIO DOMICILIO'
        color = 'info'
      }
      return (
        <Chip label={name} size="small" variant="outlined" color={color} />
      )
    }
    function EstadoPagado(estado) {
      const d = estado.estado
  
      let name;
      let color;
      //Controlamos el estado de la factiblidad
  
      if (d.estado_pagado === 0 || !d.estado_pagado) {
        name = 'ESPERANDO'
        color = 'warning'
      }
      if (d.estado_pagado === 1) {
        name = 'VERIFICAR'
        color = 'info'
      }
      if (d.estado_pagado === 2) {
        name = 'PAGADO'
        color = 'primary'
      }
      if (d.estado_pagado === 3) {
        name = 'RECHAZADO'
        color = 'error'
      }
   
      return (
        <Chip label={name} size="small" color={color} />
      )
    }
    const [open, setOpen] = useState(false)
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    //
    const current = new Date();
    const [startDate, setStartDate] = useState(new Date("2022-07-3"))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    //
    useEffect(() => {
      LoadData()
    }, [])
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    const LoadData = (startAfterDoc, persistMessages = []) => {
      let ref = db.collection("tecnicos").where("estado_pagado", "==", 1)
  
      //Si tiene algun doc anterior se agrega acontinuacion
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(12, 0, 0, 0)
        let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('fechaHora', '>=', startfulldate)
        ref = ref.where('fechaHora', '<=', endfulldate)
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
          setData(data)
        })
      }
    }
 
  
    const columns = [
  
      {
        field: 'estado2', headerName: 'ESTADO PAGO', width: 100,
        renderCell: (params) => {
          return <EstadoPagado estado={params.row} />
        }
      },
  
      {
        field: '', headerName: '', width: 90,
        renderCell: (params) => {
          return params.row.estado_pagado == 1 && <VerificarVoucher props={params.row} />
        }
      },
      { field: 'user_id_microwisp', headerName: 'ID CLIENTE', width: 200 },
      { field: 'id_factura', headerName: 'ID FACTURA', width: 200 },
  
      { field: 'id', headerName: 'CONTRATO', width: 200 },
      { field: 'cedula', headerName: 'CEDULA', width: 400 },
      { field: 'nombres', headerName: 'CLIENTE', width: 400 },
      { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
      /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
      {
        field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
        valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
      },
      {
        field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 150,
        valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
      },
      {
        field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
        valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
      },
      { field: 'telefono', headerName: 'TELEFONO', width: 150 },
      { field: 'celular', headerName: 'CELULAR', width: 150 },
      { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
      { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
      { field: 'planNombre', headerName: 'PLAN', width: 150 },
      { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
      { field: 'vendedor', headerName: 'VENDEDOR', width: 200 },
      {
        field: 'referido', headerName: 'REFERIDO', width: 150,
        valueGetter: ({ value }) => value === undefined ? 'NO' : value
      },
      {
        field: 'flotaReferida', headerName: 'FLOTA REFERIDA', width: 150,
        valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
      },
      {
        field: 'fechaHora', headerName: 'FECHA INSTALACION', type: 'dateTime', width: 250,
        valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
      },
      { field: 'direccion', headerName: 'DIRECCION', width: 200 },
      { field: 'zona', headerName: 'ZONA', width: 200 },
      { field: 'sector', headerName: 'SECTOR', width: 200 },
      { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
      { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
      { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
      {
        field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
        valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
      },
      {
        field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 250,
        valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
      },
    ]
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
      </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
      <GridToolbarContainer {...props}>
        <GridToolbarFilterButton  />
        <CustomExportButton />
        {/* <GridToolbarQuickFilter /> */}
      </GridToolbarContainer>
    );
  
    return (
      <>
        <Box p={2}>
          <Typography variant="h5" ><strong>VERIFICACION VOUCHER</strong> </Typography>
        </Box>
        <Box pt={1} pl={1}>
          <Box display='flex' style={{ textTransform: 'uppercase' }} >
            <Typography variant="button" >
              <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography >
            <Typography variant="button" style={{ paddingLeft: 10 }}>
              <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
          </Box>
        </Box>
        <Box pl={1} pt={1} display="flex" alignItems='center' >
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            </Box>
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
            </Collapse>
          </Box>
        </Box>
        <Paper>
          <Box sx={{ height: 600, width: '100%' }}>
            
            <DataGrid
              rows={data ? data : []}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              density="compact"
              // disableColumnFilter
              // disableColumnSelector
              // disableDensitySelector
              //hideFooterPagination
              columns={columns}
              components={{
                Toolbar: CustomToolbar,

              }}
  
            
            // componentsProps={{
            //   toolbar: {
            //     showQuickFilter: true,
            //     quickFilterProps: { debounceMs: 500 },
            //   },
            // }}
            />
          </Box>
        </Paper>
      </>
    )
  
  }
  
  