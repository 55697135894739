import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { db } from "../../../../../firebase";
export const UpdateItem = ({ props, tabla }) => {
    const [open, setOpen] = useState(null)
    const [data, setData] = useState('')
    const [nombre, setNombre] = useState(props.nombre)
    const [loading, setLoading] = useState(false)
    const onData = () => {
        setOpen(true)
        setData(props)
    }

    const updateProducto = async () => {
        setLoading(true)
        await db.collection(tabla).doc(data.id.toString()).update({
            nombre: nombre,
        }).then(() => {
            setOpen(false)
            setLoading(false)
            Swal.fire({ icon: "success", text: "Se ha actualizado la categoria con exito" });
        })
    }
    return (
        <>
            <Tooltip title="Actualizar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>EDITAR</strong>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        label="NOMBRE"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button
                        onClick={() => updateProducto()}
                        variant="contained"
                        disabled={loading}
                    >
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};