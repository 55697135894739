import { Typography, Box, Paper, Chip, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../firebase";

import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import Collapse from "@mui/material/Collapse";
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
function Estado(estado) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado.props == 0) {
        name = "Esperando..";
        color = "warning";
    } else if (estado.props === 1) {
        name = "No factible";
        color = "info";
    } else if (estado.props === 2) {
        name = "APROBADAS";
        color = "success";
    }
    return <Chip size='small' label={name} color={color} />;
}
export const ListEncuesta = () => {
    const [open, setOpen] = useState(null);
    const [data, setData] = useState();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 518400000))
    const [endDate, setEndDate] = useState(new Date())
    useEffect(() => {
        LoadData();
    }, [startDate]);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth;

    const LoadData = (startAfterDoc, persistMessages = []) => {
        let ref = db.collection("encuestas2").orderBy("created", "desc")
     
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where("created", ">=", startfulldate);
            ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.onSnapshot((snapshot) => {
               
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                setData(data);
            });
        }

    }
    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }
    const onChange2 = () => {
        LoadData()
    }
    const [total, setTotal] = useState(0);
    const columns = [
        {
            field: 'created', headerName: 'FECHA DE CREACION DE SOLICITUD', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
        },

        { field: 'id', headerName: 'CONTRATO', width: 200 },
        { field: 'nombre', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
        { field: 'cedula', headerName: 'CEDULA', width: 150 },
     
        { field: 'res1', headerName: '¿Tuvo algún problema con el servicio durante los 15 días de prueba?', width: 200 },
        { field: 'res2', headerName: '¿Qué tan satisfecho esta con la calidad de la imagen y el sonido?', width: 200 },
        { field: 'res3', headerName: '¿Qué tan satisfecho esta con la variedad de contenido disponible?', width: 200 },
        { field: 'res4', headerName: '¿Qué tan satisfecho esta con la factibilidad de uso del servicio?', width: 200 },
        { field: 'res5', headerName: 'En general, ¿cómo calificar su experiencia con el servicio de Tv por internet?', width: 200 },
        { field: 'res6', headerName: '¿Recomendaría este servicio un amigo o familiar?', width: 200 },
        { field: 'res7', headerName: 'Del contenido que disfruto, ¿qué tipo de canales fueron sus favoritos?', width: 200 },
        { field: 'res8', headerName: 'Adicional al contenido que disfruto, ¿qué canales les gustaría poder visualizar posteriormente?', width: 200 },
        { field: 'res10', headerName: '¿qué canales les gustaría poder visualizar?', width: 200 },
        { field: 'res9', headerName: '¿Porque no ha contratado este tipo de servicios, anteriormente?', width: 200 },
      
    ]
    const csvOptions = {
        delimiter: ';',
        fileName: `ENCUESTAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    function CustomFooterTotalComponent(props) {
        return ''
    }
    return (
        <>
            <Box pt={1} pl={1}>
                <Typography>
                    <strong>({data && data.length})</strong> ENCUESTAS
                </Typography>
                <Box display="flex" style={{ textTransform: "uppercase" }}>
                    <Typography variant="button">
                        <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                    <Typography variant="button" style={{ paddingLeft: 10 }}>
                        <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                </Box>
            </Box>
            <Box pl={1} >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
                </Box>
                <Box>
                    <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                        </Box>
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box p={2}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                            <Button
                                size="small"
                                disableElevation
                                variant="contained"
                                onClick={onChange2}
                            >
                                Aplicar busqueda
                            </Button>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            <Paper>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={data ? data : []}
                        density="compact"
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
