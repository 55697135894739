import {
    Box,
    Container,
    Grid,
    Table,
    Stack,
    TableHead,
    TableRow,
    TableCell as StyledTableCell,
    TableBody,
} from '@mui/material'
import React from 'react'
import logo1 from '../../../../assets/img/contrato/image005.jpg'
import moment from 'moment'

export const Formato1 = (props) => {
    const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
    return (
        <div
            style={{
                position: "absolute",
                top: "20px",
                width: "90%"
            }}>
            <Stack style={{ position: "absolute" }} direction="row">
                <img src={logo1} alt="soei" width="90" />

            </Stack>
            <p style={{ fontSize: "11px" }} align="right">
                <strong>Usuario:</strong>    {props.usuario}
                <br />
                <strong>Fecha:</strong>      {fecha}
            </p>
        </div>
    )
};

export const Formato2 = (props) => {
    const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
    return (
        <div
            style={{
                position: "absolute",
                top: "20px",
                width: "90%"
            }}>
            <Stack style={{ position: "absolute" }} direction="row">
                <img src={logo1} alt="soei" width="90" />

            </Stack>
            <p style={{ fontSize: "11px", paddingTop: '10px' }} align="right">
                Fecha:   {fecha}
            </p>
        </div>
    )
};


export const Plantilla1 = (props) => {
    const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
    return (
        <div>
            {props.pageNum == 1 &&
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                    <p style={{ fontSize: "11px" }} align="right">
                        <strong>Usuario:</strong>    HWILA
                        <br />
                        <strong>Fecha:</strong>      {fecha}
                    </p>
                </div>
            }
            <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                Pag {props.pageNum} de {props.totalPages}
            </p>
        </div >


    )
};

export const Plantilla2 = (props) => {
    const fecha = moment(new Date().toString()).format('DD/MM/YYYY');

    return (
        <div>
            {props.pageNum == 1 &&
                <div
                    style={{
                        position: "absolute",
                        top: "20px",
                        width: "90%"
                    }}>
                    <Stack style={{ position: "absolute" }} direction="row">
                        <img src={logo1} alt="soei" width="90" />
                    </Stack>
                    <p style={{ fontSize: "11px", paddingTop: '5px' }} align="right">
                        Fecha:   {fecha}
                    </p>
                </div>
            }
            <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                Pag {props.pageNum} de {props.totalPages}
            </p>
        </div >


    )
};