export const listRolesReducers = (state = { roles: [] }, action) => {
    switch (action.type) {
        case 'ROLES_LIST_REQUEST':
            return { ...state, loading: true }
        case 'ROLES_LIST_SUCCESS':
            return { ...state, loading: false, roles: action.payload }
        case 'ROLES_LIST_FAIL':
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}