import {
    Box, Typography, Grid, FormControl,
    CardContent, Select, MenuItem, InputLabel, Card, CardHeader
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css"
import { useParams } from 'react-router-dom'
import InputValidation2 from "../../../../components/utils/InputValidation2"
export const ConfiguracionTab = () => {
    const params = useParams()
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const [configurarUtilizandoPlantilla, setConfigurarUtilizandoPlantilla] = useState('')
    //
    const configPlantillas = useSelector(state => state.configPlantillasList)
    const { config_plantillas, loading: loadingConfig } = configPlantillas
    //Datos del Cliente
    const clientDetails = useSelector(state => state.clientDetails)
    const { client_details } = clientDetails
    //
    const [tipo, setTipo] = useState('')
    const [diaPago, setDiaPago] = useState('')
    const [crearFactura, setCrearFactura] = useState('')
    const [tipoImpuesto, setTipoImpuesto] = useState('')
    const [diasGracia, setdiasGracia] = useState('')
    const [aplicarCorte, setAplicarCorte] = useState('')
    const [impuesto1, setImpuesto1] = useState({ campo: 0, valido: true })
    const [impuesto2, setImpuesto2] = useState({ campo: 0, valido: true })
    const [impuesto3, setImpuesto3] = useState({ campo: 0, valido: true })
    const [recordatorio1, setRecordatorio1] = useState('')
    const [recordatorio2, setRecordatorio2] = useState('')
    const [recordatorio3, setRecordatorio3] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
     
        loadConfigPlantilla(client_details.configPlantillaId)
        console.log(loadingConfig, ' configuracion')
    }, [dispatch])
    //
    const loadConfigPlantilla = (e) => {
        const p = config_plantillas.filter((item) => item.id === e)
        setTipo(p[0].tipo)
        setDiaPago(p[0].diaPago)
        setCrearFactura(p[0].crearFactura)
        setTipoImpuesto(p[0].tipoImpuesto)
        setdiasGracia(p[0].diasGracia)
        setAplicarCorte(p[0].aplicarCorte)
        setRecordatorio1(p[0].recordatorio1)
        setRecordatorio2(p[0].recordatorio2)
        setRecordatorio3(p[0].recordatorio3)
        setConfigurarUtilizandoPlantilla(e)
    }
    const handleConfig = (e) => {
        setTipo('')
        setDiaPago('')
        setCrearFactura('')
        setTipoImpuesto('')
        setdiasGracia('')
        setAplicarCorte('')
        setRecordatorio1('')
        setRecordatorio2('')
        setRecordatorio3('')
        //Cargamos la plantilla
        loadConfigPlantilla(e)
    }
    const listRecordatorio = [
        '1 DIA ANTES',
        '2 DIA ANTES',
        '3 DIA ANTES',
        '4 DIA ANTES',
        '5 DIA ANTES',
        '6 DIA ANTES',
        '7 DIA ANTES',
        '8 DIA ANTES',
        '9 DIA ANTES',
        '10 DIA ANTES',
        '1 DIA DESPUES',
        '2 DIA DESPUES',
        '3 DIA DESPUES',
        '4 DIA DESPUES',
        '5 DIA DESPUES',
        '6 DIA DESPUES',
        '7 DIA DESPUES',
        '8 DIA DESPUES',
        '9 DIA DESPUES',
        '10 DIA DESPUES',
        '11 DIA DESPUES',
        '12 DIA DESPUES',
        '13 DIA DESPUES',
        '14 DIA DESPUES',
        '15 DIA DESPUES',
        '16 DIA DESPUES',
        '17 DIA DESPUES',
        '18 DIA DESPUES',
        '19 DIA DESPUES',
        '20 DIA DESPUES',
        '21 DIA DESPUES',
        '22 DIA DESPUES',
        '23 DIA DESPUES',
        '24 DIA DESPUES',
        '25 DIA DESPUES',
    ]
    const listDiasPago = Array.from({ length: 28 }, (_, i) => i + 1)
    const listDiasGracias = Array.from({ length: 25 }, (_, i) => i + 1)
    const listCorte = Array.from({ length: 12 }, (_, i) => i + 1)
    return (
        <>
            <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='PLANTILLAS DE CONFIGURACION' />
            <CardContent>
                {
                    loadingConfig && 'cargo'
                }
                <Grid container spacing={5}>
                    <Grid item xs={6}>
                        <Box pb={2}>
                            <InputLabel>
                                CONFIGURAR UTILIZANDO PLANTILLA:
                            </InputLabel>
                            <FormControl fullWidth>
                                <Select sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                    value={configurarUtilizandoPlantilla}
                                    onChange={(e) => handleConfig(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value=''>--</MenuItem>
                                    {config_plantillas && config_plantillas.map((user, key) => (
                                        <MenuItem value={user.id} key={key}>
                                            {user.nombrePlantilla}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item lg={6} md={12}>
                        <Card variant='outlined'>
                            <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='FACTURACION' />
                            <CardContent variant='outlined'>
                                <Box pb={3}>
                                    <InputLabel>
                                        TIPO
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={tipo}
                                            onChange={(e) => setTipo(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem value="PREPAGO">{`PREPAGO (ADELANTADO)`}</MenuItem>
                                            <MenuItem value="POSPAGO">{`POSPAGO (VENCIDO)`}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        DIA DE PAGO:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            value={diaPago}
                                            onChange={(e) => setDiaPago(e.target.value)}
                                            size="small"
                                        >
                                            {listDiasPago.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        CREAR FACTURA:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={crearFactura}
                                            onChange={(e) => setCrearFactura(e.target.value)}
                                            size="small"
                                        >
                                            {listDiasGracias.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name} DIAS ANTES
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        TIPO IMPUESTO:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={tipoImpuesto}
                                            onChange={(e) => setTipoImpuesto(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem value="IMPUESTOS INCLUIDOS">IMPUESTOS INLCUIDOS</MenuItem>
                                            <MenuItem value="MAS IMPUESTOS">MAS IMPUESTOS</MenuItem>
                                            <MenuItem value="NINGUNO">NINGUNO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        DIAS DE GRACIA:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={diasGracia}
                                            onChange={(e) => setdiasGracia(e.target.value)}
                                            size="small"
                                        >
                                            {listDiasGracias.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name} DIAS
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        APLICAR CORTE:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={aplicarCorte}
                                            onChange={(e) => setAplicarCorte(e.target.value)}
                                            size="small"
                                        >
                                            <MenuItem value="DESACTIVADO">DESACTIVADO</MenuItem>
                                            {listCorte.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name} MES VENCIDO
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box py={2}>
                                    <Typography>OTROS IMPUESTOS</Typography>
                                </Box>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <InputValidation2
                                            estado={impuesto1}
                                            cambiarEstado={setImpuesto1}
                                            label="IMPUESTO #1 (%):"
                                            name="impuesto1"
                                            type='number'
                                        />
                                        <InputValidation2
                                            estado={impuesto3}
                                            cambiarEstado={setImpuesto3}
                                            label="IMPUESTO #3 (%):"
                                            name="impuesto3"
                                            type='number'
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputValidation2
                                            estado={impuesto2}
                                            cambiarEstado={setImpuesto2}
                                            label="IMPUESTO #2 (%):"
                                            name="impuesto2"
                                            type='number'
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <Card variant='outlined'>
                            <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='NOTIFICACIONES' />
                            <CardContent variant='outlined'>
                                <Box pb={2}>
                                    <InputLabel>
                                        Recordatorio #1:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={recordatorio1}
                                            onChange={(e) => setRecordatorio1(e.target.value)}
                                            size="small"
                                        >
                                            {listRecordatorio.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Recordatorio #2:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={recordatorio2}
                                            onChange={(e) => setRecordatorio2(e.target.value)}
                                            size="small"
                                        >
                                            {listRecordatorio.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Recordatorio #3:
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 }
                                            }}
                                            value={recordatorio3}
                                            onChange={(e) => setRecordatorio3(e.target.value)}
                                            size="small"
                                        >
                                            {listRecordatorio.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                >
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    )
}