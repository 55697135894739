import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MenuItem, FormControl, InputLabel, Select, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { listsCantones, listsParroquia, listsProvincia, listsSector } from '../../redux/actions/parametersActions';
export default function SelectGeografico({ cambiarEstado }) {
    const dispatch = useDispatch()
    const provinciaList = useSelector(state => state.provinciaList);
    const { provincias, loading } = provinciaList
    const cantonesList = useSelector(state => state.cantonesList);
    const { cantones, loading: loadingCantones } = cantonesList
    const parroquiaList = useSelector(state => state.parroquiaList);
    const { parroquias, loading: loadingParroquias } = parroquiaList
    const sectorList = useSelector(state => state.sectorList);
    const { sectores, loading: loadingSector } = sectorList
    const [provincia, setProvincia] = useState('')
    const [canton, setCanton] = useState('')
    const [parroquia, setParroquia] = useState('')
    const [sector, setSector] = useState('')
    useEffect(() => {
        dispatch(listsProvincia())
    }, [dispatch])
    const changeProvincia = (e) => {
        setProvincia(e)
        setCanton(e)
        dispatch(listsCantones(e))
        cambiarEstado({ provincia: e })
    }
    const changeCanton = (e) => {
        setCanton(e)
        setParroquia(e)
        dispatch(listsParroquia(provincia, e))
        cambiarEstado({ provincia: provincia, canton: e })
    }
    const changeParroquia = (e) => {
        setParroquia(e)
        dispatch(listsSector(provincia, canton, e))
        cambiarEstado({ provincia: provincia, canton: canton, parroquia: e })
    }
    const changeSector = (e) => {
        setSector(e)
        cambiarEstado({ provincia: provincia, canton: canton, parroquia: parroquia, sector: e })
    }
    return (
        <>
            <Box pb={3}>
                <FormControl disabled={loading} fullWidth>
                    <InputLabel>PROVINCIA</InputLabel>
                    <Select
                        value={provincia}
                        label="PROVINCIA"
                        onChange={(e) => changeProvincia(e.target.value)}>
                        {provincias &&
                            provincias.map(item => (
                                <MenuItem key={item.id} value={item.id} >
                                    {item.id}
                                </MenuItem>
                            ))}
                    </Select>
                    {loading && <LinearProgress />}
                </FormControl>
            </Box>
            <Box pb={3}>
                <FormControl disabled={!provincia || loadingCantones} fullWidth>
                    <InputLabel>CANTON</InputLabel>
                    <Select
                        label="CANTON"
                        value={canton}
                        onChange={(e) => changeCanton(e.target.value)}>
                        {cantones &&
                            cantones.map(item => (
                                <MenuItem key={item.id} value={item.id} >
                                    {item.id}
                                </MenuItem>
                            ))}
                    </Select>
                    {loadingCantones && <LinearProgress />}
                </FormControl>
            </Box>
            <Box pb={3}>
                <FormControl disabled={!parroquia || loadingParroquias} fullWidth>
                    <InputLabel>PARROQUIA</InputLabel>
                    <Select
                        label="PARROQUIA"
                        value={parroquia}
                        onChange={(e) => changeParroquia(e.target.value)}>
                        {parroquias &&
                            parroquias.map(item => (
                                <MenuItem key={item.id} value={item.id} >
                                    {item.id}
                                </MenuItem>
                            ))}
                    </Select>
                    {loadingParroquias && <LinearProgress />}
                </FormControl>
            </Box>
            <Box pb={1}>
                <FormControl disabled={!parroquia || loadingSector} fullWidth>
                    <InputLabel>SECTOR</InputLabel>
                    <Select
                        label="SECTOR"
                        value={sector}
                        onChange={(e) => changeSector(e.target.value)}>
                        {sectores &&
                            sectores.map(item => (
                                <MenuItem key={item.id} value={item.id} >
                                    {item.id}
                                </MenuItem>
                            ))}

                    </Select>
                    {loadingSector && <LinearProgress />}

                </FormControl>
            </Box>
        </>
    )
}