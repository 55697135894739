import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
const data = [
    {
        name: 'Agenda',
        link: 'agenda/0'
    },
    {
        name: 'Tickets',
        link: 'tickets'
    },
]

export const ListPlaneacion = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [open, setOpen] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'planeacion']?.includes(role))? <>
                <ListItemButton onClick={() => setOpen(!open)}>
                    <ListItemIcon>
                        <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Planeacion" />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {data.map((item, index) => (
                            <ListItemButton
                                key={item.link}
                                component={RouterLink} to={`/Planeacion/${item.link}/`}
                            >
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText style={{ textTransform: 'capitalize' }} primary={item.name} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}