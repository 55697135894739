import { Box, Tab, Tabs, tabsClasses, AppBar } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { Anexo1 } from './Anexo1';
import { Anexo2 } from './Anexo2';
import { Anexo3 } from './Anexo3';
import { Anexo4 } from './Anexo4';
import { Anexo5 } from './Anexo5';
import { Contrato } from './Contrato';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const ClientesDocumentos = () => {
    const [tab, setTab] = useState(0)
    function tabs(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const handleChange = (event, newValue) => {
        setTab(newValue)
    }
    return (
        <>
            <AppBar elevation={0}  position="static">
                <Tabs
                    value={tab}
                    variant="scrollable"
                    indicatorColor="secondary"
                      textColor="inherit"
                    allowScrollButtonsMobile
                    onChange={handleChange}
                    sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    }}
                    aria-label="solicitud de servicio">
                    <Tab label="ANEXO 1" />
                    <Tab label="ANEXO 2" />
                    <Tab label="ANEXO 3" />
                    <Tab label="ANEXO 4" />
                    <Tab label="ANEXO 5" />
                    <Tab label="CONTRATO" />
                </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
                <Anexo1 />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Anexo2 />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Anexo3 />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Anexo4 />
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <Anexo5 />
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <Contrato />
            </TabPanel>
        </>
    )
}
