import {
    Button, Dialog, DialogContent, DialogActions,
    DialogTitle, CircularProgress, Stack,
    CardContent, Card
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, { useEffect, useState } from "react";
import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";


import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import moment from 'moment'
import { db } from "../../../firebase";
import { ReporteMovimientoStock } from "./PlantillasPDF/ReporteMovimientoStock"

export const DescargarMovimientos = ({ props, desde, hasta, modo }) => {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dataItems, setDataItems] = useState();
    const [fecha1, setFecha1] = useState();
    const [fecha2, setFecha2] = useState();

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (!exporter.current) {
            onData();
        }
        if (exporter.current) {
            exporter.current.save();
        }
    };

    useEffect(() => {
        onData();
    }, [])

    useEffect(() => {
        if (!open2) setOpen(false)
    }, [open2])

    useEffect(() => {
        onData();
    }, [desde, hasta, props])


    async function onData(opcion) {
        if (opcion < 3) setOpen(true);
        if (desde && hasta) {
            setFecha1(moment(desde.toString()).format('DD/MM/YYYY'))
            setFecha2(moment(hasta.toString()).format('DD/MM/YYYY'))
        }

        let items = [];
        for (const element of props) {
            await db.collection("reporte_movimientos").doc(element.id).collection('items').get().then((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        let seriales = []
                        if (doc.data().series) {
                            let series = doc.data().series
                            series.map((ser) => {
                                if (ser.serie !== 0) {
                                    seriales.push(' ' + ser.serie)
                                }
                            })
                        }

                        return {
                            ...doc.data(),
                            tipoMovimiento: (element.uid_orden_compra
                                ? element.uid_orden_compra : (element.relacion
                                    ? element.relacion : 'No')),
                            id: doc.id,
                            bodega2: element.bodega,
                            user: element.user,
                            series: (seriales.length !== 0 ? seriales.toString() : 'No asignado'),
                            comentario: element.observacion_adicional,
                            estado_producto: "BUEN ESTADO",
                            fecha: element.created === undefined ? "No Asignado" :
                                moment(element.created.toDate().toString()).format('DD/MM/YYYY'),
                            id2: (element.uid_solicitud_pedido === undefined || element.uid_solicitud_pedido === '') ?
                                element.uidSolicitud : element.uid_solicitud_pedido,
                        };
                    }),
                ];
                let ingresos = 0;
                let egresos = 0;
                let valor = 0;

                for (const element of data) {
                    valor = element.cantidad_solicitada
                    if (element.tipo_movimiento == "ingreso") {
                        ingresos = ingresos + valor
                    } else if (element.tipo_movimiento == "egreso") {
                        egresos = egresos + valor
                    }
                    element.motivo = element.motivo === undefined || element.motivo === '' ?
                        "No Asignado" : element.motivo;
                    element.flota = element.flota === undefined || element.flota === '' ?
                        "No Asignado" : element.flota;


                    if (element.series !== 'No asignado') {
                        let lista = element.series.split(', ')
                        for (const ele of lista) {
                            items.push({
                                ...element,
                                series2: ele,
                                cantidad_solicitada: 1,
                            });
                        }
                    } else {
                        items.push({
                            ...element,
                            series2: 'No Asignado',
                        });
                    }
                }
            });
        }
        if (items.length == 0) setOpen(false)
        setDataItems(items);
        if (opcion < 3) setOpen2(true);


    }


    return (
        <>
            {!modo &&
                <>
                    <Button onClick={() => onData(2)}
                        size="small" color="error" variant="outlined"
                        startIcon={<PictureAsPdfIcon />}
                    >
                        {" "}
                        DESCARGAR PDF
                    </Button>

                    <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="lg">
                        <DialogTitle>
                            <strong>Descargar Movimientos Stock </strong>
                        </DialogTitle>
                        <DialogContent>
                            <ReporteMovimientoStock contenido={dataItems} inicio={desde} fin={hasta} />
                            {/* <Container>
                                <div className="example-config">
                                    <Button
                                        onClick={exportPDFWithMethod}
                                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                                        color="error" size='small' variant="outlined"
                                        startIcon={<PictureAsPdfIcon />}>
                                        <strong>PDF</strong>
                                    </Button>
                                </div>
                                <div className="border rounded p-2">
                                    <PDFExport>
                                        <div ref={container}>
                                            <Container maxWidth='xl'>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                                        <strong>INTERCOMMERCE S A</strong>
                                                    </Grid>
                                                    <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                                        <strong>REPORTE MOVIMIENTO STOCK</strong>
                                                    </Grid>
                                                    <Grid item xs={4} align="center" />
                                                    <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                                        <strong>0992988061001</strong>
                                                    </Grid>
                                                    <Grid item xs={4} align="center" style={{ fontSize: "11px" }}>
                                                        <strong>{fecha1} - {fecha2}</strong>
                                                    </Grid>
                                                    <Grid item xs={4} align="center" />
                                                    <Grid item xs={4} align="left" style={{ fontSize: "11px" }}>
                                                        <strong>AV. NARCISA DE JESUS 6421 Y PUENTE DE LA JOYA</strong>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                            <br />
                                            <Grid2 data={dataItems} >
                                                <Column field="id2" title="Id" headerCell={headerColumn} cell={customCell} />
                                                <Column field="fecha" title="Fecha" headerCell={headerColumn} cell={customCell} />
                                                <Column field="fullName" title="Codigo" headerCell={headerColumn} cell={customCell} />
                                                <Column field="descripcion" title="Producto" headerCell={headerColumn} cell={customCell} />
                                                <Column field="estado_producto" title="Estado Producto" headerCell={headerColumn} cell={customCell} />
                                                <Column field="motivo" title="Subtipo" headerCell={headerColumn} cell={customCell} />
                                                <Column field="flota" title="Cuadrilla" headerCell={headerColumn} cell={customCell} />
                                                <Column field="cantidad_solicitada" title="Cant." headerCell={headerColumn} cell={customCell} />
                                                <Column field="user" title="Usuario" footerCell={unidadesTotales2} headerCell={headerColumn} cell={customCell} />
                                                <Column field="comentario" title="Comentario" footerCell={unidadesTotales} headerCell={headerColumn} cell={customCell} />
                                            </Grid2>
                                        </div>
                                    </PDFExport>
                                </div>
                            </Container> */}
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
            {modo &&
                <>
                    <Button onClick={() => onData(1)} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                    >
                        {" "}
                        DESCARGAR EXCEL
                    </Button>
                    <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xs">
                        <DialogTitle>
                            <strong>Reporte Movimientos</strong> <br />
                            <p style={{ fontSize: '16px' }}>{fecha1} - {fecha2}</p>
                        </DialogTitle>
                        <DialogContent>
                            <Card>
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                                        >
                                            {" "}
                                            DESCARGAR EXCEL
                                        </Button>
                                    </Stack>
                                </CardContent>
                            </Card>

                            <ExcelExport data={dataItems} fileName={"Reporte Movimiento " + fecha1 + " - " + fecha2 + " .xlsx"} ref={exporter} filterable={true} >
                                <ExcelExportColumn field="tipoMovimiento" title="Relacion" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="id2" title="Id" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="fecha" title="Fecha" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="bodega2" title="Bodega" headerCellOptions={{ background: "#672780" }} />
                                {/* <ExcelExportColumn field="fullName" title="Serial" headerCellOptions={{ background: "#672780" }} /> */}
                                <ExcelExportColumn field="fullName" title="Código" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="series2" title="Series" headerCellOptions={{ background: "#672780" }} width={200} />
                                <ExcelExportColumn field="descripcion" title="Producto" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="estado_producto" title="Estado Producto" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="motivo" title="Subtipo" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="flota" title="Cuadrilla" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="cantidad_solicitada" title="Cant." headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="user" title="Usuario" headerCellOptions={{ background: "#672780" }} />
                                <ExcelExportColumn field="comentario" title="Comentario" headerCellOptions={{ background: "#672780" }} />
                            </ExcelExport>

                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>
                </>
            }

            <Dialog open={open} fullWidth maxWidth="xs">
                <DialogContent>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogContent>
            </Dialog>

        </>
    );
};