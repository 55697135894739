import {
    Box, Button, Container,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Grid, LinearProgress,
    Tab, TextField
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DataGrid, esES } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { TabContext, TabList } from '@mui/lab';


export const Notas = (props) => {

    const [asunto, setAsunto] = useState('')
    const [mensaje, setMensaje] = useState('')

    const [nombre, setNombre] = useState('')
    const [id, setId] = useState('')
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)

    const [tab, setTab] = useState('0');
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        setId(props.id)
        setNombre(props.nombre)
    }, [props]);

    function limpiar() {
        setNombre('')
        setAsunto('')
        setMensaje('')
    }

    const handleOpen = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };


        fetch(`https://serverbd.intercommerce.com.ec/apiInfoMikrowisp/?notas=${id}`,
            requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                res.reverse()
                setData([...res.map((item) => {
                    let str2 = (item.mensaje ? item.mensaje.replaceAll('&lt;p&gt;', '') : '')
                    str2 = (str2 ? str2.replaceAll('&lt;/p&gt;', '') : '')

                    return {
                        ...item,
                        mensaje: str2,
                    }
                })
                ])
                setOpen(true)
            })
            .catch(error => {
                console.log('error', error)
            });
    }


    const crearNota = () => {
        let formdata = new FormData();
        formdata.append("idcliente", id);
        formdata.append("asunto", asunto);
        formdata.append("mensaje", mensaje);

        let requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://serverbd.intercommerce.com.ec/apiInfoMikrowisp/", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                Swal.fire({ icon: 'success', text: result })
                setOpen(false)
                limpiar()
            }).catch((error) => {
                Swal.fire({ icon: 'error', text: `Error: ${error}` })
                setOpen(false)
                limpiar()
            });
    }


    const columns = [
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', width: 100 },
        { field: 'asunto', headerClassName: 'super-app-theme--header', headerName: 'Asunto', width: 150 },
        {
            field: 'mensaje', headerClassName: 'super-app-theme--header', headerName: 'Mensaje', width: 350,
            renderCell: (params) => {
                return (
                    <>
                        <br />
                        {params.row.mensaje}
                        <br /><br />
                    </>
                )
            }
        },
        { field: 'fecha', headerClassName: 'super-app-theme--header', headerName: 'Fecha', width: 200 },

    ]
    return (
        <>

            <Button
                fullWidth variant="contained" color='secondary' onClick={() => {
                    handleOpen()
                }}  >
                NOTAS
            </Button>

            <Dialog open={open} fullWidth onClose={() => {
                limpiar()
                setOpen(false)
            }} maxWidth="md">
                <DialogTitle sx={{ bgcolor: '#1A6EB9', color: "white" }}><strong>NOTAS DEL CLIENTE ID: {id}</strong>
                </DialogTitle>
                <DialogContent>
                    <TabContext value={tab}>
                        <Box px={2}>
                            <Container maxWidth='xl' >
                                <Box sx={{ borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} variant="scrollable">
                                        <Tab label="Notas" value={'0'} />
                                        <Tab label="Crear Nota" value={'1'} />
                                    </TabList>
                                </Box>
                            </Container>
                        </Box>
                        {tab === '0' &&
                            <Container>
                                <Box sx={{
                                    height: 400, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    '& .super-app-theme--header': {
                                        backgroundColor: 'LightSlateGray',
                                        color: 'white',
                                        fontSize: '16px'
                                    },
                                }}>
                                    <DataGrid
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                        }}
                                        hideFooterSelectedRowCount={true}
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={columns}
                                        density='compact'
                                        rows={data ?? []}
                                        getRowHeight={() => 'auto'}
                                        getEstimatedRowHeight={() => 200}
                                    />
                                </Box>
                            </Container>
                        }

                        {tab === '1' &&
                            <Container>
                                <Box sx={{ height: 400 }}>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} xs={12}>
                                            <a style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                <strong>ASUNTO:</strong></a>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={asunto}
                                                onChange={(e) => setAsunto(e.target.value)}
                                                size="small"
                                                fullWidth
                                            />
                                            <a style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                <strong>CLIENTE:</strong></a>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={nombre}
                                                disabled={true}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <a style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                <strong>MENSAJE:</strong></a>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={mensaje}
                                                onChange={(e) => setMensaje(e.target.value)}
                                                size="small"
                                                multiline={true}
                                                rows={3}
                                                fullWidth
                                            />
                                        </Grid>


                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => crearNota()}
                                                disabled={mensaje === '' || asunto === '' || id === ''}
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                            >
                                                CREAR NOTA
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Container>
                        }
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        limpiar()
                        setOpen(false)
                    }
                    }><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
