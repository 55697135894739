import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../../firebase";
import { listsSubCategorias } from "../../../../../redux/actions/parametersActions";

export const UpdatePorcentajeDeDepreciacion = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [nombreCategoriaProducto, setNombreNombreCategoriaProducto] = useState(props.procentaje_de_depreciacion);

    const updateProducto = async () => {
        await db.collection('activos_fijos_parametros').doc('porcentaje_de_depreciacion').collection('list').doc(props.id).update({
            procentaje_de_depreciacion: nombreCategoriaProducto,
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado el tipo de activo fijo", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Actualizar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>EDICION DE TIPO: {props.procentaje_de_depreciacion}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box py={2}>
                        <FormControl fullWidth>

                            <Grid item xs={6}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        label="NOMBRE"
                                        value={nombreCategoriaProducto}
                                        onChange={(e) => setNombreNombreCategoriaProducto(e.target.value)}
                                    />
                                </Box>

                            </Grid>

                        </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"

                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};