import {
    Box, Button, Chip, Collapse,
    Container, Dialog, DialogActions,
    DialogContent, DialogTitle, FormControl, Grid,
    LinearProgress, MenuItem, Select, Table, TableBody,
    TableCell, TableHead, TableRow, Typography
} from '@mui/material';
import React, { useState } from 'react'
import Chart from "react-apexcharts";
import moment from 'moment';
import 'moment/locale/es'
import { DataGrid, esES, GridToolbarContainer,GridToolbarQuickFilter } from '@mui/x-data-grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
moment.locale('es')


export const GraficaConsumo = (props) => {
    const [categorias, setCategorias] = useState([])
    const [valores, setValores] = useState([])
    const [valores2, setValores2] = useState([])

    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [data, setData] = useState([])
    const current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - (7 * 86400000)))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

    if (startDate) startDate.setHours(0, 0, 0, 0)
    if (endDate) endDate.setHours(23, 59, 0, 0)


    const [startDate2, setStartDate2] = useState(startDate)
    const [endDate2, setEndDate2] = useState(endDate)


    const onChange = (dates) => {
        const [start, end] = dates;

        if (start) start.setHours(0, 0, 0, 0)
        if (end) end.setHours(23, 59, 0, 0)
        setStartDate(start);
        setEndDate(end);
    }




    const series = [
        {
            name: 'Consumo Subida',
            data: valores,
        },
        {
            name: 'Consumo Bajada',
            data: valores2,
        },
    ]

    const options = {
        chart: {
            height: 350,
            type: 'area'
        },

        zoom: {
            enabled: true,
            autoScaleYaxis: true
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            padding: {
                right: 30,
                left: 20
            }
        },
        xaxis: {
            type: "datetime",
            categories: categorias,
            min: startDate2.getTime(),
            max: endDate2.getTime(),

        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value > 999 ? parseFloat((value / 1000).toFixed(2)) + ' GB' : parseFloat(value) + ' MB';
                }
            },
        },

        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },

    };

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <strong>BUSCAR:</strong><GridToolbarQuickFilter sx={{ paddingLeft: '10px' }}
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .filter((value) => value !== '')
                } />
        </GridToolbarContainer>
    );

    function cargarHistorial() {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apimikroconsumo/?idservicio=${props.servicio + ''}`,
            requestOptions)
            .then(response => response.text())
            .then(result => {
                let re = JSON.parse(result)
                let datos = re
                datos.reverse()
                setData([...datos.map((val, index) => {
                    return {
                        id: index,
                        ...val,
                        acctinputoctets: parseInt(val.acctinputoctets) / 1000000,
                        acctoutputoctets: parseInt(val.acctoutputoctets) / 1000000,
                    }
                }
                )])
                let fechas = []
                let subida = []
                let bajada = []
                let mesesConAños = []
                let agrupado4 = re.reduce((formato, { acctstarttime, acctinputoctets, acctoutputoctets }) => {
                    let campo = acctstarttime.split(" ")[0]
                    let datos = campo.split("-")
                    datos = datos[0] + '-' + datos[1]
                    mesesConAños.push(datos)
                    if (!formato[campo]) {
                        formato[campo] = [0, 0]
                        fechas.push(campo)
                    };
                    formato[campo][0] = formato[campo][0] + parseInt(acctinputoctets);
                    formato[campo][1] = formato[campo][1] + parseInt(acctoutputoctets);
                    return formato;
                }, {});
                for (const element in fechas) {
                    subida.push(parseFloat((agrupado4[fechas[element]][0] / 1000000).toFixed(2)))
                    bajada.push(parseFloat((agrupado4[fechas[element]][1] / 1000000).toFixed(2)))
                }
                setCategorias(fechas)
                setValores(subida)
                setValores2(bajada)
                setOpen(true)
            }).catch(error => console.log('error', error));
    }


    function cambioTiempo(tiempoTotal) {
        let horas = parseInt(tiempoTotal / 3600)
        tiempoTotal -= horas * 3600
        let minutos = parseInt(tiempoTotal / 60)
        tiempoTotal -= minutos * 60
        let segundos = tiempoTotal
        if (horas < 0) {
            horas *= -1
        }
        if (minutos < 0) {
            minutos *= -1
        }
        if (segundos < 0) {
            segundos *= -1
        }
        if (horas < 10) {
            horas = '0' + horas
        }
        if (minutos < 10) {
            minutos = '0' + minutos
        }
        if (segundos < 10) {
            segundos = '0' + segundos
        }
        return horas + ':' + minutos + ':' + segundos

    }

    const columns = [
        { field: 'acctstarttime', headerClassName: 'super-app-theme--header', headerName: 'Conectado', flex: 1 },
        { field: 'acctstoptime', headerClassName: 'super-app-theme--header', headerName: 'Desconectado', flex: 1 },
        {
            field: 'acctsessiontime', headerClassName: 'super-app-theme--header', headerName: 'Tiempo', flex: .8,
            renderCell: (params) => {
                return <>
                    {params.row.acctsessiontime ?
                        cambioTiempo(parseInt(params.row.acctsessiontime)) : '00:00:00'
                    }

                </>
            }
        },
        {
            field: 'acctoutputoctets', headerClassName: 'super-app-theme--header', headerName: 'Descarga', flex: 1,
            renderCell: (params) => {
                return <>
                    {params.row.acctoutputoctets &&
                        <>
                            {params.row.acctoutputoctets > 999 ?
                                parseFloat(params.row.acctoutputoctets / 1000).toFixed(2) + ' GB'
                                : parseFloat(params.row.acctoutputoctets).toFixed(2) + ' MB'}
                        </>
                    }

                </>
            }
        },
        {
            field: 'acctinputoctets', headerClassName: 'super-app-theme--header', headerName: 'Subida', flex: 1,
            renderCell: (params) => {
                return <>
                    {params.row.acctinputoctets &&
                        <>
                            {params.row.acctinputoctets > 999 ?
                                parseFloat(params.row.acctinputoctets / 1000).toFixed(2) + ' GB'
                                : parseFloat(params.row.acctinputoctets).toFixed(2) + ' MB'}
                        </>
                    }

                </>
            }
        },

        { field: 'framedipaddress', headerClassName: 'super-app-theme--header', headerName: 'IPV4', flex: 1 },
        { field: 'nasipaddress', headerClassName: 'super-app-theme--header', headerName: 'Router', flex: 1 },
    ]

    return (
        <>
            <Button
                fullWidth variant="contained" onClick={() => {
                    cargarHistorial()
                }} color='success' >
                CONSUMO
            </Button>
            <Box px={2}>
                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xl">
                    <DialogTitle sx={{
                        bgcolor: '#1A6EB9',
                        color: "white"
                    }}><strong>CONSUMO DEL SERVICIO:
                            {props.servicio}</strong></DialogTitle>
                    <DialogContent>
                        <Container maxWidth="lg">
                            <Grid container >
                                <Grid item md={12} xs={12}>
                                    <br />
                                    <Box display="flex" alignItems="center">
                                        <Button onClick={() => setOpen2(!open2)}>
                                            Buscar por fecha
                                        </Button>
                                    </Box>
                                    <Collapse in={open2} timeout="auto" unmountOnExit>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectsRange
                                            inline
                                        />
                                        <Button
                                            disableElevation
                                            variant="contained"
                                            onClick={() => {
                                                setStartDate2(startDate)
                                                setEndDate2(endDate)
                                            }}
                                        >
                                            Aplicar busqueda
                                        </Button>
                                    </Collapse>

                                    <Box display='flex' style={{ textTransform: 'uppercase' }} py={2} pl={2}>
                                        <Typography variant="button">
                                            <strong>Desde</strong>
                                            {startDate && moment(startDate.toISOString()).format('DD/MMM/YYYY')}
                                        </Typography >
                                        <Typography variant="button" style={{ paddingLeft: 10 }}>
                                            <strong>Hasta</strong>
                                            {endDate && moment(endDate.toISOString()).format('DD/MMM/YYYY')}
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Chart type="area"
                                        options={options}
                                        series={series}
                                        height={385}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        height: 500, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                        '& .super-app-theme--header': {
                                            backgroundColor: 'LightSlateGray',
                                            color: 'white',
                                            fontSize: '16px'
                                        },
                                        backgroundColor: '#f0f2f5'
                                    }}>
                                        <DataGrid
                                            hideFooterSelectedRowCount={true}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            density='compact'
                                            rows={data ?? []}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant="contained"><strong>Regresar</strong></Button>
                    </DialogActions>
                </Dialog>

            </Box>

        </>
    )
}
