import {
    Button,
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { db, firebase, auth } from "../../../firebase";
import Swal from "sweetalert2";
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';
import { SeriesCargaInicial } from "./IconButtonsCargaInicial/SeriesCargaInicial";

export const CargaInicial = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [message, setMessage] = useState();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [openCaja, setOpenCaja] = React.useState(false);

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [data, setData] = useState();

    const [data2, setData2] = useState();

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData)

                const fechaDocumento = new Date(2023, 6, 2);

                const timestamp = firebase.firestore.Timestamp.fromDate(fechaDocumento);

                jsonData.forEach(async (obj) => {

                    /* const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.CODIGO_PRODUCTO).where('bodega', '==', obj.LOCALIDAD).get() */

                    const valorTotal = parseFloat(obj.STOCK) * parseFloat(obj.COSTO_PROMEDIO)

                    db.collection("total_movimientos")
                        .doc()
                        .set({

                            codigo: obj.CODIGO_PRODUCTO === '' ||
                                obj.CODIGO_PRODUCTO === null ||
                                obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                            bodega: obj.LOCALIDAD === '' ||
                                obj.LOCALIDAD === null ||
                                obj.LOCALIDAD === undefined ? '' : obj.LOCALIDAD.toUpperCase(),

                            uid_solicitud_pedido: obj.CODIGO_PRODUCTO === '' ||
                                obj.CODIGO_PRODUCTO === null ||
                                obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                            descripcion: obj.DESCRIPCION === '' ||
                                obj.DESCRIPCION === null ||
                                obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                            motivo: 'CARGA INICIAL',

                            tipo_movimiento: 'ingreso',

                            categoria_producto: obj.SUBTIPO === '' ||
                                obj.SUBTIPO === null ||
                                obj.SUBTIPO === undefined ? '' : obj.SUBTIPO.toUpperCase(),

                            unidad_medida: obj.UNIDAD_MEDIDA === '' ||
                                obj.UNIDAD_MEDIDA === null ||
                                obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                            cantidad_solicitada:
                                obj.STOCK === 0 ||
                                    obj.STOCK === '' ||
                                    obj.STOCK === null ||
                                    obj.STOCK === undefined ? 0 : parseFloat(obj.STOCK).toFixed(2),

                            cantidad_total_despues: obj.STOCK === 0 ||
                                obj.STOCK === '' ||
                                obj.STOCK === null ||
                                obj.STOCK === undefined ? 0 : parseFloat(obj.STOCK).toFixed(2),

                            costo_promedio: obj.COSTO_PROMEDIO === 0 ||
                                obj.COSTO_PROMEDIO === '0' ||
                                obj.COSTO_PROMEDIO === '' ||
                                obj.COSTO_PROMEDIO === null ||
                                obj.COSTO_PROMEDIO === undefined ||
                                Number.isNaN(Number(obj.COSTO_PROMEDIO)) ? 0 : parseFloat(obj.COSTO_PROMEDIO).toFixed(2),

                            costo_promedio_general: obj.COSTO_PROMEDIO === 0 ||
                                obj.COSTO_PROMEDIO === '0' ||
                                obj.COSTO_PROMEDIO === '' ||
                                obj.COSTO_PROMEDIO === null ||
                                obj.COSTO_PROMEDIO === undefined ||
                                Number.isNaN(Number(obj.COSTO_PROMEDIO)) ? 0 : parseFloat(obj.COSTO_PROMEDIO).toFixed(2),

                            total: obj.TOTAL === 0 ||
                                obj.TOTAL === '' ||
                                obj.TOTAL === '0' ||
                                obj.TOTAL === null ||
                                obj.TOTAL === undefined
                                || Number.isNaN(Number(obj.TOTAL)) ? 0 : valorTotal.toFixed(2),/* parseFloat(obj.STOCK).toFixed(2) * parseFloat(obj.COSTO_PROMEDIO).toFixed(2), */

                            saldo_valorizado: obj.TOTAL === 0 ||
                                obj.TOTAL === '' ||
                                obj.TOTAL === '0' ||
                                obj.TOTAL === null ||
                                obj.TOTAL === undefined
                                || Number.isNaN(Number(obj.TOTAL)) ? 0 : valorTotal.toFixed(2),

                            created: firebase.firestore.FieldValue.serverTimestamp(),
                            precio_compra: 0,
                            usuario_que_creo_el_producto: userInfo.displayName,
                            user: userInfo.displayName,
                            fecha_documento: timestamp
                            /* total: obj.TOTAL */
                            /* codigoProducto: obj.CODIGO_PRODUCTO,
                            bodega: obj.LOCALIDAD,
                            descripcion: obj.DESCRIPCION,
                            categoria: obj.SUBTIPO,
                            unidad_medida: obj.UNIDAD_MEDIDA, */
                        })
                });

                // jsonData.forEach((obj) => {

                //     const valorTotal = parseFloat(obj.STOCK) * parseFloat(obj.COSTO_PROMEDIO)

                // db.collection("total_movimientos_general")
                //     .doc()
                //     .set({

                //         codigo: obj.CODIGO_PRODUCTO === '' ||
                //             obj.CODIGO_PRODUCTO === null ||
                //             obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                //         bodega: obj.LOCALIDAD === '' ||
                //             obj.LOCALIDAD === null ||
                //             obj.LOCALIDAD === undefined ? '' : obj.LOCALIDAD.toUpperCase(),

                //         uid_solicitud_pedido: obj.CODIGO_PRODUCTO === '' ||
                //             obj.CODIGO_PRODUCTO === null ||
                //             obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                //         codigo: obj.CODIGO_PRODUCTO === '' ||
                //             obj.CODIGO_PRODUCTO === null ||
                //             obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO.toUpperCase(),

                //         descripcion: obj.DESCRIPCION === '' ||
                //             obj.DESCRIPCION === null ||
                //             obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                //         motivo: 'CARGA INICIAL',

                //         tipo_movimiento: 'ingreso',

                //         categoria_producto: obj.SUBTIPO === '' ||
                //             obj.SUBTIPO === null ||
                //             obj.SUBTIPO === undefined ? '' : obj.SUBTIPO.toUpperCase(),

                //         unidad_medida: obj.UNIDAD_MEDIDA === '' ||
                //             obj.UNIDAD_MEDIDA === null ||
                //             obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                //         cantidad_solicitada:
                //             obj.STOCK === 0 ||
                //                 obj.STOCK === '' ||
                //                 obj.STOCK === null ||
                //                 obj.STOCK === undefined ? 0 : parseFloat(obj.STOCK).toFixed(2),

                //         cantidad_total_despues: obj.STOCK === 0 ||
                //             obj.STOCK === '' ||
                //             obj.STOCK === null ||
                //             obj.STOCK === undefined ? 0 : parseFloat(obj.STOCK),

                //         costo_promedio: obj.COSTO_PROMEDIO === 0 ||
                //             obj.COSTO_PROMEDIO === '' ||
                //             obj.COSTO_PROMEDIO === '0' ||
                //             obj.COSTO_PROMEDIO === null ||
                //             obj.COSTO_PROMEDIO === undefined ||
                //             Number.isNaN(Number(obj.COSTO_PROMEDIO)) ? 0 : parseFloat(obj.COSTO_PROMEDIO).toFixed(2),

                //         costo_promedio_general: obj.COSTO_PROMEDIO === 0 ||
                //             obj.COSTO_PROMEDIO === '' ||
                //             obj.COSTO_PROMEDIO === '0' ||
                //             obj.COSTO_PROMEDIO === null ||
                //             obj.COSTO_PROMEDIO === undefined ||
                //             Number.isNaN(Number(obj.COSTO_PROMEDIO)) ? 0 : parseFloat(obj.COSTO_PROMEDIO).toFixed(2),

                //         total: obj.TOTAL === 0 ||
                //             obj.TOTAL === '' ||
                //             obj.TOTAL === '0' ||
                //             obj.TOTAL === null ||
                //             obj.TOTAL === undefined ? 0 : valorTotal.toFixed(2),/* parseFloat(obj.STOCK).toFixed(2) * parseFloat(obj.COSTO_PROMEDIO).toFixed(2), */

                //         saldo_valorizado: obj.TOTAL === 0 ||
                //             obj.TOTAL === '' ||
                //             obj.TOTAL === '0' ||
                //             obj.TOTAL === null ||
                //             obj.TOTAL === undefined ? 0 : valorTotal.toFixed(2),

                //         precio_compra: 0,

                //         created: firebase.firestore.FieldValue.serverTimestamp(),
                //         usuario_que_creo_el_producto: userInfo.displayName,
                //         user: userInfo.displayName,
                //         fecha_documento: timestamp
                //         /* total: obj.TOTAL */
                //         /* codigoProducto: obj.CODIGO_PRODUCTO,
                //         bodega: obj.LOCALIDAD,
                //         descripcion: obj.DESCRIPCION,
                //         categoria: obj.SUBTIPO,
                //         unidad_medida: obj.UNIDAD_MEDIDA, */
                //     })
                // });

                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };


    const subirCargaInicial = () => {
        /* Swal.fire({ icon: "success", title: "Informacion subida correctamente" }); */
        data.forEach((obj) => {
            db.collection("carga_inicial")
                .doc(obj.CODIGO_PRODUCTO)
                .set({
                    codigoProducto: obj.CODIGO_PRODUCTO,
                    bodega: obj.LOCALIDAD,
                    descripcion: obj.DESCRIPCION,
                    categoria: obj.SUBTIPO,
                    unidad_medida: obj.UNIDAD_MEDIDA,
                    /* stock_actual: obj.STOCK,
                    costo_promedio: obj.COSTO_PROMEDIO,
                    total: obj.TOTAL */
                })
        });
        Swal.fire({
            icon: "success",
            title: "Subiendo datos, esto tardara un poco...",
        });
    }

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("carga_inicial")

        //Si tiene algun doc anterior se agrega acontinuacion

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData2(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    return (
        <>
            <Box pt={1}>
                <Card>
                    <CardContent>
                        <p style={{ fontSize: "12px", marginBottom: "8px" }}><strong> CARGA INICIAL:</strong></p>
                        <Button style={{ marginLeft: "10px", marginBottom: "10px" }} onClick={() => setOpenCaja(true)} size='small' /* onClick={() => onDataCuentaMenor()} */ variant="contained" startIcon={<UploadIcon />}>
                            SUBIR EXCEL
                        </Button>

                        <Box pt={1}>
                            <Paper elevation={3} />
                            <TableContainer sx={{ maxHeight: 640 }}>
                                <Table size="small" stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>BODEGA</TableCell>
                                            <TableCell>CODIGO PRODUCTO</TableCell>
                                            <TableCell>DESCRIPCION</TableCell>
                                            <TableCell>CATEGORIA</TableCell>
                                            <TableCell>UNIDAD MEDIDA</TableCell>
                                            <TableCell>STOCK ACTUAL</TableCell>
                                            <TableCell>COSTO PROMEDIO</TableCell>
                                            <TableCell>SALDO VALORIZADO</TableCell>
                                            {/* <TableCell>INGRESAR SERIES</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data2 && data2.map((row) => (
                                            <TableRow>

                                                <TableCell component="th" scope="row">
                                                    {row.bodega}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.codigo_producto}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.descripcion}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.categoria}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.unidad_medida}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.stock_actual}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {row.costo_promedio}
                                                </TableCell>

                                                <TableCell component="th" scope="row">
                                                    {/* {parseFloat(row.stock_actual).toFixed(2) * parseFloat(row.costo_promedio).toFixed(2)} */}
                                                    {parseFloat(row.total_valorizado).toLocaleString('en')}
                                                </TableCell>

                                                {/* <TableCell component="th" scope="row">
                                                            <SeriesCargaInicial props={row} />
                                                        </TableCell> */}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Paper />
                        </Box>


                    </CardContent>
                </Card>
            </Box>

            {/* <Box pt={2}>
                <Button disabled={!data} color="success" onClick={() => subirCargaInicial()} size='small' variant="contained" >
                    SUBIR CARGA INICIAL
                </Button>
            </Box> */}

            {/* SUBIR EXCEL */}
            <Dialog
                open={openCaja}
                onClose={() => setOpenCaja(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir Excel</DialogTitle>
                <DialogContent>
                    <Box pb={2}>
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>
                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={5}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                </DialogContent>
            </Dialog>

        </>
    );
};