import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NuevaCategoria = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [categoria, setCategoria] = useState({ campo: '', valido: true })
    const [subcategoria, setSubCategoria] = useState({ campo: "", valido: true });

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async (categoria) => {
        await db.collection('categorias_productos').doc().set({
            nombre_categoria_producto: categoria,
            fecha_creacion_categoria_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva categoria creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                estado={categoria}
                cambiarEstado={setCategoria}
                label="CATEGORIA"
                name="categoria"
            />
            
            <br />
            <Button
                onClick={() => createProduct(categoria.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !categoria.campo}
            >
                {" "}
                CREAR NUEVA CATEGORIA{" "}
            </Button>



        </>
    );
};
