import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    Box,
    Button,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    DialogTitle,
    Paper,
    TextField,
    TableContainer,
    TableBody,
    TableCell,
    Grid,
    IconButton,
    Tooltip,
    Autocomplete
} from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import {
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
} from '@mui/x-data-grid';
import { db, firebase } from '../../firebase'

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import { useNavigate } from "react-router-dom";

const VerBodega = ({ props }) => {

    const [loadingButtonDespachoCompleto, setLoadingButtonDespachoCompleto] = useState(false);

    const [loadingButtonDespachoParcial, setLoadingButtonDespachoParcial] = useState(false);

    const [numSolicitudFlota, setNumSolicitudFlota] = useState('')

    const [incluyeCodigo, setIncluyeCodigo] = useState('')

    const checkDataExistsProduct = () => {

        const resultados = items.filter(objeto => objeto.codigo === 'INV-00113');
        const incluye = resultados.length === 1;

        setIncluyeCodigo(incluye)
    };

    const loadNumSolicitudFlota = () => {
        const ref = db.collection("reporte_movimientos_flotas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `MOV-` + numeroSolicitud;
                setNumSolicitudFlota(numeroSolicitud)
            }
        })
    }

    let navigate = useNavigate()

    const [loadingRealizarDespachoParcial, setLoadingRealizarDespachoParcial] = useState(false);

    const [loadingRealizarDespacho, setLoadingRealizarDespacho] = useState(false);

    const [openValidacionOnus, setOpenValidacionOnus] = useState(false);

    const [openConfirmDespachoTotal, setOpenConfirmDespachoTotal] = useState(false);

    const [openConfirmDespachoParcial, setOpenConfirmDespachoParcial] = useState(false);

    const [botonValidado, setBotonValidado] = useState('')

    const UploadSeriesFunction = ({ props, codigo, bodega }) => {

        const [itemSerie, setItemSerie] = useState('')

        const [inputFieldsSeries, setInputFieldsSeries] = useState([]);
        const [seriesList, setSeriesList] = useState([]);
        const [series, setSeries] = useState([]);
        const [seriesAutomaticas, setSeriesAutomaticas] = useState([]);

        const [modalSelectedSeries, setModalSelectedSeries] = useState(false)

        const [openListSeriesAEgresar, setOpenListSeriesAEgresar] = useState(false)

        const [openSelectMetodoAEgresar, setOpenSelectMetodoAEgresar] = useState(null)

        const [openListSeriesAEgresarAutomaticas, setOpenListSeriesAEgresarAutomaticas] = useState(false)

        const uploadSeriesManuales = () => {

            const uidSolicitud = `EGR-` + numPedido;

            props.series === undefined ? console.log('') : props.series.map((prop, index) => {
                inputFieldsSeries.forEach((obj, indexExcel) => {
                    handleSeriesExcel(indexExcel, obj.serie.toString(), props)
                })
            })

            setOpenListSeriesAEgresar(false)

            setOpenListSeriesAEgresarAutomaticas(false)

            getProductsRequired()

            /* Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            }); */

        }

        const uploadSeriesAutomaticas = () => {

            const uidSolicitud = `EGR-` + numPedido;

            props.series === undefined ? console.log('') : props.series.map((prop, index) => {
                seriesAutomaticas.forEach((obj, indexExcel) => {
                    handleSeriesExcel(indexExcel, obj.serie.toString(), props)
                })
            })

            /* seriesAutomaticas.forEach(async function (obj, index) {

                console.log(items)

                console.log('seriesAutomaticas', obj)

                console.log('seriesAutomaticas', obj.serie)

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                    .doc(codigo).collection('series').doc(obj.serie)
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                db.collection(`egreso_productos/${uidSolicitud}/items`)
                    .doc(codigo).collection('series').doc(obj.serie)
                    .set({
                        serie:
                            obj.serie === "" ||
                                obj.serie === null ||
                                obj.serie === undefined
                                ? ""
                                : obj.serie,
                        created:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_cargo_serie: userInfo.displayName,
                        utilizado_por_codigo: codigo,
                        bodega: bodega,
                        estado: 0
                    })

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigo).where('bodega', '==', bodega).get()

                const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.serie)
                    .update({
                        estado: 1
                    })

            }) */

            setOpenListSeriesAEgresarAutomaticas(false)

            /* Swal.fire({
                icon: "success",
                title: "Series cargadas con exito",
            }); */

            getProductsRequired()

        }

        /* ADD SERIES */

        const addInputSeries = (row) => {
            if (inputFieldsSeries.find(x => x.serie === row.serie)) {
                Swal.fire({ icon: "info", text: 'Ya se agrego esta serie a la lista', });
            } else {
                setInputFieldsSeries([...inputFieldsSeries, {
                    serie: row.serie
                }])
            }
            setModalSelectedSeries(false)
            /* setItem('')
            setModal(false) */
        }

        const removeInputFieldsSeries = (index) => {
            const rows = [...inputFieldsSeries];
            rows.splice(index, 1);
            setInputFieldsSeries(rows);
        }

        function detalleMateSeries(data, index) {
            return (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell align="center">
                        {data.serie}
                    </TableCell>

                    <TableCell align="center">
                        <IconButton onClick={() => removeInputFieldsSeries(index)} color='error'>
                            <DeleteForeverIcon />
                        </IconButton>
                    </TableCell>

                </TableRow>
            )
        }

        const getSeries = async (row) => {
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.codigo).where('bodega', '==', row.bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0)
            docRef.get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setSeries(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const getSeriesAutomaticas = async (row) => {
            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", row.fullName).where('bodega', '==', bodega).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var docRef = db.collection("reporte_stock").doc(data2[0].id).collection('series').where('estado', '==', 0).limit(parseFloat(row.cant_entrega))
            docRef.get().then((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setSeriesAutomaticas(arrayMessage)
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }

        const onData = () => {
            getSeries(props)
            getSeriesAutomaticas(props)
            setOpenSelectMetodoAEgresar(true)
        };

        return (
            <>
                {/* SELECCIONAR METODO DE EGRESAR SERIES */}

                <Tooltip title="INGRESAR SERIES">
                    <Button onClick={() => onData()}>
                        {
                            props.usa_serie === true ? <QrCodeScannerIcon color="success" /> : <QrCodeScannerIcon color="primary" />
                        }
                    </Button>
                </Tooltip>

                <Dialog
                    open={openSelectMetodoAEgresar}
                    maxWidth='xs'
                    fullWidth
                    onClose={() => setOpenSelectMetodoAEgresar(false)}
                >
                    <DialogTitle>
                        METODO A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Grid container rowSpacing={2} columnSpacing={2}>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresar(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='primary'>Seleccion manual</Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button onClick={() => {
                                    setOpenListSeriesAEgresarAutomaticas(true)
                                    setOpenSelectMetodoAEgresar(false)
                                }} variant='contained' size='small' color='secondary'>Seleccion automatica</Button>
                            </Grid>

                        </Grid>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='primary' onClick={() => setOpenSelectMetodoAEgresar(false)}>
                            REGRESAR
                        </Button>
                    </DialogActions>

                </Dialog>


                {/* LISTA DE SERIES A EGRESAR */}

                <Dialog
                    open={openListSeriesAEgresar}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Box pb={1}>
                            <Button disabled={props.cant_entrega <= inputFieldsSeries.length} onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        inputFieldsSeries.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresar(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={inputFieldsSeries.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesManuales()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>

                {/* LISTA PARA SELECCIONAR Y BUSCAR LAS SERIES A EGRESAR */}

                <Dialog
                    open={modalSelectedSeries}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        AGREGAR ITEMS
                    </DialogTitle>
                    <DialogContent dividers >
                        <Box pb={3}>
                            <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                options={series}
                                clearOnBlur={false}
                                autoComplete={false}
                                disableClearable
                                getOptionLabel={(option) => option.serie}
                                sx={{ width: 300 }}
                                onInputChange={(e, newInputValue) => {
                                    setItemSerie(newInputValue)
                                }}
                                renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SERIES</TableCell>
                                        <TableCell>ACCIONES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {series && series
                                        .filter((val) => {
                                            if (itemSerie === "") {
                                                /* return val; */
                                            } else if (val.serie.includes(itemSerie)) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow
                                                key={row.serie}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.serie}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        size='small'
                                                        variant='contained'
                                                        color='success'
                                                        onClick={() => /* blockButtonAddItem */addInputSeries(row)}
                                                    >Agregar</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions dividers>
                        <Button size='small' variant='contained' onClick={() => setModalSelectedSeries(false)}>
                            Cerrar Ventana
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* LISTA DE SERIES A EGRESAR AUTOMATICAS */}

                <Dialog
                    open={openListSeriesAEgresarAutomaticas}
                    maxWidth='md'
                    fullWidth
                >
                    <DialogTitle>
                        SERIES A EGRESAR
                    </DialogTitle>

                    <DialogContent dividers>

                        <Box pb={1}>
                            <Button onClick={() => setModalSelectedSeries(true)} variant='contained' size='small' color='primary'>Agregar Series</Button>
                        </Box>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center" style={{ fontSize: '12px' }}><strong>SERIE</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        seriesAutomaticas.map((data, index) => {

                                            return (
                                                detalleMateSeries(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </DialogContent>

                    <DialogActions dividers>
                        <Button size='small' variant='outlined' color='error' onClick={() => {
                            setOpenSelectMetodoAEgresar(true)
                            setOpenListSeriesAEgresarAutomaticas(false)
                        }}>
                            REGRESAR
                        </Button>
                        <Button disabled={seriesAutomaticas.length === 0} size='small' variant='contained' color='success' onClick={() => uploadSeriesAutomaticas()}>
                            EGRESAR SERIES
                        </Button>
                    </DialogActions>

                </Dialog>
            </>
        )
    }

    const handleSeriesExcel = async (index, evnt, row) => {
        if (evnt) {
            const list = [...items];
            let index_serie = row.series[0].index_serie;
            let array = {
                id: index,
                index_serie: index_serie,
                serie: evnt.toUpperCase(),
            };
            list[index_serie]["series"][index] = array;
        } else {
            const list = [...items];
            /* list[index]["fullName"] = evnt; */
            setItems(list);
        }
        //console.log('input files', inputFields)
    };

    const [totalCompra, setTotalCompra] = useState('')

    const contador = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        //
        function sumarDiferencia(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.cant_diferencia)) {
                    suma += parseFloat(elemento.cant_diferencia);
                }
            }
            return suma;
        }
        const list = items
        var pendiente_total = sumarDiferencia(items)
        setTotal(pendiente_total)
    }

    const [cantTotalOnus, setCantTotalOnus] = useState(0)


    const handleChange = async (index, evnt, item) => {
        const { name, value } = evnt.target;
        const list = [...items];
        const cantidad_pedida = list[index]["cantidad_pendiente"]
        const cantidad_entregada = parseInt(evnt.target.value)
        if (cantidad_pedida < cantidad_entregada) {
            return;
        } else if (Math.sign(evnt.target.value) === -1) {
            return;
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...items];
                list[index][name] = value;

                const cantidad_recibida = list[index]["cant_entrega"]
                const diferencia_recepcion = list[index]["cantidad_pendiente"] - parseInt(cantidad_recibida)
                list[index]["cant_diferencia"] = parseInt(diferencia_recepcion)

                if (item.usa_serie === true) {
                    const num = evnt.target.value;
                    let array = [];
                    for (var i = 0; i < num; i++) {
                        let n;
                        n += i;
                        array.push({
                            index_serie: index,
                            id: i,
                            serie: 0,
                        });
                    }
                    list[index]["series"] = array;

                    setItems(list, { series: [{ serie: 0 }], });
                    contador();
                    getProductsRequired()
                } else if (item.usa_serie === undefined) {
                    contador();
                    getProductsRequired()
                    return;
                } else {
                    contador();
                    getProductsRequired()
                    return;
                }

                if (list[index]["fullName"] === nameOnus) {
                    const cantidadTotalOnus = parseInt(cantidad_entregada) + parseInt(cantOnus)
                    if (cantidadTotalOnus > 8) {
                        setCantTotalOnus(cantidadTotalOnus)
                        setOpenValidacionOnus(true)
                    } else {
                    }
                } else {
                }
            } else {
                getProductsRequired()
                const list = [...items];
                list[index]['fullName'] = evnt;
                setItems(list, { series: [{ serie: 0 }], });
            }
        }
    }


    const [observacionExtra, setObservacionExtra] = useState('');
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState('');
    const [motivoRechazo, setMotivoRechazo] = useState('');
    const [items, setItems] = useState([]);

    const [numPedido, setNumPedido] = useState('')

    const [numIngreso, setNumIngreso] = useState('')

    const loadNumPedido = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const loadNumIngreso = () => {
        const ref = db.collection("ingreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `IGR-` + numeroSolicitud;
                setNumIngreso(numeroSolicitud)
            }
        })
    }

    const pruebaBotonesUseEffect = () => {
        const cantidadesValidas = items.some(item => item.cant_entrega < item.cantidad_pendiente);
        setBotonValidado(cantidadesValidas)
    }

    const [cantOnus, setCantOnus] = useState(0)
    const [nameOnus, setNameOnus] = useState('')

    const getOnusInstaladores = async () => {
        if (props.correo.includes('flota')) {
            const agendaDB2 = await db.collection("flotas").where("correo", "==", props.correo).get()
            const data2 = agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            const agendaDB4 = await db.collection("flotas").doc(data2[0].id).collection('materiales').get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            data4.forEach((obj, index) => {
                if (obj.id === 'SUM-00201') {
                    setCantOnus(obj.stock_actual)
                    setNameOnus(obj.id)
                } else {
                    return;
                }
            })
        } else {
        }
    }

    const [boolSeries, setBoolSeries] = useState(true)

    const getProductsRequired = () => {
        items.forEach((obj, index) => {

            if (obj.series === undefined || obj.series === null) {
                return
            } else {

                const seriesValidas = obj.series.some(item => item.serie <= 0);

                setBoolSeries(seriesValidas)
            }


        })
    }

    useEffect(() => {
        checkDataExistsProduct()
        loadNumSolicitudFlota()
        loadNumTransferencia()
        loadNumPedido()
        loadNumIngreso()
        contador()
        getProductsRequired()
    }, [items]);
    const onData = () => {
        setOpen(true)
        setData(props)
        loadItems()
    }
    const loadItems = () => {
        var docRef = db.collection("solicitud_pedido").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            data.forEach(async (obj, index) => {

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', obj.bodega).get()

                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                try {// Obtiene los resultados de la consulta

                    if (!data2[0].empty) {

                        const list = [...data];

                        list[index]['stock_actual'] = data2[0].stock_actual;

                    } else {
                        return;
                    }
                } catch (error) {
                    console.error('Error al obtener el stock del código:', error);
                }
            })

            setTimeout(() => {
                setItems(data);
            }, 500);


        });
    }
    const onSubmit = () => {
        setLoading(true)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 1,
            egreso: firebase.firestore.FieldValue.increment(1),
            user_entrego: userInfo.displayName,
            fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
            observacion_extra: observacionExtra,
            /* todos_productos_entregados: true */
        }).then((docRef) => {
            Swal.fire({ icon: "success", text: 'Se actulizo su solicitud de pedido' });
            setLoading(false)
        })
    }
    //
    const onRechazar = () => {
        setLoading(true)
        db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
            estado: 2,
            motivo_rechazo: motivoRechazo,
            user_rechazo: userInfo.displayName,
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
            observacion_extra: observacionExtra
        }).then((docRef) => {
            Swal.fire({ icon: "info", text: 'Se rechazo su solicitud de pedido' });
            setLoading(false)
        })
    }

    const handleIngresoKardexFlotas = () => {
        let rol = ''
        if (userInfo.rol === 0) {
            rol = 'ADMIN'
        }
        if (userInfo.rol === 1) {
            rol = 'COMERCIAL'
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = 'OPERACIONES'
        }
        if (userInfo.rol === 3) {
            rol = 'PLANEACION'
        }
        if (userInfo.rol === 4) {
            rol = 'FLOTAS'
        }
        if (userInfo.rol === 5) {
            rol = 'REPORTES'
        }
        if (userInfo.rol === 6) {
            rol = 'RECURSOS HUMANOS'
        }
        //
        const uidSolicitud = `MOV-` + numSolicitudFlota

        items.forEach(async function (obj, index) {

            if (obj.cant_entrega != 0 && obj.cant_entrega != undefined && obj.cant_entrega != null) {
                const agendaDB1 = await db.collection("flotas").where('correo', '==', props.correo).get()

                const data1 = await agendaDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const agendaDB2 = await db.collection("flotas").doc(data1[0].id).collection('materiales').get()

                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const agendaDB3 = await db.collection("flotas").doc(data1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo).get()

                const data3 = await agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                if (data3[0] != undefined) {
                    const costoPromedio = data3[0].costo_promedio

                    const cantidad_total = parseFloat(data3[0].stock_actual) + parseFloat(obj.cant_entrega)

                    const saldo_valorizado = parseFloat(data3[0].costo_promedio).toFixed(2) * parseFloat(obj.cant_entrega).toFixed(2)

                    const dataRefBodegaFlota = {
                        ...obj,
                        cantidad_solicitada: Number.isNaN(Number(parseInt(obj.cant_entrega))) ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: Number.isNaN(Number(parseFloat(data3[0].stock_actual))) ? 0 : parseFloat(data3[0].stock_actual),
                        cantidad_total_despues: Number.isNaN(Number(parseFloat(cantidad_total).toFixed(2))) ? 0 : parseFloat(cantidad_total).toFixed(2),
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: ''/* obj.categoria */,
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio,
                        saldo_valorizado: saldo_valorizado === 0 || saldo_valorizado === undefined || Number.isNaN(Number(saldo_valorizado)) ? 0 : saldo_valorizado,
                        total: '',
                        precio_compra: data3[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: data1[0] === undefined || data1[0].numeroFlota === undefined || data1[0].numeroFlota === null || data1[0].numeroFlota === '' ? '' : data1[0].numeroFlota,
                        lider_flota: data1[0] === undefined || data1[0].nombreLider === undefined || data1[0].nombreLider === null || data1[0].nombreLider === '' ? '' : data1[0].nombreLider,
                        auxiliar_flota: data1[0] === undefined || data1[0].nombreAuxiliar === undefined || data1[0].nombreAuxiliar === null || data1[0].nombreAuxiliar === '' ? '' : data1[0].nombreAuxiliar,
                        placa_flota: data1[0] === undefined || data1[0].placa === undefined || data1[0].placa === null || data1[0].placa === '' ? '' : data1[0].placa,
                        marca_flota: data1[0] === undefined || data1[0].marca === undefined || data1[0].marca === null || data1[0].marca === '' ? '' : data1[0].marca,
                        modelo_flota: data1[0] === undefined || data1[0].modelo === undefined || data1[0].modelo === null || data1[0].modelo === '' ? '' : data1[0].modelo,
                        bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        ubicacion: '',
                        motivo: 'ORDEN DE TRABAJO',
                        tipo_movimiento: 'egreso',
                        total_bodegas: Number.isNaN(Number(parseFloat(data3[0].stock_actual) + parseFloat(obj.cant_entrega))) ? 0 : parseFloat(data3[0].stock_actual) + parseFloat(obj.cant_entrega),
                        suma_valores_total_blodegas: '',
                        costo_promedio_general: costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio,
                        id_orden_trabajo: props.id,
                        codigo: obj.codigo,
                        bodega_general: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        tipo_flota: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp()
                    }

                    db.collection(`total_movimientos_flotas`).doc().set(dataRefBodegaFlota)
                } else {
                    return
                }
            } else {

            }



        })

    }

    const sendConfirmItems = () => {

        setLoadingRealizarDespachoParcial(true)
        setLoadingRealizarDespacho(true)

        const uidSolicitud = `EGR-` + numPedido;

        items.forEach(async (obj, index) => {

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', obj.bodega).get()

            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            console.log('costo_promedio', costoPromedio)

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

            const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            const cantidad_total = parseFloat(data2[0].stock_actual) - parseFloat(obj.cant_entrega)

            const cantidad_total_ing = parseFloat(data2[0].stock_actual) + parseFloat(obj.cant_entrega)

            /* CALCULA EL TOTAL DE LAS BODEGAS */

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

            const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            /* FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS */

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            /* FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS */

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            /* TOTAL STOCK ACTUAL + CANTIDAD STOCK DE LA OPERACION (EG8RESO) */

            /* TOTAL VALORIZADO + TOTAL VALORIZADO DE LA OPERACION (EG8RESO)*/

            const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

            const cantidad_producto_productos = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(0)
            const cantidad_producto_reporteS = parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cant_entrega).toFixed(0)

            if (parseFloat(obj.cant_entrega) === 0 /* || obj.cant_entrega === undefined || obj.cant_entrega === null */) {

                if (parseFloat(data4[0].cantidad_producto) >= parseFloat(obj.cant_entrega)) {

                    /* const uidSolicitud = `EGR-` + numPedido;

                    db.collection("reporte_stock").doc(data2[0].id).update({
                        stock_actual: cantidad_producto_reporteS <= 0 || Number.isNaN(Number(cantidad_producto_reporteS)) ? 0 : cantidad_producto_reporteS,
                        total_valorizado: total_operacion.toFixed(2),
                        costo_promedio: costoPromedio.toFixed(2)
                    })

                    // OBTENEMOS EL ID DE LA COLECCION PRODUCTOS

                    db.collection("productos").doc(data4[0].id).update({
                        cantidad_producto: cantidad_producto_productos <= 0 || Number.isNaN(Number(cantidad_producto_productos)) ? 0 : cantidad_producto_productos
                    })

                    // ESTADO 1 SE ENVIA LA SOLICITUD A SOLICITUDES YA REALIZADAS

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
                        estado: 4,
                        egreso: firebase.firestore.FieldValue.increment(1),
                        user_entrego: userInfo.displayName,
                        fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
                        observacion_extra: observacionExtra.toUpperCase(),
                    })

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).collection('items').doc(obj.fullName).update({
                        // cant_entrega: obj.cant_entrega,
                        cant_diferencia: obj.cant_diferencia,
                        cantidad_pendiente: parseFloat(obj.cantidad_pendiente) - parseFloat(obj.cant_entrega)
                    })

                    sendSeriesToFirebase()

                    sendMateriales()

                    setOpen(false) */

                } else {

                    setOpen(false)

                    Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
                }

            } else {
                /* EGRESO DE PRODUCTO */

                if (parseFloat(data4[0].cantidad_producto) >= parseFloat(obj.cant_entrega)) {

                    const itemsReporteStockDB = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega).get()

                    const itemsReporteStock = await itemsReporteStockDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    const operacionCostoPromedio = parseFloat(itemsReporteStock[0].total_valorizado) / parseFloat(itemsReporteStock[0].stock_actual)

                    const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

                    const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

                    const cantidad_total = parseFloat(itemsReporteStock[0].stock_actual) - parseFloat(obj.cant_entrega)

                    /*  */

                    const uidSolicitudTran = `SO-TRAN-` + numTransferencia;

                    const uidSolicitud = `EGR-` + numPedido;

                    /* db.collection('egreso_productos').doc(uidSolicitud).set({
                        uidSolicitud: uidSolicitud,
                        estado: 0,
                        numero_solicitud: numPedido,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        departamento: props.departamento_seleccionado,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega: props.bodega,
                        motivo: props.motivo.toUpperCase(),
                        motivo_movimiento: 'EGRESO',
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                        tipo_movimiento: 'SOLICITUD PEDIDO',
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                        relacion: props.uidSolicitud
                    })

                    db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
                        uidSolicitud: uidSolicitud,
                        estado: 0,
                        numero_solicitud: numPedido,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        departamento: props.departamento_seleccionado,
                        rol: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega: props.bodega,
                        ubicacion: '',
                        motivo: props.motivo.toUpperCase(),
                        motivo_movimiento: 'EGRESO',
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                        flota: '',
                        lider_flota: '',
                        auxiliar_flota: '',
                        placa_flota: '',
                        marca_flota: '',
                        modelo_flota: '',
                        tipo_movimiento: 'SOLICITUD PEDIDO',
                        empleado: props.user,
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                        relacion: props.uidSolicitud
                    }) */

                    db.collection("reporte_stock").doc(data2[0].id).update({
                        stock_actual: cantidad_producto_reporteS <= 0 || Number.isNaN(Number(cantidad_producto_reporteS)) ? 0 : cantidad_producto_reporteS,
                        total_valorizado: total_operacion.toFixed(2),
                        costo_promedio: costoPromedio.toFixed(2)
                    })

                    /* OBTENEMOS EL ID DE LA COLECCION PRODUCTOS */

                    db.collection("productos").doc(data4[0].id).update({
                        cantidad_producto: cantidad_producto_productos <= 0 || Number.isNaN(Number(cantidad_producto_productos)) ? 0 : cantidad_producto_productos
                    })

                    db.collection(`egreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                        cantidad_solicitada: parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        costo_promedio: costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra === undefined || data2[0].precio_compra === null || data2[0].precio_compra === '' ? 0 : data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        bodega: props.bodega,
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) - parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                    })

                    const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(2)

                    const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

                    const total_bodegas = parseFloat(data4[0].cantidad_producto) - parseInt(obj.cant_entrega)

                    const dataRef = {
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso <= 0 || totalEgreso === undefined || totalEgreso === null ? 0 : totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra === undefined || data2[0].precio_compra === null || data2[0].precio_compra === '' ? 0 : data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.bodega,
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: total_bodegas <= 0 ? 0 : Math.abs(total_bodegas),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    const dataRefIngreso = {
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        ...obj,
                        uid_solicitud_pedido: `ING-${numIngreso}`/* uidSolicitud */,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'ingreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    const dataRef2 = {
                        cantidad_solicitada: parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) || kardexGeneralSumaTotalStock <= 0 ? 0 : kardexGeneralSumaTotalStock,
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso.toFixed(2),
                        total: kardexGeneralSumaTotal === 'NaN' || isNaN(kardexGeneralSumaTotal) ? 0 : Math.abs(kardexGeneralSumaTotal),
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.bodega,
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: total_bodegas <= 0 ? 0 : Math.abs(total_bodegas),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set({
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        tipo_movimiento: 'egreso',
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('reporte_movimientos').doc(`ING-${numIngreso}`).collection('items').doc(obj.codigo).set({
                        ...obj,
                        uid_solicitud_pedido: `ING-${numIngreso}`,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total_ing <= 0 ? 0 : parseFloat(cantidad_total_ing).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        tipo_movimiento: 'ingreso',
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection(`total_movimientos`).doc().set(dataRef)

                    db.collection(`total_movimientos`).doc().set(dataRefIngreso)

                    // db.collection(`total_movimientos_general`).doc().set(dataRef2)

                    /* ESTADO 1 SE ENVIA LA SOLICITUD A SOLICITUDES YA REALIZADAS */

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
                        estado: 1,
                        egreso: firebase.firestore.FieldValue.increment(1),
                        user_entrego: userInfo.displayName,
                        fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
                        observacion_extra: observacionExtra.toUpperCase(),
                    })

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).collection('items').doc(obj.fullName).update({
                        /* cant_entrega: obj.cant_entrega, */
                        cant_diferencia: obj.cant_diferencia,
                        cantidad_pendiente: parseFloat(obj.cantidad_pendiente) - parseFloat(obj.cant_entrega)
                    })

                    sendSeriesToFirebase()

                    /* sendMateriales() */

                    // SEND MATERIALES



                    db.collection('transferencias').doc(uidSolicitudTran).set({
                        subcategoria_producto: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega_origen: obj.bodega,
                        bodega_destino: flotas1[0].numeroFlota,
                        numeroSolicitud: numTransferencia,
                        uidSolicitud: uidSolicitudTran,
                        fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_transfiere: userInfo.displayName,
                        estado: 2,
                        motivo: 'TRANSFERENCIA A FLOTAS',
                        observacion: ''
                    })

                    db.collection('transferencias').doc(uidSolicitudTran).collection('items').doc(obj.codigo).set({
                        subcategoria_producto: '',
                        codigo: obj.codigo,
                        bodega_origen: obj.bodega,
                        bodega_destino: flotas1[0].numeroFlota,
                        numeroSolicitud: numTransferencia,
                        uidSolicitud: uidSolicitudTran,
                        fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_transfiere: userInfo.displayName,
                        descripcion: obj.descripcion,
                        cantidad_transferencia: parseInt(obj.cantidad_pedida),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: ''
                    })

                    db.collection(`reporte_movimientos`).doc(uidSolicitudTran).set({
                        uidSolicitud: uidSolicitudTran,
                        estado: 3,
                        numero_solicitud: numTransferencia,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        departamento: userInfo.departamentoText,
                        rol: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega: obj.bodega,
                        bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        ubicacion: flotas1[0].numeroFlota,
                        motivo_movimiento: 'TRANSFERENCIA',
                        motivo: 'TRANSFERENCIA',
                        observacion_adicional: observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        tipo_movimiento: 'transferencia',
                        fecha_documento: props.fecha_documento ? props.fecha_documento : firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('reporte_movimientos').doc(uidSolicitudTran).collection('items').doc(obj.codigo).set({
                        ...obj,
                        bodega_origen: obj.bodega,
                        bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        uid_solicitud_pedido: uidSolicitudTran,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: itemsReporteStock[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        tipo_movimiento: 'transferencia',
                        precio_compra: itemsReporteStock[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    if (props.correo.includes('flota')) {

                        const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

                        const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                        const query = await db.collection('flotas').doc(flotas1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo).get();

                        if (query.size > 0) {

                            const stock = await query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                            db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).update({
                                stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                            })

                            /* Si son flotas internas */
                            if (props.correo.includes('u')) {
                                db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).update({
                                    stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                                })
                            } else {
                                db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).update({
                                    stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                                })
                            }

                            // INGRESO SERIES A FLOTAS

                            obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                                if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                    return;
                                } else {
                                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })

                                    if (props.correo.includes('u')) {
                                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    } else {
                                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })

                                    }

                                }

                            })

                        } else {

                            db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).set({
                                bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                stock_actual: parseFloat(obj.cant_entrega),
                                total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                            })

                            /* Si son flotas internas */
                            if (props.correo.includes('u')) {
                                db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).set({
                                    bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                    categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                    codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                    costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                    descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                    motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                    precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                    stock_actual: parseFloat(obj.cant_entrega),
                                    total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                    unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                    usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                                })
                            } else {
                                db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).set({
                                    bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                    categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                    codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                    costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                    descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                    motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                    precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                    stock_actual: parseFloat(obj.cant_entrega),
                                    total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                    unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                    usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                                })
                            }

                            // INGRESO SERIES A FLOTAS

                            obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                                if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                    return;
                                } else {
                                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })

                                    if (props.correo.includes('u')) {
                                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    } else {
                                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    }
                                }

                            })

                        }
                    }

                    handleIngresoKardexFlotas()

                    // END SEND MATERIALES

                    setOpen(false)

                    /* Swal.fire({
                        title: 'Se estan cargando todos los datos',
                        text: 'Por favor espere',
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading()
                        },
                    });

                    setTimeout(() => {

                        Swal.fire({
                            title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                            text: 'Por favor espere',
                            allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                            },
                        });

                        navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                        setTimeout(() => {
                            window.location.reload();
                        }, 100000);

                    }, 160000); */

                } else {

                    setOpen(false)

                    Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
                }
            }
        });

        if (items.length >= 0 && items.length <= 8) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 60000);
        } else if (items.length >= 9 && items.length <= 18) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 80000);
        } else if (items.length >= 19 && items.length <= 30) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 110000);
        } else if (items.length >= 31 && items.length <= 40) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 130000);
        } else if (items.length >= 41 && items.length <= 50) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 350000);
        } else if (items.length >= 51) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 490000);
        }
    }

    const sendPrincipalInformationCollections = async () => {

        if (props.correo.includes('flota')) {

            const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

            const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const uidSolicitud = `EGR-` + numPedido;

            await db.collection('egreso_productos').doc(uidSolicitud).set({
                uidSolicitud: uidSolicitud,
                estado: 0,
                numero_solicitud: numPedido,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'EGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
                uidSolicitud: uidSolicitud,
                estado: 0,
                numero_solicitud: numPedido,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                rol: '',
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                ubicacion: flotas1[0].numeroFlota,
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'EGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                empleado: props.user,
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection('ingreso_productos').doc(`ING-${numIngreso}`).set({
                uidSolicitud: `ING-${numIngreso}`,
                estado: 0,
                numero_solicitud: numIngreso,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'INGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection(`reporte_movimientos`).doc(`ING-${numIngreso}`).set({
                uidSolicitud: `ING-${numIngreso}`,
                estado: 0,
                numero_solicitud: numIngreso,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                rol: '',
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                ubicacion: flotas1[0].numeroFlota,
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'INGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                empleado: props.user,
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

        } else {

            const uidSolicitud = `EGR-` + numPedido;

            await db.collection('egreso_productos').doc(uidSolicitud).set({
                uidSolicitud: uidSolicitud,
                estado: 0,
                numero_solicitud: numPedido,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'EGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
                uidSolicitud: uidSolicitud,
                estado: 0,
                numero_solicitud: numPedido,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                rol: '',
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                ubicacion: '',
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'EGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                tipo_movimiento: 'SOLICITUD PEDIDO',
                empleado: props.user,
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection('ingreso_productos').doc(`ING-${numIngreso}`).set({
                uidSolicitud: `ING-${numIngreso}`,
                estado: 0,
                numero_solicitud: numIngreso,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'INGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                tipo_movimiento: 'SOLICITUD PEDIDO',
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })

            await db.collection(`reporte_movimientos`).doc(`ING-${numIngreso}`).set({
                uidSolicitud: `ING-${numIngreso}`,
                estado: 0,
                numero_solicitud: numIngreso,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                departamento: props.departamento_seleccionado,
                rol: '',
                created: firebase.firestore.FieldValue.serverTimestamp(),
                bodega: props.bodega,
                ubicacion: '',
                motivo: props.motivo.toUpperCase(),
                motivo_movimiento: 'INGRESO',
                observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                tipo_movimiento: 'SOLICITUD PEDIDO',
                empleado: props.user,
                fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                relacion: props.uidSolicitud
            })
        }

    }

    const sendConfirmItemsEntregaParcial = () => {

        setLoadingRealizarDespachoParcial(true)
        setLoadingRealizarDespacho(true)

        const uidSolicitud = `EGR-` + numPedido;

        items.forEach(async function (obj, index) {

            /* SI LA CANTIDAD ENTREGADA ES IGUAL A 0 NO SE REFLEJARA NINGUN EGRESO */

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

            const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', obj.bodega).get()

            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

            /* const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(saldo_valorizado) */

            const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            const cantidad_total = parseFloat(data2[0].stock_actual) - parseFloat(obj.cant_entrega)

            const cantidad_total_ing = parseFloat(data2[0].stock_actual) + parseFloat(obj.cant_entrega)

            /* CALCULA EL TOTAL DE LAS BODEGAS */

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            /* Si cantidad entregada es igual a 0 */

            if (parseFloat(obj.cant_entrega) === 0 || obj.cant_entrega === undefined || obj.cant_entrega === null) {

                /* if (parseFloat(data4[0].cantidad_producto) >= parseFloat(obj.cant_entrega)) {

                    // FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS

                    var datoSumaStockTotalBodegas;

                    let sumaStock = 0;
                    for (let i = 0; i < data3.length; i++) {
                        sumaStock += parseFloat(data3[i]["stock_actual"])
                        datoSumaStockTotalBodegas = sumaStock
                    }

                    // FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS

                    var sumaValorTotalValorizadoBodegas;

                    let sumaTotalValorizado = 0;
                    for (let i = 0; i < data3.length; i++) {
                        sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                        sumaValorTotalValorizadoBodegas = sumaTotalValorizado
                    }

                    // TOTAL STOCK ACTUAL + CANTIDAD STOCK DE LA OPERACION (EG8RESO)

                    // TOTAL VALORIZADO + TOTAL VALORIZADO DE LA OPERACION (EG8RESO)

                    const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

                    const cantidad_producto_productos = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(0)
                    const cantidad_producto_reporteS = parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cant_entrega).toFixed(0)

                    db.collection("reporte_stock").doc(data2[0].id).update({
                        stock_actual: cantidad_producto_reporteS <= 0 || Number.isNaN(Number(cantidad_producto_reporteS)) ? 0 : cantidad_producto_reporteS,
                        total_valorizado: total_operacion.toFixed(2),
                        costo_promedio: costoPromedio.toFixed(2)
                    })

                    // OBTENEMOS EL ID DE LA COLECCION PRODUCTOS

                    db.collection("productos").doc(data4[0].id).update({
                        cantidad_producto: cantidad_producto_productos <= 0 || Number.isNaN(Number(cantidad_producto_productos)) ? 0 : cantidad_producto_productos
                    })

                    // ESTADO 4 SE ENVIAN LOS ITEMS A PENDIENTES DE COMPRAS

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
                        estado: 4,
                        egreso: firebase.firestore.FieldValue.increment(1),
                        user_entrego: userInfo.displayName,
                        fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
                        observacion_extra: observacionExtra.toUpperCase(),
                    })
                    db.collection('solicitud_pedido').doc(props.uidSolicitud).collection('items').doc(obj.fullName).update({
                        cant_diferencia: obj.cant_diferencia,
                        cantidad_pendiente: parseFloat(obj.cantidad_pendiente) - parseFloat(obj.cant_entrega)
                    })

                    sendSeriesToFirebase()

                    sendMateriales()

                    setOpen(false)

                } else {

                    setOpen(false)

                    Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
                }
 */
            } else {

                // EGRESO DE PRODUCTO

                if (parseFloat(data4[0].cantidad_producto) >= parseFloat(obj.cant_entrega)) {

                    const itemsReporteStockDB = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega).get()

                    const itemsReporteStock = await itemsReporteStockDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    const operacionCostoPromedio = parseFloat(itemsReporteStock[0].total_valorizado) / parseFloat(itemsReporteStock[0].stock_actual)

                    const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

                    const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

                    const cantidad_total = parseFloat(itemsReporteStock[0].stock_actual) - parseFloat(obj.cant_entrega)

                    const uidSolicitudTran = `SO-TRAN-` + numTransferencia;

                    const uidSolicitud = `EGR-` + numPedido;

                    // FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS

                    var datoSumaStockTotalBodegas;

                    let sumaStock = 0;
                    for (let i = 0; i < data3.length; i++) {
                        sumaStock += parseFloat(data3[i]["stock_actual"])
                        datoSumaStockTotalBodegas = sumaStock
                    }

                    // FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS

                    var sumaValorTotalValorizadoBodegas;

                    let sumaTotalValorizado = 0;
                    for (let i = 0; i < data3.length; i++) {
                        sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                        sumaValorTotalValorizadoBodegas = sumaTotalValorizado
                    }

                    const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

                    const cantidad_producto_productos = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(0)
                    const cantidad_producto_reporteS = parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cant_entrega).toFixed(0)

                    await db.collection("reporte_stock").doc(data2[0].id).update({
                        stock_actual: cantidad_producto_reporteS <= 0 || Number.isNaN(Number(cantidad_producto_reporteS)) ? 0 : cantidad_producto_reporteS,
                        total_valorizado: total_operacion.toFixed(2),
                        costo_promedio: costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2)
                    })

                    // OBTENEMOS EL ID DE LA COLECCION PRODUCTOS

                    await db.collection("productos").doc(data4[0].id).update({
                        cantidad_producto: cantidad_producto_productos <= 0 || Number.isNaN(Number(cantidad_producto_productos)) ? 0 : cantidad_producto_productos
                    })

                    await db.collection(`egreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                        cantidad_solicitada: parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra === undefined || data2[0].precio_compra === null || data2[0].precio_compra === '' ? 0 : data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) - parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                    })

                    const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(2)

                    const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

                    const dataRef = {
                        cantidad_solicitada: parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.bodega,
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) - parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    const dataRefIngreso = {
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        ...obj,
                        uid_solicitud_pedido: `ING-${numIngreso}`,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        total: total_operacion.toFixed(2),
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA'/* props.bodega */,
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'ingreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    const dataRef2 = {
                        cantidad_solicitada: parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) || kardexGeneralSumaTotalStock <= 0 ? 0 : kardexGeneralSumaTotalStock,
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: '',
                        subcategoria_producto: '',
                        marca_producto: '',
                        modelo_producto: '',
                        presentacion_producto: '',
                        unidad_Medida_Producto: '',
                        usuario_que_creo_el_producto: '',
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        saldo_valorizado: totalEgreso.toFixed(2),
                        total: Number.isNaN(Number(kardexGeneralSumaTotal)) ? 0 : Math.abs(kardexGeneralSumaTotal),
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        bodega: props.bodega,
                        ubicacion: '',
                        motivo: props.motivo,
                        tipo_movimiento: 'egreso',
                        total_bodegas: parseFloat(data4[0].cantidad_producto) - parseInt(obj.cant_entrega),
                        suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                        costo_promedio_general: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    }

                    await db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set({
                        ...obj,
                        uid_solicitud_pedido: uidSolicitud,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        tipo_movimiento: 'egreso',
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    await db.collection('reporte_movimientos').doc(`ING-${numIngreso}`).collection('items').doc(obj.codigo).set({
                        ...obj,
                        uid_solicitud_pedido: `ING-${numIngreso}`,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: data2[0].stock_actual,
                        cantidad_total_despues: cantidad_total_ing <= 0 ? 0 : parseFloat(cantidad_total_ing).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: data2[0].costo_promedio <= 0 || data2[0].costo_promedio === undefined || data2[0].costo_promedio === null || data2[0].costo_promedio === '' ? 0 : data2[0].costo_promedio,
                        tipo_movimiento: 'ingreso',
                        precio_compra: data2[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection(`total_movimientos`).doc().set(dataRef)

                    db.collection(`total_movimientos`).doc().set(dataRefIngreso)

                    // db.collection(`total_movimientos_general`).doc().set(dataRef2)

                    // ESTADO 4 SE ENVIAN LOS ITEMS A PENDIENTES DE COMPRAS

                    db.collection('solicitud_pedido').doc(props.uidSolicitud).update({
                        estado: 4,
                        egreso: firebase.firestore.FieldValue.increment(1),
                        user_entrego: userInfo.displayName,
                        fecha_egreso: firebase.firestore.FieldValue.serverTimestamp(),
                        observacion_extra: observacionExtra.toUpperCase(),
                    })
                    db.collection('solicitud_pedido').doc(props.uidSolicitud).collection('items').doc(obj.fullName).update({
                        // cant_entrega: obj.cant_entrega
                        cant_diferencia: obj.cant_diferencia,
                        cantidad_pendiente: parseFloat(obj.cantidad_pendiente) - parseFloat(obj.cant_entrega)
                    })

                    sendSeriesToFirebase()

                    /* sendMateriales() */

                    // SEND MATERIALES

                    /*  */



                    db.collection('transferencias').doc(uidSolicitudTran).set({
                        subcategoria_producto: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega_origen: obj.bodega,
                        bodega_destino: flotas1[0].numeroFlota,
                        numeroSolicitud: numTransferencia,
                        uidSolicitud: uidSolicitudTran,
                        fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_transfiere: userInfo.displayName,
                        estado: 2,
                        motivo: 'TRANSFERENCIA A FLOTAS',
                        observacion: ''
                    })

                    db.collection('transferencias').doc(uidSolicitudTran).collection('items').doc(obj.codigo).set({
                        subcategoria_producto: '',
                        codigo: obj.codigo,
                        bodega_origen: obj.bodega,
                        bodega_destino: flotas1[0].numeroFlota,
                        numeroSolicitud: numTransferencia,
                        uidSolicitud: uidSolicitudTran,
                        fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                        usuario_que_transfiere: userInfo.displayName,
                        descripcion: obj.descripcion,
                        cantidad_transferencia: parseInt(obj.cantidad_pedida),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        categoria_producto: ''
                    })

                    db.collection(`reporte_movimientos`).doc(uidSolicitudTran).set({
                        uidSolicitud: uidSolicitudTran,
                        estado: 3,
                        numero_solicitud: numTransferencia,
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        departamento: userInfo.departamentoText,
                        rol: '',
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        bodega: obj.bodega,
                        bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        ubicacion: flotas1[0].numeroFlota,
                        motivo_movimiento: 'TRANSFERENCIA',
                        motivo: 'TRANSFERENCIA',
                        observacion_adicional: observacionExtra.toUpperCase(),
                        flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                        lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                        auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                        placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                        marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                        modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                        tipo_movimiento: 'transferencia',
                        fecha_documento: props.fecha_documento ? props.fecha_documento : firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('reporte_movimientos').doc(uidSolicitudTran).collection('items').doc(obj.codigo).set({
                        ...obj,
                        bodega_origen: obj.bodega,
                        bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                        uid_solicitud_pedido: uidSolicitudTran,
                        cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                        cantidad_total_antes: itemsReporteStock[0].stock_actual,
                        cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                        uid_user: userInfo.uid,
                        user: userInfo.displayName,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                        costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                        tipo_movimiento: 'transferencia',
                        precio_compra: itemsReporteStock[0].precio_compra,
                        observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                        fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    if (props.correo.includes('flota')) {

                        const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

                        const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                        const query = await db.collection('flotas').doc(flotas1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo).get();

                        if (query.size > 0) {

                            const stock = await query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                            db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).update({
                                stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                            })

                            /* Si son flotas internas */
                            if (props.correo.includes('u')) {
                                db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).update({
                                    stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                                })
                            } else {
                                db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).update({
                                    stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                                })
                            }

                            // INGRESO SERIES A FLOTAS

                            obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                                if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                    return;
                                } else {
                                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })

                                    if (props.correo.includes('u')) {
                                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    } else {
                                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })

                                    }

                                }

                            })

                        } else {

                            db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).set({
                                bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                stock_actual: parseFloat(obj.cant_entrega),
                                total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                            })

                            /* Si son flotas internas */
                            if (props.correo.includes('u')) {
                                db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).set({
                                    bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                    categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                    codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                    costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                    descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                    motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                    precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                    stock_actual: parseFloat(obj.cant_entrega),
                                    total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                    unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                    usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                                })
                            } else {
                                db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).set({
                                    bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                                    categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                                    codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                                    costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                                    descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                                    motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                                    precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                                    stock_actual: parseFloat(obj.cant_entrega),
                                    total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                                    unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                                    usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                                })
                            }

                            // INGRESO SERIES A FLOTAS

                            obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                                if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                    return;
                                } else {
                                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })

                                    if (props.correo.includes('u')) {
                                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    } else {
                                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                            serie:
                                                objSerie.serie === "" ||
                                                    objSerie.serie === null ||
                                                    objSerie.serie === undefined
                                                    ? ""
                                                    : objSerie.serie,
                                            created:
                                                firebase.firestore.FieldValue.serverTimestamp(),
                                            usuario_que_cargo_serie: userInfo.displayName,
                                            utilizado_por_codigo: obj.codigo,
                                            bodega: obj.bodega,
                                            estado: 0,
                                            descripcion: obj.descripcion
                                        })
                                    }
                                }

                            })

                        }
                    }

                    handleIngresoKardexFlotas()

                    // END SEND MATERIALES

                    setOpen(false)

                } else {

                    setOpen(false)

                    Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
                }
            }

        });

        if (items.length >= 0 && items.length <= 8) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 60000);
        } else if (items.length >= 9 && items.length <= 18) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 80000);
        } else if (items.length >= 19 && items.length <= 30) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 110000);
        } else if (items.length >= 31 && items.length <= 40) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 130000);
        } else if (items.length >= 41 && items.length <= 50) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 350000);
        } else if (items.length >= 51) {
            Swal.fire({
                title: 'Se estan cargando todos los datos',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            setTimeout(() => {

                Swal.fire({
                    title: 'Estamos cargando sus datos para mostrarlos en el PDF',
                    text: 'Por favor espere',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });

                navigate(`/inventario/documentacion/reporte-de-movimientos/`)

                setTimeout(() => {
                    window.location.reload();
                }, 4000);

            }, 490000);
        }

    }

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );

    const verificacionCantidadATransferirParcial = () => {

        const cantidadesValidasTransferirParcial = items.some(item => item.stock_actual >= item.cant_entrega);

        if (cantidadesValidasTransferirParcial) {
            /* setOpenConfirmDespachoParcial(false) */
            handleParcialButtonClick()
            /* setOpenConfirmDespachoParcial(false)
            sendPrincipalInformationCollections()
            sendConfirmItemsEntregaParcial() */
        } else {
            setOpen(false)
            Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
            setItems([])
        }

        /* 
        items.forEach(async function (obj, index) {
            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            if (cantidadesValidasTransferirParcial) {
                sendPrincipalInformationCollections()
                sendConfirmItemsEntregaParcial()
            } else {
                setOpen(false)

                Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
            }
        });
        */

    }

    const verificacionCantidadATransferirCompleta = () => {

        const cantidadesValidasTransferirParcial = items.some(item => item.stock_actual >= item.cant_entrega);

        if (cantidadesValidasTransferirParcial) {
            /* setOpenConfirmDespachoTotal(false)
            sendPrincipalInformationCollections()
            sendConfirmItems() */
            /* setOpenConfirmDespachoTotal(false) */
            handleButtonClick()
        } else {
            setOpen(false)
            Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
            setItems([])
        }

        /* items.forEach(async function (obj, index) {
            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.fullName).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            if (parseFloat(data4[0].cantidad_producto) >= obj.cant_entrega) {
                sendPrincipalInformationCollections()
                sendConfirmItems()
            } else {
                setOpen(false)
                Swal.fire({ icon: "warning", text: `No existe el stock necesario para algunos productos` });
            }
        }); */
    }

    const sendSeriesToFirebase = async () => {

        items.forEach(async (obj, index) => {

            obj.series.forEach(async (objSerie, index) => {

                if (objSerie.serie.toString().length <= 0 || objSerie.serie === undefined || objSerie.serie === null) {
                    return;
                } else {

                    const uidSolicitud = `EGR-` + numPedido;

                    await db.collection('reporte_movimientos').doc(uidSolicitud).collection('items')
                        .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: obj.bodega,
                            estado: 1
                        })

                    await db.collection(`egreso_productos/${uidSolicitud}/items`)
                        .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: obj.bodega,
                            estado: 1
                        })

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).where('bodega', '==', obj.bodega).get()

                    const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    await db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(objSerie.serie.toString())
                        .set({
                            serie:
                                objSerie.serie === "" ||
                                    objSerie.serie === null ||
                                    objSerie.serie === undefined
                                    ? ""
                                    : objSerie.serie.toString(),
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: obj.fullName,
                            bodega: obj.bodega,
                            estado: 1
                        })
                }

            })

        })

    }

    const [numTransferencia, setNumTransferencia] = useState(false)

    const loadNumTransferencia = () => {
        const ref = db.collection("transferencias").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `SO-TRAN-` + numeroSolicitud;
                setNumTransferencia(numeroSolicitud)
            }
        })
    }

    const sendMateriales = async () => {

        if (props.correo.includes('flota')) {

            handleIngresoKardexFlotas()

            const flotasDB1 = await db.collection("flotas").where("correo", "==", props.correo).get()

            const flotas1 = await flotasDB1.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            items.forEach(async function (obj, index) {

                const itemsReporteStockDB = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega).get()

                const itemsReporteStock = await itemsReporteStockDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                const operacionCostoPromedio = parseFloat(itemsReporteStock[0].total_valorizado) / parseFloat(itemsReporteStock[0].stock_actual)

                const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

                const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

                const cantidad_total = parseFloat(itemsReporteStock[0].stock_actual) - parseFloat(obj.cant_entrega)

                /*  */

                const query = await db.collection('flotas').doc(flotas1[0].id).collection('materiales').where('codigo_producto', '==', obj.codigo).get();

                const uidSolicitud = `SO-TRAN-` + numTransferencia;

                db.collection('transferencias').doc(uidSolicitud).set({
                    subcategoria_producto: '',
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    bodega_origen: obj.bodega,
                    bodega_destino: flotas1[0].numeroFlota,
                    numeroSolicitud: numTransferencia,
                    uidSolicitud: uidSolicitud,
                    fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                    usuario_que_transfiere: userInfo.displayName,
                    estado: 2,
                    motivo: 'TRANSFERENCIA A FLOTAS',
                    observacion: ''
                })

                db.collection('transferencias').doc(uidSolicitud).collection('items').doc(obj.codigo).set({
                    subcategoria_producto: '',
                    codigo: obj.codigo,
                    bodega_origen: obj.bodega,
                    bodega_destino: flotas1[0].numeroFlota,
                    numeroSolicitud: numTransferencia,
                    uidSolicitud: uidSolicitud,
                    fecha_transferencia: firebase.firestore.FieldValue.serverTimestamp(),
                    usuario_que_transfiere: userInfo.displayName,
                    descripcion: obj.descripcion,
                    cantidad_transferencia: parseInt(obj.cantidad_pedida),
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    categoria_producto: ''
                })

                db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
                    uidSolicitud: uidSolicitud,
                    estado: 3,
                    numero_solicitud: numTransferencia,
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    departamento: userInfo.departamentoText,
                    rol: '',
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    bodega: obj.bodega,
                    bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    ubicacion: flotas1[0].numeroFlota,
                    motivo_movimiento: 'TRANSFERENCIA',
                    motivo: 'TRANSFERENCIA',
                    observacion_adicional: observacionExtra.toUpperCase(),
                    flota: flotas1[0] === undefined || flotas1[0].numeroFlota === undefined || flotas1[0].numeroFlota === null || flotas1[0].numeroFlota === '' ? '' : flotas1[0].numeroFlota,
                    lider_flota: flotas1[0] === undefined || flotas1[0].nombreLider === undefined || flotas1[0].nombreLider === null || flotas1[0].nombreLider === '' ? '' : flotas1[0].nombreLider,
                    auxiliar_flota: flotas1[0] === undefined || flotas1[0].nombreAuxiliar === undefined || flotas1[0].nombreAuxiliar === null || flotas1[0].nombreAuxiliar === '' ? '' : flotas1[0].nombreAuxiliar,
                    placa_flota: flotas1[0] === undefined || flotas1[0].placa === undefined || flotas1[0].placa === null || flotas1[0].placa === '' ? '' : flotas1[0].placa,
                    marca_flota: flotas1[0] === undefined || flotas1[0].marca === undefined || flotas1[0].marca === null || flotas1[0].marca === '' ? '' : flotas1[0].marca,
                    modelo_flota: flotas1[0] === undefined || flotas1[0].modelo === undefined || flotas1[0].modelo === null || flotas1[0].modelo === '' ? '' : flotas1[0].modelo,
                    tipo_movimiento: 'transferencia',
                    fecha_documento: props.fecha_documento ? props.fecha_documento : firebase.firestore.FieldValue.serverTimestamp(),
                })

                db.collection('reporte_movimientos').doc(uidSolicitud).collection('items').doc(obj.codigo).set({
                    ...obj,
                    bodega_origen: obj.bodega,
                    bodega_destino: props.correo.includes('u') ? 'BODEGA FLOTA INTERNA' : 'BODEGA FLOTA EXTERNA',
                    uid_solicitud_pedido: uidSolicitud,
                    cantidad_solicitada: parseInt(obj.cant_entrega) <= 0 ? 0 : parseInt(obj.cant_entrega),
                    cantidad_total_antes: itemsReporteStock[0].stock_actual,
                    cantidad_total_despues: cantidad_total <= 0 ? 0 : parseFloat(cantidad_total).toFixed(2),
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    saldo_valorizado: totalEgreso <= 0 ? 0 : totalEgreso.toFixed(2),
                    costo_promedio: costoPromedio <= 0 || costoPromedio === undefined || costoPromedio === null || costoPromedio === '' ? 0 : costoPromedio.toFixed(2),
                    tipo_movimiento: 'transferencia',
                    precio_compra: itemsReporteStock[0].precio_compra,
                    observacion_adicional: observacionExtra === '' ? 'NINGUNA' : observacionExtra.toUpperCase(),
                    fecha_documento: firebase.firestore.FieldValue.serverTimestamp(),
                })

                if (query.size > 0) {

                    const stock = await query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).update({
                        stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                    })

                    /* Si son flotas internas */
                    if (props.correo.includes('u')) {
                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).update({
                            stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                        })
                    } else {
                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).update({
                            stock_actual: parseFloat(stock[0].stock_actual) + parseFloat(obj.cant_entrega),
                        })
                    }

                    items.forEach(async (obj, index) => {

                        obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                            if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                return;
                            } else {
                                db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie,
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega,
                                    estado: 0,
                                    descripcion: obj.descripcion
                                })

                                if (props.correo.includes('u')) {
                                    db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })
                                } else {
                                    db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })
                                }
                            }

                        })

                    })

                } else {

                    db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).set({
                        bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                        categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                        codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                        costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                        descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                        motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                        precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                        stock_actual: parseFloat(obj.cant_entrega),
                        total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                        unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                        usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                    })

                    /* Si son flotas internas */
                    if (props.correo.includes('u')) {
                        db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).set({
                            bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                            categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                            codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                            costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                            descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                            motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                            precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                            stock_actual: parseFloat(obj.cant_entrega),
                            total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                            unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                            usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                        })
                    } else {
                        db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).set({
                            bodega: itemsReporteStock[0].bodega === undefined ? '' : itemsReporteStock[0].bodega,
                            categoria: itemsReporteStock[0].categoria === undefined ? '' : itemsReporteStock[0].categoria,
                            codigo_producto: itemsReporteStock[0].codigo_producto === undefined ? '' : itemsReporteStock[0].codigo_producto,
                            costo_promedio: itemsReporteStock[0].costo_promedio === undefined ? '' : itemsReporteStock[0].costo_promedio,
                            descripcion: itemsReporteStock[0].descripcion === undefined ? '' : itemsReporteStock[0].descripcion,
                            motivo: itemsReporteStock[0].motivo === undefined ? '' : itemsReporteStock[0].motivo,
                            precio_compra: itemsReporteStock[0].precio_compra === undefined ? '' : itemsReporteStock[0].precio_compra,
                            stock_actual: parseFloat(obj.cant_entrega),
                            total_valorizado: itemsReporteStock[0].total_valorizado === undefined ? '' : itemsReporteStock[0].total_valorizado,
                            unidad_medida: itemsReporteStock[0].unidad_medida === undefined ? '' : itemsReporteStock[0].unidad_medida,
                            usuario_que_subio_carga_producto: itemsReporteStock[0].usuario_que_subio_carga_producto === undefined ? '' : itemsReporteStock[0].usuario_que_subio_carga_producto
                        })
                    }

                    items.forEach(async (obj, index) => {

                        obj.series === undefined ? console.log('') : obj.series.forEach(async (objSerie, index) => {

                            if (objSerie.serie.toString().length <= 0 || objSerie.serie === 0 || objSerie.serie === '0') {
                                return;
                            } else {
                                db.collection('flotas').doc(flotas1[0].id).collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie,
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega,
                                    estado: 0,
                                    descripcion: obj.descripcion
                                })

                                if (props.correo.includes('u')) {
                                    db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })
                                } else {
                                    db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc(obj.codigo).collection('series').doc(objSerie.serie.toString()).set({
                                        serie:
                                            objSerie.serie === "" ||
                                                objSerie.serie === null ||
                                                objSerie.serie === undefined
                                                ? ""
                                                : objSerie.serie,
                                        created:
                                            firebase.firestore.FieldValue.serverTimestamp(),
                                        usuario_que_cargo_serie: userInfo.displayName,
                                        utilizado_por_codigo: obj.codigo,
                                        bodega: obj.bodega,
                                        estado: 0,
                                        descripcion: obj.descripcion
                                    })
                                }
                            }

                        })

                    })

                }

            })

        } else {
        }

    }

    const printData = () => {
        console.log('items', items)
    }

    const handleButtonClick = async () => {

        setLoadingButtonDespachoCompleto(true)

        console.log('items', items)
        try {
            const data = {
                props: props,
                items: items === undefined || items === null ? [] : items,
                numPedido: numPedido,
                numIngreso: numIngreso,
                numTransferencia: numTransferencia,
                observacionExtra: observacionExtra,
                userInfo: userInfo
            };
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                data: {
                    ...data
                },
            })
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(
                "https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiBodega/sendDespachoCompleto",
                requestOptions
            )
                .then(response => response.text())
                .then(result => {
                    setOpen(false)

                    setOpenConfirmDespachoTotal(false)

                    Swal.fire({
                        icon: "success",
                        text: "Se realizo el despacho completo de mercaderia correctamente"
                    }).then((res) => {
                        if (res.isConfirmed) {
                            navigate(`/inventario/solicitudes/solicitud-inventario/${props.id}/`)
                        }
                    })

                    /* setTimeout(() => {
                        navigate(`/inventario/documentacion/reporte-de-movimientos/`) 
                        navigate(`/inventario/solicitudes/solicitud-inventario/${props.id}/`)
                    }, 3000);*/

                })
            /*const sendDespachoCompleto = firebase.functions().httpsCallable("sendDespachoCompleto");

            const data = {
                // Aquí colocas los parámetros que necesitas enviar a la Cloud Function
                props: props,
                items: items === undefined || items === null ? [] : items,
                numPedido: numPedido, // Ejemplo, reemplaza esto con el valor real
                numIngreso: numIngreso, // Ejemplo, reemplaza esto con el valor real
                numTransferencia: numTransferencia,
                observacionExtra: observacionExtra, // Ejemplo, reemplaza esto con el valor real
                userInfo: userInfo
                // userInfo: {
                //     uid: userInfo.uid, // Ejemplo, reemplaza esto con el valor real
                //     displayName: userInfo.displayName, // Ejemplo, reemplaza esto con el valor real
                //     // Resto de información de usuario que necesitas enviar
                // },
            };

            const response = await sendDespachoCompleto(data);*/

        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpen(false)

            setOpenConfirmDespachoTotal(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    };

    const handleParcialButtonClick = async () => {

        /* navigate(`/inventario/solicitudes/solicitud-inventario/${props.id}/`) */

        setLoadingButtonDespachoParcial(true)

        console.log('items', items)
        try {
            const data = {
                props: props,
                items: items === undefined || items === null ? [] : items,
                numPedido: numPedido,
                numIngreso: numIngreso,
                numTransferencia: numTransferencia,
                observacionExtra: observacionExtra,
                userInfo: userInfo
            };
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                data: {
                    ...data
                },
            })
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(
                "https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiBodega/sendDespachoParcial",
                requestOptions
            )
                .then(response => response.text())
                .then(result => {
                    setOpen(false)

                    setOpenConfirmDespachoParcial(false)

                    Swal.fire({
                        icon: "success",
                        text: "Se realizo el despacho parcial de mercaderia correctamente",
                    }).then((res) => {
                        if (res.isConfirmed) {
                            navigate(`/inventario/solicitudes/solicitud-inventario/${props.id}/`)
                        }
                    });

                    /*setTimeout(() => {
                         navigate(`/inventario/documentacion/reporte-de-movimientos/`) 
                        navigate(`/inventario/solicitudes/solicitud-inventario/${props.id}/`)
                    }, 3000);

                    setTimeout(() => {
                        window.location.reload();
                    }, 6000);

                    console.log(response.data.message); */
                })
            /*const sendDespachoParcial = firebase.functions().httpsCallable("sendDespachoParcial");

            const data = {
                // Aquí colocas los parámetros que necesitas enviar a la Cloud Function
                props: props,
                items: items === undefined || items === null ? [] : items,
                numPedido: numPedido, // Ejemplo, reemplaza esto con el valor real
                numIngreso: numIngreso, // Ejemplo, reemplaza esto con el valor real
                numTransferencia: numTransferencia,
                observacionExtra: observacionExtra, // Ejemplo, reemplaza esto con el valor real
                userInfo: userInfo
                // userInfo: {
                //     uid: userInfo.uid, // Ejemplo, reemplaza esto con el valor real
                //     displayName: userInfo.displayName, // Ejemplo, reemplaza esto con el valor real
                //     // Resto de información de usuario que necesitas enviar
                // },
            };

            const response = await sendDespachoParcial(data);*/

        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpen(false)

            setOpenConfirmDespachoParcial(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    };


    const verificacionDeStockParcial = () => {
        const cantidadesValidas = items.some(item => item.cant_entrega > item.stock_actual);
        if (cantTotalOnus > 8) {
            Swal.fire({ icon: "warning", text: `SE HA DETECTADO QUE LA FLOTA EN SU BODEGA TIENE ${cantTotalOnus} ONUS, LA CANTIDAD MAXIMA DE ONUS PERMITIDAS 8` })
            setOpen(false)
        } else if (cantidadesValidas) {
            setItems([])
            Swal.fire({ icon: "warning", text: `Se ha detectado que algunas cantidades que desea egresar son mayores al stock actual` })
            setOpen(false)
        } else {
            verificacionCantidadATransferirParcial()
        }
    }

    const verificacionDeStockCompleta = () => {
        const cantidadesValidas = items.some(item => item.cant_entrega > item.stock_actual);
        if (cantTotalOnus > 8) {
            Swal.fire({ icon: "warning", text: `SE HA DETECTADO QUE LA FLOTA EN SU BODEGA TIENE ${cantTotalOnus} ONUS, LA CANTIDAD MAXIMA DE ONUS PERMITIDAS 8` })
            setOpen(false)
        } else if (cantidadesValidas) {
            setItems([])
            Swal.fire({ icon: "warning", text: `Se ha detectado que algunas cantidades que desea egresar son mayores al stock actual` })
            setOpen(false)
        } else {
            verificacionCantidadATransferirCompleta()
        }
    }

    return (
        <>
            <Button onClick={() => onData()}>
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
            >
                <DialogTitle>
                    CANTIDAD A ENTREGAR: {props.id}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 550, width: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CODIGO</TableCell>
                                        <TableCell align="center">DESCRIPCION</TableCell>
                                        {/* <TableCell align="center">STOCK ACTUAL</TableCell> */}
                                        <TableCell align="center">CANTIDAD SOLICITADA</TableCell>
                                        <TableCell align="center">CANTIDAD PENDIENTE</TableCell>
                                        {/* <TableCell align="center">CANTIDAD ENTREGADA</TableCell> */}
                                        {/* <TableCell align="center">DIFERENCIA</TableCell> */}
                                        {/* <TableCell align="center">SERIES</TableCell> */}
                                        <TableCell align="center">MOTIVO</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        items && items.map((item, index) => (
                                            <TableRow>
                                                <TableCell align="center">{item.codigo}</TableCell>
                                                <TableCell align="center">{item.descripcion}</TableCell>
                                                {/* <TableCell align="center">{item.stock_actual}</TableCell> */}
                                                <TableCell align="center">{item.cantidad}</TableCell>
                                                <TableCell align="center">{item.cantidad_pendiente}</TableCell>
                                                {/* <TableCell align="center">
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        name={"cant_entrega"}
                                                        onChange={(e) => {
                                                            handleChange(index, e, item)
                                                            pruebaBotonesUseEffect()
                                                        }}
                                                        value={item.cant_entrega}
                                                        size='small'
                                                        type='number'
                                                    />
                                                </TableCell> */}
                                                {/* <TableCell align="center">{item.cant_diferencia ? item.cant_diferencia : 0}</TableCell> */}
                                                {/* <TableCell>
                                                    <UploadSeriesFunction props={item} codigo={item.codigo} bodega={item.bodega} />
                                                </TableCell> */}
                                                <TableCell align="center">{item.motivo}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>



                </DialogContent>
                <Box p={2}>
                    <TextField
                        label='OBSERVACION'
                        fullWidth
                        value={observacionExtra}
                        onChange={(e) => setObservacionExtra(e.target.value)}
                    />
                </Box>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                        setBotonValidado('')
                    }}>CERRAR VENTANA</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        setOpen1(true)
                    }} size='small' color='error' variant="contained" disabled={loading} >RECHAZAR DESPACHO</Button>
                    {/* {
                        incluyeCodigo === true ?
                            <LoadingButton
                                loading={loadingRealizarDespachoParcial}
                                endIcon={loadingRealizarDespachoParcial === true ?
                                    <SendIcon /> : ''}
                                loadingPosition="end"
                                disabled={
                                    boolSeries === true ||
                                    botonValidado === '' ||
                                    botonValidado === false}
                                onClick={() => setOpenConfirmDespachoParcial(true)}
                                size='small' color='warning' variant="contained" >
                                REALIZAR DESPACHO PARCIAL
                            </LoadingButton>
                            :
                            <LoadingButton loading={loadingRealizarDespachoParcial} endIcon={loadingRealizarDespachoParcial === true ? <SendIcon /> : ''} loadingPosition="end" disabled={botonValidado === '' || botonValidado === false} onClick={() => setOpenConfirmDespachoParcial(true)} size='small' color='warning' variant="contained" >REALIZAR DESPACHO PARCIAL</LoadingButton>
                    }

                    {
                        incluyeCodigo === true ?
                            <LoadingButton loading={loadingRealizarDespacho} endIcon={loadingRealizarDespacho === true ? <SendIcon /> : ''} loadingPosition="end" disabled={boolSeries === true || botonValidado === '' || botonValidado === true} onClick={() => setOpenConfirmDespachoTotal(true)} size='small' color='success' variant="contained" >REALIZAR DESPACHO</LoadingButton>
                            :
                            <LoadingButton loading={loadingRealizarDespacho} endIcon={loadingRealizarDespacho === true ? <SendIcon /> : ''} loadingPosition="end" disabled={botonValidado === '' || botonValidado === true} onClick={() => setOpenConfirmDespachoTotal(true)} size='small' color='success' variant="contained" >REALIZAR DESPACHO</LoadingButton>
                    } */}

                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth

                open={open1}
            >
                <DialogTitle>
                    ESCRIBIR EL RECHAZO DE: {props.id}
                </DialogTitle>
                <DialogContent dividers>
                    <Box py={1}>
                        <TextField
                            label='RECHAZO DE LA ENTREGA'
                            fullWidth
                            multiline
                            onChange={(e) => setMotivoRechazo(e.target.value)}
                            rows={4}
                        />
                    </Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setOpen1(false)}>CERRAR VENTANA</Button>
                    <Button onClick={onRechazar} color='error' variant="contained" disabled={loading || !motivoRechazo} >RECHAZAR ENTREGA</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth

                open={openValidacionOnus}
            >
                <DialogTitle dividers>
                    SE HA DETECTADO QUE LA FLOTA EN SU BODEGA TIENE <strong>{cantTotalOnus}</strong> ONUS, LA CANTIDAD MAXIMA DE ONUS PERMITIDAS <strong>8</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions dividers>
                    <Button color="secondary" variant="contained" size="small" onClick={() => setOpenValidacionOnus(false)}>OK</Button>
                </DialogActions>
            </Dialog>

            {/* confirm entrega total */}

            <Dialog
                fullWidth

                open={openConfirmDespachoTotal}
            >
                <DialogTitle dividers>
                    ¿ESTA SEGURO DE REALIZAR EL DESPACHO TOTAL?
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions dividers>
                    <LoadingButton loading={loadingButtonDespachoCompleto}
                        endIcon={loadingButtonDespachoCompleto === true ? <SendIcon /> : ''} loadingPosition="end" color="warning" variant="outlined" size="small" onClick={() => setOpenConfirmDespachoTotal(false)}>REGRESAR</LoadingButton>
                    <LoadingButton loading={loadingButtonDespachoCompleto}
                        endIcon={loadingButtonDespachoCompleto === true ? <SendIcon /> : ''} loadingPosition="end" color="success" variant="contained" size="small" onClick={() => verificacionDeStockCompleta()}>REALIZAR DESPACHO</LoadingButton>
                </DialogActions>
            </Dialog>

            {/* confirm entrega parcial */}

            <Dialog
                fullWidth

                open={openConfirmDespachoParcial}
            >
                <DialogTitle dividers>
                    ¿ESTA SEGURO DE REALIZAR EL DESPACHO PARCIAL?
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions dividers>
                    <LoadingButton loading={loadingButtonDespachoParcial}
                        endIcon={loadingButtonDespachoParcial === true ? <SendIcon /> : ''} loadingPosition="end" color="warning" variant="outlined" size="small" onClick={() => setOpenConfirmDespachoParcial(false)}>REGRESAR</LoadingButton>
                    <LoadingButton loading={loadingButtonDespachoParcial}
                        endIcon={loadingButtonDespachoParcial === true ? <SendIcon /> : ''} loadingPosition="end" color="success" variant="contained" size="small" onClick={() => verificacionDeStockParcial()}>REALIZAR DESPACHO PARCIAL</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VerBodega