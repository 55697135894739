/* import {
  IconButton,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Collapse,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";

import moment from 'moment'

import { db, firebase, auth } from '../../../firebase'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useSelector } from 'react-redux';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SearchInput from '../../../components/utils/SearchInput';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridExportMenuItemProps,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridApi,
  GridToolbar,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { ViewPdfClient } from "../../../components/clientes/ViewPdfClient";
import { FiscalizarInstalacion } from "./IconButtonsFiscalizacion/FiscalizarInstalacion";

export const InstalacionesPorFiscalizara = () => {

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  function Estado({ estado }) {

    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'Por agendar'
      color = 'warning'
    } else if (estado === 1) {
      name = 'Rechazada'
      color = 'info'
    } else if (estado === 2) {
      name = 'Realizada'
      color = 'success'
    }
    return (
      <Chip label={name} color={color} />
    )
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  var current = new Date();
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  console.log(startDate, 'date')
  useEffect(() => {
    LoadData()
  }, []);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("registro_orden_trabajo");


    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)
      console.log(startDate, 'fecha1')
      console.log(endDate, 'fecha2')
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];

        setData(data);
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
        setIsLastPage(totalCount < 1);
      });
    }
  };

  function CustomFooterTotalComponent(props) {
    console.log(props)
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  function Estado() {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (0 === 0) {
      name = 'POR FISCALIZAR'
      color = 'warning'
    }
    return (
      <Chip label={name} size="small" variant="filled" color={color} />
    )
  }

  const columns = [
    {
      field: 'estado', headerName: 'ESTADO', width: 175,
      renderCell: (params) => {
        return <Estado />
      }
    },
    {
      field: '', headerName: 'FISCALIZAR', width: 130,
      renderCell: (params) => {
        return <FiscalizarInstalacion props={params.row} />
      }
    },
    {
      field: 'motivo', headerName: 'MOTIVO', width: 175,
      renderCell: (params) => {

        return params.row.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION'
      }
    },
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'CLIENTE', width: 380 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 150 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 200 },
    {
      field: 'created', headerName: 'FECHA AGENDAMIENTO', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    { field: 'cableInicio', headerName: 'CABLE INICIO', width: 200 },
    { field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 200 },
    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 150 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
  ];

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    function instalacion(fechaHora) {
      if (fechaHora && fechaHora.seconds) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }
    }
    //
    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.id,
          MOTIVO: doc.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION',
          TIPO_CONTRATO: doc.contrato,
          CEDULA: doc.cedula,
          CLIENTE: doc.nombres,
          CORREO: doc.correo,

          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
          DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
          GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.discapacidad,
          DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.descuento,
          VALOR_DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.valor,
          DIRECCION: doc.direccion,
          SECTOR: doc.sector,
          COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,
          TELEFONO: doc.telefono,
          CELULAR: doc.celular,
          WHATSAPP: doc.whatsapp,
          NUM_REFERENCIA: doc.numbRef,
          PLAN: doc.planNombre,
          NUMERO_PLAN: doc.numFlota,
          LIDER: doc.nombreLider,
          FECHA_AGENDA: doc.created.toDate(),
          FECHA_INSTALACION: instalacion(doc.fechaHora),

          SECTOR: doc.sector,
          DIRECCION: doc.direccion,
          COORDENADAS: doc.coordenadas,
          CELULAR: doc.celular,
          TELEFONO: doc.telefono,

          PLAN: doc.planNombre,
          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',

          CABLE_INICIO: doc.cableInicio,
          CABLE_FINAL: doc.cableFinaliza,
          FIBRA_UTILIZADA: parseInt(doc.cableInicio) - parseInt(doc.cableFinaliza),
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,

          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,

          VENDEDOR_RESPONSABLE: doc.vendedor,
          NUM_FLOTA: doc.numFlota,
          LIDER_FLOTA: doc.nombreLider,


        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }

  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  return (
    <>

      <Box pt={1} pl={1}>
        <Typography >
          <strong>({data && data.length}) INSTALACIONES POR FISCALIZAR</strong>
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <Box display="flex" alignItems='center' py={2}>
          <SearchInput setSearch={setSearch} search={search} />
          <IconButton id="basic-button" onClick={() => setOpen(!open)}>
            <FilterListIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>


        </Box>

      </Box>

      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,

            }}

            onStateChange={(state) => {
              console.log(state.pagination.rowCount);
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
}; */

import {
  IconButton,
  Table,
  Button,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Collapse,
  Dialog
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";

import moment from 'moment'

import { db, firebase, auth } from '../../../firebase'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useSelector } from 'react-redux';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import SearchInput from '../../../components/utils/SearchInput';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridExportMenuItemProps,
  useGridApiContext,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  GridApi,
  GridToolbar,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { ViewPdfClient } from "../../../components/clientes/ViewPdfClient";
import { FiscalizarInstalacion } from "./IconButtonsFiscalizacion/FiscalizarInstalacion";

export const InstalacionesPorFiscalizara = () => {

  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()

  const [position, setPosition] = useState({
    lat: latitude,
    lng: longitude,
  })

  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth

  function Estado({ estado }) {

    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = 'Por agendar'
      color = 'warning'
    } else if (estado === 1) {
      name = 'Rechazada'
      color = 'info'
    } else if (estado === 2) {
      name = 'Realizada'
      color = 'success'
    }
    return (
      <Chip label={name} color={color} />
    )
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  var current = new Date();
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date(current.getTime() - 259200000))
  //const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  useEffect(() => {
    LoadData()
    geolocationPosition();
    geo();
  }, []);

  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("tecnicos")
      .where('estado', '==', 2)
      .where('estado_pagado', '==', 2)
    startDate.setHours(0, 0, 0, 0)
    endDate.setHours(23, 59, 0, 0)

    let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
    let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
    ref = ref.where('fechaHora', '>=', startfulldate)
    ref = ref.where('fechaHora', '<=', endfulldate)
    ref.onSnapshot((snapshot) => {
      const totalCount = snapshot.size;
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];

      if (userInfo.email === "luis.vinan@intercommerce.com.ec" ||
        userInfo.email === "fernando.malave@intercommerce.com.ec") {
        setData(data.filter((item) => item.numContrato.includes('GUAYAQUIL')));

      }
      else if (userInfo.email === "diego.macias@intercommerce.com.ec") {
        setData(data.filter((item) => item.numContrato.includes('SANTA-ELENA')));
      }
      else {
        setData(data);
      }


      setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
      setIsLastPage(totalCount < 1);
    });
  };

  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  function Estado() {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (0 === 0) {
      name = 'POR FISCALIZAR'
      color = 'warning'
    }
    return (
      <Chip label={name} size="small" variant="filled" color={color} />
    )
  }

  const geo = () => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      let crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    };
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  const geolocationPosition = () => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      let crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    }

    function error(err) {
      console.warn("ERROR(" + err.code + "): " + err.message);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  //

  const columns = [
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado', headerName: 'ESTADO', width: 150,
      renderCell: () => {
        return <Estado />
      }
    },
    {
      field: '1', headerName: 'MOTIVO', width: 160,
      renderCell: (params) => {
        const respuesta = params.row.observacion_adicional === true ? 'CAMBIO DOMICILIO' : 'INSTALACION'
        return `${respuesta}`
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 110 },
    { field: 'nombres', headerName: 'CLIENTE', width: 350 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 180 },
    {
      field: '2', headerName: 'TERCERA EDAD', width: 140,
      renderCell: (params) => {
        const respuesta = params.row.terceraEdad === 1 ? 'SI' : 'NO'
        return `${respuesta}`
      }
    },
    {
      field: '3', headerName: 'DISCAPACIDAD', width: 140,
      renderCell: (params) => {
        const respuesta = params.row.discapacitado === 1 ? 'SI' : 'NO'
        return `${respuesta}`
      }
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 130 },
    { field: 'celular', headerName: 'CELULAR', width: 130 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 120 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 120 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS DEL CLIENTE', width: 300,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'planNombre', headerName: 'PLAN', width: 280 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 120 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 300 },
    {
      field: 'fechaHora', headerName: 'FECHA  INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    /* { field: 'fechaInstalacion', headerName: 'FECHA DE INSTALACION', width: 260 }, */
    //{ field: 'cableInicio', headerName: 'CABLE INICIO', width: 130 },
    //{ field: 'cableFinaliza', headerName: 'CABLE FINALIZA', width: 140 },
    { field: 'metraje', headerName: 'METRAJE', width: 130 },
    { field: 'metraje_cable_extra', headerName: 'METRAJE_EXTRA', width: 140 },
    {
      field: 'direccion', headerName: 'DIRECCION', width: 500
    },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'caja', headerName: 'CAJA', width: 140 },
    /* { field: 'metraje_instalacion_inicio', headerName: 'COORDENADAS CAJA', width: 270 }, */
    {
      field: '4', headerName: 'COORDENADAS CAJA', width: 250,
      renderCell: (params) => {
        const respuesta = params.row.coordenadas_caja === undefined ? '' : params.row.coordenadas_caja._lat
        const respuesta1 = params.row.coordenadas_caja === undefined ? '' : params.row.coordenadas_caja._long
        return `${respuesta}, ${respuesta1}`
      }
    },
    { field: 'sector', headerName: 'SECTOR', width: 230 },
    {
      field: 'numFlota', headerName: 'NUMERO FLOTA', width: 160
    },
    {
      field: 'nombreLider', headerName: 'LIDER FLOTA', width: 280
    },
    {
      field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 280
    },
    {
      field: '5', headerName: 'FISCALIZAR', width: 150,
      renderCell: (params) => {
        return <FiscalizarInstalacion props={params.row} />
      }
    },
  ];

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    function instalacion(fechaHora) {
      if (fechaHora && fechaHora.seconds) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }
    }
    //

    //
    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.numContrato,
          MOTIVO: doc.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION',
          TIPO_CONTRATO: doc.contrato,
          CEDULA: doc.cedula,
          CLIENTE: doc.nombres,
          CORREO: doc.correo,

          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
          DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
          GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.discapacidad,
          DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.descuento,
          VALOR_DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.valor,
          DIRECCION: doc.direccion,
          SECTOR: doc.sector,
          COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,
          TELEFONO: doc.telefono,
          CELULAR: doc.celular,
          WHATSAPP: doc.whatsapp,
          NUM_REFERENCIA: doc.numbRef,
          PLAN: doc.planNombre,
          NUMERO_PLAN: doc.numFlota,
          LIDER: doc.nombreLider,
          FECHA_AGENDA: doc.created.toDate(),
          FECHA_INSTALACION: instalacion(doc.fechaHora),

          SECTOR: doc.sector,
          DIRECCION: doc.direccion,
          COORDENADAS: doc.coordenadas,
          CELULAR: doc.celular,
          TELEFONO: doc.telefono,

          PLAN: doc.planNombre,
          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',

          METRAJE: doc.metraje,
          METRAJE_EXTRA: doc.metraje_cable_extra,
          FIBRA_UTILIZADA: parseInt(doc.cableInicio) - parseInt(doc.cableFinaliza),
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,

          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,

          VENDEDOR_RESPONSABLE: doc.vendedor,
          NUM_FLOTA: doc.numFlota,
          LIDER_FLOTA: doc.nombreLider,


        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }

  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      {/* <CustomExportButton /> */}
    </GridToolbarContainer>
  );


  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>({data && data.length})</strong> INSTALACIONES REALIZADAS
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography>
        </Box>
      </Box>

      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
      </Box>

      <IconButton color="primary" onClick={handleExport}>
        <SimCardDownloadIcon />
      </IconButton>

      {/* <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <Box display="flex" alignItems='center' py={2}>
          <SearchInput setSearch={setSearch} search={search} />
          <IconButton id="basic-button" onClick={() => setOpen(!open)}>
            <FilterListIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>
        </Box>
      </Box> */}

      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>

          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density='compact'
            columns={columns}
            components={{
              // Footer: CustomFooterTotalComponent,
              Toolbar: CustomToolbar,
            }}

            onStateChange={(state) => {

              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>

      {/* <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>CONTRATO</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>MOTIVO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>CLIENTE</TableCell>
                <TableCell>FECHA NACIMIENTO</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>NUM. REF</TableCell>
                <TableCell>COORDENADAS DEL CLIENTE</TableCell>
                <TableCell>PLAN</TableCell>
                <TableCell>PLAN VALOR</TableCell>
                <TableCell>VENDEDOR</TableCell>
                <TableCell>FECHA DE INSTALACION</TableCell>
                <TableCell>CABLE INICIO</TableCell>
                <TableCell>CABLE FINALIZA</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>ZONA</TableCell>
                <TableCell>CAJA</TableCell>
                <TableCell>COORDENADAS CAJA</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>NUMERO FLOTA</TableCell>
                <TableCell>LIDER FLOTA</TableCell>
                <TableCell>AUXILIAR FLOTA</TableCell>
                <TableCell>FISCALIZAR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.numContrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Estado />
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.fechaNacimiento}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.terceraEdad === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.discapacitado === 1 ? 'SI' : 'NO'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.whatsapp}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numbRef}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.planNombre}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.planValor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {
                      row.created && row.created.seconds ?
                        moment(new Date(row.created.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
                        : <>
                          {row.created && typeof row.created === 'number' &&
                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                          {row.created && typeof row.created === 'object' &&
                            moment(row.created.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                        </>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cableInicio}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cableFinaliza}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.zona}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.caja}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coordenadas_caja === undefined ? '' : row.coordenadas_caja._lat},
                    {row.coordenadas_caja === undefined ? '' : row.coordenadas_caja._long}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.sector}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FiscalizarInstalacion props={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>|
          </Table>
        </TableContainer>
      </Paper> */}
    </>
  );
};