import React, { useState, useEffect } from 'react';
import {
    Grid as Grid2,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import moment from 'moment'
import {
    Button,
    TableCell as StyledTableCell,
    Grid,
    Box,
    Stack,
    Container,
} from "@mui/material";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { FirmaComprobanteIngreso } from './Firmas';
import { Formato2 } from './Headers';
import { SerialPDF } from './SerialPDF';


export const ComprobanteIngreso = (props) => {
    const container = React.useRef(null);
    const [items, setItems] = useState([]);
    const [totalCant, setTotalCant] = useState(0);
    const [id, setId] = useState(null)

    useEffect(() => {
        setId(props.encabezado.id)
        setItems(props.contenido)
        if (props.contenido != undefined) {
            const valor = props.contenido.reduce((acc, current) => acc + current.cantidad_solicitada, 0);
            setTotalCant(valor)
        }
    }, [props.contenido])


    const total = props => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                <strong>Total:</strong>
            </td>
        );
    };


    const unidadesTotales = props => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {(totalCant).toFixed(2)}
            </td>
        );

    };

    const headerColumn = (props) => {
        return (
            <a >
                <span ><strong>{props.title}</strong></span>
            </a>
        );
    };

    const plantilla = (props) => {
        const fecha = moment(new Date().toString()).format('DD/MM/YYYY');
        return (
            <div>
                {props.pageNum == 1 &&
                    <Formato2 />
                }
                <p style={{ fontSize: "11px", marginBottom: "10px", marginTop: "10px" }}>
                    Pag {props.pageNum} de {props.totalPages}
                </p>
            </div >


        )
    };

    const serialColumn = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <SerialPDF id={id} fullName={value} />
        );
    };

    const tabla = (
        <div>
            <br />
            <Grid2 data={items} >
                <Column field="codigo" title="Codigo" headerCell={headerColumn} footerCell={total} />
                <Column field="descripcion" title="Descripcion" headerCell={headerColumn} />
                <Column field="fullName" title="Serial" headerCell={headerColumn} cell={serialColumn} />
                {/*<Column field="unidad" title="Unidad" headerCell={headerColumn} cell={customCell} />*/}
                <Column field="cantidad_solicitada" title="Cantidad" footerCell={unidadesTotales} headerCell={headerColumn} format='{0:n2}' />
            </Grid2>
        </div>

    );


    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            repeatHeaders: 'true',
            pageTemplate: plantilla,
            paperSize: "A4",
            margin: { top: "1.2cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `Comprobante de Ingreso ${props.encabezado.id}`,
        });

    };



    return (
        <Container>
            <div className="example-config">
                <Button
                    onClick={exportPDFWithMethod}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </div>
            <div className="border rounded p-2">
                <PDFExport>
                    <div ref={container}>
                        <Container maxWidth='xl'>
                            <Grid container spacing={1} style={{ fontSize: '14px' }}>
                                <Grid item xs={12} align="center">
                                    <strong>COMPROBANTE DE INGRESO</strong>
                                </Grid>
                                <Grid item xs={6} align="left">
                                    <Box sx={{ border: 1, borderRadius: '20px', padding: '10px' }}>
                                        <a><strong>INTERCOMMERCE S.A.</strong></a><br />
                                        <a>AV. NARCISA DE JESUS 6421 Y PUENTE DE LA JOYA </a><br />
                                        <a>TELF: 045010056</a>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{ border: 1, borderRadius: '20px', padding: '10px' }} >
                                        <Grid container >
                                            <Grid item xs={6} align="left">
                                                <a ><strong>DOC. IMPRESO POR:</strong></a><br />
                                                <a ><strong>N° INGRESO:</strong></a><br />
                                                <a ><strong>MODIFICADO POR:</strong></a>
                                            </Grid>
                                            <Grid item xs={6} align="left">
                                                <a>{props.encabezado.user}</a><br />
                                                <a>{props.encabezado.numero_solicitud}</a><br />
                                                <a>{props.encabezado.user}</a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ border: 1, borderRadius: '20px', padding: '10px' }} >
                                        <Grid container >
                                            <Grid item xs={3} align="left">
                                                <a ><strong>CENTRO DISTRIBUCION:</strong></a><br />
                                                <a ><strong>TIPO INGRESO:</strong></a><br />
                                                <a ><strong>PROVEEDOR:</strong></a><br />
                                                <a ><strong>NRO:</strong></a><br />
                                                <a ><strong>CUADRILLA:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} align="left">
                                                <a>NINGUNO</a><br />
                                                <a>{props.encabezado.motivo}</a><br />
                                                <a>NINGUNO</a><br />
                                                <a>{props.encabezado.numero_solicitud}</a><br />
                                                <a>{props.encabezado.flota == '' ? "NINGUNO" : props.encabezado.flota}</a>
                                            </Grid>
                                            <Grid item xs={3} align="left">
                                                <br />
                                                <a ><strong>TIPO DEVOLUCION:</strong></a><br /><br />
                                                <a ><strong>FECHA FACTURA:</strong></a><br /><br />
                                            </Grid>
                                            <Grid item xs={3} align="left">
                                                <br />
                                                <a>NINGUNO</a><br /><br />
                                                <a>{props.encabezado.fecha_documento && typeof props.encabezado.fecha_documento === 'object' &&
                                                    moment(props.encabezado.fecha_documento.toDate().toString()).format('DD/MM/YYYY')}</a><br /><br />
                                            </Grid>
                                            <Grid item xs={3} align="left">
                                                <a ><strong>CONCEPTO:</strong></a>
                                            </Grid>
                                            <Grid item xs={9} align="left">
                                                <a>{props.encabezado.observacion_adicional}</a>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>

                        <Container maxWidth='xl'>{tabla}</Container>
                        <FirmaComprobanteIngreso />
                    </div>
                </PDFExport>
            </div>
        </Container>
    );
}
