import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CardTravelIcon from '@mui/icons-material/CardTravel';
export const Cobranza = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'cobranzas']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <CardTravelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cobranzas" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton component={RouterLink} to={`cobranzas/reingreso`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Reingreso" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`cobranzas/debito`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Clientes Debito" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`cobranzas/facturas`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Facturas" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`cobranzas/transacciones`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Transacciones" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`cobranzas/credito`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Promesas de Pago" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`cobranzas/facturas-masivas-cobranza`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga facturas" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}