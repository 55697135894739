import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";
import { listsSubCategorias } from "../../../../redux/actions/parametersActions";

export const UpdateSubCategoria = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [nombreSubCategoriaProducto, setNombreNombreSubCategoriaProducto] = useState(props.nombre_subcategoria_producto);

    const updateProducto = async () => {
        await db.collection('subcategorias_productos').doc(props.id).update({
            nombre_subcategoria_producto: nombreSubCategoriaProducto,
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado la subcategoria con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>EDICION DE SUBCATEGORIA: {props.descripcionProducto}</strong>
                </DialogTitle>
                <DialogContent>

                    <Box py={2}>
                        <FormControl fullWidth>

                            <Grid item xs={6}>
                                <Box py={1}>
                                    <TextField
                                        fullWidth
                                        label="NOMBRE"
                                        value={nombreSubCategoriaProducto}
                                        onChange={(e) => setNombreNombreSubCategoriaProducto(e.target.value)}
                                    />
                                </Box>

                            </Grid>

                        </FormControl>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"

                    >
                        {" "}
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};