import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
    cantonesReducers,
    modelosReducers,
    nivelReducers,
    nodoReducers,
    parametersReducers,
    parroquiaReducers,
    planesReducers,
    provinciaReducers,
    sectorReducers,
    serviciosReducers,
    splitterReducers,
    discapacidadReducers,
    changePlanesReducers,
    marcasReducers,
    zonasReducers,
    categoriaReducers,
    subCategoriaReducers,
    modeloProductosReducers,
    unidadMedidaProductosReducers,
    productosReducers,
    bodegasReducers,
    ubicacionesReducers,
    motivoIngresoProductosReducers,
    motivoEgresoProductosReducers,
    tipoCuentaReducers,
    cuenta1Reducers,
    cuenta2Reducers,
    cuenta3Reducers,
    cuenta4Reducers,
    cuenta5Reducers,
    categoriasFirebaseReducer,
    subCategoriasFirebaseReducer,
    PresentacionFirebaseReducer,
    MarcasFirebaseReducer,
    ModelosFirebaseReducer,
    UnidadMedidaFirebaseReducer,
    kardexReducers,
    TiposInfraccionesReducer,
    NominaReducer,
    CentroDeCostosReducer,
    MaterialesReducer,
    TiposActivosFijosReducer,
    PorcentajeDeDepreciacionReducer,
    EstadoProductoReducer,
    VidaUtilReducer,
    ClaseDeActivoReducer,
    MetodoDeDepreciacionReducer,
    TipoDeProductReducer,
    ClaseDeProductoReducer,
    FrecuenciaDeDepreciacionReducer,
    CondicionDeActivoReducer,
    LocalidadProductoReducer,
    DepartamentoReducer,
    TipoTransaccionReducer,
    SubcategoriaActivosReducer,
    ResidualReducer,
    CategoriaActivosReducer,
    UnidadMedidaReducer,
    UnidadMedidaActivosReducer,
    MarcaActivosReducer,
    ModeloActivosReducer,
    DepartamentosInventarioReducer,
    FormaPagoRubrosReducer,
    TicketsAsuntosReducer,
    TicketsDepartamentosReducer,
    TicketsOficinasReducer,
    TicketsTurnosReducer,
    DepartamentosReducer,
    RelationFamilyUsersReducer
} from './reducers/parametersReducers';
import { tipoNombreCuentasReducers } from './reducers/parametersReducers';
import { naturalezaCuentaReducers } from './reducers/parametersReducers';
import { itemsEstadoFinancieroReducers } from './reducers/parametersReducers';
import { detalleInstalacionReducers, detalleSolicitudReducers, updateSolicitudReducers, detalleInstalacionTicketsReducers } from './reducers/solicitudReducers'
import { listSolicitudesReducers, uploadFileReducers } from './reducers/ventaReducers';
import { authReducer, networkingAuthorizationReducer, userLoginReducer } from './reducers/account/userReducers';
import { cajasSectorReducers } from './reducers/operaciones/cajasReducers';
import { updateFactibilidadReducers, verifyFactibilidadReducers } from './reducers/operaciones/factibilidadReducers';
import { codeOTPSubmitReducer, listSolicitudAprobadaReducer, sendCodeReducer, solicitudCreateReducer } from './reducers/ventas/solicitudesReducers'
import { flotasReducers } from './reducers/flotas/flotasReducers';
import { flotasLidersReducers } from './reducers/flotas/flotasLidersReducers';
import { flotasAuxiliarReducers } from './reducers/flotas/flotasAuxiliarReducers';
import { cargoReducers, departamentoReducers } from './reducers/recursosHumanos/recursosHumanosReducers';
import { listRolesReducers } from './reducers/parametros/rolesReducers';
import { agendaCreateReducers } from './reducers/agenda/agendaReducers';
import { clienteServicioReducers, clientesReducers, configPlantillasReducers, detailsClientesReducers, detailsMikrotiksReducers } from './clientes/clientesReducers';
import { listClientesReducer } from './reducers/clientes/clientesReducers';
//import { authReducer, userLoginReducer, userSignupReducer } from '../redux/reducers/userReducers'
//Creamos nuestro gestionador de estados
const reducer = combineReducers({
    userAuth: authReducer,
    userLogin: userLoginReducer,
    //clientes
    clientesList: clientesReducers,
    clientDetails: detailsClientesReducers,
    clientServicio: clienteServicioReducers,
    mikrotiksDetails: detailsMikrotiksReducers,
    configPlantillasList: configPlantillasReducers,
    //
    networkingAuthorization: networkingAuthorizationReducer,
    parametersList: parametersReducers,
    solicitudDetalle: detalleSolicitudReducers,
    instalacionTicketsDetalle: detalleInstalacionTicketsReducers,
    instalacionDetalle: detalleInstalacionReducers,
    provinciaList: provinciaReducers,
    cantonesList: cantonesReducers,
    parroquiaList: parroquiaReducers,
    sectorList: sectorReducers,
    zonasList: zonasReducers,
    //
    agendaCreate: agendaCreateReducers,
    //
    rolesList: listRolesReducers,
    splitterList: splitterReducers,
    nivelList: nivelReducers,
    nodoList: nodoReducers,
    modeloList: modelosReducers,
    ////categorias
    categoriaList: categoriaReducers,
    subCategoriaList: subCategoriaReducers,
    modeloProductoList: modeloProductosReducers,
    unidadMedidaProductoList: unidadMedidaProductosReducers,
    productosList: productosReducers,
    bodegasList: bodegasReducers,
    ubicacionesList: ubicacionesReducers,
    motivoIngresoProductosList: motivoIngresoProductosReducers,
    motivoEgresoProductosList: motivoEgresoProductosReducers,
    //
    cargoList: cargoReducers,
    departamentoList: departamentoReducers,
    //Solicitudes

    solicitudList: listSolicitudesReducers,
    solicitudListAprobada: listSolicitudAprobadaReducer,
    codigoOTPSubmit: codeOTPSubmitReducer,
    sendCode: sendCodeReducer,
    //
    servicioList: serviciosReducers,
    discapacidadList: discapacidadReducers,
    //
    factibilidadCreate: verifyFactibilidadReducers,
    solicitudUpdate: updateSolicitudReducers,
    planesList: planesReducers,
    planesChange: changePlanesReducers,
    marcasList: marcasReducers,
    cajasSectorList: cajasSectorReducers,
    flotasList: flotasReducers,
    liderList: flotasLidersReducers,
    auxiliarList: flotasAuxiliarReducers,
    //
    factibilidadUpdate: updateFactibilidadReducers,
    //
    uploadFile: uploadFileReducers,
    //CUENTA MAYOR
    tipoNombreCuentasList: tipoNombreCuentasReducers,
    naturalezaCuentaList: naturalezaCuentaReducers,
    tiposCuentasList: tipoCuentaReducers,
    itemsEtsadoFinancierosList: itemsEstadoFinancieroReducers,
    //CUENTAS
    cuenta1List: cuenta1Reducers,
    cuenta2List: cuenta2Reducers,
    cuenta3List: cuenta3Reducers,
    cuenta4List: cuenta4Reducers,
    cuenta5List: cuenta5Reducers,
    //ListInventario
    categoriasFirebaseList: categoriasFirebaseReducer,
    subCategoriasFirebaseList: subCategoriasFirebaseReducer,
    PresentacionesFirebaseList: PresentacionFirebaseReducer,
    MarcasFirebaseList: MarcasFirebaseReducer,
    ModelosFirebaseList: ModelosFirebaseReducer,
    UnidadesMedidaList: UnidadMedidaFirebaseReducer,
    //KARDEX
    kardexList: kardexReducers,
    //TIPOS INFRACCIONES
    tiposInfraccionesList: TiposInfraccionesReducer,
    //Nomina
    nominalist: NominaReducer,
    //Centros de Costos
    centrosDeCostosList: CentroDeCostosReducer,
    //MATERIALES
    materialesList: MaterialesReducer,
    //ACTIVOS FIJOS PARAMETERS,
    tiposActivosFijosList: TiposActivosFijosReducer,
    porcentajeDeDepreciacionList: PorcentajeDeDepreciacionReducer,
    estadoProductoList: EstadoProductoReducer,
    vidaUtilList: VidaUtilReducer,
    claseDeActivoList: ClaseDeActivoReducer,
    metodoDeDepreciacionList: MetodoDeDepreciacionReducer,
    //GENERAL/TIPO DE PRODUCTO
    tipoDeProductList: TipoDeProductReducer,
    claseDeProductoList: ClaseDeProductoReducer,
    frecuenciaDeDepreciacionList: FrecuenciaDeDepreciacionReducer,
    condicionDeActivoList: CondicionDeActivoReducer,
    //LOCALIDAD/LOCALIDADPRODUCTO
    localidadProductoList: LocalidadProductoReducer,
    departamentoList: DepartamentoReducer,

   
    //DEPRECIACION

    tipoTransaccionList: TipoTransaccionReducer,
    subcategoriaActivosList: SubcategoriaActivosReducer,
    residualList: ResidualReducer,

    categoriaActivosList: CategoriaActivosReducer,

    unidadMedidaActivosList: UnidadMedidaActivosReducer,

    marcaActivosList: MarcaActivosReducer,
    modeloActivosList: ModeloActivosReducer,

    //INVENTARIO

    departamentosInventarioList: DepartamentosInventarioReducer,
    formaPagoRubrosList: FormaPagoRubrosReducer,
    departamentosList : DepartamentosReducer,

    //TICKETS

    ticketsDepartamentosList: TicketsDepartamentosReducer,
    ticketsAsuntosList: TicketsAsuntosReducer,
    ticketsOficinasList: TicketsOficinasReducer,
    ticketsTurnosList: TicketsTurnosReducer,


    listClientes: listClientesReducer,

    //RELATION FAMILTY USERS

    RelationFamilyUsersList: RelationFamilyUsersReducer

})
export const replaceItem = (collection, item) => {
    const index = collection.findIndex((entry) => entry.id === item.id);
    return [...collection.slice(0, index), item, ...collection.slice(index + 1)];
};
const middleware = [thunk]
const store = createStore(reducer,
    composeWithDevTools(applyMiddleware(...middleware)))
export default store