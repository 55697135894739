import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
} from "@mui/material";
import { useParams } from 'react-router-dom';
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from 'react-redux';

import { changePlanes, listsPlanes } from "../../../redux/actions/parametersActions";

import { detallesSolicitud } from '../../../redux/actions/ventasActions';

export const UploadInformationVentasRechazadas = ({ props }) => {

    const dispatch = useDispatch()

    const { planes, loading: loadingPlanes } = useSelector(state => state.planesList)
    const { planess, loading: loadingPlaness } = useSelector(state => state.planesChange)
    const [nombres, setNombre] = useState(false);
    const [cedula, setCedula] = useState(false);
    const [direccion, setDireccion] = useState(false);
    const [coordenadas, setCoordenadas] = useState(false);
    const [observaciones, setObservaciones] = useState({ campo: "", valido: true });

    const [celular, setCelular] = useState(false);
    const [movil, setMovil] = useState(false);
    const [numbRef, setNumbRef] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [plan, setPlan] = useState([]);

    const [planValor, setPlanValor] = useState();

    const [coordenadasLat, setCoordenadasLat] = useState(false);
    const [coordenadasLong, setCoordenadasLong] = useState(false);

    const [open, setOpen] = useState(false);
    const [estado, setEstado] = useState(props.estado);
    const [cajas, setCajas] = useState(false);


    useEffect(() => {
   
        dispatch(listsPlanes()) /* props.codigoPlan */
        dispatch(changePlanes())
        setNombre(props.nombres);
        setCedula(props.cedula);
        setDireccion(props.direccion);
        setCelular(props.celular);
        setMovil(props.movil);
        setNumbRef(props.numbRef);
        setWhatsapp(props.whatsapp);
        setObservaciones(props.observacion);
        setCajas(props.caja);
        setPlan(props.planNombre);
        setCoordenadas(props.coordenadas._lat, props.coordenadas._long)
        setCoordenadasLat(props.coordenadas._lat)
        setCoordenadasLong(props.coordenadas._long)
        /* setObservaciones(props.observaciones); */
        /* setCoordenadas(props.coordenadas._lat, props.coordenadas._long) */
    }, [dispatch]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handlePlan = (e) => {
        //id
        const p = planes.filter((item) => item.id === e);
        const plan = p[0]
        setPlan(e)
        setPlanValor(parseFloat(plan.costo))
        console.log(plan, 'a')
      }

    const handleChangePlan = (e) => {
        //console.log(e)
        setPlan(e)
        const p = planess.filter((item) => item.nombrePlanes === e);
        const plan = p[0]
        setPlanValor(plan.valor)
        //console.log(plan)
    }

    const siFactible = (id) => {
        //Validaciones
        db.collection("solicitudes")
            .doc(id)
            .update({
                estado: 2,
                observacion: observaciones
            })
            .then((docRef) => {
                console.log("licencia ", docRef);
                Swal.fire({
                    icon: "success",
                    title: "Has confirmado que hay factibilidad.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    };

    const handleSubmit = async (id) => {

        const agendaDB = await db.collection("agenda").where("numContrato", "==", props.id).get()
        const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log('a', data1)
        const data = data1[0]

        console.log(data.id)
        console.log('Hola 2')

        db.collection("pre_ventas").doc(id).update({
            estado: 2
        })

        db.collection("agenda").doc(data.id).update({
            estado: 2,
            direccion: direccion,
            celular: celular,
            movil: movil,
            numbRef: numbRef,
            whatsapp: whatsapp,
            coordenadas: new firebase.firestore.GeoPoint(
                coordenadasLat,
                coordenadasLong
            ),
            planNombre: plan,
            planValor: parseFloat(planValor)
        }).then(() => {
            setOpen(false)


            Swal.fire({ icon: "success", text: "Se ha reagendado la pre venta con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });

        /* db.collection("solicitudes")
            .doc(id)
            .update({
                nombres: nombres,
                observacion: observaciones,
                cedula: cedula,
                direccion: direccion,
                caja: cajas
            })
            .then((docRef) => {
                Swal.fire({
                    icon: "success",
                    title: "Se ha actualizado la informacion exitosamente.",
                });
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            }); */
    }



    const MostrarId = async (id) => {
        const agendaDB = await db.collection("agenda").where("numContrato", "==", props.id).get()
        const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        console.log('a', data1)
        const data = data1[0]

        console.log(id)
        console.log('Hola 2')
    };

    return (
        <>


            {/* <Tooltip title="FACTIBILIDAD">
            <Button onClick={() => siFactible(props.id)}>
              <CheckIcon />
            </Button>
          </Tooltip> */}



            <Tooltip title="Editar Informacion">
                <Button onClick={handleClickOpen}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">

                <DialogTitle>Numero de solicitud {props.numeroSolicitud} - vendedor: {props.cedula}</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Se creo{" "}
                        {moment(props.created.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                        )}
                    </DialogContentText>

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                disabled
                                fullWidth
                                label="Nombres"
                                value={nombres}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                disabled
                                fullWidth
                                label="Cedula"
                                value={cedula}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Direccion"
                                value={direccion}

                                onChange={(e) => setDireccion(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Decisión cambio de plan cliente' | props.rechazoVenta === 'Cambio por baja de plan (Diferente al contrato)' ?
                        <Box py={3}>
                        <FormControl fullWidth>
                <InputLabel>PLAN</InputLabel>
                <Select
                  value={plan}
                  label="PLAN"
                  onChange={(e) => handlePlan(e.target.value)}
                >
                  {planes && planes.map((row, key) => (
                    // row.id >= 354 && row.id <= 358 &&
                    <MenuItem key={key} value={row.id}>{row.plan}</MenuItem>
                    // <MenuItem key={key} value={row.nombrePlanes}>{row.nombrePlanes}</MenuItem>
                  ))}
                </Select>
              </FormControl>
                      </Box> : ''
                    }

                    {props.rechazoVenta === 'Coordenadas no concuerdan con direccion' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Coordenadas"
                                value={coordenadasLat}

                                onChange={(e) => setCoordenadasLat(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Coordenadas no concuerdan con direccion' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Coordenadas"
                                value={coordenadasLong}

                                onChange={(e) => setCoordenadasLong(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Celular"
                                value={celular}
                                onChange={(e) => setCelular(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Movil"
                                value={movil}

                                onChange={(e) => setMovil(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Numero Referencia"
                                value={numbRef}

                                onChange={(e) => setNumbRef(e.target.value)}
                            />
                        </Box> : ''
                    }

                    {props.rechazoVenta === 'Datos incorrectos' ?
                        <Box py={2}>
                            <TextField
                                fullWidth
                                label="Whatsapp"
                                value={whatsapp}

                                onChange={(e) => setWhatsapp(e.target.value)}
                            />
                        </Box> : ''
                    }

                </DialogContent>
                <DialogActions>

                    <Button onClick={() => setOpen(false)}>Cancelar</Button>
                    <Button onClick={() => handleSubmit(props.id)} variant="contained">
                        {" "}
                        Guardar Cambios
                    </Button>
                    {/* <Button onClick={() => MostrarId(props.id)} variant="contained">
                        {" "}
                        Id
                    </Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};