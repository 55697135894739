export const solicitudCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SOLICITUD_CREATE_REQUEST':
            return { loading: true }
        case 'SOLICITUD_CREATE_SUCCESS':
            return { loading: false, success: true, solicitud: action.payload }
        case 'SOLICITUD_CREATE_FAIL':
            return { loading: false, error: action.payload }
        case 'SOLICITUD_CREATE_RESET':
            return {}
        default:
            return state
    }
}
export const listSolicitudAprobadaReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SOLICITUD_APROBADA_LIST_REQUEST':
            return { loading: true }
        case 'SOLICITUD_APROBADA_LIST_SUCCESS':
            return { loading: false, success: true, solicitudesAprobadas: action.payload }
        case 'SOLICITUD_APROBADA_LIST_FAIL':
            return { loading: false, error: action.payload }
        case 'SOLICITUD_APROBADA_LIST_RESET':
            return {}
        default:
            return state
    }
}
export const sendCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SEND_CODE_REQUEST':
            return { loading: true }
        case 'SEND_CODE_SUCCESS':
            return { loading: false, success: true, sendCodeOTP: action.payload }
        case 'SEND_CODE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const codeOTPSubmitReducer = (state = {}, action) => {
    switch (action.type) {
        case 'CODIGO_OTP_SUBMIT_REQUEST':
            return { loading: true }
        case 'CODIGO_OTP_SUBMIT_SUCCESS':
            return { loading: false, success: true, codeOTPSubmit: action.payload }
        case 'CODIGO_OTP_SUBMIT_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}