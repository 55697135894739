import { Radio, Tabs, Typography, Box, Tab, RadioGroup, Button, FormLabel, Divider, Select, FormControl, FormControlLabel, CardContent } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import { TransferenciasAprobadas } from './TransferenciasAprobadas';
import { TransferenciasEnEspera } from './TransferenciasEnEspera';
import { TransferenciasRechazadas } from './TransferenciasRechazadas';
import { TransferirMercaderia } from './TransferirMercaderia';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const TransferenciaMercaderia = () => {
  const params = useParams()
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (params.id) {
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setValue(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Box>
        <Typography variant='h5' component='h1'> <strong>TRANSFERENCIA DE MERCADERIA</strong> </Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Transferir mercaderia" {...a11yProps(0)} />
        <Tab label="Transferencias en Espera" {...a11yProps(1)} />
        <Tab label="Transferencias Aprobadas" {...a11yProps(2)} />
        <Tab label="Transferencias Rechazadas" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <TransferirMercaderia />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TransferenciasEnEspera />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TransferenciasAprobadas />
      </TabPanel>
      <TabPanel value={value} index={3}>
      <TransferenciasRechazadas />
      </TabPanel>
    </>
  )
}