import React, { useState, useEffect } from "react";
import {
    TextField, FormControl, Select, MenuItem, Button, Box,
    DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, CircularProgress,
    Divider
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";
import { createAgenda } from "../../../redux/actions/agenda/agendaActions";
import DeleteIcon from '@mui/icons-material/Delete';

export const DeleteInformation = ({ props }) => {

    const [centroCosto, setCentroCosto] = useState(props.centro_de_costo)

    const [estado, setEstado] = useState(props.estado)

    const [openConfirm, setOpenConfirm] = useState(false);

    const onData = () => {
        setOpenConfirm(true)
    }

    const onHandleConfirm = () => {
        db.collection("centros_de_costos_proyectos").doc(props.id).delete().then(() => {
            setOpenConfirm(false)
            Swal.fire({ icon: "success", text: "Se ha eliminado el centro de costo con exito", });
            setTimeout(
                function () {
                    window.location.reload();
                }, 600);
        })
            .catch((error) => { console.error("Error adding document: ", error); });
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip>

            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xs">

                <DialogTitle><strong>¿Desea eliminar el proyecto: {props.centro_de_costo}?</strong></DialogTitle>

                <DialogActions>
                    <Button onClick={() => setOpenConfirm(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => onHandleConfirm()} variant="contained" color="error">
                        Eliminar
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
};