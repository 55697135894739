import EditIcon from "@mui/icons-material/Edit";

import {
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { db } from "../../../firebase";

import { useEffect, useState } from "react";

import Swal from 'sweetalert2'

import DeleteIcon from '@mui/icons-material/Delete';

export const DeleteItemsKitInventario = ({ props }) => {

    const [openDialogDeleteKit, setOpenDialogDeleteKit] = useState(false)

    const deleteKit = () => {
        db.collection('kits_inventario').doc(props.uid_kit).delete()

        setOpenDialogDeleteKit(false)

        Swal.fire({ icon: "success", text: 'Se ha eliminado el kit con exito', });

    }

    return (
        <>
            <Tooltip title="ELIMINAR">
                <Button onClick={() => setOpenDialogDeleteKit(true)}>
                    <DeleteIcon color="error"/>
                </Button>
            </Tooltip>

            {/* DIALOG CONFIRMAR*/}
            <Dialog open={openDialogDeleteKit} onClose={() => setOpenDialogDeleteKit(false)} maxWidth="xs" fullWidth>
                <DialogTitle>¿ESTA SEGURO SE ELIMINAR EL <strong>{props.uid_kit}</strong>?</DialogTitle>

                <DialogContent>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="primary" size="small" onClick={() => setOpenDialogDeleteKit(false)}>REGRESAR</Button>
                    <Button variant="contained" color="error" size="small" onClick={() => deleteKit()}>ELIMINAR</Button>
                </DialogActions>

            </Dialog>


        </>
    )
}
