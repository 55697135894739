import {
    TextField, Typography, Box, Grid, Button, Card, Autocomplete
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputValidation from "../../../components/utils/InputValidation";
import { useDispatch, useSelector } from 'react-redux';
import { firebase, db } from '../../../firebase';
import Swal from "sweetalert2";
import { networkingAuth } from "../../../redux/actions/account/authActions";
export const NewUsers = () => {
    const dispatch = useDispatch()
    const networkingAuthorization = useSelector((state) => state.networkingAuthorization)
    //
    const [username, setUsername] = useState({ campo: "", valido: true });
    const [password, setPassword] = useState({ campo: "", valido: true });
    const [attribute, setAttribute] = useState('');
    const [op, setOp] = useState('')
    const [ipAdress, setIpAdress] = useState('');
    const [ipAdress6, setIpAdress6] = useState('')
    //
    const [username2, setUsername2] = useState({ campo: "", valido: true });
    const [frame, setFrame] = useState('');
    const [attribute2, setAttribute2] = useState('');
    const [op2, setOp2] = useState('')
    const [select, setSelec] = useState('');
    //
    const [pools, setPools] = useState('');
    const [ipPool, setIpPool] = useState('');
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    //
    const [ipv4, setIpv4] = useState('');
    const [ipv6, setIpv6] = useState('');
    const [ipvTunel, setIpvTunel] = useState('');
    const [used_Ipv4, setUsedIpv4] = useState([]);
    const [used_Ipv6, setUsedIpv6] = useState([]);
    //
    useEffect(() => {
        loadPools()
        dispatch(networkingAuth())
        if (networkingAuthorization?.networking_auth?.access) {
            onChangeIPV4()
            onChangeIPV6()
        }

    }, [dispatch])

    //
    const load_pool = async () => {
        try {
            let licenciaActual = null
            let poolActual = 0
            //Consultamos a la query
            db.collection("nms/pools/ipv4")
                .get().then((qs) => {
                    //Obtenemos la tabla de los pools de ipv4
                    const list_pools2 = qs.docs.map((doc, i) => ({ index: i, id: doc.id, ...doc.data() }))
                    obtenerSiguientePool()
                    async function obtenerSiguientePool() {
                        // Obtener el pool actual
                        const pool = list_pools2[poolActual]
                        // Verificar si hay ipv4 disponibles en el pool actual
                        if (pool.ipv_count === 0) {
                            // Si el pool actual está vacío, pasar el siguiente pool
                            if (poolActual < list_pools2.length - 1) {
                                poolActual++;
                                obtenerSiguientePool();
                            } else {
                                // Si todos los pools están vacíos, establecer la licencia actual en null
                                console.log('No tiene pools disponibles')
                                licenciaActual = null;
                            }
                        } else {
                            // Si hay list ipv4 disponibles en el lote actual, obtener la siguiente licencia
                            db.collection(`nms/pools/ipv4/${pool.id}/not_assign`).orderBy("created", "asc")
                                .get().then((qs2) => {
                                    const list_ipv4 = qs2.docs.map((doc, i) => ({ index: i, id: doc.id, ...doc.data() }))
                                    const ipv4_assign = list_ipv4[0]
                                    //
                                    db.collection(`nms/pools/ipv4/${pool.id}/assign`).doc(ipv4_assign.ipv4).set({
                                        ipv4: ipv4_assign.ipv4
                                    })
                                    //Actualizamos el contador del pool escogido
                                    db.collection(`nms/pools/ipv4/`).doc(pool.id).update({
                                        ipv_count: firebase.firestore.FieldValue.increment(-1),
                                    }).then(() => {
                                        console.log(`Se actualizo el contador del pool: ${ipv4_assign.pool}`)
                                    })
                                    //Eliminamos la ipv4 de la tabla de 
                                    db.collection(`nms/pools/ipv4/${pool.id}/not_assign`).doc(ipv4_assign.ipv4).delete().then(() => {
                                        console.log(`Se elimino la ipv4: ${ipv4_assign.ipv4} del pool: ${ipv4_assign.pool}`)
                                    })
                                    console.log(`Se asigno la ipv4: ${ipv4_assign.ipv4} del pool: ${ipv4_assign.pool}`)
                                    var nom = 'DESARROLLO'
                                    var username = `${nom[0]}${nom[1]}${nom[3]}`
                                    //APIS
                                    var formdata = new FormData()
                                    formdata.append("username", username)
                                    formdata.append("attribute", "Framed-Address")
                                    formdata.append("op", ">=")
                                    formdata.append("value", ipv4_assign.ipv4)
                                    var requestOptions = {
                                        method: 'POST',
                                        body: formdata
                                    };
                                    fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadReply/", requestOptions)
                                        .then(response => response.text())
                                        .then(result => {
                                            console.log(result)
                                        })
                                        .catch(e => { console.log(`radius rad reply ${e}`) })
                                    //
                                    var length = 8,
                                        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                                        password = "";
                                    for (var i = 0, n = charset.length; i < length; ++i) {
                                        password += charset.charAt(Math.floor(Math.random() * n));
                                    }
                                    //
                                    var formdata = new FormData()
                                    formdata.append("username", 'DESAROLLO')
                                    formdata.append("attribute", "Cleartext-Password")
                                    formdata.append("op", ">=")
                                    formdata.append("value", password)
                                    var requestOptions = {
                                        method: 'POST',
                                        body: formdata
                                    }
                                    fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadcheck/?username&attribute&op&value", requestOptions)
                                        .then(response => response.text())
                                        .then(result => {

                                        }).catch(e => {
                                            console.log(`radius rad check ${e}`)
                                        })
                                })
                        }
                    }
                })
        } catch (e) {
            //notificamos si hay algun error con los datos ingresados
            console.log('e ', e)
        }
        //
        // const agendaDB = await db.collection("flotas").where("numeroFlota", "==", props.numFlota).get()
        // const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        // const data = data1[0]
    }
    //
    const submitHandler = () => {
        var data = {
            nombre: username,
            password: '',
            linea: '',
            attributes: '',
            frame: frame,
        }
    }
    const generatePassword = () => {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        setPassword({
            campo: retVal,
            valido: true
        })
    }
    //
    const onChangeIPV4 = (ipv4) => {
        setLoading(true)
        setAttribute2(ipv4)
        //
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("Authorization", `Bearer ${networkingAuthorization.networking_auth.access}`)
        var raw = JSON.stringify({
            "ipPool": "192.168.90.0/24"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("http://192.168.80.242:3000/listIpPoolIpv4", requestOptions)
            .then(response => response.text())
            .then(result => {
                var re = JSON.parse(result)
                const pool_ipv4 = [
                    ...re.map((doc) => {
                        return {
                            id: doc,
                        };
                    }),
                ]
                //Consultamos las ipv6 asigandas
                var docRef = db.collection("nms").doc("ipv_asignadas").collection("ipv4")
                    .onSnapshot((querySnapshot) => {
                        const used_Ipv4 = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                        let resultado = pool_ipv4.filter(function (item) {
                            return used_Ipv4.find(x => x.id === item.id) === undefined;
                        });
                        resultado = resultado.slice(0, 10 + 0)
                        setIpAdress(resultado)
                    }).catch((error) => {
                    });
            })
            .catch(error => { setLoading(false) })
    }
    const onChangeIPV6 = (ipv4) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${networkingAuthorization.networking_auth.access}`)
        var raw = JSON.stringify({
            "ipPool": "2803:9A10::/32"
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("http://192.168.80.242:3000/listIpPoolIpv6", requestOptions)
            .then(response => response.text())
            .then(result => {
                var re = JSON.parse(result)
                const pool_ipv6 = [
                    ...re.map((doc) => {
                        return {
                            id: doc,
                        };
                    }),
                ]
                //Consultamos las ipv6 asigandas
                var docRef = db.collection("nms").doc("ipv_asignadas").collection("ipv6")
                    .onSnapshot((querySnapshot) => {
                        const used_Ipv6 = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                        let resultado = pool_ipv6.filter(function (item) {
                            return used_Ipv6.find(x => x.id === item.id) === undefined;
                        });
                        resultado = resultado.slice(0, 10 + 0)
                        setIpAdress6(resultado)
                    }).catch((error) => {
                    });
            })
            .catch(error => { setLoading(false) })

    }
    //
    const loadPools = () => {
        let ref = db.collection("pools").orderBy("created", "desc");
        ref.onSnapshot((snapshot) => {
            const totalCount = snapshot.size;
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setPools(data);
        });
        //
        var docRef = db.collection("nms").doc("ipv_asignadas").collection("ipv4");
        docRef.get().then((querySnapshot) => {
            var data = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            //console.log(data)
            setUsedIpv4(data)
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });

    }
    //
    const submitRadcheck = () => {
        console.log('Hola')
        setLoading(true)
        var formdata = new FormData();
        formdata.append("username", username.campo);
        formdata.append("attribute", "Cleartext-Password");
        formdata.append("op", ">=");
        formdata.append("value", username.password);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadcheck/?username&attribute&op&value", requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false)
                setUsername({ campo: "", valido: true })
                setPassword({ campo: "", valido: true })
                setAttribute('')
                setOp('')
                console.log(result)
                Swal.fire({ icon: "success", text: "Se creo el usuario" })
            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)
            })
    }
    //
    const handleSubmit = () => {
        setLoading2(true)
        //
        var formdata = new FormData();
        formdata.append("username", username.campo);
        formdata.append("attribute", "FRAMED-ADDRESS");
        formdata.append("op", op2);
        formdata.append("value", frame);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadReply/", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                setLoading2(false)
                setUsername2({ campo: "", valido: true })
                setFrame('')
                setAttribute2('')
                setOp2('')
                Swal.fire({ icon: "success", text: "Se creo el Radreply" })
            })
            .catch(error => console.log('error', error));
        //
        var formdata = new FormData();
        formdata.append("username", username.campo);
        formdata.append("attribute", "Cleartext-Password");
        formdata.append("op", ">=");
        formdata.append("value", password.campo);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }
        fetch("https://serverbd.intercommerce.com.ec/ApiDaloRadiusRadcheck/?username&attribute&op&value", requestOptions)
            .then(response => response.text())
            .then(result => {
                setLoading(false)
                setUsername({ campo: "", valido: true })
                setPassword({ campo: "", valido: true })
                setAttribute('')
                setOp('')
                Swal.fire({ icon: "success", text: "Se creo el usuario" })
            }).catch(error => {
                setLoading(false)
            })
        //Se guardar la ip que se asigno ipv4
        db.collection("nms/ipv_asignadas/ipv4").doc(ipv4).set({
            username: username.campo,
            ipv4: ipv4,
            ipv6: ipv6,
            ipvTunel: ipvTunel.campo,
            created: firebase.firestore.FieldValue.serverTimestamp()
        }).then((docRef) => {
            setIpvTunel({ campo: "", valido: true })
            setIpv4('')
            setIpv6('')
        }).catch((error) => {
        })
        db.collection("nms/ipv_asignadas/ipv6").doc(ipv6).set({
            username: username.campo,
            ipv4: ipv4,
            ipv6: ipv6,
            ipvTunel: ipvTunel.campo,
            created: firebase.firestore.FieldValue.serverTimestamp()
        }).then((docRef) => {
        }).catch((error) => {
        })
        db.collection("nms/clients/clients").doc(username.campo).set({
            username: username.campo,
            password: password.campo,
            ipv4_no_navigation: '4.0.0.0',
            ipv6_no_navigation: '6.0.0.0',
            assigned_ipv6: ipv6,
            assigned_ipv4: ipv4,
            ipv6: ipv6,
            ipv4: ipv4,
            ipvTunel: ipvTunel.campo,
            estado_pago: 0,
            created: firebase.firestore.FieldValue.serverTimestamp()
        }).then((docRef) => {
        }).catch((error) => {
        })
        //
        console.log({
            "username": username.campo,
            "attribute2": attribute2,
            "attribute": "Cleartext-Password",
            "op": ">=",
            "op2": ">=",
            "value": frame,
            "value": username.password
        })
    }
    return (
        <>
            <button
                onClick={() => {
                    onChangeIPV4()
                    onChangeIPV6()
                }}
            >ejecutar ipvs</button>
            <button
                onClick={() => {
                    load_pool()
                }}
            >ejecutar funcion</button>
            <Card p={1} variant='outlined'>
                <Box p={2}>
                    <Grid container spacing={5}>
                        <Grid item md={6} xs={12}>
                            <Box pb={2}>
                                <Typography>PPPUSER</Typography>
                            </Box>
                            <InputValidation
                                estado={username}
                                cambiarEstado={setUsername}
                                name="USERNAME"
                            />
                            <Box pb={2}>
                                <Typography>PPPASSWORD</Typography>
                            </Box>
                            <Box display='flex'>
                                <InputValidation
                                    estado={password}
                                    cambiarEstado={setPassword}
                                    name="password"
                                />
                                <Box pl={1}>
                                    <Button onClick={() => generatePassword()}>Random pass</Button>
                                </Box>
                            </Box>
                            {/* <Box display='flex'>
                                <FormControl fullWidth>
                                    <InputLabel>PASSWORD TYPE</InputLabel>
                                    <Select
                                        value={attribute}
                                        label="LINEA DE CONTRATO"
                                        onChange={(e) => { setAttribute(e.target.value) }}
                                    >
                                        <MenuItem value="Cleartext-Password">Cleartext-Password</MenuItem>
                                        <MenuItem value="User-Password">User-Password</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box pl={1} >
                                    <FormControl >
                                        <Select
                                            value={op}
                                            onChange={(e) => { setOp(e.target.value) }}
                                        >
                                            <MenuItem value="">----</MenuItem>
                                            <MenuItem value=":=">:=</MenuItem>
                                            <MenuItem value="=">=</MenuItem>
                                            <MenuItem value="==">==</MenuItem>
                                            <MenuItem value="+=">+=</MenuItem>
                                            <MenuItem value="!=">!=</MenuItem>
                                            <MenuItem value=">">{">"}</MenuItem>
                                            <MenuItem value=">=">{">="}</MenuItem>
                                            <MenuItem value="<">{"<"}</MenuItem>
                                            <MenuItem value="<=">{"<="}</MenuItem>
                                            <MenuItem value="=~">=~</MenuItem>
                                            <MenuItem value="!~">!~</MenuItem>
                                            <MenuItem value="=*">=*</MenuItem>
                                            <MenuItem value="!*">!*</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box> */}
                            <Box pb={2}>
                                <Typography>IPV4</Typography>
                            </Box>
                            <Box display='flex'>
                                <div style={{ width: '300px' }}>
                                    {ipAdress &&
                                        <Autocomplete
                                            options={ipAdress}
                                            disabled={!ipAdress}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(newInputValue, 'newInput')
                                                setIpv4(newInputValue)
                                                setFrame(newInputValue)
                                            }}
                                            getOptionLabel={(option) => option.id}
                                            renderInput={(params) =>
                                                <TextField
                                                    name="career"
                                                    {...params}
                                                    label="FRAMED-IP-ADDRESS"
                                                />}
                                        />}
                                </div>
                            </Box>
                            <Box py={2}>
                                <Typography>IPV6</Typography>
                            </Box>
                            <Box display='flex'>
                                <div style={{ width: '300px' }}>
                                    {ipAdress6 &&
                                        <Autocomplete
                                            options={ipAdress6}
                                            disabled={!ipAdress6}
                                            onInputChange={(e, newInputValue) => {
                                                console.log(newInputValue, 'newInput')
                                                setIpv6(newInputValue)
                                                setFrame(newInputValue)
                                            }}
                                            getOptionLabel={(option) => option.id}
                                            renderInput={(params) =>
                                                <TextField
                                                    name="career"
                                                    {...params}
                                                    label="FRAMED-IP-ADDRESS"
                                                />}
                                        />}
                                </div>
                            </Box>
                            <Box py={2}>
                                <Typography>IPV6 TUNEL</Typography>
                            </Box>
                            <Box display='flex'>
                                <InputValidation
                                    estado={ipvTunel}
                                    cambiarEstado={setIpvTunel}
                                    label="IPV TUNEL"
                                    name="ipvtunel"
                                />
                            </Box>
                            <Box pb={5} display="flex" flexDirection="row-reverse" pt={4}>
                                <Button
                                    disabled={loading2}
                                    color='secondary'
                                    variant='contained'
                                    onClick={() => handleSubmit()}
                                >
                                    ENVIAR INFORMACION
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {/* 
                            <Box pb={1}>
                                <FormControl fullWidth>
                                    <Select
                                        value={attribute2}
                                        onChange={(e) => setAttribute2(e.target.value)}
                                    >
                                        <MenuItem value="FRAMED-ADDRESS">FRAMED-ADDRESS</MenuItem>
                                        <MenuItem value="FRAMED-IP-ADDRESS">FRAMED-IP-ADDRESS</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box py={2}>
                                <Typography>SELECCIONAR POOL</Typography>
                            </Box>
                            <Box pb={1}>
                                <FormControl fullWidth>
                                    <Select
                                        value={attribute2}
                                        onChange={(e) => onChangeIPV4(e.target.value)}
                                    >
                                        {pools && pools.map(item => (
                                            <MenuItem key={item.id} value={item.red} >
                                                {item.nombre}-{item.red}-{item.tipo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box> */}

                        </Grid>
                    </Grid>
                </Box>
            </Card>
            {/* <button onClick={() => cambiarIP()}>Cambiar</button> */}
        </>
    );
};