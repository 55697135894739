import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NewModelproduct = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [modeloProducto, setModeloProducto] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async (ModeloProducto) => {
        await db.collection('modelos_productos').doc().set({
            nombre_modelo_producto: ModeloProducto,
            fecha_creacion_modelo_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nuevo modelo creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={modeloProducto}
                cambiarEstado={setModeloProducto}
                label="MODELOPRODUCTO"
                name="modeloProducto"
            />

            <br />
            <Button
                onClick={() => createProduct(modeloProducto.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !modeloProducto.campo}
            >
                {" "}
                CREAR NUEVO MODELO DE PRODUCTO{" "}
            </Button>
        </>
    );
};
