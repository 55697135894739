import {
    Box, Chip, Grid, Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../../../../firebase.js";
import { DeleteRubro } from "./Buttons/DeleteRubro.jsx";
import { AgregarPago } from "./Buttons/AgregarPago.js";
import { EditPago } from "./Buttons/EditPago.jsx";
import { ReportePagado } from "./ReportePagado.jsx";
import { ReporteNoPagado } from "./ReporteNoPagado.jsx";
import { ReporteAnulado } from "./ReporteAnulado.jsx";
import { AnularRubro } from "./Buttons/AnularRubro.jsx";
import { EditRubroNoPagado } from "./Buttons/EditRubroNoPagado.js";
import { RubroDeServicios } from "./RubroDeServicios.js";
import { NuevoRubroLibre } from "./NuevoRubroLibre.js";
import {
    listsFormaPagoRubros
} from "../../../../redux/actions/parametersActions";
export const FacturacionTab = () => {
    const params = useParams()
    const clientDetails = useSelector((state) => state.clientDetails)
    const { client_details } = clientDetails
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [idCliente, setIdCliente] = useState("");
    const [data, setData] = useState();
    //
    var current = new Date();
    const dispatch = useDispatch()
    useEffect(() => {
        LoadData()
        dispatch(listsFormaPagoRubros())
    }, [])
    const LoadData = () => {
        let ref = db.collection('rubros').where('idcliente','==', client_details.idcliente).orderBy('created', 'desc')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ]
            console.log(data, ' data')
            setData(data)
            setIdCliente(params.idcliente)
        })
    }

    function Estado({ estado }) {
        let name
        let color
        if (estado === 0) {
            name = "NO PAGADO"
            color = "warning"
        } else if (estado === 1) {
            name = "PAGADO"
            color = "success"
        } else if (estado === 2) {
            name = "VENCIDO"
            color = "error"
        } else if (estado === 3) {
            name = "ANULADO"
            color = "info"
        }
        return <Chip label={name} color={color}  size="small" />
    }

    function Tipo({ tipo }) {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (tipo === 'LIBRE') {
            name = "LIBRE";
            color = "success";
        } else if (tipo === 'SERVICIO') {
            name = "SERVICIOS";
            color = "warning";
        }
        return <Chip label={name} color={color} variant='outlined' size="small" />;
    }
    const columns = [
        { field: 'id_rubro', headerName: 'ID', width: 50 },
        {
            field: 'fecha_emision', headerName: 'EMITIDO', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'fecha_vencimiento', headerName: 'VENCIMIENTO', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: 'estado1', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Estado
                    estado={
                        params.row.estado
                    }
                />
            }
        },
        { field: 'total', headerName: 'TOTAL', width: 100 },
        { field: 'impuesto', headerName: 'IMPUESTO', width: 100 },
        {
            field: 'tipo', headerName: 'TIPO', width: 125,
            renderCell: (params) => {
                return <Tipo
                    tipo={
                        params.row.tipo
                    }
                />
            }
        },
        { field: 'total_pagado', headerName: 'PAGADO', width: 100 },
        {
            field: 'fecha_pago', headerName: 'FECHA PAGO', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'forma_pago', headerName: 'FORMA PAGO', width: 125 },
        {
            field: 'editar', headerName: '', width: 50,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.estado === 2 ? '<EditPago props={params.row} />' : ''
                        }
                        {
                            params.row.estado === 0 ? '<EditRubroNoPagado props={params.row} />' : ''
                        }
                        {
                            params.row.estado === 3 ? '' : ''
                        }
                    </>
                )
            }
        },
        {
            field: '12', headerName: 'AGREGAR PAGO', width: 140,
            renderCell: (params) => {
                return params.row.estado === 0 ? <AgregarPago props={params.row} /> : ''
            }
        },
        // {
        //     field: '2', headerName: 'EDITAR RUBRO', width: 130,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 {
        //                     params.row.estado === 2 ? <EditPago props={params.row} /> : ''
        //                 }
        //                 {
        //                     params.row.estado === 0 ? <EditRubroNoPagado props={params.row} /> : ''
        //                 }
        //                 {
        //                     params.row.estado === 3 ? '' : ''
        //                 }
        //             </>
        //         )
        //     }
        // },
        // {
        //     field: '3', headerName: 'ELIMINAR', width: 100,
        //     renderCell: (params) => {
        //         return params.row.estado === 3 ? '' : <DeleteRubro props={params.row} />
        //     }
        // },
        // {
        //     field: '4', headerName: 'ANULAR', width: 90,
        //     renderCell: (params) => {
        //         return params.row.estado === 0 ? <AnularRubro props={params.row} /> : ''
        //     }
        // },
        // {
        //     field: '5', headerName: 'VER PDF', width: 90,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 {params.row.estado === 2 &&
        //                     <ReportePagado contenido={params.row} idCliente={idCliente} />}
        //                 {params.row.estado === 3 &&
        //                     <ReporteAnulado contenido={params.row} idCliente={idCliente} />}
        //                 {params.row.estado === 0 &&
        //                     <ReporteNoPagado contenido={params.row} idCliente={idCliente} />}
        //             </>
        //         )
        //     }
        // },
    ]

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <>
            <Box pb={5} sx={{ height: 600, width: '100%' }}>
                <Stack
                    my={1}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                >
                    <NuevoRubroLibre />
                    <RubroDeServicios />
                </Stack>
                <DataGrid
                    density='compact'
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    columns={columns}

                    hideFooter
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </Box>
        </>
    );
};