import {
    Button,
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    Autocomplete,
    TextField,
    DialogActions,
    FormControl
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { db, firebase, auth } from "../../../firebase";
import Swal from "sweetalert2";
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';

export const CargaSeries = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        /* LoadData() */
        getProductos()
        LoadData()
    }, [])

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [message, setMessage] = useState();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [openCaja, setOpenCaja] = React.useState(false);

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [data, setData] = useState();

    const [data2, setData2] = useState();

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [totalSeries, setTotalSeries] = useState(0);

    const LoadData = () => {

        var docRef = db.collection("series")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setTotalSeries(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    };

    const [item, setItem] = useState('')

    const [openDialogProductos, setOpenDialogProductos] = useState(false);

    const [productos, setProductos] = useState('')

    const [codigoProducto, setCodigoProducto] = useState('')

    const [bodegaProducto, setBodegaProducto] = useState('')

    const [producto, setProducto] = useState('')

    const [noombre, setNombre] = useState('')

    const getProductos = () => {
        var docRef = db.collection("reporte_stock")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const addProducto = (row) => {
        setProducto(row.codigo_producto)
        setOpenDialogProductos(false)
        setCodigoProducto(row.codigo_producto)
        setBodegaProducto(row.bodega)
    }

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData)

                jsonData.forEach(async (obj) => {

                    const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", codigoProducto).where('bodega', '==', bodegaProducto).get()
                    const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(obj.SERIE.toString())
                        .set({
                            serie:
                                obj.SERIE === "" ||
                                    obj.SERIE === null ||
                                    obj.SERIE === undefined
                                    ? ""
                                    : obj.SERIE,
                            created:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            utilizado_por_codigo: codigoProducto,
                            bodega: bodegaProducto,
                            estado: 0
                        })

                    /* db.collection("series")
                        .doc(obj.SERIE)
                        .set({
                            serie:
                                obj.SERIE === "" ||
                                    obj.SERIE === null ||
                                    obj.SERIE === undefined
                                    ? ""
                                    : obj.SERIE,
                            fecha_carga_serie:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            usuario_que_cargo_serie: userInfo.displayName,
                            estado: 0,
                            utilizado_por_codigo: codigoProducto,
                        }); */
                });

                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    return (
        <>
            <Box pt={1}>
                <Card>
                    <CardContent>
                        <Box py={2}>
                            <TextField
                                size="small"
                                fullWidth
                                label="CODIGO PRODUCTO"
                                value={producto}
                            />

                            {/* <TextField
                                size="small"
                                fullWidth
                                label="NOMBRE PRODUCTO"
                                value={NOMBRE}
                            /> */}
                        </Box>

                        <Button variant="outlined" color="secondary" onClick={() => setOpenDialogProductos(true)}>
                            Seleccionar producto de series a egresar
                        </Button>

                        {/* <strong>SERIES {totalSeries && totalSeries.length}</strong> */}

                        <Box py={2}>
                            <p style={{ fontSize: "12px", marginBottom: "8px" }}><strong></strong></p>
                            <Button disabled={producto === ''} style={{ marginLeft: "10px", marginBottom: "10px" }} onClick={() => setOpenCaja(true)} size='small' /* onClick={() => onDataCuentaMenor()} */ variant="contained" startIcon={<UploadIcon />}>
                                SUBIR EXCEL
                            </Button>
                        </Box>

                        {/* {
                            data === '' ? '' : <strong>SERIES SUBIDAS: {data && data.length}</strong>
                        } */}

                        <Box pt={1}>
                            <Paper elevation={3} />
                            <TableContainer sx={{ maxHeight: 640 }}>
                                <Table size="small" stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SERIE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data.map((row) => (
                                            <TableRow>

                                                <TableCell component="th" scope="row">
                                                    {row.serie}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Paper />
                        </Box>


                    </CardContent>
                </Card>
            </Box>

            {/* <Box pt={2}>
                <Button disabled={!data} color="success" onClick={() => subirCargaInicial()} size='small' variant="contained" >
                    SUBIR CARGA INICIAL
                </Button>
            </Box> */}

            {/* SUBIR EXCEL */}
            <Dialog
                open={openCaja}
                onClose={() => setOpenCaja(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir Excel</DialogTitle>
                <DialogContent>
                    <Box pb={2}>
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>
                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={5}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                        to
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                </DialogContent>
            </Dialog>

            {/* DIALOG DE CODIGO Y PRODUCTOS PARA SUBIR SERIES*/}
            <Dialog open={openDialogProductos} fullWidth onClose={() => setOpenDialogProductos(false)} maxWidth="md">
                <DialogTitle><strong>SELECCIONAR UN CENTRO DE COSTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={productos}
                                    getOptionLabel={(option) => option.descripcion}
                                    onInputChange={(e, newInputValue) => {
                                        setItem(newInputValue)
                                        setProducto(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CENTRO DE COSTO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>NOMBRE</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>BODEGA</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productos && productos
                                        .filter((val) => {
                                            if (item === "") {
                                                return val;
                                            } else if (val.descripcion.toLowerCase().includes(item.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.codigo_producto}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.descripcion}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.bodega}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addProducto(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogProductos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogProductos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
};
