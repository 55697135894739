import {
    Box, Chip
} from "@mui/material";
import { db } from "../../../../firebase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
function Estado({ estado }) {
    let name;
    let color;
    if (estado === 0) {
        name = "NO FACTURADO"
        color = "error"
    } else if (estado === 1) {
        name = "FACTURADO"
        color = "success"
    }
    return <Chip label={name} color={color} size='small' />
}
export const SaldosTab = () => {
    const clientDetails = useSelector(state => state.clientDetails)
    const { client_details } = clientDetails
    const [data, setData] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const LoadData = () => {
        let ref = db.collection('saldos').where('idcliente','==', client_details.idcliente).orderBy('created', 'desc')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ]
            console.log(data)
            setData(data)
        })
    }
    const columns = [
        { field: 'id_saldos', headerName: 'ID', width: 100 },
        { field: 'factura_origen', headerName: 'RUBRO ORIGEN', width: 150 },
        { field: 'factura_destino', headerName: 'RUBRO DESTINO', width: 150 },
        {
            field: 'monto', headerName: 'MONTO', width: 100,
            valueGetter: ({ value }) => `$${value}`,
        },
        {
            field: 'fecha_generacion', headerName: 'FECHA GENERACION', type: 'dateTime', width: 175,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 300 },
        {
            field: 'estado', headerName: 'ESTADO', width: 165,
            renderCell: (params) => {
                return <Estado
                    estado={
                        params.row.estado
                    }
                />
            }
        },
        
    ]
    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )
    return (
        <>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={data ? data : []}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    hideFooter
                    columns={columns}
               
                    components={{
                        Toolbar: CustomToolbar
                    }}
                />
            </Box>
        </>
    );
};