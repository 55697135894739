import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../../components/utils/InputValidation";
import { db, firebase } from "../../../../firebase";
export const NewMetodoDeDepreciacion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [categoria, setCategoria] = useState({ campo: '', valido: true })
    const [subcategoria, setSubCategoria] = useState({ campo: "", valido: true });

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async (categoria) => {
        await db.collection('activos_fijos_parametros').doc('metodo_de_depreciacion').collection('list').doc(categoria).set({
            metodo_de_depreciacion: categoria,
            fecha_creacion_metodo_de_depreciacion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nuevo metodo de depreciacion creado con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                estado={categoria}
                cambiarEstado={setCategoria}
                label="METODO DE DEPRECIACION"
                name="categoria"
            />

            <br />
            <Button
                onClick={() => createProduct(categoria.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !categoria.campo}
            >
                {" "}
                CREAR{" "}
            </Button>



        </>
    );
};
