import {
    Box,
    CircularProgress,
    DialogTitle,
    Dialog,
    Paper,
    Chip,
    Button,
    Collapse,
    IconButton,
    Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES,
    GridToolbarExport
} from '@mui/x-data-grid';
import { ViewTicketEnEsperaPlaneacion } from "./ViewTicketEnEsperaPlaneacion";
import { ObservacionExtraPyl } from "../Buttons/ObservacionExtraPyl";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

export const TicketsEnEsperaPlaneacion = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData();
    }, [dispatch]);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );

    //

    const LoadData = () => {

        let ref = db.collection("tickets").where('estado', '==', 0);


        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        estado2: 'EN ESPERA'
                    }
                })
            ];
            setData(data)
        })

    };
    function CustomFooterTotalComponent(props) {
        console.log(props)
        return ''
    }
    const columns = [
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="EN ESPERA" color="warning" />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 120 },
        { field: 'correo', headerName: 'CORREO', width: 220 },
        { field: 'direccion', headerName: 'DIRECCION', width: 600 },
        { field: 'zona', headerName: 'ZONA', width: 350 },
        { field: 'coordenadas', headerName: 'COORDENADAS', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 130 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        {
            field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <ViewTicketEnEsperaPlaneacion props={params.row} />
            }
        },
        {
            field: '2', headerName: 'OBSERVACION EXTRA', width: 190,
            renderCell: (params) => {
                return <ObservacionExtraPyl props={params.row} />
            }
        },
    ];
    const csvOptions = { delimiter: ';' };
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarExport /> */}
            {/* <GridToolbarQuickFilter /> */}
        </GridToolbarContainer>
    );
    const exporter = React.createRef(null);
    const [open2, setOpen2] = useState(false);
    const handleExport = () => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        var date1;
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`;
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`;
        }

        const reData = [
            ...data.map((doc) => {
                return {
                    CEDULA: doc.cedula,
                    CAJA: doc.caja,
                    DETALLE: `${doc.nombre_cliente}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}\n${doc.correo}\n${doc.coordenadas}\n${doc.caja}\n${doc.numContrato}`,
                    COORD_CAJA: doc.coordenadas,
                    TICKET: doc.uid_ticket,
                    NOMBRE: doc.nombre_cliente,
                    TELEFONO: doc.telefono,
                    CELULAR: doc.celular,
                    CORREO: doc.correo,
                    DIRECCION: doc.direccion,
                    ZONA: doc.zona,
                    CONTRATO: doc.linea_contrato,
                    AGENDADO_DESDE: doc.agendado_desde,
                    DEPARTAMENTO: doc.departamento,
                    ASUNTO_TICKET: doc.asunto_del_ticket,
                    VALOR: doc.valor_a_cobrar,
                    DETALLES_TICKET: doc.detalles_del_ticket,
                    TURNO: doc.turno,
                    created: doc.created.toDate(),
                };
            }),
        ];
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "Licencias");
        XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`);
    }
    const handleExport2 = () => {
        setOpen2(false)
    };
    const esVerdadero = 0;
    return (
        <>
            <Paper>
                <IconButton color="primary" onClick={handleExport}>
                    <SimCardDownloadIcon />
                </IconButton>
                <Box sx={{ height: 600, width: '100%' }}>
                    <DataGrid
                        density="compact"
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,

                        }}
                        onStateChange={(state) => {
                            console.log(state.pagination.rowCount);
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>
            <ExcelExport onExportComplete={handleExport2} data={data ? data : []} fileName={"TicketsEspera.xlsx"} ref={exporter} filterable={true} >
                <ExcelExportColumn field="estado2" title="ESTADO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="uid_ticket" title="ID TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="nombre_cliente" title="NOMBRE CLIENTE" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="telefono" title="TELEFONO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="celular" title="CELULAR" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="correo" title="CORREO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="direccion" title="DIRECCION" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="zona" title="ZONA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="coordenadas" title="COORDENADAS" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="caja" title="CAJA" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="linea_contrato" title="LINEA CONTRATO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="agendado_desde" title="AGENDADO DESDE" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="departamento" title="DEPARTAMENTO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="asunto_del_ticket" title="ASUNTO DEL TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="valor_a_cobrar" title="VALOR" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="detalles_del_ticket" title="DETALLES DEL TICKET" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="turno" title="TURNO" headerCellOptions={{ background: "#672780" }} width={150} />
                <ExcelExportColumn field="created" title="FECHA CREACION" headerCellOptions={{ background: "#672780" }} width={150} />
            </ExcelExport>
            <Dialog open={open2} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>DESCARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    );
};