import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import {
    Grid, Box, InputLabel, Button, Select, FormControl,
    MenuItem, CircularProgress, Card, CardContent, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import InputValidation from '../utils/InputValidation'
import { listsDiscapacidad, listsServicios } from '../../redux/actions/parametersActions';
import { actualizarSolicitud } from '../../redux/actions/ventasActions';
import { useNavigate } from "react-router-dom";
import { DebitoAutomatico } from './solicitud/DebitoAutomatico';
import Swal from 'sweetalert2';
import { SecurityUpdateSharp } from '@mui/icons-material';
export const NuevaVenta = ({ items }) => {
    let navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    //
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const solicitudDetalle = useSelector(state => state.solicitudDetalle)
    const { loading, solicitud } = solicitudDetalle
    const servicioList = useSelector(state => state.servicioList)
    const { servicios, loading: loadingServicios } = servicioList
    //
    const solicitudUpdate = useSelector(state => state.solicitudUpdate)
    const { loading: loadingUpdateSolicitud, updateSolicitud } = solicitudUpdate
    //
    const discapacidadList = useSelector(state => state.discapacidadList)
    const { loading: loadingDiscapacidad, discapacidad: _discapacidad } = discapacidadList
    //
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [correo, setCorreo] = useState({ campo: '', valido: true })
    const [zona, setZona] = useState({ campo: '', valido: true })
    const [celular, setCelular] = useState({ campo: '', valido: true })
    const [nombres, setNombres] = useState({ campo: '', valido: true })
    const [whatsapp, setWhatsapp] = useState({ campo: '', valido: true })
    const [numbRef, setNumbRef] = useState({ campo: '', valido: true })
    //Preguntas
    const [terceraEdad, setTerceraEdad] = useState('')
    const [direccion, setDireccion] = useState({ campo: '', valido: true })
    const [telefono, setTelefono] = useState({ campo: '', valido: true })
    const [movil, setMovil] = useState({ campo: '', valido: true })
    const [nacionalidad, setNacionalidad] = useState({ campo: '', valido: true })
    const [permanencia, setPermanencia] = useState('')
    const [contratar, setContratar] = useState('')
    const [referido, setReferido] = useState('')
    const [tarifa, setTarifa] = useState('')
    const [debito, setDebito] = useState('')
    const [numBanco, setNumBanco] = useState('')
    const [tipoCuenta, setTipoCuenta] = useState('')
    const [banco, setBanco] = useState('')
    //
    const [renovacion, setRenovacion] = useState('')
    const [contrato, setContrato] = useState('')
    const [metodoPago, setMetodoPago] = useState('')
    const [discapacidad, setDiscapacidad] = useState('')
    const [geografico, setGeografico] = useState('')
    const [discapacitado, setDiscapacitado] = useState('')
    const [paramsDiscapacidad, setParamDiscpacidad] = useState('')
    const [empleado, setEmpleado] = useState('')
    const [mesh, setMesh] = useState('NO APLICA')
    const [miniups, setUPS] = useState('NO')
    useEffect(() => {
        setReferido(items.referido)
        setCedula({ campo: items.cedula, valido: true })
        setNombres({ campo: items.nombres, value: true })
        setDireccion({ campo: items.direccion, value: true })
        setNacionalidad({ campo: items.nacionalidad, value: true })
        setZona({ campo: items.zona, value: true })
        setCorreo({ campo: items.correo, valido: true })
        setCelular({ campo: items.celular, valido: true })
        setTelefono({ campo: items.telefono, valido: true })
        setNumbRef({ campo: items.numbRef, valido: true })
        setWhatsapp({ campo: items.whatsapp, valido: true })
        setTerceraEdad(items.terceraEdad)
        //setDebito(items.debito)
        setTarifa(items.tarifa)
        setContrato(items.contrato)
        setDiscapacitado(items.discapacitado ? items.discapacitado : '')
        setDiscapacidad(items.discapacidad ? items.discapacidad : '')
        setEmpleado(items.empleado)
        dispatch(listsServicios())
        dispatch(listsDiscapacidad())
        console.log(items.planNombre)
    }, [dispatch, geografico])
    const handleTarifa = (e) => {
        setTarifa(e)
        if (e === 1) {
            setTerceraEdad(2)
            setDiscapacitado(2)

        } else {
            setTerceraEdad(2)
            setDiscapacitado(2)
        }
    }

    useEffect(() => {
        if (tipoCuenta != 'TARJETA CREDITO') {
            // if (items.planNombre.includes('PLAN POWER DEFENDER PLUS_50')
            //     || items.planNombre.includes('PLAN MAX DEFENDER PLUS_100')
            //     || items.planNombre.includes('PLAN UNLIMITED DEFENDER PLUS')) {
            setMesh('NO APLICA')
            // }
        }
    }, [tipoCuenta])
    useEffect(() => {
        if (debito != 1) {
            setMesh('NO APLICA')
        }
    }, [debito])
    const handleTerceraEdad = (e) => {
        setTarifa(e)
        if (e === 1) {
            setTerceraEdad(e)
            setTarifa(1)
        } else {
            setTerceraEdad(e)
            setTarifa(1)
        }
    }
    const handleDiscapacitado = (e) => {
        setDiscapacitado(e)
        if (e === 1) {
        } else {
            setDiscapacidad('')
        }
    }
    const handleDiscapacidad = (e) => {
        setDiscapacidad(e)
        const p = _discapacidad.filter((item) => item.id === e);
        const plan = p[0]
        console.log(plan, 'plan')
        setParamDiscpacidad(plan)
    }
    const handleUpdate = () => {
        const data = {
            uid: params.id,
            cedula: cedula.campo,
            correo: correo.campo,
            celular: celular.campo,
            nombres: nombres.campo,
            whatsapp: whatsapp.campo,
            numbRef: numbRef.campo,
            direccion: direccion.campo,
            movil: movil.campo,
            telefono: telefono.campo,
            nacionalidad: nacionalidad.campo,
            permanencia: permanencia,
            contratar: contratar,
            terceraEdad: terceraEdad,
            tarifa: tarifa,
            zona: zona.campo ? zona.campo : '',
            contrato: contrato,
            cargo: userInfo.cargo,
            discapacitado: discapacitado,
            discapacidad: discapacidad,
            paramsDiscapacidad: paramsDiscapacidad,
            empleado: empleado,
            referido: referido ? referido : '',
            debito: debito,
            banco: banco,
            numBanco: numBanco,
            tipoCuenta: tipoCuenta,
            mesh: mesh === 'NO APLICA' ? 'NO' : mesh,
            ups:miniups
        }

        dispatch(actualizarSolicitud(params.id, data, navigate))
        console.log(data, 'Informacion enviada')
    }
    const validationDebito = () => {
        if (debito === 2) {
            return false
        }
        if (debito === 1 && banco && numBanco) {
            console.log('Si es valido')
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <Card variant='outlined'>
                <CardContent>
                    <Box pb={2}>
                        <Typography><strong>{items.uid}</strong> </Typography>
                    </Box>
                    <Box py={2}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4} >
                                <InputValidation
                                    disabled
                                    estado={cedula}
                                    cambiarEstado={setCedula}
                                    label="CEDULA / RUC"
                                    name="cedula" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={4}>
                                <InputValidation
                                    disabled
                                    estado={nombres}
                                    cambiarEstado={setNombres}
                                    label="NOMBRE / RAZON SOCIAL"
                                    name="nombres" />
                                <InputValidation
                                    estado={celular}
                                    cambiarEstado={setCelular}
                                    helperText="Minimo 10 caracteres"
                                    patterns={/^\d{10}$/}
                                    // disabled={true}
                                    label="CELULAR"
                                    name="celular" />
                                <InputValidation
                                    estado={whatsapp}
                                    cambiarEstado={setWhatsapp}
                                    label="WHATSAPP"
                                    helperText="Minimo 10 caracteres"
                                    patterns={/^\d{10}$/}
                                    name="celular" />
                                <InputValidation
                                    estado={numbRef}
                                    cambiarEstado={setNumbRef}
                                    label="NUMERO DE REFERENCIA"
                                    helperText="Minimo 10 caracteres"
                                    patterns={/^\d{10}$/}
                                    name="numref" />
                                <Box pb={3}>
                                    <FormControl fullWidth >
                                        <InputLabel>¿DESCUENTO EMPLEADO?</InputLabel>
                                        <Select
                                            label="¿DESCUENTO EMPLEADO?"
                                            value={empleado}
                                            onChange={(e) => setEmpleado(e.target.value)}>
                                            <MenuItem value="SI">Si</MenuItem>
                                            <MenuItem value="NO">No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputValidation
                                    estado={direccion}
                                    cambiarEstado={setDireccion}
                                    label="DIRECCIÓN"
                                    name="direccion" />
                                <InputValidation
                                    estado={telefono}
                                    // cambiarEstado={setTelefono}
                                    disabled={true}
                                    label="TELÉFONO"
                                    name="telefono" />


                                <DebitoAutomatico cDebito={setDebito} cBanco={setBanco} cNumBanco={setNumBanco} cTipoCuenta={setTipoCuenta} />


                                <Box pb={3}>
                                    <FormControl fullWidth >
                                        <InputLabel>¿ACCEDE A TARIFA PREFERENCIAL?</InputLabel>
                                        <Select
                                            label="¿ACCEDE A TARIFA PREFERENCIAL?"
                                            value={tarifa}
                                            onChange={(e) => handleTarifa(e.target.value)}>
                                            <MenuItem value={1}>Si</MenuItem>
                                            <MenuItem value={2}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {tarifa === 1 && <>
                                    <FormControl fullWidth >
                                        <InputLabel>¿ES TERCERA EDAD?</InputLabel>
                                        <Select
                                            label="ES TERCERA EDAD?"
                                            value={terceraEdad}
                                            onChange={(e) => handleTerceraEdad(e.target.value)}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Si</MenuItem>
                                            <MenuItem value={2}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Box pt={3}>
                                        <FormControl fullWidth >
                                            <InputLabel>¿ES DISCAPACITADO?</InputLabel>
                                            <Select
                                                label="¿ES DISCAPACITADO?"
                                                value={discapacitado}
                                                onChange={(e) => handleDiscapacitado(e.target.value)}>
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={1}>Si</MenuItem>
                                                <MenuItem value={2}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {
                                        discapacitado == 1 &&
                                        <Box pt={3}>
                                            <FormControl fullWidth >
                                                <InputLabel>¿DISCAPACIDAD?</InputLabel>
                                                <Select
                                                    label="¿DISCAPACIDAD?"
                                                    value={discapacidad}
                                                    onChange={(e) => handleDiscapacidad(e.target.value)}>
                                                    {_discapacidad && _discapacidad.map((row, key) => (
                                                        <MenuItem key={key} value={row.id}>{row.discapacidad} - {row.descuento} - ${row.valor}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    }
                                </>
                                }

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <InputValidation
                                    disabled
                                    estado={nacionalidad}
                                    cambiarEstado={setNacionalidad}
                                    label="NACIONALIDAD"
                                    name="nacionalidad" />
                                <InputValidation
                                    estado={correo}
                                    cambiarEstado={setCorreo}
                                    label="CORREO ELECTRONICO"
                                    name="contrato" />
                                <InputValidation
                                    disabled
                                    estado={zona}
                                    cambiarEstado={setZona}
                                    label="ZONA"
                                    name="zona" />
                                <Box pb={4}>
                                    <FormControl fullWidth >
                                        <InputLabel>TIPO DE CONTRATO</InputLabel>
                                        <Select
                                            label="TIPO DE CONTRATO"
                                            value={contrato}
                                            onChange={(e) => setContrato(e.target.value)}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"HOGAR"}>HOGAR</MenuItem>
                                            <MenuItem value={"CORPORATIVO"}>CORPORATIVO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                {userInfo.vendedor.centro_costo === 'AGENCIA FORTIN'
                                || userInfo.vendedor.centro_costo === 'ISLA FORTIN' ?
                                <Box pb={4}>
                                    <FormControl fullWidth >
                                        <InputLabel>MESH</InputLabel>
                                        <Select
                                            label="MESH"
                                            value={mesh}
                                            disabled={tipoCuenta != 'TARJETA CREDITO' || debito != 1
                                                || (!(items.planNombre.includes('PLAN POWER DEFENDER PLUS_50')
                                                    || items.planNombre.includes('PLAN MAX DEFENDER PLUS_100')
                                                    || items.planNombre.includes('PLAN UNLIMITED DEFENDER PLUS')))}
                                            onChange={(e) => setMesh(e.target.value)}>

                                            <MenuItem value={"NO APLICA"}>NO APLICA</MenuItem>
                                            <MenuItem value={"SI"}>SI</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box> : ''}
                                {userInfo.vendedor.centro_costo === 'AGENCIA FORTIN'
                                || userInfo.vendedor.centro_costo === 'ISLA FORTIN' ?
                                <Box pb={4}>
                                    <FormControl fullWidth >
                                        <InputLabel>MINI UPS</InputLabel>
                                        <Select
                                            label="MINI UPS"
                                            value={miniups}
                                            onChange={(e) => setUPS(e.target.value)}>
                                            <MenuItem value={"SI"}>SI</MenuItem>
                                            <MenuItem value={"NO"}>NO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box> : ''}
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="row-reverse">
                            <Button
                                onClick={handleUpdate}
                                disabled={loadingUpdateSolicitud
                                    || !empleado || !contrato || !celular.campo || !debito || validationDebito() ||
                                    celular.valido === false || telefono.valido === false
                                    || !whatsapp.campo || !numbRef.campo
                                    || whatsapp.valido === false || numbRef.valido === false || celular.valido === false
                                }
                                variant='contained'
                                disableElevation
                            > {!loadingUpdateSolicitud ? <strong>Enviar Informacion</strong> : <CircularProgress size={24} />}  </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

        </>
    )
}