import {
    Button,
    Box,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import { db, firebase, auth } from "../../firebase";
import Swal from "sweetalert2";
import UploadIcon from '@mui/icons-material/Upload';
import { useDispatch, useSelector } from 'react-redux';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';

export const CargaMasiva = () => {

    const [total, setTotal] = useState(0);

    const ingresoClientes = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cedula": 953416261,
            "nombres": "juan",
            "direccion": "111",
            "provincia": "11",
            "canton": "111",
            "sector": "111",
            "zona": "111",
            "parroquia": "111",
            "coordenadas": "111",
            "telefono": "111",
            "celular": "111",
            "whatapp": "111",
            "numReferencia": "111",
            "estado": "111",
            "cuentabancaria": "1111",
            "banco": "111",
            "tipobanco": "111",
            "fechanacimiento": "111",
            "accededebitobancario": "111",
            "codigopago": "10000s",
            "gradodiscapacidad": "111",
            "gradodiscapacitado": "111",
            "numContrato": "111",
            "obtieneCamara": "111",
            "cantidadCamara": "1111"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const [message, setMessage] = useState();

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [openCaja, setOpenCaja] = React.useState(false);

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };

    const [data, setData] = useState();

    const [data2, setData2] = useState();

    const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData)

                /* FOR EACH DE LA DATA DEL EXCEL DE CLIENTES */

                jsonData.forEach((obj) => {

                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "cedula": obj.CEDULA,
                        "nombres": `${obj.NOMBRES}`,
                        "direccion": `${obj.DIRECCION}`,
                        "provincia": `${obj.PROVINCIA}`,
                        "canton": `${obj.CANTON}`,
                        "sector": `${obj.SECTOR}`,
                        "zona": `${obj.ZONA}`,
                        "parroquia": `${obj.PARROQUIA}`,
                        "coordenadas": `${obj.COORDENADAS}`,
                        "telefono": `${obj.TELEFONO}`,
                        "celular": `${obj.CELULAR}`,
                        "whatapp": `${obj.WHATSAPP}`,
                        "numReferencia": `${obj.NUM_REFERENCIA}`,
                        "estado": `${obj.ESTADO}`,
                        "cuentabancaria": `${obj.CUENTA_BANCARIA}`,
                        "banco": `${obj.BANCO}`,
                        "tipobanco": `${obj.TIPO_BANCO}`,
                        "fechanacimiento": `${obj.FECHA_NACIMIENTO}`,
                        "accededebitobancario": `${obj.ACCEDE_DEBITO_BANCARIO}`,
                        "codigopago": `${obj.CODIGO_PAGO}`,
                        "gradodiscapacidad": `${obj.GRADO_DISCAPACIDAD}`,
                        "gradodiscapacitado": `${obj.GRADO_DISCAPACITADO}`,
                        "numContrato": `${obj.NUM_CONTRATO}`,
                        "obtieneCamara": `${obj.OBTIENE_CAMARA}`,
                        "cantidadCamara": `${obj.CANTIDAD_CAMARA}`
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
                        .then(response => response.text())
                        .then(result => console.log(result))
                        .catch(error => console.log('error', error));

                });

                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se ha cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    };

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };


    const subirCargaInicial = () => {
        /* Swal.fire({ icon: "success", title: "Informacion subida correctamente" }); */
        data.forEach((obj) => {
            db.collection("carga_masiva_clientes")
                .doc(obj.CODIGO_PRODUCTO)
                .set({
                    codigoProducto: obj.CODIGO_PRODUCTO,
                    bodega: obj.LOCALIDAD,
                    descripcion: obj.DESCRIPCION,
                    categoria: obj.SUBTIPO,
                    unidad_medida: obj.UNIDAD_MEDIDA,
                    /* stock_actual: obj.STOCK,
                    costo_promedio: obj.COSTO_PROMEDIO,
                    total: obj.TOTAL */
                })
        });
        Swal.fire({
            icon: "success",
            title: "Subiendo datos, esto tardara un poco...",
        });
    }

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("carga_masiva_clientes")

        //Si tiene algun doc anterior se agrega acontinuacion

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData2(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })
    };

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        { field: 'CEDULA', headerName: 'CEDULA', width: 180 },
        { field: 'NOMBRES', headerName: 'NOMBRES', width: 180 },
        { field: 'DIRECCION', headerName: 'DIRECCION', width: 180 },
        { field: 'PROVINCIA', headerName: 'PROVINCIA', width: 180 },
        { field: 'CANTON', headerName: 'CANTON', width: 180 },
        { field: 'SECTOR', headerName: 'SECTOR', width: 180 },
        { field: 'ZONA', headerName: 'ZONA', width: 180 },
        { field: 'PARROQUIA', headerName: 'PARROQUIA', width: 180 },
        { field: 'COORDENADAS', headerName: 'COORDENADAS', width: 180 },
        { field: 'TELEFONO', headerName: 'TELEFONO', width: 180 },
        { field: 'CELULAR', headerName: 'CELULAR', width: 180 },
        { field: 'WHATSAPP', headerName: 'WHATSAPP', width: 180 },
        { field: 'NUM_REFERENCIA', headerName: 'NUM REFERENCIA', width: 180 },
        { field: 'ESTADO', headerName: 'ESTADO', width: 180 },
        { field: 'CUENTA_BANCARIA', headerName: 'CUENTA BANCARIA', width: 180 },
        { field: 'BANCO', headerName: 'BANCO', width: 180 },
        { field: 'TIPO_BANCO', headerName: 'TIPO BANCO', width: 180 },
        { field: 'FECHA_NACIMIENTO', headerName: 'FECHA NACIMIENTO', width: 180 },
        { field: 'ACCEDE_DEBITO_BANCARIO', headerName: 'ACCEDE DEBITO BANCARIO', width: 230 },
        { field: 'CODIGO_PAGO', headerName: 'CODIGO PAGO', width: 180 },
        { field: 'GRADO_DISCAPACIDAD', headerName: 'GRADO DISCAPACIDAD', width: 180 },
        { field: 'GRADO_DISCAPACITADO', headerName: 'GRADO DISCAPACITADO', width: 190 },
        { field: 'NUM_CONTRATO', headerName: 'NUM CONTRATO', width: 180 },
        { field: 'OBTIENE_CAMARA', headerName: 'OBTIENE CAMARA', width: 180 },
        { field: 'CANTIDAD_CAMARA', headerName: 'CANTIDAD CAMARA', width: 180 },
    ];

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const getData = () => {
        console.log(data)
    }

    return (
        <>

            <Box py={1} px={1}>

                <Box pb={1}>
                    <Button onClick={() => setOpenCaja(true)} size='small' variant="contained" color="secondary" startIcon={<UploadIcon />}>
                        CARGAR EXCEL
                    </Button>
                </Box>

                <Paper>
                    <Box sx={{ height: 850, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            // disableColumnFilter
                            // disableColumnSelector
                            // disableDensitySelector
                            //hideFooterPagination
                            columns={columns}
                            getRowId={(row) => row.CEDULA}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}
                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

            {/* <Box pt={1}>
                <Card>
                    <CardContent>

                        <Button style={{ marginLeft: "10px", marginBottom: "10px" }} onClick={() => setOpenCaja(true)} size='small' variant="contained" color="secondary" startIcon={<UploadIcon />}>
                            CARGAR EXCEL
                        </Button>

                        <Box pt={1}>
                            <Paper elevation={3} />
                            <TableContainer sx={{ maxHeight: 640 }}>
                                <Table size="small" stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>CEDULA</TableCell>
                                            <TableCell>NOMBRE</TableCell>
                                            <TableCell>DIRECCION</TableCell>
                                            <TableCell>PROVINCIA</TableCell>
                                            <TableCell>CANTON</TableCell>
                                            <TableCell>SECTOR</TableCell>
                                            <TableCell>ZONA</TableCell>
                                            <TableCell>PARROQUIA</TableCell>
                                            <TableCell>COORDENADAS</TableCell>
                                            <TableCell>TELEFONO</TableCell>
                                            <TableCell>CELULAR</TableCell>
                                            <TableCell>WHATSAPP</TableCell>
                                            <TableCell>NUM_REFERENCIA</TableCell>
                                            <TableCell>ESTADO</TableCell>
                                            <TableCell>CUENTA_BANCARIA</TableCell>
                                            <TableCell>BANCO</TableCell>
                                            <TableCell>TIPO_BANCO</TableCell>
                                            <TableCell>FECHA_NACIMIENTO</TableCell>
                                            <TableCell>ACCEDE_DEBITO_BANCARIO</TableCell>
                                            <TableCell>CODIGO_PAGO</TableCell>
                                            <TableCell>GRADO_DISCAPACIDAD</TableCell>
                                            <TableCell>GRADO_DISCAPACITADO</TableCell>
                                            <TableCell>NUM_CONTRATO</TableCell>
                                            <TableCell>OBTIENE_CAMARA</TableCell>
                                            <TableCell>CANTIDAD_CAMARA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data2 && data2.map((row) => (
                                            <TableRow>

                                                <TableCell component="th" scope="row">
                                                    {row.nombre}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Paper />
                        </Box>


                    </CardContent>
                </Card>
            </Box> */}


            {/* SUBIR EXCEL */}
            <Dialog
                open={openCaja}
                onClose={() => setOpenCaja(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Subir Excel</DialogTitle>
                <DialogContent>
                    <Box pb={2}>
                        {fileName
                            ? `Nombre el archivo cargado:  ${fileName}`
                            : "Por favor, cargar el archivo..."}
                    </Box>
                    {!file ? (
                        <>
                            <input
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadFileLocal(e)}
                                multiple
                                id="buttonFile"
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor="buttonFile">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    component="span"
                                >
                                    Cargar archivo
                                </Button>
                            </label>
                        </>
                    ) : (
                        <Box>
                            <Box display="flex">
                                <Box pr={5}>
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        onClick={(e) => handleRemove()}
                                    >
                                        Borrar archivo
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        size="small"
                                        onClick={handleUploadFile}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Subir archivo
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                </DialogContent>
            </Dialog>

        </>
    );
};