import {
    Grid,
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Card,
    CardContent,
    CardActions,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Tabs,
    Tab,
} from "@mui/material";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useState, useEffect } from 'react';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Bar, Pie } from 'react-chartjs-2';
import Hammer from 'hammerjs';

import {
    Chart as Chart2,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem
} from "@progress/kendo-react-charts";


import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
    Colors
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Stack } from "@mui/system";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import moment from "moment";
import { db, firebase } from "../../firebase";
import { Formato2 } from "../inventario/reporte_de_movimientos/PlantillasPDF/Headers";


export const ReportesDeVentas = () => {
    let current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    const [vista, setVista] = useState('RANGO DE FECHAS')
    const [centro, setCentro] = useState('TODOS')
    const [zona, setZona] = useState('TODOS')
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [loading3, setLoading3] = useState(true)
    const [loading4, setLoading4] = useState(true)
    const [loading5, setLoading5] = useState(true)
    const [loading6, setLoading6] = useState(true)
    const [loading7, setLoading7] = useState(true)

    const meses = ["ENERO", "FEBRERO", 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'];

    const container = React.useRef(null);
    const container2 = React.useRef(null);
    const [dataItems, setDataItems] = useState([])

    const _exporter = React.createRef();
    const _exporter2 = React.createRef();
    const _exporter3 = React.createRef();

    const [centrosCostos, setCentrosCostos] = useState([])
    const [interno, setInterno] = useState([])
    const [externo, setExterno] = useState([])
    const [lideres, setLideres] = useState([])
    const [zonas, setZonas] = useState([])

    const [datosLider, setDatosLider] = useState([])
    const [datosPlanes, setDatosPlanes] = useState([])
    const [datosZonas, setDatosZonas] = useState([])
    const [datosCentro, setDatosCentro] = useState([])
    const [datosHora, setDatosHora] = useState([])
    const [planesOrdenados, setPlanesOrdenados] = useState([])
    const [datosPlanesO, setDatosPlanesO] = useState([])
    const [planesFiltrados, setPlanesFiltrados] = useState([])

    const [dataActual, setDataActual] = useState([])
    const [dataAnterior, setDataAnterior] = useState([])
    const [diasAnteriores, setDiasAnteriores] = useState([])

    const [dataGeneral, setDataGeneral] = useState([])
    const [dataDetallada, setDataDetallada] = useState([])
    const [dataDetalladaV, setDataDetalladaV] = useState([])
    const [mesesActuales, setMesesActuales] = useState([])

    const [filtroTabs, setFiltroTabs] = useState('BARRAS')
    const [filtroPlan, setFiltroPlan] = useState('BARRAS')
    const [vistaFiltro, setVistaFiltro] = useState('LIDER')

    const [tipoVenta, setTipoVenta] = useState('2')


    const [formato, setFormato] = useState("UNIDAD")
    const [rango, setRango] = useState("")

    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ChartDataLabels,
        ArcElement,
        Colors
    );
    Chart.defaults.color = 'black';

    useEffect(() => {
        cargarDatosPrevios()
    }, [])


    useEffect(() => {
        agregarData()
        cargarAnual()
    }, [formato])

    useEffect(() => {
        agregarData()
        cargarAnual()
    }, [tipoVenta])


    useEffect(() => {
        if (centrosCostos.length > 0 && externo.length > 0 && interno.length > 0 &&
            zonas.length > 0 && lideres.length > 0) {
            cargarAnual()
            LoadData()
        }
    }, [centrosCostos, externo, interno, zonas, lideres])


    const cargarDatosPrevios = () => {
        setRango(moment(startDate.toString()).format('DD/MM/YYYY')
            + "   -   " +
            moment(endDate.toString()).format('DD/MM/YYYY'))
        var docRef1 = db.collection("system").doc("centro_de_costos").collection("centro_de_costos")
        docRef1.onSnapshot((snapshot) => {
            var externo = []
            var interno = []
            snapshot.docs.map((doc) => {
                if (doc.id) {
                    if (!doc.id.includes("ISAL")) {
                        if (doc.id.includes("DISTRIBUIDOR")) {
                            externo.push(doc.id)
                        }
                        else {
                            interno.push(doc.id)
                        }
                    }
                }
            })
            setExterno(externo)
            interno.push("VENTAS CORPORATIVO")
            interno.push("VENTAS CONVENIO")
            setInterno(interno)
            setCentrosCostos(externo.concat(interno).concat("NO ASIGNADO"))
        });
        let docRef2 = db.collection("system").doc("centro_de_costos").collection("lider_de_ventas")
        docRef2.onSnapshot((snapshot) => {
            let lideres = []
            snapshot.docs.map((doc) => {
                if (doc.id && doc.id.length > 6) {
                    lideres.push(doc.id)
                }
            })
            lideres.push("NO ASIGNADO")
            setLideres(lideres)
        });
        let docRef3 = db.collection("zonas")
        docRef3.onSnapshot((snapshot) => {
            let zonas = []
            snapshot.docs.map((doc) => {
                if (doc.id && doc.id.includes("ZONA")) {
                    zonas.push(doc.id)
                }
            })
            setZonas(zonas)
        });

    }
    //carga de información de ventas 
    const LoadData = async () => {
        let dias = []
        for (let h = 1; h <= current.getDate(); h++) {
            dias.push(h)
        }
        setDiasAnteriores(dias)
        let mesesA = []
        for (let j = 0; j <= current.getMonth(); j++) {
            mesesA.push(meses[j])
        }
        setMesesActuales(mesesA)
        //.where("estado", "==", parseInt(tipoVenta))
        let ref = db.collection("tecnicos").orderBy("fechaInstalacionVenta", "desc");
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 0, 0)

            let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('fechaInstalacionVenta', '>=', startfulldate)
            ref = ref.where('fechaInstalacionVenta', '<=', endfulldate)
            ref.onSnapshot((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                console.log(data.length)
                if (zona != 'TODOS') {
                    let data2 = []
                    for (const element of data) {
                        if (element.centro_costo.centro_costo == zona.toUpperCase()) {
                            data2.push(element)
                        }
                    }
                    setDataItems(data2);
                    cargarLideres(data2)
                    cargarZonas(data2)
                    cargarCentros(data2)
                    cargarTop(data2)
                    cargarPlanesZonas(data2)
                    cargarHoras(data2)

                } else {
                    setDataItems(data);
                    cargarLideres(data)
                    cargarZonas(data)
                    cargarCentros(data)
                    cargarTop(data)
                    cargarPlanesZonas(data)
                    cargarHoras(data)
                }
            });
        }
    };

    //formateo de la data para gráficos de Ventas por Horas
    const cargarHoras = (data) => {
        setLoading7(true)
        let horas = []
        for (let i = 0; i < 24; i++) {
            horas.push(0)
        }
        data.map((item) => {
            if (item.fechaInstalacionVenta) {
                let hora = parseInt(moment(item.fechaInstalacionVenta.toDate().toString()).format('HH'))
                if (item.planValor) {
                    let agregar = formato != "DOLARES" ? 1 : parseFloat(item.planValor);
                    horas[hora] = horas[hora] + agregar
                }
            }
        })
        setDatosHora(horas)
        setLoading7(false)
    }

    //formateo de la data para gráficos de ventas anuales
    const cargarAnual = () => {
        setLoading(true)
        //.where("estado", "==", parseInt(tipoVenta))
        let ref = db.collection("tecnicos").orderBy("fechaInstalacionVenta", "desc");
        let year = current.getFullYear()
        let fecha = new Date(year - 2 + '-11' + '-11')
        fecha.setHours(0, 0, 0, 0)
        let startfulldate = firebase.firestore.Timestamp.fromDate(fecha);
        ref = ref.where('fechaInstalacionVenta', '>=', startfulldate)
        ref.onSnapshot((snapshot) => {
            //var ids = [];
            const data = [
                ...snapshot.docs.map((doc) => {
                    //if(!doc.data().centro_costo) ids.push(doc.data().id)
                    return {
                        ...doc.data(),
                        id: doc.id,
                        dia: doc.data().fechaInstalacionVenta.toDate().getDate(),
                        año: doc.data().fechaInstalacionVenta.toDate().getFullYear(),
                        mes: (doc.data().fechaInstalacionVenta.toDate().getMonth() + 1) + ''
                    };
                }),
            ];

            let agrupado = data.reduce((filtrado, { año, mes, planValor }) => {
                if (!planValor) planValor = 0;
                if (año == year) {
                    if (!filtrado[mes]) filtrado[mes] = [0];
                    filtrado[mes] = formato != "DOLARES" ?
                        [filtrado[mes][0] + 1] : [filtrado[mes][0] + parseFloat(planValor)];
                }
                return filtrado;
            }, {});


            let valoresActual = []
            for (let j = 1; j < 13; j++) {
                if (agrupado[j + '']) {
                    valoresActual.push(agrupado[j + ''][0])
                }
            }
            setDataActual(valoresActual)

            let agrupado2 = data.reduce((filtrado2, { año, mes, planValor }) => {
                if (!planValor) planValor = 0;
                if (año == (year - 1)) {
                    if (!filtrado2[mes]) filtrado2[mes] = [0];
                    filtrado2[mes] = formato != "DOLARES" ?
                        [filtrado2[mes][0] + 1] : [filtrado2[mes][0] + parseFloat(planValor)];
                }
                return filtrado2;
            }, {});

            let valoresAnterior = []
            for (let i = 1; i < 13; i++) {
                (!agrupado2[i + '']) ? valoresAnterior.push(null) : valoresAnterior.push(agrupado2[i + ''][0])
            }



            setDataAnterior(valoresAnterior)
            setLoading(false)
            cargarAnualDetallado(data, "")
            cargarAnualDetallado(data, "sector")
            dataResumenGeneral2(data)
        });
    }

    //formateo de la data para del excel detallado por nodo y vendedor
    const cargarAnualDetallado = (data, opcion) => {
        let year = current.getFullYear()
        let sectores = []
        let vendedores = []
        let agrupado = data.reduce((filtrado, { año, mes, dia, sector, vendedor, centro_costo, planValor }) => {
            let campo = opcion == 'sector' ? sector : 'NO ASIGNADO'
            let mes2 = meses[mes - 1]
            let dia2 = dia + ''
            let campo2 = 'NO ASIGNADO'
            if (centro_costo) {
                campo2 = centro_costo.centro_costo;
                campo = opcion == 'sector' ? sector : parseInt(centro_costo.codigo_vendedor) + ''
            }
            if (año == year) {
                if (!sectores.includes(campo)) sectores.push(campo)
                if (!vendedores.includes(campo)) vendedores.push(campo)
                if (!filtrado[campo2]) filtrado[campo2] = [];
                if (!filtrado[campo2][campo]) filtrado[campo2][campo] = [];
                if (!filtrado[campo2][campo][mes2]) filtrado[campo2][campo][mes2] = 0;
                filtrado[campo2][campo][mes2] = formato != "DOLARES" ?
                    filtrado[campo2][campo][mes2] + 1 : filtrado[campo2][campo][mes2] + parseFloat(planValor);
                if (mes2 == meses[current.getMonth()]) {
                    if (!filtrado[campo2][campo][dia2]) filtrado[campo2][campo][dia2] = 0;
                    filtrado[campo2][campo][dia2] = formato != "DOLARES" ?
                        filtrado[campo2][campo][dia2] + 1 : filtrado[campo2][campo][dia2] + parseFloat(planValor);
                }
            }
            return filtrado;
        }, {});

        let valoresActual = []
        centrosCostos.map((centro) => {
            if (agrupado[centro.toUpperCase()]) {
                let lista = opcion == 'sector' ? sectores : vendedores
                lista.map((item) => {
                    if (agrupado[centro.toUpperCase()][item]) {
                        let dataMes = {
                            centro_costo: centro,
                            sector: item,
                            vendedor: item
                        }
                        var totalFila = 0
                        for (let j = 1; j < current.getMonth() + 2; j++) {
                            let mes = meses[j - 1]
                            if (agrupado[centro.toUpperCase()][item][mes]) {
                                totalFila += agrupado[centro.toUpperCase()][item][mes]
                                dataMes = {
                                    ...dataMes,
                                    [mes]: agrupado[centro.toUpperCase()][item][mes]
                                }
                            }
                        }
                        for (let j = 1; j <= current.getDate(); j++) {
                            if (agrupado[centro.toUpperCase()][item][j + '']) {
                                dataMes = {
                                    ...dataMes,
                                    [j + '']: agrupado[centro.toUpperCase()][item][j + '']
                                }
                            }
                        }
                        dataMes = {
                            ...dataMes,
                            total: totalFila
                        }
                        valoresActual.push(dataMes)
                    }
                })
            }
            else {
                let dataMes = {
                    centro_costo: centro,
                }
                valoresActual.push(dataMes)
            }
        })

        if (opcion == 'sector') {
            valoresActual = [].concat(valoresActual)
                .sort((a, b) => a.sector > b.sector ? 1 : -1)
            setDataDetallada(valoresActual)
        } else {
            valoresActual = [].concat(valoresActual)
                .sort((a, b) => parseInt(a.vendedor) > parseInt(b.vendedor) ? 1 : -1)
            setDataDetalladaV(valoresActual)
        }
    }

    //formateo de la data para grafico de top planes
    const cargarTop = async (data) => {
        setLoading6(true)
        var planes = []
        var agrupado = data.reduce((formatoTop, { planNombre, planValor }) => {
            if (planNombre && typeof planNombre == "string") {
                var nombre = planNombre + ' $' + parseFloat(planValor)
                if (!planes.includes(nombre)) planes.push(nombre)
                if (!formatoTop[nombre]) formatoTop[nombre] = [0];
                formatoTop[nombre] = formato != "DOLARES" ?
                    [formatoTop[nombre][0] + 1] : [formatoTop[nombre][0] + parseFloat(planValor)];
            }
            return formatoTop;
        }, {});
        var data1 = [];
        planes.map((item, index) => {
            data1.push({
                nombre: item,
                valor: (!agrupado[item]) ? [0] : agrupado[item][0]
            })
        })
        var planes2 = [].concat(data1)
            .sort((a, b) => a.valor < b.valor ? 1 : -1)

        if (planes2.length > 5) {
            planes2 = planes2.slice(0, 5)
        }
        var planesOrdenados = []
        var cantidadOrdenada = []
        planes2.map((item, index) => {
            var label = (formato == "DOLARES") ? '$' + (item.valor).toFixed(2) : item.valor + ''
            planesOrdenados.push(item.nombre + ': ' + label)
            cantidadOrdenada.push(item.valor)
        })
        setPlanesOrdenados(planesOrdenados)
        setDatosPlanesO(cantidadOrdenada)
        setLoading6(false)
    }

    //formateo de la data para grafico de ventas por lider
    const cargarLideres = async (data) => {
        setLoading2(true)
        var agrupado2 = data.reduce((formatoLideres, { centro_costo, planValor }) => {
            var campo = 'NO ASIGNADO'
            if (centro_costo) {
                campo = centro_costo.lider_venta
            }
            if (!formatoLideres[campo]) formatoLideres[campo] = [0];
            if (planValor) {
                formatoLideres[campo] = formato != "DOLARES" ?
                    [formatoLideres[campo][0] + 1] : [formatoLideres[campo][0] + parseFloat(planValor)];
            }
            return formatoLideres;
        }, {});
        var data2 = [];
        lideres.map((item, index) => {
            data2.push({
                label: item,
                data: (!agrupado2[item]) ? [0] : [agrupado2[item][0]],
                data2: (!agrupado2[item]) ? null : agrupado2[item][0]
            })
        })
        setDatosLider(data2)
        setLoading2(false)
    }

    //formateo de la data para grafico de ventas por Zonas
    const cargarZonas = async (data) => {
        setLoading4(true)
        var agrupado3 = data.reduce((formatoZona, { zona, planValor }) => {
            if (!formatoZona[zona]) formatoZona[zona] = [0];
            if (planValor) {
                formatoZona[zona] = formato != "DOLARES" ?
                    [formatoZona[zona][0] + 1] : [formatoZona[zona][0] + parseFloat(planValor)];
            }
            return formatoZona;
        }, {});
        var data3 = [];
        zonas.map((item, index) => {
            data3.push({
                label: item,
                data: (!agrupado3[item]) ? [0] : [agrupado3[item][0]],
                data2: (!agrupado3[item]) ? null : agrupado3[item][0]
            })
        })
        setDatosZonas(data3)
        setLoading4(false)
    }

    //formateo de la data para grafico de venta de planes por zonas
    const cargarPlanesZonas = async (data) => {
        setLoading5(true)
        var planes = []
        var agrupado = data.reduce((formatoTopPlanes, { planNombre, planValor }) => {
            if (planNombre) {
                var nombre = planNombre + ' $' + parseFloat(planValor)
                if (!planes.includes(nombre)) planes.push(nombre)
                if (!formatoTopPlanes[nombre]) formatoTopPlanes[nombre] = [0];
                formatoTopPlanes[nombre] = formato != "DOLARES" ?
                    [formatoTopPlanes[nombre][0] + 1] : [formatoTopPlanes[nombre][0] + parseFloat(planValor)];
            }
            return formatoTopPlanes;
        }, {});
        var data1 = [];
        planes.map((item) => {
            data1.push({
                nombre: item,
                valor: (!agrupado[item]) ? [0] : agrupado[item][0]
            })
        })
        var planes2 = [].concat(data1)
            .sort((a, b) => a.valor < b.valor ? 1 : -1)

        var planesOrdenados = []
        var cantidadOrdenada = []
        planes2.map((item) => {
            planesOrdenados.push(item.nombre)
            cantidadOrdenada.push(item.valor)
        })
        setDatosPlanes(cantidadOrdenada)
        setPlanesFiltrados(planesOrdenados)
        setLoading5(false)
    }

    //formateo de la data para grafico de ventas por centros de costo
    const cargarCentros = async (data) => {
        setLoading3(true)
        var agrupado4 = data.reduce((formatoCentros, { centro_costo, planValor }) => {
            var campo = 'NO ASIGNADO'
            if (centro_costo) {
                campo = centro_costo.centro_costo
            }
            if (!formatoCentros[campo]) formatoCentros[campo] = [0];
            if (planValor) {
                formatoCentros[campo] = formato != "DOLARES" ?
                    [formatoCentros[campo][0] + 1] : [formatoCentros[campo][0] + parseFloat(planValor)];
            }
            return formatoCentros;
        }, {});
        var data4 = [];
        centrosCostos.map((item, index) => {
            data4.push({
                label: item,
                data: (!agrupado4[item.toUpperCase()]) ? [0] : [agrupado4[item.toUpperCase()][0]],
                data2: (!agrupado4[item.toUpperCase()]) ? null : agrupado4[item.toUpperCase()][0]
            })
        })
        setDatosCentro(data4)
        setLoading3(false)
    }

    //formateo para grafico Pie de plan por Zona
    const dataPlanZonasPie = () => {
        var etiquetas = [];
        var valores = []
        for (let i = 0; i < planesFiltrados.length; i++) {
            var label = (formato == "DOLARES") ? '$' + (datosPlanes[i]).toFixed(2) : datosPlanes[i] + ''
            etiquetas.push(planesFiltrados[i] + ': ' + label)
            valores.push(datosPlanes[i])
        }
        return {
            labels: etiquetas,
            datasets: [
                {
                    data: valores,
                },
            ]
        }
    };

    const dataHoras = {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
        datasets: [
            {
                data: datosHora,
            },
        ]
    };

    const dataPlanes = {
        labels: planesFiltrados,
        datasets: [
            {
                data: datosPlanes,
            },
        ]
    };

    const dataPlanesOr = {
        labels: planesOrdenados,
        datasets: [
            {
                data: datosPlanesO,
            },
        ]
    };

    //cambio de formato: unidades o dolares
    const cambioFormato = (elemento) => {
        setLoading(true)
        setFormato(elemento)
    }

    //filtro de zona para ventas por plan en zonas
    function cambiarZona(elemento) {
        setZona(elemento)
        if (elemento == 'TODOS') {
            cargarPlanesZonas(dataItems)
        } else {
            var dataFiltrada = []
            for (let i = 0; i < dataItems.length; i++) {
                if (dataItems[i].zona == elemento) {
                    dataFiltrada.push(dataItems[i])
                }
            }
            cargarPlanesZonas(dataFiltrada)
        }
    }

    const seriesLabels = {
        visible: true,
        font: "11px sans-serif",
        format: formato == "DOLARES" ? "$0.00" : "0",
    }

    const seriesLabels2 = (props) => {
        var numero = parseFloat(props.dataItem.data2);
        var valor = (formato == "DOLARES") ? "$ " + (numero).toFixed(2) : numero;
        return `${props.dataItem.label}: ${valor}`
    }

    const options4 = {
        aspectRatio: 1,
        devicePixelRatio: 2,
        plugins: {
            colors: {
                forceOverride: true
            },
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Top 5 Planes Vendidos',
            },
            datalabels: {
                display: false,
            },
        },
    };

    const options5 = {
        aspectRatio: 1,
        devicePixelRatio: 2,
        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    callback: function (value, index, ticks) {
                        return value + ' h';
                    }
                },

            },
            x: {
                ticks: {
                    callback: function (value, index, ticks) {
                        var signo = formato == "DOLARES" ? '$ ' : ''
                        return signo + value;
                    }
                },
                padding: 30,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Ventas por Horas',
            },
            datalabels: {
                formatter: function (value, context) {
                    if (value == 0) {
                        return ''
                    }
                    var signo = formato == "DOLARES" ? '$ ' : ''
                    var valor = formato == "DOLARES" ? signo + (value).toFixed(2) : signo + value
                    return '            ' + valor;
                }
            }
        },
    };

    const options8 = {
        aspectRatio: 1,
        devicePixelRatio: 2,
        indexAxis: 'y',
        scales: {
            x: {
                ticks: {
                    callback: function (value, index, ticks) {
                        var signo = formato == "DOLARES" ? '$ ' : ''
                        return signo + value;
                    }
                }
            },
            y: {
                padding: 30
            }
        },
        plugins: {
            ChartDataLabels,
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Plan Por Zona: ' + zona,
            },
            datalabels: {
                formatter: function (value, context) {
                    if (value == 0) {
                        return ''
                    }
                    var signo = formato == "DOLARES" ? '$ ' : ''
                    var valor = formato == "DOLARES" ? signo + (value).toFixed(2) : signo + value
                    return '            ' + valor;
                }
            }
        },
    };

    const options88 = {
        aspectRatio: 1,
        devicePixelRatio: 2,
        plugins: {
            legend: {
                position: 'right'
            },
            title: {
                display: true,
                text: 'Plan Por Zona: ' + zona,
            },
            datalabels: {
                display: false,
            },
        },
    };


    const loader = (
        <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <br />
            <CircularProgress align="center" />
            <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
        </Stack>
    )

    async function agregarData() {
        setLoading2(true)
        setLoading3(true)
        setLoading4(true)
        setLoading5(true)
        setLoading6(true)
        setLoading7(true)
        setRango(moment(startDate.toString()).format('DD/MM/YYYY')
            + "   -   " +
            moment(endDate.toString()).format('DD/MM/YYYY'))
        LoadData()

        // cargarAnual()
    }

    //formateo de la data para dialog con los totales mensuales por centro de costo
    const dataResumenGeneral2 = async (data) => {
        var year = current.getFullYear()
        var agrupado = data.reduce((filtrado, { año, mes, centro_costo, planValor }) => {
            var mes2 = mes + ''
            var campo = 'NO ASIGNADO'
            if (centro_costo) {
                campo = centro_costo.centro_costo
            }
            if (año == year) {
                if (!filtrado[campo]) filtrado[campo] = [];
                if (!filtrado[campo][mes2]) filtrado[campo][mes2] = 0;
                filtrado[campo][mes2] = formato != "DOLARES" ?
                    filtrado[campo][mes2] + 1 : filtrado[campo][mes2] + parseFloat(planValor);
            }
            return filtrado;
        }, {});

        var valoresGenerales = []
        function cargar(grupo, etiqueta) {
            var dataTotal = {
                centro_costo: 'TOTAL ' + etiqueta,
                grupo: 'Total'
            }
            grupo.map((centro) => {
                var data1 = {
                    centro_costo: centro,
                    grupo: etiqueta
                }
                for (let j = 1; j <= current.getMonth() + 1; j++) {
                    var totalMes = 0;
                    if (agrupado[centro.toUpperCase()] && agrupado[centro.toUpperCase()][j + '']) {
                        totalMes = totalMes + agrupado[centro.toUpperCase()][j + '']
                    }
                    data1 = {
                        ...data1,
                        [j + '']: totalMes
                    }

                    dataTotal = {
                        ...dataTotal,
                        [j + '']: (dataTotal[j + '']) ? (dataTotal[j + ''] + totalMes) : (totalMes)
                    }
                }
                valoresGenerales.push(data1)
            })
            valoresGenerales.push(dataTotal)
        }
        cargar(interno, 'Interno')
        cargar(externo, 'Externo')
        cargar(["No asignado"], 'NO ASIGNADO')

        var totales = {
            centro_costo: 'TOTAL',
            grupo: 'Total'
        }
        valoresGenerales.map((item) => {
            if (item.centro_costo.includes('TOTAL')) {
                for (let j = 1; j < current.getMonth() + 2; j++) {
                    totales = {
                        ...totales,
                        [j + '']: (totales[j + '']) ? totales[j + ''] + item[j + ''] : item[j + '']
                    }

                }
            }
        })
        valoresGenerales.push(totales)
        setDataGeneral(valoresGenerales)
    }

    //cambio de rango de fechas y data mostrada
    function cambioVista(modo) {
        if (modo != "RANGO DE FECHAS") {
            var dia = current.getDay()
            var mes = current.getMonth()
            var año = current.getFullYear()
            var fecha1 = null
            var fecha2 = null
            if (modo == 'HOY') {
                fecha1 = current
                fecha2 = new Date(current.getTime() + 86400000)
            } else if (modo == 'MES EN CURSO') {
                if (mes < 10) {
                    fecha1 = new Date((new Date(año + '-0' + (mes + 1) + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + '-0' + (mes + 2) + '-01')
                } else if (mes == 11) {
                    fecha1 = new Date((new Date(año + '-' + (mes + 1) + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + 1 + '-01' + '-01')
                } else {
                    fecha1 = new Date((new Date(año + '-' + (mes + 1) + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + '-' + (mes + 2) + '-01')
                }
            } else if (modo == 'MES ANTERIOR') {
                if (mes == 0) {
                    fecha1 = new Date((new Date(año + '-12' + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + '-01' + '-01')
                } else if (mes < 10) {
                    fecha1 = new Date((new Date(año + '-0' + (mes) + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + '-0' + (mes + 1) + '-01')
                } else {
                    fecha1 = new Date((new Date(año + '-' + (mes) + '-01')).getTime() + 86400000)
                    fecha2 = new Date(año + '-' + (mes + 1) + '-01')
                }

            } else if (modo == 'AÑO EN CURSO') {
                fecha1 = new Date((new Date(año + '-01' + '-01')).getTime() + 86400000)
                fecha2 = new Date(año + 1 + '-01' + '-01')
            } else if (modo == 'AÑO ANTERIOR') {
                fecha1 = new Date((new Date(año - 1 + '-01' + '-01')).getTime() + 86400000)
                fecha2 = new Date(año + '-01' + '-01')
            }
            fecha1.setHours(0, 0, 0, 0)
            fecha2.setHours(23, 59, 0, 0)
            setStartDate(fecha1)
            setEndDate(fecha2)
        }
        setVista(modo)
    }

    //plantilla para encabezado de pdf
    const plantilla = (props) => {
        return (
            <div>
                {props.pageNum == 1 &&
                    <Formato2 />
                }
            </div >
        )
    };


    //descarga de pdf y formato de la hoja
    const descargarPDF = (opcion) => {
        let element = container.current;
        if (opcion == 2) {
            element = container2.current;
            savePDF(element, {
                pageTemplate: plantilla,
                paperSize: "A4",
                landscape: true,
                margin: { top: "1.2cm", left: "1cm", right: "1cm", bottom: "1cm" },
                scale: 0.5,
                fileName: `Resumen General Dashboard: ${formato}`,
            });
        } else {
            savePDF(element, {
                repeatHeaders: 'true',
                pageTemplate: plantilla,
                paperSize: "A4",
                landscape: true,
                margin: { top: "1.2cm", left: "1cm", right: "1cm", bottom: "1cm" },
                scale: 0.5,
                fileName: `Resumen General de Ventas: ${formato}`,
            });
        }


    };

    //descarga de excel
    const excelExport = (opcion) => {
        if (opcion && opcion == 1) {
            if (_exporter2.current) {
                _exporter2.current.save();
            }
        } else if (opcion && opcion == 2) {
            if (_exporter3.current) {
                _exporter3.current.save();
            }
        } else {
            if (_exporter.current) {
                _exporter.current.save();
            }
        }
    };


    function tabs(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    //funcion para la suma de las columnas en las tablas excel
    function aggregates() {
        var campos = []
        mesesActuales.map((item) => {
            campos.push({
                field: item,
                aggregate: "sum",
            })
        })
        campos.push({
            field: 'total',
            aggregate: "sum",
        })

        diasAnteriores.map((item) => {
            campos.push({
                field: item + '',
                aggregate: "sum",
            })
        })
        return campos
    }

    //agrupacion del excel por centro de costo
    const group = [
        {
            field: "centro_costo",
            aggregates: aggregates(),
        },
    ];
    const excelDetallado = process(dataDetallada, {
        group: group,
    }).data;

    const excelDetalladoV = process(dataDetalladaV, {
        group: group,
    }).data;

    const CustomGroupHeader = (props) => {
        return props.value.toUpperCase();
    };

    const CustomGroupFooter = (props) => {
        if (formato == "DOLARES") {
            if (props.aggregates.sum) {
                return `$ ${props.aggregates.sum.toFixed(2)}`;
            } else {
                return `$ 0`;
            }
        } else {
            if (props.aggregates.sum) {
                return `${props.aggregates.sum.toFixed(2)}`;
            } else {
                return `0`;
            }
        }
    };

    //agrupacion del excel por grupo interno o externo
    const group2 = [
        {
            field: "grupo",
        },
    ];
    const excelGeneral = process(dataGeneral, {
        group: group2,
    }).data;


    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>REPORTE DE VENTAS</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Grid container spacing={5}>
                                    <Grid item xs={4}>
                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESDE:</strong></p>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        renderInput={(props) => <TextField {...props} sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 }
                                                        }} size="small" />}
                                                        value={startDate}
                                                        inputFormat="dd/MM/yyyy"
                                                        disabled={(vista != "RANGO DE FECHAS") ? true : false}
                                                        onChange={(newValue) => {
                                                            setStartDate(newValue)
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Box>
                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VISTA DE GRAFICO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    size="small"
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    onChange={(e) => cambioVista(e.target.value)}
                                                    value={vista}
                                                >
                                                    <MenuItem key="HOY" value="HOY">HOY</MenuItem>
                                                    <MenuItem key="MES EN CURSO" value="MES EN CURSO">MES EN CURSO</MenuItem>
                                                    <MenuItem key="MES ANTERIOR" value="MES ANTERIOR">MES ANTERIOR</MenuItem>
                                                    <MenuItem key="AÑO EN CURSO" value="AÑO EN CURSO">AÑO EN CURSO</MenuItem>
                                                    <MenuItem key="AÑO ANTERIOR" value="AÑO ANTERIOR">AÑO ANTERIOR</MenuItem>
                                                    <MenuItem key="RANGO DE FECHAS" value="RANGO DE FECHAS">RANGO DE FECHAS</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {/* <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TIPO DE VENTA:</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    size="small"
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    onChange={(e) => setTipoVenta(e.target.value)}
                                                    value={tipoVenta}
                                                >
                                                    <MenuItem key="INSTALADA" value="2">INSTALADA</MenuItem>
                                                    <MenuItem key="EN ESPERA" value="0">EN ESPERA</MenuItem>
                                                    <MenuItem key="RECHAZADA" value="1">RECHAZADA</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box> */}

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>HASTA:</strong></p>

                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        renderInput={(props) => <TextField {...props} sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 }
                                                        }} size="small" />}
                                                        value={endDate}
                                                        disabled={(vista != "RANGO DE FECHAS") ? true : false}
                                                        inputFormat="dd/MM/yyyy"
                                                        onChange={(newValue) => {
                                                            setEndDate(newValue)
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Box>

                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FORMATO:</strong></p>

                                            <FormControl fullWidth>
                                                <Select
                                                    size="small"
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    onChange={(e) => cambioFormato(e.target.value)}
                                                    value={formato}
                                                >
                                                    <MenuItem key="UNIDAD" value="UNIDAD">UNIDAD</MenuItem>
                                                    <MenuItem key="DOLARES" value="DOLARES">DOLARES</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        {/* <Box py={2}>
                                        GRAFICOS POR ARREGLAR
                                        </Box> */}

                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CENTRO DE COSTO:</strong></p>
                                            <FormControl fullWidth>
                                                <Select
                                                    size="small"
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    onChange={(e) => setCentro(e.target.value)}
                                                    value={centro}
                                                >
                                                    <MenuItem key={-1} value={'TODOS'}>TODOS</MenuItem>
                                                    {centrosCostos.map((item, index) => (
                                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>

                                        <Box py={2}>
                                            <Button fullWidth color="success" variant="contained"
                                                disableElevation onClick={() => setOpen(true)}>
                                                {" "}
                                                VER RESUMEN GENERAL{" "}
                                            </Button>
                                        </Box>
                                        <Box py={2}>
                                            <Button fullWidth color="error" variant="contained"
                                                disableElevation onClick={() => setOpen2(true)}>
                                                {" "}
                                                VER RESUMEN DETALLADO{" "}
                                            </Button>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                    </CardContent>
                    <CardActions>
                        <br />
                        <br />
                        <Button
                            onClick={() => agregarData()}
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disableElevation>
                            {" "}
                            CONSULTAR{" "}
                        </Button>
                    </CardActions>
                </Card>
            </Box>

            <Box px={2}>
                <Card>
                    <CardContent>
                        <Button onClick={() => descargarPDF(2)} size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />} >
                            PDF
                        </Button>

                        <PDFExport>
                            <div ref={container2}>
                                <Container maxWidth="xl">
                                    <Grid container spacing={5} style={{ maxWidth: "1680px" }}>
                                        <Grid item xs={12} alignItems="center" justifyContent="center"  >
                                            <p align="center"><strong>Ventas Año Anterior vs Año Actual</strong></p>
                                            {!loading &&
                                                <Chart2 >
                                                    <ChartCategoryAxis>
                                                        <ChartCategoryAxisItem labels={{ font: "11px sans-serif" }}
                                                            categories={meses}
                                                        />
                                                    </ChartCategoryAxis>
                                                    <ChartValueAxis >
                                                        <ChartValueAxisItem labels={{ font: "11px sans-serif" }} />
                                                    </ChartValueAxis>
                                                    <ChartLegend position="bottom" orientation="horizontal" spacing={20} />
                                                    <ChartSeries>
                                                        <ChartSeriesItem type="line" name="Año Actual" data={dataActual} labels={seriesLabels} markers={{ visible: false }} />
                                                        <ChartSeriesItem type="line" name="Año Anterior" data={dataAnterior} labels={seriesLabels} markers={{ visible: false }} />
                                                    </ChartSeries>
                                                </Chart2>
                                            }
                                            {loading &&
                                                <>{loader}</>
                                            }

                                        </Grid>
                                    </Grid>
                                    <br /><br />
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} alignItems="center" justifyContent="center" style={{ fontSize: "11px" }}>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <br />
                                                    <p align="center" style={{ fontSize: "15px", marginBottom: "10px" }}>
                                                        <strong>{rango}</strong></p>
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <br />
                                                    <br />
                                                    <p align="center" style={{ fontSize: "15px", marginBottom: "10px" }}><strong>VENTAS POR:</strong></p>
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FILTRO:</strong></p>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            size="small"
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            onChange={(e) => setVistaFiltro(e.target.value)}
                                                            value={vistaFiltro}
                                                        >
                                                            <MenuItem key="LIDER" value="LIDER">LIDER DE VENTAS</MenuItem>
                                                            <MenuItem key="CENTRO" value="CENTRO">CENTROS DE COSTO</MenuItem>
                                                            <MenuItem key="ZONA" value="ZONAS">ZONAS</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4} >
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>GRAFICO:</strong></p>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            size="small"
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            onChange={(e) => setFiltroTabs(e.target.value)}
                                                            value={filtroTabs}
                                                        >
                                                            <MenuItem key="BARRAS" value="BARRAS">BARRAS</MenuItem>
                                                            <MenuItem key="PASTEL" value="PASTEL">PASTEL</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {filtroTabs == 'BARRAS' &&
                                                <>
                                                    {vistaFiltro == "LIDER" &&
                                                        <>
                                                            {loading2 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading2 &&
                                                                <Chart2>
                                                                    <ChartLegend position="right" orientation="vertical" spacing={20} />
                                                                    <ChartCategoryAxis >
                                                                        <ChartCategoryAxisItem labels={{ font: "11px sans-serif" }} />
                                                                    </ChartCategoryAxis>
                                                                    <ChartValueAxis >
                                                                        <ChartValueAxisItem labels={{ font: "11px sans-serif" }} />
                                                                    </ChartValueAxis>
                                                                    <ChartSeries>
                                                                        {datosLider.map((item) => (
                                                                            <ChartSeriesItem name={item.label} type="column" data={item.data} labels={seriesLabels} />
                                                                        ))}

                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>

                                                    }
                                                    {vistaFiltro == "CENTRO" &&
                                                        <>
                                                            {loading3 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading3 &&
                                                                <Chart2>
                                                                    <ChartLegend position="right" orientation="vertical" spacing={20} />
                                                                    <ChartValueAxis >
                                                                        <ChartValueAxisItem labels={{ font: "11px sans-serif" }} />
                                                                    </ChartValueAxis>
                                                                    <ChartSeries>
                                                                        {datosCentro.map((item) => (
                                                                            <ChartSeriesItem name={item.label} type="column" data={item.data} labels={seriesLabels} />
                                                                        ))}

                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>
                                                    }
                                                    {vistaFiltro == "ZONAS" &&
                                                        <>
                                                            {loading4 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading4 &&
                                                                <Chart2>
                                                                    <ChartLegend position="right" orientation="vertical" spacing={20} />
                                                                    <ChartCategoryAxis >
                                                                        <ChartCategoryAxisItem labels={{ font: "11px sans-serif" }} />
                                                                    </ChartCategoryAxis>
                                                                    <ChartValueAxis >
                                                                        <ChartValueAxisItem labels={{ font: "11px sans-serif" }} />
                                                                    </ChartValueAxis>
                                                                    <ChartSeries>
                                                                        {datosZonas.map((item) => (
                                                                            <ChartSeriesItem name={item.label} type="column" data={item.data} labels={seriesLabels} />
                                                                        ))}

                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>
                                                    }
                                                </>

                                            }
                                            {filtroTabs == 'PASTEL' &&
                                                <>
                                                    {vistaFiltro == "LIDER" &&
                                                        <>
                                                            {loading2 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading2 &&
                                                                <Chart2>
                                                                    <ChartSeries>
                                                                        <ChartSeriesItem
                                                                            type="pie"
                                                                            autoFit={true}
                                                                            data={datosLider}
                                                                            field="data2"
                                                                            labels={{
                                                                                visible: true,
                                                                                font: "11px sans-serif",
                                                                                content: seriesLabels2,
                                                                            }}
                                                                        />
                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>
                                                    }
                                                    {vistaFiltro == "CENTRO" &&
                                                        <>
                                                            {loading3 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading3 &&
                                                                <Chart2>
                                                                    <ChartSeries>
                                                                        <ChartSeriesItem
                                                                            type="pie"
                                                                            autoFit={true}
                                                                            data={datosCentro}
                                                                            field="data2"
                                                                            labels={{
                                                                                visible: true,
                                                                                font: "11px sans-serif",
                                                                                content: seriesLabels2,
                                                                            }}
                                                                        />
                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>
                                                    }
                                                    {vistaFiltro == "ZONAS" &&
                                                        <>
                                                            {loading4 &&
                                                                <>{loader}</>
                                                            }
                                                            {!loading4 &&
                                                                <Chart2>
                                                                    <ChartSeries>
                                                                        <ChartSeriesItem
                                                                            type="pie"
                                                                            autoFit={true}
                                                                            data={datosZonas}
                                                                            field="data2"
                                                                            labels={{
                                                                                visible: true,
                                                                                font: "11px sans-serif",
                                                                                content: seriesLabels2,
                                                                            }}
                                                                        />
                                                                    </ChartSeries>
                                                                </Chart2>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }

                                        </Grid>
                                    </Grid>
                                    <br /><br />
                                    <Grid container spacing={5}>
                                        <Grid item xs={2} >
                                            <Box py={2}>
                                                <p style={{ fontSize: "11px" }}><strong>ZONA:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select
                                                        size="small"
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        onChange={(e) => cambiarZona(e.target.value)}
                                                        value={zona}
                                                    >
                                                        <MenuItem key={-1} value={'TODOS'}>TODOS</MenuItem>
                                                        {zonas.map((item, index) => (
                                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box py={2}>
                                                <p style={{ fontSize: "11px" }}><strong>GRAFICO:</strong></p>
                                                <FormControl fullWidth>
                                                    <Select
                                                        size="small"
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        onChange={(e) => setFiltroPlan(e.target.value)}
                                                        value={filtroPlan}
                                                    >
                                                        <MenuItem key="BARRAS" value="BARRAS">BARRAS</MenuItem>
                                                        <MenuItem key="PASTEL" value="PASTEL">PASTEL</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10} alignItems="center" justifyContent="center" style={{ maxHeight: "1050px" }}>
                                            {filtroPlan == 'BARRAS' &&
                                                <>
                                                    {loading5 &&
                                                        <>{loader}</>
                                                    }
                                                    {!loading5 &&
                                                        <Bar options={options8} data={dataPlanes} />
                                                    }
                                                </>
                                            }
                                            {filtroPlan == 'PASTEL' &&
                                                <>
                                                    {loading5 &&
                                                        <>{loader}</>
                                                    }
                                                    {!loading5 &&
                                                        <Pie options={options88} data={dataPlanZonasPie()} />
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                    <br /> <br />
                                    <Grid container spacing={5}>
                                        <Grid item xs={6} alignItems="center" justifyContent="center">
                                            {loading6 &&
                                                <>{loader}</>
                                            }
                                            {!loading6 &&
                                                <Pie options={options4} data={dataPlanesOr} />
                                            }

                                        </Grid>
                                        <Grid item xs={6} alignItems="center" justifyContent="center">
                                            {loading7 &&
                                                <>{loader}</>
                                            }
                                            {!loading7 &&
                                                <Bar options={options5} data={dataHoras} />
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                            </div>
                        </PDFExport>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                <DialogTitle>
                    <strong>Resumen General  : {formato}</strong>
                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}>
                            DESCARGAR EXCEL
                        </Button>
                        <Button onClick={() => descargarPDF()} size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />} >
                            DESCARGAR PDF
                        </Button>
                    </Stack >
                </DialogTitle>
                <DialogContent>
                    <PDFExport>
                        <div ref={container}>
                            <Card>
                                <CardContent>
                                    <Table>
                                        <TableHead style={{ background: '#672780' }}>
                                            <TableRow >
                                            </TableRow>
                                            <TableRow >
                                                <TableCell colSpan={2} sx={{ color: 'white', fontSize: '11px' }}>
                                                    <strong>CENTRO DE COSTO</strong>
                                                </TableCell>
                                                {mesesActuales && mesesActuales.map((item) => (
                                                    <TableCell colSpan={1} sx={{ color: 'white', fontSize: '11px', textAlign: 'left' }}>
                                                        TOTAL {item}
                                                    </TableCell>
                                                ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataGeneral && dataGeneral.map((item) => (
                                                <>
                                                    {item && !item.centro_costo.includes('TOTAL') &&
                                                        <TableRow>
                                                            <TableCell colSpan={2} sx={{ fontSize: '11px' }}>
                                                                {item.centro_costo}
                                                            </TableCell>
                                                            {mesesActuales && mesesActuales.map((item2, index) => (
                                                                <TableCell colSpan={1} sx={{ fontSize: '11px', textAlign: 'left' }} >
                                                                    {formato == "DOLARES" && <>$</>} {formato == "DOLARES" ? parseFloat(item[(index + 1) + '']).toFixed(2) : parseInt(item[(index + 1) + ''])}
                                                                </TableCell>
                                                            ))
                                                            }
                                                        </TableRow>
                                                    }
                                                    {item && item.centro_costo.includes('TOTAL') &&
                                                        <TableRow sx={{ background: 'gray' }}>
                                                            <TableCell colSpan={2} sx={{ fontSize: '11px' }}>
                                                                <strong>{item.centro_costo}</strong>
                                                            </TableCell>
                                                            {mesesActuales && mesesActuales.map((item2, index) => (
                                                                <TableCell colSpan={1} sx={{ fontSize: '11px', textAlign: 'left' }} >
                                                                    <strong>{formato == "DOLARES" && <>$</>} {formato == "DOLARES" ? parseFloat(item[(index + 1) + '']).toFixed(2) : parseInt(item[(index + 1) + ''])}</strong>
                                                                </TableCell>
                                                            ))
                                                            }
                                                        </TableRow>
                                                    }
                                                </>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </div>
                    </PDFExport>

                    <ExcelExport
                        data={excelGeneral}
                        group={group2}
                        collapsible={true}
                        fileName={"ResumenGeneral: " + formato + ".xlsx"}
                        ref={_exporter}
                    >
                        <ExcelExportColumn field="centro_costo" title="CENTRO" headerCellOptions={{ background: "#672780" }} width={200} />
                        <ExcelExportColumn field="grupo" title="NODOS" hidden={true} groupHeader={CustomGroupHeader} />
                        {mesesActuales && mesesActuales.map((item, index) => (
                            <ExcelExportColumn field={(index + 1) + ''} title={"TOTAL " + item} width={150} headerCellOptions={{ background: "#672780" }} />
                        ))}
                    </ExcelExport>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>Resumen Detallado  : {formato}</strong>
                </DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Button onClick={() => excelExport(1)} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}>
                                    DESCARGAR EXCEL NODOS
                                </Button>
                                <Button onClick={() => excelExport(2)} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}>
                                    DESCARGAR EXCEL VENDEDORES
                                </Button>
                            </Stack >

                            <ExcelExport
                                data={excelDetallado}
                                group={group}
                                collapsible={true}
                                fileName={"ResumenDetalladoNodos: " + formato + ".xlsx"}
                                ref={_exporter2}
                            >
                                <ExcelExportColumn field="sector" title="NODOS" headerCellOptions={{ background: "#672780" }} width={200} />
                                <ExcelExportColumn field="centro_costo" title="CENTRO" hidden={true} groupHeader={CustomGroupHeader} />
                                {mesesActuales.map((item) => (
                                    <ExcelExportColumn field={item} title={'TOTAL ' + item} headerCellOptions={{ background: "#672780" }}
                                        groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} width={150} />
                                ))}
                                {diasAnteriores && diasAnteriores.map((item) => (
                                    <ExcelExportColumn field={item + ''} title={item + ''} headerCellOptions={{ background: "#672780" }}
                                        groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} />
                                ))}
                                <ExcelExportColumn field="total" title="TOTAL GENERAL" headerCellOptions={{ background: "#672780" }}
                                    groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} width={150} />
                            </ExcelExport>

                            <ExcelExport
                                data={excelDetalladoV}
                                group={group}
                                collapsible={true}
                                fileName={"ResumenDetalladoVendedores: " + formato + ".xlsx"}
                                ref={_exporter3}
                            >
                                <ExcelExportColumn field="vendedor" title="COD VENDEDOR" headerCellOptions={{ background: "#672780" }} width={150} />
                                <ExcelExportColumn field="centro_costo" title="NODOS" hidden={true} groupHeader={CustomGroupHeader} />
                                {mesesActuales.map((item) => (
                                    <ExcelExportColumn field={item} title={'TOTAL ' + item} headerCellOptions={{ background: "#672780" }}
                                        groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} width={150} />
                                ))}
                                {diasAnteriores && diasAnteriores.map((item) => (
                                    <ExcelExportColumn field={item + ''} title={item + ''} headerCellOptions={{ background: "#672780" }}
                                        groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} />
                                ))}
                                <ExcelExportColumn field="total" title="TOTAL GENERAL" headerCellOptions={{ background: "#672780" }}
                                    groupFooterCellOptions={{ textAlign: "right" }} groupFooter={CustomGroupFooter} width={150} />
                            </ExcelExport>

                        </CardContent>
                    </Card>

                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

