import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
export const RequerimientosSistema = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    return (

        <>
            {userAuth?.roles?.find(role => ['ventas', 'tecnicos']?.includes(role)) ?
                ''
                :
                <>
                    <ListItemButton onClick={() => setOpenVent(!openVent)}>
                        <ListItemIcon>
                            <WysiwygIcon />
                        </ListItemIcon>
                        <ListItemText primary="Requerimientos Sistema" />
                        {openVent ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openVent} timeout="auto" unmountOnExit >
                        <List component="div" disablePadding dense>
                            <ListItemButton component={RouterLink} to={`requerimientos-sistema/solicitud`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Solicitud de Sistema" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </>

            }
                {
                   userAuth?.roles?.find(role => [,'requerimientos-sistema']?.includes(role)) ?
                // userAuth?.email === 'priscila.venegas@intercommerce.com.ec' || 
                // userAuth?.email === 'maikel.fernandez@intercommerce.com.ec' || 
                // userAuth?.email === 'pamela.villamar@intercommerce.com.ec'?
                <>
                    <ListItemButton onClick={() => setOpenVent(!openVent)}>
                        <ListItemIcon>
                            <WysiwygIcon />
                        </ListItemIcon>
                        <ListItemText primary="Requerimientos Sistema" />
                        {openVent ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openVent} timeout="auto" unmountOnExit >
                        <List component="div" disablePadding dense>
                            <ListItemButton component={RouterLink} to={`requerimientos-sistema/solicitud`}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Solicitud de Sistema" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </>
                : ''
            }
        </>
    )
}