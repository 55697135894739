import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
export const ListCompras = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'compras']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Compras" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        {/* <ListItemButton component={RouterLink} to={`compras/realizar-solicitud-compra/compras/0`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Realizar Solicitud de Compras" />
                        </ListItemButton> */}
                        <ListItemButton component={RouterLink} to={`compras/solicitud/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Solicitudes de  Compras" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`compras/orden-compra/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Orden de Compra" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`compras/proveedores/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Proveedores" />
                        </ListItemButton>
                        
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}