import React, { useState, useRef } from 'react'
import { Box, Button, CircularProgress } from '@mui/material';
import Swal from 'sweetalert2'
import { storage, firebase, db } from '../../firebase';
import Compressor from 'compressorjs';
export const UpdateFileUploadInstaladores = ({ instalacion, cambiarEstado: setFile, estado: file, nameFile }) => {

    const [fileLoading, setFileLoading] = useState(false)
    const fileRef = useRef()
    const [fileName, setFileName] = useState(null)
    const [fileImg, setFileImg] = useState('')

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        if (file.type === "image/png" || file.type === "image/jpeg") {
            setFileName(file.name)
            setFile(file)
            const img = URL.createObjectURL(file)
            setFileImg(img)
        } else {
            Swal.fire({ icon: "warning", text: "No aceptamos este tipo de archivos", });
        }
    }
    const handleRemove = () => {
        setFileName('')
        setFile('')
        setFileLoading(false)
        fileRef.current.value = ""
    }
    const handleUploadFile = async () => {

        if (file) {
            setFileLoading(true)
            new Compressor(file, {
                quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {

                    const pictureRef = storage.ref().child('instalaciones').child(`${instalacion.numFlota} - ${instalacion.nombres} - ${instalacion.vendedor}`).child(nameFile)
                    const letreroUpload = pictureRef.put(compressedResult)
                    letreroUpload.on("state_changed",
                        (snapshot) => {

                            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            console.log('Upload is ' + progress + '% done');
                            switch (snapshot.state) {
                                case firebase.storage.TaskState.PAUSED: // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case firebase.storage.TaskState.RUNNING: // or 'running'
                                    console.log('Upload is running');
                                    break;
                            }
                        },
                        (error) => {
                            // Handle unsuccessful uploads
                            setFileLoading(false)
                            Swal.fire({ icon: 'error', text: error, })
                        },
                        () => {
                            letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                                console.log('File available at', downloadURL)
                                //POTENCIA CASA
                                if (nameFile === 'foto_potencia_casa') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_potencia_casa: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_potencia_casa: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_potencia_casa: downloadURL
                                    })

                                    //POTENCIA CAJA
                                } else if (nameFile === 'foto_potencia_caja') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_potencia_caja: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_potencia_caja: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_potencia_caja: downloadURL
                                    })
                                    //ROUTER CASA
                                } else if (nameFile === 'foto_router_casa') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_router_casa: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_router_casa: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_router_casa: downloadURL
                                    })
                                    //FOTO ETIQUETA
                                } else if (nameFile === 'foto_etiqueta') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_etiqueta: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_etiqueta: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_etiqueta: downloadURL
                                    })
                                    //FOTO RECIBO
                                } else if (nameFile === 'foto_recibo') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_recibo: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_recibo: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_recibo: downloadURL
                                    })
                                } else if (nameFile === 'foto_caja') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_caja: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_caja: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_caja: downloadURL
                                    })
                                } else if (nameFile === 'foto_premio') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_premio: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_premio: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_premio: downloadURL
                                    })
                                } else if (nameFile === 'router_encontrado_en_casa') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        router_encontrado_en_casa: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        router_encontrado_en_casa: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        router_encontrado_en_casa: downloadURL
                                    })

                                 } else if (nameFile === 'foto_test_velocidad') {
                                        db.collection('tecnicos').doc(instalacion.numContrato).update({
                                            foto_test_velocidad: downloadURL
                                        })
                                        db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                            foto_test_velocidad: downloadURL
                                        })
                                        db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                            foto_test_velocidad: downloadURL
                                        })
                                }

                                else if (nameFile === 'foto_acta_ups') {
                                    db.collection('tecnicos').doc(instalacion.numContrato).update({
                                        foto_acta_ups: downloadURL
                                    })
                                    db.collection('hoja_de_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_acta_ups: downloadURL
                                    })
                                    db.collection('registro_orden_trabajo').doc(instalacion.uid_hoja_instalacion).update({
                                        foto_acta_ups: downloadURL
                                    })
                            }
                                setFileLoading(false)

                            });

                        }
                    )
                },
                error: (error) => {
                    Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                }
            });
        }
    }
    return (
        <>
            {fileName ? <Box display='block'>
                <img width={100} src={fileImg} />
                <Box>Nombre el archivo: {fileName}</Box>
            </Box> : 'Por favor, cargue el archivo...'}
            {!file ?
                <Box>
                    <input
                        onChange={(e) => handleUploadFileLocal(e)}
                        style={{ display: 'none' }}
                        id={`contained-button-file${nameFile}`}
                        multiple
                        type="file"
                        ref={fileRef}
                    />
                    <label htmlFor={`contained-button-file${nameFile}`}>
                        <Button style={{ textTransform: 'none' }} variant="contained" color="primary" disableElevation component="span">
                            Cargar archivo...
                        </Button>
                    </label>
                </Box> :
                <Box display="flex">
                    <Box pr={1}>
                        <Button
                            disableElevation
                            variant='outlined'
                            disabled={fileLoading}
                            onClick={(e) => handleRemove()}
                        >Borrar archivo</Button>
                    </Box>
                    <Box>
                        <Button
                            onClick={handleUploadFile}
                            variant="contained"
                            disabled={fileLoading}
                            color="primary"
                            disableElevation
                        >{!fileLoading ? <strong> Subir archivo</strong> : <CircularProgress size={24} />}</Button>
                    </Box>
                </Box>}
        </>
    )
}