import { Box, Paper, Tab, Tabs, tabsClasses } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { DatosGenerales } from './DatosGenerales';
import { PuntosDeEmision } from './PuntosDeEmision';
import { Sucursales } from './Sucursales';

export const ConfiguracionFactura = () => {
    const [tab, setTab] = useState(0);

    function tabs(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={1} px={1}>
                        <Tabs
                            value={tab}
                            variant="scrollable"
                            indicatorColor="primary"
                            allowScrollButtonsMobile
                            onChange={handleChange}
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                            aria-label="configuracion de factura">
                            <Tab label="Datos Generales" {...tabs(0)} style={{ fontSize: "14px" }} />
                            <Tab label="Establecimientos" {...tabs(1)} style={{ fontSize: "14px" }} />
                            <Tab label="Puntos de Emisión" {...tabs(2)} style={{ fontSize: "14px" }} />
                        </Tabs>
                        <br />
                        {tab == 0 &&
                            <DatosGenerales />
                        }
                        {tab == 1 &&
                            <Sucursales />
                        }
                        {tab == 2 &&
                            <PuntosDeEmision />
                        }

                    </Box>
                </Paper>
            </Box>

        </>


    )
}
