import React, { useState } from "react";
import {
  TextField, FormControl, Button, Box, Grid,
  DialogActions, DialogContent, DialogTitle, Dialog, Tooltip,
} from "@mui/material";
import {  useSelector } from 'react-redux';
import Swal from "sweetalert2"
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../firebase";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import 'moment/locale/es'
export const ViewVentasServicio = ({ props }) => {
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  const [data, setData] = useState('')
  const [open, setOpen] = useState(false)
  const [fecha, setFecha] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    setLoading(true)

    if (data.cambio_domicilio === undefined) {
      if (data.tipo_venta === 'MIGRACION') {
        db.collection("agenda").doc(data.numContrato).update({
          fecha_servicio: fecha,
          fechaInstalacionMigracion: fecha,
          persona_servicio: userInfo.displayName
        })
        db.collection("tecnicos").doc(data.numContrato).update({
          fecha_servicio: fecha,
          fechaInstalacionMigracion: fecha,
          persona_servicio: userInfo.displayName
        })
      } else {
        db.collection("agenda").doc(data.numContrato).update({
          fecha_servicio: fecha,
          fechaInstalacionVenta: fecha,
          persona_servicio: userInfo.displayName
        })
        db.collection("tecnicos").doc(data.numContrato).update({
          fecha_servicio: fecha,
          fechaInstalacionVenta: fecha,
          persona_servicio: userInfo.displayName
        })
      }
    } else {
      db.collection("agenda").doc(data.numContrato).update({
        fecha_servicio: fecha,
        fechaInstalacionCambio: fecha,
        persona_servicio: userInfo.displayName
      })
      db.collection("tecnicos").doc(data.numContrato).update({
        fecha_servicio: fecha,
        fechaInstalacionCambio: fecha,
        persona_servicio: userInfo.displayName
      })
    }

    //
    db.collection("ventas").doc(data.numContrato).set({
      ...data,
      estado: 0,
      fecha_servicio: fecha,
      persona_servicio: userInfo.displayName
    }).then(() => {
      Swal.fire({ icon: "success", text: "Se le ha asignado fecha de servicio" })
      setLoading(false)
      setOpen(false)
    })
  }
  //
  const onData = () => {
    setData(props)
    setOpen(true)
  }
  return (
    <>
      <Tooltip title="Asignar fecha de servicio">
        <Button size='small' onClick={() => onData()}>
          <EditIcon color='secondary' />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="sm">
        <DialogTitle>
          {data.nombres}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <p>CEDULA FRONTAL</p>
              <a target="_blank" href={data.foto_cedula_frontal}>
                <img src={data.foto_cedula_frontal} width={200} />
              </a>
            </Grid>
            <Grid item xs={6}>
              <p>CEDULA POSTERIOR</p>
              <a target="_blank" href={data.foto_cedula_trasera}>
                <img src={data.foto_cedula_trasera} width={200} />
              </a>
            </Grid>
            <Grid item xs={6}>
              <p>CEDULA PLANILLA/SENSO</p>
              <a target="_blank" href={data.foto_cliente_planilla}>
                <img src={data.foto_cliente_planilla} width={200} />
              </a>
            </Grid>
            <Grid item xs={6}>
              <p><strong>CLIENTE LETRERO</strong></p>
              <a target="_blank" href={data.foto_cliente_letrero}>
                <img src={data.foto_cliente_letrero} width={200} />
              </a>
            </Grid>
            <Grid item xs={6}>
              <p><strong>VIVIENDA CLIENTE</strong></p>
              <a target="_blank" href={data.foto_cliente_vivienda}>
                <img src={data.foto_cliente_vivienda} width={200} />
              </a>
            </Grid>
            {
              data.foto_carnet_discapacidad &&
              <Grid item xs={6}>
                <p>Foto carnet discapacidad</p>
                <img src={data.foto_carnet_discapacidad} style={{ width: "360px" }} />
              </Grid>
            }
          </Grid>
          <Box py={1}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="FECHA DE SERVICIO"
                  value={fecha}
                  onChange={(newValue) => {
                    setFecha(newValue);
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit()} variant="contained" disabled={!fecha || loading}>
            ASIGNAR FECHA DE SERVICIO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};