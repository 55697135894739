import {
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Paper,
    Chip,
    Stack,
    Select,
    MenuItem,
    FormControl,
    Collapse,
    DialogTitle,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Autocomplete,
    TextField,
    IconButton,
    InputAdornment
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { MostrarSeries } from "./MostrarSeries";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { ReporteStockPDF } from "./ReporteStockPDF";

import SearchIcon from '@mui/icons-material/Search';

export const ReporteStockHistorico = () => {

    const [copyCollectionReporteStock, setCopyCollectionReporteStock] = useState([]);

    const [copyDataReporteStock, setCopyDataReporteStock] = useState([]);

    const [selectedFecha, setSelectedFecha] = useState('');

    const [openDatesFilter, setOpenDatesFilter] = useState(false);

    const [item, setItem] = useState('')

    const getHistoryReportStock = async (e) => {
        console.log('e', e)

        const docRef = await db.collection("daily_copy_reporte_stock")

        docRef.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setCopyDataReporteStock(data)
            console.log(data)
        })

    }

    const cronReporteStock = async () => {

        const agendaDB2 = await db.collection("reporte_stock")

        agendaDB2.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setCopyCollectionReporteStock(data)
            console.log('data reporte stock', data)
        })

    }

    const dispatch = useDispatch()

    useEffect(() => {
        /* LoadData() */
        /* dataProduct() */
        setTotal()
        setSumaBodegas()
        setTotalValorizado()
        cronReporteStock()
        getHistoryReportStock()
    }, [])

    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState(0);
    const [dataPdf, setDataPdf] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    useEffect(() => {
        tabla()
    }, [data])

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    const LoadData = async (e) => {
        const docRef = await db.collection("daily_copy_reporte_stock").doc(e).collection('copy')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        /* const docRef = await db.collection("reporte_stock")
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
    };

    function CustomFooterTotalComponent(props) {
        return <div style={{ display: "inline-flex" }}>
            <Box sx={{ padding: "10px", display: "flex" }}>Total: {total}</Box>
            <Box sx={{ padding: "10px", display: "flex" }}>Stock Actual: {sumaBodegas}</Box>
            <Box sx={{ padding: "10px", display: "flex" }}>Total valorizado: {totalValorizado}</Box>
        </div>
    }

    const columns = [
        { field: 'codigo_producto', headerName: 'CODIGO', width: 140 },
        /* { field: 'bodega', headerName: 'BODEGA', width: 140 }, */
        {
            field: 'bodega', headerName: 'BODEGA', width: 200,
            renderCell: (params) => {
                const respuesta = params.row.bodega
                return respuesta.toUpperCase()
            }
        },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 520 },
        { field: 'categoria', headerName: 'SUBTIPO', width: 120 },
        { field: 'unidad_medida', headerName: 'UNIDAD MEDIDA', width: 140 },
        {
            field: '21', headerName: 'PRECIO COMPRA', width: 160,
            renderCell: (params) => {
                const respuesta = parseFloat(Math.abs(params.row.precio_compra))
                return respuesta.toLocaleString('en', { maximumFractionDigits: 2 })
            }
        },
        /* { field: 'precio_compra', headerName: 'PRECIO COMPRA', width: 130 }, */
        {
            field: '20', headerName: 'STOCK ACTUAL', width: 160,
            renderCell: (params) => {
                const respuesta = parseFloat(Math.abs(params.row.stock_actual))
                return respuesta.toLocaleString('en', { maximumFractionDigits: 2 })
            }
        },
        /* { field: 'stock_actual', headerName: 'STOCK ACTUAL', width: 130 }, */
        /* { field: 'fechaNacimiento', headerName: 'FLOTA', width: 150 }, */
        /* { field: 'costo_promedio', headerName: 'COSTO PROMEDIO', width: 150 }, */
        {
            field: '8', headerName: 'COSTO PROMEDIO', width: 160,
            renderCell: (params) => {
                const respuesta = parseFloat(params.row.costo_promedio)
                return `$${respuesta.toFixed(2)}`
            }
        },
        /* { field: '', headerName: 'MOVIMIENTO VALORIZADO', width: 110 }, */
        {
            field: '', headerName: 'TOTAL VALORIZADO', width: 160,
            renderCell: (params) => {
                const respuesta = parseFloat(Math.abs(params.row.total_valorizado))
                return `$${respuesta.toLocaleString('en', { maximumFractionDigits: 2 })}`
            }
        },
        {
            field: '2', headerName: 'MOSTRAR SERIES', width: 150,
            renderCell: (params) => {
                return <MostrarSeries props={params.row} />
            }
        },


    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarExport /> */}
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {

                    LOCALIDAD: doc.bodega,

                    CODIGO_PRODUCTO: doc.codigo_producto,

                    DESCRIPCION: doc.descripcion,

                    SUBTIPO: doc.categoria,

                    UNIDAD_MEDIDA: doc.unidad_medida,

                    STOCK: doc.stock_actual,

                    COSTO_PROMEDIO: doc.costo_promedio,

                    TOTAL: doc.total_valorizado,

                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "reporte_stock");
        XLSX.writeFile(wb, `REPORTE_STOCK_${date1}.xlsx`)
    }

    const [sumaBodegas, setSumaBodegas] = useState()

    const [totalValorizado, setTotalValorizado] = useState()

    const [totalReporteStock, setTotalReporteStock] = useState()

    const dataProduct = async (e) => {

        const docRef = await db.collection("daily_copy_reporte_stock").doc(e).collection('copy')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setData(arrayMessage)

            var datoSumaTotalBodegas;

            let suma = 0;
            for (let i = 0; i < arrayMessage.length; i++) {
                suma += parseFloat(arrayMessage[i]["stock_actual"])
                datoSumaTotalBodegas = suma
            }

            setSumaBodegas(parseFloat(suma).toLocaleString('en'))

            var saldoTotalValorizado;
            let sumaTotal = 0;
            for (let i = 0; i < arrayMessage.length; i++) {
                sumaTotal += parseFloat(arrayMessage[i]["total_valorizado"])
                setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))
                saldoTotalValorizado = sumaTotal
            }

            setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))

        }).catch((error) => {
            console.log("Error getting document:", error);
        });

        /* console.log('dataProduct')
    
        console.log(`TOTAL REPORTE STOCK ${data && data.length}`)
    
        const contadorTotal = data && data.length
    
        var datoSumaTotalBodegas;
    
        let suma = 0;
        for (let i = 0; i < contadorTotal.length; i++) {
          console.log(contadorTotal[i]["stock_actual"])
          suma += parseFloat(contadorTotal[i]["stock_actual"])
          datoSumaTotalBodegas = suma
        }
    
        setSumaBodegas(parseFloat(suma).toLocaleString('en'))
    
        var saldoTotalValorizado;
        let sumaTotal = 0;
        for (let i = 0; i < await contadorTotal.length; i++) {
          console.log(contadorTotal[i]["total_valorizado"])
          sumaTotal += parseFloat(contadorTotal[i]["total_valorizado"])
          setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en'))
          saldoTotalValorizado = sumaTotal
        }
    
        setTotalValorizado(parseFloat(sumaTotal).toLocaleString('en')) */

    }

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            exporter.current.save();
        }
    };

    const tabla = () => {
        let datos = []
        if (data) {
            data.map((item) => {
                datos.push({
                    unidad_medida: item.unidad_medida === undefined ? "No asignado" : item.unidad_medida,
                    codigo_producto: item.codigo_producto === undefined ? "No asignado" : item.codigo_producto,
                    bodega: item.bodega === undefined ? "No asignado" : item.bodega,
                    descripcion: item.descripcion === undefined ? "No asignado" : item.descripcion,
                    categoria: item.categoria === undefined ? "No asignado" : item.categoria,
                    total_valorizado: item.total_valorizado === undefined ? 0.00 : parseFloat(item.total_valorizado).toFixed(2),
                    costo_promedio: item.costo_promedio === undefined ? 0.00 : parseFloat(item.costo_promedio).toFixed(2),
                    stock_actual: item.stock_actual === undefined ? 0.00 : parseFloat(item.stock_actual).toFixed(2),
                    precio_compra: item.precio_compra === undefined ? 0.00 : parseFloat(item.precio_compra).toFixed(2),
                    total_valorizado2: item.total_valorizado === undefined ? 0.00 : Number(parseFloat(item.total_valorizado).toFixed(2)),
                    costo_promedio2: item.costo_promedio === undefined ? 0.00 : Number(parseFloat(item.costo_promedio).toFixed(2)),
                    stock_actual2: item.stock_actual === undefined ? 0.00 : Number(parseFloat(item.stock_actual).toFixed(2)),
                    precio_compra2: item.precio_compra === undefined ? 0.00 : Number(parseFloat(item.precio_compra).toFixed(2)),
                })
            })
            setDataPdf(datos)
        }
    };

    const createDailyCopyReporteStock = async () => {

        const agendaDB4 = await db.collection("reporte_stock").get();

        const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        console.log('data4', data4)

        /* const currentDate = new Date();
    
        // Días de la semana y meses en español
        const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    
        // Obtiene el nombre del día, día del mes, nombre del mes y año
        const nombreDia = diasSemana[currentDate.getDay()];
        const diaMes = currentDate.getDate();
        const nombreMes = meses[currentDate.getMonth()];
        const anio = currentDate.getFullYear();
    
        // Construye el string con el formato deseado
        const fechaFormateada = `${nombreDia} ${diaMes} de ${nombreMes} del ${anio}`;
    
        // Imprime la fecha formateada por consola
        console.log('Fecha actual:', fechaFormateada);
    
        await db.collection('daily_copy_reporte_stock').doc(fechaFormateada).set({
          id_copia: fechaFormateada,
          fecha_copia: firebase.firestore.FieldValue.serverTimestamp(),
        })
    
        copyCollectionReporteStock.forEach(async (obj, index) => {
          console.log('obj', obj)
          console.log('obj.id', obj.id)
          console.log('index', index)
    
          const dataRef = {
            ...obj
          }
    
          await db.collection('daily_copy_reporte_stock').doc(fechaFormateada).collection('copy').doc(obj.id).set(dataRef)
        }) */
    }

    const functionSelectedDay = async (e) => {
        console.log('e', e)
        setSelectedFecha(e)
        LoadData(e)
        dataProduct(e)
        setOpenDatesFilter(false)
    }

    return (
        <>

            <Box pb={1} pl={2}>

                <FormControl>
                    <p style={{ fontSize: "14px", marginBottom: '0px', fontWeight: 'bold' }}>SELECCIONE UNA FECHA</p>

                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={selectedFecha}
                        /* onChange={(e) => setSolicitudDePedido(e.target.value)} */
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => setOpenDatesFilter(true)}>
                                    <SearchIcon color='primary' />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />

                    {/* <Select sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        size="small"
                        value={selectedFecha}
                        onChange={(e) => {
                            setSelectedFecha(e.target.value)
                            LoadData(e.target.value)
                            dataProduct(e.target.value)
                        }}>
                        {copyDataReporteStock &&
                            copyDataReporteStock.map(item => (
                                <MenuItem key={item.id_copia} value={item.id_copia} >
                                    {item.id_copia}
                                </MenuItem>
                            ))}
                    </Select> */}
                </FormControl>
            </Box>

            <Box pt={0} pl={1}>
                <Typography variant="button" >

                    <Typography >
                        <strong>REPORTE STOCK HISTORICO</strong>
                    </Typography >
                </Typography >
            </Box>
            <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    {/* <Stack direction="row">
  
              <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
              >
                {" "}
                DESCARGAR EXCEL
              </Button>
            </Stack > */}

                    <Stack direction="row" spacing={2}>

                        <Button onClick={excelExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                        >
                            {" "}
                            EXCEL
                        </Button>
                        <Button onClick={() => setOpen2(true)} size="small" color="error" variant="outlined" startIcon={<PictureAsPdfIcon />}
                        >
                            {" "}
                            PDF
                        </Button>
                    </Stack >

                    <ExcelExport data={dataPdf} fileName="REPORTE STOCK.xlsx" ref={exporter} filterable={true} >
                        <ExcelExportColumn field="codigo_producto" title="CODIGO" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="bodega" title="BODEGA" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="descripcion" title="DESCRIPCION" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="categoria" title="SUBTIPO" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="unidad_medida" title="UNIDAD MEDIDA" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="precio_compra2" title="PRECIO COMPRA" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="stock_actual2" title="STOCK ACTUAL" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="costo_promedio2" title="COSTO PROMEDIO" headerCellOptions={{ background: "#672780" }} />
                        <ExcelExportColumn field="total_valorizado2" title="TOTAL VALORIZADO" headerCellOptions={{ background: "#672780" }} />
                    </ExcelExport>

                    <Dialog open={open2} fullWidth onClose={() => setOpen2(false)} maxWidth="xl">

                        <DialogContent>
                            <ReporteStockPDF contenido={dataPdf} />
                        </DialogContent>
                        <DialogActions>
                            <Button size="small" variant="contained" onClick={() => setOpen2(false)}><strong>Regresar</strong></Button>
                        </DialogActions>
                    </Dialog>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box pt={1}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box>

            <Paper>
                <Box sx={{ height: 690, width: '100%' }}>
                    <DataGrid
                        density="compact"
                        rows={data ? data : []}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        // disableColumnFilter
                        // disableColumnSelector
                        // disableDensitySelector
                        //hideFooterPagination
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}

                        onStateChange={(state) => {
                            setTotal(state.pagination.rowCount)
                        }}
                    />
                </Box>
            </Paper>

            {/* MODAL DATES HISTORY */}

            <Dialog
                open={openDatesFilter}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    BUSCAR FECHAS
                </DialogTitle>

                <DialogContent dividers>
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={copyDataReporteStock}
                            clearOnBlur={false}
                            autoComplete={false}
                            disableClearable
                            getOptionLabel={(option) => option.id_copia}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item)
                                setSelectedFecha(newInputValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="BUSCAR" />}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>N</TableCell>
                                    <TableCell>FECHA</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {copyDataReporteStock && copyDataReporteStock
                                    .filter((val) => {
                                        if (item === "") {
                                            return val;
                                        } else if (val.id_copia.toLowerCase().includes(item.toLowerCase())) {
                                            return val;
                                        }
                                    }).map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{index}</TableCell>
                                            <TableCell>{row.id_copia}</TableCell>
                                            <TableCell><Button
                                                size='small'
                                                variant='contained'
                                                color='success'
                                                /* onChange={(e) => {
                                                    setSelectedFecha(e.target.value)
                                                    LoadData(e.target.value)
                                                    dataProduct(e.target.value)
                                                    setOpenDatesFilter(false)
                                                }} */
                                                onClick={(e) => functionSelectedDay(row.id)}
                                            /* onClick={(e) =>console.log(e)} */
                                            >Seleccionar</Button></TableCell>
                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <DialogActions dividers>
                    <Button onClick={() => setOpenDatesFilter(false)}>
                        Cerrar ventana
                    </Button>
                </DialogActions>

            </Dialog>

        </>

    );
};