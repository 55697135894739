import React, { useEffect, useState } from "react"
import { Box, Typography, CardContent, Card, LinearProgress, Grid, Container, Chip, Button, CardHeader } from "@mui/material"
import "react-datepicker/dist/react-datepicker.css"
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { auth, db, firebase } from "../../firebase";
import { listClientes } from "../../redux/clientes/clientesActions";
import { ViewPdfClient } from "../../components/clientes/ViewPdfClient";
function Estado({ estado }) {
    let color
    let label
    if (estado === 1) {
        color = "success"
        label = 'ACTIVO'
    } else if (estado === 0) {
        color = "warning"
        label = 'SUSPENDIDO'
    } else if (estado === 3) {
        color = "error"
        label = 'RETIRADO'
    }
    return <Chip variant='outlined' size='small' label={label} color={color} />
}
export const UsuariosPage = () => {
    const userAuth = useSelector((state) => state.userAuth)
    const { loading, userInfo } = userAuth
    const clientesList = useSelector((state) => state.clientesList)
    // const { clientes } = clientesList
    const [clientes, setClientes] = useState([]);
    const [data, setData] = useState();
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 518400000))
    const [endDate, setEndDate] = useState(new Date())
    //
    const dispatch = useDispatch()
    useEffect(() => {
        if (clientes.length === 0) {
            // dispatch(listClientes())
            LoadData()
        }
    }, [])
    //
    const LoadData = () => {
        let ref = db.collection("clientes").orderBy('created', 'desc')

        // let ref = db.collection("usuarios").orderBy('created', 'desc')
        // if (endDate && startDate) {
            // startDate.setHours(0, 0, 0, 0);
            // endDate.setHours(23, 59, 0, 0);
            // var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            // var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            // ref = ref.where("created", ">=", startfulldate);
            // ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            //if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
            ref.get().then((querySnapshot) => {
                const data = []
                querySnapshot.docs.map((doc) => {
                    // if (doc.data().foto_router_casa && doc.data().foto_router_casa.includes('http')) {
                        data.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    // }
                })
                // setData(data)
                setClientes(data)
            });
        // }
    }
    // 
    const csvOptions = {
        delimiter: ';',
        fileName: `CLIENTES SOEI ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    }
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    )
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    )

    const columns = [
        // { field: 'idcliente', headerName: 'ID', width: 100 },
        { field: 'cedula', headerName: 'CEDULA', width: 100 },
        {
            field: 'nombres', headerName: 'NOMBRES', width: 300,
            renderCell: (params) => {
                return <RouterLink to={`/clientes/usuarios/${params.row.id}/`}>{params.row.nombres}</RouterLink>
            }
        },
        // {
        //     field: '', headerName: 'ESTADO', width: 100,
        //     renderCell: (params) => {
        //         return <Estado estado={params.row.estado} />
        //     }
        // },
        { field: 'numContrato', headerName: 'NUM DE CONTRATO', width: 225 },
        { field: 'planNombre', headerName: 'PLAN NOMBRE', width: 300 },
        { field: 'celular', headerName: 'CELULAR', width: 150 },
        { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
        { field: 'numbRef', headerName: 'NUM. REF', width: 150 },

        { field: 'canton', headerName: 'CANTON', width: 200 },
        { field: 'zona', headerName: 'ZONA', width: 200 },
        { field: 'sector', headerName: 'SECTOR', width: 200 },
        { field: 'direccion', headerName: 'DIRECCION', width: 400 },
        {
            field: 'pdf', headerName: 'DOCUMENTOS', width: 175,
            renderCell: (params) => {
                return <ViewPdfClient props={params.row} />
            }
        },
    ]

    return (
        <>
            <Container maxWidth="lx">
                {/* <Box pt={1}>
                    <Typography variant="button" >
                        <Typography>
                            <strong>CLIENTES</strong>
                        </Typography>
                    </Typography >
                    <Typography variant="button" >
                        <p style={{ fontSize: "20px", paddingBottom: "0px" }}>
                            TOTAL DE CLIENTES YIGA5 <strong>53.685</strong>
                        </p>
                    </Typography >
                </Box> */}
                <Card>
                    <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader='LISTADO CLIENTES' />
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            // slots={{
                            //     loadingOverlay: LinearProgress,
                            // }}
                            // loading={clientes.length === 0}
                            rows={clientes ? clientes : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            getRowId={(row) => row.id}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                            onStateChange={(state) => {
                            }}
                        />
                    </Box>
                </Card>
                {/* <Box py={1}></Box>


                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Card style={{ backgroundColor: 'Green', color: 'white' }}>
                            <CardContent>
                                <Typography variant="h5"><strong>Clientes Activos</strong> </Typography>
                                <Typography variant="h6"><strong>53.685</strong></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card style={{ backgroundColor: 'DarkOrange', color: 'white' }}>
                            <CardContent>
                                <Typography variant="h5"><strong>Clientes Suspendidos</strong> </Typography>
                                <Typography variant="h6"><strong>6.234</strong></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4}>
                        <Card style={{ backgroundColor: 'Gray', color: 'white' }}>
                            <CardContent>
                                <Typography variant="h5"><strong>Clientes Retirados</strong> </Typography>
                                <Typography variant="h6"><strong>7.011</strong></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> */}
            </Container>
        </>
    )
}
