export const departamentoReducers = (state = { departamento: [] }, action) => {
    switch (action.type) {
        case 'DEPARTAMENTO_LIST_REQUEST':
            return { loading: true }
        case 'DEPARTAMENTO_LIST_SUCCESS':
            return { loading: false, departamento: action.payload }
        case 'DEPARTAMENTO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const cargoReducers = (state = { cargo: [] }, action) => {
    switch (action.type) {
        case 'CARGO_LIST_REQUEST':
            return { loading: true }
        case 'CARGO_LIST_SUCCESS':
            return { loading: false, cargo: action.payload }
        case 'CARGO_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}