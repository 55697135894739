import React, { useState } from 'react'
import { Tabs, Box, Tab,  Container, Card } from '@mui/material';
import { NuevoProveedor } from './NuevoProveedor.js';
import { ListProveedores } from './ListProveedores';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const ProveedoresPage = () => {
    const [tab, setTab] = useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    return (
        <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
            <Card>
                <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                    <Tabs value={tab} onChange={handleChange}>
                        <Tab label="Nuevo Proveedor" />
                        <Tab label="Lista Proveedores" />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <NuevoProveedor />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ListProveedores />
                    </TabPanel>
                </Box>
            </Card>
        </Container>
    )
}