import {
    Button, Box, Dialog, DialogContent, TableContainer, Table, TableHead,
    Paper, TableBody, TextField, IconButton, Card,
    TableRow, TableCell, DialogActions, DialogTitle, Grid,
} from "@mui/material";
import React, { useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Clear'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db, firebase } from "../../../../firebase";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import Swal from "sweetalert2";
export const NuevoRubroLibre = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { client_details } = clientDetails
    const input = [
        {
            cant: 1,
            descripcion: '',
            imp: 12,
            precio: 0,
            total: 0,
            iva: 0,
            subtotal: 0
        }
    ]
    var current = new Date()
    const params = useParams()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [subtotal, setSubtotal] = useState(0)
    const [impuesto, setImpuesto] = useState(0)
    const [total, setTotal] = useState(0)
    const [fechaVencimiento, setFechaVencimiento] = useState(new Date())
    const [inputFields, setInputFields] = useState(input)
    function detalleMate(data, index) {
        return (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                    <TextField
                        name={"descripcion"}
                        fullWidth
                        onChange={(e) => handleChange(index, e)}
                        value={data.descripcion}
                        size='small'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={125}>
                    <TextField
                        name={"precio"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.precio}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    <TextField
                        name={"imp"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.imp}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    <TextField
                        name={"cant"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cant}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center" width={100}>
                    ${parseFloat(data.precio).toFixed(2)}
                </TableCell>
                <TableCell align="center" width={5}>
                    {index !== 0 &&
                        <IconButton onClick={() => removeInputFields(index)}>
                            <CloseIcon />
                        </IconButton>}
                </TableCell>
            </TableRow>
        )
    }
    function sumar(row) {
        let iva = 0
        let subtotal = 0
        let total = 0
        for (let i = 0; i < row.length; i++) {
            const elemento = row[i]
            subtotal += parseFloat(elemento.subtotal)
            iva += parseFloat(elemento.iva)
            total += parseFloat(elemento.total)
        }
        const subtotalOperacion = parseFloat(subtotal).toFixed(2) - parseFloat(iva).toFixed(2)
        setTotal(subtotal)
        setImpuesto(iva)
        setSubtotal(subtotalOperacion)
    }
    const handleChange = (index, evnt) => {
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target
                const list = [...inputFields]
                list[index][name] = value
                var precio = list[index]['precio']
                var imp = list[index]['imp']
                var cantidad = list[index]['cant']
                var iva = precio * (imp / 112.2)
                var total = parseInt(precio) + iva

                list[index]['iva'] = iva * cantidad
                list[index]['subtotal'] = precio * cantidad
                list[index]['total'] = total * cantidad
                setInputFields(list)
                sumar(list)
            } else {
                const list = [...inputFields]
                list[index]['fullName'] = evnt
                setInputFields(list)
            }

        }
    }
    const removeInputFields = (index) => {
        const rows = [...inputFields]
        rows.splice(index, 1)
        setInputFields(rows)
        sumar(rows)
    }
    const addInput = () => {
        setInputFields([...inputFields, {
            descripcion: '',
            precio: 0,
            imp: 12,
            cant: 1,
            total: 0,
            iva: 0,
            subtotal: 0
        }])
    }
    const crearFacturaLibre = async () => {
        //console.log('inputFields', inputFields)
        //console.log('subtotal', subtotal)
        //console.log('total', total)
        //console.log('impuesto', impuesto)
        //console.log('params', params)
        //console.log('params', params.id)
        setLoading(true)
        //Creamos el Rubro
        db.collection('rubros').orderBy("created", "desc").limit(1).get()
            .then((rubrosDb) => {
                var id = 1
                if (rubrosDb.size > 0) {
                    const rubros = rubrosDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    const rubro = rubros[0]
                    id = rubro.numero_rubro + 1
                }
                //Creamos Rubro General
                const rubro = {
                    uid: id,
                    tipo: 'LIBRE',
                    estado: 0,
                    numero_rubro: id,
                    id_rubro: id,
                    total_pagado: 0,
                    fecha_emision: firebase.firestore.FieldValue.serverTimestamp(),
                    fecha_vencimiento: fechaVencimiento,
                    impuesto: parseFloat(impuesto),
                    total: parseFloat(total),
                    subTotal: parseFloat(subtotal),
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                    nombre: client_details.nombres,
                    cedula: client_details.cedula,
                    direccion: client_details.direccion,
                    telefono: client_details.telefono,
                    correo: client_details.correo,
                    idcliente: client_details.idcliente,
                }
                //console.log(rubro, ' data rubro')
                db.collection('rubros').doc(`${id}`).set(rubro).then(() => {
                    //Dupliamos el Rubro para el Cliente
                    db.collection('clientes').doc(params.id).collection('rubros').doc(`${id}`).set(rubro).then(() => {
                        //Dejamos los servicios vincualos con ese rubro
                        inputFields.forEach((obj) => {
                            console.log('obj', obj)
                            db.collection(`clientes/${params.id}/rubros/${id}/items`).doc(obj.descripcion).set({
                                descripcion: obj.descripcion,
                                precio: obj.precio,
                                impuesto: obj.iva,
                                cantidad: obj.cant,
                                total: parseFloat(obj.total),
                                subTotal: obj.subtotal,
                                iva: obj.imp,
                                created: firebase.firestore.FieldValue.serverTimestamp(),
                                id: obj.descripcion,
                                nombre: client_details.nombres,
                                cedula: client_details.cedula,
                                direccion: client_details.direccion,
                                telefono: client_details.telefono,
                                correo: client_details.correo
                            })
                        })
                        //Reniciamos los compos
                        setInputFields(input)
                        setSubtotal(0)
                        setImpuesto(0)
                        setTotal(0)
                        setOpen(false)
                        Swal.fire({ icon: "success", text: "Se ha creado el rubro libre con exito", showConfirmButton: false })
                        setLoading(false)
                    }).catch((e) => {
                        console.log(e, 'error: 2')
                        setLoading(false)
                    })
                }).catch((e) => {
                    console.log(e, 'error: 1')
                    setLoading(false)
                })
            }).catch((e) => {
                console.log(e, 'error: 0')
                setLoading(false)
            })
    }
    return (
        <>
            <Button
                size="small"
                color="secondary"
                variant='outlined'
                onClick={() => setOpen(true)}
            >Nuevo Rubro Libre
            </Button>
            <Dialog
                maxWidth='md'
                open={open}
                fullWidth
            >
                <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>NUEVO RUBRO LIBRE
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box py={2}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} size='small' />}
                                        label="FECHA VENCIMIENTO"
                                        inputFormat="dd/MM/yyyy"
                                        value={fechaVencimiento}
                                        onChange={(newValue) => {
                                            setFechaVencimiento(newValue);
                                        }}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box py={2}>
                                    <DatePicker
                                        disabled
                                        renderInput={(props) => <TextField {...props} size='small' />}
                                        label="FECHA EMISION"
                                        inputFormat="dd/MM/yyyy"
                                        value={new Date()}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Card>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Descripcion</TableCell>
                                        <TableCell align="center">Precio</TableCell>
                                        <TableCell align="center">Imp%</TableCell>
                                        <TableCell align="center">Cant.</TableCell>
                                        <TableCell align="center">Total</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputFields.map((data, index) => {
                                        return detalleMate(data, index)
                                    })}
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={5}>
                                        </TableCell>
                                        <TableCell align="center" width={5}>
                                            <IconButton onClick={() => addInput()}>
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3}>
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            SUBTOTAL
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            ${subtotal.toFixed(2)}
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3}>
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            IMPUESTO
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            ${impuesto.toFixed(2)}
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={3}>
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            TOTAL
                                        </TableCell>
                                        <TableCell align="center" width={100}>
                                            ${total.toFixed(2)}
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>
                    <Box pr={1}>
                        <Button
                            disabled={loading === true}
                            variant='contained'
                            onClick={() => crearFacturaLibre()}
                        >Crear rubro</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};