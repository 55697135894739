
import { Tabs, Typography, Box, Tab, Container, Card } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { SolicitudMarketingAprobadas } from './SolicitudMarketingAprobadas';
import { SolicitudMarketingEspera } from './SolicitudMarketingEspera';
import { useParams } from 'react-router-dom';
import { RequerimientosMarketing } from './RequerimientosMarketing';
import { SolicitudMarketingAsignado } from './SolicitudMarketingAsignado';
import { SolicitudMarketingRevision } from './SolicitudMarketingRevision';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const SolicitudMarketingPage = () => {
  const params = useParams()
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (params.id) {
      setValue(parseInt(params.id))
    }
  }, [params.id])
  return (
    <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1">REQUERIMIENTOS MARKETING/<strong>SOLICITUD</strong></Typography>
      </Box>
      <Card>
        <Tabs value={value} onChange={handleChange} >
          <Tab label="Nueva Solicitud" {...a11yProps(0)} />
          <Tab label="Solicitudes En Espera" {...a11yProps(1)} />
          <Tab label="Solicitudes Asignadas" {...a11yProps(2)} />
          <Tab label="Solicitudes En Revisión" {...a11yProps(3)} />
          <Tab label="Solicitudes Cerradas" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <RequerimientosMarketing />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SolicitudMarketingEspera />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SolicitudMarketingAsignado />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SolicitudMarketingRevision />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SolicitudMarketingAprobadas />
        </TabPanel>
      </Card>
    </Container >
  )
}