import {
    Container,
    TextField,
    Button,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Box,
    Paper,
    Chip,
    Grid
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db, firebase } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { DatePicker, TimePicker } from "@mui/x-date-pickers";

export const UpdateFechaCorte = ({ props }) => {
    const [open, setOpen] = useState(false);

    const [diaCorte, setDiaCorte] = useState(moment(new Date(props.dia_de_corte.seconds * 1000).toISOString()).format('DD/MM/YYYY'));

    const [descripcion, setDescripcion] = useState(props.descripcion);

    const [diaMaximoPago, setDiaMaximoPago] = useState(props.dia_maximo_pago);

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
    }, []);

    const [nombre, setNombre] = useState('')
    const [ubicacion, setUbicacion] = useState('');

    const handleSubmit = async () => {

        await db
            .collection("fechas_de_corte").doc(props.id).update({
                dia_de_corte: diaCorte,
                descripcion: descripcion,
                dia_maximo_pago: diaMaximoPago,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                Swal.fire({ icon: "success", title: "Fecha de corte creada con exito" });
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    };

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => setOpen(true)} color='secondary'>
                    <EditIcon />
                </Button>
            </Tooltip>


            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>EDICION DE FECHA DE CORTE</DialogTitle>

                <DialogContent>
                    <Paper elevation={3}>
                        <Box py={2} px={2}>
                            <Grid container spacing={7}>
                                <Grid item xs={10} md={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FECHA DE CORTE:</strong></p>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <FormControl fullWidth>
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                label="Seleccione"
                                                value={diaCorte}
                                                onChange={(newValue) => {
                                                    setDiaCorte(newValue);

                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>

                                    <Box py={4}>

                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DESCRIPCION:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={descripcion}
                                            onChange={(e) => setDescripcion(e.target.value)}
                                            name="zona"
                                            size="small"
                                            fullWidth
                                        />

                                    </Box>
                                </Grid>


                                {/* *********************************** */}
                                <Grid item xs={10} md={6}>

                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>DIA MAXIMO DE PAGO:</strong></p>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <FormControl fullWidth>

                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} size="small" />}
                                                label="Seleccione"
                                                value={diaMaximoPago}
                                                onChange={(newValue) => {
                                                    setDiaMaximoPago(newValue);
                                                }}
                                            />
                                        </FormControl>
                                    </LocalizationProvider>

                                </Grid>

                            </Grid>
                        </Box>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="warning" size="small" onClick={() => setOpen(false)}>Regresar</Button>
                    <Button onClick={() => handleSubmit()}
                        disabled={diaCorte === ''
                            || descripcion === ''
                            || diaMaximoPago === ''}
                        variant="contained"
                        color="secondary"
                        size="small"
                    >ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>



        </>
    );
};
