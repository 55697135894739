import { db, firebase, auth, functions } from '../../../firebase'
import Swal from 'sweetalert2';
//Crear Solicitud de Servicio
export const createSolicitud = (formData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'SOLICITUD_CREATE_REQUEST' })
        await db.collection("solicitudes").orderBy("created", "desc").limit(1).get()
            .then((solicitudesDb) => {
                let numeroSolicitud = 1
                if (solicitudesDb.size > 0) {
                    const solicitudes = solicitudesDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    const solicitud = solicitudes[0]
                    numeroSolicitud = solicitud.numeroSolicitud + 1;
                } else {
                    numeroSolicitud = 1
                }
                const uidSolicitud = `SO-SER-` + numeroSolicitud
                db.collection('solicitudes').doc(uidSolicitud).set({
                    ...formData,
                    uid: uidSolicitud,
                    linea: formData.linea,
                    numeroSolicitud: numeroSolicitud,
                    user_uid: auth.currentUser.uid,
                    cedula: formData.cedula,
                    nombres: formData.nombres,
                    direccion: formData.direccion,
                    nacionalidad: formData.nacionalidad,
                    fechaNacimiento: formData.fechaNacimiento,
                    telefono: formData.telefono,
                    celular: formData.celular,
                    correo: formData.correo,
                    numCam: formData.numCam,
                    contrato: formData.contrato,
                    observacion: formData.observacion,
                    coordenadas: new firebase.firestore.GeoPoint(
                        formData.coordenadas.latitude,
                        formData.coordenadas.longitude
                    ),
                    camara: formData.camara,
                    planNombre: formData.planNombre,
                    planValor: formData.planValor,
                    estado: 0,
                    provincia: formData.provincia,
                    canton: formData.canton,
                    parroquia: formData.parroquia,
                    sector: formData.sector,
                    cargo: formData.cargo,
                    vendedor: formData.vendedor,

                    created: firebase.firestore.FieldValue.serverTimestamp()
                }).then((docRef) => {
                    navigate("/gestion_post_ventas/solicitude_de_servicio/1")
                    Swal.fire({ icon: "success", text: 'Solicitud enviada' })
                    dispatch({
                        type: 'SOLICITUD_CREATE_SUCCESS',
                        payload: docRef,
                    })
                }).catch((e) => {
                    alert(e)
                    console.error(e)
                })
            }).catch((e) => {
                alert(e)
            })
    } catch (e) {
        alert(e)
        dispatch({
            type: 'SOLICITUD_CREATE_FAIL',
            payload: e
        })
    }
}

export const listSolicitudes = (startAfterDoc, persistProducts = []) => async (dispatch, getState) => {
    try {
        const {
            userAuth: { userInfo },
        } = getState()

        dispatch({ type: 'SOLICITUD_LIST_REQUEST' })
        let ref = db.collection("solicitudes").orderBy('created', 'desc').limit(20)
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistProducts,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            dispatch({
                type: 'SOLICITUD_LIST_SUCCESS',
                payload: data,
                queryDoc: snapshot.docs[totalCount - 1],
                isLastPage: totalCount < 1
            })
        })

    } catch (error) {
        dispatch({
            type: 'SOLICITUD_LIST_FAIL',
            payload: error
        })
    }
}
export const listSolicitudesAprobadas = (startAfterDoc, persistProducts = []) => async (dispatch, getState) => {
    try {
        const {
            userAuth: { userInfo },
        } = getState()
        console.log('Usuario id: ', auth.currentUser.uid)
        dispatch({ type: 'SOLICITUD_APROBADA_LIST_REQUEST' })
        let ref = db.collection("solicitudes").orderBy('created', 'desc').where("estado", "==", 2).limit(30)
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('user_uid', '==', auth.currentUser.uid)
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistProducts,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            dispatch({
                type: 'SOLICITUD_APROBADA_LIST_SUCCESS',
                payload: data,
                queryDoc: snapshot.docs[totalCount - 1],
                isLastPage: totalCount < 1
            })
        })

    } catch (error) {
        dispatch({
            type: 'SOLICITUD_APROBADA_LIST_FAIL',
            payload: error
        })
    }
}
export const sendCodeOTP = (newData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'SEND_CODE_REQUEST' })
        if (newData) {
            const enviarCodigo = functions.httpsCallable('enviarCodigoOTP');
            enviarCodigo(newData)
                .then(res => {
                    dispatch({ type: 'SEND_CODE_SUCCESS' })
                    console.log('se envio el codigo', res)

                })
                .catch(error => Swal.fire({ icon: "error", text: `Capa 2 ${error}` }))
        }

    } catch (error) {
        Swal.fire({
            icon: "error",
            text: `Capa 1 ${error}`
        });
        dispatch({
            type: 'SEND_CODE_FAIL',
            payload: error
        })
    }
}
export const submitCodeOTP = (solicitud, codigoOTP, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CODIGO_OTP_SUBMIT_REQUEST' })
        const codigoDB = await db.collection('codigoOTP').doc(solicitud.uid)
            .onSnapshot((querySnapshot) => {
                const data = querySnapshot.data(item => { return { ...item.data(), id: item.id } })
                console.log('Datos', data)
                const data_created = data.created.toDate().toString()
                const date = new Date()
                var past = new Date(data_created).getTime();
                var fiveMin = 1000 * 60 * 5;
                //Verificar si el codigo caduco
                //if (new Date().getTime() - past < fiveMin) {
                console.log(data.codigoOTP, parseInt(codigoOTP))
                if (data.codigoOTP === parseInt(codigoOTP)) {
                    //Enviar correo de confirmacion\
                    db.collection("pre_ventas").orderBy("created", "desc").limit(1).get()
                        .then((preventasDb) => {
                            if (preventasDb.size > 0) {
                                const preventas = preventasDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                                console.log(preventas, 'ULTIMA PREVENTA')
                                const preventa = preventas[0]
                                console.log(preventa, 'preventa')
                                //
                                const numeroSolicitud = preventa.numeroContrato + 1;
                                //const uidContrato = solicitud.provincia `-` + 1 + contrato;
                                const contrato = solicitud.contrato.substring(0, 3)
                                const uidContrato = solicitud.canton + `-` + solicitud.linea + `-` + numeroSolicitud + '-' + contrato;
                                console.log(uidContrato)
                                db.collection("pre_ventas").doc(uidContrato).set({
                                    ...solicitud,
                                    numeroContrato: numeroSolicitud,
                                    numContrato: uidContrato,
                                    created: firebase.firestore.FieldValue.serverTimestamp(),
                                }).then(() => {
                                    db.collection("agenda").doc(uidContrato).set({
                                        ...solicitud,
                                        numeroContrato: numeroSolicitud,
                                        numContrato: uidContrato,
                                        estado: 0,
                                        created: firebase.firestore.FieldValue.serverTimestamp(),
                                    }).then((docRef) => {
                                        //Cambiar estado 
                                        db.collection("solicitudes").doc(solicitud.uid).update({
                                            estado: 3
                                        })
                                        //Crear usuario en SOEI

                                        dispatch({
                                            type: 'CODIGO_OTP_SUBMIT_SUCCESS',
                                            payload: docRef
                                        })
                                        navigate("/ventas/ventas-pre-aprobadas/")
                                        Swal.fire({
                                            icon: "success",
                                            text: `Su preventa ha sido realizada con éxito, recuerde revisar su ventana de ventas pre aprobada.`
                                        })

                                        if (solicitud.nombres.includes('DESARROLLO')) {
                                            console.log('funcion de prueba')
                                            //Crear usuario
                                            var myHeaders = new Headers();
                                            myHeaders.append("Content-Type", "application/json");
                                            var raw = JSON.stringify({
                                                "cedula": solicitud.cedula,
                                                "nombres": solicitud.nombres,
                                                "direccion": solicitud.direccion,
                                                "provincia": solicitud.provincia,
                                                "canton": solicitud.canton,
                                                "sector": solicitud.sector,
                                                "zona": solicitud.zona,
                                                "parroquia": solicitud.parroquia,
                                                "coordenadas": `${solicitud.coordenadas._lat},${solicitud.coordenadas._long}`,
                                                "telefono": solicitud.telefono,
                                                "celular": solicitud.celular,
                                                "whatapp": solicitud.whatapp,
                                                "numReferencia": solicitud.numbRef,
                                                "estado": "1",
                                                "cuentabancaria": solicitud.numBanco,
                                                "banco": solicitud.banco,
                                                "tipobanco": "111",
                                                "fechanacimiento": solicitud.fechaNacimiento,
                                                "accededebitobancario": "111",
                                                "codigopago": "10000s",
                                                "gradodiscapacidad": "111",
                                                "gradodiscapacitado": "111",
                                                "numContrato": solicitud.numContrato,
                                                "obtieneCamara": solicitud.camara,
                                                "cantidadCamara": solicitud.numCam,
                                                "email": solicitud.correo,
                                                "vendedor": solicitud.vendedor,
                                            })
                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: raw
                                            }
                                            fetch("http://192.168.90.24:9000/crearCliente", requestOptions)
                                                .then(response => response.text())
                                                .then(result => {
                                                    console.log(result, 'se creo usuariom')
                                                })
                                                .catch(error => console.log('error', error))
                                        }
                                    }).catch((error) => {
                                        Swal.fire({
                                            icon: "Error",
                                            text: error
                                        });
                                        console.log('Error agenda', error)
                                    })
                                }).catch((error) => {
                                    Swal.fire({
                                        icon: "Error",
                                        text: error
                                    });
                                    console.log('Error al crear agenda', error)
                                })

                            } else {
                                console.log(solicitud, 'preventa')
                                const contrato = solicitud.contrato.substring(0, 3)

                                const uidContrato = solicitud.canton + `-` + 1 + '-' + contrato;
                                db.collection("pre_ventas").doc(uidContrato).set({
                                    ...solicitud,
                                    numeroContrato: 1,
                                    numContrato: uidContrato,
                                    created: firebase.firestore.FieldValue.serverTimestamp(),
                                }).then(() => {
                                    db.collection("agenda").doc(uidContrato).set({
                                        ...solicitud,
                                        numeroContrato: uidContrato,
                                        numContrato: uidContrato,
                                        estado: 0,
                                        created: firebase.firestore.FieldValue.serverTimestamp(),
                                    }).then(() => {

                                        console.log('Se creo un agenda')
                                        const enviarCorreo = functions.httpsCallable('enviarContrato');
                                        enviarCorreo(solicitud)
                                            .then(res => {
                                                navigate("/ventas/ventas-pre-aprobadas/")
                                                dispatch({
                                                    type: 'CODIGO_OTP_SUBMIT_SUCCESS',
                                                    payload: res
                                                })
                                                Swal.fire({
                                                    icon: "success",
                                                    text: `Su preventa ha sido realizada con éxito, recuerde revisar su ventana de ventas pre aprobada`
                                                });
                                            })
                                            .catch(error => console.log(error))
                                    }).catch((error) => {
                                        Swal.fire({
                                            icon: "Error",
                                            text: error
                                        });
                                        console.log('Error al crear agenda', error)
                                    })
                                }).catch((error) => {
                                    Swal.fire({
                                        icon: "Error",
                                        text: error
                                    });
                                    console.log('Erro al crear venta', error)
                                })

                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: "Error",
                                text: error
                            });
                            console.error("No se puedo crear el id codigo de la solicitud: ", error);
                        });
                } else {
                    console.log('Codigo incorrecto')
                    Swal.fire({ icon: "info", text: 'Codigo de confirmacion no valido.', });
                    dispatch({
                        type: 'CODIGO_OTP_SUBMIT_SUCCESS',
                        payload: data
                    })
                }
            })
    } catch (error) {
        Swal.fire({
            icon: "error",
            text: error
        });
        dispatch({
            type: 'CODIGO_OTP_SUBMIT_FAIL',
            payload: error
        })
    }
}
//Agendar con cambio de domicilio

export const submitDomicilio = (solicitud, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CODIGO_OTP_SUBMIT_REQUEST' })
        //Enviar correo de confirmacion\
        db.collection("pre_ventas").orderBy("created", "desc").limit(1).get()
            .then((preventasDb) => {
                if (preventasDb.size > 0) {
                    const preventas = preventasDb.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                    const preventa = preventas[0]

                    //
                    const numeroSolicitud = preventa.numeroContrato + 1;

                    //const uidContrato = solicitud.provincia `-` + 1 + contrato;
                    const contrato = solicitud.contrato.substring(0, 3)
                    const uidContrato = solicitud.canton + `-` + solicitud.linea + `-` + numeroSolicitud + '-' + contrato;

                    db.collection("pre_ventas").doc(uidContrato).set({
                        ...solicitud,
                        numeroContrato: numeroSolicitud,
                        numContrato: uidContrato,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }).then(() => {
                        db.collection("agenda").doc(uidContrato).set({
                            ...solicitud,
                            numeroContrato: numeroSolicitud,
                            numContrato: uidContrato,
                            estado: 1,
                            created: firebase.firestore.FieldValue.serverTimestamp(),
                        }).then((docRef) => {
                            //Cambiar estado 
                            db.collection("solicitudes").doc(solicitud.uid).update({
                                estado: 3
                            })
                            dispatch({
                                type: 'CODIGO_OTP_SUBMIT_SUCCESS',
                                payload: docRef
                            })
                            navigate("/ventas/ventas-pre-aprobadas/")
                            Swal.fire({
                                icon: "success",
                                text: `Su preventa ha sido realizada con éxito, recuerde revisar su ventana de ventas pre aprobada.`
                            });
                            // const data = {
                            //     ...solicitud,
                            //     numeroContrato: numeroSolicitud,
                            //     numContrato: uidContrato
                            // }
                            // //Enviamos el correo de confirmacion
                            // const confirmarContrato = functions.httpsCallable('confirmarContrato');
                            // confirmarContrato(data)
                            //     .then(res => {
                            //         navigate("/ventas/ventas-pre-aprobadas/")
                            //         Swal.fire({
                            //             icon: "success",
                            //             text: `Su preventa ha sido realizada con éxito, recuerde revisar su ventana de ventas pre aprobada.`
                            //         });
                            //         //Enviamos el contrato, esto tarda un poco

                            //         console.log('Se creo un agendado')
                            //         const enviarCorreo = functions.httpsCallable('enviarContrato');
                            //         enviarCorreo(data)
                            //             .then(res => {
                            //                 console.log('Se envio la funcion de enviar contrato')
                            //             })
                            //             .catch(error => console.log(error))
                            //     })
                            //     .catch(error => console.log(error))
                        }).catch((error) => {
                            Swal.fire({
                                icon: "Error",
                                text: error
                            });
                            console.log('Error agenda', error)
                        })
                    }).catch((error) => {
                        Swal.fire({
                            icon: "Error",
                            text: error
                        });
                        console.log('Error al crear agenda', error)
                    })

                } else {
                    console.log(solicitud, 'preventa')
                    const contrato = solicitud.contrato.substring(0, 3)

                    const uidContrato = solicitud.canton + `-` + 1 + '-' + contrato;
                    db.collection("pre_ventas").doc(uidContrato).set({
                        ...solicitud,
                        numeroContrato: 1,
                        numContrato: uidContrato,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    }).then(() => {
                        db.collection("agenda").doc(uidContrato).set({
                            ...solicitud,
                            numeroContrato: uidContrato,
                            numContrato: uidContrato,
                            estado: 0,
                            created: firebase.firestore.FieldValue.serverTimestamp(),
                        }).then(() => {


                            const enviarCorreo = functions.httpsCallable('enviarContrato');
                            enviarCorreo(solicitud)
                                .then(res => {
                                    navigate("/ventas/ventas-pre-aprobadas/")
                                    dispatch({
                                        type: 'CODIGO_OTP_SUBMIT_SUCCESS',
                                        payload: res
                                    })
                                    Swal.fire({
                                        icon: "success",
                                        text: `Su preventa ha sido realizada con éxito, recuerde revisar su ventana de ventas pre aprobada`
                                    });
                                })
                                .catch(error => console.log(error))
                        }).catch((error) => {
                            Swal.fire({
                                icon: "Error",
                                text: error
                            });
                            console.log('Error al crear agenda', error)
                        })
                    }).catch((error) => {
                        Swal.fire({
                            icon: "Error",
                            text: error
                        });
                        console.log('Erro al crear venta', error)
                    })

                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "Error",
                    text: error
                });
                console.error("No se puedo crear el id codigo de la solicitud: ", error);
            });
    } catch (error) {
        Swal.fire({
            icon: "Error",
            text: error
        });
        dispatch({
            type: 'CODIGO_OTP_SUBMIT_FAIL',
            payload: error
        })
    }
}