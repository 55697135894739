import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, Select, MenuItem, TextField, Box } from "@mui/material"
import React, { useEffect, useState } from "react"
import Swal from "sweetalert2"
import { useParams, useNavigate } from 'react-router-dom'
import { db, firebase } from "../../../../../firebase"
import { useDispatch, useSelector } from 'react-redux'
import CheckIcon from '@mui/icons-material/Check'
export const AgregarPago = ({ props }) => {
    const [items, setItems] = useState([])
    const [arregloItems, setArregloItems] = useState([])
    const [arregloItemsPagos, setArregloItemsPagos] = useState([])
    const params = useParams()
    const apiPagos = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ambiente": 1,
            "secuencial": parseInt(numPagosTotalesClientes),
            "ruc": "0992988061001",
            "establecimiento": {
                "punto_emision": "001",
                "codigo": "001",
                "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
            },
            "totales": {
                "total_sin_impuestos": parseFloat(props.subTotal),
                "total_del_impuesto": parseFloat(props.impuesto),
                "importe_total": parseFloat(props.total),
                "descuento": 0
            },
            "cliente": {
                "email": `sebastian_mite@hotmail.com`,
                "identificacion": `${props.cedula}`,
                "tipo_identificacion": "05",
                "razon_social": `${params.nombre}`,
                "direccion": `${props.direccion}`,
                "telefono": `${props.telefono}`
            },
            "items": arregloItems,
            "pagos": arregloItemsPagos
        })
        console.log({
            "ambiente": 1,
            "secuencial": parseInt(numPagosTotalesClientes),
            "ruc": "0992988061001",
            "establecimiento": {
                "punto_emision": "001",
                "codigo": "001",
                "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
            },
            "totales": {
                "total_sin_impuestos": parseFloat(props.subTotal),
                "total_del_impuesto": parseFloat(props.impuesto),
                "importe_total": parseFloat(props.total),
                "descuento": 0
            },
            "cliente": {
                "email": `sebastian_mite@hotmail.com`,
                "identificacion": `${props.cedula}`,
                "tipo_identificacion": "05",
                "razon_social": `${params.nombre}`,
                "direccion": `${props.direccion}`,
                "telefono": `${props.telefono}`
            },
            "items": arregloItems,
            "pagos": arregloItemsPagos
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://serverapiolt.intercommerce.com.ec/datil/datil_fact/",
            requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

    }
    const printPostDtil = () => {

        var raw = JSON.stringify({
            "ambiente": 1,
            "secuencial": parseInt(numPagosTotalesClientes),
            "ruc": "0992988061001",
            "establecimiento": {
                "punto_emision": "001",
                "codigo": "001",
                "direccion": "Av. Narcisa de Jesús 6421 y Puente La Joya, Parque de Negocios Nexus 1"
            },
            "totales": {
                "total_sin_impuestos": parseFloat(props.subTotal),
                "total_del_impuesto": parseFloat(props.impuesto),
                "importe_total": parseFloat(props.total),
                "descuento": 0
            },
            "cliente": {
                "email": `${props.correo}`,
                "identificacion": `${props.cedula}`,
                "tipo_identificacion": "05",
                "razon_social": `${params.nombre}`,
                "direccion": `${props.direccion}`,
                "telefono": `${props.telefono}`
            },
            "items": arregloItems,
            "pagos": arregloItemsPagos
        })

        console.log('raw', raw)
    }
    const consultItems = () => {

        let ref = db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).collection('items')

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setItems(data);
           

            /* data.forEach((obj) => {
                arregloItems.push(
                    {
                        "cantidad": parseInt(obj.cantidad),
                        "precio_unitario": parseFloat(obj.precio),
                        "descuento": 0,
                        "descripcion": `${obj.descripcion}`,
                        "precio_total_sin_impuestos": parseFloat(obj.precio),
                        "impuestos": [
                            {
                                "base_imponible": parseFloat(obj.precio),
                                "valor": parseFloat(obj.impuesto),
                                "tarifa": parseFloat(obj.iva),
                                "codigo": "2",
                                "codigo_porcentaje": "2"
                            }
                        ],
                        "descuento": 0.0,
                    },
                )
            }) */

            const nuevosItems = data.map((obj) => ({
                cantidad: parseInt(obj.cantidad),
                precio_unitario: parseFloat(obj.precio),
                descripcion: obj.descripcion,
                precio_total_sin_impuestos: parseFloat(obj.precio),
                impuestos: [
                    {
                        base_imponible: parseFloat(obj.precio),
                        valor: parseFloat(obj.impuesto),
                        tarifa: parseFloat(obj.iva),
                        codigo: "2",
                        codigo_porcentaje: "2",
                    },
                ],
                descuento: 0.0,
            })).filter((item) => !arregloItems.some((i) => i.descripcion === item.descripcion));

            setArregloItems(arregloItems.concat(nuevosItems));
            

            /* data.forEach((obj) => {

                arregloItemsPagos.push({
                    "medio": "102",
                    "total": parseFloat(obj.subTotal)
                })
                
            })

            console.log('arregloItems', arregloItems) */

            const nuevosItemsPagos = data.map((obj) => ({
                "medio": `${obj.medio}`,
                total: parseFloat(obj.subTotal),
            })).filter((item) => !arregloItemsPagos.some((i) => i.total === item.total));

            setArregloItemsPagos(arregloItemsPagos.concat(nuevosItemsPagos));
         

        });
    }
    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth
    const [open, setOpen] = useState(null);
    const [openSaldoPendienteMenor, setOpenSaldoPendienteMenor] = useState(null);
    const [openSaldoPendienteMayor, setOpenSaldoPendienteMayor] = useState(null);
    const [openSaldoPendienteExacto, setOpenSaldoPendienteExacto] = useState(null);
    const [nTransaccion, setNTransaccion] = useState('');
    const [result, setResult] = useState('');
    const [monto, setMonto] = useState(props.total);
    const [comision, setComision] = useState('');
    const [notas, setNotas] = useState('');
    const [numSaldo, setNumSaldo] = useState()
    const [numPagosTotalesClientes, setNumPagosTotalesClientes] = useState()
    const [numTransaccion, setNumTransaccion] = useState()
    const onData = () => {
        setOpen(true)
    }
    const formaPagoRubrosList = useSelector(state => state.formaPagoRubrosList);
    const { formaPagoRubros, loading } = formaPagoRubrosList
    const [formaPagoRubro, setFormaPagoRubro] = useState('')
    const registrarPagoFunciton = () => {
        if (parseFloat(monto) > parseFloat(props.total)) {
            setOpenSaldoPendienteMayor(true)
            setOpen(false)
        } else if (parseFloat(monto) < parseFloat(props.total)) {
            setOpenSaldoPendienteMenor(true)
            setOpen(false)
        } else {
            setOpenSaldoPendienteExacto(true)
            setOpen(false)
        }
    }
    const calculateResult = (e) => {
        const resultado = parseFloat(e) - parseFloat(props.total)
        setResult(resultado)
        console.log(resultado)
    }
    const loadNumSaldo = () => {
        const ref = db.collection('clientes').doc(params.idcliente).collection('saldos').orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_saldo + 1;
                setNumSaldo(numeroSolicitud)
            }
        })
    }
    const loadNumPagosTotalesClientes = () => {
        const ref = db.collection('pagos_totales_clientes').orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_pago + 1;
                setNumPagosTotalesClientes(numeroSolicitud)
            }
        })
    }
    const loadNumTransaccion = () => {
        const ref = db.collection('clientes').doc(params.idcliente).collection('transacciones').orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_transaccion + 1;
                setNumTransaccion(numeroSolicitud)
            }
        })
    }
    useEffect(() => {
        loadNumPagosTotalesClientes()
        consultItems()
        loadNumTransaccion()
        loadNumSaldo()
    }, [])
    const sendPagoMontoMayorSaldos = () => {
        db.collection('clientes').doc(params.idcliente).collection('saldos').get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {

                    console.log("La colección está vacía.");

                    const idFactura = 'SAL-1'

                    /* SI EL NUMERO ES POSITIVO */

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('saldos').doc(idFactura).set({
                        id_saldo: 'SAL-1',
                        numero_saldo: 1,
                        factura_origen: props.id,
                        factura_destino: '00000000',
                        monto: Number.isNaN(Number(result)) ? 0 : -parseFloat(result).toFixed(2),
                        fecha_generacion: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                } else {

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('saldos').doc(`SAL-${numSaldo}`).set({
                        id_saldo: `SAL-${numSaldo}`,
                        numero_saldo: numSaldo,
                        factura_origen: props.id,
                        factura_destino: '00000000',
                        monto: Number.isNaN(Number(result)) ? 0 : -parseFloat(result).toFixed(2),
                        fecha_generacion: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                }
            });
        Swal.fire({ icon: "success", text: "Movimiento realizado con exito", });
        setOpenSaldoPendienteMayor(false)
    }
    const sendPagoMontoMenorSaldos = () => {

        db.collection('clientes').doc(params.idcliente).collection('saldos').get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {

                    console.log("La colección está vacía.");

                    const idFactura = 'SAL-1'

                    /* SI EL NUMERO ES POSITIVO */

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('saldos').doc(idFactura).set({
                        id_saldo: 'SAL-1',
                        numero_saldo: 1,
                        factura_origen: props.id,
                        factura_destino: '00000000',
                        monto: Number.isNaN(Number(result)) ? 0 : Math.abs(parseFloat(result).toFixed(2)),
                        fecha_generacion: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                    })

                    apiPagos()

                } else {

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('saldos').doc(`SAL-${numSaldo}`).set({
                        id_saldo: `SAL-${numSaldo}`,
                        numero_saldo: numSaldo,
                        factura_origen: props.id,
                        factura_destino: '00000000',
                        monto: Number.isNaN(Number(result)) ? 0 : Math.abs(parseFloat(result).toFixed(2)),
                        fecha_generacion: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                }
            });
        /* Swal.fire({ icon: "success", text: "Movimiento realizado con exito", });
        setOpenSaldoPendienteMenor(false) */
    }
    const sendPagoMontoMayorTransaccion = () => {

        db.collection('clientes').doc(params.idcliente).collection('transacciones').get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {

                    console.log("La colección está vacía.");

                    const idFactura = 'TRAN-1'

                    /* SI EL NUMERO ES POSITIVO */

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(idFactura).set({
                        id_transaccion: 'TRAN-1',
                        numero_transaccion: 1,
                        numero_factura: props.id,
                        monto: Number.isNaN(Number(result)) ? 0 : -parseFloat(result).toFixed(2),
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: 'TRAN-1',
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                } else {

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(`TRAN-${numTransaccion}`).set({
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_transaccion: numTransaccion,
                        numero_factura: props.id,
                        factura_destino: '00000000',
                        monto: Number.isNaN(Number(result)) ? 0 : -parseFloat(result).toFixed(2),
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        numero_transaccion: numTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                }
            });
        Swal.fire({ icon: "success", text: "Movimiento realizado con exito", });
        setOpenSaldoPendienteMayor(false)
    }
    const sendPagoMontoMenorTransaccion = () => {

        db.collection('clientes').doc(params.idcliente).collection('transacciones').get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {

                    console.log("La colección está vacía.");

                    const idFactura = 'TRAN-1'

                    /* SI EL NUMERO ES POSITIVO */

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(idFactura).set({
                        id_transaccion: 'TRAN-1',
                        numero_transaccion: 1,
                        numero_factura: props.id,
                        monto: Number.isNaN(Number(result)) ? 0 : Math.abs(parseFloat(result).toFixed(2)),
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: 'TRAN-1',
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                } else {

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(`TRAN-${numTransaccion}`).set({
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_transaccion: numTransaccion,
                        numero_factura: props.id,
                        monto: Number.isNaN(Number(result)) ? 0 : Math.abs(parseFloat(result).toFixed(2)),
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                }
            });
        Swal.fire({ icon: "success", text: "Movimiento realizado con exito", });
        setOpenSaldoPendienteMenor(false)
    }
    const sendPagoMontoExactoTransaccion = () => {

        db.collection('clientes').doc(params.idcliente).collection('transacciones').get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {

                    console.log("La colección está vacía.");

                    const idFactura = 'TRAN-1'

                    /* SI EL NUMERO ES POSITIVO */

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(idFactura).set({
                        id_transaccion: 'TRAN-1',
                        numero_transaccion: 1,
                        numero_factura: props.id,
                        monto: 0,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: 'TRAN-1',
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                } else {

                    db.collection('pagos_totales_clientes').doc(`PAGO-${numPagosTotalesClientes}`).set({
                        id_pago: `PAGO-${numPagosTotalesClientes}`,
                        numero_pago: numPagosTotalesClientes,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        cliente_que_pago: params.nombre
                    })

                    db.collection('clientes').doc(params.idcliente).collection('transacciones').doc(`TRAN-${numTransaccion}`).set({
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_factura: props.id,
                        monto: 0,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        descripcion: 'SALDO GENERADO AUTOMATICO',
                        estado: 0,
                        forma_de_pago: formaPagoRubro,
                        num_transaccion_campo: nTransaccion,
                        numero_transaccion: numTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        total_pagado: monto,
                        valor_rubro: props.total,
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                    })

                    db.collection('clientes').doc(params.idcliente).collection('rubros').doc(props.id).update({
                        estado: 2,
                        total_pagado: monto,
                        fecha_pago: firebase.firestore.FieldValue.serverTimestamp(),
                        forma_de_pago: formaPagoRubro,
                        numero_transaccion: nTransaccion,
                        comision: comision,
                        nota: notas.toUpperCase(),
                        operador: userInfo.displayName,
                        id_transaccion: `TRAN-${numTransaccion}`,
                        numero_pago_secuencial: numPagosTotalesClientes
                    })

                    apiPagos()

                }
            });
        Swal.fire({ icon: "success", text: "Movimiento realizado con exito", });
        setOpenSaldoPendienteExacto(false)
    }
    const sendPagosMenores = () => {
        sendPagoMontoMenorSaldos()
        sendPagoMontoMenorTransaccion()
    }
    const sendPagosMayores = () => {
        sendPagoMontoMayorSaldos()
        sendPagoMontoMayorTransaccion()
    }
    const sendPagoExacto = () => {
        sendPagoMontoExactoTransaccion()
    }
    return (
        <>
            <Tooltip title="Editar rubro">
                <Button onClick={() => onData()}>
                    <CheckIcon color="success" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle sx={{ backgroundColor: 'whitesmoke' }}>
                    AGREGAR PAGO
                </DialogTitle>
                <DialogContent dividers>
                    <p style={{ fontSize: "12px", marginBottom: "6px" }}>Forma de Pago</p>
                    <Select sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        fullWidth
                        size="small"
                        value={formaPagoRubro}
                        label="forma pago"
                        onChange={(e) => setFormaPagoRubro(e.target.value)}>
                        {formaPagoRubros &&
                            formaPagoRubros.map(item => (
                                <MenuItem key={item.id} value={item.forma_pago} >
                                    {item.forma_pago}
                                </MenuItem>
                            ))}
                    </Select>
                    <Box py={2}>
                        <p style={{ fontSize: "12px", marginBottom: "6px" }}>N Transaccion</p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={nTransaccion}
                            onChange={(e) => setNTransaccion(e.target.value)}
                            name="N Transaccion"
                            size="small"
                            fullWidth
                            type="number"
                        />
                    </Box>
                    
                    <p style={{ fontSize: "12px", marginBottom: "6px" }}>Monto</p>
                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={monto}
                        onChange={(e) => {
                            calculateResult(e.target.value)
                            setMonto(e.target.value)
                        }}
                        name="Monto"
                        size="small"
                        fullWidth
                        type="number"
                    />

                    <Box py={2}>
                        <p style={{ fontSize: "12px", marginBottom: "6px" }}>Comision</p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={comision}
                            onChange={(e) => setComision(e.target.value)}
                            name="Comision"
                            size="small"
                            fullWidth
                            type="number"
                        />
                    </Box>

                    <p style={{ fontSize: "12px", marginBottom: "6px" }}>Notas</p>

                    <TextField sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                    }}
                        value={notas}
                        onChange={(e) => setNotas(e.target.value)}
                        name="Notas"
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                    />

                </DialogContent>
                <DialogActions sx={{ backgroundColor: 'whitesmoke' }}>
                    <Button  color="secondary" variant="outlined" onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        console.log('arregloItems', arregloItems)
                        console.log('arregloItemsPagos', arregloItemsPagos)
                        registrarPagoFunciton()
                    }}
                    
                        color="secondary"
                        variant="contained"
                    >
                        {" "}
                        REGISTRAR PAGO
                    </Button>
                </DialogActions>
            </Dialog>
            {/* PAGO MENOR AL DE LA FACTURA */}
            <Dialog open={openSaldoPendienteMenor} fullWidth onClose={() => setOpenSaldoPendienteMenor(false)} maxWidth="md">
                <DialogTitle>
                    <strong>SALDO PENDIENTE</strong>
                </DialogTitle>

                <DialogContent>
                    Esta registrando un monto menor al total de la factura, si registra el pago quedará un saldo pendiente de <strong>${Math.abs(parseFloat(result).toFixed(2))}</strong>
                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="outlined" onClick={() => { setOpenSaldoPendienteMenor(false) }}><strong>CANCELAR</strong></Button>
                    <Button onClick={() => sendPagosMenores()}
                        size='small'
                        color="error"
                        variant="contained"
                    >
                        {" "}
                        SI, REGISTRAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* PAGO MAYOR AL DE LA FACTURA */}

            <Dialog open={openSaldoPendienteMayor} fullWidth onClose={() => setOpenSaldoPendienteMayor(false)} maxWidth="md">
                <DialogTitle>
                    <strong>SALDO PENDIENTE</strong>
                </DialogTitle>

                <DialogContent>
                    Esta registrando un monto mayor al total de la factura, si registra el pago quedará un saldo a favor del cliente de <strong>$-{parseFloat(result).toFixed(2)}</strong>
                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="outlined" onClick={() => {
                        setOpen(true)
                        setOpenSaldoPendienteMayor(false)
                    }}><strong>CANCELAR</strong></Button>
                    <Button onClick={() => sendPagosMayores()}
                        size='small'
                        color="error"
                        variant="contained"
                    >
                        {" "}
                        SI, REGISTRAR
                    </Button>
                </DialogActions>
            </Dialog>

            {/* PAGO EXACTO */}

            <Dialog open={openSaldoPendienteExacto} fullWidth onClose={() => setOpenSaldoPendienteExacto(false)} maxWidth="xs">
                <DialogTitle>
                    <strong>¿ESTA SEGURO DE REALIZAR EL PAGO?</strong>
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="outlined" onClick={() => {
                        setOpen(true)
                        setOpenSaldoPendienteExacto(false)
                    }}><strong>CANCELAR</strong></Button>
                    <Button onClick={() => sendPagoExacto()}
                        size='small'
                        color="error"
                        variant="contained"
                    >
                        {" "}
                        SI, REGISTRAR
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};