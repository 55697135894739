import { Grid as Grid2, GridColumn } from '@progress/kendo-react-grid';
import React, { useState } from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import logo1 from '../../assets/img/contrato/image005.jpg'
import logo2 from '../../assets/img/contrato/image003.jpg'

import {
    Box, Button, Stack, Container, Dialog,
    DialogActions, DialogContent, DialogTitle,
    Grid, Tooltip
} from '@mui/material';
import moment from 'moment'
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { db } from '../../firebase';


export const PdfHojaTrabajo = ({ info }) => {
    const container = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState(null);

    const [plan, setPlan] = useState('');





    function LoadData() {
        let ref = db.collection("tecnicos").doc(info.numContrato).collection("materiales_utilizados")
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                        serie_utilizada: doc.data().codigo_producto === 'INV-00113'
                            && doc.data().serie_utilizada === 'NINGUNA'
                            ? info.serie_utilizada : doc.data().serie_utilizada
                    }
                })
            ]
            setItems(data)
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispPerfilesplanes/?id=${info.planId}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let re2 = JSON.parse(result)
                    if (re2[0].id) {
                        let velocidad = re2[0].velocidad.replaceAll('K', '').split('/')[0]
                        velocidad = parseInt(parseInt(velocidad) / 1000)
                        if (velocidad % 5 !== 0) {
                            velocidad = velocidad - 2
                        }
                        setPlan(velocidad)
                    }
                }).catch(error => console.log('error', error));
        })

    }


    const PageTemplate = (props) => {
        return (
            <div>
                {props.pageNum == 1 &&
                    <div
                        style={{
                            position: "absolute",
                            top: "20px",
                            width: "90%"
                        }}>
                        {/* <p align="left">
                            <img src={logo1} alt="soei" width="90" />
                        </p> */}
                        <Stack direction='row'
                            spacing={1}
                        >
                            <img src={logo1} alt="soei" width="90" />
                            <img src={logo2} alt="soei" width="120" />
                        </Stack>
                        <a style={{ fontSize: "12px", paddingTop: '10px' }} align="right">
                            <strong>RUC:   {'0992988061001'}</strong><br />
                            <strong>PBX:   {'0979370118'}</strong><br />
                            <strong>MAPASINGUE ESTE AV. VIA DAULE Nro. 1 km 5.5</strong>
                        </a>
                    </div>
                }
            </div >
        )
    };

    const firma = (
        <Grid container spacing={7} alignItems="center" justifyContent="center" style={{ fontSize: '14px' }}>
            <Grid item xs={4}>
                <Box align="center" sx={{ marginTop: "70px", borderBottom: 1 }} >
                    <a >{info.nombres}</a>
                </Box>
                <p align="center" ><strong>FIRMA CLIENTE</strong></p>
            </Grid>
            <Grid item xs={4}>
                <Box align="center" sx={{ marginTop: "70px", borderBottom: 1 }} >
                    <a  >{info.nombreAuxiliar}</a>
                </Box>
                <p align="center"><strong>TECNICO RESPONSABLE</strong></p>
            </Grid>
        </Grid >
    )

    const headerColumn = (props) => {
        return (
            <a >
                <span ><strong>{props.title}</strong></span>
                {props.children}
            </a>
        );
    };

    const serialColumn = (props) => {
        const field = props.field || "";
        const value = props.dataItem[field];
        return (
            <>
                <td>${value ? value.toFixed(2) : '0.00'}</td>
            </>
        );
    };

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            repeatHeaders: 'true',
            pageTemplate: PageTemplate,
            paperSize: "A4",
            margin: { top: "3cm", left: "1.2cm", right: "1.2cm", bottom: "1.2cm" },
            scale: 0.5,
            fileName: `Orden De Trabajo ${info.uid_hoja_instalacion}`,
        });

    };

    const tabla = (
        <Grid2 data={items ?? []} >
            <GridColumn field="codigo_producto" title="Codigo" headerCell={headerColumn} />
            <GridColumn field="descripcion" title="Descripcion" headerCell={headerColumn} />
            <GridColumn field="cantidad_utilizada" title="Cantidad" headerCell={headerColumn} />
            <GridColumn field="serie_utilizada" title="Serie" headerCell={headerColumn} />
            <GridColumn field="valor_metraje_extra" title="Valor Extra" headerCell={headerColumn} cell={serialColumn} />
        </Grid2>
    )

    const bordes = {
        borderRight: "1px solid black",
        borderBottom: "1px solid black",
        borderLeft: "1px solid black"
    }

    return (
        <>
            <Tooltip title="Ver Contrato">
                <Button onClick={() => {
                    setOpen(true)
                    LoadData()
                }}>
                    <MenuBookIcon />
                </Button>
            </Tooltip>




            <Dialog open={open} fullWidth onClose={() =>
                setOpen(false)
            } maxWidth="lg">
                <DialogTitle>
                    <strong>Descargar: {info.numContrato}</strong>
                </DialogTitle>
                <DialogContent>
                    <Container >
                        <div className="example-config">
                            <Button
                                onClick={exportPDFWithMethod}
                                style={{ marginLeft: "10px", marginBottom: "10px" }}
                                color="error" size='small' variant="outlined"
                                startIcon={<PictureAsPdfIcon />}>
                                <strong>PDF</strong>
                            </Button>
                        </div>
                        <div className="border rounded p-2">
                            <PDFExport>
                                <div ref={container}>
                                    <Container maxWidth='xl'>


                                        <Grid container spacing={1}>
                                            <Grid item xs={12} align="center"
                                                style={{ borderTop: "1px solid black", ...bordes, backgroundColor: '#672780', color: 'white' }}>
                                                <a><strong>ORDEN DE TRABAJO</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>TIPO:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a>{info.tipo_orden_trabajo}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>FECHA SOLICITUD:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.createdGis ?
                                                    moment(info.createdGis.toDate()
                                                        .toString()).format('DD/MM/YYYY') : ''}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>CONTRATO:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black" }}>
                                                <a>{info.numContrato ? info.numContrato : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>FECHA INSTALACION:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.created_instalacion ?
                                                    moment(info.created_instalacion.toDate()
                                                        .toString()).format('DD/MM/YYYY') : ''}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>ID HOJA:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.uid_hoja_instalacion ? info.uid_hoja_instalacion : '-'}</a>
                                            </Grid>

                                            <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                                <br />  <a><strong>DATOS DEL SOLICITANTE</strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>NOMBRE Y APELLIDOS:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a>{info.nombres ? info.nombres : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>Nº CEDULA:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.cedula ? info.cedula : '-'}</a>
                                            </Grid>


                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>EMAIL:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a>{info.correo ? info.correo : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>TELEFONO:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.telefono ? info.telefono : '-'}</a>
                                            </Grid>


                                            <Grid item xs={3} style={{
                                                ...bordes, backgroundColor:
                                                    '#672780', color: 'white'
                                            }}>
                                                <a><strong>COORDENADAS:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black",
                                            }}>
                                                <a>{info.coordenadas ? info.coordenadas._lat +
                                                    ', ' + info.coordenadas._long : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>VENDEDOR:</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.vendedor ? info.vendedor : '-'}</a>
                                            </Grid>

                                            <Grid item xs={3} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>DIRECCION:</strong></a>
                                            </Grid>
                                            <Grid item xs={9} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.direccion ? info.direccion : '-'}</a>
                                            </Grid>


                                            <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                                <br /> <a><strong>SERVICIOS A CONTRATAR</strong></a>
                                            </Grid>


                                            <Grid item xs={4} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>PLAN</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom:
                                                    "1px solid black", backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>TIPO</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>AP-CAJA</strong></a>
                                            </Grid>


                                            <Grid item xs={4} style={{ ...bordes, }}>
                                                <a>{info.planNombre}</a>
                                            </Grid>
                                            <Grid item xs={4} style={{ borderBottom: "1px solid black", }}>
                                                <a>{info.contrato ? info.contrato : '-'}</a>
                                            </Grid>
                                            <Grid item xs={4} style={{ ...bordes, }}>
                                                <a>{info.caja ? info.caja : '-'}</a>
                                            </Grid>



                                            <Grid item xs={4} style={{
                                                ...bordes,
                                                backgroundColor: '#672780', color: 'white'
                                            }}>
                                                <a><strong>PRECIO</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom:
                                                    "1px solid black", backgroundColor:
                                                    '#672780', color: 'white', borderRight: "1px solid black"
                                            }}>
                                                <a><strong>MEGAS</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom:
                                                    "1px solid black", backgroundColor:
                                                    '#672780', color: 'white', borderRight: "1px solid black"
                                            }}>
                                                <a><strong>POTENCIA</strong></a>
                                            </Grid>
                                            {/* <Grid item xs={6} style={{ borderBottom: "1px solid black", backgroundColor: '#672780', color: 'white', borderRight: "1px solid black" }}>
                                                <a><strong>VIVIENDA</strong></a>
                                            </Grid> */}

                                            <Grid item xs={4} style={{ ...bordes, }}>
                                                <a>${info.planValor}</a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black"
                                            }}>
                                                {/* <a>{info.contrato ? info.contrato : '-'}</a> */}
                                                {plan} MBPS
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black"
                                            }}>
                                                <a>{info.potencia ? info.potencia : '-'}</a>
                                            </Grid>
                                            {/* <Grid item xs={6} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <Box sx={{display:'flex', justifyContent:'center'}}>
                                                    <img width={200} src={info.foto_cliente_vivienda} />
                                                </Box>
                                            </Grid> */}



                                            <Grid item xs={12} align="left" >
                                                <br /> <a><strong>FOTO VIVIENDA</strong></a>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img height={400} src={info.foto_cliente_vivienda} />
                                                </Box>
                                            </Grid>


                                            {/* <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                                <br /> <a><strong>EQUIPOS</strong></a>
                                            </Grid>


                                            <Grid item xs={3} style={{ ...bordes, backgroundColor: '#672780', color: 'white' }}>
                                                <a><strong>EQUIPO</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", backgroundColor: '#672780', color: 'white' }}>
                                                <a><strong>DSERIE</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes, backgroundColor: '#672780', color: 'white' }}>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", backgroundColor: '#672780', color: 'white', borderRight: "1px solid black" }}>
                                                <a><strong>FLOTA</strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>{info.equipo ? info.equipo : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a>{info.DSerie ? info.DSerie : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes, }}>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <a>{info.numFlota ? info.numFlota : '-'}</a>
                                            </Grid>


                                            <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                                <br /><a><strong>DETALLE CABLE</strong></a>
                                            </Grid>


                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a><strong>CABLE EXTRA</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a><strong></strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes }}>
                                                <a><strong></strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <a><strong></strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>{info.metraje ? info.metraje : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>{" "}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <a>{" "}</a>
                                            </Grid> */}

                                            <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                                <br /><a><strong>DETALLE INSTALACION Y EXTRAS</strong></a>
                                            </Grid>


                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a><strong>VALOR COBRADO</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black" }}>
                                                <a><strong>NO. CUOTAS</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes }}>
                                                <a><strong></strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a><strong>FLOTA</strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>${info.valorCobrado}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black" }}>
                                                <a>{info.noCuotas ? info.noCuotas : '0'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a></a>
                                            </Grid>

                                            <Grid item xs={3} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.numFlota ? info.numFlota : '-'}</a>
                                            </Grid>
                                            {/* <Grid item xs={3} style={{ ...bordes, }}>
                                                <a><strong>CABLE EXTRA</strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a><strong></strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes }}>
                                                <a><strong></strong></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <a><strong></strong></a>
                                            </Grid>

                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>{info.metraje ? info.metraje : '-'}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", }}>
                                                <a></a>
                                            </Grid>
                                            <Grid item xs={3} style={{ ...bordes, }}>
                                                <a>{" "}</a>
                                            </Grid>
                                            <Grid item xs={3} style={{ borderBottom: "1px solid black", borderRight: "1px solid black" }}>
                                                <a>{" "}</a>
                                            </Grid>  */}
                                        </Grid>
                                        <br />
                                        {tabla}
                                        <br />
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} align="left" style={{ borderBottom: "1px solid black" }}>
                                            </Grid>
                                            <Grid item xs={4} style={{ ...bordes, }}>
                                                <a><strong>NOVEDADES DE INSTALACION</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black"
                                            }}>
                                                <a><strong>MATERIALES EXTRAS</strong></a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom: "1px solid black",
                                                borderRight: "1px solid black"
                                            }}>
                                                <a><strong>OBSERVACION</strong></a>
                                            </Grid>

                                            <Grid item xs={4} style={{ ...bordes, }}>
                                                <a>{info.observacionInstalacionRealizada ?
                                                    info.observacionInstalacionRealizada : 'NINGUNA'}</a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.materialesExtra ? info.materialesExtra : 'NINGUNO'}</a>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                borderBottom:
                                                    "1px solid black", borderRight: "1px solid black"
                                            }}>
                                                <a>{info.observacion2 ? info.observacion2 : 'NINGUNA'}</a>
                                            </Grid>

                                            {/* <Grid item xs={12} >
                                                <a><strong>VALOR COBRADO: ${info.valorCobrado}</strong></a>
                                            </Grid> */}
                                            <Grid item xs={12} >
                                                <a><strong>FORMA DE PAGO: TRANSFERENCIA</strong></a>
                                            </Grid>
                                        </Grid>
                                        {firma}
                                        <br />
                                        <Box align="center" px={6} sx={{ justifyContent: 'center' }}>
                                            {/* <QRCode value={info.uid_hoja_instalacion ? 
                                                info.uid_hoja_instalacion : 'NINGUNO'} /> */}
                                            <p>
                                                <br /><strong>Equipos de Propiedad de Intercommerce S.A.
                                                    el cliente se compromete a la devolución
                                                    de los mismos cuando de alguna manera se llegase a
                                                    terminar el contrato, ya sea por
                                                    parte unilateral del proveedor o el cliente ya no
                                                    requiera el servicio. En caso de la no
                                                    devolución de los mismos INTERCOMMERCE S.A. llevará
                                                    a cabo los procedimientos legales
                                                    correspondientes para el cobro de los valores de
                                                    los equipos.</strong></p>
                                        </Box>

                                    </Container>
                                </div>
                            </PDFExport>
                        </div>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained"
                        onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>

        </>

    )
}
