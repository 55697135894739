import { Table, Box, Button, Collapse, Typography, TableContainer, TableRow, TableCell, TableBody, TableHead, Paper, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from 'moment'
import { useSelector } from 'react-redux';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx/xlsx.mjs'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import IconButton from '@mui/material/IconButton';

import { ViewPdfClient } from "./ViewPdfClient";
import SearchInput from '../../components/utils/SearchInput';
import { ViewPhotosInstalaciones } from "../inventario/ViewPhotosInstalaciones";

export const Clientes = () => {
  function Estado(estado) {
    const d = estado.estado
    console.log(d.cambio_domicilio)
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.estado === 0) {
      name = 'INSTALADO'
      color = 'success'
    }
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }
    if (d.estado === 1) {
      name = 'AGENDADA'
      color = 'success'
    }
    return (
      <Chip label={name} color={color} />
    )
  }
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState('')
  //
  const current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  //
  useEffect(() => {
    LoadData();
  }, []);
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  const LoadData = (startAfterDoc, persistMessages = []) => {
    if (userInfo.email === "jazmin.perez@distribuidor1.santaelena.com.ec" || userInfo.email === "giovanny.rodas@distribuidor1.santaelena.com.ec" || userInfo.email === "jonathan.mendoza@intercommerce.com.ec" || userInfo.email === "daniela.galve@intercommerce.com.ec") {
      let ref = db.collection("clientes").where("provincia", "==", "SANTA ELENA").where("estado", "==", 0)
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
        let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('created', '>=', startfulldate)
        ref = ref.where('created', '<=', endfulldate)
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          setData(data);
        })
      }
    } else {
      let ref = db.collection("clientes").where("estado", "==", 0)
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
        let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where('created', '>=', startfulldate)
        ref = ref.where('created', '<=', endfulldate)
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              //console.log(doc.data(), 'doc')
              //
              //const dataForm = doc.data()
              //
              // var docRef = db.collection("clientes").doc(dataForm.numContrato);
              // docRef.get().then((doc) => {
              //   if (doc.exists) {
              //     const data = doc.data()
              //     const fecha = data.url_instalacion
              //     console.log(data.id, '--', fecha)
              //     //
              //     var washingtonRef = db.collection("tecnicos").doc(dataForm.numContrato);

              //     // Set the "capital" field of the city 'DC'
              //     return washingtonRef.update({
              //       url_instalacion: fecha
              //     })
              //       .then(() => {
              //         console.log("Document successfully updated!");
              //       })
              //       .catch((error) => {
              //         // The document probably doesn't exist.
              //         console.error("Error updating document: ", error);
              //       })

              //     //console.log("Document data:", data.created);
              //   } else {
              //     // doc.data() will be undefined in this case
              //     console.log("No such document!");
              //   }
              // }).catch((error) => {
              //   console.log("Error getting document:", error);
              // });


              // const enviarHoja = functions.httpsCallable('enviarHojaInstalacion')
              // enviarHoja(dataForm)
              //   .then(res => {
              //     console.log('Se envio la funcion: ', res)
              //     if (dataForm.debito && dataForm.debito === 1) {
              //       const anexo5 = functions.httpsCallable('crateAnexo5')
              //       anexo5(dataForm)
              //         .then(res => {

              //           console.log('Se envio el anexo5: ', res)
              //         })
              //         .catch(error => {
              //           console.log(`No se puedo crear el anexo5, ${error}`)
              //         })
              //     }
              //   })
              //   .catch(error => {
              //     console.log(`No se puedo crear la hoja de instalacion, ${error}`)
              //   })

              return {
                ...doc.data(),
                id: doc.id,
              };

            }),
          ];
          setData(data);
          console.log('Test aqui')

        })
      }
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    let date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }



    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.numContrato,
          CEDULA: doc.cedula,
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,
          /* COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`, */
          /* COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`, */
          PLAN: doc.planNombre,
          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
          DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
          CABLE_INICIO: doc.cableInicio,
          CABLE_FINAL: doc.cableFinaliza,
          ASESOR: doc.vendedor,
          NUM_FLOTA: doc.numFlota,
          LIDER_FLOTA: doc.nombreLider,
          TIPO: doc.cambio_domicilio === true ? 'CAMBIO DOMICLIO' : 'INSTALACION',
          created: doc.created.toDate(),
          FECHA_INSTALACION: doc.fechaHora && doc.fechaHora.seconds ?
            moment(new Date(doc.fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A') : ''
        }
      })
    ]
    let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }

  return (
    <>
      <Typography pt={3} pl={3}>
        <strong>({data ? data.length : 0})GESTION CLIENTES</strong>
      </Typography>
      <Box pl={2} pt={2} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
            <Box display="flex" alignItems='center' >
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <Box>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Box display='flex' style={{ textTransform: 'uppercase' }} py={2} pl={2}>
        <Typography variant="button">
          <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography >
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
      </Box>

      <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ESTADO</TableCell>
                <TableCell>ACCIONES</TableCell>
                <TableCell>FOTOS</TableCell>
                <TableCell>#CONTRATO</TableCell>
                <TableCell>CAMARA</TableCell>
                <TableCell>DEBITO</TableCell>
                <TableCell>BANCO</TableCell>
                <TableCell>TIPO CONTRATO</TableCell>

                <TableCell>CEDULA</TableCell>
                <TableCell>CLIENTE</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                {/* <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DESCUENTO</TableCell> */}
                <TableCell>DIRECCION</TableCell>
                <TableCell>SECTOR</TableCell>
                {/* <TableCell>COORDENADAS</TableCell> */}
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>#REFERENCIA</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                {/* <TableCell>FECHA Y HORA INSTALACION</TableCell> */}
                <TableCell>NUMERO FLOTA</TableCell>
                <TableCell>NOMBRE LIDER</TableCell>
                <TableCell>NOMBRE AUXILIAR</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>CABLE INICIO</TableCell>
                <TableCell>CABLE FINAL</TableCell>
                {/* <TableCell>FECHA CREACION</TableCell> */}
                <TableCell>FECHA INSTALACION</TableCell>
                <TableCell>VENDEDOR</TableCell>
                <TableCell>REFERIDO</TableCell>
                <TableCell>FLOTA REFERIDA</TableCell>
                <TableCell>CARGO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? data.filter((val) => {
                if (search === '') {
                  return val
                } else if (val.nombres === undefined) {
                  return ''
                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                  return val
                } else if (val.cedula === undefined) {
                  return ''

                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                  return val
                }
              }).map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    <Estado estado={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <ViewPdfClient props={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <ViewPhotosInstalaciones props={row} />
                  </TableCell>
                  <TableCell component="th" scope="row">

                    {row.numContrato && row.cambio_domicilio === true ? row.NUM_CONTRATO : row.numContrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                  {row.camara}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.debito == 1 ? 'SI' : 'NO'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.banco}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.contrato}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula === undefined ? '' : row.cedula}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombres === undefined ? '' : row.nombres}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.terceraEdad === 1 ? 'SI' : 'NO'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.discapacitado === 1 ? 'SI' : 'NO'}
                  </TableCell>

                  {/* <TableCell component="th" scope="row">
                    {row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.discapacidad}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.descuento
                    }
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.paramsDiscapacidad === "" ? "NINGUNO" : row.paramsDiscapacidad.valor
                    }
                  </TableCell> */}

                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>


                  <TableCell component="th" scope="row">
                    {row.sector}
                  </TableCell>


                  {/* <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell> */}

                  <TableCell component="th" scope="row">
                    {row.telefono}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.celular}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.whatsapp}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.numbRef}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.planNombre}
                  </TableCell>



                  <TableCell component="th" scope="row">
                    {row.numFlota}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombreLider}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.nombreAuxiliar}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.planValor}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cableInicio}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.cableFinaliza}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.fechaHora && row.fechaHora.seconds ?
                        moment(new Date(row.fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
                        : <>
                          {row.fechaHora && typeof row.fechaHora === 'number' &&
                            moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss A')}
                          {row.fechaHora && typeof row.fechaHora === 'object' &&
                            moment(row.fechaHora.toDate().toString()).format('DD/MM/YYYY h:mm:ss A')}
                        </>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.vendedor}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.referido === undefined ? 'NO' : row.referido}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {userInfo.cargo}
                  </TableCell>

                </TableRow>
              )) : <></>}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

    </>
  );
};