import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const ListRecursosHumanos = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'recursos_humanos']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <BadgeTwoToneIcon  />
                    </ListItemIcon>
                    <ListItemText primary="Recursos Humanos" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`RecursosHumanos/CrearUsuario/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Usuarios" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`RecursosHumanos/Nomina/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Nomina" />
                        </ListItemButton>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}