import React, { useState, useEffect } from "react";
import {
  TextField, FormControl, Select, MenuItem, Button, Box,
  DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, auth } from "../../../firebase";
import Swal from "sweetalert2";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { listFlotas } from "../../../redux/actions/flotas/flotasActions";
import { listLiders } from "../../../redux/actions/flotas/flotasLiders";
import { listAuxiliares } from "../../../redux/actions/flotas/flotasAuxiliares";
import moment from 'moment';
import 'moment/locale/es' 
export const UpdateVentasReAgendadas = ({ props }) => {
  const dispatch = useDispatch()

  const flotasList = useSelector(state => state.flotasList)
  const { flotas, loading } = flotasList

  const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList

  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList

 /*  const liderlist = useSelector(state => state.liderlist)
  const { liders, loading1 } = liderlist */

  /* const liderList = useSelector(state => state.liderList)
  const { liders, loading1 } = liderList

  const auxiliarList = useSelector(state => state.auxiliarList)
  const { auxiliars, loading2 } = auxiliarList */

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [value, setValue] = useState(null);
  const [flota, setFlota] = useState('')
  const [numFlota, setNumFlota] = useState('')
  const [lider, setLider] = useState('')
  const [auxiliar, setAuxiliar] = useState('')

  useEffect(() => {

    dispatch(listFlotas())
    dispatch(listLiders())
    dispatch(listAuxiliares())
  }, [dispatch, open, openConfirm])

  const handleFlota = (e) => {
  
    setFlota(e)
    const p = flotas.filter((item) => item.numeroFlota === e);
    const data = p[0]

    setNumFlota(data.numeroFlota)
  }

  const handleLider = (e) => {
 
    setLider(e)
    const p = liders.filter((item) => item.nombreLider === e);
    const data = p[0]
  
    setLider(data.nombreLider)
  }

  const handleAuxiliar = (e) => {
 
    setAuxiliar(e)
    const p = auxiliars.filter((item) => item.nombreAuxiliar === e);
    const data = p[0]
  
    setAuxiliar(data.nombreAuxiliar)
  }
  
  const handleSubmit = (id) => {
    
    /* db.collection("orden_de_trabajo").doc(id).set({
      caja: props.caja,
      canton: props.canton,
      cargo: props.cargo,
      cedula: props.cedula,
      celular: props.celular,
      contratar: props.contratar,
      contrato: props.contrato,
      coordenadas: props.coordenadas,
      correo: props.correo,
      created: props.created,
      debito: props.debito,
      direccion: props.direccion,
      discapacidad: props.discapacidad,
      estado: props.estado,
      movil: props.movil,
      nacionalidad: props.nacionalidad,
      nombres: props.nombres,
      numContrato: props.numContrato,
      numbRef: props.numbRef,
      numeroContrato: props.numeroContrato,
      numeroSolicitud: props.numeroSolicitud,
      observacion: props.observacion,
      observacion2: props.observacion2,
      paramsDiscapacidad: props.paramsDiscapacidad,
      parroquia: props.parroquia,
      permanencia: props.permanencia,
      planNombre: props.planNombre,
      planValor: props.planValor,
      provincia: props.provincia,
      sector: props.sector,
      tarifa: props.tarifa,
      telefono: props.telefono,
      terceraEdad: props.terceraEdad,
      uid: props.uid,
      user_uid: props.user_uid,
      vendedor: props.vendedor,
      whatsapp: props.whatsapp,

      fechaHora: props.fechaHora,
      nombreLider: props.nombreLider,
      nombreAuxiliar: props.nombreAuxiliar,
      numFlota: props.numFlota,
      estado: 0
    })
      .then(() => {
        setOpen(false)
        Swal.fire({ icon: "success", text: "Se ha realizado el proceso con exito", });
      })
      .catch((error) => { console.error("Error adding document: ", error); }); */

      db.collection("tecnicos").doc(id).update({
        caja: props.caja,
        canton: props.canton,
        cargo: props.cargo,
        cedula: props.cedula,
        celular: props.celular,
        contratar: props.contratar,
        contrato: props.contrato,
        coordenadas: props.coordenadas,
        correo: props.correo,
        created: props.created,
        debito: props.debito,
        direccion: props.direccion,
        discapacidad: props.discapacidad,
        estado: props.estado,
        movil: props.movil,
        nacionalidad: props.nacionalidad,
        nombres: props.nombres,
        numContrato: props.numContrato,
        numbRef: props.numbRef,
        numeroContrato: props.numeroContrato,
        numeroSolicitud: props.numeroSolicitud,
        observacion: props.observacion,
        observacion2: props.observacion2,
        paramsDiscapacidad: props.paramsDiscapacidad,
        parroquia: props.parroquia,
        permanencia: props.permanencia,
        planNombre: props.planNombre,
        planValor: props.planValor,
        provincia: props.provincia,
        sector: props.sector,
        tarifa: props.tarifa,
        telefono: props.telefono,
        terceraEdad: props.terceraEdad,
        uid: props.uid,
        user_uid: props.user_uid,
        vendedor: props.vendedor,
        whatsapp: props.whatsapp,

        fechaHora: props.fechaHora,
        nombreLider: props.nombreLider,
        nombreAuxiliar: props.nombreAuxiliar,
        numFlota: props.numFlota,
        estado: 0
      }).then(() => {
        setOpen(false)
        Swal.fire({ icon: "success", text: "Se ha asignado la instalacion", });
      })
      .catch((error) => { console.error("Error adding document: ", error); });

  }
  return (
    <>
      <Tooltip title="Asignar instalacion">
        <Button onClick={() => setOpen(true)}>
          <EditIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle><strong>Asignar orden de trabajo y asignar instalacion</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
           
            {/* Contrato */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Contrato"
                value={props.numContrato}
              />
            </Box>

            {/* Tipo contrato */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Tipo de contrato"
                value={props.contrato}
              />
            </Box>

            {/* Nombres */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Cliente"
                value={props.nombres}
              />
            </Box>

            {/* Cedula */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Cedula"
                value={props.cedula}
              />
            </Box>

            {/* Tercera edad */}
            {
                props.terceraEdad === 1 ? 
                <Box py={2}>
                <TextField
                  fullWidth
                  label="Tercera edad"
                  value='SI'
                />
                </Box> : 
                <Box py={2}>
                <TextField
                fullWidth
                label="Tercera edad"
                value='NO'
              /> 
              </Box>
            }

            {/* Discapacidad */}
            {
                props.discapacitado === 1 ? 
                <Box py={2}>
                <TextField
                  fullWidth
                  label="Discapacidad"
                  value='SI'
                />
                </Box> : 
                <Box py={2}>
                <TextField
                fullWidth
                label="Discapacidad"
                value='NO'
              /> 
              </Box>
            }

            {/* Grado Discapacidad */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Grado discapacidad"
                value={
                  props.paramsDiscapacidad === "" ? "NINGUNO" : props.paramsDiscapacidad.discapacidad
                }
              />
            </Box>

            {/* Descuento */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Descuento"
                value={
                  props.paramsDiscapacidad === "" ? "NINGUNO" : props.paramsDiscapacidad.descuento
                }
              />
            </Box>

            {/* Valor descuento */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Valor Descuento"
                value={
                  props.paramsDiscapacidad === "" ? "NINGUNO" : props.paramsDiscapacidad.valor
                }
              />
            </Box>

            {/* Direccion */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Direccion"
                value={props.direccion}
              />
            </Box>
            
            {/* Telefono */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Telefono"
                value={props.telefono}
              />
            </Box>

            {/* Celular */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Celular"
                value={props.celular}
              />
            </Box>

            {/* Whatsapp */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Whatsapp"
                value={props.whatsapp}
              />
            </Box>

            {/* Numero Referencia */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Numero Referencia"
                value={props.numbRef}
              />
            </Box>

            {/* Plan Contratado */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Plan Contratado"
                value={props.planNombre}
              />
            </Box>

            {/* Numero Flota */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Numero Flota"
                value={props.numFlota}
              />
            </Box>

            {/* Nombre Lider */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Nombre Lider Flota"
                value={props.nombreLider}
              />
            </Box>

            {/* Nombre Auxiliar Flota*/}
            <Box py={2}>
              <TextField
                fullWidth
                label="Nombre Auxiliar Flota"
                value={props.nombreAuxiliar}
              />
            </Box>

            {/* Valor Plan */}
            <Box py={2}>
              <TextField
                fullWidth
                label="Valor del plan"
                value={props.planValor}
              />
            </Box>
            

          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}><strong>Ok</strong></Button>
          {/* <Button onClick={() => handleSubmit(props.id)} variant="contained"> */}
          {/* <Button onClick={() => handleSubmit(props.id)} variant="contained"> */}
          {/* <Button onClick={() => setOpenConfirm(true)} variant="contained">
            {" "}
            Asignar instalacion
          </Button> */}
        </DialogActions>
      </Dialog>
      {/* ---------------------------------------------------------- */}
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xs">
        <DialogTitle><strong>¿Esta seguro de asignar la instalacion?</strong></DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
          <Button onClick={() => handleSubmit(props.id)} variant="contained">
            {" "}
            Asignar instalacion
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
