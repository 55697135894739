import { IconButton, Button, Typography, Paper, Chip, Box, Collapse, } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../firebase";
import moment from 'moment'
import { auth } from '../../firebase'
import * as XLSX from 'xlsx/xlsx.mjs'
import { useSelector } from 'react-redux';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DatePicker from "react-datepicker";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchInput from '../../components/utils/SearchInput';
import { DataGrid, GridToolbarContainer, GridToolbarExportContainer, GridCsvExportMenuItem, GridToolbarFilterButton, esES, GridToolbarExport } from '@mui/x-data-grid';
import { ViewPdfClient } from "../../components/clientes/ViewPdfClient";
export const InstalacionesRealizadas = () => {
  const userAuth = useSelector(state => state.userAuth)
  const { userInfo } = userAuth
  const [open, setOpen] = useState(false)
  const [data, setData] = useState()
  const [search, setSearch] = useState('')
  const current = new Date();
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  useEffect(() => {
    LoadData()
  }, [])
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("tecnicos").where("estado", "==", 2);
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('fechaInstalacionVenta', '>=', startfulldate)
      ref = ref.where('fechaInstalacionVenta', '<=', endfulldate)
      let ref1 = db.collection("tecnicos").where("estado", "==", 2)
                  .where('fechaDeInstalacion', '>=', startfulldate)
      ref1 = ref1.where('fechaDeInstalacion', '<=', endfulldate)
      console.log(startDate, 'fecha1')
      console.log(endDate, 'fecha2')
      //Si tiene algun doc anterior se agrega acontinuacion
      if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email)
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ]
        ref1.onSnapshot((snapshot)=>{
          const data2 = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ]
          data.concat(data2)
        })
        setData(data)
      })
    }
  };

  function CustomFooterTotalComponent(props) {
    console.log(props)
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  const columns = [
    { field: 'id', headerName: 'CONTRATO', width: 200 },

    {
      field: 'motivo', headerName: 'MOTIVO', width: 175,
      renderCell: (params) => {
        return params.row.cambio_domicilio === true ?
          <Chip label="CAMBIO DOMICILIO" size="small" color="info" />
          : <Chip label="INSTALACION" size="small" color="success" />
      }
    },

    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
    { field: 'premios', headerName: 'PREMIOS', width: 240 },
    { field: 'camara', headerName: 'CAMARA', width: 200 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'numCam', headerName: 'NUM CAMERA', width: 150 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 150,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 150 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 150 },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },

    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'referido', headerName: 'ES REFERIDO', width: 150,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },

    {
      field: 'flotaReferida', headerName: 'VENTA REFERIDA POR FLOTA ', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },
    {
      field: 'created', headerName: 'FECHA AGENDAMIENTO', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    {
      field: 'created_instalacion_foto', headerName: 'FECHA DE INSTALACION ', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && new Date(value.seconds * 1000)
    },
    {
      field: 'fechaInstalacionVenta', headerName: 'FECHA DE SERVICIO', type: 'dateTime', width: 250,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
    },
    /* {
      field: 'fecha_servicio', headerName: 'FECHA DE SERVICIO', type: 'dateTime', width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.cambio_domicilio !== undefined &&
              <a>{params.row.fechaInstalacionCambio && moment(new Date(params.row.fechaInstalacionCambio.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')}
              </a>
            }
            {params.row.cambio_domicilio === undefined &&
              <a>{params.row.fechaInstalacionVenta && moment(new Date(params.row.fechaInstalacionVenta.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')}
              </a>
            }
          </>
        )
      }
    }, */
    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'DSerie', headerName: 'DSERIE', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    { field: 'valorCobrado', headerName: 'VALOR COBRADO', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    // {
    //   field: 'pdf', headerName: 'HOJA', width: 175,
    //   renderCell: (params) => {
    //     return <ViewPdfClient props={params.row} />
    //   }
    // },
    { field: 'cambio_domicilio', headerName: 'CAMBIO DE DOMICILIO', width: 100 },
  ]

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    function instalacion(fechaHora) {
      if (fechaHora && fechaHora.seconds) {
        return moment(new Date(fechaHora.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss A')
      }
    }



    //
    const reData = [
      ...data.map(doc => {
        return {
          NUM_CONTRATO: doc.id,
          MOTIVO: doc.cambio_domicilio === true ? 'CAMBIO DOMICILIO' : 'INSTALACION',
          TIPO_CONTRATO: doc.contrato,
          CEDULA: doc.cedula,
          CLIENTE: doc.nombres,
          CORREO: doc.correo,

          TERCERA_EDAD: doc.terceraEdad === 1 ? 'SI' : 'NO',
          DISCAPACIDAD: doc.discapacitado === 1 ? 'SI' : 'NO',
          GRADO_DISCAPACIDAD: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.discapacidad,
          DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.descuento,
          VALOR_DESCUENTO: doc.paramsDiscapacidad === '' ? 'NINGUNO' : doc.paramsDiscapacidad.valor,
          DIRECCION: doc.direccion,
          SECTOR: doc.sector,
          COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,
          TELEFONO: doc.telefono,
          CELULAR: doc.celular,
          WHATSAPP: doc.whatsapp,
          NUM_REFERENCIA: doc.numbRef,
          PLAN: doc.planNombre,
          NUMERO_PLAN: doc.numFlota,
          LIDER: doc.nombreLider,
          FECHA_AGENDA: doc.created.toDate(),
          FECHA_INSTALACION: instalacion(doc.created_instalacion_foto),
          FECHA_SERVICIO: doc.cambio_domicilio !== undefined ? instalacion(doc.fechaInstalacionCambio) : instalacion(doc.fechaInstalacionVenta),
          COORDENADAS: doc.coordenadas,

          PLAN_VALOR: doc.planValor,

          CABLE_INICIO: doc.cableInicio,
          CABLE_FINAL: doc.cableFinaliza,
          FIBRA_UTILIZADA: parseInt(doc.cableInicio) - parseInt(doc.cableFinaliza),
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,

          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,

          VENDEDOR_RESPONSABLE: doc.vendedor,
          NUM_FLOTA: doc.numFlota,
          LIDER_FLOTA: doc.nombreLider,


        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`)
  }

  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>GESTIONES REALIZADAS</strong>
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY h:mm:ss a')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
          <Box display="flex" alignItems='center' >
            <Button onClick={() => setOpen(!open)}>Buscar por fecha {open ? <ExpandLess /> : <ExpandMore />}</Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
          </Collapse>
        </Box>
        <Box display="flex" alignItems='center' py={2}>
          <SearchInput setSearch={setSearch} search={search} />
          <IconButton id="basic-button" onClick={() => setOpen(!open)}>
            <FilterListIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleExport}>
            <SimCardDownloadIcon />
          </IconButton>
        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            density="compact"
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              // Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {

              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};