export const updateFactibilidadReducers = (state = { updateFactibilidad: [] }, action) => {
    switch (action.type) {
        case 'FACTIBILIDAD_UPDATE_REQUEST':
            return { loading: true }
        case 'FACTIBILIDAD_UPDATE_SUCCESS':
            return { loading: false, updateFactibilidad: action.payload }
        case 'FACTIBILIDAD_UPDATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const verifyFactibilidadReducers = (state = { verifyfacti: [] }, action) => {
    switch (action.type) {
        case 'FACTIBILIDAD_VERIFY_REQUEST':
            return { loading: true }
        case 'FACTIBILIDAD_VERIFY_SUCCESS':
            return { loading: false, verifyfacti: action.payload }
        case 'FACTIBILIDAD_VERIFY_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}