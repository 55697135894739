import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";
//  
const firebaseConfig = {
  apiKey: "AIzaSyD3iIFL4g5ArUihW70vh5VNRnJmdk-JOcA",
  authDomain: "sistema-gestion-intercommerce.firebaseapp.com",
  databaseURL: "https://sistema-gestion-intercommerce-default-rtdb.firebaseio.com",
  projectId: "sistema-gestion-intercommerce",
  storageBucket: "sistema-gestion-intercommerce.appspot.com",
  messagingSenderId: "301531622778",
  appId: "1:301531622778:web:df682b11bfa18e6be67ac3",
  // apiKey: "AIzaSyDMPK-1P_DmCQzfVBE1k23tAF0XjzJ-xx0",
  // authDomain: "soei-desarollo.firebaseapp.com",
  // projectId: "soei-desarollo",
  // storageBucket: "soei-desarollo.appspot.com",
  // messagingSenderId: "1012449534441",
  // appId: "1:1012449534441:web:19a514b7a02b9b5a012b78",
}
//Desarollo
/* apiKey: "AIzaSyDMPK-1P_DmCQzfVBE1k23tAF0XjzJ-xx0",
  authDomain: "soei-desarollo.firebaseapp.com",
  projectId: "soei-desarollo",
  storageBucket: "soei-desarollo.appspot.com",
  messagingSenderId: "1012449534441",
  appId: "1:1012449534441:web:19a514b7a02b9b5a012b78", */
//Producion
// apiKey: "AIzaSyD3iIFL4g5ArUihW70vh5VNRnJmdk-JOcA",
// authDomain: "sistema-gestion-intercommerce.firebaseapp.com",
// databaseURL: "https://sistema-gestion-intercommerce-default-rtdb.firebaseio.com",
// projectId: "sistema-gestion-intercommerce",
// storageBucket: "sistema-gestion-intercommerce.appspot.com",
// messagingSenderId: "301531622778",
// appId: "1:301531622778:web:df682b11bfa18e6be67ac3",
// measurementId: "G-92VEC29CF3"
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();
const functions = firebase.functions();
export { db, auth, firebase, functions, storage };