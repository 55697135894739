import React, { useState, useEffect } from 'react'
import { Tabs, Typography, Box, Tab } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { FlotasActivas } from '../../components/flotas/FlotasActivas';
import { FlotasInactivas } from '../../components/flotas/FlotasInactivas';
import { CrearFlotas } from '../../components/flotas/CrearFlotas';
import { FlotasSuspendidas } from '../../components/flotas/FlotasSuspendidas';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function tabs(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const FlotaPage = () => {
  const params = useParams()
  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    console.log(newValue)

  };
  useEffect(() => {
    if (params.id) {
      console.log(typeof params.id)
      //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
      setTab(parseInt(params.id));
    }
  }, [params.id]);
  return (
    <>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1"><strong>FLOTAS</strong></Typography>
      </Box>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="solicitud de servicio">
        <Tab label="Flotas" {...tabs(0)} />
        <Tab label="Flotas Inactivas" {...tabs(1)} />
        <Tab label="Flotas Suspendidas" {...tabs(2)} />
        <Tab label="Crear Nueva Flota" {...tabs(3)} />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <FlotasActivas />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <FlotasInactivas />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <FlotasSuspendidas />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <CrearFlotas />
      </TabPanel>
    </>
  )
}