import { Box, Button, Paper, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
export const DatosInstalacionPage = () => {
    const [idClientMK, setIdClientMK] = useState('')

    const [fechaInstalacion, setFechaInstalacion] = useState('')
    const [datosGpon, setDatosGpon] = useState('')
    const [noFlota, setNoFlota] = useState('')
    const [serie, setSerie] = useState('')
    const [auxiliarInstalacion, setAuxiliarInstalacion] = useState('')
    const [modelo, setModelo] = useState('')
    const [liderInstalacion, setLiderInstalacion] = useState('')
    const [codigoBobina, setCodigoBobina] = useState('')
    const [observacion, setObservacion] = useState('')
    const [cableInicio, setCableInicio] = useState('')
    const [personaQueAgendo, setPersonaQueAgendo] = useState('')
    const [cableFin, setCableFin] = useState('')
    const [potencia, setPotencia] = useState('')
    const [cableUtilizado, setCableUtilizado] = useState('')
    const [puerto, setPuerto] = useState('')
    const [camara, setCamara] = useState('')
    const [noHojaDeInstalacion, setNoHojaDeInstalacion] = useState('')
    const [cantidadAmarrasGrandes, setCantidadAmarrasGrandes] = useState('')
    const [permanencia, setPermanencia] = useState('')
    const [cantidadAmarrasPequenas, setCantidadAmarrasPequenas] = useState('')
    const [cantidadTacos, setCantidadTacos] = useState('')
    const [cantidadGrapas, setCantidadGrapas] = useState('')
    const [cantidadConectoresMecanicos, setCantidadConectoresMecanicos] = useState('')
    const [cantidadOnus, setCantidadOnus] = useState('')
    const [cantidadPernos, setCantidadPernos] = useState('')
    const [cantidadSoportes, setCantidadSoportes] = useState('')
    const [cantidadTransicionesDuplex, setCantidadTransicionesDuplex] = useState('')

    const [coordenadasCaja, setCoordenadasCaja] = useState('')

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>

                        <Box pb={3} >
                            <Paper elevation={3}>
                                <Box py={2} px={2} sx={{ bgcolor: '#cfe8fc' }}>
                                    <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold" }}>DATOS DE INSTALACION</p>
                                </Box>
                            </Paper>
                        </Box>

                        <Paper elevation={3}>
                            <Box py={3} px={3}>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>

                                        {/* TIPO */}

                                        <Box pb={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA DE INSTALACION:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={fechaInstalacion}
                                                        onChange={(e) => setFechaInstalacion(e.target.value)}
                                                        name="tipo"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* NO FLOTA */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>NO FLOTA:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={noFlota}
                                                    onChange={(e) => setNoFlota(e.target.value)}
                                                    name="noFlota"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* AUXILIAR DE INSTALACION */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>AUXILIAR DE INSTALACION:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={auxiliarInstalacion}
                                                        onChange={(e) => setAuxiliarInstalacion(e.target.value)}
                                                        name="auxiliarInstalacion"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* LIDER DE INSTALACION */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>LIDER DE INSTALACION:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={liderInstalacion}
                                                    onChange={(e) => setLiderInstalacion(e.target.value)}
                                                    name="liderInstalacion"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item xs={6}>

                                        <Box pb={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>DATOS / G-PON:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={datosGpon}
                                                        onChange={(e) => setDatosGpon(e.target.value)}
                                                        name="DatosGpon"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* SERIE */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>SERIE:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={serie}
                                                    onChange={(e) => setSerie(e.target.value)}
                                                    name="serie"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* MODELO */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>MODELO:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={modelo}
                                                        onChange={(e) => setModelo(e.target.value)}
                                                        name="modelo"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box py={3} >
                            <Paper elevation={3}>
                                <Box py={2} px={2} sx={{ bgcolor: '#cfe8fc' }}>
                                    <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold" }}>MATERIALES UTILIZADOS</p>
                                </Box>
                            </Paper>
                        </Box>

                        <Paper elevation={3}>
                            <Box py={3} px={3}>
                                <Grid container spacing={5}>
                                    <Grid item xs={6}>

                                        {/* CODIGO BOBINA */}

                                        <Box pb={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CODIGO BOBINA:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={codigoBobina}
                                                        onChange={(e) => setCodigoBobina(e.target.value)}
                                                        name="codigoBobina"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CABLE INICIO */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CABLE INICIO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cableInicio}
                                                    onChange={(e) => setCableInicio(e.target.value)}
                                                    name="cableInicio"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CABLE FIN */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CABLE FIN:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cableFin}
                                                        onChange={(e) => setCableFin(e.target.value)}
                                                        name="cableFin"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CABLE UTILIZADO */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CABLE UTILIZADO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cableUtilizado}
                                                    onChange={(e) => setCableUtilizado(e.target.value)}
                                                    name="cableUtilizado"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CAMARA */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CAMARA:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={camara}
                                                        onChange={(e) => setCamara(e.target.value)}
                                                        name="camara"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CANTIDAD AMARRAS GRANDES */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD AMARRAS GRANDES:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cantidadAmarrasGrandes}
                                                    onChange={(e) => setCantidadAmarrasGrandes(e.target.value)}
                                                    name="cantidadAmarrasGrandes"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CANTIDAD AMARRAS PEQUENAS */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD AMARRAS PEQUENAS:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cantidadAmarrasPequenas}
                                                        onChange={(e) => setCantidadAmarrasPequenas(e.target.value)}
                                                        name="cantidadAmarrasPequenas"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CANTIDAD GRAPAS */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD GRAPAS:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cantidadGrapas}
                                                    onChange={(e) => setCantidadGrapas(e.target.value)}
                                                    name="cantidadGrapas"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CANTIDAD ONUS */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD ONUS:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cantidadOnus}
                                                        onChange={(e) => setCantidadOnus(e.target.value)}
                                                        name="cantidadOnus"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CANTIDAD SOPORTES */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD SOPORTES:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cantidadSoportes}
                                                    onChange={(e) => setCantidadSoportes(e.target.value)}
                                                    name="cantidadSoportes"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid item xs={6}>

                                        {/* OBSERVACION */}

                                        <Box pb={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>OBSERVACION:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={observacion}
                                                        onChange={(e) => setObservacion(e.target.value)}
                                                        name="observacion"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* PERSONA QUE AGENDO */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>PERSONA QUE AGENDO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={personaQueAgendo}
                                                    onChange={(e) => setPersonaQueAgendo(e.target.value)}
                                                    name="personaQueAgendo"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* POTENCIA */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>POTENCIA:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={potencia}
                                                        onChange={(e) => setPotencia(e.target.value)}
                                                        name="potencia"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* PUERTO */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>PUERTO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={puerto}
                                                    onChange={(e) => setPuerto(e.target.value)}
                                                    name="puerto"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* NO HOJA DE INSTALACION */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>NO HOJA DE INSTALACION:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={noHojaDeInstalacion}
                                                        onChange={(e) => setNoHojaDeInstalacion(e.target.value)}
                                                        name="noHojaDeInstalacion"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* PERMANENCIA */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>PERMANENCIA:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={permanencia}
                                                    onChange={(e) => setPermanencia(e.target.value)}
                                                    name="permanencia"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CANTIDAD TACOS */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD TACOS:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cantidadTacos}
                                                        onChange={(e) => setCantidadTacos(e.target.value)}
                                                        name="cantidadTacos"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CANTIDAD CONECTORES MECANICOS */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD CONECTORES MECANICOS:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cantidadConectoresMecanicos}
                                                    onChange={(e) => setCantidadConectoresMecanicos(e.target.value)}
                                                    name="cantidadConectoresMecanicos"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* CANTIDAD PERNOS */}

                                        <Box py={3}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD PERNOS:</p>
                                                </Grid>
                                                <Grid item md={7}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cantidadPernos}
                                                        onChange={(e) => setCantidadPernos(e.target.value)}
                                                        name="cantidadPernos"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* CANTIDAD TRANSICIONES DUPLEX */}

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTIDAD TRANSICIONES DUPLEX:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cantidadTransicionesDuplex}
                                                    onChange={(e) => setCantidadTransicionesDuplex(e.target.value)}
                                                    name="cantidadTransicionesDuplex"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box pt={2}>
                            <Button variant='contained' fullWidth>VER IMAGENES DE INSTALACION</Button>
                        </Box>

                    </Box>
                </Paper>
            </Box>
        </>
    )
}
