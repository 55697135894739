import {
  Container,
  TextField,
  Button,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
  Paper,
  Chip,
  Grid
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import RoomIcon from "@mui/icons-material/Room";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export const UpdatePlanes = ({ props }) => {

  const [open, setOpen] = useState(false)

  const [nombrePlan, setNombrePlan] = useState(props.plan)
  const [codigoPlan, setCodigoPlan] = useState(props.id);
  const [velocidadSubida, setVelocidadSubida] = useState(props.velocidadSubida);

  const [velocidadBajada, setVelocidadBajada] = useState(props.velocidadBajada);

  const [valor, setValor] = useState(props.valor);

  const [comparticion, setComparticion] = useState(props.comparticion);
  const [estado, setEstado] = useState(props.estado);
  const [tipo, setTipo] = useState(props.tipo);


  const handleSubmit = () => {
    db.collection("perfiles")
      .doc(props.id)
      .update({
        plan: nombrePlan,
        velocidadSubida: velocidadSubida,
        velocidadBajada: velocidadBajada,
        valor: parseFloat(valor).toFixed(2),
        comparticion: comparticion,
        tipo: tipo,
        estado: estado
      })
      .then((docRef) => {
        setOpen(false)
        Swal.fire({
          icon: "success",
          title: "Se ha actualizado el plan exitosamente.",
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }

  return (
    <>
      <Tooltip title="Editar">
        <Button onClick={() => setOpen(true)}>
          <EditIcon />
        </Button>
      </Tooltip>


      <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>EDICION DE: {props.plan}</DialogTitle>

        <DialogContent>
          {/* <Box py={1}>
              <TextField
                fullWidth
                label="Nombre Plan"
                value={nombreplan}
                onChange={(e) => setNombreplan(e.target.value)}
              />
            </Box>
  
            <Box py={1}>
              <TextField
                fullWidth
                label="Codigo Plan"
                value={codigoPlan}
                onChange={(e) => setCodigoPlan(e.target.value)}
              />
            </Box>
  
            <Box py={1}>
              <TextField
                fullWidth
                label="Velocidad subida"
                value={velocidadSubida}
                onChange={(e) => setVelocidadSubida(e.target.value)}
              />
            </Box>
  
            <Box py={1}>
              <TextField
                fullWidth
                label="Velocidad bajada"
                value={velocidadBajada}
               onChange={(e) => setVelocidadbajada(e.target.value)}
              />
            </Box>
  
            <Box py={1}>
              <TextField
                fullWidth
                label="Valor"
                value={valor}
               onChange={(e) => setValor(e.target.value)}
              />
            </Box>
  
            <Box py={1}>
            <FormControl fullWidth>
              <InputLabel>Comparticion</InputLabel>
              <Select
                value={comparticion}
                onChange={(e) => setComparticion(e.target.value)}
              >
                <MenuItem value="1:1">1:1</MenuItem>
                <MenuItem value="2:1">2:1</MenuItem>
              </Select>
              
            </FormControl>
            </Box>
  
            <Box py={1}>
                <FormControl fullWidth>
              <InputLabel>Tipo*</InputLabel>
              <Select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                <MenuItem value="CYBER">CYBER</MenuItem>
                <MenuItem value="PYMES">PYMES</MenuItem>
                <MenuItem value="HOME">HOME</MenuItem>
              </Select>
            </FormControl>
            </Box>
  
            <Box py={1}>
            <FormControl fullWidth >
                
                <InputLabel>Estado</InputLabel>
                <Select value={estado} onChange={(e) => setEstado(e.target.value)}>
                  <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                  <MenuItem value="VIGENTE">VIGENTE</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
          <Paper elevation={3}>
            <Box py={2} px={2}>
              <Grid container spacing={7}>
                <Grid item xs={10} md={6}>

                  <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NOMBRE DEL PLAN:</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                    value={nombrePlan}
                    onChange={(e) => setNombrePlan(e.target.value)}
                    name="NombreDelPlan"
                    size="small"
                    fullWidth
                  />

                  <Box py={4}>
                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE SUBIDA:</strong></p>
                    <TextField sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                    }}
                      value={velocidadSubida}
                      onChange={(e) => setVelocidadSubida(e.target.value)}
                      name="VelocidadDeSubida"
                      size="small"
                      fullWidth
                    />
                  </Box>

                  <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VALOR DEL PLAN:</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                    value={valor}
                    onChange={(e) => setValor(e.target.value)}
                    name="valorDelPlan"
                    size="small"
                    fullWidth
                  />

                  <Box py={4}>
                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>TIPO:</strong></p>
                    <FormControl fullWidth>
                      <Select size="small" value={tipo} onChange={(e) => setTipo(e.target.value)}>
                        <MenuItem value="COORPORATIVO">COORPORATIVO</MenuItem>
                        <MenuItem value="PYMES">PYMES</MenuItem>
                        <MenuItem value="HOME">HOME</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>


                {/* *********************************** */}
                <Grid item xs={10} md={6}>


                  <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CODIGO DEL PLAN:</strong></p>
                  <TextField sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                  }}
                    value={codigoPlan}
                    disabled={true}
                    name="codigoPlan"
                    size="small"
                    fullWidth
                  />

                  <Box py={4}>
                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VELOCIDAD DE BAJADA:</strong></p>
                    <TextField sx={{
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                    }}
                      value={velocidadBajada}
                      onChange={(e) => setVelocidadBajada(e.target.value)}
                      name="velocidadDeBajada"
                      size="small"
                      fullWidth
                    />
                  </Box>

                  <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>COMPARTICION:</strong></p>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      value={comparticion}
                      onChange={(e) => setComparticion(e.target.value)}
                    >
                      <MenuItem value="1:1">1:1</MenuItem>
                      <MenuItem value="2:1">2:1</MenuItem>
                    </Select>

                  </FormControl>

                  <Box py={4}>
                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                    <FormControl fullWidth>
                      <Select size="small" value={estado} onChange={(e) => setEstado(e.target.value)}>
                        <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                        <MenuItem value="VIGENTE">VIGENTE</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                </Grid>

              </Grid>

              {/* <Button
                onClick={() => onsubmit()}
                disabled={nombrePlan === ''
                  || codigoPlan === ''
                  || velocidadBajada === ''
                  || velocidadSubida === ''
                  || valor === ''
                  || comparticion === ''
                  || estado === ''
                  || tipo === ''}
                fullWidth
                variant="contained"
                color="secondary"
              >
                {" "}
                CREAR{" "}
              </Button> */}
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" size="small" onClick={() => setOpen(false)}>Regresar</Button>
          <Button onClick={() => handleSubmit()}
            disabled={nombrePlan === ''
              || codigoPlan === ''
              || velocidadBajada === ''
              || velocidadSubida === ''
              || valor === ''
              || comparticion === ''
              || estado === ''
              || tipo === ''}
            variant="contained"
            color="secondary"
            size="small"
          >ACTUALIZAR
          </Button>
        </DialogActions>
      </Dialog>



    </>
  );
};
