import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../../firebase";

export const DeleteSector = ({ props, provincia, canton }) => {
    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const deleteProducto = async () => {
        db.collection("parameters").doc("cantones").collection("provincias").doc(provincia).collection("canton").doc(canton).collection("parroquia").doc("TODOS").collection("sector").doc(props.id).delete()
    }

    const handleConfirm = () => {
        deleteProducto()
        setOpen(false)
        Swal.fire({ icon: "success", text: 'Se ha eliminado el sector con exito', });
    }

    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color="error" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="xs">
                <DialogTitle>ESTA SEGURO DE ELIMINAR EL SECTOR: <strong> {props.nombre}</strong>
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined" color="error" size="small"><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        handleConfirm()
                        /* datosFlota() */
                    }} variant="contained"
                        color="error"
                    >
                        {" "}
                        ELIMINAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};