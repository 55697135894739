import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
export const ListClients = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'clientes']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Clientes" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>
                        <ListItemButton component={RouterLink} to={`clientes/usuarios/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Usuarios" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`clientes/correos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Correos" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`clientes/family_card/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Family Card" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`clientes/luktv/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="LuKtv" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`clientes/agenda-verificacion/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Agenda Verificacion" />
                        </ListItemButton>
                        {/* <ListItemButton component={RouterLink} to={`clientes/gestionClientes/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Clientes" />
                        </ListItemButton> */}
                        <ListItemButton component={RouterLink} to={`clientes/pagos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Pago Instalacion Clientes" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`clientes/pagos/debito`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Pago Debito Clientes" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`clientes/carga-masiva`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga Masiva" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`clientes/carga-facturas`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Carga Facturas" />
                        </ListItemButton>

                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}