import { db, firebase } from '../../../firebase'
import Swal from 'sweetalert2';
export const updateFactibilidad = (solicitudId, data) => async (dispatch) => {
    try {
        dispatch({ type: 'FACTIBILIDAD_UPDATE_REQUEST' })
        const solicitudDb = await db.collection('service_request').doc(`${solicitudId}`).update({
            coordenadas: new firebase.firestore.GeoPoint(data.latitude, data.longitude),
            cajas: data.cajas,
        }).then(() => {
            Swal.fire({ icon: 'success', title: 'Se actualizaron los datos' })
        }).catch((error) => { Swal.fire({ icon: 'error', title: error, }) })
        dispatch({
            type: 'FACTIBILIDAD_UPDATE_SUCCESS',
            payload: solicitudDb
        })
    } catch (error) {
        dispatch({
            type: 'FACTIBILIDAD_UPDATE_FAIL',
            payload: error
        })
    }
}
export const verificarFactibilidad = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'FACTIBILIDAD_VERIFY_REQUEST' })
        db.collection("solicitudes").doc(formData.id_).update(formData).then((docRef) => {
            Swal.fire({ icon: "success", text: "Se ha actualizado la informacion exitosamente." })
            dispatch({
                type: 'FACTIBILIDAD_VERIFY_SUCCESS',
                payload: docRef
            })
        }).catch((error) => {
            console.error("Error adding document: ", error);
        })
    } catch (error) {
        dispatch({
            type: 'FACTIBILIDAD_VERIFY_FAIL',
            payload: error
        })
    }
}