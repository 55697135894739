import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Typography, Divider } from '@mui/material'
import React, { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import InputValidation from '../utils/InputValidation'
import { FileUpload } from '../utils/FileUpload';
import Swal from 'sweetalert2';
import { db, functions } from '../../firebase'

export default function VerificarVoucher({ props }) {
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false)

    //
    useEffect(() => {

    }, [])

    const onSubmit = () => {
        if (window.confirm("¿Estas seguro de enviar el voucher a verificacion?") == true) {
            db.collection('agenda').doc(data.numContrato).update({
                estado_pagado: 1
            }).then((res) => {
                setOpen(false)
                Swal.fire({ icon: "success", text: "El voucher se envio a verificacion", });
            })
        }
    }
    const onData = () => {
        setOpen(true)
        console.log(props)
        setData(props)
    }
    const onVeri = () => {
        if (window.confirm("¿Estas seguro de enviar como verificado?") == true) {
            setOpen(false)
            db.collection('agenda').doc(data.numContrato).update({
                estado_pagado: 2
            }).then((res) => {
                
                Swal.fire({ icon: "success", text: "El voucher se verifico", });
            })
            db.collection('tecnicos').doc(data.numContrato).update({
                estado_pagado: 2
            })
        }
    }
    const onNeg = (id_cliente) => {
        if (window.confirm("¿Estas seguro de rechazar?") == true) {
            setOpen(false)
            db.collection('agenda').doc(data.numContrato).update({
                estado_pagado: 3,
                observacionInstalacionRechazada: 'No tiene dinero cliente'
            }).then((res) => {
                
                Swal.fire({ icon: "success", text: "El voucher se rechazo", });
            })
            db.collection('tecnicos').doc(data.numContrato).update({
                estado_pagado: 3,
                observacionInstalacionRechazada: 'No tiene dinero cliente'
            })
            //
            console.log(id_cliente)
            const datad = {
                id_cliente: id_cliente
            }
            const deleteUser = functions.httpsCallable('deleteUser')
            deleteUser(datad)
                .then(res => {
                    console.log('Se eliminio usuario: ', res)
                    
                })
                .catch(error => console.log(error))
        }
        console.log(props)

    }
    return (
        <>
            <Button
                onClick={() => onData()}
            >
                <EditIcon />
            </Button>
            <Dialog

                fullScreen
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>VERIFICAR VOUCHER {data.numContrato}</DialogTitle>

                <DialogContent dividers>
                    <Typography align='center' gutterBottom>NOMBRE: {data.nombres}</Typography>
                    <Typography align='center' gutterBottom>USER ID MICROWISP: {data.id_cliente}</Typography>
                    <Typography align='center' gutterBottom>ID FACTURA: {data.id_factura}</Typography>

                    <Typography align='center' gutterBottom>ID VAOUCHER: {data.id_factura}</Typography>
                  
                    <Typography align='center' gutterBottom>NUMERO DE PAGO: {data.num_pago}</Typography>

                    
                    {props && props.foto_foto_vaucher &&
                        <img width='100%' src={props.foto_foto_vaucher} />
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                    >Cerrar ventana</Button>
                    <Button
                        color='error'
                        variant='contained'
                        onClick={() => onNeg(data.id_cliente)}
                    >RECHAZAR VERIFICACION</Button>
                    <Button
                        color='success'
                        variant='contained'
                        onClick={() => onVeri()}
                    >ACEPTAR VERIFICACION</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}
