import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Grid, Box, InputLabel, Button, Select, FormControl, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";
export const NuevaSubCategoria = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { marcas, loading: loadingMarcas } = useSelector(state => state.marcasList)
    const [subcategoria, setSubCategoria] = useState({ campo: "", valido: true });

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createProduct = async (subcategoria) => {
        await db.collection('subcategorias_productos').doc().set({
            nombre_subcategoria_producto: subcategoria,
            fecha_creacion_subcategoria_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nueva subcategoria creada con exito" });
        })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={subcategoria}
                cambiarEstado={setSubCategoria}
                label="SUBCATEGORIA"
                name="subcategoria"
            />
            <br />

            <Button
                onClick={() => createProduct(subcategoria.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !subcategoria.campo}
            >
                {" "}
                CREAR NUEVA SUBCATEGORIA{" "}
            </Button>
        </>
    );
};
