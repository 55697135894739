import {
  Table, TableContainer, TableRow, TableCell, TableBody,
  TableHead, Paper, Chip, Button, Box, Collapse, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { auth, db, firebase } from '../../../firebase'
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import { FeasibilityGistAccept } from './components/FeasibilityGistAccept';
import IconButton from '@mui/material/IconButton';

import FilterListIcon from '@mui/icons-material/FilterList';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import * as XLSX from "xlsx/xlsx.mjs";

export const SolicitudesAprobadas = () => {

  const [total, setTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState()
  const [isLastPage, setIsLastPage] = useState(0)
  const [queryDoc, setQueryDocqueryDoc] = useState([])
  const [reData, setreData] = useState([]);
  const [search, setSearch] = useState('')
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const onChange2 = () => {
    LoadData()
  }

  useEffect(() => {
    reDatamod()
    LoadData()
  }, [])
  const reDatamod = (e) => {
    if (data) {

      const data2 = [
        ...data.map(doc => {
          return {
            ...doc,
            //created: doc.created.toDate(),
            latitud: doc.coordenadas._lat,
            longitud: doc.coordenadas._long,
          }
        })
      ]
      console.log('Data modificada', data2)
      setreData(data2)

    }
  }
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  const LoadData = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("solicitudes").orderBy('created', 'desc').where('estado', 'in', [2, 3])

    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 0, 0)
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where('created', '>=', startfulldate)
      ref = ref.where('created', '<=', endfulldate)

      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
      ref.onSnapshot(snapshot => {
        const totalCount = snapshot.size
        const data = [
          ...persistMessages,
          ...snapshot.docs.map(doc => {
            return {
              ...doc.data(),
              id: doc.id,
            }
          })
        ]
        setData(data)
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
        setIsLastPage(totalCount < 1)
      })
    }
  }

  function subtractDate(created, gis) {
    //
    //console.log('Hola', created.toDate())
    //const createdMoment = moment(created.toDate()).format("MMMM Do YYYY, h:mm:ss a")
    var date = moment("20170101", "YYYYMMDD");
    //console.log(createdMoment)
    var date7 = moment("20170108", "YYYYMMDD");
    var mins7 = moment("20170101 00:07", "YYYYMMDD HH:mm");
    var secs1 = moment("20170101 00:00:01", "YYYYMMDD HH:mm:ss");

    console.log(date7.diff(date, "days") + "d"); // "7d"
    console.log(mins7.diff(date, "minutes") + "m"); // "7m"
    console.log(secs1.diff(date, "seconds") + "s"); // "1s"
    return (
      <>a</>
    )
  }
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado == 0) {
      name = 'Esperando..'
      color = 'warning'
    } else if (estado === 1) {
      name = 'No factible'
      color = 'info'
    } else if (estado === 2) {
      name = 'FACTIBILIDAD'
      color = 'success'
    }else if (estado === 3) {
      name = 'GESTIONADA'
      color = 'info'
    }
    return (
      <Chip label={name} color={color} />
    )
  }
  const columns = [
    {
      field: 'created', headerName: 'FECHA DE CREACION SOLICITUD', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'estado', headerName: 'ESTADO', width: 150,
      renderCell: (params) => {
        return       <Estado estado={params.row.estado} />
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 140 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 300 },
    { field: 'fechaNacimiento', headerName: 'FECHA DE NACIMIENTO', width: 200 },
    { field: 'direccion', headerName: 'DIRECCION', width: 450 },
    { field: 'canton', headerName: 'CANTON', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 220,
      valueGetter: ({ value }) => `${value._lat}, ${value._long}`
    },
    { field: 'caja', headerName: 'CAJA', width: 140 },
    { field: 'observacion2', headerName: 'OBSERVACIONES', width: 250 },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR	', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      renderCell: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    {
      field: 'createdGis', headerName: 'HORA FACTIBILIDAD', type: 'dateTime', width: 190,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'usuario_que_dio_factibilidad', headerName: 'USUARIO QUE DIO FACTIBILIDAD', width: 250 },
    { field: 'referido', headerName: 'REFERIDO', width: 130 },
    /* {
      field: 'referido', headerName: 'REFERIDO', width: 100,
      valueGetter: ({ value }) => value === undefined || value === null || value === '' ? '' : value
    }, */
    {
      field: '', headerName: 'ACCIONES', width: 90,
      renderCell: (params) => {
        return <FeasibilityGistAccept props={params.row} />
      }
    },
  ];

  const csvOptions = {
    delimiter: ';',
    fileName: `SOLICITUD APROBADAS ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  }
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )



  function CustomFooterTotalComponent(props) {
    return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
  }

  const handleExport = () => {
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let seconds = date.getSeconds()
    var date1
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
    }
    //
    const reData = [
      ...data.map(doc => {
        return {

          FECHA_CREACION_SOLICITUD: moment(doc.created.toDate().toString()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),

          ESTADO: doc.estado === 2 ? 'APROBADO' : 'GESTIONADA',

          CEDULA: doc.cedula,

          NOMBRE_DEL_CLIENTE: doc.nombres,

          FECHA_DE_NACIMIENTO: doc.fechaNacimiento,

          DIRECCION: doc.direccion,

          COORDENADAS: `${doc.coordenadas._lat}, ${doc.coordenadas._long}`,

          CAJA: doc.caja,

          OBSERVACIONES: doc.observacion2,

          HORA_FACTIBILIDAD: moment(doc.createdGis.toDate().toString()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ) === undefined || moment(doc.createdGis.toDate().toString()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ) === null ? '' : moment(doc.createdGis.toDate().toString()).format(
            "MMMM Do YYYY, h:mm:ss a"
          ),

          USUARIO_QUE_DIO_FACTIBILIDAD: doc.usuario_que_dio_factibilidad === undefined
            || doc.usuario_que_dio_factibilidad === null
            || doc.usuario_que_dio_factibilidad === ''
            ? ''
            : doc.usuario_que_dio_factibilidad,

          /* DURACION_SOLICITUD: moment(doc.created).diff(doc.createdGis, 'days'), */

          ES_REFERIDO: doc.referido,

          VENTA_REFERIDA_POR_FLOTA: doc.referido === 'SI' ? doc.flotaReferida.numeroFlota : 'NINGUNA',

        }
      })
    ]
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "reporte_stock");
    XLSX.writeFile(wb, `REPORTE_STOCK_${date1}.xlsx`)
  }

  return (
    <>
      <Box pt={1} pl={1}>
        <Typography >
          <strong>({data && data.length})</strong> SOLICITUDES APROBADAS 5
        </Typography >
        <Box display='flex' style={{ textTransform: 'uppercase' }} >
          <Typography variant="button" >
            <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('MMMM Do YYYY')}
          </Typography >
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} pt={1} display="flex" alignItems='center' >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems='center' py={2}>
     
            <IconButton id="basic-button" onClick={() => setOpen(!open)}>
              <FilterListIcon />
            </IconButton>
            {/* <Button color='success' variant='outlined' size='small' onClick={() => handleExport()}>
              Descargar excel
            </Button> */}
            {/* <ExportarCSV data={data} /> */}
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button size='small' disableElevation variant='contained' onClick={onChange2}>Aplicar busqueda</Button>
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Paper>
        <Box px={2} py={2} sx={{ height: 800, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={data ? data : []}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,

            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>

      {/* <Paper sx={{ maxWidth: { xs: 340, sm: '100%' }, overflow: 'hidden' }} >
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>FECHA DE CREACION DE SOLICITUD</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>FECHA DE NACIMIENTO</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>CANTON</TableCell>
                <TableCell>COORDENADAS</TableCell>
                <TableCell>CAJA</TableCell>
                <TableCell>OBSERVACIONES</TableCell>
                <TableCell>HORA FACTIBILIDAD</TableCell>
                <TableCell>USUARIO QUE DIO FACTIBILIDAD</TableCell>
                <TableCell>ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA</TableCell>
                <TableCell>ACCIONES</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.filter((val) => {
                if (search === '') {
                  return val
                } else if (val.nombres.toLowerCase().includes(search.toLowerCase())) {
                  return val
                } else if (val.cedula.toLowerCase().includes(search.toLowerCase())) {
                  return val
                }
              }).map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.created &&
                      typeof row.created === "number" &&
                      moment(new Date().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    {row.created &&
                      typeof row.created === "object" &&
                      moment(row.created.toDate().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Estado estado={row.estado} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.cedula}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.nombres}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.fechaNacimiento}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.direccion}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.canton}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.coordenadas._lat},{row.coordenadas._long}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.caja}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.observacion2}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.createdGis &&
                      typeof row.createdGis === "number" &&
                      moment(new Date().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    {row.createdGis &&
                      typeof row.createdGis === "object" &&
                      moment(row.createdGis.toDate().toString()).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {
                      row.usuario_que_dio_factibilidad === undefined
                        || row.usuario_que_dio_factibilidad === null
                        || row.usuario_que_dio_factibilidad === ''
                        ? ''
                        : row.usuario_que_dio_factibilidad}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.referido === '' ? 'NO' : row.referido}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.referido === 'SI' ? row.flotaReferida.numeroFlota : 'NINGUNA'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FeasibilityGistAccept props={row} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper> */}
    </>
  )
}