import {
    Box, Typography, Button, TableHead, Paper, Chip, IconButton, Collapse, Hidden, Stack
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx/xlsx.mjs";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES,
    GridToolbarExport
} from '@mui/x-data-grid';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export const TicketsAprobados = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    function Estado(estado) {
        const d = estado.estado
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (d.estado === 0) {
            name = 'POR INSTALAR'
            color = 'warning'
        }
        if (d.cambio_domicilio === true) {
            name = 'CAMBIO DOMICILIO'
            color = 'info'
        }
        return (
            <Chip label={name} size="small" variant="outlined" color={color} />
        )
    }
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    var totalInstalaciones;
    const [nombreZona, setNombreZona] = useState();
    const [codigoZona, setCodigoZona] = useState("");
    const [data, setData] = useState();
    const [data2, setData2] = useState();
    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])
    const [isLastPage2, setIsLastPage2] = useState(0);
    const [queryDoc2, setQueryDocqueryDoc2] = useState([])
    const [search, setSearch] = useState('')
    const [total, setTotal] = useState(0);
    //
    var current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const LoadData = (startAfterDoc, persistMessages = []) => {

        let ref = db.collection("tickets").where('estado', '==', 1);

        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
            const totalCount = snapshot.size
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setData(data)
            setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
            setIsLastPage(totalCount < 1)
        })

    };

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData()
    }, [dispatch])
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        //LoadData()
    }

    /* const LoadData = (startAfterDoc, persistMessages = []) => {
  
      let ref = db.collection("productos").where("estado_producto", "==", 0);
  
      //Si tiene algun doc anterior se agrega acontinuacion
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
  
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("fecha_creacion_producto", '>=', startfulldate)
        ref = ref.where("fecha_creacion_producto", '<=', endfulldate)
  
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
          setData(data)
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
          setIsLastPage(totalCount < 1)
        })
      }
    }; */

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const columns = [
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="RESPONDIDO" color="success" />
            }
        },
        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 120 },
        { field: 'correo', headerName: 'CORREO', width: 220 },
        { field: 'direccion', headerName: 'DIRECCION', width: 350 },
        { field: 'zona', headerName: 'ZONA', width: 350 },
        { field: 'coordenadas', headerName: 'COORDENADAS', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 130 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        { field: 'flota', headerName: 'FLOTA', width: 100 },
        { field: 'lider_flota', headerName: 'LIDER', width: 280 },
        { field: 'auxiliar_flota', headerName: 'AUXILIAR', width: 250 },
        {
            field: 'fecha_gestion_ticket_operaciones', headerName: 'FECHA RESPUESTA', type: 'dateTime', width: 170,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'usuario_que_asigno_flota', headerName: 'USUARIO QUE RESPONDIO', width: 250 },
        /* {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <ViewTransferenciaActivosFijos props={params.row} />
            }
        }, */

    ];

    const csvOptions = { delimiter: ';' };
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarExport /> */}
            <Button onClick={() => handleExport()}>
                EXPORTAR
            </Button>
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const esVerdadero = 0;

    const handleExport = () => {
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        let hours = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        var date1
        if (month < 10) {
            date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`
        } else {
            date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`
        }
        //
        const reData = [
            ...data.map(doc => {
                return {
                    ESTADO: 'RESPONDIDO',
                    VISITA_REALIZADA: doc.visita_realizada === 0 ? 'EN ESPERA' : (doc.visita_realizada === 1 ? 'REALIZADA' : ''),
                    ID_TICKET: doc.uid_ticket,
                    LINEA_CONTRATO: doc.linea_contrato,
                    NOMBRE_CLIENTE: doc.nombre_cliente,
                    TELEFONO: doc.telefono,
                    MOVIL: doc.celular,
                    CORREO: doc.correo,
                    DIRECCION: doc.direccion,
                    AGENDADO_DESDE: doc.agendado_desde,
                    DEPARTAMENTO: doc.agendado_desde,
                    ASUNTO: doc.asunto_del_ticket,
                    VALOR: doc.valor_a_cobrar,
                    DETALLES_TICKET: doc.detalles_del_ticket,
                    TURNO: doc.turno,
                    FLOTA: doc.flota,
                    LIDER: doc.lider_flota,
                    AUXILIAR: doc.auxiliar_flota,
                    FECHA_RESPUESTA: doc.fecha_asignacion_flota,
                    USUARIO_QUE_RESPONDIO: doc.usuario_que_asigno_flota,
                    ZONA: doc.zona
                }
            })
        ]
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(reData);
        XLSX.utils.book_append_sheet(wb, ws, "tickets");
        XLSX.writeFile(wb, `Tickets_Gestionados ${date1}.xlsx`)
    }

    const onChange2 = () => {
        LoadData();
    };

    return (
        <>
            {/* <Box pt={1} pl={1}>
                <Typography variant="button" >

                    <Typography >
                        <strong>({data && data.length}) ACTIVOS FIJOS</strong>
                    </Typography >
                </Typography >
            </Box>
            <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Stack direction="row" spacing={2}>

                        <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                        >
                            {" "}
                            DESCARGAR EXCEL
                        </Button>
                    </Stack >

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box pt={1}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box> */}

            <Box px={2} py={2}>
                <Paper>
                    <Box pl={2} pt={2}>
                        <p style={{ fontSize: "18px", marginBottom: "0px" }}><strong>TICKETS GESTIONADOS</strong></p>
                    </Box>
                    <Box px={2} py={2} sx={{ height: 850, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,

                            }}

                            onStateChange={(state) => {
                                console.log(state.pagination.rowCount);
                                setTotal(state.pagination.rowCount)
                            }}
                        />
                    </Box>
                </Paper>
            </Box>

        </>
    );
};