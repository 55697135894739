import { Chip, Box, Button, Collapse, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {  db, firebase } from "../../firebase";
import moment from "moment";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES,

} from '@mui/x-data-grid';
import { ViewVentasServicio } from "./update/ViewVentasServicio";
export const VentasAgendadas = () => {
  function EstadoPagado(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad

    if (d.estado_pagado === 0 || !d.estado_pagado) {
      name = 'DEBITO'
      color = 'info'
    }
    if (d.estado_pagado === 1) {
      name = 'VERIFICANDO'
      color = 'info'
    }
    if (d.estado_pagado === 2) {
      name = 'PAGADO'
      color = 'secondary'
    }
    if (d.estado_pagado === 3) {
      name = 'NO PAGADO'
      color = 'error'
    }

    return (
      <Chip label={name} size="small" color={color} />
    )
  }
  function Tipos(estado) {
    const d = estado.estado
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (d.cambio_domicilio === true) {
      name = 'CAMBIO DOMICILIO'
      color = 'info'
    }
    if (d.promo_santa_elena === false || d.cambio_domicilio === false) {
      name = 'INSTALACION'
      color = 'warning'
    }
    if (d.debito === 1) {
      name = 'DEBITO AUTOMATICO'
      color = 'success'
    }
    if (d.tipo_venta === 'MIGRACION'){
      name = 'MIGRACION'
      color = 'warning'
    }

    return (
      <Chip label={name} size="small" variant="outlined" color={color} />
    )
  }
  const [open, setOpen] = useState(false)

  const [data, setData] = useState([])
  //

  //
  const current = new Date();
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  )
  useEffect(() => {
    LoadData()
  }, [])

  const userAuth = useSelector((state) => state.userAuth)
  const { loading, userInfo } = userAuth;
  const LoadData = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("agenda").where("estado", "in", [5, 7])
    //if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
    //ref = ref.where("user_uid", "==", auth.currentUser.uid);
    if (endDate && startDate) {

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("fechaInstalacionVenta", ">=", startfulldate);
      ref = ref.where("fechaInstalacionVenta", "<=", endfulldate);

      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              fechaServicio2: doc.data().cambio_domicilio !== undefined &&
                doc.data().fechaInstalacionCambio ? moment(new Date(doc.data().fechaInstalacionCambio.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')
                : (doc.data().cambio_domicilio === undefined &&
                  doc.data().tipo_venta === 'MIGRACION' && doc.data().fechaInstalacionMigracion
                  ? moment(new Date(doc.data().fechaInstalacionMigracion.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')
                  : (doc.data().cambio_domicilio === undefined && doc.data().fechaInstalacionVenta
                    ? moment(new Date(doc.data().fechaInstalacionVenta.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a') : '')),

            }
          })
        ]
        setData(data)
      })
    }
  }
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  //
  const printOptions = { hideFooter: true, hideToolbar: true }
  const csvOptions = { delimiter: ';' }
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )

  const columns = [
    // {
    //   field: 'fecha_actualizacion_estado_pago', headerName: 'FECHA AGENDADA', type: 'dateTime', width: 200,
    //   valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    // },
    {
      field: 'fechaDeAgendamiento', headerName: 'FECHA AGENDADA', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'fechaHora', headerName: 'FECHA PARA INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'pago', headerName: 'PAGO', width: 100,
      renderCell: (params) => {
        return (
          <EstadoPagado estado={params.row} />
        )
      }
    },
    {
      field: 'tipo', headerName: 'TIPO', width: 175,
      renderCell: (params) => {
        return <Tipos estado={params.row} />
      }
    },
    { field: 'id', headerName: 'CONTRATO', width: 200 },
    // {
    //   field: '', headerName: '', width: 90,
    //   renderCell: (params) => {
    //     return (
    //       params.row.fecha_servicio == null && <ViewVentasServicio props={params.row} />
    //     )
    //   }
    // },
    // {
    //   field: 'fecha_servicio', headerName: 'FECHA DE SERVICIO', type: 'dateTime', width: 200,
    //   valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    // },
    {
      field: '', headerName: '', width: 90,
      renderCell: (params) => {
        return (
          // params.row.fechaInstalacionServicio == null && <ViewVentasServicio props={params.row} />
          <>
            {params.row.estado === 5 &&
              <ViewVentasServicio props={params.row} />
            }
          </>
        )
      }
    },
    /* { field: 'fechaInstalacionVenta', headerName: 'FECHA DE SERVICIO', width: 200 }, */
    {
      field: 'fechaInstalacionVenta', headerName: 'FECHA DE SERVICIO',width: 200,  
      type: 'dateTime', 
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'fechaInstalacionCambio', headerName: 'FECHA DE CAMBIO DOMICILIO',width: 200,  
      type: 'dateTime', 
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    /* {
      field: 'fechaServicio2', headerName: 'FECHA DE SERVICIO', width: 200
    }, */
    // {
    //   field: 'fechaServicio2', headerName: 'FECHA DE SERVICIO', type: 'dateTime', width: 200    },
    /* {
      field: '5',
      headerName: 'FECHA DE SERVICIO',
      width: 150,
      valueGetter: (params) => params.row.servicios?.[0]?.instalado || ''
    }, */
    // {
    //   field: 'fecha_servicio', headerName: 'FECHA DE SERVICIO', type: 'dateTime', width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.cambio_domicilio !== undefined &&
    //           <a>{params.row.fechaInstalacionCambio && moment(new Date(params.row.fechaInstalacionCambio.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')}
    //             {/* {params.row.fechaInstalacionServicio && params.row.fechaInstalacionCambio===undefined && moment(new Date(params.row.fechaInstalacionServicio.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')} */}
    //           </a>
    //         }
    //         {params.row.cambio_domicilio === undefined &&
    //           <a>{params.row.fechaInstalacionVenta && moment(new Date(params.row.fechaInstalacionVenta.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')}
    //             {/* {params.row.fechaInstalacionServicio && params.row.fechaInstalacionVenta===undefined && moment(new Date(params.row.fechaInstalacionServicio.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a')} */}
    //           </a>
    //         }
    //       </>
    //     )
    //   }
    // },
    { field: 'cedula', headerName: 'CEDULA', width: 100 },
    { field: 'nombres', headerName: 'CLIENTE', width: 250 },
    { field: 'fechaNacimiento', headerName: 'FECHA NACIMIENTO', width: 100 },
    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 125,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 125,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'paramsDiscapacidad.valor'
    },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },
    { field: 'planNombre', headerName: 'PLAN', width: 150 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 100 },
    { field: 'vendedor', headerName: 'VENDEDOR', width: 200 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      valueGetter: (params) => {
        return params.row.centro_costo?.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo?.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo?.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo?.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo?.categoria
      }
    },
    {
      field: 'referido', headerName: 'REFERIDO', width: 150,
      valueGetter: ({ value }) => value === undefined ? 'NO' : value
    },

    {
      field: 'flotaReferida', headerName: 'FLOTA REFERIDA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },

    { field: 'direccion', headerName: 'DIRECCION', width: 200 },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'sector', headerName: 'SECTOR', width: 200 },
    { field: 'numFlota', headerName: 'NUMERO FLOTA', width: 200 },
    { field: 'nombreLider', headerName: 'LIDER FLOTA', width: 200 },
    { field: 'nombreAuxiliar', headerName: 'AUXILIAR FLOTA', width: 200 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 200,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    {
      field: 'created', headerName: 'FECHA CREACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
  ]
  return (
    <>
      <Box pl={1} pt={1}>
        <Typography>
          <strong>({data?.length})</strong> VENTAS AGENDADAS
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button
              disableElevation
              variant="contained"
              onClick={() => LoadData()}
            >
              Aplicar busqueda
            </Button>
          </Collapse>
        </Box>
      </Box>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={data}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          columns={columns}
          hideFooter
          components={{
            Toolbar: CustomToolbar
          }}
          onStateChange={(state) => {
          }}
        />
      </Box>
    </>
  );
};
