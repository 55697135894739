import {
    Box, Button, Container, Dialog,
    DialogActions, DialogContent, DialogTitle,
    LinearProgress, Tooltip
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, esES } from '@mui/x-data-grid';


export const DetalleFactura = (props) => {

    const [id, setId] = useState('')
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setId(props.id)
    }, [props]);

    const handleOpen = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiMikrowipsItemsFactura/?idfactura=${id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                setData(res)
                setOpen(true)
            })
            .catch(error => {
                console.log('error', error)
            });
    }

    const columns = [
        { field: 'descripcion', headerClassName: 'super-app-theme--header', headerName: 'Descripción', width: 350 },
        { field: 'unidades', headerClassName: 'super-app-theme--header', headerName: 'Cantidad', width: 150 },
        {
            field: 'impuesto', headerClassName: 'super-app-theme--header', headerName: 'Impuesto', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.impuesto} %
                </>
            }
        },
        {
            field: 'cantidad', headerClassName: 'super-app-theme--header', headerName: 'Valor', width: 150,
            renderCell: (params) => {
                return <>
                    ${params.row.cantidad}
                </>
            }
        },
    ]

    return (
        <>
            <Tooltip title="Ver" >
                <InfoIcon color='warning' onClick={() => {
                    handleOpen()
                }} fontSize='medium' />
            </Tooltip>

            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle sx={{ bgcolor: '#1A6EB9', color: "white" }}><strong>DETALLE FACTURA ID: {id}</strong>
                </DialogTitle>
                <DialogContent>
                    <br />
                    <Container>
                        <Box sx={{
                            height: 250, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                            '& .super-app-theme--header': {
                                backgroundColor: '#672780',
                                color: 'white',
                                fontSize: '16px'
                            },
                        }}>
                            <DataGrid
                                slots={{
                                    loadingOverlay: LinearProgress,
                                }}
                                hideFooterSelectedRowCount={true}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                density='compact'
                                rows={data ?? []}
                                hideFooter={true}
                                getRowHeight={() => 'auto'}
                                getEstimatedRowHeight={() => 100}
                            />
                        </Box>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
