import { db, storage, firebase } from '../../firebase'
import Swal from 'sweetalert2'
export const detallesInstalacion = (instalacionId) => async (dispatch) => {
    try {
        dispatch({ type: 'INSTALACION_DETAILS_REQUEST' })

        console.log(instalacionId, 'ID')
        const docRef = await db.collection('tecnicos').doc(instalacionId)
        .onSnapshot((doc) => {
            if (doc.exists) {
                console.log(doc.data())
                    const data = doc.data()
                    dispatch({
                        type: 'INSTALACION_DETAILS_SUCCESS',
                        payload: data
                    })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        });


            
    } catch (error) {
        dispatch({
            type: 'INSTALACION_DETAILS_FAIL',
            payload: error
        })
    }
}
export const actualizarInstalacion = (instalacionId, data, navigate) => async (dispatch) => {
    try {

        dispatch({ type: 'INSTALACION_UPDATE_REQUEST' })
        const solicitudDb = await db.collection('tecnicos').where('uid', '==', instalacionId).doc().update(data).then(() => {
            /* navigate(`/Instaladores/subir-foto/${instalacionId}`) */
            Swal.fire({ icon: 'success', text: 'Nueva venta' })
        }).catch((error) => {
            Swal.fire({ icon: 'error', text: error, })
        });
        console.log('Si paso', data)
        dispatch({
            type: 'INSTALACION_UPDATE_SUCCESS',
            payload: solicitudDb
        })
    } catch (error) {
        dispatch({
            type: 'INSTALACION_UPDATE_FAIL',
            payload: error
        })
    }
}
export const uploadFile = (instalacionId, nameFile, uploadFile) => async (dispatch) => {
    try {
        dispatch({ type: 'CARGA_DOCUMENTO_REQUEST' })
        const pictureRef = await storage.ref().child('instalaciones').child(instalacionId.nombres).child(nameFile)
        const letreroUpload = pictureRef.put(uploadFile)
        letreroUpload.on("state_changed",
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                Swal.fire({ icon: 'error', text: error, })
            },
            () => {
                letreroUpload.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL)

                    if (nameFile === 'potencia_casa') {
                         db.collection('tecnicos').doc(instalacionId.uid).update({
                            potencia_casa: downloadURL
                        })
                    } else if (nameFile === 'potencia_caja') {
                         db.collection('tecnicos').doc(instalacionId.uid).update({
                            potencia_caja: downloadURL
                        })
                    } else if (nameFile === 'router_casa') {
                         db.collection('tecnicos').doc(instalacionId.uid).update({
                            router_casa: downloadURL
                        })
                    } else if (nameFile === 'foto_etiqueta') {
                         db.collection('tecnicos').doc(instalacionId.uid).update({
                            foto_etiqueta: downloadURL
                        })
                    } else if (nameFile === 'foto_recibo') {
                         db.collection('tecnicos').doc(instalacionId.uid).update({
                            foto_recibo: downloadURL
                        })
                    }
                    dispatch({
                        type: 'CARGA_DOCUMENTO_SUCCESS',
                        payload: downloadURL
                    })
                    
                    // const solicitudDb = db.collection('solicitudes').doc(params.id).update({
                    //     foto_cliente_letrero: downloadURL
                    // }).then(() => {
                    //     console.info("Finished uploading CEDULA FRONTAL");

                    // }).catch((error) => {
                    //     Swal.fire({ icon: 'error', text: error, })
                    // });
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            }
        )




       
    } catch (error) {
        dispatch({
            type: 'CARGA_DOCUMENTO_FAIL',
            payload: error
        })
    }
}

export const detallesInstalacionTickets = (instalacionId) => async (dispatch) => {
    try {
        dispatch({ type: 'INSTALACIONTICKETS_DETAILS_REQUEST' })

        console.log(instalacionId, 'ID')
        const docRef = await db.collection('tickets_tecnicos').where('uid_ticket', '==', instalacionId)
        .onSnapshot((doc) => {
            if (doc.exists) {
                console.log(doc.data())
                    const data = doc.data()
                    dispatch({
                        type: 'INSTALACIONTICKETS_DETAILS_SUCCESS',
                        payload: data
                    })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        });
            
    } catch (error) {
        dispatch({
            type: 'INSTALACIONTICKETS_DETAILS_FAIL',
            payload: error
        })
    }
}