import { Tabs, Typography, Box, Tab, Card } from '@mui/material';
import { Container } from '@mui/system';
import React, { useState } from 'react'
import { CallCenterAprobada } from './CallCenterAprobadas';
import { CallCenterEspera } from './CallCenterEspera';
import { CallCenterRechazado } from './CallCenterRechazado';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
export const CallCenterPage = () => {
    const [value, setValue] = useState(0)
    return (
        <>
            <Container maxWidth='lx'>
                <Box pl={1} pb={1}>
                    <Typography variant='h5' component='h1'> <strong>SOLICITUDES CALL CENTER</strong> </Typography>
                </Box>
                <Card>
                    <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                        <Tabs value={value} onChange={(e, newValue) => setValue(newValue)}>
                            <Tab label="Solicitudes en Espera" />
                            <Tab label="Solicitudes Aprobadas" />
                            <Tab label="Solicitudes Rechazadas" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <CallCenterEspera />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CallCenterAprobada />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <CallCenterRechazado />
                        </TabPanel>
                    </Box>
                </Card>
            </Container>
        </>
    )
}