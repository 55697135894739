import { Box, Button, Collapse, Typography, Paper, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db, firebase } from "../../../firebase";
import moment from "moment";
import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx/xlsx.mjs";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import IconButton from "@mui/material/IconButton";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES,
} from '@mui/x-data-grid';
import { UpdateInformation } from "../views/UpdateInformation";

export const VentasRechazadasAgenda = () => {
  function CustomFooterTotalComponent(props) {
    return ''
  }



  const columns = [
    { field: 'numContrato', headerName: 'CONTRATO', width: 200 },
    {
      field: 'estado', headerName: 'ESTADO', width: 125,
      renderCell: (params) => {
        return <Chip size="small" label="RECHAZADA" color="info" />
      }
    },

    { field: 'numFlota', headerName: 'FLOTA', width: 75 },
    {
      field: 'estado2', headerName: 'ACCIONES', width: 100,
      renderCell: (params) => {
        return params.row.rechazoVenta == 'NO PAGO INSTALACION' ? <div key={params.row.id}><UpdateInformation props={params.row} /></div> : ''
      }
    },
    { field: 'cedula', headerName: 'CEDULA', width: 120 },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 400 },
    { field: 'rechazoVenta', headerName: 'MOTIVO DE RECHAZO', width: 300 },
    { field: 'personaQueRechazoAgendamiento', headerName: 'USUARIO QUE RECHAZO AGENDAMIENTO	', width: 300 },
    
    { field: 'camara', headerName: 'CAMARA', width: 100 },
    { field: 'mesh', headerName: 'MESH', width: 100 },
    { field: 'numCam', headerName: '#CAMERA', width: 100 },
    { field: 'telefono', headerName: 'TELEFONO', width: 150 },
    { field: 'celular', headerName: 'CELULAR', width: 150 },
    { field: 'whatsapp', headerName: 'WHATSAPP', width: 150 },
    { field: 'numbRef', headerName: 'NUM. REF', width: 150 },

    { field: 'caja', headerName: 'CAJA', width: 100 },
    {
      field: 'coordenadas', headerName: 'COORDENADAS', width: 250,
      valueGetter: ({ value }) => value && `${value._lat}, ${value._long}`
    },
    { field: 'direccion', headerName: 'DIRECCION', width: 400 },
    {
      field: 'foto_cliente_vivienda', headerName: 'FOTO VIVIENDA', width: 100,
      renderCell: (params) => {
        return params.row.foto_cliente_vivienda &&
          <a target="_blank" href={params.row.foto_cliente_vivienda}>VER FOTO</a>

      }
    },
    { field: 'zona', headerName: 'ZONA', width: 200 },
    { field: 'empleado', headerName: 'EMPLEADO', width: 100 },

    {
      field: 'terceraEdad', headerName: 'TERCERA EDAD', width: 130,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'discapacitado' === 1 ? 'SI' : 'NO', headerName: 'DISCAPACIDAD', width: 140,
      valueGetter: ({ value }) => value === 1 ? 'SI' : 'NO'
    },
    {
      field: 'paramsDiscapacidad', headerName: 'VALOR DESCUENTO', width: 150,
      valueGetter: ({ value }) => value === '' ? 'NINGUNO' : 'value.paramsDiscapacidad.valor'
    },
    { field: 'premios', headerName: 'PREMIOS', width: 240 },
    { field: 'planNombre', headerName: 'PLAN', width: 240 },
    { field: 'planValor', headerName: 'PLAN VALOR', width: 100 },
    { field: 'vendedor', headerName: 'NOMBRE DEL VENDEDOR', width: 300 },
    {
      field: 'codigo_vendedor', headerName: 'COD VENDEDOR', width: 150,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.codigo_vendedor
      }
    },
    {
      field: 'centro_costo', headerName: 'CENTRO DE COSTO', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.centro_costo
      }
    },
    {
      field: 'canal', headerName: 'CANAL DE VENTAS', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.canal
      }
    },
    {
      field: 'lider_venta', headerName: 'LIDER VENTA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.lider_venta
      }
    },
    {
      field: 'categoria', headerName: 'CATEGORIA', width: 200,
      valueGetter: (params) => {
        return params.row.centro_costo && params.row.centro_costo.categoria
      }
    },
    { field: 'observacion', headerName: 'OBSERVACION', width: 200 },
    { field: 'observacion2', headerName: 'OBSERVACIONES', width: 250 },
    {
      field: 'flotaReferida', headerName: 'VENTA REFERIDA POR FLOTA', width: 150,
      valueGetter: ({ value }) => value && value.referido === 'SI' ? 'flotaReferida.numeroFlota' : 'NINGUNA'
    },
    {
      field: 'fechaHora', headerName: 'FECHA Y HORA INSTALACION', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },

    {
      field: 'created', headerName: 'FECHA DE GENERACION DE PRE-VENTA', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'fechaDeAgendamiento', headerName: 'FECHA AGENDAMIENTO', type: 'dateTime', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'personaQueAgendo', headerName: 'USUARIO QUE AGENDA', width: 200 },
  ]
  const csvOptions = {
    delimiter: ';',
    fileName: `VENTAS POR AGENDADAR ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  }
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  )
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  //
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  //
  useEffect(() => {
    LoadData();
  }, []);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("agenda").where("estado", "==", 4)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("fechaDeAgendamiento", ">=", startfulldate);
      ref = ref.where("fechaDeAgendamiento", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      })
    }
  }
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const handleExport = () => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    var date1;
    if (month < 10) {
      date1 = `${day}-0${month}-${year}_${hours}_${minutes}_${seconds}`;
    } else {
      date1 = `${day}-${month}-${year}_${hours}_${minutes}_${seconds}`;
    }

    const reData = [
      ...data.map((doc) => {
        return {
          CEDULA: doc.cedula,
          CAJA: doc.caja,
          DETALLE: `${doc.nombres}\n ${doc.cedula}\n${doc.direccion}\n${doc.celular}-${doc.telefono}\n${doc.planNombre}${doc.planValor}\n${doc.correo}\n${doc.coordenadas._lat},${doc.coordenadas._long}\n${doc.caja}\n${doc.numContrato}\n${doc.vendedor}`,
          COORD_CAJA: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          COORD_CLIENTE: `${doc.coordenadas._lat},${doc.coordenadas._long}`,
          PLAN: doc.planNombre,
          ASESOR: doc.vendedor,
          created: doc.created.toDate(),
        };
      }),
    ];
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(reData);
    XLSX.utils.book_append_sheet(wb, ws, "Licencias");
    XLSX.writeFile(wb, `REPORTE_${date1}.xlsx`);
  }


  return (
    <>

      <Box pl={1} pt={1}>
        <Typography>
          <strong>({data?.length})</strong> VENTAS/RECHAZADAS
        </Typography>

        <Box display="flex" style={{ textTransform: "uppercase" }} >
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate &&
              moment(startDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate &&
              moment(endDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} sx={{ flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
          </Box>
          <Box>
            <IconButton color="primary" onClick={handleExport}>
              <SimCardDownloadIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
          <Button
            disableElevation
            variant="contained"
            onClick={() => LoadData()}
          >
            Aplicar busqueda
          </Button>
        </Collapse>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
