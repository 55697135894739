import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, Typography, Container, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Producto } from './Productos';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabs(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ParametrosContablesPage = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const handleChange = (event, newValue) => {
        setTab(newValue);
    };
    useEffect(() => {
        if (params.id) {
            console.log(typeof params.id)
            //Se crear una nueva solicitud y retorna a solicitudes de espera por URL
        }
    }, [params.id]);
    return (
        <>
            <Container maxWidth="lg">
                <Tabs value={tab} onChange={handleChange} aria-label="solicitud de servicio">
                    <Tab style={{ fontSize: "13px" }} label="Generales" {...tabs(0)} />
                    <Tab style={{ fontSize: "13px" }} label="Productos" {...tabs(1)} />
                    <Tab style={{ fontSize: "13px" }} label="Compras y Ventas" {...tabs(2)} />
                    <Tab style={{ fontSize: "13px" }} label="Cobros" {...tabs(3)} />
                    <Tab style={{ fontSize: "13px" }} label="Pagos" {...tabs(4)} />
                    <Tab style={{ fontSize: "13px" }} label="Caja y Bancos" {...tabs(5)} />
                    <Tab style={{ fontSize: "13px" }} label="Nomina" {...tabs(6)} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Producto />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                </TabPanel>
                <TabPanel value={tab} index={3}>
                </TabPanel>
                <TabPanel value={tab} index={4}>
                </TabPanel>
                <TabPanel value={tab} index={5}>
                </TabPanel>
                <TabPanel value={tab} index={6}>
                </TabPanel>
            </Container>
        </>
    )
}