import {
    ListItemText, Typography, Box, ListItemIcon, Drawer,
    Divider, Toolbar, IconButton, List, ListItem, ListItemButton,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector } from 'react-redux';
import { ListVentas } from './listItem/ListVentas';
import { ListOperaciones } from './listItem/ListOperaciones';
import { ListPlaneacion } from './listItem/ListPlaneacion';
import { ListReportes } from './listItem/ListReportes';
import { ListCuadrillas } from './listItem/ListCuadrillas';
import { ListConfig } from './listItem/ListConfig';
import { ProfileMenu } from './ProfileMenu';
import { ListRecursosHumanos } from './listItem/ListRecursosHumanos';
import { ListInstaladores } from './listItem/ListInstaladores';
import { ListGestionPostVentas } from './listItem/ListGestionPostVentas'
import { ThemeProvider, styled, createTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { ListClients } from './listItem/ListClients';
import { ListGrupoVentas } from './listItem/ListGrupoVentas';
import { ListInventario } from './listItem/ListInventario';
import { ListCodigosOtp } from './listItem/ListCodigosOtp';
import image from '../../assets/img/logo_dark.png'
import { SupervisionOperacion } from './listItem/SupervisionOperacion';
import { ListSolicitudes } from './listItem/ListSolicitudes';
import { ListCompras } from './listItem/ListCompras';
import { ListAdministracion } from './listItem/ListAdministracion';
import { ListContabilidad } from './listItem/ListContabilidad';
import { ListNetworking } from './listItem/ListNetworking';
import { RequerimientosSistema } from './listItem/RequerimientosSistema';
import { Cobranza } from './listItem/Cobranza';
import { ListIPTV } from './listItem/ListIPTV';
import { ListBancos } from './listItem/ListBancos';
import { ReporteResumen } from '../../pages/ReportesResumen/reporteresumen';
import { ListSIG } from './listItem/ListSIG';
import { ListActivosFijos } from './listItem/ListActivosFijos';
import { ListTickets } from './listItem/ListTickets';
import { ListCallCenter } from './listItem/ListCallCenter';
import { ListMarketing } from './listItem/ListMarketing';
import { ListCuentasPagar } from './listItem/ListCuentasPagar';
import { ListExtra } from './listItem/ListExtra';
import { ListEncuestas } from './listItem/ListEncuestas';
import { ListPremios } from './listItem/ListPremios';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
const drawerWidth = 300;
export const Header = (props) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    let theme = createTheme({
        palette: {
            mode: 'dark',
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#333",
                        color: "white",
                        boxSizing: "border-box",

                    }
                }
            }
        }
    });
    const drawer = (
        <ThemeProvider theme={theme}>
                <Divider />
                <Toolbar style={{ justifyContent: 'center' }}>
                    <img src={image} alt="soei" width="100" />
                </Toolbar>
                <Divider />
                <List dense>
                    <ListItemButton component={RouterLink} to={`/`}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListAdministracion />
                    <ListVentas />
                    <ListGrupoVentas />
                    <ListOperaciones />
                    <ListPlaneacion />
                    <ListInstaladores />
                    <ListEncuestas />
                    <ListCallCenter />
                    <ListCuadrillas />
                    <ListReportes />
                    <ListConfig />
                    <ListMarketing />
                    <ListPremios />
                    <ListRecursosHumanos />
                    <ListClients />
                    <Cobranza />
                    <ListInventario />
                    <ListActivosFijos />
                    <ListCompras />
                    <ListCuentasPagar />
                    <SupervisionOperacion />
                    <ListGestionPostVentas />
                    <ListSolicitudes />
                    <ListContabilidad />
                    <ListTickets />
                    <ListBancos />
                    <ListNetworking />
                    <ListCodigosOtp />
                    <ListIPTV />
                    <RequerimientosSistema />
                    <ListExtra />
                    <ReporteResumen />
                    <ListSIG />
                    <ListItem >
                        <ListItemText primary={`Departamento de Desarrollo ${new Date().getFullYear()}`} />
                    </ListItem>
                </List>
            </ThemeProvider>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex' }}>
                {
                    props.isAuth && <>
                        <AppBar
                            position='absolute'
                            // elevation={1}
                            elevation={0}
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                
                            }}
                            // color='default'
                            color='transparent'
                        >
                            <Toolbar >
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => setMobileOpen(true)}
                                    edge="start"
                                    sx={{ mr: 2, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h5" color="text.secondary" noWrap component="div" sx={{ flexGrow: 1 }}>
                                    {/* <strong>GRUPO EMPRESARIAL YIGA5</strong> */}
                                </Typography>
                                <ProfileMenu props={userInfo} />
                            </Toolbar>
                        </AppBar>
                        <Box
                            component="nav"
                            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                            aria-label="mailbox folders"
                        >
                            <Drawer
                                container={container}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                            >
                                {drawer}
                            </Drawer>
                            <Drawer
                                variant="permanent"
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                }}
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Box>
                    </>
                }
                <Box
                    component="main"
                    //p={props.isAuth ? 2 : 0}
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                >
                    {props.isAuth && <Toolbar />}
                    {props.children}
                </Box>
            </Box>
    )
}