import {
    Box,
    Button,
    Container,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@mui/material'

import React from 'react'
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import logo1 from '../../../assets/img/contrato/image005.jpg'
import logo2 from '../../../assets/img/contrato/image003.jpg'



export const Contrato = () => {

    const container = React.useRef(null);

    const exportPDFWithMethod = () => {
        let element = container.current;
        savePDF(element, {
            paperSize: "A4",
            repeatHeaders: 'true',
            margin: { top: "1.2cm", left: "1cm", right: "1cm", bottom: "1.2cm" },
            scale: 0.61,
            fileName: `CONTRATO`,
        });

    };

    return (
        <>
            <div className="example-config">
                <Button
                    onClick={exportPDFWithMethod}
                    style={{ marginLeft: "10px", marginBottom: "10px" }}
                    color="error" size='small' variant="outlined"
                    startIcon={<PictureAsPdfIcon />}>
                    <strong>PDF</strong>
                </Button>
            </div>
            <Container>
                {/* <div style={{
                    position: "absolute",
                    left: "-50000px",
                    top: 0, 
                }}>*/}
                    <PDFExport>
                        <div ref={container}>
                            <Container maxWidth="md" sx={{ fontFamily: "Arial", lineHeight: "16px", color: "black" }}>
                                <Grid container spacing={0} sx={{ fontSize: "16px", marginTop: "5px" }}>
                                    <Grid item xs={2} >
                                        <img src={logo1} alt="soei" width="160" />
                                    </Grid>
                                    <Grid item xs={8} sx={{ fontSize: "22px", lineHeight: "20px" }}>
                                        <p align="center"><strong>CONTRATO DE PRESTACIÓN DE SERVICIOS <br />
                                            DE VALOR AGREGADO DE ACCESO A<br />
                                            INTERNET</strong></p><br />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <img src={logo2} alt="soei2" width="170" />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <a>PRIMERA. -</a>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <a style={{ color: "red" }}> CONTRATO GUAYAQUIL-L1-14321-<br />
                                            HOG
                                        </a>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <a> En la ciudad de GUAYAQUIL, el 25 de Febrero del 2023 se celebra el presente contrato de Adhesión de servicios,<br />
                                            por una parte, la compañía INTERCOMMERCE S.A., en calidad de PERMISIONARIO del Servicio de Acceso a<br />
                                            Internet, con los siguientes datos:<br /><br />
                                        </a>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <a>Nombre/Razón INTERCOMMERCE S.A.<br />
                                            Nombre Comercial: YIGA5.<br />
                                            Dirección: Mapasingue Este Cooperativa 27 de enero,<br />
                                            manzana 1, solar 12, km 5.5 vía a Daule, diagonal al<br />
                                            Colegio Dolores Sucre<br />
                                            Provincia: Guayas; Cantón: Guayaquil; Parroquia: Tarqui.<br />
                                            E-mail: servicioalcliente@intercommerce.com.ec<br />
                                            Teléfonos: 042033405- 045010056-<br />
                                            0979370118-0969854489<br />
                                            Página Web: www.yiga5.com<br />
                                            A quien podrá denominarse simplemente “EL<br />
                                            PRESTADOR”,<br />
                                            Y, por otra parte:”,<br /><br />
                                        </a>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <a>Nombre/Razón QUIMIS ALVARADO CHRISTIAN<br />
                                            ALBERTO<br />
                                            Cédula/RUC. 0927387597<br />
                                            E-mail Quimiscristhianalberto@gmail.com<br />
                                            Dirección FLOR DE BASTION BLOQUE 8 MZ1284 SL24<br />
                                            Provincia: GUAYAS Cantón: GUAYAQUIL Parroquia:<br />
                                            FLOR DE BASTIÓN<br />
                                            Dirección donde será prestado el Servicio: FLOR DE<br />
                                            BASTION BLOQUE 8 MZ1284 SL24<br />
                                            Número telefónico de referencia fijo/móvil: 0968904023<br />
                                        </a>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <a>EL ABONADO ES DE LA TERCERA EDAD O DISCAPACITADO: SI……… NO…X…<br />
                                            ACCEDE A TARIFA PREFERENCIAL: SI……… NO…X…,<br /><br />
                                        </a>
                                        <a>A quien podrá denominarse simplemente como “ABONADO”, siendo mayor de edad (en el caso de personas<br />
                                            naturales), quienes, de manera libre, voluntaria y por mutuo acuerdo celebran el presente contrato<br />
                                            SEGUNDA. - OBJETO, El prestador del servicio se compromete a proporcionar al abonado/suscriptor el/los<br />
                                            siguientes (s) servicio(s), para lo cual el prestador dispone de los correspondientes títulos habilitantes otorgados por<br />
                                            ARCOTEL, de conformidad con el ordenamiento jurídico vigente:<br /><br />
                                        </a>
                                    </Grid>

                                    <Grid item xs={12} >
                                        <Grid container spacing={0} alignItems="center" justifyContent="center">
                                            <Grid item xs={7}>
                                                <br />
                                                <Table spacing={0}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Móvil Avanzado (SMA)</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Móvil Avanzado a través de operador Móvil Virtual (OMV)</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Telefonía Fija</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Telecomunicaciones por satélite</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Acceso a Internet X</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}> X </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Troncalizados</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Comunales</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Audio y Video Por Suscripción</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={11} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Portado</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <br /><br />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} />

                                    <Grid item xs={12}>
                                        <a>TERCERA. - VIGENCIA DEL CONTRATO: El presente contrato, tendrá una duración de 18 meses. y entrara en<br />
                                            vigencia, a partir de la fecha de instalación y prestación efectiva del servicio. La fecha inicial considerada para<br />
                                            facturación para cada uno de los servicios contratados debe ser la de la activación de servicio, para dicho efecto,<br />
                                            las partes suscribirán una Acta de Entrega – Recepción (ANEXO 4).<br /><br />

                                            Las partes se comprometen a respetar el plazo de vigencia pactado, sin perjuicio de que el ABONADO O<br />
                                            SUSCRIPTOR pueda darlo por terminado unilateralmente, en cualquier tiempo, previa notificación por medios<br />
                                            físicos o electrónicos al prestador con por lo menos 15 días de anticipación, conforme lo dispuesto en las leyes<br />
                                            orgánicas de Telecomunicaciones y de Defensa del Consumidor y sin que para ello este obligado a cancelar multas<br />
                                            o recargos de valores de ninguna naturaleza.<br /><br />

                                            EL ABONADO acepta la renovación automática sucesiva del contrato SI…X….NO…….., en las mismas<br />
                                            condiciones de este contrato, independientemente de su derecho a terminar la relación contractual conforme a la<br />
                                            legislación aplicable, o solicitar en cualquier tiempo, con hasta (15) días de antelación a la fecha de renovación, su<br />
                                            decisión de no renovación..<br /><br />

                                            CUARTA. - PERMANENCIA MINIMA: El ABONADO O SUSCRIPTOR se acoge al periodo de permanencia mínima<br />
                                            de 18 de meses. en la prestación del servicio contratado? SI …X……NO …………. y recibir beneficios que serán<br />
                                            establecidos en el ANEXO 1.<br /><br />
                                        </a>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Grid container spacing={0} alignItems="center" justifyContent="center">
                                            <Grid item xs={8}>
                                                <br />
                                                <Table spacing={0}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}></TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}>SI</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}>NO</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>Pago directo en cajas del prestador del Servicio</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>Débito automático cuenta de ahorro o corriente (ANEXO 5)</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>Pago en ventanilla de locales autorizados</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={10} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>Transferencia vía medios electrónicos</TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                            <TableCell colSpan={1} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, padding: "0px 5px 0px 5px" }}></TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <br /><br />
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                    <Grid item xs={12} >
                                        <a>La Tarifa correspondiente al servicio contratado y efectivamente prestado, estará dentro de los techos tarifarios<br />
                                            señalados por Arcotel y en los títulos habilitantes correspondientes, en caso de que se establezcan, de conformidad<br />
                                            con el ordenamiento jurídico vigente<br /><br />

                                            En caso de que el ABONADO O SUSCRIPTOR desee cambiar su modalidad de pago a otra de las disponibles,<br />
                                            deberá comunicar al prestador del servicio con quince (15) días de anticipación. El prestador del servicio, luego de<br />
                                            haber sido comunicado, instrumentara la nueva forma de pago.<br /><br />

                                            SEXTA.- COMPRA, ARRENDAMIENTO DE EQUIPOS.- (Cuando sea procedente el arrendamiento o adquisición<br />
                                            de equipos, por parte del ABONADO O SUSCRIPTOR, toda la información pertinente será detallada en un ANEXO<br />
                                            2, suscrito por al abonado el cual contendrá los temas relacionados a las condiciones de los equipos adquiridos/<br />
                                            arrendados, cantidad, precio, marca, estado, tiempo y cualquier otra condición de la compra/arrendamiento del<br />
                                            equipo, particularmente el tiempo en el que se pagara el arrendamiento o la compra del equipo, el valor mensual a<br />
                                            cancelar o las condiciones de pago). .<br /><br />

                                            SEPTIMA.- USO DE INFORMACION PERSONAL.- Los datos personales que los usuarios proporcionen a los<br />
                                            prestadores de servicios del régimen general de telecomunicaciones, no podrán ser usados para la promoción<br />
                                            comercial de servicios o productos, inclusive de la propia operadora, salvo autorización y consentimiento expreso<br />
                                            del ABONADOS O SUSCRIPTOR, (ANEXO 3), el que constara como instrumento separado y distinto al presente<br />
                                            contrato de adhesión de servicios a través de medios físicos o electrónicos, en dicho instrumento se deberá dejar<br />
                                            constancia expresa de los datos personales o información que están expresamente autorizados; el plazo de la<br />
                                            autorización y el objetivo que esta utilización persigue, conforme lo dispuesto en el artículo 121 del Reglamento<br />
                                            General a la ley Orgánica de Telecomunicaciones.El ABONADO O SUSCRIPTOR podrá revocar su consentimiento,<br />
                                            sin que el prestador pueda condicionar o establecer requisitos para tal fin, adicionales a la simple voluntad del<br />
                                            ABONADO O SUSCRIPTOR.<br /><br />

                                            Además el PRESTADOR se compromete a implementar mecanismos necesarios para precautelar la información de<br />
                                            datos personales de sus ABONADOS O SUSCRIPTORES, incluyendo el secreto e inviolabilidad del contenido de<br />
                                            sus comunicaciones, con las excepciones previstas en la ley y a manejar de manera confidencial el uso,<br />
                                            conservación y destino de los datos personales del ABONADO O SUSCRIPTOR, siendo su obligación entregar<br />
                                            dicha información, únicamente, a pedido de autoridad competente de conformidad al ordenamiento jurídico<br /><br />
                                            vigente..

                                            OCTAVA. - RECLAMOS Y SOPORTE TECNICO. - El ABONADO/CLIENTE podrá requerir soporte técnico o<br />
                                            presentar reclamos al prestador de servicios a través de los diferentes medios o puntos:<br /><br />

                                            MEDIO ELECTRONICO: www.yiga5.com<br />
                                            OFICINAS DE ATENCION: Mapasingue Este Cooperativa 27 de enero, manzana 1, solar 12, km 5.5 vía a Daule,<br />
                                            diagonal al Colegio Dolores Sucre.<br />
                                            HORARIOS DE ATENCION: 08h00 a 17h00<br />
                                            TELEFONOS: 045010056 - 0988705568<br /><br />

                                            Para la atención de reclamos NO resueltos por el prestador, el abonado podrá presentar sus denuncias y reclamos<br />
                                            ante la AGENCIA DE REGULACION Y CONTROL DE LAS TELECOMUNICACIONES - ARCOTEL por cualquiera<br />
                                            de los siguientes canales de atención:<br /><br />
                                        </a>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <ul>
                                            <li>
                                                Atención personalizada directamente a las oficinas de las coordinaciones Zonales de la Arcote
                                            </li>
                                            <li>
                                                PBX-DIRECTO Matriz, Coordinaciones Zonales y Oficinas Técnicas
                                            </li>
                                            <li>
                                                Call center (llamadas gratis al número 1800-567567)
                                            </li>
                                            <li>
                                                Correo Tradicional mediante oficios
                                            </li>
                                            <li>
                                                O en la página web de la Arcotel www.arcotel.gob.ec o al link http://reclamoconsumidor.arcotel.gob.ec/osTicket
                                            </li>
                                        </ul><br />
                                    </Grid>

                                    <Grid item xs={12} >
                                        <a>NOVENA. - DERECHOS DE LAS PARTES. - DERECHOS DEL ABONADO. -<br /><br />

                                            1.- A recibir el servicio de acuerdo a los términos estipulados en el presente contrato.<br />
                                            2.- A obtener de su prestador la compensación por los servicios contratados y no recibidos por deficiencias en los<br />
                                            mismos o el reintegro de valores indebidamente cobrados.<br />
                                            3.- A que no se varíe el precio estipulado en el contrato o sus Anexos, mientras dure la vigencia del mismo o no se<br />
                                            cambien las condiciones de la prestación a través de la suscripción de nuevos Anexos Técnico (s) y Comercial (es).<br />
                                            4.- A reclamar respecto de la calidad del servicio, cobros no contratados, elevaciones de tarifas, irregularidades en<br />
                                            relación a la prestación del servicio ante la Defensoría del Pueblo y/o al Centro de Atención y Reclamos de la<br />
                                            AGENCIA DE REGULACION Y CONTROL DE LAS TELECOMUNICACIONES - ARCOTEL<br />
                                            5.- A reclamar de manera integral por los problemas de calidad tanto de la Prestación de servicios de Acceso a<br />
                                            Internet, así como por las deficiencias en el enlace provisto para brindar el servicio. En particular en los casos en<br />
                                            que aparezca el “EL PRESTADOR” como revendedor del servicio portador. En este último caso, responderá EL<br />
                                            PRESTADOR plenamente a su abonado conforme a la Ley Orgánica de Defensa del Consumidor,<br />
                                            (independientemente de los acuerdos existentes entre los operadores o las responsabilidades ante las autoridades<br />
                                            de telecomunicaciones).<br />
                                            6.- EL PRESTADOR reconoce a sus ABONADOS todos los derechos que se encuentran determinados en Ley<br />
                                            Orgánica de Telecomunicaciones y su Reglamento, Ley del Anciano y su reglamento, Ley Orgánica de Defensa del<br />
                                            Consumidor y su Reglamento; Ley Orgánica de Discapacidades y su reglamento, Reglamento para la prestación de<br />
                                            Servicios de Telecomunicaciones y Servicios de Radiodifusión por Suscripción.<br />
                                            7.- EL PRESTADOR no podrá bloquear, priorizar, restringir o discriminar de modo arbitrario y unilateral aplicaciones,<br />
                                            contenidos o servicios, sin consentimiento expreso del ABONADO o de autoridad competente. Sin embargo, si el<br />
                                            ABONADO así lo requiere, EL PRESTADOR podrá ofrecer el servicio de control y bloqueo de contenidos que<br />
                                            atenten contra la Ley, la moral o las buenas costumbres, debiendo informar al usuario el alcance, precio y modo de<br />
                                            funcionamiento de estos y contar con la anuencia expresa del ABONADO.<br />
                                            8.- Cuando se utilicen medios electrónicos para la contratación, se sujetarán a las disposiciones de la Ley de<br />
                                            Comercio Electrónico, Firmas Electrónicas y Mensajes de Datos.<br />
                                            9.- A que el PRESTADOR le informe oportunamente sobre la interrupción, suspensión o averías de los servicios<br />
                                            contratados y sus causas.<br /><br />

                                            DERECHOS DEL PRESTADOR. -<br /><br />

                                            1.- A percibir el pago oportuno por parte de los abonados, clientes, suscriptores, por el servicio prestado, con<br />
                                            sujeción a lo pactado en el presente contrato.<br />
                                            2.- A suspender el servicio propuesto por falta de pago de los abonados, clientes, suscriptores, previa notificación a<br />
                                            los abonados, clientes, suscriptores, con dos días de anticipación, así como por uso ilegal de servicio calificado por<br />
                                            autoridad competente, en este último caso con suspensión inmediata sin necesidad de notificación previa.<br />
                                            3.- Cobrar a los abonados, las tarifas conforme al ordenamiento jurídico vigente, y los pliegos tarifarios aprobados<br />
                                            por la Dirección Ejecutiva de la ARCOTEL.<br /><br />

                                            DECIMA. - TERMINACION: El presente contrato terminará por las siguientes causas:<br /><br />

                                            POR EL PRESTADOR DEL SERVICIO:<br />
                                            a) Por incumplimiento de las condiciones contractuales del abonado<br />
                                            b) Si el ABONADO utiliza los servicios contratados para fines distintos a los convenidos, o si los utiliza en<br />
                                            prácticas contrarias a la ley, las buenas costumbres, la moral o cualquier forma que perjudique a EL PRESTADOR.<br />
                                            c) Por vencimiento del plazo de vigencia del contrato, cuando no exista renovación<br />
                                            d) Por falta de pago<br />
                                            e) Por las demás causas previstas en el Ordenamiento Jurídico Vigente<br /><br />

                                            POR EL ABONADO O SUSCRIPTOR:<br />
                                            a) El ABONADO podrá dar por terminado, en forma anticipada, en cualquier tiempo, para lo cual solo requiere<br />
                                            notificarlo al prestador con por lo menos quince días calendario de anticipación, por cualquier medio físico o<br />
                                            electrónico.<br />
                                            b) Por vencimiento del plazo de vigencia del contrato, cuando no exista renovación<br />
                                            c) Por las demás causas previstas en el Ordenamiento Jurídico Vigente<br /><br />

                                            Terminación unilateral del contrato, por el abonado.- Sin perjuicio del plazo de vigencia del contrato, los abonados<br />
                                            podrán darlos por terminado anticipada y unilateralmente, en cualquier tiempo y por cualquier medio físico o<br />
                                            electrónico, conformo lo dispuesto en la ley Orgánica de defensa del consumidor, y sin que para ello este obligado a<br />
                                            cancelar multas o recargos de ninguna naturaleza, salvo saldos pendientes por servicios efectivamente prestados o<br />
                                            bienes solicitados y recibidos, hasta la terminación del contrato.<br /><br />

                                            DECIMA PRIMERA. - CONTROVERSIAS:<br /><br />

                                            Las diferencias que surjan de la ejecución del presente contrato podrán ser resueltas por mutuo acuerdo entre las<br />
                                            partes, sin perjuicio de que el ABONADO/SUSCRIPTOR acuda con su reclamo, queja o denuncia, ante las<br />
                                            autoridades administrativas que correspondan. De NO llegarse a una solución, cualquiera de las partes podrá<br />
                                            acudir ante los jueces competentes.<br /><br />

                                            No obstante, lo indicado, las partes pueden pactar adicionalmente, someter sus controversias ante un centro de<br />
                                            mediación o arbitraje, si así lo deciden expresamente, en cuyo caso el ABONADO/SUSCRIPTOR deberá señalarlo<br />
                                            en forma expresa.<br /><br />

                                            El ABONADO/SUSCRIPTOR, en caso de conflicto, acepta someterse a la mediación o arbitraje ante la Cámara de<br />
                                            Comercio de GUAYAQUIL, SI…X……NO………<br /><br />
                                        </a>
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Grid item xs={5}>
                                        <br />
                                        <Table spacing={0}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}> Acepto Cláusula arbitral</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>QUIMIS ALVARADO CHRISTIAN ALBERTO</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={12} sx={{ border: 1, fontSize: "16px", color: "black", p: 0, paddingLeft: "10px" }}>Firma ABONADO</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <br /><br />
                                    </Grid>
                                    <Grid item xs={6} />
                                    <Grid item xs={12} >
                                        <a>DECIMA SEGUNDA. - NORMATIVA APLICABLE:<br /><br />

                                            En la prestación del servicio, se entienden incluidos todos los derechos y obligaciones de los ABONADO/<br />
                                            SUSCRIPTOR, establecidos en las normas jurídicas aplicables, así como también los derechos y obligaciones de<br />
                                            los prestadores de servicios de telecomunicaciones y/o servicios de radiodifusión por suscripción, dispuestos en el<br />
                                            marco regulatorio.<br /><br />

                                            DECIMA TERCERA. - EMPAQUETAMIENTOS DE SERVICIOS:<br /><br />

                                            La contratación incluye empaquetamiento de servicios: SI …X.. NO…….<br />
                                            Los servicios del paquete y los beneficios para cada uno de los mismos están especificados en el ANEXO 1.<br /><br />

                                            DECIMA CUARTA. - ANEXOS:<br /><br />

                                            Las notificaciones que correspondan serán entregadas en el domicilio de cada una de las partes señalado arriba<br />
                                            del presente contrato, cualquier cambio de domicilio debe de ser comunicado por escrito a la otra parte en un plazo<br />
                                            de 10 días, a partir del día siguiente en que el cambio se efectué.<br /><br />

                                            DECIMA QUINTA. - ANEXOS:<br /><br />

                                            Es parte integrante del presente contrato el ANEXO 1, que contiene las condiciones particulares del servicio, así<br />
                                            como los demás anexos y documentos que se incorporen de conformidad con el ordenamiento jurídico.<br /><br />

                                            El ABONADO/SUSCRIPTOR acepta el presente contrato con sus términos y condiciones y demás documentos<br />
                                            anexos para lo cual deja constancia de lo anterior y firman junto con el Ing. Juan Carlos González Ortega,<br />
                                            representante legal de INTERCOMMERCE S.A., en tres ejemplares del mismo tenor, en la ciudad de GUAYAQUIL a<br />
                                            los días 25 del mes de Febrero del año 2023<br /><br />

                                            La fecha de inscripción de modelo de contrato de adhesión que se utiliza es 25/Febrero/2023<br /><br />
                                        </a>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={7} alignItems="center" justifyContent="center" sx={{ fontSize: "14px", lineHeight: "14px" }}>
                                    <Grid item xs={6}>
                                        <p align="center" >
                                            JUAN CARLOS GONZALEZ ORTEGA <br /> <br />
                                            PRESTADOR DEL SERVICIO <br />
                                            ING. JUAN CARLOS GONZALEZ ORTEGA <br />
                                            REPRESENTANTE LEGAL INTERCOMMERCE S.A <br />
                                            RUC: 0992988061001 <br />
                                        </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p align="center" >
                                            QUIMIS ALVARADO CHRISTIAN ALBERTO <br /> <br />
                                            ABONADO/SUSCRIPTOR<br />
                                            NOMBRE: QUIMIS ALVARADO CHRISTIAN ALBERTO<br />
                                            C.I./R.U.C: 0927387597<br />
                                            Dirección: FLOR DE BASTION BLOQUE 8 MZ1284 SL24<br />
                                        </p>
                                    </Grid>
                                </Grid >

                            </Container>
                        </div>
                    </PDFExport>
                {/* </div> */}
            </Container>

        </>
    )
}
