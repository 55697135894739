import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Select,
    FormControl,
    MenuItem,
    TextField,
    Card,
    CardContent,
    InputAdornment,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Container,
    Typography,
    Paper,
    LinearProgress,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';
import { auth, db, functions, firebase } from '../../firebase';
import { DetalleFactura } from './DetalleFactura';
import { useSelector } from 'react-redux';
import { DataGrid, esES } from '@mui/x-data-grid';
import { GraficaConsumo } from './GraficaConsumo';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Logs } from './Logs';
import { Notas } from './Notas';
import { HistorialTickets } from './HistorialTickets';


export const ConsultaServicios = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [cedula, setCedula] = useState('')
    const [linea, setLinea] = useState(0)

    const [data, setData] = useState([{}])
    const [data2, setData2] = useState([{}])

    const [ID, setID] = useState('')
    const [codigo, setCodigo] = useState('')
    const [estado, setEstado] = useState('')
    const [cedula2, setCedula2] = useState('')
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [movil, setMovil] = useState('')
    const [direccion, setDireccion] = useState('')
    const [referencia, setReferencia] = useState('')
    const [correo, setCorreo] = useState('')
    const [contrato, setContrato] = useState('')
    const [agente, setAgente] = useState('')
    const [instalador, setInstaldor] = useState('')
    const [gpon, setGpon] = useState('')
    const [routerMac, setRouterMac] = useState('')
    const [modelo, setModelo] = useState('')
    const [codigoPago, setCodigoPago] = useState('')

    const [deuda, setDeuda] = useState('')
    const [plan, setPlan] = useState("")

    const [open, setOpen] = useState(false)

    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)

    const [facturas, setFacturas] = useState([])
    const [nodos, setNodos] = useState([])
    const [loading, setLoading] = useState(false)

    const [suspension, setSuspension] = useState('')
    const [ultimo, setUltimo] = useState('')

    useEffect(() => {
        cargarNodos()
    }, []);

    const cargarNodos = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispCCR/`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let re2 = JSON.parse(result)
                const data = [
                    ...re2.map((item) => {
                        return {
                            ...item,
                            alias: item.nodo,
                        }
                    })
                ]
                setNodos(data)
                console.log(data)
            }).catch(error => console.log('error', error));
    }


    useEffect(() => {
        if (data[0].nombre) {

            if (data[linea].estado !== 'RETIRADO') {
                setID(data[linea].id)
                setCodigo(data[linea].codigo)
                setEstado(data[linea].estado)
                setCedula2(data[linea].cedula)
                setNombre(data[linea].nombre)
                setTelefono(data[linea].telefono)
                setMovil(data[linea].movil)
                setDireccion(data[linea].direccion_principal)
                setReferencia(data[linea].REFERENCIA)
                setCorreo(data[linea].correo)
                setContrato(data[linea].NUM_CONTRATO)
                setAgente(data[linea].AGENTE_VENDEDOR)
                setInstaldor(data[linea].INSTALADOR)
                setGpon(data[linea].DATOS_GPON)
                setRouterMac(data[linea].ROUTER_MAC)
                setModelo(data[linea].ROUTER_MODELO)
                setCodigoPago(data[linea].codigo_pago)
            } else {
                setEstado(data[linea].estado)
                setData2([{}])
                setID('')
                setCodigo('')
                setCedula2('')
                setNombre('')
                setTelefono('')
                setMovil('')
                setDireccion('')
                setReferencia('')
                setCorreo('')
                setContrato('')
                setAgente('')
                setInstaldor('')
                setGpon('')
                setRouterMac('')
                setModelo('')
                setCodigoPago('')
                setDeuda('')
                setPlan('')
                setUltimo('')
                setSuspension('')
                setFacturas([])
            }

        }
    }, [linea]);

    const cargarSuspension = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiInfoMikrowisp/?suspendido=${data[linea].id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let re2 = JSON.parse(result)
                const data = [
                    ...re2.map((item) => {
                        return {
                            ...item,
                        }
                    })
                ]
                setSuspension(data[0].fecha_suspendido)
            }).catch(error => console.log('error', error));
    }

    const cargarUltimoPago = () => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiInfoMikrowisp/?pago=${data[linea].id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let re2 = JSON.parse(result)
                const data = [
                    ...re2.map((item) => {
                        return {
                            ...item,
                        }
                    })
                ]
                setUltimo(data[0].ultimo)
            }).catch(error => console.log('error', error));
    }


    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            loadInfo()
        }
    };

    const columns = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleFactura id={params.row.id} />
                </>
            }
        },
        {
            field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estado && params.row.estado !== "pagado" &&
                        <Chip label={params.row.estado} color='warning' style={{ textTransform: 'uppercase' }} />}
                    {params.row.estado && params.row.estado === "pagado" &&
                        <Chip label={params.row.estado} color='success' style={{ textTransform: 'uppercase' }} />}

                </>
            }
        },
        { field: 'emitido', headerClassName: 'super-app-theme--header', headerName: 'F. Emisión', width: 120 },
        { field: 'vencimiento', headerClassName: 'super-app-theme--header', headerName: 'F. Vencimiento', width: 150 },
        {
            field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'TIPO', width: 140,
            renderCell: (params) => {
                return <>
                    {params.row.tipo && params.row.tipo === "2" &&
                        <Chip label={"SERVICIOS"} color='info' />}
                    {params.row.tipo && params.row.tipo === "1" &&
                        <Chip label={"LIBRE"} color='success' />}

                </>
            }
        },
        { field: 'forma', headerClassName: 'super-app-theme--header', headerName: 'Forma Pago', width: 200 },
        {
            field: 'cobrado', headerClassName: 'super-app-theme--header', headerName: 'Pagado', width: 100,
            renderCell: (params) => {
                return <>
                    $ {params.row.cobrado}
                </>
            }
        },
        {
            field: 'pago', headerClassName: 'super-app-theme--header', headerName: 'F. Pago', width: 120,
            renderCell: (params) => {
                return <>
                    {params.row.pago}

                </>
            }
        },
        {
            field: 'total', headerClassName: 'super-app-theme--header', headerName: 'TOTAL', width: 100,
            renderCell: (params) => {
                return <>
                    $ {params.row.total}

                </>
            }
        },

    ]


    const cargarDeuda = async () => {
        cargarUltimoPago()
        if (data[linea].estado === 'SUSPENDIDO') {
            cargarSuspension()
        }
        if (data[linea].estado !== 'RETIRADO') {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?idcliente=${data[linea].id}`,
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    let re2 = JSON.parse(result)
                    let valor = 0.00
                    if (re2[0].id) {
                        re2.map((item) => {
                            if (item.estado === 'No pagado' && item.total && parseFloat(item.total)) {
                                valor += parseFloat(item.total)
                            }
                        })
                    }
                    setDeuda(valor)
                }).catch(error => console.log('error', error));
            handleFacturas()
        }
    }

    const handleFacturas = () => {
        if (data[linea].estado !== 'RETIRADO') {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?idcliente=${data[linea].id}`,
                requestOptions)
                .then(response => response.text())
                .then(result => {
                    let re2 = JSON.parse(result)
                    if (re2.length > 0) {
                        re2.reverse()
                        if (re2.length > 20) {
                            setFacturas(re2.slice(0, 20))
                        } else {
                            setFacturas(re2)
                        }
                    }
                }).catch(error => console.log('error', error));
        }
    }

    const handlePlan = (id) => {
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiMikrowispPerfilesplanes/?id=${id}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let re2 = JSON.parse(result)
                if (re2[0].id) {
                    setPlan(re2[0].plan)
                }
            }).catch(error => console.log('error', error));
    }


    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setOpen2(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setOpen2(false)
    }

    useEffect(() => {
        if (data[0].nombre) {
            setFacturas([])
            setPlan("")
            setDeuda('')
            setUltimo('')
            setSuspension('')
            if (data[linea].id) {
                let requestOptions = {
                    method: 'GET',
                    redirect: 'follow'
                };
                fetch(`https://serverbd.intercommerce.com.ec/apimikrowispservicio/?idcliente=${data[linea].id}`,
                    requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const re2 = JSON.parse(result)
                        if (re2.length > 0) {
                            setData2(re2[0])
                            if (re2[0].idperfil) {
                                handlePlan(re2[0].idperfil)
                            }
                        }
                    }).catch(error => console.log('error', error));
            }
            cargarDeuda()
        }
    }, [linea, data]);


    const loadInfo = () => {
        if (cedula.length > 7) {
            let requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`https://serverbd.intercommerce.com.ec/apiusuarios/?campos=${cedula}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const re = JSON.parse(result)
                    if (re.length > 0) {
                        setEstado(re[0].estado)
                        setData(re)
                        if (re[0].estado !== 'RETIRADO') {
                            setID(re[0].id)
                            setCodigo(re[0].codigo)
                            setCedula2(re[0].cedula)
                            setNombre(re[0].nombre)
                            setTelefono(re[0].telefono)
                            setMovil(re[0].movil)
                            setDireccion(re[0].direccion_principal)
                            setReferencia(re[0].REFERENCIA)
                            setCorreo(re[0].correo)
                            setContrato(re[0].NUM_CONTRATO)
                            setAgente(re[0].AGENTE_VENDEDOR)
                            setInstaldor(re[0].INSTALADOR)
                            setGpon(re[0].DATOS_GPON)
                            setRouterMac(re[0].ROUTER_MAC)
                            setModelo(re[0].ROUTER_MODELO)
                            setCodigoPago(re[0].codigo_pago)
                        }
                    } else {
                        fetch(`https://serverbd.intercommerce.com.ec/apiusuarios/?campos=%${cedula}%`, requestOptions)
                            .then(response => response.text())
                            .then(result => {
                                const re2 = JSON.parse(result)
                                if (re2.length > 0) {
                                    setEstado(re2[0].estado)
                                    setData(re2)
                                    if (re2[0].estado !== 'RETIRADO') {
                                        setID(re2[0].id)
                                        setCodigo(re2[0].codigo)
                                        setCedula2(re2[0].cedula)
                                        setNombre(re2[0].nombre)
                                        setTelefono(re2[0].telefono)
                                        setMovil(re2[0].movil)
                                        setDireccion(re2[0].direccion_principal)
                                        setReferencia(re2[0].REFERENCIA)
                                        setCorreo(re2[0].correo)
                                        setContrato(re2[0].NUM_CONTRATO)
                                        setAgente(re2[0].AGENTE_VENDEDOR)
                                        setInstaldor(re2[0].INSTALADOR)
                                        setGpon(re2[0].DATOS_GPON)
                                        setRouterMac(re2[0].ROUTER_MAC)
                                        setModelo(re2[0].ROUTER_MODELO)
                                        setCodigoPago(re2[0].codigo_pago)
                                    }
                                }
                            }).catch(error => console.log('error', error));
                    }
                }).catch(error => console.log('error', error));
        }
    }

    const loader = (
        <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress align="center" size={30} />
        </Stack>
    )

    const onsubmit = () => {
        setOpen2(true)
        let data11 = {
            token: "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
            idcliente: parseInt(ID),
            datos: {
                "AGENTE_VENDEDOR": agente,
                "DATOS_GPON": gpon,
                "INSTALADOR": instalador,
                "NUM_CONTRATO": contrato,
                "REFERENCIA": referencia,
                "ROUTER_MAC": routerMac,
                "ROUTER_MODELO": modelo,
                "cedula": cedula2,
                "codigo": codigo,
                "codigo_pago": codigoPago,
                "correo": correo,
                "direccion_principal": direccion,
                "movil": movil,
                "nombre": nombre,
                "telefono": telefono
            }
        };

        let anterior = data[linea] ? data[linea] : ''
        const updateUserMikro = functions.httpsCallable('updateUserMikro');
        updateUserMikro(data11)
            .then(res => {
                const authUser = auth.currentUser
                db.collection("logs").doc(`updateUserMikro  ${new Date()}`).set({
                    estado: 2,
                    departamento: 'CALL CENTER',
                    function: 'updateUserMikro',
                    detalle: `id_cliente: ${ID} Response: ${res.data.mensaje}`,
                    usuario: authUser.displayName,
                    datosAnteriores: anterior,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })
                setOpen2(false)
                Swal.fire({ icon: 'success', text: `${res.data.mensaje}` }).then(() => {
                    limpiar()
                    // loadInfo()
                })
            })
            .catch(error => {
                const authUser = auth.currentUser
                db.collection("logs").doc(`updateUserMikro  ${new Date()}`).set({
                    estado: 2,
                    departamento: 'CALL CENTER',
                    function: 'updateUserMikro',
                    detalle: `id_cliente: ${ID} Error ${error}`,
                    usuario: authUser.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })
                setOpen2(false)
                Swal.fire({ icon: 'error', text: `Error` })

            })
    }

    const [pingRespuesta, setPingRespuesta] = useState([])

    const realizarPing = (info) => {
        setOpen2(true)
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "ipConsult": info,
            "packages": 4
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://serverapiolt.intercommerce.com.ec/api/makePing",
            requestOptions)
            .then(response => response.text())
            .then(result => {
                const dataJSON = JSON.parse(result)
                if (dataJSON.status === 200) {
                    setPingRespuesta(dataJSON.console_output)
                } else {
                    setPingRespuesta([])
                }
                setOpen2(false)
                setOpen3(true)

            }).catch(error => {
                setPingRespuesta([])
                setOpen2(false)
                setOpen3(true)
            });
    }

    const limpiar = () => {
        setCedula('')
        setData([{}])
        setData2([{}])
        setLinea(0)
        setID('')
        setCodigo('')
        setEstado('')
        setCedula2('')
        setNombre('')
        setTelefono('')
        setMovil('')
        setDireccion('')
        setReferencia('')
        setCorreo('')
        setContrato('')
        setAgente('')
        setInstaldor('')
        setGpon('')
        setRouterMac('')
        setModelo('')
        setCodigoPago('')
        setDeuda('')
        setUltimo('')
        setSuspension('')
        setPlan('')
        setFacturas([])
    }

    const regexCell = new RegExp('^[0-9]{10}$')
    // const regexCorreo = new RegExp('@[a-zA-Z]+(\.[a-zA-Z]*)*')
    const activarServicio = () => {
        console.log(ID, ' ID')
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({
            "idcliente": ID
        });
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        setOpen2(true)
        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/mikrowisp/activar_servicio",
            requestOptions)
            .then(response => response.text())
            .then(result => {
                const dataJSON = JSON.parse(result)
                setOpen2(false)
                Swal.fire({ icon: 'success', text: `${dataJSON.mensaje}` })

                const authUser = auth.currentUser
                db.collection("logs").doc(`activateUserMikro  ${new Date()}`).set({
                    estado: 2,
                    departamento: 'CALL CENTER',
                    function: 'activateUserMikro',
                    detalle: `id_cliente: ${ID} Response: ${dataJSON.mensaje}`,
                    usuario: authUser.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })
                setLoading(false)
            }).catch(error => {
                const authUser = auth.currentUser
                db.collection("logs").doc(`activateUserMikro  ${new Date()}`).set({
                    estado: 2,
                    departamento: 'CALL CENTER',
                    function: 'activateUserMikro',
                    detalle: `id_cliente: ${ID} Response: ${error}`,
                    usuario: authUser.displayName,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })
                setOpen2(false)
                Swal.fire({ icon: 'error', text: `${error}` })
                setLoading(false)
                console.log('error', error)
            });
    }
    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>CONSULTA DE SERVICIOS</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4} lg={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>CEDULA / RUC / NOMBRE / SN:</strong></p>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cedula}
                                            onChange={(e) => setCedula(e.target.value)}
                                            size="small"
                                            onKeyDown={handleKeyDown}
                                            fullWidth
                                            disabled={data[0].nombre}
                                            error={cedula.length > 0 && cedula.length < 8}
                                            helperText={(cedula.length > 0 && cedula.length < 8)
                                                ? 'Minimo 8 caracteres' : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => loadInfo()}>
                                                        <SearchIcon color='primary' />
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button color="error" style={{ marginTop: "30px" }}
                                            disableElevation onClick={() => limpiar()} variant="contained">
                                            {" "}
                                            LIMPIAR
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={4} >
                                        {
                                            userInfo?.roles?.find(role => ['activar_servicio']?.includes(role)) &&
                                            <Button color="success"
                                                disabled={ID === ''
                                                    || nombre === '' || estado === "ACTIVO" || loading}
                                                style={{ marginTop: "25px" }}
                                                disableElevation onClick={() => activarServicio()} variant="contained">
                                                {" "}
                                                ACTIVAR SERVICIO
                                            </Button>
                                        }

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <a style={{ fontSize: "16px" }}>
                                            <strong>DATOS DEL CLIENTE ({data[0].nombre ? data.length
                                                : 0} LINEAS)</strong></a>
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {estado === "ACTIVO" &&
                                            <Chip label={estado} size="medium"
                                                color='success' style={{ fontSize: "16px" }} />
                                        }
                                        {estado === "RETIRADO" &&
                                            <Chip label={estado} size="medium"
                                                color='error' style={{ fontSize: "16px" }} />
                                        }
                                        {estado === "SUSPENDIDO" &&
                                            <>
                                                <Chip label={estado} size="medium"
                                                    color='error' style={{ fontSize: "16px" }} />
                                                <br /><a><strong>Fecha:</strong>
                                                    {suspension ? suspension.split(" ")[0] : ''}</a>
                                            </>
                                        }
                                        {estado === "ONLINE" &&
                                            <Chip label={estado} size="medium"
                                                color='success' style={{ fontSize: "16px" }} />
                                        }
                                        {estado === "" && data[0].nombre !== undefined &&
                                            loader
                                        }
                                        <br />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {deuda !== "" &&
                                            <>
                                                <Chip onClick={() => setOpen(true)}
                                                    label={"PENDIENTE: $" + deuda} size="medium"
                                                    color='warning' style={{ fontSize: "16px", marginRight: '5px' }} />
                                                <br /><a><strong>U. Pago:</strong> {ultimo}</a>
                                            </>
                                        }
                                        {deuda === "" && facturas.length > 0 &&
                                            <>
                                                <Chip onClick={() => setOpen(true)}
                                                    label={"PENDIENTE: $0.00"} size="medium" color='warning'
                                                    style={{ fontSize: "16px", marginRight: '5px' }} />
                                                <br /><a><strong>U. Pago:</strong> {ultimo}</a>
                                            </>
                                        }
                                        {deuda === "" && facturas.length === 0 && data[0].nombre !== undefined &&
                                            loader
                                        }
                                        <br />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}>
                                            <strong>LINEA:</strong></p>
                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={linea}
                                                onChange={(e) => setLinea(e.target.value)}
                                            >
                                                {data ? data.map((item, index) => (
                                                    <MenuItem key={index + item.nombre}
                                                        value={index}>{item.nombre}</MenuItem>
                                                )) : <></>}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        {estado !== '' &&
                                            <>
                                                <a style={{ fontSize: "11px", marginBottom: "10px" }}>
                                                    <strong>OPCIONES:</strong></a>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={12} md={3}>
                                                        <Logs id={ID} nombre={nombre} plan={plan} />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <Notas id={ID} nombre={nombre} />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <GraficaConsumo servicio={data2.id} />
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <HistorialTickets cedula={cedula} />
                                                    </Grid>

                                                </Grid>
                                            </>
                                        }
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>ID:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={ID || ''}
                                                        disabled={true}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>CODIGO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={codigo || ''}
                                                        disabled={true}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>ESTADO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={estado || ''}
                                                        disabled={true}
                                                        // onChange={(e) => setEstado(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <a style={{ fontSize: "12px", marginBottom: "10px" }}>PPPUSER:</a>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value=""
                                                        disabled={true}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                {data2.pppuser}
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <a style={{ fontSize: "12px", marginBottom: "10px" }}>PPPPASS:</a>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value=""
                                                        disabled={true}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                {data2.ppppass}
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <a style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        COORDENADAS:</a>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value=""
                                                        disabled={true}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                {data2.coordenadas}
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        CEDULA / RUC:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={cedula2 || ''}
                                                        onChange={(e) => setCedula2(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        error={ID.length > 0 && cedula2.length < 1}
                                                        helperText={ID.length > 0 && cedula2.length
                                                            < 1 ? "Ingrese una identificación" : ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>NOMBRES:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={nombre || ''}
                                                        onChange={(e) => setNombre(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        error={ID.length > 0 && nombre.length < 1}
                                                        helperText={ID.length > 0 && nombre.length
                                                            < 1 ? "Ingrese un nombre" : ''}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>TELEFONO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={telefono || ''}
                                                        onChange={(e) => setTelefono(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        error={ID.length > 0 && !regexCell.test(telefono)}
                                                        helperText={ID.length > 0 && !regexCell.test(telefono)
                                                            ? "Ingrese 10 digitos" : ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>MOVIL:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={movil || ''}
                                                        onChange={(e) => setMovil(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        error={ID.length > 0 && !regexCell.test(movil)}
                                                        helperText={ID.length > 0 && !regexCell.test(movil)
                                                            ? "Ingrese 10 digitos" : ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        DIRECCION PRINCIPAL:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={direccion || ''}
                                                        onChange={(e) => setDireccion(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        error={ID.length > 0 && direccion.length < 1}
                                                        helperText={ID.length > 0 && direccion.length < 1
                                                            ? "Ingrese una dirección" : ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        REFERENCIA:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={referencia || ''}
                                                        onChange={(e) => setReferencia(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>CORREO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={correo || ''}
                                                        onChange={(e) => setCorreo(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>PLAN:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Chip label={plan || ''} color='info'
                                                        style={{ textTransform: 'uppercase' }} />

                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <a style={{ fontSize: "12px", marginBottom: "10px" }}>NODO:</a>
                                                </Grid>
                                                <Grid item xs={9} >
                                                    <Chip color='success'
                                                        label={data2.nodo && nodos.filter((item) => item.id ===
                                                            data2.nodo).length > 0 ?
                                                            nodos.filter((item) => item.id === data2.nodo)[0].alias :
                                                            ''} style={{ textTransform: 'uppercase' }} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        IP SERVICIO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Stack direction='row' spacing={1}>
                                                        <TextField sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                            size="small"
                                                            value=""
                                                            disabled={true}
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">
                                                                    {data2.ip &&
                                                                        <a href={"http://" + data2.ip}
                                                                            target="_blank"> {data2.ip} </a>
                                                                    }
                                                                </InputAdornment>
                                                            }}
                                                        />
                                                        <Button color="error" disabled={!data2.ip}
                                                            onClick={() => realizarPing(data2.ip)} variant="contained">
                                                            {" "}
                                                            PING
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <a style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        FECHA INSTALACION:</a>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        size="small"
                                                        value=""
                                                        disabled={true}
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                {data2.instalado &&
                                                                    <a> {data2.instalado !== '0000-00-00' ?
                                                                        data2.instalado : ''} </a>
                                                                }
                                                            </InputAdornment>
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>CONTRATO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={contrato || ''}
                                                        onChange={(e) => setContrato(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        AGENTE VENDEDOR:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={agente || ''}
                                                        onChange={(e) => setAgente(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        INSTALADOR:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={instalador || ''}
                                                        onChange={(e) => setInstaldor(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        DATOS GPON:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={gpon || ''}
                                                        onChange={(e) => setGpon(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        ROUTER MAC:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={routerMac || ''}
                                                        onChange={(e) => setRouterMac(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        ROUTER MODELO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={modelo || ''}
                                                        onChange={(e) => setModelo(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box py={1}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                                                        CODIGO PAGO:</p>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                        value={codigoPago || ''}
                                                        onChange={(e) => setCodigoPago(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button
                                                onClick={() => onsubmit()}
                                                disabled={estado === "RETIRADO" || cedula2 === '' /*|| correo === ''*/
                                                    || direccion === '' || telefono === ''
                                                    || nombre === '' || movil === '' /*|| referencia === ''*/
                                                    || !regexCell.test(telefono) || !regexCell.test(movil)
                                                    /*|| !regexCorreo.test(correo)*/}
                                                fullWidth
                                                variant="contained"
                                                color="secondary"
                                            >
                                                {" "}
                                                ACTUALIZAR{" "}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">
                                    <DialogTitle><strong>FACTURAS PENDIENTES DE {nombre}</strong>
                                    </DialogTitle>
                                    <DialogContent>
                                        <ExcelExport onExportComplete={handleExport2}
                                            data={facturas} fileName={"Facturas de " +
                                                nombre + " .xlsx"}
                                            ref={exporter} filterable={true} >
                                            <ExcelExportColumn field="id" title="ID"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="estado" title="ESTADO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="emitido" title="F. EMISION"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="vencimiento" title="F VENCIMIENTO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="tipo" title="TIPO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="forma" title="FORMA PAGO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="cobrado" title="PAGADO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="pago" title="F. PAGO"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                            <ExcelExportColumn field="total" title="TOTAL"
                                                headerCellOptions={{ background: "#672780" }} width={200} />
                                        </ExcelExport>
                                        <br />
                                        <Button onClick={() => excelExport()} size="small" color="success"
                                            variant="outlined" startIcon={<SimCardDownloadIcon />}
                                        >
                                            {" "}
                                            DESCARGAR EXCEL
                                        </Button>
                                        <Paper elevation={2} sx={{ padding: '10px' }}>
                                            <Box sx={{
                                                height: 400, '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                                '& .super-app-theme--header': {
                                                    backgroundColor: 'LightSlateGray',
                                                    color: 'white',
                                                    fontSize: '16px'
                                                },
                                            }}>
                                                <DataGrid
                                                    slots={{
                                                        loadingOverlay: LinearProgress,
                                                    }}
                                                    loading={facturas.length === 0}
                                                    hideFooterSelectedRowCount={true}
                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                    columns={columns}
                                                    density='compact'
                                                    rows={facturas ?? []}
                                                />
                                            </Box>
                                        </Paper>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog open={open2} >
                                    <DialogTitle>
                                        <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress align="center" />
                                            <strong><p align="center" style={{ fontSize: '14px' }}>
                                                CARGANDO...</p></strong>
                                        </Stack>
                                    </DialogTitle>
                                </Dialog>

                                <Dialog open={open3} onClose={() => setOpen3(false)}>
                                    <DialogTitle><strong>PING: {data2.ip ? data2.ip : ''}</strong>
                                    </DialogTitle>
                                    <DialogContent>
                                        <br />
                                        <Container>
                                            {pingRespuesta.length > 0 ? <>
                                                <Table size="small" aria-label="simple table">
                                                    <TableHead style={{ background: '#672780' }}>
                                                        <TableRow >
                                                            <TableCell sx={{ color: 'white' }}>
                                                                <strong>Host</strong></TableCell>
                                                            <TableCell sx={{ color: 'white' }}>
                                                                <strong>Size</strong></TableCell>
                                                            <TableCell sx={{ color: 'white' }}>
                                                                <strong>TTL</strong></TableCell>
                                                            <TableCell sx={{ color: 'white' }}>
                                                                <strong>Time</strong></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {pingRespuesta.length > 0 ? pingRespuesta.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell component="th" scope="row">
                                                                    {item.Host} </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.Size} </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.TTL} </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {item.Time} </TableCell>
                                                            </TableRow>
                                                        )) : ''}
                                                    </TableBody>
                                                </Table>
                                            </> : <>
                                                <Typography variant="h5"  >
                                                    EL PROTOCOLO ICMP NO FUE EJECUTADO CORRECTAMENTE
                                                </Typography>
                                            </>}

                                        </Container>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setOpen3(false)}><strong>Regresar</strong></Button>
                                    </DialogActions>
                                </Dialog>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box >
        </>
    )
}
