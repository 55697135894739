import React, { useEffect, useState } from "react"
import { db, firebase } from "../../../firebase"
import Swal from "sweetalert2"
import { Grid, Button, Box, CardContent, TextField, InputLabel, Divider } from "@mui/material"
import InputValidation from "../../../components/utils/InputValidation"
import { useSelector } from 'react-redux'
import { DataGrid, esES } from '@mui/x-data-grid'
export const CrearCentroCosto = () => {
    const userAuth = useSelector((state) => state.userAuth)
    const { loading, userInfo } = userAuth

    const [lider, setLider] = useState({ campo: '', valido: true })
    const [centro, setCentro] = useState({ campo: '', valido: true })
    const [categoria, setCategoria] = useState({ campo: '', valido: true })
    const [canal, setCanal] = useState({ campo: '', valido: true })
    useEffect(() => {
    }, [])
    const onsubmit1 = () => {
        db.collection('system/centro_de_costos/lider_de_ventas').doc(lider.campo).set({
            lider: lider.campo
        }).then(() => {
            setLider({ campo: '', valido: true })
            Swal.fire({ icon: 'success', text: "Se creo lider de venta" })
        }).catch((e) => {
            alert(e)
        })
    }
    const onsubmit2 = () => {
        db.collection('system/centro_de_costos/centro_de_costo').doc(centro.campo).set({
            centro: centro.campo
        }).then(() => {
            setCentro({ campo: '', valido: true })
            Swal.fire({ icon: 'success', text: "Se creo el centro de costo" })
        }).catch((e) => {
            alert(e)
        })
    }
    const onsubmit3 = () => {
        db.collection('system/centro_de_costos/categoria').doc(categoria.campo).set({
            categoria: categoria.campo
        }).then(() => {
            setCategoria({ campo: '', valido: true })
            Swal.fire({ icon: 'success', text: "Se creo la categoria" })
        }).catch((e) => {
            alert(e)
        })
    }
    const onsubmit4 = () => {
        db.collection('system/centro_de_costos/canal').doc(canal.campo).set({
            canal: canal.campo
        }).then(() => {
            setCanal({ campo: '', valido: true })
            Swal.fire({ icon: 'success', text: "Se creo el canal de venta" })
        }).catch((e) => {
            alert(e)
        })
    }
    return (
        <>
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item xs={10} md={6}>
                        <Box pb={4}>
                            <InputValidation
                                estado={lider}
                                cambiarEstado={setLider}
                                label="LIDER DE VENTA"
                            />
                            <Button
                                onClick={() => onsubmit1()}
                                disabled={!lider.campo}
                                variant="contained"
                                color="secondary"
                            >Crear Lider de Venta
                            </Button>
                        </Box>
                        <Divider />
                        <Box pb={4}>
                            <InputValidation
                                estado={centro}
                                cambiarEstado={setCentro}
                                label="CENTRO DE COSTE"
                            />
                            <Button
                                onClick={() => onsubmit2()}
                                disabled={!centro.campo}
                                variant="contained"
                                color="secondary"
                            >Crear Centro de Coste
                            </Button>
                        </Box>
                        <Divider />
                    </Grid>
                    <Grid item xs={10} md={6}>
                    <Box pb={4}>
                            <InputValidation
                                estado={categoria}
                                cambiarEstado={setCategoria}
                                label="CATEGORIA"
                            />
                            <Button
                                onClick={() => onsubmit3()}
                                disabled={!categoria.campo}
                                variant="contained"
                                color="secondary"
                            >Crear Categoria
                            </Button>
                        </Box>
                        <Box pb={4}>
                            <InputValidation
                                estado={canal}
                                cambiarEstado={setCanal}
                                label="CANAL DE VENTA"
                            />
                            <Button
                                onClick={() => onsubmit4()}
                                disabled={!canal.campo}
                                variant="contained"
                                color="secondary"
                            >Crear Canal de Vneta
                            </Button>
                        </Box>
                    </Grid>
                </Grid>


            </CardContent>
        </>
    )
}
