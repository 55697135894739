import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const ListActivosFijos = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)

    const [open7, setOpen7] = useState(false);
    const [open8, setOpen8] = useState(false);
    const [open9, setOpen9] = useState(false);
    const [open10, setOpen10] = useState(false);

    const [open11, setOpen11] = useState(false);


    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'activos_fijo']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <LibraryBooksIcon />
                    </ListItemIcon>
                    <ListItemText primary="Activos Fijos" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`activos-fijos/crear-producto/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Crear Activo" />
                        </ListItemButton>

                    </List>

                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`activos-fijos/lista-activos-fijos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Lista activos fijos" />
                        </ListItemButton>

                    </List>

                    <List component="div" disablePadding dense>

                        <ListItemButton component={RouterLink} to={`activos-fijos/transferencias-activos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Transferencia activos" />
                        </ListItemButton>

                    </List>

                    <ListItemButton onClick={() => setOpen7(!open7)}>
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary="Parametros" />
                        {open7 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open7} timeout="auto" unmountOnExit>


                        {/* COLLAPSE GENERAL */}

                        <ListItemButton onClick={() => setOpen8(!open8)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="General" />
                            {open8 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open8} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['tipo de activos fijos'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/tipo-de-activos-fijos/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Categoria'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/categoria/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Subcategoria'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/subcategoria/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['clase de activo'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/clase-de-activo/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Unidad de medida'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/unidad-de-medida/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            {/* <List component="div" disablePadding dense>
                                {['Tipo de producto'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/tipo-de-producto/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Clase de producto'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/general/clase-de-producto/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List> */}
                        </Collapse>

                        {/* COLLAPSE INFORMACION TECNICA */}

                        <ListItemButton onClick={() => setOpen9(!open9)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Informacion tecnica" />
                            {open9 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open9} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Marcas'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/informacion-tecnica/marcas/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Modelo'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/informacion-tecnica/modelo/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Condicion activo'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/informacion-tecnica/condicion-de-activo/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                        {/* COLLAPSE LOCALIDAD */}

                        <ListItemButton onClick={() => setOpen10(!open10)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Localidad" />
                            {open10 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open10} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding dense>
                                {['Localidad producto'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/localidad/localidad-producto/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Departamento'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/localidad/departamento/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>



                        {/* COLLAPSE DEPRECIACION */}

                        <ListItemButton onClick={() => setOpen11(!open11)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Depreciacion" />
                            {open11 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open11} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['metodo de depreciacion'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/metodo-de-depreciacion/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['vida util'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/vida-util/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['porcentaje de depreciacion'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/porcentaje-de-depreciacion/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Frecuencia de depreciacion'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/frecuencia-de-depreciacion/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Tipo transaccion '].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/tipo-transaccion/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Residual '].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/depreciacion/residual/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse>

                        {/* COLLAPSE SEGUROS */}

                        {/* <ListItemButton onClick={() => setOpen12(!open12)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Seguros" />
                            {open11 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={open12} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding dense>
                                {['Poliza No'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/seguros/poliza-no`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['vigencia documento'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/seguros/vigencia-documento/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Caducidad documento'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/seguros/caducidad-documento/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Suma asegurada'].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/seguros/suma-asegurada/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                            <List component="div" disablePadding dense>
                                {['Duracion seguro '].map((text, index) => (
                                    <ListItemButton
                                        key={text}
                                        component={RouterLink} to={`/activos-fijos/parametros/seguros/duracion-seguro/`}
                                    >
                                        <ListItemIcon
                                            style={{ marginLeft: 25 }}
                                        >
                                        </ListItemIcon>
                                        <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                    </ListItemButton>
                                ))}
                            </List>

                        </Collapse> */}

                        {/*  */}

                        {/* <List component="div" disablePadding dense>
                            {['tipo de activos fijos'].map((text, index) => (
                                <ListItemButton
                                    key={text}
                                    component={RouterLink} to={`/activos-fijos/parametros/tipo-de-activos-fijos/`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                </ListItemButton>
                            ))}
                        </List> */}



                        {/* <List component="div" disablePadding dense>
                            {['estado producto'].map((text, index) => (
                                <ListItemButton
                                    key={text}
                                    component={RouterLink} to={`/activos-fijos/parametros/estado-producto/`}
                                >
                                    <ListItemIcon
                                        style={{ marginLeft: 25 }}
                                    >
                                    </ListItemIcon>
                                    <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                                </ListItemButton>
                            ))}
                        </List> */}

                    </Collapse>

                </Collapse>
            </> : ''}
        </>
    )
}