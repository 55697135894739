import React, { useEffect, useState, useRef } from "react";
import { db, firebase, storage } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Select,
    FormControl,
    MenuItem,
    LinearProgress,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    CardActions,
    Autocomplete,
    Dialog,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Paper,
    InputBase,
    Divider,
    IconButton,
    Container,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Typography,
    TableContainer
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx/xlsx.mjs";
import SearchIcon from '@mui/icons-material/Search';
import { listsCentrosDeCostos } from "../../../redux/actions/parametersActions";

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
/* import { NuevoAsiento } from "./NuevoAsiento"; */
import { set } from "js-cookie";
import moment from 'moment'

import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

export const BalanceGeneral = () => {

    const [cuenta, setCuenta] = useState('')

    const [desde, setDesde] = useState(null);

    const [hasta, setHasta] = useState(null);

    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [Imagen, setImagen] = useState(null);
    const dispatch = useDispatch()

    const [item, setItem] = useState('')
    const [productos, setProductos] = useState('')

    const [filtro, setFiltro] = useState('')

    const [nivel, setNivel] = useState('')

    const [vista, setVista] = useState('')

    const [centroCosto, setCentroCosto] = useState('')

    const [dataPlanCuentasGeneral, setDataPlanCuentasGeneral] = useState('')

    const [centrosDeCostos, setCentrosDeCostos] = useState('')

    const [itemCentroCosto, setItemCentroCosto] = useState('')

    const [proyecto, setProyecto] = useState('')

    const [tipo, setTipo] = useState('')

    const [proyectosData, setProyectosData] = useState('')

    const [itemProyecto, setItemProyecto] = useState('')

    const [inputFields, setInputFields] = useState([]);

    const [totalDebe, setTotalDebe] = useState('')

    const [totalHaber, setTotalHaber] = useState('')

    var current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getTime() - 86400000))
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))

    const getAsientos = (startAfterDoc, persistMessages = []) => {

        console.log(startDate)
        console.log(endDate)

        let ref = db.collection("asientos")

        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(12, 0, 0, 0)

            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('created', '>=', startfulldate)
            ref = ref.where('created', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                console.log(data)
                setData(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        }
    }

    const getPlanDeCuentasGenerales = (startAfterDoc, persistMessages = []) => {

        console.log('Datos de data plan cuentas general')
        console.log(startDate)
        console.log(endDate)

        let ref = db.collection("plan_cuentas_test").where('cuenta', '==', '5')

        ref.get(snapshot => {
            const data = [
                ...persistMessages,
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            console.log(data)
            setDataPlanCuentasGeneral(data)
        })

        /* if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0)
            endDate.setHours(12, 0, 0, 0)

            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            ref = ref.where('fecha_carga_plan_cuentas', '>=', startfulldate)
            ref = ref.where('fecha_carga_plan_cuentas', '<=', endfulldate)

            //Si tiene algun doc anterior se agrega acontinuacion
            if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
            ref.onSnapshot(snapshot => {
                const totalCount = snapshot.size
                const data = [
                    ...persistMessages,
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                console.log(data)
                setDataPlanCuentasGeneral(data)
                setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
                setIsLastPage(totalCount < 1)
            })
        } */

        /* var docRef = db.collection("plan_cuentas_test").where('cuenta', '>=', '4')
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setDataPlanCuentasGeneral(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
    }

    const getCuentas = () => {
        var docRef = db.collection("plan_cuentas_test")/* .where('cuenta', '>=', '4') */
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setDataPlanCuentasGeneral(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getCentrosCostos = () => {
        var docRef1 = db.collection("centros_de_costos")
        docRef1.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setCentrosDeCostos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getProyectos = () => {
        var docRef1 = db.collection("contabilidad_proyectos")
        docRef1.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProyectosData(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const centrosDeCostosList = useSelector(state => state.centrosDeCostosList)
    const { centroDeCostos, loadingCentroDeCosto } = centrosDeCostosList
    const [centroDeCosto, setCentroDeCosto] = useState('')

    useEffect(() => {
        getCuentas()
        getPlanDeCuentasGenerales()
        getCentrosCostos()
        getProyectos()
        /* getAsientos() */
        contadorDebe()
        contadorHaber()
    }, [dispatch]);


    const onHandleClick = async () => {
        console.log('Ok')
    }

    const [data, setData] = useState();

    const [dataExcel, setDataExcel] = useState();

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([]);

    const obtenerId = () => {
        const p = data.filter((item) => item.codigoProducto);
        console.log(data)
        console.log(`p ${p}`);
    }

    const [openCaja, setOpenCaja] = React.useState(false);

    const [message, setMessage] = useState();

    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0];
        setFileName(file.name);
        setFile(file);
    };


    /* const handleUploadFile = async () => {
        try {
            if (file) {
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data);
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setDataExcel(jsonData)
                jsonData.forEach((obj) => {
                    db.collection("productos")
                        .doc(obj.CODIGO_PRODUCTO)
                        .set({
                            codigo_producto: obj.CODIGO_PRODUCTO === '' ||
                                obj.CODIGO_PRODUCTO === null ||
                                obj.CODIGO_PRODUCTO === undefined ? '' : obj.CODIGO_PRODUCTO,

                            fecha_creacion_producto: firebase.firestore.FieldValue.serverTimestamp(),

                            descripcion_producto: obj.DESCRIPCION === '' ||
                                obj.DESCRIPCION === null ||
                                obj.DESCRIPCION === undefined ? '' : obj.DESCRIPCION.toUpperCase(),

                            categoria_producto: obj.CATEGORIA === '' ||
                                obj.CATEGORIA === null ||
                                obj.CATEGORIA === undefined ? '' : obj.CATEGORIA.toUpperCase(),

                            subcategoria_producto: obj.SUBCATEGORIA === '' ||
                                obj.SUBCATEGORIA === null ||
                                obj.SUBCATEGORIA === undefined ? '' : obj.SUBCATEGORIA.toUpperCase(),

                            presentacion_producto: obj.PRESENTACION === '' ||
                                obj.PRESENTACION === null ||
                                obj.PRESENTACION === undefined ? '' : obj.PRESENTACION.toUpperCase(),

                            marca_producto: obj.MARCA === '' ||
                                obj.MARCA === null ||
                                obj.MARCA === undefined ? '' : obj.MARCA.toUpperCase(),

                            modelo_producto: obj.MODELO === '' ||
                                obj.MODELO === null ||
                                obj.MODELO === undefined ? '' : obj.MODELO.toUpperCase(),

                            unidad_Medida_Producto: obj.UNIDAD_MEDIDA === '' ||
                                obj.UNIDAD_MEDIDA === null ||
                                obj.UNIDAD_MEDIDA === undefined ? '' : obj.UNIDAD_MEDIDA.toUpperCase(),

                            costo_estandar_producto: obj.COSTO_ESTANDAR === '' ||
                                obj.COSTO_ESTANDAR === null ||
                                obj.COSTO_ESTANDAR === undefined ? 0 : obj.COSTO_ESTANDAR,

                            precio_compra_producto: obj.PRECIO_COMPRA === '' ||
                                obj.PRECIO_COMPRA === null ||
                                obj.PRECIO_COMPRA === undefined ? 0 : obj.PRECIO_COMPRA,

                            precio_venta_producto: obj.PRECIO_VENTA === '' ||
                                obj.PRECIO_VENTA === null ||
                                obj.PRECIO_VENTA === undefined ? 0 : obj.PRECIO_VENTA,

                            cantidad_producto: obj.CANTIDAD === '' ||
                                obj.CANTIDAD === null ||
                                obj.CANTIDAD === undefined ? 0 : obj.CANTIDAD,

                            estado_producto: obj.ESTADO_PRODUCTO === '' ||
                                obj.ESTADO_PRODUCTO === undefined ||
                                obj.ESTADO_PRODUCTO === 'ACTIVO' ? 0 : 1,

                            stock_minimo_producto: obj.STOCK_MINIMO === '' ||
                                obj.STOCK_MINIMO === null ||
                                obj.STOCK_MINIMO === undefined ? 0 : obj.STOCK_MINIMO,

                            stock_seguridad_producto: obj.STOCK_SEGURIDAD === '' ||
                                obj.STOCK_SEGURIDAD === null ||
                                obj.STOCK_SEGURIDAD === undefined ? 0 : obj.STOCK_SEGURIDAD,

                            stock_maximo_producto: obj.STOCK_MAXIMO === '' ||
                                obj.STOCK_MAXIMO === null ||
                                obj.STOCK_MAXIMO === undefined ? 0 : obj.STOCK_MAXIMO,

                            usuario_que_creo_el_producto: userInfo.displayName
                        })
                });
                Swal.fire({
                    icon: "success",
                    title: "Subiendo datos, esto tardara un poco...",
                });
                setOpenCaja(false);
            } else {
                console.log("No se cargado ningun archivo");
            }
        } catch (error) {
            console.log("error", error);
            setMessage(error.toString());
        }
    }; */

    const fileRef = useRef();

    const handleRemove = () => {
        setFileName(null);
        setFile(null);
        fileRef.current.value = "";
    };

    const [openDialogCuentas, setOpenDialogCuentas] = useState(false);

    const [openDialogCentrosCostos, setOpenDialogCentrosCostos] = useState(false);

    const [openDialogProyectos, setOpenDialogProyectos] = useState(false);

    const columns = [
        { field: 'cuenta', headerName: 'CUENTA', width: 150 },
        { field: 'nombre', headerName: 'NOMBRE', width: 150 },
        { field: 'estado', headerName: 'ESTADO', width: 150 },
    ];

    const addCount = (row) => {
        console.log(row.nombre)
        setCuenta(row.nombre)
        setOpenDialogCuentas(false)
    }

    const addCentroCosto = (row) => {
        setCentroCosto(row.centro_de_costo)
        setOpenDialogCentrosCostos(false)
    }

    const addProyecto = (row) => {
        setProyecto(row.proyecto)
        setOpenDialogProyectos(false)
    }

    /* TOTAL DEBE */

    const contadorDebe = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.debe)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.debe);
                }
                const total = contenido[i]["debe"] + contenido[i]["debe"]
                /* list[i]["precio_total"] = total ? total : 0 */
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                /* var docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                }); */

            }
            return suma;
        }
        const list = inputFields
        const total = sumarContenidoArreglo(inputFields)
        setTotalDebe(total)
    }

    /* TOTAL HABER */

    const contadorHaber = () => {
        function esNumerico(dato) {
            return !isNaN(parseFloat(dato)) && isFinite(dato);
        }
        function sumarContenidoArreglo(contenido) {
            if (!Array.isArray(contenido)) {
                return null;
            }
            let suma = 0;

            for (let i = 0; i < contenido.length; i++) {
                const elemento = contenido[i];
                if (esNumerico(elemento.haber)) {
                    //var total_ = elemento.costo_estandar_producto * elemento.cantidad_pedida
                    suma += parseFloat(elemento.haber);
                }
                const total = contenido[i]["haber"] + contenido[i]["haber"]
                /* list[i]["precio_total"] = total ? total : 0 */
                //
                const id = contenido[i]["codigo"]
                //const precio_compra_producto = productoPrecioHistorico(`${id}`)
                //
                /* var docRef = db.collection("productos").doc(id);
                docRef.get().then((doc) => {
                    if (doc.exists) {
                        const data = doc.data()
                        //sum = data.precio_compra_producto
                        console.log(data.precio_compra_producto, '--', id)
                        //return sum
                        list[i]["precio_compra_producto"] = data.precio_compra_producto
                    } else {
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                }); */

            }
            return suma;
        }
        const list = inputFields
        const total = sumarContenidoArreglo(inputFields)
        setTotalHaber(total)
    }

    /* ESTILOS DE LA TABLA CAMPO FECHA */

    const StyledTableCellFecha = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#D2CFCE',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
    }));

    /* ESTILOS DE LA TABLA CUENTAS */

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F5F5F5',
            color: theme.palette.common.black,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 13,
        },
    }));

    /* FUNCION DATA RANGO DE FECHAS ASIENTOS */

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        const [dataItems, setDataItems] = useState();
        const [isLastPageItems, setIsLastPageItems] = useState(0);
        const [queryDocItems, setQueryDocqueryDocItems] = useState([]);

        const desplegarTablaOpen = async (props) => {
            setOpen(!open)
            console.log(props.id)
            /* console.log('desplegado')
            console.log(props.id)
            const agendaDB = await db.collection("plan_cuentas").where("codigo_contable", "==", props.id).get()
            const data1 = await agendaDB.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            console.log(data1[0].motivo) */

            let ref = db.collection("asientos").doc(props.id).collection('items');
            //Si tiene algun doc anterior se agrega acontinuacion
            ref.onSnapshot((snapshot) => {
                const totalCount = snapshot.size;
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];

                setDataItems(data);
                setQueryDocqueryDocItems(snapshot.docs[totalCount - 1]);
                setIsLastPageItems(totalCount < 1);
                console.log(data)
            });
        }

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell component="th" scope="row" style={{ fontSize: '11px' }}>
                        <strong>{row.created && typeof row.created === 'number' &&
                            moment(new Date().toString()).format('MMMM Do YYYY')}
                            {row.created && typeof row.created === 'object' &&
                                moment(row.created.toDate().toString()).format('MMMM Do YYYY')}</strong>
                    </TableCell>
                    <TableCell align="right">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => desplegarTablaOpen(row)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box py={1}>
                                {/* <Box py={1}>
                                    <p style={{ fontSize: '16px', marginBottom: '0px', marginLeft: '0px', marginRight: '0px', fontWeight: 'bold' }}>
                                        CUENTAS
                                    </p>
                                </Box> */}
                                <Paper elevation={1}>
                                    <Table size="small" stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></StyledTableCell>
                                                <StyledTableCell style={{ fontSize: '11px' }}><strong>DEBE</strong></StyledTableCell>
                                                <StyledTableCell style={{ fontSize: '11px' }}><strong>HABER</strong></StyledTableCell>
                                                <StyledTableCell style={{ fontSize: '11px' }}><strong>CENTRO DE COSTO</strong></StyledTableCell>
                                                <StyledTableCell style={{ fontSize: '11px' }}><strong>PROYECTO</strong></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataItems && dataItems.map((historyRow) => (
                                                <TableRow>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.cuenta_contable}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.debe === '' ? '' : `$${historyRow.debe}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.haber === '' ? '' : `$${historyRow.haber}`}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.centro_de_costo === '' ? '' : historyRow.centro_de_costo}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                                        {historyRow.proyecto === '' ? '' : historyRow.proyecto}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                        <TableRow>
                                            <TableCell><strong>GLOSA:</strong> {row.glosa}</TableCell>
                                            {/* <TableCell align="center">{totalHistorico ? totalHistorico.toFixed(2) : 0.00}</TableCell> */}
                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell colSpan={1}></TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell><Typography variant='h7'><strong>TOTAL:</strong> </Typography></TableCell>
                                            {/* <TableCell align="center">{totalHistorico ? totalHistorico.toFixed(2) : 0.00}</TableCell> */}

                                            <TableCell><Typography variant='h7'><strong>${row.total_debe}</strong> </Typography></TableCell>
                                            <TableCell><Typography variant='h7'><strong>${row.total_haber}</strong> </Typography></TableCell>

                                            <TableCell colSpan={1}></TableCell>
                                            <TableCell colSpan={1}></TableCell>
                                        </TableRow>
                                    </Table>
                                </Paper>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }

    return (
        <>
            <Box px={2}>
                <Card>

                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>ESTADO DE SITUACION FINANCIERA</strong></p>
                    </Box>

                    <CardContent>

                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>

                                    <Grid item xs={4}>
                                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FILTRO:</strong></p>

                                        <FormControl fullWidth>
                                            <Select
                                                size="small"
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                value={filtro}
                                                onChange={(e) => setFiltro(e.target.value)}
                                            >
                                                <MenuItem value="AÑO EN CURSO">AÑO EN CURSO</MenuItem>
                                                <MenuItem value="AÑO PASADO">AÑO PASADO</MenuItem>
                                                <MenuItem value="MES ACTUAL">MES ACTUAL</MenuItem>
                                                <MenuItem value="MES PASADO">MES PASADO</MenuItem>
                                                <MenuItem value="POR FECHA">POR FECHA</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <Box py={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>CENTRO COSTO:</strong></p>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={centroCosto}
                                                onChange={(e) => setCentroCosto(e.target.value)}
                                                name="codigoProducto"
                                                size="small"
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => setOpenDialogCentrosCostos(true)}>
                                                            <SearchIcon color='primary' />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                        </Box>

                                    </Grid>

                                    <Grid item xs={4}>

                                        {
                                            filtro === 'MES PASADO' ? <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NIVEL:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select
                                                        size="small"
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        value={nivel}
                                                        onChange={(e) => setNivel(e.target.value)}
                                                    >
                                                        <MenuItem value="1">1</MenuItem>
                                                        <MenuItem value="2">2</MenuItem>
                                                        <MenuItem value="3">3</MenuItem>
                                                        <MenuItem value="4">4</MenuItem>
                                                        <MenuItem value="5">5</MenuItem>
                                                        <MenuItem value="TODOS">TODOS</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </> : <>
                                                <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>VISTA:</strong></p>

                                                <FormControl fullWidth>
                                                    <Select
                                                        size="small"
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        value={vista}
                                                        onChange={(e) => setVista(e.target.value)}
                                                    >
                                                        <MenuItem value="GENERAL">GENERAL</MenuItem>
                                                        <MenuItem value="SEMESTRAL">SEMESTRAL</MenuItem>
                                                        <MenuItem value="TRIMESTRAL">TRIMESTRAL</MenuItem>
                                                        <MenuItem value="MENSUAL">MENSUAL</MenuItem>
                                                    </Select>
                                                </FormControl></>
                                        }

                                    </Grid>

                                    <Grid item xs={4}>

                                        {
                                            filtro === 'MES PASADO' ? '' :
                                                <>
                                                    <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>NIVEL:</strong></p>

                                                    <FormControl fullWidth>
                                                        <Select
                                                            size="small"
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            value={nivel}
                                                            onChange={(e) => setNivel(e.target.value)}
                                                        >
                                                            <MenuItem value="1">1</MenuItem>
                                                            <MenuItem value="2">2</MenuItem>
                                                            <MenuItem value="3">3</MenuItem>
                                                            <MenuItem value="4">4</MenuItem>
                                                            <MenuItem value="5">5</MenuItem>
                                                            <MenuItem value="TODOS">TODOS</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </>
                                        }

                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>

                    </CardContent>
                    <CardActions>

                        <br />
                        <br />
                        <Button
                            /* disabled={
                                cuenta === '' ||
                                centroCosto === '' ||
                                filtro === '' ||
                                proyecto === '' ||
                                centroCosto === '' ||
                                desde === '' ||
                                hasta === '' ||
                                tipo === ''
                            } */
                            /* onClick={() => { onHandleClick() }} */
                            onClick={() => { getPlanDeCuentasGenerales() }}
                            fullWidth
                            color="secondary"
                            variant="contained"
                            disableElevation>
                            {" "}
                            CONSULTAR{" "}
                        </Button>
                    </CardActions>
                </Card>
            </Box>

            {/* CONSULTA DE LAS TABLAS PLAN DE CUENTAS*/}

            <Box py={2} px={2}>
                <Paper elevation={1}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center"><strong>INTERCOMMERCE S A</strong></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="center"><strong>Estado de Resultado</strong></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="center"><strong>Desde {startDate && moment(startDate.toString()).format('MMMM Do YYYY')} hasta {endDate && moment(endDate.toString()).format('MMMM Do YYYY')}</strong></StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></StyledTableCell>
                                <StyledTableCell style={{ fontSize: '11px' }}><strong>TOTAL</strong></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPlanCuentasGeneral && dataPlanCuentasGeneral.map((row) => (
                                <TableRow>
                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                        <strong>{row.cuenta}</strong> {row.nombre}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '11px' }} component="th" scope="row">
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Box>



            {/* DIALOG CUENTAS*/}
            <Dialog open={openDialogCuentas} onClose={() => setOpenDialogCuentas(false)} maxWidth="xl">
                <DialogTitle><strong>SELECCIONAR UNA CUENTA</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>

                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={productos}
                                    getOptionLabel={(option) => option.cuenta}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(item)
                                        setItem(newInputValue)
                                        setCuenta(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CUENTA" />}
                                />
                            </Box>

                            {/* <DataGrid
                                rows={productos ? productos : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                density="compact"
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,
                                }}
                                onStateChange={(state) => {
                                    setTotal(state.pagination.rowCount)
                                }}
                            /> */}

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CODIGO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CUENTA</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>GRUPO</strong></TableCell>
                                        <TableCell style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productos && productos
                                        .filter((val) => {
                                            if (item === "") {
                                                return val;
                                            } else if (val.cuenta.toLowerCase().includes(item.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.cuenta}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.nombre}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}>{row.estado}</TableCell>
                                                <TableCell style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCount(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogCuentas(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCuentas(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG CENTROS COSTOS*/}
            <Dialog open={openDialogCentrosCostos} fullWidth onClose={() => setOpenDialogCentrosCostos(false)} maxWidth="md">
                <DialogTitle><strong>SELECCIONAR UN CENTRO DE COSTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={centrosDeCostos}
                                    getOptionLabel={(option) => option.centro_de_costo}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(itemCentroCosto)
                                        setItemCentroCosto(newInputValue)
                                        setCentroCosto(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CENTRO DE COSTO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>CENTRO DE COSTO</strong></TableCell>
                                        <TableCell align="right" style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {centrosDeCostos && centrosDeCostos
                                        .filter((val) => {
                                            if (itemCentroCosto === "") {
                                                return val;
                                            } else if (val.centro_de_costo.toLowerCase().includes(itemCentroCosto.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.centro_de_costo}</TableCell>
                                                <TableCell align="right" style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addCentroCosto(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogCentrosCostos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogCentrosCostos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG PROYECTOS*/}
            <Dialog open={openDialogProyectos} fullWidth onClose={() => setOpenDialogProyectos(false)} maxWidth="md">
                <DialogTitle><strong>SELECCIONAR UN PROYECTO</strong></DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <Box pb={36}>
                            <Box pb={2}>
                                <Autocomplete
                                    size='small'
                                    disablePortal
                                    id="combo-box-demo"
                                    options={proyectosData}
                                    getOptionLabel={(option) => option.centro_de_costo}
                                    onInputChange={(e, newInputValue) => {
                                        console.log(itemCentroCosto)
                                        setItemProyecto(newInputValue)
                                        setProyecto(newInputValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="BUSCAR CENTRO DE COSTO" />}
                                />
                            </Box>

                            <Table size="small" stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: '11px' }}><strong>PROYECTO</strong></TableCell>
                                        <TableCell align="right" style={{ fontSize: '11px' }}><strong>ACCIONES</strong></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {proyectosData && proyectosData
                                        .filter((val) => {
                                            if (itemProyecto === "") {
                                                return val;
                                            } else if (val.proyecto.toLowerCase().includes(itemProyecto.toLowerCase())) {
                                                return val;
                                            }
                                        }).map((row) => (
                                            <TableRow key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell style={{ fontSize: '11px' }}>{row.proyecto}</TableCell>
                                                <TableCell align="right" style={{ fontSize: '11px' }}><Button
                                                    size='small'
                                                    variant='contained'
                                                    color='success' style={{ fontSize: '11px' }}
                                                    onClick={() => addProyecto(row)}
                                                >Seleccionar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>

                        </Box>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenDialogProyectos(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenDialogProyectos(false)} size="small" variant="contained">
                        {" "}
                        ACEPTAR
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
};
