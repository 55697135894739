import { Box, Card, Tab, Typography, Container } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { CrearEncuesta } from './CrearEncuesta';
import { ListEncuesta } from './ListEncuesta';
import { ListEncuesta2 } from './ListEncuesta2';
import { CrearEncuesta2 } from './CrearEncuesta2';
import { ListEncuesta3 } from './ListEncuesta3';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  )
}
export const EncuestasPage = () => {
  const params = useParams()
  let navigate = useNavigate()
  const [tab, setTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue)
    //navigate(`/Planeacion/Agenda/${newValue}`)
  }
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id));
    } else {
      setTab(0)
    }
  }, [params.id])
  return (
    <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1"><strong>Encuestas</strong></Typography>
      </Box>
      <Card>
        <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={tab}
            onChange={handleChange}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Crear Encuesta" />
            <Tab label="Lista de Encuestas" />
            <Tab label="Lista de Encuestas 2" />
            <Tab label="Crear Encuesta 3" />
            <Tab label="Lista de Encuestas 3" />
          </Tabs>
        </Box>
          <Card>
            <TabPanel value={tab} index={0}>
                <CrearEncuesta />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ListEncuesta />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <ListEncuesta2 />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <CrearEncuesta2 />
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <ListEncuesta3 />
            </TabPanel>
          </Card>
      </Card>
    </Container>
  )
}