import React, { useState, useEffect } from 'react'
import { Tabs, Box, Tab, tabsClasses, Typography, Container, AppBar, Card } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ClientesResumen } from '../usuarios/ClientesResumen';
import { ClientesServicios } from '../servicios/ClientesServicios';
import { TabsFacturacion } from '../facturacion/TabsFacturacion';
import { DatosInstalacionPage } from '../instalacion/DatosInstalacionPage';
import { useSelector, useDispatch } from 'react-redux'
import { clientServicio, detailCliente } from '../../../redux/clientes/clientesActions';
import { Cargando } from '../../../components/utils/Cargando';
import { ClientesDocumentos } from '../documentacion/ClientesDocumentos';
import { HerramientasPage } from '../herramientas/HerramientasPage';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
export const ViewUsuariosPage = () => {
  const clientDetails = useSelector((state) => state.clientDetails)
  const { loading, client_details } = clientDetails
  const [tab, setTab] = useState(0)
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(() => {
    dispatch(detailCliente(params.id))
    dispatch(clientServicio(params.id))
  }, [dispatch])
  return (
    <>
      {
        loading == false && client_details ?
          <>
            <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
              <Box py={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5" >{client_details.nombres} </Typography>
                <Typography variant='body2'>{`(#${params.id})`}</Typography>
              </Box>
              <Card>
                <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
                  <AppBar elevation={0} color='default' position="static">
                    <Tabs
                      value={tab}
                      variant="scrollable"
                      indicatorColor="primary"
                      allowScrollButtonsMobile
                      onChange={(event, newValue) => setTab(newValue)}
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          '&.Mui-disabled': { opacity: 0.3 },
                        },
                      }}
                    >
                      <Tab label="RESUMEN" />
                      <Tab label="SERVICIOS" />
                      <Tab label="INSTALACIÓN" />
                      <Tab label="FACTURACIÓN" />
                      <Tab label="LOG" />
                      <Tab label="DOCUMENTOS" />
                      <Tab label="CONFIGURACION" />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tab} index={0}>
                    <ClientesResumen />
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <ClientesServicios />
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <DatosInstalacionPage />
                  </TabPanel>
                  <TabPanel value={tab} index={3}>
                    <TabsFacturacion />
                  </TabPanel>
                  <TabPanel value={tab} index={4}>
                  </TabPanel>
                  <TabPanel value={tab} index={5}>
                    <ClientesDocumentos />
                  </TabPanel>
                  <TabPanel value={tab} index={6}>
                    <HerramientasPage />
                  </TabPanel>
                </Box>
              </Card>
            </Container>
          </>
          : <Cargando />
      }
    </>
  )
}