import React, { useState } from 'react'
import { storage, db, firebase } from '../../firebase'
import { useParams, Link as RouterLink } from "react-router-dom";
import { Button, Tooltip, Dialog, DialogContent, DialogActions, DialogTitle, IconButton, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
export const DeletProducts = ({ props }) => {
    let { id } = useParams();
    const [open, setOpen] = useState(false);
    //
    const [data, setData] = useState('')
    //
   
    const handleAddProducts = (e) => {
        db.collection('Products').doc(data.id).delete().then(() => {
         
            setOpen(false)
        }).catch(error => alert(error.message));
    }
    const onData = () => {
        setData(props)
        console.log(props, 'props')
        setOpen(true)
    }
    return (
        <div >
            <Tooltip title="Eliminar">
                <IconButton onClick={() => onData()}>
                    <DeleteIcon fontSize='small' />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth='xs'>
                <DialogTitle>
                    Eliminar Producto
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    ¿Estas seguro de eliminar {data.title}?
                </DialogContent>
                <DialogActions>
                    <button onClick={() => handleAddProducts()} className="btn btn-danger">  Eliminar Producto</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
