import {
  Grid,
  Box,
  Button,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Card,
  CardContent,
  CardActions,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
  Stack,
} from "@mui/material"; import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from "react";

export const DatosGenerales = () => {

  const [imagen, setImagen] = useState(null);


  const changeImagen = async (e) => {
    //
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      //console.log('RESULT', reader.result)
      setImagen(reader.result);
      // setUrlImagenCajaAbiertaAntes64(reader.result)
      // db.collection('instalaciones_fiscalizadas').doc(props.numContrato).update({
      //   urlImagenCajaAbiertaAntes64: reader.result === '' ? '' : reader.result,
      // })
    }
    reader.readAsDataURL(file);


    //setViewImagen(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0]);

  }


  return (
    <>
      <Box px={2}>
        <Card>
          <Box paddingLeft={2}>
            <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>DATOS EMPRESARIALES</strong></p>
          </Box>
          <CardContent>
            <Grid container spacing={5} >
              <Grid item xs={3}>
                <Paper elevation={3} style={{ height: "100%" }}>

                  <Box py={1} px={1}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                      <p style={{ fontSize: "14px", marginBottom: "10px" }} align=" center"><strong> LOGO </strong></p>
                      <br />
                      {imagen &&
                        <img src={imagen} width="100%" />
                      }
                      <Button align=" center"
                        size="small"
                        onChange={changeImagen}
                        variant="contained"
                        component="label"
                      >CAMBIAR IMAGEN
                        <input
                          type="file"
                          hidden
                        />
                      </Button>
                    </div>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper elevation={3} >
                  <Box py={1} px={1}>
                    <p style={{ fontSize: "14px", marginBottom: "10px" }}><strong> Datos Generales</strong></p>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="RUC"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Razón Social"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Nombre Comercial"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                          }}
                          renderInput={(props) => <TextField {...props}
                            label="Fecha de Creación"
                            size="small" />}
                        // value={fecha}
                        // onChange={(newValue) => {
                        //   setFecha(newValue);
                        // }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="País"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="Dirección Matriz"
                        // value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Razón Social"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper elevation={3} >
                  <Box py={1} px={1}>
                    <p style={{ fontSize: "14px", marginBottom: "10px" }}><strong> Datos de Contacto</strong></p>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Teléfono 1"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Teléfono 2"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Sitio Web"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Correo Instucional"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper elevation={3} >
                  <Box py={1} px={1}>
                    <p style={{ fontSize: "14px", marginBottom: "10px" }}><strong> Representante Legal</strong></p>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Cédula"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Nombre"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Dirección"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                  <Box py={1} px={1}>
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        //value={0992988061001}
                        // onChange={(e) => setNombrePlan(e.target.value)}
                        label="Cargo Instucional"
                        size="small"
                      />
                    </FormControl>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}
