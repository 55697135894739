import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, storage, firebase, auth } from "../../../../firebase";
import { listsSubCategorias } from "../../../../redux/actions/parametersActions";

export const InfoFiscalizacion = ({ props }) => {

    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth

    const [file, setFile] = useState(null);
    const [openImage, setOpenImage] = useState(false);
    const [ref, setRef] = useState(null);
    const useStorage = storage;
    const [Imagen, setImagen] = useState(null);

    useEffect(() => {
        setFile(Imagen)
    }, [])

    const [image, setImage] = useState(null)

    const [viewImagen, setViewImagen] = useState(null)

    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
        console.log(Imagen);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImagen(URL.createObjectURL(event.target.files[0]));
        }
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())
    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }
    //
    const download = () => {
        //props.forEach(URL => window.open(URL));

        console.log('Descargar imagenes', props)
    }

    const [observacionFizcalizacion, setObservacionFizcalizacion] = useState(props.observaciones_fiscalizacion);

    var imagenUrl;

    return (
        <>
            <Tooltip title="FISCALIZAR">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>
                    <strong>FISCALIZACION DE: {props.id}</strong>
                </DialogTitle>
                <DialogContent>
                    <Box py={2}>
                        <FormControl fullWidth>

                            <Box py={1}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="OBSERVACIONES"
                                    value={observacionFizcalizacion}
                                />
                            </Box>
                            <Grid container
                                direction="row"
                                justifyContent="center"
                                alignItems="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>CAJA ABIERTA ANTES</strong></p>
                                    <img src={props.imagen_caja_abierta_antes} width="100%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>CAJA ABIERTA DESPUES</strong></p>
                                    <img src={props.imagen_caja_abierta_despues} width="100%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>POTENCIA CAJA</strong></p>
                                    <img src={props.imagen_potencia_caja} width="100%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>CODIGO CAJA</strong></p>
                                    <img src={props.imagen_codigo_caja} width="100%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>EQUIPO</strong></p>
                                    <img src={props.imagen_equipo_caja} width="100%" />
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{ fontSize: "12px" }}><strong>SERIAL EQUIPO</strong></p>
                                    <img src={props.imagen_serial_equipo_caja} width="100%" />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>
            
                <DialogActions >
                    <Button onClick={() => download()} color='success' variant='contained'>Descargar imagenes</Button>
                    <Button onClick={() => setOpen(false)}><strong>OK</strong></Button>
                </DialogActions>
            </Dialog>
        </>
    );
};