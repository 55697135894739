import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Chip,
  Box,
  Button,
  Collapse,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../firebase";
import moment from "moment";
import { useSelector } from "react-redux";
/* import { ExportarCSV } from "../utils/ExportarCSV"; */
import { ExportarCSV } from "../../../components/utils/ExportarCSV";
/* import { UpdateInformation } from "./views/UpdateInformation"; */

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchInput from "../../../components/utils/SearchInput";

export const VentasReagendadas = () => {
  function Estado({ estado }) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado === 0) {
      name = "POR AGENDAR";
      color = "warning";
    } else if (estado === 1) {
      name = "agendada";
      color = "success";
    } else if (estado === 3) {
      name = "Reagendada";
      color = "success";
    }
    return <Chip label={name} color={color} />;
  }
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [nombreZona, setNombreZona] = useState();
  const [reData, setreData] = useState([]);
  const [data, setData] = useState();
  const [isLastPage, setIsLastPage] = useState(0);
  const [queryDoc, setQueryDocqueryDoc] = useState([]);
  //
  var current = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData();
    if (data) {
      const data2 = [
        ...data.map((doc) => {
          return {
            ...doc,
            //created: doc.created.toDate(),
            latitud: doc.coordenadas._lat,
            longitud: doc.coordenadas._long,
          };
        }),
      ];
      setreData(data2);
    }
  }, [data]);

  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth;
  const LoadData = (startAfterDoc, persistMessages = []) => {

    let ref = db.collection("agenda").where("estado", "==", 3);
    if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
      ref = ref.where("user_uid", "==", auth.currentUser.uid);
    if (userInfo?.roles?.find(role => ['lideres_ventas']?.includes(role)))
      ref = ref.where("centro_costo.lider_venta", "==", userInfo.nombres)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("created", ">=", startfulldate);
      ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
        setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
        setIsLastPage(totalCount < 1);
      });
    }

    /* if (userInfo.email === "jonathan.mendoza@intercommerce.com.ec" || userInfo.email === "daniela.galve@intercommerce.com.ec"
    ) {
      let ref = db
        .collection("agenda")
        .where("provincia", "==", "SANTA ELENA")
        .where("estado", "==", 3);
      if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
        ref = ref.where("user_uid", "==", auth.currentUser.uid);
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("created", ">=", startfulldate);
        ref = ref.where("created", "<=", endfulldate);
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          setData(data);
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
          setIsLastPage(totalCount < 1);
        });
      }
    } else {
      let ref = db.collection("agenda").where("estado", "==", 3);
      if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
        ref = ref.where("user_uid", "==", auth.currentUser.uid);
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("created", ">=", startfulldate);
        ref = ref.where("created", "<=", endfulldate);
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          setData(data);
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1]);
          setIsLastPage(totalCount < 1);
        });
      }
    } */
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <Box pl={2} pt={2} display="flex" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
            <Button
              disableElevation
              variant="contained"
              onClick={() => LoadData()}
            >
              Aplicar busqueda
            </Button>
          </Collapse>
        </Box>
        <SearchInput setSearch={setSearch} search={search} />
        <Box>
          <ExportarCSV data={reData} />
        </Box>
      </Box>
      <Box display="flex" style={{ textTransform: "uppercase" }} py={2} pl={2}>
        <Typography variant="button">
          <strong>Desde</strong>{" "}
          {startDate &&
            moment(startDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
        </Typography>
        <Typography variant="button" style={{ paddingLeft: 10 }}>
          <strong>Hasta</strong>{" "}
          {endDate &&
            moment(endDate.toString()).format("MMMM Do YYYY, h:mm:ss a")}
        </Typography>
      </Box>

      <Paper sx={{ maxWidth: { xs: 340, sm: "100%" }, overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 540 }}>
          <Table size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>FECHA DE CREACION DE SOLICITUD</TableCell>
                <TableCell>ESTADO</TableCell>
                <TableCell># REFERENCIA</TableCell>
                <TableCell>CEDULA</TableCell>
                <TableCell>NOMBRE DEL CLIENTE</TableCell>
                <TableCell>TELEFONO</TableCell>
                <TableCell>CELULAR</TableCell>
                <TableCell>WHATSAPP</TableCell>
                <TableCell>CANTON</TableCell>
                <TableCell>SECTOR</TableCell>
                <TableCell>DIRECCION</TableCell>
                <TableCell>COORDENADAS DEL CLIENTE</TableCell>
                <TableCell>PLAN CONTRATADO</TableCell>
                <TableCell>TIPO DE CONTRATO</TableCell>
                <TableCell>TERCERA EDAD</TableCell>
                <TableCell>DISCAPACIDAD</TableCell>
                <TableCell>GRADO DISCAPACIDAD</TableCell>
                <TableCell>DESCUENTO</TableCell>
                <TableCell>VALOR DEL DESCUENTO</TableCell>
                <TableCell>VALOR PLAN</TableCell>
                <TableCell>NOMBRE DEL VENDEDOR</TableCell>
                <TableCell>CODIGO DE VENDEDOR</TableCell>
                <TableCell>CENTRO DE COSTO</TableCell>
                <TableCell>CANAL DE VENTAS</TableCell>
                <TableCell>LIDER DE VENTAS</TableCell>
                <TableCell>CATEGORIA</TableCell>

                <TableCell>ES REFERIDO</TableCell>
                <TableCell>VENTA REFERIDA POR FLOTA </TableCell>
                <TableCell>USUARIO QUE REAGENDA</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data
                  .filter((val) => {
                    if (search === "") {
                      return val;
                    } else if (
                      val.nombres.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.cedula.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((row, key) => (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {row.created &&
                          typeof row.created === "number" &&
                          moment(new Date().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        {row.created &&
                          typeof row.created === "object" &&
                          moment(row.created.toDate().toString()).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Estado estado={row.estado} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.numContrato}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.cedula}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.nombres}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.telefono}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.celular}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.whatsapp}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.numbRef}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.canton}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.sector}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.direccion}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.coordenadas._lat},{row.coordenadas._long}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.planNombre}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.contrato}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.terceraEdad === 1 ? "SI" : "NO"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.discapacitado === 1 ? "SI" : "NO"}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.discapacidad}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.descuento}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.paramsDiscapacidad === ""
                          ? "NINGUNO"
                          : row.paramsDiscapacidad.valor}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.planValor}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.vendedor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.codigo_vendedor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.centro_costo}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.canal}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.lider_venta}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.centro_costo && row.centro_costo.categoria}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.referido === undefined ? "NO" : row.referido}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.referido === "SI"
                          ? row.flotaReferida.numeroFlota
                          : "NINGUNA"}
                      </TableCell>
                      {/* CAMBIAR NOMBRE DE QUIEN AGENDA */}
                      <TableCell component="th" scope="row">
                        { row.vendedor}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
