import { db } from '../../firebase'
export const listClientes = () => async (dispatch) => {
    try {
        dispatch({ type: 'CLIENTES_LIST_REQUEST' })
        let ref = db.collection("clientes").orderBy("created", "desc").limit(100)
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id
                    }
                })
            ]
            dispatch({
                type: 'CLIENTES_LIST_SUCCESS',
                payload: data
            })
        })
    } catch (e) {
        dispatch({ type: 'CLIENTES_LIST_FAIL', payload: e })
    }
}
export const detailCliente = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'CLIENT_DETAILS_REQUEST' })
        db.collection("clientes").doc(formData)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    var client = doc.data()

                    dispatch({
                        type: 'CLIENT_DETAILS_SUCCESS',
                        payload: client
                    })
                } else {
                    console.log("No such document!")
                }
            }).catch((e) => {
                dispatch({ type: 'CLIENT_DETAILS_FAIL', payload: e })
                console.log(e, ' error 1 ')
            })
    } catch (e) {
        dispatch({ type: 'CLIENT_DETAILS_FAIL', payload: e })
        console.log(e, ' error 0 ')
    }
}
export const listConfigPlantilla = () => async (dispatch) => {
    try {
        dispatch({ type: 'CONFIG_PLANTILLAS_REQUEST' })
        db.collection(`system/settings/plantillas_configuraciones`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                dispatch({
                    type: 'CONFIG_PLANTILLAS_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (e) {
        dispatch({ type: 'CONFIG_PLANTILLAS__FAIL', payload: e })
    }
}
export const detailsMikrotik = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'MIKROTIK_DETAILS_REQUEST' })

        db.collection('mikrotiks').doc(formData).get()
            .then((doc) => {
                if (doc.exists) {
                    var client = doc.data()
                    dispatch({
                        type: 'MIKROTIK_DETAILS_SUCCESS',
                        payload: client
                    })
                } else {
                    console.log("NO EXISTE MIKRTOTIP")
                }
            })
    } catch (e) {
        dispatch({ type: 'MIKROTIP_DETAILS__FAIL', payload: e })
    }
}
export const clientServicio = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'CLIENT_SERVICE_REQUEST' })
        console.log(formData, ' form data')
        db.collection('servicios').where('idcliente', '==', parseInt(formData))
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage, ' servicio')
                dispatch({
                    type: 'CLIENT_SERVICE_SUCCESS',
                    payload: arrayMessage
                })
            })
    } catch (e) {
        dispatch({ type: 'CLIENT_SERVICE__FAIL', payload: e })
    }
}



export const listClientsActions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: 'LIST_CLIENTES_REQUEST' })
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`https://serverbd.intercommerce.com.ec/apiusuarios/`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                dispatch({
                    type: 'LIST_CLIENTES_SUCCESS',
                    payload: res,
                })
            })
            .catch(error => {
                dispatch({
                    type: 'LIST_CLIENTES_FAIL',
                    payload: error
                })
                console.log('error', error)
            });

    } catch (error) {
        dispatch({
            type: 'LIST_CLIENTES_FAIL',
            payload: error
        })
        console.log('error', error)
    }
}