import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Box,
    Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";
import { useNavigate } from "react-router-dom";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { Link as RouterLink } from "react-router-dom";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

export const CheckTransferenciasAprobadas = ({ props }) => {

    const [inputFields, setInputFields] = useState([]);

    const [loadingButtonAprobarSolicitudTransferencia, setLoadingButtonAprobarSolicitudTransferencia] = useState(false);

    let navigate = useNavigate()

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const [total, setTotal] = useState(0);

    const [data, setData] = useState([]);

    const [items, setItems] = useState([]);

    const [isLastPage, setIsLastPage] = useState(0);
    const [queryDoc, setQueryDocqueryDoc] = useState([])

    const [open, setOpen] = useState(null);

    const [openAcceptTransferencia, setOpenAcceptTransferencia] = useState(null);

    const [openRechazoTransferencia, setOpenRechazoTransferencia] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const LoadData = () => {

        var docRef = db.collection("transferencias").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('items', data)
            setItems(data);
        });
    };

    const [numPedido, setNumPedido] = useState('')

    const loadNumPedido = () => {
        const ref = db.collection("egreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const [numEgreso, setNumEgreso] = useState('');

    const loadNumEgreso = () => {
        const ref = db
            .collection("egreso_productos")
            .orderBy("created", "desc")
            .limit(1);
        ref.onSnapshot((snapshot) => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                const solicitud = solicitudes[0];
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumEgreso(numeroSolicitud);
            }
        });
    };

    const dispatch = useDispatch();

    useEffect(() => {
        loadNumEgreso()
        LoadData()
        loadNumPedido()
        loadItems()
    }, [dispatch])

    const columns = [
        { field: 'codigo', headerName: 'CODIGO PRODUCTO', width: 165 },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 350 },
        { field: 'bodega_origen', headerName: 'BODEGA ORIGEN', width: 190 },
        { field: 'bodega_destino', headerName: 'BODEGA DESTINO', width: 190 },
        { field: 'cantidad_transferencia', headerName: 'CANTIDAD A TRANSFERIR', width: 205 },
        { field: 'stock_actual', headerName: 'STOCK ACTUAL', width: 130 },
    ];

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const getSeriesItemsTransferencias = () => {

        const uidEgreso = `EGR-` + numEgreso;

        items.forEach((obj) => {
            db.collection('transferencias').doc(props.id).collection('items').doc(obj.codigo).collection('series').get()
                .then(async (querySnapshot) => {
                    if (querySnapshot.size > 0) {

                        const ref = await db.collection('transferencias').doc(props.id).collection('items').doc(obj.codigo).collection('series').get()
                        const data = await ref.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                        data.forEach(async (objSerie) => {
                            console.log(objSerie.serie)

                            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_origen).get()

                            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                            db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(objSerie.serie.toString())
                                .update({
                                    estado: 1
                                })

                            await db.collection('reporte_movimientos').doc(uidEgreso).collection('items')
                                .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                                .set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie.toString(),
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega_destino,
                                    estado: 1
                                })

                            await db.collection(`egreso_productos/${uidEgreso}/items`)
                                .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                                .set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie.toString(),
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega_destino,
                                    estado: 1
                                })

                        })

                    } else {
                        console.log('No hay series')
                    }
                });
        })
    }

    var totalBodegasOrigenDestino;

    const handleSubmitEgreso = () => {
        let rol = ''
        if (userInfo.rol === 0) {
            rol = 'ADMIN'
        }
        if (userInfo.rol === 1) {
            rol = 'COMERCIAL'
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = 'OPERACIONES'
        }
        if (userInfo.rol === 3) {
            rol = 'PLANEACION'
        }
        if (userInfo.rol === 4) {
            rol = 'FLOTAS'
        }
        if (userInfo.rol === 5) {
            rol = 'REPORTES'
        }
        if (userInfo.rol === 6) {
            rol = 'RECURSOS HUMANOS'
        }
        //
        const uidSolicitud = `EGR-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega_origen,
            ubicacion: '',
            motivo: props.motivo,
            motivo_movimiento: 'EGRESO',
            observacion_adicional: props.observacion === '' ? 'NINGUNA' : props.observacion,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso',
            fecha_documento: props.fecha_documento,
            relacion: props.uidSolicitud
        }
        console.log(data, 'DATA')
        console.log(items, 'INPUT FIELDS')

        //SE INGRESAN LOS PRODUCTOS A 'EGRESO DE PRODUCTOS'

        db.collection('egreso_productos').doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega_origen,
            ubicacion: '',
            motivo: props.motivo,
            motivo_movimiento: 'EGRESO',
            observacion_adicional: props.observacion,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso',
            relacion: props.uidSolicitud
            /* fecha_documento: props.fecha_documento */
        })

        db.collection(`reporte_movimientos`).doc(uidSolicitud).set({
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega_origen,
            ubicacion: '',
            motivo: props.motivo,
            motivo_movimiento: 'EGRESO',
            observacion_adicional: props.observacion,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'egreso',
            fecha_documento: props.fecha_documento,
            relacion: props.uidSolicitud
        })



        items.forEach(async function (obj, index) {

            getSeriesItemsTransferencias()

            /* console.log(obj, 'OBJ') */

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_origen).get()

            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.codigo).get()

            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_transferencia)

            /* const DivisionTotalStockActual = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual) */

            /* const costoPromedio = parseFloat(DivisionTotalStockActual) * parseFloat(obj.cantidad_transferencia) */

            const saldo_valorizado = parseFloat(data2[0].costo_promedio).toFixed(2) * parseFloat(obj.cantidad_transferencia).toFixed(2)

            /* const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(saldo_valorizado) */

            const total_operacion = parseFloat(data2[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            totalBodegasOrigenDestino = total_operacion.toFixed(2)

            const cantidad_total = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_transferencia)

            const totalRestaSaldoTotal = parseFloat(data2[0].stock_actual) - parseFloat(obj.cantidad_transferencia)

            /* console.log(`DIVISION TOTAL Y STOCK VALORIZADO ${DivisionTotalStockActual.toFixed(2)}`) */

            console.log('PRECIO COMPRA', data2[0].precio_compra)

            console.log(`COSTO PROMEDIO ${costoPromedio.toFixed(2)}`)

            console.log(`TOTAL EGRESO ${totalEgreso.toFixed(2)}`)

            /* console.log(`SALDO VALORIZADO ${saldo_valorizado.toFixed(2)}`) */

            console.log(`TOTAL OPERACION ${total_operacion.toFixed(2)}`)

            /* CALCULA EL TOTAL DE LAS BODEGAS */

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).get()

            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            /* FOR PARA CALCULAR EL STOCK ACTUAL DE LAS BODEGAS */

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            /* FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS */

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            /* TOTAL STOCK ACTUAL + CANTIDAD DE LA OPERACION (EG8RESO) */

            const total_bodegas = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseFloat(obj.cantidad_transferencia).toFixed(2)

            console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

            /* TOTAL VALORIZADO + TOTAL DE LA OPERACION (EG8RESO)*/

            const sumaTotalBodegas = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) + parseFloat(total_operacion).toFixed(2)

            console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS')

            db.collection("reporte_stock").doc(data2[0].id).update({
                stock_actual: parseFloat(data2[0].stock_actual).toFixed(2) - parseFloat(obj.cantidad_transferencia).toFixed(2),
                total_valorizado: total_operacion.toFixed(2),
                costo_promedio: costoPromedio.toFixed(2)
            })

            /* OBTENEMOS EL ID DE LA COLECCION PRODUCTOS */

            db.collection("productos").doc(data4[0].id).update({
                cantidad_producto: parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2)
            })

            const respTotalBodegas = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2)

            db.collection(`egreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_origen,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: 'egreso',
                total_bodegas: Number.isNaN(Number(respTotalBodegas)) || parseFloat(respTotalBodegas) < 0 ? 0 : respTotalBodegas,/* parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2), *//* total_bodegas.toFixed(2), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
            })

            /* INGRESO DATOS A REPORTE DE MOVIMIENTOS Y KARDEX */

            const kardexGeneralSumaTotalStock = parseFloat(datoSumaStockTotalBodegas).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2)

            const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

            console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

            console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

            const dataRef = {
                fullName: obj.codigo,
                cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: total_operacion.toFixed(2),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_origen,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: 'egreso',
                total_bodegas: Number.isNaN(Number(respTotalBodegas)) || parseFloat(respTotalBodegas) < 0 ? 0 : respTotalBodegas,/* parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
                fecha_documento: props.fecha_documento,
            }

            const dataRef2 = {
                fullName: obj.codigo,
                cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) ? 0 : kardexGeneralSumaTotalStock,
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: costoPromedio.toFixed(2),
                saldo_valorizado: totalEgreso.toFixed(2),
                total: Number.isNaN(Number(kardexGeneralSumaTotal)) || kardexGeneralSumaTotal === 'NaN' || kardexGeneralSumaTotal === NaN ? 0 : Math.abs(kardexGeneralSumaTotal),
                precio_compra: data2[0].precio_compra,
                observacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_origen,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: 'egreso',
                total_bodegas: Number.isNaN(Number(respTotalBodegas)) || parseFloat(respTotalBodegas) < 0 ? 0 : respTotalBodegas,/* parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cantidad_transferencia).toFixed(2), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: costoPromedio.toFixed(2),
                fecha_documento: props.fecha_documento
            }

            db.collection(`total_movimientos`).doc().set(dataRef)

            // db.collection(`total_movimientos_general`).doc().set(dataRef2)

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)

            /* setModalConfirm(false) */
            /* setOpenConfirm(false)

            Swal.fire({ icon: "success", text: `Se ha realizado el egreso de productos con exito` }) */
        })

    }



    const aprobarSolicitud = () => {

        db.collection(`reporte_movimientos`).doc(props.uidSolicitud).set({
            uidSolicitud: props.uidSolicitud,
            estado: 3,
            numero_solicitud: props.numeroSolicitud,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: '',
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega_origen: props.bodega_origen,
            bodega_destino: props.bodega_destino,
            ubicacion: '',
            motivo_movimiento: 'TRANSFERENCIA',
            motivo: 'TRANSFERENCIA',
            observacion_adicional: props.observacion,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: 'transferencia',
            fecha_documento: props.fecha_documento
        })

        console.log(data)

        handleSubmitEgreso()

        items.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", obj.bodega_destino).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_origen).get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const operacionCostoPromedio = parseFloat(data4[0].total_valorizado) / parseFloat(data4[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cantidad_transferencia)

            const total_operacion = parseFloat(data4[0].total_valorizado) - parseFloat(totalEgreso).toFixed(2)

            /* handleSubmitEgreso() */

            /* db.collection("reporte_stock").doc(data4[0].id).update({
                stock_actual: parseInt(data4[0].stock_actual) - parseInt(obj.cantidad_transferencia),
            }) */

            db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                fullName: obj.codigo,
                categoria_producto: obj.categoria_producto,
                descripcion: obj.descripcion,
                costo_promedio: data4[0].costo_promedio,
                precio_compra: data4[0].precio_compra,
                cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                bodega_origen: obj.bodega_origen,
                bodega_destino: obj.bodega_destino,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })

            if (data3[0] === undefined) {

                /* Se realiza el egreso del producto de bodega origen */

                /* handleSubmitEgreso() */

                /* Se crea el producto de la bodega destino */

                console.log('NO EXISTE LA BODEGA')
                db.collection("reporte_stock").doc().set({
                    bodega: obj.bodega_destino,
                    categoria: '',
                    codigo_producto: obj.codigo,
                    costo_promedio: data4[0].costo_promedio,
                    descripcion: obj.descripcion,
                    fecha_carga_producto: firebase.firestore.FieldValue.serverTimestamp(),
                    motivo: 'CREACION POR TRANSFERENCIA',
                    precio_compra: 0,
                    stock_actual: 0/* parseInt(obj.cantidad_transferencia) */,
                    tipo_movimiento: 'TRANSFERENCIA',
                    total_valorizado: 0/* total_operacion */,
                    unidad_medida: '',
                    usuario_que_subio_carga_producto: userInfo.displayName
                })

                /* db.collection("reporte_stock").doc(data4[0].id).update({
                    stock_actual: parseInt(obj.cantidad_transferencia),
                })
 */
                db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                    fullName: obj.codigo,
                    categoria_producto: obj.categoria_producto,
                    descripcion: obj.descripcion,
                    costo_promedio: data4[0].costo_promedio,
                    precio_compra: 0,
                    cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                    bodega_origen: obj.bodega_origen,
                    bodega_destino: obj.bodega_destino,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                })

            } else {

                /* Se realiza el egreso del producto de bodega origen */

                /* handleSubmitEgreso() */

                console.log('SI EXISTE LA BODEGA')

                /* db.collection("reporte_stock").doc(data4[0].id).update({
                    stock_actual: parseInt(data4[0].stock_actual) - parseInt(obj.cantidad_transferencia),
                }) */

                /* db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                    fullName: obj.codigo,
                    categoria_producto: obj.categoria_producto,
                    descripcion: obj.descripcion,
                    costo_promedio: data4[0].costo_promedio,
                    precio_compra: data4[0].precio_compra,
                    cantidad_solicitada: parseInt(obj.cantidad_transferencia),
                    bodega_origen: obj.bodega_origen,
                    bodega_destino: obj.bodega_destino,
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                }) */

            }

        });

        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 3,
            usuario_que_aprobo: userInfo.displayName,
            fecha_de_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenAcceptTransferencia(false)
        /* Swal.fire({ icon: "success", title: `Solicitud aprobada con exito` }); */
        Swal.fire({
            title: 'Se esta realizando la aprobacion de transferencia',
            text: 'Por favor espere',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        setTimeout(() => {

            Swal.fire({
                title: 'Estamos cargando sus datos de transferencia',
                text: 'Por favor espere',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });

            navigate(`/inventario/productos/transferencias/recepcion-transferencias`)

            setTimeout(() => {
                window.location.reload();
            }, 4000);

        }, 20000);
    }

    const rechazarSolicitud = () => {
        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 1,
            usuario_que_rechazo: userInfo.displayName,
            fecha_de_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setOpenRechazoTransferencia(false)
        Swal.fire({ icon: "success", title: `Solicitud rechazada con exito` });
    }

    const handleButtonClick = async () => {

        let fechaDate = Date(props.fecha_documento);

        const fechaTimestamp = firebase.firestore.Timestamp.fromDate(new Date(fechaDate));

        console.log('fechaTimestamp', fechaTimestamp)

        setLoadingButtonAprobarSolicitudTransferencia(true)

        console.log('items', items)
        try {
            const aprobarSolicitudTransferenciaProductos = firebase.functions().httpsCallable("aprobarSolicitudTransferenciaProductos");

            const data = {
                numPedido: numPedido,
                userInfo: userInfo,
                items: items,
                props: props,
                fechaDocumento: fechaTimestamp
            }

            const response = await aprobarSolicitudTransferenciaProductos(data);

            setOpen(false)

            Swal.fire({ icon: "success", text: "Se realizo la aprobacion de transferencia correctamente", });

            setTimeout(() => {
                navigate(`/inventario/productos/transferencias/recepcion-transferencias`)
            }, 3000);

            console.log(response.data.message); // Mensaje de éxito de la Cloud Function
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpen(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });

            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    };

    const loadItems = () => {
        const docRef = db.collection("transferencias").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot(async (snapshot) => {

            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            /* data.forEach(async (obj, index) => {

                const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_origen).get()

                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                console.log('data2[0]', data2[0])

                try {// Obtiene los resultados de la consulta

                    if (!data2[0].empty) {

                        const list = [...data];

                        list[index]['stock_actual'] = data2[0].stock_actual;

                        console.log('Stock obtenido correctamente:', data2[0].stock_actual);
                        console.log('Objeto "items" actualizado:', items);
                    } else {
                        console.log('No se encontraron resultados para el código');
                    }
                } catch (error) {
                    console.error('Error al obtener el stock del código:', error);
                }
            }) */

            const promises = data.map(async (obj) => {
                const agendaDB2 = await db
                    .collection("reporte_stock")
                    .where("codigo_producto", "==", obj.codigo)
                    .where('bodega', '==', obj.bodega_origen)
                    .get();

                const data2 = agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                if (data2.length > 0) {
                    obj.stock_actual = data2[0].stock_actual;
                    return obj;
                } else {
                    return obj;
                }
            });

            const resolvedData = await Promise.all(promises);
            setInputFields(resolvedData);

            setTimeout(() => {
                console.log('items', data)
                setItems(data);
            }, 500);

        });
    }

    const verificarItems = () => {
        console.log('props', props)
        console.log('inputFields', inputFields)
        const cantidadesValidas = inputFields.some(item => item.cantidad_transferencia > item.stock_actual);
        console.log('cantidadesValidas', cantidadesValidas)
        if (cantidadesValidas) {
            console.log('Al menos una cantidad ingresada es mayor que la cantidad disponible para un item.');
            setOpen(false)
            Swal.fire({ icon: "warning", text: `Se ha detectado que algunas cantidades que desea aprobar son mayores al stock actual` })
        } else {
            /* handleButtonClick() */
            console.log('Todo correcto');
            setOpen(false)
            setOpenAcceptTransferencia(true)
        }
    }

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            {/* DIALOG SOLICITUDES DE TRANSFERENCIAS */}
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">

                <DialogTitle>
                    <strong>SOLICITUD DE TRANSFERENCIA: {props.uidSolicitud}</strong>
                </DialogTitle>

                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper>
                            <Box sx={{ height: 700, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={items ? items : []}
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    // disableColumnFilter
                                    // disableColumnSelector
                                    // disableDensitySelector
                                    //hideFooterPagination
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                        Footer: CustomFooterTotalComponent,
                                    }}

                                    onStateChange={(state) => {
                                        console.log(state.pagination.rowCount);
                                        setTotal(state.pagination.rowCount)
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>REGRESAR</strong></Button>
                    <Button onClick={() => {
                        setOpen(false)
                        setOpenRechazoTransferencia(true)
                    }} variant="contained" color="warning" size="small"><strong>RECHAZAR TRANSFERENCIA</strong></Button>
                    <Button onClick={() => {
                        /* setOpen(false)
                        setOpenAcceptTransferencia(true) */
                        /* console.log('TRANSFERENCIA APROBADA') */
                        verificarItems()
                    }} variant="contained"
                        color="secondary"
                        size="small">
                        APROBAR TRANSFERENCIA
                    </Button>
                </DialogActions>

            </Dialog>

            {/* DIALOG ACEPTAR SOLICITUDES */}
            <Dialog open={openAcceptTransferencia} fullWidth onClose={() => setOpenAcceptTransferencia(false)} maxWidth="xs">

                <DialogTitle>
                    <strong>¿ESTA SEGURO DE APROBAR LA TRANSFERENCIA?</strong>
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end"
                        onClick={() => {
                            setOpenAcceptTransferencia(false)
                            setOpen(true)
                        }} variant="contained" color="warning" size="small"><strong>REGRESAR</strong></LoadingButton>
                    <LoadingButton
                        loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end"
                        onClick={() => handleButtonClick()/* aprobarSolicitud() */}
                        variant="contained" color="secondary" size="small"><strong>APROBAR</strong></LoadingButton>
                </DialogActions>

            </Dialog>

            {/* DIALOG RECHAZAR SOLICITUDES */}
            <Dialog open={openRechazoTransferencia} fullWidth onClose={() => setOpenRechazoTransferencia(false)} maxWidth="xs">

                <DialogTitle>
                    <strong>¿ESTA SEGURO DE RECHAZAR LA TRANSFERENCIA?</strong>
                </DialogTitle>

                <DialogContent>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => {
                        setOpenRechazoTransferencia(false)
                        setOpen(true)
                    }} variant="contained" color="warning" size="small"><strong>REGRESAR</strong></Button>
                    <Button onClick={() => rechazarSolicitud()} variant="contained" color="error" size="small"><strong>RECHAZAR</strong></Button>
                </DialogActions>

            </Dialog>
        </>
    );
};