import React, { useEffect, useState } from "react";
import { db, storage, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import {
    Grid,
    Box,
    InputLabel,
    Button,
    Divider,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    Breadcrumbs,
    TextField,
    Paper
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import {
    listsProvincia
} from "../../../redux/actions/parametersActions";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { EditSector } from "./EditSector";
import { DeleteSector } from "./DeleteSector";

export const Sectores = () => {

    const provinciaList = useSelector(state => state.provinciaList);
    const { provincias, loading } = provinciaList
    const [provincia, setProvincia] = useState('')

    const [total, setTotal] = useState(0)

    /* const cantonesList = useSelector(state => state.cantonesList);
    const { cantones, loading: loadingCantones } = cantonesList */
    const [canton, setCanton] = useState('')
    const [cantones, setCantones] = useState('')

    const [sector, setSector] = useState('')
    const [sectores, setSectores] = useState('')

    const [codigo, setCodigo] = useState('');

    const [zona, setZona] = useState('');

    const getCanton = (e) => {

        let ref = db.collection("parameters").doc("cantones").collection("provincias").doc(e).collection("canton")

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setCantones(data)
        })
    };

    const getSectores = (e) => {

        let ref = db.collection("parameters").doc("cantones").collection("provincias").doc(provincia).collection("canton").doc(e).collection("parroquia").doc("TODOS").collection("sector")

        //Si tiene algun doc anterior se agrega acontinuacion
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setSectores(data)
        })
    };

    /* const getLastCodigoZona = () => {
        const ref = db.collection("parameters").doc("cantones").collection("provincias").
            ref.onSnapshot(snapshot => {
                if (snapshot.size > 0) {
                    const zonas = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                    const zona = zonas[0]
                    const numeroZona = zona.codigo + 1;
                    setCodigo(numeroZona)
                    setZona(`ZONA ${numeroZona}`)
                }
            })
    } */

    /* const getLastCodigoZona = (e) => {
        const ref = db.collection("parameters").doc("cantones").collection("provincias").doc(e)
            ref.onSnapshot(snapshot => {
                if (snapshot.size > 0) {
                    const zonas = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                    const zona = zonas[0]
                    const numeroZona = zona.codigo + 1;
                    setCodigo(numeroZona)
                    setZona(`ZONA ${numeroZona}`)
                }
            })
    } */

    const dispatch = useDispatch();

    useEffect(() => {
        /* getProductos() */
        /* getLastCodigoZona() */
        dispatch(listsProvincia())
    }, [dispatch]);

    const [nombre, setNombre] = useState('')
    const [ubicacion, setUbicacion] = useState('');

    /* const onsubmit = async () => {

        await db
            .collection("zonas").doc(`${zona} - ${nombre} - ${ubicacion}`).set({
                zona: zona,
                nombre: nombre,
                ubicacion: ubicacion,
                codigo: codigo,
                created: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                Swal.fire({ icon: "success", title: "Zona creada con exito" });
            })
            .catch((error) => {
                Swal.fire({ icon: "error", title: error });
            });
    }; */

    function CustomFooterTotalComponent(props) {
        console.log(props)
        return <Box sx={{ padding: "10px", display: "flex" }}>Total :{total} </Box>
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            {/* <GridToolbarQuickFilter /> */}

        </GridToolbarContainer>
    );

    const columns = [
        { field: 'nombre', headerName: 'NOMBRE', width: 1300 },
        {
            field: '1', headerName: 'EDITAR', width: 90,
            renderCell: (params) => {
                return <EditSector props={params.row} provincia={provincia} canton={canton} />
            }
        },
        {
            field: '2', headerName: 'ELIMINAR', width: 90,
            renderCell: (params) => {
                return <DeleteSector props={params.row} provincia={provincia} canton={canton} />
            }
        },
    ];

    return (
        <>
            <Box py={2} px={2}>
                <Paper elevation={3}>
                    <Box py={2} px={2}>

                        <p style={{ fontSize: "11px", marginBottom: "00px" }}><strong>PROVINCIA:</strong></p>
                        <Box py={2}>
                            <Select
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                fullWidth
                                value={provincia}
                                label="PROVINCIA"
                                onChange={(e) => {
                                    setProvincia(e.target.value)
                                    getCanton(e.target.value)
                                    /* getLastCodigoZona(e.target.value) */
                                }}>
                                {provincias &&
                                    provincias.map(item => (
                                        <MenuItem key={item.id} value={item.id} >
                                            {item.id}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Box>

                        {
                            provincia === '' ? '' :
                                <>
                                    <p style={{ fontSize: "11px", marginBottom: "00px" }}><strong>CANTON:</strong></p>
                                    <Box py={2}>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size="small"
                                            fullWidth
                                            value={canton}
                                            label="CANTON"
                                            onChange={(e) => {
                                                setCanton(e.target.value)
                                                getSectores(e.target.value)
                                            }}>
                                            {cantones &&
                                                cantones.map(item => (
                                                    <MenuItem key={item.id} value={item.id} >
                                                        {item.id}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Box>
                                </>
                        }

                        {
                            canton === '' ? '' :
                                <>

                                    <Paper>
                                        <Box pb={2} sx={{ height: 800, width: '100%' }}>
                                            <DataGrid
                                                density="compact"
                                                rows={sectores ? sectores : []}
                                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                // disableColumnFilter
                                                // disableColumnSelector
                                                // disableDensitySelector
                                                //hideFooterPagination
                                                columns={columns}
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                    Footer: CustomFooterTotalComponent,
                                                }}

                                                onStateChange={(state) => {
                                                    console.log(state.pagination.rowCount);
                                                    setTotal(state.pagination.rowCount)
                                                }}
                                            />
                                        </Box>
                                    </Paper>

                                    {/* <p style={{ fontSize: "11px", marginBottom: "00px" }}><strong>SECTORES:</strong></p>
                                    <Box py={2}>
                                        <Select
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size="small"
                                            fullWidth
                                            value={sector}
                                            label="PROVINCIA"
                                            onChange={(e) => {
                                                setSector(e.target.value)
                                            }}>
                                            {sectores &&
                                                sectores.map(item => (
                                                    <MenuItem key={item.id} value={item.id} >
                                                        {item.id}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Box> */}
                                </>
                        }

                        {
                            provincia === '' ?
                                <></> :
                                <>
                                    {/* <Box py={2} px={2}>
                                        <Paper>
                                            <Box sx={{ height: 800, width: '100%' }}>
                                                <DataGrid
                                                    density="compact"
                                                    rows={data ? data : []}
                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                    // disableColumnFilter
                                                    // disableColumnSelector
                                                    // disableDensitySelector
                                                    //hideFooterPagination
                                                    columns={columns}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                        Footer: CustomFooterTotalComponent,
                                                    }}

                                                    onStateChange={(state) => {
                                                        console.log(state.pagination.rowCount);
                                                        setTotal(state.pagination.rowCount)
                                                    }}
                                                />
                                            </Box>
                                        </Paper>
                                    </Box> */}
                                </>

                        }

                        {/* <Button
                            onClick={() => onsubmit()}
                            disabled={
                                provincia === ''
                            }
                            fullWidth
                            variant="contained"
                            color="secondary"
                        >
                            COSULTAR
                        </Button> */}
                    </Box>
                </Paper>
            </Box>
        </>
    );
};
