import React from 'react'
import { Tabs, Typography, Box, Tab } from '@mui/material';
import { VentasReingreso } from './VentasReingreso';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const ReingresoPage = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box pb={2}>
        <Typography variant='h5' component='h1'>VENTAS REINGRESO</Typography>
      </Box>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Reingreso" {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <VentasReingreso />
      </TabPanel>
    </>
  )
}