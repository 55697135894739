import React, { useState } from 'react';
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
export const SupervisionOperacion = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [openVent, setOpenVent] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'supervision_operaciones']?.includes(role))
            ? 
            <>
                <ListItemButton onClick={() => setOpenVent(!openVent)}>
                    <ListItemIcon>
                        <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Supervision Operacion" />
                    {openVent ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openVent} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                    <ListItemButton component={RouterLink} to={`supervision-operacion/kardex-instalaciones/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Kardex instalaciones" />
                        </ListItemButton>

                        <ListItemButton component={RouterLink} to={`supervision-operacion/agenda/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Agenda" />
                        </ListItemButton>
                     

                        <ListItemButton component={RouterLink} to={`supervision-operacion/tecnicos/`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Gestion Tecnicos" />
                        </ListItemButton>

                        {['factibilidad', 'Postes', 'proyectos', 'cajas', 'nodos'].map((text, index) => (
                            <ListItemButton
                                key={text}
                                component={RouterLink} to={`/operaciones/${text}/`}
                            >
                                <ListItemIcon></ListItemIcon>
                                <ListItemText style={{ textTransform: 'capitalize' }} primary={text} />
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}