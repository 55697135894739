import {
    Box,
    Button,
    Paper,
    Chip,
    Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { auth, db, firebase } from "../../../../firebase";
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    esES
} from '@mui/x-data-grid';
import { ViewTicketEnEsperaInstalador } from "./ViewTicketEnEsperaInstalador";
import { ViewTicketEnEsperaInstaladorNew } from "./ViewTicketEnEsperaInstaladorNew";
import { ViewTicketEnEsperaInstaladorNoMateriales } from "./ViewTicketEnEsperaInstaladorNoMateriales";

export const TicketsEnEsperaInstalador = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth

    const dispatch = useDispatch();
    useEffect(() => {
        LoadData();
    }, [dispatch]);

    const [open, setOpen] = useState(false);
  
    const [data, setData] = useState();
    //
    const current = new Date();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    //

    const LoadData = () => {

        if (userInfo.email === 'pamela.villamar@intercommerce.com.ec' || userInfo.email === 'intercommercebases@gmail.com') {

            let ref = db.collection("tickets_tecnicos").where('estado', '==', 0)
            /* if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email) */

            if (endDate && startDate) {
                startDate.setHours(0, 0, 0, 0)
                endDate.setHours(23, 59, 0, 0)

                let startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
                let endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
                ref = ref.where("fecha_gestion_ticket_operaciones", '>=', startfulldate)
                ref = ref.where("fecha_gestion_ticket_operaciones", '<=', endfulldate)

                ref.onSnapshot(snapshot => {
                    const data = [
                        ...snapshot.docs.map(doc => {
                            return {
                                ...doc.data(),
                                id: doc.id,
                            }
                        })
                    ];
                    setData(data)
                })

            }

        } else if (userInfo.email.includes('flota')) {

            let ref = db.collection("tickets_tecnicos").where('estado', '==', 0).where('correo_flota', '==', auth.currentUser.email);
            /* if (userInfo?.roles?.find(role => ['operador']?.includes(role))) ref = ref.where('correo_flota', '==', auth.currentUser.email) */

            ref.onSnapshot(snapshot => {
                const data = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];
                setData(data)
            })

        }

    };

    const onChange = (dates) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
    }

    /* const LoadData = (startAfterDoc, persistMessages = []) => {
  
      let ref = db.collection("productos").where("estado_producto", "==", 0);
  
      //Si tiene algun doc anterior se agrega acontinuacion
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(23, 59, 0, 0)
  
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        ref = ref.where("fecha_creacion_producto", '>=', startfulldate)
        ref = ref.where("fecha_creacion_producto", '<=', endfulldate)
  
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc)
        ref.onSnapshot(snapshot => {
          const totalCount = snapshot.size
          const data = [
            ...persistMessages,
            ...snapshot.docs.map(doc => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          ];
          setData(data)
          setQueryDocqueryDoc(snapshot.docs[totalCount - 1])
          setIsLastPage(totalCount < 1)
        })
      }
    }; */


    const columns = [
        {
            field: 'estado', headerName: 'ESTADO', width: 125,
            renderCell: (params) => {
                return <Chip size="small" label="EN ESPERA" color="warning" />
            }
        },

        { field: 'uid_ticket', headerName: 'ID TICKET', width: 120 },
        { field: 'nombre_cliente', headerName: 'NOMBRE CLIENTE', width: 350 },
        { field: 'telefono', headerName: 'TELEFONO', width: 130 },
        { field: 'celular', headerName: 'CELULAR', width: 120 },
        { field: 'correo', headerName: 'CORREO', width: 220 },
        { field: 'direccion', headerName: 'DIRECCION', width: 350 },
        { field: 'zona', headerName: 'ZONA', width: 350 },
        { field: 'coordenadas', headerName: 'COORDENADAS', width: 200 },
        { field: 'caja', headerName: 'CAJA', width: 130 },
        { field: 'linea_contrato', headerName: 'LINEA CONTRATO', width: 200 },
        { field: 'agendado_desde', headerName: 'AGENDADO DESDE', width: 250 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 250 },
        { field: 'asunto_del_ticket', headerName: 'ASUNTO DEL TICKET', width: 400 },
        {
            field: '1', headerName: 'VALOR', width: 100,
            renderCell: (params) => {
                const respuesta = params.row.valor_a_cobrar === undefined || params.row.valor_a_cobrar === null || params.row.valor_a_cobrar === '' ? '' : params.row.valor_a_cobrar
                return `$${respuesta}`
            }
        },
        { field: 'detalles_del_ticket', headerName: 'DETALLES DEL TICKET', width: 250 },
        { field: 'flota', headerName: 'FLOTA', width: 250 },
        { field: 'lider_flota', headerName: 'AUXILIAR', width: 250 },
        { field: 'auxiliar_flota', headerName: 'AUXILIAR', width: 250 },
        { field: 'turno', headerName: 'TURNO', width: 250 },
        {
            field: 'requiere_materiales', headerName: 'REQUIERE MATERIALES', width: 210,
            renderCell: (params) => {
                return params.row.requiere_materiales === 0 ? 'SI' : 'NO'
            }
        },
        {
            field: 'fecha_para_visita_operaciones', headerName: 'FECHA / HORA DE VISITA', type: 'dateTime', width: 210,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss'),
        },
        { field: 'observacion_extra_pyl', headerName: 'OBSERVACIONES EXTRA PYL', width: 400 },
        {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <ViewTicketEnEsperaInstaladorNew props={params.row} />
                /* return params.row.requiere_materiales === 0 ? <ViewTicketEnEsperaInstaladorNew props={params.row} /> : <ViewTicketEnEsperaInstaladorNoMateriales props={params.row} /> */
            }
        },
        /* {
            field: '', headerName: 'VER', width: 90,
            renderCell: (params) => {
                return <ViewTransferenciaActivosFijos props={params.row} />
            }
        }, */

    ];

 
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />

        </GridToolbarContainer>
    );


    return (
        <>
            {/* <Box pt={1} pl={1}>
                <Typography variant="button" >

                    <Typography >
                        <strong>({data && data.length}) ACTIVOS FIJOS</strong>
                    </Typography >
                </Typography >
            </Box>
            <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    <Stack direction="row" spacing={2}>

                        <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                        >
                            {" "}
                            DESCARGAR EXCEL
                        </Button>
                    </Stack >

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box pt={1}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box> */}

            <Box pl={1} pt={1} pb={1} display="flex" alignItems='center' >
                <Box sx={{ flexGrow: 1 }}>
                    <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                    </Box>
                    {/* <Stack direction="row" spacing={2}>

                        <Button onClick={handleExport} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                        >
                            {" "}
                            DESCARGAR EXCEL
                        </Button>
                    </Stack > */}

                    <Box px={1} pt={1} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Button size="small" variant="contained" color="secondary" onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                        </Box>
                    </Box>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box pt={1}>
                            <DatePicker
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                            />
                        </Box>
                        <Button size="small" disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                    </Collapse>
                </Box>
            </Box>

            <Box px={2} py={2}>
                <Paper>
                    <Box pl={2} pt={2}>
                        <p style={{ fontSize: "18px", marginBottom: "0px" }}><strong>TICKETS EN ESPERA</strong></p>
                    </Box>
                    <Box px={2} py={2} sx={{ height: 850, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={data ? data : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,

                            }}

                           
                        />
                    </Box>
                </Paper>
            </Box>

        </>
    );
};