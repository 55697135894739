import { db } from '../../../firebase'
export const listsCajasSector = (sectorId) => async (dispatch) => {
    try {
        dispatch({ type: 'CAJAS_SECTOR_LIST_REQUEST' })
        db.collection(`cajas`).where('SECTOR', '==', sectorId)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
              
                dispatch({
                    type: 'CAJAS_SECTOR_LIST_SUCCESS',
                    payload: arrayMessage
                })
            })
          
    } catch (error) {
        dispatch({
            type: 'CAJAS_SECTOR_LIST_FAIL',
            payload: error
        })
    }
}