import React, { useState, useEffect } from "react";
import { Grid as Grid2, GridColumn } from '@progress/kendo-react-grid';//jesus

import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    InputLabel,
    Tooltip,
    Grid,
    Paper,
    Divider,
    Card,
    CardContent,
    Typography,
    Alert
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../firebase";
import Swal from "sweetalert2";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'moment/locale/es'
import moment from 'moment'
import { listsMateriales } from '../../../redux/actions/parametersActions'
import { detallesInstalacionTickets } from '../../../redux/actions/instalacionesAction';


export const ViewTicketAprobadoInstaladorOperaciones = ({ props }) => {

    //OBTENER LOS MATERIALES OTORGADOS PARA LA INSTALACION

    const [dataMateriales, setDataMateriales] = useState();

    const getMaterialesUtilizados = () => {
        let ref = db.collection("tickets_tecnicos").doc(props.id).collection('materiales_instalacion')
        ref.onSnapshot(snapshot => {
            const data = [
                ...snapshot.docs.map(doc => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    }
                })
            ];
            setDataMateriales(data)
        })
    }

    /* INSTALACION */

    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth

    const materialesList = useSelector(state => state.materialesList)
    const { materiales, loading } = materialesList
    const [material, setMaterial] = useState('')

    const [caja, setCaja] = useState(props.caja);
    const [open, setOpen] = useState(false);
    const [openCaja, setOpenCaja] = useState(false);
    const [nombrePlan, setNombrePlan] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPuerto, setOpenPuerto] = useState(false);
    const [openConfirmInstalacion, setOpenConfirmInstalacion] = useState(false);
    const [openInstalacionRechazada, setOpenInstalacionRechazada] = useState(false);
    const [openSendInstalacionRechazada, setOpenSendInstalacionRechazada] = useState(false);
    //Servicios contratados
    const [potencia, setPotencia] = useState('');
    const [puerto, setPuerto] = useState('');

    const [nombresConfirmacionCable, setNombresConfirmacionCable] = useState('');

    const [observacionesExtra, setObservacionesExtra] = useState('');

    //Equipos instalados
    const [equipo, setEquipo] = useState('');
    const [DSerie, setDSerie] = useState('');
    const [NMac, setNMac] = useState({ campo: "", valido: true });
    const [NSerie, setNSerie] = useState({ campo: "", valido: true });
    //Accesorios
    const [materialesExtra, setMaterialesExtra] = useState('');

    const [valorCuotas, setValorCuotas] = useState({ campo: "", valido: true });
    //Observaciones
    //Detalle cable
    const [bobinaCod, setBobinaCod] = useState('');
    const [cableInicio, setCableInicio] = useState('');
    const [cableFinaliza, setCableFinaliza] = useState('');
    const [cableUtilizado, setCableUtilizado] = useState({ campo: "", valido: true });
    //Detalle instalacion y extras (accesorios) (detalles pago instalacion)
    const [valorMaterialesExtra, setValorMaterialesExtra] = useState('');
    const [valorCobrado, setValorCobrado] = useState({ campo: "", valido: true });
    const [valorExtra, setValorExtra] = useState('');
    const [noCuotas, setNoCuotas] = useState('');


    //Novedades de instalacion (Materiales utilizados)
    const [onus, setOnu] = useState('');
    const [tornillos, setTornillos] = useState('');
    //
    const [grapas, setGrapas] = useState('');

    //
    const [number, setNumber] = useState({ one: 0, two: 0 })
    const [suma, setSuma] = useState()
    const [cableTotal, setCableTotal] = useState(0);
    const [fibra, setFibra] = useState({ campo: "", valido: true });
    const [transicionesDuplex, setTransicionesDuplex] = useState('');
    const [soportes, setSoportes] = useState('');
    const [amarrasP, setAmarrasP] = useState('');
    const [amarrasG, setAmarrasG] = useState('');
    const [mecanicos, setMecanicos] = useState('');
    const [tacos, setTacos] = useState('');
    const [pernos, setPernos] = useState('');
    const [observacionInstalacionRealizada, setObservacionInstalacionRealizada] = useState('');
    const [observacionInstalacionRechazada, setObservacionInstalacionRechazada] = useState({ campo: "", valido: true });
    const [data, setData] = useState('');

    const [motivosRechazos, setMotivosRechazos] = useState([]);
    const [motivoRechazo, setMotivoRechazo] = useState('');

    const getMotivosRechazos = async () => {
        const ref = await db.collection("parameters").doc('tipos_rechazos_tickets_operaciones').collection('tipos_rechazos')

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setMotivosRechazos(data);
            console.log('data', data)
        });
    }

    const [numSolicitudFlota, setNumSolicitudFlota] = useState(false)

    const [updateValorCobrado, setUpdateValorCobrado] = useState(false)

    const [dialogValorCobradoActualizadoConExito, setDialogValorCobradoActualizadoConExito] = useState(false)

    const [newValorCobradoUpdate, setNewValorCobradoUpdate] = useState(props.valor_a_cobrar)

    const loadNumSolicitudFlota = () => {
        const ref = db.collection("reporte_movimientos_flotas").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `MOV-` + numeroSolicitud;
                setNumSolicitudFlota(numeroSolicitud)
            }
        })
    }
//jesus



//jesus


//jesus

    const [materialesInstalacion, setMaterialesInstalacion] = useState([]);

    const [materialesInstalacionAsignados, setMaterialesInstalacionAsignados] = useState([]);

    const getMaterialesAsignados = async () => {

        console.log('props.id', props.id)

        let ref = await db.collection("tickets_tecnicos").doc(props.id).collection('materiales_utilizados')

        ref.onSnapshot(async (snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            console.log('dataMaterialesAsignados', data)

            setMaterialesInstalacionAsignados(data);

            data.forEach(async (material, index) => {
                console.log('material', material)
                console.log('codigo producto', material.codigo_producto)

                let ref = await db.collection("tickets_tecnicos").doc(props.id).collection('materiales_utilizados').doc(material.codigo_producto).collection('series')

                ref.onSnapshot((snapshot) => {
                    const dataSerie = [
                        ...snapshot.docs.map((doc) => {
                            return {
                                ...doc.data(),
                                id: doc.id,
                            };
                        }),
                    ];

                    dataSerie.forEach((serie, indexSerie) => {
                        console.log('serie', serie)
                        console.log('numero serie', serie.serie)
                        console.log('index serie', indexSerie)
                        console.log('index material', index)

                        const num = indexSerie;
                        let array = [];
                        for (var i = 0; i <= num; i++) {
                            //console.log('Hola')
                            let n;
                            n += i;
                            //console.log('for', n)
                            array.push({
                                index_serie: i,
                                id: i,
                                serie: dataSerie[i]['serie'],
                                descripcion: dataSerie[i]['descripcion']
                            });
                            data[index]["series"] = array;
                        }
                    })


                });
            })

        });
    }

    const rechazarInstalacion = () => {

        db.collection("tickets").doc(props.id).update({
            estado: 3, //Tickets por reasignar
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
            motivo_rechazo: motivoRechazo
        })

        db.collection("tickets_tecnicos").doc(props.id).update({
            estado: 3, //Tickets por reasignar
            fecha_rechazo: firebase.firestore.FieldValue.serverTimestamp(),
            motivo_rechazo: motivoRechazo
        })
            .then(() => {
                setOpen(false)
                Swal.fire({ icon: "success", text: "Se ha rechazado el ticket con exito", });
            })
            .catch((error) => { console.error("Error adding document: ", error); });

        /* db.collection("agenda").doc(id).update({
            estado: 6,
            observacionInstalacionRechazada: observacionInstalacionRechazada,
            fechaRechazoInstalacion: firebase.firestore.FieldValue.serverTimestamp(),
        }) */

    }

    /* FUNCION DE ENVIO */

    const CerrarTicket = async () => {

        db.collection("tickets_tecnicos").doc(props.id).update({
            estado: 2,
            fecha_cierra_ticket: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_cerro_ticket: userInfo.displayName,
        })

        db.collection("tickets").doc(props.id).update({
            estado: 2,
            fecha_cierra_ticket: firebase.firestore.FieldValue.serverTimestamp(),
            usuario_que_cerro_ticket: userInfo.displayName,
        })

        setOpenPuerto(false)
        setOpenConfirmInstalacion(false)

        Swal.fire({
            icon: "success",
            text: 'Gestion enviada con exito'
        });

    }



    const onData = () => {
        setOpen(true)
        setData(props)
    }

    //TOTAL PAGO

    const conversionPlanValor = parseFloat(props.valor_a_cobrar)

    const convertValorExtra = parseFloat(valorExtra)

    const ConversorCuotas = parseFloat(noCuotas);

    const valorExtraValorCobrado = conversionPlanValor + convertValorExtra;

    const valorIntsalacionYNumeroDeCuotas = valorExtraValorCobrado / ConversorCuotas;

    const numeroCuotasAPagar = parseFloat(props.valor_a_cobrar) / parseFloat(noCuotas);

    //TOTAL CABLE

    const convertCableInicio = parseFloat(cableInicio);

    const convertCableFin = parseFloat(cableFinaliza)

    const sumaCableInicioYCableFinal = convertCableInicio - convertCableFin;

    const metrajeExtra = sumaCableInicioYCableFinal - 150;

    const nombrePersonaAutorizaMetraje = () => {
        console.log(nombresConfirmacionCable)
    }

    const setUpdateCaja = async (numFlota) => {
        await db.collection('tecnicos').doc(numFlota).update({
            caja: caja
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado la caja con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });

        setOpenCaja(false)
    }

    const [isDisabled, setIsDisabled] = useState(false);

    const [seriesMaterialesInstalacionAsignados, setSeriesMaterialesInstalacionAsignados] = useState([]);

    /* FUNCION PARA LAS SERIES ENTREGADAS */

    const handleChangeSerieEntrega = async (index, evnt) => {
        console.log(evnt.target.value)

        const list = [...materialesInstalacionAsignados];
        const serie_entrega = evnt.target.value
        list[index]["serie_entrega"] = parseInt(serie_entrega)


        /* const { name, value } = evnt.target;
        const list = [...materialesInstalacionAsignados];
        list[index]["serie_entrega"] = parseInt(cantidad_entregada) */
        /* const { name, value } = evnt.target;
        const list = [...materialesInstalacionAsignados];
        if (evnt.target) {
          const { name, value } = evnt.target;
          const list = [...materialesInstalacionAsignados];
          list[index][name] = value;
    
          const serie_entrega = list[index]["serie_entrega"]
          list[index]["serie_entrega"] = parseInt(serie_entrega)
    
    
          //
          console.log(list, "--");
    
          setMaterialesInstalacionAsignados(list);
          console.log(materialesInstalacionAsignados, 'input')
    
        } else {
          const list = [...materialesInstalacionAsignados];
          list[index]['serie_entrega'] = evnt.target.value;
          setMaterialesInstalacionAsignados(list);
    
        } */
    }

    const closeModalUpdateCaja = () => {
        setOpenPuerto(true)
        setOpenCaja(false)
    }

    /* FOTOS */

    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [file4, setFile4] = useState(null);
    const [file6, setFile6] = useState(null);

    const instalacionTicketsDetalle = useSelector(state => state.instalacionTicketsDetalle)
    const { instalacionTickets } = instalacionTicketsDetalle

    const dispatch = useDispatch()

    useEffect(() => {

        getMotivosRechazos()

        getMaterialesUtilizados()

        loadNumSolicitudFlota()

        getMaterialesAsignados()

        dispatch(detallesInstalacionTickets(props.id))
        /* setFile1(instalacion.foto_potencia_casa)
        setFile2(instalacion.foto_potencia_caja)
        setFile3(instalacion.foto_router_casa)
        setFile4(instalacion.foto_etiqueta)

        setFile6(instalacion.foto_caja) */

        /* getMateriales() */

        setNombrePlan(props.planNombre);
        const { one, two } = number
        setSuma(Number(one) + Number(two))
        setValorCobrado(props.valor_a_cobrar);
        //mensajeHidden()
        dispatch(listsMateriales())


    }, [dispatch, number])

    const updateValorCobradoFunction = () => {
        db.collection('tickets').doc(props.id).update({
            valor_a_cobrar: newValorCobradoUpdate
        })

        db.collection('tickets_tecnicos').doc(props.id).update({
            valor_a_cobrar: newValorCobradoUpdate
        })

        setUpdateValorCobrado(false)

        setDialogValorCobradoActualizadoConExito(true)

    }

    return (
        <>
            <Tooltip title="Cerrar ticket">
                <Button onClick={() => setOpenPuerto(true)/* onData() */}>
                    <EditIcon />
                </Button>
            </Tooltip>

            {/* ---------------------------------------------------------- */}
            {/* ACTUALIZAR CAJA */}
            <Dialog open={openCaja} onClose={() => setOpenCaja(false)} maxWidth="xs">
                <DialogTitle>Por favor asegurece de ingresar correctamente los datos.</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Box py={1}>
                            <FormControl fullWidth>
                                <Box py={1}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        label="CAJA"
                                        value={caja}
                                        onChange={(e) => setCaja(e.target.value)}
                                    />
                                </Box>
                            </FormControl>
                        </Box>


                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeModalUpdateCaja()}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setUpdateCaja(props.numContrato)} size="small" variant="contained" color="secondary">
                        {" "}
                        ACTUALIZAR CAJA
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ---------------------------------------------------------- */}
            {/* RECHAZO INSTALACION */}
            <Dialog open={openInstalacionRechazada} onClose={() => setOpenInstalacionRechazada(false)} maxWidth="xs">
                <DialogTitle>Por favor deje una observacion del por que rechaza la instalacion.</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <Box py={2}>
                            <FormControl fullWidth>
                                <InputLabel>Observaciones</InputLabel>
                                <Select
                                    label="Observaciones"

                                    value={observacionInstalacionRechazada}
                                    onChange={(e) => setObservacionInstalacionRechazada(e.target.value)}
                                >
                                    <MenuItem value="No hay caña o posteria">No hay caña o posteria</MenuItem>
                                    <MenuItem value="No hay disponibilidad">No hay disponibilidad</MenuItem>
                                    <MenuItem value="No hay dinero">No hay dinero</MenuItem>
                                    <MenuItem value="Sector inseguro">Sector inseguro</MenuItem>
                                    <MenuItem value="Caja llena">Caja llena</MenuItem>
                                    <MenuItem value="Coordenadas erroneas del vendedor">Coordenadas erroneas del vendedor</MenuItem>
                                    <MenuItem value="Cliente no disponible">Cliente no disponible</MenuItem>
                                    <MenuItem value="No existe unidad">No existe unidad</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>


                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => setOpenSendInstalacionRechazada(true)} variant="contained" size="small" color="error">
                        {" "}
                        RECHAZAR TICKET VISITA
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ---------------------------------------------------------- */}
            {/* CONFIRMAR RECHAZO INSTALACION */}
            <Dialog open={openSendInstalacionRechazada} onClose={() => setOpenSendInstalacionRechazada(false)} maxWidth="xs">
                <DialogTitle>¿Esta seguro de rechazar el ticket? <strong>{props.id}</strong></DialogTitle>
                <DialogContent>
                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>MOTIVO DE RECHAZO:</p>
                    <Select
                        sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                        size="small"
                        fullWidth
                        label="MOTIVOS RECHAZO"
                        value={motivoRechazo}
                        onChange={(e) => setMotivoRechazo(e.target.value)}
                    >
                        {motivosRechazos && motivosRechazos.map((row, key) => (
                            <MenuItem value={row.tipo}>{row.tipo}</MenuItem>
                        ))}

                    </Select>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSendInstalacionRechazada(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => rechazarInstalacion()} variant="contained" size="small" color="error">
                        {" "}
                        RECHAZAR TICKET VISITA
                    </Button>
                </DialogActions>
            </Dialog>
            {/* --------------------------------------------------------------- */}
            {/* COLOCACION DEL PUERTO */}
            <Dialog fullScreen open={openPuerto} fullWidth onClose={() => setOpenPuerto(false)} maxWidth="md">
                {/* <DialogTitle><strong>ORDEN DE TRABAJO</strong></DialogTitle> */}
                <DialogContent dividers>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>VISITA</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>



                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>ASUNTO TICKET</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.asunto_del_ticket}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>#TICKET</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.numero_ticket}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

{//jesus
}
<Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>#FECHA VISITA</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.fechaTicketRealizado ? moment(props.fechaTicketRealizado.toDate()).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}

                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>


                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>#CONTRATO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.numContrato}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>NOMBRE DEL CLIENTE</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.nombre_cliente}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>COORDENADAS</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.coordenadas}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>CAJA</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.caja}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>ZONA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.zona}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>TELEFONO</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.telefono}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SERVICIO CONTRATADO</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.planNombre}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR COBRADO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.valor_a_cobrar}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>N CUOTAS</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            type="number"
                                                            size="small"
                                                            fullWidth
                                                            value={props.numero_cuotas}

                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR MENSUAL A PAGAR</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.valor_a_pagar_mensual}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box> */}

                                    </Grid>

                                    <Grid item xs={6}>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>TECNICO RESPONSABLE</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.lider_flota}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>FLOTA DE INSTALACION</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.flota}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SERVICIO CONTRATADO</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.planNombre}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR COBRADO</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            type="number"
                                                            size="small"
                                                            fullWidth
                                                            value={newValorCobradoUpdate}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>N CUOTAS</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        type="number"
                                                        size="small"
                                                        fullWidth
                                                        value={props.numero_cuotas}

                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box py={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR MENSUAL A PAGAR</p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            size="small"
                                                            fullWidth
                                                            value={props.valor_a_pagar_mensual}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box pt={2}>
                                            <Grid container spacing={6}>
                                                <Grid item xs={2}>
                                                    <p
                                                        style={{
                                                            fontSize: "10px",
                                                            marginBottom: "10px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        CONTACTAR CON CLIENTE
                                                    </p>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            sx={{
                                                                '& legend': { display: 'none' },
                                                                '& fieldset': { top: 0 },
                                                            }}
                                                            disabled
                                                            size="small"
                                                            fullWidth
                                                            value={props.contactar_con_cliente}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Grid>

                                </Grid>

                                <Grid container spacing={5}>

                                    <Grid item xs={6}>

                                        <Box pt={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO VIVIENDA CLIENTE:</strong></p>
                                        </Box>

                                        <Box py={2}>
                                            <img width={400} src={props.imagen} />
                                        </Box>

                                    </Grid>

                                    <Grid item xs={6}>

                                        <Box pt={2}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>FOTO OPCIONAL:</strong></p>
                                        </Box>

                                        <Box py={2}>
                                            <img width={400} src={props.imagen_opcional} />
                                        </Box>

                                    </Grid>

                                </Grid>

                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>MATERIALES</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
{
    //jesus
}

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>

                                {/* MATERIALES ASIGNADOS */}

                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                       
                                    </Grid>

                                    {/* MATERIALES UTILIZADOS */}

                                    <Grid item xs={6}>
                                        <Box pb={1} px={1}>

                                            <Box py={1}>
                                                <Paper elevation={3}>
                                                    <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                                        <Box py={1} px={1}>
                                                            <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>MATERIALES UTILIZADOS</p>
                                                        </Box>
                                                    </Box>
                                                </Paper>
                                            </Box>

                                            {/* <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>MATERIALES UTILIZADOS</p> */}
                                            <Paper elevation={3}>
                                                <Box py={2} px={2}>
                                                    {
                                                        materialesInstalacionAsignados.map((material, index) => {
                                                            return (
                                                                <>
                                                                    <Box pb={2}>
                                                                        <Grid container spacing={6}>
                                                                            <Grid item xs={2}>
                                                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>{material.descripcion}</p>
                                                                            </Grid>
                                                                            <Grid item xs={10}>
                                                                                <FormControl fullWidth>
                                                                                    <TextField
                                                                                        sx={{
                                                                                            '& legend': { display: 'none' },
                                                                                            '& fieldset': { top: 0 },
                                                                                        }}
                                                                                        name="cant_entrega"
                                                                                        size="small"
                                                                                        fullWidth
                                                                                        onChange={(e) => {
                                                                                            /* handleChange(index, e) */
                                                                                            console.log('print array', materialesInstalacionAsignados)
                                                                                        }}
                                                                                        value={material.cantidad_utilizada}
                                                                                    />

                                                                                    {/* <Button size="small" onClick={() => console.log(material.series)}>Serie</Button> */}

                                                                                    {
                                                                                        material.cant_entrega === undefined || material.cant_entrega === 0 ? '' :
                                                                                            <>
                                                                                                {
                                                                                                    material.series === undefined ? '' : material.series.filter(item => /ONU/i.test(item.descripcion)).map((serie, index) => {
                                                                                                        return (
                                                                                                            <Box py={1}>
                                                                                                                <Select
                                                                                                                    key={parseInt(material.cant_entrega)}
                                                                                                                    fullWidth
                                                                                                                    sx={{
                                                                                                                        "& legend": { display: "none" },
                                                                                                                        "& fieldset": { top: 0 },
                                                                                                                    }}
                                                                                                                    size="small"
                                                                                                                    value={material.serie_entrega}
                                                                                                                    label="MOTIVO"
                                                                                                                    onChange={(e) => {
                                                                                                                        handleChangeSerieEntrega(index, e)
                                                                                                                        console.log('print array', materialesInstalacionAsignados)
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <MenuItem
                                                                                                                        key={index}
                                                                                                                        value={serie.serie}
                                                                                                                    >
                                                                                                                        {serie.serie}
                                                                                                                    </MenuItem>
                                                                                                                </Select>
                                                                                                            </Box>
                                                                                                        )
                                                                                                    })}
                                                                                            </>
                                                                                    }

                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Paper>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>SOLUCION DE VISITA</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <Grid container spacing={5}>

                                    <Grid item xs={6}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>SOLUCION DE VISITA</p>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                        }}
                                                        size="small"
                                                        fullWidth
                                                        value={props.observaciones_extra}
                                                        onChange={(e) => setObservacionesExtra(e.target.value)}
                                                        multiline
                                                        rows={3}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6}>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Paper>
                    </Box>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={1} px={1} sx={{ bgcolor: '#ececec' }}>
                                <Box py={1} px={1}>
                                    <p style={{ fontSize: "14px", marginBottom: "0px", fontWeight: "bold" }}>FOTOS</p>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>

                    <Box px={1} py={1}>
                        <Paper elevation={3}>

                            <Box py={2} px={2}>

                                <Grid container>
                                    <Grid md={6}>
                                        <Box pb={1}>
                                            <Typography>Foto de la ONU antes de Solución</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_antes} />

                                        <Box py={1}>
                                            <Typography>Potencia casa del cliente antes de solución</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_opcional1} />
                                    </Grid>
                                    <Grid md={6}>
                                        <Box pb={1}>
                                            <Typography>Foto de la ONU despues de la solución</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_despues} />

                                        <Box py={1}>
                                            <Typography>Foto del daño encontrado</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_opcional2} />

                                        <Box py={1}>
                                            <Typography>Foto Test</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_test} />
                                        

                                        <Box py={1}>
                                            <Typography>Foto de potencia en casa cliente despues de la solución</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_potencia} />
                                        <Box py={1}>
                                            <Typography>Foto solucion dada</Typography>
                                            <Divider />
                                        </Box>
                                        <img width={400} src={props.foto_solucion} />
                                       
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button color="warning" variant="outlined" size="small" onClick={() => setOpenPuerto(false)}><strong>REGRESAR</strong></Button>
                    <Button color="inherit" variant="outlined" size="small" onClick={() => setUpdateValorCobrado(true)}><strong>ACTUALIZAR VALOR COBRADO</strong></Button>
                    <Button size="small" color="error" variant="outlined" onClick={() => {
                        setOpenSendInstalacionRechazada(true)
                    }} >RECHAZAR TICKET</Button>
                    <Button
                        size="small"
                        color='secondary'
                        disableElevation
                        onClick={() => setOpenConfirmInstalacion(true)}
                        variant="contained"
                    >

                        CERRAR TICKET
                    </Button>
                </DialogActions>
            </Dialog>
            {/* --------------------------------------------------------------- */}
            {/* CONFIRMACION DE LA INSTALACION */}
            <Dialog open={openConfirmInstalacion} onClose={() => setOpenConfirmInstalacion(false)} maxWidth="xs">
                <DialogContent>Por favor, asegúrese de que todos los datos esten correctos.</DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenConfirmInstalacion(false)} size="small" color="primary" variant="outlined" ><strong>Cancelar</strong></Button>
                    <Button
                        /* component={RouterLink} */
                        size="small"
                        color='secondary'
                        onClick={() => CerrarTicket()}
                        variant="contained"
            /* to={`/Instaladores/subir-foto/${props.id}`} */>
                        {" "}
                        Cerrar ticket
                    </Button>
                    
                </DialogActions>
            </Dialog>

            {/* --------------------------------------------------------------- */}
            {/* ACTUALIZAR VALOR COBRADO */}
            <Dialog open={updateValorCobrado} onClose={() => setUpdateValorCobrado(false)} maxWidth="xs">
                <DialogContent>
                    Por favor, asegúrese de que el valor cobrado esta correcto.
                    <Box py={2}>
                        <Grid container spacing={6}>
                            <Grid item xs={2}>
                                <p style={{ fontSize: "10px", marginBottom: "10px", fontWeight: "bold" }}>VALOR COBRADO</p>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControl fullWidth>
                                    <TextField
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        type="number"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => setNewValorCobradoUpdate(e.target.value)}
                                        value={newValorCobradoUpdate}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setUpdateValorCobrado(false)} size="small" color="primary" variant="outlined" ><strong>Regresar</strong></Button>
                    <Button
                        /* component={RouterLink} */
                        size="small"
                        color='secondary'
                        onClick={() => updateValorCobradoFunction()}
                        variant="contained">
                        {" "}
                        Actualizar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* --------------------------------------------------------------- */}
            {/* DIALOG VALOR COBRADO ACTUALIZADO CON EXITO */}
            <Dialog open={dialogValorCobradoActualizadoConExito} onClose={() => setDialogValorCobradoActualizadoConExito(false)} maxWidth="xs">
                <DialogContent>Se ha actualizado el valor cobrado correctamente.</DialogContent>

                <DialogActions>
                    <Button onClick={() => setDialogValorCobradoActualizadoConExito(false)} size="small" color="primary" variant="outlined" ><strong>Ok</strong></Button>
                </DialogActions>
            </Dialog>

        </>
    );
}