import React, { useState } from 'react'
import { storage, db, firebase } from '../../firebase'
import { Dialog, Tooltip, DialogContent, DialogActions, DialogTitle, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploading from 'react-images-uploading';
import Compressor from 'compressorjs';
import Swal from "sweetalert2"
import EditIcon from "@mui/icons-material/Edit";
export const EdditProducts = ({ props }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(0);
    const [images, setImages] = useState(null);
    const [cantidad, setCantidad] = useState(0);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('')
    const handleAddProducts = async () => {
        setLoading(true)
        const uid = title.replace(/\s+/g, '-');
        await db.collection('premios').doc(uid).set({
            product_name: title,
            product_description: description,
            estado: 1,
            product_price: price,
            product_quantity: cantidad,
            created: firebase.firestore.FieldValue.serverTimestamp()
        });
        const uploadPromises = images.map(async (image, index) => {
            if (image.file) {
                console.log(image, 'image')
                const compressedResult = await new Promise((resolve, reject) => {
                    new Compressor(image.file, {
                        quality: 0.5,
                        success: (result) => {
                            resolve(result);
                        },
                        error: reject
                    });
                });
                const pictureRef = storage.ref().child('premios').child(uid).child(image.file.name);
                const letreroUpload = pictureRef.put(compressedResult);
                letreroUpload.on("state_changed", (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED:
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    Swal.fire({ icon: 'error', text: error, });
                }, async () => {
                    const downloadURL = await letreroUpload.snapshot.ref.getDownloadURL();
                    db.collection('premios').doc(uid).update({
                        product_photo: firebase.firestore.FieldValue.arrayUnion(downloadURL)
                    });
                });
            }
        });
        const uploadResults = await Promise.all(uploadPromises);
        const allUploadsSuccessful = uploadResults.every((result) => result !== null);
        if (allUploadsSuccessful) {
            setLoading(false)
        } else {
            setLoading(false)
        }
        setLoading(false)
        setOpen(false)
        setTitle('')
        setDescription('')
        setCantidad(0)
        setPrice(0)
        setImages(null)
        Swal.fire({ icon: 'success', text: 'Se agrego el producto' })
    }
    const onData = () => {
        setTitle(props.product_name)
        setDescription(props.product_description)
        setCantidad(props.product_quantity)
        setPrice(props.product_price)
        //setImages(aaa)
        setOpen(true)
    }
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    return (
        <div >
            <Tooltip title="Editar">
                <IconButton onClick={() => onData()}>
                    <EditIcon fontSize='small' />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    Editar Producto
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <label>Nombre del Producto</label>
                    <input type="text" className='form-control'
                        onChange={(e) => setTitle(e.target.value)} value={title}></input>
                    <br></br>
                    <label>Descripcion del Producto</label>
                    <textarea cols="40" rows="5" type="text" className='form-control'
                        onChange={(e) => setDescription(e.target.value)} value={description}></textarea >
                    <br></br>
                    <Grid container spacing={5}>
                        <Grid item md={6}>
                            <label>Precio del Producto</label>
                            <input type="number" className='form-control'
                                onChange={(e) => setPrice(e.target.value)} value={price}></input>
                            <br></br>
                        </Grid>
                        <Grid item md={6}>
                            <label>Cantidad del Producto</label>
                            <input type="number" className='form-control'
                                onChange={(e) => setCantidad(e.target.value)} value={cantidad}></input>
                            <br></br>
                        </Grid>
                    </Grid>
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={15}
                        dataURLKey="data_url"
                        acceptType={["jpg"]}
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                        }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                                <button
                                    style={isDragging ? { color: "red" } : null}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Click or Drop here
                                </button>
                                &nbsp;
                                <button onClick={onImageRemoveAll}>Remove all images</button>
                                {imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                        <img src={image.data_url} alt="" width="100" />
                                        <div className="image-item__btn-wrapper">
                                            <button onClick={() => onImageUpdate(index)}>Update</button>
                                            <button onClick={() => onImageRemove(index)}>Remove</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </ImageUploading>
                </DialogContent>
                <DialogActions>
                    <button disabled={loading || !title} onClick={() => handleAddProducts()} className="btn btn-primary">Agregar Producto</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
