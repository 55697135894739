import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { db } from '../../../../firebase';
export const CalcularItems = ({ items }) => {
    const [value, setValue] = useState('');
    const onSubmit = (e) => {
        //console.log(items, 'items')
        const cantidad_entregada = parseInt(e)
        if(items.cantidad_transferencia < e ){
            //console.log('Si entra')
        }else if(Math.sign(e) === -1)
        {
            console.log('negativo')
        }        
        else{
            setValue(e)
            console.log(items)
            const diferencia = items.cantidad_transferencia - cantidad_entregada
            db.collection('transferencias').doc(items.uidSolicitud).collection('items').doc(items.id).update({
                cant_entrega: /* parseInt(e) */ Number.isNaN(Number(parseInt(e))) ? 0 : parseInt(e),
                cant_diferencia: /* diferencia */ Number.isNaN(Number(diferencia)) ? 0 : diferencia
            })
        }
    }
    return (
        <>
            <TextField 
                 size='small'
                 type='number'
                 value={value}
                 onChange={(e) => onSubmit(e.target.value)}
                 variant="outlined"
            />
        </>
    )
}