import { db, firebase, auth, functions } from '../../../firebase'
import Swal from 'sweetalert2';
export const createAgenda = (id, formData, agendaData, navigate) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'AGENDA_CREATE_REQUEST' })
        db.collection("registro_orden_trabajo").doc(id).set(formData)
        db.collection("tecnicos").doc(id).set(formData)
        db.collection("agenda").doc(id).update(agendaData).then(() => {
            if (formData.cambio_domicilio === undefined) {
                db.collection("agenda").doc(id).get()
                    .then((doc) => {
                        const solicitud = doc.data()
                        const data = {
                            id_agenda: id,
                            numeroContrato: solicitud.numContrato,
                            numContrato: solicitud.numContrato,
                            ...solicitud
                        }
                        if (agendaData.estado === 3) {
                            Swal.fire({ icon: "success", text: "Se ha reagendo la instalacion con exito" })
                            navigate("/planeacion/agenda/1")
                        } else {
                            //Enviamos el correo de confirmacion

                            const enviarCorreo = functions.httpsCallable('enviarContrato');
                            enviarCorreo(data)
                                .then(res => {
                                    console.log('Se envio correo')
                                })
                                .catch(error => console.log(error))

                            //Mikrowips
                            if (data.cambio_domicilio === undefined) {
                                const dataUser = {
                                    nombre: data.nombres,
                                    cedula: data.cedula,
                                    linea: data.linea,
                                    correo: data.correo,
                                    telefono: data.telefono,
                                    movil: data.celular,
                                    empleado: data.empleado,
                                    terceraEdad: data.terceraEdad,
                                    paramsDiscapacidad: data.paramsDiscapacidad,
                                    direccion_principal: data.direccion,
                                    canton: data.canton,
                                    sector: data.sector,
                                    centro_costo: data.centro_costo,
                                }
                                //Creacion del cliente en SOEI
                                const newUser = functions.httpsCallable('newUser');
                                newUser(dataUser)
                                    .then(res => {
                                        const dataRes = res.data
                                        const coordenadas = ``
                                        const pppuser = ``
                                        const ppppass = ``
                                        const instalado = ''
                                        let descripcionT = solicitud.planNombre
                                        if (solicitud.paramsDiscapacidad && solicitud.paramsDiscapacidad.valor) {
                                            descripcionT = `${solicitud.planNombre} + ${solicitud.paramsDiscapacidad.descuento} INSTALACION`
                                        }
                                        let promo_santa_elena = data.provincia == 'SANTA ELENA' && data.debito == 1

                                        db.collection("agenda").doc(id).update({
                                            user_id_microwisp: dataRes.idcliente,
                                            promo_santa_elena: promo_santa_elena,
                                        })
                                        var linea = data.linea[1]
                                        let numeroConCeros = data.cedula.padStart(13, "0")
                                        var codigo_pago = `01${numeroConCeros}0${linea}`
                                        console.log(codigo_pago, ' codigo de pago')
                                        //Agregar agenda
                                        var data_codigo_pago = {
                                            codigo_pago: codigo_pago,
                                            REFERENCIA: data.direccion,
                                            NUM_CONTRATO: data.numContrato,
                                            AGENTE_VENDEDOR: data.vendedor,
                                            id: dataRes.idcliente
                                        }
                                        //console.log(data_codigo_pago, 'codigo pago')
                                        const updateCodigoPago = functions.httpsCallable('updateCodigoPago');
                                        updateCodigoPago(data_codigo_pago)
                                            .then(res => {
                                                console.log('Se actualizo codigo de pago')
                                            })
                                            .catch(error => console.log(error))
                                        //
                                        const dataService = {
                                            ...dataUser,
                                            name: 'SEBAS',
                                            provincia: solicitud.provincia,
                                            debito: solicitud.debito,
                                            promo_santa_elena: promo_santa_elena,
                                            idcliente: dataRes.idcliente,
                                            idperfil: solicitud.planId,
                                            nodo: '14',
                                            planNombre: solicitud.planNombre,
                                            descripcion: descripcionT,
                                            costo: solicitud.planValor,
                                            ipap: '',
                                            mac: '0',
                                            ip: '',
                                            instalado: instalado,
                                            antena: '0',
                                            pppuser: pppuser,
                                            ppppass: ppppass,
                                            emisor: '0',
                                            user_ubnt: '0',
                                            pass_ubnt: '0',
                                            tiposervicio: 'internet',
                                            status_user: 'OFFLINE',
                                            tipoipv4: '0',
                                            redipv4: '385',
                                            coordenadas: coordenadas,
                                            direccion: data.direccion,
                                            snmp_comunidad: '0',
                                            firewall_state: '0',
                                            smartolt: '0',
                                            limitado: '0',
                                            ppp_routes: '0',
                                            ppp_localaddress: '0',
                                            idnap: '0',
                                            portnap: '0',
                                            onu_sn: '0',
                                            onu_ssid_wifi: '0',
                                            onu_password_wifi: '0',
                                            personalizados: '0',
                                            smartolt_catv: '0',
                                            ipv6: '',
                                            sector: solicitud.sector,
                                            rechazoVenta: solicitud.rechazoVenta ? solicitud.rechazoVenta : ''
                                        }
                                        console.log(dataService, ' data service')
                                        const createService = functions.httpsCallable('createService')
                                        createService(dataService)
                                            .then(res => {
                                                //console.log('Se creo el servicio', res)
                                                
                                                console.log('Se creo servicio')
                                                //console.log(dataService, 'data service')
                                                const createInvoice = functions.httpsCallable('createInvoice')
                                                createInvoice(dataService)
                                                    .then(res => {
                                                        if (res.data.estado == 'error') {
                                                            const authUser = auth.currentUser
                                                            db.collection("logs").doc(`createInvoice  ${new Date()}`).set({
                                                                estado: 2,
                                                                departamento: 'PLANEACION',
                                                                function: 'createInvoice',
                                                                detalle: `id_cliente: ${dataService.idcliente} Response: 408 Request Timeout`,
                                                                usuario: authUser.displayName,
                                                                created: firebase.firestore.FieldValue.serverTimestamp(),
                                                            })
                                                            //
                                                            const deleteClient = functions.httpsCallable('deleteClient')
                                                            deleteClient(dataService.idcliente)
                                                                .then(res => {
                                                                    console.log('Si se puedo eliminar cliente', dataService.idcliente)
                                                                }).catch(error => alert('Error 04 - SOEI No pudo eliminar el cliente en mikro, contacte con soporte. ' + dataService.idcliente))

                                                            db.collection("agenda").doc(`${id}`).update({
                                                                estado: 0
                                                            })
                                                            return alert('Error 03 - Se presenta desconexion del sistema mikrowisp al momento no puedes continuar con la ejecucion del proceso normal, esta cliente se reversara al estado anterior por favor comunicate con soporte. id_cliente:' + dataService.idcliente)
                                                        }

                                                        //dispatch({ type: 'AGENDA_CREATE_SUCCESS' })

                                                        //navigate("/planeacion/agenda/1")
                                                        const factura = res.data.idfactura

                                                        //console.log('Se creo la factura', res)
                                                        console.log(id, ' id')
                                                        db.collection("tecnicos").doc(`${id}`).update({
                                                            id_factura: factura,
                                                            idcliente: dataService.idcliente,
                                                            promo_santa_elena: promo_santa_elena,
                                                        })

                                                        db.collection("agenda").doc(`${id}`).update({
                                                            id_factura: factura,
                                                            idcliente: dataService.idcliente,
                                                            promo_santa_elena: promo_santa_elena,
                                                        })
                                                        Swal.fire({ icon: "success", text: "Se ha agendo la instalacion con exito", });
                                                    })
                                            }).catch(error => {

                                                const authUser = auth.currentUser
                                                db.collection("logs").doc(`createService  ${new Date()}`).set({
                                                    estado: 2,
                                                    departamento: 'PLANEACION',
                                                    detalle: error,
                                                    function: 'createService',
                                                    usuario: authUser.displayName,
                                                    created: firebase.firestore.FieldValue.serverTimestamp(),
                                                })
                                                db.collection("agenda").doc(`${id}`).update({
                                                    estado: 0
                                                })
                                                alert('Error 02 - Se presenta desconexion del sistema mikrowisp al momento de crear el servicio', error)
                                            })

                                    }).catch(error => {
                                        const authUser = auth.currentUser
                                        db.collection("logs").doc(`createClient  ${new Date()}`).set({
                                            estado: 2,
                                            departamento: 'PLANEACION',
                                            detalle: error,
                                            function: 'createClient',
                                            usuario: authUser.displayName,
                                            created: firebase.firestore.FieldValue.serverTimestamp(),
                                        })
                                        db.collection("agenda").doc(`${id}`).update({
                                            estado: 0
                                        })
                                        alert('Error 01 - Se presenta desconexion del sistema mikrowisp al momento de crear el cliente.', error)
                                    })
                                //Creacion del cliente en SOEI
                                if (data.nombres.includes('DESARROLLO')) {
                                    const endpoint = 'https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net'
                                    const data1 = {
                                        ...data,
                                        linea: data.linea[1]
                                    }
                                    console.log(data1, ' data')
                                    const newUser2 = functions.httpsCallable('newUser2')
                                    newUser2(data1)
                                        .then(res => {
                                            const idcliente = res.data
                                            console.log(idcliente, ' Se creo usuario en soei')
                                            const cliente = {
                                                ...data,
                                                idcliente: idcliente,
                                            }
                                            //Crear servicio jerson
                                            const createService2 = functions.httpsCallable('createService2')
                                            createService2(data)
                                                .then(res => {
                                                    console.log('Se creo servicio Jerson')
                                                })
                                                .catch(e => {
                                                    console.log(e, ' error jerson')
                                                })
                                            var myHeaders = new Headers()
                                            myHeaders.append("Content-Type", "application/json")
                                            var raw = JSON.stringify({
                                                "idcliente": idcliente,
                                                "idperfil": data.planId,
                                                "descripcion": "",
                                                "costo": "",
                                                "tiposervicio": "",
                                                "instalado": "",
                                                "direccion": ""
                                            })
                                            console.log(data.planId, ' plan id')
                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: raw
                                            }
                                            

                                            fetch(`${endpoint}/api/createService`, requestOptions)
                                                .then(response => response.text())
                                                .then(result => {
                                                    const data = JSON.parse(result)
                                                    db.collection('clientes').doc(`${idcliente}`).update({
                                                        idservicio: data.idservicio
                                                    })
                                                    db.collection('agenda').doc(`${id}`).update({
                                                        idservicio: data.idservicio
                                                    })
                                                })
                                                .catch(error => console.log('error', error))
                                            //Crear Servicios Recurrentes
                                            var raw2 = JSON.stringify({
                                                "idcliente": idcliente,
                                                "zona": data.zona
                                            })
                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: raw2
                                            }
                                            fetch(`${endpoint}/api/createRecurringServices`, requestOptions)
                                                .then(response => response.text())
                                                .then(result => {
                                                    console.log(result)
                                                })
                                                .catch(error => console.log('error', error))

                                            const createInvoice2 = functions.httpsCallable('createInvoice2')
                                            createInvoice2(cliente)
                                                .then(res => {
                                                    const dataRes = res.data
                                                    console.log(dataRes, ' Se creo factura en soei')
                                                }).catch(error => {
                                                    alert(`Error Soei Creacion de Factura`, error)
                                                })
                                        }).catch(error => {
                                            alert(`Error Soei Creacion de Usuario`, error)
                                        })
                                }
                            }
                        }
                    })
            }
        })

    } catch (error) {
        Swal.fire({ icon: "info", text: error.message, });
        dispatch({
            type: 'AGENDA_CREATE_FAIL',
            payload: error
        })
    }
}