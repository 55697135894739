import { TextField, Button, Box, Grid, Chip } from "@mui/material";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
export const ViewFactibilitySale = ({ props }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
  }, []);
  return (
    <>
      <Tooltip title="Visualizar">
        <Button onClick={() => setOpen(true)}>
          <VisibilityIcon />
        </Button>
      </Tooltip>
      <Dialog open={open} fullWidth fullScreen maxWidth="md">
        <DialogTitle>
          {props.uid}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Chip size='small' label="APROBADO" color="success" />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

              <Box py={2}>
                <TextField fullWidth label="NUMERO DE SOLICITUD" value={props.uid} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="NOMBRES" value={props.nombres} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="LINEA" value={props.linea} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="PLAN" value={props.planNombre} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="CEDULA" value={props.cedula} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="DIRECCION" value={props.direccion} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="CORREO" value={props.correo} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box py={2}>
                <TextField fullWidth label="CELULAR" value={props.celular} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="TELEFONO" value={props.telefono} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="CAJA" value={props.caja} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="OBSERVACIONES" value={props.observacion} />
              </Box>
              <Box py={2}>
                <TextField fullWidth label="OBSERVACIONES" value={props.observacion2} />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button component={RouterLink}
            disableElevation
            style={{ color: 'white' }}
            to={`/ventas/nueva-venta/${props.id}`} variant="contained">
            Generar Pre Venta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
