export const cajasSectorReducers = (state = { cajas: [] }, action) => {
    switch (action.type) {
        case 'CAJAS_SECTOR_LIST_REQUEST':
            return { loading: true }
        case 'CAJAS_SECTOR_LIST_SUCCESS':
            return { loading: false, cajas: action.payload }
        case 'CAJAS_SECTOR_LIST_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}