import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Grid,
    Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { db } from "../../../../firebase";
import { listsSubCategorias } from "../../../../redux/actions/parametersActions";

export const UpdateAsuntoTicket = ({ props }) => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listsSubCategorias())

    }, [dispatch]);

    const [open, setOpen] = useState(null);

    const onData = () => {
        setOpen(true)
    }

    const [nombreCategoriaProducto, setNombreNombreCategoriaProducto] = useState(props.asunto);

    const [valorACobrar, setValorACobrar] = useState(props.valor_a_cobrar)

    const updateProducto = async () => {
        await db.collection('tickets_parametros').doc('asuntos').collection('list').doc(props.id).update({
            asunto: nombreCategoriaProducto,
            valor_a_cobrar: valorACobrar
        }).then(() => {

            Swal.fire({ icon: "success", text: "Se ha actualizado el asunto de ticket con exito", });
        })
            .catch((error) => { console.error("Error adding document: ", error); });
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Actualizar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="md">
                <DialogTitle>EDICION DE ASUNTO: <strong> {props.asunto}</strong></DialogTitle>
                <DialogContent>

                    <Box py={1} px={1}>
                        <Paper elevation={3}>
                            <Box py={2} px={2}>
                                <FormControl fullWidth>

                                    <Grid container spacing={5}>

                                        <Grid item xs={6}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ASUNTO DEL TICKET:</strong></p>
                                            <TextField
                                                fullWidth
                                                label="ASUNTO"
                                                value={nombreCategoriaProducto}
                                                onChange={(e) => setNombreNombreCategoriaProducto(e.target.value)}
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ASIGNAR UN VALOR A COBRAR:</strong></p>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                type="number"
                                                value={valorACobrar}
                                                onChange={(e) => setValorACobrar(e.target.value)}
                                                name="valorACobrar"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={6}>
                                        <Box py={1}>
                                            <TextField
                                                fullWidth
                                                label="ASUNTO"
                                                value={nombreCategoriaProducto}
                                                onChange={(e) => setNombreNombreCategoriaProducto(e.target.value)}
                                            />
                                        </Box>
                                    </Grid> */}
                                </FormControl>
                            </Box>
                        </Paper>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} variant="outlined" size="small" color="primary"><strong>Cancelar</strong></Button>
                    <Button onClick={() => {
                        updateProducto()
                    }} variant="contained"
                        size="small"
                        color="secondary"
                    >
                        ACTUALIZAR
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};