import React, { useState } from 'react'
import { db } from '../../../firebase'

export const EgresoFlotas = () => {

  const [data, setData] = useState('')

  const getFlotas = async () => {
    const docRef = await db.collection("flotas")

    docRef.onSnapshot(snapshot => {
      const data = [
        ...snapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          }
        })
      ];
      setData(data)
      console.log(data)
    })
  }

  return (
    {/* <h1>EgresoFlotas</h1> */}
  )
}