import { Button, Typography, Card, Box } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { DescargarPdf } from '../inventario/reporte_de_movimientos/DescargarPdf';
import { DescargarPdfDespacho } from './DescargarPdfDespacho';

export const PantallaPdf = () => {
    const params = useParams()
    let navigate = useNavigate()

    const [data, setData] = useState([]);

    useEffect(() => {
        getIdDespacho();
    }, []);

    const getIdDespacho = async () => {

        console.log('params', params)
        console.log('params.id', params.id)

        let ref = db.collection('reporte_movimientos').where('relacion', '==', params.id).where('motivo_movimiento', '==', 'EGRESO').limit(1);

        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];

            setData(data);
        });

        console.log('data use effect', data)

    }

    return (
        <>
            <Box px={2}>
                <Typography variant="h3" gutterBottom mb={0}><strong>PDF</strong></Typography>
            </Box>
            <Box py={2} px={2}>
                <Card elevation={2}>
                    <Box py={2} px={2}>
                        <p style={{ marginBottom: '0px' }}>NUMERO DE SOLICITUD REALIZADA</p>
                        <Typography><strong>{params.id}</strong></Typography>
                        <Box py={2}>
                            <p style={{ marginBottom: '0px' }}>NUMERO DE MOVIMIENTO</p>
                            <Typography><strong>{data.length > 0 ? data[0].id : null}</strong></Typography>
                        </Box>
                        {/* <Button onClick={() => {

                console.log('params', params)
                console.log('params.id', params.id)

                console.log('data', data)
                console.log('data[0].id', data[0].id)

            }
            }>Get id despacho</Button> */}
                        {/* <Button onClick={() => getIdDespacho()}>Get id despacho</Button> */}
                        {/* <DescargarPdfDespacho data={data[0]} id={`${data[0].id}`} /> */}
                        <DescargarPdfDespacho data={data.length > 0 ? data[0] : null} id={data.length > 0 ? data[0].id : null} />
                    </Box>
                </Card>
            </Box>
        </>
    )
}