import { TextField, Box, Button, Tooltip, Dialog, DialogTitle, CardActions, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import Loader from '../../components/utils/Loader'
import RoomIcon from "@mui/icons-material/Room";
export const GeolocationGoogle = ({ position, setPosition }) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    function success(pos) {
      let crd = pos.coords;

      setPosition({
        lat: crd.latitude,
        lng: crd.longitude,
      })
    };
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  }, [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBjtUvCBhC9tio12UcIa4HBT8hPqUBjYl0"
  })
  const containerStyle = {
    width: '100%',
    height: '500px'
  };
  const onLoad = marker => {
  
  }
  const setClickedLatLng = (e) => {
 

    setPosition({
      lat: e.lat,
      lng: e.lng,
    })
  }
    
  return (
    <>
      <Tooltip title="Seleccionar ubicacion manual">
        <Button onClick={() => setOpen(!open)}>
          <RoomIcon />
        </Button>
      </Tooltip>
      <Dialog fullScreen maxWidth="lg" fullWidth onClose={() => setOpen(false)} open={open}>
        <DialogTitle>Seleccionar Ubicacion</DialogTitle>
        {position.lat ? <>
          <DialogContent dividers>
          <Box display="flex">
                <TextField
                  label="LATITUD"
                  onChange={(e) => setPosition({ ...position, lat: parseFloat(e.target.value) })}
                  value={position.lat}
                />
              <TextField
                label="LONGITUD"
                onChange={(e) => setPosition({ ...position, lng: parseFloat(e.target.value) })}
                value={position.lng}
              />
            </Box>
        
             </DialogContent>
             {
            isLoaded && <GoogleMap
              mapContainerStyle={containerStyle}
              center={position}
              clickableIcons={false}
              zoom={20}
              zoomOnClick={false}
              onClick={e => setClickedLatLng(e.latLng.toJSON())}
            >
              <Marker
                onLoad={onLoad}
                position={position} />
            </GoogleMap>
          }
          <CardActions>
          <Button variant='outlined' onClick={() => setOpen(false)}>Seleccionar Ubicacion</Button>
          </CardActions>
        </> :
          <Loader />
        }
      </Dialog>

    </>
  )
}