import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Button} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
/* import InputValidation from "../utils/InputValidation"; */
import InputValidation from "../../../components/utils/InputValidation";
import { db, firebase } from "../../../firebase";

export const NewMotivoEgresoProducto = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [motivoEgresoProducto, setMotivoEgresoProducto] = useState({ campo: '', valido: true })

    const dispatch = useDispatch()
    useEffect(() => {
    }, [dispatch]);

    const onHandleClick = async () => {
        console.log('Todo correcto');
    };

    const createNewMotivoEgresoProducto = async (MotivoEgresoProducto) => {
        await db.collection('motivos_egreso_productos').doc().set({
            nombre_motivo_egreso_producto: MotivoEgresoProducto,
            
            fecha_motivo_egreso_producto: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
            Swal.fire({ icon: "success", title: "Nuevo Motivo creado con exito" });
        })
        .catch((error) => {
            Swal.fire({ icon: "error", title: error });
        });
    }

    return (
        <>
            <br />

            <InputValidation
                fullWidth
                estado={motivoEgresoProducto}
                cambiarEstado={setMotivoEgresoProducto}
                label="MOTIVO EGRESO PRODUCTO"
                name="motivoEgresoProducto"
            />

            <br />
            <Button
                onClick={() => createNewMotivoEgresoProducto(motivoEgresoProducto.campo.toUpperCase())}
                fullWidth
                variant="contained"
                disableElevation
                disabled={
                    !motivoEgresoProducto.campo}
            >
                {" "}
                CREAR NUEVO MOTIVO{" "}
            </Button>
        </>
    );
};
