export const agendaCreateReducers = (state = { createAgenda: [] }, action) => {
    switch (action.type) {
        case 'AGENDA_CREATE_REQUEST':
            return { loading: true }
        case 'AGENDA_CREATE_SUCCESS':
            return { loading: false, createAgenda: action.payload }
        case 'AGENDA_CREATE_FAIL':
            return { loading: false, error: action.payload }
        default:
            return state
    }
}