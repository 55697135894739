import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Box, Button, TextField, Paper, Chip, Card, CardContent, Typography } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TvIcon from '@mui/icons-material/Tv';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import InputValidation from "../../../components/utils/InputValidation";
import { Herramientas } from "./Herramientas";
import { useDispatch, useSelector } from 'react-redux'
import { listConfigPlantilla } from "../../../redux/clientes/clientesActions"
export const ClientesResumen = () => {
    const clientDetails = useSelector((state) => state.clientDetails)
    const { loading, client_details } = clientDetails
    const params = useParams()

    const userAuth = useSelector((state) => state.userAuth);
    const [data, setData] = useState();
    const [id, setId] = useState(client_details.uid)
    const [cedula, setCedula] = useState({ campo: client_details.cedula, valido: true })
    const [cliente, setCliente] = useState('')
    const [direccion, setDireccion] = useState({ campo: client_details.direccion, valido: true })
    const [nombres, setNombres] = useState({ campo: client_details.nombres, valido: true })
    const [telefono, setTelefono] = useState(client_details.telefono)
    const [celular, setCelular] = useState(client_details.celular)
    const [email, setEmail] = useState(client_details.correo)
    const [coordenadas, setCoordenadas] = useState('')
    const [zona, setZona] = useState(client_details.zona)
    const [contrato, setContrato] = useState({ campo: "", valido: true })
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 518400000)
    )
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    )
    const [noContrato, setNoContrato] = useState({ campo: client_details.numContrato, valido: true })
    const [idClienteSoei, setIdClienteSoei] = useState('')
    const [idClienteMk, setIdClienteMk] = useState('')
    const [whatsapp, setWhatsapp] = useState(client_details.whatsapp)
    const [noReferencia, setNoReferencia] = useState(client_details.numReferencia)
    const [estado, setEstado] = useState('')
    const [cuentaBancaria, setCuentaBancaria] = useState(client_details.cuentabancaria)
    const [provincia, setProvincia] = useState(client_details.provincia)
    const [banco, setBanco] = useState(client_details.banco)
    const [canton, setCanton] = useState(client_details.canton)
    const [tipoCuentaBancaria, setTipoCuentaBancaria] = useState(client_details.tipobanco)
    const [sector, setSector] = useState(client_details.sector)
    const [fechaNacimiento, setFechaNacimiento] = useState(client_details.fechanacimiento)

    const [cuentaConDebitoBancario, setCuentaConDebitoBancario] = useState(client_details.accededebitobancario)

    const [parroquia, setParroquia] = useState(client_details.parroquia)

    const [codigoDePago, setCodigoDePago] = useState(client_details.codigopago)

    const [porcentajeDeDiscapacidad, setPorcentajeDeDiscapacidad] = useState(client_details.gradodiscapacidad)

    const [esDiscapacitado, setEsDiscapacitado] = useState('')

    const [vendedor, setVendedor] = useState(client_details.vendedor)

    const [viewMore, setViewMore] = useState(false)

    const [codigoPago, setCodigoPago] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(listConfigPlantilla())
    }, [dispatch])
    const LoadData = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`http://192.168.90.24:3000/obtenerClienteByCedula/${params.cedula}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                console.log(re, 'FRESPUESTA API')
                console.log(params.idcliente, 'idcliente')
                //const dataF = re[0]
                var p = re.filter((item) => item.idcliente == params.idcliente)
                console.log(p, 'p')
                p = p[0]
                console.log(p, 'data a')
                setData(p)
                setId(p.idcliente)
                setCedula(p.cedula)
                setCliente(p.nombres)
                setDireccion(p.direccion)
                setCodigoPago(p.codigopago)
                setTelefono(p.telefono)
                setCelular(p.celular)
                setEmail(p.email)
                setCoordenadas(p.coordenadas)
                setZona(p.zona)
                setContrato(p.numcontrato)
                setNoContrato(p.numcontrato)
                setFechaNacimiento(p.numcontrato)
                setCodigoDePago(p.codigopago)

                setBanco(p.banco)
                setTipoCuentaBancaria(p.tipobanco)
                setWhatsapp(p.whatapp)
                setProvincia(p.provincia)
                setParroquia(p.parroquia)
                setCanton(p.canton)
                setSector(p.sector)
                setIdClienteSoei(p.idcliente)
                setNoReferencia(p.numreferencia)
                setCuentaBancaria(p.cuentabancaria)
                setCuentaConDebitoBancario(p.accededebitobancario)
                setPorcentajeDeDiscapacidad(p.gradodiscapacidad)
            })
            .catch(error => console.log('error', error));
    }
    function Estado({ props }) {
        let name;
        let color;
        if (props === 0) {
            name = 'SUSPENDIDO'
            color = 'warning'
        } else if (props === 1) {
            name = 'ACTIVO'
            color = 'success'
        }
        return (
            <Chip variant='outlined' label={name} color={color} />
        )
    }
    return (
        <>
            <Card variant='outlined'>
                <CardContent>
                    <Herramientas />
                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <Box py={3}>
                                <Estado props={client_details.estado} />
                            </Box>
                            <InputValidation
                                estado={noContrato}
                                cambiarEstado={setNoContrato}
                                label="NO CONTRATO"
                                name="numContrato"
                            />
                            <InputValidation
                                estado={cedula}
                                cambiarEstado={setCedula}
                                label="CEDULA"
                                name="cedula"
                            />
                            <InputValidation
                                estado={nombres}
                                cambiarEstado={setNombres}
                                label="NOMBRES"
                                name="cedula"
                            />
                            <InputValidation
                                estado={direccion}
                                cambiarEstado={setDireccion}
                                label="DIRECCION"
                                name="cedula"
                            />
                            {
                                viewMore === true ? <>
                                    {/* CANTON */}

                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>CANTON:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={canton}
                                                onChange={(e) => setCanton(e.target.value)}
                                                name="canton"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* SECTOR */}

                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>SECTOR:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={sector}
                                                    onChange={(e) => setSector(e.target.value)}
                                                    name="sector"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* ZONA */}

                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>ZONA:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={zona}
                                                onChange={(e) => setZona(e.target.value)}
                                                name="zona"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* PARROQUIA */}

                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>PARROQUIA:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={parroquia}
                                                    onChange={(e) => setParroquia(e.target.value)}
                                                    name="parroquia"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* COORDENADAS */}

                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>COORDENADAS:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={coordenadas}
                                                onChange={(e) => setCoordenadas(e.target.value)}
                                                name="coordenadas"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* ES DISCAPACITADO */}

                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>ES DISCAPACITADO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={esDiscapacitado}
                                                    onChange={(e) => setEsDiscapacitado(e.target.value)}
                                                    name="esDiscapacitado"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* VENDEDOR */}

                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>VENDEDOR:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={vendedor}
                                                onChange={(e) => setVendedor(e.target.value)}
                                                name="vendedor"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </> : ''
                            }

                        </Grid>
                        <Grid item xs={4}>
                            <Box py={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>TELEFONO:</p>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={telefono}
                                            onChange={(e) => setTelefono(e.target.value)}
                                            name="telefono"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={6}>
                                <Grid item xs={2}>
                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>CELULAR:</p>
                                </Grid>
                                <Grid item md={7}>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={celular}
                                        onChange={(e) => setCelular(e.target.value)}
                                        name="celular"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>

                            {/* WHATSAPP */}

                            <Box py={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>WHATSAPP:</p>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={whatsapp}
                                            onChange={(e) => setWhatsapp(e.target.value)}
                                            name="whatsapp"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>

                            {/* NO REFERENCIA */}

                            <Grid container spacing={6}>
                                <Grid item xs={2}>
                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>NO REFERENCIA:</p>
                                </Grid>
                                <Grid item md={7}>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={noReferencia}
                                        onChange={(e) => setNoReferencia(e.target.value)}
                                        name="noReferencia"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box py={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CORREO ELECTRONICO:</p>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            name="email"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={6}>
                                <Grid item xs={2}>
                                    <p style={{ fontSize: "10px", marginBottom: "10px" }}>PROVINCIA:</p>
                                </Grid>
                                <Grid item md={7}>
                                    <TextField sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                        value={provincia}
                                        onChange={(e) => setProvincia(e.target.value)}
                                        name="provincia"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Box py={3}>
                                <Grid container spacing={6}>
                                    <Grid item xs={2}>
                                        <p style={{ fontSize: "10px", marginBottom: "10px" }}>CUENTA BANCARIA:</p>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                            value={cuentaBancaria}
                                            onChange={(e) => setCuentaBancaria(e.target.value)}
                                            name="cuentaBancaria"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            {
                                viewMore === true ? <>
                                    < Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>BANCO:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={banco}
                                                onChange={(e) => setBanco(e.target.value)}
                                                name="banco"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>TIPO CUENTA BANCARIA:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={tipoCuentaBancaria}
                                                    onChange={(e) => setTipoCuentaBancaria(e.target.value)}
                                                    name="tipoCuentaBancaria"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>FECHA NACIMIENTO:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={fechaNacimiento}
                                                onChange={(e) => setFechaNacimiento(e.target.value)}
                                                name="fechaNacimiento"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>CUENTA CON DEBITO BANCARIO:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={cuentaConDebitoBancario}
                                                    onChange={(e) => setCuentaConDebitoBancario(e.target.value)}
                                                    name="cuentaConDebitoBancario"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "10px", marginBottom: "10px" }}>CODIGO DE PAGO:</p>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={codigoDePago}
                                                onChange={(e) => setCodigoDePago(e.target.value)}
                                                name="codigoDePago"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box py={3}>
                                        <Grid container spacing={6}>
                                            <Grid item xs={2}>
                                                <p style={{ fontSize: "10px", marginBottom: "10px" }}>PORCENTAJE DISCAPACIDAD:</p>
                                            </Grid>
                                            <Grid item md={7}>
                                                <TextField sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    value={porcentajeDeDiscapacidad}
                                                    onChange={(e) => setPorcentajeDeDiscapacidad(e.target.value)}
                                                    name="porcentajeDeDiscapacidad"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </> : ''
                            }
                        </Grid>
                    </Grid>
                    <Box pt={2}>
                        {
                            viewMore === false ? <Button size="small" onClick={() => setViewMore(true)} color="secondary" variant="contained">VER MAS</Button> : <Button size="small" onClick={() => setViewMore(false)} color="secondary" variant="contained">VER MENOS</Button>
                        }
                    </Box>
                    <Box pt={2} pb={2}>
                        <p style={{ fontSize: "12px", marginBottom: "10px", fontWeight: "bold" }}>RESUMEN NOTIFICACIONES</p>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Card>

                            </Card>
                            <Card elevation={3}>
                                <Box py={1} px={1} sx={{ bgcolor: '#2296F3' }}>
                                    <CalendarTodayIcon sx={{ color: "white" }} />
                                    <Box py={1}>
                                        <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Dia de pago</p>
                                    </Box>
                                    <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>06/02/2023</p>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card elevation={3}>
                                <Box py={1} px={1} sx={{ bgcolor: '#1A6EB9' }}>
                                    <CreditCardIcon sx={{ color: "white" }} />
                                    <Box py={1}>
                                        <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Deuda actual</p>
                                    </Box>
                                    <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>$0.00</p>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={4}>
                            <Card elevation={3}>
                                <Box py={1} px={1} sx={{ bgcolor: '#E25A5A' }}>
                                    <ErrorOutlineIcon sx={{ color: "white" }} />
                                    <Box py={1}>
                                        <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Proximo corte de servicios</p>
                                    </Box>
                                    <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>06/02/2023</p>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                    <Box pt={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Card elevation={3}>
                                    <Box py={1} px={1} sx={{ bgcolor: '#0AAB96' }}>
                                        <TvIcon sx={{ color: "white" }} />
                                        <Box py={1}>
                                            <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Aviso en pantalla</p>
                                        </Box>
                                        <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>Desactivado</p>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card elevation={3}>
                                    <Box py={1} px={1} sx={{ bgcolor: '#F3A122' }}>
                                        <ForwardToInboxIcon sx={{ color: "white" }} />
                                        <Box py={1}>
                                            <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Crear y enviar factura</p>
                                        </Box>
                                        <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>27/01/2023</p>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card elevation={3}>
                                    <Box py={1} px={1} sx={{ bgcolor: '#624EB1' }}>
                                        <QuestionAnswerOutlinedIcon sx={{ color: "white" }} />
                                        <Box py={1}>
                                            <p style={{ fontSize: "12px", marginBottom: "0px", fontWeight: "bold", color: "white" }}>Aviso sms</p>
                                        </Box>
                                        <p style={{ fontSize: "11px", marginBottom: "0px", color: "white" }}>29/01/2023</p>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pt={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <Card sx={{ bgcolor: '#CE55A8' }}>
                                    <CardContent>
                                        <PaidOutlinedIcon sx={{ color: "white" }} />
                                        <Typography sx={{ color: "white" }}>Saldos</Typography>
                                        <Typography sx={{ color: "white" }}>$0.00</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}
