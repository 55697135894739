import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
    Box,
    Chip,
} from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    esES
} from '@mui/x-data-grid';

import { ChangeStatePuerto } from "./ChangeStatePuerto";

export const VerSplitterButton = ({ props }) => {

    const Estado = ({ estado }) => {
        let name;
        let color;
        //Controlamos el estado de la factiblidad
        if (estado === 'ACTIVO') {
            name = 'ACTIVO'
            color = 'success'
        } else if (estado === 'SUSPENDIDO') {
            name = 'SUSPENDIDO'
            color = 'warning'
        } else {
            name = ''
            color = 'primary'
        }
        return (
            <Chip label={name} color={color} size="small"/>
        )
    }

    const [open, setOpen] = useState(null);


    const [cedulaCliente, setCedulaCliente] = useState('');

    const [listSplitters, setListSplitters] = useState([]);

    const [total, setTotal] = useState(0);

    const onData = () => {
        setOpen(true)
    }

    const getListSplitters = () => {

        const datosString = props.splitter;

        const [inicio, fin] = datosString.split(":").map(Number);

        db.collection("cajas")
            .doc(props.id)
            .collection("puertos")
            .get()
            .then(async (querySnapshot) => {
                if (querySnapshot.size === 0) {
                    for (let i = inicio; i <= fin; i++) {
                        try {
                            await db
                                .collection("cajas")
                                .doc(props.id)
                                .collection("puertos")
                                .doc(`puerto-${i.toString()}`)
                                .set({
                                    puerto: i,
                                    usado: 0,
                                    id_puerto: `puerto-${i.toString()}`
                                });
                            console.log(`Dato ${i} agregado correctamente`);
                        } catch (error) {
                            console.error(`Error al agregar el dato ${i}: ${error}`);
                        }
                    }
                } else {
                    console.log("La colección no está vacía.");
                }
            });

        setTimeout(function () {
            let ref = db.collection('cajas').doc(props.caja).collection('puertos')

            ref.onSnapshot(snapshot => {
                const data = [
                    ...snapshot.docs.map(doc => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        }
                    })
                ];

                setListSplitters(data)
                setCedulaCliente(data[0].cedula)
                console.log(data)
            })
        }, 2500);
    }

    useEffect(() => {
        getListSplitters()
    }, [])

    // function Estado2({ estado, cedula }) {

    //     console.log('cedula', cedula)

    //     console.log('cedula', cedula)
    //     listSplitters.forEach((obj, index) => {
    //         console.log(`${index} ${obj.cedula}`)

    //         let myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");

    //         let raw = JSON.stringify({
    //             "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
    //             "cedula": `${obj.cedula}`
    //         });

    //         let requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetClientsDetails", requestOptions)
    //             .then(response => response.text())
    //             .then(result => {
    //                 console.log(result)
    //                 const dataParse = JSON.parse(result);
    //                 const data = dataParse;
    //                 console.log('DATA DATOS', data.datos[0].estado)

    //                 let name;
    //                 let color;
    //                 //Controlamos el estado de la factiblidad
    //                 if (estado === 1) {
    //                     name = "OCUPADO";
    //                     color = "error";
    //                 } else if (estado === 0) {
    //                     name = "LIBRE";
    //                     color = "success";
    //                 } else if (data.datos[0].estado === 'SUSPENDIDO') {
    //                     name = "SUSPENDIDO";
    //                     color = "warning";
    //                 }
    //                 return <Chip size="small" label={name} color={color} />;

    //                 /* if (data.datos[0].estado === 'SUSPENDIDO') {
    //                     db.collection('cajas').doc(props.id).collection('puertos').doc(obj.id_puerto).update({
    //                         usado: 2
    //                     })
    //                 } */
    //             })
    //             .catch(error => console.log('error', error));

    //     })
    // }

    const columns = [
        { field: 'id_puerto', headerName: 'ID', width: 170 },
        /* {
            field: 'estado_pagado', headerName: 'ESTADO', width: 140,
            renderCell: (params) => {
                return <Estado2 estado={params.row.usado} cedula={params.row.cedula_usuario} />
            }
        }, */
        { field: 'puerto', headerName: 'PUERTO', width: 170 },
        { field: 'puerto_de_usuario', headerName: 'PUERTO PERTENECE A USUARIO', width: 340 },
        /* { field: 'estado', headerName: 'ESTADO', width: 170 }, */
        {
            field: 'estado', headerName: 'ESTADO', width: 170,
            renderCell: (params) => {
              return params.row.estado === '' || params.row.estado === undefined || params.row.estado === null ? '' : <Estado estado={params.row.estado} />
            }
          },
        { field: 'ultima_fecha_pago', headerName: 'ULTIMO PAGO', width: 170 },
        {
            field: '1', headerName: 'ACCIONES', width: 100,
            renderCell: (params) => {
                return <ChangeStatePuerto props={params.row} idCaja={props.id} />
            }
        },
    ]

    function CustomFooterTotalComponent(props) {
        return `PUERTOS TOTALES: ${total}`
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );

    // const pruebaGCD = () => {
    //     console.log('cedula_usuario', cedulaCliente)
    //     listSplitters.forEach((obj, index) => {
    //         console.log(`${index} ${obj.cedula_usuario}`)

    //         var myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");

    //         var raw = JSON.stringify({
    //             "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
    //             "cedula": `${obj.cedula_usuario}`
    //         });

    //         var requestOptions = {
    //             method: 'POST',
    //             headers: myHeaders,
    //             body: raw,
    //             redirect: 'follow'
    //         };

    //         fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetClientsDetails", requestOptions)
    //             .then(response => response.text())
    //             .then(result => {
    //                 console.log(result)
    //                 const dataParse = JSON.parse(result);
    //                 const data = dataParse;
    //                 console.log('DATA DATOS', data.datos[0].estado)

    //                 if (data.datos[0].estado === 'SUSPENDIDO') {
    //                     db.collection('cajas').doc(props.id).collection('puertos').doc(obj.id_puerto).update({
    //                         usado: 2
    //                     })
    //                 }
    //             })
    //             .catch(error => console.log('error', error));

    //     })
    //     /* var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
    //         "cedula": '0953416260'
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch("https://mikrowisp.intercommerce.com.ec/api/v1/GetClientsDetails", requestOptions)
    //         .then(response => response.text())
    //         .then(result => {
    //             console.log(result)
    //             const dataParse = JSON.parse(result);
    //             const data = dataParse;
    //             setEstadoCaja(data.datos[0].estado)
    //             console.log('DATA DATOS', data.datos[0].estado)
    //         })
    //         .catch(error => console.log('error', error)); */
    // }

    return (
        <>
            <Tooltip title="VER">
                <Button onClick={() => onData()}>
                    <RemoveRedEyeIcon color="primary" />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth onClose={() => setOpen(false)} maxWidth="lg">

                <DialogTitle>
                    CAJA: <strong>{props.caja}</strong> SPLITTER: <strong>{props.splitter}</strong>
                </DialogTitle>

                <DialogContent>

                    <Paper>
                        <Box sx={{ height: 700, width: '100%' }}>
                            <DataGrid
                                rows={listSplitters ? listSplitters : []}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                density="compact"
                                pagination={true}
                                // disableColumnSelector
                                // disableDensitySelector
                                //hideFooterPagination
                                columns={columns}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent,
                                }}
                                onStateChange={(state) => {
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </Paper>

                </DialogContent>

                <DialogActions>
                    <Button size="small" color="secondary" variant="contained" onClick={() => setOpen(false)}><strong>Regresar</strong></Button>
                    {/* <Button size="small" color="success" variant="contained" onClick={() => pruebaGCD()}><strong>PRUEBA GCD</strong></Button> */}
                </DialogActions>
            </Dialog>
        </>
    );
};