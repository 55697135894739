import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Stack, CircularProgress, IconButton, Box, Button, DialogActions, DialogTitle, Typography, FormControl, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { db, firebase, storage } from '../../firebase'
const VerItemsMarketing = ({ props }) => {
    const useStorage = storage;
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [Imagen, setImagen] = useState('');
    const [viewImagen, setViewImagen] = useState(null)
    const [file, setFile] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    
    useEffect(() => {
    }, [props]);
    const onData = () => {
        setOpen(true)
        setData(props)
    }
    const onSubmit = async () => {
        let imagenUrl = '';
        let archivoUrl = '';
        setLoading(true)
        //
        if (viewImagen) {
            const newRef = useStorage.ref('requerimientos_marketing').child(data.id).child('IMAGEN_REQUERIMIENTO_TERMINADO')
            await newRef.put(Imagen);
            const urlImagen = await newRef.getDownloadURL()
            imagenUrl = urlImagen;
            console.log('la url de la imagen es' + urlImagen);
        }

        if (archivo) {
            const newRef2 = useStorage.ref('requerimientos_marketing').child(data.id).child('REQUERIMIENTO_' + file)
            await newRef2.put(archivo);
            const urlArchivo = await newRef2.getDownloadURL()
            archivoUrl = urlArchivo;
            console.log('la url del archivo es' + urlArchivo);
        }
        //
        await db.collection('requerimientos_marketing').doc(data.id).update({
            estado: 2,
            imagen_requerimiento: imagenUrl,
            archivo_requerimiento: archivoUrl,
            nombreArchivo_requerimiento: 'REQUERIMIENTO_' + file,
        }).then((docRef) => {
            setOpen(false)
            Swal.fire({ icon: "success", text: 'Se aprobo su solicitud' });
            setLoading(false)
        }).catch((error) => {
            Swal.fire({ icon: "error", text: error, });
            setOpen(false)
            setLoading(false)
            console.error("Error adding document: ", error);
        });
    }
    //
    const onNega = () => {
        setLoading(true)
        db.collection('requerimientos_marketing').doc(data.id).update({
            estado: 1,
            fecha_negacion: firebase.firestore.FieldValue.serverTimestamp(),
            imagen_requerimiento: null,
            archivo_requerimiento: null,
            motivo_rechazo: descripcion,
            nombreArchivo_requerimiento: null,
        }).then((docRef) => {
            setOpen(false)
            Swal.fire({ icon: "error", text: 'Se nego su solución' });
            setLoading(false)
        }).catch((error) => {
            Swal.fire({ icon: "error", text: error, });
            setOpen(false)
            setLoading(false)
            console.error("Error adding document: ", error);
        });
    }
    const aprobar = () => {
        setLoading(true)
        db.collection('requerimientos_marketing').doc(data.id).update({
            estado: 3,
            motivo_rechazo: '',
            fecha_aprobacion: firebase.firestore.FieldValue.serverTimestamp(),
        }).then((docRef) => {
            setOpen(false)
            Swal.fire({ icon: "success", text: 'Se aprobó su solicitud' });
            setLoading(false)
        }).catch((error) => {
            Swal.fire({ icon: "error", text: error, });
            setOpen(false)
            setLoading(false)
            console.error("Error adding document: ", error);
        });
    }
    const changeImagen = async (e) => {
        setImagen(e.target.files[0]);
        setViewImagen(URL.createObjectURL(e.target.files[0]));
    }
    const changeArchivo = async (e) => {
        setArchivo(e.target.files[0]);
        setFile(e.target.files[0].name)
    }
    return (
        <>
            <IconButton size='small' onClick={() => onData()}>
                <RemoveRedEyeIcon fontSize='small' />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
            >
                <DialogTitle>
                    REQUERIMIENTO: {props.id}
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <DialogContent dividers>
                    <Box pb={2}>
                        <Typography><strong>REQUERIMIENTO</strong></Typography>
                        <Typography>{props.requerimiento}</Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography><strong>DEPARTAMENTO: </strong>{props.departamento}</Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography><strong>DESCRIPCION</strong></Typography>
                        <Typography>{props.descripcion}</Typography>
                    </Box>

                    {props.asignado !== '' &&
                        <Box pb={2}>
                            <Typography><strong>ASIGNADO A: </strong>{props.asignado}</Typography>
                        </Box>
                    }
                    {props.observacion !== '' &&
                        <Box pb={2}>
                            <Typography><strong>OBSERVACION: </strong>{props.observacion}</Typography>
                        </Box>
                    }

                    {props.motivo_rechazo && props.motivo_rechazo !== '' &&
                        <Box pb={2}>
                            <Typography><strong>MOTIVO RECHAZO: </strong></Typography>
                            <Typography>{props.motivo_rechazo}</Typography>
                        </Box>
                    }

                    {
                        props.imagen_requerimiento && props.imagen_requerimiento!=='' &&
                        <Box pb={2}>
                            <Typography><strong>IMAGEN SOLUCION</strong></Typography>
                            <a href={props.imagen_requerimiento} target='_blank'>
                                <img src={props.imagen_requerimiento} width="50%" />
                            </a>
                        </Box>
                    }
                    {
                        props.archivo_requerimiento && props.archivo_requerimiento!=='' &&
                        <Box pb={2}>
                            <Typography><strong>ARCHIVO SOLUCION</strong></Typography>
                            <strong>DESCARGAR: </strong><a href={props.archivo_requerimiento} style={{ color: 'blue' }} download>{props.nombreArchivo_requerimiento}</a>
                        </Box>

                    }
                    {
                        props.imagen && props.imagen!=='' &&
                        <Box pb={2}>
                            <Typography><strong>IMAGEN SOLICITUD</strong></Typography>
                            <a href={props.imagen} target='_blank'>
                                <img src={props.imagen} width="50%" />
                            </a>
                        </Box>

                    }
                    {
                        props.archivo && props.archivo!=='' &&
                        <Box pb={2}>
                            <Typography><strong>ARCHIVO SOLICITUD</strong></Typography>
                            <strong>DESCARGAR: </strong><a href={props.archivo} style={{ color: 'blue' }} download>{props.nombreArchivo}</a>
                        </Box>

                    }

                    {
                        props.asignado !== '' && props.estado == 1 &&
                        <>
                            <Box display="flex" flexDirection="row-reverse" >
                                <Button
                                    size="small"
                                    onChange={changeImagen}
                                    variant='outlined'
                                    disableElevation
                                    component="label"
                                > {viewImagen === null ? 'SUBIR IMAGEN DE REQUERIMIENTO' : 'CAMBIAR IMAGEN DE REQUERIMIENTO'}
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button>
                            </Box>
                            <Box display="flex" flexDirection="row-reverse" py={1}>
                                <img style={{ width: "10%" }} src={viewImagen} />
                            </Box>
                        </>
                    }

                    {
                        props.asignado !== '' && props.estado == 1 &&
                        <>
                            <Box display="flex" flexDirection="row-reverse" >
                                <Button
                                    size="small"
                                    onChange={changeArchivo}
                                    variant='outlined'
                                    disableElevation
                                    component="label"
                                > {archivo === null ? 'SUBIR ARCHIVO DE REQUERIMIENTO' : 'CAMBIAR ARCHIVO DE REQUERIMIENTO'}
                                    <input
                                        type="file"
                                        hidden
                                    />
                                </Button>
                            </Box>
                            <Box display="flex" flexDirection="row-reverse">
                                <Typography sx={{ fontSize: '14px' }}> <strong>Archivo: </strong> {file}
                                </Typography>
                            </Box>

                        </>
                    }

                    {props.estado === 2 && <>
                        <Box pb={2}>
                            <FormControl fullWidth>
                                <Typography gutterBottom >MOTIVO RECHAZO</Typography>
                                <TextField
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    size="small"
                                    rows={5}
                                    multiline
                                    fullWidth
                                    value={descripcion}
                                    onChange={(e) => setDescripcion(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                    </>}

                </DialogContent>
                <DialogActions>
                    {
                        props.asignado !== '' && props.estado == 1 && <>
                            <Button onClick={() => onSubmit()} disabled={!viewImagen && !archivo} variant='contained' color='success'>SUBIR REQUERIMIENTO</Button>
                        </>
                    }
                    {
                        props.estado == 2 && <>
                            <Button onClick={() => aprobar()} variant='contained' color='success'>SOLICITUD TERMINADA</Button>
                            <Button onClick={() => onNega()} disabled={descripcion.length < 1} variant='contained' color='error'>SOLUCION RECHAZADA</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    )
}

export default VerItemsMarketing