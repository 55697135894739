import React, { useState, useEffect } from "react";
import {
    TextField, FormControl, Select, MenuItem, Button, Box,
    DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, CircularProgress,
    Divider
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db, functions, firebase } from "../../../../firebase";
import Swal from "sweetalert2";
import 'moment/locale/es'
import { useNavigate } from "react-router-dom";
import { createAgenda } from "../../../../redux/actions/agenda/agendaActions";
export const UpdateInformation = ({ props }) => {

    const [centroCosto, setCentroCosto] = useState(props.proyecto)

    const [estado, setEstado] = useState(props.estado)

    const [openConfirm, setOpenConfirm] = useState(false);

    const onData = () => {
        setOpenConfirm(true)
    }

    const onHandleConfirm = () => {
        db.collection("contabilidad_proyectos").doc(props.id).update({
            proyecto: centroCosto.toUpperCase(),
            estado: estado.toUpperCase(),
        }).then(() => {
            setOpenConfirm(false)
            Swal.fire({ icon: "success", text: "Se ha actualizado el proyecto con exito", });
            setTimeout(
                function () {
                    window.location.reload();
                }, 600);
        })
            .catch((error) => { console.error("Error adding document: ", error); });
    }

    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xl">

                <DialogTitle><strong>Editar proyecto: {props.proyecto}</strong></DialogTitle>

                <DialogContent>

                    <Box py={2}>
                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>PROYECTO:</strong></p>
                        <TextField sx={{
                            '& legend': { display: 'none' },
                            '& fieldset': { top: 0 },
                        }}
                            value={centroCosto}
                            onChange={(e) => setCentroCosto(e.target.value)}
                            name="codigoProducto"
                            size="small"
                            fullWidth
                        />
                    </Box>

                    <Box py={2}>
                        <p style={{ fontSize: "11px", marginBottom: "10px" }}><strong>ESTADO:</strong></p>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={estado}
                                onChange={(e) => setEstado(e.target.value)}
                            >
                                <MenuItem value="ACTIVO">ACTIVO</MenuItem>
                                <MenuItem value="INACTIVO">INACTIVO</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setOpenConfirm(false)}><strong>Cancelar</strong></Button>
                    <Button onClick={() => onHandleConfirm()} variant="contained" color="success">
                        Confirmar
                    </Button>
                </DialogActions>

            </Dialog>

        </>
    );
};