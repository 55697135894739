import { Box, Button, Card, CardContent, Chip, FormControl, Select, MenuItem, CircularProgress, Collapse, Dialog, DialogTitle, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { GridToolbarFilterButton, GridToolbarContainer, GridToolbarQuickFilter, DataGrid, esES } from '@mui/x-data-grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import React, { useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { DetalleFactura } from '../call_center/DetalleFactura';


export const Facturas = () => {
    let current = new Date();
    const [startDate, setStartDate] = useState(new Date(current.getFullYear() + '-' + (current.getMonth() + 1) + "-01"))
    const [data, setData] = useState([])
    const [endDate, setEndDate] = useState(new Date(current.getTime() + 86400000))
    const [open, setOpen] = useState(false);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [estado, setEstado] = useState('TODOS');
    const [fecha, setFecha] = useState('VENCIMIENTO');


    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const exporter = React.createRef(null);

    const excelExport = () => {
        if (exporter.current) {
            setLoading(true)
            exporter.current.save();
        }
    };

    const handleExport2 = () => {
        setLoading(false)
    }


    // useEffect(() => {
    //     LoadData()
    // }, [])

    useEffect(() => {
        LoadData()
    }, [fecha])

    function LoadData() {
        setLoad(true)
        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let inicio = moment(startDate.toString()).format('YYYY-MM-DD')
        let fin = moment(endDate.toString()).format('YYYY-MM-DD')
        let ruta = `https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?inicioVencido=${inicio}&finVencido=${fin}`
        if (fecha === 'EMITIDO') {
            ruta = `https://serverbd.intercommerce.com.ec/apiMikrowispFacturas/?inicioEmitido=${inicio}&finEmitido=${fin}`
        }
        fetch(ruta, requestOptions)
            .then(response => response.text())
            .then(result => {
                const re = JSON.parse(result)
                re.reverse()
                setData([...re.map((item) => {
                    return {
                        ...item,
                        tipo2: item.tipo === '2' ? 'SERVICIOS' : (item.tipo === '1' ? 'LIBRE' : ''),
                        cobrado: '$ ' + item.cobrado,
                        saldo: '$ ' + item.saldo,
                        total: '$ ' + item.total,
                        iva: '$ ' + item.iva_igv,
                        subtotal: '$ ' + item.sub_total,
                    }
                })])
                setLoad(false)
            }).catch(error => {
                console.log('error', error)
                setLoad(false)
            });
    }

    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );

    const columns = [
        {
            field: 'a', headerClassName: 'super-app-theme--header', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <DetalleFactura id={params.row.id} />
                </>
            }
        },
        { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'Id', width: 100 },
        { field: 'legal', headerClassName: 'super-app-theme--header', headerName: 'Legal', width: 100 },
        {
            field: 'tipo', headerClassName: 'super-app-theme--header', headerName: 'Tipo', width: 120,
            renderCell: (params) => {
                return <>
                    {params.row.tipo && params.row.tipo === "2" &&
                        <Chip label={"SERVICIOS"} color='info' />}
                    {params.row.tipo && params.row.tipo === "1" &&
                        <Chip label={"LIBRE"} color='success' />}

                </>
            }

        },
        { field: 'cedula', headerClassName: 'super-app-theme--header', headerName: 'Cedula', width: 100 },
        { field: 'nombre', headerClassName: 'super-app-theme--header', headerName: 'Nombre', width: 300 },
        { field: 'NUM_CONTRATO', headerClassName: 'super-app-theme--header', headerName: 'Contrato', width: 200 },
        { field: 'emitido', headerClassName: 'super-app-theme--header', headerName: 'F. Emitido', width: 120 },
        { field: 'vencimiento', headerClassName: 'super-app-theme--header', headerName: 'F. Vencimiento', width: 120 },
        { field: 'total', headerClassName: 'super-app-theme--header', headerName: 'Total', width: 100 },
        {
            field: 'estado', headerClassName: 'super-app-theme--header', headerName: 'Estado', width: 150,
            renderCell: (params) => {
                return <>
                    {params.row.estado && params.row.estado === "pagado" &&
                        <Chip label={params.row.estado} color='success' style={{ textTransform: 'uppercase' }} />}
                    {params.row.estado && params.row.estado === "No pagado" &&
                        <Chip label={params.row.estado} color='warning' style={{ textTransform: 'uppercase' }} />}
                    {params.row.estado && params.row.estado === "anulado" &&
                        <Chip label={params.row.estado} color='error' style={{ textTransform: 'uppercase' }} />}

                </>
            }
        },
        { field: 'pago', headerClassName: 'super-app-theme--header', headerName: 'F. Pago', width: 120 },
        { field: 'forma', headerClassName: 'super-app-theme--header', headerName: 'Forma Pago', width: 300 },
        { field: 'cobrado', headerClassName: 'super-app-theme--header', headerName: 'Cobrado', width: 100 },
        { field: 'saldo', headerClassName: 'super-app-theme--header', headerName: 'Saldo', width: 100 },
    ];

    return (
        <>
            <Box px={2}>
                <Card>
                    <Box paddingLeft={2}>
                        <p style={{ fontSize: "18px", marginBottom: "10px" }}><strong>FACTURAS</strong></p>
                    </Box>
                    <CardContent>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>FECHA:</p>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={fecha}
                                                onChange={(e) => setFecha(e.target.value)}
                                            >
                                                <MenuItem key={'EMITIDO'} value={'EMITIDO'}>EMITIDO</MenuItem>
                                                <MenuItem key={'VENCIMIENTO'} value={'VENCIMIENTO'}>VENCIMIENTO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>ESTADO:</p>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                size="small"
                                                value={estado}
                                                onChange={(e) => setEstado(e.target.value)}
                                            >
                                                <MenuItem key={'TODOS'} value={'TODOS'}>TODOS</MenuItem>
                                                <MenuItem key={'PAGADO'} value={'PAGADO'}>PAGADO</MenuItem>
                                                <MenuItem key={'NO PAGADO'} value={'NO PAGADO'}>NO PAGADO</MenuItem>
                                                <MenuItem key={'ANULADO'} value={'ANULADO'}>ANULADO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={0} md={4} />

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Box sx={{ flexGrow: 1 }} display='flex' alignItems='center'  >
                                                <Box display="flex" alignItems='center' >
                                                    <Button onClick={() => setOpen(!open)}>Buscar por fecha</Button>
                                                </Box>
                                            </Box>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <ReactDatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                />
                                                <Button disableElevation variant='contained' onClick={() => LoadData()}>Aplicar busqueda</Button>
                                            </Collapse>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Box display='flex' style={{ textTransform: 'uppercase' }} >
                                            <Typography variant="button">
                                                <strong>Desde</strong>   {startDate && moment(startDate.toString()).format('DD/MM/YYYY')}
                                            </Typography >
                                            <Typography variant="button" style={{ paddingLeft: 10 }}>
                                                <strong>Hasta</strong>  {endDate && moment(endDate.toString()).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Button onClick={() => excelExport()} size="small" color="success" variant="outlined" startIcon={<SimCardDownloadIcon />}
                                >
                                    {" "}
                                    DESCARGAR EXCEL
                                </Button>

                                <Paper>
                                    <Box sx={{
                                        height: 600, width: '100%', '& .super-app-theme--header': {
                                            backgroundColor: '#672780',
                                            color: 'white',
                                            fontWeight: 'bold',
                                        },
                                    }}>
                                        <DataGrid
                                            slots={{
                                                loadingOverlay: LinearProgress,
                                            }}
                                            density="compact"
                                            loading={load}
                                            hideFooterSelectedRowCount={true}
                                            rows={data ? data.filter((val) => {
                                                if (estado === 'TODOS') {
                                                    return val
                                                } else {
                                                    if (val.estado.toUpperCase() === estado) {
                                                        return val
                                                    }
                                                }
                                            }) : []}
                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                            columns={columns}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            getRowId={(row) => row.id}
                                        />
                                    </Box>
                                </Paper>

                                <ExcelExport onExportComplete={handleExport2} data={data} fileName={"Facturas " +
                                    (startDate ? moment(startDate.toString()).format('YYYY-MM-DD') : '') + " - " +
                                    (endDate ? moment(endDate.toString()).format('YYYY-MM-DD') : '')
                                    + " .xlsx"}
                                    ref={exporter} filterable={true} >
                                    <ExcelExportColumn field="id" title="N° Factura" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="legal" title="N° Legal" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="tipo2" title="Tipo" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="nombre" title="Cliente" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="idcliente" title="ID Cliente" headerCellOptions={{ background: "#672780" }} width={200} />

                                    <ExcelExportColumn field="emitido" title="F. Emitido" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="vencimiento" title="F. Vencimiento" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="pago" title="F. Pagado" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="total" title="Total" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="iva" title="Impuesto" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="subtotal" title="SubTotal" headerCellOptions={{ background: "#672780" }} width={200} />

                                    <ExcelExportColumn field="cobrado" title="Cobrado" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="a" title="Cobro Digital" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="b" title="Referencia conekta" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="direccion_principal" title="Dirección" headerCellOptions={{ background: "#672780" }} width={200} />

                                    <ExcelExportColumn field="saldo" title="Saldo" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="forma" title="Forma de pago" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="cedula" title="N° Identificación" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="movil" title="Teléfono" headerCellOptions={{ background: "#672780" }} width={200} />
                                    <ExcelExportColumn field="estado" title="Estado" headerCellOptions={{ background: "#672780" }} width={200} />


                                </ExcelExport>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box>

            <Dialog open={loading} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>

        </>
    )
}
