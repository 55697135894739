import { Box, Card, Tab, Typography, Container } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { db } from '../../../firebase';
import { SolicitudAprobadas } from '../../ventas/solicitud/SolicitudAprobadas';
import { SolicitudServicioEspera } from '../../ventas/solicitud/SolicitudServicioEspera';
import { SolicitudServicioRechazadas } from '../../ventas/solicitud/SolicitudServicioRechazadas';

import { NuevaMigracion} from './NuevaMigracion';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  )
}
export const MigracionPage = () => {
  const params = useParams()
  let navigate = useNavigate()
  const [tab, setTab] = React.useState(0);
  const [ticket, setTicket] = React.useState(null);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    //navigate(`/gestion_post_ventas/cambio_domicilio/${newValue}`)
  }
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id));
    } else {
      setTab(0)
    }
  }, [params.id])

  useEffect(() => {
    if (params.ticket) {
      let docRef = db.collection("CRM_migracion").doc(params.ticket)
      docRef.get().then((doc) => {
        if (doc.exists) {
          if(doc.data().estado_solicitud==='2' || doc.data().estado_solicitud==='4'){
            Swal.fire({ icon: "warning", text: "El ticket ya fue resuelto" })
          }
          setTicket({
            ...doc.data(),
            id: doc.id
          })
        }
      }).catch((error) => {
        console.log("Error getting document:", error);
      });
    }
  }, [params.ticket])


  return (
    <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
      <Box pl={2} pb={2} pt={2}>
        <Typography variant="h5" component="h1"><strong>MIGRACION</strong></Typography>
      </Box>
      <Card>
        <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={tab}
            onChange={handleChange}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            <Tab label="Nuevo Migracion" />
          
          </Tabs>
        </Box>
          <Card >
            <TabPanel value={tab} index={0}>
              <NuevaMigracion ticket={ticket}/>
            </TabPanel>
          </Card>
      </Card>
    </Container>
  )
}