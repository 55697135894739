import { Table, TableContainer, TableRow, TableCell, TableBody, TableHead } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import { DeleteItem } from "./options/DeleteItem";
import { UpdateItem } from "./options/UpdateItem";
export const Lista = ({ tabla }) => {
  const [data, setData] = useState();
  useEffect(() => {
    onLoad()
  }, [])
  const onLoad = () => {
    db.collection(tabla).orderBy('created', 'desc').onSnapshot((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      setData(data)
    })
  }
  return (
    <>
      <TableContainer sx={{ maxHeight: 600 }} >
        <Table size="small" stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>NOMBRE</TableCell>
              <TableCell>ACCIONES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.nombre}
                </TableCell>
                <TableCell component="th" scope="row">
                  <DeleteItem props={row} tabla={tabla} />
                  <UpdateItem props={row} tabla={tabla} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};