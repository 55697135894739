import {
    Box,
    CardHeader,
    Paper,
    Chip,
    Card,
    Dialog,
    CircularProgress,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Typography,
    FormControl,
    TextField,
    Select,
    MenuItem,
} from "@mui/material";
import {
    DataGrid,
    esES
} from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { db } from "../../firebase";
import { useSelector } from "react-redux";
import VerItems from "./VerItemsMarketing";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";

export const SolicitudMarketingEspera = () => {
    const [open, setOpen] = useState(null);
    const [open2, setOpen2] = useState(false);
    const [data, setData] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioAsignado, setUsuarioAsignado] = useState('');
    const [idEdit, setIdEdit] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const userAuth = useSelector((state) => state.userAuth.userInfo);


    useEffect(() => {
        LoadData()
    }, [])
    //
    const LoadData = () => {
        let ref = db.collection("requerimientos_marketing").orderBy("created", "desc").where("estado", "==", 0);
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            setData(data);
        });

        let ref2 = db.collection("usuarios");
        ref2.onSnapshot((snapshot) => {
            let datos = []
            snapshot.docs.map((doc) => {
                if (doc.data().roles?.toString().includes('marketing')) {
                    datos.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                }
            })
            setUsuarios(datos)
        })
    }
    
    const columns = [
        {
            field: 'created', headerName: 'FECHA EMISION', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        {
            field: 'estado', headerName: 'ESTADO', width: 100,
            renderCell: (params) => {
                return <Chip size="small" label="ABIERTA" color="success" />
            }
        },
        { field: 'id', headerName: 'NO. DE PEDIDO', width: 200 },
        { field: 'requerimiento', headerName: 'REQUERIMIENTO', width: 300 },
        {
            field: 'a', headerName: 'ASIGNAR', width: 100,
            renderCell: (params) => {
                return <>
                    <Button onClick={() => {
                        setIdEdit(params.row.id)
                        setOpen(true)
                    }}>
                        <EditIcon />
                    </Button>
                </>
            }
        },
        {
            field: '', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <VerItems props={params.row} />
                </>
            }
        },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 400 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 200 },
        { field: 'user', headerName: 'SOLICITANTE', width: 200 },
    ];

    const columns2 = [
        {
            field: 'created', headerName: 'FECHA EMISION', type: 'dateTime', width: 200,
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, HH:mm:ss'),
        },
        {
            field: 'estado', headerName: 'ESTADO', width: 100,
            renderCell: (params) => {
                return <Chip size="small" label="ABIERTA" color="success" />
            }
        },
        { field: 'id', headerName: 'NO. DE PEDIDO', width: 200 },
        { field: 'requerimiento', headerName: 'REQUERIMIENTO', width: 300 },
        {
            field: '', headerName: '', width: 50,
            renderCell: (params) => {
                return <>
                    <VerItems props={params.row} />
                </>
            }
        },
        { field: 'descripcion', headerName: 'DESCRIPCION', width: 400 },
        { field: 'departamento', headerName: 'DEPARTAMENTO', width: 200 },
        { field: 'user', headerName: 'SOLICITANTE', width: 200 },
    ];

    const asignar = () => {
        setOpen2(true)
        db.collection("requerimientos_marketing").doc(idEdit).update({
            estado: 1,
            asignado: usuarioAsignado,
            observacion: descripcion,
        }).then(() => {
            setOpen(false)
            setOpen2(false)
            setUsuarioAsignado('')
            Swal.fire({ icon: "success", text: 'Requerimiento asignado a ' + usuarioAsignado });
        }).catch((error) => {
            Swal.fire({ icon: "error", text: error, });
            setUsuarioAsignado('')
            setOpen(false)
            setOpen2(false)
            console.error("Error adding document: ", error);
        });
    }

    return (
        <>
            <Card>
                <CardHeader variant='outlined' sx={{ backgroundColor: 'whitesmoke' }} subheader={<strong> REQUERIMIENTOS MARKETING/SOLICITUDES EN ESPERA</strong>} />
                <Paper>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            density='compact'
                            rows={data ? data.filter((val) => {
                                if (userAuth?.roles?.find(role => ['marketing', 'admin']?.includes(role))) {
                                    return val
                                } else {
                                    if (userAuth.displayName === val.user)
                                        return val
                                }
                            }) : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={userAuth?.roles?.find(role => ['marketing', 'admin']?.includes(role)) ? columns : columns2}
                            hideFooterSelectedRowCount={true}
                        />
                    </Box>
                </Paper>
                <Dialog open={open} fullWidth onClose={() => {
                    setUsuarioAsignado('')
                    setOpen(false)
                }} maxWidth="sm">
                    <DialogTitle>
                        <strong>ASIGNAR REQUERIMIENTO A:</strong>
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom >USUARIO</Typography>
                        <FormControl fullWidth>
                            <Select sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                                size="small"
                                value={usuarioAsignado}
                                onChange={(e) => setUsuarioAsignado(e.target.value)}>
                                {usuarios ?
                                    usuarios.map(item => (
                                        <MenuItem key={item.nombres} value={item.nombres} >
                                            {item.nombres}
                                        </MenuItem>
                                    )) : <></>}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <br />
                            <Typography gutterBottom >OBSERVACION</Typography>
                            <TextField
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                size="small"
                                rows={5}
                                multiline
                                fullWidth
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            setUsuarioAsignado('')
                            setOpen(false)
                        }}><strong>Cancelar</strong></Button>
                        <Button onClick={() => {
                            asignar()
                        }} variant="contained"
                            color="info"
                            disabled={usuarioAsignado === '' || descripcion === ''}
                        >
                            {" "}
                            ASIGNAR
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>

            <Dialog open={open2} >
                <DialogTitle>
                    <Stack spacing={2} style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress align="center" />
                        <strong><p align="center" style={{ fontSize: '14px' }}>CARGANDO...</p></strong>
                    </Stack>
                </DialogTitle>
            </Dialog>
        </>
    );
};
