import {
    Typography,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableHead,
    Button,
    TableCell,
    IconButton,
    Autocomplete,
    TableRow,
    TableContainer,
    TableBody,
    Divider,
    Box,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControl,
    Select,
    MenuItem
} from '@mui/material'
import React, { useEffect, useState } from "react";
import { db } from '../../firebase'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
    listsDepartamentosInventario
} from "../../redux/actions/parametersActions";

import {
    listsBodegas
} from "../../redux/actions/parametersActions";

export const SolicitudPedido = () => {

    const departamentosInventarioList = useSelector((state) => state.departamentosInventarioList);
    const { departamentosInventario, loadingDepartamentosInventario } = departamentosInventarioList;
    const [departamentoInventario, setDepartamentoInventario] = useState("");

    const [observacion, setObservacion] = useState('')

    let navigate = useNavigate()
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;
    const [fecha, setFecha] = useState({ campo: new Date, valido: true })
    const [numero, setNumero] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState('')
    const [tipo_material, setTipoMaterial] = useState({ campo: '', valido: true })
    const [egreso, setEgreso] = useState({ campo: '', valido: true })
    const [loading, setLoading] = useState(false)
    const [numPedido, setNumPedido] = useState(false)
    const [productos, setProductos] = useState('')
    const [productosActivosFijos, setProductosActivosFijos] = useState('')
    const [motivo, setMotivo] = useState('')

    const [detalleProducto, setDetalleProducto] = useState('')
    //
    const [cantidad, setCantidad] = useState('')
    const [item, setItem] = useState('')

    const [nombreLiderFlota, setNombreLiderFlota] = useState('')

    const [nombreAuxiliarFlota, setNombreAuxiliarFlota] = useState('')

    const [itemActivoFijo, setItemActivoFijo] = useState('')
    //
    const [modal, setModal] = useState(false)

    const bodegasList = useSelector(state => state.bodegasList);
    const { bodegas, loadingBodegas } = bodegasList
    const [bodega, setBodegas] = useState('')

    const [modalActivosFijos, setModalActivosFijos] = useState(false)
    //
    //
    const [inputFields, setInputFields] = useState([
    ]);

    const [enviando, setEnviando] = useState(false)
    //
    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])
    }

    const getDataFlota = async () => {
        /* .includes('flota') */
        if (userInfo.email.includes('flota')) {
            const agendaDB2 = await db.collection("flotas").where('correo', '==', userInfo.email).get()
            const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            setNombreLiderFlota(data2[0].nombreLider);
            setNombreAuxiliarFlota(data2[0].nombreAuxiliar)
        } else {
            setNombreLiderFlota('');
            setNombreAuxiliarFlota('')
        }
    }

    const addInput = (row) => {
        if (inputFields.find(x => x.fullName === row.codigo_producto)) {
            Swal.fire({ icon: "info", text: 'Ya se agrego este producto a la lista', });
        } else {
            setInputFields([...inputFields, {
                fullName: row.codigo_producto,
                cantidad_pedida: 0,
                descripcion: row.descripcion_producto,
                codigo: row.codigo_producto,
                motivo: motivo,
                usa_serie: row.usa_serie
                /* categoria: row.categoria_producto,
                subcategoria_producto: row.subcategoria_producto,
                marca_producto: row.marca_producto,
                modelo_producto: row.modelo_producto,
                presentacion_producto: row.presentacion_producto,
                unidad_Medida_Producto: row.unidad_Medida_Producto, */
            }])
        }
        setItem('')
        setModal(false)
        setModalActivosFijos(false)
    }

    const removeInputFields = (index) => {
        console.log('Borrado', index)
        const rows = [...inputFields];

        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {
        //console.log(typeof evnt.target.value, 'items')
        if (Math.sign(evnt.target.value) === -1) {
            console.log('Numero negativo no pasa')
        }
        else {
            if (evnt.target) {
                const { name, value } = evnt.target;
                const list = [...inputFields];
                list[index][name] = value;
                setInputFields(list);
            } else {
                const list = [...inputFields];
                list[index]['fullName'] = evnt;
                setInputFields(list);
            }
        }
    }
    const getProductos = (e) => {
        let docRef = db.collection("reporte_stock").where('bodega', '==', e)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            console.log(arrayMessage)
            setProductos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    const getProductosActivosFijos = (e) => {
        let docRef = db.collection("activos_fijos").where('bodega', '==', e)
        docRef.get().then((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })

            setProductosActivosFijos(arrayMessage)
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    function detalleMate(data, index) {
        const handleCodigo = (index, e) => {
            data.codigo = e.target.value
            let docRef = db.collection("productos").where("codigoProducto", "==", `${e.target.value}`);
            docRef.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const dataRef = doc.data()
                    data.descripcion = dataRef.descripcionProducto
                    console.log(data.codigo)
                });
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
            console.log(inputFields, 'da')
        }
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {index + 1}
                </TableCell>
                <TableCell align="center">
                    {data.codigo}
                </TableCell>
                <TableCell align="center">{data.descripcion}</TableCell>
                <TableCell align="center">
                    <TextField
                        name={"cantidad_pedida"}
                        onChange={(e) => handleChange(index, e)}
                        value={data.cantidad_pedida}
                        size='small'
                        type='number'
                        label={``}
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => removeInputFields(index)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }
    //
    const dispatch = useDispatch()

    useEffect(() => {
        /* getProductos() */
        /* getProductosActivosFijos() */
        getDataFlota()
        dispatch(listsDepartamentosInventario())
        dispatch(listsBodegas())
    }, [dispatch]);
    //
    const loadNumPedido = () => {
        const ref = db.collection("solicitud_pedido").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numeroSolicitud + 1;
                const uidSolicitud = `SO-PED-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const handleSubmit = (e) => {
        setEnviando(true)
        e.preventDefault()
        let rol = ''
        if (userInfo.rol === 0) {
            rol = 'ADMIN'
        }
        if (userInfo.rol === 1) {
            rol = 'COMERCIAL'
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = 'OPERACIONES'
        }
        if (userInfo.rol === 3) {
            rol = 'PLANEACION'
        }
        if (userInfo.rol === 4) {
            rol = 'FLOTAS'
        }
        if (userInfo.rol === 5) {
            rol = 'REPORTES'
        }
        if (userInfo.rol === 6) {
            rol = 'RECURSOS HUMANOS'
        }
        //
        const uidSolicitud = `SO-PED-` + numPedido;
        const data = {
            uidSolicitud: uidSolicitud,
            estado: 3,
            numeroSolicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            motivo: motivo,
            rol: rol,
            // created: firebase.firestore.FieldValue.serverTimestamp(),
            observacion: observacion,
            departamento_seleccionado: departamentoInventario,
            detalle_de_producto: detalleProducto,
            bodega: bodega,
            correo: userInfo.email,
            nombre_lider_flota: nombreLiderFlota,
            nombre_auxiliar_flota: nombreAuxiliarFlota
        }
        console.log(data, 'enviar')
        console.log(inputFields, 'enviar 2')

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const reData = [
            ...inputFields.map(obj => {
                return {
                    cantidad: parseInt(obj.cantidad_pedida),
                    cantidad_pendiente: parseInt(obj.cantidad_pedida),
                    ...obj,
                    uid_solicitud_pedido: uidSolicitud,
                    uid_user: userInfo.uid,
                    user: userInfo.displayName,
                    // created: firebase.firestore.FieldValue.serverTimestamp(),
                    motivo: motivo,
                    bodega: bodega,
                }
            })
        ]
        let raw = JSON.stringify({
            data: {
                ...data
            },
            inputFields: reData,
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/apiBodega/solicitudPedido", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log('Solicitud id: ', result)
                navigate("/compras/compra/1")
                Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
                setMotivo('')
                setBodegas('')
                setDepartamentoInventario('')
                setDetalleProducto('')
                setInputFields([])
                setObservacion('')
                setEnviando(false)
            })
            .catch(error => {
                Swal.fire({ icon: "info", text: error, });
                setMotivo('')
                setBodegas('')
                setDepartamentoInventario('')
                setDetalleProducto('')
                setInputFields([])
                setObservacion('')
                setEnviando(false)
            });


        //
        // const doc = db.collection('solicitud_pedido').doc(uidSolicitud).set(data)
        //     .then((docRef) => {
        //         setLoading(false)
        //         console.log('Solicitud id: ', docRef)
        //         navigate("/compras/compra/1")
        //         Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
        //         //
        //         inputFields.forEach(function (obj, index) {
        //             const dataRef = {
        //                 cantidad: parseInt(obj.cantidad_pedida),
        //                 cantidad_pendiente: parseInt(obj.cantidad_pedida),
        //                 ...obj,
        //                 uid_solicitud_pedido: uidSolicitud,
        //                 uid_user: userInfo.uid,
        //                 user: userInfo.displayName,
        //                 created: firebase.firestore.FieldValue.serverTimestamp(),
        //                 motivo: motivo,
        //                 bodega: bodega,
        //                 /* categoria: obj.categoria_producto,
        //                 subcategoria_producto: obj.subcategoria_producto,
        //                 marca_producto: obj.marca_producto,
        //                 modelo_producto: obj.modelo_producto,
        //                 presentacion_producto: obj.presentacion_producto,
        //                 unidad_Medida_Producto: obj.unidad_Medida_Producto, */
        //             }
        //             const doc = db.collection(`solicitud_pedido/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)
        //                 .then((docRef) => {
        //                     console.log('items id: ', docRef)
        //                     setMotivo('')
        //                     setBodegas('')
        //                     setDepartamentoInventario('')
        //                     setDetalleProducto('')
        //                     setInputFields([])
        //                     setObservacion('')
        //                     //Swal.fire({ icon: "success", text: 'Se creo su solicitud de pedido' });
        //                 })
        //                 .catch((error) => {
        //                     console.error("Error adding document: ", error);
        //                     setMotivo('')
        //                     setBodegas('')
        //                     setDepartamentoInventario('')
        //                     setDetalleProducto('')
        //                     setInputFields([])
        //                     setObservacion('')
        //                 });
        //         });
        //         //
        //     })
        //     .catch((error) => {
        //         setLoading(false)
        //         Swal.fire({ icon: "info", text: error, });
        //         console.error("Error adding document: ", error);
        //         setMotivo('')
        //         setBodegas('')
        //         setDepartamentoInventario('')
        //         setDetalleProducto('')
        //         setInputFields([])
        //         setObservacion('')
        //     });
    }
    useEffect(() => {
        loadNumPedido()
    }, [inputFields])

    const blockButtonAddItem = async (row) => {
        /* addInput(row) */
        /* console.log(row)
        console.log(row.id)
        console.log(row.codigo_producto) */

        const agendaDB3 = await db.collection("productos").where("codigo_producto", "==", row.codigo_producto).get()

        const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

        const openAndCloseModal = () => {
            console.log('No existe id')
            setModal(false)
            Swal.fire({ icon: "warning", text: `La ${bodega} no esta asociada a este producto` })
        }

        {
            data3[0] === '' ||
                data3[0] === null ||
                data3[0] === undefined ?
                /* console.log('No existe id') */
                openAndCloseModal() :
                addInput(data3[0])
        }

        console.log(`RESP: ${data3[0].id}`)

        /* {
            data3[0].id === '' ||
                data3[0].id === null ||
                data3[0].id === undefined ?
                console.log('No existe id') :
                console.log('Si existe id')
        } */

        /* const resp = await db.collection("reporte_stock").where("codigo_producto", "==", obj.fullName).get()

        const data1 = resp.docs.map(doc => ({ id: doc.id, ...doc.data() })) */

    }

    return (
        <>
            <Box py={1} pl={2}>
                <Typography>
                    NUEVA SOLICITUD
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={5}>
                            <Grid item md={6}>
                                <Typography gutterBottom><strong>NUMERO DE REFERENCIA</strong></Typography>
                                <Typography>SO-SE-{numPedido}</Typography>
                                <Typography gutterBottom><strong>FECHA DE CREACION</strong></Typography>
                                <Typography>{moment().format('YYYY-MM-D h:mm:ss A')}</Typography>

                                {/* <Box pt={5}>
                                    <TextField
                                        fullWidth
                                        label='MOTIVO'
                                        variant='outlined'
                                        value={motivo}
                                        onChange={(e) => setMotivo(e.target.value)}
                                    />
                                </Box> */}

                                <Divider />

                                <Box pt={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <Typography gutterBottom style={{ fontSize: '14px' }}>BODEGA</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Select sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                fullWidth
                                                size="small"
                                                value={bodega}
                                                label="BODEGA"
                                                onChange={(e) => {
                                                    getProductos(e.target.value)
                                                    getProductosActivosFijos(e.target.value)
                                                    setBodegas(e.target.value)
                                                    console.log(e.target.value)
                                                }}>
                                                {bodegas &&
                                                    bodegas.map(item => (
                                                        <MenuItem key={item.nombre_bodega} value={item.nombre_bodega} >
                                                            {item.nombre_bodega}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pt={4}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>MOTIVO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                size='small'
                                                fullWidth
                                                variant='outlined'
                                                value={motivo}
                                                onChange={(e) => setMotivo(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pt={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>OBSERVACION:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                                value={observacion}
                                                onChange={(e) => setObservacion(e.target.value)}
                                                name="observacion"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                            <Grid item md={6}>
                                <Typography gutterBottom><strong>USUARIO</strong></Typography>
                                <Typography>{userInfo.displayName}</Typography>
                                {/* <Typography gutterBottom><strong>ROL</strong></Typography>
                                <ConvertRoles id={userInfo.rol} /> */}

                                <Box pt={12}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>DEPARTAMENTO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <FormControl fullWidth>
                                                <Select sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                    size="small"
                                                    value={departamentoInventario}
                                                    label="MODELO"
                                                    onChange={(e) => setDepartamentoInventario(e.target.value)}>
                                                    {departamentosInventario &&
                                                        departamentosInventario.map(item => (
                                                            <MenuItem key={item.departamento} value={item.departamento} >
                                                                {item.departamento}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pt={2}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={2}>
                                            <p style={{ fontSize: "12px", marginBottom: "10px" }}>DETALLE DE PRODUCTO:</p>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <FormControl fullWidth>
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    size="small"
                                                    value={detalleProducto}
                                                    onChange={(e) => setDetalleProducto(e.target.value)}
                                                >
                                                    <MenuItem value="ACTIVO FIJO">ACTIVO FIJO</MenuItem>
                                                    <MenuItem value="INVENTARIO">INVENTARIO</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                        </Grid>

                        {
                            detalleProducto === 'INVENTARIO' ? <Box display="flex" flexDirection="row-reverse" py={1}>
                                <Button
                                    disabled={bodega === '' || detalleProducto === ''}
                                    variant='outlined'
                                    onClick={() => setModal(true)}>
                                    Agregar Items
                                </Button>
                            </Box> : <Box display="flex" flexDirection="row-reverse" py={1}>
                                <Button
                                    disabled={bodega === '' || detalleProducto === ''}
                                    variant='outlined'
                                    onClick={() => setModalActivosFijos(true)}>
                                    Agregar Items
                                </Button>
                            </Box>
                        }

                        {/* <Box display="flex" flexDirection="row-reverse" py={1}>
                            <Button
                                variant='outlined'
                                onClick={() => setModal(true)}>
                                Agregar Items
                            </Button>
          Profesora              </Box> */}

                        {/* <Box display="flex" flexDirection="row-reverse" py={1}>
                            <Button
                                variant='outlined'
                                onClick={() => setModal(true)}>
                                Agregar Items
                            </Button>
                        </Box> */}

                        <Typography gutterBottom>DETALLE DE MATERIALES {item}</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>NO.</TableCell>
                                        <TableCell align="center">CODIGO 5</TableCell>
                                        <TableCell align="center">DESCRIPCION</TableCell>
                                        <TableCell align="center">CANT PEDIDA</TableCell>
                                        <TableCell align="center">ACCIONES</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        inputFields.map((data, index) => {
                                            const { categoria_producto, descripcion_producto, codigo_producto } = data;
                                            return (
                                                detalleMate(data, index)
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Divider />
                        <Box display="flex" flexDirection="row-reverse" pt={1}>
                            <Button
                                variant='contained'
                                disabled={inputFields.length <= 0 || enviando}
                                type="submit"
                            >Enviar solicitud</Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>

            {/* MODAL PRODUCTOS INVENTARIO */}

            <Dialog
                open={modal}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    AGREGAR ITEMS
                </DialogTitle>
                <DialogContent dividers >
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={productos}
                            clearOnBlur={false}
                            autoComplete={false}
                            disableClearable
                            getOptionLabel={(option) => option.descripcion}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item)
                                setItem(newInputValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CODIGO</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>CANTIDAD</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productos && productos
                                    .filter((val) => {
                                        if (item === "") {
                                            return val;
                                        } else if (val.descripcion.toLowerCase().includes(item.toLowerCase())) {
                                            return val;
                                        }
                                    }).map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.codigo_producto}</TableCell>
                                            <TableCell>{row.descripcion}</TableCell>
                                            <TableCell>{row.stock_actual}</TableCell>
                                            <TableCell><Button
                                                size='small'
                                                variant='contained'
                                                color='success'
                                                onClick={() => blockButtonAddItem(row)
                                                            /* onClick={() => addInput(row) */}
                                            >Agregar</Button></TableCell>
                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box py={5} ></Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setModal(false)}>
                        Cerrar ventana
                    </Button>
                </DialogActions>
            </Dialog>

            {/* MODAL PRODUCTOS ACTIVOS FIJOS */}

            <Dialog
                open={modalActivosFijos}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    AGREGAR ITEMS
                </DialogTitle>
                <DialogContent dividers >
                    <Box pb={3}>
                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={productos}
                            clearOnBlur={false}
                            autoComplete={false}
                            disableClearable
                            getOptionLabel={(option) => option.descripcion}
                            sx={{ width: 300 }}
                            onInputChange={(e, newInputValue) => {
                                console.log(item)
                                setItem(newInputValue)
                            }}
                            renderInput={(params) => <TextField {...params} label="BUSCAR PRODUCTO" />}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>CODIGO</TableCell>
                                    <TableCell>DESCRIPCION</TableCell>
                                    <TableCell>CANTIDAD</TableCell>
                                    <TableCell>ACCIONES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productos && productos
                                    .filter((val) => {
                                        if (item === "") {
                                            return val;
                                        } else if (val.descripcion.toLowerCase().includes(item.toLowerCase())) {
                                            return val;
                                        }
                                    }).map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{row.codigo_producto}</TableCell>
                                            <TableCell>{row.descripcion}</TableCell>
                                            <TableCell>{row.stock_actual}</TableCell>
                                            <TableCell><Button
                                                size='small'
                                                variant='contained'
                                                color='success'
                                                onClick={() => blockButtonAddItem(row)
                                                            /* onClick={() => addInput(row) */}
                                            >Agregar</Button></TableCell>
                                        </TableRow>
                                    ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box py={5} ></Box>
                </DialogContent>
                <DialogActions dividers>
                    <Button onClick={() => setModalActivosFijos(false)}>
                        Cerrar ventana
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}