import { Button, Typography, Container, Box } from '@mui/material'
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import InputValidation from '../../components/utils/InputValidation'
import { firebase, db } from '../../firebase'
export const EditAccount = () => {
    const [password, setPassword] = useState({ campo: "", valido: true })
    const [loading, setLoading] = useState(false)
    const change = () => {
        setLoading(true)
        const user = firebase.auth().currentUser;
        console.log(user, 'user')
        user.updatePassword(password.campo).then(() => {
            setLoading(false)
            Swal.fire({ icon: "success", text: "Se cambio su contraseña" })
            db.collection("usuarios").doc(user.email).add({
                "password": password.campo
            })
        }).catch((error) => {
            setLoading(false)
            Swal.fire({ icon: "error", text: error, })
        });
    }
    return (
        <>
            <Container>
                <Typography>Cambiar contraseña</Typography>
                <Box py={2}>
                    <InputValidation
                        estado={password}
                        cambiarEstado={setPassword}
                        label="Cambiar contraseña"
                        name="Contraseña"
                    />
                </Box>
                <Button
                    disabled={
                        !password.campo || loading 
                    }
                    variant='contained'
                    onClick={() => change()}
                >Cambiar contraseña</Button>
            </Container>
        </>
    )
}
