import {
  Box,
  Typography,
  Grid,
  Button,
  Container,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { firebase, functions, auth, db } from '../firebase'
import 'moment/locale/es'
import moment from 'moment'
import { Dashboard } from './administracion/dashboard/Dashboard';
import { LogsPage } from './administracion/LogsPage';
import { SolicitudRequeEspera } from './requerimientos-sistema/SolicitudRequeEspera';
import { ListUsersOnline } from './administracion/dashboard/ListUsersOnline';
import { Test } from './Test';
import data_data from './data2.json'

import { listsRelationFamilyUsers } from "../redux/actions/parametersActions";
import { ClientesMaps } from './ClientesMaps';

import { Client } from 'twilio-chat';

import * as XLSX from "xlsx/xlsx.mjs";

/* import Twilio from 'twilio-react'; */

export const HomePage = () => {

  const updateAllEstados = async () => {
    const querySnapshot = await db.collection('reporte_stock').doc('ymwDRsk1kUVqbSSJhA7j').collection('series').get();

    const batch = db.batch();

    querySnapshot.forEach((doc) => {
      const docRef = db.collection('reporte_stock').doc('ymwDRsk1kUVqbSSJhA7j').collection('series').doc(doc.id);
      batch.update(docRef, { estado: 1 });
    });

    await batch.commit();
  }

  const [message, setMessage] = useState();

  const [data, setData] = useState();

  const fileRef = useRef();

  const handleRemove = () => {
    setFileName(null);
    setFile(null);
    fileRef.current.value = "";
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [openCaja, setOpenCaja] = React.useState(false);

  const handleUploadFileLocal = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  };

  const handleUploadFile = async () => {
    try {
      if (file) {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setData(jsonData)

        jsonData.forEach(async (obj) => {
          console.log('obj.SERIE', obj.SERIE)
          db.collection('reporte_stock').doc('ymwDRsk1kUVqbSSJhA7j').collection('series').doc(`${obj.SERIE}`).update({
            estado: 0
          })
        });

        /* Swal.fire({
          icon: "success",
          title: "Subiendo datos, esto tardara un poco...",
        });
        setOpenCaja(false); */
      } else {
        console.log("No se cargado ningun archivo");
      }
    } catch (error) {
      console.log("error", error);
      setMessage(error.toString());
    }
  };

  /* constructor(props) {
    super(props);
    this.state = {
      surveySent: false,
    };
  }

  const sendSurvey = () => {
    const twilio = new Twilio({
      accountSid: 'AC83c2a6b00a97ca8d3d4dac8fffdadb9c',
      authToken: '78c71b1264757b1b1593266f651050ce',
    });

    const to = '0978976616';
    const from = '+15555555555';
    const body = '2. ¿Fue solucionado el requerimiento en la Visita Técnica?\nSi\nNo';

    twilio.sendMessage({ to, from, body });

    this.setState({ surveySent: true });
  }; */

  /* sendMessageTwilio */

  const sendMessageTwilio = async () => {
    const accountSid = 'AC83c2a6b00a97ca8d3d4dac8fffdadb9c';
    const authToken = '78c71b1264757b1b1593266f651050ce';
    const client = new Client(accountSid, authToken);

    try {
      await client.messages.create({
        body: '2. ¿Fue solucionado el requerimiento en la Visita Técnica?\n- Si\n- No',
        from: '+593997788138', // Tu número de Twilio
        to: '+593978976616', // Número de WhatsApp al que deseas enviar la encuesta
      });
      alert('Encuesta enviada con éxito');
    } catch (error) {
      console.error('Error al enviar la encuesta:', error);
      alert('Error al enviar la encuesta');
    }
  };

  const dispatch = useDispatch()

  /* const zonasList = useSelector(state => state.zonasList);
  const { zonas, loading2 } = zonasList
  const [zona, setZona] = useState('') */

  const [time, setTime] = useState(new Date());
  const [clock, changeTime] = useState(new Date().toLocaleTimeString());
  const [mes1, setMes1] = useState("");
  const [greeting, setGreeting] = useState('');
  const userAuth = useSelector(state => state.userAuth)
  const { loading, userInfo } = userAuth
  const [cajas, setCajas] = useState([]);
  const [cedulas, setCedulas] = useState([]);
  const [cedulasCajasMikro, setCedulasCajasMikro] = useState([]);

  const functionDeleteTotalMovimientos = () => {
    // const totalMovimientosRef = db.collection('total_movimientos_general');
    // totalMovimientosRef
    //   .where('user', '==', 'DESARROLLO PRODUCCIÓN')
    //   .get()
    //   .then((querySnapshot) => {
    //     // Eliminar los documentos que cumplen la condición
    //     querySnapshot.forEach((doc) => {
    //       doc.ref.delete();
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Error al realizar la consulta:', error);
    //   });
  }

  const funcionUpdateSeriesReporteMovimientos = () => {
    const serie = '485754435476F5A8'
    db.collection('reporte_movimientos').doc('EGR-417').collection('items').doc('INV-00113').collection('series').doc(`${serie}`).set({
      bodega: 'BODEGA SANTA ELENA',
      created: firebase.firestore.FieldValue.serverTimestamp(),
      estado: 1,
      serie: `${serie}`,
      usuario_que_cargo_serie: '',
      utilizado_por_codigo: 'INV-00113'
    })
  }

  const funcionUpdateSeriesFlotas = () => {

    const serie = '4857544387B63574'
    db.collection('flotas').doc('PhlxCElaxSpuAdjTzN3M').collection('materiales').doc('INV-00113').collection('series').doc(`${serie}`).set({
      bodega: 'BODEGA URDENOR',
      created: firebase.firestore.FieldValue.serverTimestamp(),
      descripcion: 'HUAWEI ONU EG8145V5 5G',
      estado: 0,
      serie: `${serie}`,
      usuario_que_cargo_serie: '',
      utilizado_por_codigo: 'INV-00113'
    })

    /* const codigo = 'INV-00055'
    db.collection('flotas').doc('PhlxCElaxSpuAdjTzN3M').collection('materiales').doc(`${codigo}`).set({
      bodega: 'BODEGA NEXUS',
      categoria: 'INVENTARIO',
      codigo_producto: `${codigo}`,
      costo_promedio: 0,
      descripcion: 'CINTA AISLANTE NEGRO (3MTS)',
      motivo: '',
      precio_compra: 0,
      stock_actual: 4,
      total_valorizado: 0,
      unidad_medida: 'UNIDAD',
      usuario_que_subio_carga_producto: ''
    }) */
  }

  const funcionUpdateSeriesBodegasFlotas = () => {
    /* const serie = '485754435476F5A8'
    db.collection('bodegas_flotas').doc('flotas_externas').collection('materiales').doc('INV-00113').collection('series').doc(`${serie}`).set({
      bodega: 'BODEGA SANTA ELENA',
      created: firebase.firestore.FieldValue.serverTimestamp(),
      descripcion: 'HUAWEI ONU EG8145V5 5G',
      estado: 0,
      serie: `${serie}`,
      usuario_que_cargo_serie: '',
      utilizado_por_codigo: 'INV-00113'
    }) */

    const serie = '48575443203926A8'
    db.collection('reporte_stock').doc('IKmuOfQtHsmfyr02hFZN').collection('series').doc(`${serie}`).set({
      bodega: 'BODEGA SANTA ELENA',
      created: firebase.firestore.FieldValue.serverTimestamp(),
      descripcion: 'HUAWEI ONU EG8145V5 5G',
      estado: 0,
      serie: `${serie}`,
      usuario_que_cargo_serie: '',
      utilizado_por_codigo: 'INV-00113'
    })

    /* const codigo = 'SUM-00059'
    db.collection('bodegas_flotas').doc('flotas_internas').collection('materiales').doc(`${codigo}`).set({
      bodega: 'BODEGA SANTA ELENA',
      categoria: 'INVENTARIO',
      codigo_producto: `${codigo}`,
      costo_promedio: 0,
      descripcion: 'FIBRA DROP 2 HILOS MARCA INTERCOM ROLLO X 2000M',
      motivo: '',
      precio_compra: 0,
      stock_actual: 1400,
      total_valorizado: 0,
      unidad_medida: 'UNIDAD',
      usuario_que_subio_carga_producto: ''
    }) */

  }

  const deleteCajas = async () => {
    const cajasRef = db.collection('cajas');
    const cajasNoEncontradasRef = db.collection('cajas-no-usadas');

    // Obtener los documentos de la colección "cajas"
    const cajasSnapshot = await cajasRef.get();

    // Crear un conjunto de campos "caja" de la colección "cajas"
    const cajasSet = new Set();
    cajasSnapshot.forEach((doc) => {
      const caja = doc.data().caja;
      cajasSet.add(caja);
    });

    // Obtener los documentos de la colección "cajas_no_encontradas"
    const cajasNoEncontradasSnapshot = await cajasNoEncontradasRef.get();

    // Eliminar documentos de "cajas_no_encontradas" en lotes más pequeños
    const batchLimit = 500; // Límite de operaciones por lote
    let batchCount = 0;
    let batch = db.batch();

    cajasNoEncontradasSnapshot.forEach((doc) => {
      const caja = doc.data().caja;
      if (cajasSet.has(caja)) {
        batch.delete(doc.ref);
        batchCount++;
      }

      if (batchCount === batchLimit) {
        batch.commit();
        batch = db.batch();
        batchCount = 0;
      }
    });

    // Ejecutar la última operación de lote si quedan documentos pendientes
    if (batchCount > 0) {
      await batch.commit();
    }
  }

  useEffect(() => {
    /* dispatch(listsRelationFamilyUsers()) */
    _greeting()
    /* getClientCaja()
    getClientsCedula() */
  }, [dispatch])

  const getAllClientsApi2 = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    cedulas.forEach((obj, index) => {
      //MUESTRO POR CONSOLA LOS NUMEROS DE CEDULA
      // console.log('obj.cedula', obj.cedula)
      //FOREACH QUE PASO POR PARAMETROS LOS NUMEROS DE CEDULA PARA CONSULTAR LOS
      //DATOS DE CAJAS GPPON
      setTimeout(() => {
        fetch(`https://serverbd.intercommerce.com.ec/apiusuarios/?cedula=${obj.cedula.toString()}`, requestOptions)
          .then(response => response.text())
          .then(resultClient => {
            // console.log('result', result)
            const dataParseClient = JSON.parse(resultClient);
            const dataClient = dataParseClient;
            // console.log('DATOS_GPON', data[0].DATOS_GPON)
            dataClient.forEach((objClient, index) => {
              console.log('obj.DATOS_GPON', objClient.DATOS_GPON)
              console.log('index', index)

              // const originalString = obj.DATOS_GPON.toString();
              // const resultado = originalString.substring(0, originalString.lastIndexOf('-'));
              // console.log('resultado', resultado)

              const originalString = objClient.DATOS_GPON.toString();
              const separador = '-';
              const partes = originalString.split(separador);
              const resultadoSplit = partes.slice(0, partes.length - 1).join(separador);
              const valorSobrante = partes[partes.length - 1];

              console.log('resultadoSplit', resultadoSplit);      // Resultado: 2GSM-30-5
              console.log('valorSobrante', valorSobrante);

              // CONSULTA CAJAS FIREBASE

              if (objClient.estado === 'ACTIVO') {

                cajas.forEach((objCaja, index) => {

                  /* console.log('objCaja',objCaja)
   */
                  /* if (objCaja.caja === resultadoSplit) {
                    console.log('La caja hace match')
  
                    const datosString = objCaja.splitter;
  
                    const [inicio, fin] = datosString.split(":").map(Number);
  
                    db.collection("cajas")
                      .doc(objCaja.caja)
                      .collection("puertos")
                      .get()
                      .then(async (querySnapshot) => {
                        if (querySnapshot.size === 0) {
                          for (let i = inicio; i <= fin; i++) {
                            try {
                              await db
                                .collection("cajas")
                                .doc(objCaja.caja)
                                .collection("puertos")
                                .doc(`puerto-${i}`)
                                .set({
                                  puerto: i,
                                  usado: 0,
                                  id_puerto: `puerto-${i.toString()}`,
                                });
  
                              if (i === valorSobrante.toString()) {
                                const agendaDB2 = await db
                                  .collection("cajas")
                                  .doc(objCaja.caja)
                                  .collection("puertos")
                                  .where('puerto', '==', i)
                                  .get()
  
                                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  
                                await db
                                  .collection("cajas")
                                  .doc(objCaja.caja)
                                  .collection("puertos")
                                  .doc(data2[0].id)
                                  .update({
                                    puerto_de_usuario: objClient.nombre
                                  });
                              }
  
                              console.log(`Dato ${i} agregado correctamente`);
                            } catch (error) {
                              console.error(`Error al agregar el dato ${i}: ${error}`);
  
                              if (i === valorSobrante.toString()) {
                                const agendaDB2 = await db
                                  .collection("cajas")
                                  .doc(objCaja.caja)
                                  .collection("puertos")
                                  .where('puerto', '==', i)
                                  .get()
  
                                const data2 = await agendaDB2.docs.map(doc => ({ id: doc.id, ...doc.data() }))
  
                                await db
                                  .collection("cajas")
                                  .doc(objCaja.caja)
                                  .collection("puertos")
                                  .doc(data2[0].id)
                                  .update({
                                    puerto_de_usuario: objClient.nombre
                                  })
                              }
  
                            }
                          }
                        } else {
                          console.log("La colección no está vacía.");
  
  
  
                        }
                      });
  
                  } else {
                    console.log('La caja no hace match')
                    db.collection('cajas_no_encontradas').doc(objClient.DATOS_GPON.toString()).set({
                      caja: objClient.DATOS_GPON.toString(),
                      numContrato: objClient.NUM_CONTRATO,
                      nombres: objClient.nombre,
                      cedula: objClient.cedula
                    })
                  } */
                })

              } else {

              }
              /* let ref = db.collection('cajas');
  
              //Si tiene algun doc anterior se agrega acontinuacion
              ref.onSnapshot(snapshot => {
                const data = [
                  ...snapshot.docs.map(doc => {
                    return {
                      ...doc.data(),
                      id: doc.id,
                    }
                  })
                ];
  
                data.forEach((obj, index) => {
                  if (obj.caja === resultadoSplit) {
                    console.log('La caja hace match')
                  } else {
                    console.log('La caja no hace match')
                  }
                })
  
              }) */

              // console.log('objClient', objClient)
            })
          })
          .catch(error => console.log('error', error));
      }, 5000);
    })
  }

  /* compararCajas(); */

  const prueba = () => {
    //var current = new Date()
    //const startDate = new Date()
    //const endDate = new Date(current.getTime() - 345600000)
    //startDate.setHours(24, 0, 0, 0)
    //endDate.setHours(0, 0, 0, 0)
    //
    let ref = db.collection("agenda").where("estado", "==", 6).where("cambio_domicilio", "==", true)
    //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
    //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
    //ref = ref.where('created', '>=', endfulldate)
    //ref = ref.where('created', '<=', startfulldate)
    ref.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //Consultamos la api de facturacion
        const data = doc.data()
        //console.log(data.numContrato, ' data')
        var washingtonRef = db.collection("agenda").doc(data.numContrato);

        // Set the "capital" field of the city 'DC'
        return washingtonRef.update({
          estado: 1
        })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });

      })
    })
  }
  const contrana = () => {

    var uid = 'piM5Ps9EzogONbd6H6yuXfQbS0y2'

    firebase.auth().updateUser(uid, {
      //email: "f9.flota@metlogistic.com.ec",
      //phoneNumber: "+11234567890",
      //emailVerified: true,
      password: "johan.carreno2023",
      //displayName: "Jane Doe",
      //photoURL: "http://www.example.com/12345678/photo.png",
      //disabled: true
    })
      .then(function (userRecord) {
        // See the UserRecord reference doc for the contents of userRecord.
        console.log("Successfully updated user", userRecord.toJSON());
      })
      .catch(function (error) {
        console.log("Error updating user:", error);
      });
  }
  const updateRol = () => {
    console.log('rol')
    //
    var data = {
      email: 'juan.tobar@distribuidor.guayaquil.com.ec',
      rol: 2,
      departamento: 1,
      cargo: 1
    }
    const addRol = functions.httpsCallable('addRol');
    addRol(data)
      .then(res => {
        console.log(res, 'res')
      })
      .catch(error => console.log(error))
  }
  const update = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "codigo_pago": "12345",
      "REFERENCIA": "1",
      "NUM_CONTRATO": "1",
      "AGENTE_VENDEDOR": "1",
      "id": "053025"
    });

    var requestOptions = {
      method: 'POST',

      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/update_codigo_pago", requestOptions)
      .then(response => response.text())
      .then(result => {
        var data = JSON.parse(result)
        console.log(data, 'resutlado 2')
      })
      .catch(error => console.log('error', error));
  }
  const updateusuario = () => {
    const user = firebase.auth().currentUser;

    user.updateProfile({
      displayName: "SOEI PRODUCCIÓN"

    }).then(() => {
      // Update successful
      // ...
    }).catch((error) => {
      // An error occurred
      // ...
    });
  }
  const test = () => {
    var data = {
      codigo_pago: '1',
      id: '53025'
    }
    const updateCodigoPago = functions.httpsCallable('updateCodigoPago');
    updateCodigoPago(data)
      .then(res => {
        console.log(res, 'res')
      })
      .catch(error => console.log(error))
  }
  const viewUser = () => {
    db.collection("olt").where("nodo", "==", 35)
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }
  const updateLoad = async () => {
    // const data = await usuario.map((doc, index) => {
    //   const da = doc
    //   if (da.whatsapp && typeof da.user_id_microwisp == 'number') {
    //     if (index > 3760 && index < 3910) {
    //       console.log(index, 'in')
    //       console.log(da.whatsapp, ' data ', da.user_id_microwisp)
    //       var myHeaders = new Headers();
    //       myHeaders.append("Content-Type", "application/json");
    //       var raw = JSON.stringify({
    //         "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
    //         "idcliente": da.user_id_microwisp,
    //         "datos": {
    //           "movil": da.whatsapp
    //         }
    //       });
    //       var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //       };
    //       fetch("https://mikrowisp.intercommerce.com.ec/api/v1/UpdateUser", requestOptions)
    //         .then(response => response.text())
    //         .then(result => console.log(result, '-', index))
    //         .catch(error => console.log('error', error))
    //     }
    //   }
    // })
  }

  const updateProductos = async () => {
    try {
      const productosRef = db.collection('reporte_stock');
      const productosSnapshot = await productosRef.get();

      // Actualizar cada documento
      productosSnapshot.forEach(async (doc) => {
        await doc.ref.update({ usa_serie: false });
      });

      console.log('Se ha actualizado la colección "productos" correctamente.');
    } catch (error) {
      console.error('Error al actualizar los documentos:', error);
    }
  };

  const load1 = () => {
    var i = 0
    setInterval(() => {
      const item = data_data[i++]
      if (item.correo) {
        //const fechaFirebase = moment(item.instalado, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        db.collection("clientes").doc(item.id).set({
          "idcliente": item.id,
          "id": item.id,
          "cedula": `${item.cedula}`,
          "nombres": item.nombre,
          "direccion": item.direccion_principal,
          "provincia": "null",
          "canton": "null",
          "sector": "null",
          "zona": "null",
          "parroquia": "null",
          "coordenadas": "null",
          "telefono": item.telefono ? `${item.telefono}` : 'null',
          "celular": item.movil ? `${item.movil}` : 'null',
          "whatapp": "null",
          "numReferencia": "null",
          "estado": item.estado,
          "cuentabancaria": "null",
          "banco": "null",
          "tipobanco": "null",
          "fechanacimiento": "null",
          "accededebitobancario": "null",
          "codigopago": "null",
          "gradodiscapacidad": "null",
          "gradodiscapacitado": "null",
          "numContrato": item.NUM_CONTRATO,
          "obtieneCamara": "null",
          "cantidadCamara": "null",
          "email": item.correo ? item.correo : 'null',
          "vendedor": item.AGENTE_VENDEDOR,
          "created": firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          console.log(item.nombre, ` `, item.NUM_CONTRATO, ' ', item.estado, ' INGRESADO A SOEI')
          // db.collection("servicios").doc(item.id).set({
          //   id: item.id,
          //   idcliente: item.idcliente,
          //   idperfil: item.idperfil,
          //   perfil: item.perfil,
          //   nodo: item.nodo,
          //   descripcion: item.descripcion,
          //   costo: item.costo,
          //   estado: 0,
          //   ipap: "",
          //   mac: item.mac,
          //   ip: item.ip,
          //   tiposervicio: item.tiposervicio,
          //   instalado: item.instalado,
          //   antena: "0",
          //   pppuser: item.pppuser,
          //   ppppass: item.ppppass,
          //   direccion: item.direccion.toUpperCase(),
          //   //created: admin.firestore.FieldValue.serverTimestamp()
          // }).then(() => {
          //   console.log(item.nombre, ` `, item.NUM_CONTRATO, ' ', item.estado, ' INGRESADO A SOEI')
          // })
        })
        // var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // var raw = JSON.stringify({
        //   "correo": item.correo
        // });
        // var requestOptions = {
        //   method: 'POST',
        //   headers: myHeaders,
        //   body: raw,
        //   redirect: 'follow'
        // };
        // fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/email", requestOptions)
        //   .then(response => response.text())
        //   .then(result => {
        //     console.log('id cliente:', item.idcliente, 'correo:', item.correo)
        //     console.log(result)
        //   })
        //   .catch(error => console.log('error', error));
      }
    }, 100)
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // var raw = JSON.stringify({
    //   "cedula": "953416270",
    //   "nombres": "SEBASTIAN MITE TORRES",
    //   "direccion": "111",
    //   "provincia": "11",
    //   "canton": "111",
    //   "sector": "111",
    //   "zona": "111",
    //   "parroquia": "111",
    //   "coordenadas": "111",
    //   "telefono": "111",
    //   "celular": "111",
    //   "whatapp": "111",
    //   "numReferencia": "111",
    //   "estado": "111",
    //   "cuentabancaria": "1111",
    //   "banco": "111",
    //   "tipobanco": "111",
    //   "fechanacimiento": "111",
    //   "accededebitobancario": "111",
    //   "codigopago": "10000s",
    //   "gradodiscapacidad": "111",
    //   "gradodiscapacitado": "111",
    //   "numContrato": "111",
    //   "obtieneCamara": "111",
    //   "cantidadCamara": "1111",
    //   "email": "lianmejia@gmail.com",
    //   "vendedor": "Sebastian Mite"
    // });
    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };
    // fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
  }
  const load2 = () => {

    const data_data = []
    data_data.map((item, index) => {

      setTimeout(() => {
        let indice = index + 2
        let numContrato = `CA-GUAYAQUIL-${indice}`
        //console.log(, ' item index: ', )

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")

        let raw = JSON.stringify({
          "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
          "idcliente": parseInt(item.id),
          "datos": {
            "NUM_CONTRATO": numContrato
          }
        });

        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch("https://mikrowisp.intercommerce.com.ec/api/v1/UpdateUser", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(parseInt(item.id), ' item index: ', numContrato)
            console.log(result)
          })
          .catch(error => {
            console.log('error ', parseInt(item.id), ' item index: ', numContrato)
            //console.log('error', error)

          })
      }, 3000)
    })

  }
  const load3 = () => {
    const data_data2 = [
      {
        cedula: '0930812656'
      }
    ]
    var i = 0
    setInterval(() => {
      //console.log(data[i++].id)
      //console.log(i, ' index')
      //var indice = i + 202
      //var numContrato = `CA-GUAYAQUIL-${indice}`
      //console.log(, ' item index: ', )
      const info = data_data[i++]
      //console.log(info.a, ' info')
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json")
      let raw = JSON.stringify({
        "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
        "cedula": info.a
      })
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      }
      fetch("http://192.168.90.249/api/v1/GetClientsDetails", requestOptions)
        .then(response => response.text())
        .then(result => {
          const data = JSON.parse(result)
          const contracts = data.datos[0]
          console.log(contracts.correo)
        })
    }, 100)
  }
  const addform = () => {

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
      "idcliente": 53891,
      "datos": {
        "movil": "0963060180"
      }
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://mikrowisp.intercommerce.com.ec/api/v1/UpdateUser", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  const _greeting = () => {
    const currentHour = time.getHours();
    const currentMinute = time.getMinutes();
    if (currentHour >= 18 || currentHour < 6) {
      setGreeting('Buenas noches');
    } else if (currentHour >= 12) {
      setGreeting('Buenas tardes');
    } else {
      setGreeting('Buenos días');
    }
  }
  const addT = () => {
    console.log('te')
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      "cedula": 953416270,
      "nombres": "juan",
      "direccion": "111",
      "provincia": "11",
      "canton": "111",
      "sector": "111",
      "zona": "111",
      "parroquia": "111",
      "coordenadas": "111",
      "telefono": "111",
      "celular": "111",
      "whatapp": "111",
      "numReferencia": "111",
      "estado": "111",
      "cuentabancaria": "1111",
      "banco": "111",
      "tipobanco": "111",
      "fechanacimiento": "111",
      "accededebitobancario": "111",
      "codigopago": "10000s",
      "gradodiscapacidad": "111",
      "gradodiscapacitado": "111",
      "numContrato": "111",
      "obtieneCamara": "111",
      "cantidadCamara": "1111",
      "email": "lianmejia@gmail.com",
      "vendedor": "Sebastian Mite"
    });

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("http://192.168.90.24:3000/crearCliente", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  function formatString(str) {
    str = str.trim();
    str = str.replace(/\s+/g, '-')
    return str
  }
  const onload22 = () => {
    //APIS
    const data_data5 = [
      {
        "idcliente": "036688",
        "nombre": "SCARLET NOELY HERNANDEZ BERMUDEZ",
        "DATOS_GPON": "2ML-10-1-1"
      },
      {
        "idcliente": "041161",
        "nombre": "JAZMANI DANIEL MORA CHIRIGUAYA",
        "DATOS_GPON": "2ML-13-3-1"
      },
      {
        "idcliente": "051749",
        "nombre": "PLAZA MONSERRATE LUISA DEL PILAR",
        "DATOS_GPON": "2ML 9-2-1"
      },
      {
        "idcliente": "052134",
        "nombre": "PROAÑO VELOZ LAURA STEFANIA ",
        "DATOS_GPON": "2ML-9-2-2"
      },
      {
        "idcliente": "052574",
        "nombre": "CHUEZ CARVAJAL CELIN XIOMARA",
        "DATOS_GPON": "2ML-11-2-1"
      },
      {
        "idcliente": "052646",
        "nombre": "BAQUE CEDEÑO MERCEDES ESPERANZA",
        "DATOS_GPON": "2ML-9-2-3"
      },
      {
        "idcliente": "053057",
        "nombre": "PARRALES GUAYGUA GIOVANNY ALFREDO",
        "DATOS_GPON": "2ML-9-1-1"
      },
      {
        "idcliente": "053080",
        "nombre": "GARCES ALANA ELIAS ELISEO",
        "DATOS_GPON": "2ML-11-1-1"
      },
      {
        "idcliente": "053318",
        "nombre": "CEDEÑO CEPEDA NELIO ISRAEL",
        "DATOS_GPON": "2ML-15-1-1"
      },
      {
        "idcliente": "055202",
        "nombre": "CAROLIN BASTIDAS JACOME (PRUEBA TI)",
        "DATOS_GPON": "onu_prueba"
      },
      {
        "idcliente": "055811",
        "nombre": "CAMPOVERDE VILLAMAR ILIANA ISABEL",
        "DATOS_GPON": "2ML-13-5-1"
      },
      {
        "idcliente": "057418",
        "nombre": "SAMANIEGO WILLIAMS FRANCER STALIN",
        "DATOS_GPON": "2ML 9-1-2"
      },
      {
        "idcliente": "057419",
        "nombre": "L2 SAMANIEGO WILLIAMS FRANCER STALIN",
        "DATOS_GPON": "2ML 11-2-2"
      },
      {
        "idcliente": "057496",
        "nombre": "MARIA TATIANA MEJIA RODRIGUEZ",
        "DATOS_GPON": "2ML-11-2-3"
      },
      {
        "idcliente": "057833",
        "nombre": "LABORATORIO Y OFICINAS (NO UTILIZAR)",
        "DATOS_GPON": "nómbrecaja "
      },
      {
        "idcliente": "058067",
        "nombre": "VILLEGAS VELASQUEZ PERFECTO MANUEL",
        "DATOS_GPON": "2OLI-1-3-1"
      },
      {
        "idcliente": "058225",
        "nombre": "TOAZA SALAZAR DELIA MARIA",
        "DATOS_GPON": "2OAS-1-2-1"
      },
      {
        "idcliente": "059110",
        "nombre": "ESPINOZA IBARRA FABIAN JAVIER ",
        "DATOS_GPON": "2OLI-1-3-2"
      }
    ]
    let inc = 0
    setInterval(() => {
      const data = data_data5[inc++]
      let nom = data.nombre.split(' ')
      let caja = formatString(data.DATOS_GPON)
      let username = `${caja}${nom[0]}${nom[1].substr(0, 1)}${nom[2]}`
      let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        password = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw2 = JSON.stringify({
        "username": username,
        "attribute": "Cleartext-Password",
        "op": ":=",
        "value": password
      })
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw2
      }
      fetch("https://radiusapi.intercommerce.com.ec/api/addUserRadcheck", requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(username, ' username')
          console.log(result, 'creado result radcheck')
        }).catch(e => {
          console.log(`error rad check ${e}`)
        })
      //
      let raw3 = JSON.stringify({
        "username": username,
        "firstname": "",
        "lastname": "",
        "email": "",
        "department": "",
        "company": "",
        "workphone": "",
        "homephone": "",
        "mobilephone": "",
        "address": "",
        "city": "",
        "state": "",
        "country": "",
        "zip": "",
        "notes": "",
        "changeuserinfo": "0",
        "portalloginpassword": "",
        "enableportallogin": 0,
        "creationdate": "2023-03-17T11:14:58-05:00",
        "creationby": "administrator",
        "updatedate": null,
        "updateby": null
      })
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw3,
      }
      fetch("https://radiusapi.intercommerce.com.ec/api/UserinfoAdd", requestOptions)
        .then(response => response.text())
        .then(result => {
          console.log(username, ' username')
          console.log(result, 'creado user info add')
        }).catch(e => {
          console.log(`error user info ${e}`)
        })
    }, 10000)
  }
  const funciontest = () => {
    let data = {
      value: '0.0.0.0',
      username: 'CAJASEBASTIANMDESARROLLO'
    }
    const addRol = functions.httpsCallable('updateRadreply');
    addRol(data)
      .then(res => {
        console.log(res, 'res')
      })
      .catch(error => console.log(error))
  }

  const delete23 = () => {

    let codigo_pago = '01000092055255101'
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
      "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
      "codigo_pago": codigo_pago
    })
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    }
    fetch("http://192.168.90.249/api/v1/GetClientsDetails", requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)
        const contracts = data.datos[0]
        console.log(contracts.id)
        let raw2 = JSON.stringify({
          "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
          "estado": 1,
          "idcliente": contracts.id
        })
        //
        let requestOptions = {
          method: 'POST',
          body: raw2,
          redirect: 'follow'
        }
        fetch('http://45.236.168.15/api/v1/GetInvoices', requestOptions)
          .then(response => response.text())
          .then(result => {
            const data = JSON.parse(result)
            const total = data.facturas.map(factura => parseFloat(factura.total)).reduce((a, b) => a + b);
            const json = {
              facturas: data.facturas.map(factura => ({
                id_factura: factura.id,
                total: parseFloat(factura.total)
              })),
              total_facturas: total
            }
            console.log(json)
          })
          .catch(err => console.log('API:', err))
      })
      .catch(error => console.log('error', error))
  }

  const onload = () => {
    console.log('hola')
  }

  const onload5 = () => {
    var number = '10'
    console.log(number.padStart(13, '0'))
  }

  const sendSeriesMikroToFirebase = () => {

  }

  return (
    <>
      {
        userInfo && userInfo.email == 'intercommercebases@gmail.com' &&
        <>
          {/* <button onClick={() => contrana()}>Funcion 3</button> */}
         
           <Test />
          <Button color='primary' size='small' variant='contained' onClick={() => { setOpenCaja(true) }}>
            Press
          </Button>
        </>
      }
      <Container maxWidth="xl">
        <Box pt={2} pb={4}>
          <Typography component="h1" variant="h5" >
            ¡{greeting} {userInfo && userInfo.nombres}!
          </Typography>



          {/* <Button variant='contained' size='small' onClick={() => {
            getAllClient4()
          }}
          >Press</Button>
          <Button variant='contained' size='small' onClick={() => {
            getDatas()
          }}
          >Get Datas</Button> */}
          <Typography sx={{ mb: 0 }} color="text.secondary" paragraph>{userInfo && userInfo.email}</Typography>
          <Typography color="text.secondary" >v0.0.8</Typography>
        </Box>
        {/* <Dashboard /> */}
        <Grid container>
          <Grid item md={12}>
            <Grid container spacing={2}>
              <Grid item lg={8} md={12}>

              </Grid>
              <Grid item lg={4} md={12}>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {
          userInfo?.roles?.find(role => ['sistema']?.includes(role)) &&
          <>
            <Box py={5}>
              <LogsPage />
            </Box>
            <SolicitudRequeEspera />
          </>
        }
      </Container>

      {/* SUBIR EXCEL */}
      <Dialog
        open={openCaja}
        onClose={() => setOpenCaja(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Subir Excel</DialogTitle>
        <DialogContent>
          <Box pb={2}>
            {fileName
              ? `Nombre el archivo cargado:  ${fileName}`
              : "Por favor, cargar el archivo..."}
          </Box>
          {!file ? (
            <>
              <input
                style={{ display: "none" }}
                onChange={(e) => handleUploadFileLocal(e)}
                multiple
                id="buttonFile"
                type="file"
                ref={fileRef}
              />
              <label htmlFor="buttonFile">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  component="span"
                >
                  Cargar archivo
                </Button>
              </label>
            </>
          ) : (
            <Box>
              <Box display="flex">
                <Box pr={5}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleRemove()}
                  >
                    Borrar archivo
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="small"
                    onClick={handleUploadFile}
                    variant="contained"
                    color="primary"
                    to
                  >
                    Subir archivo
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

        </DialogContent>
      </Dialog>
    </>
  )
}