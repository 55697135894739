import {
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
    Box,
    FormControl,
    Stack,
    Select,
    MenuItem,
    LinearProgress,
    InputLabel,
    Grid,
    Paper,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TableContainer
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from 'react-redux';
import { db, firebase } from "../../../../firebase";
import SendIcon from '@mui/icons-material/Send';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridExportMenuItemProps,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector,
    gridVisibleColumnFieldsSelector,
    GridApi,
    GridToolbar,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { CalcularItems } from "./CalcularItems";

import { useNavigate } from "react-router-dom";

export const CheckRecepcionTransferencias = ({ props }) => {

    let navigate = useNavigate()

    const [loadingButtonAprobarSolicitudTransferencia, setLoadingButtonAprobarSolicitudTransferencia] = useState(false)

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const [numPedido, setNumPedido] = useState('')

    const [items, setItems] = useState([]);

    const [open, setOpen] = useState(false);

    const [openConfirm, setOpenConfirm] = useState(false);

    const openModalConfirm = () => {
        setOpenConfirm(true)
        setOpen(false)
    }

    const closeModalConfirm = () => {
        setOpen(true)
        setOpenConfirm(false)
    }

    const loadItems = () => {
        var docRef = db.collection("transferencias").doc(props.uidSolicitud).collection("items");
        docRef.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log('items', data)
            setItems(data);
        });
    }

    const loadNumPedido = () => {
        const ref = db.collection("ingreso_productos").orderBy("created", "desc").limit(1)
        ref.onSnapshot(snapshot => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } })
                const solicitud = solicitudes[0]
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `EGR-` + numeroSolicitud;
                setNumPedido(numeroSolicitud)
            }
        })
    }

    const [totalesValorizados, setTotalesValorizados] = useState('')

    const calculateTotalValorizado = async () => {
        const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", props.bodega).get();

        const data3 = agendaDB3.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        /* FOR PARA CALCULAR EL TOTAL VALORIZADO DE TODAS LAS BODEGAS */

        var sumaValorTotalValorizadoBodegas;

        let sumaTotalValorizado = 0;
        for (let i = 0; i < data3.length; i++) {
            console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
            sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
            sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            setTotalesValorizados(sumaTotalValorizado)
            console.log(totalesValorizados)
        }
    }

    const [numIngreso, setNumIngreso] = useState('');

    const loadNumIngreso = () => {
        const ref = db
            .collection("ingreso_productos")
            .orderBy("created", "desc")
            .limit(1);
        ref.onSnapshot((snapshot) => {
            if (snapshot.size > 0) {
                const solicitudes = snapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                const solicitud = solicitudes[0];
                const numeroSolicitud = solicitud.numero_solicitud + 1;
                const uidSolicitud = `ING-` + numeroSolicitud;
                setNumIngreso(numeroSolicitud);
            }
        });
    };

    useEffect(() => {
        loadNumIngreso()
        /* calculateTotalValorizado() */
        loadNumPedido()
    }, [items]);

    const onData = () => {
        setOpen(true)
        loadItems()
        /* setData(props)
        loadItems() */
        console.log(props, 'props')
    }

    const [sumaBodegas, setSumaBodegas] = useState("");

    const agregarSeriesBodegaDestino = () => {
        console.log(props.id)
        /* items.forEach(async function (obj, index) {
            db.collection()
        }) */
    }

    const getSeriesItemsTransferencias = () => {

        const uidIngreso = `ING-` + numIngreso;

        items.forEach((obj) => {
            db.collection('transferencias').doc(props.id).collection('items').doc(obj.codigo).collection('series').get()
                .then(async (querySnapshot) => {
                    if (querySnapshot.size > 0) {

                        const ref = await db.collection('transferencias').doc(props.id).collection('items').doc(obj.codigo).collection('series').limit(obj.cant_entrega).get()
                        const data = await ref.docs.map(doc => ({ id: doc.id, ...doc.data() }))

                        data.forEach(async (objSerie) => {

                            console.log('objSerie.serie', objSerie.serie)
                            console.log('obj.codigo', obj.codigo)
                            console.log('uidIngreso', uidIngreso)

                            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_destino).get()

                            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                            db.collection('reporte_stock').doc(data2[0].id).collection('series').doc(objSerie.serie.toString())
                                .set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie.toString(),
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega_destino,
                                    estado: 0
                                })

                            await db.collection('reporte_movimientos').doc(uidIngreso).collection('items')
                                .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                                .set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie.toString(),
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega_destino,
                                    estado: 0
                                })

                            await db.collection(`ingreso_productos/${uidIngreso}/items`)
                                .doc(obj.codigo).collection('series').doc(objSerie.serie.toString())
                                .set({
                                    serie:
                                        objSerie.serie === "" ||
                                            objSerie.serie === null ||
                                            objSerie.serie === undefined
                                            ? ""
                                            : objSerie.serie.toString(),
                                    created:
                                        firebase.firestore.FieldValue.serverTimestamp(),
                                    usuario_que_cargo_serie: userInfo.displayName,
                                    utilizado_por_codigo: obj.codigo,
                                    bodega: obj.bodega_destino,
                                    estado: 0
                                })

                        })

                    } else {
                        console.log('No hay series')
                    }
                });
        })
    }

    const handleSubmitIngreso = () => {

        getSeriesItemsTransferencias()

        console.log('Hola')
        let rol = "";
        if (userInfo.rol === 0) {
            rol = "ADMIN";
        }
        if (userInfo.rol === 1) {
            rol = "COMERCIAL";
        }
        if (userInfo?.roles?.find(role => ['operador']?.includes(role))) {
            rol = "OPERACIONES";
        }
        if (userInfo.rol === 3) {
            rol = "PLANEACION";
        }
        if (userInfo.rol === 4) {
            rol = "FLOTAS";
        }
        if (userInfo.rol === 5) {
            rol = "REPORTES";
        }
        if (userInfo.rol === 6) {
            rol = "RECURSOS HUMANOS";
        }
        //
        const uidSolicitud = `ING-` + numPedido;

        console.log(`UidSolicitud ${uidSolicitud}`)

        items.forEach(async function (obj, index) {
            console.log(obj)
            console.log(obj.cant_entrega)
        })

        const data = {
            uidSolicitud: uidSolicitud,
            estado: 0,
            numero_solicitud: numPedido,
            uid_user: userInfo.uid,
            user: userInfo.displayName,
            departamento: userInfo.departamentoText,
            rol: rol,
            created: firebase.firestore.FieldValue.serverTimestamp(),
            bodega: props.bodega_destino,
            ubicacion: '',
            motivo: props.motivo,
            motivo_movimiento: 'INGRESO',
            observacion_adicional: props.observacion === '' ? 'NINGUNA' : props.observacion,
            flota: '',
            lider_flota: '',
            auxiliar_flota: '',
            placa_flota: '',
            marca_flota: '',
            modelo_flota: '',
            tipo_movimiento: "ingreso",
            precio_compra: 0,
            fecha_documento: props.fecha_documento,
            relacion: props.uidSolicitud
        };

        db.collection("ingreso_productos").doc(uidSolicitud).set(data)

        db.collection("reporte_movimientos").doc(uidSolicitud).set(data)

        items.forEach(async function (obj, index) {

            const agendaDB2 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_destino).get()

            const data2 = await agendaDB2.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const agendaDB4 = await db.collection("productos").where("codigo_producto", "==", obj.codigo).get();

            const data4 = agendaDB4.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            const operacionCostoPromedio = parseFloat(data2[0].total_valorizado) / parseFloat(data2[0].stock_actual)

            const costoPromedio = Number.isNaN(Number(operacionCostoPromedio)) ? 0 : operacionCostoPromedio

            const totalEgreso = parseFloat(costoPromedio) * parseFloat(obj.cant_entrega)

            const saldo_valorizado =
                parseFloat(obj.cant_entrega) *
                parseFloat(data2[0].costo_promedio);

            const Total =
                parseFloat(data2[0].total_valorizado) +
                parseFloat(saldo_valorizado);

            const cantidad_total =
                parseFloat(data2[0].stock_actual) + parseFloat(obj.cant_entrega);

            console.log('SALDO VALORIZADO', saldo_valorizado)
            console.log('TOTAL', Total)
            console.log('CANTIDAD TOTAL', cantidad_total)

            console.log('data2[0].id', data2[0].id);

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).get();

            const data3 = agendaDB3.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

            var datoSumaStockTotalBodegas;

            let sumaStock = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["stock_actual"], 'STOCK ACTUAL')
                sumaStock += parseFloat(data3[i]["stock_actual"])
                datoSumaStockTotalBodegas = sumaStock
            }

            var sumaValorTotalValorizadoBodegas;

            let sumaTotalValorizado = 0;
            for (let i = 0; i < data3.length; i++) {
                console.log(data3[i]["total_valorizado"], 'TOTAL VALORIZADO')
                sumaTotalValorizado += parseFloat(data3[i]["total_valorizado"])
                sumaValorTotalValorizadoBodegas = sumaTotalValorizado
            }

            const total_bodegas = parseFloat(data4[0].cantidad_producto).toFixed(2) - Math.abs(parseFloat(obj.cant_entrega).toFixed(2))

            console.log(total_bodegas.toFixed(2), 'TOTAL BODEGAS')

            const sumaTotalBodegas =
                parseFloat(sumaValorTotalValorizadoBodegas) + parseFloat(Total);

            console.log(sumaTotalBodegas, 'SUMA TOTAL BODEGAS')

            console.log(data2[0].id, 'DATA 2 ID')

            console.log(parseFloat(parseFloat(data2[0].stock_actual).toFixed(2) + parseFloat(obj.cant_entrega).toFixed(2)).toFixed(0), 'SUMA')

            db.collection("reporte_stock").doc(data2[0].id).update({
                stock_actual: parseFloat(data2[0].stock_actual) + parseFloat(obj.cant_entrega),
                total_valorizado: Total.toFixed(2),
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio
            })

            db.collection("productos").doc(data4[0].id).update({
                cantidad_producto: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),
            });

            db.collection(`ingreso_productos/${uidSolicitud}/items`).doc(obj.codigo).set({
                cantidad_solicitada: parseInt(obj.cant_entrega),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                oobservacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_destino,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data2[0].costo_promedio,
            })

            const kardexGeneralSumaTotalStock = parseFloat(data4[0].cantidad_producto).toFixed(2) - parseInt(obj.cant_entrega).toFixed(2)

            const kardexGeneralSumaTotal = parseFloat(sumaValorTotalValorizadoBodegas).toFixed(2) - parseFloat(totalEgreso).toFixed(2)

            console.log(kardexGeneralSumaTotalStock, 'kardexGeneralSumaTotalStock')

            console.log(kardexGeneralSumaTotal, 'kardexGeneralSumaTotal')

            const dataRef = {
                fullName: obj.codigo,
                cantidad_solicitada: parseInt(obj.cant_entrega),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: parseFloat(cantidad_total).toFixed(2),
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                oobservacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_destino,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),/* Math.abs(total_bodegas.toFixed(2)), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data2[0].costo_promedio,
                fecha_documento: props.fecha_documento
            }

            const dataRef2 = {
                fullName: obj.codigo,
                cantidad_solicitada: parseInt(obj.cant_entrega),
                cantidad_total_antes: data2[0].stock_actual,
                cantidad_total_despues: kardexGeneralSumaTotalStock === null || kardexGeneralSumaTotalStock === undefined || Number.isNaN(Number(kardexGeneralSumaTotalStock)) ? 0 : kardexGeneralSumaTotalStock,
                ...obj,
                uid_solicitud_pedido: uidSolicitud,
                uid_user: userInfo.uid,
                user: userInfo.displayName,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                categoria_producto: obj.categoria_producto,
                subcategoria_producto: '',
                marca_producto: '',
                modelo_producto: '',
                presentacion_producto: '',
                unidad_Medida_Producto: '',
                usuario_que_creo_el_producto: obj.usuario_que_transfiere,
                costo_promedio: obj.subcategoria_producto === "COSTEABLE" ? costoPromedio : data2[0].costo_promedio,
                saldo_valorizado: parseFloat(saldo_valorizado),
                total: Number.isNaN(Number(Total)) ? 0 : Total,
                oobservacion_adicional: props.observacion,
                flota: '',
                lider_flota: '',
                auxiliar_flota: '',
                placa_flota: '',
                marca_flota: '',
                modelo_flota: '',
                bodega: obj.bodega_origen,
                ubicacion: '',
                motivo: props.motivo,
                tipo_movimiento: "ingreso",
                precio_compra: 0,
                total_bodegas: parseFloat(data4[0].cantidad_producto) + parseInt(obj.cant_entrega),/* Math.abs(total_bodegas.toFixed(2)), */
                suma_valores_total_blodegas: parseFloat(sumaTotalBodegas).toFixed(2),
                costo_promedio_general: data2[0].costo_promedio,
                fecha_documento: props.fecha_documento
            }

            db.collection(`total_movimientos`).doc().set(dataRef)

            // db.collection(`total_movimientos_general`).doc().set(dataRef2)

            db.collection(`reporte_movimientos/${uidSolicitud}/items`).doc(obj.codigo).set(dataRef)

        });

    }

    const aprobarSolicitud = () => {

        handleSubmitIngreso()

        items.forEach(async function (obj, index) {
            console.log(obj, 'OBJ')

            const agendaDB3 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where("bodega", "==", obj.bodega_destino).get()
            const data3 = agendaDB3.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            const agendaDB4 = await db.collection("reporte_stock").where("codigo_producto", "==", obj.codigo).where('bodega', '==', obj.bodega_origen).get()
            const data4 = agendaDB4.docs.map(doc => ({ id: doc.id, ...doc.data() }))

            console.log('SI EXISTE LA BODEGA')

            /* db.collection("reporte_stock").doc(data3[0].id).update({
                stock_actual: parseInt(data3[0].stock_actual) + parseInt(obj.cant_entrega),
            }) */

            /* REPORTE DE MOVIMIENTO TRANSFERENCIA */

            db.collection(`reporte_movimientos/${props.uidSolicitud}/items`).doc(obj.codigo).set({
                fullName: obj.codigo,
                categoria_producto: obj.categoria_producto,
                descripcion: obj.descripcion,
                costo_promedio: data4[0].costo_promedio,
                precio_compra: data4[0].precio_compra,
                cantidad_solicitada: parseInt(obj.cant_entrega),
                bodega_origen: obj.bodega_origen,
                bodega_destino: obj.bodega_destino,
                created: firebase.firestore.FieldValue.serverTimestamp(),
                usuario_que_aprobo_recepcion: userInfo.displayName,
                fecha_de_aprobacion_recepcion: firebase.firestore.FieldValue.serverTimestamp(),
            })

        });

        db.collection("reporte_movimientos").doc(props.uidSolicitud).update({
            usuario_que_aprobo: userInfo.displayName,
            usuario_que_aprobo_recepcion: userInfo.displayName,
            fecha_documento: props.fecha_documento
        })

        db.collection("transferencias").doc(props.uidSolicitud).update({
            estado: 4,
            usuario_que_aprobo: userInfo.displayName,
            usuario_que_aprobo_recepcion: userInfo.displayName,
            fecha_de_aprobacion_recepcion: firebase.firestore.FieldValue.serverTimestamp(),
            fecha_documento: props.fecha_documento
        })
        setOpenConfirm(false)
        /* Swal.fire({ icon: "success", title: `Solicitud aprobada con exito` }); */
        Swal.fire({
            title: 'Se esta realizando la recepcion de transferencia',
            text: 'Por favor espere',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        setTimeout(() => {

            Swal.fire({ icon: "success", title: `Recepcion realizada con exito` });

        }, 20000);
    }

    const handleButtonClick = async () => {

        let fechaDate = Date(props.fecha_documento);

        const fechaTimestamp = firebase.firestore.Timestamp.fromDate(new Date(fechaDate));

        console.log('fechaTimestamp', fechaTimestamp)

        setLoadingButtonAprobarSolicitudTransferencia(true)

        console.log('items', items)
        console.log('numPedido', numPedido)
        console.log('userInfo', userInfo)
        console.log('props', props)
        console.log('fechaTimestamp', fechaTimestamp)
        try {
            const recepcionTransferencias = firebase.functions().httpsCallable("recepcionTransferencias");

            const data = {
                numPedido: numPedido,
                userInfo: userInfo,
                items: items,
                props: props,
                fechaDocumento: fechaTimestamp
            };

            const response = await recepcionTransferencias(data);

            setOpenConfirm(false)

            Swal.fire({ icon: "success", text: "Se realizo la solicitud de transferencia correctamente", });

            console.log(response.data.message); // Mensaje de éxito de la Cloud Function
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);

            setOpenConfirm(false)

            Swal.fire({ icon: "error", text: "Ha ocurrido un error, por favor intentelo nuevamente", });
        }
    };

    return (
        <>
            <Tooltip title="Ver">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>

            <Dialog
                fullWidth
                maxWidth="xl"
                open={open}
            >
                <DialogTitle>
                    TRANSFERENCIA: {props.id}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 550, width: '100%' }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>CODIGO</TableCell>
                                        <TableCell align="center">DESCRIPCION</TableCell>
                                        <TableCell align="center">BODEGA ORIGEN</TableCell>
                                        <TableCell align="center">BODEGA DESTINO</TableCell>
                                        <TableCell align="center">CANTIDAD TRANSFERIDA</TableCell>
                                        <TableCell align="center">CANTIDAD RECIBIDA</TableCell>
                                        <TableCell align="center">DIFERENCIA</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        items && items.map((item, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="center">{item.codigo}</TableCell>
                                                <TableCell align="center">{item.descripcion}</TableCell>
                                                <TableCell align="center">{item.bodega_origen}</TableCell>
                                                <TableCell align="center">{item.bodega_destino}</TableCell>
                                                <TableCell align="center">{item.cantidad_transferencia}</TableCell>
                                                <TableCell align="center"><CalcularItems items={item} /></TableCell>
                                                {/* <TableCell align="center">{item.cantidad_transferencia - item.cant_entrega}</TableCell> */}
                                                <TableCell align="center">{item.cant_diferencia}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <DataGrid
                            density='compact'
                            rows={items ? items : []}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent,
                            }}
                            onStateChange={(state) => {
                                setTotal(state.pagination.rowCount)
                            }}
                        /> */}
                    </Box>



                </DialogContent>
                {/* <Box p={2}>
                    <TextField
                        label='OBSERVACION'
                        fullWidth
                        value={observacionExtra}
                        onChange={(e) => setObservacionExtra(e.target.value)}
                    />
                </Box> */}
                <DialogActions>
                    <LoadingButton loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end" variant="outlined" color="primary" size="small" onClick={() => setOpen(false)}>CERRAR VENTANA</LoadingButton>
                    <LoadingButton loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end" variant="contained" color="secondary" size="small" onClick={() => setOpenConfirm(true)}>CONFIRMAR TRANSFERENCIA</LoadingButton>
                    {/* <Button onClick={() => setOpen(false)}>CERRAR VENTANA</Button>
                    <Button onClick={() => {
                        setOpen(false)
                        setOpen1(true)
                    }} color='error' variant="contained" disabled={loading} >RECHAZAR ENTREGA</Button>
                    <Button onClick={sendConfirmItems} color='success' variant="contained" disabled={loading} >APROBAR ENTREGA</Button> */}
                </DialogActions>
            </Dialog>

            {/* CONFIRM RECEPCION DE TRANSFERENCIA */}

            <Dialog
                fullWidth
                maxWidth="xs"
                open={openConfirm}
            >
                <DialogTitle>
                    ¿ESTA SEGURO DE CONFIRMAR LA RECEPCION DE MERCADERIA? <strong>{props.id}</strong>
                </DialogTitle>
                <DialogContent>


                </DialogContent>

                <DialogActions>
                    <LoadingButton loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end" variant="outlined" color="primary" size="small" onClick={() => closeModalConfirm()}>REGRESAR</LoadingButton>
                    <LoadingButton loading={loadingButtonAprobarSolicitudTransferencia}
                        endIcon={loadingButtonAprobarSolicitudTransferencia === true ? <SendIcon /> : ''}
                        loadingPosition="end" variant="contained" color="secondary" size="small" onClick={() => handleButtonClick()/* aprobarSolicitud() */}>CONFIRMAR</LoadingButton>
                </DialogActions>
            </Dialog>

        </>
    );
};